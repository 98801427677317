<div>
	<textarea [dataValidation]="{ placement: 'top', value: noteText }" [ngModel]="noteText()" (ngModelChange)="noteText($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" name="note" maxlength="4000" placeholder="Enter ticket note (4,000 characters max)"></textarea>
	<div class="card-footer">
		<div class="row">
			<div class="col-sm-4">
				<select [dataValidation]="{ placement: 'top', value: contactTypeId }" [ngModel]="contactTypeId()" (ngModelChange)="contactTypeId($event)" [ngModelOptions]="{ standalone: true }" class="form-select input-sm" style="font-size:14px;" name="contactTypeId">
					<option [ngValue]="undefined">Contact Type</option>
					<option *ngFor="let option of contactTypes" [ngValue]="option.ContactTypeId">{{ option.Name }}</option>
				</select>
			</div>
			<div class="col-sm-8">
				<button [hidden]="!canCancel" (click)="triggerTicketCancel()" class="btn btn-sm btn-link">Cancel</button>
				<div class="btn-group">
					<button (click)="triggerTicketSave(SaveTypes.Update, true)" class="btn btn-sm btn-primary">
						<i class="fa fa-save"></i>
						Update
					</button>
					<ng-container *ngIf="canUpdateAndKeepOpen">
						<button type="button" class="btn btn-sm btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="caret"></span>
							<span class="sr-only">Toggle Dropdown</span>
						</button>
						<ul class="dropdown-menu">
							<li>
								<button (click)="triggerTicketSave(SaveTypes.Update, false)" class="btn btn-sm btn-link">Update &amp; Keep Open</button>
							</li>
						</ul>
					</ng-container>
				</div>
				<button [hidden]="!canComplete" (click)="triggerTicketSave(SaveTypes.Complete, true)" class="btn btn-sm btn-success">
					<i class="fa fa-check-square"></i>
					Complete
				</button>
			</div>
		</div>
	</div>
</div>
