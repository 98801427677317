import User from 'app/models/typescript/User';

export default class PartnerUser extends User implements Boo.Objects.PartnerUser {
    public InsertedDate: Date;
    public Partner: Boo.Objects.Partner;
    public PartnerUserId: number;
    public PartnerId: number;
    public IsPrimary: boolean;
    public UserLevelId: number;
    public IsManager: boolean;
    public IsReseller: boolean;
    public TaskTypes: Boo.Objects.TaskType[];
    public IsCsr: boolean;
    public IsWriter: boolean;
    public CanViewAdultContent: boolean;
    public TicketTypes: Boo.Objects.PartnerUserTicketType[];
    public Teams: Boo.Objects.PartnerUserTeam[];
    public IsReadonly: boolean;
    public HasIdentity: boolean;
}
