<div>
	<div [loadingOverlay]="isLoading()" class="card">
		<div class="card-header">Add Guarantee Violation</div>
		<div class="card-body">
			<form>
				<div class="form-group">
					<label>Type</label>
					<select [dataValidation]="{ placement: 'top', value: selectedType }" [ngModel]="selectedType()" (ngModelChange)="selectedType($event)" [ngModelOptions]="{ standalone: true }" class="form-select">
						<option [ngValue]="undefined">Choose...</option>
						<option *ngFor="let option of types" [ngValue]="option">{{ option.Name }}</option>
					</select>
				</div>
				<div class="form-group">
					<label>Comment</label>
					<textarea [dataValidation]="{ placement: 'top', value: description }" [ngModel]="description()" (ngModelChange)="description($event)" [ngModelOptions]="{ standalone: true }" class="form-control line-breaks"></textarea>
				</div>
			</form>
		</div>
		<div class="card-footer">
			<div class="row">
				<div class="col-lg-1 text-start">
					<button (click)="cancel()" class="btn btn-link">Cancel</button>
				</div>
				<div class="col-lg-2 offset-lg-9 text-end">
					<button (click)="save()" class="btn btn-success">
						<i class="fa fa-plus"></i>
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
