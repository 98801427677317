/* tslint:disable */

export default class BoundFunctionEqualityMonkeyPatch {
    public static register() {
        //note: this makes Object.is(function.bind(this), function.bind(this)) return true. function.bind(this) === function.bind(this) will still be false (because there'se no way to override that in JS).
        var oldFunctionPrototypeBind = Function.prototype.bind;
        Function.prototype.bind = function () {
            if (this[Symbol.for('boundFunction')] !== undefined) {
                return this;
            }

            var result = oldFunctionPrototypeBind.apply(this, arguments);
            result[Symbol.for('boundFunction')] = this;
            result[Symbol.for('boundArguments')] = arguments;
            return result;
        };

        let oldObjectIs = Object.is;
        Object.is = function (value1: any, value2: any) {
            if (value1 && value2 && value1[Symbol.for('boundFunction')] && value2[Symbol.for('boundFunction')]) {
                value1 = value1[Symbol.for('boundFunction')];
                value2 = value2[Symbol.for('boundFunction')];
                let arguments1 = value1[Symbol.for('boundArguments')];
                let arguments2 = value2[Symbol.for('boundArguments')];
                return oldObjectIs(value1, value2) && oldObjectIs(arguments1, arguments2);
            }

            return oldObjectIs(value1, value2);
        };
    }
}
