import { Component } from '@angular/core';
import { BlacklistDomainService } from '../../../services/blacklist-domain.service';

@Component({
  selector: 'app-components-manager-blacklistdomains',
  templateUrl: './BlacklistDomains.component.html'
})
export class BlacklistDomainsComponent {
  domainToAdd: string = '';
  blacklistDomains: Boo.Objects.BlacklistDomain[] = [];
  filteredBlacklistDomains: Boo.Objects.BlacklistDomain[] = [];
  filterString: string = '';
  isLoading: Boolean;

  constructor(private blacklistDomainService: BlacklistDomainService) { }

  ngOnInit(): void {
    this.refreshDomains()
      .catch((error: string) => toastr.error(error))
      .finally(() => this.isLoading = false);
  }

  filter(): void {
    this.filteredBlacklistDomains = this.blacklistDomains.filter(x => x.Domain.trim().toLowerCase().indexOf(this.filterString.trim().toLowerCase()) >= 0);
  }

  addDomain() {
    if (!this.domainToAdd) {
      return;
    }

    if (this.domainToAdd.includes('://')) {
      toastr.error('Blacklist domain must not include protocol, e.g., "https://"');
      return;
    }

    var domainSegments = this.domainToAdd.split('.');
    if ((domainSegments.length !== 2 && domainSegments.length !== 3)
      || domainSegments.some(x => x.length === 0)
      || domainSegments.some(x => x.includes('/'))) {
      toastr.error('Blacklist domain must include exactly one root domain with an optional subdomain.<br><br>Example: website.com or blog.website.com')
      return;
    }

    this.isLoading = true;
    this.blacklistDomainService.save(this.domainToAdd)
      .toPromise()
      .then(() => {
        this.refreshDomains();
        this.domainToAdd = '';
        toastr.success('Root domain succesfully added. Please allow up to 15 minutes for this change to be reflected in workspaces.')
      })
      .catch((error: string) => toastr.error(error))
      .finally(() => this.isLoading = false);
  }

  delete(id: number) {
    this.isLoading = true;
    this.blacklistDomainService.delete(id)
      .toPromise()
      .then(() => this.refreshDomains())
      .catch((error: string) => toastr.error(error))
      .finally(() => this.isLoading = false);
  }

  private refreshDomains() {
    return this.blacklistDomainService.getAll()
      .toPromise()
      .then((domains: Boo.Objects.BlacklistDomain[]) => {
        this.blacklistDomains = domains;
        this.filter(); 
      });
  }
}
