import { Component } from '@angular/core';
import System from 'framework/System';
import { CustomerTodoListComponent } from '../../todo/CustomerTodoList/CustomerTodoList.component';

@Component({
	selector: 'app-components-ticket-customertodolistticket',
	templateUrl: './CustomerTodoListTicket.component.html'
})
export class CustomerTodoListTicketComponent {
  public customer: Boo.Objects.Customer;
  public unresolved: KnockoutObservable<number> = ko.observable(0);
  public unsavedChanges: KnockoutObservable<boolean> = ko.observable(false);
  public isUserPartner: KnockoutObservable<boolean> = ko.observable(false);
  public ctlViewModel: CustomerTodoListComponent;

  public activate(params: any): JQueryPromise<void> {
    this.customer = ko.toJS(params.customer);

    return System.emptyPromise();
  }

  public saveTodoList(): void {
    this.ctlViewModel.save();
  }

  // tslint:disable-next-line
  public customerTodoListComposeBinding(model: CustomerTodoListComponent): void {
    this.ctlViewModel = model;

    this.isUserPartner(this.ctlViewModel.isUserPartner);
    // number of unresolved todos
    this.unresolved(this.ctlViewModel.unresolvedTodos());
    this.ctlViewModel.unresolvedTodos.subscribe(x => this.unresolved(x));

    // change detection
    this.ctlViewModel.unsavedChanges.subscribe(x => this.unsavedChanges(x));
  }
}
