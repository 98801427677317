import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ticketTypeConfig } from '../../../src/app/components/ticket/TicketTypeConfig';

@Injectable({
  providedIn: 'root'
})
export class PartnerUserService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/partnerusers`;

  constructor(private http: HttpClient) { }

  getSalesOverview(): Observable<Boo.Objects.SalesMetrics> {
    return this.http.get<Boo.Objects.SalesMetrics>(`${this.baseUrl}/GetSalesOverview`);
  }

  create(
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    phoneExtension: string,
    partnerUsers: Boo.Objects.PartnerUser[],
    languageIds: number[],
    ticketTypeIds: number[],
    taskTypeIds: number[],
    teamIds: number[],
    isCsr: boolean,
    isReseller: boolean,
    employeeCode: string,
    frontTeammateId: string,
    businessEntityId: number = 0,
    permissionIds: number[] = null,
    organizationalTeamId?: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/Create`, {
        username: username,
        password: password,
        firstName: firstName,
        lastName: lastName,
        phoneExtension: phoneExtension,
        partnerUsers: partnerUsers,
        languageIds: languageIds,
        ticketTypeIds: ticketTypeIds,
        taskTypeIds: taskTypeIds,
        teamIds: teamIds,
        isCsr: isCsr,
        isReseller: isReseller,
        employeeCode: employeeCode,
        organizationalTeamId: organizationalTeamId,
        frontTeammateId: frontTeammateId,
        businessEntityId: businessEntityId,
        permissionIds: permissionIds
    });
  }

  update(
    partnerUsersToUpdate: Boo.Objects.PartnerUser[],
    languageIds: number[],
    ticketTypeIds: number[],
    taskTypes: Boo.Objects.TaskType[],
    taskTypeIds: number[],
    teamIds: number[],
    frontTeammateId: string,
    organizationalTeamId?: number
  ): Observable<any> {
    return this.http.post(`${this.baseUrl}/Update`, {
        partnerUsersToUpdate: partnerUsersToUpdate,
        languageIds: languageIds,
        ticketTypeIds: ticketTypeIds,
        taskTypes: taskTypes,
        taskTypeIds: taskTypeIds,
        teamIds: teamIds,
        organizationalTeamId: organizationalTeamId,
        frontTeammateId: frontTeammateId
    });
  }

  assignTicketTypesByTeamId(teamIds: number[], ticketTypeIds: number[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/AssignTicketTypesByTeamId`, {
        teamIds: teamIds,
        ticketTypeIds: ticketTypeIds
    });
  }

  changePassword(email: string, currentPassword: string, newPassword: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/ChangePassword`, {
        email: email,
        currentPassword: currentPassword,
        newPassword: newPassword
    });
  }

  getByUserLevel(userLevelId: number): Observable<Boo.Objects.PartnerUser[]> {
    const params = new HttpParams().set('userLevelId', userLevelId);

    return this.http.get<Boo.Objects.PartnerUser[]>(`${this.baseUrl}/GetByUserLevel`, { params: params});
  }

  getActiveBoostabilityUsers(): Observable<Boo.Objects.PartnerUserFragment[]> {
    return this.http.get<Boo.Objects.PartnerUserFragment[]>(`${this.baseUrl}/GetActiveBoostabilityUsers`);
  }

  getByPartnerAndUserLevel(partnerId: number, userLevelId: number): Observable<any> {
    const params = new HttpParams()
      .set('partnerId', partnerId)
      .set('userLevelId', userLevelId);

    return this.http.get<any>(`${this.baseUrl}/GetByPartnerAndUserLevel`, { params: params });
  }

  selectByUserId(userId: number): Observable<Boo.Objects.PartnerUser[]> {
    const params = new HttpParams().set('userId', userId);

    return this.http.get<Boo.Objects.PartnerUser[]>(`${this.baseUrl}/SelectByUserId`, { params: params });
  }

  getByUserId(userId: number): Observable<Boo.Objects.PartnerUser[]> {
    const params = new HttpParams().set('userId', userId);

    return this.http.get<Boo.Objects.PartnerUser[]>(`${this.baseUrl}/GetByUserId`, { params: params });
  }

  getInactiveByUserLevelId(userLevelId: number): Observable<Boo.Objects.PartnerUser[]> {
    const params = new HttpParams().set('userLevelId', userLevelId);

    return this.http.get<Boo.Objects.PartnerUser[]>(`${this.baseUrl}/GetInactiveByUserLevelId`, { params: params });
  }

  search(searchString: string): Observable<Boo.Objects.PartnerUserSearchResult[]> {
    const params = new HttpParams().set('searchString', searchString);

    return this.http.get<Boo.Objects.PartnerUserSearchResult[]>(`${this.baseUrl}/Search`, { params: params });
  }

  searchByOrganizationalTeam(organizationalTeamId: number): Observable<Boo.Objects.PartnerUserSearchResult[]> {
    const params = new HttpParams().set('organizationalTeamId', organizationalTeamId);

    return this.http.get<Boo.Objects.PartnerUserSearchResult[]>(`${this.baseUrl}/SearchByOrganizationalTeam`, { params: params });
  }

  delete(partnerUserId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/Delete`, partnerUserId);
  }

  addCurrentUserToPartner(partnerId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/AddCurrentUserToPartner`, partnerId);
  }

  getActiveCustomerReps(): Observable<Boo.Objects.PartnerUser[]> {
    return this.http.get<Boo.Objects.PartnerUser[]>(`${this.baseUrl}/GetActiveCustomerReps`);
  }
}
