import { Component, Input } from '@angular/core';
import { QaCriteriaControl } from '../qa-criteria-control/qa-criteria-control.component';

@Component({
	selector: 'app-components-qa-criteria-button-group',
	templateUrl: './qa-criteria-button-group.component.html',
	styleUrls: ['./qa-criteria-button-group.scss']
})
export class QaCriteriaButtonGroup extends QaCriteriaControl {
	@Input() blockTitle: boolean = false;

	ngOnInit(): void {
		super.ngOnInit();
	}
}