import { Observable, of } from "rxjs";
import { KeywordsHelper } from "./keywords-helper";
import { KeywordsComponent } from "../Keywords.component";
import { KeywordSuggestionConfigFactory } from "../../../../factories/KeywordSuggestionConfigFactory";
import { KeywordSuggestionConfig } from "app/models/KeywordSuggestionConfig";
import { UniqueKeywordSuggestionValidator } from "../../../shared/Keywords/unique-keyword-suggestion-validator";
import { KeywordSuggestionService } from "../../../../services/keyword-suggestion.service";

export class SeoOnboardingKeywordsHelper implements KeywordsHelper {
  private keywordSuggestionConfigFactory: KeywordSuggestionConfigFactory = new KeywordSuggestionConfigFactory();

  constructor(private component: KeywordsComponent) { }

  getKeywordSuggestionConfig(): KeywordSuggestionConfig {
      return this.keywordSuggestionConfigFactory.create(this.component.customer.SeoMinutesPaidRecurring(), this.component.customer.IsCampaignNational(), this.component.customer.PartnerId());
  }

  loadAdditional(): Observable<any> {
      return of(null);
  }

  getHaloKeywordCount(): number {
      let count = this.component.summary.HaloKeywordSuggestions.filter(x => x.KeywordSuggestionStatusId !== Boo.Objects.Enums.KeywordSuggestionStatus.Rejected).length;
      return count + this.component.newHaloKeywords.filter(x => x.KeywordSuggestionStatusId !== Boo.Objects.Enums.KeywordSuggestionStatus.Rejected).length;
  }

  createUniqueKeywordSuggestionValidator(): UniqueKeywordSuggestionValidator {
      return new UniqueKeywordSuggestionValidator(this.component.summary.UrlSuggestions, this.component.summary.HaloKeywordSuggestions, () => this.component.customer.IsCampaignNational());
  }

  requestRedo(keywordSuggestionService: KeywordSuggestionService): Observable<any> {
      return keywordSuggestionService.requestRedo({ CustomerId: this.component.ticket.CustomerId(), Note: this.component.redoNoteForm.value, UserId: this.component.user.UserId });
  }
}