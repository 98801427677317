import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class KeywordSuggestionService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/keywordsuggestions`;

  constructor(private http: HttpClient) { }

  getSummary(customerId: number) : Observable<Boo.Objects.KeywordSuggestions.KeywordSuggestionSummary> {
    return this.http.get<Boo.Objects.KeywordSuggestions.KeywordSuggestionSummary>(`${this.baseUrl}/summary`, { params: { customerId: customerId } });
  }

  getTicketSummary(customerId: number) : Observable<Boo.Objects.KeywordSuggestions.KeywordSuggestionSummary> {
    return this.http.get<Boo.Objects.KeywordSuggestions.KeywordSuggestionSummary>(`${this.baseUrl}/ticketSummary`, { params: { customerId: customerId } });
  }

  save(keywordSuggestions: Boo.Objects.LegacyKeywordSuggestion[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/`, keywordSuggestions);
  }

  requestRedo(redoRequest: Boo.Objects.KeywordSuggestions.KeywordResearchRedoRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/requestRedo`, redoRequest); // TODO: Removed from service
  }

  requestStrategyUpdateRedo(redoRequest: Boo.Objects.KeywordSuggestions.KeywordResearchStrategyUpdateRedoRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/requestStrategyUpdateRedo`, redoRequest);
  }
}