import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { SiteInformationComponent } from './SiteInformation/SiteInformation.component';
import { WebsiteComponent } from './Website/Website.component';
import { WebsiteAdjustmentsComponent } from './WebsiteAdjustments/WebsiteAdjustments.component';
import { BoostSiteAdminSSOButtonComponent } from './BoostSiteAdminSSOButton/BoostSiteAdminSSOButton.component';
import { WebsiteDetailsComponent } from './WebsiteDetails/WebsiteDetails.component';
import { VendorNotificationsComponent } from './VendorNotifications/VendorNotifications.component';
import { WebsiteDomainComponent } from './WebsiteDomain/WebsiteDomain.component';
import { WebsiteReportingComponent } from './WebsiteReporting/WebsiteReporting.component';
import { WebsitesComponent } from './Websites.component';
import { WebsiteTemplateSelectorComponent } from './WebsiteTemplateSelector/WebsiteTemplateSelector.component';
import { SharedComponentsModule } from '../shared/shared-components.module';


@NgModule({
  declarations: [
    SiteInformationComponent,
    WebsiteComponent,
    WebsiteAdjustmentsComponent,
    BoostSiteAdminSSOButtonComponent,
    WebsiteDetailsComponent,
    VendorNotificationsComponent,
    WebsiteDomainComponent,
    WebsiteReportingComponent,
    WebsitesComponent,
    WebsiteTemplateSelectorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedComponentsModule
  ],
  exports: [
    BoostSiteAdminSSOButtonComponent
  ]
})
export class WebsitesModule { }
