import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-components-specialist-internal-tasks-qa-action-information',
  templateUrl: './qa-action-information.component.html'
})
export class QaActionInformationComponent implements OnInit {
  @Input() internalAction: Boo.Objects.InternalAction;
  associatedTaskCustomer: Boo.Objects.Customer;
  dateString: string;

  ngOnInit() {
    this.associatedTaskCustomer = this.internalAction.InternalTaskDetails.AssociatedTaskCustomer;
    this.dateString = moment(this.internalAction.InternalTaskDetails.AssociatedTask.StatusDate).format('MM/DD/YYYY h:mm:ss A');
  }
}
