import { Component } from '@angular/core';
import { TicketService } from '../../../../services/ticket.service';
import { TaskFileService } from '../../../../services/task-file.service';
import { CustomerService } from '../../../../services/customer.service';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import CustomerAccountTypeGroup from 'app/customeraccount/CustomerAccountTypeGroup';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import TaskHelper from 'app/specialist/TaskHelper';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import System from 'framework/System';
import { DisplayModes } from 'app/models/enums/DisplayModes';

@Component({
  selector: 'app-components-specialist-actioncomponents-premium-monthly-campaign-update',
  templateUrl: './premium-monthly-campaign-update.component.html'
})
export class PremiumMonthlyCampaignUpdateComponent implements IActionWorkspace, IActivatable {
  DisplayModes = DisplayModes;
  window: any = window;
  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;
  isLoading: boolean = false;
  taskFiles: Boo.Objects.TaskFile[] = [];
  taskDefinitionFiles: Boo.Objects.TaskFile[] = [];
  tickets: Boo.Objects.Ticket[] = [];
  noteToCsr: string;
  customerAccountGroupIsEnabled: KnockoutObservable<boolean> = ko.observable(false);

  customer: Boo.Objects.Customer;
  customerId: number;
  customerAccountTypes: number[];
  noteCount: KnockoutComputed<number>;
  notes: KnockoutObservableArray<Boo.Objects.TaskNote>;
  private taskActionUrl: string;

  constructor(
    private customerService: CustomerService,
    private taskFileService: TaskFileService,
    private ticketService: TicketService) { }

  ngOnInit(): void {
    this.actionViewModel = ko.observable(null);
    this.customer = this.currentAction().Customer;
    this.customerId = this.currentAction().Task.CustomerId;
    this.customerAccountTypes = CustomerAccountTypeGroup.customImplementationLogins;
    this.noteToCsr = '';
    this.isLoading = true;
    forkJoin([
      this.taskFileService.selectByTaskId(this.currentAction().Task.TaskId),
      this.ticketService.getByCustomer(this.customerId, false)
    ])
    .pipe(finalize(() => this.isLoading = false))
    .subscribe(([taskFiles, tickets]) => {
        this.taskDefinitionFiles = taskFiles.filter(x => x.TaskFileType === Boo.Objects.Enums.TaskFileTypeEnum.TaskDefinitionFile);
        this.taskFiles = taskFiles.filter(x => x.TaskFileType !== Boo.Objects.Enums.TaskFileTypeEnum.TaskDefinitionFile);
        this.tickets = tickets.filter(x => x.AssociatedTaskId === this.currentAction().Task.TaskId && x.StatusId === Boo.Objects.Enums.TicketStatusEnum.Completed);
      },
      err => toastr.error(err));
  }

  activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {
    params.controlViewModel(this);
    this.currentAction = params.currentAction;
    return System.emptyPromise();
  }

  isStepReadyToReject(): boolean {
    return true;
  }

  isStepReadyToSave(): boolean {
    return this.currentAction().Task.TaskTypeSummary.IsPersistable;
  }

  isStepReadyToDelete(): boolean {
    return false;
  }

  isStepReadyToComplete(): boolean {
    TaskHelper.setComment(this.currentAction().Task, this.noteToCsr, this.currentAction().Task.AssignedUserId);

    if (_.isArray(this.taskFiles) && this.taskFiles.length > 0) {
      this.currentAction().Task.TaskActionUrl = this.taskActionUrl;
    } else {
      this.currentAction().Task.TaskActionUrl = null;
    }

    return true;
  }

  uploadFile(): void {
    let request = new PresignedUrlRequest();
    request.BucketName = launchpad.config.S3Buckets.s3staticfiles;
    request.Folder = 'taskfile/' + this.currentAction().Task.TaskId;
    request.UseUniqueFileName = true;

    PriorityViewModel.show('app-components-shared-uploadfile', { preSignedURLRequest: request, acceptedFileExtensions: ['.xlsx', '.csv'] })
      .then((data: any) => {
        let taskFile = <Boo.Objects.TaskFile>{
          Name: data.FileName,
          TaskFileType: Boo.Objects.Enums.TaskFileTypeEnum.TaskActionFile,
          IsDeleted: false,
          TaskId: this.currentAction().Task.TaskId,
          S3Url: data.FullUrl
        };

        this.taskActionUrl = taskFile.S3Url;
        return this.taskFileService.insertUpdate(taskFile)
          .pipe(
            finalize(() => this.isLoading = false)
          )
          .subscribe(
            taskFileResult => {
              this.taskFiles.push(taskFileResult);
            },
            err => toastr.error(err));
      });
  }

  viewTicket(ticketId: number): void {
    let ticketOptions = {
      // don't pass customer so that ticket vm will load customer.
      ticketId: ticketId
    };
    PriorityViewModel.show('app-components-ticket', ticketOptions);
  }

  deleteFile(file: Boo.Objects.TaskFile): void {
    bootbox.confirm('Are you sure? This action cannot be undone.', (result: boolean) => {
      if (result === true) {
        file.IsDeleted = true;
        if (this.taskActionUrl === file.S3Url) {
          this.taskActionUrl = undefined;
        }

        this.taskFileService.insertUpdate(file)
          .subscribe(
            x => {
              this.taskFiles = this.taskFiles.filter(y => y.TaskFileId !== file.TaskFileId);
              toastr.success('Task File Deleted');
            },
            err => toastr.error(err));
      }
    });
  }
}
