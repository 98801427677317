<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <span>Change Password</span>
          <button (click)="cancel()" class="btn-close" title="Close"></button>
        </div>
        <div [loadingOverlay]="isLoading" class="card-body">
          <form [formGroup]="changePasswordForm" (submit)="update()">
            <div class="form-group col-lg-6">
              <label for="currentPassword">Current Password</label>
              <input id="currentPassword" formControlName="currentPassword" type="password" class="form-control"
                [ngClass]="changePasswordForm.controls['currentPassword'].touched && changePasswordForm.controls['currentPassword'].invalid ? 'is-invalid' : ''">
              <label class="text-danger"
                *ngIf="changePasswordForm.controls['currentPassword'].touched && changePasswordForm.controls['currentPassword'].hasError('required')">
                Required
              </label>
            </div>
            <div class="form-group col-lg-6">
              <label for="password">New Password</label>
              <input id="password" formControlName="password" type="password" class="form-control"
                [ngClass]="changePasswordForm.controls['password'].touched && changePasswordForm.controls['password'].invalid ? 'is-invalid' : ''">
              <div class="mt-2" *ngIf="changePasswordForm.controls['password'].dirty">
                <label class="col"
                  [ngClass]="changePasswordForm.controls['password'].hasError('required') || changePasswordForm.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                  <i class="fas"
                    [ngClass]="changePasswordForm.controls['password'].hasError('required') || changePasswordForm.controls['password'].hasError('minlength') ? 'fa-times-circle' : 'fa-check-circle'"></i>
                  Must contain at least 8 characters
                </label><br>
                <label class="col"
                  [ngClass]="changePasswordForm.controls['password'].hasError('required') || changePasswordForm.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                  <i class="fas"
                    [ngClass]="changePasswordForm.controls['password'].hasError('required') ||
                    changePasswordForm.controls['password'].hasError('hasNumber') ? 'fa-times-circle' : 'fa-check-circle'"></i>
                  Include number
                </label><br>
                <label class="col"
                  [ngClass]="changePasswordForm.controls['password'].hasError('required') || changePasswordForm.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                  <i class="fas"
                    [ngClass]="changePasswordForm.controls['password'].hasError('required') ||
                    changePasswordForm.controls['password'].hasError('hasCapitalCase') ? 'fa-times-circle' : 'fa-check-circle'"></i>
                  Include uppercase characters
                </label><br>
                <label class="col"
                  [ngClass]="changePasswordForm.controls['password'].hasError('required') || changePasswordForm.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                  <i class="fas"
                    [ngClass]="changePasswordForm.controls['password'].hasError('required') ||
                    changePasswordForm.controls['password'].hasError('hasSmallCase') ? 'fa-times-circle' : 'fa-check-circle'"></i>
                  Include lowercase characters
                </label><br>
                <label class="col"
                  [ngClass]="changePasswordForm.controls['password'].hasError('required') || changePasswordForm.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                  <i class="fas"
                    [ngClass]="changePasswordForm.controls['password'].hasError('required') ||
                    changePasswordForm.controls['password'].hasError('hasSpecialCharacters') ? 'fa-times-circle' : 'fa-check-circle'"></i>
                  Include special character
                </label>
              </div>
            </div>
            <div class="form-group col-lg-6">
              <label for="confirmPassword">Confirm Password</label>
              <input id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control"
                [ngClass]="changePasswordForm.controls['confirmPassword'].touched && changePasswordForm.controls['confirmPassword'].invalid ? 'is-invalid' : ''">
              <label class="text-danger" *ngIf="changePasswordForm.controls['confirmPassword'].hasError('required')">
                Password is Required!
              </label>
              <label class="text-danger"
                *ngIf="changePasswordForm.controls['confirmPassword'].touched && changePasswordForm.controls['confirmPassword'].hasError('NoPassswordMatch')">
                Password does not match
              </label>
            </div>
            <div class="card card-body bg-light d-block text-end">
              <button (click)="cancel()" class="btn btn-link">Cancel</button>
              <button [disabled]="changePasswordForm.invalid" type="submit" class="btn btn-success">
                <i class="fa fa-save"></i>
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
