export default class PushNotificationConfig {
    /**
     * Configuration of app channels and their events.
     * Use these values to subscribe to events on the channels.
     */
    public static channels = {
        ticket: {
            name: 'ticket',
            events: {
                seoOnboardingWorkBlockLockChanged: 'seoOnboardingWorkBlockLockChanged'
            }
        },
        connectedUser: {
            name: 'connectedUsers',
            events: {
                onUserAdded: 'onUserAdded',
                onUserRemove: 'onUserRemove',
                onUsersSet: 'onUsersSet'
            },
            methods: {
                reconnect: 'reconnect'
            }
        },
        nonRecurringRanks: {
            name: 'nonRecurringRanks',
            events: {
                onRanksComplete: 'OnRanksComplete',
            }
        }
    };
}