export default class WorkflowHelper {
  static getIsBoostImplementingByResponsibility(workflowKeyValuePairs: Boo.Objects.WorkflowKeyValuePair[]): boolean {
    return WorkflowHelper.getImplementationResponsibility(workflowKeyValuePairs) === Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Boostability;
  }

  static getImplementationResponsibility(workflowKeyValuePairs: Boo.Objects.WorkflowKeyValuePair[]): Boo.OnsiteRecommendations.Models.Enums.Responsibilities {
    let value: string = WorkflowHelper.getValue(workflowKeyValuePairs, Boo.Objects.Enums.KeyEnum.ImplementationResponsibility);
    if (value) {
      return parseInt(value, 10);
    } else {
      return Boo.OnsiteRecommendations.Models.Enums.Responsibilities.NotSelected;
    }
  }

  static setImplementationResponsibility(workflowKeyValuePairs: Boo.Objects.WorkflowKeyValuePair[], workflowId: number, implementationResponsibility: Boo.OnsiteRecommendations.Models.Enums.Responsibilities): void {
    WorkflowHelper.setValue(workflowKeyValuePairs, Boo.Objects.Enums.KeyEnum.ImplementationResponsibility, workflowId, String(implementationResponsibility));
  }
  
  static getIsBoostImplementing(workflowKeyValuePairs: Boo.Objects.WorkflowKeyValuePair[]): boolean {
    return WorkflowHelper.getValue(workflowKeyValuePairs, Boo.Objects.Enums.KeyEnum.IsBoostImplementing).toLowerCase() === 'true';
  }

  static workflowKvpFactory(workflowId: number, value: string, key: Boo.Objects.Enums.KeyEnum): Boo.Objects.WorkflowKeyValuePair {
    return {
      WorkflowId: workflowId,
      WorkflowKeyValuePairId: 0,
      InsertedDate: null,
      KeyValuePair: {
        Key: {
          KeyId: key
        } as Boo.Objects.Key,
        KeyId: key,
        KeyValuePairId: 0,
        Value: value
      },
      KeyValuePairId: 0
    };
  }

  private static getValue(workflowKeyValuePairs: Boo.Objects.WorkflowKeyValuePair[], key: Boo.Objects.Enums.KeyEnum): string {
    let kvp = _.find(_.sortBy(workflowKeyValuePairs, k => k.InsertedDate).reverse(), x => x.KeyValuePair.KeyId === key);
    return kvp ? kvp.KeyValuePair.Value : '';
  }

  private static setValue(keyValuePairs: Boo.Objects.WorkflowKeyValuePair[], key: Boo.Objects.Enums.KeyEnum, workflowId: number, value: string): void {
    let kvp = _.find(_.sortBy(keyValuePairs, k => k.InsertedDate).reverse(), x => x.KeyValuePair.KeyId === key);
    if (kvp && kvp.KeyValuePair) {
      kvp.KeyValuePair.Value = value;
    } else {
      kvp = this.workflowKvpFactory(workflowId, value, key);
      keyValuePairs.push(kvp);
    }
  }
}

