/* tslint:disable */

export default class partneruser {
    public Categories = <any>null;
    public IsCsr = false;
    public IsManager = false;
    public IsPrimary = false;
    public IsReadonly = false;
    public IsReseller = false;
    public IsWriter = false;
    public PartnerId = 0;
    public PartnerUserId = 0;
    public Teams = <any>null;
    public TicketTypes = <any>null;
    public UserLevelId = 0;
    public AccessToken = <any>null;
    public CustomerUsers = <any>null;
    public Customers = <any>null;
    public FirstName = <any>null;
    public FullName = <any>null;
    public InsertedDate = '0001-01-01T00:00:00';
    public IsActive = false;
    public Languages = <any>null;
    public LastName = <any>null;
    public PartnerUsers = <any>null;
    public Partners = <any>null;
    public Password = <any>null;
    public UserId = 0;
    public UserPermissions = <any>null;
    public Username = <any>null;
}