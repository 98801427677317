<div class="row">
	<div class="col-md-12">
		<div class="row">
			<div class="form-group col-md-3">
				<label class="control-label">Language</label>
				<select [ngModel]="selectedLanguage()" (ngModelChange)="selectedLanguage($event)" [ngModelOptions]="{ standalone: true }" name="languages" id="languages" class="form-select">
					<option *ngFor="let option of languages" [ngValue]="option.LanguageId">{{ option.Name }}</option>
				</select>
			</div>
			<div class="form-group col-md-9">
				<button [disabled]="isLoading()" (click)="createNewProTip()" class="btn btn-primary float-end">
					<i class="fa fa-plus"></i>
					Create Pro Tip
				</button>
			</div>
		</div>
		<ng-container *ngIf="!isLoading()">
      <app-table [items]="proTips">
        <ng-template #tableHeader>
          <tr>
            <th sort desc field="StartDate">Start Date</th>
            <th sort field="EndDate">End Date</th>
            <th sort field="Title">Title</th>
            <th sort field="Body">Body</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template #tableBody let-tip>
          <tr>
            <td>{{tip.StartDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
            <td>{{tip.EndDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
            <td>{{tip.Title}}</td>
            <td>{{tip.Body}}</td>
            <td>
              <button (click)="openProTip(tip)" class="btn btn-primary">
                <i class="fas fa-external-link-square-alt"></i>
                Open
              </button>
              <button [disabled]="isLoading()" (click)="delete(tip)" class="btn btn-danger">
                <i class="fa fa-times"></i>
                Delete
              </button>
            </td>
          </tr>
        </ng-template>
      </app-table>
		</ng-container>
	</div>
</div>
