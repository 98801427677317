<div>
	<app-components-togglepanel [(isOpen)]="isOpen" title="Link Building" toggleBody="true">
    <div class="form-group">
      <label class="control-label">Heading</label>
      <input [dataValidation]="{ placement: 'top', value: heading }" [ngModel]="heading()" (ngModelChange)="heading($event)" [ngModelOptions]="{ standalone: true }" type="text" class="form-control"/>
      <label class="control-label">Description</label>
      <textarea [dataValidation]="{ placement: 'top', value: description }" [ngModel]="description()" (ngModelChange)="description($event)" [ngModelOptions]="{ standalone: true }" class="form-control"></textarea>
    </div>
  </app-components-togglepanel>
</div>
