import { Component } from '@angular/core';
import System from 'framework/System';
import ITodoObservable = app.tsmodels.interfaces.ITodoObservable;

@Component({
	selector: 'app-components-todo-readonlytodolegacy',
	templateUrl: './ReadonlyTodoLegacy.component.html'
})
export class ReadonlyTodoLegacyComponent {
    public window = window;
    public launchpad = launchpad;
    public todo: KnockoutObservable<ITodoObservable>;
    public modalId: string;
    public userId: number;

    public activate(params: ITodoRViewModelActivationParams): JQueryPromise<void> {
        this.todo = params.todo;
        this.userId = params.userId;
        this.modalId = params.modalId;

        return System.emptyPromise();
    }

    public closeTodo(todo: ITodoObservable): void {
        $('#' + this.modalId).modal('hide');
    }
}

export interface ITodoRViewModelActivationParams {
    todo: KnockoutObservable<ITodoObservable>;
    userId: number;
    modalId: string;
}
