<div>
	<div class="row">
		<div class="col-md-12">
			<compose Selector="app-components-specialist-actioncomponents-customerinformation" [ActivateOptions]="{ customer: customer, taskDetails: taskDetails }"></compose>
		</div>
	</div>
	<ng-container *ngIf="customer.SeoContentSurvey">
		<div class="row">
			<div class="col-md-12">
				<compose Selector="app-components-specialist-actioncomponents-seocontentsurvey" [ActivateOptions]="{ seoContentSurvey: customer.SeoContentSurvey }"></compose>
			</div>
		</div>
	</ng-container>
</div>