<div class="card">
	<div class="card-header">
		<span>Tickets Overview</span>
		<button (click)="openTicketSummary()" class="btn btn-primary btn-sm float-end">
			<i class="fa fa-heartbeat"></i>
			Ticket Summary
		</button>
		<button (click)="loadStats()" class="btn btn-primary btn-sm float-end" style="margin-right: 5px;">
			<i class="fas fa-sync"></i>
			Refresh
		</button>
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body">
		<div class="row">
			<div class="col-md-12 text-center">
				<em class="text-muted" style="font-size: 24px;">We connect with our customers, share the wins, and do what we say.</em>
			</div>
		</div>
		<div class="row">
			<div class="col-md-3">
				<h4 class="card card-body bg-light d-block">
					<i class="far fa-check-square"></i>
					<span>{{ticketsCompleted()}}</span>
				</h4>
				<h5>Tickets Completed</h5>
			</div>
			<div class="col-md-3">
				<h4 class="card card-body bg-light d-block">
					<i class="fa fa-bolt"></i>
					<span>{{criticalTicketPercent()}}</span>
				</h4>
				<h5>Critical Ticket %</h5>
			</div>
			<div class="col-md-2">
				<h4 class="card card-body bg-light d-block">
					<i class="far fa-edit"></i>
					<span>{{notesEntered()}}</span>
				</h4>
				<h5>Notes Entered</h5>
			</div>
			<div class="col-md-2">
				<h4 class="card card-body bg-light d-block">
					<i class="far fa-envelope"></i>
					<span>{{emailsSent()}}</span>
				</h4>
				<h5>Emails Sent</h5>
			</div>
			<div class="col-md-2">
				<h4 class="card card-body bg-light d-block">
					<i class="fa fa-phone"></i>
					<span>{{callsMade()}}</span>
				</h4>
				<h5>Calls Made</h5>
			</div>
		</div>
	</div>
</div>
