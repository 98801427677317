<div>
  <div class="row">
    <div class="col-md-12">
      <ul class="list-group">
        <li class="list-group-item" *ngIf="onsiteCopyImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Implementation Task</strong>
            </div>
            <div class="col-md-10">
              {{ onsiteCopyImplementationSummary.TaskTypeName }}
            </div>
          </div>
        </li>
        <li class="list-group-item" *ngIf="onsiteCopyImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Description</strong>
            </div>
            <div class="col-md-10">
              {{ onsiteCopyImplementationSummary.Description }}  Please implement the attached copy to the following page as body copy on the URL below. If the page does not currently exist, create the page using the path indicated by the URL. For more specific instruction, see the notes from the CSR.
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Instructions</strong>
            </div>
            <div class="col-md-10 preserve-whitespace">{{ taskInstructions }}</div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Page Type</strong>
            </div>
            <div class="col-md-10">
              {{ pageType }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>URL</strong>
            </div>
            <div class="col-md-10">
              <a [externalLink]="{ url: url, text: url}" rel="noreferrer" style="cursor: pointer;"></a>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Content</strong>
            </div>
            <div class="col-md-10">
              <div [innerHtml]="content"></div>
            </div>
          </div>
        </li>
        <li class="list-group-item" *ngIf="onsiteCopyImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Publish Url</strong>
            </div>
            <div class="col-md-10">
              <a [externalLink]="{ url: onsiteCopyImplementationSummary.PublishUrl, text: onsiteCopyImplementationSummary.PublishUrl}" rel="noreferrer" style="cursor: pointer;"></a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New Title</strong>
            </div>
            <div class="col-md-10">
              {{ newTitle }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New Description</strong>
            </div>
            <div class="col-md-10">
              {{ newDescription }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New H1</strong>
            </div>
            <div class="col-md-10">
              {{ newH1 }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New H2</strong>
            </div>
            <div class="col-md-10">
              {{ newH2 }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <br />
  <br />
  <div class="row" *ngIf="onsiteCopyImplementationSummary">
    <div class="col-md-8">
      <app-components-specialist-taskfiles [options]="{
        taskId: onsiteCopyImplementationSummary.TaskId,
        sources: taskFileSources,
        allowUpload: true,
        apiCallback: taskFilesApiCallback,
        useSmallTitle: true
      }"></app-components-specialist-taskfiles>
    </div>
  </div>
  <app-floating-panels>
    <app-floating-panel [displayConfig]="{ title: 'External Access', icon: 'fa-key', size: 'floating-panel-md' }">
      <compose *floatingPanelContent Selector="app-components-managecustomer-readonlyexternalaccess" [Model]="{ customer: currentAction().Customer }">
      </compose>
    </app-floating-panel>
    <app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
      <app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="currentAction().TaskDetails.TaskNotes">
      </app-components-specialist-actioncomponents-comments>
    </app-floating-panel>
  </app-floating-panels>
</div>