import { Component, ViewChild } from '@angular/core';
import TaskHelper from 'app/specialist/TaskHelper';
import System from 'framework/System';
import { BusinessCitationService } from '../../../../services/business-citation.service';
import Utils from '../../../../shared/utils';
import { LocalProfilesComponent } from '../../../shared/local-profiles/local-profiles/local-profiles.component';

@Component({
  selector: 'app-components-specialist-actioncomponents-resolvebusinessprofileissues',
  templateUrl: './ResolveBusinessProfileIssues.component.html'
})
export class ResolveBusinessProfileIssuesComponent implements IActionWorkspace, IActivatable {
  @ViewChild(LocalProfilesComponent) localProfilesComponent: LocalProfilesComponent;
  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;
  taskDetails: Boo.Objects.ActionDetail;
  localProfile: Boo.Objects.LocalProfiles.LocalProfile;
  customer: Boo.Objects.Customer;
  allowRejectionConfirmation: boolean;
  localProfileIssues: Boo.BusinessCitations.Models.LocalProfileIssue[];
  allIssuesResolved: KnockoutObservable<boolean>;
  validation: KnockoutObservable<any>;
  isLoading: boolean = false;
  issueLabel: string;

  constructor(
    private businessCitationService: BusinessCitationService) { }

  activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {
    params.controlViewModel(this);
    this.currentAction = params.currentAction;
    this.actionViewModel = ko.observable(null);
    this.taskDetails = this.currentAction().TaskDetails;
    this.localProfile = params.currentAction().LocalProfile;
    this.customer = params.currentAction().Customer;
    this.allowRejectionConfirmation = TaskHelper.getAllowRejectionConfirmation(this.currentAction().Task);

    this.allIssuesResolved = ko.observable(null).extend({
      required: {
        onlyIf: () => {
          return this.allowRejectionConfirmation;
        }
      },
      validation: {
        validator: (val: boolean): boolean => {
          return this.allowRejectionConfirmation || val;
        },
        message: 'All issues must be resolved.'
      }
    });

    this.validation = ko.validatedObservable([this.allIssuesResolved]);

    return Utils.wrapDfd(this.businessCitationService.getUnresolvedLocalProfileIssues(this.localProfile.LocalProfileId))
      .then(issues => {
        this.localProfileIssues = issues;
        this.issueLabel = 'issue' + (issues.length > 1 ? 's' : '');
      });
  }

  isStepReadyToReject(): boolean {
    return false;
  }

  isStepReadyToSave(): boolean {
    return false;
  }

  isStepReadyToComplete(): JQueryPromise<boolean> {
    this.validation.errors.showAllMessages(false);

    if (this.localProfilesComponent.hasPendingChanges()) {
      toastr.error('You have pending changes. Please save them before completing this task.');
      return System.resolvedPromise(false);
    }

    if (!this.localProfilesComponent.isValid()) {
      return System.resolvedPromise(false);
    }

    if (!this.validation.isValid()) {
      this.validation.errors.showAllMessages();
      return System.resolvedPromise(false);
    }

    TaskHelper.setAllIssuesResolved(this.currentAction().Task, this.allIssuesResolved(), this.currentAction().Task.AssignedUserId);
    return System.resolvedPromise(true);
  }

  isStepReadyToDelete(): boolean {
    return false;
  }
}
