import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RedoRequest } from '../redo-request';

@Component({
  selector: 'app-components-shared-keywords-keyword-suggestion-group',
  templateUrl: './keyword-suggestion-group.component.html'
})
export class KeywordSuggestionGroupComponent implements OnInit {
  @Input() suggestions: Boo.Objects.LegacyKeywordSuggestion[];
  @Input() type: Boo.Objects.Enums.KeywordSuggestionTypes;
  @Input() isApproved: boolean;
  @Input() isRejected: boolean;
  @Input() redoRequested: EventEmitter<RedoRequest>;
  @Input() recommendationSelected: EventEmitter<Boo.Objects.Enums.KeywordSuggestionTypes>;

  @Output() approved = new EventEmitter<Boo.Objects.Enums.KeywordSuggestionTypes>();
  @Output() removedKeyword = new EventEmitter<Boo.Objects.LegacyKeywordSuggestion>();
  @Output() isApprovedChange = new EventEmitter<boolean>();
  @Output() isRejectedChange = new EventEmitter<boolean>();

  private previouslyApproved: boolean = false;
  private previouslyRejected: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.redoRequested.subscribe(redoRequest => {
      if (redoRequest.isRedoRequested) {
        this.previouslyRejected = this.isRejected;
        this.previouslyApproved = this.isApproved;
        this.isApproved = false;
      } else if (this.previouslyApproved) {
        this.approveGroup();
      }

      this.isRejected = redoRequest.isRedoRequested || this.previouslyRejected;
      this.approvalChanged();
    });

    this.recommendationSelected.subscribe(type => {
      if (!type) {
        this.isRejected = false;
        this.isApproved = false;
      } else if (type !== this.type) {
        this.isRejected = true;
        this.isApproved = false;
      }

      this.approvalChanged();
    });
  }

  approveGroup(): void {
    this.isApproved = true;
    this.approved.emit(this.type);
  }

  removeKeyword(keyword: Boo.Objects.LegacyKeywordSuggestion): void {
    this.removedKeyword.emit(keyword);
  }

  approvalChanged(): void {
    this.isApprovedChange.emit(this.isApproved);
    this.isRejectedChange.emit(this.isRejected);
  }

  getKeywordDisplayText(keyword: Boo.Objects.LegacyKeywordSuggestion): string {
    if (keyword.Area) {
      return keyword.IsAreaFirst ? keyword.Area + ' ' + keyword.Keyword : keyword.Keyword + ' ' + keyword.Area;
    } else {
      return keyword.Keyword;
    }
  }
}
