import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';

@Component({
	selector: 'app-components-ticket-forcecustomerrefresh',
	templateUrl: './ForceCustomerRefresh.component.html'
})
export class ForceCustomerRefreshComponent extends BaseComponent {
    public getModifiedDataFlags(): app.ticket.interfaces.IModifiedDataFlags {
        return { customer: true };
    }
}
