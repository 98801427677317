<div>
	<ng-container *ngFor="let $data of grid().rows">
    <div class="row">
      <ng-container *ngFor="let $data2 of $data">
        <div [attr.class]="grid().bootstrapColumnClass">
          <compose [Selector]="$data2.selector" [ActivateOptions]="$data2.options" [Model]="$data2.model" [Callback]="$data2.callback?.bind(this)" [FailureCallback]="$data2.failureCallback?.bind(this)"></compose>
        </div>
      </ng-container>
    </div>
	</ng-container>
</div>