import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
    private defaultSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();
    private idTokenRegex = new RegExp('^(id_token)=(.*)$', 'i');

    parse(url: string): UrlTree {
        // SAML tokens are passed with format /#id_token=value without a ?. Angular's default url serializer doesn't recognize this as a query string, so it removes it

        let urlTree = this.defaultSerializer.parse(url);

        const matches = this.idTokenRegex.exec(url);
        if (matches && matches.length >= 3) {
            urlTree = this.defaultSerializer.parse('');
            urlTree.queryParams[matches[1]] = matches[2];
        }

        return urlTree;
    }

    serialize(tree: UrlTree): string {
        return this.defaultSerializer.serialize(tree);
    }
}