import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-components-specialist-actioncomponents-keywordresearchnote',
  templateUrl: './KeywordResearchNote.component.html',
})
export class KeywordResearchNoteComponent implements OnInit {
  public noteControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(2000),
  ]);

  @Input() public note: string;
  @Input() public callback: (keywordResearchNoteComponent: KeywordResearchNoteComponent) => void;

  get keywordResearchNote(): string {
    return this.noteControl.value;
  }  

  ngOnInit(): void {
    if (this.note) {
      this.noteControl.patchValue(this.note);
    }

    this.callback(this);
  }

  public isValid(): boolean {
    this.noteControl.markAsTouched();
    return this.noteControl.valid;
  }
}