<div class="row">
  <div class="col-md-5">
    <div class="row g-0 my-3">
      <div class="col-md-9">
        <ng-select [items]="partnerTaskTypeUsages" [multiple]="false" [closeOnSelect]="true" [searchable]="true"
          [clearable]="false" bindLabel="Name" placeholder="Choose Task Type..." groupBy="IsCustom" [(ngModel)]="selectedTaskType">
          <ng-template ng-label-tmp let-item="item">
            <i [ngClass]="getIcon(item)"></i>
            <b class="ms-2">{{item.Name}}</b>
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            <i [ngClass]="getIcon(item)"></i>
            <b class="ms-2">{{item.Name}}</b>
          </ng-template>
        </ng-select>
      </div>
      <div class="col-md-3">
        <label class="control-label"></label>
        <button class="btn btn-primary" (click)="addExclusion()" [disabled]="cannotExclude(selectedTaskType)">Add</button>
      </div>
    </div>
  </div>
  <div class="col-md-5" *ngIf="selectedTaskType">
    <div class="alert alert-danger" *ngIf="selectedTaskType.IsInPartnerDefaults">
      <strong><i class="fas fa-stop-circle"></i> Task type in partner defaults</strong>
      <div class="mt-2">
        <div class="row">
          <div class="col-md-12">
            <p>This task type exists in the partner defaults.</p>
            <p>To exclude this task type, please first contact Product to adjust the partner's default tasks.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="alert alert-danger" *ngIf="selectedTaskType.IsInCustomerDefaults">
      <strong><i class="fas fa-stop-circle"></i> Task type in customer defaults</strong>
      <div class="mt-2">
        <div class="row">
          <div class="col-md-12">
            <p>This task type exists in the customer defaults.</p>
            <p>To exclude this task type, please first contact Product to adjust the customers' default tasks.</p>
            <p>Customers with the task type in their defaults: {{selectedTaskType.CustomerIdsWithTaskAsDefault}}</p>
          </div>
          <div class="col-md-12">
            <span *ngIf="numberOfCustomerIdsShowing == 10" (click)="getCustomersWithTaskTypeInDefaults(300)">
              <i class="far fa-caret-square-down"></i>
              Show More
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="alert alert-danger" *ngIf="selectedTaskType.HasOpenWork">
      <strong><i class="fas fa-stop-circle"></i>Task type is included in future allocations</strong>
      <div class="mt-2">
        <div class="row">
          <div class="col-md-12">
            <p>This task type is currently part of future work allocations for this partner's clients and cannot be excluded.</p>
            <p *ngIf="selectedTaskType.CustomerIdsWithFutureAllocatedTask">Customers with the task type in future allocations: {{selectedTaskType.CustomerIdsWithFutureAllocatedTask}}</p>
            <span *ngIf="!selectedTaskType.CustomerIdsWithFutureAllocatedTask" (click)="getCustomersWithTaskTypeInFutureAllocations(300)">
              <i class="far fa-caret-square-down"></i>
              Show More
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
