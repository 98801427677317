<div class="card">
  <div class="card-header">
    <div data-toggle="collapse" [attr.href]="'#' + uniqueId" class="cursor-pointer todo-toggle">
      {{ header }}
      <i class="fa fa-caret-right fa-fw"></i>
      <i class="fa fa-caret-down fa-fw"></i>
    </div>
  </div>
  <div [attr.id]="uniqueId" class="collapse show">
    <div class="card-body p-0">
      <ul class="list-group list-group-flush">
        <ng-container *ngFor="let item of items">
          <ng-container *ngIf="item.groupConditionsSatisfied() && !item.shouldHide()">
            <li class="list-group-item border-end">
              <div class="row d-flex">
                <div class="col-md-3">
                  {{ item.checklistItem.Detail.Title }}
                </div>
                <div class="col-md-6">
                  {{ item.checklistItem.Detail.Description }}
                </div>
                <div class="col-md-3 justify-content-end">
                  <shared-components-customchoice [value]="item.Value" (valueChange)="item.Value = $event" [disable]="isReadOnly || item.checklistItem.IsAutoChecked">
                  </shared-components-customchoice>
                  <ng-container *ngIf="!isReadOnly && !item.checklistItem.Note">
                    <i class="fa fa-plus fa-lg cursor-pointer" (click)="openNoteModal(noteModal, item.checklistItem)"></i>
                  </ng-container>
                  <ng-container *ngIf="item.checklistItem.Note && !displayNotesInline">
                    <i class="fa fa-sticky-note fa-lg cursor-pointer" (click)="openNoteModal(noteModal, item.checklistItem)"></i>
                  </ng-container>
                  @if (item.checklistItem.IsAutoChecked) {
                    <div class="mt-2"><span class="badge pill bg-info"><i class="fa-solid fa-bolt"></i>Automated Response</span></div>
                  }
                </div>
              </div>
              <ng-container *ngIf="item.checklistItem.Note && displayNotesInline">
                <div class="row pt-5">
                  <div class="col-md-3">
                    <strong>Notes:</strong>
                  </div>
                  <div class="col-md-9">
                    {{ item.checklistItem.Note }}
                  </div>
                </div>
              </ng-container>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
</div>


<ng-template #noteModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ noteControl.disabled ? 'View Note' : 'Edit Note' }}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
      <textarea class="form-control" [formControl]="noteControl" lpValidationMsg></textarea>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
      <button *ngIf="noteControl.enabled" type="button" class="btn btn-success" (click)="saveNote(modal)">Save</button>
    </div>
  </div>
</ng-template>