<app-components-floatingtabpanel [options]="{
  apiCallback: floatingTabPanelApiCallback.bind(this),
  verticalPanelOffset: 1
}" (click)="increaseTabPanelComponentZIndex()"></app-components-floatingtabpanel>
<app-components-specialist-internal-tasks-qa-action-information
  [internalAction]="internalAction"></app-components-specialist-internal-tasks-qa-action-information>
<div id="internal-task-qa-criteria-float-panel" class="float-panel" (click)="increaseLocalTabPanelZIndex()">
  <div class="float-panel-tab">
    <button class="float-panel-btn-expand btn btn-lg btn-primary">
      <i class="fa fa-check"></i>
    </button>
  </div>
  <div class="float-panel-main card">
    <div class="float-panel-draggable card-header">
      <i class="fa fa-check"></i>
      Profile Submission Quality
      <i class="float-panel-btn-collapse fa fa-minus float-end"></i>
    </div>
    <div class="card-body" style="overflow-y: scroll; max-height: 550px;">
      <app-components-qa-grading-area-panel [taskType]="taskType"
        [qaCriteriaGroups]="qaCriteriaGroups" #gradingArea>
      </app-components-qa-grading-area-panel>
    </div>
    <div class="card-footer">
      <div class="text-end">
        <button (click)="close.next()" class="btn btn-primary">Close</button>
        <button (click)="unlock.next()" class="btn btn-info">
          <i class="fa fa-unlock"></i>
          Unlock &amp; Unassign
        </button>
        <button (click)="complete.next()" class="btn btn-success">
          <i class="fa fa-check"></i>
          Complete
        </button>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="row">
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <ng-container *ngIf="websiteUrl">
            <span>
              <strong>Website Url</strong>
              <a [externalLink]="{url : websiteUrl, text: websiteUrl}" class="float-end"></a>
            </span>
          </ng-container>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <span>
            <strong>Partner</strong>
            <span class="float-end">{{ partner }}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-12">
      <ng-container *ngIf="taskDefination">
        <label class="control-label">Definition of Task</label>
        <textarea disabled [ngModel]="taskDefination" class="form-control" rows="15" style="height: 150px;"></textarea>
      </ng-container>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-12">
      <label class="control-label">Description</label>
      <textarea disabled [ngModel]="customDescriptionFromTask" class="form-control" rows="15"
        style="height: 150px;"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label">Task Definition Files</label>
        <table [hidden]="!showTaskDefinitionFiles" class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Created Date</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let $data of taskDefinitionFiles">
              <tr>
                <td>
                  <a [externalLink]="{ url: $data.S3Url, text: $data.Name}"></a>
                </td>
                <td>{{$data.InsertedDate | dateText }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div [hidden]="!(showTaskDefinitionFiles === false)" class="alert alert-info"
          style="padding:8px;margin-bottom: 5px;">No results to display</div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label">Uploaded Files</label>
        <table [hidden]="!showTaskFiles" class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Created Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let $data of taskFiles">
              <tr>
                <td>
                  <a [externalLink]="{ url: $data.S3Url, text: $data.Name}"></a>
                </td>
                <td>{{$data.InsertedDate | dateText }}</td>
                <td class="text-center">
                  <button disabled class="btn btn-danger">
                    <i class="fa fa-times"></i>
                    Delete
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div [hidden]="!(showTaskFiles === false)" class="alert alert-info" style="padding:8px;margin-bottom: 5px;">No
          results to display</div>
      </div>
      <div class="form-group">
        <ng-container *ngIf="taskFiles.length < 1">
          <button disabled class="btn btn-primary">
            <i class="fa fa-upload"></i>
            Upload New File
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label">Resolve Action Work Block - Ticket History</label>
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th><span>Ticket Type</span></th>
          <th><span>Last Action Date</span></th>
          <th><span>Assigned User</span></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let ticket of tickets">
          <tr>
            <td>
              <button (click)="viewTicket(ticket.TicketId);" class="btn btn-primary">
                <i class="fas fa-external-link-square-alt"></i>
                <span>{{ticket.TicketType.Name}}</span>
              </button>
            </td>
            <td>{{ ticket.ScheduledDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
            <td>{{ (ticket.AssignedUser) ? ticket.AssignedUser.FullName : '' }}</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="!tickets.length">
          <tr>
            <td class="alert alert-info" colspan="3">No results to display</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <br />
  <div class="form-group">
    <label class="control-label">External Access</label>
    <div class="text-warning float-end">Notice: All password retrieval requests are recorded for security purposes.
    </div>
    <app-components-managecustomer-customeraccountgroup [options]="{
        showSaveButton: false,
        customerId: customerId,
        customerAccountTypes: customerAccountTypes
    }"></app-components-managecustomer-customeraccountgroup>
  </div>
  <div [hidden]="!notifyOnCompletion" class="form-group">
    <label class="control-label">Note to CSR</label>
    <p class="float-end help-block">4000 character maximum</p>
    <textarea disabled [ngModel]="noteToCsr" name="customActionNote" class="form-control" rows="15"
      style="height: 150px;" placeholder="Note to CSR"></textarea>
  </div>
</div>