<div>
  <app-components-togglepanel [(isOpen)]="isOpen" title="Local Profiles" toggleBody="true">
    <app-components-shared-local-profiles
      [customer]="customerJs"
      [canAddLocalProfile]="true"
      [requireFullLocalProfileOnAdd]="true"
      [showRegistration]="true"
      [manageableTaskTypes]="manageableTaskTypes"
      [showSubscriptionSaveButton]="true"
      [allowAutoSubscribe]="true">
    </app-components-shared-local-profiles>
  </app-components-togglepanel>
</div>