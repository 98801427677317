<div>
	<div class="row">
		<div class="col-lg-12">
			<div class="card card-body bg-light d-block" style="margin:10px;">
				<h4>
					Is the site currently accessible?
					<ng-container *ngIf="isUp() === 2">
						<span class="badge bg-warning label-badge">Unknown</span>
					</ng-container>
					<ng-container *ngIf="isUp() === 1">
						<span class="badge bg-success label-badge">Yes</span>
					</ng-container>
					<ng-container *ngIf="isUp() === 0">
						<span class="badge bg-danger label-badge">No</span>
					</ng-container>
				</h4>
				<p>Checks that the site is currently online.</p>
				<ul>
					<li>Checks that the webserver is returning a 200 response code.</li>
					<li>Checks that the content returned is the same content that is deployed for the blog.</li>
				</ul>
				<p class="text-end">
					<button (click)="checkIsUp()" class="btn btn-outline-secondary">Check Now</button>
				</p>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-lg-12">
			<div class="card card-body bg-light d-block" style="margin: 10px">
				<h4>
					Is DNS Configured Correctly?
					<ng-container *ngIf="isDnsCorrect() === 2">
						<span class="badge bg-warning label-badge">Unknown</span>
					</ng-container>
					<ng-container *ngIf="isDnsCorrect() === 1">
						<span class="badge bg-success label-badge">Yes</span>
					</ng-container>
					<ng-container *ngIf="isDnsCorrect() === 0">
						<span class="badge bg-danger label-badge">No</span>
					</ng-container>
				</h4>
				<p>Checks that the DNS is configured correctly.</p>
				<ol class="list-group">
					<li class="list-group-item">
						Namecheap nameservers (should point to Cloudflare nameservers)
						<button (click)="checkNamecheapNameservers()" class="btn btn-sm">Check Now</button>
						<ul>
							<ng-container *ngFor="let $data of namecheapNameservers()">
								<li>{{$data}}</li>
							</ng-container>
						</ul>
					</li>
					<li class="list-group-item">
						Cloudflare CNAME record should point to CloudFront
						<button (click)="checkCloudflareDns()" class="btn btn-sm">Check Now</button>
						<ul>
							<ng-container *ngFor="let $data of cloudflareDnsRecords()">
								<li>
									<span class="badge bg-primary">{{$data.Key}}</span>
									<span class="badge bg-info">{{$data.Value}}</span>
								</li>
							</ng-container>
						</ul>
					</li>
				</ol>
				<p class="text-end">
					<button (click)="checkIsDnsCorrect()" class="btn btn-outline-secondary">Check Now</button>
				</p>
			</div>
		</div>
	</div>
</div>
