/* tslint:disable */

class GoogleAnalytics {
    public static register() {
        const environment = (window as any).environment;
        gtag('js', new Date())
        let userId = 0;
        try {
            if (localStorage) {
                const user = localStorage.getItem('u');
                if (user) {
                    userId = JSON.parse(user).UserId;
                }
            }
        } catch (error) {
            // intentional no-op. If we don't have a userId, that's ok.
        }
        if (environment.logToGoogleAnalytics) {
            
            if (userId > 0) {
                gtag('config', 'G-ERFLW491T8', { userId: userId });
            } else {
                gtag('config', 'G-ERFLW491T8');
            }
        } else if (environment.logToDevGoogleAnalytics) {
            if (userId > 0) {
                gtag('config', 'G-EBV693Q1Q7', { userId: userId });
            } else {
                gtag('config', 'G-EBV693Q1Q7');
            }
        }
        gtag('event', 'page_view');
    }
}
GoogleAnalytics.register();