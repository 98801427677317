import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UrlSuggestionService {

  private baseUrl: string = `${environment.urlConfig.serviceUrl}/urlsuggestions`;

  constructor(private http: HttpClient) { }

  get(customerId: number) : Observable<Boo.Objects.LegacyUrlSuggestion[]> {
    return this.http.get<Boo.Objects.LegacyUrlSuggestion[]>(`${this.baseUrl}/`, { params: { customerId: customerId } });
  }

  save(customerId: number, urlSuggestions: Boo.Objects.LegacyUrlSuggestion[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/`, 
    {
      customerId: customerId,
      urlSuggestions: urlSuggestions
    });
  }
}