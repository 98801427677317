<div class="input-group">
  <div class="input-group-text">
    <ng-container *ngIf="disabled">
      <input readonly disabled [checked]="value" type="checkbox" />
    </ng-container>
    <ng-container *ngIf="!disabled">
      <input [checked]="value" (click)="toggleValue()" type="checkbox" />
    </ng-container>
  </div>
  <div (click)="toggleValue()" class="form-control cursor-pointer" [class.text-muted]="disabled">{{ label }}</div>
</div>