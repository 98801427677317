import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'shared-components-customchoice',
  templateUrl: './customChoice.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomChoiceComponent
    }
  ]
})
export class CustomChoiceComponent implements ControlValueAccessor {
  @Input() public value: boolean;
  @Input() public disable?: boolean;
  @Input() public choiceTrue?: string;
  @Input() public choiceFalse?: string;

  @Output() public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private onChange = (_value: boolean) => {};
  private onTouched = () => {};

  touched = false;

  public changeValue(newValue: boolean): void {
    this.value = newValue;
    this.valueChange.emit(this.value);
    this.onChange(this.value);
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disable = isDisabled;
  }
}
