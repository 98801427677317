import ITodoListObservable = app.tsmodels.interfaces.ITodoListObservable;

export default class TodoListFactory {
  public create(): ITodoListObservable {
    return <ITodoListObservable>({
      TodoListId: ko.observable(0),
      Todos: ko.observableArray()
    });
  }
}
