import { Component, OnInit, ViewChild } from '@angular/core';
import { SessionStorageService } from '../../../../services/session-storage.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerNoteService } from '../../../../services/customer-note.service';
import { Subject, forkJoin } from 'rxjs';
import { UrlSuggestionsComponent } from '../../../shared/Keywords/url-suggestions/url-suggestions.component';
import { KeywordSuggestionService } from '../../../../services/keyword-suggestion.service';
import { KeywordResearchNoteComponent } from '../KeywordResearchNote/KeywordResearchNote.component';
import KeywordSuggestionStatus = Boo.Objects.Enums.KeywordSuggestionStatus;
import { HaloKeywordSuggestionsComponent } from '../../../shared/Keywords/halo-keyword-suggestions/halo-keyword-suggestions.component';
import System from 'framework/System';
import { finalize } from 'rxjs/operators';
import { CustomerService } from '../../../../services/customer.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import CustomValidators from '../../../../shared/custom-validators';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { KeywordSuggestionConfig } from 'app/models/KeywordSuggestionConfig';
import { KeywordSuggestionConfigFactory } from '../../../../factories/KeywordSuggestionConfigFactory';
import { FloatingPanelsComponent } from '../../../../shared/components/floating-panels/floating-panels.component';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-specialist-actioncomponents-keywordresearch',
  templateUrl: './KeywordResearch.component.html',
  styleUrls: ['./KeywordResearch.component.scss']
})
export class KeywordResearchComponent implements IActivatable, IActionWorkspace, OnInit {
  isLoading: boolean = false;
  panelsLoading: boolean = false;
  customer: Boo.Objects.Customer;
  taskId: number;
  salesNote: string = '';
  isCampaignNational: boolean = undefined;
  discoveryNote: string = '';
  keywordSuggestionConfig: KeywordSuggestionConfig;
  totalPageCount: number;
  totalKeywordCount: number;
  haloKeywordCount: number;
  verticals: Boo.Objects.Vertical[] = [];
  countries: Boo.Objects.Country[] = [];
  verticalHelpTextKey: string = '';
  selectedCountryId: UntypedFormControl = new UntypedFormControl('', { validators: [Validators.required] });
  selectedVerticalId: UntypedFormControl = new UntypedFormControl('', { validators: [Validators.required] });
  urlSuggestionAdded: Subject<Boo.Objects.LegacyUrlSuggestion[]> = new Subject<Boo.Objects.LegacyUrlSuggestion[]>();
  haloKeywordSuggestionsAdded: Subject<Boo.Objects.LegacyKeywordSuggestion[]> = new Subject<Boo.Objects.LegacyKeywordSuggestion[]>();
  historicalKeywordSuggestions: Boo.Objects.LegacyKeywordSuggestion[] = [];
  keywordSuggestionTypes: Boo.Objects.KeywordSuggestionType[];
  formGroup: UntypedFormGroup = new UntypedFormGroup({ countryId: this.selectedCountryId, verticalId: this.selectedVerticalId })
  newPageIsExisting?: boolean;
  newPageControl: UntypedFormControl = new UntypedFormControl('', [CustomValidators.validUrl(), Validators.required, Validators.maxLength(512)]);
  newPageFormGroup: UntypedFormGroup = new UntypedFormGroup({ newPage: this.newPageControl });

  private keywordResearchNoteComponent: KeywordResearchNoteComponent;
  keywordResearchNoteCallback = (keywordResearchNoteComponent: KeywordResearchNoteComponent) => {
    this.keywordResearchNoteComponent = keywordResearchNoteComponent;
  }
  @ViewChild(UrlSuggestionsComponent) urlSuggestionComponent: UrlSuggestionsComponent;
  @ViewChild(HaloKeywordSuggestionsComponent) haloSuggestionComponent: HaloKeywordSuggestionsComponent;
  @ViewChild(FloatingPanelsComponent) floatingPanelsComponent: FloatingPanelsComponent;
  
  private keywordDiscoveryTag: string = 'Keyword Discovery: ';

  constructor(
    private sessionStorageService: SessionStorageService,
    private keywordSuggestionService: KeywordSuggestionService,
    private customerService: CustomerService,
    private modalService: NgbModal,
    private customerNoteService: CustomerNoteService,
    private keywordSuggestionConfigFactory: KeywordSuggestionConfigFactory,
    private customerCampaignService: CustomerCampaignService
  ) { }

  // Declared but intentionally not used in this component
  actionViewModel: KnockoutObservable<IActionWorkspace>;

  currentAction: KnockoutObservable<Boo.Objects.Action>;

  isStepReadyToReject(): boolean {
    return false;
  }

  isStepReadyToSave(): JQueryPromise<boolean> {
    return this.validateAndSave(SaveTypes.Update);
  }

  isStepReadyToComplete(): boolean | JQueryPromise<boolean> {
    return this.validateAndSave(SaveTypes.Complete);
  }

  isStepReadyToDelete(): boolean {
    return false
  }

  canActivate?(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: IActionWorkspaceActivationParams): boolean {
    this.currentAction = params.currentAction;
    params.controlViewModel(this);
    return true;
  }

  ngOnInit(): void {
    this.isLoading = true;
    
    forkJoin([
      this.sessionStorageService.getStaticData(),
      this.customerService.get(this.currentAction().Customer.CustomerId),
      this.keywordSuggestionService.getSummary(this.currentAction().Customer.CustomerId),
      this.customerCampaignService.getWebsiteUrls(this.currentAction().Customer.CustomerId, true, this.customerCampaignService.activeStatuses()), // excludeInactiveKeywords: true
    ])
    .pipe(finalize(() => {
      this.isLoading = false;
    }))
    .subscribe(([staticData, customer, keywordSuggestionSummary, activeUrls]): void => {
      this.customer = customer;
      this.isCampaignNational = this.customer.IsCampaignNational;
      this.taskId = this.currentAction().Task.TaskId;
      this.keywordSuggestionTypes = staticData.KeywordSuggestionTypes;
      keywordSuggestionSummary.HaloKeywordSuggestions = keywordSuggestionSummary.HaloKeywordSuggestions.filter(x => x.KeywordSuggestionStatusId !== KeywordSuggestionStatus.Historic);
      keywordSuggestionSummary.HaloKeywordSuggestions.map(suggestion => suggestion.KeywordSuggestionType = this.keywordSuggestionTypes.find(x => x.KeywordSuggestionTypeId === suggestion.KeywordSuggestionTypeId));
      keywordSuggestionSummary.HistoricalKeywordSuggestions.map(suggestion => suggestion.KeywordSuggestionType = this.keywordSuggestionTypes.find(x => x.KeywordSuggestionTypeId === suggestion.KeywordSuggestionTypeId));
      keywordSuggestionSummary.UrlSuggestions.flatMap(x => x.KeywordSuggestions).map(suggestion => suggestion.KeywordSuggestionType = this.keywordSuggestionTypes.find(x => x.KeywordSuggestionTypeId === suggestion.KeywordSuggestionTypeId));
      this.urlSuggestionAdded.next(keywordSuggestionSummary.UrlSuggestions);
      this.haloKeywordSuggestionsAdded.next(keywordSuggestionSummary.HaloKeywordSuggestions);
      this.historicalKeywordSuggestions = keywordSuggestionSummary.HistoricalKeywordSuggestions;
      this.historicalKeywordSuggestions.map(suggestion => suggestion.KeywordSuggestionType = this.keywordSuggestionTypes.find(x => x.KeywordSuggestionTypeId === suggestion.KeywordSuggestionTypeId));
      this.verticals = staticData.Verticals;
      this.countries = staticData.Countries;
      this.selectedCountryId.setValue(this.customer.CountryId > 0 ? this.customer.CountryId : undefined);
      this.selectedVerticalId.setValue(this.customer.VerticalId > 0 ? this.customer.VerticalId : undefined);
      let salesNote = _.filter(this.currentAction().TaskDetails.CustomerNotes, (note: Boo.Objects.CustomerNote) => { return note.Text !== ''; });
      let homepage = activeUrls.find(x => x.IsHomePage)
      if (homepage) {
        this.newPageControl.addValidators(CustomValidators.domainMustMatch(homepage.Url, `Must contain customer's base domain (${homepage.Url})`))
      }
      
      if (salesNote) {
        this.salesNote = _.pluck(salesNote, 'Text').reverse().join('\n--------\n');
      } else {
        this.salesNote = 'No sales note available.'
      }

      this.keywordSuggestionConfig = this.keywordSuggestionConfigFactory.create(this.customer.SeoMinutesPaidRecurring, this.isCampaignNational, this.customer.PartnerId);

      this.customerNoteService.get(this.customer.CustomerId)
        .filter(`startswith(Text, '${this.keywordDiscoveryTag}')`)
        .orderBy('InsertedDate desc')
        .top(1)
        .load()
        .then((notes: framework.data.IPageResult<Boo.Objects.CustomerNote>) => {
          if (notes.Items.length) {
            this.discoveryNote = notes.Items[0].Text.replace(this.keywordDiscoveryTag, '').trim();
          } else {
            this.discoveryNote = 'No discovery note available.';
          }
      });
    });
  }

  updateNewPageIsExisting(newPageIsExisting: boolean) {
    this.newPageIsExisting = newPageIsExisting;
  }

  addNewPage(modal: NgbModalRef = null): any {
    Object.keys(this.newPageFormGroup.controls).forEach((key) => {
      this.newPageFormGroup.controls[key].markAsDirty();
      this.newPageFormGroup.controls[key].updateValueAndValidity();
    });

    if (this.newPageIsExisting == null) {
      toastr.error("Must select whether the page is existing or new.");
    }

    if (this.newPageControl.errors && this.newPageControl.errors.mismatchedDomain) {
      toastr.error(this.newPageControl.errors.mismatchedDomain.alternateMessage);
    }

    if (this.newPageFormGroup.valid && this.newPageIsExisting != null) {
      this.urlSuggestionAdded.next([{ Url: this.newPageControl.value, IsExisting: this.newPageIsExisting } as Boo.Objects.LegacyUrlSuggestion]);
      modal.close({} as Boo.Objects.LegacyUrlSuggestion);
    } else {
      toastr.error("Please correct the errors on the page.");
    }
  }

  openModal(template: any): void {
    this.modalService.open(template).result.finally(() => {
      this.newPageControl.setValue('');
      this.newPageIsExisting = null;
      Object.keys(this.newPageFormGroup.controls).forEach((key) => {
        this.newPageFormGroup.controls[key].markAsPristine();
        this.newPageFormGroup.controls[key].markAsUntouched();
      })
    });
  }

  validateAndSave(saveType: SaveTypes): JQueryPromise<boolean> {
    let researchValid = this.isValid(saveType);
    let urlSuggestionsValid = this.urlSuggestionComponent.isValid(saveType);
    let haloSuggestionsValid = this.haloSuggestionComponent.isValid(saveType);
    if (!researchValid || !urlSuggestionsValid || !haloSuggestionsValid) {
      return System.resolvedPromise(false);
    }

    this.currentAction().Customer.VerticalId = this.selectedVerticalId.value;
    this.currentAction().Customer.CountryId = this.selectedCountryId.value;
    this.currentAction().Customer.IsCampaignNational = this.isCampaignNational;
    if (this.keywordResearchNoteComponent && this.keywordResearchNoteComponent.keywordResearchNote) {
      let newNote: Boo.Objects.CustomerNote = <Boo.Objects.CustomerNote>{
          CustomerId: this.customer.CustomerId,
          CustomerNoteId: 0,
          Text: this.keywordResearchNoteComponent.keywordResearchNote,
          IsSystemGenerated: false,
          ContactTypeId: launchpad.config.ContactTypeEnum.None,
          UserId: this.currentAction().Task.AssignedUserId
      };
      this.currentAction().TaskDetails.CustomerNotes.push(newNote);
    }

    if (saveType === SaveTypes.Complete && (this.keywordResearchNoteComponent && !this.keywordResearchNoteComponent.isValid())) {
      this.floatingPanelsComponent.expandPanelByTitle("Keyword Research Note"); 
      return System.resolvedPromise(false);
    }
    this.floatingPanelsComponent.setLoading(true);

    this.currentAction().TaskDetails.UrlSuggestions = this.urlSuggestionComponent.get(saveType);
    this.currentAction().TaskDetails.KeywordSuggestions = this.haloSuggestionComponent.get();

    return System.resolvedPromise(true);
  }

  OnUrlSuggestionAdded($event: Boo.Objects.LegacyUrlSuggestion): void {
    this.urlSuggestionAdded.next([$event]);
  }

  OnHaloKeywordSuggestionsAdded($event: Boo.Objects.LegacyKeywordSuggestion[]): void {
    this.haloKeywordSuggestionsAdded.next($event);
  }

  OnCampaignTypeChanged($event: boolean): void {
    this.isCampaignNational = $event.valueOf();
    this.customer.IsCampaignNational = this.isCampaignNational;
    this.keywordSuggestionConfig = this.keywordSuggestionConfigFactory.create(this.customer.SeoMinutesPaidRecurring, this.isCampaignNational, this.customer.PartnerId);
  }

  private isValid(saveType: SaveTypes): boolean {
    if (saveType == SaveTypes.Update) {
      return true;
    }

    Object.keys(this.formGroup.controls).forEach((key) => {
      this.formGroup.controls[key].markAsDirty();
      this.formGroup.controls[key].updateValueAndValidity();
    });
    this.formGroup.updateValueAndValidity();
    
    return this.formGroup.valid;
  }
}