import { Component } from '@angular/core';
import { CustomerPriorityService } from '../../../services/customer-priority.service';
import { PerformanceMetricsService } from '../../../services/performance-metrics.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-customerservice-performancemetrics',
  templateUrl: './PerformanceMetrics.component.html'
})
export class PerformanceMetricsComponent implements IActivatable {
    public user: Boo.Objects.User;
    public partnerUser: Boo.Objects.PartnerUser;
    public performanceSummary: Boo.Objects.UserPerformanceSummary;
    public customersInQueue: KnockoutObservable<number>;

    constructor(
      private customerPriorityService: CustomerPriorityService,
      private performanceMetricsService: PerformanceMetricsService) { }

    public activate(): JQueryPromise<any> {
        this.customersInQueue = ko.observable(undefined);

        return Utils.wrapDfd(this.performanceMetricsService.get(this.user.UserId))
            .then((ups) => {
                this.performanceSummary = ups;
            });
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner): boolean {
        this.user = user;
        this.partnerUser = _.find(user.PartnerUsers, (partnerUser: Boo.Objects.PartnerUser) => { 
            return partnerUser.UserLevelId === Boo.Objects.Enums.UserLevelEnum.CustomerService && partnerUser.PartnerId === partner.PartnerId;
        });

        return !!this.partnerUser;
    }

    public getQueueSize(): JQueryPromise<void> {
        return Utils.wrapDfd(this.customerPriorityService.getQueueSize(this.partnerUser.PartnerUserId))
            .then((queueSize: number) => {
                this.customersInQueue(queueSize);
            });
    }
}
