<div class="card" [loadingOverlay]="loading">
  <div class="card-header">
    <span>Edit Organizational Team</span>
    <button (click)="cancel()" class="btn-close" title="Close"></button>
  </div>
  <div class="card-body animated fadeIn">
    <ng-container *ngIf="formIsReady">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Name</label>
            <input class="form-control" [formControl]="nameCtrl" lpValidationMsg />
          </div>
          <div class="form-group">
            <label class="control-label">Parent Team</label>
            <ng-select [items]="teams" [clearable]="true" placeholder="Choose..." bindLabel="DisplayName"
              bindValue="OrganizationalTeamId" [formControl]="parentTeamIdCtrl"></ng-select>
          </div>
          <div class="form-group">
            <label class="control-label">Organization</label>
            <ng-select [items]="organizations" [clearable]="false" placeholder="Choose..." bindLabel="Name"
              bindValue="Value" [readonly]="organizationIdIsReadOnly" [formControl]="organizationIdCtrl" lpValidationMsg></ng-select>
          </div>
          <div class="form-group">
            <label class="control-label">Executive</label>
            <ng-select [items]="boostabilityUsers" [clearable]="true" placeholder="Choose..." bindLabel="FullName"
              bindValue="UserId" [(ngModel)]="team.ExecutiveUserId"></ng-select>
          </div>
          <div class="form-group">
            <label class="control-label">Manager</label>
            <ng-select [items]="boostabilityUsers" [clearable]="true" placeholder="Choose..." bindLabel="FullName"
              bindValue="UserId" [(ngModel)]="team.ManagerUserId"></ng-select>
          </div>
          <div class="form-group">
            <label class="control-label">Team Lead</label>
            <ng-select [items]="boostabilityUsers" [clearable]="true" placeholder="Choose..." bindLabel="FullName"
              bindValue="UserId" [(ngModel)]="team.TeamLeadUserId" appendTo="body"></ng-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Is Active</label>
            <div>
              <shared-components-customchoice [(value)]="team.IsActive"></shared-components-customchoice>
            </div>
          </div>
          <div *ngIf="activeWhenLoaded && !team.IsActive" class="alert alert-warning">
            <span>When a team is deactivated, all users assigned to the team will be unassigned.</span>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body bg-light d-block text-end">
          <button (click)="cancel()" class="btn btn-link">Cancel</button>
          <button (click)="save()" class="btn btn-success" [disabled]="!isFormValid">
            <i class="fa fa-save"></i> Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
