<div [loadingOverlay]="isLoading">
  <div class="row mb-2 mt-2">
    <div class="col-md-6">
      <h3>Past - Incomplete</h3>
    </div>
  </div>

  <table *ngIf="work && work.length; else noItems" class="table table-bordered" id="work-table">
    <thead>
      <tr>
        <th style="width: 80px"></th>
        <th>Status</th>
        <th>Task Name</th>
        <th>Task Id</th>
        <th class="text-end">Task Inserted</th>
        <th class="text-end">Ordered Date</th>
        <th class="text-end">Ordered By</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of work">
        <tr>
          <td class="text-center">
            <a *ngIf="item.CanDelete" (click)="deleteItem(deleteItemModal, item)" title="Delete" class="cursor">
              <i class="fas fa-trash-alt fa-2x text-danger"></i>
            </a>
          </td>
          <td>{{ item.WorkStatusDisplayName }}</td>
          <td>
            <span [ngbPopover]="item.WorkDescription" popoverTitle="Description" placement="left auto" triggers="mouseenter:mouseleave">
              <i class="fa fa-info-circle"></i>
            </span>
            <ng-container *ngIf="item.CanEdit; else uneditableItem">
              <a (click)="editItem(item)" title="Edit" class="cursor">
                {{ item.WorkDisplayName }} <span *ngIf="item.WorkRequestWebsiteUrl">- {{ item.WorkRequestWebsiteUrl}}</span>
              </a>
            </ng-container>
            <ng-template #uneditableItem>
              {{ item.WorkDisplayName }} <span *ngIf="item.WorkRequestWebsiteUrl">- {{ item.WorkRequestWebsiteUrl}}</span>
            </ng-template>
          </td>
          <td>{{ item.OrderTaskId }}</td>
          <td class="text-end">{{ item.TaskInsertedDate | date: 'shortDate' }}</td>
          <td class="text-end">{{ item.WorkRequest.InsertedDate | date: 'shortDate' }}</td>
          <td class="text-end">{{ item.WorkRequest.InsertedByUserFullName }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #noItems>
  <div class="alert alert-info">
    No items
  </div>
</ng-template>

<ng-template #deleteItemModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Confirm Delete</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
      <h4>Are you sure?</h4>
      <span>Note: This task will be <u>deleted immediately.</u> The minutes for the deleted task(s) will be added to the current period.</span><br><br>
      <span>Also, any unsaved changes you have made to work allocations will be lost.</span>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="modal.close()">Delete</button>
    </div>
  </div>
</ng-template>
