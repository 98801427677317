import { Component } from '@angular/core';
import System from 'framework/System';
import { WorkflowService } from '../../../services/workflow.service';
import Utils from '../../../shared/utils';
import { InternalTaskService } from '../../../services/internal-task.service';

@Component({
  selector: 'app-components-admin-internaltaskhistory',
  templateUrl: './InternalTaskHistory.component.html'
})
export class InternalTaskHistoryComponent implements IActivatable {
    public window = window;
    public launchpad = launchpad;
    public _ = _;
    public isLoading: KnockoutObservable<boolean>;
    public searchText: KnockoutObservable<number>;
    public selectedSearchFilter: KnockoutObservable<string>;
    public workflow: KnockoutObservable<Boo.Objects.Workflow>;
    public internalTasks: KnockoutObservableArray<Boo.Objects.InternalTaskHistoryDetail>;

    constructor(
      private workflowService: WorkflowService,
      private internalTaskService: InternalTaskService) { }

    public activate(params: any): JQueryPromise<void> {
        this.isLoading = ko.observable(false);
        this.searchText = ko.observable(NaN);
        this.workflow = ko.observable(null);
        this.selectedSearchFilter = ko.observable(WorkflowHistorySearchBy.LinkLocationId.toString());
        this.internalTasks = ko.observableArray([]);

        return System.emptyPromise();
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        return _.any(user.PartnerUsers, (pu) => {
            return pu.UserLevelId === Boo.Objects.Enums.UserLevelEnum.Administrator;
        });
    }

    public search(): void {
        this.isLoading(true);
        this.internalTasks.removeAll();
        this.workflow(null);

        (parseInt(this.selectedSearchFilter(), 10) === WorkflowHistorySearchBy.InternalTaskId
            ? Utils.wrapDfd(this.internalTaskService.getWorkflowHistoryByInternalTaskId(this.searchText()))
            : Utils.wrapDfd(this.internalTaskService.getWorkflowHistoryByLinkLocationId(this.searchText())))
            .then((result) => {
                if (!result.length) {
                    toastr.error('Could not locate any workflow internal tasks.');
                    return;
                }
                this.internalTasks(result);
                return Utils.wrapDfd(this.workflowService.get(result[0].WorkflowId));
            })
            .then((wf) => {
                this.workflow(wf);
            })
            .fail((msg: string) => {
                toastr.error(msg);
            }).always(() => {
                this.isLoading(false);
            });
    }
}

enum WorkflowHistorySearchBy {
    LinkLocationId = 0,
    InternalTaskId = 1
}
