import { Component, ViewChild, ElementRef, Inject, OnInit } from '@angular/core';
import { QaGradingArea } from '../../../../qa/qa-grading-area/qa-grading-area.component';
import { InternalActionToken } from '../../internal-action.token';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';
import { Subject } from 'rxjs';
import { DeletableWorkspace } from 'app/interfaces/deletable-workspace';
import { SessionStorageService } from '../../../../../services/session-storage.service';
import { finalize } from 'rxjs/operators';
import QaCriteriaGroup = Boo.Qa.QaCriteriaGroup;

@Component({
    selector: 'app-components-specialist-internal-tasks-content-review-qa',
    templateUrl: './content-review-qa.component.html'
})
export class ContentReviewQaComponent implements OnInit, CompletableWorkspace, UnlockableWorkspace, DeletableWorkspace, ClosableWorkspace {
    showCompleteButton: boolean = false;
    showUnlockButton: boolean = true;
    showDeleteButton: boolean = false;
    showCloseButton: boolean = true;
    complete = new Subject<void>()
    unlock = new Subject<void>();
    delete = new Subject<void>();
    close = new Subject<void>();

    @ViewChild('gradingArea')
    gradingArea: QaGradingArea;
    taskType: Boo.Objects.Enums.TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaContentReviewBlog;
    qaCriteriaGroups: QaCriteriaGroup[] = [];
    @ViewChild('editorRichText', { static: true }) editorRichText: ElementRef<HTMLTextAreaElement>;

    editorToolbar: any = {
        toolbar: [
            {
                name: 'document',
                items: ['Source', 'Maximize']
            },
            {
                name: 'editing',
                items: ['SelectAll']
            },
            {
                name: 'clipboard',
                items: ['Copy']
            }
        ],
        readOnly: true,
        allowedContent: true
    };

    reviewerToolbar: any = {
        toolbar: [
            {
                name: 'document',
                items: ['Source', 'Maximize']
            },
            {
                name: 'editing',
                items: ['SelectAll']
            },
            {
                name: 'clipboard',
                items: ['Copy']
            }
        ],
        readOnly: true,
        allowedContent: true
    };
    articleTitle: string = 'Article Title';
    blogTitle: string = 'Blog Title';
    customArticlePublicationContent: string = 'Custom Article Publication Content';
    standardBacklinkContent: string = 'Standard Backlink Content';
    customerCountry: Boo.Objects.Country;
    customerLanguage: Boo.Objects.Language;
    goalSeconds: number = 900;
    title: string = 'Content Review';
    rubricUrl: string = 'https://docs.google.com/spreadsheets/d/1SmaX48HqZFWod-45ZMEI_C8w4KGknTld0ZvWQ2fAhMQ/edit#gid=1143832377';
    diffCheckerUrl: string = 'https://www.diffchecker.com/';

    associatedTask: Boo.Objects.Task;
    associatedTaskCustomer: Boo.Objects.Customer;
    contentReviewSnapshot: Boo.Qa.Objects.ContentReviewSnapshot;
    websiteUrl: Boo.Objects.WebsiteUrl;
    taskLinkStrategyDetail: Boo.Objects.TaskLinkStrategyDetail;
    wasAccepted: boolean;

    showPlagiarismTable: boolean;

    originalContentTitle: string;
    originalContent: string;
    originalWordCount: number;

    reviewedContentTitle: string;
    reviewedContent: string;
    reviewedWordCount: number;

    additionalKeywords: string[];
    businessCategory: string;
    isLoading: boolean;
    linkStrategyType: string;
    linkStrategyTypeId: number;
    recommendedAnchorText: string;
    reviewerComments: string;
    specialConsiderations: string;
    showAdditionalKeywords: boolean;
    showRecommendedAnchorText: boolean;
    showSpecialConsiderations: boolean;
    showTargetArea: boolean;
    tags: string;
    targetArea: string;
    targetKeyword: string;
    verticals: Boo.Objects.Vertical[];

    constructor(
        @Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction,
        private sessionStorageService: SessionStorageService) { }

    ngOnInit() {
        //Create the criteria float panel
        (<any>$('#internal-task-qa-criteria-float-panel')).floatPanel({ collapsed: false });
        this.qaCriteriaGroups = this.internalAction.InternalTaskDetails.QaCriteriaGroups;
        this.associatedTask = this.internalAction.InternalTaskDetails.AssociatedTask;
        this.associatedTaskCustomer = this.internalAction.InternalTaskDetails.AssociatedTaskCustomer;
        this.contentReviewSnapshot = this.internalAction.InternalTaskDetails.ContentReviewSnapshot;
        this.websiteUrl = this.internalAction.InternalTaskDetails.WebsiteUrl;
        this.taskLinkStrategyDetail = this.internalAction.InternalTaskDetails.ContentReviewSnapshot.TaskLinkStrategyDetail;
        this.reviewerComments = this.contentReviewSnapshot.ReviewerComment;
        this.wasAccepted = this.associatedTask.TaskStatusEnum === Boo.Objects.Enums.TaskStatusEnum.Complete;

        this.customerCountry = this.associatedTaskCustomer.Country;
        this.customerLanguage = this.associatedTaskCustomer.Language;

        this.showPlagiarismTable = (this.contentReviewSnapshot.Url1 !== null
            || this.contentReviewSnapshot.Url2 !== null
            || this.contentReviewSnapshot.Url3 !== null);

        this.additionalKeywords = [];
        this.businessCategory = '';
        this.originalWordCount = 0;
        this.reviewedWordCount = 0;
        this.isLoading = true;
        this.linkStrategyType = '';
        this.recommendedAnchorText = '';
        this.showAdditionalKeywords = false;
        this.showRecommendedAnchorText = false;
        this.showTargetArea = false;

        this.loadContentFields();
        this.loadSpecialInstructions();
        this.loadKeywordsAndAreas();

        return this.sessionStorageService.getStaticData()
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((data: any) => {
                let vertical = _.find(data.Verticals as Boo.Objects.Vertical[], (vert) => {
                    return vert.VerticalId === this.associatedTaskCustomer.VerticalId;
                });

                if (vertical) {
                    this.businessCategory = vertical.Name;
                }

                this.loadLinkStrategyFields();
            });
    }
    
    isReadyToComplete(): boolean {
        if (!this.gradingArea.validate()) {
            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
            return false;
        }

        this.internalAction.InternalTaskDetails.QaCriteriaDetails = this.gradingArea.getDetails();
        this.internalAction.InternalTaskDetails.QaGrade.Comment = this.gradingArea.gradeComment;
        return true;
    }

    isReadyToDelete(): boolean { 
        return true;
    }

    private loadSpecialInstructions(): void {
        // Populate and show special instructions
        if (this.associatedTaskCustomer.Considerations) {
            this.specialConsiderations = this.associatedTaskCustomer.Considerations;
            this.showSpecialConsiderations = true;
        }
    }

    private loadKeywordsAndAreas(): void {
        this.targetKeyword = this.associatedTask.Keyword.Name;
        if (this.associatedTask.Area) {
            this.targetArea = this.associatedTask.Area.Name;
            this.showTargetArea = true;
        }

        // Get additional Keywords to display
        let keywords = _.filter(this.websiteUrl.Keywords, (word) => {
            return word.IsActive && (word.KeywordId !== this.associatedTask.KeywordId || word.AreaId !== this.associatedTask.AreaId);
        });

        let doAdditionalKeywordsExist = keywords && keywords.length > 0;
        if (doAdditionalKeywordsExist) {
            let array: string[] = [];
            _.each(keywords, (word) => {
                array.push(launchpad.utils.getKeywordPhrase(word));
            });
            this.additionalKeywords = array;
        }
        this.showAdditionalKeywords = doAdditionalKeywordsExist;
    }

    private getAnchorText(): string {
        let linkStrategyTypeId = this.taskLinkStrategyDetail.LinkStrategyId;
        switch (linkStrategyTypeId) {
            case Boo.Objects.Enums.LinkStrategyEnum.CompanyName:
                return this.associatedTaskCustomer.Name || '';
            case Boo.Objects.Enums.LinkStrategyEnum.RootDomain:
                return this.associatedTaskCustomer.Url || '';
            case Boo.Objects.Enums.LinkStrategyEnum.ActionPhrase:
                return this.taskLinkStrategyDetail.LinkActionPhrase?.Name || '';
            default:
                return '';
        }
    }

    private loadLinkStrategyFields(): void {
        if (this.taskLinkStrategyDetail &&
            this.taskLinkStrategyDetail.LinkStrategyId) {

            // Set Link Strategy Type
            this.linkStrategyTypeId = this.taskLinkStrategyDetail.LinkStrategyId;

            if (this.linkStrategyTypeId === Boo.Objects.Enums.LinkStrategyEnum.ExactMatch) {
                this.showRecommendedAnchorText = false;
            } else {
                this.showRecommendedAnchorText = true;
            }

            // Set Anchor Text
            // murder: After 07/02/2024, the else and getAnchorText method can be deleted.
            if (this.contentReviewSnapshot.RecommendedAnchorText?.length) {
                this.recommendedAnchorText = this.contentReviewSnapshot.RecommendedAnchorText;
            }
            else {
                this.recommendedAnchorText = this.getAnchorText();
            }

            // Show Link Strategy Type
            this.linkStrategyType = this.getLinkStrategyTypeDescription(this.linkStrategyTypeId);
        }
    }

    private getLinkStrategyTypeDescription(linkStrategyTypeId: number): string {
        let result;
        switch (linkStrategyTypeId) {
            case Boo.Objects.Enums.LinkStrategyEnum.CompanyName:
                result = 'Company Name';
                break;
            case Boo.Objects.Enums.LinkStrategyEnum.RootDomain:
                result = 'Root Domain';
                break;
            case Boo.Objects.Enums.LinkStrategyEnum.ActionPhrase:
                result = 'Action Phrase';
                break;
            case Boo.Objects.Enums.LinkStrategyEnum.ExactMatch:
                result = 'Active Keywords';
                break;
            default:
                result = 'Unknown';
                break;
        }
        return result;
    }

    private loadContentFields(): void {
        // Show Content

        this.originalContent = this.contentReviewSnapshot.OriginalContent;
        this.originalContentTitle = this.contentReviewSnapshot.OriginalContentTitle;

        if (this.contentReviewSnapshot.ReviewedContent) {
            this.reviewedContent = this.contentReviewSnapshot.ReviewedContent;
            this.reviewedContentTitle = this.contentReviewSnapshot.ReviewedContentTitle;
        }

        this.tags = this.contentReviewSnapshot.Tags;
    }
}