import { Component, Injector, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { InternalActionToken } from '../internal-action.token';
import { InternalActionService } from '../../../../services/internal-action.service';
import { isCompletable } from '../../../../../Scripts/app/interfaces/completable-workspace';
import { isUnlockable } from '../../../../../Scripts/app/interfaces/unlockable-workspace';
import { isRejectable } from '../../../../../Scripts/app/interfaces/rejectable-workspace';
import { isSavable } from '../../../../../Scripts/app/interfaces/savable-workspace';
import { isDeletable } from '../../../../../Scripts/app/interfaces/deletable-workspace';
import { isClosable } from '../../../../../Scripts/app/interfaces/closable-workspace';
import Workspaces from '../workspaces/workspaces';
import Timer from '../../../../shared/models/timer';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InternalActionRejectComponent } from '../reject-modal/reject-modal.component';
import IPriorityViewPublicApi = app.interfaces.IPriorityViewPublicApi;
import TaskStatusEnum = Boo.Objects.Enums.TaskStatusEnum;

@Component({
    selector: 'app-components-specialist-internal-tasks-internal-action-workspace',
    templateUrl: './internal-action-workspace.component.html'
})
export class InternalActionWorkspaceComponent implements OnInit, OnDestroy {
    @ViewChild('workspace', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;
    @Input() internalAction: Boo.Objects.InternalAction;

    isLoading: boolean;
    showCompleteButton: boolean;
    showUnlockButton: boolean;
    showRejectButton: boolean;
    showSaveButton: boolean;
    showDeleteButton: boolean;
    showCloseButton: boolean;
    hideActionBar: boolean;
    timer: Timer = new Timer();

    priorityViewApi: IPriorityViewPublicApi;
    private component: any;

    constructor(
        private injector: Injector,
        private internalActionService: InternalActionService,
        private modalService: NgbModal) { }

    ngOnInit() {
        this.timer.start();

        let workspace = Workspaces.getByTaskType(this.internalAction.InternalTask.TaskTypeId);

        if (!workspace) {
            toastr.error(`No workspace found for task type ${this.internalAction.InternalTaskDetails.TaskTypeName}`)
            this.priorityViewApi.reject();
        }

        this.component = this.viewContainerRef.createComponent(
            workspace, {
            injector: this.createInjector()
        }).instance;

        this.configureActions();
    }

    ngOnDestroy(): void {
        //If the component was never created we don't need to unsubscribe from anything.
        if (!this.component) {
            return;
        }

        if (isCompletable(this.component)) {
            this.component.complete?.unsubscribe();
        }
        if (isUnlockable(this.component)) {
            this.component.unlock?.unsubscribe();
        }
        if (isRejectable(this.component)) {
            this.component.reject?.unsubscribe();
        }
        if (isSavable(this.component)) {
            this.component.save?.unsubscribe();
        }
        if (isDeletable(this.component)) {
            this.component.delete?.unsubscribe();
        }
        if (isClosable(this.component)) {
            this.component.close?.unsubscribe();
        }
    }

    complete() {
        if (!isCompletable(this.component) || !this.component.isReadyToComplete()) {
            return;
        }
        this.internalAction.InternalTask.TaskStatusId = TaskStatusEnum.Complete;
        this.internalAction.InternalTask.ActualSeconds = this.timer.elapsedSeconds;

        this.updateStatus(this.internalAction).subscribe(
            {
                next: () => {
                    toastr.success('Task has been completed.')
                    this.priorityViewApi.resolve({ getNext: true })
                },
                error: () => toastr.error("Unable to complete action.")
            }
        );
    }

    unlock() {
        bootbox.confirm('Are you sure you want to unlock and unassign this task?', (confirmed: boolean) => {
            if (confirmed) {
                this.internalAction.InternalTask.TaskStatusId = TaskStatusEnum.UnlockAndUnassign;
                this.updateStatus(this.internalAction).subscribe(
                    {
                        next: () => {
                            toastr.info('You have been unassigned from this task.');
                            this.priorityViewApi.resolve({ getNext: false });
                        },
                        error: () => toastr.error("Unable to unlock action.")
                    }
                );
            }
        });
    }

    reject() {
        if (!isRejectable(this.component) || !this.component.isReadyToReject()) {
            return;
        }

        this.modalService.open(InternalActionRejectComponent).result
            .then((comment: string) => {

                this.internalAction.InternalTask.ReviewerComments = comment;
                this.internalAction.InternalTask.TaskStatusId = TaskStatusEnum.NeedsData;
                this.internalAction.InternalTask.ActualSeconds = this.timer.elapsedSeconds;
                this.updateStatus(this.internalAction).subscribe(
                    {
                        next: () => {
                            toastr.info('You have rejected this task.');
                            this.priorityViewApi.resolve({ getNext: false });
                        },
                        error: () => toastr.error("Unable to reject action.")
                    }
                );
            });
    }

    save() {
        if (!isSavable(this.component) || !this.component.isReadyToSave()) {
            return;
        }

        this.internalAction.InternalTask.TaskStatusId = TaskStatusEnum.SaveProgress;
        this.internalAction.InternalTask.ActualSeconds = this.timer.elapsedSeconds;
        this.updateStatus(this.internalAction,).subscribe(
            {
                next: () => {
                    toastr.info('This task is still assigned to you.');
                    this.priorityViewApi.resolve({ getNext: false });
                },
                error: () => toastr.error("Unable to save action.")
            }
        );
    }

    delete() {
        if (!isDeletable(this.component) || !this.component.isReadyToDelete()) {
            return;
        }

        this.internalAction.InternalTask.TaskStatusId = TaskStatusEnum.Deleted;
        this.internalAction.InternalTask.ActualSeconds = this.timer.elapsedSeconds;
        this.updateStatus(this.internalAction).subscribe(
            {
                next: () => {
                    toastr.success('Task has been deleted.');
                    this.priorityViewApi.resolve({ getNext: true });
                },
                error: () => toastr.error("Unable to delete action.")
            }
        );
    }

    close() {
        if (isSavable(this.component)) {
            this.save();
            return;
        }

        this.priorityViewApi.resolve({ getNext: false });
        toastr.info('This task is still assigned to you.')
    }

    private updateStatus(internalAction: Boo.Objects.InternalAction): Observable<void> {
        this.isLoading = true;

        return this.internalActionService.updateStatus(internalAction, false)
            .pipe(finalize(() => this.isLoading = false));
    }

    private createInjector(): Injector {
        return Injector.create({
            providers: [
                {
                    provide: InternalActionToken,
                    useValue: this.internalAction
                }
            ],
            parent: this.injector
        });
    }

    private configureActions() {
        if (isCompletable(this.component)) {
            this.showCompleteButton = this.component.showCompleteButton ?? true;
            this.component.complete?.subscribe(this.complete.bind(this));
        }
        if (isUnlockable(this.component)) {
            this.showUnlockButton = this.component.showUnlockButton;
            this.component.unlock?.subscribe(this.unlock.bind(this));
        }
        if (isRejectable(this.component)) {
            this.showRejectButton = this.component.showRejectButton ?? true;
            this.component.reject?.subscribe(this.delete.bind(this));
        }
        if (isSavable(this.component)) {
            this.showSaveButton = this.component.showSaveButton ?? true;
            this.component.save?.subscribe(this.save.bind(this));
        }
        if (isDeletable(this.component)) {
            this.showDeleteButton = this.component.showDeleteButton ?? true;
            this.component.delete?.subscribe(this.delete.bind(this));
        }
        if (isClosable(this.component)) {
            this.showCloseButton = this.component.showCloseButton ?? true;
            this.component.close?.subscribe(this.close.bind(this));
        }

        this.hideActionBar = !this.showCompleteButton
            && !this.showUnlockButton
            && !this.showRejectButton
            && !this.showSaveButton
            && !this.showDeleteButton
            && !this.showCloseButton;
    }
}

export interface InternalActionWorkspaceResult {
    getNext: boolean;
}