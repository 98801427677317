import { Component, OnInit } from '@angular/core';
import BaseComponent from '../BaseComponent';
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { UntypedFormControl, Validators } from '@angular/forms';
import { RetentionService } from '../../../services/retention.service';
import { WorkblockService } from '../../../services/workblock.service';
import { forkJoin } from 'rxjs';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-ticket-retention',
  templateUrl: './Retention.component.html'
})
export class RetentionComponent extends BaseComponent implements OnInit {
  public retentionAttempt: Boo.Objects.Retention.RetentionAttempt;
  public retentionReasons: Boo.Objects.Retention.RetentionReason[];
  public displayRetentionReasons: Boo.Objects.Retention.RetentionReason[];
  public retentionResolutionTypes: Boo.Objects.Retention.RetentionResolutionType[];
  public canContactInMonthsOptions: any = [
    {
      Value: 0,
      Name: 'Do not contact'
    },
    {
      Value: 3,
      Name: '3 months'
    },
    {
      Value: 6,
      Name: '6 months'
    },
    {
      Value: 9,
      Name: '9 months'
    },
    {
      Value: 12,
      Name: '12+ months'
    }
  ];

  public canContactInMonthsCtrl: UntypedFormControl;
  public retentionReasonCtrl: UntypedFormControl;
  public retentionResolutionTypeIdCtrl: UntypedFormControl;
  public workblockAdded: boolean;

  private validatedControls: UntypedFormControl[];

  constructor(
    private retentionService: RetentionService,
    private workblockService: WorkblockService) {
    super();
  }

  public ngOnInit(): void {

    this.loadRetentionAttempt();
  }

  public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
    return super.activate(params);
  }

  public validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
    return super.validate(saveType)
      .then((validationResult) => {
        if (saveType === SaveTypes.Complete && !this.isFormValid) {
          this.showErrors();
          validationResult.isValid = false;
        }
        return validationResult;
      });
  }

  public save(saveType: SaveTypes): JQueryPromise<void> {
    this.retentionAttempt.RetentionReasonId = this.retentionReasonCtrl.value?.RetentionReasonId;
    this.retentionAttempt.RetentionResolutionTypeId = this.retentionResolutionTypeIdCtrl.value;
    this.retentionAttempt.CanContactInMonths = this.canContactInMonthsCtrl.value;
    if (saveType === SaveTypes.Complete) {
      this.retentionAttempt.ResolutionDate = new Date();
    }
    return super.save(saveType)
      .then(() => {
        return Utils.wrapDfd(this.retentionService.saveRetentionAttempt(this.retentionAttempt));
      })
      .then(() => this.reloadRetentionAttempt());
  }

  public createWorkblock(): void {
    this.isLoading(true);
    this.workblockService.retentionWorkblockCustomer(this.customer.CustomerId(), "Tasks are blocked for this customer until the Retention team has completed retention attempts.")
      .subscribe(() => {
        this.isLoading(false);
        this.workblockAdded = true;
        toastr.success(`Workblock succesfully added for customer: ${this.customer.CustomerId()}`)
      }, err => toastr.error(err));
  }

  searchFn(searchTerm: string, item: Boo.Objects.Retention.RetentionReason): boolean {
    if (!item) {
      return false;
    }
    searchTerm = searchTerm.toLocaleLowerCase();
    return item.Name.toLocaleLowerCase().includes(searchTerm) || item.Description.toLocaleLowerCase().includes(searchTerm);
  }

  getParentName = (item: Boo.Objects.Retention.RetentionReason) => this.retentionReasons.find(x => x.RetentionReasonId === item.ParentId)?.Name || 'Other';

  get formIsReady(): boolean {
    return !!this.retentionReasons && !!this.retentionResolutionTypes;
  }

  get isFormValid(): boolean {
    return this.validatedControls.every(x => x.valid);
  }

  get canContactInMonthsCtrlIsReadOnly(): boolean {
    return !this.retentionResolutionTypeIdCtrl || this.retentionResolutionTypeIdCtrl.value !== Boo.Objects.Retention.Enums.RetentionResolutionTypes.Cancel;
  }

  private loadRetentionAttempt(): void {
    forkJoin([this.retentionService.getRetentionAttempt(this.customer.CustomerId(), this.ticket.TicketId()), this.retentionService.getRetentionReasons(), this.retentionService.getRetentionResolutionTypes() ])
    .subscribe(([retentionAttempt, retentionReasons, retentionResolutionTypes]) => {
      this.retentionAttempt = retentionAttempt;
      this.retentionReasons = retentionReasons;
      this.displayRetentionReasons = retentionReasons.filter(x => 
        this.retentionAttempt.RetentionReasonId == x.RetentionReasonId || 
        (x.IsActive && (x.ParentId || x.Description)));
      this.retentionResolutionTypes = retentionResolutionTypes;

      this.canContactInMonthsCtrl = new UntypedFormControl(this.retentionAttempt.CanContactInMonths);
      this.retentionReasonCtrl = new UntypedFormControl(this.retentionReasons.find(x => x.RetentionReasonId === this.retentionAttempt.RetentionReasonId), [Validators.required]);
      this.retentionResolutionTypeIdCtrl = new UntypedFormControl(this.retentionAttempt.RetentionResolutionTypeId, [Validators.required]);
      this.retentionResolutionTypeIdCtrl.valueChanges.subscribe(value => {
        if (value === Boo.Objects.Retention.Enums.RetentionResolutionTypes.Cancel) {
          this.canContactInMonthsCtrl.setValidators([Validators.required]);
        } else {
          this.canContactInMonthsCtrl.setValidators([]);
          this.canContactInMonthsCtrl.setValue(null);
        }
      });

      this.validatedControls = [this.canContactInMonthsCtrl, this.retentionReasonCtrl, this.retentionResolutionTypeIdCtrl];
    });
  }

  private reloadRetentionAttempt(): void {
    this.retentionService.getRetentionAttempt(this.customer.CustomerId(), this.ticket.TicketId())
      .subscribe((retentionAttempt) => {
        this.retentionAttempt = retentionAttempt;

        this.canContactInMonthsCtrl.setValue(this.retentionAttempt.CanContactInMonths);
        this.retentionReasonCtrl.setValue(this.retentionReasons.find(x => x.RetentionReasonId === this.retentionAttempt.RetentionReasonId));
        this.retentionResolutionTypeIdCtrl.setValue(this.retentionAttempt.RetentionResolutionTypeId);
    });
  }

  private showErrors(): void {
    this.validatedControls.forEach(x => {
      x.markAsTouched();
      x.markAsDirty();
      x.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    });
  }
}
