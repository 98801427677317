import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "seoMinutes"
})
export class SeoMinutesPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let formattedMinutes = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let formattedHours = (value / 60).toFixed(2);
    return formattedMinutes + ' (' + formattedHours + ' hrs)';
  }
}