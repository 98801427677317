export default class PresignedUrlRequest implements Boo.Objects.Amazon.PreSignedUrlRequest {
    public BucketName: string;
    public ContentSize: number;
    public ContentType: string;
    public Expiration: Date;
    public Filename: string;
    public Folder: string;
    public UseUniqueFileName: boolean;

    constructor() {
        this.BucketName = null;
        this.ContentSize = 0;
        this.ContentType = null;
        this.Expiration = null;
        this.Filename = null;
        this.Folder = null;
        this.UseUniqueFileName = false;
    };
};
