import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
    public trackEvent(event: IAnalyticsEvent): void {
        if (typeof gtag === 'undefined' || !gtag) {
            return;
        }

        gtag('event', event.action, {
            event_category: event.category,
            event_label: event.label
        });
    }
}