<div class="pb-3">
  <div class="row pb-2">
    <div class="col-md-12">
      Does Google Analytics need to be created for this customer?
    </div>
  </div>
  <div class="row pb-2">
    <div class="col-md-12">
      <shared-components-customchoice [value]="shouldCreateAnalytics.value" (valueChange)="shouldCreateAnalytics.patchValue($event)"></shared-components-customchoice>
    </div>
  </div>
  <ng-container *ngIf="shouldCreateAnalytics.value === false && isAuthorized && googleTags.length > 0">
    <div class="row pb-2">
      <div class="col-md-12">
        Google Analytics URL
      </div>
    </div>
    <div class="row pb-2">
      <div class="col-md-8">
        <ng-select clas="form-control" [items]="googleTags" bindLabel="Url" bindValue="GoogleTag"
          placeholder="Select a url..." [clearable]="false" [(formControl)]="selectedGoogleTag" lpValidationMsg>
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        Google Tag: {{ selectedGoogleTag.value }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isAuthorized && googleTags.length === 0">
    <div class="row">
      <div class="col-md-8">
        <div class="alert alert-warning">
          The connected Google Analytics account has no data streams.
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isAuthorized">
    <div class="row">
      <div class="col-md-8">
        <div class="alert alert-warning">
          Google Analytics not connected for this customer.
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="shouldCreateAnalytics.value && isAuthorized && googleTags.length > 0">
    <div class="row">
      <div class="col-md-8">
        <div class="alert alert-warning">
          This customer is already connected to Google Analytics.
        </div>
      </div>
    </div>
  </ng-container>
</div>