import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { ContentAuditService } from "../../../services/content-audit.service";
import { finalize, map, takeWhile } from 'rxjs/operators';


@Component({
  selector: 'app-components-content-audit',
  templateUrl: './content-audit.component.html'
})
export class ContentAuditComponent {
  @Input() content: string;
  @Input() isVisible: boolean;
  @Input() delay: number;
  @Output() contentChecked: EventEmitter<null> = new EventEmitter<null>();

  audit: Boo.ContentAudit.Models.ExternalContentAudit;
  remainingDelay$: Observable<number> = timer(0, 1000).pipe(
    map(n => (0 - n) * 1000),
    takeWhile(n => n >= 0)
  );
  isLoading: boolean = false;

  constructor(private contentAuditService: ContentAuditService) { }

  auditContent(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.contentChecked.emit();
      this.contentAuditService.auditContent(this.content)
        .pipe(finalize(() => this.isLoading = false))
          .subscribe((audit: Boo.ContentAudit.Models.ExternalContentAudit) => {
            if (audit === null) {
              toastr.info('Duplicate content service is unavailable. Please try again later.');
              this.remainingDelay$ = timer(0, 1000).pipe(map(n => (0 - n) * 1000), takeWhile(n => n >= 0));
              this.audit = null;
            }
            else {
              this.audit = audit;
              this.remainingDelay$ = timer(0, 1000).pipe(map(n => (this.delay - n) * 1000),takeWhile(n => n >= 0));
            }
          },(err) => toastr.error(err));
    }
  }
}
