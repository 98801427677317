import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'shared-components-textwithlinks',
  templateUrl: './textWithLinks.component.html',
  styleUrls: ['./textWithLinks.component.scss']
})
export class TextWithLinksComponent {
  @ViewChild('container', { static: true })
  public container: ElementRef;

  public InnerHtml: string;

  private _text: string;
  @Input() public get Text(): string {
    return this._text;
  }
  public set Text(value: string) {
    this._text = value;

    this.InnerHtml = this._text;
    if (_.isString(this.InnerHtml)) {
      const linkRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-\.\+~]+)*(\/([a-z0-9_\-\.\+]*)(\?[a-z0-9+_\-\.\+%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*\+.=-_~:@\/?]*)?)(\s+|$)/gi;
      this.InnerHtml = this.InnerHtml.replace(linkRegex,
        function (url: any) {
          let address = url;
          if (url.toLowerCase().indexOf('http') < 0) {
            address = `http://${url}`;
          }
          return `<a target=_blank rel="noreferrer" href="${address}">${url}</a>`;
        });
    }
  }
}
