<div>
  <ng-container *ngIf="!urlSuggestions?.length">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>No pages or keywords have been defined yet</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
  <ng-container *ngFor="let urlSuggestion of urlSuggestions">
    <div class="row mb-5">
      <div class="col-md-12">
        <app-components-shared-keywords-ticket-url-suggestion 
          [urlSuggestion]="urlSuggestion"
          [keywordSuggestionValidator]="keywordSuggestionValidator"
          [customerId]="customer?.CustomerId"
          [isNationalChanged]="isNationalChanged"
          [addKeywordToUrlSuggestion]="addKeywordToUrlSuggestion"
          [keywordTypes]="keywordTypes"
          (redoRequested)="updateRedos($event)"
          (urlDeleted)="deleteUrl($event)"
          (keywordCountChanged)="handleKeywordCountChange()"
          (urlApproved)="urlApproved.emit($event)"
          (urlApprovalUndone)="urlApprovalUndone.emit($event)">
        </app-components-shared-keywords-ticket-url-suggestion>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="readonlyUrls.length !== 0">
    <app-components-shared-keywords-ticket-website-urls-readonly
      [websiteUrls]="readonlyUrls">
    </app-components-shared-keywords-ticket-website-urls-readonly>
  </ng-container>
  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-info" (click)="newUrlSuggestionForm.enable()">+ Add Page</button>
    </div>
  </div>
  <ng-container *ngIf="newUrlSuggestionForm.enabled">
    <div class="row d-flex align-items-center form-group mt-2" [(formGroup)]="newUrlSuggestionForm">
      <div class="col-md-6">
        <input class="form-control" formControlName="url" lpValidationMsg [placeholder]="'Url'">
      </div>
      <div class="col-md-4">
        <label>This page needs to be created</label>
        <input type="checkbox" formControlName="needsCreation">
      </div>
      <div class="col-md-1">
        <button class="btn btn-danger w-100" (click)="resetNewUrlSuggestionForm()">Cancel</button>
      </div>
      <div class="col-md-1">
        <button class="btn btn-success w-100" (click)="addUrlSuggestion()">Add</button>
      </div>
    </div>
  </ng-container>
</div>