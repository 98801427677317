import { Component } from '@angular/core';
import System from 'framework/System';

@Component({
	selector: 'app-components-specialist-actioncomponents-seocontentsurvey',
	templateUrl: './SeoContentSurvey.component.html'
})
export class SeoContentSurveyComponent {
    seoContentSurvey: Boo.Objects.SeoContentSurvey;

    public activate(params: any): JQueryPromise<void> {
        this.seoContentSurvey = params.seoContentSurvey;

        return System.emptyPromise();
    }
}
