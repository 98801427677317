import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './Login/Login.component';
import { LogoutComponent } from './Logout/Logout.component';
import { ResetPasswordComponent } from './ResetPassword/ResetPassword.component';
import { SwitchPartnerComponent } from './SwitchPartner/SwitchPartner.component';
import { ChangePasswordComponent } from './ChangePassword/ChangePassword.component';


@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    ResetPasswordComponent,
    SwitchPartnerComponent,
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class SystemModule { }
