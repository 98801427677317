<div class="row">
  <div class="col-md-12">
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <app-table [items]="adjustments" [exportFilename]="'website-adjustments'">
      <ng-template #tableHeader>
        <tr>
          <th export sort field="Name">Name</th>
          <th export sort field="AdjustmentId">Adjustment ID</th>
          <th export sort asc field="InsertedDate">Start Date</th>
          <th export sort field="StatusDate">Completed Date</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-adjustment>
        <tr>
          <td>{{adjustment.TicketType.Name}}</td>
          <td>{{adjustment.AdjustmentId}}</td>
          <td>{{adjustment.InsertedDate | dateText }}</td>
          <ng-container *ngIf="adjustment.StatusId === window.launchpad.config.TicketStatusEnum.Completed">
            <td>{{adjustment.StatusDate | dateText }}</td>
          </ng-container>
          <ng-container *ngIf="adjustment.StatusId !== window.launchpad.config.TicketStatusEnum.Completed">
            <td>--</td>
          </ng-container>
        </tr>
      </ng-template>
    </app-table>
  </div>
</div>