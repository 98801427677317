import { Component } from '@angular/core';
import System from 'framework/System';

@Component({
	selector: 'app-components-specialist-actioncomponents-customerinformation',
	templateUrl: './CustomerInformation.component.html'
})
export class CustomerInformationComponent {
    public customer: Boo.Objects.Customer;
    public taskDetails: Boo.Objects.ActionDetail;
    public activate(params: any): JQueryPromise<void> {
        this.customer = params.customer;
        this.taskDetails = params.taskDetails;
        return System.emptyPromise();
    }
}
