<div class="row">
	<div class="col-md-12 text-center">
		<ng-container *ngIf="!currentAssignment()">
			<button (click)="getNewAssignment()" class="btn btn-danger" [ngClass]="customerButtonCss">
        <ng-container *ngIf="theme !== themes.regular">
          <i [ngClass]="icon1"></i>
        </ng-container>
        <span>Help Next Customer</span>
        <ng-container *ngIf="theme !== themes.regular">
          <i [ngClass]="icon2"></i>
        </ng-container>
			</button>

		</ng-container>
		<ng-container *ngIf="currentAssignment()">
			<div class="row">
				<div class="col-md-12 text-center">
					<h3>Assigned Customer</h3>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 text-center">
					<button (click)="openCustomer(currentAssignment().CustomerId)" class="btn btn-primary">
						<i class="fas fa-external-link-square-alt"></i>
						<span>{{currentAssignment().CustomerName}}</span>
						-
						<span>{{currentAssignment().CustomerId}}</span>
					</button>
					<button (click)="endAssignment()" class="btn btn-danger">
						<i class="fas fa-trash-alt"></i>
						Unassign
					</button>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 text-center">
					<small>
						until
						<span>{{currentAssignment().ExpirationDate | dateText: 'M/DD/YYYY h:mm a' }}</span>
					</small>
				</div>
			</div>
		</ng-container>
	</div>
</div>
