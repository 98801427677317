<div *withPermissions="let allow of ['CanInsertTickets']" class="row">
	<div class="col-md-12">
		<div class="card">
			<div class="card-header">
				Open Tickets
				<button [hidden]="!allow().CanInsertTickets()" (click)="newTicket()" class="btn btn-primary float-end">
					<i class="fa fa-plus"></i>
					New Ticket
				</button>
				<button (click)="loadTickets()" style="margin-right: 5px;" class="btn btn-primary float-end">
					<i class="fas fa-sync"></i>
					Refresh
				</button>
			</div>
			<div [loadingOverlay]="isLoading" class="card-body">
				<app-tab-panel *ngIf="!isLoading" [displayMode]="tabPanelDisplayMode.horizontal" [activeTab]="activeTab" (selected)="activeTab = $event">
					<ng-container *ngFor="let ticketGroup of ticketGroups">
						<app-tab [title]="ticketGroup.TicketTypeGroup.Name" [titleBadge]="ticketGroup.TicketPartials.length.toString()">
							<app-components-managecustomer-ticketgroup *tabContent
								[customer]="customer"
								[parent]="this"
								[tickets]="ticketGroup.TicketPartials"
								[notesAndTicketsPublicApi]="notesAndTicketsPublicApi">
							</app-components-managecustomer-ticketgroup>
						</app-tab>
					</ng-container>
				</app-tab-panel>
			</div>
		</div>
	</div>
</div>
