import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import BusinessEntities from 'app/models/enums/businessentities';
import IPriorityViewPublicApi = app.interfaces.IPriorityViewPublicApi;
import { TaskStewardshipService } from '../../../services/task-stewardship.service';
import CustomValidators from '../../../shared/custom-validators';

@Component({
  selector: 'app-components-manager-taskstewardship',
  templateUrl: './TaskStewardship.component.html'
})
export class TaskStewardshipComponent implements OnInit {
  @Input() public taskStewardshipResponsibility: Boo.TaskStewardship.Models.TaskStewardshipResponsibility;
  @Input() public taskStewardshipResponsibilities: Boo.TaskStewardship.Models.TaskStewardshipResponsibility[];
  @Input() public boostabilityUsers: Boo.Objects.PartnerUserFragment[];
  @Input() public taskTypes: Boo.Objects.TaskType[];
  @Input() public languages: Boo.Objects.Language[];
  @Input() public available: any[];
  @Input() public usedNames: string[];
  @Input() public priorityViewApi: IPriorityViewPublicApi;

  public loading: boolean;
  public nameCtrl: UntypedFormControl;
  public businessEntityIdCtrl: UntypedFormControl;
  public taskStewardUserIdCtrl: UntypedFormControl;
  public taskTypeCtrl: UntypedFormControl;
  public languageCtrl: UntypedFormControl;
  public availableTaskTypes: Boo.Objects.TaskType[] = [];
  public availableLanguages: Boo.Objects.Language[] = [];

  public businessEntities: any = [
    {
      Value: BusinessEntities.USA,
      Name: 'USA'
    },
    {
      Value: BusinessEntities.Netherlands,
      Name: 'Netherlands'
    },
    {
      Value: BusinessEntities.Germany,
      Name: 'Germany'
    },
    {
      Value: BusinessEntities.Manta,
      Name: 'Manta'
    }
  ];

  private validatedControls: UntypedFormControl[];

  private defaultTaskStewardship: Boo.TaskStewardship.Models.TaskStewardship = {
    BusinessEntityId: null,
    BusinessEntityName: '',
    Name: '',
    StewardName: '',
    StewardUserId: null,
    TaskStewardshipId: 0
  } as Boo.TaskStewardship.Models.TaskStewardship;

  private defaultTaskStewardshipResponsibility: Boo.TaskStewardship.Models.TaskStewardshipResponsibility = {
    LanguageId: null,
    LanguageName: '',
    TaskStewardship: this.defaultTaskStewardship,
    TaskStewardshipId: 0,
    TaskStewardshipResponsibilityId: 0,
    TaskTypeId: null,
    TaskTypeName: ''
  } as Boo.TaskStewardship.Models.TaskStewardshipResponsibility;

  constructor(
    private taskStewardshipService: TaskStewardshipService
  ) {
  }

  public ngOnInit(): void {
    this.loading = true;
    this.taskStewardshipResponsibility = !!this.taskStewardshipResponsibility ? this.taskStewardshipResponsibility : this.defaultTaskStewardshipResponsibility;
    this.taskStewardshipResponsibilities = this.taskStewardshipResponsibilities || [];
    this.nameCtrl = new UntypedFormControl(this.taskStewardshipResponsibility.TaskStewardship.Name, [Validators.required, CustomValidators.stringsNotAllowed(this.usedNames, true, 'Name must be unique')]);
    this.businessEntityIdCtrl = new UntypedFormControl(this.taskStewardshipResponsibility.TaskStewardship.BusinessEntityId, [Validators.required]);
    this.taskStewardUserIdCtrl = new UntypedFormControl(this.taskStewardshipResponsibility.TaskStewardship.StewardUserId, [Validators.required]);
    this.taskTypeCtrl = new UntypedFormControl(null, []);
    this.taskTypeCtrl.valueChanges.subscribe(_ => {
      this.calculateAvailableLanguages();
    });
    this.languageCtrl = new UntypedFormControl(null, []);

    this.validatedControls = [this.nameCtrl, this.businessEntityIdCtrl, this.taskStewardUserIdCtrl ];

    this.calculateAvailableTaskTypes();
    this.loading = false;
  }

  public delete(): void {
    this.loading = true;
    this.taskStewardshipService.delete(this.taskStewardshipResponsibility.TaskStewardshipId)
    .subscribe(
      _ => {
        toastr.success('Task Stewardship Deleted');
        this.priorityViewApi.resolve();
      },
      err => {
        this.loading = false;
        toastr.error(err);
      });
  }

  public cancel(): void {
    this.priorityViewApi.reject();
  }

  public save(): void {
    this.loading = true;
    if (!this.isFormValid) {
      this.showErrors();
      if (!this.taskStewardshipResponsibilities.length) {
        toastr.error('At least one responsibility required');
      }
      this.loading = false;
      toastr.error('Task Stewardship not saved');
      return;
    }

    this.taskStewardshipResponsibilities
      .forEach(x => {
        x.TaskStewardship.Name = this.nameCtrl.value;
        x.TaskStewardship.BusinessEntityId = this.businessEntityIdCtrl.value;
        x.TaskStewardship.StewardUserId = this.taskStewardUserIdCtrl.value;
      });
    this.taskStewardshipService.save(this.taskStewardshipResponsibilities)
      .subscribe(
        _ => {
          toastr.success('Task Stewardship saved');
          this.priorityViewApi.resolve();
        },
        err => {
          this.loading = false;
          toastr.error(err.replace(/\n/ig, '<br />'));
        });
  }

  public calculateAvailableTaskTypes(): void {
    this.availableTaskTypes = this.taskTypes.filter(taskType =>
      !!this.available.find(x => x.TaskTypeId === taskType.TaskTypeId));
  }

  public calculateAvailableLanguages(): void {
    this.availableLanguages = !!this.taskTypeCtrl.value ?
      this.languages.filter(language =>
        !!this.available.find(x => x.TaskTypeId === this.taskTypeCtrl.value && x.LanguageId === language.LanguageId)) : [];
  }

  public add(): void {
    let taskType = this.availableTaskTypes.find(x => x.TaskTypeId === this.taskTypeCtrl.value);
    let language = this.availableLanguages.find(x => x.LanguageId === this.languageCtrl.value);

    this.taskStewardshipResponsibilities.push(
     {
        TaskStewardship: this.taskStewardshipResponsibility.TaskStewardship,
        TaskStewardshipId: this.taskStewardshipResponsibility.TaskStewardshipId,
        TaskStewardshipResponsibilityId: 0,
        TaskTypeId: taskType.TaskTypeId,
        TaskTypeName: taskType.Name,
        LanguageId: language.LanguageId,
        LanguageName: language.Name
    } as Boo.TaskStewardship.Models.TaskStewardshipResponsibility);

    this.available = this.available.filter(x => !(x.TaskTypeId === taskType.TaskTypeId && x.LanguageId === language.LanguageId));
    this.calculateAvailableTaskTypes();
    this.calculateAvailableLanguages();
    this.languageCtrl.reset(null);
    if (this.availableLanguages.length === 0) {
      this.taskTypeCtrl.reset(null);
    }
  }

  public remove(responsibility: Boo.TaskStewardship.Models.TaskStewardshipResponsibility): void {
    this.taskStewardshipResponsibilities = this.taskStewardshipResponsibilities.filter(x => x != responsibility);
    this.available.push({TaskTypeId: responsibility.TaskTypeId, LanguageId: responsibility.LanguageId });
    this.calculateAvailableTaskTypes();
    this.calculateAvailableLanguages();
  }

  get isFormValid(): boolean {
    return this.validatedControls.every(x => x.valid)
      && !!this.taskStewardshipResponsibilities.length;
  }

  get businessEntityIdIsReadOnly(): boolean {
    return !!this.taskStewardshipResponsibility.TaskStewardshipId;
  }

  private showErrors(): void {
    this.validatedControls.forEach(x => x.markAsTouched());
  }
}
