<div>
	<div>
		<ng-container *ngIf="isCreateMode()">
			<compose Selector="app-components-guarantees-add" [ActivateOptions]="{
                                customerId: customerId,
                                api: api, 
                                guarantee: guarantee
                            }"></compose>
		</ng-container>
	</div>
	<div>
		<ng-container *ngIf="!isCreateMode()">
			<compose Selector="app-components-guarantees-list" [ActivateOptions]="{
                                customerId: customerId,
                                api: api
                            }"></compose>
		</ng-container>
	</div>
</div>