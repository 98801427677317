/* tslint:disable */

import { Component } from '@angular/core';
import { PartnerPricingToolPriceLevelService } from '../../../services/partner-pricing-tool-price-level.service';
import Utils from '../../../shared/utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-components-manager-addpartnerpricingtoolpricelevel',
	templateUrl: './AddPartnerPricingToolPriceLevel.component.html'
})
export class AddPartnerPricingToolPriceLevelComponent {
  dfd: any;

  title = 'New Price Level';

  form = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    price: new FormControl<number>(0, [Validators.required, Validators.min(0)]),
    seoMinutes: new FormControl<number | null>(null, Validators.min(0))
  });

  private partnerId = 1; // defaulted to Boostability
  private languageId = 1; // defaulted to English
  private partnerPricingToolPriceLevel:  Boo.Objects.SEOPresence.PartnerPricingToolPriceLevel;


  constructor(private partnerPricingToolPriceLevelService: PartnerPricingToolPriceLevelService) { }

  activate(options: any): void {
    if (_.isObject(options) && _.isNumber(options.partnerId) && _.isNumber(options.languageId)) {
      this.languageId = options.languageId;
      this.partnerId = options.partnerId;
    } else if (_.isObject(options) && _.isObject(options.partnerPricingToolPriceLevel)) {
      this.partnerPricingToolPriceLevel = options.partnerPricingToolPriceLevel as Boo.Objects.SEOPresence.PartnerPricingToolPriceLevel;
      this.form.setValue({
        name: this.partnerPricingToolPriceLevel.Name,
        price: this.partnerPricingToolPriceLevel.Price,
        seoMinutes: this.partnerPricingToolPriceLevel.SeoMinutes
      });
    } else {
      toastr.error('Error adding price level. Could not locate partner/language');
    }
  }

  close(success: any): void {
    if (this.dfd) {
      if (success) {
        this.dfd.resolve();
      } else {
        this.dfd.reject();
      }
    }
  }

  save(): void {
    if (this.form.valid) {
      if (this.partnerPricingToolPriceLevel)
      {
        this.partnerPricingToolPriceLevel.Price = this.form.get('price').value;
        this.partnerPricingToolPriceLevel.Name = this.form.get('name').value;
        this.partnerPricingToolPriceLevel.SeoMinutes = this.form.get('seoMinutes').value;
      } else {
        this.partnerPricingToolPriceLevel = {
          PartnerId: this.partnerId,
          LanguageId: this.languageId,
          Name: this.form.get('name').value,
          Price: this.form.get('price').value,
          SeoMinutes: this.form.get('seoMinutes').value
        } as Boo.Objects.SEOPresence.PartnerPricingToolPriceLevel;
      }

      Utils.wrapDfd(this.partnerPricingToolPriceLevelService.save(this.partnerPricingToolPriceLevel))
        .then(() => {
          toastr.success('Price Level changes were saved successfully.');
          this.close(true);
        })
        .fail((displayMessage) => {
          toastr.error(displayMessage);
        });
    } else {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      this.form.markAsDirty();
    }
  }
}
