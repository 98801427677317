import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import { UniqueKeywordSuggestionValidator } from '../unique-keyword-suggestion-validator';
import { RedoRequest } from '../redo-request';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { Clipboard } from '@angular/cdk/clipboard';
import { AddKeywordSuggestionRequest } from '../add-keyword-suggestion-request';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-keywords-ticket-url-suggestion',
  templateUrl: './url-suggestion-ticket.component.html'
})
export class UrlSuggestionTicketComponent implements OnInit {
  @Input() urlSuggestion: Boo.Objects.LegacyUrlSuggestion;
  @Input() keywordSuggestionValidator: UniqueKeywordSuggestionValidator;
  @Input() customerId: number;
  @Input() isNationalChanged: EventEmitter<boolean>;
  @Input() addKeywordToUrlSuggestion: EventEmitter<AddKeywordSuggestionRequest>;
  @Input() keywordTypes: Boo.Objects.KeywordSuggestionType[];
  @Output() redoRequested = new EventEmitter<RedoRequest>();
  @Output() urlDeleted = new EventEmitter<Boo.Objects.LegacyUrlSuggestion>();
  @Output() keywordCountChanged = new EventEmitter<void>();
  @Output() urlApproved = new EventEmitter<Boo.Objects.LegacyUrlSuggestion>();
  @Output() urlApprovalUndone = new EventEmitter<Boo.Objects.LegacyUrlSuggestion>();
  recommendationSelected = new EventEmitter<Boo.Objects.Enums.KeywordSuggestionTypes>();
  keywordGroups: KeywordSuggestionGroup[] = [];
  urlForm: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(512),
    CustomValidators.validUrl()
  ]);
  newKeywordForm: UntypedFormGroup = new UntypedFormGroup({
    keyword: new UntypedFormControl('', [Validators.required, Validators.maxLength(128), CustomValidators.mustNotBeWhitespace()]),
    area: new UntypedFormControl('', [CustomValidators.requiredIfValidator((() => !this.keywordSuggestionValidator?.isNational)), Validators.maxLength(256), CustomValidators.mustNotBeWhitespace()]),
    isAreaLast: new UntypedFormControl(true),
    type: new UntypedFormControl(null, [Validators.required])
  }, {
    validators: [CustomValidators.keywordsCannotBeDuplicated(this.customerCampaignService, () => !this.newKeywordForm.get('isAreaLast').value, this.getUnavailableKeywords.bind(this), 'This keyword already exists on this URL')]
  });
  keywordRedoForm: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(2048)]);
  isRedoRequested: boolean = false;
  wasAlreadyRedo: boolean = false;
  canCancelRedo: boolean = true;
  approvedType: Boo.Objects.Enums.KeywordSuggestionTypes = null;
  csrSuggestedUrl: boolean = false;

  private _defaultRank: number = 101;
  constructor(private modalService: NgbModal, private clipboard: Clipboard, private customerCampaignService: CustomerCampaignService) { }


  ngOnInit(): void {
    this.urlForm.patchValue(this.urlSuggestion.Url);
    this.urlForm.disable();
    this.newKeywordForm.disable();
    this.keywordRedoForm.disable();

    this.redoRequested.subscribe(redoRequest => this.isRedoRequested = redoRequest.isRedoRequested);
    this.isNationalChanged.subscribe(isNationalChanged => {
      this.canCancelRedo = !isNationalChanged;

      if (this.csrSuggestedUrl) {
        this.deleteUrl();
        return;
      }

      if (isNationalChanged && this.isRedoRequested) {
        this.wasAlreadyRedo = true;
        return;
      }

      if (!isNationalChanged && this.wasAlreadyRedo) {
        this.wasAlreadyRedo = false;
        return;
      }

      if (isNationalChanged) {
        this.requestRedo();
      } else {
        this.cancelRedo();
      }
    });

    this.keywordGroups = this.urlSuggestion.KeywordSuggestions.filter(x => x.KeywordSuggestionTypeId).reduce(
      (acc, curr) => {
        let arr = acc.find(x => x.type === curr.KeywordSuggestionTypeId);
        arr ? arr.keywordSuggestions.push(curr) : acc.push({ type: curr.KeywordSuggestionTypeId, status: curr.KeywordSuggestionStatusId, keywordSuggestions: [curr] });
        return acc;
      },
      []
    );

    this.keywordGroups.forEach(x => {
      x.isApproved = x.keywordSuggestions.reduce((acc, suggestion) => suggestion.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Approved && acc, true);
      x.isRejected = x.keywordSuggestions.reduce((acc, suggestion) => suggestion.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Rejected && acc, true);
    });

    this.approvedType = this.keywordGroups.find(x => x.isApproved)?.type;

    this.csrSuggestedUrl = (this.urlSuggestion.KeywordSuggestions.length || !this.urlSuggestion.UrlSuggestionId) && this.urlSuggestion.KeywordSuggestions.reduce((acc, curr) => acc && curr.IsCustomerChoice, true);

    this.addKeywordToUrlSuggestion.pipe(filter(x => x.url === this.urlSuggestion.Url)).subscribe(x => {
      let newKeywordStatus = this.approvedType ? Boo.Objects.Enums.KeywordSuggestionStatus.Approved : Boo.Objects.Enums.KeywordSuggestionStatus.Proposed;
      this.addNewKeyword(x.keyword, x.area, x.isAreaFirst, x.rank, x.type, newKeywordStatus);
    });
  }

  approveType(type: Boo.Objects.Enums.KeywordSuggestionTypes): void {
    this.urlSuggestion.KeywordSuggestions.filter(x => x.IsCustomerChoice && x.KeywordSuggestionTypeId !== type).forEach(x => this.removeKeyword(x));
    this.urlSuggestion.KeywordSuggestions
      .forEach(x => x.KeywordSuggestionStatusId = x.KeywordSuggestionTypeId === type ? Boo.Objects.Enums.KeywordSuggestionStatus.Approved : Boo.Objects.Enums.KeywordSuggestionStatus.Rejected);
    this.recommendationSelected.emit(type);
    this.approvedType = type;
    this.urlApproved.emit(this.urlSuggestion);
  }

  undoApproval(): void {
    this.urlSuggestion.KeywordSuggestions.forEach(x => x.KeywordSuggestionStatusId = Boo.Objects.Enums.KeywordSuggestionStatus.Proposed);
    this.recommendationSelected.emit(null);
    this.approvedType = null;
    this.urlApprovalUndone.emit(this.urlSuggestion);
  }

  saveNewKeyword(modal: NgbActiveModal): void {
    if (this.newKeywordForm.invalid) {
      this.newKeywordForm.markAllAsTouched();
      return;
    }
    // If a type has been approved, then the new keyword should be automatically approved.
    let newKeywordStatus = this.approvedType ? Boo.Objects.Enums.KeywordSuggestionStatus.Approved : Boo.Objects.Enums.KeywordSuggestionStatus.Proposed;
    this.addNewKeyword(this.newKeywordForm.get('keyword').value, this.newKeywordForm.get('area').value, !this.newKeywordForm.get('isAreaLast').value, this._defaultRank, this.newKeywordForm.get('type').value, newKeywordStatus);

    modal.dismiss();
  }

  addNewKeyword(keyword: string, area: string, isAreaFirst: boolean, rank: number, type: Boo.Objects.Enums.KeywordSuggestionTypes, status: Boo.Objects.Enums.KeywordSuggestionStatus): void {
    let newKeywordSuggestion: Boo.Objects.LegacyKeywordSuggestion =
    {
      Area: area,
      CreatedByTaskId: null,
      CustomerId: this.urlSuggestion.CustomerId,
      InsertedDate: new Date(),
      IsAreaFirst: isAreaFirst,
      Keyword: keyword,
      KeywordSuggestionId: 0,
      KeywordSuggestionStatusId: status,
      KeywordSuggestionTypeId: type,
      KeywordSuggestionType: this.keywordTypes.find(x => x.KeywordSuggestionTypeId === type),
      IsCustomerChoice: true,
      Page: 1,
      Rank: rank,
      SearchVolume: 101,
      UpdatedDate: new Date(),
      UrlSuggestionId: this.urlSuggestion.UrlSuggestionId
    };

    this.addKeyword(newKeywordSuggestion);
  }

  addKeyword(keyword: Boo.Objects.LegacyKeywordSuggestion): void {
    let index = this.keywordGroups.findIndex(x => x.type === keyword.KeywordSuggestionTypeId);

    if (index === -1) {
      this.keywordGroups.push({ type: keyword.KeywordSuggestionTypeId, keywordSuggestions: [keyword], isApproved: false, isRejected: false });
    } else {
      this.keywordGroups[index].keywordSuggestions.push(keyword);
    }

    this.urlSuggestion.KeywordSuggestions.push(keyword);
    this.keywordSuggestionValidator.addKeywordSuggestion(keyword);
    this.keywordCountChanged.emit();
  }

  removeKeyword(keyword: Boo.Objects.LegacyKeywordSuggestion): void {
    let groupIndex = this.keywordGroups.findIndex(x => x.type === keyword.KeywordSuggestionTypeId);
    // TODO: stop checking for !IsCustomerChoice if we ever stop allowing duplicate keywords.
    this.keywordGroups[groupIndex].keywordSuggestions = this.keywordGroups[groupIndex].keywordSuggestions.filter(x => x.Keyword !== keyword.Keyword || x.Area !== keyword.Area || !x.IsCustomerChoice);
    this.urlSuggestion.KeywordSuggestions = this.urlSuggestion.KeywordSuggestions.filter(x => x.Keyword !== keyword.Keyword || x.Area !== keyword.Area || !x.IsCustomerChoice);
    this.keywordSuggestionValidator.removeKeywordSuggestion(keyword);

    if (this.keywordGroups[groupIndex].keywordSuggestions.length === 0) {
      this.keywordGroups.splice(groupIndex, 1);
    }
    this.keywordCountChanged.emit();
  }

  updateUrl(): void {
    if (!this.urlForm.valid) {
      this.urlForm.markAsTouched();
      return;
    }

    this.urlForm.disable();
    this.urlSuggestion.Url = this.urlForm.value;
    this.urlSuggestion.IsExisting = true;
  }

  openModal(content: TemplateRef<any>): void {
    let modal = this.modalService.open(content);
    modal.dismissed.subscribe(() => this.resetNewKeywordForm());
    this.newKeywordForm.enable();

    if (this.approvedType) {
      this.newKeywordForm.get('type').patchValue(this.approvedType);
      this.newKeywordForm.get('type').disable();
    }
  }

  cancelEditUrl(): void {
    this.urlForm.disable();
    this.urlForm.patchValue(this.urlSuggestion.Url);
    this.urlForm.updateValueAndValidity();
  }

  resetNewKeywordForm(): void {
    this.newKeywordForm.disable();
    this.newKeywordForm.reset();
    this.newKeywordForm.get('keyword').setValue('');
    this.newKeywordForm.get('area').setValue('');
    this.newKeywordForm.get('isAreaLast').setValue(true);
    this.newKeywordForm.get('type').setValue(null);

    if (this.newKeywordForm.enabled) {
      this.newKeywordForm.get('type').setValue(null);
    }
  }

  requestRedo(): void {
    if (this.keywordRedoForm.invalid) {
      this.keywordRedoForm.markAsTouched();
      return;
    }

    this.redoRequested.emit({ urlSuggestionId: this.urlSuggestion.UrlSuggestionId, isRedoRequested: true });
    this.urlSuggestion.KeywordSuggestions.filter(x => x.IsCustomerChoice).forEach(x => this.removeKeyword(x));
    this.urlSuggestion.KeywordSuggestions.forEach(x => x.KeywordSuggestionStatusId = Boo.Objects.Enums.KeywordSuggestionStatus.Rejected);

    if (this.urlForm.enabled) {
      this.cancelEditUrl();
    }

    if (this.newKeywordForm.enabled) {
      this.resetNewKeywordForm();
    }

    if (this.approvedType) {
      this.urlApprovalUndone.emit(this.urlSuggestion);
    }

    this.urlSuggestion.RejectionNote = this.keywordRedoForm.value;
    this.resetRedoRequestForm();
  }

  cancelRedo(): void {
    this.urlSuggestion.KeywordSuggestions.forEach(x => x.KeywordSuggestionStatusId = Boo.Objects.Enums.KeywordSuggestionStatus.Proposed);
    this.urlSuggestion.RejectionNote = '';
    this.redoRequested.emit({ urlSuggestionId: this.urlSuggestion.UrlSuggestionId, isRedoRequested: false });
  }

  resetRedoRequestForm(): void {
    this.keywordRedoForm.disable();
    this.keywordRedoForm.reset();
    this.keywordRedoForm.setValue('');
  }

  deleteUrl(): void {
    this.urlDeleted.emit(this.urlSuggestion);
  }

  isKeywordRejected(keyword: Boo.Objects.LegacyKeywordSuggestion) {
    return keyword.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Rejected;
  }

  copyKeywordsToClipboard(): void {
    let text = this.urlSuggestion.Url + "\n" + this.urlSuggestion.KeywordSuggestions
      .filter(x => x.KeywordSuggestionStatusId == Boo.Objects.Enums.KeywordSuggestionStatus.Approved)
      .map(x => this.customerCampaignService.getKeywordPhrase(x.Keyword, x.Area, x.IsAreaFirst))
      .join("\n");
    this.clipboard.copy(text);
    toastr.success("URL and approved keywords copied to clipboard.");
  }

  getUnavailableKeywords(): string[] {
    return this.urlSuggestion ? this.urlSuggestion.KeywordSuggestions.map(x => this.customerCampaignService.getKeywordPhrase(x.Keyword, x.Area, x.IsAreaFirst)) : []
  }
}

interface KeywordSuggestionGroup {
  keywordSuggestions: Boo.Objects.LegacyKeywordSuggestion[];
  type: Boo.Objects.Enums.KeywordSuggestionTypes;
  isRejected?: boolean;
  isApproved?: boolean;
}