import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from '@angular/common';

@Pipe({
  name: "dateText"
})
export class DateTextPipe implements PipeTransform {
  transform(value: any, format?: string, displayAsUtc?: boolean): any {
    value = ko.utils.unwrapObservable(value);
    if (!value || window.launchpad.utils.isDefaultDate(value)) {
      return '-';
    }

    format = format || 'L';

    let momentValue = moment(value);
    if (displayAsUtc || (momentValue.hours() === 0 && momentValue.minutes() === 0 && momentValue.seconds() === 0 && momentValue.milliseconds() === 0 && displayAsUtc !== false)) {
      momentValue = momentValue.utc();
    }

    return momentValue.format(format);
  }
}