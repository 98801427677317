import { Component } from '@angular/core';
import System from 'framework/System';
import Utils from '../../../shared/utils';
import { BlogDiagnosticService } from '../../../services/blog-diagnostic.service';

@Component({
	selector: 'app-components-specialist-blogdiagnostics',
	templateUrl: './BlogDiagnostics.component.html'
})
export class BlogDiagnosticsComponent implements IActivatable {
    public isLoading: KnockoutObservable<boolean>;
    public isUp: KnockoutObservable<Tristate>;
    public isDnsCorrect: KnockoutObservable<Tristate>;
    public namecheapNameservers: KnockoutObservableArray<string>;
    public cloudflareDnsRecords: KnockoutObservableArray<{ Key: string, Value: string }>;
    private blog: Boo.Objects.LinkLocationBlogDetail;

    constructor(private blogDiagnosticService: BlogDiagnosticService) { }

    public activate(params: IBlogDiagnosticsActivationParams): JQueryPromise<void> {
        this.isLoading = ko.observable(false);
        this.isUp = ko.observable(Tristate.Unknown);
        this.isDnsCorrect = ko.observable(Tristate.Unknown);
        this.namecheapNameservers = ko.observableArray([]);
        this.cloudflareDnsRecords = ko.observableArray([]);

        this.blog = ko.mapping.toJS(params.blog);
        return System.emptyPromise();
    }

    public checkIsUp(): void {
        this.isLoading(true);
        Utils.wrapDfd(this.blogDiagnosticService.isUp(this.blog.LinkLocationBlogDetailId))
            .then((result) => {
                this.isUp(result ? Tristate.True : Tristate.False);
            })
            .fail(() => {
                this.isUp(Tristate.False);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    public checkNamecheapNameservers(): JQueryPromise<void> {
        this.namecheapNameservers([]);
        this.isLoading(true);

        return Utils.wrapDfd(this.blogDiagnosticService.namecheapNameservers(this.blog.LinkLocationBlogDetailId))
            .then((result) => {
                this.namecheapNameservers(result);
            })
            .always(() => {
                this.determineCorrectDns();
                this.isLoading(false);
            });
    }

    public checkCloudflareDns(): JQueryPromise<void> {
        this.isLoading(true);
        this.cloudflareDnsRecords([]);

        return Utils.wrapDfd(this.blogDiagnosticService.cloudFlareDns(this.blog.LinkLocationBlogDetailId))
            .then((result) => {
                this.cloudflareDnsRecords(result);
            })
            .always(() => {
                this.determineCorrectDns();
                this.isLoading(false);
            });
    }

    public checkIsDnsCorrect(): void {
        this.checkCloudflareDns();
        this.checkNamecheapNameservers();
    }

    private determineCorrectDns(): void {
        if (_.any(this.namecheapNameservers(), (x) => {
            return x.indexOf('cloudflare') > -1;
        }) &&
            _.any(this.cloudflareDnsRecords(), (x) => {
                return x.Value.indexOf('cloudfront') > -1;
            })) {
            this.isDnsCorrect(Tristate.True);
        } else {
            this.isDnsCorrect(Tristate.False);
        }
    }
}

interface IBlogDiagnosticsActivationParams {
    blog: LinkLocationBlogDetailsObservable;
}

enum Tristate {
    False,
    True,
    Unknown
}
