<div>
  <div class="card">
    <div class="card-header">
      <div class="d-flex flex-row justify-content-between">
        <div class="flex-grow">
          Specialist Workspace
        </div>
        <div *ngIf="canSetOwnPriority && taskTypes.length > 1" class="col-md-4">
          <div class="row">
            <div class="col-md-4 text-end">
              Task Type Priority
              <app-components-helptext [key]="'SpecialistWorkspace_PriorityTask'"></app-components-helptext>
            </div>
            <div class="col-md-8">
              <ng-select [(ngModel)]="priorityTaskTypeId" [clearable]="false">
                <ng-option [value]="0">All</ng-option> <!--Default priority value-->
                <ng-option *ngFor="let taskType of taskTypes" [value]="taskType.TaskTypeId">{{taskType.Name}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf="canFilterLanguages">
          <div class="row">
            <div class="col-md-2">
              Select Languages
            </div>
            <div class="col-md-10">
              <ng-select [(ngModel)]="selectedLanguages" [multiple]="true" [closeOnSelect]="false">
                <ng-template ng-option-tmp let-item="languageOptions" let-index="index">
                  <i [ngClass]="getIconClass(languageOptions[index].languageId)"></i>
                  {{languageOptions[index].fullName}}
                </ng-template>
                <ng-option *ngFor="let selection of languageOptions" [value]="selection.languageId">{{selection.label}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <button [disabled]="isLoading()" (click)="getNextTaskInQueue()" class="btn btn-danger" [ngClass]="customerButtonCss">
            <ng-container *ngIf="theme !== themes.regular">
              <i [ngClass]="icon" class="fa-3x" id="action-button-icon"></i><br>
            </ng-container>
            <span>GET NEXT ACTION</span>
          </button>

        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasWebsiteBookmarkingPermissions" class="card">
    <div class="card-header">
      Options
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-primary btn-sm" (click)="deleteWebsiteBookmarkingSkips();">Clear Website Bookmarking
            Skips</button>
          <app-components-helptext [key]="'SpecialistWorkspace_DeleteCustomerSkips'"></app-components-helptext>
        </div>
      </div>
    </div>
  </div>
</div>
