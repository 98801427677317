<div class="row" [loadingOverlay]="isLoading">
  <div class="col-md-12">
    <div class="card card-warning">
      <div class="card-header">
        Instructions
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            Review listing information and resolve the {{issueLabel}}. If you are unable, but additional information may
            help, create a workblock.
            Otherwise, indicate that the {{issueLabel}} could not be resolved.
            <ul class="list-group list-group-flush">
              <li *ngFor="let issue of localProfileIssues" class="list-group-item"><i
                  class="fa fa-exclamation-triangle"></i>{{issue.Description}}</li>
            </ul>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4 my-auto">
                <div class="form-group" [dataValidation]="{ placement: 'top', value: allIssuesResolved }">
                  <label class="control-label">Were you able to resolve the {{issueLabel}}?</label><br />
                  <shared-components-customchoicelegacy
                    [Settings]="{ value: this.allIssuesResolved }"></shared-components-customchoicelegacy>
                </div>
              </div>
              <div class="col-md-4 my-auto">
                <div *ngIf="allIssuesResolved() === false && allowRejectionConfirmation" class="alert alert-danger">
                  <span>If you are unable to resolve the {{issueLabel}}, but additional information may help, create a
                    workblock.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <ng-container *ngIf="localProfile">
      <div class="card">
        <div class="card-header">
          Local Profile
        </div>
        <div class="card-body">
          <app-components-shared-local-profiles [canAddLocalProfile]="false" [customer]="customer" [localProfileId]="localProfile.LocalProfileId">
          </app-components-shared-local-profiles>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<app-floating-panels>
  <app-floating-panel
    [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
    <app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="taskDetails.TaskNotes">
    </app-components-specialist-actioncomponents-comments>
  </app-floating-panel>
</app-floating-panels>