/* tslint:disable */

import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import timer from 'app/managecustomer/Timer';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { StoreService } from '../../../services/store.service';
import { ESignatureDocumentService } from '../../../services/esignature-document.service';
import { ESignatureDocumentStatuses } from 'app/models/enums/ESignatureDocumentStatuses';
import { CustomerContactPreferencesService } from '../../../services/customer-contact-preferences.service';
import { MerchantFieldsService } from '../../../services/merchant-fields.service';
import { UserService } from '../../../services/user.service';
import { PartnerSupportLevelService } from '../../../services/partner-support-level.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';
import { FrontService } from '../../../services/front.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CustomerUserService } from '../../../services/customer-user.service';
import { of, throwError } from 'rxjs';

@Component({
    selector: 'app-components-managecustomer-customerinformation',
    templateUrl: './CustomerInformation.component.html',
    styleUrls: ['./CustomerInformation.component.scss']
})
export class CustomerInformationComponent implements OnInit {
    @Input() context: any;
    @Input() pageNg: string;
    @Input() showStoreLink: boolean = false;
    @Output() pageChangeEvent = new EventEmitter<string>();

    @ViewChild('draftValidationModal') draftValidationModal: TemplateRef<any>;
    
    public window = window;
    public ko = ko;
    public launchpad = launchpad;
    private updateTimersTimoutInterval: any;
    private emailModifierConfig = {};
    public title = 'Customer Information';
    public parent: any = null;
    public customerSavedCallback: any = null;
    public termsAndConditions: Boo.ESignature.Models.ESignatureDocument;
    public showTermsAndConditions: boolean = false;
    public eSignatureDocumentStatuses = ESignatureDocumentStatuses
    // observables
    public isLoading: any = ko.observable(false);
    public customer: any = ko.observable();
    public contact: CustomerUserObservable;
    public contactNote: string;
    public merchantFields: Boo.Objects.CustomerMerchantFields;
    public loggedInUser: any = ko.observable();
    public partner: any = ko.observable();
    public partnerSupportLevelName: any = ko.observable();
    public customerUser: any = ko.observable();
    public customerTime: any = ko.observable();
    public lastTicketSaveTime: any = ko.observable();
    public page: any = ko.observable('');
    public showTicketSaveTimer: any = ko.observable(true);
    public canEditMerchantInformation: any = ko.observable(false);
    public countries: any = ko.observableArray([]);
    public partnerGroups: any = ko.observableArray([]);
    public ordersSnapshot: Boo.ApisExternal.Store.Objects.Order[];
    public subscriptionsSnapshot: Boo.ApisExternal.Store.Objects.Subscription[]
    public orderStatus: boolean;
    public today: Date = new Date();
    public nextRenewalDate: Date;
    public emailContactLabel: string;
    // Customer Merchant Field Labels
    public MerchantIdLabel: any = ko.observable('Merchant Id');
    public MerchantSourceLabel: any = ko.observable('Merchant Source');
    public MerchantProposalIdLabel: any = ko.observable('Merchant Proposal Id');
    public MerchantCategoryLabel: any = ko.observable('Merchant Category');
    public MerchantCategoryIdLabel: any = ko.observable('Merchant Category Id');
    public MerchantSubCategoryLabel: any = ko.observable('Merchant Subcategory');
    public MerchantSubCategoryIdLabel: any = ko.observable('Merchant Subcategory Id');
    public MerchantConsultantLabel: any = ko.observable('Merchant Consultant');
    public MerchantConsultantContactLabel: any = ko.observable('Merchant Consultant Email');
    public MerchantUdacLabel: any = ko.observable('Merchant UDAC');
    public MerchantProductCodeLabel: any = ko.observable('Product Code');
    public MerchantItemIdLabel: any = ko.observable('Merchant Item Id');
    public MerchantMiscIdLabel: any = ko.observable('Merchant Misc Id');
    public MerchantSitePublishDateLabel: any = ko.observable('Merchant Site Publish Date');
    public MerchantRegionLabel: any = ko.observable('Merchant Region');
    public MerchantCustomerValueLabel: any = ko.observable('Merchant Customer Value');
    public MerchantContractEndDateLabel: any = ko.observable('Merchant Contract End Date');
    public MerchantServiceLevelLabel: any = ko.observable('Merchant Service Level');
    public MerchantCampaignManagerLabel: any = ko.observable('Merchant Campaign Manager');
    public MerchantCampaignManagerContactLabel: any = ko.observable('Merchant Campaign Manager Contact');


    private activateLoaded: boolean;

    constructor(
        private storeService: StoreService,
        private eSignatureDocumentService: ESignatureDocumentService,
        private customerContactPreferencesService: CustomerContactPreferencesService,
        private merchantFieldsService: MerchantFieldsService,
        private userService: UserService,
        private partnerSupportLevelService: PartnerSupportLevelService,
        private sessionStorageService: SessionStorageService,
        private frontService: FrontService,
        private modalService: NgbModal,
        private customerUserService: CustomerUserService) { }

    ngOnInit(): void {
        if (this.activateLoaded) {
            return;
        }
        this.activate({ parent: this.context, page: this.pageNg })
            .then(() => {
                this.showTermsAndConditions = this.partner().PartnerId === Boo.Objects.Enums.PartnerEnum.Boostability;
                if (this.showTermsAndConditions) {
                    this.eSignatureDocumentService.getLatest(this.customer().CustomerId(), Boo.ESignature.Models.Enums.ESignatureDocumentTypes.TermsAndConditions)
                        .subscribe(x => this.termsAndConditions = x);
                }
            });
    }

    public partnerName: any = ko.computed(() => {
        if (this.customer() && this.customer().Partner) {
            return this.customer().Partner.Name();
        } else {
            return '';
        }
    });

    public isStatusActive: any = ko.computed(() => {
        if (this.customer()) {
            return this.customer().StatusId() === launchpad.config.StatusEnum.Active;
        } else {
            return false;
        }
    });

    public isStatusCanceled: any = ko.computed(() => {
        if (this.customer()) {
            return this.customer().StatusId() === launchpad.config.StatusEnum.Canceled;
        }
        return false;
    });

    public customerSpendSummaries: KnockoutComputed<CustomerSpendSummaryObservable[]> = ko.computed(() => {
        return this.customer() ? ko.utils.unwrapObservable(this.customer().Spends) : [];
    });

    public hasCsr: any = ko.computed(() => {
        let hasAM = false;
        if (this.customer()) {
            hasAM = !!(this.customer().Csr);
        }
        return hasAM;
    });

    public csrName: any = ko.computed(() => {
        let name = '';
        if (this.customer() && this.customer().Csr && this.customer().Csr.FullName) {
            name = this.customer().Csr.FullName();
        }
        return name;
    });

    public csrPhoneNumber: any = ko.computed(() => {
        let phoneNumber = '';
        if (this.customer() && this.customer().Csr && this.customer().Csr.PhoneExtension()) {
            phoneNumber = phoneNumber.concat(' Ext. ').concat(this.customer().Csr.PhoneExtension());
        }
        return phoneNumber;
    });

    public csrEmailAddress: any = ko.computed(() => {
        let emailAddress = 'N/A';
        if (this.customer() && this.customer().Csr) {
            emailAddress = this.getCsrEmailAddress(this.customer());
        } else if (this.partner()) {
            emailAddress = this.partner().SupportEmailAddress;
        }
        return emailAddress;
    });

    public supportTeam: any = ko.computed(() => {
        let team = 'No Team Set';
        if (this.customer() && this.customer().Team && this.customer().Team.Name()) {
            team = this.customer().Team.Name();
        }
        return team;
    });

    public supportPhoneNumber: any = ko.computed(() => {
        let phoneNumber = 'No Phone Number Set';
        if (this.partner() && this.partner().SupportPhoneNumber) {
            phoneNumber = this.partner().SupportPhoneNumber;
        }
        return phoneNumber;
    });

    public supportPhoneNumberCountryCode: any = ko.computed(() => {
        if (this.partner() && this.partner().CountryId && this.countries) {
            const partnerCountry = this.countries().find((country: any) => {
                return country.CountryId === this.partner().CountryId;
            });
            return partnerCountry ? partnerCountry.Abbreviation : null;
        }
        return null;
    });

    public supportEmailAddress: any = ko.computed(() => {
        let emailAddress = 'N/A';
        if (this.partner() && this.partner().SupportEmailAddress) {
            emailAddress = this.partner().SupportEmailAddress;
        }
        return emailAddress;
    });

    public accountStatusWobble: any = ko.computed(() => {
        return this.customer() && this.customer().StatusId() !== launchpad.config.StatusEnum.Active;
    });

    public customerStatus: any = ko.computed(() => {
        if (this.customer()) {
            return launchpad.config.StatusEnum.names[this.customer().StatusId()];
        } else {
            return '';
        }
    });

    public isValidDate(item: any): any {
        return !window.launchpad.utils.isDefaultDate(ko.utils.unwrapObservable(item));
    }

    public editMerchantFields(): void {
        PriorityViewModel.show('app-components-managecustomer-editmerchantfields', { 'customer': this.customer() })
            .then((data: any) => {
                if (data) {
                    this.customer(data.customer);
                    Utils.wrapDfd(this.merchantFieldsService.save(ko.mapping.toJS(this.customer().MerchantFields)))
                      .then(() => {
                        if (this.customerSavedCallback) {
                            this.customerSavedCallback();
                        }
                      })
                      .fail((displayMessage) => {
                          toastr.error(displayMessage);
                      })
                      .always(() => {
                          this.isLoading(false);
                      });
                }
            }).fail(() => {
                this.parent.refreshCustomer();
            });
    }

    public editBasicInformation(): void {
        PriorityViewModel.show('app-components-managecustomer-basicinformation',
            {
                'customer': this.customer(),
            }).done((data: any) => {
                this.customer(data.customer);
                if (this.customerSavedCallback) {
                    this.customerSavedCallback();
                }
            });
    }

    public editCustomerContacts(): void {
        PriorityViewModel.show('app-components-managecustomer-customerusers',
            {
                customer: ko.mapping.fromJS(this.customer()),
                user: this.loggedInUser,
                partner: this.partner
            })
            .then((data: any) => {
                if (data.users) {
                    const ccu = _.find(data.users(),
                        (User: any) => {
                            return User.UserLevelId() === window.launchpad.config.keys.analyticsUserLevelId && User.CanContact();
                        });
                    this.customerUser(ccu ? ko.mapping.fromJS(ccu) : null);
                    this.customer().Users(data.users());
                    this.setContactEmail();
                }
                // call customerSavedCallback because customer.Users may need to be refreshed
                if (this.customerSavedCallback) {
                    this.customerSavedCallback();
                }
            });
    }

    // Changes to merchant labels should also be added to editmerchantfields.js and MerchantFieldsService.ts
    public fixPartnerInformationLabels(): void {
        if (this.checkIfCustomerIsInPartnerGroup(window.launchpad.config.PartnerGroupEnum.Thryv)) {
            this.MerchantIdLabel('Thryv Customer Id');
            this.MerchantProductCodeLabel('Product Code');
            this.MerchantCategoryIdLabel('Company Id');
            this.MerchantItemIdLabel('Item Id');
            this.MerchantCategoryLabel('Listing Id');
            this.MerchantSubCategoryLabel('Category');
            this.MerchantSubCategoryIdLabel('Enterprise Account Id');
            this.MerchantConsultantLabel('Marketing Consultant');
            this.MerchantSitePublishDateLabel('Site Publish Date');
            this.MerchantUdacLabel('UDAC');
            this.MerchantSourceLabel('Source (Local/National)');
            this.MerchantProposalIdLabel('Merchant ID');
            this.MerchantMiscIdLabel('SEO Proposal ID');
            this.MerchantConsultantContactLabel('Marketing Consultant Email');
            this.MerchantRegionLabel('Sales Region');
            this.MerchantCustomerValueLabel('Total Contract Value');
            this.MerchantContractEndDateLabel('Contract End Date');
            this.MerchantServiceLevelLabel('Client Service Level');
            this.MerchantCampaignManagerLabel('Campaign Manager');
            this.MerchantCampaignManagerContactLabel('Campaign Manager Email');
        } else if (this.checkIfCustomerIsInPartnerGroup(window.launchpad.config.PartnerGroupEnum.Yellow)) {
            this.MerchantIdLabel('Advertiser ID:');
            this.MerchantSourceLabel('BC Product ID:');
            this.MerchantProposalIdLabel('MSA Name:');
            this.MerchantCategoryLabel('MISC:');
            this.MerchantConsultantContactLabel('MSA/MC Email:');
            this.MerchantCategoryIdLabel('MSA Phone:');
            this.MerchantSubCategoryLabel('Heading');
            this.MerchantSubCategoryIdLabel('SiteSmart(Y/N):');
            this.MerchantConsultantLabel('SiteSmart Customisation (Y/N):');
            this.MerchantUdacLabel('Need Click Manager PPC Campaign (Y/N):');
            this.MerchantProductCodeLabel('Product Code:');
            this.MerchantItemIdLabel('Web Specialist:');
            this.MerchantMiscIdLabel('Web Specialist Email Address:');
        } else if (this.customer().PartnerId() ===  Boo.Objects.Enums.PartnerEnum.FoundDigital) {
            this.MerchantIdLabel('Advertiser ID:');
            this.MerchantSourceLabel('BC Product ID:');
            this.MerchantProposalIdLabel('MSA Name:');
            this.MerchantCategoryLabel('MSA Email:');
            this.MerchantCategoryIdLabel('MSA Phone:');
            this.MerchantSubCategoryLabel('Heading');
            this.MerchantSubCategoryIdLabel('SiteSmart(Y/N):');
            this.MerchantConsultantLabel('SiteSmart Customisation (Y/N):');
            this.MerchantUdacLabel('Need Click Manager PPC Campaign (Y/N):');
            this.MerchantProductCodeLabel('Product Code:');
            this.MerchantItemIdLabel('Web Specialist:');
            this.MerchantMiscIdLabel('Web Specialist Email Address:');
        } else if (this.checkIfCustomerIsInPartnerGroup(window.launchpad.config.PartnerGroupEnum.GoDaddy)) {
            this.MerchantCustomerValueLabel('GoDaddy ID');
            this.MerchantSourceLabel('Plan Name');
            this.MerchantProductCodeLabel('Term Length');
            this.MerchantContractEndDateLabel('Pre-Pay Plan Expiration');
            this.MerchantCategoryLabel('NPS Score');
            this.MerchantConsultantLabel('NPS Score Updated');
            this.MerchantProposalIdLabel('Auto-Renew Status');
        } else if (this.customer().PartnerId() === Boo.Objects.Enums.PartnerEnum.Herold) {
            this.MerchantConsultantContactLabel('Customer Email for Reports');
        } else if (this.customer().PartnerId() === Boo.Objects.Enums.PartnerEnum.HeiseRegioConcept) {
            this.MerchantConsultantContactLabel('Customer Email for Reports');
        } else if (this.customer().PartnerId() === Boo.Objects.Enums.PartnerEnum.HeiseRegioConceptÖsterreich) {
            this.MerchantConsultantContactLabel('Customer Email for Reports');
        } else if (this.customer().PartnerId() === Boo.Objects.Enums.PartnerEnum.HeiseRegioConceptSchweiz) {
            this.MerchantConsultantContactLabel('Customer Email for Reports');
        }
    }

    public canActivate(user: any): any {
        this.loggedInUser = ko.observable(user);
        return true;
    }

    public updateTimers(): void {
        this.customerTime(timer.getFormattedTimeSinceStart());
        this.lastTicketSaveTime(timer.getFormattedTimeSinceLastTicketSaveTime());
    }

    public activate(options: any): JQueryPromise<void> {
        if (options && options.parent) {
            this.activateLoaded = true;
            const customerId: number = ko.utils.unwrapObservable(ko.utils.unwrapObservable(options.parent.customer).CustomerId);
            return $.when<any>(
              Utils.wrapDfd(this.sessionStorageService.getUser()),
              Utils.wrapDfd(this.sessionStorageService.getStaticData()),
              Utils.wrapDfd(this.customerContactPreferencesService.get(customerId)),
              Utils.wrapDfd(this.merchantFieldsService.get(customerId)),
              Utils.wrapDfd(this.storeService.getOrdersSnapshot(customerId)),
              Utils.wrapDfd(this.storeService.getSubscriptionsSnapshot(customerId)))
                .done((user, staticData, contactPreferences: Boo.Objects.CustomerContactPreferences, merchantFields: Boo.Objects.CustomerMerchantFields, ordersSnapshot: Boo.ApisExternal.Store.Objects.Order[], subscriptionsSnapshot: Boo.ApisExternal.Store.Objects.Subscription[]) => {
                    this.countries(staticData.Countries);
                    this.partnerGroups(staticData.PartnerGroups);
                    this.setUpEmailModifier();
                    this.merchantFields = merchantFields;
                    this.ordersSnapshot = ordersSnapshot;
                    this.subscriptionsSnapshot = subscriptionsSnapshot;
                    this.nextRenewalDate = subscriptionsSnapshot
                        .filter(x => x.IntervalMonths > 0)
                        .pop()
                        ?.Next;

                    this.canEditMerchantInformation(_.any(user.PartnerUsers,
                        (pu: any) => {
                            return pu.UserLevelId === window.launchpad.config.keys.customerServiceUserLevelId && pu.IsReadonly === false;
                        })); // User has a customer service user and not readonly then they can edit merchant fields
                    this.page(options.page);
                    if (options.showTicketSaveTimer === false) {
                        this.showTicketSaveTimer(false);
                    }
                    this.parent = options.parent;
                    if (ko.isObservable(this.parent.partner)) {
                        this.partner(this.parent.partner());
                    } else if (this.parent.partner) {
                        this.partner(ko.toJS(this.parent.partner));
                    }
                    if (ko.isObservable(this.parent.customer)) {

                        this.parent.customer.subscribe((newValue: any) => {
                            this.customer(newValue);
                        });
                        this.customer(this.parent.customer());
                    } else {
                        this.customer(this.parent.customer);
                    }
                    this.contactNote = contactPreferences.Note || '';
                    this.setContactEmail();
                    if (ko.isObservable(this.parent.customerUser) && this.parent.customerUser()) {
                        this.customerUser(this.parent.customerUser());

                        this.customerUser().FullName = ko.computed(() => {
                            return this.customerUser() ? this.customerUser().FirstName() + ' ' + this.customerUser().LastName() : '';
                        });

                        this.parent.customerUser.subscribe((newValue: any) => {
                            this.customerUser(newValue);
                        });
                    }
                    this.customerSavedCallback = options.customerSavedCallback || null;
                    this.loadPartnerSupportLevelName();
                    this.updateTimersTimoutInterval = setInterval(() => this.updateTimers(), 1000);
                    if (this.accountStatusWobble() === true) {
                        $('#manageCustomerStatusDiv').animateCSS('wobble', 1000);
                    }
                    this.fixPartnerInformationLabels();
                });
        }
    };

    public deactivate(): void {
        clearInterval(this.updateTimersTimoutInterval);
    }

    public viewReportingSite(): JQueryPromise<void> {
        return Utils.wrapDfd(this.userService.csrReportingAccess(this.customer().CustomerId()))
            .then(token => {
                window.open(`${this.customer().Partner.ReportingUrl()}/?t=${token}`, '_blank');
            })
            .fail(err => toastr.error(err));
    }

    public loadPartnerSupportLevelName(): any {
        const id = this.partner() ? ko.utils.unwrapObservable(this.partner().SupportLevelId) : 0;
        if (id <= 0) {
            this.partnerSupportLevelName('None');
            return;
        }
        Utils.wrapDfd(this.partnerSupportLevelService.getActive())
            .then((allLevels: any) => {
                const level: any = _.findWhere(allLevels || [], { PartnerSupportLevelId: id });
                this.partnerSupportLevelName(level ? level.Name : 'None');
            }).fail((displayMessage: any) => {
                toastr.error(displayMessage);
            });
    }

    public getCsrEmailAddress(customer: any): any {
        const emailModifier = this.emailModifierConfig[customer.PartnerId()];
        if (emailModifier) {
            return customer.Csr.Username().replace(emailModifier.replaceText, emailModifier.withText);
        } else {
            return customer.Csr.Username();
        }
    }

    public checkIfCustomerIsInPartnerGroup(partnerGroup: any): any {
        return window.launchpad.utils.partnerInPartnerGroup(this.customer().PartnerId(), partnerGroup, this.partnerGroups());
    }

    public getPartnerGroup(partnerGroup: any): any {
        return this.partnerGroups().find((x: any) => x.GroupId === partnerGroup).PartnerGroups.map((x: any) => x.PartnerId);
    }

    public setUpEmailModifier(): void {
        // For all Thryv Partners we replace @dexypteam.com with @boostability.com
        _.each(this.getPartnerGroup(window.launchpad.config.PartnerGroupEnum.Thryv),
            (partnerId: any) => {
                this.emailModifierConfig[partnerId] = {
                    replaceText: '@boostability.com',
                    withText: '@dexypteam.com'
                };
            });
    }

    public openCustomerUsersTab(): void {
        this.pageChangeEvent.emit('Customer Contact')
    }

    public navigateToStore(): void {
        this.storeService.sso(this.customer().CustomerId(), '/', `#/customerservice/managecustomer/${this.customer().CustomerId()}`)
            .subscribe(
                url => window.location.href = url,
                err => toastr.error(err)
            ); 
    }

    public createDraft(): void {
        this.customerUserService.getForCustomer(this.customer().CustomerId())
            .pipe(
                switchMap(currentUsers => {
                    this.validateCustomerUsers(currentUsers);
                    return of({});
                }),
                switchMap(() => this.frontService.getPartnerChannel(this.customer().PartnerId())),
                switchMap(channel => {
                    this.validatePartnerChannel(channel);
                    toastr.success('Redirecting to Front');
                    return this.frontService.createDraft(channel.Id, this.customer().CustomerId())
                }),
                switchMap(conversationId => window.open(`${environment.urlConfig.frontAppUrl}/go?link=%2Fopen%2F${conversationId}`, '_blank')),
                catchError(err => throwError(err))
            )
            .subscribe(
                window => {},
                err => {
                    if (err === 'No To contact found') {
                        this.modalService.open(this.draftValidationModal);
                    }
                    else {
                        toastr.error(err);
                    }
                }
            );
    }

    private validateCustomerUsers(customerUsers: Boo.Objects.CustomerUser[]): void {
        if (customerUsers.filter((x: Boo.Objects.CustomerUser) => x.EmailRecipientType == Boo.Objects.Enums.EmailRecipientTypes.To && x.CanContact).length === 0) {
            throw 'No To contact found';
        }
    }

    private validatePartnerChannel(channel: app.tsmodels.interfaces.FrontChannel): void {
        if (!channel) {
            throw 'No Front channel set for this partner, please contact support.';
        }
    }

    public setContactEmail() {
        this.contact = this.customer().Users().find((x: CustomerUserObservable) => x.EmailRecipientType() == Boo.Objects.Enums.EmailRecipientTypes.To && x.CanContact()) ?? this.customer().Users().find((x: CustomerUserObservable) => x.CanContact());
        let recipientCount = this.customer().Users()
            .filter((x: CustomerUserObservable) => x.EmailRecipientType() != null && x.CanContact()).length;
        this.emailContactLabel = this.contact?.Username() + (recipientCount < 2 ? '' : ' (+' + (recipientCount - 1) + ')');
    }
}
