import { Component, Inject, OnInit } from '@angular/core';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { InternalActionToken } from '../../internal-action.token';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';

@Component({
    selector: 'app-components-specialist-internal-tasks-blog-build',
    templateUrl: './blog-build.component.html'
})
export class BlogBuildComponent implements OnInit, UnlockableWorkspace, ClosableWorkspace {
    showUnlockButton: boolean = true;
    showCloseButton: boolean = true;
    url: string;

    constructor(@Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction) { }

    ngOnInit() {
        this.url = `blogs://${this.internalAction.InternalTask.InternalTaskId}`;
    }
}
