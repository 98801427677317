import { Component } from '@angular/core';
import System from 'framework/System';
import ITodoListObservable = app.tsmodels.interfaces.ITodoListObservable;
import ITodoObservable = app.tsmodels.interfaces.ITodoObservable;
import TodoStatus = Boo.Objects.Todo.Enums.TodoStatus;
import ITodoGroupObservable = app.tsmodels.interfaces.ITodoGroupObservable;
import ITodoNoteObservable = app.tsmodels.interfaces.ITodoNoteObservable;
import IValidatedResult = app.interfaces.IValidatedResult;
import IValidationPublicApi = app.interfaces.IValidationPublicApi;
import TodoListService from 'app/services/TodoListService';

@Component({
	selector: 'app-components-todo-todolistlegacy',
	templateUrl: './TodoListLegacy.component.html'
})
export class TodoListLegacyComponent {
    public selectedTodo: KnockoutObservable<ITodoObservable>;
    public todoGroups: KnockoutObservableArray<ITodoGroupObservable>;
    public todoLists: KnockoutObservableArray<ITodoListObservable>;
    public todos: KnockoutObservableArray<ITodoObservable>;
    public todoModalId: string;
    public validationApi: IValidationPublicApi;
    public userId: number;
    public isCustomerTodo: KnockoutObservable<boolean> = ko.observable(false);

    protected todoListService: TodoListService = new TodoListService();

    public activate(params: ITodoListViewModelActivationParams): JQueryPromise<void> {

        this.todoLists = params.todoLists;
        this.userId = params.userId;
        this.isCustomerTodo(!!params.isCustomerTodo);
        this.todoModalId = _.uniqueId('todoModal_');
        this.validationApi = params.validationApi;
        this.selectedTodo = ko.observable();

        this.todos = ko.observableArray(_.chain(this.todoLists())
            .map((x: ITodoListObservable) => { return x.Todos(); })
            .map((y: ITodoObservable[]) => { return y; })
            .flatten()
            .sortBy((z: ITodoObservable) => { return z.SortOrder(); })
            .value());

        this.todoGroups = ko.observableArray([]);

        let tempTodoGroups = _.chain(this.todos())
            .filter(todo => !!todo.TodoGroupId())
            .map(todo => ko.utils.unwrapObservable(todo.TodoGroup))
            .uniq(todoGroup => todoGroup.TodoGroupId())
            .value();

        if (tempTodoGroups.length > 0) {
            let configs = ko.mapping.toJS(_.chain(this.todoLists())
                .map((x: ITodoListObservable) => { return x.TodoGroupConfigurations; })
                .flatten()
                .value());

            let sortArray = _.chain(configs)
                .groupBy('TodoGroupId')
                .map((value, key) => {
                    return {
                        'todoGroupId': Number(key),
                        'sortOrder': _.chain(value)
                            .pluck('SortOrder')
                            .reduce((a: number, b: number) => { return a + b; }, 0)
                            .value()
                    };
                })
                .sortBy('sortOrder')
                .pluck('todoGroupId')
                .value();

            this.todoGroups(tempTodoGroups.sort((a,b) => sortArray.indexOf(a.TodoGroupId()) - sortArray.indexOf(b.TodoGroupId())));
        }

        if (!params.skipValidation) {
          this.todoListService.attachValidationRules(this.todoLists);
        }

        if (this.validationApi) {
            this.validationApi.add(
                {
                    isValid: this.isValid.bind(this),
                    validate: this.validate.bind(this)
                },
                this.todoModalId);
        }

        return System.emptyPromise();
    }

    public isValid(): boolean {
        return ko.validation.group(this.todos(), { deep: true })().length === 0;
    }

    public validate(): JQueryPromise<IValidatedResult> {
        let errors: KnockoutValidationErrors = ko.validation.group(this.todos(), { deep: true });
        if (errors().length > 0) {
            errors.showAllMessages(true);
        }

        return System.resolvedPromise<IValidatedResult>({ isValid: this.isValid(), errorMessages: errors().map((message: KnockoutObservable<string>) => { return message(); }) });
    }

    public todoNoteCount(todo: ITodoObservable): number {
        return _.filter(todo.TodoNotes(), (note: ITodoNoteObservable) => {
            return note.InsertedUserId() !== launchpad.config.systemAdminUserId;
        }).length;
    }

    public toggleTodo(todo: ITodoObservable): void {
        todo.Status(todo.Status() === TodoStatus.Incomplete || todo.Status() === TodoStatus.Complete ? TodoStatus.New : TodoStatus.Complete);
        todo.StatusDate(moment().utc().toDate());
        todo.StatusUserId(this.userId);
    }

    public markTodoIncomplete(todo: ITodoObservable): void {
        todo.Status(TodoStatus.Incomplete);
        todo.StatusUserId(this.userId);
        $('#' + this.todoModalId).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        this.selectedTodo(todo);
    }

    public openTodo(todo: ITodoObservable): void {
        $('#' + this.todoModalId).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        this.selectedTodo(todo);
    }

    public hasNewNote(todoNotes: KnockoutObservableArray<ITodoNoteObservable>): boolean {
        return _.some(todoNotes(), (todoNote: ITodoNoteObservable) => {
            return todoNote.IsEditable();
        });
    }
}

export interface ITodoListViewModelActivationParams {
    todoLists: KnockoutObservableArray<ITodoListObservable>;
    userId: number;
    validationApi?: IValidationPublicApi;
    skipValidation?: boolean;
    isCustomerTodo: boolean;
}
