<div *withPermissions="let allow of ['IsLaunchPadAdmin']">
  <!-- THIS IS A PRIORITY VIEW -->
  <div class="card" style="margin-bottom: 0px;">
    <div [loadingOverlay]="isLoading">
      <div class="card-header">
        <span>New Country And Language</span>
        <button (click)="cancel()" class="btn-close" title="Close"></button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <label class="control-label">Country</label>
            <select class="form-select" [(ngModel)]="selectedCountry">
              <option *ngFor="let country of filteredCountries" [ngValue]="country">{{country.Name}}</option>
            </select>
          </div>
          <div class="col-md-6">
            <label class="control-label">Language</label>
            <select class="form-select" [(ngModel)]="selectedLanguage">
              <option *ngFor="let language of filteredLanguages" [ngValue]="language">{{language.Name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-footer text-end">
        <button (click)="cancel()" class="btn btn-link">Cancel</button>
        <button (click)="save()" class="btn btn-success">
          <i class="fa fa-save"></i>
          Save
        </button>
      </div>
    </div>
  </div>
</div>
