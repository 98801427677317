<div>
  <ng-container *ngIf="isEnabled">
    <app-components-togglepanel [(isOpen)]="isOpen" title="Review">
      <ng-container *ngIf="!isOpen">
        <div class="row">
          <div class="col-md-6">
            <ul class="list-group">
              <li class="list-group-item">
                <strong>Company Name</strong>
                <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                  class="float-end">{{customer.Name()}}</span>
              </li>
              <li class="list-group-item">
                <strong>Website</strong>
                <a [externalLink]="{ url: customer.Url, text: customer.Url}" class="float-end" rel="noreferrer"></a>
                <ng-container *ngIf="canResetWebsite">
                  <a (click)="resetCustomerWebsite()" class="float-end" href="javascript:void(0)"
                    title="Reset Customer Website (Save Ticket)">
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                </ng-container>
              </li>
              <li class="list-group-item">
                <strong class="mb-2">Vertical/Category</strong>
                <ng-select [formControl]="verticalCtrl" [items]="verticals" placeholder="Choose..." [clearable]="false"
                  bindValue="VerticalId" bindLabel="Name">
                </ng-select>
              </li>
              <li class="list-group-item">
                <strong>Minutes</strong>
                <span class="float-end">{{customer.SeoMinutesTotal()| seoMinutes}}</span>
              </li>
              <li class="list-group-item">
                <app-components-recentorders [orders]="ordersSnapshot"></app-components-recentorders>
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <ul class="list-group">
              <li class="list-group-item">
                <div class="row">
                  <strong class="col-md-4">Company Email</strong>
                  <div class="col-md-8">
                    <a [attr.href]="'mailto:' + customer.Email()" class="float-end">
                      <i class="fa fa-envelope"></i>
                      <span>{{customer.Email()}}</span>
                    </a>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <strong>Company Phone</strong>
                <span class="float-end"><shared-components-clicktocall [Phone]="customer.Phone()"
                    [CountryCode]="customer.Country.Abbreviation()"></shared-components-clicktocall></span>
              </li>
              <ng-container *ngIf="customerUser">
                <li class="list-group-item">
                  <strong>Contact Name</strong>
                  <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                    class="float-end">{{customerUser.FirstName() + ' ' + customerUser.LastName()}}</span>
                </li>
                <li class="list-group-item">
                  <strong>Contact Username/Email</strong>
                  <a [attr.href]="'mailto:' + customerUser.Username()" class="float-end">
                    <i class="fa fa-envelope"></i>
                    <span>{{customerUser.Username()}}</span>
                  </a>
                </li>
                <li class="list-group-item">
                  <strong>Contact Phone</strong>
                  <span class="float-end"><shared-components-clicktocall [Phone]="customerUser.PrimaryPhoneNumber()"
                      [CountryCode]="customer.Country.Abbreviation()"></shared-components-clicktocall></span>
                </li>
                <li class="list-group-item">
                  <compose Selector="app-components-managecustomer-customercontactpreferences" [ActivateOptions]="{
                                           customerId: customer.CustomerId,
                                           isReadOnly: false
                                        }"></compose>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isOpen">
        <compose Selector="app-components-managecustomer-customerinformationlimited" [ActivateOptions]="{
                parent: customerInformationControlParent,
                page: 'EditTicket',
                customerSavedCallback: customerSavedCallback.bind(this),
                canResetWebsite: canResetWebsite,
                ordersSnapshot: ordersSnapshot,
                triggerTicketSave: triggerTicketSave
             }"></compose>
      </ng-container>
    </app-components-togglepanel>
  </ng-container>
</div>
