<div [loadingOverlay]="isLoading()">
  <div class="row">
    <div [hidden]="!targetKeywords" class="col-md-4">
      <div class="form-group">
        <label class="control-label">Target Keywords</label>
        <ul class="list-group">
          <ng-container *ngFor="let $data of targetKeywords">
            <li class="list-group-item list-group-item-small cursor-pointer">
              <span>{{$data}}</span>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <ng-container *ngIf="currentAction()">
          <label class="control-label">Citation Url</label>
          <div class="input-group">
            <input [(ngModel)]="currentAction().Task.TaskActionUrl"
              [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"
              readonly="readonly" />
            <button (click)="openTaskActionUrl()" class="btn btn-primary btn-file">
              <i class="fas fa-external-link-square-alt"></i>
              Open
            </button>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label class="control-label">Task Action Url</label>
        <input [dataValidation]="{ placement: 'top', value: taskActionUrl }" [ngModel]="taskActionUrl()"
          (ngModelChange)="taskActionUrl($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text"
          class="form-control" name="taskActionUrl" />
      </div>
    </div>
  </div>
  <hr />
  <div>
    <ng-container *ngIf="localProfile">
      <app-components-shared-local-profiles [customer]="customer" [canAddLocalProfile]="false" [localProfileId]="localProfile.LocalProfileId"
        [readOnly]="true" [showRegistration]="true"></app-components-shared-local-profiles>
    </ng-container>
  </div>
  <div class="card">
    <div class="card-header card-header-small">
      Files
    </div>
    <div class="card-body">
      <div class="col-md-12">
        <app-table [items]="files" [exportFilename]="'classified-business-listings-files'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="Name">Name</th>
              <th export sort field="Comment">Comment</th>
              <th export sort field="InsertedDate">Upload Date</th>
              <th export field="S3Url"></th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-file>
            <tr>
              <td>{{file.Name}}</td>
              <td>
                <span [hidden]="!(file.Comment)" [ngbPopover]="file.Comment" popoverTitle="Comment"
                  placement="right auto" triggers="mouseenter:mouseleave">
                  <i class="far fa-file-alt"></i>
                  (Hover for Comment)
                </span>
              </td>
              <td>{{file.InsertedDate | dateText }}</td>
              <td style="width:50px;">
                <a [externalLink]="{ url: file.S3Url, text:'View File'}" class="btn btn-primary"></a>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
	<app-floating-panels>
		<app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
			<app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="currentAction().TaskDetails.TaskNotes">
			</app-components-specialist-actioncomponents-comments>
		</app-floating-panel>
	</app-floating-panels>
</div>