import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-components-customchoicelegacy',
  templateUrl: './customChoiceLegacy.component.html'
})
export class CustomChoiceLegacyComponent {
  @Input()
  public Settings: { value: KnockoutObservable<boolean>, disable?: KnockoutObservable<boolean> | boolean, choiceTrue?: string, choiceFalse?: string };

  public get disable(): boolean {
    return ko.utils.unwrapObservable(this.Settings.disable);
  }
}
