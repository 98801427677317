<div class="card">
	<div class="card-header">
		<span>{{title}}</span>
		<button (click)="close()" class="btn-close" title="Close"></button>
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body">
		<div class="row">
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-12">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<ng-container *ngIf="partner()">
										<label class="control-label">Partner</label>
										<input [(ngModel)]="partner().Name" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" readonly="readonly"/>
									</ng-container>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="headingSelection" class="control-label">Headings</label>
							<select [ngModel]="userSelectedPartnerPricingToolHeadingGroupIds()" (ngModelChange)="userSelectedPartnerPricingToolHeadingGroupIds($event)" [ngModelOptions]="{ standalone: true }" select2 multiple="multiple" class="form-control" name="headingSelection">
								<option *ngFor="let option of partnerPricingToolHeadingGroups()" [ngValue]="option.PartnerPricingToolHeadingGroupId">{{ option.Name }}</option>
							</select>
						</div>
						<div class="form-group">
							<button (click)="savePartnerHeadingGroups()" class="btn btn-primary">
								<i class="fa fa-save"></i>
								Save
							</button>
						</div>
					</div>
				</div>
				<hr/>
				<div class="row">
					<div class="col-md-12">
						<div class="form-group">
							<label class="control-label">Language Options</label>
						</div>
						<table class="table table-bordered table-sm">
							<thead>
								<tr>
									<th>Language</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngFor="let $data of partnerPricingToolDetailsList()">
									<tr>
										<td>{{$data.Language.Name()}}</td>
										<ng-container *ngIf="$data.PartnerPricingToolDetailId() > 0">
											<td width="70%">
												<button (click)="editPartnerPricingToolDetail($data)" style="width:96px;" class="btn btn-primary">
													<i class="fa fa-edit"></i>
													Edit
												</button>
												<button (click)="deletePartnerPricingToolDetail($data)" class="btn btn-danger">
													<i class="fa fa-times"></i>
													Remove
												</button>
											</td>
										</ng-container>
										<ng-container *ngIf="$data.PartnerPricingToolDetailId() <= 0">
											<td width="70%">
												<button (click)="addPartnerPricingToolDetail($data)" style="width:96px;" class="btn btn-primary">
													<i class="fa fa-plus"></i>
													Add
												</button>
											</td>
										</ng-container>
									</tr>
								</ng-container>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card-footer text-end">
		<button (click)="close()" class="btn btn-link">Close</button>
	</div>
</div>
