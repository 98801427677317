<div class="card">
	<div class="card-header">
		<span>Sales Overview</span>
		<button (click)="loadStats()" class="btn btn-primary btn-sm float-end">
			<i class="fas fa-sync"></i>
			Refresh
		</button>
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body">
		<div class="row">
			<div class="col-md-4">
				<h4 class="card card-body bg-light d-block">
					<i class="fa fa-check"></i>
					<span>{{sales()}}</span>
				</h4>
				<h5>Sales Today</h5>
			</div>
			<div class="col-md-4">
				<h4 class="card card-body bg-light d-block">
					<i class="fas fa-calendar-alt"></i>
					<span>{{salesMTD()}}</span>
				</h4>
				<h5>Sales MTD</h5>
			</div>
			<div class="col-md-4">
				<h4 class="card card-body bg-light d-block">
					<i class="far fa-chart-bar"></i>
					<span>{{allTimeSales()}}</span>
				</h4>
				<h5>All Time Sales</h5>
			</div>
		</div>
	</div>
</div>
