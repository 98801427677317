<table *ngIf="showTable" class="table table-bordered" id="default-table">
  <thead>
    <tr>
      <th>Task Name</th>
      <th class="num-col">Minutes Per Task</th>
      <th class="num-col">Qty</th>
      <th class="num-col">Total Minutes</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let $seoWorkDefault of seoWorkDefaults">
      <tr *ngIf="$seoWorkDefault.TaskCount">
        <td>
          <span [ngbPopover]="$seoWorkDefault.TaskType.Description" popoverTitle="Description" placement="left auto" triggers="mouseenter:mouseleave">
            <i class="fa fa-info-circle"></i>
          </span>
          <span>{{ $seoWorkDefault.TaskType.Name }}</span>
        </td>
        <td class="text-center">{{ $seoWorkDefault.TaskType.BillableMinutes }}</td>
        <td class="text-center">{{ $seoWorkDefault.TaskCount }}</td>
        <td class="text-center">{{ ($seoWorkDefault.TaskType.BillableMinutes * $seoWorkDefault.TaskCount) }}</td>
      </tr>
    </ng-container>
    <tr class="whitesmoke">
      <td class="text-end" colspan="3">
        <div class="mb-0">
          Minutes to Reserve
        </div>
      </td>
      <td class="text-center">{{ reservedMinutes }}</td>
    </tr>
    <tr class="whitesmoke">
      <td class="text-end" colspan="3">
        <div *ngIf="!isAllocationValid" class="alert alert-danger mb-0">
          Invalid Minutes: {{ minuteDifferenceMessage }}
          <i class="fas fa-arrow-right"></i>
        </div>
      </td>
      <td class="text-center ">
        <span [ngClass]="{'text-danger': !isAllocationValid}">{{ allocatedMinutes }}</span>/{{ minutes }}
      </td>
    </tr>
  </tbody>
</table>

<div class="alert alert-info" *ngIf="!showTable && !seoWorkDefaults.length && reservedMinutes <= 0">
  No results
</div>
