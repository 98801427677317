export default class TaskHelper {
  public static getCustomName(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.CustomTaskName);
  }

  public static setCustomName(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.CustomTaskName, value, task.TaskId, userId);
  }

  public static getCustomDescription(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.CustomTaskDescription);
  }

  public static setCustomDescription(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.CustomTaskDescription, value, task.TaskId, userId);
  }

  public static getCustomBillableMinutes(task: Boo.Objects.Task): number {
    return parseInt(TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.CustomTaskBillableMinutes), 10);
  }

  public static setCustomBillableMinutes(task: Boo.Objects.Task, value: number, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.CustomTaskBillableMinutes, value as unknown as string, task.TaskId, userId);
  }

  public static getCustomTaskTypeName(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.CustomTaskTaskTypeName);
  }

  public static setCustomTaskTypeName(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.CustomTaskTaskTypeName, value, task.TaskId, userId);
  }

  public static getCustomCreatedByUserId(task: Boo.Objects.Task): number {
    return parseInt(TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.CustomTaskCreatedByUserId), 10);
  }

  public static setCustomCreatedByUserId(task: Boo.Objects.Task, value: number, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.CustomTaskCreatedByUserId, value.toString(), task.TaskId, userId);
  }

  public static getComment(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.Comment);
  }

  public static setComment(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.Comment, value, task.TaskId, userId);
  }

  public static getExternalStatus(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.ExternalStatus);
  }

  public static setExternalStatus(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.ExternalStatus, value, task.TaskId, userId);
  }

  public static setExternalId(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.ExternalId, value, task.TaskId, userId);
  }

  public static getLinkBuildingAccountName(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.LinkBuildingAccountName);
  }

  public static setLinkBuildingAccountName(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.LinkBuildingAccountName, value, task.TaskId, userId);
  }
  
  public static getOnsiteImplementationNoteToReviewer(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.OnsiteImplementationNoteToReviewer);
  }

  public static getPreviouslyCompletedImplementationTaskId(task: Boo.Objects.Task): number {
    return parseInt(TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.PreviouslyCompletedImplementationTaskId), 10);
  }

  public static maybeSetOnsiteImplementationNoteToReviewer(task: Boo.Objects.Task, value: string, userId: number): void {
    if (this.getOnsiteImplementationNoteToReviewer(task) || value) {
      TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.OnsiteImplementationNoteToReviewer, value, task.TaskId, userId);
    }
  }

  public static getOnsiteCopyOrderReason(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.OnsiteCopyOrderReason);

  }
  public static setOnsiteCopyOrderReason(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.OnsiteCopyOrderReason, value, task.TaskId, userId);
  }

  public static getOnsiteCopyOrderReasonOther(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.OnsiteCopyOrderReasonOther);
  }
  public static setOnsiteCopyOrderReasonOther(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.OnsiteCopyOrderReasonOther, value, task.TaskId, userId);
  }

  public static getOnsiteCopyPageType(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.OnsiteCopyPageType);
  }
  public static setOnsiteCopyPageType(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.OnsiteCopyPageType, value, task.TaskId, userId);
  }

  public static getOnsiteCopyImplementationInstructions(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.OnsiteCopyImplementationInstructions);
  }
  public static setOnsiteCopyImplementationInstructions(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.OnsiteCopyImplementationInstructions, value, task.TaskId, userId);
  }

  public static getNumberOfContentPagesCreated(task: Boo.Objects.Task): number {
    return parseInt(TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.NumberOfContentPagesCreated), 10);
  }
  public static setNumberOfContentPagesCreated(task: Boo.Objects.Task, value: number, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.NumberOfContentPagesCreated, value.toString(), task.TaskId, userId);
  }

  public static getWasContactUsPageCreated(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.WasContactUsPageCreated);
  }
  public static setWasContactUsPageCreated(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.WasContactUsPageCreated, value, task.TaskId, userId);
  }

  public static getOnsiteCopyLinkBuildAfterPublish(task: Boo.Objects.Task): boolean {
    return (TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.OnsiteCopyLinkBuildAfterPublish).toLowerCase() === 'true');
  }
  public static setOnsiteCopyLinkBuildAfterPublish(task: Boo.Objects.Task, value: boolean, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.OnsiteCopyLinkBuildAfterPublish, String(value), task.TaskId, userId);
  }

  public static getTaskNotifyOnComplete(task: Boo.Objects.Task): boolean {
    return (TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.TaskNotifyOnComplete).toLowerCase() === 'true');
  }

  public static getTaskActionUrl(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.TaskActionUrl);
  }

  public static getAllowRejectionConfirmation(task: Boo.Objects.Task): boolean {
    return (TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.AllowRejectionConfirmation).toLowerCase() === 'true');
  }

  public static setAllIssuesResolved(task: Boo.Objects.Task, value: boolean, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.AllIssuesResolved, value.toString(), task.TaskId, userId);
  }

  public static getGoogleAnalyticsTag(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.GoogleAnalyticsTag);
  }

  public static setGoogleAnalyticsTag(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.GoogleAnalyticsTag, value, task.TaskId, userId);
  }

  public static getCreateGoogleAnalytics(task: Boo.Objects.Task): boolean {
    return (TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.CreateGoogleAnalytics).toLowerCase() === 'true');
  }

  public static setCreateGoogleAnalytics(task: Boo.Objects.Task, value: boolean, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.CreateGoogleAnalytics, value.toString(), task.TaskId, userId);
  }

  public static getBusinessProfileValidationAndAugmentationLocations(task: Boo.Objects.Task): string {
    return TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.BusinessProfileValidationAndAugmentationLocations);
  }

  public static setBusinessProfileValidationAndAugmentationLocations(task: Boo.Objects.Task, value: string, userId: number): void {
    TaskHelper.setValue(task, Boo.Objects.Enums.KeyEnum.BusinessProfileValidationAndAugmentationLocations, value, task.TaskId, userId);
  }

  public static getRequiresBusinessCitationSubscriptionManagement(task: Boo.Objects.Task): boolean {
    return (TaskHelper.getValue(task.KeyValuePairs, Boo.Objects.Enums.KeyEnum.RequiresBusinessCitationSubscriptionManagement).toLowerCase() === 'true');
  }

 public static taskKvpFactory(taskId: number, userId: number, key: Boo.Objects.Enums.KeyEnum, value: string): Boo.Objects.TaskKeyValuePair {
    return {
      TaskId: taskId,
      KeyValuePair: {
        Key: {
          KeyId: key
        } as Boo.Objects.Key,
        KeyId: key,
        KeyValuePairId: 0,
        Value: value
      },
      InsertedDate: new Date(),
      InsertedUserId: userId,
      KeyValuePairId: 0,
      TaskKeyValuePairId: 0
    };
  }

  private static getValue(taskKeyValuePairs: Boo.Objects.TaskKeyValuePair[], key: Boo.Objects.Enums.KeyEnum): string {
    let kvp = _.find(_.sortBy(taskKeyValuePairs, k => k.InsertedDate).reverse(), x => x.KeyValuePair.KeyId === key);
    return kvp ? kvp.KeyValuePair.Value : '';
  }

  private static setValue(task: Boo.Objects.Task, key: Boo.Objects.Enums.KeyEnum, value: string, taskId: number, userId: number): void {
    if (!task.KeyValuePairs) {
      task.KeyValuePairs = [];
    }

    let kvp = _.find(_.sortBy(task.KeyValuePairs, k => k.InsertedDate).reverse(), x => x.KeyValuePair.KeyId === key);
    if (kvp && kvp.KeyValuePair) {
      kvp.KeyValuePair.Value = value;
    } else {
      const tkvp = this.taskKvpFactory(taskId, userId, key, value);
      task.KeyValuePairs.push(tkvp);
    }
  }
}

