<div [loadingOverlay]="isLoading">
  <ng-container *ngFor="let url of websiteUrls">
    <div class="row mb-3">
      <div class="col-md-2">
        <ng-container *ngIf="url.isOrdered">
          <button class="btn w-100 btn-danger" (click)="url.isOrdered = false">Cancel</button>
        </ng-container>
        <ng-container *ngIf="!url.isOrdered">
          <button class="btn w-100 btn-success" (click)="url.isOrdered = true">Order</button>
        </ng-container>
      </div>
      <div class="col-md-6">
        <input class="form-control" [readOnly]="true" [value]="url.websiteUrl.Url">
      </div>
      <ng-container *ngIf="url.websiteUrl.StatusId === websiteUrlStatuses_Archived">
        <div class="col-md-2 text-danger">
          This URL is not active.
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="url.websiteUrl.Keywords">
      <div class="row mb-4">
        <div class="col-md-6 offset-md-2">
          <ul class="list-group">
            <ng-container *ngFor="let keyword of url.websiteUrl.Keywords">
              <li class="list-group-item">
                {{keyword.KeywordPhrase}}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>