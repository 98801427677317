<div id="connected-users-float-panel-mc" class="float-panel-cu">
	<div [hidden]="!(numberOfConnectedUsers() > 1)" class="float-panel-cu-tab">
		<button class="float-panel-btn-expand btn btn-lg btn-primary">
			<i class="fa fa-user ms-1" style="font-size: 135%;"></i>
			<span class="badge rounded-pill bg-danger" style="font-size:70%; margin-left: -20px">{{numberOfConnectedUsers()}}</span>
		</button>
	</div>
	<div class="float-panel-cu-main card">
		<div class="float-panel-cu-draggable card-header">
			<i class="fa fa-user" style="font-size: 135%;"></i>
			<span class="badge rounded-pill bg-danger" style="font-size:70%; margin-left: -20px">{{numberOfConnectedUsers()}}</span>
			Connected Users
			<i class="float-panel-btn-collapse fa fa-minus float-end"></i>
		</div>
		<div class="card-body">
			<compose Selector="app-components-managecustomer-connectedusers" [ActivateOptions]="{
                            customerId: customerId,
                            onChanged: onChanged.bind(this),
                            scope: 'ManageCustomer'
                             }"></compose>
		</div>
	</div>
</div>
