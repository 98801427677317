import { Component, Input, OnInit } from '@angular/core';
import FulFillmentMethods = Boo.ApisExternal.Store.Enums.FulfillmentMethods;

@Component({
  selector: 'app-components-recentorders',
  templateUrl: './RecentOrders.component.html'
})
export class RecentOrdersComponent implements OnInit {
  @Input() orders: Boo.ApisExternal.Store.Objects.Order[];
  itemGroupings: OrderItemGrouping[] = [];

  ngOnInit(): void {
    this.orders.forEach(order => {
      this.itemGroupings.push(...this.groupItems(order));
    });

    this.itemGroupings.forEach(group => {
      group.total = group.items.map(i => i.Msrp.Amount).reduce((a, b) => a + b, 0);
    });
  }

  private groupItems(parent: Boo.ApisExternal.Store.Objects.Order): OrderItemGrouping[] {
    return parent.Items
      .filter(item => item.FulfillmentMethod !== FulFillmentMethods.StoreInternal)
      .reduce((groupings: OrderItemGrouping[], item: Boo.ApisExternal.Store.Objects.OrderItem) => {
        const key = item.Group ? item.Group.Name : item.Name;
        const group = groupings.find(grp => grp.name === key);
        if (!group) {
          groupings.push({
            name: key,
            items: [item],
            description: item.Group ? item.Group.Description : '',
            total: 0,
            orderDate: parent.OrderDate,
            isOpen: parent.IsOpen
          });
        } else {
          group.items.push(item);
        }
        return groupings;
      }, []);
  }
}

interface OrderItemGrouping {
  name: string;
  description: string;
  items: Boo.ApisExternal.Store.Objects.OrderItem[];
  total: number;
  orderDate: Date;
  isOpen: boolean;
}
