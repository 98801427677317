import { MustacheTag } from "./mustache-tag.model";
import { MustacheTagTypes } from "./mustache-tag-types.model";

export class MustacheArrayTag implements MustacheTag {
  type = MustacheTagTypes.array;
  key: string;
  subKeys: MustacheTag[];
  value: MustacheTag[][];

  constructor(key: string, subKeys: MustacheTag[]) {
    this.key = key;
    this.subKeys = subKeys;
  }
}