import { Component } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { TaskPostService } from '../../../services/taskpost.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-managecustomer-blogposts',
  templateUrl: './BlogPosts.component.html'
})
export class BlogPostsComponent implements IActivatable {
    public blogPosts: Boo.Objects.TaskPost[];

    constructor(private taskPostService: TaskPostService) { }

    public activate(params: any): JQueryPromise<void> {
        return Utils.wrapDfd(this.taskPostService.getForCustomer(ko.utils.unwrapObservable(params.customer.CustomerId), [Boo.Objects.Enums.TaskTypeEnum.CustomArticlePublication, Boo.Objects.Enums.TaskTypeEnum.StandardBacklink]))
          .then((taskPosts: Boo.Objects.TaskPost[]) => {
            this.blogPosts = taskPosts;
          });
    }

    public editBlog(taskPost: Boo.Objects.TaskPost): void {
        PriorityViewModel.show('app-components-managecustomer-editblogpost', { taskPost: taskPost });
    }
}
