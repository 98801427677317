import { Subject } from "rxjs";

export interface DeletableWorkspace {
  isReadyToDelete(): boolean;
  showDeleteButton?: boolean;
  delete?: Subject<void>;
}

export function isDeletable(workspace: DeletableWorkspace): workspace is DeletableWorkspace {
  return workspace.isReadyToDelete !== undefined;
}