<div [loadingOverlay]="isLoading()" class="card">
	<div class="card-header">
		Add Customer
	</div>
	<div class="card-body">
		<ng-container *ngIf="newCustomer">
			<div class="row">
				<div class="col-md-4">
					<h6>Customer Contact</h6>
					<hr>
				</div>
				<div class="col-md-8">
					<h6>Basic Information</h6>
					<hr>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
					<div class="form-group">
						<label class="control-label">First Name</label>
						<input [dataValidation]="{ placement: 'top', value: newCustomer.FirstName }" [ngModel]="newCustomer.FirstName()" (ngModelChange)="newCustomer.FirstName($event)" [ngModelOptions]="{ standalone: true }" class="form-control"/>
					</div>
					<div class="form-group">
						<label class="control-label">Last Name</label>
						<input [dataValidation]="{ placement: 'top', value: newCustomer.LastName }" [ngModel]="newCustomer.LastName()" (ngModelChange)="newCustomer.LastName($event)" [ngModelOptions]="{ standalone: true }" class="form-control"/>
					</div>
					<div class="form-group">
						<label class="control-label">Email</label>
						<input [dataValidation]="{ placement: 'top', value: newCustomer.Email }" [ngModel]="newCustomer.Email()" (ngModelChange)="newCustomer.Email($event)" [ngModelOptions]="{ standalone: true }" class="form-control"/>
					</div>
					<div class="form-group">
						<label class="control-label">Phone</label>
						<input [dataValidation]="{ placement: 'top', value: newCustomer.Phone }" [ngModel]="newCustomer.Phone()" (ngModelChange)="newCustomer.Phone($event)" [ngModelOptions]="{ standalone: true }" class="form-control"/>
					</div>
				</div>
				<div class="col-md-4">
					<div class="form-group">
						<label class="control-label">Company Name</label>
						<input [dataValidation]="{ placement: 'top', value: newCustomer.Company }" [ngModel]="newCustomer.Company()" (ngModelChange)="newCustomer.Company($event)" [ngModelOptions]="{ standalone: true }" class="form-control"/>
					</div>
					<div class="form-group">
						<label class="control-label">Website</label>
						<input [dataValidation]="{ placement: 'top', value: newCustomer.Url }" [ngModel]="newCustomer.Url()" (ngModelChange)="newCustomer.Url($event)" [ngModelOptions]="{ standalone: true }" class="form-control"/>
					</div>
					<div class="form-group">
						<label class="control-label">Language</label>
						<select [dataValidation]="{ placement: 'top', value: newCustomer.LanguageId }" [disabled]="!newCustomer.CountryId()" [ngModel]="newCustomer.LanguageId()" (ngModelChange)="newCustomer.LanguageId($event)" [ngModelOptions]="{ standalone: true }" class="form-select">
							<option [ngValue]="undefined">Choose...</option>
							<option *ngFor="let option of languages" [ngValue]="option.LanguageId">{{ option.Name }}</option>
						</select>
					</div>
					<div class="form-group">
						<label class="control-label">Country</label>
						<select [dataValidation]="{ placement: 'top', value: newCustomer.CountryId }" [ngModel]="newCustomer.CountryId()" (ngModelChange)="OnCountrySelected ($event)" [ngModelOptions]="{ standalone: true }" class="form-select">
							<option [ngValue]="undefined">Choose...</option>
							<option *ngFor="let option of countries" [ngValue]="option.CountryId">{{ option.Name }}</option>
						</select>
					</div>
				</div>
				<div class="col-md-4">
					<div class="form-group">
						<label class="control-label">
							<span>Merchant Id</span>
							<span class="text-muted">(Optional)</span>
							<app-components-helptext [key]="'AddCustomer_MerchantId'"></app-components-helptext></label>
						<input [ngModel]="newCustomer.MerchantId()" (ngModelChange)="newCustomer.MerchantId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
					</div>
					<div class="form-group">
						<label class="control-label">
							<span>Referred By</span>
							<span class="text-muted">(Optional)</span>
						</label>
						<input [ngModel]="newCustomer.ReferredBy()" (ngModelChange)="newCustomer.ReferredBy($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
					</div>
					<ng-container *ngIf="partner.PartnerId === 1">
						<div class="form-group">
							<label class="control-label">LeadSource</label>
							<select select2 [ngModel]="newCustomer.LeadSourceId()" (ngModelChange)="newCustomer.LeadSourceId($event)" [ngModelOptions]="{ standalone: true }" class="form-control">
								<option [ngValue]="undefined">Choose...</option>
								<option *ngFor="let option of leadSources" [ngValue]="option.LeadSourceId">{{ option.Name }}</option>
							</select>
						</div>
					</ng-container>
					<ng-container *ngIf="partner.PartnerId !== 1">
						<div class="text-end">
							<button (click)="showAdvancedMerchantInfo()" class="btn btn-link">Advanced Merchant Fields</button>
						</div>
					</ng-container>
				</div>
			</div>
			<div [hidden]="!viewAdvancedMerchantInfo()" class="row">
				<hr/>
				<div class="col-md-6">
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().IdLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantId()" (ngModelChange)="newCustomer.MerchantId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().SourceLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantSource()" (ngModelChange)="newCustomer.MerchantSource($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().ProposalIdLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantProposalId()" (ngModelChange)="newCustomer.MerchantProposalId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().CategoryLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantCategory()" (ngModelChange)="newCustomer.MerchantCategory($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().CategoryIdLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantCategoryId()" (ngModelChange)="newCustomer.MerchantCategoryId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().SubCategoryLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantSubCategory()" (ngModelChange)="newCustomer.MerchantSubCategory($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().SubCategoryIdLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantSubCategoryId()" (ngModelChange)="newCustomer.MerchantSubCategoryId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().ConsultantLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantConsultant()" (ngModelChange)="newCustomer.MerchantConsultant($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().ConsultantContactLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantConsultantContact()" (ngModelChange)="newCustomer.MerchantConsultantContact($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().UdacLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantUdac()" (ngModelChange)="newCustomer.MerchantUdac($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().ProductCodeLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantProductCode()" (ngModelChange)="newCustomer.MerchantProductCode($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().ItemIdLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantItemId()" (ngModelChange)="newCustomer.MerchantItemId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().MiscIdLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantMiscId()" (ngModelChange)="newCustomer.MerchantMiscId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().RegionLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantRegion()" (ngModelChange)="newCustomer.MerchantRegion($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().CustomerValueLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantCustomerValue()" (ngModelChange)="newCustomer.MerchantCustomerValue($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().ContractEndDateLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantContractEndDate()" (ngModelChange)="newCustomer.MerchantContractEndDate($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().ServiceLevelLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantServiceLevel()" (ngModelChange)="newCustomer.MerchantServiceLevel($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().SitePublishDateLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantSitePublishDate()" (ngModelChange)="newCustomer.MerchantSitePublishDate($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control" placeholder="MM-DD-YYYY"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().CampaignManagerLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantCampaignManager()" (ngModelChange)="newCustomer.MerchantCampaignManager($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
					<div class="form-group" style="padding-bottom:30px">
						<label class="control-label col-md-5">{{merchantFieldLabelMap().CampaignManagerContactLabel.Value}}</label>
						<div class="col-md-7">
							<input [ngModel]="newCustomer.MerchantCampaignManagerContact()" (ngModelChange)="newCustomer.MerchantCampaignManagerContact($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="card-footer text-end">
		<button (click)="save()" class="btn btn-primary">
			<i class="fa fa-plus"></i>
			Add Customer
		</button>
	</div>
</div>
