import { Component } from '@angular/core';
import Check from 'framework/Check';
import System from 'framework/System';

@Component({
	selector: 'app-components-pager',
	templateUrl: './Pager.component.html'
})
export class PagerComponent implements IActivatable {
    protected stateChangeCallback: (page: number, pageSize: number) => JQueryPromise<void>;
    protected page: KnockoutObservable<number>;
    public pageSize: KnockoutObservable<number>;
    protected pageSizeOptions: number[];
    protected maxPageLinks: number;
    protected totalItems: KnockoutObservable<number>;

    public activate(params: app.components.interfaces.IPagerActivateParams): JQueryPromise<void> {
        Check.isTrue(_.isFunction(params.apiCallback), 'params.apiCallback must be a function');
        Check.isTrue(_.isFunction(params.stateChangeCallback), 'params.stateChangeCallback must be a function');

        let originalPageSize = Math.max(1, params.pageSize || 10);

        this.stateChangeCallback = params.stateChangeCallback;
        this.totalItems = ko.observable(0);
        this.page = ko.observable(1);
        this.pageSize = ko.observable(originalPageSize);
        this.pageSizeOptions = params.pageSizeOptions || [5, 10, 25, 50, 100];
        this.maxPageLinks = Math.max(3, params.maxPageLinks || 5);

        // configure page options to include original pageSize
        // make a copy so we don't mess up a passed in array
        if (this.pageSizeOptions.indexOf(originalPageSize) === -1) {
            this.pageSizeOptions = [originalPageSize, ...this.pageSizeOptions];
            this.pageSizeOptions = this.pageSizeOptions.sort((a, b) => a - b);
        }

        params.apiCallback(this.getPublicApi());

        return System.emptyPromise();
    }

    public getPageSize(): number {
        return this.pageSize();
    }

    public setPageSize(size: number): JQueryPromise<void> {
        let newSize = Math.max(0, size);
        if (newSize === this.pageSize()) {
            return System.emptyPromise();
        }

        this.page(1); // reset to first page
        this.pageSize(newSize);
        return this.notifyStateChange();
    }

    public getTotalItems(): number {
        return this.totalItems();
    }

    public getTotalPages(): number {
        return this.pageSize() > 0 ? Math.max(1, Math.ceil(this.totalItems() / this.pageSize())) : 1;
    }

    public getPage(): number {
        return this.page();
    }

    public setPage(pageNumber: number): JQueryPromise<void> {
        let newPage = Math.min(this.getTotalPages(), Math.max(1, pageNumber));
        if (newPage === this.page()) {
            return System.emptyPromise();
        }

        this.page(newPage);
        return this.notifyStateChange();
    }

    public prevPage(): JQueryPromise<void> {
        return this.setPage(this.page() - 1);
    }

    public nextPage(): JQueryPromise<void> {
        return this.setPage(this.page() + 1);
    }

    public isFirstPage(): boolean {
        return this.page() === 1;
    }

    public isLastPage(): boolean {
        return this.page() === this.getTotalPages();
    }

    public getPageSizeOptions(): number[] {
        return this.pageSizeOptions;
    }

    /**
     * Gets an array of page numbers based on centering the current page and adding page links up to maxPageLinks.
     * If you have an even maxPageLinks, you may get maxPageLinks-1 so that the current page can be centered.
     * Null values are used as placeholders when a range is missing.
     * 
     * Below are some examples for maxPageLinks=5 (current page is in *asterisks*):
     * 
     * [*1*, 2, 3, 4, 5, null, 10]
     * [1, 2, 3, *4*, 5, null, 10]
     * [1, 2, 3, *4*, 5, null, 10]
     * [1, null, 5, 6, *7*, 8, 9, 10]
     * [1, null, 6, 7, 8, 9, *10*]
     * 
     * @returns (number|null)[]
     */
    public getPageLinks(): number[] {
        let page = this.page();
        let totalPages = this.getTotalPages();
        let radius = Math.max(1, Math.floor(this.maxPageLinks % 2 === 0 ? (this.maxPageLinks - 1) / 2 : this.maxPageLinks / 2));
        let firstLink = Math.max(1, Math.min(totalPages - this.maxPageLinks + 1, page - radius));
        let lastLink = Math.min(totalPages, Math.max(this.maxPageLinks, page + radius));
        let links = lastLink > firstLink ? _.range(firstLink, lastLink + 1) : [firstLink];

        // if first link is not 1, add 1 and either 2 or a placeholder/null
        if (firstLink !== 1) {
            if (firstLink > 2) {
                links.unshift(null);
            } else if (firstLink !== 2) {
                links.unshift(2);
            }
            links.unshift(1);
        }

        // if last link is not totalPages, add totalPages and either totalPages-1 or a placeholder/null
        if (lastLink !== totalPages) {
            if (lastLink < totalPages - 1) {
                links.push(null);
            } else if (lastLink !== totalPages - 1) {
                links.push(totalPages - 1);
            }
            links.push(totalPages);
        }

        return links;
    }

    protected notifyStateChange(): JQueryPromise<void> {
        let currentPage = Math.max(1, this.page());
        let pageSize = Math.max(1, this.pageSize());

        return this.stateChangeCallback(currentPage, pageSize);
    }

    protected setState(totalItems: number, page?: number, pageSize?: number): void {
        // the order we set things matter because totalItems, pageSize, and page are related
        this.totalItems(Math.max(0, totalItems));
        
        if (_.isNumber(pageSize)) {
            this.pageSize(Math.max(1, pageSize));
        }

        if (_.isNumber(page)) {
            this.page(Math.min(this.getTotalPages(), Math.max(1, page)));
        }
    }

    protected getPublicApi(): app.components.interfaces.IPagerPublicApi {
        return {
            getPage: (): number => this.getPage(),
            getPageSize: (): number => this.getPageSize(),
            setState: (totalItems: number, page?: number, pageSize?: number): void => this.setState(totalItems, page, pageSize)
        };
    }
}
