<div class="card">
	<div class="card-header">
		<ul navigationHandler class="nav nav-pills">
			<li class="nav-item">
				<a href="/#/admin/verticalmanagement" class="nav-link">
					<i class="fa fa-road"></i>
					Product Tools
				</a>
			</li>
			<li class="nav-item">
				<a href="/#/admin/applicationmanagement" class="nav-link">
					<i class="fa fa-code"></i>
					Dev Tools
				</a>
			</li>
		</ul>
	</div>
	<div [slideVisible]="showDevToolsSubMenu" class="card-footer" style="display: none;">
		<ul navigationHandler class="nav nav-pills">
			<li class="nav-item">
				<a href="/#/admin/applicationmanagement" class="nav-link">
					<i class="fas fa-link"></i>
					Application Management
				</a>
			</li>
			<li class="nav-item">
				<a href="/#/admin/internaltaskhistory" class="nav-link">
					<i class="fas fa-link"></i>
					Workflow Internal Tasks
				</a>
			</li>
			<li class="nav-item">
				<a href="/#/admin/workflows" class="nav-link">
					<i class="fas fa-code-branch"></i>
					Workflows
				</a>
			</li>
			<li class="nav-item">
				<a href="/#/admin/translations" class="nav-link">
					<i class="fas fa-link"></i>
					Translation Management
				</a>
			</li>
			<li class="nav-item">
				<a href="/#/admin/partnerusers" class="nav-link">
					<i class="fas fa-link"></i>
					Partner Users
				</a>
			</li>
		</ul>
	</div>
	<div [slideVisible]="showProductToolsSubMenu" class="card-footer" style="display: none;">
		<ul navigationHandler class="nav nav-pills">
			<li class="nav-item">
				<a href="/#/admin/verticalmanagement" class="nav-link">
					<i class="fa fa-cog"></i>
					Vertical Management
				</a>
			</li>
			<li class="nav-item">
				<a href="/#/admin/helptexteditor" class="nav-link">
					<i class="fas fa-link"></i>
					Help Text Editor
				</a>
			</li>
			<li class="nav-item">
				<a href="/#/admin/countryandlanguagemanagement" class="nav-link">
					<i class="fas fa-globe"></i>
					Country/Language
				</a>
			</li>
		</ul>
	</div>
</div>
