<ng-template #content>
  <pre style="all: unset; font-family: 'Tauri', Helvetica; font-size: 14px; word-break: break-word;">{{helpText?.Content||''}}</pre>
</ng-template>

<ng-template #modalContent let-modal>
  <app-components-helptexteditor [keyFilter]="helpText?.Key" [keyFilterInputVisible]="false" (saved)="modal.dismiss()" (canceled)="modal.dismiss()"></app-components-helptexteditor>
</ng-template>

<ng-container *withPermissions="let allow of ['CanEditHelpText']">
  <i *ngIf="allow().CanEditHelpText() || helpText?.Title || helpText?.Content" triggers="mouseenter:mouseleave" class="fa fa-question-circle" [class.fa-no-margin]="noMargin" [ngbPopover]="content" [popoverTitle]="helpText?.Title" placement="top auto"></i>
  <i *ngIf="allow().CanEditHelpText()" (click)="openModal(modalContent);" class="btn-link fas fa-pencil-alt" ></i>
</ng-container>