/* tslint:disable */

import { Component } from '@angular/core';
import permissions from 'app/models/Permissions';
import { PartnerUserService } from '../../../services/partner-user.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-components-customerservice-assigntickettypes',
	templateUrl: './AssignTicketTypes.component.html'
})
export class AssignTicketTypesComponent {
  public isLoading: any = ko.observable(false);
  public selectedTicketTypes: any = ko.observableArray([]);
  public selectedTeams: any = ko.observableArray([]);
  public teams: any = ko.observableArray([]);
  public ticketTypes: any = ko.observableArray([]);

  public canActivate(user: any, partner: any): any {
    return window.launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanMassAssignTicketTypes, user);
  }

  constructor(
    private partnerUserService: PartnerUserService,
    private sessionStorageService: SessionStorageService) { }

  public activate(): void {
    forkJoin([
      this.sessionStorageService.getStaticData(),
      this.sessionStorageService.getPartnerUsers(),
      this.sessionStorageService.getUser(),
      this.sessionStorageService.getPartner()
    ])
    .subscribe(([staticData, partnerUsers, user, partner]) => {
        const hasAllTeams = launchpad.hasPermission(partner, partnerUsers, permissions.HasAllTeams, user);
        if (hasAllTeams) {
          this.teams(staticData.Teams);
        } else {
          let allMyTeams: any = [];
          _.each(partnerUsers,
            (pu: any) => {
              if (_.isArray(pu.Teams)) {
                allMyTeams = allMyTeams.concat(pu.Teams);
              }
            });
          const teamIds = _.pluck(allMyTeams, 'TeamId');
          this.teams(_.filter(staticData.Teams,
            (team: any) => {
              return _.contains(teamIds, team.TeamId);
            }));
        }
        const hasAllTicketTypes = launchpad.hasPermission(partner, partnerUsers, permissions.HasAllTicketTypes, user);
        if (hasAllTicketTypes) {
          this.ticketTypes(staticData.TicketTypes);
        } else {
          let allMyTicketTypes: any = [];
          _.each(partnerUsers,
            (pu: any) => {
              if (_.isArray(pu.TicketTypes)) {
                allMyTicketTypes = allMyTicketTypes.concat(pu.TicketTypes);
              }
            });
          const ticketTypeIds = _.pluck(allMyTicketTypes, 'TicketTypeId');
          this.ticketTypes(_.filter(staticData.TicketTypes,
            (ticketType: any) => {
              return _.contains(ticketTypeIds, ticketType.TicketTypeId);
            }));
        }
      }, (err) => {
        throw new Error('Cannot get static data.');
      });
  }

  public assignTicketTypes(): void {
    if (_.isArray(this.selectedTeams()) && _.isArray(this.selectedTicketTypes()) && this.selectedTeams().length > 0 && this.selectedTicketTypes().length > 0) {
      bootbox.confirm('Are you sure you want to assign these ticket types? This action cannot be undone.',
        (result: any) => {
          if (result === true) {
            this.isLoading(true);

            Utils.wrapDfd(this.partnerUserService.assignTicketTypesByTeamId(this.selectedTeams(), this.selectedTicketTypes()))
              .then(() => {
                toastr.success('Ticket Type Assignments have been made.');
              }).fail((displayMessage) => {
                toastr.error(displayMessage);
              }).always(() => {
                this.isLoading(false);
              });
          }
        });
    } else {
      toastr.error('Please select at least one Team and one Ticket Type');
    }
  }
}
