import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AddComponent } from './Add/Add.component';
import { ListComponent as ListComponent1 } from './List/List.component';
import { MoneyBackGuaranteeComponent } from './MoneyBackGuarantee/MoneyBackGuarantee.component';
import { SingleComponent } from './Single/Single.component';


@NgModule({
  declarations: [
    AddComponent,
    ListComponent1,
    MoneyBackGuaranteeComponent,
    SingleComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class GuaranteesModule { }
