import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PageSuggestion } from '../shared/models/keywords/keywords';

@Injectable({
  providedIn: 'root'
})
export class KeywordResearchService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/keywordresearch`;

  constructor(private http: HttpClient) { }

  get(customerId: number): Observable<PageSuggestion[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<PageSuggestion[]>(`${this.baseUrl}`, { params: params })
  }

  save(customerId: number, pageSuggestions: PageSuggestion[], isCampaignNational?: boolean): Observable<any> {
    return this.http.post(`${this.baseUrl}/save`, {
      customerId: customerId,
      pageSuggestions: pageSuggestions,
      isCampaignNational: isCampaignNational
    });
  }

  findAreas(input: string): Observable<Boo.GooglePlaces.PlaceDescription[]> {
    let params = new HttpParams().set('input', input);
    return this.http.get<Boo.GooglePlaces.PlaceDescription[]>(`${this.baseUrl}/findAreas`, { params: params });
  }

  redo(customerId: number, isCampaignNational: boolean, placeId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/redo`, {
      customerId: customerId,
      isCampaignNational: isCampaignNational,
      placeId: placeId
    });
  }
}
