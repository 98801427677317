<ng-container *ngIf="addingPage; else notAddingPage">
  <div class="row justify-content-end">
    <div class="col">
      <div class="input-group">
        <span class="input-group-text">{{ customer.Url }}</span>
        <input #pageInput type="text" class="form-control" [formControl]="pageControl" [formValidation]="pageControl" (keyup.enter)="addPage()" />
      </div>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary" (click)="addPage()"><i class="fas fa-plus"></i>Page</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-warning" (click)="toggleAddingPage(true)">Cancel</button>
    </div>
  </div>
</ng-container>

<ng-template #notAddingPage>
  <button class="btn btn-primary btn-sm" type="button" data-toggle="dropdown">
    <i class="fas fa-plus"></i>Page
  </button>
  <ul class="dropdown-menu" role="menu">
    <li>
      <button class="btn-link dropdown-item" (click)="toggleAddingPage(true)">Existing Page</button>
    </li>
    @if (false) { <!-- Hidden for initial release -->
      <li>
        <button class="btn-link dropdown-item" (click)="toggleAddingPage(false)">New Page with Url</button>
      </li>
    }
    <li>
      <button class="btn-link dropdown-item" (click)="addStub()">New Page</button>
    </li>
    @if (availablePageSuggestions.length) {
      <div class="dropdown-divider"></div>
      <h6 class="dropdown-header">Recommended Pages</h6>
      <ng-container *ngFor="let pageSuggestion of availablePageSuggestions | orderBy: pageSort">
        @if (!pageSuggestion.IsSelected) {
          <li>
            <button class="btn-link dropdown-item" (click)="addPageSuggestion(pageSuggestion)">
              @if (pageSuggestion.Priority) {
                <small><i class="text-primary fas fa-star" title="Recommended Page"></i></small>
              }
              {{ pageSuggestion.Url }}
            </button>
          </li>
        }
      </ng-container>
    }
  </ul>
</ng-template>