import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import IValidatedResult = app.interfaces.IValidatedResult;
import Responsibilities  = Boo.OnsiteRecommendations.Models.Enums.Responsibilities;
import Grid from 'app/components/Grid';
import permissions from 'app/models/Permissions';
import { SessionStorageService } from '../../../services/session-storage.service';
import System from 'framework/System';
import timer from 'app/managecustomer/Timer';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import Utils from '../../../shared/utils';
import OnsiteRecommendationDetail from 'app/models/typescript/OnsiteRecommendationDetail';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { OnsiteRecommendationDetailService } from '../../../services/onsite-recommendation-detail.service';
import { finalize, forkJoin } from 'rxjs';
import { WorkRequestService } from '../../../services/work-request.service';
import WorkRequestHelper from 'app/managecustomer/WorkRequestHelper';
import { SeoWorkService } from '../../../services/seo-work.service';
import { onsiteBloggingOrderingContentOnlyTaskTypes, onsiteBloggingOrderingWithImplementationTaskTypes, onsiteBloggingOrderTaskTypes } from 'app/models/collections/TaskTypeCollections';
import CustomValidators from '../../../shared/custom-validators';
import { PermissionService } from '../../../services/permission.service';
import { CustomerNotificationService } from '../../../services/customer-notification.service';

@Component({
    selector: 'app-components-publishoptions',
    templateUrl: './PublishOptions.component.html'
})
export class PublishOptionsComponent implements OnInit {
    @Input() customer: CustomerObservable;
    @Output() responsibilityChanged: EventEmitter<Responsibilities> = new EventEmitter<Responsibilities>();
    onsiteRecommendationDetail: OnsiteRecommendationDetail;
    customerId: number;
    partnerId: number;
    allowsCustomerContact: boolean;
    grid: Grid<ICompositionRoute>;
    isLoading: boolean;
    isSendAutomaticallyChecked: boolean;
    pageValidation: any;
    showStatuses: boolean;
    recommendationForm: UntypedFormGroup;
    contactForm: UntypedFormGroup;
    technicalContactForm: UntypedFormGroup;
    currentUser: Boo.Objects.User;
    validResponsibilities: Boo.OnsiteRecommendations.Models.Enums.Responsibilities[] = [
        Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Customer,
        Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Boostability,
        Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Partner
    ];
    private canEditOnsiteRecommendations: boolean;

    get isUpdateInformationButtonEnabled(): boolean {
        return this.canEditOnsiteRecommendations && !this.isLoading;
    }

    get isPartnerMakingChangesToTheSite(): boolean {
        return this.recommendationForm.get('Responsibility').value === Responsibilities.Partner;
    }

    get isCustomerMakingChangesToTheSite(): boolean {
        return this.recommendationForm.get('Responsibility').value === Responsibilities.Customer;
    }

    get isBoostabilityMakingChangesToTheSite(): boolean {
        return this.recommendationForm.get('Responsibility').value === Responsibilities.Boostability;
    }

    constructor(
        private sessionStorageService: SessionStorageService,
        private onsiteRecommendationDetailService: OnsiteRecommendationDetailService,
        private workRequestService: WorkRequestService,
        private seoWorkService: SeoWorkService,
        private permissionService: PermissionService,
        private customerNotificationService: CustomerNotificationService) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.customerId = this.customer.CustomerId();
        this.partnerId = this.customer.PartnerId();
        this.canEditOnsiteRecommendations = false;
        this.isSendAutomaticallyChecked = true;
        this.showStatuses = false;

        forkJoin([
            this.onsiteRecommendationDetailService.getByCustomerId(this.customerId),
            this.permissionService.hasPermission(permissions.CanEditOnsiteRecommendations),
            this.sessionStorageService.getUser(),
            this.customerNotificationService.CanSendOnsiteChangesActionEmail(this.customer.CustomerId())
        ])
          .pipe(finalize(() => this.isLoading = false))
          .subscribe({
            next: ([details, canEditOnsiteRecommendations, user, canSendOnsiteChanges]) => {
              this.currentUser = user;
              this.canEditOnsiteRecommendations = canEditOnsiteRecommendations;
              this.allowsCustomerContact = canSendOnsiteChanges;
              this.onsiteRecommendationDetail = (details || new OnsiteRecommendationDetail(this.customerId));

              let isTechnicalContactChecked = false;
              let isCustomerContactChecked = false;

              if (!this.allowsCustomerContact||
                this.onsiteRecommendationDetail.ContactTypeId === Boo.OnsiteRecommendations.Models.Enums.ContactTypes.NotSpecified ||
                this.onsiteRecommendationDetail.ContactTypeId === Boo.OnsiteRecommendations.Models.Enums.ContactTypes.Manual) {
                  this.isSendAutomaticallyChecked = false;
                  isTechnicalContactChecked = false;
                  isCustomerContactChecked = false;
              } else if (this.onsiteRecommendationDetail.ContactTypeId === Boo.OnsiteRecommendations.Models.Enums.ContactTypes.TechnicalContactAndCustomerContact) {
                  isTechnicalContactChecked = true;
                  isCustomerContactChecked = true;
              } else if (this.onsiteRecommendationDetail.ContactTypeId === Boo.OnsiteRecommendations.Models.Enums.ContactTypes.TechnicalContact) {
                  isTechnicalContactChecked = true;
              } else if (this.onsiteRecommendationDetail.ContactTypeId === Boo.OnsiteRecommendations.Models.Enums.ContactTypes.CustomerContact) {
                  isCustomerContactChecked = true;
              }

              this.recommendationForm = new UntypedFormGroup({
                  Responsibility: new UntypedFormControl(this.onsiteRecommendationDetail.Responsibility, this.implementationResponsibilityValidator()),
                  Note: new UntypedFormControl(this.onsiteRecommendationDetail.Note, Validators.maxLength(512)),
              });

              this.contactForm = new UntypedFormGroup({
                  customerContactChecked: new UntypedFormControl(isCustomerContactChecked),
                  technicalContactChecked: new UntypedFormControl(isTechnicalContactChecked)
              }, this.contactValidator().bind(this));
              this.contactForm.enable();
              this.recommendationForm.enable();

              this.technicalContactForm = new UntypedFormGroup({
                  technicalContactEmail: new UntypedFormControl(this.onsiteRecommendationDetail.TechnicalContactEmail, [Validators.required, CustomValidators.email]),
                  technicalContactName: new UntypedFormControl(this.onsiteRecommendationDetail.TechnicalContactName, Validators.required),
                  technicalContactPhone: new UntypedFormControl(this.onsiteRecommendationDetail.TechnicalContactPhone, CustomValidators.phoneNumberValidator(() => this.customer.Country.Abbreviation() ?? "US").bind(this))
              });

              this.recommendationForm.get('Responsibility').valueChanges.subscribe((value: Responsibilities) => this.responsibilityChanged.emit(value));
              this.responsibilityChanged.emit(this.onsiteRecommendationDetail.Responsibility);
            },
            error: (err) => toastr.error('Could not load onsite recommendations')
        });
    }

    get isTechnicalContactChecked(): boolean {
        return this.contactForm.get('technicalContactChecked').value;
    }

    get isCustomerContactChecked(): boolean {
        return this.contactForm.get('customerContactChecked').value;
    }


    isSendAutomaticallyCheckedChanged($event: boolean): void {
        this.isSendAutomaticallyChecked = $event;
        if (this.isSendAutomaticallyChecked === false) {
            this.clearContactInfo();
        }
        this.contactForm.updateValueAndValidity();
    }

    save(): JQueryPromise<void> {
        if (this.onsiteRecommendationDetail.Responsibility !== this.recommendationForm.get('Responsibility').value) {
            this.responsibilityWarning(this.recommendationForm.get('Responsibility').value);
        }
        this.onsiteRecommendationDetail.Responsibility = this.recommendationForm.get('Responsibility').value;
        this.onsiteRecommendationDetail.Note = this.recommendationForm.get('Note').value;
        this.onsiteRecommendationDetail.TechnicalContactEmail = this.technicalContactForm.get('technicalContactEmail').value;
        this.onsiteRecommendationDetail.TechnicalContactName = this.technicalContactForm.get('technicalContactName').value;
        this.onsiteRecommendationDetail.TechnicalContactPhone = this.technicalContactForm.get('technicalContactPhone').value;
        this.isLoading = true;

        this.discoverContactType();

        return Utils.wrapDfd(this.onsiteRecommendationDetailService.save(this.onsiteRecommendationDetail))
            .then(() => {
                return Utils.wrapDfd(this.onsiteRecommendationDetailService.getByCustomerId(this.customerId))
                    .then((loadedDetails: Boo.Objects.OnsiteRecommendationDetail) => {
                        if (!loadedDetails) {
                            return System.autoRejectedPromise('Could not load detail settings');
                        }
                        this.onsiteRecommendationDetail = loadedDetails;
                        timer.resetUIActivityTime();
                    });
            })
            .fail((message: string) => {
                toastr.error(message);
            })
            .always(() => {
                this.isLoading = false;
            });
    }

    validate(saveType: SaveTypes): IValidatedResult {
        let validatedResult: IValidatedResult = <IValidatedResult>{
            isValid: true,
            errorMessages: []
        };
        this.recommendationForm.get('Responsibility').updateValueAndValidity();
        if (this.recommendationForm.invalid) {
            if (this.recommendationForm.get('Responsibility').invalid && saveType === SaveTypes.Complete) {
                validatedResult.errorMessages.push('Please select who is making changes to the site.');
                validatedResult.isValid = false;
            }
            if (this.recommendationForm.get('Note').invalid) {
                validatedResult.errorMessages.push('Note to recommendation specialist is too long');
                validatedResult.isValid = false;
            }
        }
        if (this.contactForm.invalid) {
            validatedResult.isValid = false;
            validatedResult.errorMessages.push('You must select to send recommendations to the technical contact and/or the customer contact');
        }
        if (this.contactForm.get('technicalContactChecked').value && this.technicalContactForm.invalid) {
            validatedResult.isValid = false;
            validatedResult.errorMessages.push('Technical contact email and name missing or invalid, see technical contact section for details');
            this.technicalContactForm.markAllAsTouched();
        }

        return validatedResult;
    }
    // TODO: This can get called by the logins component before the subscription that sets the value is completed. We should rework the flow so that's not a problem.
    getCurrentImplementationResponsibility(): Boo.OnsiteRecommendations.Models.Enums.Responsibilities {
        return this.recommendationForm?.get('Responsibility').value ?? Responsibilities.NotSelected;
    }

    private clearContactInfo(): void {
        this.contactForm.patchValue(
            {
                technicalContactChecked: false,
                customerContactChecked: false
            });
        this.technicalContactForm.patchValue({
            technicalContactName: null,
            technicalContactEmail: null,
            technicalContactPhone: null
        });
    }

    private discoverContactType(): void {
        let contactType: Boo.OnsiteRecommendations.Models.Enums.ContactTypes;
        if (!this.isSendAutomaticallyChecked) {
            contactType = Boo.OnsiteRecommendations.Models.Enums.ContactTypes.Manual;
        } else if (this.isTechnicalContactChecked && this.isCustomerContactChecked) {
            contactType = Boo.OnsiteRecommendations.Models.Enums.ContactTypes.TechnicalContactAndCustomerContact;
        } else if (this.isTechnicalContactChecked) {
            contactType = Boo.OnsiteRecommendations.Models.Enums.ContactTypes.TechnicalContact;
        } else if (this.isCustomerContactChecked) {
            contactType = Boo.OnsiteRecommendations.Models.Enums.ContactTypes.CustomerContact;
        } else {
            contactType = Boo.OnsiteRecommendations.Models.Enums.ContactTypes.Manual;
        }
        this.onsiteRecommendationDetail.ContactTypeId = contactType;
    }

    private contactValidator(): ValidatorFn {
        return (contacts: AbstractControl): { [key: string]: boolean } | null => {
            return (!this.isSendAutomaticallyChecked || (contacts.get('customerContactChecked').value || contacts.get('technicalContactChecked').value))
                ? null : { 'data missing': true };
        };
    }

    private implementationResponsibilityValidator(): ValidatorFn {
        return (responsibility: AbstractControl): { [key: string]: boolean } | null => {
            return this.validResponsibilities.includes(responsibility.value) ? null : {'invalid implementer': true};
        };
    }

    private responsibilityWarning(value: Responsibilities): void {
        forkJoin([
          this.workRequestService.getByCustomerId(this.customerId),
          this.seoWorkService.getSeoWorkDefaults(this.customerId)
        ]).subscribe(([workRequests, seoWorkDefaults]) => {
            let message = [];

            if (workRequests.some(x => this.hasDifferentResponsibilityWorkRequest(value, x))) {
                message.push('This customer has allocated work in the current or a future period that is set to be implemented by a different source than the one selected.');
            }
            // onsiteblogging: TODO Remove when seperate blogging implementation tasks are depreciated
            if (value !== Responsibilities.Boostability && seoWorkDefaults.find(x => onsiteBloggingOrderingWithImplementationTaskTypes.includes(x.TaskTypeId))) {
                message.push('This customer has default work that is set to be implemented by Boostability. This no longer matches their implemenation setting.');
            } else if (value === Responsibilities.Boostability && seoWorkDefaults.find(x => onsiteBloggingOrderingContentOnlyTaskTypes.includes(x.TaskTypeId))) {
                message.push('This customer has default work that is not set to be implemented by Boostability. This no longer matches their implemenation setting.');
            }

            if(message.length !== 0) {
                bootbox.alert(message.join('<br>') + '<br><br>Please re-allocate the work in Allocate Work.');
            }
        });
    }

    private hasDifferentResponsibilityWorkRequest(value: Responsibilities, workRequest: Boo.Objects.Work.WorkRequest): boolean {
        if (onsiteBloggingOrderTaskTypes.includes(workRequest.WorkTypeCreationId)) {
            return value === Responsibilities.Boostability
                ? WorkRequestHelper.getIsBoostImplementing(workRequest) == false
                : WorkRequestHelper.getIsBoostImplementing(workRequest) == true;
        }

        let responsibility = WorkRequestHelper.getImplementationResponsibility(workRequest);
        return responsibility !== Responsibilities.NotSelected && responsibility !== value;
    }
}