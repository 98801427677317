<div class="card">
  <div class="card-header">
    Backlink Order Parameters
  </div>
  <div class="card-body">
    <div class="form-group">
      <div class="row">
        <div class="col-md-3">
          <span>Original Url</span>
        </div>
        <div class="col-md-3">
          <span>Replacement Url</span>
        </div>
        <div class="col-md-3">
          <span>Original Keyword</span>
        </div>
        <div class="col-md-3">
          <span>Replacement Keyword</span>
        </div>
      </div>
    </div>
    @for (parameter of backlinkParametersCorrections; track parameter) {
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <span>{{ parameter.Url }}</span>
          </div>
          <div class="col-md-3">
            <ng-select [items]="websiteUrls" [searchable]="true"
              bindLabel="Url" bindValue="WebsiteUrlId" placeholder="Choose..." [(ngModel)]="parameter.WebsiteUrlId">
            </ng-select>
          </div>
          <div class="col-md-3">
            <label>{{ parameter.Keyword }}</label>
          </div>
          <div class="col-md-3">
            <ng-select [items]="getWebsiteUrlKeywords(parameter)" [searchable]="true"
            bindLabel="KeywordPhrase" bindValue="WebsiteUrlKeywordId" placeholder="Choose..." [(ngModel)]="parameter.WebsiteUrlKeywordId">
          </ng-select>
          </div>
        </div>
      </div>
    }
  </div>
</div>