import { Component } from '@angular/core';
import app from 'App';
import { PriorityViewModel } from 'app/models/PriorityViewModel';

@Component({
    selector: 'app-components-customerservice-managecustomer',
    templateUrl: './ManageCustomer.component.html'
})
export class ManageCustomerComponent implements IActivatable {
    /**
     * View model to launch the managecustomer priority view from a route that specifies the customer id.
     * Example route: customerservice / managecustomer / 2744
     */
    public activate(params: any): JQueryPromise<void> {
        let customerId = params ? parseInt(params[0], 10) : null;
        if (_.isNaN(customerId) || customerId <= 0) {
            toastr.error('Invalid Customer ID');
            return;
        }
        return PriorityViewModel.showManageCustomer(customerId, false, false)
            .then(null, () => {
                // priority views are "rejected" when they're closed. This will cause CompositionEngine to show an error thinking it couldn't load the route.
                return $.Deferred().resolve();
            });
    }
}
