<div
  *withPermissions="let allow of ['CanViewOnsiteRecommendations', 'CanViewCustomReports', 'CanViewCustomActions', 'CanEditCustomerPartnerInformation']">
  <div [loadingOverlay]="isLoading()" class="card-body">
    <div id="details" style="min-height: 150px;">
      <ng-container *ngIf="customer()">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a [attr.href]="'#basic' + page()" href="#basic" data-toggle="tab" class="active nav-link">Key Information</a>
          </li>
          <li class="nav-item">
            <a [attr.href]="'#sales' + page()" href="#sales" data-toggle="tab" class="nav-link">Sales Information</a>
          </li>
          <li class="nav-item">
            <a [attr.href]="'#contact' + page()" href="#contact" data-toggle="tab" class="nav-link">Contact Us</a>
          </li>
          <li class="nav-item">
            <a [attr.href]="'#partner' + page()" href="#partner" data-toggle="tab" class="nav-link">Partner Information</a>
          </li>
          <li class="nav-item">
            <a (click)="viewReportingSite()" class="nav-link external-link">
              <i class="fas fa-sign-in-alt"></i>
              Reporting Site
            </a>
          </li>
          <li *ngIf="showStoreLink" class="nav-item">
            <a (click)="navigateToStore()" class="nav-link external-link">
              <i class="fas fa-sign-in-alt"></i>
              Billing and Products
            </a>
          </li>
          <li class="float-end nav-item" *ngIf="hasCsr()">
            <a class="text-success"><i class="fas fa-user-check nav-link"></i>CSR: {{csrName()}}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div [attr.id]="'basic' + page()" class="tab-pane active" id="basic">
            <div class="row">
              <div class="col-md-4">
                <ul class="list-group">
                  <li class="list-group-item">
                    <strong>Customer ID</strong>
                    <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                      class="float-end">{{customer().CustomerId()}}</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Merchant ID</strong>
                    <span *ngIf="merchantFields" (click)="window.launchpad.utils.selectText(undefined, $event)"
                      class="float-end">{{ merchantFields.MerchantId }}</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Customer Inserted Date</strong>
                    <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                      class="float-end">{{ customer().InsertedDate() | dateText }}</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Order Renewal Date</strong>
                    <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                      class="float-end">{{ nextRenewalDate | dateText : 'L' : true }}</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Hours</strong>
                    <span class="float-end">{{customer().SeoMinutesTotal()| seoHours}}</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Subscriptions</strong>
                    <span *ngIf="!subscriptionsSnapshot.length" class="float-end">No Active Subscriptions</span>
                    <ul>
                      <ng-container *ngFor="let sub of subscriptionsSnapshot">
                        <li>
                          <span>{{sub.Name}}</span>
                          <span>({{ sub.IntervalMonths | frequency }})</span>
                          <span *ngIf="sub.Start > today" class="text-info">Starts
                            {{ sub.Start | dateText : 'L' : true }}</span>
                        </li>
                      </ng-container>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <ul class="list-group">
                  <li class="list-group-item">
                    <strong>Website</strong>
                    <a [externalLink]="{ url: customer().Url, text:customer().Url}" class="float-end" rel="noreferrer"></a>
                  </li>
                  <li class="list-group-item">
                    <strong>Company Name</strong>
                    <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                      class="float-end">{{customer().Name()}}</span>
                  </li>
                  <ng-container *ngIf="!contact">
                    <li class="list-group-item">
                      <strong>DO NOT CONTACT</strong>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="contact">
                    <li class="list-group-item">
                      <strong>Contact Name</strong>
                      <div class="float-end">
                        <span>{{ contact.FullName() }}</span>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <strong>Contact phone</strong>
                      <div class="float-end">
                        <shared-components-clicktocall [Phone]="contact.PrimaryPhoneNumber()"
                          [CountryCode]="supportPhoneNumberCountryCode()"></shared-components-clicktocall>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <strong>Contact Email</strong>
                      <div class="float-end">
                        <a (click)="createDraft()" triggers="mouseenter:mouseleave" [openDelay]="200" [closeDelay]="600" [ngbPopover]="'Create Front draft'" class="draft-link" placement="right auto">
                          <i class="fa fa-envelope"></i>{{ emailContactLabel }}
                        </a>
                      </div>
                    </li>
                  </ng-container>
                  <li class="list-group-item">
                    <strong>Customer Current Time</strong>
                    <compose Selector="app-shared-components-timezonedatetime"
                      [Model]="{ timeZone: customer().TimeZone() }" class="float-end"></compose>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <ul class="list-group">
                  <li class="list-group-item">
                    <strong>Contact Note</strong>
                    <div>
                      <span *ngIf="contactNote.length < 178">{{ contactNote || '-' }}</span>
                      <span *ngIf="contactNote.length >= 178">
                        {{ contactNote | slice:0:178 }}
                        <button (click)="openCustomerUsersTab()" class="btn btn-link" style="padding: 0px 12px;">view
                          more...</button>
                      </span>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <app-components-recentorders [orders]="ordersSnapshot"></app-components-recentorders>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div [attr.id]="'sales' + page()" class="tab-pane" id="sales">
            <div class="row">
              <div class="col-md-4">
                <ul class="list-group">
                  <li class="list-group-item">
                    <strong>Minutes</strong>
                    <span class="float-end">{{customer().SeoMinutesTotal()| seoMinutes}}</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Referred By</strong>
                    <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                      class="float-end">{{customer().ReferredBy()}}</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Lead Source</strong>
                    <span *ngIf="customer().LeadSourceId()" (click)="window.launchpad.utils.selectText(undefined, $event)"
                      class="float-end">{{customer().LeadSource.Name()}}</span>
                  </li>
                  <ng-container *ngIf="showTermsAndConditions">
                    <li class="list-group-item">
                      <strong>Terms Acceptance</strong>
                      <div class="float-end">
                        <ng-container *ngIf="termsAndConditions && $any(termsAndConditions.StatusId) === eSignatureDocumentStatuses.Complete">
                          {{ termsAndConditions.StatusDate | dateText }}
                        </ng-container>
                        <ng-container *ngIf="!termsAndConditions">
                          Not Available
                        </ng-container>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
              <div class="col-md-4">
                <ul class="list-group">
                  <li class="list-group-item">
                    <strong>Billing Cycle</strong>
                    <span class="float-end">Every Month</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Campaign End Date</strong>
                    <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                      class="float-end">{{customer().CampaignEndDate() | dateText : 'L' : true }}</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Contract Length</strong>
                    <div class="float-end">
                      <span
                        (click)="window.launchpad.utils.selectText(undefined, $event)">{{customer().MinimumContractLength() + ' ' + (customer().MinimumContractLength() === 0 ? 'Month' : 'Months')}}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <ul class="list-group">
                  <li class="list-group-item">
                    <h4>Partner Support Information</h4>
                  </li>
                  <li class="list-group-item">
                    <strong>Partner</strong>
                    <div class="float-end">
                      <span>{{partner().Name}}</span>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <strong>Full White Label Support?</strong>
                    <div class="float-end">
                      <span>{{partner().IsWhiteLabel ? 'Yes' : 'No'}}</span>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <strong>Support Tier</strong>
                    <div class="float-end">
                      <span>{{partnerSupportLevelName()}}</span>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-md-12">
                        <strong>Support Phone</strong>
                        <span class="float-end">
                          <shared-components-clicktocall [Phone]="partner().SupportPhoneNumber"
                            [CountryCode]="supportPhoneNumberCountryCode()"></shared-components-clicktocall>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-md-12">
                        <strong>Support Email</strong>
                        <span class="float-end">{{partner().SupportEmailAddress}}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div [attr.id]="'contact' + page()" class="tab-pane" id="contact">
            <div class="row">
              <div class="col-md-6">
                <ul class="list-group">
                  <ng-container *ngIf="hasCsr()">
                    <li class="list-group-item">
                      <strong>CSR Name</strong>
                      <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                        class="float-end">{{csrName()}}</span>
                    </li>
                    <li class="list-group-item">
                      <strong>CSR Phone</strong>
                      <span class="float-end">
                        <shared-components-clicktocall [Phone]="csrPhoneNumber()">
                        </shared-components-clicktocall>
                      </span>
                    </li>
                    <li class="list-group-item">
                      <strong>CSR Email</strong>
                      <a [attr.href]="'mailto:' + csrEmailAddress()" class="float-end">
                        <i class="fa fa-envelope"></i>
                        <span>{{csrEmailAddress()}}</span>
                      </a>
                    </li>
                  </ng-container>
                  <li class="list-group-item">
                    <strong>Support Team</strong>
                    <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                      class="float-end">{{supportTeam()}}</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Support Phone</strong>
                    <span class="float-end">
                      <shared-components-clicktocall [Phone]="supportPhoneNumber()"
                        [CountryCode]="supportPhoneNumberCountryCode()"></shared-components-clicktocall>
                    </span>
                  </li>
                  <li class="list-group-item">
                    <strong>Support Email</strong>
                    <a [attr.href]="'mailto:' + supportEmailAddress()" class="float-end">
                      <i class="fa fa-envelope"></i>
                      <span>{{supportEmailAddress()}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div [attr.id]="'partner' + page()" class="tab-pane" id="partner">
            <compose Selector="app-components-managecustomer-merchantfields" [ActivateOptions]="{
                                            customer: customer,
                                            merchantFields: merchantFields,
                                            triggerRefreshCustomer: parent.refreshCustomer.bind(parent)
                                        }"></compose>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
			
</div>
<ng-template #draftValidationModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <span>Cannot create draft because no customer contact is set as a "To" email recipient. Do you want to edit contact settings?</span>
    </div>
    <div class="modal-footer">
      <button (click)="editCustomerContacts(); modal.close()" type="button" class="btn btn-primary">Contact Settings</button>
      <button type="button" class="btn btn-secondary" (click)="modal.close()">Cancel</button>
    </div>
  </div>
</ng-template>
