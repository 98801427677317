<ng-container *ngIf="localProfile">
  <div *ngIf="!localProfile.IsComplete" class="alert alert-warning mt-2" role="alert">
    A Business Profile Validation and Augmentation task has not been completed for this Local Profile.
  </div>
  <div class="d-flex flex-row mb-3">
    <div class="p-2">
      <strong>Active:</strong> {{localProfile.IsActive ? 'Yes' : 'No'}}
    </div>
    <div class="p-2 ms-5">
      <strong>Can Subscribe:</strong> {{localProfile.CanSubscribe ? 'Yes' : 'No'}}
    </div>
    <div class="p-2 ms-5">
      <strong>Location Is Closed:</strong> {{localProfile.IsClosed ? 'Yes' : 'No'}}
    </div>
  </div>
  <hr />
  <h5>General Info</h5>
  <div class="row mb-3">
    <div class="col-6">
      <div class="row g-3">
        <div class="col-12">
          <app-components-shared-local-profile-address
            [localProfile]="localProfile"></app-components-shared-local-profile-address>
          <span>{{countryName}}</span>
        </div>
        <div class="col-12">
          <strong>Opening Date:</strong> {{localProfile.OpeningDate ? localProfile.OpeningDate : 'Not provided'}}
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row g-3">
        <div class="col-12">
          <strong>Hide Street Address:</strong> {{localProfile.IsStreetAddressHidden ? 'Yes' : 'No'}}
        </div>
        <div class="col-12">
          <ng-container *ngIf="localProfile.ServiceAreas.length > 0">
            <strong>Service Areas</strong>
            <ul>
              <ng-container *ngFor="let serviceArea of localProfile.ServiceAreas">
                <li>{{ serviceArea.Description }}</li>
              </ng-container>
            </ul>
          </ng-container>
          <ng-container *ngIf="localProfile.ServiceAreas.length === 0">
            <strong>Service Areas:</strong> Not provided
          </ng-container>
        </div>
        <div class="col-12">
          <strong>Categories</strong>
          <ul *ngFor="let category of localProfile.Categories">
            <li>{{ category.FullName }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <h5>Contact Info</h5>
  <div class="row mb-3">
    <div class="col-6">
      <div class="row g-3">
        <div class="col-12">
          <strong>Company Phone:</strong> {{localProfile.Phone ? localProfile.Phone : 'Not provided'}}
        </div>
        <div class="col-12">
          <strong>Alternate Phone:</strong> {{localProfile.AlternatePhone ? localProfile.AlternatePhone : 'Not provided'}}
        </div>
        <div class="col-12">
          <strong>Fax:</strong> {{localProfile.Fax ? localProfile.Fax : 'Not provided'}}
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row g-3">
        <div class="col-12">
          <strong>Contact Name:</strong> {{localProfile.ContactName ? localProfile.ContactName : 'Not provided'}}
        </div>
        <div class="col-12">
          <strong>Website:</strong> {{localProfile.Website ? localProfile.Website : 'Not provided'}}
        </div>
        <div class="col-12">
          <strong>Email:</strong> {{localProfile.Email ? localProfile.Email : 'Not provided'}}
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-6">
      <div class="row g-3">
        <ng-container *ngIf="!localProfile.ShowOperatingHours">
          <div class="col-12">
            <strong>Business Hours:</strong> Do not post operating hours
          </div>
        </ng-container>
        <ng-container *ngIf="localProfile.ShowOperatingHours">
          <ng-container *ngIf="localProfile.IsOpen247">
            <div class="col-12">
              <strong>Business Hours:</strong> Open 24/7
            </div>
          </ng-container>
          <ng-container *ngIf="localProfile.IsByAppointmentOnly">
            <div class="col-12">
              <strong>Business Hours:</strong> By Appointment Only
            </div>
          </ng-container>
          <ng-container *ngIf="!localProfile.IsOpen247 && !localProfile.IsByAppointmentOnly">
            <div class="col-12">
              <strong>Standard Hours</strong>
              <ul>
                <ng-container *ngFor="let businessHour of localProfile.BusinessHours">
                  <li>{{businessHourToString(businessHour)}}</li>
                </ng-container>
              </ul>
            </div>
          </ng-container>
        </ng-container>
        <div class="col-12">
          <ng-container *ngIf="!localProfile.AdditionalHoursInformation">
            <strong>Additional Hours Information: </strong> None
          </ng-container>
          <ng-container *ngIf="localProfile.AdditionalHoursInformation">
            <strong>Additional Hours Information</strong>
            <p>{{localProfile.AdditionalHoursInformation}}</p>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row g-3">
        <div class="col-12">
          <ng-container *ngIf="localProfile.SpecialHours.length > 0">
            <strong>Special Hours</strong>
            <ul>
              <ng-container *ngFor="let specialHour of localProfile.SpecialHours">
                <li>{{specialHourToString(specialHour)}}</li>
              </ng-container>
            </ul>
          </ng-container>
          <ng-container *ngIf="localProfile.SpecialHours.length === 0">
            <strong>Special Hours:</strong> None
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <h5>Business Info and Description</h5>
  <div class="row g-3 mb-3">
    <div class="col-6">
      <strong>Featured Message</strong>
      <p>{{ localProfile.FeaturedMessage }}</p>
    </div>
    <div class="col-6">
      <strong>Description</strong>
      <p>{{ localProfile.Description }}</p>
    </div>
  </div>
  <hr />
  <h5>Social Media Profiles</h5>
  <div class="row mb-3">
    <div class="col-6">
      <div class="row g-3">
        <div class="col-12">
          <strong>Facebook Page URL:</strong> {{localProfile.FacebookHandle ? 'facebook.com/' +
          localProfile.FacebookHandle : 'Not provided'}}
        </div>
        <div class="col-12">
          <strong>Twitter:</strong> {{localProfile.TwitterHandle ? '@' + localProfile.TwitterHandle : 'Not provided'}}
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row g-3">
        <div class="col-12">
          <strong>LinkedIn:</strong> {{localProfile.LinkedInHandle ? localProfile.LinkedInHandle : 'Not provided'}}
        </div>
        <div class="col-12">
          <strong>YouTube:</strong> {{localProfile.YouTubeHandle ? localProfile.YouTubeHandle : 'Not provided'}}
        </div>
        <div class="col-12">
          <strong>Instagram:</strong> {{localProfile.InstagramHandle ? localProfile.InstagramHandle : 'Not provided'}}
        </div>
        <div class="col-12">
          <strong>Pinterest:</strong> {{localProfile.PinterestHandle ? localProfile.PinterestHandle : 'Not provided'}}
        </div>
      </div>
    </div>
  </div>
  <hr />
  <h5>Rich Data</h5>
  <div class="row mb-3">
    <div class="col-6">
      <div class="row g-3">
        <div class="col-12">
          <strong>Payment Options:</strong> {{ paymentOptions }}
        </div>
        <div class="col-12">
          <strong>Products:</strong> {{localProfile.Products ? localProfile.Products : 'Not provided'}}
        </div>
        <div class="col-12">
          <strong>Services:</strong> {{localProfile.Services ? localProfile.Services : 'Not provided'}}
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row g-3">
        <div class="col-12">
          <strong>Associations:</strong> {{localProfile.Associations ? localProfile.Associations : 'Not provided'}}
        </div>
        <div class="col-12">
          <strong>Specialties:</strong> {{localProfile.Specialties ? localProfile.Specialties : 'Not provided'}}
        </div>
        <div class="col-12">
          <ng-container *ngIf="localProfile.Notes">
            <strong>Additional Information</strong>
            <p>{{localProfile.Notes}}</p>
          </ng-container>
          <ng-container *ngIf="!localProfile.Notes">
            <strong>Additional Information:</strong> None
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <h5>Photos & Videos</h5>
  <div class="row mb-3">
    <div class="col-6">
      <div class="row g-3">
        <div class="col-12">
          <strong>Logo URL:</strong> {{localProfile.LogoUrl ? localProfile.LogoUrl : 'Not provided'}}
        </div>
        <div class="col-12">
          <ng-container *ngIf="localProfile.ImageUrls">
            <strong>Image URLs</strong>
            <p>{{localProfile.ImageUrls}}</p>
          </ng-container>
          <ng-container *ngIf="!localProfile.ImageUrls">
            <strong>Image URLs:</strong> None
          </ng-container>
        </div>
        <div class="col-12">
          <ng-container *ngIf="localProfile.VideoUrls">
            <strong>Video URLs</strong>
            <p>{{localProfile.VideoUrls}}</p>
          </ng-container>
          <ng-container *ngIf="!localProfile.VideoUrls">
            <strong>Video URLs:</strong> None
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row g-3">
        <div class="col-12">
          <strong>Google Cover Photo:</strong> {{localProfile.GoogleCoverPhoto ? localProfile.GoogleCoverPhoto : 'Not provided'}}
        </div>
        <div class="col-12">
          <strong>Google Profile Photo:</strong> {{localProfile.GoogleProfilePhoto ? localProfile.GoogleProfilePhoto :
          'Not provided'}}
        </div>
        <div class="col-12">
          <strong>Google Preferred Photo:</strong> {{localProfile.GooglePreferredPhoto ?
          localProfile.GooglePreferredPhoto : 'Not provided'}}
        </div>
      </div>
    </div>
  </div>
</ng-container>