import { Component } from '@angular/core';

@Component({
	selector: 'app-components-customerservice-helpnextcustomer',
	templateUrl: './HelpNextCustomer.component.html'
})
export class HelpNextCustomerComponent implements IActivatable {
    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner): boolean {
        return !!(_.find(user.PartnerUsers, (partnerUser: Boo.Objects.PartnerUser) => {
            return partnerUser.UserLevelId === Boo.Objects.Enums.UserLevelEnum.CustomerService 
                && partnerUser.PartnerId === partner.PartnerId;
        }));
    }
}
