<div [ngClass]="{centered: !showTitle}">
  <label class="control-label" *ngIf="showTitle"
    [ngClass]="{'block-title' : blockTitle}"><strong>{{name}}:</strong></label>
  <div *ngIf="showTitle && description !== null">
    <label class="control-label">{{description}}</label>
  </div>
  <div class="btn-group" [ngClass]="{'has-error' : validated && !validateCriteria()}">
    <label class="btn btn-outline-secondary grouped-button" *ngIf="!required"
      [ngClass]="{'active' : !selectedDefinition }" (click)="setDefintion(null)">
      None
    </label>
    <label class="btn btn-outline-secondary grouped-button" *ngFor="let ld of labeledDefinitions"
      [ngClass]="{'active' : selectedDefinition === ld }" (click)="setDefintion(ld)">
      {{ld.label}}
    </label>
  </div>
  <app-components-qa-stock-comment-select *ngIf="showStockComments"
    [stockComments]="selectedStockComments" (insertStockComment)="insertStockComment($event)">
  </app-components-qa-stock-comment-select>
  <textarea *ngIf="showComment" [(ngModel)]="comment" class="spaced-content form-control"
    [ngClass]="{'has-error' : validated && !validateComment()}">
  </textarea>
</div>