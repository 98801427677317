import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import { UpaChecklistService } from '../../../services/upa-checklist.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-ticket-upa-checklist',
  templateUrl: './upa-checklist.component.html'
})
export class UpaChecklistComponent extends BaseComponent {
  upaChecklists: Boo.Objects.Checklist.UpaChecklist[];

  constructor(private upaChecklistService: UpaChecklistService) { 
    super();
  }

  public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
    return Utils.wrapDfd(this.upaChecklistService.get(params.ticket.AssociatedTaskId()))
      .then(upaChecklists => {
        this.upaChecklists = upaChecklists.filter(upaChecklist => upaChecklist.Checklist.Items.some(item => (item === null || item.Value === !item.PassValue) && !item.IsSecondary));
        return super.activate(params);
      })
  }

}
