import { Component } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { VerticalService } from '../../../services/vertical.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-admin-verticalmanagement',
  templateUrl: './VerticalManagement.component.html'
})
export class VerticalManagementComponent {
    public isLoading: any = ko.observable(false);
    public searchText: any = ko.observable('');
    public verticals: any = ko.observableArray([]);

    constructor(private verticalService: VerticalService) { }

    public filteredVerticals: any = ko.computed(() => {
        if (this.searchText().length >= 3) {
            const searchText = this.searchText().toLowerCase();
            const matchingVerticals = _.filter(this.verticals(), (vertical: any) => {
                return vertical.Name.toLowerCase().indexOf(searchText) > -1 || vertical.ParentVerticalName.toLowerCase().indexOf(searchText) > -1;
            });
            return matchingVerticals;
        }
        return this.verticals();
    });

    public openVertical(data: any): void {
        PriorityViewModel.show('app-components-admin-vertical', { verticalId: data.VerticalId }).done(() => {
            this.loadVerticals();
        });
    }

    public openParentVertical(data: any): void {
        PriorityViewModel.show('app-components-admin-vertical', { verticalId: data.ParentVerticalId }).done(() => {
            this.loadVerticals();
        });
    }

    public addVertical(): void {
        PriorityViewModel.show('app-components-admin-vertical', {}).done(() => {
            this.loadVerticals();
        });
    }

    public activate(): void {
        this.loadVerticals();
    }

    private loadVerticals(): void {
        this.isLoading(true);
        Utils.wrapDfd(this.verticalService.getManagementSummary())
          .then(verticals => {
              // Get verticals sorted
              const sorter = new launchpad.utils.pSort();
              sorter.key = 'VerticalId';
              sorter.parentKey = 'ParentVerticalId';
              this.verticals(sorter.sort(verticals));
          }).fail((displayMessage) => {
              toastr.error(displayMessage);
          }).always(() => {
              this.isLoading(false);
          });
    }
}
