import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { AddJobComponent } from './AddJob/AddJob.component';
import { ApplicationManagementComponent } from './ApplicationManagement/ApplicationManagement.component';
import { AdminHelpTextEditorComponent } from './AdminHelpTextEditor/AdminHelpTextEditor.component';
import { CountryAndLanguageManagementComponent } from './CountryAndLanguageManagement/CountryAndLanguageManagement.component';
import { CountryAndLanguageComponent } from './CountryAndLanguageManagement/CountryAndLanguage.component'
import { InternalTaskHistoryComponent } from './InternalTaskHistory/InternalTaskHistory.component';
import { JobListComponent } from './job-list/job-list.component';
import { JobsByTypeComponent } from './JobsByType/JobsByType.component';
import { PartnerUsersComponent } from './PartnerUsers/PartnerUsers.component';
import { TranslationsComponent } from './Translations/Translations.component';
import { UpdateJobsComponent } from './UpdateJobs/UpdateJobs.component';
import { VerticalComponent } from './Vertical/Vertical.component';
import { VerticalManagementComponent } from './VerticalManagement/VerticalManagement.component';
import { VerticalTranslationComponent } from './VerticalTranslation/VerticalTranslation.component';
import { WorkflowsComponent } from './Workflows/Workflows.component';

@NgModule({
  declarations: [
    AddJobComponent,
    ApplicationManagementComponent,
    AdminHelpTextEditorComponent,
    CountryAndLanguageManagementComponent,
    CountryAndLanguageComponent,
    InternalTaskHistoryComponent,
    JobListComponent,
    JobsByTypeComponent,
    PartnerUsersComponent,
    TranslationsComponent,
    UpdateJobsComponent,
    VerticalComponent,
    VerticalManagementComponent,
    VerticalTranslationComponent,
    WorkflowsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedComponentsModule
  ],
  exports: [
    AdminHelpTextEditorComponent
  ]
})
export class AdminModule { }
