import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ITranslatedTableService } from 'app/admin/config/TranslatedTableConfig';

@Injectable({
  providedIn: 'root'
})
export class PartnerPricingToolHeadingTranslationService implements ITranslatedTableService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/partnerpricingtoolheadingtranslation`;

  constructor(private http: HttpClient) { }

  getMany(languageId: number, includeMissing: boolean): Observable<Boo.Objects.SEOPresence.PartnerPricingToolHeadingTranslation[]> {
    const params = new HttpParams()
    .set('languageId', languageId)
    .set('includeMissing', includeMissing);

    return this.http.get<Boo.Objects.SEOPresence.PartnerPricingToolHeadingTranslation[]>(`${this.baseUrl}/GetMany`, { params: params });
  }

  saveMany(translations: Boo.Objects.SEOPresence.PartnerPricingToolHeadingTranslation[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveMany`, translations);
  }
}
