<div>
	<ul class="list-unstyled">
		<ng-container *ngFor="let todo of todos()">
			<ng-container *ngIf="!todo.TodoGroupId()">
				<ng-container *ngTemplateOutlet="readTodoTemplate;context:{$context:todo}"></ng-container>
			</ng-container>
		</ng-container>
	</ul>
	<div>
		<ng-container *ngFor="let group of todoGroups()">
			<ul class="list-unstyled">
				<li class="list-group-item bg-default">
					<strong>
						<span>{{group.Name()}}</span>
					</strong>
				</li>
				<ng-container *ngFor="let todo of todos()">
					<ng-container *ngIf="group.TodoGroupId() === todo.TodoGroupId()">
						<ng-container *ngTemplateOutlet="readTodoTemplate;context:{$context:todo}"></ng-container>
					</ng-container>
				</ng-container>
			</ul>
		</ng-container>
	</div>
	<div [attr.id]="todoModalId" class="modal fade" id="todoModal">
		<div class="modal-dialog">
			<div class="modal-content">
				<ng-container *ngIf="selectedTodo()">
					<compose Selector="app-components-todo-readonlytodolegacy" [ActivateOptions]="{
                            todo: selectedTodo,
                            userId: userId,
                            modalId: todoModalId
                         }"></compose>
				</ng-container>
			</div>
		</div>
	</div>
	<ng-template #readTodoTemplate let-$context="$context">
		<li [dataValidation]="{ placement: 'top', value: $context.Status }" class="list-group-item">
			<div class="row">
				<div [class]="setTodoClass($context)" [attr.title]="setTodoHelpText($context)" class="col-md-1">
					<i [class]="setTodoIcon($context)"></i>
				</div>
				<div class="col-md-11">
					<div class="row">
						<div class="col-md-12">
							<span [class]="setTodoClass($context)">
                {{$context.Content()}}
              </span>
							<span style="margin-left: 10px;" class="cursor-pointer text-muted">
								<ng-container *ngIf="todoNoteCount($context) > 0">
									<i (click)="openTodo($context)" class="fa fa-sticky-note">
										<span>{{todoNoteCount($context)}}</span>
									</i>
								</ng-container>
							</span>
						</div>
					</div>
				</div>
			</div>
		</li>
	</ng-template>
</div>
