/* tslint:disable */

import { Component } from '@angular/core';
import { CustomerService } from '../../../services/customer.service';
import { PartnerService } from '../../../services/partner.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-manager-transferaccounts',
	templateUrl: './TransferAccounts.component.html'
})
export class TransferAccountsComponent {
  public isLoading: any = ko.observable(false);

  public referredBy: any = ko.observable().extend({ required: true });

  public selectedToPartner: any = ko.observable().extend({ required: true });

  public selectedFromPartner: any = ko.observable().extend({ required: true });
  public partners: any = ko.observableArray();
  public customers: any = ko.observableArray();

  public customerId: any = ko.observable().extend({ number: true, required: true });

  constructor(
    private customerService: CustomerService,
    private partnerService: PartnerService) { }

  public searchValidation: any = ko.validatedObservable({
    selectedToPartner: this.selectedToPartner,
    selectedFromPartner: this.selectedFromPartner,
    referredBy: this.referredBy
  });

  public transferValidation: any = ko.validatedObservable({
    selectedToPartner: this.selectedToPartner
  });

  public isCustomerId: any = ko.computed(() => {
    const isCustomer = this.customerId() && $.trim(this.customerId()).length > 0;
    if (isCustomer) {
      this.selectedFromPartner.clearError();
      this.referredBy.clearError();
    }
    return isCustomer;
  });

  public isReferredBy: any = ko.computed(() => {
    const isReferredBy = this.referredBy() && $.trim(this.referredBy()).length > 0;
    if (isReferredBy) {
      this.customerId.clearError();
    }
    return isReferredBy;
  });

  public activate(): void {
    this.loadPartners();
  }

  public findCustomers(): void {
    if (this.isCustomerId() === true) {
      if (this.customerId.isValid()) {
        Utils.wrapDfd(this.customerService.get(this.customerId()))
          .then((data: any) => {
            if (data) {
              data.isDeleting = ko.observable(false);
              this.customers([data]);
            } else {
              toastr.error('No results');
            }
          }).fail((displayMessage) => {
            toastr.error(displayMessage);
          });
      } else {
        toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      }
    } else {
      if (this.searchValidation.isValid()) {
        Utils.wrapDfd(this.customerService.getByReferredBy(this.selectedFromPartner().PartnerId, this.referredBy()))
          .then((data: any) => {
            _.each(data,
              (item: any) => {
                item.isDeleting = ko.observable(false);
              });
            this.customers(data);
          }).fail((displayMessage) => {
            toastr.error(displayMessage);
          })
      } else {
        toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
        this.searchValidation.errors.showAllMessages();
      }
    }
  }

  public transfer(customer: any): void {
    if (this.transferValidation.isValid()) {
      customer.isDeleting(true);
      this.isLoading(true);
      const partnerId = this.isCustomerId() ? (_.first(this.customers()) as any).PartnerId : this.selectedFromPartner().PartnerId;
      Utils.wrapDfd(this.customerService.changePartner(customer.CustomerId, partnerId, this.selectedToPartner().PartnerId))
        .then(() => {
          toastr.success('Historical task information successfully transferred to new partner.  IMPORTANT: Please login to the Store and configure Product subscriptions and payment methods.');
          this.customers.remove(customer);
        }).fail((displayMessage) => {
          customer.isDeleting(false);
          toastr.error(displayMessage);
        }).always(() => {
          this.isLoading(false);
        });
    } else {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      this.transferValidation.errors.showAllMessages();
    }
  }

  private loadPartners(): void {
    this.partners([]);
    this.isLoading(true);
    Utils.wrapDfd(this.partnerService.getAll())
      .then((data: any) => {
        this.partners(data.sort((a: any, b: any) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (b.Name < a.Name) {
            return 1;
          }
          return 0;
        }));
      }).fail((displayMessage) => {
        toastr.error(displayMessage);
      }).always(() => {
        this.isLoading(false);
      });
  }
}
