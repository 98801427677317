import { Directive, Input, ElementRef, HostListener, Output, EventEmitter, forwardRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[percentageValue][type=number]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PercentageDirective),
    multi: true
  }]
})
export class PercentageDirective implements ControlValueAccessor {
  onChange = (_: any) => { };
  onTouched = () => { };

  constructor(private element: ElementRef, private renderer: Renderer2) { }

  writeValue(value: number): void {
    this.element.nativeElement.value = value * 100;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  
  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
  }

  @HostListener('input') onInputChange() {
    this.onChange(this.element.nativeElement.value / 100);
    this.onTouched();
  }

  @HostListener('blur') handleBlurEvent() {
    if(this.element.nativeElement.value === '') {
      this.element.nativeElement.value = 0;
    }
  }
}