import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/tickets`;

  constructor(private http: HttpClient) { }

  getUserTicketSummary(startDate: string, endDate: string): Observable<Boo.Objects.UserTicketSummary[]> {
    return this.http.post<Boo.Objects.UserTicketSummary[]>(`${this.baseUrl}/GetUserTicketSummary`, {
        startDate: startDate,
        endDate: endDate
    });
  }

  search(
    searchString: string,
    teamId: number,
    userId: number,
    isAssigned: number,
    statusIds: string,
    ticketTypeIds: string, 
    languageIds: string,
    startDate: Date,
    endDate: Date,
    minSpend: number,
    maxSpend: number,
    customerStatusId: number): Observable<Boo.Objects.TicketSearchResult[]> {
    return this.http.post<Boo.Objects.TicketSearchResult[]>(`${this.baseUrl}/Search`, {
        searchString: searchString,
        teamId: teamId,
        userId: userId,
        isAssigned: isAssigned,
        statusIds: statusIds,
        ticketTypeIds: ticketTypeIds,
        languageIds: languageIds,
        startDate: startDate,
        endDate: endDate,
        minSpend: minSpend,
        maxSpend: maxSpend,
        customerStatusId: customerStatusId
    });
  }

  getByCustomer(customerId: number, onlyNonCompleted: boolean): Observable<Boo.Objects.Ticket[]> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('onlyNonCompleted', onlyNonCompleted);

    return this.http.get<Boo.Objects.Ticket[]>(`${this.baseUrl}/GetByCustomer`, { params: params });
  }

  getAppointments(userId: number): Observable<Boo.Objects.AssignedTicketSummary[]> {
    const params = new HttpParams().set('userId', userId);

    return this.http.get<Boo.Objects.AssignedTicketSummary[]>(`${this.baseUrl}/GetAppointments`, { params: params });
  }

  getWorkStoppage(userId: number): Observable<Boo.Objects.AssignedTicketSummary[]> {
    const params = new HttpParams().set('userId', userId);

    return this.http.get<Boo.Objects.AssignedTicketSummary[]>(`${this.baseUrl}/GetWorkStoppage`, { params: params });
  }

  getUpcoming(userId: number): Observable<Boo.Objects.AssignedTicketSummary[]> {
    const  params = new HttpParams().set('userId', userId);

    return this.http.get<Boo.Objects.AssignedTicketSummary[]>(`${this.baseUrl}/GetUpcoming`, { params: params });
  }

  getAssigned(userId: number): Observable<Boo.Objects.AssignedTicketSummary[]> {
    const  params = new HttpParams().set('userId', userId);

    return this.http.get<Boo.Objects.AssignedTicketSummary[]>(`${this.baseUrl}/GetAssigned`, { params: params });
  }

  getAppointmentIdsByAssignedUser(assignedUserId: number, startDateTime: Date, endDateTime: Date): Observable<number[]> {
    return this.http.post<number[]>(`${this.baseUrl}/GetAppointmentIdsByAssignedUser`, {
        assignedUserId: assignedUserId,
        startDateTime: startDateTime,
        endDateTime: endDateTime
    });
  }

  selectWorkStoppage(): Observable<Boo.Objects.TicketSummary[]> {
    return this.http.get<Boo.Objects.TicketSummary[]>(`${this.baseUrl}/SelectWorkStoppage`);
  }

  assignUnassignBatch(isAssign: boolean, assignToUserId: number, ticketIds: number[]): Observable<Boo.Apis.TicketAssignResult> {
    return this.http.post<Boo.Apis.TicketAssignResult>(`${this.baseUrl}/AssignUnassignBatch`, {
        isAssign: isAssign,
        assignToUserId: assignToUserId,
        ticketIds: ticketIds
    });
  }

  delete(ticketId: number, reason: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/Delete`, {
        ticketId: ticketId,
        reason: reason
    });
  }

  createTicketBatch(customerIds: number[], ticketTypeId: number, startDate: Date, note: string, userId?: number): Observable<Boo.Objects.BatchTicketCreateResponse> {
    return this.http.post<Boo.Objects.BatchTicketCreateResponse>(`${this.baseUrl}/CreateTicketBatch`, {
        customerIds: customerIds,
        ticketTypeId: ticketTypeId,
        startDate: startDate,
        note: note,
        userId: userId
    });
  }

  save(ticket: Boo.Objects.Ticket): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/Save`, ticket);
  }

  createSystemTicket(
    customerId: number,
    userId: number,
    reasonForTicket: string,
    ticketTypeId: number,
    scheduledDate: Date,
    dueDate: Date,
    priority: number
  ): Observable<any> {
    return this.http.post(`${this.baseUrl}/CreateSystemTicket`, {
        customerId: customerId,
        userId: userId,
        reasonForTicket: reasonForTicket,
        ticketTypeId: ticketTypeId,
        scheduledDate: scheduledDate,
        dueDate: dueDate,
        priority: priority
    });
  }

  get(ticketId: number): Observable<Boo.Objects.Ticket> {
    const params = new HttpParams().set('ticketId', ticketId);

    return this.http.get<Boo.Objects.Ticket>(`${this.baseUrl}/Get`, { params: params });
  }

  hasHadUrlUnreachableTicket(customerId: number): Observable<boolean> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<boolean>(`${this.baseUrl}/HasHadUrlUnreachableTicket`, { params: params });
  }

  lockTicket(ticketId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/LockTicket`, ticketId);
  }

  unlockTicket(ticketId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/UnlockTicket`, ticketId);
  }

  getLastCompleted(customerId: number, ticketTypeId: number): Observable<Boo.Objects.TicketSummary> {
    const  params = new HttpParams()
      .set('customerId', customerId)
      .set('ticketTypeId', ticketTypeId);

    return this.http.get<Boo.Objects.TicketSummary>(`${this.baseUrl}/GetLastCompleted`, { params: params });
  }

  isTicketAppointmentConflicting(ticketId: number, assignedUserId: number, startDateTime: Date, endDateTime: Date): Observable<boolean> {
    return this.getAppointmentIdsByAssignedUser(assignedUserId, startDateTime, endDateTime)
      .pipe(
        map(ids => ids.some(id => id !== ticketId))
      );
  }
}
