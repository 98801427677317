<div>
	<ng-container *ngIf="hasItems">
		<ul class="checklist list-group">
			<ng-container *ngFor="let $data of items">
				<li (click)="toggleItem($data)" [class.checklist-item-complete]="$data.isComplete()" [class.list-group-item-success]="$data.isComplete()" class="checklist-item list-group-item">
					<i [hidden]="!($data.isComplete())" class="fa fa-check-circle"></i>
					<i [hidden]="!(!$data.isComplete())" class="far fa-circle"></i>
					<span class="checklist-item-title">{{$data.title}}</span>
				</li>
			</ng-container>
		</ul>
	</ng-container>
</div>