<div [loadingOverlay]="isLoading()">
	<ng-container *ngIf="isEnabled()">
		<div class="card card-danger">
			<div class="card-header">
				Register Domain
			</div>
			<div class="card-body">
				<ng-container *ngIf="!isRegistrationSupported">
					<div class="alert alert-warning">
						The Website Host
						<strong>{{website.WebsiteAccount.HostName()}}</strong>
						does not support domain registration, so a pre-owned domain must be used and manually configured.
					</div>
				</ng-container>
				<div class="form-group">
					<label>Domain</label>
					<div class="input-group">
						<input [dataValidation]="{ placement: 'top', value: requestedDomain }" [ngModel]="requestedDomain()" (ngModelChange)="requestedDomain($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="isRequestedDomainAvailable()" [readonly]="isRequestedDomainAvailable()" type="text" class="form-control"/>
						<ng-container *ngIf="isRegistrationSupported">
							<button [disabled]="isRequestedDomainAvailable()" (click)="checkDomainAvailability()" class="btn btn-primary">Check Availability</button>
						</ng-container>
						<button (click)="saveDomainIsRegistered()" class="btn btn-danger">Use Pre-Owned Domain</button>
					</div>
				</div>
				<ng-container *ngIf="isRegistrationSupported">
					<div [hidden]="!isRequestedDomainAvailable()" class="row animated bounceInDown">
						<div class="col-md-3 offset-md-3">
							<div class="form-group d-grid gap-2">
								<button (click)="registerDomain()" type="button" class="btn btn-success btn-lg">
									Register
									<strong>{{requestedDomain()}}</strong>
								</button>
							</div>
						</div>
						<div class="col-md-3 d-grid gap-2">
							<button (click)="clearRequestedDomain()" type="button" class="btn btn-danger btn-lg">Start Over</button>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</ng-container>
</div>
