import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import ITicketNavigationLink = app.tsmodels.interfaces.ITicketNavigationLink;

@Component({
	selector: 'app-components-ticket-ticketnavigation',
	templateUrl: './TicketNavigation.component.html'
})
export class TicketNavigationComponent extends BaseComponent {
    public navigationLinks: ITicketNavigationLink[];

    public activate(params: app.ticket.components.interfaces.ITicketNavigationViewModelActivateParams): JQueryPromise<void> {
        return super.activate(params).then(() => {
           this.navigationLinks = params.featureConfig.navigationLinks;
        });
    }
}
