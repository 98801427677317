import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import TaskHelper from 'app/specialist/TaskHelper';
import { WorkflowKeyValuePairService } from '../../../services/workflow-keyvaluepair.service';
import WorkflowHelper from '../../../shared/WorkflowHelper';
import Utils from '../../../shared/utils';
import { OnsiteCopyDetailService } from '../../../services/onsite-copy-detail.service';


@Component({
	selector: 'app-components-ticket-onsitecopyrequest',
	templateUrl: './OnsiteCopyRequest.component.html'
})
export class OnsiteCopyRequestComponent extends BaseComponent {
    public onsiteCopyDetails: KnockoutObservable<Boo.Objects.OnsiteCopyDetails> = ko.observable();
    public orderReason: KnockoutObservable<string> = ko.observable();
    public boostToPublish: KnockoutObservable<boolean> = ko.observable();
    public linkBuildAfterPublish: KnockoutObservable<string> = ko.observable();

    constructor(
      private workflowKeyValuePairService: WorkflowKeyValuePairService,
      private onsiteCopyDetailService: OnsiteCopyDetailService) {
        super();
    }

    public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        return super.activate(params)
            .then(() => {
                return Utils.wrapDfd(this.onsiteCopyDetailService.getByTaskId(params.ticket.AssociatedTaskId()));
            })
            .then((onsiteCopyDetails) => {
                this.onsiteCopyDetails(onsiteCopyDetails);

                this.orderReason(TaskHelper.getOnsiteCopyOrderReason(onsiteCopyDetails.BillingTask));
                
                this.workflowKeyValuePairService.getByTaskId(onsiteCopyDetails.BillingTask.TaskId)
                    .subscribe((keyValuePairs: Boo.Objects.WorkflowKeyValuePair[]) => {
                        this.boostToPublish(WorkflowHelper.getIsBoostImplementingByResponsibility(keyValuePairs));
                    });
                this.linkBuildAfterPublish('N / A');
                this.linkBuildAfterPublish(TaskHelper.getOnsiteCopyLinkBuildAfterPublish(onsiteCopyDetails.BillingTask) ? 'Yes' : 'No');
            })
            .fail((msg: string) => {
                toastr.error(msg);
            }).always(() => {
                this.isLoading(false);
            });
    }
}
