import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureGroupService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/featuregroups`;

  constructor(private http: HttpClient) { }

  getTranslated(groupId: number, languageId: number): Observable<Boo.Objects.SEOPresence.FeatureGroup> {
    const params = new HttpParams()
      .set('groupId', groupId)
      .set('languageId', languageId);

    return this.http.get<Boo.Objects.SEOPresence.FeatureGroup>(`${this.baseUrl}/getTranslated`, { params: params });
  }

  getAllTranslated(languageId: number): Observable<Boo.Objects.SEOPresence.FeatureGroup[]> {
    const params = new HttpParams().set('languageId', languageId);
    return this.http.get<Boo.Objects.SEOPresence.FeatureGroup[]>(`${this.baseUrl}/getAllTranslated`, { params: params });
  }
}
