<div class="row">
  <div class="col-md-12">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th>
            Halo Keywords
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let keyword of haloKeywords">
          <td>
            {{ getKeywordDisplayText(keyword) }}
            <span class="badge rounded-pill bg-secondary">{{"rank: " + keyword.Rank}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>