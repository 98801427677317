import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NonworkableTaskTypeService } from '../../../../../services/nonworkable-tasktype.service';
import FlagTypes = Boo.Objects.Work.SeoMinute.Enums.FlagTypes;

@Component({
  selector: 'app-components-managecustomer-allocatework-nonworkabletasktypeflag',
  templateUrl: './NonworkableTaskTypeFlag.component.html',
  styleUrls: ['../../AllocateWork.component.scss']
})
export class NonworkableTaskTypeFlagComponent {
  @Input() customerId: number;
  @Input() taskTypeId: number;
  @Input() taskTypeName: string;
  @Input() flag: Boo.Objects.Work.SeoMinute.TaskTypeFlag;
  @Output() refresh = new EventEmitter();

  constructor(private nonworkableTaskTypeService: NonworkableTaskTypeService) { }

  reactivateNonWorkableTaskType(): void {
    this.nonworkableTaskTypeService.setIsWorkable(this.customerId, this.taskTypeId)
      .subscribe(() => {
        toastr.success(`${this.taskTypeName} has been reactivated`);
        this.refresh.emit();
      }, (err) => toastr.error(err));
  }
}
