import { Directive, Input, ElementRef, OnChanges, AfterViewInit, OnDestroy, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[timePicker]'
})
export class TimePickerDirective implements AfterViewInit {
  @Input()
  public DateTime: KnockoutObservable<moment.Moment>;

  private $element: JQuery;
  constructor(elementRef: ElementRef) {
    this.$element = $(elementRef.nativeElement);
  }

  public ngAfterViewInit(): void {
    if (!this.$element.hasClass('form-control')) {
        this.$element.addClass('form-control');
    }

    this.$element.timeEntry({ ampmPrefix: ' ', spinnerImage: '' }).change(() => {
        const newTime = this.$element.val();
        const dateTimeString = moment(this.DateTime()).format('L') + ' ' + newTime;
        this.DateTime(moment(dateTimeString));
    });

    this.$element.timeEntry('setTime', moment(this.DateTime()).toDate());
  }
}