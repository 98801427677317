import { Component } from '@angular/core';
import System from 'framework/System';
import IPriorityViewPublicApi = app.interfaces.IPriorityViewPublicApi;
import { TaskPostService } from '../../../services/taskpost.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-managecustomer-editblogpost',
  templateUrl: './EditBlogPost.component.html'
})
export class EditBlogPostComponent implements IActivatable {
    public title = 'Edit Blog Post';
    public taskPost: KnockoutObservable<Boo.Objects.TaskPost>;
    private priorityViewApi: IPriorityViewPublicApi;

    constructor(private taskPostService: TaskPostService) { }

    public activate(params: app.managecustomer.interfaces.IEditBlogPostActivateParams): JQueryPromise<void> {
        this.priorityViewApi = params.priorityViewApi;

        this.taskPost = ko.observable(params.taskPost);
        return System.emptyPromise();
    }

    public saveBlog(): JQueryPromise<void> {
        return Utils.wrapDfd(this.taskPostService.updateTaskPostContent(this.taskPost().TaskPostId, this.taskPost().Content))
            .then(x => {
                toastr.success('Blog updated.');
                this.priorityViewApi.resolve();
            })
            .fail((displayMessage: string) => {
                toastr.error(displayMessage);
            });
    }

    public close(): void {
        this.priorityViewApi.reject();
    }
}
