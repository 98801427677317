import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SessionStorageService } from '../../../services/session-storage.service';
import RejectionFactory from '../../../factories/RejectionFactory';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-components-specialist-rejecttask',
  templateUrl: './reject-task.component.html'
})
export class RejectTaskComponent implements OnInit {
  @Input() rejectionCtrl: UntypedFormGroup;
  @Input() rejectableByRejectionType: boolean = false;
  @Input() taskType: Boo.Objects.Enums.TaskTypeEnum;
  @Output() saved: EventEmitter<any> = new EventEmitter();
  @Output() canceled: EventEmitter<any> = new EventEmitter();
  taskRejectionTypes: Boo.Objects.RejectionReasonType[] = [];
  isLoading: boolean;

  constructor(private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    if (this.rejectableByRejectionType) {
      this.isLoading = true;
      this.sessionStorageService.getStaticData()
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(
          staticData => this.taskRejectionTypes = RejectionFactory.getRejectionReasonTypes(this.taskType).map(x => staticData.RejectionReasonTypes.find(y => y.RejectionReasonTypeId === x)),
          error => console.error(error)
        );
    }
  }

  cancel(): void {
    this.canceled.emit();
  }

  save(): void {
    if (!this.rejectionCtrl.valid) {
      this.rejectionCtrl.markAllAsTouched();
      return;
    }

    this.saved.emit();
  }
}