import { Component, Input, OnInit } from '@angular/core';
import { TaskService } from '../../../../services/task.service';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-onsite-task-information',
  templateUrl: './onsite-task-information.component.html',
  styleUrls: ['../customer-campaign/customer-campaign.component.scss']
})
export class OnsiteTaskInformationComponent implements OnInit {

  @Input() websiteUrl: Boo.Objects.WebsiteUrl;
  @Input() customerId: number;
  @Input() restrictions: Boo.Objects.Enums.CustomerCampaignRestrictions[];
  @Input() reviewSource: Boo.OnsiteRecommendations.Models.Enums.ReviewSources;

  websiteUrlTask: Boo.Objects.WebsiteUrlTask;
  taskActionPending: boolean = false;
  taskTypeLabel: string
  allowVerification: boolean;
  allowApproveReject: boolean;

  customerCampaignRestrictions_DisableTaskActions = Boo.Objects.Enums.CustomerCampaignRestrictions.DisableTaskActions;

  constructor(
    private customerCampaignService: CustomerCampaignService,
    private taskService: TaskService
  ) { }

  ngOnInit(): void {
    this.websiteUrlTask = this.websiteUrl.WebsiteUrlTask;
    this.taskTypeLabel = this.customerCampaignService.getTaskTypeLabelMap()[this.websiteUrlTask.TaskTypeId];
    this.calculateButtonDisplay();
  }

  reloadTask(taskId: number): void {
    this.customerCampaignService.getTaskInfo(this.customerId, taskId).subscribe(task => {
      this.websiteUrlTask.TaskStatusId = task.TaskStatusId;
      this.websiteUrlTask.WorkflowState = task.WorkflowState;
      this.websiteUrlTask.WorkflowStateId = task.WorkflowStateId;
      this.websiteUrlTask.TaskCompletedDate = task.TaskCompletedDate;
      this.websiteUrlTask.TaskPublishedDate = task.TaskPublishedDate;
      this.websiteUrlTask.TaskVerifiedDate = task.TaskVerifiedDate;
      this.websiteUrlTask.TaskActionUrl = task.TaskActionUrl;
      this.websiteUrlTask.HavePriorTasksBeenRejected = task.HavePriorTasksBeenRejected;
      this.websiteUrlTask.DisplayStatus = task.DisplayStatus;
      this.websiteUrlTask.DisplayStatusId = task.DisplayStatusId;

      this.customerCampaignService.calculateReadOnlyState(this.websiteUrl);
      this.calculateButtonDisplay();
      this.taskActionPending = false;
    });
  }

  calculateButtonDisplay(): void {
    this.allowVerification = (this.websiteUrlTask.TaskPublishedDate != null || this.websiteUrlTask.WorkflowStateId == Boo.Objects.Enums.WorkflowStates.Completed)
      && this.websiteUrlTask.TaskVerifiedDate == null;
    this.allowApproveReject = this.websiteUrlTask.WorkflowStateId == Boo.Objects.Enums.WorkflowStates.ReadyForCsrApproval
      && this.websiteUrlTask.TaskStatusId != Boo.Objects.Enums.TaskStatusEnum.ReconcileComplete;
  }

  setTaskActionPending(pending: boolean): void {
    this.taskActionPending = pending;
  }

  verifyTask(): void {
    this.taskActionPending = true;
    this.taskService.verify(this.websiteUrlTask.TaskId).subscribe((result: Boo.Objects.VerificationResult) => {
      if (!result.IsVerified) {
        toastr.error(result.Reason);
      } else {
        toastr.success(result.Reason);
        this.reloadTask(this.websiteUrlTask.TaskId);
      }
      this.taskActionPending = false;
    })
  }
}
