import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from 'rxjs/operators';
import { SeoWorkService } from '../../../../services/seo-work.service';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { TaskService } from '../../../../services/task.service';

@Component({
  selector: 'app-components-managecustomer-pastperiods',
  templateUrl: './PastPeriods.component.html',
  styleUrls: ['../AllocateWork.component.scss']
})
export class PastPeriodsComponent implements OnInit {
  @Input() customer: CustomerObservable;
  @Input() seoWorkSettings: Boo.Objects.Work.SeoMinute.SeoWorkSettings;
  @Output() refreshSettings = new EventEmitter();
  @Output() editWorkRequest = new EventEmitter();
  isLoading = false;
  work: WorkRequestWithTaskInsertedDate[];

  constructor(private seoWorkService: SeoWorkService, private modalService: NgbModal, private taskService: TaskService) { }

  ngOnInit(): void {
    this.updateWork(this.seoWorkSettings.PastWorkRequestDetails);
  }

  deleteItem(content: TemplateRef<any>, item: Boo.Objects.Work.SeoMinute.WorkRequestDetail): void {
    this.modalService.open(content).result
      .then(() => {
        this.isLoading = true;
        this.delete(item);
      }, (dismiss) => {});
  }

  private delete(item: Boo.Objects.Work.SeoMinute.WorkRequestDetail): void {
    this.seoWorkService.deleteSeoWorkRequest(item).pipe(
      switchMap(() => {
        toastr.success(`${item.WorkDisplayName} - ${item.OrderTaskId} deleted`);
        return this.seoWorkService.getOpenWorkRequestsForPastPeriods(this.customer.CustomerId());
      })
    ).subscribe(
      (updatedWork) => {
        this.updateWork(updatedWork);

        // this timeout is necessary in order to wait for the jobs that handle updating the current minutes before calling for this refresh.
        setTimeout(() => {
          this.refreshSettings.emit();
          this.isLoading = false;
        }, 5000); // we are going off 5 seconds to play it safe, but most jobs should finish in under 1 second.
      },
      (err) => {
        this.isLoading = false;
        toastr.error(err);
      });
  }

  editItem(item: Boo.Objects.Work.SeoMinute.WorkRequestDetail): void {
    let taskType = this.seoWorkSettings.TaskTypes.find(x => x.TaskTypeId === item.WorkRequest.WorkTypeCreationId);
    PriorityViewModel.show(
      'app-components-managecustomer-workrequestedit',
      {
        customer: this.customer,
        workRequestDetail: item,
        taskType: taskType,
        hasValidAccessDetails: this.seoWorkSettings.HasValidAccessDetails
      }
    )
    .always(() => this.editWorkRequest.emit());
  }

  private updateWork(work: Boo.Objects.Work.SeoMinute.WorkRequestDetail[]): void {
    this.work = work as WorkRequestWithTaskInsertedDate[];
    this.taskService.getPendingWorkForCustomer(this.customer.CustomerId())
      .subscribe(
        (pendingTasks: Boo.Objects.PendingTaskDetail[]) => {
          this.work.map(x => x.TaskInsertedDate = pendingTasks.find(y => y.TaskId === x.OrderTaskId).InsertedDate);
        }
      );
  }
}

interface WorkRequestWithTaskInsertedDate extends Boo.Objects.Work.SeoMinute.WorkRequestDetail {
  TaskInsertedDate: Date
}