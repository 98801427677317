import { Component, OnInit } from '@angular/core';
import System from 'framework/System';
import ITaskFilesPublicApi = app.components.interfaces.ITaskFilesPublicApi;
import ITaskFilesPublicApiCallback = app.components.interfaces.ITaskFilesPublicApiCallback;
import { OnsiteRecommendationService } from '../../../../../services/onsite-recommendation.service';


@Component({
  selector: 'app-components-specialist-actioncomponents-onsiterecommendationimplementationreview',
  templateUrl: './OnsiteRecommendationImplementationReview.component.html'
})
export class OnsiteRecommendationImplementationReviewComponent implements OnInit, IActionWorkspace, IActivatable {

  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;
  taskFilesApi: ITaskFilesPublicApi;
  taskFilesApiCallback: ITaskFilesPublicApiCallback;
  onsiteRecommendationImplementationSummary: Boo.Objects.OnsiteImplementation.OnsiteRecommendationImplementationSummary;
  onsiteChangeFileUrl: string;
  newTitle: string;
  newDescription: string
  newH1: string;
  newH2: string;
  newAltImage: string;

  constructor(private onsiteRecommendationService: OnsiteRecommendationService) {}

  ngOnInit(): void {
    this.onsiteRecommendationService.getImplementationSummary(this.currentAction().Task.TaskId)
      .subscribe(summary => {
        this.onsiteRecommendationImplementationSummary = summary;
      });

    this.onsiteChangeFileUrl = this.currentAction().TaskDetails.OnsiteRecommendation.S3Url;
    this.newTitle = this.currentAction().TaskDetails.OnsiteRecommendation.TitleNew;
    this.newDescription = this.currentAction().TaskDetails.OnsiteRecommendation.DescriptionNew;
    this.newH1 = this.currentAction().TaskDetails.OnsiteRecommendation.H1New;
    this.newH2 = this.currentAction().TaskDetails.OnsiteRecommendation.H2New;
    this.newAltImage = this.currentAction().TaskDetails.OnsiteRecommendation.AltImageNew;

    this.taskFilesApiCallback = (api: ITaskFilesPublicApi) => { this.taskFilesApi = api; }
  }

  activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {
    params.controlViewModel(this);
    this.currentAction = params.currentAction;
    this.actionViewModel = ko.observable(null);
    return System.emptyPromise();
  }

  isStepReadyToReject(): boolean {
    return true;
  }

  isStepReadyToSave(): boolean {
    return this.currentAction().Task.TaskTypeSummary.IsPersistable;
  }

  isStepReadyToDelete(): boolean {
    return false;
  }

  isStepReadyToComplete(): boolean {
    return true
  }
}
