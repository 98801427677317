<div class="d-flex align-items-center">
  <div class="keyword-column">
    <button class="btn btn-secondary me-4 btn-sm" type="button" (click)="removeKeyword()" title="Remove Keyword">
      <i class="fas fa-times fa-no-margin"></i>
    </button>
    @if (keywordSuggestion.SourcePageSuggestionId === pageSuggestion.PageSuggestionId) {
      <small><i class="text-primary fas fa-star" title="Recommended for this page"></i></small>
    }
    {{ keywordSuggestion.KeywordPhrase }}
  </div>
  <div class="keyword-column-data">
    @if (keywordSuggestion.Rank) {
      <span>Rank: {{keywordSuggestion.Rank}}</span>
    }
    @if (!keywordSuggestion.Rank && keywordSuggestion.SourceId === keywordSuggestionSources_OpenAI) {
      <span>Rank: {{unknownRank}}</span>
    }
  </div>
  <div class="keyword-column-data">
    @if (keywordSuggestion.VolumeDescription) {
      <span>Volume: <span [ngClass]="{
        'text-warning': keywordSuggestion.VolumeDescription === 'Low' || keywordSuggestion.VolumeDescription === 'Medium',
        'text-success': keywordSuggestion.VolumeDescription === 'High'}">{{keywordSuggestion.VolumeDescription}}</span>
      </span>
    }
  </div>
  <div class="keyword-column-data">
    @if (keywordSuggestion.CompetitionDescription) {
      <span>Competition: <span [ngClass]="{
        'text-warning': keywordSuggestion.CompetitionDescription === 'High' || keywordSuggestion.CompetitionDescription === 'Medium',
        'text-success': keywordSuggestion.CompetitionDescription === 'Low'}">{{keywordSuggestion.CompetitionDescription}}</span>
      </span>
    }
  </div>
</div>