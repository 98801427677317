<ul class="list-group">
	<li class="list-group-item">
		<strong>Company Name</strong>
		<span class="float-end" name="companyName">{{customer.Name}}</span>
	</li>
	<li class="list-group-item">
		<strong>Customer ID</strong>
		<span class="float-end" name="customerId">{{customer.CustomerId}}</span>
	</li>
	<ng-container *ngIf="taskDetails">
		<li class="list-group-item">
			<strong>Vertical</strong>
			<span class="float-end">{{taskDetails && taskDetails.Vertical ? taskDetails.Vertical.Name : 'N/A'}}</span>
		</li>
	</ng-container>
	<li class="list-group-item">
		<strong>Partner Name</strong>
		<span class="float-end">{{customer.Partner.Name}}</span>
	</li>
	<li class="list-group-item">
		<strong>Language</strong>
		<span class="float-end">{{customer.Language.Name}}</span>
	</li>
	<li class="list-group-item">
		<strong>Country</strong>
		<span class="float-end">{{customer.Country.Name}}</span>
	</li>
	<ng-container *ngIf="taskDetails && taskDetails.OnsiteCopyDetails">
		<li class="list-group-item">
			<strong>Parent Task ID</strong>
			<span class="float-end" name="parentTaskId">{{taskDetails.OnsiteCopyDetails.BillingTask.TaskId}}</span>
		</li>
	</ng-container>
</ul>
