import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export class UniqueKeywordSuggestionValidator {
  get isNational(): boolean {
    return this._isNational();
  }

  private keywordRecords: KeywordSuggestionValidationRecord[] = [];
  private _isNational: () => boolean;

  constructor(urlSuggestions: Boo.Objects.LegacyUrlSuggestion[], haloKeywordSuggestions: Boo.Objects.LegacyKeywordSuggestion[], isNational: () => boolean) {
    urlSuggestions.forEach(urlSuggestion =>
      urlSuggestion.KeywordSuggestions.forEach(keywordSuggestion =>
        this.keywordRecords.push({keyword: keywordSuggestion.Keyword, area: keywordSuggestion.Area})));

    haloKeywordSuggestions.forEach(keywordSuggestion => this.keywordRecords.push({keyword: keywordSuggestion.Keyword, area: keywordSuggestion.Area}));

    this._isNational = isNational;
  }

  getValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this._isNational()) {
        return this.isNationalKeywordUnique(control.get('keyword')?.value) ? null : {keywordNotUnique: true};
      } else {
        return this.isLocalKeywordUnique(control.get('keyword')?.value, control.get('area')?.value) ? null : {keywordNotUnique: true};
      }
    }
  }
  
  addKeywordSuggestion(keywordSuggestion: Boo.Objects.LegacyKeywordSuggestion): void {
    this.keywordRecords.push({keyword: keywordSuggestion.Keyword, area: keywordSuggestion.Area});
  }

  addKeywordSuggestions(keywordSuggestions: Boo.Objects.LegacyKeywordSuggestion[]): void {
    this.keywordRecords = this.keywordRecords.concat(keywordSuggestions.map(keywordSuggestion => ({keyword: keywordSuggestion.Keyword, area: keywordSuggestion.Area})));
  }

  addHaloKeywords(haloKeywords: Boo.Objects.WebsiteKeywordTracking[]): void {
    this.keywordRecords = this.keywordRecords.concat(haloKeywords.map(keyword => ({keyword: keyword.Keyword.Name, area: keyword.Area?.Name})));
  }

  addWebsiteUrlKeywords(websiteUrlKeywords: Boo.Objects.WebsiteUrlKeyword[]): void {
    this.keywordRecords = this.keywordRecords.concat(websiteUrlKeywords.map(keyword => ({keyword: keyword.Keyword.Name, area: keyword.Area?.Name})));
  }

  removeKeywordSuggestion(keywordSuggestion: Boo.Objects.LegacyKeywordSuggestion): void {
    this.keywordRecords = this.keywordRecords.filter(keywordRecord => keywordRecord.keyword !== keywordSuggestion.Keyword || keywordRecord.area !== keywordSuggestion.Area);
  }

  isKeywordUnique(keywordSuggestion: Boo.Objects.LegacyKeywordSuggestion): boolean {
    return this._isNational() ? this.isNationalKeywordUnique(keywordSuggestion.Keyword) : this.isLocalKeywordUnique(keywordSuggestion.Keyword, keywordSuggestion.Area);
  }

  private isLocalKeywordUnique(keyword: string, area: string): boolean {
    return !this.keywordRecords.some(keywordRecord => keywordRecord.keyword === keyword && keywordRecord.area === area);
  }

  private isNationalKeywordUnique(keyword: string): boolean {
    return !this.keywordRecords.some(keywordRecord => keywordRecord.keyword === keyword);
  }
}

interface KeywordSuggestionValidationRecord {
  keyword: string,
  area: string
}