import QaDisplayConfig = app.specialist.qa.interfaces.QaDisplayConfig
import TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum;
import QaCriteriaGroupEnum = Boo.Qa.QaCriteriaGroupEnum;
import QaCriteriaGroup = Boo.Qa.QaCriteriaGroup;
import { QaCriteriaDefinitionProperty } from '../enums/qa-criteria-definition-property';

export default class QaDisplayConfigFactory {
    public static create(internalTaskType: TaskTypeEnum, criteriaGroups: QaCriteriaGroup[]): QaDisplayConfig[] {
        let displayConfigs: QaDisplayConfig[];

        switch (internalTaskType) {
            case TaskTypeEnum.InternalTaskQaClassifiedBusinessListings:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ClassifiedBusinessListing]),
                        displayItem: QaCriteriaDefinitionProperty.Name,
                        showComment: false,
                        showTitle: false
                    }
                ];
                break;
            case TaskTypeEnum.InternalTaskQaLocalBusinessCitations:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.LocalBusinessCitation]),
                        displayItem: QaCriteriaDefinitionProperty.Name,
                        showComment: false,
                        showTitle: false
                    }
                ];
                break;
            case TaskTypeEnum.InternalTaskQaKeywordResearch:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchQualityKeyword]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchTargetPageSelection]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchAddressedExcludedKwRequests]),
                        displayItem: QaCriteriaDefinitionProperty.Name
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchExcludedMainKwRationale]),
                        displayItem: QaCriteriaDefinitionProperty.Name
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchRequiredHaloKeywords]),
                        displayItem: QaCriteriaDefinitionProperty.Name
                    }
                ];
                break;
            case TaskTypeEnum.InternalTaskQaKeywordResearchRedo:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchRedoQualityKeyword]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchRedoTargetPageSelection]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchRedoAddressedExcludedKwRequests]),
                        displayItem: QaCriteriaDefinitionProperty.Name
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchRedoExcludedMainKwRationale]),
                        displayItem: QaCriteriaDefinitionProperty.Name
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchRedoRequiredHaloKeywords]),
                        displayItem: QaCriteriaDefinitionProperty.Name
                    }
                ];
                break;
            case TaskTypeEnum.InternalTaskQaKeywordResearchStrategyUpdate:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchStrategyUpdateQualityKeyword]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchStrategyUpdateTargetPageSelection]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchStrategyUpdateAddressedExcludedKwRequests]),
                        displayItem: QaCriteriaDefinitionProperty.Name
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchStrategyUpdateExcludedMainKwRationale]),
                        displayItem: QaCriteriaDefinitionProperty.Name
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchStrategyUpdateRequiredHaloKeywords]),
                        displayItem: QaCriteriaDefinitionProperty.Name
                    }
                ];
                break;
            case TaskTypeEnum.InternalTaskQaKeywordResearchStrategyUpdateRedo:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchStrategyUpdateRedoQualityKeyword]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchStrategyUpdateRedoTargetPageSelection]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchStrategyUpdateRedoAddressedExcludedKwRequests]),
                        displayItem: QaCriteriaDefinitionProperty.Name
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchStrategyUpdateRedoExcludedMainKwRationale]),
                        displayItem: QaCriteriaDefinitionProperty.Name
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.KeywordResearchStrategyUpdateRedoRequiredHaloKeywords]),
                        displayItem: QaCriteriaDefinitionProperty.Name
                    }
                ];
                break;
            case TaskTypeEnum.InternalTaskQaContentReviewBlog:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ContentReviewBlogAcceptedCoversEntireTier])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ContentReviewBlogRejectedCoversEntireTier])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ContentReviewBlogAcceptedStockComment])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ContentReviewBlogAcceptedProfessional])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ContentReviewBlogRejectedStockComment])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ContentReviewBlogRejectedProfessional])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ContentReviewBlogGrammatical])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ContentReviewBlogImplementation])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ContentReviewBlogPromotional])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ContentReviewBlogNegative])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ContentReviewBlogEditingQuality]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    }
                ];
                break;
            case TaskTypeEnum.InternalTaskQaOnsiteBloggingReview:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.OnsiteBloggingReviewAutoFail]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.OnsiteBloggingReviewEditorsFeedback]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.OnsiteBloggingReviewEditingQuality]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    }
                ];
                break;
            case TaskTypeEnum.InternalTaskQaOnsiteCopyReview:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.OnsiteCopyReviewAutoFail]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.OnsiteCopyReviewEditorsFeedback]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.OnsiteCopyReviewEditingQuality]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                ];
                break;
            case TaskTypeEnum.InternalTaskQaPremiumMonthlyCampaignUpdate:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumMonthlyCampaignUpdateDates])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumMonthlyCampaignUpdateBranding])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumMonthlyCampaignUpdateData])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumMonthlyCampaignUpdateKwrRank])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumMonthlyCampaignUpdateScreenShots])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumMonthlyCampaignUpdateTables])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumMonthlyCampaignUpdateGmb])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumMonthlyCampaignUpdateSavedFormatting])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumMonthlyCampaignUpdateClickTracking])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumMonthlyCampaignUpdateTicket])
                    }
                ];
                break;
            case TaskTypeEnum.InternalTaskQaPremiumPreliminaryAudit:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumPreliminaryAuditBranding])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumPreliminaryAuditExecutiveSummary])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumPreliminaryAuditKeywords])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumPreliminaryAuditGeneral])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumPreliminaryAuditScreenshots])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumPreliminaryAuditPreviousData])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumPreliminaryAuditReport])
                    }
                ];
                break;
            case TaskTypeEnum.InternalTaskQaProactiveUnderperformingKeyword:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ProactiveUnderperformingKeywordQaLaunchpad])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ProactiveUnderperformingKeywordQaGlobal])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ProactiveUnderperformingKeywordQaPageSpecific])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ProactiveUnderperformingKeywordQaSummaryandRecommendation])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ProactiveUnderperformingKeywordQaIncorrectItem]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ProactiveUnderperformingKeywordQaSpellingandGrammar])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.ProactiveUnderperformingKeywordQaNotesFilled])
                    }
                ];
                break;
            case TaskTypeEnum.InternalTaskQaPremiumBacklink:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumBacklinkReportedCorrectly]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    }, {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumBacklinkBlogPublisherQuality]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumBacklinkClientLink]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumBacklinkPublisher]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumBacklinkArticleParagraph]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumBacklinkTitle]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumBacklinkNegativity]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumBacklinkFillerContent]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumBacklinkFormattingFlow]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumBacklinkGrammarPunctuationTypos]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.PremiumBacklinkReviewsAuthority]),
                        displayItem: QaCriteriaDefinitionProperty.Points
                    }
                ];
                break;
            case TaskTypeEnum.InternalTaskQaOnsiteRecommendationReview:
                displayConfigs = [
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.OnsiteRecommendationReviewStyleGuideStandards])
                    },
                    {
                        groups: this.filterGroups(criteriaGroups, [QaCriteriaGroupEnum.OnsiteRecommendationReviewGrammar])
                    }
                ];
                break;
            default:
                throw Error(`Cannot find QA display config for task type (${internalTaskType})`);
        }

        return displayConfigs.filter(x => x.groups.length !== 0);
    }

    private static filterGroups(groups: QaCriteriaGroup[], filterFor: QaCriteriaGroupEnum[]): QaCriteriaGroup[] {
        return groups.filter(x => filterFor.includes(x.QaCriteriaGroupEnum));
    }
}
