import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import FloatingTabPanelIcon = app.components.enums.FloatingTabPanelIcon;
import TitleBadgeClass = app.components.enums.FloatingTabPanelTitleBadgeClass;
import IFloatingTabPanelPublicApi = app.components.interfaces.IFloatingTabPanelPublicApi;
import { param } from 'jquery';

@Component({
  selector: 'app-components-ticket-connectedusersfloatingpanel',
  templateUrl: './ConnectedUsersFloatingPanel.component.html'
})
export class ConnectedUsersFloatingPanelComponent extends BaseComponent {
    public numberOfConnectedUsers: KnockoutObservable<number>;
    public showPanel: KnockoutObservable<boolean>;
    public connectedUsers: KnockoutObservableArray<string>;
    protected floatPanel: IJQueryFloatPanel;
    private customerId: number;
    private floatingTabPanelApi: KnockoutObservable<IFloatingTabPanelPublicApi>;

    public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        return super.activate(params).then(() => {
            this.numberOfConnectedUsers = ko.observable(0);
            this.showPanel = ko.observable(false);
            this.customerId = params.customer.CustomerId();
            this.floatingTabPanelApi = params.floatingTabPanelApi;

            if (params.floatingTabPanelApi().addPanel) {
                this.addPanel();
            }

            params.floatingTabPanelApi.subscribe(() => {
                this.addPanel();
            });
        });
    }

    public addPanel(): void {
        this.floatingTabPanelApi().addPanel({
            title: 'Connected Users',
            titleIcon: FloatingTabPanelIcon.User,
            titleBadge: this.numberOfConnectedUsers,
            titleBadgeClass: TitleBadgeClass.Danger,
            showPanel: this.showPanel,
            composition: {
                selector: 'app-components-managecustomer-connectedusers',
                options: {
                    customerId: this.customerId,
                    onChanged: this.onChanged.bind(this),
                    scope: 'Ticket'
                }
            }
        });
    }

    public onChanged(users: string[]): void {
        this.numberOfConnectedUsers(users.length);
        this.showPanel(users.length > 1);
    }
}
