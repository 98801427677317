<div>
  <ng-container *ngIf="customer.Considerations">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-warning">
          <strong>Customer Considerations:</strong>
          <span>{{customer.Considerations}}</span>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="col-md-7">
      <form role="form">
        <div>
          <ng-container *ngIf="currentAction()">
            <div class="card card-info">
              <div class="card-header">
                <a data-toggle="collapse" href="#instructions">
                  <h4>Instructions</h4>
                </a>
              </div>
              <div id="instructions" class="collapse show">
                <div class="card-body">
                  <div class="form-group">
                    <div class="line-breaks">{{instructions}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-info">
              <div class="card-header">
                <h4>Workspace</h4>
              </div>
              <div id="workspace" class="collapse show">
                <div class="card-body">
                  <div class="form-group">
                    <label>Research Sources</label>
                    <p>{{researchSources}}</p>
                  </div>
                  <div class="form-group">
                    <label>Page Title</label>
                    <p class="help-block">Condense the title within the blog content to provide an effective Page Title,
                      please then add Company Name, separated by a pipe.</p>
                    <input [dataValidation]="{ value: onsiteRecommendationsPageTitle, placement: 'top' }"
                      [ngModel]="onsiteRecommendationsPageTitle()"
                      (ngModelChange)="onsiteRecommendationsPageTitle($event)"
                      [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control" />
                  </div>
                  <div class="form-group">
                    <label>Meta Description</label>
                    <p class="help-block">This will be auto-populated. Please review for any truncated words, and ensure
                      it does not terminate inappropriately.</p>
                    <textarea [dataValidation]="{ value: onsiteRecommendationsMetaDescription, placement: 'top' }"
                      [ngModel]="onsiteRecommendationsMetaDescription()"
                      (ngModelChange)="onsiteRecommendationsMetaDescription($event)"
                      [ngModelOptions]="{ standalone: true }" class="form-control" style="height: auto;"></textarea>
                    <span>{{osrMetaDescriptionCount()}}</span>
                  </div>
                  <div class="form-group">
                    <label>Title / H1</label>
                    <input [dataValidation]="{ value: contentTitle, placement: 'top' }" [ngModel]="contentTitle()"
                      (ngModelChange)="contentTitle($event)" [ngModelOptions]="{ standalone: true }" type="text"
                      class="form-control" />
                    <span>{{osrTitleCount()}}</span>
                  </div>
                  <div class="form-group">
                    <label>H2</label>
                    <input [dataValidation]="{ value: contentTitle2, placement: 'top' }"
                      [ngModel]="contentTitle2()" (ngModelChange)="contentTitle2($event)"
                      [ngModelOptions]="{ standalone: true }" type="text" class="form-control" />
                    <span>{{osrTitle2Count()}}</span>
                  </div>
                  <div [dataValidation]="{ value: content, placement: 'top' }" class="form-group">
                    <label>Content</label>
                    <textarea [richText]="{ content: content, wordCount: wordCount, autoGrow: true }"></textarea>
                  </div>
                  <div class="form-group" style="margin-bottom: 80px;">
                    <label>Image</label>
                    <div>
                      <button [dataValidation]="{ placement: 'right', value: imageUrl}" [hidden]="imageUrl()"
                        (click)="uploadImage()" class="btn btn-sm">
                        <span class="fa fa-camera"></span>
                        Upload
                      </button>
                    </div>
                    <div class="btn-group">
                      <ng-container *ngIf="imageUrl()">
                        <button type="button" class="btn btn-outline-secondary" data-toggle="dropdown">
                          <img [hidden]="!imageUrl()" [attr.src]="imageUrl()" class="img-fluid" />
                        </button>
                        <ul class="dropdown-menu">
                          <li class="dropdown-item">
                            <a (click)="uploadImage()" href="javascript:void(0)">
                              <span class="fa fa-camera"></span>
                              Upload
                            </a>
                          </li>
                          <div class="dropdown-divider"></div>
                          <li class="dropdown-item">
                            <a (click)="removeImage()" href="javascript:void(0)">Remove</a>
                          </li>
                        </ul>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-info">
              <div class="card-header">
                <a data-toggle="collapse" href="#pastImages">
									<h4>8 Month Image History</h4>
								</a>
              </div>
              <div id="pastImages" class="collapse show">
								<div class="card-body">
									<div class="row" style="margin: 0px 10px">
										<ng-container *ngFor="let $data of pastImageUrls">
												<img src={{$data}} style="max-height: 120px; max-width: 24%; object-fit: cover; margin: 2px"/>
										</ng-container>
									</div>
								</div>
							</div>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
    <div class="col-md-5">
      <div class="card card-info">
        <div class="card-header">
          <h4>Additional Information</h4>
        </div>
        <div class="card-body">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a href="#info" class="nav-link active" data-toggle="tab">
                <i class="fa fa-info-circle"></i>
                Info
              </a>
            </li>
            <li class="nav-item">
              <a href="#notes" class="nav-link" data-toggle="tab">
                <i class="fa fa-comments"></i>
                Comments
                <span style="margin-left:15px;" class="badge bg-secondary">{{notes().length}}</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="#files" class="nav-link" data-toggle="tab">
                <i class="fa fa-file"></i>
                Files
                <span style="margin-left:15px;" class="badge bg-secondary">{{files.length}}</span>
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane active" id="info">
              <div class="row">
                <div class="col-md-12">
                  <ng-container *ngIf="currentAction()">
                    <div class="card">
                      <div class="card-header">
                        <a data-toggle="collapse" href="#customer">
                          <h4>
                            Customer Information
                          </h4>
                        </a>
                      </div>
                      <div id="customer" class="collapse show">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-12">
                              <ul class="list-group">
                                <li class="list-group-item">
                                  <strong>Customer</strong>
                                  <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                                    class="float-end">{{currentAction().Customer.Name}}</span>
                                </li>
                                <li class="list-group-item">
                                  <strong>Customer ID</strong>
                                  <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                                    class="float-end">{{currentAction().Customer.CustomerId}}</span>
                                </li>
                                <ng-container *ngIf="currentAction().Customer.Vertical">
                                  <li class="list-group-item">
                                    <ng-container *ngIf="currentAction().Customer.Vertical">
                                      <strong>Vertical</strong>
                                      <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                                        class="float-end">{{currentAction().Customer.Vertical.Name}}</span>
                                    </ng-container>
                                  </li>
                                </ng-container>
                                <li class="list-group-item">
                                  <ng-container *ngIf="currentAction().Task">
                                    <strong>Task ID</strong>
                                    <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                                      class="float-end">{{currentAction().Task.TaskId}}</span>
                                  </ng-container>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="currentAction().Customer.SeoContentSurvey">
                      <div class="card">
                        <ng-container *ngIf="currentAction().Customer.SeoContentSurvey">
                          <div class="card-header">
                            <a data-toggle="collapse" href="#customerDetails">
                              <h4>Customer Details</h4>
                            </a>
                          </div>
                          <div id="customerDetails" class="collapse show">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-md-12">
                                  <ul class="list-group">
                                    <li class="list-group-item">
                                      <strong>Business Overview</strong>
                                      <div>{{currentAction().Customer.SeoContentSurvey.BusinessDescription}}
                                      </div>
                                    </li>
                                    <li class="list-group-item">
                                      <strong>Restrictions</strong>
                                      <div>{{currentAction().Customer.SeoContentSurvey.Limitations}}</div>
                                    </li>
                                    <li class="list-group-item">
                                      <strong>Audience</strong>
                                      <div>{{currentAction().Customer.SeoContentSurvey.Demographic}}</div>
                                    </li>
                                    <li class="list-group-item">
                                      <strong>Value Propositions and Unique Selling Points</strong>
                                      <div>{{currentAction().Customer.SeoContentSurvey.SellingPoints}}</div>
                                    </li>
                                    <li class="list-group-item" *ngIf="currentAction().Customer.SeoContentSurvey.BlogConsiderations">
                                      <strong>Onsite Blog Restrictions and Considerations</strong>
                                      <div>{{currentAction().Customer.SeoContentSurvey.BlogConsiderations}}</div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="notes">
              <div class="row">
                <div class="col-md-12">
                  <ng-container *ngIf="notes().length === 0">
                    <div class="alert alert-info">
                      <strong>No Comments</strong>
                      Comments are created when tasks are rejected.
                    </div>
                  </ng-container>
                  <ul class="list-group">
                    <ng-container *ngFor="let $data of notes()">
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-md-7">
                            <div>
                              <strong>{{$data.FromUserFullName}}</strong>
                            </div>
                            <small>
                              to
                              <span>{{$data.ToUserFullName}}</span>
                            </small>
                          </div>
                          <div class="col-md-5 text-end">
                            <small>{{$data.InsertedDate | dateText: 'M/DD/YYYY h:mm a' }}</small>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="list-group-item-text">
                              <div [innerHtml]="$data.Note" style="padding-top:15px; padding-bottom:15px;"></div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="files">
              <div class="col-md-12">
                <app-table [items]="files" [exportFilename]="'onsite-blogging-revision-files'">
                  <ng-template #tableHeader>
                    <tr>
                      <th export sort field="Name">Name</th>
                      <th export sort field="Comment">Comment</th>
                      <th export sort field="InsertedDate">Upload Date</th>
                      <th export field="S3Url"></th>
                    </tr>
                  </ng-template>
                  <ng-template #tableBody let-file>
                    <tr>
                      <td>{{file.Name}}</td>
                      <td>
                        <span [hidden]="!(file.Comment)" [ngbPopover]="file.Comment" popoverTitle="Comment"
                          placement="right auto" triggers="mouseenter:mouseleave">
                          <i class="far fa-file-alt"></i>
                          (Hover for Comment)
                        </span>
                      </td>
                      <td>{{file.InsertedDate | dateText }}</td>
                      <td style="width:50px;">
                        <a [externalLink]="{ url: file.S3Url, text:'View File'}" class="btn btn-primary"></a>
                      </td>
                    </tr>
                  </ng-template>
                </app-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
