<div [loadingOverlay]="isLoading()">
	<app-components-togglepanel [(isOpen)]="isOpen" title="Onsite Recommendations" keepBodyInDomWhenClosed="true" toggleBody="true">
		<div class="card-body">
			<app-components-publishoptions [customer]="customer" (responsibilityChanged)="currentResponsibility.set($event)"> 				
			</app-components-publishoptions>
		</div>
		<app-tab-panel [displayMode]="tabPanelDisplayMode.horizontal">
			<app-lazy-tab title="Website">
				<div *tabContent>
					@if (isBoostabilityMakingChangesToTheSite()) {
						<app-components-managecustomer-cms [customerId]="customer.CustomerId()" [partnerId]="customer.PartnerId()"
							[canEditContentManagementSystem]="canEditContentManagementSystem" [isManagedByPartner]="isManagedByPartner()"
							(cmsChange)="contentManagementSystemChanged($event)" (isManagedByPartnerChange)="isManagedByPartner($event)" />
						@if (!isManagedByPartner()) {
							<app-components-managecustomer-customeraccountgroup #cmsGroup [options]="{
								customerId: customer.CustomerId(),
								editableNotes: true,
								customerAccountTypes: cmsAccountTypes,
								isEnabled: true,
								showSaveButton: false,
								supressSaveMessage: true
							}" />
						}
					}
					<app-components-managecustomer-customeraccountgroup [options]="{
						customerId: customer.CustomerId(),
						editableNotes: true,
						customerAccountTypes: websiteAccountTypes,
						isEnabled: true,
						showSaveButton: false,
						supressSaveMessage: true
					}" />
				</div>
			</app-lazy-tab>
		</app-tab-panel>
	</app-components-togglepanel>
</div>