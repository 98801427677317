import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { QaGradingArea } from '../../../../qa/qa-grading-area/qa-grading-area.component';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';
import { Subject } from 'rxjs';
import { InternalActionToken } from '../../internal-action.token';
type QaCriteriaGroup = Boo.Qa.QaCriteriaGroup;
type OnsiteRecommendationInput = Boo.Tasks.Inputs.Models.OnsiteRecommendationInput;
type OnsiteRecommendationReviewOutput = Boo.Tasks.Outputs.Models.OnsiteRecommendationReviewOutput;

@Component({
  selector: 'app-components-specialist-internal-tasks-onsite-copy-review-qa',
  templateUrl: './onsite-recommendation-review-qa.component.html'
})
export class OnsiteRecommendationReviewQaComponent implements OnInit, CompletableWorkspace, UnlockableWorkspace, ClosableWorkspace {
  showCompleteButton: boolean = false;
  showUnlockButton: boolean = true;
  showCloseButton: boolean = true;
  complete = new Subject<void>();
  unlock = new Subject<void>();
  close = new Subject<void>();

  @ViewChild('gradingArea')
  gradingArea: QaGradingArea;
  taskType: Boo.Objects.Enums.TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaOnsiteRecommendationReview;
  qaCriteriaGroups: QaCriteriaGroup[] = [];

  editorToolbar: any = {
    toolbar: [
      {
        name: 'document',
        items: ['Source', 'Maximize']
      },
      {
        name: 'editing',
        items: ['SelectAll']
      },
      {
        name: 'clipboard',
        items: ['Copy']
      }
    ],
    readOnly: true,
    allowedContent: true
  };

  input: OnsiteRecommendationInput;
  output: OnsiteRecommendationReviewOutput;
  wasRejected: boolean;

  constructor(@Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction) { }

  ngOnInit() {
    //Create the criteria float panel
    (<any>$('#internal-task-qa-criteria-float-panel')).floatPanel({ collapsed: false });

    this.qaCriteriaGroups = this.internalAction.InternalTaskDetails.QaCriteriaGroups;
    this.input = this.internalAction.InternalTaskDetails.AssociatedTaskHistory.Input as OnsiteRecommendationInput;
    this.output = this.internalAction.InternalTaskDetails.AssociatedTaskHistory.Output as OnsiteRecommendationReviewOutput;
    this.wasRejected = this.output.RejectionReason != null;
  }

  isReadyToComplete() {
    if (!this.gradingArea.validate()) {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return false;
    }

    this.internalAction.InternalTaskDetails.QaCriteriaDetails = this.gradingArea.getDetails();
    this.internalAction.InternalTaskDetails.QaGrade.Comment = this.gradingArea.gradeComment;
    return true;
  }
}
