<span>
  <strong>Recent Orders</strong>
  <span *ngIf="!itemGroupings.length" class="float-end">No Recent Orders</span>
  <div style="margin-left: 1em; padding-top: 0.5em;">
    <div *ngFor="let item of itemGroupings" style="padding-bottom: 0.2em;">
      <i *ngIf="item.isOpen" class="far fa-circle text-success" title="Open"></i>
      <i *ngIf="!item.isOpen" class="fas fa-check-circle text-info" title="Closed"></i>
      <span [ngbPopover]="item.description" popoverTitle="Description" placement="right auto"
        triggers="mouseenter:mouseleave">{{item.name}}: {{ item.total | currency }}</span>
      <span class="small">
        - (<span>{{item.orderDate | dateText : 'L' : true }}</span>)
      </span>
    </div>
  </div>
</span>
