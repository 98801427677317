import { Component } from '@angular/core';
import System from 'framework/System';
import StatusEnum = Boo.Objects.Enums.StatusEnum;
import UserLevelEnum = Boo.Objects.Enums.UserLevelEnum;
import { CustomerUserService } from '../../../services/customer-user.service';
import { UserService } from '../../../services/user.service';
import { finalize } from 'rxjs/operators';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-managecustomer-customerusers',
  templateUrl: './CustomerUsers.component.html'
})
export class CustomerUsersComponent implements IActivatable {
    public title: string;
    public boostabilityPartnerId: number = 1;
    public canSelectUsers: KnockoutObservable<boolean>;
    public customerCountryAbbreviation: string;
    public partnerCountryAbbreviation: string;
    public customer: CustomerObservable;
    public hasGoogleAnalyticsAccess: KnockoutComputed<boolean>;
    public isLoading: KnockoutObservable<boolean>;
    public partner: Boo.Objects.Partner;
    public refreshCustomer: () => {};
    public showResendLogin: boolean;
    public user: Boo.Objects.User;
    public users: KnockoutObservableArray<CustomerUserObservable>;

    public priorityViewApi: app.interfaces.IPriorityViewPublicApi;

    public get noContactInfo() : boolean {
        return !this.users().some(x => x.CanContact());
    }

    public get isNotSaved() : boolean {
        return this.users().some(x => x.IsDirty());
    }

    constructor(
      private customerUserService: CustomerUserService,
      private userService: UserService,
      private sessionStorageService: SessionStorageService) { }

    public activate(params: any): JQueryPromise<any> {
        this.isLoading = ko.observable(false);
        this.customer = params.customer;
        this.user = params.user();
        this.partner = params.partner();
        this.priorityViewApi = params.priorityViewApi;
        this.title = `Customer Contact (${this.customer.CustomerId()})`;

        if (params.refreshCustomer) {
            this.refreshCustomer = params.refreshCustomer;
        }

        this.showResendLogin = this.customer.Partner.IsWhiteLabel()
            && !this.customer.Partner.IsSingleSignonRequired()
            && !this.customer.Partner.IsUserAndContactRestricted()
            && this.customer.StatusId() !== StatusEnum.Canceled;

        if (this.partner.PartnerId !== this.boostabilityPartnerId) {
            this.users = ko.observableArray(_.filter(this.customer.Users(), (user) => {
                return user.UserLevelId() !== UserLevelEnum.Specialist;
            }));
        } else {
            this.users = ko.observableArray(_.filter(this.customer.Users(), (user) => {
                return user.UserLevelId() === UserLevelEnum.Analytics || user.UserId() === this.user.UserId;
            }));
        }

        this.users().forEach((user) => {
            user.Editing = ko.observable(false);
            user.IsDirty = ko.observable(false);
        });

        let reportingUser = this.customer.Users().find(x => x.UserLevelId() === UserLevelEnum.Analytics);
        this.canSelectUsers = ko.observable(this.partner.PartnerId === this.boostabilityPartnerId
            || (reportingUser && reportingUser.Username().indexOf('ru-') < 0)
            || this.customer.StatusId() === StatusEnum.Active);

        const userIds: number[] = this.users().map(u => u.UserId());
        return Utils.wrapDfd(this.sessionStorageService.getStaticData())
            .then((data: any) => {
                let customerCountry = data.Countries.find((c: any) =>  c.CountryId === this.customer.CountryId());
                let partnerCountry = data.Countries.find((c: any) =>  c.CountryId === this.customer.Partner.CountryId());

                this.customerCountryAbbreviation = customerCountry ? customerCountry.Abbreviation : 'US';
                this.partnerCountryAbbreviation = partnerCountry ? partnerCountry.Abbreviation : 'US';
                return Utils.wrapDfd(this.customerUserService.getByIds(userIds));
            })
            .fail((err: any) => toastr.error(err))
            .then((customerUsers: any) => {
                this.users().forEach(u => {
                    u.CustomerUsers(customerUsers.filter((cu: any) => cu.UserId === u.UserId()));
                    u.EmailRecipientType(customerUsers.find((cu: any) => cu.CustomerUserId === u.CustomerUserId())?.EmailRecipientType);
                });
            });
    }

    public impersonateUser(impersonateUser: CustomerUserObservable): void {
        this.userService.impersonate(impersonateUser.CustomerUserId())
          .subscribe(
            (token: string) => window.open(`${this.customer.Partner.ReportingUrl()}/?t=${token}`, '_blank'),
            (error: string) => toastr.error(error, 'Impersonated User')
        );
    }

  public sendPasswordResetEmail(user: CustomerUserObservable): void {
        this.isLoading(true);

        if (!user.CanContact()) {
          toastr.warning('This user has opted out of contact. Please contact them directly to reset their password.', 'Password Reset');
          this.isLoading(false);
          return;
        }

        this.userService.sendPasswordResetEmail(user.Username(), false)
          .pipe(finalize(() => this.isLoading(false))).subscribe(
            () => toastr.success('Password reset instructions have been sent to the email address.', 'Password Reset'),
            (error) => toastr.error(error, 'Password Reset')
        );
    }

    public addUser(): void {
        let newUser = {
            CanContact: ko.observable(true),
            CanReceiveTextMessages: ko.observable(false),
            CustomerId: ko.observable(this.customer.CustomerId()),
            CustomerUserId: ko.observable(0),
            CustomerUserPhoneNumbers: ko.observableArray(),
            EmailRecipientType: ko.observable(),
            InsertedDate: ko.observable(new Date()),
            IsCustomerUserActive: ko.observable(true),
            IsCustomerUserPartner: ko.observable(false),
            LoginLink: ko.observable(''),
            UserLevelId: ko.observable(UserLevelEnum.Analytics),
            PhoneNumber: ko.observable(''),
            PrimaryPhoneNumber: ko.observable(''),
            FullName: ko.observable(''),
            UserId: ko.observable(0),
            Username: ko.observable(''),
            Password: ko.observable(''),
            FirstName: ko.observable(''),
            LastName: ko.observable(''),
            PhoneExtension: ko.observable(''),
            IsActive: ko.observable(true),
            AccessToken: ko.observable(''),
            CustomerUsers: ko.observableArray([]),
            PartnerUsers: ko.observableArray([]),
            Customers: ko.observableArray([]),
            Languages: ko.observableArray([]),
            Partners: ko.observable(),
            UserPermissions: ko.observableArray([]),
            SystemAdminUserId: ko.observable(0),
            Editing: ko.observable(true),
            IsDirty: ko.observable(false)
        } as CustomerUserObservable;

        this.users.push(newUser);
    }

    public editUser(customerUser: CustomerUserObservable): void {
        customerUser.Editing(!customerUser.Editing());
    }

    public deleteUser(customerUser: CustomerUserObservable): JQueryPromise<void> {
        if (customerUser.CustomerUserId() === 0) {
            this.users.remove(customerUser);
            return System.emptyPromise();
        }

        return bootbox.confirm('Are you sure you want to delete this user? This action cannot be undone.', (result: boolean) => {
            if (result) {
                return Utils.wrapDfd(this.customerUserService
                    .delete(customerUser.CustomerUserId()))
                    .then(() => {
                        this.users.remove(customerUser);
                        toastr.success('Successfully deleted user.');
                        if (this.refreshCustomer) {
                            this.refreshCustomer();
                        }
                    });
            }
            return System.emptyPromise();
        });
    }

    public close(): void {
        if (this.priorityViewApi) {
            this.priorityViewApi.resolve({ users: this.users });
        }
    }
}
