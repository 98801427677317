<div class="row">
	<div class="col-md-6">
		<div class="btn-group btn-group-toggle" data-toggle="buttons">
			<ng-container *ngFor="let $data of getPageSizeOptions()">
				<label (click)="setPageSize($data)" [class.active]="$data == pageSize()" class="btn btn-outline-primary">
					<ng-container *ngIf="$data == pageSize()">
						<input type="radio" class="btn btn-check" name="datatable-pagesize-options"/>
						<span>{{$data}}</span>
					</ng-container>
					<ng-container *ngIf="!($data == pageSize())">
						<input type="radio" class="btn btn-check" name="datatable-pagesize-options"/>
						<span>{{$data}}</span>
					</ng-container>
				</label>
			</ng-container>
		</div>
		<span class="ms-2 text-primary">records per page</span>
	</div>
	<div class="col-md-6 text-end">
		<ul class="pagination justify-content-end">
			<li [class.disabled]="isFirstPage()" class="page-item">
				<a (click)="prevPage()" href="javascript:void(0)" class="page-link">
					<i class="fa fa-chevron-circle-left"></i>
				</a>
			</li>
			<ng-container *ngFor="let $data of getPageLinks()">
				<ng-container *ngIf="$data">
					<li [class.active]="$data == getPage()" class="page-item">
						<a (click)="setPage($data)" href="javascript:void(0)" class="page-link">{{$data}}</a>
					</li>
				</ng-container>
				<ng-container *ngIf="!($data)">
					<li class="page-item">
						<span class="page-link">
							<i class="fa fa-ellipsis-h"></i>
						</span>
					</li>
				</ng-container>
			</ng-container>
			<li [class.disabled]="isLastPage()" class="page-item">
				<a (click)="nextPage()" href="javascript:void(0)" class="page-link">
					<i class="fa fa-chevron-circle-right"></i>
				</a>
			</li>
		</ul>
	</div>
</div>
