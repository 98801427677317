import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { WorkblockService } from '../../../services/workblock.service';
import { taskTypeActionFeatures } from '../ActionWorkspace/TaskTypeActionFeatures';
import TaskWorkblockRequest = Boo.Workblocks.Models.TaskWorkblockRequest;
import TaskTypeWorkblockRequest = Boo.Workblocks.Models.TaskTypeWorkblockRequest;
import Reasons = Boo.Workblocks.Models.WorkblockReason;

export enum Filters {
  UpcomingTickets = 'Upcoming Tickets',
  All = 'All',
  Appointments = 'Appointments',
  WorkStoppage = 'Work Stoppage'
}

@Component({
  selector: 'app-components-specialist-workblocktask',
  templateUrl: './workblock-task.component.html'
})
export class WorkblockTaskComponent implements OnInit {
  @Input() action: Boo.Objects.Action;
  @Input() reason: UntypedFormControl;
  @Input() note: UntypedFormControl;
  @Output() public saved: EventEmitter<any> = new EventEmitter();
  @Output() public canceled: EventEmitter<any> = new EventEmitter();

  workblockThisTaskType: boolean = false;
  isLoading: boolean = false;
  reasons: Reasons[];
  canWorkblockTaskType: boolean;

  constructor(
    private workblockService: WorkblockService) { }

  ngOnInit(): void {
    this.reason.addValidators(Validators.required);
    this.note.addValidators(Validators.required);
    this.workblockService.getReasons().subscribe(x => this.reasons = x);
    this.canWorkblockTaskType = !taskTypeActionFeatures.canNotWorkblockTaskType.includes( this.action.Task.TaskTypeEnum);
  }

  public cancel(): void {
    this.clearForm();
    this.canceled.emit();
  }

  public save(): void {
    this.note.markAsTouched();
    this.reason.markAsTouched();
    if (this.note.invalid || this.reason.invalid) {
      return;
    }

    this.isLoading = true;

    let $workblock: Observable<any>;
    if (this.workblockThisTaskType) {
      let taskTypeWorkblockRequest: TaskTypeWorkblockRequest = {
        CustomerId: this.action.Task.CustomerId,
        Note: this.note.value,
        TaskTypeEnum: this.action.Task.TaskTypeEnum,
        OriginationTaskId: this.action.Task.TaskId,
        WorkblockReasonId: this.reason.value
      };
      $workblock = this.workblockService.workblockTaskType(taskTypeWorkblockRequest);
    } else {
      let taskWorkblockRequest: TaskWorkblockRequest = {
        CustomerId: this.action.Task.CustomerId,
        Note: this.note.value,
        TaskTypeEnum: this.action.Task.TaskTypeEnum,
        TaskId: this.action.Task.TaskId,
        WorkblockReasonId: this.reason.value
      };
      $workblock = this.workblockService.workblockTask(taskWorkblockRequest);
    }

    $workblock.subscribe(
      () => {
        toastr.success('Task was unlocked and unassigned and Resolve Action Workblock Ticket was created.');
        this.clearForm();
        this.saved.emit();
      },
      (err) => toastr.error(err),
      () => this.isLoading = false);
  }

  private clearForm(): void {
    this.note.markAsUntouched();
    this.reason.markAsUntouched();
    this.workblockThisTaskType = false;
  }
}