import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class InternalActionService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/internalactions`;

  constructor(private http: HttpClient) { }

  getNextActionInQueue(): Observable<Boo.Objects.InternalAction> {
    return this.http.post<Boo.Objects.InternalAction>(`${this.baseUrl}/GetNextActionInQueue`, null)
  }

  updateStatus(internalAction: Boo.Objects.InternalAction, assignNextTask: boolean): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/UpdateStatus`, {
      internalAction: internalAction,
      assignNextTask: assignNextTask
    });
  }

  getByInternalTaskId(internalTaskId: number): Observable<Boo.Objects.InternalAction> {
    const params = new HttpParams().set('internalTaskId', internalTaskId);

    return this.http.get<Boo.Objects.InternalAction>(`${this.baseUrl}/GetByInternalTaskId`, { params: params });
  }

  unlockAndUnassign(internalTaskId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/UnlockAndUnassign`, { internalTaskId: internalTaskId });
  }
}
