import { Component, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import Check from 'framework/Check';
import JobStatus from 'app/models/typescript/JobStatus';
import { JobService } from '../../../services/job.service';
import { JobTypeService } from '../../../services/job-type.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-admin-joblist',
  templateUrl: './job-list.component.html'
})
export class JobListComponent implements IActivatable, AfterViewInit {
    moment = moment;
    @ViewChild('jobs_row_template') jobsRowTemplate: TemplateRef<any>;
    startDate: KnockoutObservable<moment.Moment>;
    endDate: KnockoutObservable<moment.Moment>;
    dateFormat: string = 'M/DD/YYYY h:mm a';
    jobs: KnockoutObservableArray<Boo.Objects.Jobs.Job> = ko.observableArray([]);
    filterText: KnockoutObservable<string> = ko.observable(undefined);
    filterRefresh: KnockoutComputed<string> = ko.computed(() => {
        if (this.dataTable) {
            this.dataTable.reload();
        }
        return this.filterText();
    });
    isLoading: boolean;
    selectedJob: KnockoutObservable<Boo.Objects.Jobs.Job> = ko.observable(undefined);
    selectedJobTypeId: KnockoutObservable<Boo.Objects.Jobs.Enums.JobTypes> = ko.observable(undefined);
    selectedJobStatusId: KnockoutObservable<Boo.Objects.Jobs.Enums.JobStatuses> = ko.observable(undefined);

    jobStatuses: JobStatus[] = [
        {
            JobStatusId: Boo.Objects.Jobs.Enums.JobStatuses.Completed,
            Name: 'Completed',
            Description: ''
        },
        {
            JobStatusId: Boo.Objects.Jobs.Enums.JobStatuses.Deleted,
            Name: 'Deleted',
            Description: ''
        },
        {
            JobStatusId: Boo.Objects.Jobs.Enums.JobStatuses.Failed,
            Name: 'Failed',
            Description: ''
        },
        {
            JobStatusId: Boo.Objects.Jobs.Enums.JobStatuses.Pending,
            Name: 'Pending',
            Description: ''
        },
        {
            JobStatusId: Boo.Objects.Jobs.Enums.JobStatuses.Processing,
            Name: 'Processing',
            Description: ''
        },
        {
            JobStatusId: Boo.Objects.Jobs.Enums.JobStatuses.Scheduled,
            Name: 'Scheduled',
            Description: ''
        }
    ];

    jobTypes: KnockoutObservableArray<Boo.Objects.Jobs.JobType> = ko.observableArray([]);

    dataTable: app.components.interfaces.DataTablePublicApi;

    dataTableOptions: app.components.interfaces.DataTableOptions;

    isActivated: KnockoutObservable<boolean> = ko.observable(false);

    canRequeueSelectedJob: KnockoutObservable<boolean> = ko.observable(false);

    canDeleteSelectedJob: KnockoutObservable<boolean> = ko.observable(false);

    jobTableWidthClass: KnockoutComputed<string> = ko.computed(() => {
        let result = 'col-md-12';
        if (_.isObject(this.selectedJob())) {
            result = 'col-md-8';
        }
        return result;
    });

    jobColumnWidthClass: KnockoutComputed<string> = ko.computed(() => {
        let result = 'col-md-3';
        if (this.selectedJob()) {
            result = 'col-md-4';
        }
        return result;
    });

    openJob(job: Boo.Objects.Jobs.Job): void {
        this.selectedJob(job);

        // Reset whether selected job can be requeued or deleted
        this.canDeleteSelectedJob(false);
        this.canRequeueSelectedJob(false);

        if (job.JobType.IsActive === true && (job.JobStatusId === Boo.Objects.Jobs.Enums.JobStatuses.Failed || job.JobStatusId === Boo.Objects.Jobs.Enums.JobStatuses.Deleted || job.JobStatusId === Boo.Objects.Jobs.Enums.JobStatuses.Completed)) {
            this.canRequeueSelectedJob(true);
        }
        if (job.JobStatusId === Boo.Objects.Jobs.Enums.JobStatuses.Pending || job.JobStatusId === Boo.Objects.Jobs.Enums.JobStatuses.Failed) {
            this.canDeleteSelectedJob(true);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.configureODataLoader();
        }, 0);
    }

    constructor(
      private jobService: JobService,
      private jobTypeService: JobTypeService) { }

    activate(params: app.components.interfaces.IJobListActivateParams): JQueryPromise<any> {
        Check.isNotEmpty(params, 'Jobs cannot be viewed without any options');
        this.isLoading = false;

        this.startDate = ko.observable(moment().add(-7, 'd'));
        this.endDate = ko.observable(moment().add(1, 'd'));

        if (params.apiCallback) {
            params.apiCallback(this.getPublicApi());
        }

        return Utils.wrapDfd(this.jobTypeService.getAll())
            .then((jobTypes) => {
                this.jobTypes(jobTypes);
            })
            .then((() => {
                let p = ko.mapping.toJS(params);
                this.selectedJobTypeId(p.jobTypeId);
                this.filterText(p.filterText);
                this.selectedJobStatusId(p.selectedJobStatusId);

                this.filterRefresh.extend({
                    throttle: 1000
                });

                this.selectedJobStatusId.subscribe(() => {
                    if (this.dataTable) {
                        this.dataTable.reload();
                    }
                });

                this.selectedJobTypeId.subscribe(() => {
                    if (this.dataTable) {
                        this.dataTable.reload();
                    }
                });

                this.startDate.subscribe(() => {
                    if (this.dataTable) {
                        this.dataTable.reload();
                    }
                });

                this.endDate.subscribe(() => {
                    if (this.dataTable) {
                        this.dataTable.reload();
                    }
                });

                this.isActivated(true);
            }).bind(this))
            .fail((displayMessage: string): void => {
                toastr.error(displayMessage);
            });
    }

    closeJob(): void {
        this.selectedJob(undefined);
    }

    getPriorityName(status: Boo.Objects.Jobs.Enums.JobPriorities): string {
        let items: { [id: number]: string } = {
            1: 'High',
            2: 'Normal',
            3: 'Low',
            4: 'Monitor',
            5: 'SingleThreaded',
            6: 'DefaultInternalUse',
            7: 'LongRun',
            8: 'StaticSites',
            10: 'Store',
            11: 'LocalListings'
        };

        return items[status];
    }

    getJobStatusName(status: Boo.Objects.Jobs.Enums.JobStatuses): string {
        let items: { [id: number]: string } = {
            1: 'Pending',
            2: 'Processing',
            3: 'Completed',
            4: 'Failed',
            5: 'Deleted'
        };

        return items[status];
    }

    requeueJob(job: Boo.Objects.Jobs.Job): void {
      let msg = 'Are you sure you would like to re-enqueue the selected job?';
      bootbox.confirm(msg, (result: boolean) => {
          if (result === true) {
            Utils.wrapDfd(this.jobService.requeue(job.HangfireJobId, job.JobId))
              .then((() => {
                this.selectedJob(undefined);
                this.dataTable.reload();
                toastr.success('Job successfully re-enqueued');
              }).bind(this))
              .fail((displayMessage: string): void => {
                  toastr.error(displayMessage);
              });
          }
      });
    }


    deleteJob(job: Boo.Objects.Jobs.Job): void {
      let msg = 'Are you sure you would like to delete the selected job?';
      bootbox.confirm(msg, (result: boolean) => {
          if (result === true) {
            Utils.wrapDfd(this.jobService.delete(job.JobId))
              .then((() => {
                this.selectedJob(undefined);
                this.dataTable.reload();
                toastr.success('Job successfully deleted');
              }).bind(this))
              .fail((displayMessage: string): void => {
                  toastr.error(displayMessage);
              });
          }
      });
    }

    protected getPublicApi(): app.components.interfaces.IJobListPublicApi {
        return {
            refresh: (): void => { this.dataTable.reload(); }
        };
    }

    private configureODataLoader(): void {
        this.dataTableOptions = {
            loader: (page, pageSize, sortOrder): JQueryPromise<framework.data.IPageResult<Boo.Objects.Jobs.Job>> => {
                this.isLoading = true;
                let filters: string[] = [];

                if (this.selectedJobTypeId()) {
                    let prop = 'JobTypeId';
                    filters.push(`${prop} eq Boo.Objects.Jobs.Enums.JobTypes'${this.selectedJobTypeId()}'`);
                }

                if (this.selectedJobStatusId()) {
                    filters.push(`JobStatusId eq Boo.Objects.Jobs.Enums.JobStatuses'${this.selectedJobStatusId()}'`);
                }

                if ($.trim(this.filterText()) && this.filterText().length > 5) {
                    let isNumbersOnly = new RegExp('^[0-9]+$').test(this.filterText());
                    if (isNumbersOnly) {
                        filters.push(`JobId eq ${this.filterText()}`);
                    } else {
                        filters.push(`startswith(HangfireJobId, '${this.filterText()}')`);
                    }
                }

                let query = this.jobService.getMany(this.startDate(), this.endDate())
                    .orderBy(sortOrder)
                    .page(page, pageSize);

                let filter: string;
                if (filters.length) {
                    filter = filters.length > 1 ? filters.join(' and ') : filters.pop();
                    query.filter(filter);
                }

                return query.load().always(() => this.isLoading = false);
            },
            columns: [
                { id: 'jobid', value: 'JobId', name: 'Job ID' },
                { id: 'inserted_date', value: 'InsertedDate', name: 'Inserted Date' },
                { id: 'statusdate', value: 'StatusDate', name: 'Status Modified Date' },
                { id: 'status', value: 'JobStatusId', name: 'Status' },
                { id: 'priority', value: 'JobPriorityId', name: 'Priority' },
                { id: 'hangfire_job_id', value: 'HangfireJobId', name: 'Hangfire Job ID' },
                { id: 'payload', name: 'Payload', canSort: false }
            ],
            rowTemplate: this.jobsRowTemplate,
            apiCallback: (api): void => {
                this.dataTable = api;
            }
        };
    }
}
