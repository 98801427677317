import { Component } from '@angular/core';
import permissions from 'app/models/Permissions';
import { GuaranteeService } from '../../../services/guarantee.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-guarantees-add',
  templateUrl: './Add.component.html'
})
export class AddComponent implements IActivatable {
    public description: KnockoutObservable<string>;
    public isLoading: KnockoutObservable<boolean>;
    public selectedType: KnockoutObservable<Boo.Objects.GuaranteeViolationType>;
    public types: Boo.Objects.GuaranteeViolationType[];

    private api: app.components.guarantees.interfaces.IGuaranteeComponentPublicApi;
    private guarantee: Boo.Objects.Guarantee;
    private validation: KnockoutObservable<any>;

    constructor(private guaranteeService: GuaranteeService) { }

    public activate(params: app.components.guarantees.interfaces.IGuaranteeSingleScopeActivationParams): JQueryPromise<void> {
        this.api = params.api;
        this.guarantee = params.guarantee;

        this.isLoading = ko.observable(false);
        this.description = ko.observable('')
            .extend({
                required: true,
                maxLength: 512
            });

        this.selectedType = ko.observable(undefined)
            .extend({
                required: true
            });

        this.validation = ko.validatedObservable({
            description: this.description,
            selectedType: this.selectedType
        });

        return Utils.wrapDfd(this.guaranteeService.getViolationTypes())
            .then(types => {
                this.types = types;
            });
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        let hasPermission = (<Launchpad>launchpad).hasPermission(partner, user.PartnerUsers, permissions.CanAddGuaranteeViolation, user);
        return hasPermission;
    }

    public cancel(): void {
        this.api.end();
    }

    public save(): void {
        if (!this.validation.isValid()) {
            this.validation.errors.showAllMessages();
            return;
        }

        this.isLoading(true);

        Utils.wrapDfd(this.guaranteeService.addViolation(this.selectedType().GuaranteeViolationTypeId, this.description(), this.guarantee.GuaranteeId))
            .then(() => {
                this.api.end();
            }).fail((msg: string) => {
                toastr.error(msg);
            })
            .always(() => {
                this.isLoading(false);
            });
    }
}
