import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import TodoValidation = app.tsmodels.interfaces.TodoValidation;
import Quill from 'quill';
import Utils from './utils';
import { PostalCode } from 'app/validation/rules/postalCode';
import { Countries } from 'app/models/enums/Countries';
import { CustomerCampaignService } from '../services/customer-campaign.service';
import Constants from './constants';

export default class CustomValidators {


  /**
   * SIMPLE VALIDATOR EXAMPLE
   * Note: This should be the prefered method where possible since a validator should only be "validating" a value
   *
   */
  // static booNotAllowed(control: AbstractControl): { [key: string]: boolean } | null {
  //   if (control.value && control.value === 'boo') {
  //     return { 'booNotAllowed': true };
  //   }
  //   return null;
  // }

  /**
   * COMPLEX VALIDATOR EXAMPLE
   * Note: Whenever there needs to external data that has to be validated against
   *
   */
  // static todoNoteValidator(todo: TodoValidation): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: boolean } | null => {
  //     const note: string = control.value.toString();
  //     if (note && note.trim().slice(-1) === ':') {
  //       return { 'informationRequiredAfterColon': true };
  //     }
  //     return null;
  //   };
  // }

  static todoNoteValidator(todo: TodoValidation): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const note: string = control.value.toString();
      if (note && note.trim().slice(-1) === ':') {
        return { 'informationRequiredAfterColon': true };
      }
      return null;
    };
  }

  static todoStatusValidator(todo: TodoValidation): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!isNaN(control.value) && todo.Status === Boo.Objects.Todo.Enums.TodoStatus.New) {
        return { 'todoInvalid': true };
      }
      return null;
    };
  }


  static stringsNotAllowed(notAllowed: string[], ignoreCase: boolean, alternateMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value: string = (ignoreCase ? control.value.toString().toLowerCase() : control.value.toString()).trim();
      let compareValues = ignoreCase ? notAllowed.map(i => i.toLowerCase()) : notAllowed;
      if (compareValues.indexOf(value) > -1) {
        return alternateMessage ? { 'stringsNotAllowed': { alternateMessage: alternateMessage }} : { 'stringsNotAllowed': true };
      }
      return null;
    };
  }

  static stringsNotAllowedMethod(getNotAllowed: (() => string[]), ignoreCase: boolean, alternateMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value: string = (ignoreCase ? control.value.toString().toLowerCase() : control.value.toString()).trim();
      let notAllowed = getNotAllowed();
      let compareValues = ignoreCase ? notAllowed.map(i => i.toLowerCase()) : notAllowed;
      if (compareValues.indexOf(value) > -1) {
        return alternateMessage ? { 'stringsNotAllowed': { alternateMessage: alternateMessage }} : { 'stringsNotAllowed': true };
      }
      return null;
    };
  }

  static keywordMustBePresent(keywords: Boo.Objects.KeywordSiteAnalysis[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value: string = control.value.toString().toLowerCase();
      const trimmedValue = this.cleanAndTrimText(value);
      const isPresent = keywords.some(x => {
        const keyword = this.cleanAndTrimText(x.Keyword);
        const area = this.cleanAndTrimText(x.Area);
        return trimmedValue.includes(keyword) && trimmedValue.includes(area);
      });

      if (!isPresent) {
        return { 'keywordNotPresent': true }
      }
      return null;
    };
  }

  static validUrl(valuePrefix?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if(!control.value && !valuePrefix) {
        return null;
      }

      const value: string = valuePrefix ? valuePrefix.toLowerCase() + control.value.toString().toLowerCase() : control.value.toString().toLowerCase();

      if (!Constants.IsUrlRegex.test(value)) {
          return { 'urlInvalid': true }
      }
      return null;
    };
  }

  static cannotContainLeadingOrTrailingSpace(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isValid = control.value ? control.value.length === control.value.trim().length : true;
      return isValid ? null : { 'leadingOrTrailingSpace': true };
    };
  }

  static cannotContainUrl(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      if(!control.value) {
        return null;
      }

      const value: string = control.value.toString().toLowerCase();

      if (Constants.ContainsUrlRegex.test(value)) {
          return { 'containsUrl': true };
      }
      return null;
    };
  }

  static cannotContainDigits(length: number, alternateMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      if(!control.value) {
        return null;
      }

      const value: string = control.value.toString().toLowerCase();

      if (value.match(/[\d]/g) ? value.match(/[\d]/g).length >= length : false) {
          return {'invalid':  {alternateMessage: alternateMessage} };
      }
      return null;
    };
  }

  static cannotContainHTML(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      if(!control.value) {
        return null;
      }

      const value: string = control.value.toString().toLowerCase();

      let doc = new DOMParser().parseFromString(value, 'text/html');
      if ([].slice.call(doc.body.childNodes).some((node: HTMLElement) => node.nodeType === 1)) {
        return {'invalid':  {alternateMessage: 'Cannot contain HTML'} };
      }

      return null;
    };
  }

  static cannotContainControlCharacters(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      if(!control.value) {
        return null;
      }

      const value: string = control.value.toString().toLowerCase();

      
      if (value.match(/[\x00-\x1F\x7F]/g)) {
        return {'invalid':  {alternateMessage: 'Cannot contain control characters'} };
      }

      return null;
    };
  }

  static keywordsCannotBeDuplicated(customerCampaignService: CustomerCampaignService, getIsLocationFirst: (() => boolean), getUnavailableKeywords: (() => string[]), message: string) {
    return (form: UntypedFormGroup): { [key: string]: any } | null => {
      let keywordValue = form.get('keyword').value;
      let areaValue = form.get('area') ? form.get('area').value : '';
      let isAreaFirst = areaValue ? getIsLocationFirst() : false;
      let unavailableKeywords = getUnavailableKeywords();

      let phrase = customerCampaignService.getKeywordPhrase(keywordValue, areaValue, isAreaFirst).toLowerCase();

      if (phrase.length === 0) {
        return null;
      }
  
      if (unavailableKeywords.includes(phrase))
      {
        return {'invalid':  {alternateMessage: message} };
      }
      return null;
    }
  }

  static mustNotBeWhitespace(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value && control.value.toString().trim().length === 0 ? {'invalid': {alternateMessage: 'Cannot be whitespace'} } : null;
    };
  }

  static domainMustMatch(urlToMatch: string, alternateMessage?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let url: uri.URI = new URI(control.value.toString().toLowerCase());
      let matchUrl: uri.URI = new URI(urlToMatch);
      let domainMatches = url.normalize().hostname() === matchUrl.normalize().hostname() && url.normalize().protocol() === matchUrl.normalize().protocol();
      if (!domainMatches)
      {
        return alternateMessage ? { 'mismatchedDomain': { alternateMessage: alternateMessage }} : { 'mismatchedDomain': true };
      }
      return null;
    };
  }


  static domainMustMatchPrefix(urlToMatch: string, valuePrefix: string, alternateMessage?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let url: uri.URI = new URI(valuePrefix.toLowerCase() + control.value.toString().toLowerCase());
      let matchUrl: uri.URI = new URI(urlToMatch);
      let domainMatches = url.normalize().hostname() === matchUrl.normalize().hostname() && url.normalize().protocol() === matchUrl.normalize().protocol();
      if (!domainMatches)
      {
        return alternateMessage ? { 'mismatchedDomain': { alternateMessage: alternateMessage }} : { 'mismatchedDomain': true };
      }
      return null;
    };
  }


  static urlMustMatch(urlToMatch: string, message?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let url = control.value.toLowerCase().replace(/\/$/, '');
      let matchUrl = urlToMatch.toLowerCase().replace(/\/$/, '');
      if (url != matchUrl)
      {
        return {'invalid':  {alternateMessage: message} };
      }
      return null;
    };
  }
  /**
   * This validator uses a closure to obtain the country abbreviation needed for phone number validation. This avoids the need to update the validator if the selected country changes. See BasicInformation.component.ts for an example.
   */
  static phoneNumberValidator(countryAbbreviation: () => string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
          return null;
        }
        const countryCode = countryAbbreviation();
        const phoneNumberUtil: any = libphonenumber.PhoneNumberUtil.getInstance();
        const exampleNumber = phoneNumberUtil.getExampleNumber(countryCode);
        const message: string = `This field must be a valid phone number, e.g., ${phoneNumberUtil.format(exampleNumber, libphonenumber.PhoneNumberFormat.NATIONAL)} or ${phoneNumberUtil.format(exampleNumber, libphonenumber.PhoneNumberFormat.INTERNATIONAL)}.`
          + ` Extensions should follow the phone number and be preceded by an \'x\', eg. ${phoneNumberUtil.format(exampleNumber, libphonenumber.PhoneNumberFormat.NATIONAL)} x 000 or ${phoneNumberUtil.format(exampleNumber, libphonenumber.PhoneNumberFormat.INTERNATIONAL)} x 000`;

        try {
            const phoneNumber = phoneNumberUtil.parse(control.value, countryCode);

            if (!phoneNumberUtil.isValidNumber(phoneNumber)) {
                return {'invalidPhoneNumber':  {alternateMessage: message} };
            }
        } catch (error) {
            return {'invalidPhoneNumber':  {alternateMessage: message} };
        }

        return null;
    };
  }

  static postalCodeValidator(country: () => Countries): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
          return null;
        }

        const countryId = country();
        if (!(new PostalCode().isValid(control.value, countryId))) {
            return { 'invalidPostalCode': true }
        }

        return null;
    };
  }
  
  static email(control: AbstractControl): { [key: string]: boolean } | null {
    if (!control.value) {
      return null;
    }

    return launchpad.utils.validation.emailAddressIsValid(control.value) ? null : {'email': true};
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static patternNotAllowedValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      const valid = regex.test(control.value);

      // if false, return no error (no error), else return error passed in the second parameter
      return !valid ? null : error;
    };
  }

  static mustEqualValidator(value: any, message: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = control.value === value;
      return valid ? null : {'invalid':  {alternateMessage: message} };
    };
  }

  static maxLengthArrayValidator(value: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = control.value.length <= value;
      return valid ? null : {'maxLengthArray': {alternateMessage: `Please select no more than ${value} items`} };
    };
  }

  static maxLengthPrefix(valuePrefix: string, maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }

      const valid = control.value.trim().length + valuePrefix.trim().length <= maxLength;
      return valid ? null : {'invalid': { alternateMessage: `Please enter no more than ${maxLength} characters` }};
    }
  }

  static maxLengthPerLineValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      const lines = control.value.toString().split(/\r\n|\r|\n/);
      if(lines.some((line: string) => line.length > maxLength)) {
        return {'lineTooLong': `Each line must be less than ${maxLength} characters`};
      }
    }
  }

  static requiredIfValidator(predicate: (...args: any[]) => boolean) {
    return ((formControl: AbstractControl) => {
      if (predicate()) {
        return Validators.required(formControl); 
      }
      return null;
    })
  }

  static requiredTrueIfValidator(message: string, predicate: (...args: any[]) => boolean) {
    return ((formControl: AbstractControl) => {
      if (predicate() && !formControl.value) {
        return { 'invalid': {alternateMessage: message} };
      }
      return null;
    })
  }

  static requiredFalse(message: string) {
    return ((formControl: AbstractControl) => {
      if (formControl.value) {
        return { 'invalid': {alternateMessage: message} };
      }
      return null;
    })
  }

  static minDate(value: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value >= value;
      return valid ? null : {'minDate': value };
    };
  }

  static maxDate(value: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value <= value;
      return valid ? null : {'maxDate': value };
    };
  }

  static uniqueUrlSuggestionsValidator(urlSuggestions: Boo.Objects.LegacyUrlSuggestion[], readonlyUrls: Boo.Objects.WebsiteUrl[]): ValidatorFn {
    return (control: AbstractControl) => {
      var existingUrls = urlSuggestions.map(x => x.Url).concat(readonlyUrls.map(x => x.Url.toLowerCase()));
      if (existingUrls.some(existingUrl => existingUrl === control.value.toString().toLowerCase())) {
        return  { 'urlAlreadyExists': true }
      }
      return null;
    }
  }

  static minWordCountValidator(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: number } => {
      const wordCount = Utils.wordCount(control.value);
      if (wordCount < min) {
        return { 'wordCountMin': min };
      }

      return null;
    };
  }

  static maxWordCountValidator(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: number } => {
      const wordCount = Utils.wordCount(control.value);
      if (wordCount > max) {
        return { 'wordCountMax': max };
      }

      return null;
    };
  }
  
  static quillWordCountValidator(quill: Quill, min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: number } => {
      var text = quill.getText();
      const wordCount = Utils.wordCount(text)
      if (wordCount < min) {
        return { 'wordCountMin': min };
      }

      return null;
    };
  }

  private static cleanAndTrimText(value: string): string {
    return $.trim(value).toLowerCase().replace(/[^\w ]|[_]+/g, '');
  }
}
