import { Component, OnInit } from '@angular/core';
import TaskHelper from 'app/specialist/TaskHelper';
import System from 'framework/System';
import { onsiteCopyOrderTaskTypes } from '../../../../Scripts/app/models/collections/TaskTypeCollections';
import { TaskService } from '../../../services/task.service';
import { WorkflowKeyValuePairService } from '../../../services/workflow-keyvaluepair.service';
import WorkflowHelper from '../../../shared/WorkflowHelper';
import { CustomerCampaignService } from '../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-managecustomer-taskdetails',
  templateUrl: './TaskDetails.component.html'
})
export class TaskDetailsComponent implements IActivatable, OnInit {

  websiteUrlStatuses_Active = Boo.Objects.Enums.WebsiteUrlStatuses.Active;

  // input parameters
  public taskId: number;
  public title = 'Task Details';

  // calculated properties
  public isLoading: boolean = false;
  public isCustomOther: boolean = false;
  public isOnsiteCopy: boolean = false;
  public isOnsiteRecommendationImplementation: boolean = false;
  public dfd: JQueryDeferred<void>;

  // data
  public task: Boo.Objects.Task;

  // UI Bound fields
  public taskTypeTips: string = '';
  public selectedUrl: Boo.Objects.WebsiteUrl;
  public customDescription: string = '';
  public notifyOnCompletion: boolean = false;
  public billableMinutes: string;
  public customName: string = '';
  public customTaskTypeName: string = '';
  public taskActionUrl: string = '';
  public customBillableMinutes: number = 0;
  public showTaskDescription: boolean;
  public showNotifyCSRWhenComplete: boolean;
  public showRedoReason: boolean;
  public onsiteCopyOrderReason: string = '';
  public onsiteCopyLinkBuildAfterPublish: boolean;
  public onsiteCopyPageType: string;
  public onsiteCopyBoostToPublish: boolean;
  public onsiteCopyImplementationInstructions: string;
  public onsiteImplementationNoteToReviewer: string;
  public taskTypeName: string;

  constructor(
    private taskService: TaskService,
    private workflowKeyValuePairService: WorkflowKeyValuePairService,
    private customerCampaignService: CustomerCampaignService) { }

  public activate(params: any): JQueryPromise<any> {
    if (params && params.taskId) {
      this.taskId = params.taskId;
    } else {
      toastr.error('taskId not provided');
    }

    return System.emptyPromise();
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.taskService.getById(this.taskId)
      .subscribe((task) => {
        this.task = task;
        if (this.task.WebsiteUrlId) {
          this.customerCampaignService.getWebsiteUrl(this.task.WebsiteUrlId)
            .subscribe(url => {
              this.selectedUrl = url;
              this.initializeView();
              this.isLoading = false;
            });
        } else {
          this.initializeView();
          this.isLoading = false;
        }
      }, err => toastr.error(err));
  }

  cancel(): void {
    if (this.dfd) {
      this.dfd.resolve();
    }
  }

  private initializeView(): void {
    this.taskTypeName = this.task.TaskTypeSummary.Name;
    this.isCustomOther = this.task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.Custom;
    this.isOnsiteCopy = onsiteCopyOrderTaskTypes.includes(this.task.TaskTypeEnum);
    this.isOnsiteRecommendationImplementation = this.task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendationImplementationManual;
    this.billableMinutes = this.formatTime(this.task.BilledMinutes);
    this.taskTypeTips = this.task.TaskTypeSummary.Tips || '';
    this.customDescription = TaskHelper.getCustomDescription(this.task);
    this.notifyOnCompletion = TaskHelper.getTaskNotifyOnComplete(this.task);
    this.customName = TaskHelper.getCustomName(this.task);
    this.customTaskTypeName = TaskHelper.getCustomTaskTypeName(this.task);
    this.customBillableMinutes = TaskHelper.getCustomBillableMinutes(this.task);
    this.taskActionUrl = TaskHelper.getTaskActionUrl(this.task);
    this.onsiteImplementationNoteToReviewer = TaskHelper.getOnsiteImplementationNoteToReviewer(this.task);
    this.onsiteCopyOrderReason = TaskHelper.getOnsiteCopyOrderReason(this.task);
    this.onsiteCopyLinkBuildAfterPublish = TaskHelper.getOnsiteCopyLinkBuildAfterPublish(this.task);
    this.onsiteCopyPageType = TaskHelper.getOnsiteCopyPageType(this.task);
    this.onsiteCopyImplementationInstructions = TaskHelper.getOnsiteCopyImplementationInstructions(this.task);
    if (this.isOnsiteCopy) {
      this.workflowKeyValuePairService.getByTaskId(this.task.TaskId)
        .subscribe((keyValuePairs: Boo.Objects.WorkflowKeyValuePair[]) => {
          this.onsiteCopyBoostToPublish = WorkflowHelper.getIsBoostImplementingByResponsibility(keyValuePairs);
        });
    }
  }

  private formatTime(totalMinutes: number): string {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    let padNumber = (number: number) => {
      return number > 9 ? number : '0' + number;
    };

    return padNumber(hours) + ' hrs : ' + padNumber(minutes) + ' mins';
  }
}

