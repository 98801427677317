<div [loadingOverlay]="isLoading">
  <div class="row" *ngIf="showRevisionWarning">
    <div class="col-md-12">
      <div class="alert alert-warning">
        <div class="row">
          <div class="col-md-12">
            <div><strong>Revision Requested</strong></div>
            <div [innerHTML]="taskPost.ReviewerComment"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <span>Target Keyword</span>
        </div>
        <div class="card-body">
          <span>{{targetKeyword}}</span>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <span>Additional Keywords</span>
        </div>
        <ng-container *ngIf="hasAdditionalKeywords">
          <div class="card-body" *ngFor="let $data of additionalKeywords">
            <span>{{$data}}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!hasAdditionalKeywords">
          <div class="card-body">
            <span>N/A</span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <span>Link Strategy Type</span>
        </div>
        <div class="card-body">
          <span>{{linkStategyType}}</span>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <span>Special Considerations</span>
        </div>
        <div class="card-body">
          <span>{{specialConsiderations}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form role="form">
        <div class="form-group">
          <label for="title" class="control-label">Blog Title</label>
          <p class="float-end help-block">128 character maximum</p>
          <input type="text" class="form-control" placeholder="Enter Title" [formControl]="titleControl"
            [formValidation]="titleControl" lpValidationMsg />
        </div>
        <div class="form-group">
          <label class="control-label">Blog Content</label>
          <span class="float-end help-block">8000 character maximum</span>
          <div class="clear-fix"></div>
          <div [formValidation]="content">
            <quill-editor [(formControl)]="content" [modules]="quillModules"  (onEditorCreated)="contentEditorCreated($event)"></quill-editor>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div [formValidation]="checkedControl">
    <app-components-content-audit [content]="content.value" [isVisible]="content.valid" [delay]="30" (contentChecked)="checkedControl.patchValue(true)"></app-components-content-audit>
  </div>
  <app-floating-panels>
    <app-floating-panel
      [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
      <app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="taskDetails.TaskNotes">
      </app-components-specialist-actioncomponents-comments>
    </app-floating-panel>
  </app-floating-panels>
</div>