import { Directive, Input, OnChanges, AfterViewInit, ChangeDetectorRef, ViewContainerRef, ApplicationRef,/*, ComponentRef, Optional, ElementRef */ 
  SkipSelf} from '@angular/core';
  
  @Directive({
    selector: '[disableChangeDetection]'
  })
  export class DisableChangeDetection implements AfterViewInit, OnChanges {
    @Input()
    public disableChangeDetection: boolean;
  
    constructor(private changeDetectorRef: ChangeDetectorRef, private viewContainerRef: ViewContainerRef) {
    }
  
    private initialized = false;
    public ngAfterViewInit(): void {
      this.updateChangeDetection();
      this.initialized = true;
    }
  
    public ngOnChanges(): void {
      if (!this.initialized) {
        return;
      }

      this.updateChangeDetection();
    }
  
    private updateChangeDetection(): void {
      if (this.disableChangeDetection) {
        this.changeDetectorRef.detach();
        for (let i = 0; i < this.viewContainerRef.length; ++i) {
          this.viewContainerRef.get(i).detach();
        }
      }
      else {
        this.changeDetectorRef.reattach();
        for (let i = 0; i < this.viewContainerRef.length; ++i) {
          this.viewContainerRef.get(i).reattach();
        }
      }
    }
  }