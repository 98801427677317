import { Component } from '@angular/core';
import System from 'framework/System';
import Check from 'framework/Check';
import { CustomerAccountRouteFactory } from 'app/customeraccount/CustomerAccountRouteFactory';
import Grid from 'app/components/Grid';
import IValidatedResult = app.interfaces.IValidatedResult;
import permissions from 'app/models/Permissions';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { CustomerAccountDefaultService } from '../../../services/customer-account-default.service';
import Utils from '../../../shared/utils';

/**
 * Displays, saves, and validates, a group of customer accounts. 
 */
@Component({
    selector: 'app-components-manager-customeraccountdefaultgroup',
    templateUrl: './CustomerAccountDefaultGroup.component.html'
})
export class CustomerAccountDefaultGroupComponent implements IActivatable, app.interfaces.ISaveAndValidate {
    public grid: KnockoutObservable<Grid<ICompositionRoute>> = ko.observable(null);
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);

    protected components: app.manager.components.interfaces.CustomerAccountDefaultViewModel[] = [];

    public getPublicApi(): app.interfaces.ISaveAndValidate {
        return {
            save: (saveType: SaveTypes, partnerId: number): JQueryPromise<void> => { return this.save(SaveTypes.Update, partnerId); },
            validate: (saveType?: SaveTypes): JQueryPromise<IValidatedResult> => { return this.validate(saveType); },
            isValid: (saveType: SaveTypes): boolean => { return this.isValid(saveType); },
            confirmSave: (saveType: SaveTypes): JQueryPromise<boolean> => { return this.confirmSave(saveType); }
        };
    }

    constructor(
      private customerAccountRouteFactory: CustomerAccountRouteFactory,
      private customerAccountDefaultService: CustomerAccountDefaultService
    ) { }

    public activate(params: app.manager.components.interfaces.CustomerAccountDefaultGroupActivationParameters): JQueryPromise<void> {
        Check.hasSequence(params.customerAccountTypes, 'Customer account default groups cannot be empty');

        if (params.publicApiCallback) {
            params.publicApiCallback(this.getPublicApi());
        }

        let maxNumberOfColumns = params.columns || 2;
        Check.notNegative(maxNumberOfColumns);

        return Utils.wrapDfd(this.customerAccountDefaultService.getOrCreate(params.partnerId, params.customerAccountTypes))
            .then((accounts: Boo.Objects.CustomerAccountDefault[]): void => {
                // The accounts don't come back in the order that the parameters specify. Sort them.
                accounts = _.sortBy(accounts, (accountDefault) => {
                    return params.customerAccountTypes.indexOf(accountDefault.CustomerAccountTypeId);
                });

                // Collect all the promises, so we can resolve them together.
                let compositions: ICompositionRoute[] = accounts.map(x => this.customerAccountRouteFactory.create(x, this.customerAccountDefaultService, x.PartnerId));
                // we want to sort the resolved routes based on the order passed in.
                _.each(compositions, (route) => {
                    // Disable if necessary.
                    route.options.isEnabled = ko.observable(true);
                    // Keep a references to all the resolved models. We want to validate them all before we save.
                    route.callback = component => this.components.push(component);
                });

                // Chunk these into rows.
                this.grid(new Grid(compositions, maxNumberOfColumns));
            });
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        return launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanManagePartners, user);
    }

    public confirmSave(saveType: SaveTypes): JQueryPromise<boolean> {
        return System.resolvedPromise(true);
    }

    public save(saveType: SaveTypes, partnerId: number): JQueryPromise<void> {
        this.isLoading(true);
        return $.when(this.validate(SaveTypes.Complete))
            .then((result) => {
                // If we are valid, continue, else reject the promise as invalid.
                if (result) {
                    // Collect all the customer account objects.
                    let validAccounts = _.filter(this.components, (c: app.manager.components.interfaces.CustomerAccountDefaultViewModel) => {
                        if (c.customerAccount && (c.customerAccount.CustomerAccountDefaultId() > 0 || ((c.customerAccount.Username() || c.customerAccount.Password()) || c.customerAccount.Note()))) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    let accountDefaults: any[] = _.map(validAccounts, (c: app.manager.components.interfaces.CustomerAccountDefaultViewModel) => {
                        c.customerAccount.PartnerId(partnerId);
                        return ko.mapping.toJS(c.customerAccount);
                    });

                    return accountDefaults.length > 0 ? Utils.wrapDfd(this.customerAccountDefaultService.save(accountDefaults)) : System.emptyPromise();
                } else {
                    return System.autoRejectedPromise('Customer accounts are invalid.');

                }
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading(false);
            });
    }

    public isValid(saveType?: SaveTypes): boolean {
        return true;
    }

    public validate(saveType?: SaveTypes): JQueryPromise<IValidatedResult> {
        // Collect all the validation methods.
        let componentPromises: JQueryPromise<IValidatedResult>[] = _.map(this.components, (c: app.interfaces.IValidate) => {
            return c.validate(saveType === SaveTypes.Complete);
        });

        // Validate components.
        return $.when.apply(this, componentPromises)
            .then((...results: IValidatedResult[]) => {
                let isValid: boolean = _.every(results, (r: IValidatedResult) => { return r.isValid === true; });

                if (!isValid) {
                    toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
                }

                return isValid;
            });
    }
}
