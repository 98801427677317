import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-components-specialist-actioncomponents-comments',
	templateUrl: './Comments.component.html'
})
export class CommentsComponent {
    @Input() public comments: Boo.Objects.TaskNote[];
		@Input() public displayAsHtml?: boolean;
}
