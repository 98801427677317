<div [loadingOverlay]="isLoading()">
  <ng-container *ngIf="parent()">
    <div class="card">
      <div (click)="toggleCustomerInfoCollapsed(!toggleCustomerInfoCollapsed())" class="card-header cursor-pointer">
        <strong>{{partnerName()}}</strong>
        — New Ticket
        <i [hidden]="!(toggleCustomerInfoCollapsed() === false)" class="far fa-caret-square-up float-end"></i>
        <i [hidden]="!toggleCustomerInfoCollapsed()" class="far fa-caret-square-down float-end"></i>
      </div>
      <div [class.show]="toggleCustomerInfoCollapsed() === false" class="collapse">
        <compose Selector="app-components-managecustomer-customerinformation"
          [ActivateOptions]="{parent: parent(), page: 'NewTicket', showTicketSaveTimer:false }"></compose>
      </div>
    </div>
  </ng-container>
  <div class="card">
    <div class="card-header">
      <strong>Ticket Details</strong>
    </div>
    <div class="card-body">
      <div class="row">
        <ng-container *ngIf="ticket()">
          <div class="col-md-4">
            <div class="form-group" [dataValidation]="{ placement: 'top', value: ticket().TicketTypeId }">
              <label class="control-label">Type</label>
              <select select2 [ngModel]="ticket().TicketTypeId()" (ngModelChange)="ticket().TicketTypeId($event)"
                [ngModelOptions]="{ standalone: true }" class="form-control" name="ticketTypes">
                <option [ngValue]="0">Select Ticket Type</option>
                <option *ngFor="let option of visibleTicketTypes()" [ngValue]="option.TicketTypeId">{{ option.Name }}
                </option>
              </select>
            </div>
            <div class="form-group" [dataValidation]="{ placement: 'top', value: ticket().AssignedUserId }">
              <label class="control-label">Assigned User</label>
              <select select2 [ngModel]="ticket().AssignedUserId()" (ngModelChange)="ticket().AssignedUserId($event)"
                [ngModelOptions]="{ standalone: true }" class="form-control" name="assignedUser">
                <option [ngValue]="undefined">Assign User</option>
                <option *ngFor="let option of partnerAssignedUsers()" [ngValue]="option.UserId">{{ option.FullName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <!-- HTML is this way because of the way that datetimepicker is styled.  This is the only way I could get it to work. -->
            <ng-container *ngIf="isChangingDatesAllowed()">
              <div class="row">
                <div class="col-lg-6 form-group">
                  <label class="control-label">Next Action Date</label>
                  <input [dataValidation]="{ placement: 'top', value: ticketStartDate }"
                    [dateTimePicker]="{ date: ticketStartDate }" type="text" name="startDate" class="form-control" />
                </div>
                <div class="col-lg-6 form-group">
                  <label class="control-label">Next Action Time</label>
                  <input [dataValidation]="{ placement: 'top', value: ticketStartDate }" type="text" timePicker
                    [DateTime]="ticketStartTime" />
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!isChangingDatesAllowed()">
              <div class="form-group">
                <label class="control-label">Next Action Date</label>
                <input [value]="moment(startDateTime()).format('MM/DD/YYYY h:mm A')" [disabled]="true" [readonly]="true"
                  type="text" class="form-control" readonly="readonly" />
              </div>
            </ng-container>
            <ng-container
              *ngIf="changeToEstimatedMinutesIsAllowed() || (changeToEstimatedMinutesIsAllowed() === false && ticket().EstimatedMinutes() > 0)">
              <div class="form-group">
                <label class="control-label">
                  Estimated Minutes
                  <span [hidden]="!changeToEstimatedMinutesIsAllowed()">(HH:MM)</span>
                  <span [hidden]="changeToEstimatedMinutesIsAllowed()">:</span>
                </label>
                <ng-container *ngIf="changeToEstimatedMinutesIsAllowed()">
                  <select [dataValidation]="{ placement: 'top', value: ticket().EstimatedMinutes }"
                    [ngModel]="ticket().EstimatedMinutes()" (ngModelChange)="ticket().EstimatedMinutes($event)"
                    [ngModelOptions]="{ standalone: true }" class="form-select" name="estimatedMinutes">
                    <option [ngValue]="undefined">Select Time</option>
                    <option *ngFor="let option of estimatedMinutes()" [ngValue]="option.value">{{ option.text }}
                    </option>
                  </select>
                </ng-container>
                <ng-container *ngIf="!changeToEstimatedMinutesIsAllowed()">
                  <span>{{ticket().EstimatedMinutes()}}</span>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label">Reason</label>
              <textarea [dataValidation]="{ placement: 'top', value: ticket().Reason }" [ngModel]="ticket().Reason()"
                (ngModelChange)="ticket().Reason($event)" [ngModelOptions]="{ standalone: true }" class="form-control"
                name="ticketReason" maxlength="4000" style="height: 80px;"></textarea>
              <span class="help-block">Maximum Length is 4000 Characters</span>
            </div>
            <div class="form-group">
              <label class="control-label">Is Appointment?</label>
              <div class="form-group">
                <shared-components-customchoicelegacy [Settings]="{ value: ticket().IsAppointment }">
                </shared-components-customchoicelegacy>
              </div>
            </div>
            <ng-container *ngIf="hasFileUploadCapability()">
              <div class="form-group">
                <label class="control-label d-block">File Upload</label>
                <div class="input-group">
                  <input [ngModel]="filename()" (ngModelChange)="filename($event)"
                    [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text"
                    disabled="" />
                  <span class="btn btn-primary btn-file">
                    Select a File
                    <input [file]="{ data: file, accept: 'All Files (*.*)|*.*' }" type="file"
                      class="btn btn-outline-secondary" />
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="card card-body bg-light d-block text-end">
        <div class="col-md-12">
          <button (click)="close(false)" class="btn btn-link">Cancel</button>
          <button [disabled]="isLoading()" (click)="saveTicket()" class="btn btn-success">
            <i class="fa fa-save"></i>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
