<app-components-specialist-internal-tasks-qa-action-information
  [internalAction]="internalAction"></app-components-specialist-internal-tasks-qa-action-information>
<div id="internal-task-qa-criteria-float-panel" class="float-panel">
  <div class="float-panel-tab">
    <button class="float-panel-btn-expand btn btn-lg btn-primary">
      <i class="fa fa-check"></i>
    </button>
  </div>
  <div class="float-panel-main card">
    <div class="float-panel-draggable card-header">
      <div class="card-title">
        <i class="fa fa-check"></i>
        Profile Submission Quality
        <i class="float-panel-btn-collapse fa fa-minus float-end"></i>
      </div>
    </div>
    <div class="card-body" style="overflow-y: scroll; max-height: 550px;">

      <app-components-qa-grading-area-panel [taskType]="taskType" [qaCriteriaGroups]="qaCriteriaGroups" #gradingArea>
      </app-components-qa-grading-area-panel>
    </div>
    <div class="card-footer">
      <div class="text-end">
        <button (click)="close.next()" class="btn btn-primary">Close</button>
        <button (click)="unlock.next()" class="btn btn-info">
          <i class="fa fa-unlock"></i>
          Unlock &amp; Unassign
        </button>
        <button (click)="complete.next()" class="btn btn-success">
          <i class="fa fa-check"></i>
          Complete
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label for="originalTitle" class="control-label">Original New Title Text</label>
      <input readonly [ngModel]="input.OnsiteRecommendation.TitleNew"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="originalTitle" class="form-control" />
    </div>
    <div class="form-group">
      <label for="originalDescription" class="control-label">Original New Description Text</label>
      <textarea [(ngModel)]="input.OnsiteRecommendation.DescriptionNew"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="originalDescription" readonly
        style="height:auto" class="form-control"></textarea>
    </div>
    <div class="form-group">
      <label for="originalH1" class="control-label">Original New H1 Tag</label>
      <input readonly [ngModel]="input.OnsiteRecommendation.H1New"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="originalH1" class="form-control" />
    </div>
    <div class="form-group">
      <label for="originalH2" class="control-label">Original New H2 Tag</label>
      <input readonly [ngModel]="input.OnsiteRecommendation.H2New"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="originalH2" class="form-control" />
    </div>
    <div class="form-group">
      <label for="originalAltImageTag" class="control-label">Original New Alt Image Tag</label>
      <textarea [(ngModel)]="input.OnsiteRecommendation.AltImageNew"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="originalAltImageTag" readonly
        style="height:auto" class="form-control"></textarea>
    </div>
  </div>
  <div class="col-md-6" *ngIf="!wasRejected">
    <div class="form-group">
      <label for="reviewedTitle" class="control-label">Reviewed New Title Text</label>
      <input readonly [ngModel]="output.OnsiteRecommendation.TitleNew"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="reviewedTitle" class="form-control" />
    </div>
    <div class="form-group">
      <label for="reviewedDescription" class="control-label">Reviewed New Description Text</label>
      <textarea [(ngModel)]="output.OnsiteRecommendation.DescriptionNew"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="originalDescription" readonly
        style="height:auto" class="form-control"></textarea>
    </div>
    <div class="form-group">
      <label for="reviewedH1" class="control-label">Reviewed New H1 Tag</label>
      <input readonly [ngModel]="output.OnsiteRecommendation.H1New"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="reviewedH1" class="form-control" />
    </div>
    <div class="form-group">
      <label for="reviewedH2" class="control-label">Reviewed New H2 Tag</label>
      <input readonly [ngModel]="output.OnsiteRecommendation.H2New"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="reviewedH2" class="form-control" />
    </div>
    <div class="form-group">
      <label for="reviewedAltImageTag" class="control-label">Reviewed New Alt Image Tag</label>
      <textarea [(ngModel)]="output.OnsiteRecommendation.AltImageNew"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="reviewedAltImageTag" readonly
        style="height:auto" class="form-control"></textarea>
    </div>
  </div>
  <div class="col-md-6" *ngIf="wasRejected">
    <div class="form-group">
      <label for="title" class="control-label">Rejection Editor Feedback</label>
      <div class="clear-fix"></div>
      <div>
        <textarea #editorRichText [richText]="{ content: output.RejectionReason, options: editorToolbar }"
          name="editorsFeedback" rows="15" style="height: 300px;" class="form-control"
          placeholder="Enter Content"></textarea>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <label for="title" class="control-label">Change Files</label>
    <div class="tab-pane active" id="files">
      <div class="col-md-12">
        <app-table [items]="input.Files">
          <ng-template #tableHeader>
            <tr>
              <th sort field="Name">Name</th>
              <th sort field="InsertedDate">Upload Date</th>
              <th field="S3Url"></th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-file>
            <tr>
              <td>{{file.Name}}</td>
              <td>{{file.InsertedDate | dateText}}</td>
              <td style="width:50px;">
                <a [externalLink]="{ url: file.S3Url, text: 'View File' }" class="btn btn-primary"></a>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
  <div class="col-md-6" *ngIf="!wasRejected">
    <label for="title" class="control-label">Review Change File</label>
    <div class="tab-pane active" id="files">
      <div class="col-md-12">
        <app-table [items]="[output.ChangesFile]">
          <ng-template #tableHeader>
            <tr>
              <th sort field="Name">Name</th>
              <th sort field="InsertedDate">Upload Date</th>
              <th field="S3Url"></th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-file>
            <tr>
              <td>{{file.Name}}</td>
              <td>{{file.InsertedDate | dateText}}</td>
              <td style="width:50px;">
                <a [externalLink]="{ url: file.S3Url, text: 'View File' }" class="btn btn-primary"></a>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>