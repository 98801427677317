<div>
  <div class="row">
    <div class="col-md-12">
      <ul class="list-group">
        <li class="list-group-item" *ngIf="onsiteRecommendationImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Implementation Task</strong>
            </div>
            <div class="col-md-10">
              {{ onsiteRecommendationImplementationSummary.TaskTypeName }}
            </div>
          </div>
        </li>
        <li class="list-group-item" *ngIf="onsiteRecommendationImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Description</strong>
            </div>
            <div class="col-md-10">
              {{ onsiteRecommendationImplementationSummary.Description }}
            </div>
          </div>
        </li>
        <li class="list-group-item" *ngIf="onsiteRecommendationImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Instructions</strong>
            </div>
            <div class="col-md-10 preserve-whitespace">{{ onsiteRecommendationImplementationSummary.Instructions }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Onsite Changes</strong>
            </div>
            <div class="col-md-10">
              <ng-container *ngIf="onsiteChangeFileUrl">
                <a [externalLink]="{ url: onsiteChangeFileUrl, text:'View File' }" name="blogUrl" rel="noreferrer"></a>
              </ng-container>
              <ng-container *ngIf="!onsiteChangeFileUrl">
                Not found
              </ng-container>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New Title</strong>
            </div>
            <div class="col-md-10">
              {{ newTitle }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New Description</strong>
            </div>
            <div class="col-md-10">
              {{ newDescription }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New H1</strong>
            </div>
            <div class="col-md-10">
              {{ newH1 }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New H2</strong>
            </div>
            <div class="col-md-10">
              {{ newH2 }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New Alt Image</strong>
            </div>
            <div class="col-md-10">
              {{ newAltImage }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <br />
  <br />
  <div class="row" *ngIf="onsiteRecommendationImplementationSummary">
    <div class="col-md-8">
      <app-components-specialist-taskfiles [options]="{
        taskId: onsiteRecommendationImplementationSummary.TaskId,
        allowUpload: true,
        apiCallback: taskFilesApiCallback,
        useSmallTitle: true
      }"></app-components-specialist-taskfiles>
    </div>
  </div>
  <app-floating-panels>
    <app-floating-panel [displayConfig]="{ title: 'External Access', icon: 'fa-key', size: 'floating-panel-md' }">
      <compose *floatingPanelContent Selector="app-components-managecustomer-readonlyexternalaccess" [Model]="{ customer: currentAction().Customer }">
      </compose>
    </app-floating-panel>
    <app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
      <app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="currentAction().TaskDetails.TaskNotes">
      </app-components-specialist-actioncomponents-comments>
    </app-floating-panel>
  </app-floating-panels>
</div>