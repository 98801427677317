import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { KeywordConfig } from '../shared/models/keywords/keywords';

@Injectable({
  providedIn: 'root'
})
export class KeywordConfigService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/keywords/config`;

  constructor(private http: HttpClient) { }

  get(customerId: number): Observable<KeywordConfig> {
    return this.http.get<KeywordConfig>(`${this.baseUrl}/${customerId}`);
  }

  getAll(customerId: number): Observable<KeywordConfig[]> {
    return this.http.get<KeywordConfig[]>(`${this.baseUrl}/${customerId}/all`);
  }
}
