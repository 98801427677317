<div class="card" [loadingOverlay]="loading">
  <div class="card-header">
    <span>Edit Task Stewardship</span>
    <button (click)="cancel()" class="btn-close" title="Close"></button>
  </div>
  <div class="card-body animated fadeIn">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Name</label>
          <input class="form-control" [formControl]="nameCtrl" lpValidationMsg />
        </div>
        <div class="form-group">
          <label class="control-label">Steward</label>
          <ng-select [items]="boostabilityUsers" [clearable]="true" placeholder="Choose..." bindLabel="FullName"
            bindValue="UserId" [formControl]="taskStewardUserIdCtrl" [virtualScroll]="true" lpValidationMsg></ng-select>
        </div>
        <div class="form-group">
          <label class="control-label">Business Entity</label>
          <ng-select [items]="businessEntities" [clearable]="true" placeholder="Choose..." bindLabel="Name"
            bindValue="Value" [formControl]="businessEntityIdCtrl" [readonly]="businessEntityIdIsReadOnly" [virtualScroll]="true" lpValidationMsg></ng-select>
        </div>
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <div class="card">
              <div class="card-header">
                Available Responsibilities <app-components-helptext [key]="'TaskStewardshipAvailableResponsibilities'"></app-components-helptext>
              </div>
              <div class="card-body animated fadeIn">
                <div class="form-group">
                  <label class="control-label">Task Type</label>
                  <ng-select [items]="availableTaskTypes" [clearable]="true" placeholder="Choose..." bindLabel="Name"
                    bindValue="TaskTypeId" [formControl]="taskTypeCtrl" [virtualScroll]="true"></ng-select>
                </div>
                <div class="form-group">
                  <label class="control-label">Language</label>
                  <ng-select [items]="availableLanguages" [clearable]="true" placeholder="Choose..." bindLabel="Name"
                    bindValue="LanguageId" [formControl]="languageCtrl" [virtualScroll]="true"></ng-select>
                </div>
              </div>
              <div class="card-footer text-end">
                <button (click)="add()" class="btn btn-primary">
                  <i class="fa fa-plus"></i> Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <app-table [items]="taskStewardshipResponsibilities">
          <ng-template #tableHeader >
            <tr>
              <th sort asc field="TaskTypeName">Task Type</th>
              <th sort field="LanguageName" >Language</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-stewardship>
            <tr>
              <td>{{ stewardship.TaskTypeName }}</td>
              <td>{{ stewardship.LanguageName }}</td>
              <td>
                <button class="btn btn-primary" (click)="remove(stewardship)">Remove</button>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row">
      <div  class="col-md-6 text-start">
        <ng-container *ngIf=" this.taskStewardshipResponsibility.TaskStewardshipId">
          <button (click)="delete()" class="btn btn-warning">Delete</button>
        </ng-container>
      </div>
      <div class="col-md-6 text-end">
        <button (click)="cancel()" class="btn btn-link">Cancel</button>
        <button (click)="save()" class="btn btn-success">
          <i class="fa fa-save"></i> Save
        </button>
      </div>
    </div>
  </div>
</div>
