import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SessionStorageService } from './session-storage.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class LogService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/log`;

  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService) {}

  error(message: string, stackTrace: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/error`, {
        message: message,
        stackTrace: stackTrace
    });
  }

  info(message: string): void {
    this.sessionStorageService.getUser()
      .pipe(
        switchMap(user => this.http.post(`${this.baseUrl}/info`, {
          userId: user.UserId,
          message: message
        }))
      ).subscribe(_ => {});
  }
}
