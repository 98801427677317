import { Component, Input } from '@angular/core';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-page',
  templateUrl: './page.component.html',
  styleUrls: ['../customer-campaign/customer-campaign.component.scss']
})
export class PageComponent {
  @Input() customer: Boo.Objects.Customer;
  @Input() websiteUrl: Boo.Objects.WebsiteUrl;
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];
  @Input() haloKeywords: Boo.Objects.WebsiteKeywordTracking[];
  @Input() archivedWebsiteUrls: Boo.Objects.ArchivedWebsiteUrl[];
  @Input() archivedKeywords: Boo.Objects.ArchivedKeyword[];
  @Input() readOnlyReasons: string;
  @Input() firstPageKeywordPhrases: string[];
  @Input() restrictions: Boo.Objects.Enums.CustomerCampaignRestrictions[];
  @Input() reviewSource: Boo.OnsiteRecommendations.Models.Enums.ReviewSources;

  isUrlReadOnly: boolean;

  constructor(private customerCampaignService: CustomerCampaignService) { }

  get allReadOnlyReasons(): string {
    let allReadOnlyReasons: string = '';

    if (this.restrictions.includes(Boo.Objects.Enums.CustomerCampaignRestrictions.ReadOnly)) {
      allReadOnlyReasons = this.readOnlyReasons;
    }

    if (this.websiteUrl?.IsReadOnly) {
      allReadOnlyReasons = allReadOnlyReasons.concat(' ', this.websiteUrl.ReadOnlyReason).trim();
    }

    return allReadOnlyReasons;
  }

  get validationMessages(): string[] {
    let messages: string[] = [];

    if (this.customerCampaignService.isActiveStatus(this.websiteUrl) && !this.customerCampaignService.domainMatches(this.customer.Url, this.websiteUrl.Url)) {
      messages.push('The page domain must match the customer domain.');
    }

    if (this.websiteUrls.some(websiteUrl => websiteUrl.WebsiteUrlId != this.websiteUrl.WebsiteUrlId && this.customerCampaignService.isActiveStatus(websiteUrl) && websiteUrl.Url.toLowerCase().trim() === this.websiteUrl.Url.toLowerCase().trim())) {
      messages.push('Duplicate');
    }

    if (this.websiteUrl.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active && this.websiteUrl.Keywords.filter(keyword => keyword.IsActive).length === 0) {
      messages.push('Active pages must have at least one keyword.');
    }

    return messages;
  }
}
