import { Component } from '@angular/core';
import Check from 'framework/Check';
import { WebsiteService } from '../../../app/services/website.service';
import Utils from '../../../app/shared/utils';

@Component({
  selector: 'app-components-websites',
  templateUrl: './Websites.component.html'
})
export class WebsitesComponent implements IActivatable {
    public websites: Boo.Objects.Websites.Website[];
    public selectedWebsiteId: KnockoutObservable<number>;
    public selectedWebsite: KnockoutComputed<Boo.Objects.Websites.Website>;
    public customer: CustomerObservable;
    public isReadOnly: boolean;
    public isAdjustmentTabVisible: boolean;

    constructor(private websiteService: WebsiteService) { }

    public activate(params: IWebsitesViewModelActivationParams): JQueryPromise<void> {
        Check.isNotEmpty(params.customer, 'Customer cannot be empty');
        this.customer = params.customer;
        this.isReadOnly = params.isReadOnly === true ? true : params.isReadOnly;
        this.isAdjustmentTabVisible = params.isAdjustmentTabVisible === false ? false : true;
        return Utils.wrapDfd(this.websiteService.getManyByCustomerId(ko.mapping.toJS(params.customer.CustomerId)))
            .then((websites) => {
                //There are many reasons why a customer might end up with multiple websites. It appears that always want to show the most recent one here.
                this.websites = _.sortBy(websites, (website) => {
                    return website.InsertedDate;
                });
                this.selectedWebsiteId = ko.observable(this.websites.reverse()[0].WebsiteId);

                this.selectedWebsite = ko.computed(() => {
                    return _.find(this.websites, (x: Boo.Objects.Websites.Website) => {
                        return x.WebsiteId === this.selectedWebsiteId();
                    });
                });
            });
    }
}

interface IWebsitesViewModelActivationParams {
    customer: CustomerObservable;
    isReadOnly: boolean;
    isAdjustmentTabVisible: boolean;
}
