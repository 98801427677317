import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "prettyJson"
})
export class PrettyJsonPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    // if it's non-numeric and not json-formatted, just return the value (usually a string)
    let regex = /^\d+$/;
    if (value.indexOf('{') === -1 && !regex.test(value)) {
      return value;
    }

    return JSON.stringify(JSON.parse(value), null, '\t');
  }
}