<ng-container *ngIf="!urlSuggestions?.length">
  <div class="row">
    <div class="col-md-12">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No pages or keywords have been defined yet</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<ng-container *ngFor="let urlSuggestion of urlSuggestions">
  <app-components-shared-keywords-url-suggestion-readonly [urlSuggestion]="urlSuggestion">
  </app-components-shared-keywords-url-suggestion-readonly>
</ng-container>