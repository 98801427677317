<div class="card">
	<div class="card-header">
		<span>View Task Workspace</span>
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body">
		<div class="row">
			<div class="col-md-3">
				<div class="form-group">
					<label class="control-label">Task Id</label>
					<input [ngModel]="taskId()" (ngModelChange)="taskId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="number"/>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group">
					<button [disabled]="isLoading()" (click)="viewWorkSpace()" style="margin-top: 25px;" class="btn btn-primary">
						<i class="fa fa-search"></i>
						View Task Workspace
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
