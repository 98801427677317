import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QaCriteriaGroupService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/qacriteriagroup`;

  constructor(private http: HttpClient) { }

  getForTask(task: Boo.Objects.Task): Observable<Boo.Qa.QaCriteriaGroup[]> {
    return this.http.post<Boo.Qa.QaCriteriaGroup[]>(`${this.baseUrl}/GetForTask`, task);
  }
}
