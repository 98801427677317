<div>
	<app-components-togglepanel title="Contact Attempts" [titleBadge]="totalAttempts()" toggleBody="true">
		<div class="row text-center">
			<div class="col-lg-4">
				<h5>Contact Made</h5>
				<h4 class="card card-body bg-light d-block">
					<i class="fa fa-check"></i>
					<span>{{numberOfContactAttemptsContactMade()}}</span>
				</h4>
				<div>
					<ng-container *ngIf="lastContactMadeNote">
						<h5>
							<span class="badge bg-warning">
								<i class="far fa-clock"></i>
								<span>{{elapsedHumanizedTimeContactMade()}}</span>
							</span>
						</h5>
						<small>{{moment(lastContactMadeNote.InsertedDate()).format('MM/DD/YYYY, h:mm:ss a')}}</small>
					</ng-container>
				</div>
			</div>
			<div class="col-lg-4">
				<h5>No Contact Made</h5>
				<h4 class="card card-body bg-light d-block">
					<i class="fa fa-times"></i>
					<span>{{numberOfContactAttemptsContactNotMade()}}</span>
				</h4>
				<div>
					<ng-container *ngIf="lastContactNotMadeNote">
						<h5>
							<span class="badge bg-danger">
								<i class="far fa-clock"></i>
								<span>{{elapsedHumanizedTimeContactNotMade()}}</span>
							</span>
						</h5>
						<small>{{moment(lastContactNotMadeNote.InsertedDate()).format('MM/DD/YYYY, h:mm:ss a')}}</small>
					</ng-container>
				</div>
			</div>
			<div class="col-lg-4">
				<h5>Total</h5>
				<h4 class="card card-body bg-warning d-block">
					<i class="fa fa-phone"></i>
					<span>{{totalAttempts()}}</span>
				</h4>
			</div>
		</div>
		<app-table [items]="ticketNotes" [pageSize]="5" [exportFilename]="'ticket-history'">
			<ng-template #tableHeader>
				<tr>
					<th export sort desc field="InsertedDate">Date</th>
					<th export sort field="Text">Note</th>
					<th export sort field="UserFullName">User</th>
					<th export sort field="ContactTypeName">Contact Type</th>
					<th *ngIf="canReviseNotes">Revise</th>
				</tr>
			</ng-template>
			<ng-template #tableBody let-note>
				<tr>
					<td>{{note.InsertedDate | dateText: 'M/DD/YYYY h:mm a'}}</td>
					<td class="line-breaks"><shared-components-textwithlinks
							[Text]="note.Text"></shared-components-textwithlinks></td>
					<td>{{note.UserFullName}}</td>
					<td>{{note.ContactTypeName}}</td>
					<ng-container *ngIf="canReviseNotes">
						<td>
							<button [hidden]="!(isNoteRevisable(note))" (click)="reviseNote(note)"
								class="btn btn-primary">Revise</button>
						</td>
					</ng-container>
				</tr>
			</ng-template>
		</app-table>
	</app-components-togglepanel>
</div>
