import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-components-shared-keywords-ticket-halo-keyword-suggestions',
  templateUrl: './halo-keyword-suggestions-ticket.component.html'
})
export class HaloKeywordSuggestionsTicketComponent implements OnInit {
  @Input() haloKeywords: Boo.Objects.LegacyKeywordSuggestion[];
  @Input() newHaloKeywords: Boo.Objects.LegacyKeywordSuggestion[];
  @Output() haloKeywordsChange = new EventEmitter<Boo.Objects.LegacyKeywordSuggestion[]>();
  @Output() newHaloKeywordsChange = new EventEmitter<Boo.Objects.LegacyKeywordSuggestion[]>();
  @Output() updateApprovedCount = new EventEmitter<void>();
  @Input() isNationalChanged: EventEmitter<boolean>;
  disableEditing: boolean = false;
  private previouslyRejected: number[];

  constructor(private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.isNationalChanged.subscribe(isNationalChanged => {
      if (isNationalChanged) {
        this.previouslyRejected = this.haloKeywords.filter(x => x.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Rejected).map(x => x.KeywordSuggestionId);
        this.haloKeywords.forEach(x => x.KeywordSuggestionStatusId = Boo.Objects.Enums.KeywordSuggestionStatus.Rejected);
        this.disableEditing = true;
      } else {
        this.haloKeywords.forEach(x => x.KeywordSuggestionStatusId = this.previouslyRejected.includes(x.KeywordSuggestionId) ? Boo.Objects.Enums.KeywordSuggestionStatus.Rejected : Boo.Objects.Enums.KeywordSuggestionStatus.Proposed);
        this.disableEditing = false;
      }
    })
  }

  rejectHaloKeyword(keyword: Boo.Objects.LegacyKeywordSuggestion): void {
    keyword.KeywordSuggestionStatusId = Boo.Objects.Enums.KeywordSuggestionStatus.Rejected;
    this.updateApprovedCount.emit();
  }

  unRejectHaloKeyword(keyword: Boo.Objects.LegacyKeywordSuggestion): void {
    keyword.KeywordSuggestionStatusId = Boo.Objects.Enums.KeywordSuggestionStatus.Proposed;
    this.updateApprovedCount.emit();
  }

  isRejected(keyword: Boo.Objects.LegacyKeywordSuggestion): boolean {
    return keyword.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Rejected;
  }

  isDuplicate(keyword: Boo.Objects.LegacyKeywordSuggestion): boolean {
    return this.newHaloKeywords.filter(x => x.KeywordSuggestionStatusId !== Boo.Objects.Enums.KeywordSuggestionStatus.Rejected && x.Keyword.toLowerCase() === keyword.Keyword.toLowerCase() && (x.Area?.toLowerCase() === keyword.Area?.toLowerCase() || x.Area === keyword.Area)).length > 1;
  }

  getKeywordDisplayText(keyword: Boo.Objects.LegacyKeywordSuggestion): string {
    if (keyword.Area) {
      return keyword.IsAreaFirst ? keyword.Area + ' ' + keyword.Keyword : keyword.Keyword + ' ' + keyword.Area;
    } else {
      return keyword.Keyword;
    }
  }

  copyKeywordsToClipboard(): void {
    let text = this.haloKeywords
      .filter(x => x.KeywordSuggestionStatusId == Boo.Objects.Enums.KeywordSuggestionStatus.Proposed)
      .map(x => this.keywordToString(x)).join("\n");
    this.clipboard.copy(text);
    toastr.success("Approved halo keywords copied to clipboard.");
  }

  private keywordToString(keyword: Boo.Objects.LegacyKeywordSuggestion) {
    if (keyword.Area) {
      return keyword.IsAreaFirst ?
      keyword.Area + " " + keyword.Keyword :
      keyword.Keyword + " " + keyword.Area;
    } else {
      return keyword.Keyword;
    }
  }
}
