import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HostConfigurationService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/hostConfigurations`;

  constructor(private http: HttpClient) { }

  getConfigurableHosts(): Observable<Boo.Objects.Websites.Enums.Hosts[]> {
    return this.http.get<Boo.Objects.Websites.Enums.Hosts[]>(`${this.baseUrl}/getConfigurableHosts`);
  }

  getByPartnerId(partnerId: number): Observable<Boo.Objects.Websites.HostConfiguration[]> {
    const params = new HttpParams().set('partnerId', partnerId);
    return this.http.get<Boo.Objects.Websites.HostConfiguration[]>(`${this.baseUrl}/getByPartnerId`, {params: params});
  }

  save(hostConfigurations: Boo.Objects.Websites.HostConfiguration[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/save `, hostConfigurations);
  }
}
