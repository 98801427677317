import { Component } from '@angular/core';
import System from 'framework/System';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { TicketService } from '../../../services/ticket.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-stats-customerservice',
  templateUrl: './CustomerService.component.html'
})
export class CustomerServiceComponent implements IActivatable {
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);
    public ticketsCompleted: KnockoutObservable<number> = ko.observable(0);
    public criticalTicketPercent: KnockoutObservable<string> = ko.observable('0');
    public notesEntered: KnockoutObservable<number> = ko.observable(0);
    public emailsSent: KnockoutObservable<number> = ko.observable(0);
    public callsMade: KnockoutObservable<number> = ko.observable(0);

    constructor(private ticketService: TicketService) { }

    public activate(params: any): JQueryPromise<void> {
        return this.loadStats().then(() => {
            return System.emptyPromise();
        });
    }

    public loadStats(): JQueryPromise<void> {
        this.isLoading(true);
        let start = moment().format('L');
        let end = (moment().format('L') + ' 23:59:59');

        return Utils.wrapDfd(this.ticketService.getUserTicketSummary(start, end))
            .then((data) => {
                if (data) {
                    let summary = _.first(data);
                    this.ticketsCompleted(summary.TicketsCompleted);
                    this.notesEntered(summary.NotesEntered);
                    this.emailsSent(summary.EmailsSent);
                    this.callsMade(summary.CallsMade);

                    if (summary.TotalOpenTickets > 0) {
                        this.criticalTicketPercent(((summary.CriticalTickets / summary.TotalOpenTickets) * 100).toFixed(2));
                    } else {
                        this.criticalTicketPercent('0');
                    }
                }
            })
            .fail((message: string) => {
                toastr.error(message);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    public openTicketSummary(): void {
        PriorityViewModel.show('app-components-stats-userticketsummary', {});
    }
}
