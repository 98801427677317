<div [loadingOverlay]="isLoading" class="row">
  <div class="col-12">
    <ng-container *ngIf="shouldShowSubscriptions; else localProfilesTemplate">
      <app-tab-panel [displayMode]="tabPanelDisplayMode.horizontal">
        <app-tab [title]="'Locations'">
          <div *tabContent>
            <ng-container *ngTemplateOutlet="localProfilesTemplate"></ng-container>
          </div>
        </app-tab>
        <app-tab [title]="'Subscriptions'">
          <div *tabContent>
            <app-components-shared-business-citation-subscriptions [customerId]="customer.CustomerId"
              [manageableTaskTypes]="manageableTaskTypes" [allowAutoSubscribe]="allowAutoSubscribe"
              [allowValidationAndAugmentationOrdering]="allowValidationAndAugmentationOrdering"
              [showSaveButton]="showSubscriptionSaveButton">
            </app-components-shared-business-citation-subscriptions>
          </div>
        </app-tab>
      </app-tab-panel>
    </ng-container>
  </div>
</div>
<ng-template #localProfilesTemplate>
  <div class="row">
    <div class="offset-6 col-6 text-end">
      <ng-container *ngIf="partnerRequiresSso">
        <ng-container *ngIf="localProfileForms.controls.length === 0 && !isLoading">
          <button (click)="editOnPartnerSite()" class="btn btn-primary">
            <i class="fas fa-sign-in-alt"></i>{{ partnerSsoLabel }}
          </button>
        </ng-container>
        <button (click)="refreshButton()" [disabled]="isLoading" class="btn btn-primary">
          <i class="fas fa-sync"></i> Refresh
        </button>
      </ng-container>
      <ng-container *ngIf="!partnerRequiresSso && !isReadOnly">
        <span class="text-warning me-3" *ngIf="this.form.dirty || this.localProfileForms.dirty">
          Changes have not been saved
        </span>
        <button (click)="add()" class="btn btn-primary" *ngIf="!localProfileId && canAddLocalProfile">
          <i class="fa fa-plus"></i> Add
        </button>
        <ng-container *ngIf="localProfileForms.controls.length > 0">
          <button (click)="saveLocalProfilesButton()" class="btn btn-primary">
            <i class="fa fa-save"></i> Save
          </button>
        </ng-container>
        <ng-container *ngIf="localProfileForms.controls.length === 0">
          <button class="btn btn-outline-primary" disabled="" style="pointer-events: auto;">
            <i class="fa fa-save"></i> Save
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <form [formGroup]="localProfileForms">
    <app-components-shared-local-profile-list
      [localProfileForms]="localProfileForms"
      [customer]="customer"
      [readOnly]="isReadOnly"
      [showRegistration]="showRegistration"
      [disableBusinessCitationEligibilityControls]="disableBusinessCitationEligibilityControls"
      [(registrationPhoneNumber)]="registrationPhoneNumber"
      [requireFullLocalProfileOnAdd]="requireFullLocalProfileOnAdd"
      [requireFullValidation]="requireFullValidation">
    </app-components-shared-local-profile-list>
  </form>
</ng-template>