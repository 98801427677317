<div [loadingOverlay]="isLoading">
	<app-floating-panels>
		<app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: input.TaskNotes.length }">
			<app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="input.TaskNotes">
			</app-components-specialist-actioncomponents-comments>
		</app-floating-panel>
		<app-floating-panel [displayConfig]="{ title: 'Keyword Research Note', icon: 'fa-sticky-note' }">
			<app-components-specialist-actioncomponents-keywordresearchnote *floatingPanelContent [note]="output.KeywordResearchNote" [callback]="keywordResearchNoteCallback">
			</app-components-specialist-actioncomponents-keywordresearchnote>
		</app-floating-panel>
	</app-floating-panels>
	<div class="row">
		<ng-container *ngIf="customer">
			<div class="col-md-6">
				<ul class="list-group">
					<li class="list-group-item">
						<strong>Customer</strong>
						<span class="float-end">{{customer.Name}}</span>
					</li>
					<li class="list-group-item">
						<strong>Minutes</strong>
						<span class="float-end">{{customer.SeoMinutesPaidRecurring | seoMinutes}}</span>
					</li>
					<li class="list-group-item">
						<strong>Products</strong>
						<ul>
							<ng-container *ngFor="let $data of customer.Spends">
								<li class="form-control-static">
									<span>{{$data.ProductName}}</span>
									:
									<span>{{ $data.RetailSpend | currency }}</span>
								</li>
							</ng-container>
						</ul>
					</li>
				</ul>
			</div>
			<div class="col-md-6" [formGroup]="formGroup">
				<ul class="list-group">
					<li class="list-group-item">
						<strong>Country</strong>
						<select select2 [formControl]="selectedCountryId" [formValidation]="selectedCountryId" class="form-control">
							<option [ngValue]=null></option>
							<option *ngFor="let country of countries" [ngValue]="country.CountryId">{{ country.Name }}</option>
						</select>
					</li>
					<li class="list-group-item">
						<strong>Vertical</strong>
						<select select2 [formControl]="selectedVerticalId" [formValidation]="selectedVerticalId" class="form-control">
							<option [ngValue]=null></option>
							<option *ngFor="let vertical of verticals" [ngValue]="vertical.VerticalId">{{ vertical.Name }}</option>
						</select>
					</li>
				</ul>
			</div>
		</ng-container>
	</div>
	<div class="row mt-2">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header">
					<div data-toggle="collapse" href="#task-description" role="button">
						<span>Instructions</span>
						<i class="only-collapsed fa-caret-down fas float-end"></i>
						<i class="only-expanded fa-caret-up fas float-end"></i>
					</div>
				</div>
				<div id="task-description" class="collapse show">
					<div class="card-body">
						<div class="form-group">
							<div class="line-breaks">{{input.Description}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12" *ngIf="customer">
			<app-components-shared-keyword-ideation-tool
				[minimumHaloKeywordSuggestions]="keywordSuggestionConfig?.minimumHaloKeywordSuggestions"
				[minimumMainKeywords]="keywordSuggestionConfig?.minimumMainKeywords"
				[minimumPages]="keywordSuggestionConfig?.minimumPages"
				[customer]="customer"
				[wasLocalCampaign]="!customer?.IsCampaignNational"
				[taskId]="taskId"
				[campaignTypeReadOnly]="true"
				(keywordsAssigned)="onUrlSuggestionAdded($event)"
				(haloKeywordsAssigned)="onHaloKeywordSuggestionsAdded($event)">
			</app-components-shared-keyword-ideation-tool>
		</div>
	</div>
	<div class="row">
		<div class="col-md-6">
			<button class="btn btn-primary" (click)="openModal(addUrlSuggestionModal)"><i class="fas fa-plus"></i>Add Page</button>
		</div>
		<div class="col-md-6 float-end">
			<div class="float-end">
				<span class="badge pill bg-secondary" style="font-size: 90%; padding: 10px; margin:10px; font-weight: 400;">Target Pages: {{totalPageCount}}/{{keywordSuggestionConfig?.minimumPages}}</span>
				<span class="badge pill bg-secondary" style="font-size: 90%; padding: 10px; margin:10px; font-weight: 400;">Required Keywords: {{totalKeywordCount}}/{{keywordSuggestionConfig?.minimumKeywordSuggestions}}</span>
				<app-components-helptext [key]="'KeywordResearch_RecommendedKeywords'" [noMargin]="true"></app-components-helptext>
				<span class="badge pill bg-secondary" style="font-size: 90%; padding: 10px; margin:10px; font-weight: 400;">Required Halo Keywords: {{haloKeywordCount}}/{{keywordSuggestionConfig?.minimumHaloKeywordSuggestions}}</span>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<a href="#pages" data-toggle="tab" class="nav-link active">Pages / Keywords</a>
				</li>
				<li class="nav-item">
					<a href="#halo" data-toggle="tab" class="nav-link">Halo Keywords</a>
				</li>
				<li class="nav-item">
					<a href="#history" data-toggle="tab" class="nav-link">Historical Keywords</a>
				</li>
			</ul>
			<div class="tab-content">
				<div class="tab-pane active" id="pages">
					<app-components-shared-keywords-url-suggestions
						[urlSuggestionAdded]="urlSuggestionAdded.asObservable()"
						[minimumKeywordSuggestions]="keywordSuggestionConfig?.minimumKeywordSuggestions"
						[minimumPages]="keywordSuggestionConfig?.minimumPages"
						[maximumPages]="keywordSuggestionConfig?.maximumPages"
						[isLocalCampaign]="isLocalCampaign"
						[(totalKeywordCount)]="totalKeywordCount"
						[(totalPageCount)]="totalPageCount"
						[customerId]="customer?.CustomerId"
						[taskId]="taskId">
					</app-components-shared-keywords-url-suggestions>
					<ng-container *ngIf="unorderedUrls.length !== 0">
						<app-components-shared-keywords-website-urls-readonly [websiteUrls]="unorderedUrls"></app-components-shared-keywords-website-urls-readonly>
					</ng-container>
				</div>
				<div class="tab-pane" id="halo">
					<ng-container *ngIf="!isLoading">
						<app-components-shared-keywords-halo-keyword-suggestions
							[haloKeywordSuggestionsAdded]="haloKeywordSuggestionsAdded.asObservable()"
							[customerId]="customer?.CustomerId"
							[isLocalCampaign]="isLocalCampaign"
							[taskId]="taskId"
							[mimimumHaloKeywordSuggestions]="keywordSuggestionConfig?.minimumHaloKeywordSuggestions - activeHaloKeywordsCount"
							[maximumHaloKeywordSuggestions]="keywordSuggestionConfig?.maximumHaloKeywordSuggestions - activeHaloKeywordsCount"
							[existingHaloKeywords]="activeHaloKeywords"
							(haloKeywordCountChange)="haloKeywordCount = $event + activeHaloKeywordsCount">
						</app-components-shared-keywords-halo-keyword-suggestions>
					</ng-container>
					<ng-container *ngIf="activeHaloKeywords.length !== 0">
						<app-components-shared-keywords-halo-keywords-readonly class="pb-3" [haloKeywords]="activeHaloKeywords"></app-components-shared-keywords-halo-keywords-readonly>
					</ng-container>
				</div>
				<div class="tab-pane" id="history">
					<app-components-shared-keywords-historical-keyword-suggestions
						[historicalKeywordSuggestions]="historicalKeywordSuggestions">
				</app-components-shared-keywords-historical-keyword-suggestions>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #addUrlSuggestionModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add New Page</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label class="control-label">Target Page</label>
        <input type="text" class="form-control" [formControl]="newPageControl" [formValidation]="newPageControl" lpValidationMsg />
      </div>
      <div class="form-group">
        <label class="control-label">Is Existing?</label>
        <div>
          <shared-components-customchoice
						[value]="newPageIsExisting"
						[choiceTrue]="'Yes'"
            [choiceFalse]="'No'"
						(valueChange)="updateNewPageIsExisting($event)">
          </shared-components-customchoice>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
      <button type="button" class="btn btn-success" (click)="addNewPage(modal)">Save</button>
    </div>
  </div>
</ng-template>
