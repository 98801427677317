import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QaGradeService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/qagrade`;

  constructor(private http: HttpClient) { }

  getWithUser(taskId: number): Observable<Boo.Qa.QaGrade> {
    const params = new HttpParams().set('taskId', taskId);
    return this.http.get<Boo.Qa.QaGrade>(`${this.baseUrl}/GetWithUser`,  { params: params });
  }

  reviseGrade(qaGrade: Boo.Qa.QaGrade, qaCriteriaDetails: Boo.Qa.QaCriteriaDetail[]): any {
    return this.http.post(`${this.baseUrl}/ReviseGrade`, { qaGrade: qaGrade, qaCriteriaDetails: qaCriteriaDetails });
  }
}
