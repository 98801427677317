<div class="card">
  <div class="card-header">
    <span>{{title}}</span>
    <button (click)="close()" class="btn-close" title="Close"></button>
  </div>
  <div [loadingOverlay]="isLoading" class="card-body">
    <div class="row">
      <div class="col-md-12">
        <h6>Excluded Task Types</h6>
      </div>
    </div>
    <app-partner-tasktype-selection
      [partnerTaskTypeUsages]="partnerTaskTypeUsages"
      [partnerId]="partner.PartnerId"
      (addExcludedTaskType)="addExclusion($event)">
    </app-partner-tasktype-selection>
    <div class="row">
      <div class="col-md-7">
        <table class="table table-bordered" *ngIf="taskTypeExclusions && taskTypeExclusions.length">
          <thead>
            <tr>
              <th style="width: 100px">TaskTypeId</th>
              <th>Name</th>
              <th style="width: 50px"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let taskType of taskTypeExclusions">
              <tr>
                <td>{{taskType.TaskTypeId}}</td>
                <td>{{ getTaskTypeName(taskType.TaskTypeId) }}</td>
                <td class="d-grid gap-2">
                  <button class="btn btn-danger btn-sm" (click)="removeExclusion(taskType.TaskTypeId)">
                    <i class="far fa-trash-alt me-0"></i>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div class="alert alert-primary" *ngIf="!taskTypeExclusions.length">No task exclusions for partner</div>
      </div>
    </div>
  </div>
  <div class="card-footer text-end">
    <button (click)="close()" class="btn btn-link">Close</button>
    <button class="btn btn-success" (click)="save()">Save</button>
  </div>
</div>
