import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActionService {
  readonly defaultPriority: number = 0;
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/actions`;

  constructor(private http: HttpClient) { }

  get(taskId: number): Observable<Boo.Objects.Action> {
    return this.http.post<Boo.Objects.Action>(`${this.baseUrl}/Select`, taskId);
  }

  getByTaskId(taskId: number): Observable<Boo.Objects.Action> {
    return this.http.post<Boo.Objects.Action>(`${this.baseUrl}/SelectByTaskId`, taskId);
  }

  updateStatus(action: Boo.Objects.Action): Observable<any> {
    return this.http.post(`${this.baseUrl}/UpdateStatus`, action);
  }

  getNextActionInQueue(priorityTaskTypeId: number = this.defaultPriority, includedLanguageIds: number[] = null): Observable<Boo.Objects.Action> {
    return this.http.post<Boo.Objects.Action>(`${this.baseUrl}/SelectNextActionInQueue`, {priorityTaskTypeId: priorityTaskTypeId, includedLanguageIds: includedLanguageIds});
  }

  getCompletedByCustomerAndDate(customerId: number, startDate: string, endDate: string, showAllTasks: boolean): Observable<Boo.Objects.ActionSummary[]> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('showAllTasks', showAllTasks);
  
    return this.http.get<Boo.Objects.ActionSummary[]>(`${this.baseUrl}/GetCompletedByCustomerAndDate`, { params: params });
  }

  toggleReportingVisibility(taskId: number, visible: boolean): Observable<any> {
    return this.http.post(`${this.baseUrl}/ToggleReportingVisibility`, {
        taskId: taskId,
        visible: visible
    });
  }
}
