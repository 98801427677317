<div class="row">
  <div class="col-md-12">
    <table class="table table-bordered table-hover table-primary">
      <thead>
        <tr>
          <th>Active Halo Keywords</th>
        </tr>
      </thead>
      <ng-container *ngFor="let haloKeyword of haloKeywords">
        <tbody>
          <tr>
            <td>{{haloKeyword.KeywordPhrase}}</td>
          </tr>
        </tbody>
      </ng-container>
      <ng-container *ngFor="let phrase of haloKeywordPhrases">
        <tbody>
          <tr>
            <td>{{phrase}}</td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>
</div>