import { Component } from '@angular/core';
import System from 'framework/System';
import IValidate = app.interfaces.IValidate;
import IValidatedResult = app.interfaces.IValidatedResult;
import IValidationPublicApi = app.interfaces.IValidationPublicApi;
import EmailRecipientTypes = Boo.Objects.Enums.EmailRecipientTypes;
import { CustomerUserService } from '../../../services/customer-user.service'
import { UserService } from '../../../services/user.service';
import Utils from '../../../shared/utils';
import { EmailRecipientTypePipe } from '../../../shared/pipes/emailRecipientType.pipe';

@Component({
	selector: 'app-components-managecustomer-customeruser',
	templateUrl: './CustomerUser.component.html',
    providers: [EmailRecipientTypePipe]
})
export class CustomerUserComponent implements IActivatable {
    public childComponentValidationApi: IValidationPublicApi;
    public countryAbbreviation: KnockoutComputed<string>;
    public customerUser: CustomerUserObservable;
    public originalCustomerUser: CustomerUserObservable;
    public customer: CustomerObservable;
    public isLoading: KnockoutObservable<boolean>;
    public partnerName: string;
    public refreshCustomer: () => {};
    public validation: KnockoutObservable<any>;
    public emailRecipientOptions = [
        EmailRecipientTypes.To,
        EmailRecipientTypes.Cc,
        EmailRecipientTypes.Bcc
    ]
    public partner: Boo.Objects.Partner;

    protected childComponentValidations: { validation: IValidate, uid: string }[];

    constructor(private customerUserService: CustomerUserService, private userService: UserService) { };

    private deleteUser: (customerUser: CustomerUserObservable) => {};

    public activate(params: ICustomerUserViewModelActivateParams): JQueryPromise<void> {
        this.isLoading = ko.observable(false);

        if (params.refreshCustomer) {
            this.refreshCustomer = params.refreshCustomer;
        }
        this.deleteUser = params.deleteUser;
        this.customer = params.customer;
        this.customerUser = params.customerUser;
        this.originalCustomerUser = ko.mapping.fromJS(ko.mapping.toJS(params.customerUser));
        this.originalCustomerUser.CustomerUserPhoneNumbers = ko.mapping.fromJS(ko.mapping.toJS(params.customerUser.CustomerUserPhoneNumbers));
        this.partner = params.partner;
        this.partnerName = this.partner.Name;
        this.countryAbbreviation = ko.computed(() => this.customerUser.IsCustomerUserPartner() ? params.partnerCountryAbbreviation : params.customerCountryAbbreviation);
        this.childComponentValidations = [];

        this.childComponentValidationApi = {
            add: (x: app.interfaces.IValidate, y: string): void => this.addValidation(x, y),
            remove: (x: string): void => this.removeValidation(x)
        };

        this.customerUser.FirstName.subscribe(() => this.customerUser.IsDirty(true));
        this.customerUser.LastName.subscribe(() => this.customerUser.IsDirty(true));
        this.customerUser.Username.subscribe(() => this.customerUser.IsDirty(true));
        this.customerUser.IsCustomerUserPartner.subscribe(() => this.customerUser.IsDirty(true));
        this.customerUser.IsCustomerUserActive.subscribe(val => {
          this.customerUser.IsDirty(true);
          if (val === false) {
            this.customerUser.CanReceiveTextMessages(false);
          }
        });
        this.customerUser.CanContact.subscribe(val => {
          this.customerUser.IsDirty(true);
          if (val === false) {
            this.customerUser.CanReceiveTextMessages(false);
          }
        });

        this.customerUser.FirstName.extend({
            required: true
        });

        this.customerUser.LastName.extend({
            required: true
        });

        this.customerUser.Username.extend({
            required: true,
            emailAddress: true
        });

        this.validation = ko.validatedObservable(
            [this.customerUser.FirstName,
            this.customerUser.LastName,
            this.customerUser.Username]);

        return System.emptyPromise();
    }

    public addValidation(validation: app.interfaces.IValidate, uid: string): void {
        this.childComponentValidations.push({ validation, uid });
    }

    public removeValidation(uid: string): void {
        this.childComponentValidations = this.childComponentValidations.filter(x => x.uid !== uid);
    }

    public cancelEdit(): void {
        if (this.customerUser.CustomerUserId() === 0) {
            this.deleteUser(this.customerUser);
        } else {
            if (this.customerUser.IsDirty()) {
                if (this.refreshCustomer) {
                    this.refreshCustomer();
                }
                else {
                  this.resetCustomerUser();
                  this.customerUser.Editing(false);
                }
            } else {
                this.customerUser.Editing(false);
            }
        }
    }

    public addPhoneNumber(): void {
        this.customerUser.IsDirty(true);
        let newPhoneNumber = {
            PhoneNumber: {
                Number: ko.observable(''),
                Primary: ko.observable(this.customerUser.CustomerUserPhoneNumbers().length === 0)
            } as PhoneNumberObservable
        } as CustomerUserPhoneNumberObservable;

        this.customerUser.CustomerUserPhoneNumbers.push(newPhoneNumber);
    }

    public deletePhoneNumber(customerUserPhoneNumber: CustomerUserPhoneNumberObservable): void {
        this.customerUser.CustomerUserPhoneNumbers.remove(customerUserPhoneNumber);
    }

    public canConfigureTextMessaging(): boolean {
      return this.partner.AllowsTextMessaging
        && this.customerUser.CanContact()
        && this.customerUser.IsCustomerUserActive()
        && !!this.customerUser.PrimaryPhoneNumber();
    }

    public save(): JQueryPromise<any> {
        this.isLoading(true);
        let promises = this.childComponentValidations.map(x => x.validation.validate(true));

        // This validation check occurs after the child validations are checked so that all messages can be displayed
        if (!this.validation.isValid()) {
            this.validation.errors.showAllMessages();
            this.isLoading(false);
            return System.resolvedPromise(false);
        }

        let areComponentsValid = false;

        return $.when(...promises)
            .then((...results: IValidatedResult[]) => {
                let isValid = results.reduce(
                    (isValidState, validation) => {
                        return isValidState && validation.isValid;
                    },
                    true);
                areComponentsValid = isValid;
                return $.when(this.confirmUserEdit(), this.confirmUserOverwrite(this.customerUser.Username(), this.isNewUser()));
            })
            .then((confirmEdit, confirmOverwrite) => {
                if (areComponentsValid && confirmEdit && confirmOverwrite) {
                    if (this.isNewUser()) {
                        return this.saveNewUser();
                    } else {
                        return this.saveUser();
                    }
                }

                return System.emptyPromise();
            }).then(() => {
                this.isLoading(false);
                return areComponentsValid;
            }).fail((displayMessage) => {
                this.isLoading(false);
                toastr.error(displayMessage);
            });
    }

    private saveUser(): JQueryPromise<any> {
        return Utils.wrapDfd(this.userService.save(
            ko.mapping.toJS(this.customerUser),
            this.customerUser.CustomerId(),
            this.customerUser.IsCustomerUserActive(),
            this.customerUser.CanContact(),
            this.customerUser.CanReceiveTextMessages(),
            this.customerUser.IsCustomerUserPartner(),
            ko.mapping.toJS(this.customerUser.CustomerUserPhoneNumbers),
            this.customerUser.EmailRecipientType()
        )).then(() => {
            toastr.success('Successfully saved user.');
            if (this.refreshCustomer) {
                this.refreshCustomer();
            } else {
                this.originalCustomerUser = ko.mapping.fromJS(ko.mapping.toJS(this.customerUser));
                this.originalCustomerUser.CustomerUserPhoneNumbers = ko.mapping.fromJS(ko.mapping.toJS(this.customerUser.CustomerUserPhoneNumbers));
                this.customerUser.IsDirty(false);
                this.customerUser.Editing(false);
            }
        });
    }

    private saveNewUser(): JQueryPromise<void> {
        return Utils.wrapDfd(this.customerUserService
            .create(
                ko.mapping.toJS(this.customer),
                this.customerUser.Username(),
                this.customerUser.FirstName(),
                this.customerUser.LastName(),
                this.customerUser.IsCustomerUserActive(),
                this.customerUser.CanContact(),
                this.customerUser.CanReceiveTextMessages(),
                this.customerUser.IsCustomerUserPartner(),
                ko.mapping.toJS(this.customerUser.CustomerUserPhoneNumbers),
                this.customerUser.EmailRecipientType()))
            .then((customerUser: Boo.Objects.CustomerUser) => {
                toastr.success('Successfully created new user.');
                if (this.refreshCustomer) {
                    this.refreshCustomer();
                } else {
                    this.originalCustomerUser = ko.mapping.fromJS(ko.mapping.toJS(this.customerUser));
                    this.originalCustomerUser.CustomerUserPhoneNumbers = ko.mapping.fromJS(ko.mapping.toJS(this.customerUser.CustomerUserPhoneNumbers));
                    this.customerUser.CustomerUserId(customerUser.CustomerUserId);
                    this.customerUser.UserId(customerUser.UserId);
                    this.customerUser.IsDirty(false);
                    this.customerUser.Editing(false);
                }
            });
    }

    private confirmUserEdit(): JQueryPromise<boolean> {
        return $.Deferred<boolean>((dfd) => {
            if (this.customerUser.CustomerUsers().length <= 1) {
                return dfd.resolve(true);
            }
            if (!this.userDataModified()) {
                return dfd.resolve(true);
            }
            const msg = 'Warning: If you click \'OK\', you will overwrite information for a user that has access to several accounts. The user will be updated as ' + this.customerUser.FullName() + '<br><br>' +
                'Did you mean to ADD a new user to this account instead? If so, please click \'CANCEL\' and then ADD a new user.' + '<br><br>' +
                'Unwanted users may be deleted from an account by clicking on the garbage can icon.';
            return bootbox.confirm(msg, (result: boolean) => dfd.resolve(result));
        }).promise();
    }

    private confirmUserOverwrite(username: string, isNewUser: boolean): JQueryPromise<boolean> {
        if (!this.usernameHasChanged()) {
            return System.resolvedPromise(true);
        }

        return $.Deferred<boolean>((dfd => {
            Utils.wrapDfd(this.userService.getByUsername(username))
                .then((user) => {
                    if (user == null) {
                        return dfd.resolve(true);
                    }

                    if (isNewUser) {
                        this.customerUser.FirstName(user.FirstName);
                        this.customerUser.LastName(user.LastName);
                        return dfd.resolve(true)
                    }
                    else {
                        toastr.error(`There is already a user with this username: ${user.Username}
                                    <br><br>Editing a user to the same username as an existing user is not allowed. Please add a new user instead.`);
                        return dfd.resolve(false);
                    }
                })
        })).promise();
    }

    private resetCustomerUser() {
      this.customerUser.FirstName(this.originalCustomerUser.FirstName());
      this.customerUser.LastName(this.originalCustomerUser.LastName());
      this.customerUser.Username(this.originalCustomerUser.Username());
      this.customerUser.CustomerUserPhoneNumbers([]);

      this.originalCustomerUser.CustomerUserPhoneNumbers().forEach((t) => {
        this.customerUser.CustomerUserPhoneNumbers.push(ko.mapping.fromJS(ko.mapping.toJS(t)));
      });

      this.customerUser.IsCustomerUserActive(this.originalCustomerUser.IsCustomerUserActive());
      this.customerUser.IsCustomerUserPartner(this.originalCustomerUser.IsCustomerUserPartner());
      this.customerUser.CanContact(this.originalCustomerUser.CanContact());
      this.customerUser.IsDirty(false);
    }
    
    private isNewUser() {
        return this.customerUser.CustomerUserId() === 0;
    }

    private usernameHasChanged() {
        return this.customerUser.Username() !== this.originalCustomerUser.Username();
    }

    private userDataModified() {
        return this.customerUser.FirstName() !== this.originalCustomerUser.FirstName()
            || this.customerUser.LastName() !== this.originalCustomerUser.LastName()
            || this.customerUser.Username() !== this.originalCustomerUser.Username();
    }
}

interface ICustomerUserViewModelActivateParams {
    customer: CustomerObservable;
    customerUser: CustomerUserObservable;
    partner: Boo.Objects.Partner;
    customerCountryAbbreviation: string;
    partnerCountryAbbreviation: string;
    refreshCustomer: () => {};
    deleteUser: (customerUser: CustomerUserObservable) => {};
}
