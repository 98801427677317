/* tslint:disable */

import { Component } from '@angular/core';
import permissions from 'app/models/Permissions';
import partner from 'app/models/partner';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { PartnerUserService } from '../../../services/partner-user.service';
import { TicketService } from '../../../services/ticket.service';
import { ticketTypeConfig } from '../../../components/ticket/TicketTypeConfig';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-customerservice-managetickets',
	templateUrl: './ManageTickets.component.html'
})
export class ManageTicketsComponent {
    public currentPartner: any = ko.observable(new partner());
    public isLoading: any = ko.observable(false);
    public startDate: any = ko.observable(moment()).extend({ required: true });
    public endDate: any = ko.observable().extend({ required: true });
    public searchText: any = ko.observable();
    public selectedTeam: any = ko.observable();
    public selectedUserFilter: any = ko.observable();
    public selectedUser: any = ko.observable(0);
    public selectedAssignedState: any = ko.observable(null);
    public minSpend: any = ko.observable(0.00).extend({ numeric: { precision: 2 } });
    public maxSpend: any = ko.observable(0.00).extend({ numeric: { precision: 2 } });
    public showInactiveUsers: any = ko.observable(false);
    public selectedStatus: any = ko.observable(window.launchpad.config.StatusEnum.Active);
    public languages: any = ko.observableArray([]);
    public selectedLanguageIds: any = ko.observableArray([]);
    public userCanDeleteTickets: any = ko.observable(false);
    public tickets: any = ko.observableArray([]);

    constructor(
      private partnerUserService: PartnerUserService,
      private ticketService: TicketService,
      private sessionStorageService: SessionStorageService) { }

    public selectedTicketTypes: any = ko.observableArray([]);
    public teams: any = ko.observableArray([]);
    public users: any = ko.observableArray([]);
    public inactiveUsers: any = ko.observableArray([]);

    public userList: any = ko.computed(() => {
        return this.showInactiveUsers() ? this.inactiveUsers() : this.users();
    });
    public assignedStates: any = {
        'Assigned': window.launchpad.config.AssignedStateEnum.Assigned,
        'NotAssigned': window.launchpad.config.AssignedStateEnum.NotAssigned,
        'Either': null
    };
    public ticketTypes: any = ko.observableArray([]);
    public customerStatuses: any = [
        { name: 'Active', value: window.launchpad.config.StatusEnum.Active },
        { name: 'Canceled', value: window.launchpad.config.StatusEnum.Canceled },
        { name: 'All', value: -2 }
    ];

    public hasTeams: any = ko.computed(() => {
        return this.teams() && this.teams().length > 0;
    });

    public isTeamSelectVisible: any = ko.computed(() => {
        return this.currentPartner().PartnerId === 1;
    });

    public isViewOnly: any = ko.computed(() => {
        return this.currentPartner().PartnerId !== 1;
    });

    public ticketCount: any = ko.computed(() => {
        return this.tickets() && this.tickets().length > 0 ? this.tickets().length : 0;
    });

    public isValid: any = ko.computed(() => {
        return ko.validatedObservable(this).isValid();
    });

    public selectedTickets: any = ko.computed(() => {
        if (!this.tickets()) {
            return [];
        }
        return this.tickets().filter((item: any) => {
            return item.isSelected;
        });
    });

    public hasTicketsSelected: any = ko.computed(() => {
        return this.selectedTickets() && this.selectedTickets().length > 0;
    });
    // Functions

    public toggleAssignedState(assignedState: any, newState: any, event: any): any {
        if (assignedState === this.assignedStates[newState]) {
            return;
        }
        this.selectedAssignedState(this.assignedStates[newState]);
    }

    public openCustomer(ticket: any): void {
      PriorityViewModel.showManageCustomer(ticket.CustomerId);
    }

    public editTicket(ticket: any): void {
      PriorityViewModel.showTicket(ticket.CustomerId, ticket.TicketId, false);
    }

    public canActivate(user: any): any {
        return _.any(user.PartnerUsers,
            (item: any) => {
                return item.UserLevelId === 1 || // Admin
                    item.UserLevelId === 5 || // Manager
                    item.UserLevelId === 6; // Customer Service
            });
    }

    public selectTicket(ticket: any): void {
        // Toggle
        ticket.isSelected(!ticket.isSelected());
    }

    public activate(): any {
        this.reset();
        return $.when<any>(
          Utils.wrapDfd(this.sessionStorageService.getStaticData()),
          Utils.wrapDfd(this.sessionStorageService.getPartner()),
          Utils.wrapDfd(this.partnerUserService.getInactiveByUserLevelId(Boo.Objects.Enums.UserLevelEnum.CustomerService)),
          Utils.wrapDfd(this.sessionStorageService.getPartnerUsers()),
          Utils.wrapDfd(this.sessionStorageService.getUser()),
          Utils.wrapDfd(this.partnerUserService.getByUserLevel(Boo.Objects.Enums.UserLevelEnum.CustomerService)))
          .then((staticData, partner, inactiveUserData, partnerUsers, user, getByUserLevel) => {
            this.userCanDeleteTickets(launchpad.hasPermission(partner, partnerUsers, permissions.CanDeleteTickets, user));
            this.languages(staticData.Languages.filter((x: any) => x.SupportStatusId === window.launchpad.config.supportStatus.supported));
            const hasAllTeams = launchpad.hasPermission(partner, partnerUsers, permissions.HasAllTeams, user);
            if (hasAllTeams) {
                this.teams(staticData.Teams);
            } else {
                let teams: any[] = [];
                _.each(partnerUsers,
                    (pu: any) => {
                        if (pu.Teams) {
                            teams = teams.concat(pu.Teams);
                        }
                    });
                const teamIds = _.pluck(teams, 'TeamId');
                this.teams(_.filter(staticData.Teams,
                    (team: any) => {
                        return _.contains(teamIds, team.TeamId);
                    }));
            }
            this.ticketTypes(_.sortBy(_.filter(staticData.TicketTypes, (item: any) => item.IsActive), 'Name'));
            this.currentPartner(partner);
            const inactiveUsers = _(inactiveUserData).sortBy((user) => {
                return user.FullName.toLowerCase();
            });
            inactiveUsers.splice(0, 0, { FullName: 'None', UserId: 0 });
            this.inactiveUsers(inactiveUsers);
            const users = _(getByUserLevel).sortBy((user) => {
                return user.FullName.toLowerCase();
            });
            users.splice(0, 0, { FullName: 'None', UserId: 0 });
            this.users(users);
            this.reset();
        }).fail(() => {
            throw 'Cannot get static data.';
        });
    }

    public reset(): void {
        this.startDate(moment().subtract(1, 'months'));
        this.endDate(moment().add(1, 'months'));
        this.selectedTeam(undefined);
        if (this.currentPartner().PartnerId !== 1) {
            const team = _(this.teams()).find((item) => {
                return item.Name === 'Partner: ' + this.currentPartner().Name;
            });
            if (team) {
                this.selectedTeam(team.TeamId);
            }
        }
        this.selectedAssignedState(null);
        this.selectedTicketTypes([]);
        this.selectedUserFilter(undefined);
        this.searchText('');
        this.minSpend(0);
        this.maxSpend(0);
        this.tickets([]);
    }

    public search(): void {
        if (this.isValid()) {
            this.isLoading(true);
            this.tickets([]);

            Utils.wrapDfd(this.ticketService.search(
              this.searchText() ? this.searchText() : null, // searchString
              this.selectedTeam() || 0, // teamId
              this.selectedUserFilter() || 0, // userId
              this.selectedAssignedState() === null ? -1 : this.selectedAssignedState(), // isAssigned
              '1,2', // statusIds
              this.selectedTicketTypes().join(','), // ticketTypeIds
              this.selectedLanguageIds().join(','), // languageIds
              window.launchpad.utils.getJsDateWithoutTime(this.startDate()), // startDate
              window.launchpad.utils.getJsDateWithoutTime(this.endDate()), // endDate - Stored procedure adds a day to the end date.  So to get values for 1/7/2014 you would pass 1/7/2014 as the start and end date
              this.minSpend(), // minSpend
              this.maxSpend(), // maxSpend
              this.selectedStatus() // customerStatusId
            )).then((data: Boo.Objects.TicketSearchResult[]) => {
                if (data.length > 0) {
                    _.each(data,
                        (item: any) => {
                            item.isSelected = ko.observable(false);
                        });
                    this.tickets(data);
                } else {
                    toastr.warning('No results found.');
                }
            }).fail((displayMessage) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading(false);
            });
        }
    }

    public selectAllTickets(): void {
        this.isLoading(true);
        $.when($.Deferred((dfd) => {
            if (this.tickets()) {
                const tickets = ko.toJS(this.tickets());
                for (let i = 0; i < tickets.length; i++) {
                    tickets[i].isSelected = ko.observable(true);
                }
                this.tickets(tickets);
            }
            dfd.resolve();
        })).done(() => {
            this.isLoading(false);
        });
    }

    public deselectAllTickets(): void {
        this.isLoading(true);
        if (this.tickets()) {
            const tickets = ko.toJS(this.tickets());
            for (let i = 0; i < tickets.length; i++) {
                tickets[i].isSelected = ko.observable(false);
            }
            this.tickets(tickets);
        }
        this.isLoading(false);
    }

    public assignSelectedTickets(): void {
        if (this.hasTicketsSelected()) {
            this.assignAndUnassignBatch(true);
        } else {
            toastr.warning('No tickets selected.');
        }
    }

    public unassignSelectedTickets(): void {
        if (this.hasTicketsSelected()) {
            this.assignAndUnassignBatch(false);
        } else {
            toastr.warning('No tickets selected.');
        }
    }

    private ticketTypeCanBeDeleted(ticketType: number): boolean {
        return !ticketTypeConfig.permanent.includes(ticketType);
    }

    public deleteTicket(ticket: any, event: any): any {
        if (!this.userCanDeleteTickets()) {
            toastr.error("You don't have permission to delete tickets");
            return;
        }
        if (!this.ticketTypeCanBeDeleted(ticket.TypeId)) {
            toastr.error('This ticket type cannot be deleted');
            return;
        }
        event.preventDefault();
        bootbox.prompt('Reason for deleting the ticket?',
            (reason: any) => {
                if (reason) {
                    $.ajaxSettings.traditional = true;
                    if (this.hasTicketsSelected()) {
                        this.isLoading(true);
                        Utils.wrapDfd(this.ticketService.delete(ticket.TicketId, `Ticket type: ${ticket.TypeName} (Id: ${ticket.TicketId}) was deleted. Reason for deletion: ${reason}`))
                        .then(() => {
                            toastr.success('The ticket has been deleted successfully.');
                            this.search();
                        }).fail((displayMessage) => {
                            toastr.error(displayMessage);
                            this.isLoading(false);
                        });
                    } else {
                        toastr.warning('No tickets selected.');
                    }
                } else {
                    let msg = 'Ticket was not deleted.';
                    if (reason === '') {
                        msg += '<br/><br/><strong>A reason is required to delete a ticket</strong>';
                        toastr.error(msg);
                    } else {
                        toastr.info(msg);
                    }
                }
            });
    }

    public assignAndUnassignBatch(shouldAssign: any): void {
        this.isLoading(true);
        const ticketIds = _.pluck(_.filter(this.tickets(), (ticket: any) => { return ticket.isSelected() === true; }), 'TicketId');
        Utils.wrapDfd(this.ticketService.assignUnassignBatch(shouldAssign, this.selectedUser(), ticketIds))
        .then((data: any) => {
            if (data.FailedCount > 0) {
                let failMessage = `Failed to update ${data.FailedCount} tickets.<ol>`;
                for (let i = 0; i < data.FailedCount; i++) {
                    failMessage += `<li>${data.FailureReasons[i]}</li>`;
                }
                failMessage += '</ol';
                toastr.error(failMessage);
            }
            if (data.SuccessfulCount) {
                toastr.success(`Successfully updated ${data.SuccessfulCount} tickets.`);
            }
        }).fail((displayMessage) => {
            toastr.error(displayMessage);
        }).always(() => {
            this.search();
        });
    }
}
