<div class="card">
  <div class="card-header">
    Customer Campaign
  </div>
  <div class="card-body">
    <app-components-shared-customer-campaign 
      [customer]="customerJS"
      [restrictions]="customerCampaignRestrictions"
      [reviewSource]="reviewSource">
    </app-components-shared-customer-campaign>
  </div>
</div>