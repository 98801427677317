import { Component, Input, OnInit } from '@angular/core';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';
import { KeywordConfig } from '../../../../shared/models/keywords/keywords';
import { KeywordConfigService } from '../../../../services/keyword-config.service';

@Component({
  selector: 'app-components-shared-campaign-summary',
  templateUrl: './campaign-summary.component.html',
  styleUrls: ['../customer-campaign/customer-campaign.component.scss']
})
export class CampaignSummaryComponent implements OnInit {

  @Input() customer: Boo.Objects.Customer;
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];
  @Input() haloKeywords: Boo.Objects.WebsiteKeywordTracking[];
  @Input() restrictions: Boo.Objects.Enums.CustomerCampaignRestrictions[];

  keywordConfig: KeywordConfig;
  keywordConfigs: KeywordConfig[];
  customerCampaignRestrictions_ReadOnly = Boo.Objects.Enums.CustomerCampaignRestrictions.ReadOnly;
  customerCampaignRestrictions_DisableCustomerCampaignType = Boo.Objects.Enums.CustomerCampaignRestrictions.DisableCustomerCampaignType;

  constructor(
    private customerCampaignService: CustomerCampaignService,
    private keywordConfigService: KeywordConfigService ) { }

  ngOnInit(): void {
    this.keywordConfigService.getAll(this.customer.CustomerId).subscribe(keywordConfigs => {
      this.keywordConfigs = keywordConfigs;
      this.getKeywordConfig();
    });
  }

  getKeywordConfig(): void {
    this.keywordConfig = this.keywordConfigs.find(x => x.IsCampaignNational === this.customer.IsCampaignNational);
  }

  get pageCount(): number {
    return this.websiteUrls ? this.websiteUrls.filter(url => this.isActiveStatus(url)).length : 0;
  }

  get keywordCount(): number {
    return this.websiteUrls ? this.websiteUrls.filter(url => this.isActiveStatus(url)).flatMap(x => x.Keywords).filter(x => x.IsActive).length : 0;
  }

  get haloCount(): number {
    return this.haloKeywords ? this.haloKeywords.filter(x => x.IsActive && !x.WasFirstPage).length : 0;
  }

  private isActiveStatus(websiteUrl: Boo.Objects.WebsiteUrl): boolean {
    return this.customerCampaignService.isActiveStatus(websiteUrl);
  }
}
