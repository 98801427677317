<div>
		<div class="row">
			<div class="col-md-6">
				<ul class="list-group">
					<li class="list-group-item">
						<span>
							<strong>Website Url</strong>
							<a [externalLink]="{url : currentAction().TaskDetails.WebsiteUrl.Url, text: currentAction().TaskDetails.WebsiteUrl.Url}"
								class="float-end"
								rel="noreferrer"></a>
						</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label class="control-label">Uploaded Files</label>
					<table *ngIf="taskFiles.length" class="table table-striped table-bordered">
						<thead>
							<tr>
								<th>Name</th>
								<th>Created Date</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<ng-container *ngFor="let file of taskFiles">
								<tr>
									<td>
										<a [externalLink]="{ url: file.S3Url, text: file.Name}"></a>
									</td>
									<td>{{ file.InsertedDate | dateText }}</td>
									<td class="text-center">
										<button (click)="deleteFile(file)" class="btn btn-danger">
											<i class="fa fa-times"></i>
											Delete
										</button>
									</td>
								</tr>
							</ng-container>
						</tbody>
					</table>
					<div *ngIf="!taskFiles.length" class="alert alert-info" style="padding:8px;margin-bottom: 5px;">No results to
						display</div>
				</div>
				<div class="form-group">
					<ng-container *ngIf="taskFiles.length < 1">
						<button (click)="uploadFile()" class="btn btn-primary">
							<i class="fa fa-upload"></i>
							Upload New File
						</button>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="form-group">
			<label class="control-label">Resolve Action Work Block - Ticket History</label>
			<table class="table table-bordered table-hover">
				<thead>
					<tr>
						<th><span>Ticket Type</span></th>
						<th><span>Last Action Date</span></th>
						<th><span>Assigned User</span></th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngFor="let ticket of tickets">
						<tr>
							<td>
								<button (click)="viewTicket(ticket.TicketId);" class="btn btn-primary">
									<i class="fas fa-external-link-square-alt"></i>
									<span>{{ticket.TicketType.Name}}</span>
								</button>
							</td>
							<td>{{ ticket.ScheduledDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
							<td>{{ (ticket.AssignedUser) ? ticket.AssignedUser.FullName : '' }}</td>
						</tr>
					</ng-container>
					<ng-container *ngIf="!tickets.length">
						<tr>
							<td class="alert alert-info" colspan="3">No results to display</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>
		<br />
		<div class="form-group">
			<label class="control-label">External Access</label>
			<div class="text-warning float-end">Notice: All password retrieval requests are recorded for security purposes.
			</div>
			<app-components-managecustomer-customeraccountgroup [options]="{
					isEnabled: customerAccountGroupIsEnabled(),
					customerId: customerId,
					customerAccountTypes: customerAccountTypes
			}"></app-components-managecustomer-customeraccountgroup>
		</div>
		<div class="form-group">
			<label class="control-label">Note to CSR</label>
			<p class="float-end help-block">4000 character maximum</p>
			<textarea [(ngModel)]="noteToCsr" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
				name="customActionNote" class="form-control" rows="15" style="height: 150px;"
				placeholder="Note to CSR"></textarea>
		</div>
	</div>
	<div [loadingOverlay]="isLoading">
		<app-floating-panels>
			<app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
				<app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="currentAction().TaskDetails.TaskNotes">
				</app-components-specialist-actioncomponents-comments>
			</app-floating-panel>
			<app-floating-panel [displayConfig]="{ title: 'Locations', icon: 'fa-map-marker', size: 'floating-panel-lg' }">
        <app-components-shared-view-local-profiles *floatingPanelContent [customer]="customer"></app-components-shared-view-local-profiles>
			</app-floating-panel>
		</app-floating-panels>
</div>
