import { Component, TemplateRef, ContentChild } from '@angular/core';
import { TabContentDirective } from '../../../directives/tab-content.directive';
import { TabBase } from '../tab-base';
/**
 * @Component TabComponent
 * @Remarks This component is responsible for rendering a tab.
 */
@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  providers: [{provide: TabBase, useExisting: TabComponent}]
})
export class TabComponent extends TabBase {

  @ContentChild(TabContentDirective, {read: TemplateRef, static: true}) tabContent: TemplateRef<any>;

  constructor() { 
    super();
  }
}
