<div class="card">
  <div class="card-header">
    <span>{{title}}</span>
    <button (click)="close()" class="btn-close" title="Close"></button>
  </div>
  <div [loadingOverlay]="isLoading()" class="card-body" style="min-height: 200px">
    <div class="row">
      <div class="col-md-3">
        <ng-container *ngIf="linkLocation()">
          <div class="form-group">
            <label class="control-label">Link Location ID</label>
            <p class="form-control-static">{{linkLocation().LinkLocationId()}}</p>
          </div>
          <div class="form-group">
            <label class="control-label">Url</label>
            <p class="form-control-static">{{linkLocation().Url()}}</p>
          </div>
        </ng-container>
      </div>
      <div class="col-md-9">
        <app-table [items]="amazonDeploymentStatusHistoryItems()">
          <ng-template #tableHeader>
            <tr>
              <th sort desc field="InsertedDate">Status Date</th>
              <th sort field="Status">Status</th>
              <th sort field="Desctiption">Description</th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-deployment>
            <tr>
              <td>{{deployment.InsertedDate | dateText: 'MM/DD/YYYY h:mm:ss a' }}</td>
              <td>{{deployment.AmazonDeploymentStatus.Name}}</td>
              <td>{{deployment.AmazonDeploymentStatus.Description}}</td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>