import { Component, Input } from '@angular/core';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-halo-keyword',
  templateUrl: './halo-keyword.component.html',
  styleUrls: ['../customer-campaign/customer-campaign.component.scss']
})
export class HaloKeywordComponent {
  @Input() customer: Boo.Objects.Customer;
  @Input() haloKeyword: Boo.Objects.WebsiteKeywordTracking;
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];
  @Input() haloKeywords: Boo.Objects.WebsiteKeywordTracking[];
  @Input() archivedKeywords: Boo.Objects.ArchivedKeyword[];
  @Input() restrictions: Boo.Objects.Enums.CustomerCampaignRestrictions[];

  customerCampaignRestrictions_ReadOnly = Boo.Objects.Enums.CustomerCampaignRestrictions.ReadOnly

  constructor(private customerCampaignService: CustomerCampaignService) { }

  isActiveStatus(websiteUrl: Boo.Objects.WebsiteUrl): boolean {
    return this.customerCampaignService.isActiveStatus(websiteUrl);
  }

  archiveHaloKeyword(haloKeyword: Boo.Objects.WebsiteKeywordTracking): void {
    this.customerCampaignService.archiveHaloKeyword(this.archivedKeywords, this.haloKeywords, haloKeyword);
  }

  moveHaloKeywordToWebsiteUrl(haloKeyword: Boo.Objects.WebsiteKeywordTracking, websiteUrl: Boo.Objects.WebsiteUrl): void {
    this.customerCampaignService.moveHaloKeywordToWebsiteUrl(haloKeyword, websiteUrl);
  }

  get validationMessages(): string[] {
    let messages: string[] = [];

    if (this.haloKeyword.IsActive) {
      var keywordPhrases = this.websiteUrls.filter(x => x.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active)
        .flatMap(page => page.Keywords)
        .filter(x => x.IsActive)
        .map(x => x.KeywordPhrase)
        .concat(this.haloKeywords.filter(x => x.IsActive && x != this.haloKeyword).map(x => x.KeywordPhrase));

      if (keywordPhrases.includes(this.haloKeyword.KeywordPhrase)) {
        messages.push('Duplicate');
      }
    }

    return messages;
  }
}
