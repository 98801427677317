<div>
	<ul class="list-unstyled">
		<ng-container *ngFor="let todo of todos()">
			<ng-container *ngIf="!todo.TodoGroupId()">
				<li [dataValidation]="{ placement: 'top', value: todo.Status }" class="list-group-item">
					<compose Selector="app-components-todolegacy" [ActivateOptions]="{
                            todo: todo,
                            userId: userId,
                            isCustomerTodo: isCustomerTodo()
                         }"></compose>
				</li>
			</ng-container>
		</ng-container>
	</ul>
	<div>
		<ng-container *ngFor="let group of todoGroups()">
			<ul class="list-unstyled">
				<li class="list-group-item bg-info">
					<strong>
						<span>{{group.Name()}}</span>
					</strong>
				</li>
				<ng-container *ngFor="let todo of todos()">
					<ng-container *ngIf="group.TodoGroupId() === todo.TodoGroupId()">
						<li [dataValidation]="{ placement: 'top', value: todo.Status }" class="list-group-item">
							<compose Selector="app-components-todolegacy" [ActivateOptions]="{
                            todo: todo,
                            userId: userId
                         }"></compose>
						</li>
					</ng-container>
				</ng-container>
			</ul>
		</ng-container>
	</div>
</div>
