import { Component } from '@angular/core';
import ITodoListObservable = app.tsmodels.interfaces.ITodoListObservable;
import ITodoObservable = app.tsmodels.interfaces.ITodoObservable;
import CustomerTodoListTypes = Boo.Objects.Todo.Enums.CustomerTodoListTypes;
import TodoStatus = Boo.Objects.Todo.Enums.TodoStatus;
import IValidationPublicApi = app.interfaces.IValidationPublicApi;
import IValidate = app.interfaces.IValidate;
import { CustomerTodoListService } from '../../../services/customer-todo-list.service';
import ICustomerTodoListObservable = app.tsmodels.interfaces.ICustomerTodoListObservable;
import TodoFactory from '../../../factories/TodoFactory';
import { TodoListLegacyComponent } from '../TodoListLegacy/TodoListLegacy.component';
import TodoListFactory from '../../../factories/TodoListFactory';
import CustomerTodoListFactory from '../../../factories/CustomerTodoListFactory';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-todo-customertodolist',
  templateUrl: './CustomerTodoList.component.html'
})
export class CustomerTodoListComponent implements IActivatable {
  public title: string = 'Customers Todo List';
  public loading: KnockoutObservable<boolean> = ko.observable(true);
  public userId: number;
  public customer: Boo.Objects.Customer;
  public todoLists: KnockoutObservableArray<ITodoListObservable> = ko.observableArray([]);
  public newTodo: KnockoutObservable<string> = ko.observable('');
  public validationApi: IValidationPublicApi;
  public todoPanelId: string;
  public unresolvedTodos: KnockoutComputed<number>;
  public unsavedChanges: KnockoutObservable<boolean> = ko.observable(false).extend({notify: 'always'});
  public validate: KnockoutObservable<any>;
  public isUserPartner: boolean = false;

  protected validation: { validation: IValidate, uid: string }[] = [];

  private todoList: ITodoListObservable;
  private customerTodoList: ICustomerTodoListObservable;
  private todoListFactory: TodoListFactory = new TodoListFactory();
  private customerTodoListFactory: CustomerTodoListFactory = new CustomerTodoListFactory();
  private todoFactory: TodoFactory = new TodoFactory();
  private todoListModel: TodoListLegacyComponent;

  constructor (private customerTodoListService: CustomerTodoListService) { }

  public activate(params: any): JQueryPromise<any> {
    this.customer = params.customer;

    this.validationApi = {
      add: (x: app.interfaces.IValidate, y: string): void => this.addValidation(x, y),
      remove: (x: string): void => this.removeValidation(x)
    };

    this.todoPanelId = _.uniqueId('todoPanel_');

    this.newTodo.extend({
      required: true,
      maxLength: 400
    });

    this.validate = ko.validatedObservable([this.newTodo]);

    const cu = this.customer.Users.find(x => x.UserId === this.userId);
    this.isUserPartner = cu ? cu.IsCustomerUserPartner : false;

    return Utils.wrapDfd(this.customerTodoListService.get(this.customer.CustomerId, CustomerTodoListTypes.General))
      .then((todoList: Boo.Objects.Todo.CustomerTodoList) => {
        if (!todoList) {
          this.setTodoList(this.customerTodoListFactory.create(this.customer.CustomerId, CustomerTodoListTypes.General));
        } else {
          this.setTodoList(ko.mapping.fromJS(todoList));
        }
      })
      .fail((err) => toastr.error(err))
      .always(() => this.loading(false));
  }

  // tslint:disable-next-line
  public todoListActivated(model: TodoListLegacyComponent): void {
    this.todoListModel = model;
  }

  public save(): void {
    this.loading(true);

    // lock down the notes
    this.todoList.Todos().forEach(x => x.TodoNotes().forEach(y => y.IsEditable(false)));

    this.customerTodoList.TodoList = this.todoList;

    Utils.wrapDfd(this.customerTodoListService.save(ko.mapping.toJS(this.customerTodoList)))
      .then((customerTodoList) => {
        this.setTodoList(ko.mapping.fromJS(customerTodoList), false);
        this.unsavedChanges(false);
        toastr.success(`Todo list saved for customer ${this.customer.CustomerId}`);
      })
      .fail((err) => toastr.error(err))
      .always(() => this.loading(false));
  }

  public onEnter(event: any): boolean {
    if (event.keyCode === 13) { // Enter key
      this.addTodo();
      return false;
    }
    return true;
  }

  public add(): void {
    this.addTodo();
  }

  public addValidation(validation: app.interfaces.IValidate, uid: string): void {
    this.validation.push({ validation, uid });
  }

  public removeValidation(uid: string): void {
    this.validation = this.validation.filter(x => x.uid !== uid);
  }

  public canActivate(user: Boo.Objects.User): boolean {
    this.userId = user.UserId;
    return true;
  }

  private setTodoList(customerTodoList: ICustomerTodoListObservable, initialLoad: boolean = true): void {
    this.customerTodoList = customerTodoList;

    if (!initialLoad) {
      this.todoListModel.todos([]);
      this.todoList.Todos([]);
      this.pushToParentAndChildTodoList(customerTodoList.TodoList.Todos);
      this.initChangeDetection();
      return;
    }
    this.todoList = this.customerTodoList.TodoList && this.customerTodoList.TodoListId() ? this.customerTodoList.TodoList : this.todoListFactory.create();

    this.todoLists = ko.observableArray([this.todoList]); // passed to grandchild components

    this.unresolvedTodos = ko.computed(() => {
      if (!this.todoList) {
        return 0;
      }
      return this.todoList.Todos().filter(x => x.Status() === TodoStatus.New).length;
    });

    this.initChangeDetection();
  }

  private addTodo(): void {
    if (!this.validate.isValid()) {
      this.validate.errors.showAllMessages();
      return;
    }

    const todo: ITodoObservable = this.todoFactory.createLegacy(this.todoList.TodoListId(), this.userId, this.newTodo());
    todo.Content.extend({ maxLength: 400 });
    todo.InsertedDate(new Date());

    this.pushToParentAndChildTodoList(ko.observableArray([todo]));
    this.resetNewTodo();
  }

  private initChangeDetection(): void {
    // subscribes to the observables we want to keep track off
    this.todoList.Todos.subscribe(x => this.unsavedChanges(true));

    this.todoList.Todos().forEach(todo => {
      todo.Status.subscribe(x => this.unsavedChanges(true));
      todo.TodoNotes.subscribe(x => this.unsavedChanges(true));
    });
  }

  private pushToParentAndChildTodoList(todos: KnockoutObservableArray<ITodoObservable>): void {
    // This is needed to keep the view in sync //

    this.todoListModel.todos.push(...todos());  // push to child component
    this.todoList.Todos.push(...todos());       // push to parent component
  }

  private resetNewTodo(): void {
    this.newTodo('');
    this.validate.errors.showAllMessages(false);
  }
}
