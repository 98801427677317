<div class="row">
  <ng-container *ngFor="let config of displayConfigs; first as isFirst">
    <div [ngClass]="isFirst ? firstClass : criteriaClass">
      <app-components-qa-criteria-button-group *ngIf="!config.useSelect" [displayConfig]="config"
        [blockTitle]="true" #criteriaControl>
      </app-components-qa-criteria-button-group>
      <app-components-qa-criteria-select *ngIf="config.useSelect" [displayConfig]="config"
        #criteriaControl>
      </app-components-qa-criteria-select>
    </div>
  </ng-container>
</div>
<br>
<h4 class="text-center">Grade Comment</h4>
<div class="row">
  <div class="col-md-12">
    <textarea class="form-control" [(ngModel)]="gradeComment"></textarea>
  </div>
</div>