<div class="card">
	<div class="card-header">
		<span>{{title}}</span>
		<button (click)="close()" class="btn-close" title="Close"></button>
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body" style="min-height: 300px;">
		<div class="row">
			<div class="col-md-12">
				<ng-container *ngIf="partnerPricingToolDetail()">
					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<ng-container *ngIf="partner()">
									<label class="control-label">Partner</label>
									<input [ngModel]="partner().Name" [ngModelOptions]="{ standalone: true }" class="form-control"
										type="text" readonly="readonly" />
								</ng-container>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label class="control-label">Language</label>
								<input [ngModel]="partnerPricingToolDetail().Language.Name()" [ngModelOptions]="{ standalone: true }"
									class="form-control" type="text" readonly="readonly" />
							</div>
						</div>
					</div>
					<hr />
					<ul class="nav nav-tabs">
						<li class="nav-item">
							<a href="#partnerpricingtool-general" class="active nav-link" data-toggle="tab">
								<i class="fa fa-cog"></i>
								General
								<span style="margin-left:15px;" class="badge bg-secondary"></span>
							</a>
						</li>
						<li class="nav-item">
							<a href="#partnerpricingtool-priceLevels" class="nav-link" data-toggle="tab">
								<i class="fas fa-dollar-sign"></i>
								Price Levels
								<span style="margin-left:15px;" class="badge bg-secondary"></span>
							</a>
						</li>
						<li class="nav-item">
							<a href="#partnerpricingtool-features" class="nav-link" data-toggle="tab">
								<i class="fa fa-list"></i>
								Features
								<span style="margin-left:15px;" class="badge bg-secondary"></span>
							</a>
						</li>
						<li class="nav-item">
							<a href="#partnerpricingtool-seoMinuteFeatures" class="nav-link" data-toggle="tab">
								<i class="fa fa-clock"></i>
								SEO Minute Features
								<span style="margin-left:15px;" class="badge bg-secondary"></span>
							</a>
						</li>
					</ul>
					<div class="tab-content">
						<div class="tab-pane active" id="partnerpricingtool-general">
							<div class="row">
								<div class="col-md-3">
									<div class="card">
										<div class="card-header">
											Logo
										</div>
										<div class="card-body">
											<div class="card card-body bg-light">
												<ng-container
													*ngIf="(partnerPricingToolDetail().LogoS3Url() && partnerPricingToolDetail().LogoS3Url().length > 0)">
													<p class="text-center">
														<img [attr.src]="partnerPricingToolDetail().LogoS3Url()" class="img-thumbnail"
															src="//placehold.it/250x100" />
													</p>
												</ng-container>
												<p class="d-grid gap-2">
													<button (click)="uploadLogo()" class="btn btn-primary">
														<i class="fa fa-upload"></i>
														Upload New Logo
													</button>
												</p>
												<ng-container
													*ngIf="(partnerPricingToolDetail().LogoS3Url() && partnerPricingToolDetail().LogoS3Url().length > 0)">
													<p class="d-grid gap-2">
														<button (click)="removeLogo()" class="btn btn-danger">
															<i class="fa fa-times"></i>
															Remove Logo
														</button>
													</p>
												</ng-container>
											</div>
										</div>
									</div>
									<div class="card">
										<div class="card-header">
											Proposal Document
										</div>
										<div class="card-body">
											<div
												[dataValidation]="{ value: partnerPricingToolDetail().ProposalDocumentS3Url, placement: 'top' }"
												class="card card-body bg-light">
												<p class="d-grid gap-2">
													<a class="btn btn-primary"
														href="https://s3staticfiles.s3.amazonaws.com/seopresencedocs/Custom_SEO_Proposal.docx">
														<i class="fa fa-download"></i>
														Download Template
													</a>
												</p>
												<p class="d-grid gap-2">
													<button (click)="uploadProposal()" class="btn btn-primary">
														<i class="fa fa-upload"></i>
														Upload New
													</button>
												</p>
												<ng-container
													*ngIf="(partnerPricingToolDetail().ProposalDocumentS3Url() && partnerPricingToolDetail().ProposalDocumentS3Url().length > 0)">
													<p>
														<a [attr.href]="partnerPricingToolDetail().ProposalDocumentS3Url()"
															class="btn btn-info btn-block" href="javascript:void(0)">
															<i class="fas fa-external-link-alt"></i>
															Download Current
														</a>
													</p>
												</ng-container>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-9">
									<div class="row">
										<div class="col-md-12">
											<div class="card">
												<div class="card-header">
													Details
												</div>
												<div class="card-body">
													<div class="form-group">
														<label class="control-label">Show Info Navigation</label>
														<span>
															<shared-components-customchoicelegacy
																[Settings]="{ value: partnerPricingToolDetail().ShowInfoNavigation }"></shared-components-customchoicelegacy>
														</span>
													</div>
													<div class="form-group">
														<label class="control-label">Show custom proposal path</label>
														<span>
															<shared-components-customchoicelegacy
																[Settings]="{ value: partnerPricingToolDetail().ShowCustomProposal }"></shared-components-customchoicelegacy>
														</span>
													</div>
													<div [slideVisible]="partnerPricingToolDetail().ShowCustomProposal()" class="form-group">
														<label class="control-label">Custom Proposal Type</label>
														<select [ngModel]="partnerPricingToolDetail().PartnerPricingToolCustomProposalTypeId()"
															(ngModelChange)="partnerPricingToolDetail().PartnerPricingToolCustomProposalTypeId($event)"
															[ngModelOptions]="{ standalone: true }" class="form-select">
															<option *ngFor="let option of partnerPricingToolCustomProposalTypes()"
																[ngValue]="option.PartnerPricingToolCustomProposalTypeId">{{ option.Name }}</option>
														</select>
													</div>
													<div [slideVisible]="emailCustomProposalTypeIsSelected()" class="form-group">
														<label class="control-label">Proposal Email Address</label>
														<input
															[dataValidation]="{ placement:'top', value: partnerPricingToolDetail().ProposalEmailAddress }"
															[ngModel]="partnerPricingToolDetail().ProposalEmailAddress()"
															(ngModelChange)="partnerPricingToolDetail().ProposalEmailAddress($event)"
															[ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text"
															class="form-control" />
													</div>
													<div [slideVisible]="textInstructionsCustomProposalTypeIsSelected()" class="form-group">
														<label class="control-label">Custom Message</label>
														<textarea
															[dataValidation]="{ placement: 'top', value: partnerPricingToolDetail().CustomMessage }"
															[ngModel]="partnerPricingToolDetail().CustomMessage()"
															(ngModelChange)="partnerPricingToolDetail().CustomMessage($event)"
															[ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"></textarea>
													</div>
													<div [slideVisible]="instaQuoteCustomProposalTypeIsSelected()" class="form-group">
														<label class="control-label">Help Contact Number</label>
														<input
															[dataValidation]="{ placement:'top', value: partnerPricingToolDetail().SalesSupportContactNumber }"
															[ngModel]="partnerPricingToolDetail().SalesSupportContactNumber()"
															(ngModelChange)="partnerPricingToolDetail().SalesSupportContactNumber($event)"
															[ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"
															maxlength="16" />
													</div>
													<div class="form-group">
														<label class="control-label">Allow all price levels</label>
														<span>
															<shared-components-customchoicelegacy
																[Settings]="{ value: partnerPricingToolDetail().AllowAllPriceLevels }"></shared-components-customchoicelegacy>
														</span>
														Note: If No is selected, the default is to allow one price level below what is recommended.
													</div>
													<div class="form-group">
														<label class="control-label">Show prices</label>
														<span>
															<shared-components-customchoicelegacy
																[Settings]="{ value: partnerPricingToolDetail().ShowPrices }"></shared-components-customchoicelegacy>
														</span>
													</div>
													<div class="form-group">
														<button (click)="savePartnerPricingToolDetail(partnerPricingToolDetail())"
															class="btn btn-primary">
															<i class="fa fa-save"></i>
															Save
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-5">
											<div class="card">
												<div class="card-header">
													City Information
												</div>
												<div class="card-body">
													<div class="form-group">
														<label for="marketingRegionSelection" class="control-label">Market(s)</label>
														<select [ngModel]="userSelectedPartnerPricingToolMarketIds()"
															(ngModelChange)="userSelectedPartnerPricingToolMarketIds($event)"
															[ngModelOptions]="{ standalone: true }" select2 multiple="multiple" class="form-control"
															name="marketingRegionSelection">
															<option *ngFor="let option of partnerPricingToolMarkets()"
																[ngValue]="option.PartnerPricingToolMarketId">{{ option.Name }}</option>
														</select>
													</div>
													<div class="form-group">
														<button (click)="savePartnerMarkets()" class="btn btn-primary">
															<i class="fa fa-save"></i>
															Save
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="partnerpricingtool-priceLevels">
							<div *ngIf="!isSuggesting" class="form-group">
								<button (click)="addPriceLevel()" class="btn btn-primary">
									<i class="fa fa-plus"></i>
									Add Price Level
								</button>
								<button (click)="suggestPricingLevels()" class="btn btn-info">
									Suggest Pricing Levels
								</button>
							</div>
							<div *ngIf="isSuggesting" class="form-group">
								<ng-select *ngIf="subscriptionCategories.length > 1" [items]="subscriptionCategories"
									(change)="selectedCategory.next($event)" class="form-select" placeholder="Select Category" [clearable]="false">
								</ng-select>
								<button (click)="saveSuggestions()" class="btn btn-success">
									Save Suggestions
								</button>
								<button (click)="cancelSuggesting()" class="btn btn-danger">
									Cancel Suggestions
								</button>
							</div>
							<div [hidden]="!(_.isArray(priceLevels) && priceLevels.length > 0)">
								<table class="table table-striped table-bordered table-sm">
									<thead>
										<tr>
											<td>Name</td>
											<td>Price</td>
											<td>SEO Minutes</td>
											<td *ngIf="!isSuggesting">Actions</td>
											<td *ngIf="isSuggesting">Suggestions</td>
										</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let $data of priceLevels">
											<tr>
												<td>{{$data.Name}}</td>
												<td>
													<span>{{ $data.Price | currency }}</span>
													<ng-container *ngIf="$data.SuggestedPrice && $data.SuggestedPrice !== $data.Price">
														<em>({{ $data.SuggestedPrice | currency }})</em>
													</ng-container>
												</td>
												<td>
													<span>{{ $data.SeoMinutes }}</span>
													<ng-container *ngIf="$data.SuggestedSeoMinutes && $data.SuggestedSeoMinutes !== $data.SeoMinutes">
														<em>({{ $data.SuggestedSeoMinutes }})</em>
													</ng-container>
												</td>
												<td>
													<ng-container *ngIf="!isSuggesting || !$data.SuggestionStatus">
														<button (click)="editPriceLevel($data)" [disabled]="isSuggesting" class="btn btn-primary">
															<i class="fa fa-edit"></i>
															Edit
														</button>
														<button (click)="removePriceLevel($data)" [disabled]="isSuggesting" class="btn btn-danger">
															<i class="fa fa-times"></i>
															Remove
														</button>
														<button (click)="previewPriceLevel($data.PartnerPricingToolPriceLevelId)" [disabled]="isSuggesting"
															class="btn btn-info">
															<i class="fa fa-magnifying-glass"></i>
															Preview
														</button>
													</ng-container>
													<ng-container *ngIf="isSuggesting && $data.SuggestionStatus" [ngSwitch]="$data.SuggestionStatus">
														<ng-container *ngSwitchCase="suggestionStatus.HasSuggestion">
															<button (click)="setSuggestionStatus($data, suggestionStatus.Accepted)" class="btn btn-success">
																<i class="fa fa-check"></i>
																Accept Suggestion
															</button>
															<button (click)="setSuggestionStatus($data, suggestionStatus.Rejected)" class="btn btn-danger">
																<i class="fa fa-times"></i>
																Reject Suggestion
															</button>
														</ng-container>
														<button *ngSwitchCase="suggestionStatus.AdditionSuggested" (click)="setSuggestionStatus($data, suggestionStatus.Added)" class="btn btn-success">Add Price Level</button>
														<button *ngSwitchCase="suggestionStatus.RemovalRecommended" (click)="setSuggestionStatus($data, suggestionStatus.Removed)" class="btn btn-danger">Remove Price Level</button>
														<button *ngSwitchDefault type="button" class="btn btn-secondary" disabled>{{ $data.SuggestionStatus }}</button>
													</ng-container>
												</td>
											</tr>
										</ng-container>
									</tbody>
								</table>
							</div>
						</div>
						<div class="tab-pane" id="partnerpricingtool-features">
							<div class="card card-body bg-light">
								<div class="form-group">
									<label class="control-label">Selected Price Level</label>
									<select [ngModel]="selectedPriceLevel()" (ngModelChange)="selectedPriceLevel($event)"
										[ngModelOptions]="{ standalone: true }" [disabled]="hasFeaturesChanged()" class="form-select">
										<option *ngFor="let option of priceLevels" [ngValue]="option">{{ option.Name }}</option>
									</select>
								</div>
								<div [hidden]="!hasFeaturesChanged()" class="form-group">
									<button (click)="savePriceLevelFeatures()" class="btn btn-primary">
										<i class="fa fa-save"></i>
										Save Changes
									</button>
									<button (click)="cancelFeatureChanges()" class="btn btn-outline-secondary">Cancel Changes</button>
								</div>
							</div>
							<div class="row">
								<ng-container *ngIf="selectedPriceLevel()">
									<div [hidden]="!(selectedPriceLevel.PartnerPricingToolPriceLevelId > 0)"></div>
									<div class="col-md-6">
										<div class="card">
											<div class="card-header">
												Included Features
												<ng-container *ngIf="_.isArray(includedFeatures()) && includedFeatures().length > 0">
													<button (click)="clearFeatures()" class="btn btn-danger btn-sm float-end">
														<i class="fa fa-times"></i>
														Clear
													</button>
												</ng-container>
											</div>
											<div class="card-body">
												<ng-container *ngIf="_.isArray(features()) && features().length > 0">
													<compose Selector="app-components-manager-partnerpricingtoolfeaturelist"
														[Model]="{ featureList: includedFeatures, afterMoveCallback: afterPriceLevelFeatureChange.bind(this) }">
													</compose>
												</ng-container>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="card">
											<div class="card-header">
												Available Features
												<ng-container *ngIf="_.isArray(availableFeatures()) && availableFeatures().length > 0">
													<button (click)="includeAllFeatures()" class="btn btn-primary btn-sm float-end">
														<i class="fa fa-angle-double-left"></i>
														Include All
													</button>
												</ng-container>
											</div>
											<div class="card-body">
												<ng-container *ngIf="_.isArray(features()) && features().length > 0">
													<compose Selector="app-components-manager-partnerpricingtoolfeaturelist"
														[Model]="{ featureList: availableFeatures, afterMoveCallback: afterPriceLevelFeatureChange.bind(this) }">
													</compose>
												</ng-container>
											</div>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
						<div class="tab-pane" id="partnerpricingtool-seoMinuteFeatures">
							<app-components-manager-seominutefeatures [priceLevels]="priceLevels" [partnerId]="this.partner().PartnerId" [languageId]="this.selectedLanguageId()"></app-components-manager-seominutefeatures>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="card-footer text-end">
		<button (click)="close()" class="btn btn-link">Close</button>
	</div>
</div>