<div class="row spaced-content">
  <div class="col-md-10">
    <ng-select [items]="stockComments" bindLabel="Label" [(ngModel)]="selectedComment"
      [clearable]="false" [searchable]="false">
    </ng-select>
  </div>
  <div class="col-md-1">
    <button class="btn btn-primary" (click)="insert()" [disabled]="stockComments.length === 0">
      <i class="fa fa-plus button-label"></i>
    </button>
  </div>
</div>