import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[focusOnCreate]'
})
export class FocusOnCreateDirective implements AfterViewInit {
  @Input('focusOnCreate') public FocusOnCreate: KnockoutObservable<boolean>;

  private element: HTMLElement;
  constructor(elementRef: ElementRef) {
    this.element = elementRef.nativeElement;
  }

  public ngAfterViewInit(): void {
    this.element.focus();
  }
}