<div class="input-group">
  <button class="btn btn-outline-secondary" (click)="d.toggle()" #t [disabled]="disabled()"><i class="fas fa-calendar-alt me-0"></i></button>
  <input type="text" [hidden]="true" readonly class="form-control" ngbDatepicker #d="ngbDatepicker" firstDayOfWeek="7"
    [ngModel]="ngbDate()" (ngModelChange)="updateDate($event)" [disabled]="disabled()" autoClose="outside" [footerTemplate]="timePicker" [positionTarget]="t"
    [minDate]="min()" [maxDate]="max()"/>
    <!-- NgBootstrap does not support showing the date + time in the above input, so we use this one for the display   -->
  <input type="text" readonly [ngModel]="displayString()" readonly class="form-control"/>
</div>

<ng-template #timePicker>
  <div class="mx-1">
    <ngb-timepicker [ngModel]="ngbTime()" (ngModelChange)="updateTime($event)" [disabled]="disabled()"></ngb-timepicker>
  </div>
</ng-template>
