<div *withPermissions="let allow of ['CanRetireBlogs', 'CanRepublishBlogs']" class="card">
	<div class="card-header">
		<span>{{title}}</span>
		<button (click)="close()" class="btn-close" title="Close"></button>
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body" style="min-height: 200px">
		<div class="row">
			<div class="col-md-4">
				<ng-container *ngIf="linkLocation()">
					<h4>
						<span class="badge bg-primary">
							Link Location ID:
							<span>{{linkLocation().LinkLocationId()}}</span>
						</span>
						<span class="badge bg-primary">
							Country:
							<span>{{country.Abbreviation}}</span>
						</span>
						<span class="badge bg-primary">
							Language:
							<span>{{language.Abbreviation.toUpperCase()}}</span>
						</span>
					</h4>
				</ng-container>
			</div>
			<div class="col-md-8 text-end">
				<div style="display: inline-block;">
					<ng-container *ngIf="allow().CanRetireBlogs() && blogCanBeRetired">
						<button (click)="retireBlogAndRepostPosts()" class="btn btn-danger" type="button" title="Retire blog and republish posts to other blogs">
							<i class="fa fa-times"></i>
							Retire & Republish
						</button>
						<button (click)="retireBlogWithoutRepublishing()" class="btn btn-danger" type="button" title="Retire blog and do not republish any posts">
							<i class="fa fa-times"></i>
							Retire & Don't Republish
						</button>
					</ng-container>
					<ng-container *ngIf="allow().CanRepublishBlogs() && blogCanBeRepublished">
						<button (click)="republishPosts()" class="btn btn-success">
							<i class="fas fa-external-link-square-alt"></i>
							Republish Posts
						</button>
						<button (click)="resetDNS()" class="btn btn-success">
							<i class="fas fa-external-link-square-alt"></i>
							Reset DNS
						</button>
					</ng-container>
					<button (click)="deployStaticSite()" class="btn btn-primary">
						<i class="fas fa-external-link-square-alt"></i>
						Deploy
					</button>
					<button (click)="viewStatusHistory()" class="btn btn-primary">
						<i class="fa fa-history"></i>
						History
					</button>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<ng-container *ngIf="linkLocation()">
					<div class="form-group">
						<label class="control-label">Url</label>
						<div class="input-group">
							<a [externalLink]="{ url: linkLocation().Url(), text:''}" class="btn btn-primary" target="_blank"></a>
							<input [dataValidation]="{ placement: 'top', value: linkLocation().Url }" [ngModel]="linkLocation().Url()" (ngModelChange)="linkLocation().Url($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" type="text" class="form-control"/>
							<span class="input-group-text">
								<span [innerHtml]="$any(this).indexStatus" [class.text-primary]="!blogDetails().IsDeindexed()" [class.text-danger]="blogDetails().IsDeindexed()"></span>
							</span>
						</div>
					</div>
					<div class="form-group">
						<label class="control-label">Vertical</label>
						<div [dataValidation]="{ placement: 'top', value: linkLocation().VerticalId }">
							<select select2 [ngModel]="linkLocation().VerticalId()" (ngModelChange)="linkLocation().VerticalId($event)" [ngModelOptions]="{ standalone: true }" [disabled]="hasPosts" class="form-control" data-placeholder="Choose...">
								<option *ngFor="let option of verticals" [ngValue]="option.VerticalId">{{ option.Name }}</option>
							</select>
						</div>
					</div>
					<div class="form-group">
						<label class="control-label">Link Location Is Active</label>
						<div>
							<shared-components-customchoicelegacy [Settings]="{ value: linkLocation().IsActive }"></shared-components-customchoicelegacy>
						</div>
					</div>
					<div class="form-group">
						<label class="checkbox-inline">
							<input [disabled]="true" [ngModel]="linkLocation().IsRetired()" (ngModelChange)="linkLocation().IsRetired($event)" [ngModelOptions]="{ standalone: true }" type="checkbox"/>
							Is Retired
						</label>
					</div>
					<div class="form-group">
						<label class="control-label">Notes</label>
						<textarea [ngModel]="blogDetails().Notes()" (ngModelChange)="blogDetails().Notes($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"></textarea>
					</div>
				</ng-container>
			</div>
			<div class="col-md-6">
				<ng-container *ngIf="blogDetails()">
					<div id="accordion" role="tablist" aria-multiselectable="true">
						<div class="card">
							<div class="card-header" role="tab" id="headingOne">
								<h4>
									<a role="button" data-toggle="collapse" data-target="#diagnostics" aria-expanded="true" aria-controls="collapseOne">Diagnostics</a>
								</h4>
							</div>
							<div id="diagnostics" data-parent="#accordion" class="card-collapse collapse" role="tabpanel">
								<div class="card-body">
									<compose Selector="app-components-specialist-blogdiagnostics"
										[ActivateOptions]="{blog: blogDetails()}"
										class="row"></compose>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab" id="headingOne">
								<h4>
									<a role="button" data-toggle="collapse" data-target="#deploymentInfo" aria-expanded="true" aria-controls="collapseOne">Deployment Information</a>
								</h4>
							</div>
							<div id="deploymentInfo" data-parent="#accordion" class="card-collapse collapse" role="tabpanel">
								<div class="card-body">
									<div class="form-group">
										<label class="control-label">Deployment Status</label>
										<input [(ngModel)]="awsDeployStatus" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" type="text" class="form-control" readonly="readonly"/>
									</div>
									<div class="form-group">
										<label class="control-label">Deployment Status Date</label>
										<input [value]="moment(blogDetails().AmazonDeploymentStatusDate()).format('L')" [disabled]="true" [readonly]="true" type="text" class="form-control" readonly="readonly"/>
									</div>
									<div class="form-group">
										<label class="control-label">First Blog Posted</label>
										<input [(ngModel)]="firstBlogPostDate" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" type="text" class="form-control" readonly="readonly"/>
									</div>
									<div class="form-group">
										<label class="control-label">Last Blog Posted</label>
										<input [(ngModel)]="lastBlogPostDate" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" type="text" class="form-control" readonly="readonly"/>
									</div>
									<div class="form-group">
										<label class="control-label">Number of Total Posts</label>
										<input [(ngModel)]="numberOfPosts" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" type="text" class="form-control" readonly="readonly"/>
									</div>
									<div [hidden]="!(linkLocation().IsRetired() === true)" class="form-group">
										<label class="control-label">Retirement Date</label>
										<input [value]="moment(linkLocation().RetirementDate()).format('L')" [disabled]="true" [readonly]="true" type="text" class="form-control" readonly="readonly"/>
									</div>
									<div class="form-group">
										<label class="control-label">Date Bought</label>
										<input [value]="moment(blogDetails().DateBought()).format('L')" [disabled]="true" [readonly]="true" type="text" class="form-control" readonly="readonly"/>
									</div>
									<div class="form-group">
										<label class="control-label">Renewal Date</label>
										<input [value]="moment(blogDetails().RenewalDate()).format('L')" [disabled]="true" [readonly]="true" type="text" class="form-control" readonly="readonly"/>
									</div>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" role="tab" id="headingTwo">
								<h4>
									<a role="button" data-toggle="collapse" data-target="#buildDetails" aria-expanded="true" aria-controls="collapseOne">Build Details</a>
								</h4>
							</div>
							<div id="buildDetails" data-parent="#accordion" class="card-collapse collapse">
								<div class="card-body">
									<div class="col-md-6">
										<ng-container *ngIf="blogDetails()">
											<div class="form-group">
												<label class="control-label">Blog Theme Id</label>
												<input [ngModel]="blogDetails().BlogThemeId()" (ngModelChange)="blogDetails().BlogThemeId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" type="text" class="form-control"/>
											</div>
											<div class="form-group">
												<label class="control-label">Pen Name</label>
												<input [dataValidation]="{ placement: 'top', value: blogDetails().PenName }" [ngModel]="blogDetails().PenName()" (ngModelChange)="blogDetails().PenName($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control" disabled=""/>
											</div>
										</ng-container>
									</div>
									<div class="col-md-6">
										<ng-container *ngIf="blogDetails()">
											<div class="form-group">
												<label class="control-label">Registrar</label>
												<input [ngModel]="blogDetails().Registrar()" (ngModelChange)="blogDetails().Registrar($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control" disabled=""/>
											</div>
											<div class="form-group">
												<label class="control-label">CloudFront Distribution ID</label>
												<p class="form-control-static">{{blogDetails().AmazonDistributionId()}}</p>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="card-footer text-end">
		<button (click)="close()" class="btn btn-link">Cancel</button>
		<button (click)="save()" class="btn btn-success">
			<i class="fa fa-save"></i>
			Save
		</button>
	</div>
</div>
