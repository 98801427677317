<div>
	<div class="row">
		<div class="col-md-12">
			<ul class="list-group">
				<li class="list-group-item">
					<strong>Tone and Company Persona</strong>
					<div>{{seoContentSurvey.Persona}}</div>
				</li>
				<li class="list-group-item">
					<strong>Site Goals and Tactics</strong>
					<div>{{seoContentSurvey.GoalsTactics}}</div>
				</li>
				<li class="list-group-item">
					<strong>Business Overview</strong>
					<div>{{seoContentSurvey.BusinessDescription}}</div>
				</li>
				<li class="list-group-item">
					<strong>Restrictions</strong>
					<div>{{seoContentSurvey.Limitations}}</div>
				</li>
				<li class="list-group-item">
					<strong>Audience</strong>
					<div>{{seoContentSurvey.Demographic}}</div>
				</li>
				<li class="list-group-item">
					<strong>Value Propositions and Unique Selling Points</strong>
					<div>{{seoContentSurvey.SellingPoints}}</div>
				</li>
				<li class="list-group-item" *ngIf="seoContentSurvey.BlogConsiderations">
					<strong>Onsite Blog Restrictions and Considerations</strong>
					<div>{{seoContentSurvey.BlogConsiderations}}</div>
				</li>
			</ul>
		</div>
	</div>
</div>
