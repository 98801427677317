<form class="form-horizontal">
	<compose Selector="app-components-optiontoggle" [ActivateOptions]="{
                            title: 'Tone',
                            left: 'Conversational',
                            right: 'Authoritative',
                            value: ToneObservable
                        }"></compose>
	<compose Selector="app-components-optiontoggle" [ActivateOptions]="{
                            title: 'Style',
                            left: 'Edgy',
                            right: 'Traditional',
                            value: StyleObservable
                        }"></compose>
	<compose Selector="app-components-optiontoggle" [ActivateOptions]="{
                            title: 'Complexity',
                            left: 'Economical',
                            right: 'Luxurious',
                            value: ComplexityObservable
                        }"></compose>
	<compose Selector="app-components-optiontoggle" [ActivateOptions]="{
                            title: 'Emotion',
                            left: 'Feed the Brain',
                            right: 'Touch the Heart',
                            value: EmotionObservable
                        }"></compose>
	<compose Selector="app-components-optiontoggle" [ActivateOptions]="{
                            title: 'Energy',
                            left: 'Dry',
                            right: 'Animated',
                            value: EnergyObservable
                        }"></compose>
</form>