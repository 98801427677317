import { Component, Input, OnInit } from '@angular/core';
import { TaskService } from '../../../services/task.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-components-managecustomer-pendingwork',
  templateUrl: './PendingWork.component.html'
})
export class PendingWorkComponent implements OnInit {
    @Input() customerId: number;
    isLoading: boolean = false;
    customerIdCrtl: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
    tasks: Boo.Objects.PendingTaskDetail[] = [];

    constructor(private taskService: TaskService) { }

    ngOnInit(): void {
        this.customerIdCrtl.setValue(this.customerId);
        this.customerIdCrtl.disable();
    }

    getTasksForCustomer(): void {
        if (this.customerIdCrtl.invalid) {
            return;
        }

        this.isLoading = true;

        this.taskService.getPendingWorkForCustomer(this.customerId)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe(
                (taskDetails: Boo.Objects.PendingTaskDetail[]) => this.tasks = taskDetails,
                err => toastr.error(err)
            );
    }
}