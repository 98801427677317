import { Component } from '@angular/core';
import System from 'framework/System';
import Check from 'framework/Check';

@Component({
	selector: 'app-components-admin-jobsbytype',
	templateUrl: './JobsByType.component.html'
})
export class JobsByTypeComponent implements IActivatable {
    public title = 'Job Type Summary';
    protected priorityViewApi: app.interfaces.IPriorityViewPublicApi;
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);

    public defaultJobTypeId: KnockoutObservable<Boo.Objects.Jobs.Enums.JobTypes | number> = ko.observable(0);

    public activate(params: any): JQueryPromise<any> {
        Check.isNotEmpty(params, 'Jobs cannot be viewed without any options');
        Check.isNotEmpty(params.jobTypeId, 'JobTypeId cannot be null');
        Check.isNotEmpty(params.priorityViewApi, 'PriorityViewApi cannot be null');
        this.priorityViewApi = params.priorityViewApi;
        this.defaultJobTypeId(params.jobTypeId);
        return System.emptyPromise();
    }

    public closeView(): void {
        this.priorityViewApi.reject();
    }
}
