<div class="card">
  <div class="card-header">
    <span>View Task Feedback</span>
  </div>
  <div [loadingOverlay]="isLoading" class="card-body">
    <div class="row">
      <div class="col-md-5 form-group">
        <label>Filter by Task ID</label>
        <input [(ngModel)]="taskIdFilter" (ngModelChange)="filterFeedback()"  type="text" class="form-control" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-table [items]="filteredFeedback">
          <ng-template #tableHeader>
            <tr>
              <th sort field="TaskId">Task ID</th>
              <th sort field="Task.TaskTypeEnum">Task Type</th>
              <th sort desc field="FeedbackTask.StatusDate">Review Date</th>
              <th sort field="Passed">Pass</th>
              <th>Feedback Details</th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-feedback>
            <tr>
            <tr>
              <td>{{feedback.TaskId}}</td>
              <td>{{displayTaskType(feedback.Task.TaskTypeEnum)}}</td>
              <td>{{feedback.FeedbackTask.StatusDate | dateText: 'M/DD/YYYY h:mm a'}}</td>
              <td>
                <span>{{feedback.Passed ? 'Passed' : 'Failed'}}</span>
                <ng-template #popupContent><span
                    [innerHTML]="feedback.Feedback ? feedback.Feedback : noFeedback"></span></ng-template>
                <span [ngbPopover]="popupContent" popoverTitle="Feedback" triggers="mouseenter:mouseleave">
                  <i class="fa fa-info-circle"></i>
                </span>
              </td>
              <td>
                <div *ngIf="feedback.TaskHistoryInfo">
                  <button type="button" class="btn btn-primary" (click)="show(feedback.TaskHistoryInfo.TaskHistoryId, feedback.Task.TaskTypeEnum)">
                    View Details
                    <span class="caret"></span>
                  </button>
                </div>
                <span *ngIf="!feedback.TaskHistoryInfo">
                  <button type="button" class="btn btn-secondary" disabled>Not Available</button>
                </span>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>