import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerPricingToolMarketService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/partnerpricingtoolmarkets`;

  constructor(private http: HttpClient) { }

  get(): Observable<Boo.Objects.SEOPresence.PartnerPricingToolMarket[]> {
    return this.http.get<Boo.Objects.SEOPresence.PartnerPricingToolMarket[]>(`${this.baseUrl}/`);
  }
}