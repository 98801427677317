<div style="min-height: 300px;">
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label>Start Date</label>
        <div>
          <input [dateTimePicker]="{ date: startDate }" type="text" class="form-control" placeholder="Start Date" />
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>End Date</label>
        <div>
          <input [dateTimePicker]="{ date: endDate }" type="text" class="form-control" placeholder="End Date" />
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Show all tasks</label>
        <app-components-helptext [key]="'CompletedTasks_ShowAllTasks'"></app-components-helptext>
        <div>
          <shared-components-customchoice [(value)]="showAllTasks"></shared-components-customchoice>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group float-end">
        <label>&nbsp;</label>
        <div>
          <button (click)="loadCompletedTasks()" class="btn btn-success">Search</button>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div [loadingOverlay]="isLoading" class="col-md-12">
      <app-table [items]="completedTasks" [pageSize]="20" [exportFilename]="'completed-tasks'">
        <ng-template #tableHeader>
          <tr>
            <th export sort field="ActionUrl">Action URL</th>
            <th export sort field="TaskId">Task ID</th>
            <th export sort field="TaskName">Task Name</th>
            <th export sort field="InsertedDate">Inserted Date</th>
            <th export sort field="CompletedDate">Completed Date</th>
            <th export sort field="MinutesSpent">Billed Minutes</th>
            <th export sort field="TargetKeyword">Target Keyword</th>
            <th export sort field="TargetArea">Target Area</th>
            <th export sort field="TargetUrl">Target URL</th>
            <th>Reporting</th>
          </tr>
        </ng-template>
        <ng-template #tableBody let-task>
          <tr>
            <td>
              <p class="linkEllipsis">
                <a [externalLink]="{ url: task.ActionUrl, text: task.ActionUrl }" rel="noreferrer"></a>
              </p>
            </td>
            <td>
              <button [hidden]="!(task.IsCustomAction)" (click)="showTaskDetails(task)" class="btn btn-sm btn-primary">
                {{task.TaskId}}
              </button>
              <span [hidden]="task.IsCustomAction">{{task.TaskId}}</span>
            </td>
            <td>{{task.TaskName}}</td>
            <td>{{task.InsertedDate | dateText: 'MM/DD/YYYY h:mm:ss a' }}</td>
            <td>{{task.CompletedDate | dateText: 'MM/DD/YYYY h:mm:ss a' }}</td>
            <td>{{task.MinutesSpent}}</td>
            <td>{{task.TargetKeyword}}</td>
            <td>{{task.TargetArea}}</td>
            <td>
              <a [externalLink]="{ url: task.TargetUrl, text: task.TargetUrl }" rel="noreferrer"></a>
            </td>
            <td>
              <button [hidden]="!(task.ReportingVisible !== null)" (click)="toggleReportingVisible(task)" [class.btn-primary]="task.ReportingVisible" [class.btn-success]="!task.ReportingVisible" class="btn btn-sm">
                <span [hidden]="!(task.ReportingVisible)">Hide</span>
                <span [hidden]="!(!task.ReportingVisible)">Show</span>
              </button>
              <span [hidden]="!(task.ReportingVisible === null)">N/A</span>
            </td>
          </tr>
        </ng-template>
      </app-table>
    </div>
  </div>
</div>