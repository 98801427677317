/* tslint:disable */

import { Component } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { ViewLocatorService } from '../../../services/view-locator.service';
import Utils from '../../../shared/utils';
import { ActionService } from '../../../services/action.service';
import { finalize } from 'rxjs/operators';
import { TaskService } from '../../../services/task.service';
import { ReloadService } from '../../../services/reload.service';

@Component({
  selector: 'app-components-specialist-assignedtasks',
  templateUrl: './AssignedTasks.component.html'
})
export class AssignedTasksComponent {
  constructor(
    private taskService: TaskService,
    private viewLocatorService: ViewLocatorService,
    private actionService: ActionService,
    private reloadService: ReloadService) { }

  public filter: any = ko.observable('');
  public mostRecentSelectedTaskId: any = ko.observable();
  public isLoading: any = ko.observable(false);
  public tasks: any = ko.observableArray([]);

  public filteredTasks: any = ko.computed(() => {
    if (this.filter().replace(/\s/g, '').length === 0) {
      return this.tasks();
    }
    return _.filter(this.tasks(),
      (action: any) => {
        return ($.trim(action.TaskId).toLowerCase().indexOf($.trim(this.filter()).toLowerCase()) >= 0 ||
          $.trim(action.CustomerId).toLowerCase().indexOf($.trim(this.filter()).toLowerCase()) >= 0 ||
          $.trim(action.TaskTypeName).toLowerCase().indexOf($.trim(this.filter()).toLowerCase()) >= 0 ||
          $.trim(action.CustomerUrl).toLowerCase().indexOf($.trim(this.filter()).toLowerCase()) >= 0);
      });
  });

  public activate(options: any): void {

    this.getLockedActions().then(() => {
      // open task if options[0] is a task id
      let taskIdToOpen: any = _.first(options);
      taskIdToOpen = taskIdToOpen ? parseInt(taskIdToOpen, 10) : null;
      if (taskIdToOpen && !_.isNaN(taskIdToOpen)) {
        const task = _.find(this.tasks(), (t: any) => { return t.TaskId === taskIdToOpen });
        if (task) {
          this.viewLocatorService.removeFromDisplayedRoute(taskIdToOpen);
          this.openAction(task);
        }
      }
    });
  }

  public getLockedActions(): any {
    this.isLoading(true);
    return Utils.wrapDfd(this.taskService.assignedTasks())
      .then((result: any) => {
        if (result) {
          this.tasks([]);
          for (let x = 0; x < result.length; x++) {
            this.tasks.unshift(result[x]);
          }
        } else {
          this.tasks([]);
        }
      }).fail((displayMessage) => {
        toastr.error(displayMessage);
      }).always(() => {
        this.isLoading(false);
      });
  }

  public openAction(item: any): any {
    this.isLoading(true);
    // if reload is required, add /<taskId> to route and reload so openAction gets called after reload
    if (this.reloadService.isReloadRequired()) {
      this.viewLocatorService.addToDisplayedRoute(item.TaskId, true);
      this.reloadService.reload('Auto reload from assignedtasks');
      return;
    }

    this.openPriorityView(item.TaskId);
  }

  public getNextTaskInQueue() {
    this.isLoading(true);
    let returnedId = 0;
    Utils.wrapDfd(this.actionService.getNextActionInQueue())
      .then((data: any) => {
        if (data && data.Task) {
          returnedId = data.Task.TaskId;
          this.mostRecentSelectedTaskId(returnedId);
        } else {
          toastr.warning('No more actions are available.');
        }
      }).fail((displayMessage) => {
        toastr.error(displayMessage);
      }).always(() => {
        this.getLockedActions();
        this.isLoading(false);
      });
  }

  private openPriorityView(taskId: number) {
    this.actionService.get(taskId)
      .pipe(finalize(() => this.isLoading(false)))
      .subscribe(x => {
        PriorityViewModel.show('app-components-specialist-actionworkspace', { currentAction: x }).done(() => {
          this.getLockedActions();
        });
      },
        err => toastr.error(err)
      );
  }
}
