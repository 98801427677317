<div [loadingOverlay]="isLoading()">
  <ng-container *ngIf="!isLoading()">
    <div class="card">
      <div class="card-header">
        <div class="form-group">
          <label class="control-label">
            Url
            <select [ngModel]="urlFilter()" (ngModelChange)="urlFilter($event)" [ngModelOptions]="{ standalone: true }"
              class="form-select">
              <option *ngFor="let option of urlFilterOptions()" [ngValue]="option.Value">{{ option.Name }}</option>
            </select>
          </label>
          <label class="control-label">
            Origin
            <select [ngModel]="originFilter()" (ngModelChange)="originFilter($event)"
              [ngModelOptions]="{ standalone: true }" class="form-select">
              <option *ngFor="let option of originFilterOptions()" [ngValue]="option.Value">{{ option.Name }}</option>
            </select>
          </label>
          <label class="control-label">
            Status
            <select [ngModel]="statusFilter()" (ngModelChange)="statusFilter($event)"
              [ngModelOptions]="{ standalone: true }" class="form-select">
              <option *ngFor="let option of statusFilterOptions()" [ngValue]="option.Value">{{ option.Name }}</option>
            </select>
          </label>
          <label class="control-label">
            Created By
            <select [ngModel]="createdByFilter()" (ngModelChange)="createdByFilter($event)"
              [ngModelOptions]="{ standalone: true }" class="form-select">
              <option *ngFor="let option of createdByFilterOptions()" [ngValue]="option.Value">{{ option.Name }}
              </option>
            </select>
          </label>
          <label class="control-label">
            Assignment
            <select [ngModel]="assignmentFilter()" (ngModelChange)="assignmentFilter($event)"
              [ngModelOptions]="{ standalone: true }" class="form-select">
              <option value=""></option>
              <option value="assigned">Assigned</option>
              <option value="unassigned">Un-Assigned</option>
            </select>
          </label>
        </div>
      </div>
      <div class="card-body" style="padding-left: 15px; padding-right: 15px;">
        <app-table [items]="filteredTasks()" [exportFilename]="'task-history'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="Url()">URL</th>
              <th export sort field="KeywordPhrases()">Keywords</th>
              <th export sort field="RequestTaskId">Task ID</th>
              <th export sort field="CreatedBy">Created By</th>
              <th export sort field="DetailsText()">Details</th>
              <th export sort field="Assignment()">Assignment</th>
              <th export sort field="Origin">Origin</th>
              <th export sort field="DisplayStatus">Status</th>
              <th export sort field="TaskInsertedDate">Ordered Date</th>
              <th export sort field="TaskCompletedDate">Written Date</th>
              <th export sort field="TaskPublishedDate">Published Date</th>
              <th export sort field="TaskVerifiedDate">Verified Date</th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-history>
            <tr>
              <td>
                <ng-container *ngIf="isOnsiteBlogTask(history.WorkflowDefinitionId)">
                  <a *ngIf="history.BoostToPublish" [attr.href]="history.PublishUrl || history.Url()" target="_blank"
                    style="word-break: break-all;" rel="noreferrer">
                    <i class="fas fa-external-link-square-alt"></i>
                    <span *ngIf="!isContentRevisionTask(history)">{{history.Comment}}</span>
                  </a>
                  <span *ngIf="!history.BoostToPublish && !isContentRevisionTask(history)">
                    {{history.Comment}}
                  </span>
                </ng-container>
                <ng-container *ngIf="!isOnsiteBlogTask(history.WorkflowDefinitionId)">
                  <a [attr.href]="history.Url()" target="_blank" style="word-break: break-all;" rel="noreferrer">
                    <i class="fas fa-external-link-square-alt"></i>
                    <span>{{history.Url()}}</span>
                  </a>
                  <span *ngIf="history.StatusId() !== websiteUrlStatuses_Active"
                    class="text-danger">&nbsp;(LinkBuild=No)</span>
                  <span [hidden]="history.IsActiveStatus()" class="text-danger">&nbsp;(Archived)</span>
                </ng-container>
              </td>
              <td>
                <div *ngIf="history.IsActiveStatus() && !isOnsiteBlogTask(history.WorkflowDefinitionId)">
                  <span [ngbPopover]="history.KeywordPhrases()" placement="top auto" triggers="mouseenter:mouseleave">
                    <i class="fa fa-sticky-note fa-2x text-info"></i>
                  </span>
                </div>
              </td>
              <td>
                <span>{{history.RequestTaskId}}</span>
              </td>
              <td>{{history.CreatedBy}}</td>
              <td>
                <a *ngIf="history.FileUrl" [externalLink]="{ url: history.FileUrl, text: 'File' }"></a>
                <a *ngIf="history.PreviewUrl" [externalLink]="{ url: history.PreviewUrl, text: 'Article Pending' }"></a>
                <span *ngIf="history.Comment && !isOnsiteBlogTask(history.WorkflowDefinitionId)"
                  [ngbPopover]="history.Comment" popoverTitle="Instructions" placement="right auto"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-list-alt fa-2x text-info"></i>
                </span>
                <span *ngIf="history.Note" [ngbPopover]="history.Note" popoverTitle="Note" placement="right auto"
                  triggers="mouseenter:mouseleave">
                  <i class="fa fa-clipboard fa-2x text-info"></i>
                </span>
                <div *ngIf="shouldShowImplementationInstructions(history)">
                  <a (click)="editImplementationInstructions(history)" style="color: #428bca; cursor: pointer">
                    <i class="fa fa-pencil-alt"></i>Implementation Instructions
                  </a>
                </div>
                <div *ngIf="shouldShowRejectionInstructions(history)">
                  <a (click)="editRejectionInstructions(history)" style="color: #428bca; cursor: pointer">
                    <i class="fa fa-pencil-alt"></i>Rejection Instructions
                  </a>
                </div>
              </td>
              <td>{{history.Assignment()}}</td>
              <td>{{history.Origin}}</td>
              <td>
                <span>{{history.DisplayStatus}}</span>
                <app-components-helptext [hidden]="history.TaskStatus !== 'ReconcileComplete'"
                  [key]="'OnsiteTaskHistory_TaskAutoCompleted'"></app-components-helptext>
              </td>
              <td>{{history.TaskInsertedDate | date:'shortDate'}}</td>
              <td>{{history.TaskCompletedDate | date:'shortDate'}}</td>
              <td>{{history.TaskPublishedDate ? (history.TaskPublishedDate | date:'shortDate') : !history.BoostToPublish
                ? '(Client Implementing)' : '' }}</td>
              <td>{{history.TaskVerifiedDate | date:'shortDate'}}</td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </ng-container>
</div>