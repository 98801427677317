import System from 'framework/System';
import { Component } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { ActionService } from '../../../services/action.service';
import { TaskPostService } from '../../../services/taskpost.service';
import { TaskKeyValuePairService } from '../../../services/task-keyvaluepair.service';
import { DocumentService } from '../../../services/document.service';
import { WorkflowRejectionService } from '../../../services/workflow-rejection.service';
import { WorkflowService } from '../../../services/workflow.service';
import { WorkflowKeyValuePairService } from '../../../services/workflow-keyvaluepair.service';
import { OnsiteRecommendationService } from '../../../services/onsite-recommendation.service';
import { CustomerQuickUrlContentAnalysisService } from '../../../services/customer-quick-url-content-analysis.service';
import Utils from '../../../shared/utils';
import BaseComponent from '../BaseComponent';
import TicketTypes = Boo.Objects.Enums.TicketTypeEnum;
import KeyEnum = Boo.Objects.Enums.KeyEnum;
import RejectionReason = Boo.Objects.Enums.RejectionReasonTypes;
import TaskHelper from 'app/specialist/TaskHelper';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import CustomValidators from '../../../shared/custom-validators';
import IValidatedResult = app.interfaces.IValidatedResult;
import RejectionFactory from '../../../factories/RejectionFactory';
import WorkflowHelper from '../../../shared/WorkflowHelper';
import { CustomerCampaignService } from '../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-ticket-blog-and-copy-content-review',
  templateUrl: './blog-and-copy-content-review.component.html',
  styleUrls: ['./blog-and-copy-content-review.component.scss']
})
export class BlogAndCopyContentReviewComponent extends BaseComponent {
  publishStatus = PublishStatus;
  action: Boo.Objects.Action;
  workflow: Boo.Objects.Workflow;
  taskPost: Boo.Objects.TaskPost;
  websiteUrl: Boo.Objects.WebsiteUrl;
  onsiteRecommendation: Boo.Objects.OnsiteRecommendation;
  isEditMode: boolean;
  saveEdit: boolean = false;
  isCopy: boolean;
  isBlog: boolean;
  documentUrl: string;
  isInRejectionStep: boolean;
  isInImplementationNoteStep: boolean;
  readyToComplete: boolean;
  currentStatus: PublishStatus = PublishStatus.Pending;
  implementationNoteCtrl: UntypedFormControl;
  osrForm: UntypedFormGroup;
  rejectOptionsForm: UntypedFormGroup;
  customerLink: string;
  isLinkExpired: boolean;
  keywords: Boo.Objects.KeywordSiteAnalysis[];
  keywordsAndAreas: string[] = [];
  originalTaskPostContent: string;
  originalTaskPostImageUrl: string;
  isTicketCompleted: boolean;
  isOpen: boolean = true;
  boostIsImplementing: boolean = true;

  private associatedTaskId: number;

  get rejectForm() { return this.rejectOptionsForm.controls; }

  constructor(
    private actionService: ActionService,
    private taskPostService: TaskPostService,
    private documentService: DocumentService,
    private workflowService: WorkflowService,
    private workflowKvpService: WorkflowKeyValuePairService,
    private workflowRejectionService: WorkflowRejectionService,
    private taskKvpService: TaskKeyValuePairService,
    private osrService: OnsiteRecommendationService,
    private customerQuickUrlContentAnalysisService: CustomerQuickUrlContentAnalysisService,
    private customerCampaignService: CustomerCampaignService,
    private fb: UntypedFormBuilder,
    private clipboard: Clipboard) {
    super();
  }

  get isInConfirmationStep(): boolean {
    return this.isInRejectionStep || this.isInImplementationNoteStep;
  }

  activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
    this.associatedTaskId = params.ticket.AssociatedTaskId();
    this.isTicketCompleted = params.ticket.StatusId() === Boo.Objects.Enums.TicketStatusEnum.Completed;

    if (params.ticket.TicketTypeId() === TicketTypes.OnsiteCopyReadyForPreview) {
      this.isCopy = true;
    } else if (params.ticket.TicketTypeId() === TicketTypes.OnsiteBlogReadyForPreview) {
      this.isBlog = true;
    }

    this.setUpRejectOptions();
    this.implementationNoteCtrl = new UntypedFormControl(null);

    return $.when<any>(
      Utils.wrapDfd(this.actionService.getByTaskId(this.associatedTaskId)),
      Utils.wrapDfd(this.workflowService.getByTaskId(this.associatedTaskId)),
      Utils.wrapDfd(this.taskPostService.getCustomerLink(this.associatedTaskId)),
      Utils.wrapDfd(this.workflowKvpService.getByTaskId(this.associatedTaskId))
    )
      .then((action: Boo.Objects.Action, workflow: Boo.Objects.Workflow, customerLink: string, workflowKvp: Boo.Objects.WorkflowKeyValuePair[]) => {
        this.action = action;
        this.workflow = workflow;
        this.onsiteRecommendation = this.action.TaskDetails.OnsiteRecommendation;
        this.taskPost = this.action.TaskDetails.TaskPost;
        this.originalTaskPostContent = this.taskPost.Content;
        this.originalTaskPostImageUrl = this.taskPost.ImageUrl;
        this.customerLink = customerLink;
        this.isLinkExpired = !customerLink;

        if (this.isBlog) {
          this.boostIsImplementing = WorkflowHelper.getIsBoostImplementing(workflowKvp);
        }

        const calls: any[] = [
          Utils.wrapDfd(this.customerCampaignService.getWebsiteUrl(action.Task.WebsiteUrlId))
        ];

        if (this.isCopy) {
          calls.push(Utils.wrapDfd(this.customerQuickUrlContentAnalysisService.process(action.Task.WebsiteUrlId)));
        }

        return $.when<any>(...calls);
      })
      .then((websiteUrl: Boo.Objects.WebsiteUrl, analysis: Boo.Objects.QuickUrlContentAnalysis) => {
        this.keywords = analysis ? analysis.Keywords : null;

        if (this.keywords) {
          this.keywords.forEach(keyword => {
            this.keywordsAndAreas.push(`${keyword.Keyword} ${keyword.Area}`);
          });
        }

        if (this.isCopy) {
          this.setUpOnsiteCopyOSRForm();
        }
        if (this.isBlog) {
          this.setUpOnsiteBlogOSRForm();
        }

        this.documentUrl = this.documentService.getDocumentUrl(this.taskPost.TaskPostId);
        this.websiteUrl = websiteUrl;
        return super.activate(params);
      })
      .fail(err => toastr.error(err));
  }

  validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
    if (saveType !== SaveTypes.Complete) {
        if (this.currentStatus !== PublishStatus.Pending) {
          const message = `Because the copy has been ${this.getStatus()}, the ticket cannot be updated. Please "Complete" the ticket`;
          return System.resolvedPromise<IValidatedResult>({ isValid: false, errorMessages: [message] });
        }

        if (this.currentStatus === PublishStatus.Pending && this.saveEdit) {
          const message = 'Cancel and Revert changes to update this ticket. Copy has to be either Approved or Rejected before completing this ticket.';
          return System.resolvedPromise<IValidatedResult>({ isValid: false, errorMessages: [message] });
        }
    }

    if (saveType === SaveTypes.Complete && this.currentStatus === PublishStatus.Pending) {
        const message = 'Copy has to be either Approved or Rejected before completing this ticket';
        return System.resolvedPromise<IValidatedResult>({ isValid: false, errorMessages: [message] });
    }

    return super.validate(saveType);
  }

  save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
    const apiCalls: any[] = [];

    if (saveType === SaveTypes.Complete) {
      if (this.saveEdit) {
        if (this.isCopy) {
          this.updateOnsiteCopyOSRData();
        }
        if (this.isBlog) {
          this.updateOnsiteBlogOSRData();
        }
      }

      const kvpsToSave = [];

      const kvpExternalStatus = TaskHelper.taskKvpFactory(this.action.Task.TaskId, this.user.UserId, KeyEnum.ExternalStatus, this.getStatus());
      const kvpExternalId = TaskHelper.taskKvpFactory(this.action.Task.TaskId, this.user.UserId, KeyEnum.ExternalId, 'LP');

      switch (this.currentStatus) {
        case PublishStatus.Approved:
          if (this.isBlog) {
            const kvpComment = TaskHelper.taskKvpFactory(this.action.Task.TaskId, this.user.UserId, KeyEnum.OnsiteBloggingImplementationInstructions, this.implementationNoteCtrl.value);
            kvpsToSave.push(kvpComment);
          }

          apiCalls.push(Utils.wrapDfd(this.taskPostService.save(this.taskPost, this.associatedTaskId)));
          break;
        case PublishStatus.Rejected:
          const rejection = this.populateRejection();
          apiCalls.push(Utils.wrapDfd(this.workflowRejectionService.save(rejection)));
          break;
        default:
          break;
      }

      kvpsToSave.push(kvpExternalStatus, kvpExternalId);
      apiCalls.push(
        Utils.wrapDfd(this.taskKvpService.save(kvpsToSave)),
        Utils.wrapDfd(this.osrService.save(this.onsiteRecommendation)),
      );
    }

    return $.when<any>(...apiCalls)
      .then(() => super.save(saveType))
      .fail(err => toastr.error(err));
  }

  triggerEditMode(): void {
    this.isEditMode = true;
    this.saveEdit = true;
    this.osrForm.enable();
  }

  reset(): void {
    this.isEditMode = false;
    this.saveEdit = false;
    this.isInRejectionStep = false;
    this.isInImplementationNoteStep = false;
    this.resetForm();
  }

  rejectCopy(): void {
    if (!this.rejectOptionsForm.valid) {
      toastr.error('Complete fields');
      return;
    }
    this.isInRejectionStep = false;
    this.currentStatus = PublishStatus.Rejected;
  }

  cancelRejectionStep(): void {
    this.isInRejectionStep = false;
    this.rejectOptionsForm.reset();
  }

  approveCopy(): void {
    this.isInImplementationNoteStep = false;
    this.currentStatus = PublishStatus.Approved;
  }

  triggerApproveStep(): void {
    this.osrForm.markAllAsTouched();
    if (this.osrForm.invalid) {
      toastr.error('Invalid Fields');
      return;
    }

    this.isEditMode = false;
    if (this.isCopy || !this.boostIsImplementing) {
      this.currentStatus = PublishStatus.Approved;
      return;
    }

    this.isInImplementationNoteStep = true;
  }

  uploadFile(): void {
    let request = <PresignedUrlRequest>({
      BucketName: launchpad.config.S3Buckets.s3staticfiles,
      Folder: 'tickets/customers/' + this.taskPost.CustomerId,
      UseUniqueFileName: true,
      ContentSize: 0,
      ContentType: '',
      Expiration: null,
      Filename: ''
    });
    PriorityViewModel.show('app-components-shared-uploadfile', { preSignedURLRequest: request })
      .then((data: any) => {
        toastr.success('File Uploaded');
        this.taskPost.ImageUrl = data.FullUrl;
      })
      .fail(err => toastr.error(err));
  }

  copyCustomerLink(): void {
    this.clipboard.copy(this.customerLink);
    toastr.success('Customer link copied to clipboard');
  }

  getStatus(): string {
    switch (this.currentStatus) {
      case PublishStatus.Approved:
        return 'Approved';
      case PublishStatus.Rejected:
        return 'Reject';
      default:
        return 'Other';
    }
  }

  private resetForm(): void {
    this.taskPost.Content = this.originalTaskPostContent;

    if (this.isCopy) {
      this.resetOnsiteCopyOSRForm();
    }

    if (this.isBlog) {
      this.implementationNoteCtrl.setValue('');
      this.resetOnsiteBlogOSRForm();
      this.taskPost.ImageUrl = this.originalTaskPostImageUrl;
    }
  }

  private setUpOnsiteCopyOSRForm(): void {
    const copyValidators = [CustomValidators.keywordMustBePresent(this.keywords), Validators.maxLength(250)];

    this.osrForm = this.fb.group({
      titleNew: new UntypedFormControl(this.onsiteRecommendation.TitleNew, copyValidators),
      descriptionNew: new UntypedFormControl(this.onsiteRecommendation.DescriptionNew, copyValidators),
      h1New: new UntypedFormControl(this.onsiteRecommendation.H1New),
      h2New: new UntypedFormControl(this.onsiteRecommendation.H2New)
    });

    this.osrForm.disable();
  }

  private resetOnsiteCopyOSRForm(): void {
    this.osrForm.disable();
    this.osrForm.get('titleNew').setValue(this.onsiteRecommendation.TitleNew);
    this.osrForm.get('descriptionNew').setValue(this.onsiteRecommendation.DescriptionNew);
    this.osrForm.get('h1New').setValue(this.onsiteRecommendation.H1New);
    this.osrForm.get('h2New').setValue(this.onsiteRecommendation.H2New);
  }

  private updateOnsiteCopyOSRData(): void {
    this.onsiteRecommendation.TitleNew  = this.osrForm.get('titleNew').value;
    this.onsiteRecommendation.DescriptionNew = this.osrForm.get('descriptionNew').value;
    this.onsiteRecommendation.H1New = this.osrForm.get('h1New').value;
    this.onsiteRecommendation.H2New = this.osrForm.get('h2New').value;
  }

  private setUpOnsiteBlogOSRForm(): void {
    const copyValidators = [Validators.maxLength(250)];

    this.osrForm = this.fb.group({
      titleNew: new UntypedFormControl(this.onsiteRecommendation.TitleNew, copyValidators),
      descriptionNew: new UntypedFormControl(this.onsiteRecommendation.DescriptionNew, copyValidators),
      contentTitle: new UntypedFormControl(this.taskPost.ContentTitle),
      h2New: new UntypedFormControl(this.onsiteRecommendation.H2New)
    });

    this.osrForm.disable();
  }

  private resetOnsiteBlogOSRForm(): void {
    this.osrForm.disable();
    this.osrForm.get('titleNew').setValue(this.onsiteRecommendation.TitleNew);
    this.osrForm.get('descriptionNew').setValue(this.onsiteRecommendation.DescriptionNew);
    this.osrForm.get('contentTitle').setValue(this.taskPost.ContentTitle);
  }

  private updateOnsiteBlogOSRData(): void {
    this.onsiteRecommendation.TitleNew  = this.osrForm.get('titleNew').value;
    this.onsiteRecommendation.DescriptionNew = this.osrForm.get('descriptionNew').value;
    this.taskPost.ContentTitle = this.osrForm.get('contentTitle').value;
    this.onsiteRecommendation.H2New = this.osrForm.get('h2New').value;
  }

  private setUpRejectOptions(): void {
    this.rejectOptionsForm = this.fb.group({
      hasInaccurateInfo: new UntypedFormControl(false),
      inaccurateInfo: new UntypedFormControl(null),
      hasMissingInfo: new UntypedFormControl(false),
      missingInfo: new UntypedFormControl(null),
      hasTone: new UntypedFormControl(false),
      tone: new UntypedFormControl(null),
      hasGrammaticalErrors: new UntypedFormControl(false),
      grammaticalErrors: new UntypedFormControl(null),
      hasOther: new UntypedFormControl(false),
      other: new UntypedFormControl(null)
    });

    this.setRejectOptionsValidation([
      { checkCtrl: this.rejectForm.hasInaccurateInfo, dataCtrl: this.rejectForm.inaccurateInfo },
      { checkCtrl: this.rejectForm.hasMissingInfo, dataCtrl: this.rejectForm.missingInfo },
      { checkCtrl: this.rejectForm.hasTone, dataCtrl: this.rejectForm.tone },
      { checkCtrl: this.rejectForm.hasGrammaticalErrors, dataCtrl: this.rejectForm.grammaticalErrors },
      { checkCtrl: this.rejectForm.hasOther, dataCtrl: this.rejectForm.other },
    ]);
  }

  private setRejectOptionsValidation(pairs: IRejectOptionPairing[]): void {
    pairs.forEach(pair => {
      pair.checkCtrl.valueChanges.subscribe((value: boolean) => {
        pair.dataCtrl.setValidators(value ? [Validators.required, Validators.maxLength(1200)] : null);
        pair.dataCtrl.updateValueAndValidity();
      });
    });
  }

  private populateRejection(): Boo.Objects.WorkflowRejection {
    const reasons: Boo.Objects.RejectionReason[] = [];

    if (this.rejectForm.hasInaccurateInfo.value) {
      reasons.push(RejectionFactory.createRejectionReason(RejectionReason.InaccurateInformation, this.rejectForm.inaccurateInfo.value));
    }

    if (this.rejectForm.hasMissingInfo.value) {
      reasons.push(RejectionFactory.createRejectionReason(RejectionReason.MissingInformation, this.rejectForm.missingInfo.value));
    }

    if (this.rejectForm.hasTone.value) {
      reasons.push(RejectionFactory.createRejectionReason(RejectionReason.Tone, this.rejectForm.tone.value));
    }

    if (this.rejectForm.hasGrammaticalErrors.value) {
      reasons.push(RejectionFactory.createRejectionReason(RejectionReason.GrammaticalErrors, this.rejectForm.grammaticalErrors.value));
    }

    if (this.rejectForm.hasOther.value) {
      reasons.push(RejectionFactory.createRejectionReason(RejectionReason.Other, this.rejectForm.other.value));
    }

    const rejection = RejectionFactory.createRejectionFromReasons(this.user.UserId, reasons, 'OSR Copy Rejection');

    return {
      WorkflowId: this.workflow.WorkflowId,
      WorkflowState: this.workflow.WorkflowStateEnum,
      Rejection: rejection,
    } as Boo.Objects.WorkflowRejection;
  }
}

enum PublishStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2
}

interface IRejectOptionPairing {
  checkCtrl: AbstractControl;
  dataCtrl: AbstractControl;
}
