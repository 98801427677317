<ng-container *ngIf="addingKeyword; else notAddingKeyword">
  <div class="row justify-content-start">
    <ng-container *ngIf="isAreaFirst; else areaLast">
      <ng-container *ngIf="!customer.IsCampaignNational">
        <div class="col-auto">
          <ng-container *ngTemplateOutlet="area"></ng-container>
        </div>
        <div class="col-auto">
          <ng-container *ngTemplateOutlet="toggleArea"></ng-container>
        </div>
      </ng-container>
      <div class="col-auto">
        <ng-container *ngTemplateOutlet="keyword"></ng-container>
      </div>
    </ng-container>
    <ng-template #areaLast>
      <div class="col-auto">
        <ng-container *ngTemplateOutlet="keyword"></ng-container>
      </div>
      <ng-container *ngIf="!customer.IsCampaignNational">
        <div class="col-auto">
          <ng-container *ngTemplateOutlet="toggleArea"></ng-container>
        </div>
        <div class="col-auto">
          <ng-container *ngTemplateOutlet="area"></ng-container>
        </div>
      </ng-container>
    </ng-template>
    <div class="col-auto">
      <button type="button" class="btn btn-primary" (click)="addKeyword()"><i class="fas fa-plus"></i>Keyword</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-warning" (click)="toggleAddingKeyword()">Cancel</button>
    </div>
  </div>
</ng-container>

<ng-template #notAddingKeyword>
  <div class="row">
    <div class="col-12">
      <button class="btn btn-primary btn-sm" (click)="toggleAddingKeyword()" [disabled]="websiteUrl?.IsReadOnly || restrictions.includes(customerCampaignRestrictions_ReadOnly)">
        <i class="fas fa-plus"></i>Keyword
      </button>
    </div>
  </div>
</ng-template>

<ng-template #keyword>
  <div class="input-group mb-0" [formValidation]="formGroup">
    <span class="input-group-text">Keyword</span>
    <input #keywordInput type="text" class="form-control" [formControl]="keywordControl"
      [formValidation]="keywordControl" (keyup.enter)="addKeyword()"/>
  </div>
</ng-template>

<ng-template #area>
  <div class="input-group mb-0" [formValidation]="formGroup">
    <span class="input-group-text">Location</span>
    <input #areaInput type="text" class="form-control" [formControl]="areaControl"
      [formValidation]="areaControl" (keyup.enter)="addKeyword()" />
  </div>
</ng-template>

<ng-template #toggleArea>
  <button tabindex="-1" type="button" class="btn btn-primary" (click)="toggleAreaPosition()">
    <i class="fas fa-arrow-right-arrow-left fa-no-margin"></i>
  </button>
</ng-template>