<div class="card" style="margin-bottom: 0px;">
  <div class="card-header">
    <span>Country And Language</span>
    <button (click)="addCountryLanguagePair()" class="btn btn-primary btn-sm float-end">
      <i class="fa fa-plus"></i>
      Add
    </button>
  </div>
  <div [loadingOverlay]="isLoading">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th (click)="sort(0)" style="cursor: pointer;">
                  Country
                  <i *ngIf="filterByCountry && filterAscending" class="fas fa-sort-down"></i>
                  <i *ngIf="filterByCountry && !filterAscending" class="fas fa-sort-up"></i>
                </th>
                <th (click)="sort(1)" style="cursor: pointer;">
                  Language
                  <i *ngIf="!filterByCountry && filterAscending" class="fas fa-sort-down"></i>
                  <i *ngIf="!filterByCountry && !filterAscending" class="fas fa-sort-up"></i>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let cl of countryLanguages">
                <tr>
                  <td>{{cl.Country.Name}}</td>
                  <td>{{cl.Language.Name}}</td>
                  <td>
                    <button (click)="delete(cl.CountryLanguageId)" class="btn btn-danger">
                      <i class="fa fa-save"></i>
                      Delete
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
