import MerchantFieldLabel from 'app/models/typescript/MerchantFieldLabel';

export default class MerchantFieldLabelMap {
    public CampaignManagerContactLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Campaign Manager Contact');
    public CampaignManagerLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Campaign Manager');
    public CategoryIdLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Category Id');
    public CategoryLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Category');
    public ContractEndDateLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Contract End Date');
    public CustomerValueLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Customer Value');
    public IdLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Id');
    public ItemIdLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Item Id');
    public MiscIdLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Misc Id');
    public ProductCodeLabel: MerchantFieldLabel = new MerchantFieldLabel('Product Code');
    public ProposalIdLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Proposal Id');
    public RegionLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Region');
    public ServiceLevelLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Service Level');
    public SitePublishDateLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Site Publish Date');
    public SourceLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Source');
    public SubCategoryIdLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Subcategory Id');
    public SubCategoryLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Subcategory');
    public ConsultantContactLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Consultant Email');
    public ConsultantLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant Consultant');
    public UdacLabel: MerchantFieldLabel = new MerchantFieldLabel('Merchant UDAC');

    public ToArray(): MerchantFieldLabel[] {
        return [
            this.CampaignManagerContactLabel,
            this.CampaignManagerLabel,
            this.CategoryIdLabel,
            this.CategoryLabel,
            this.ContractEndDateLabel,
            this.CustomerValueLabel,
            this.IdLabel,
            this.ItemIdLabel,
            this.MiscIdLabel,
            this.ProductCodeLabel,
            this.ProposalIdLabel,
            this.RegionLabel,
            this.ServiceLevelLabel,
            this.SitePublishDateLabel,
            this.SourceLabel,
            this.SubCategoryIdLabel,
            this.SubCategoryLabel,
            this.ConsultantContactLabel,
            this.ConsultantLabel,
            this.UdacLabel];
    }
}