import { Component, Input, OnInit } from '@angular/core';
import CustomerAccountTypeGroup from 'app/customeraccount/CustomerAccountTypeGroup';
import { PartnerGroupService } from '../../../services/partner-group.service';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';

@Component({
  selector: 'app-components-managecustomer-readonlyexternalaccess',
  templateUrl: './ReadOnlyExternalAccess.component.html'
})
export class ReadOnlyExternalAccessComponent {
  @Input() public customer: Boo.Objects.Customer;

  blog: number[] = CustomerAccountTypeGroup.blog;
  seo: number[] = CustomerAccountTypeGroup.seo;
  website: number[] = CustomerAccountTypeGroup.website;
  tabPanelDisplayMode = TabPanelDisplayMode;

  constructor(public partnerGroupService: PartnerGroupService) { }
}
