import System from 'framework/System';
import BounceAnimation from 'framework/Animations/BounceAnimation';
import IPriorityViewPublicApi = app.interfaces.IPriorityViewPublicApi;
import { ViewLocatorService } from '../../../app/services/view-locator.service';
import { ReloadService } from '../../../app/services/reload.service';

export class PriorityViewModel {

    static isLoadingPriorityView: boolean = false;
    private static app: any;
    private static viewLocatorService: ViewLocatorService;
    private static reloadService: ReloadService;
    private static priorityViewUniqueIndex: number = 1;
    static register(app: any, viewLocatorService: ViewLocatorService, reloadService: ReloadService): void {
        PriorityViewModel.app = app;
        PriorityViewModel.viewLocatorService = viewLocatorService;
        PriorityViewModel.reloadService = reloadService;
    }

    static show(selector: string, activateOptions?: any, showBreadcrumb: boolean = true, resetUrlWhenDone: boolean = true, hideComposeError: boolean = false): JQueryPromise<any> {
        return new PriorityViewModel().show(selector, activateOptions, showBreadcrumb, resetUrlWhenDone, hideComposeError);
    }

    static ngShow(selector: string, model?: any, showBreadcrumb: boolean = true, resetUrlWhenDone: boolean = true): JQueryPromise<any> {
      return new PriorityViewModel().ngShow(selector, model, showBreadcrumb, resetUrlWhenDone);
  }

    // This is a convenience method to show the manage customer priority view
    static showManageCustomer(customerId: any, showBreadcrumb: boolean = false, resetUrlWhenDone: boolean = true): JQueryPromise<any> {
        return PriorityViewModel.show('app-components-managecustomer', { customerId: customerId }, showBreadcrumb, resetUrlWhenDone, true);
    }

    static showTicket(customerId: any, ticketId: any, showBreadcrumb: boolean = false, resetUrlWhenDone: boolean = true): JQueryPromise<any> {
        return PriorityViewModel.show('app-components-managecustomer', { customerId: customerId, editTicket: true, ticketIdToEdit: ticketId }, showBreadcrumb, resetUrlWhenDone);
    }

    private static setPriorityViewVisible(value: boolean): void {
        PriorityViewModel.app.showModalContainer(value);
        PriorityViewModel.app.showHeader(!value);
        PriorityViewModel.app.showFooter(!value);
        PriorityViewModel.app.showContent(!value);
    }

    private static onPriorityViewDisplayedEvent(priorityView: IResolvedPriorityView): void {
        PriorityViewModel.app.priorityViews().forEach((x: IResolvedPriorityView) => x.visible(false));
        priorityView.visible(true);
        (new BounceAnimation()).in(priorityView.htmlElement);
        window.scrollTo(0, 0);

        PriorityViewModel.app.showPriorityViewBreadcrumb(priorityView.showBreadcrumb);
        PriorityViewModel.app.updatePageTitle();

        if (window.hasOwnProperty('ga')) {
            gtag('event', 'page_view', { page_path: '/PriorityView_' + priorityView.selector });
        }
    }

    private static close(closeAll: boolean = false): void {
        let closing: IResolvedPriorityView = PriorityViewModel.app.priorityViews.pop();

        PriorityViewModel.app.showPriorityViewBreadcrumb(false);

        if (PriorityViewModel.app.priorityViews().length === 0) {
            PriorityViewModel.setPriorityViewVisible(false);
        } else {
            if (closeAll) {
                PriorityViewModel.close(closeAll);
                return;
            }

            let viewToShow: IResolvedPriorityView = <IResolvedPriorityView>_.last(PriorityViewModel.app.priorityViews());
            PriorityViewModel.onPriorityViewDisplayedEvent(viewToShow);
        }

        if (closing.returnPath) {
            PriorityViewModel.viewLocatorService.setDisplayedRoute(closing.returnPath);
        } else {
            PriorityViewModel.app.goToDefaultRoute();
        }
    }

    private show(
        selector: string,
        params: any,
        showBreadcrumb:
        boolean,
        resetUrlWhenDone:
        boolean,
        hideComposeError: boolean = false): JQueryPromise<any> {
        let returnPath = undefined;
        if (resetUrlWhenDone) {
            // Store path to update displayed path when exit
            let splitHash = location.hash.split('#/');
            if (_.isArray(splitHash) && splitHash.length > 1) {
                returnPath = splitHash[1];
            }
        }

        if (PriorityViewModel.isLoadingPriorityView) {
            return System.autoRejectedPromise();
        } else {
            PriorityViewModel.isLoadingPriorityView = true;
        }

        // show message if reload is required because user is probably at a good reload point when opening a new priority view
        if (PriorityViewModel.reloadService.isReloadRequired()) {
          PriorityViewModel.reloadService.showMessage();
        }

        let resolutionDeferred: JQueryDeferred<any> = $.Deferred()
            .fail(error => {
                // if we have an error, log it to the console (in test mode) so that we can figure out what's wrong.
                // We can't throw the error because priority views get rejected when they are canceled, and this is
                // not really a failure state.
                if (error) {
                    launchpad.utils.log('priorityView rejected', error);
                }
            }).always((options: any) => {
                PriorityViewModel.close(options && options.closeAll);
            });

        let priorityViewParams: any = _.extend(params || {}, {
            // add public api to pass to priorityView so it can close the view
            priorityViewApi: <IPriorityViewPublicApi>{
                resolve: (value?: any): void => { resolutionDeferred.resolve(value); },
                reject: (value?: any): void => { resolutionDeferred.reject(value); }
            }
        });
        let priorityView: IResolvedPriorityView = {
            uniqueId: PriorityViewModel.priorityViewUniqueIndex++,
            selector: selector,
            showBreadcrumb: showBreadcrumb,
            title: '',
            returnPath: returnPath,
            options: priorityViewParams,
            visible: ko.observable(false),
            hideErrors: hideComposeError,
            callback: (componentInstance: any, htmlElement: HTMLElement) => {
                priorityView.componentInstance = componentInstance;
                priorityView.htmlElement = htmlElement;
                priorityView.title = ko.utils.unwrapObservable(componentInstance.title) || '';
                PriorityViewModel.isLoadingPriorityView = false;
                PriorityViewModel.setPriorityViewVisible(true);
                PriorityViewModel.onPriorityViewDisplayedEvent(priorityView);
            },
            failureCallback: () => {
                PriorityViewModel.isLoadingPriorityView = false;
            },
            model: { dfd: resolutionDeferred } // deprecated: new components should use priorityViewApi from activate instead
        };

        PriorityViewModel.app.priorityViews.push(priorityView);

        return resolutionDeferred;
    }

    private ngShow(selector: string, model: any, showBreadcrumb: boolean, resetUrlWhenDone: boolean): JQueryPromise<any> {
      let returnPath = undefined;
      if (resetUrlWhenDone) {
          // Store path to update displayed path when exit
          let splitHash = location.hash.split('#/');
          if (_.isArray(splitHash) && splitHash.length > 1) {
              returnPath = splitHash[1];
          }
      }

      if (PriorityViewModel.isLoadingPriorityView) {
          return System.autoRejectedPromise();
      } else {
          PriorityViewModel.isLoadingPriorityView = true;
      }

      // show message if reload is required because user is probably at a good reload point when opening a new priority view
      if (PriorityViewModel.reloadService.isReloadRequired()) {
          PriorityViewModel.reloadService.showMessage();
      }

      let resolutionDeferred: JQueryDeferred<any> = $.Deferred()
          .fail(error => {
              // if we have an error, log it to the console (in test mode) so that we can figure out what's wrong.
              // We can't throw the error because priority views get rejected when they are canceled, and this is
              // not really a failure state.
              if (error) {
                  launchpad.utils.log('priorityView rejected', error);
              }
          }).always((options: any) => {
              PriorityViewModel.close(options && options.closeAll);
          });

      let priorityViewParams: any = _.extend({}, {
          // add public api to pass to priorityView so it can close the view
          priorityViewApi: <IPriorityViewPublicApi>{
              resolve: (value?: any): void => { resolutionDeferred.resolve(value); },
              reject: (value?: any): void => { resolutionDeferred.reject(value); }
          }
      });

      model.priorityViewApi = priorityViewParams.priorityViewApi;
      let priorityView: IResolvedPriorityView = {
          uniqueId: PriorityViewModel.priorityViewUniqueIndex++,
          selector: selector,
          showBreadcrumb: showBreadcrumb,
          title: '',
          returnPath: returnPath,
          options: priorityViewParams,
          visible: ko.observable(false),
          hideErrors: false,
          callback: (componentInstance: any, htmlElement: HTMLElement) => {
              priorityView.componentInstance = componentInstance;
              priorityView.htmlElement = htmlElement;
              priorityView.title = ko.utils.unwrapObservable(componentInstance.title) || '';
              PriorityViewModel.isLoadingPriorityView = false;
              PriorityViewModel.setPriorityViewVisible(true);
              PriorityViewModel.onPriorityViewDisplayedEvent(priorityView);
          },
          failureCallback: () => {
            PriorityViewModel.isLoadingPriorityView = false;
          },
          model: _.extend(model, { dfd: resolutionDeferred })
      };

      PriorityViewModel.app.priorityViews.push(priorityView);
      return resolutionDeferred;
  }
}

export interface IResolvedPriorityView extends ICompositionRoute {
    uniqueId: number;
    componentInstance?: any;
    htmlElement?: HTMLElement;
    visible: KnockoutObservable<boolean>;
    returnPath: string;
    title: string;
    showBreadcrumb: boolean;
    hideErrors: boolean;
}
