<div *withPermissions="let allow of ['IsLaunchPadAdmin']">
	<!-- THIS IS A PRIORITY VIEW -->
	<div class="card">
		<div class="card-header">
			<span>{{title}}</span>
			<button (click)="close()" class="btn-close" title="Close"></button>
		</div>
		<div [loadingOverlay]="isLoading()" class="card-body">
			<div class="row">
				<div [class.col-md-3]="verticalId() !== 0" [class.col-md-12]="verticalId() === 0" class="col-md-3">
					<div class="form-group">
						<ng-container *ngIf="vertical()">
							<label class="control-label">Name</label>
							<input [ngModel]="vertical().Name()" (ngModelChange)="vertical().Name($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="editMode()" [readonly]="editMode()" type="text" class="form-control"/>
						</ng-container>
					</div>
					<ng-container *ngIf="!isTopLevelVertical() || !editMode()">
						<div class="form-group">
							<label class="control-label">Parent Vertical</label>
							<select [dataValidation]="{ placement: 'top', value: selectedParentVerticalId }" [ngModel]="selectedParentVerticalId()" (ngModelChange)="selectedParentVerticalId($event)" [ngModelOptions]="{ standalone: true }" [disabled]="editMode()" class="form-select">
								<option *ngFor="let option of parentVerticals()" [ngValue]="option.VerticalId">{{ option.Name }}</option>
							</select>
						</div>
					</ng-container>
				</div>
				<div [hidden]="!(verticalId() !== 0)" class="col-md-9">
					<div id="translations">
						<div class="row">
							<div class="col-md-12 text-end" style="margin-bottom: 15px;">
								<button (click)="addVerticalTranslation()" class="btn btn-primary">
									<i class="fa fa-plus"></i>
									Add Translation
								</button>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<table class="table table-bordered">
									<thead>
										<tr>
											<th>Language</th>
											<th>Translation</th>
										</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let $data of translations()">
											<tr>
												<td>
													<button (click)="editVerticalTranslation($data)" class="btn btn-primary">
														<i class="fas fa-external-link-alt"></i>
														<span>{{$data.languageName}}</span>
													</button>
												</td>
												<td>{{$data.Name}}</td>
											</tr>
										</ng-container>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer text-end">
			<button (click)="cancel()" class="btn btn-link">Cancel</button>
			<button (click)="save()" class="btn btn-success">
				<i class="fa fa-save"></i>
				Save
			</button>
		</div>
	</div>
</div>
