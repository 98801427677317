/* tslint:disable */

class Config {
    public static register() {
        if (!window.launchpad) {
            window.launchpad = {};
        }
        if (!window.launchpad.config) {
            window.launchpad.config = {};
        }

        //Error Messages
        window.launchpad.config.ErrorMessages = {
            Generic: 'Something went wrong... please refresh your browser and try again.',
            ValidationFailed: 'Please fix the errors on the page and try again.'
        };

        window.launchpad.config.maxInt = 2147483647;

        //Constants
        window.launchpad.config.mainContainer = '#mainContainer';
        window.launchpad.config.headerDivId = '#header';
        window.launchpad.config.specialistWorkspaceDivId = '#content';

        window.launchpad.config.accessTokenRequestHeader = 'X-Auth-AccessToken';

        window.launchpad.config.customActionItemValue = 30;
        window.launchpad.config.ckeditorExtraPlugins = 'wordcount,linkcustomer,selectall';
        window.launchpad.config.systemAdminUserId = 0;

        //Keys
        if (!window.launchpad.config.keys) {
            window.launchpad.config.keys = {};
        }

        window.launchpad.config.keys.adminUserLevelId = 1;
        window.launchpad.config.keys.seoSpecialistUserLevelId = 2;
        window.launchpad.config.keys.analyticsUserLevelId = 3;
        window.launchpad.config.keys.salesUserLevelId = 4;
        window.launchpad.config.keys.managerUserLevelId = 5;
        window.launchpad.config.keys.customerServiceUserLevelId = 6;

        //Events
        if (!window.launchpad.config.events) {
            window.launchpad.config.events = {};
        }

        window.launchpad.config.events.errorOccurred = 'launchpad:erroroccurred';
        window.launchpad.config.events.urlChanged = 'launchpad:urlchanged';
        window.launchpad.config.events.sessionExpired = 'launchpad:sessionexpired';

        //#region Enums

        // Image file types
        window.launchpad.config.imageTypes = ['png', 'gif', 'jpg'];

        window.launchpad.config.PartnerPricingToolCustomProposalTypeEnum = {
            Email: 1,
            TextInstructions: 2,
            InstaQuote: 3
        };

        window.launchpad.config.PaymentMethodEnum = {
            CreditCard: 1,
            Invoice: 4
        };

        window.launchpad.config.StatusEnum = {
            Free: 0,
            Pending: 1,
            Active: 2,
            BillingSuspension: 3,
            Paused: 4,
            Canceled: 5,
            Voided: 6,
            names: {
                0: 'Free',
                1: 'Pending',
                2: 'Active',
                3: 'Billing Suspension',
                4: 'Paused',
                5: 'Canceled',
                6: 'Voided'
            }
        };

        window.launchpad.config.CustomerCreateStatusEnum = {
            NotApplicable: -1,
            New: 0,
            Sold: 1
        };

        window.launchpad.config.ProcessingStatusEnum = {
            New: 1,
            Processing: 2,
            Completed: 3,
            Requested: 4,
            Downloading: 5,
            Stored: 6,
            Error: 7
        };

        window.launchpad.config.BankAccountEnum = {
            Unspecified: 0,
            Checking: 1,
            BusinessChecking: 2,
            Savings: 3
        };

        window.launchpad.config.SiteStatusEnum = {
            New: 1,
            PendingEdit: 2,
            Editing: 3,
            PendingReview: 4,
            Reviewing: 5,
            Reviewed: 6,
            Published: 7,
            Archived: 8,
            Canceled: 9
        };

        window.launchpad.config.AssignedStateEnum = {
            Assigned: 1,
            NotAssigned: 0
        };

        window.launchpad.config.supportStatus = {
            notSupported: 1,
            settingUp: 2,
            supported: 3
        };

        window.launchpad.config.implementationCustomTaskTypes = [
            Boo.Objects.Enums.TaskTypeEnum.ThreeHundredOneRedirectMappingAndImplementation,
            Boo.Objects.Enums.TaskTypeEnum.GoogleAnalyticsCreationAndImplementation,
            Boo.Objects.Enums.TaskTypeEnum.BlogMatching,
            Boo.Objects.Enums.TaskTypeEnum.CanonicalDomainImplementation,
            Boo.Objects.Enums.TaskTypeEnum.CanonicalTagImplementation,
            Boo.Objects.Enums.TaskTypeEnum.ClickTrackingImplementation,
            Boo.Objects.Enums.TaskTypeEnum.DomainTransfer,
            Boo.Objects.Enums.TaskTypeEnum.GoogleMapsIntegration,
            Boo.Objects.Enums.TaskTypeEnum.GoogleSearchConsoleCreationAndInstallation,
            Boo.Objects.Enums.TaskTypeEnum.InsertNofollowTagsIntoOnsiteLinks,
            Boo.Objects.Enums.TaskTypeEnum.LoginCredentialTesting,
            Boo.Objects.Enums.TaskTypeEnum.MenuEdits,
            Boo.Objects.Enums.TaskTypeEnum.OnsiteBlogInstallationStandardCMS,
            Boo.Objects.Enums.TaskTypeEnum.OnsiteBrokenLinkRepair,
            Boo.Objects.Enums.TaskTypeEnum.OnsiteCopyImplementationManual,
            Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendationImplementationManual,
            Boo.Objects.Enums.TaskTypeEnum.WebPageCreation,
            Boo.Objects.Enums.TaskTypeEnum.WebPageDeletion,
            Boo.Objects.Enums.TaskTypeEnum.RemoveExcessH1Tags,
            Boo.Objects.Enums.TaskTypeEnum.RobotsTxtCreation,
            Boo.Objects.Enums.TaskTypeEnum.RobotsTxtFileCreationAndImplementation,
            Boo.Objects.Enums.TaskTypeEnum.SchemaTagImplementation,
            Boo.Objects.Enums.TaskTypeEnum.SiteSpeedOptimization,
            Boo.Objects.Enums.TaskTypeEnum.SitemapXmlCreation,
            Boo.Objects.Enums.TaskTypeEnum.XMLSitemapCreationAndImplementation,
            Boo.Objects.Enums.TaskTypeEnum.VideoImageImplementation
        ];

        window.launchpad.config.TaskStatusEnum = {
            pending: 1,
            locked: 2,
            complete: 3,
            deleted: 4,
            needsData: 6,
            reconcileComplete: 9,
            unlockAndUnassign: 20,
            saveProgress: 30,
            scheduledForDelete: 99
        };

        window.launchpad.config.ContactTypeEnum = {
            None: 1,
            InboundContact: 2,
            OutboundCallContactMade: 3,
            Email: 4,
            Internal: 5,
            Sales: 6,
            OutboundCallNoContact: 7,
            CampaignSummary: 8,
            NoteRevision: 9,
            SalesSupport: 12
        };

        window.launchpad.config.FileSectionTypeEnum = {
            Marketing: 1,
            LocalProfile: 2,
            General: 3,
            BoostSite: 5,
            Ranks: 6,
            KeywordResearch: 7
        };

        window.launchpad.config.S3Buckets = {
            s3staticfiles: 's3staticfiles',
            blogLaunchSites: 'blog_launch_sites',
            queuedForResize: 'queued-for-resize'
        };

        window.launchpad.config.workflowStateEnum = {
            'Unknown': 0,
            'Initial': 1,
            'Ready': 2,
            'NotReady': 3,
            'InProgress': 4,
            'Suspended': 5,
            'Completed': 6,
            'Deleted': 7,
            'Error': 8,
            'ReadyForContent': 9,
            'ReadyForReview': 10,
            'ReadyForPreview': 11,
            'ReadyForPublish': 12,
            'ReadyForQA': 13,
            'ReadyForAMApproval': 14,
            'ReadyForDefinition': 15,
            'ReconcileCompleted': 45,
            'Fulfilling': 91
        };

        window.launchpad.config.WorkflowDefinitionsEnum = {
            OnsiteRecommendationWorkflowDefinition: 3,
            OnsiteCopyWorkflowDefinition: 4,
        };

        window.launchpad.config.ContentTypeEnum = {
            Link: 1,
            Interaction: 2,
            Quote: 3
        };

        window.launchpad.config.CustomerAccountStatus = {
            Unknown: 1,
            Created: 2,
            NotCreated: 3,
            Connected: 4,
            Disconnected: 5,
            NeedsToBeReset: 6
        };

        window.launchpad.config.BlogDomainCandidateStatusEnum = {
            Available: 1,
            Purchased: 2
        };

        window.launchpad.config.AmazonDeploymentStatusEnum = {
            names: {
                0: 'Not Applicable',
                1: 'New',
                7: 'Deployed',
                12: 'Retired'
            },
            ids: {
                "NotApplicable": 0,
                "New": 1,
                "Deployed": 7,
                "Retired": 12
            }
        };

        window.launchpad.config.TicketStatusEnum = {
            New: 1,
            InProgress: 2,
            Completed: 3
        };

        window.launchpad.config.LanguageIdEnum = {
            Unspecified: 0,
            English: 1,
            French: 2,
            Spanish: 3,
            EnglishAU: 4,
            EnglishUK: 5,
            German: 6,
            Dutch: 7,
            Finnish: 8
        };

        window.launchpad.config.PartnerGroupEnum = {
            Thryv: 1,
            Vivial: 2,
            Yellow: 3,
            GoDaddy: 4,
            Vendasta: 5
        };

        window.launchpad.config.listingClaimedByStatusesEnum = {
            NotClaimed: 1,
            Client: 2,
            Partner: 3
        };

        window.launchpad.config.blogDomainCandidateStatusEnum = {
            Available: 1,
            Purchased: 2,
            ConvertedToLinkLocation: 3,
            Deleted: 4
        };

        //#endregion

        //Partner Helpers
        window.launchpad.config.partners = window.launchpad.config.partners || {};
        window.launchpad.config.partners.boostability = 1;
        window.launchpad.config.partners.sensis = 152;
        window.launchpad.config.partners.thryv = 86;
        window.launchpad.config.partners.thryvMoneyBackGuarantee = 413;
        window.launchpad.config.partners.dtg = 354;
        window.launchpad.config.partners.vivial = 98;
        window.launchpad.config.partners.foundDigital = 254;
        window.launchpad.config.partners.goDaddy = 292;
        window.launchpad.config.partners.yp = 397;
        window.launchpad.config.partners.herold = 418;
        window.launchpad.config.partners.heiseRegioConcept = 344;
        window.launchpad.config.partners.heiseRegioConceptÖsterreich = 450;
        window.launchpad.config.partners.heiseRegioConceptSchweiz = 647;
        window.launchpad.config.partners.ionos = 419;
        window.launchpad.config.partners.silentFreeway = 632;
        window.launchpad.config.partners.yellow = 324;

        //Prevent validation errors from displaying
        ko.validation.init({
            insertMessages: false
        });

        window.launchpad.config.onsiteCopyPreviewAutoApprovalDays = 30;
        window.launchpad.config.onsiteBlogPreviewAutoApprovalDays = 14;

        //Workstoppage refresh frequency (in ms) -- set for more than 2 hours so that session timeout occurs.
        window.launchpad.config.workStoppage = {
            'refreshFrequency': 7500000
        };

        window.launchpad.config.schedules = {
            daily: 1,
            weekly: 2,
            monthly: 3,
            quarterly: 4,
            lifetime: 5
        };

        window.launchpad.config.linkLocations = {
            googleSpanish: 6099290,
            googleUs: 5980231,
            googleCanada: 6099292,
            googleFirstAustralia: 9588177,
            googleSecondAustralia: 14776283,
            googleFrance: 6099293
        };

        window.launchpad.config.keywordSuggestionGeographicalReaches = [
            'Local',
            'National'
        ];

        window.launchpad.config.todoStatus = {
            new: 0,
            complete: 1,
            incomplete: 2
        };

        window.launchpad.config.todoListActions = {
            create: 0,
            do: 1,
            read: 2
        };

        window.launchpad.config.mmi = {
            profile: 'user/profile'
        };
    }
}

Config.register();
