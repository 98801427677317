<div *withPermissions="let allow of ['CanManagePartners', 'IsLaunchPadAdmin', 'CanManageStorefronts', 'CanManageStorefrontsLimited', 'CanOnboardStorefronts', 'CanManagePartnerDefaultAllocations', 'CanViewPartnerDefaultAllocations', 'CanViewPartnerEmails', 'CanManagePartnerEmails']" class="main">
  <div [hidden]="!allow().CanManagePartners()" class="card">
    <div class="card-header">
      Manage Partners
      <button (click)="addPartner()" class="btn btn-primary float-end">
        <i class="fa fa-plus"></i>
        Add Partner
      </button>
    </div>
    <div [loadingOverlay]="isLoading" class="card-body">
      <div class="row">
        <div class="col-md-4">
          <div class="input-group">
            <input [(ngModel)]="partnerNameFilter" (keyup)="filterPartners()" type="text" class="form-control" placeholder="Filter by partner name"/>
            <button type="button" (click)="resetNameFilter()" class="btn bg-transparent" style="margin-left: -40px; z-index: 100;">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <div class="col-md-2">
          <label class="form-label">Active</label>
          <shared-components-customchoice [value]="onlyActivePartnerFilter" (valueChange)="setActiveFilter($event)"></shared-components-customchoice>
        </div>
        <div class="col-md-2">
          <label class="form-label">Live</label>
          <shared-components-customchoice [value]="onlyLivePartnerFilter" (valueChange)="setLiveFilter($event)"></shared-components-customchoice>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-table [items]="filteredPartners" [exportFilename]="'manage-partners'">
            <ng-template #tableHeader>
              <tr>
                <th></th>
                <th export sort field="IsPartnerActive">Active</th>
                <th export sort field="IsPartnerActive">Live</th>
                <th export sort field="PartnerId">Id</th>
                <th export sort field="PartnerName">Name</th>
                <th export sort field="PartnerManager">Manager</th>
                <th></th>
                <th *ngIf="canManageStorefronts"></th>
              </tr>
            </ng-template>
            <ng-template #tableBody let-partner>
              <tr *withPermissions="let allow of ['CanManagePartners','IsLaunchPadAdmin', 'CanManageStorefronts', 'CanManageStorefrontsLimited', 'CanManagePartnerDefaultAllocations', 'CanViewPartnerDefaultAllocations', 'CanViewPartnerEmails', 'CanManagePartnerEmails']">
                <td class="text-center" style="width: 50px;">
                  <a *ngIf="(allow().CanManagePartners() && boostabilityPartnerId !== partner.PartnerId) || (boostabilityPartnerId === partner.PartnerId && allow().IsLaunchPadAdmin())" (click)="editPartner(partner)" title="Edit Partner" style="cursor: pointer;">
                    <i class="fas fa-edit fa-lg text-primary"></i>
                  </a>
                </td>
                <td class="text-center" style="width: 50px;">
                  <ng-container *ngIf="partner.IsPartnerActive">
                    <i class="fas fa-circle text-success" title="Active"></i>
                  </ng-container>
                  <ng-container *ngIf="!partner.IsPartnerActive">
                    <i class="fas fa-circle text-danger" title="Not Active"></i>
                  </ng-container>
                </td>
                <td class="text-center" style="width: 50px;">
                  <ng-container *ngIf="partner.IsPartnerLive">
                    <i class="fas fa-circle text-success" title="Active"></i>
                  </ng-container>
                  <ng-container *ngIf="!partner.IsPartnerLive">
                    <i class="fas fa-circle text-danger" title="Not Active"></i>
                  </ng-container>
                </td>
                <td style="width: 50px;">{{partner.PartnerId}}</td>
                <td>{{partner.PartnerName}}</td>
                <td>{{partner.PartnerManager || 'N/A'}}</td>
                <td>
                  <div ngbDropdown class="d-inline-block d-grid gap-2">
                    <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Actions</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button ngbDropdownItem (click)="openReporting(partner)">
                        <i class="fa fa-chart-pie me-2"></i>Manage Reporting Site
                      </button>
                      <ng-container *ngIf="allow().CanManagePartners()">
                        <button ngbDropdownItem (click)="managePartnerPricingTool(partner)">
                          <i class="fa fa-edit me-2"></i>Manage Partner Pricing Tool
                        </button>
                      </ng-container>
                      <ng-container *ngIf="allow().CanManagePartners() && !partner.hasPartnerUserInPartner">
                        <button ngbDropdownItem (click)="addUserToPortal(partner)">
                          <i class="fas fa-user-plus me-2"></i>Add user to portal
                        </button>
                      </ng-container>
                      <ng-container *ngIf="allow().CanManagePartners()">
                        <button ngbDropdownItem (click)="openTaskTypeExclusions(partner)">
                          <i class="fas fas fa-stop-circle me-2"></i>Task Type Exclusions
                        </button>
                      </ng-container>
                      <ng-container *ngIf="allow().CanManagePartnerDefaultAllocations() || allow().CanViewPartnerDefaultAllocations()">
                        <button ngbDropdownItem (click)="openPartnerSeoWorkDefaults(partner, !allow().CanManagePartnerDefaultAllocations() && allow().CanViewPartnerDefaultAllocations())">
                          <i class="fas fa-gears me-2"></i>Default Task Type Allocations
                        </button>
                      </ng-container>
                      <ng-container *ngIf="allow().CanViewPartnerEmails() || allow().CanManagePartnerEmails()">
                        <button ngbDropdownItem (click)="openPartnerEmails(partner)">
                          <i class="fas fa-envelope me-2"></i>Manage Partner Emails
                        </button>
                      </ng-container>
                    </div>
                  </div>
                </td>
                <ng-container *ngIf="canManageStorefronts">
                  <td style="width: 120px;">
                    <div class="d-grid gap-2">
                      <ng-container *ngIf="partner.StorefrontId">
                        <button (click)="manageStorefront(partner)" class="btn btn-primary btn-sm">
                          <i class="fas fa-external-link-alt"></i>
                          Manage Storefront
                        </button>
                      </ng-container>
                      <ng-container *ngIf="!partner.StorefrontId">
                        <button (click)="createStorefront(partner)" class="btn btn-success btn-sm">
                          <i class="fa fa-plus"></i>
                          Create Storefront
                        </button>
                      </ng-container>
                    </div>
                  </td>
                </ng-container>
              </tr>
            </ng-template>
          </app-table>
        </div>
      </div>
    </div>
  </div>
  </div>
