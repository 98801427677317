import { Component, Input, OnInit } from '@angular/core';
import { CustomerAccountService } from '../../../services/customer-account.service';
import { CustomerKeyValuePairService } from '../../../services/customer-keyvaluepair.service';
import CustomerHelper from 'app/managecustomer/CustomerHelper';
import { PartnerGroupService } from '../../../services/partner-group.service';
import PartnerGroupEnum = Boo.Objects.Enums.PartnerGroupEnum;
import { PreAuditDetailService } from '../../../services/pre-audit-detail.service';
import { forkJoin } from 'rxjs';
import { SessionStorageService } from '../../../services/session-storage.service';

@Component({
  selector: 'app-components-managecustomer-readonlycustomeraccountgroup',
  templateUrl: './ReadOnlyCustomerAccountGroup.component.html'
})
export class ReadOnlyCustomerAccountGroupComponent implements OnInit {
  @Input() public customer: Boo.Objects.Customer;
  @Input() public customerAccountTypes: number[];
  @Input() public showBoostSiteAccounts?: boolean;
  @Input() public checkThryvDudaSso: boolean;

  showDudaSsoUrl: boolean;
  window = window;
  customerAccounts: Boo.Objects.CustomerAccount[];

  // These partner groups all use the Thryv Duda account
  private thryvDudaSsoVisiblePartnerGroups: PartnerGroupEnum[] = [PartnerGroupEnum.Thryv, PartnerGroupEnum.Vivial, PartnerGroupEnum.Yellow];

  constructor(
    private customerKeyValuePairService: CustomerKeyValuePairService,
    private customerAccountService: CustomerAccountService,
    private partnerGroupService: PartnerGroupService,
    private preAuditDetailService: PreAuditDetailService,
    private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    this.customerAccountService.getOrCreate(this.customer.CustomerId, this.customerAccountTypes)
      .subscribe(accounts => {
        this.customerAccounts = _.sortBy(accounts, (account) => {
          this.customerAccountTypes.indexOf(account.CustomerAccountTypeId);
        })
      });

    if(this.checkThryvDudaSso)
    {
      this.partnerGroupService.get(this.customer.PartnerId).subscribe(
        partnerGroups => {
          if (partnerGroups?.some(x => this.thryvDudaSsoVisiblePartnerGroups.includes(x.GroupId))) {
            this.checkThryvDudaSsoVisibility();
          }
        },
        err => toastr.error(err)
      );
    }
  }

  private checkThryvDudaSsoVisibility(): void {
    forkJoin([
      this.preAuditDetailService.getByCustomerId(this.customer.CustomerId),
      this.sessionStorageService.getStaticData(),
      this.customerKeyValuePairService.get(this.customer.CustomerId)
    ]).subscribe(([preAudit, staticData, keys]) => {
      let cms: Boo.Objects.ContentManagementSystem = (preAudit ? staticData.ContentManagementSystems.find((i: { ContentManagementSystemId: any; }) => i.ContentManagementSystemId == preAudit.ContentManagementSystemId) : null);
      this.showDudaSsoUrl = cms && cms.ContentManagementSystemId == Boo.Objects.Enums.ContentManagementSystems.Duda && CustomerHelper.getIsWebsiteManagedByPartner(keys);
    });
  }
}