import { Component, OnInit } from '@angular/core';
import System from 'framework/System';
import ITaskFilesPublicApi = app.components.interfaces.ITaskFilesPublicApi;
import ITaskFilesPublicApiCallback = app.components.interfaces.ITaskFilesPublicApiCallback;
import { OnsiteBloggingService } from '../../../../../services/onsite-blogging.service';
import { TaskPostService } from '../../../../../services/taskpost.service';
import Utils from '../../../../../shared/utils';

@Component({
  selector: 'app-components-specialist-actioncomponents-onsitebloggingimplementationreview',
  templateUrl: './OnsiteBloggingImplementationReview.component.html'
})
export class OnsiteBloggingImplementationReviewComponent implements OnInit, IActionWorkspace, IActivatable {
  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;
  taskFilesApi: ITaskFilesPublicApi;
  taskFilesApiCallback: ITaskFilesPublicApiCallback;
  onsiteBloggingImplementationSummary: Boo.Objects.OnsiteImplementation.OnsiteBloggingImplementationSummary;
  blogUrl: string;
  publishUser: string;
  imageUrl: string;
  pageTitle: string;
  newDescription: string;
  contentTitle: string;
  newH2: string;
  content: string;
  approvedToPublish: boolean;
  publishDate: string;
  publishUrl: string;

  constructor(
    private onsiteBloggingService: OnsiteBloggingService,
    private taskPostService: TaskPostService) {}

  ngOnInit(): void {
    this.onsiteBloggingService.getImplementationSummary(this.currentAction().Task.TaskId)
      .subscribe(summary => {
        this.onsiteBloggingImplementationSummary = summary;
      });

    this.publishUrl = this.currentAction().Task.TaskActionUrl;
    this.blogUrl = this.currentAction().Customer.SeoContentSurvey.BlogUrl;
    this.imageUrl = this.currentAction().TaskDetails.TaskPost.ImageUrl;
    this.publishUser = this.currentAction().Customer.SeoContentSurvey.PublishUser;
    this.contentTitle = this.currentAction().TaskDetails.TaskPost.ContentTitle;
    this.newH2 = this.currentAction().TaskDetails.OnsiteRecommendation ? this.currentAction().TaskDetails.OnsiteRecommendation.H2New : '';
    this.pageTitle = this.currentAction().TaskDetails.OnsiteRecommendation ? this.currentAction().TaskDetails.OnsiteRecommendation.TitleNew : '';
    this.newDescription = this.currentAction().TaskDetails.OnsiteRecommendation ? this.currentAction().TaskDetails.OnsiteRecommendation.DescriptionNew : '';
    this.content = this.currentAction().TaskDetails.TaskPost.Content;

    Utils.wrapDfd(this.taskPostService.getLastOnsiteBloggingPublishDate(this.currentAction().TaskDetails.TaskPost.CustomerId))
      .then((publishDate: Date) => {
          this.publishDate = moment().format('l');
          let lastPublishDate = moment(moment(publishDate).format('l'));
          if (publishDate
              && !launchpad.utils.isDefaultDate(publishDate)
              && lastPublishDate.add(5, 'd').isAfter(moment().format('l'))) {
              this.publishDate = lastPublishDate.add(5, 'd').format('l');
          }
      });

    this.taskFilesApiCallback = (api: ITaskFilesPublicApi) => { this.taskFilesApi = api; };
  }

  activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {
    params.controlViewModel(this);
    this.currentAction = params.currentAction;
    this.actionViewModel = ko.observable(null);
    return System.emptyPromise();
  }

  isStepReadyToReject(): boolean {
    return true;
  }

  isStepReadyToSave(): boolean {
    return this.currentAction().Task.TaskTypeSummary.IsPersistable;
  }

  isStepReadyToDelete(): boolean {
    return false;
  }

  isStepReadyToComplete(): boolean {
    return true;
  }
}
