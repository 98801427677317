import { KeywordSuggestionConfig } from "app/models/KeywordSuggestionConfig";
import { KeywordSuggestionConfigFactory } from "../../../../factories/KeywordSuggestionConfigFactory";
import { KeywordsComponent } from "../Keywords.component";
import { KeywordsHelper } from "./keywords-helper";
import { UniqueKeywordSuggestionValidator } from "../../../shared/Keywords/unique-keyword-suggestion-validator";
import { KeywordSuggestionService } from "../../../../services/keyword-suggestion.service";
import { Observable } from "rxjs";

export class StrategyUpdateKeywordsHelper implements KeywordsHelper {
  private keywordSuggestionConfigFactory: KeywordSuggestionConfigFactory = new KeywordSuggestionConfigFactory();

  constructor(private component: KeywordsComponent) { }

  getKeywordSuggestionConfig(): KeywordSuggestionConfig {
      return this.keywordSuggestionConfigFactory.createForStrategyUpdate(this.component.customer.SeoMinutesPaidRecurring(), this.component.orderedUrls.length);
  }

  loadAdditional(): Observable<any> {
      return this.component.loadStrategyUpdateData();
  }

  getHaloKeywordCount(): number {
      let count = this.component.summary.HaloKeywordSuggestions.filter(x => x.KeywordSuggestionStatusId !== Boo.Objects.Enums.KeywordSuggestionStatus.Rejected).length;
      count += this.component.newHaloKeywords.filter(x => x.KeywordSuggestionStatusId !== Boo.Objects.Enums.KeywordSuggestionStatus.Rejected).length;
      // Exclude keywords that are already on the first page since they do not count against the limit in keywords and urls
      return count + this.component.activeHaloKeywords.filter(x => !x.WasFirstPage).length;
  }

  createUniqueKeywordSuggestionValidator(): UniqueKeywordSuggestionValidator {
      let validator = new UniqueKeywordSuggestionValidator(this.component.summary.UrlSuggestions, this.component.summary.HaloKeywordSuggestions, () => this.component.customer.IsCampaignNational());
      validator.addHaloKeywords(this.component.activeHaloKeywords);
      validator.addWebsiteUrlKeywords(this.component.unorderedUrls.flatMap(x => x.Keywords));
      return validator;
  }

  requestRedo(keywordSuggestionService: KeywordSuggestionService): Observable<any> {
      return keywordSuggestionService.requestStrategyUpdateRedo({ CustomerId: this.component.customer.CustomerId(), TicketId: this.component.ticket.TicketId(), Note: this.component.redoNoteForm.value, UserId: this.component.user.UserId });
  }
}