import { AbstractControl, ValidatorFn } from '@angular/forms';
import CustomValidators from './custom-validators';

export default class LocalProfileValidators {

  static cannotContainPOBox(): ValidatorFn {
    return CustomValidators.patternNotAllowedValidator(/[p]\.?\s?[o]\.?\s?[b][o][x]/i, { 'containsPOBox': true });
  }

  static facebookPageValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      const value: string = control.value.toString();
      const facebookRegex = /(^profile\.php\?id=[0-9]+$)|(^group\.php\?gid=[0-9]+$)|(^groups\/[0-9]+[\/]?$)|(^[a-zA-Z0-9\-.]+[\/]?$)|(^pages\/[a-zA-Z0-9\-.]+\/[0-9]+[\/]?$)|(^people\/[a-zA-Z0-9\-.]+\/[0-9]+[\/]?$)/;

      if (!facebookRegex.test(value)) {
        return { 'facebookPage': { alternateMessage: 'Facebook must match one of the following patterns: profile.php?id=[numId], group.php?gid=[numId], groups/[numId], [Name], pages/[Name]/[numId], people/[Name]/[numId]' } };
      }

      return null;
    };
  }

  static instagramHandleValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      const value: string = control.value.toString();
      const instagramRegex = /^[a-zA-Z_0-9.]+$/;

      if (!instagramRegex.test(value)) {
        return { 'instagramHandle': { alternateMessage: 'Instagram handle can only contain letters (A-Z and a-z), numbers (0-9), underscores (_), and periods (.)' } };
      }

      return null;
    };
  }

  static twitterHandleValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      const value: string = control.value.toString();
      const twitterRegex = /^_*[a-zA-Z0-9_]+_*$/;

      if (!twitterRegex.test(value)) {
        return { 'twitterHandle': { alternateMessage: 'Twitter handle can only contain letters (A-Z and a-z), numbers (0-9), underscores (_), and may start or end with any number of underscores (_)' } };
      }

      return null;
    };
  }

  static businessHoursValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const businessHours = control.value
        .map((x: any) => {
          return {
            DayOfWeek: x.dayOfWeek,
            From1: x.from1,
            From2: x.from2,
            IsClosed: x.isClosed,
            IsOpen24Hours: x.isOpen24Hours,
            To1: x.to1,
            To2: x.to2
          };
        }) as Boo.Objects.LocalProfiles.LocalProfileBusinessHour[];

      if (businessHours.filter((x) => { return !x.IsClosed && !x.IsOpen24Hours && (x.From1 === '' || x.To1 === ''); }).length > 0) {
        return { 'invalid': { alternateMessage: 'All days must have hours specified' } };
      }

      if (businessHours.filter((x) => { return (x.From1 === '' && x.To1 !== '') || (x.From1 !== '' && x.To1 === '') || (x.From2 === '' && x.To2 !== '') || (x.From2 !== '' && x.To2 === ''); }).length > 0) {
        return { 'invalid': { alternateMessage: 'Hours cannot be partially complete' } };
      }

      if (businessHours.filter((x) => { return x.From2 !== '' && x.To2 !== '' && x.From1 === '' && x.To1 === ''; }).length > 0) {
        return { 'invalid': { alternateMessage: 'Hours cannot be partially complete' } };
      }

      if (businessHours.map(x => {
        return {
          From1: x.From1,
          To1: x.To1 === '00:00' ? '24:00' : x.To1,
          From2: x.From2,
          To2: x.To2 === '00:00' ? '24:00' : x.To2
        };
      }).filter((x) => {
        return x.From1 !== '' && x.To1 !== '' && (x.From2 === '' || x.To2 === '') && (x.From1 >= x.To1);
      }).length > 0) {
        return { 'invalid': { alternateMessage: 'Hours must be specified in order and cannot overlap' } };
      }


      if (businessHours.map(x => {
        return {
          From1: x.From1,
          To1: x.To1 === '00:00' ? '24:00' : x.To1,
          From2: x.From2,
          To2: x.To2 === '00:00' ? '24:00' : x.To2
        };
      }).filter((x) => {
        return x.From1 !== '' && x.To1 !== '' && x.From2 !== '' && x.To2 !== '' &&
          (x.From1 >= x.To1 ||
            x.From1 >= x.From2 ||
            x.From1 >= x.To2 ||
            x.To1 >= x.From2 ||
            x.To1 >= x.To2);
      }).length > 0) {
        return { 'invalid': { alternateMessage: 'Hours must be specified in order and cannot overlap' } };
      }

      return null;
    }
  }

  static specialHoursValidator(isProfileActive: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const specialHours = control.value
        .map((x: any) => {
          return {
            Date: x.date,
            Description: x.description,
            From1: x.from1,
            From2: x.from2,
            IsClosed: x.isClosed,
            IsOpen24Hours: x.isOpen24Hours,
            To1: x.to1,
            To2: x.to2
          };
        }) as Boo.Objects.LocalProfiles.LocalProfileSpecialHour[];

      if (specialHours.filter((x) => { return x.Description.length > 50; }).length > 0) {
        return { 'invalid': { alternateMessage: 'Special hours descriptions have a max length of 50' } };
      }

      if (isProfileActive) {

        if (specialHours.filter((x) => { return !x.Date; }).length > 0) {
          return { 'invalid': { alternateMessage: 'All special hours must have a date specified' } };
        }

        if (specialHours.some((x, index) => { return specialHours.some((y, yIndex) => y.Date === x.Date && yIndex !== index); })) {
          return { 'invalid': { alternateMessage: 'Each date can only be defined once' } };
        }

        if (specialHours.filter((x) => { return !x.IsClosed && !x.IsOpen24Hours && (x.From1 === '' || x.To1 === ''); }).length > 0) {
          return { 'invalid': { alternateMessage: 'All dates must have hours specified' } };
        }

        if (specialHours.filter((x) => { return (x.From1 === '' && x.To1 !== '') || (x.From1 !== '' && x.To1 === '') || (x.From2 === '' && x.To2 !== '') || (x.From2 !== '' && x.To2 === ''); }).length > 0) {
          return { 'invalid': { alternateMessage: 'Hours cannot be partially complete' } };
        }

        if (specialHours.filter((x) => { return x.From2 !== '' && x.To2 !== '' && x.From1 === '' && x.To1 === ''; }).length > 0) {
          return { 'invalid': { alternateMessage: 'Hours cannot be partially complete' } };
        }

        if (specialHours.map(x => {
          return {
            From1: x.From1,
            To1: x.To1 === '00:00' ? '24:00' : x.To1,
            From2: x.From2,
            To2: x.To2 === '00:00' ? '24:00' : x.To2
          };
        }).filter((x) => {
          return x.From1 !== '' && x.To1 !== '' && (x.From2 === '' || x.To2 === '') && x.From1 >= x.To1;
        }).length > 0) {
          return { 'invalid': { alternateMessage: 'Hours must be specified in order and cannot overlap' } };
        }

        if (specialHours.map(x => {
          return {
            From1: x.From1,
            To1: x.To1 === '00:00' ? '24:00' : x.To1,
            From2: x.From2,
            To2: x.To2 === '00:00' ? '24:00' : x.To2
          };
        }).filter((x) => {
          return x.From1 !== '' && x.To1 !== '' && x.From2 !== '' && x.To2 !== '' &&
            (x.From1 >= x.To1 ||
              x.From1 >= x.From2 ||
              x.From1 >= x.To2 ||
              x.To1 >= x.From2 ||
              x.To1 >= x.To2);
        }).length > 0) {
          return { 'invalid': { alternateMessage: 'Hours must be specified in order and cannot overlap' } };
        }
      }
      return null;
    }
  }
}
