/* tslint:disable */

export default class customernote {
    public ContactTypeId = 0
    public ContactTypeName = <any>null
    public CustomerId = 0
    public CustomerNoteId = 0
    public Duration = 0
    public FollowupDate = <any>"0001-01-01T00:00:00"
    public InsertedDate = <any>"0001-01-01T00:00:00"
    public PartnerUserId = 0
    public Text = <any>null
    public TicketId = 0
    public TicketTypeName = <any>null
    public UserFullName = <any>null
    public UserId = 0
    public IsSystemGenerated = false
}