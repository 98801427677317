<div [loadingOverlay]="isLoading()">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4>Instructions</h4>
        </div>
        <div class="card-body">
          <div [hidden]="!hasComments" class="form-group">
            <div class="alert alert-warning">
              <ng-container *ngIf="lastNote()">
                <div class="row">
                  <div class="col-md-8">
                    <div>
                      <strong>{{lastNote().FromUserFullName}}</strong>
                    </div>
                    <small>
                      to
                      <span>{{lastNote().ToUserFullName}}</span>
                    </small>
                  </div>
                  <div class="col-md-4 text-end">
                    <small>{{lastNote().InsertedDate | dateText }}</small>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="list-group-item-text">
                      <div style="padding-top:15px; padding-bottom:15px;">{{lastNote().Note}}</div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="form-group">
            <div *ngIf="isTaskImported" class="alert alert-warning">
              <strong>This task was imported into the system and cannot be deleted. Please fix the content and complete
                the task.</strong>
            </div>
            <label class="fw-bold">URL</label>
            <div class="form-control" disabled="">
              <a [externalLink]="{ url: url(), text: url() }" rel="noreferrer" style="cursor: pointer;"></a>
            </div>
          </div>
          <div class="form-group">
            <label class="fw-bold">Page Type</label>
            <div>{{pageType()}}</div>
          </div>
          <div class="form-group">
            <label class="fw-bold">Recommended Word Count</label>
            <div>{{recommendedWordCount}}</div>
          </div>
          <div class="form-group">
            <div class="form-group">
              <label class="fw-bold" [hidden]="!(orderReason() !== 'New Page' && orderReason() !== 'Existing Page')">Reason for ordering
                the copy</label>
              <label class="fw-bold" [hidden]="!(orderReason() === 'New Page' || orderReason() === 'Existing Page')">Where will this
                copy be published?</label>
              <div>{{orderReason()}}</div>
            </div>
          </div>
          <div *ngIf="isStandard" class="form-group">
            <label class="fw-bold">Task Instructions</label>
            <div name="instructions">{{taskInstructions}}</div>
          </div>
          <div class="form-group">
            <label class="fw-bold">Content Writer Instructions</label>
            <div>{{contentWriterInstructions()}}</div>
          </div>
          <div class="form-group">
            <label class="fw-bold">Will Boostability implement this piece of copy?</label>
            <div>{{boostToPublish ? 'Yes' : 'No'}}</div>
          </div>
          <div class="form-group">
            <label class="fw-bold">Notes for Implementation Specialist</label>
            <div>{{implementationNotes}}</div>
          </div>
          <div class="form-group">
            <label class="fw-bold">Page Strategy</label>
            <textarea class="form-control" [disabled]="true">{{pageStrategy()}}</textarea>
          </div>
          <div [dataValidation]="{ value: content, placement: 'top' }" class="form-group">
            <textarea [richText]="{ content: content, wordCount: wordCount, autoGrow: true }" id="ckEditor"></textarea>
          </div>
          <div class="form-group">
            <label class="fw-bold">Writer Feedback</label>
            <textarea [dataValidation]="{ placement: 'top', value: writerFeedback }" [ngModel]="writerFeedback()"
              (ngModelChange)="writerFeedback($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
              class="form-control" name="writerFeedback" maxlength="4000" style="height: 75px;"></textarea>
            <span class="help-block">Maximum Length is 4000 Characters</span>
          </div>
          <div class="form-group">
            <label class="fw-bold">Note to QA</label>
            <input [(ngModel)]="noteToQa" type="text" class="form-control" name="noteToQa" autocomplete="off" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-components-specialist-actioncomponents-changed-keywords [websiteUrl]="currentAction().TaskDetails.WebsiteUrl"
        [since]="currentAction().Task.InsertedDate"></app-components-specialist-actioncomponents-changed-keywords>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <compose Selector="app-components-specialist-actioncomponents-components-onsiterecommendation" [ActivateOptions]="{
                            apiCallback: onsiteRecommendationApiCallback.bind(this),
                            onsiteRecommendation: currentAction().TaskDetails.OnsiteRecommendation,
                            websiteUrlId: currentAction().Task.WebsiteUrlId,
                            googleSearchUrl: currentAction().Customer.Country.GoogleSearchUrl,
                            isReadOnly: false,
                            isValidated: true
                        }"></compose>
    </div>
  </div>
  <app-floating-panels>
    <app-floating-panel [displayConfig]="{ title: 'Information', icon: 'fa-info-circle', size: 'floating-panel-md' }">
      <compose *floatingPanelContent Selector="app-components-specialist-actioncomponents-information"
        [ActivateOptions]="{
				customer: customer(),
				taskDetails: this.currentAction().TaskDetails
			}">
      </compose>
    </app-floating-panel>
    <app-floating-panel
      [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
      <app-components-specialist-actioncomponents-comments *floatingPanelContent
        [comments]="currentAction().TaskDetails.TaskNotes">
      </app-components-specialist-actioncomponents-comments>
    </app-floating-panel>
    <app-floating-panel [displayConfig]="{
      title: 'Files',
      icon: 'fa-file',
      size: 'floating-panel-md',
      badgeCount: currentAction().TaskDetails.Files ? currentAction().TaskDetails.Files.length : 0 }">
      <app-components-specialist-actioncomponents-files *floatingPanelContent
        [files]="this.currentAction().TaskDetails.Files">
      </app-components-specialist-actioncomponents-files>
    </app-floating-panel>
  </app-floating-panels>
</div>