import { Component } from '@angular/core';
import CustomerAccountBase from '../customer-account-base';
import { SessionStorageService } from '../../../../services/session-storage.service';

@Component({
	selector: 'app-components-customeraccount-credentials',
	templateUrl: './credentials.component.html'
})
export class CredentialsComponent extends CustomerAccountBase {

  constructor(sessionStorageService: SessionStorageService) {
    super(sessionStorageService);
  }
}
