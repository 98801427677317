import { Component } from '@angular/core';
import { CustomerService } from '../../../services/customer.service';
import { StoreService } from '../../../services/store.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-sales-searchcustomers',
  templateUrl: './SearchCustomers.component.html'
})
export class SearchCustomersComponent {
  isLoading: boolean = false;
  searchString: string = '';
  customers: Boo.Objects.CustomerSearchResult[] = [];

  constructor(
    private customerService: CustomerService,
    private storeService: StoreService) {
  }

  searchCustomers(): void {
    this.isLoading = true;

    const searchString = this.searchString;
    const filterByPartnerId = 0; // All Partners
    const page = 1;
    const pageSize = 1000;
    const languageId = 0; // All Languages
    const liveAccountsOnly = true;
    const isMyCustomersSearch = false;
    const searchAllCustomerStatuses = true;

    Utils.wrapDfd(this.customerService.search(searchString, filterByPartnerId, page, pageSize, languageId, liveAccountsOnly, isMyCustomersSearch, searchAllCustomerStatuses))
      .then((customers: Boo.Objects.CustomerSearchResult[]) => {
        this.customers = customers;
      })
      .fail(err => toastr.error(err))
      .always(() => this.isLoading = false);
  }

  addProducts(customer: Boo.Objects.CustomerSearchResult): void {
    Utils.wrapDfd(this.storeService.sso(customer.CustomerId, `/place-order`, `#/sales/searchcustomers`))
      .then(x => window.location.href = x)
      .fail(err => toastr.error(err));
  }
}
