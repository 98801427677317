import { Component } from '@angular/core';
import System from 'framework/System';

@Component({
	selector: 'app-components-optiontoggle',
	templateUrl: './OptionToggle.component.html'
})
export class OptionToggleComponent implements IActivatable {
    public title: string;
    public left: string;
    public right: string;
    public value: KnockoutObservable<string>;

    public activate(params: any): JQueryPromise<void> {
        this.title = params.title;
        this.left = params.left;
        this.right = params.right;
        this.value = params.value;
        return System.emptyPromise();
    }

    public toggle(value: string): void {
        this.value(value);
    }
}
