import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SessionStorageService } from '../../../../../services/session-storage.service';
import { CustomerService } from '../../../../../services/customer.service';
import TaskHelper from 'app/specialist/TaskHelper';
import { Subject, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CustomerNoteService } from '../../../../../services/customer-note.service';
import { QaGradingArea } from '../../../../qa/qa-grading-area/qa-grading-area.component';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { DeletableWorkspace } from 'app/interfaces/deletable-workspace';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';
import { InternalActionToken } from '../../internal-action.token';
import { CustomerCampaignService } from '../../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-specialist-internal-tasks-keyword-research-strategy-update-redo-qa',
  templateUrl: './keyword-research-strategy-update-redo-qa.component.html'
})
export class KeywordResearchStrategyUpdateRedoQaComponent implements OnInit, UnlockableWorkspace, DeletableWorkspace, ClosableWorkspace {
  showCompleteButton: boolean = false;
  showUnlockButton: boolean = true;
  showCloseButton: boolean = true;
  complete = new Subject<void>();
  unlock = new Subject<void>();
  close = new Subject<void>();

  isLoading: boolean;
  customer: Boo.Objects.Customer;
  vertical: Boo.Objects.Vertical;
  country: Boo.Objects.Country;
  isMoneyBackGuarantee: boolean;
  customDescription: string;
  associatedTask: Boo.Objects.Task;
  keywordResearchNote: string = '';
  textFilter: string = '';
  dataTable: app.components.interfaces.DataTablePublicApi;
  dataTableOptions: app.components.interfaces.DataTableOptions;
  suggestedHaloKeywords: Boo.Objects.QaKeywordSuggestion[];
  suggestedUrls: Boo.Objects.QaUrlSuggestion[];
  keywordSuggestionsTypes: Boo.Objects.KeywordSuggestionType[];
  unorderedWebsiteUrlIds: number[];
  unorderedUrls: Boo.Objects.WebsiteUrl[];
  activeHaloKeywords: string[] = [];
  taskType: Boo.Objects.Enums.TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaKeywordResearchStrategyUpdateRedo;
  qaCriteriaGroups: Boo.Qa.QaCriteriaGroup[] = [];

  @ViewChild('noteGridTemplate') noteGridTemplate: TemplateRef<any>;
  @ViewChild('gradingArea') gradingArea: QaGradingArea;

  constructor(
    @Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction,
    private sessionStorageService: SessionStorageService,
    private customerService: CustomerService,
    private customerNoteService: CustomerNoteService,
    private customerCampaignService: CustomerCampaignService
  ) { }

  ngOnInit(): void {
    (<any>$('#internal-task-qa-criteria-float-panel')).floatPanel({ collapsed: false });
    this.isLoading = true;
    this.associatedTask = this.internalAction.InternalTaskDetails.AssociatedTask;
    this.customDescription = TaskHelper.getCustomDescription(this.associatedTask);
    var details = this.internalAction.InternalTaskDetails;
    this.keywordResearchNote = details.KeywordResearchNote;
    this.suggestedHaloKeywords = details.KeywordSuggestionSummary.HaloKeywordSuggestions;
    this.suggestedUrls = details.KeywordSuggestionSummary.UrlSuggestions;
    this.unorderedWebsiteUrlIds = details.MainKeywordPhrases.map(x => x.WebsiteUrlId);
    this.activeHaloKeywords = details.TrackingKeywordPhrases;
    this.qaCriteriaGroups = details.QaCriteriaGroups;

    forkJoin([
      this.sessionStorageService.getStaticData(),
      this.customerService.get(this.internalAction.InternalTaskDetails.AssociatedTaskCustomer.CustomerId),
      this.customerCampaignService.getWebsiteUrls(this.internalAction.InternalTaskDetails.AssociatedTaskCustomer.CustomerId, true) // excludeInactiveKeywords: true
    ])
      .pipe(finalize(() => {
        this.configureNoteDataTable();
        this.configureKeywordSuggestionTypes();
        this.isLoading = false;
      }))
      .subscribe(([staticData, customer, allUrls]): void => {
        this.customer = customer;
        this.country = staticData.Countries.find(x => x.CountryId === this.customer.CountryId);
        this.vertical = staticData.Verticals.find(x => x.VerticalId === this.customer.VerticalId);
        this.keywordSuggestionsTypes = staticData.KeywordSuggestionTypes;
        this.unorderedUrls = allUrls.filter(x => this.unorderedWebsiteUrlIds.includes(x.WebsiteUrlId));
      });
  }

  isReadyToComplete() {
    if (!this.gradingArea.validate()) {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return false;
    }

    this.internalAction.InternalTaskDetails.QaCriteriaDetails = this.gradingArea.getDetails();
    this.internalAction.InternalTaskDetails.QaGrade.Comment = this.gradingArea.gradeComment;
    return true;
  }

  isReadyToDelete(): boolean {
    return true;
  }

  configureKeywordSuggestionTypes(): void {
    this.suggestedUrls.forEach(url => {
      url.QaKeywordSuggestions.forEach(keyword => {
        keyword.KeywordSuggestionType = this.keywordSuggestionsTypes.find(x => x.KeywordSuggestionTypeId === keyword.KeywordSuggestionTypeId);
      });
    });
    let haloType = this.keywordSuggestionsTypes.find(x => x.KeywordSuggestionTypeId === Boo.Objects.Enums.KeywordSuggestionTypes.Halo);
    this.suggestedHaloKeywords.forEach(keyword => {
      keyword.KeywordSuggestionType = haloType;
    });
  }

  //customer note table methods
  search(): void {
    this.dataTable.reload();
  }

  clear(): void {
    this.textFilter = '';
    this.dataTable.reload();
  }

  private configureNoteDataTable(): void {
    this.dataTableOptions = {
      loader: (page, pageSize, sortOrder): JQueryPromise<framework.data.IPageResult<Boo.Objects.CustomerNote>> => {
        if (!sortOrder) {
          sortOrder = 'InsertedDate desc';
        }

        let filters = [`ContactTypeId ne ${Boo.Objects.Enums.ContactTypeEnum.Sales}`];

        let term = this.textFilter.trim();

        if (term) {
          filters.push(`contains(Text, '${term}')`);
        }

        let query = this.customerNoteService.get(this.customer.CustomerId)
          .orderBy(sortOrder)
          .page(page, pageSize)
          .filter(filters.join(' and '));

        return query.load();
      },
      columns: [
        { id: 'inserted_date', value: 'InsertedDate', name: 'Date' },
        { id: 'note', value: 'Text', name: 'Note', canSort: false },
      ],
      pageSize: 5,
      rowTemplate: this.noteGridTemplate,
      apiCallback: (api): void => {
        this.dataTable = api;
      }
    }
  }
}
