import { trigger, style, state, transition, animate, keyframes } from '@angular/animations';

export const fadeIn = trigger('fadeIn', [
    state('void', style({ opacity: 0 })),
    state('*', style({ opacity: 1 })),
    transition(':enter', animate(10000))
]);

export const smoothSize = trigger('smoothSize', [
    state('void', style({ height: 0, opacity: 0 })),
    state('*', style({ height: '*', opacity: 1 })),
    transition('* <=> *', animate(150)),
]);

export const wobbleIf = trigger('wobbleIf', [
    state('void', style({ opacity: 1 })),
    state('false', style({ opacity: 1 })),
    state('true',
        style({ opacity: 1 }),
        { params: { timing: 1, delay: 0 } }
    ),
    transition('void => true, false => true',
        animate('{{ timing }}s {{ delay }}s',
            keyframes([
                style({ transform: 'none', offset: 0 }),
                style({
                    transform: 'translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)',
                    offset: 0.15,
                }),
                style({
                    transform: 'translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)',
                    offset: 0.3,
                }),
                style({
                    transform: 'translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)',
                    offset: 0.45,
                }),
                style({
                    transform: 'translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)',
                    offset: 0.6,
                }),
                style({
                    transform: 'translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)',
                    offset: 0.75,
                }),
                style({ transform: 'none', offset: 1 })
            ]))
    )
]);

export const shakeIf = trigger('shakeIf', [
    transition('void => true, false => true',
        animate('1s ease-in',
            keyframes([
                style({ transform: 'translate3d(-1px, 0, 0)', offset: 0.1 }),
                style({ transform: 'translate3d(2px, 0, 0)', offset: 0.2 }),
                style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.3 }),
                style({ transform: 'translate3d(4px, 0, 0)', offset: 0.4 }),
                style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.5 }),
                style({ transform: 'translate3d(4px, 0, 0)', offset: 0.6 }),
                style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.7 }),
                style({ transform: 'translate3d(2px, 0, 0)', offset: 0.8 }),
                style({ transform: 'translate3d(-1px, 0, 0)', offset: 0.9 })
            ])
        )
    )
]);
