import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'shared-components-customtoggle',
  templateUrl: './customToggle.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomToggleComponent
    }
  ]
})
export class CustomToggleComponent implements ControlValueAccessor {
  @Input() value: boolean;
  @Input() disabled?: boolean;
  @Input() label?: string;

  private onChange = (_value: boolean) => {};
  private onTouched = () => {};

  toggleValue(): void {
    if (!this.disabled) {
      this.onTouched();
      this.value = !this.value;
      this.onChange(this.value);
    }
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
