<div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-2">Jobs</div>
            <div class="col-md-2 offset-md-4">
              <button (click)="addJob()" style="margin-left:10px;" class="btn btn-success float-end">
                <i class="fa fa-plus"></i>
                Add New Job
              </button>
            </div>
            <div class="col-md-3">
              <div class="input-group">
                <input [ngModel]="filterText()" (ngModelChange)="filterText($event)"
                  [ngModelOptions]="{ standalone: true }" type="text" class="form-control"
                  placeholder="Search by Job ID or Hangfire ID" />
                <div class="input-group-text"><i class="fa fa-search"></i></div>
              </div>
            </div>
            <div class="col-md-1">
              <ng-container *ngIf="jobListPublicApi()">
                <button (click)="refreshJobs()" class="btn btn-primary btn-sm float-end">
                  <i class="fas fa-sync"></i>
                  Refresh
                </button>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="card-body">
          <compose Selector="app-components-admin-joblist" [ActivateOptions]="jobsActivationParams()"></compose>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          <span>Job Status History</span>
          <button (click)="loadHistoryChart()" class="btn btn-primary btn-sm float-end">
            <i class="fas fa-sync"></i>
            Refresh
          </button>
        </div>
        <div [loadingOverlay]="isLoading()" class="card-body">
          <div class="col-md-12">
            <ng-container *ngIf="chart()">
              <div class="chart"><shared-components-chart [Settings]="chart()"></shared-components-chart></div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card">
        <div class="card-header">
          <span>Current Jobs</span>
          <button (click)="deleteJobs()" style="margin-left:10px;" class="btn btn-danger btn-sm">
            <i class="fas fa-times"></i>
            Delete Jobs
          </button>
          <button (click)="requeueJobs()" style="margin-left:10px;" class="btn btn-primary btn-sm">
            <i class="fas fa-sync"></i>
            Re-enqueue Jobs
          </button>
          <button (click)="loadCurrentJobs()" class="btn btn-primary btn-sm float-end">
            <i class="fas fa-sync"></i>
            Refresh
          </button>
        </div>
        <div [loadingOverlay]="isLoading()" class="card-body">
          <div class="row">
            <div class="col-md-12">
              <app-table [items]="jobTypeSummaries()" [pageSize]="100">
                <ng-template #tableHeader>
                  <tr>
                    <th sort field="Name">Job Type</th>
                    <th sort field="PendingCount">Pending</th>
                    <th sort field="CompletedCount">Completed</th>
                    <th sort field="FailedCount">Failed</th>
                    <th sort field="ProcessingCount">Processing</th>
                    <th sort field="IsActiveObservable">Is Active</th>
                  </tr>
                </ng-template>
                <ng-template #tableBody let-jobType>
                  <tr>
                    <td>
                      <button (click)="openJobType(jobType)" class="btn btn-primary">
                        <i class="fas fa-external-link-square-alt"></i>
                        <span>{{jobType.Name}}</span>
                      </button>
                    </td>
                    <td>{{jobType.PendingCount}}</td>
                    <td>{{jobType.CompletedCount}}</td>
                    <td>{{jobType.FailedCount}}</td>
                    <td>{{jobType.ProcessingCount}}</td>
                    <td>
                      <div class="btn-group" style="min-width: 150px;">
                        <button (click)="changeJobTypeStatus(jobType);"
                          [class.active]="jobType.IsActiveObservable() === true" type="button" style="min-width: 70px;"
                          class="btn btn-outline-secondary active">
                          <i [class.fa]="jobType.IsActiveObservable() === true"
                            [class.fa-check]="jobType.IsActiveObservable() === true" class="fa fa-check"></i>
                          Yes
                        </button>
                        <button (click)="changeJobTypeStatus(jobType);"
                          [class.active]="jobType.IsActiveObservable() === false" type="button" style="min-width: 70px;"
                          class="btn btn-outline-secondary">
                          <i [class.fa]="jobType.IsActiveObservable() === false"
                            [class.fa-check]="jobType.IsActiveObservable() === false" class=""></i>
                          No
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </app-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>