import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import FloatingTabPanelIcon = app.components.enums.FloatingTabPanelIcon;
import IFloatingTabPanelPublicApi = app.components.interfaces.IFloatingTabPanelPublicApi;

@Component({
	selector: 'app-components-ticket-ticketnavigationfloatingpanel',
	templateUrl: './TicketNavigationFloatingPanel.component.html'
})
export class TicketNavigationFloatingPanelComponent extends BaseComponent {
    protected floatPanel: IJQueryFloatPanel;
    private floatingTabPanelApi: KnockoutObservable<IFloatingTabPanelPublicApi>;
    private ticketParams: app.ticket.components.interfaces.ITicketNavigationViewModelActivateParams;

    public activate(params: app.ticket.components.interfaces.ITicketNavigationViewModelActivateParams): JQueryPromise<void> {
        return super.activate(params).then(() => {
            this.ticketParams = params;
            this.floatingTabPanelApi = params.floatingTabPanelApi;

            if (this.floatingTabPanelApi().addPanel) {
                this.addPanel();
            }

            params.floatingTabPanelApi.subscribe(() => {
                this.addPanel();
            });
        });
    }

    public addPanel(): void {
        this.floatingTabPanelApi().addPanel({
            title: 'Ticket Navigation',
            titleIcon: FloatingTabPanelIcon.TicketNavigation,
            isScrollable: false,
            composition: {
                selector: 'app-components-ticket-ticketnavigation',
                options: this.ticketParams
            }
        });
    }
}
