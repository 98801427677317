export default class User implements Boo.Objects.User {
    public FullName: string;
    public InsertedDate: Date;
    public UserId: number;
    public Username: string;
    public Password: string;
    public SetPassword: string;
    public FirstName: string;
    public LastName: string;
    public PhoneExtension: string;
    public IsActive: boolean;
    public AccessToken: string;
    public CustomerUsers: Boo.Objects.CustomerUser[];
    public PartnerUsers: Boo.Objects.PartnerUser[];
    public Customers: Boo.Objects.Customer[];
    public Languages: Boo.Objects.UserLanguage[];
    public Partners: Boo.Objects.Partner[];
    public UserPermissions: any[];
    public BusinessEntityId: number;
    public EmployeeCode: string;
}
