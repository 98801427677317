<div class="card">
  <div class="card-header">
    Locked Tasks
  </div>
  <div [loadingOverlay]="isLoading()" class="card-body">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="customerId" class="control-label">Customer ID (optional)</label>
          <input [ngModel]="customerId()" (ngModelChange)="customerId($event)"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [returnKey]="reloadLockedActions.bind(this)"
            type="text" name="customerId" class="form-control" placeholder="Enter Customer ID" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Filter Assigned User</label>
          <select select2 [ngModel]="assignedUserFilter()" (ngModelChange)="assignedUserFilter($event)"
            [ngModelOptions]="{ standalone: true }" class="form-control">
            <option [ngValue]="undefined">{{assignedUserFilterCaption}}</option>
            <option *ngFor="let option of assignedUsersOptions()" [ngValue]="option">{{ option }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Filter Task Type</label>
          <select [ngModel]="actionTitleFilter()" (ngModelChange)="actionTitleFilter($event)"
            [ngModelOptions]="{ standalone: true }" class="form-select">
            <option [ngValue]="undefined">{{actionTitleFilterCaption}}</option>
            <option *ngFor="let option of actionTitleOptions()" [ngValue]="option">{{ option }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label d-block">&nbsp;</label>
          <button [disabled]="isLoading()" (click)="reloadLockedActions()" class="btn btn-primary">
            <i class="fa fa-search"></i>
            Search
          </button>
          <button [disabled]="!searchTextExists()" (click)="clearSearch()" type="submit" class="btn btn-link">Clear
            Search</button>
        </div>
      </div>
    </div>
    <hr />
    <div>
      <app-table [items]="lockedActions" [exportFilename]="'locked-actions'">
        <ng-template #tableHeader>
          <tr>
            <th export sort field="CustomerId">Customer ID</th>
            <th export sort field="CustomerName">Customer Name</th>
            <th export sort field="DaysTilRenewal">Days Left</th>
            <th export sort field="TaskTypeTitle">Task Type</th>
            <th export sort field="AssignedUser">Assigned User</th>
            <th export sort field="PartnerName">Partner</th>
            <th>Action</th>
          </tr>
        </ng-template>
        <ng-template #tableBody let-action>
          <tr>
            <td>
              <button (click)="openCustomer(action)" class="btn btn-primary">
                <i class="fas fa-external-link-square-alt"></i>
                <span>{{action.CustomerId}}</span>
              </button>
            </td>
            <td>{{action.CustomerName}}</td>
            <td>{{action.DaysTilRenewal}}</td>
            <td>{{action.TaskTypeTitle}}</td>
            <td>{{action.AssignedUser}}</td>
            <td>{{action.PartnerName}}</td>
            <td>
              <button [hidden]="!(action.FulfillmentMethodId === 1 || action.FulfillmentMethodId === 2)"
                [disabled]="isLoading()" (click)="UnlockCustomer(action)" type="button" class="btn btn-success">
                <i class="fa fa-unlock"></i>
                Unlock
              </button>
              <app-components-helptext
                [hidden]="!(action.FulfillmentMethodId === 3 || action.FulfillmentMethodId === 4)"
                [key]="'LockedActions_ExternalFulfillmentMethod'"></app-components-helptext>
            </td>
          </tr>
        </ng-template>
      </app-table>
    </div>
  </div>
</div>