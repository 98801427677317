import { Component, Input, OnInit } from '@angular/core';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { ReportingSiteService } from '../../../services/reporting-site.service';
import Utils from '../../../shared/utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-components-manager-reportingcustomizations',
  templateUrl: './ReportingCustomizations.component.html'
})
export class ReportingCustomizationsComponent implements OnInit {
    @Input() public partnerId: number;
    public reportingSite: ReportingSiteObservable;
    public isLoading: boolean;
    private validation: KnockoutObservable<any> = ko.validatedObservable(undefined);

    constructor(private reportingSiteService: ReportingSiteService) { }

    public ngOnInit(): void {
        this.isLoading = true;
        this.reportingSiteService.get(this.partnerId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(
                (reportingSite: Boo.Objects.ReportingSite) => {
                    this.reportingSite = ko.mapping.fromJS(reportingSite);
                    this.setupValidation();
                },
                (err: string) => toastr.error(err)
            );
    }

    public setupValidation(): void {
        let colors = this.reportingSite.ChartColors().map(x => {
            x.Color.extend({
                required: { message: 'Chart color is required.' },
                validation: [{
                    validator: (val: string): any => {
                        return val.indexOf('rgba') >= 0;
                    },
                    message: 'Chart color must be rgba'
                }]
            });
            return x;
        });
        this.validation = ko.validatedObservable(colors);
    }

    public uploadFile(): void {
        let request = new PresignedUrlRequest();
        request.BucketName = launchpad.config.S3Buckets.s3staticfiles;
        request.Folder = 'loadingicons/' + this.partnerId;
        request.UseUniqueFileName = true;

        PriorityViewModel.show('app-components-shared-uploadfile', { preSignedURLRequest: request })

            .then((fileData: any) => {
                this.reportingSite.LoadingIconUrl(fileData.FullUrl);
                this.save();
            });
    }

    public save(): JQueryPromise<void> {
        if (!this.validation.isValid()) {
            this.validation.errors.showAllMessages();
            return;
        }

        return Utils.wrapDfd(this.reportingSiteService.save(ko.mapping.toJS(this.reportingSite)))
            .then((reportingSite: Boo.Objects.ReportingSite) => {
                this.reportingSite.ReportingSiteId(reportingSite.ReportingSiteId);
                this.reportingSite.ChartColors(ko.mapping.fromJS(reportingSite).ChartColors());
                this.setupValidation();
                toastr.success('Reporting customizations saved.');
            })
            .fail((displayMessage: string) => {
                toastr.error(displayMessage);
            });
    }
}

interface IReportingCustomizationsViewModelActivateParams {
    partnerId: number;
}
