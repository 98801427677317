<div>
	<compose Selector="app-components-websites-websitedomain" [ActivateOptions]="componentParams"></compose>
	<div class="card">
		<div class="card-header">
			Website
			<span class="badge bg-secondary" style="margin: 0 6px;">{{website.WebsiteAccount.HostName()}}</span>
		</div>
		<div *ngIf="hasHadAnyWebsiteProduct" class="card-body">
			<app-tab-panel [displayMode]="tabPanelDisplayMode.horizontal">
				<ng-container *ngIf="areBoostSiteTabsVisible">
					<app-dynamic-tab [title]="'Details'">
						<compose *tabContent Selector="app-components-websites-websitedetails" [ActivateOptions]="componentParams"></compose>
					</app-dynamic-tab>
					<app-dynamic-tab [title]="'Site Information'">
						<compose *tabContent Selector="app-components-websites-siteinformation" [ActivateOptions]="componentParams"></compose>
					</app-dynamic-tab>
					<app-dynamic-tab [title]="'Summary'">
						<compose *tabContent Selector="app-components-websites-websitereporting" [ActivateOptions]="componentParams"></compose>
					</app-dynamic-tab>
				</ng-container>
				<app-dynamic-tab *ngIf="isAdjustmentTabVisible" [title]="'Adjustments'">
					<compose *tabContent Selector="app-components-websites-websiteadjustments" [ActivateOptions]="componentParams"></compose>
				</app-dynamic-tab>
				<app-dynamic-tab *ngIf="isCustomWebsiteReportingVisible" [title]="'Wordpress'">
					<compose *tabContent Selector="app-components-managecustomer-wordpresswebsitereports" [ActivateOptions]="{
						customer: customer
					}"></compose>
				</app-dynamic-tab>
				<app-dynamic-tab [title]="'Vendor Notifications'">
					<app-components-websites-vendornotifications
						*tabContent Selector="app-components-websites-vendornotifications"
						[customerId]="customer.CustomerId"
						[hasHadCustomWeb]="isCustomWebsiteReportingVisible"
						[hasHadBoostSite]="areBoostSiteTabsVisible">
					</app-components-websites-vendornotifications>
				</app-dynamic-tab>
			</app-tab-panel>
		</div>
		<div *ngIf="!hasHadAnyWebsiteProduct" class="card-body">
			Customer has not ordered a website product for website: {{website.Domain()}}
		</div>
	</div>
</div>
