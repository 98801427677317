<div class="card">
	<div class="card-header">
		Reset Customer Website
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body">
		<div class="col-md-12">
			<div class="form-horizontal">
				<div class="alert alert-danger">
					<button type="button" class="btn-close" title="Close" data-dismiss="alert"></button>
					<h4>Warning!</h4>
					<p>
						Please exercise extreme caution while using this tool.
						Resetting the website also clears all URLs and keywords
						configured for SEO.
					</p>
				</div>
				<div class="form-group">
					<label class="col-md-4 control-label" for="customerId">Customer Id</label>
					<div class="col-md-8">
						<input [dataValidation]="{ placement: 'top', value: customerId }" [ngModel]="customerId()" (ngModelChange)="customerId($event)" [ngModelOptions]="{ standalone: true }" [disabled]="verified()" [readonly]="verified()" [returnKey]="verify.bind(this)" class="form-control" type="text" placeholder="Customer Id"/>
					</div>
				</div>
				<div class="form-group">
					<div class="col-md-8 offset-md-4">
						<button [disabled]="isLoading()" (click)="verify()" class="btn btn-primary">
							<i class="fa fa-check"></i>
							Verify Customer
						</button>
					</div>
				</div>
				<div [hidden]="!verified()">
					<div class="form-group">
						<label class="control-label col-md-4" for="currentWebsite">Current Website</label>
						<div class="col-md-8">
							<p class="form-control-static">{{currentWebsite()}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="control-label col-md-4" for="newWebsite">New Website</label>
						<div class="col-md-8">
							<input [dataValidation]="{ placement: 'top', value: newWebsite }" [ngModel]="newWebsite()" (ngModelChange)="newWebsite($event)" [ngModelOptions]="{ standalone: true }" [returnKey]="resetWebsite.bind(this)" class="form-control" type="text" id="newWebsite" placeholder="New Website"/>
						</div>
					</div>
					<div class="card card-body bg-light d-block text-end">
						<button (click)="cancel()" class="btn btn-link">Cancel</button>
						<button [disabled]="isLoading()" (click)="resetWebsite()" class="btn btn-success">
							<i class="fas fa-redo"></i>
							Reset Website
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
