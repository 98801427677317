<div class="card">
  <div class="card-header">
    Local Profiles
  </div>
  <div class="card-body" style="min-height: 700px">
    <app-components-shared-local-profiles
      [customer]="customer"
      [canAddLocalProfile]="true"
      [showSubscriptions]="true"
      [allowValidationAndAugmentationOrdering]="true"
      [createValidationAndAugmentationForNewLocalProfiles]="true">
    </app-components-shared-local-profiles>
  </div>
</div>