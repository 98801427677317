<ng-container *ngFor="let tag of tags">
  <div class="mt-2">
    <ng-container *ngIf="tag.type === types.text">
      <label class="fw-bold">{{tag.key}}</label>
      <input class="form-control" type="text" [(ngModel)]="tag.value">
    </ng-container>
    <ng-container *ngIf="tag.type !== types.text">
      <label class="fw-bold">{{tag.key}}</label>
      <button class="btn btn-info float-end" (click)="clearValue(tag)">
        <i class="fa fa-trash"></i>
        {{ tag.type === types.boolean ? 'Mark False' : 'Clear Values' }}
      </button>
      <ng-container *ngIf="tag.type === types.implicitArray">
        <button class="btn btn-info float-end me-2" (click)="addImplicitValue(tag)">
          <i class="fa fa-plus"></i>
          Add Implicit Iterator Value
        </button>
      </ng-container>
      <ng-container *ngIf="tag.type === types.array">
        <button class="btn btn-info float-end me-2" (click)="addSubkeys(tag)">
          <i class="fa fa-plus"></i>
          Add Iterator Values
        </button>
      </ng-container>
      <ng-container *ngIf="tag.type === types.boolean">
        <button class="btn btn-info float-end me-2" (click)="markTrue(tag)">
          <i class="fa fa-plus"></i>
          Mark True
        </button>
      </ng-container>
      <ng-container *ngIf="tag.type === types.array">
        <ng-container *ngFor="let subNode of tag.value">
          <div class="ms-4 pb-2 border-bottom">
            <app-mustache-value-display [tags]="subNode"></app-mustache-value-display>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="tag.type === types.implicitArray">
        <ng-container *ngFor="let value of tag.value">
          <div class="ms-4 mt-4">
            <input class="form-control" type="text" [(ngModel)]="value.value">
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>