/* tslint:disable */

import { Component } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-specialist-contentfeedback',
	templateUrl: './ContentFeedback.component.html'
})
export class ContentFeedbackComponent {
    public feedback: any = ko.observableArray([]);
    public isLoading: any = ko.observable(false);
    public userId: any = 0;

    constructor(
      private userService: UserService,
      private sessionStorageService: SessionStorageService) { }

    public activate(): void {
      Utils.wrapDfd(this.sessionStorageService.getUser())
        .done((user) => {
            if (user) {
                this.userId = user.UserId;
                this.loadFeedback();
            }
        });
    }

    public loadFeedback(): void {
        if (this.userId !== 0) {
            this.isLoading(true);
            Utils.wrapDfd(this.userService.getTaskNoteAcceptedFeedback(this.userId))
              .then((data) => {
                  this.feedback(data);
              })
              .fail((displayMessage) => {
                  toastr.error(displayMessage);
              })
              .always(() => {
                  this.isLoading(false);
              });
        }
    }
}
