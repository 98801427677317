import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, EMPTY } from 'rxjs';
import { CustomerAccountTypeService } from './customer-account-type.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerAccountService implements app.services.interfaces.ICustomerAccountService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/customeraccounts`;

  constructor(
    private http: HttpClient,
    private customerAccountTypeService: CustomerAccountTypeService) { }

  getOrCreate(customerId: number, customerAccountTypes: number[]): Observable<Boo.Objects.CustomerAccount[]> {
    return this.customerAccountTypeService.filterByPermissions(customerAccountTypes)
      .pipe(
        switchMap((filteredCustomerAccountTypes: number[]) => {
          return this.http.post<Boo.Objects.CustomerAccount[]>(`${this.baseUrl}/GetOrCreate`, {
            customerId: customerId,
            customerAccountTypes: filteredCustomerAccountTypes
          });
        })
      );
  }

  selectPasswordByAccountType(customerId: number, customerAccountTypeId: number): Observable<string> {
    return this.customerAccountTypeService.isCustomerAccountPermitted(customerAccountTypeId)
      .pipe(
        switchMap((isCustomerAccountPermitted: boolean) => {
          if (!isCustomerAccountPermitted) {
            toastr.error('You do not have permission to view this password');
            return EMPTY;
          }

          const params = new HttpParams()
            .set('customerId', customerId)
            .set('customerAccountTypeId', customerAccountTypeId);

          return this.http.get<string>(`${this.baseUrl}/SelectPasswordByAccountType`, {params: params});
        })
      );
    }

  updateManyMetadataOnly(accounts: Boo.Objects.CustomerAccount[]): Observable<Boo.Objects.CustomerAccount[]> {
    return this.http.post<Boo.Objects.CustomerAccount[]>(`${this.baseUrl}/UpdateManyMetadataOnly`, accounts);
  }

  insertUpdateMultiple(accounts: Boo.Objects.CustomerAccount[]): Observable<void> {
    accounts.forEach((account: Boo.Objects.CustomerAccount) => {
      if (account.Note == null || account.Note.replace(/\s/g, '').length < 1) {
        account.Note = null;
      }
    });

    return this.http.post<void>(`${this.baseUrl}/InsertUpdateMultiple`, accounts);
  }
}
