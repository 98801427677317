import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-components-shared-halo-keywords',
  templateUrl: './halo-keywords.component.html',
  styleUrls: ['../customer-campaign/customer-campaign.component.scss']
})
export class HaloKeywordsComponent {
  @Input() customer: Boo.Objects.Customer;
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];
  @Input() haloKeywords: Boo.Objects.WebsiteKeywordTracking[];
  @Input() archivedKeywords: Boo.Objects.ArchivedKeyword[];
  @Input() readOnlyReasons: string;
  @Input() restrictions: Boo.Objects.Enums.CustomerCampaignRestrictions[];

  get activeHaloKeywordCount(): number {
    return this.haloKeywords ? this.haloKeywords.filter(haloKeyword => haloKeyword.IsActive).length : 0;
  }
}
