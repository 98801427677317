import { AnalyticsService } from './analytics.service';
import ReloadRequiredType = app.services.models.enums.ReloadRequiredType;
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReloadAnalyticsService implements OnInit{
    protected static reloadMessage: string = 'Reload Message';
    protected static noReloadMessage: string = 'No Reload Message';
    protected static applicationReloaded: string = 'Application Reloaded';
    protected static reloadRequired: string = 'Reload Required';
    private reloadMessageShown: boolean;
    private reloadNeeded: boolean;
    private reloadKey: string;

    constructor(private analyticsService: AnalyticsService) {
    }

    ngOnInit(): void {
      this.reloadMessageShown = false;
        this.reloadNeeded = false;
        this.reloadKey = null;

        window.onbeforeunload = this.onApplicationUnload.bind(this);
    }

    public trackReload(isManualReload?: boolean): void {
        let action = this.reloadMessageShown ? ReloadAnalyticsService.reloadMessage : ReloadAnalyticsService.noReloadMessage;
        let label = isManualReload ? 'Manual Reload' : 'Auto-Reload';
        this.analyticsService.trackEvent(<IAnalyticsEvent>{ category: `${ ReloadAnalyticsService.applicationReloaded } - ${ this.reloadKey }`, action: action, label: label });
        this.reloadNeeded = false;
        this.reloadMessageShown = false;
    }

    public messageWasShown(): void {
        this.reloadMessageShown = true;
    }

    public trackReloadNeeded(reloadRequiredType: ReloadRequiredType, reloadKey: string): void {
        this.reloadNeeded = true;
        this.reloadKey = reloadKey;

        let action = 'Eventual';
        if (reloadRequiredType === ReloadRequiredType.forced) {
            action = 'Forced';
        }
        this.analyticsService.trackEvent(<IAnalyticsEvent>{ category: `${ReloadAnalyticsService.reloadRequired} - ${this.reloadKey}`, action: action, label: window.location.href });
    }

    private onApplicationUnload(): void {
        if (this.reloadNeeded) {
            let action = this.reloadMessageShown ? ReloadAnalyticsService.reloadMessage : ReloadAnalyticsService.noReloadMessage;
            this.analyticsService.trackEvent(<IAnalyticsEvent>{ category: `${ReloadAnalyticsService.applicationReloaded} - ${this.reloadKey}`, action: action, label: 'Browser Unload' });
            this.reloadNeeded = false;
            this.reloadMessageShown = false;
        }
    }
}