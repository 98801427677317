import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScreenErrors } from '../AllocateWork.component';
import FlagTypes = Boo.Objects.Work.SeoMinute.Enums.FlagTypes;
import { SeoWorkService } from '../../../../services/seo-work.service';

@Component({
  selector: 'app-components-managecustomer-workdefaults',
  templateUrl: './WorkDefaults.component.html',
  styleUrls: ['../AllocateWork.component.scss']
})
export class WorkDefaultsComponent {
  @Input() customer: CustomerObservable;
  @Input() defaults: Boo.Objects.Work.SeoMinute.SeoWorkDefault[];
  @Input() screenErrors: ScreenErrors;
  @Output() defaultsChange = new EventEmitter();
  @Input() seoWorkSettings: Boo.Objects.Work.SeoMinute.SeoWorkSettings;
  @Output() validateDefaults = new EventEmitter();
  @Output() refresh = new EventEmitter();
  defaultTaskTypes: Boo.Objects.Work.SeoMinute.SeoWorkTaskType[];
  qtyNumbers: number[] = [];
  maxQtyNumber = 99;
  reservedMinutesDescription: string = "These minutes will be reserved each month and can be used in future billing periods.";
  private existingErrors: boolean;

  constructor(private seoWorkService: SeoWorkService) { }

  ngOnInit(): void {
    this.existingErrors = !!this.screenErrors;
    this.defaultTaskTypes = this.seoWorkSettings.TaskTypes.filter(x => !x.IsCustom);
    for (let number = 1; number <= 40; number++) {
      this.qtyNumbers.push(number);
    }
    this.sortDefaults();
  }

  get totalMinutes(): number {
    if (!this.defaults.length) {
      return 0 + this.seoWorkSettings.ReservedSeoMinutesDefault;
    }

    return this.defaults.map(item => item.MinutesPerTask * item.TaskCount).reduce((a, b) => a + b) + this.seoWorkSettings.ReservedSeoMinutesDefault;
  }

  get validTotalMinutes(): boolean {
    const isValid = this.totalMinutes === this.seoWorkSettings.AllotedSeoMinutesForDefaults;

    if (this.existingErrors && isValid) {
      this.existingErrors = false;
      this.validateDefaults.emit();
    }

    return isValid;
  }

  get minuteDifferenceMessage(): string {
    if (this.seoWorkSettings.AllotedSeoMinutesForDefaults > this.totalMinutes) {
      return (this.seoWorkSettings.AllotedSeoMinutesForDefaults - this.totalMinutes).toString() + " Under";
    } else {
      return (this.totalMinutes - this.seoWorkSettings.AllotedSeoMinutesForDefaults).toString() + " Over";
    }
  }

  get showTable(): boolean {
    return this.defaults && this.defaults.length && this.defaults.some(x => x.TaskCount);
  }

  addDefaultItem(taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType): void {
    const existingDefaultsIds = this.defaults.map(x => x.TaskTypeId);
    if (existingDefaultsIds.includes(taskType.TaskTypeId)) {
      const defaultTaskType = this.defaults.find(x => x.TaskTypeId === taskType.TaskTypeId);
      const taskTypeMax = this.defaultTaskTypes.find(x => x.TaskTypeId === taskType.TaskTypeId).MaxCount;
      if (defaultTaskType.TaskCount >= taskTypeMax) {
        return;
      }
      defaultTaskType.TaskCount++;
    } else {
      const newItem = this.workDefaultFactory(taskType);
      this.defaults.push(newItem);
      this.sortDefaults();
      this.defaultsChange.emit(this.defaults);
    }
  }

  updateDefaults(): void {
    this.defaults = this.defaults.filter(x => x.TaskCount > 0);
    this.defaultsChange.emit(this.defaults);
  }

  qtyDisabled(qty: number, item: Boo.Objects.Work.SeoMinute.SeoWorkDefault): boolean {
    return qty > this.maxCount(item) || (qty > 0 && this.isNonWorkable(item.TaskTypeId));
  }

  maxCount(item: Boo.Objects.Work.SeoMinute.SeoWorkDefault): number {
    let taskType = this.defaultTaskTypes.find(x => x.TaskTypeId === item.TaskTypeId);
    // taskType.MaxCount can be null so must be checked separately here
    return taskType && taskType.MaxCount && taskType.MaxCount >= 0 ? taskType.MaxCount : this.maxQtyNumber;
  }

  isNonWorkable(taskTypeId: number): boolean {
    return this.seoWorkSettings.TaskTypes.find(x => x.TaskTypeId === taskTypeId).Flags.some(y => y.Type === FlagTypes.Nonworkable)
  }

  resetToPartnerDefaults() {
    this.seoWorkService.getSeoWorkDefaultConfiguration(this.customer.CustomerId())
      .subscribe((defaultConfiguration) => {
        this.defaults.length = 0; // Clear defaults but keep the reference
        this.defaults.push(...defaultConfiguration.SeoWorkDefaults);
        this.seoWorkSettings.ReservedSeoMinutesDefault = defaultConfiguration.ReservedMinutes;
        this.sortDefaults();
        this.defaultsChange.emit(this.defaults);
      });
  }

  private sortDefaults(): void {
    this.defaults
      // sort by name
      .sort((a, b) => a.TaskType.Name.localeCompare(b.TaskType.Name))
      // sort by minutes
      .sort((a, b) => a.MinutesPerTask - b.MinutesPerTask);
  }

  private workDefaultFactory(taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType): Boo.Objects.Work.SeoMinute.SeoWorkDefault {
    return {
      CustomerId: this.customer.CustomerId(),
      MinutesPerTask: taskType.Minutes,
      PartnerId: this.customer.PartnerId(),
      TaskTypeId: taskType.TaskTypeId,
      TaskType: {
        Name: taskType.Name
      },
      TaskCount: 1,
    } as Boo.Objects.Work.SeoMinute.SeoWorkDefault;
  }
}
