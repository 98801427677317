import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import { NonRecurringRanksService } from '../../../../../Scripts/app/services/NonRecurringRanksService'
import PushNotificationConfig from 'app/PushNotificationConfig';
import { BehaviorSubject } from 'rxjs';
import KeywordSuggestion = Boo.Objects.LegacyKeywordSuggestion;
import UrlSuggestion = Boo.Objects.LegacyUrlSuggestion;
import KeywordSuggestionTypes = Boo.Objects.Enums.KeywordSuggestionTypes
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';
import { NonRecurringRanksRequestSources } from '../non-recurring-ranks-request-sources';

@Component({
  selector: 'app-components-shared-keyword-ideation-tool',
  templateUrl: './keyword-ideation-tool.component.html',
})
export class KeywordIdeationToolComponent {
  @Input() minimumHaloKeywordSuggestions: number;
  @Input() minimumMainKeywords: number;
  @Input() minimumPages: number;
  @Input() customer: Boo.Objects.Customer;
  @Input() taskId: number;
  @Input() wasLocalCampaign: boolean;
  @Input() campaignTypeReadOnly: boolean;
  @Output() campaignTypeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() keywordsAssigned: EventEmitter<UrlSuggestion> = new EventEmitter();
  @Output() haloKeywordsAssigned: EventEmitter<KeywordSuggestion[]> = new EventEmitter();

  constructor(
    private nonRecurringRanksService: NonRecurringRanksService,
    private customerCampaignService: CustomerCampaignService) {}

  keywordTotal: number;
  isLocalCampaign: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  generatedKeywords: IKeywordViewModel[] = [];

  allKeywordToggle: boolean = false;
  keywordsCount: number = 0;
  locationsCount: number = 0;

  targetUrlControl = new UntypedFormControl(
    '',
    { validators: [Validators.required, Validators.maxLength(512), CustomValidators.validUrl() ] }
  );
  addToPageFormGroup = new UntypedFormGroup({
    targetUrl: this.targetUrlControl
  })

  locationsControl = new UntypedFormControl(
    { value: '', disabled: true},
    { validators: [CustomValidators.maxLengthPerLineValidator(50), Validators.required] }
  );
  keywordsControl = new UntypedFormControl(
    '',
    { validators: [CustomValidators.maxLengthPerLineValidator(128), Validators.required] }
  );
  generateKeywordsFormGroup = new UntypedFormGroup({
    locations: this.locationsControl,
    keywords: this.keywordsControl
  });

  private subscriberId: string

  ngOnInit() {
    this.isLocalCampaign.asObservable().subscribe(isLocalCampaign => {
      if (isLocalCampaign) {
        this.locationsControl.enable();
        this.locationsControl.reset('');
      }
      else {
        this.locationsControl.disable();
        this.locationsControl.setValue('');
      }
      this.keywordTotal = this.minimumHaloKeywordSuggestions + this.minimumMainKeywords;
    });
    const events = PushNotificationConfig.channels.nonRecurringRanks.events;
    this.subscriberId = `nonRecurringRanks:${this.customer.Url}`
    this.nonRecurringRanksService.subscribe(
      this.subscriberId,
      events.onRanksComplete,
      ((e: any) => this.populateRanks(e))
    );
    this.nonRecurringRanksService.connect(this.customer.Url);
    this.keywordTotal = this.minimumHaloKeywordSuggestions + this.minimumMainKeywords;
    this.targetUrlControl.addValidators(CustomValidators.domainMustMatch(this.customer.Url, `Target page must contain customer's base domain (${this.customer.Url})`));
    this.keywordsControl.valueChanges.subscribe((value: string) => {
      this.keywordsCount = value ? value.trim().split(/\r\n|\r|\n/).filter(x => !!x).length : 0;
    });
    this.locationsControl.valueChanges.subscribe((value: string) => {
      this.locationsCount = value ? value.trim().split(/\r\n|\r|\n/).filter(x => !!x).length : 0;
    });
  }

  ngOnDestroy() {
    this.nonRecurringRanksService.disconnect(this.subscriberId);
  }

  ngOnChanges() {
    this.isLocalCampaign.next(this.wasLocalCampaign);
  }

  generateKeywords() {
    this.generateKeywordsFormGroup.markAllAsTouched();
    if (this.isLocalCampaign.getValue() === undefined) {
      toastr.error("Must indicate the campaign type");
      return;
    }

    if(this.locationsControl.enabled && !this.locationsControl.valid) {
      if(this.locationsControl.errors.required) {
        toastr.error("Must provide locations");
      }
      if(this.locationsControl.errors.lineTooLong) {
        toastr.error("Location input error: " + this.locationsControl.errors.lineTooLong);
      }
    }

    if(!this.keywordsControl.valid) {
      if(this.keywordsControl.errors.required) {
        toastr.error("Must provide keywords");
      }
      if(this.keywordsControl.errors.lineTooLong) {
        toastr.error("Keyword input error: " + this.keywordsControl.errors.lineTooLong);
      }
    }

    if(!this.generateKeywordsFormGroup.valid) {
      return;
    }

    let locations = this.locationsControl.value.trim().split(/\r\n|\r|\n/).map((x: string) => this.customerCampaignService.normalizeArea(x));
    let keywords = this.keywordsControl.value.trim().split(/\r\n|\r|\n/).map((x: string) => this.customerCampaignService.normalizeKeyword(x));
    locations = [... new Set(locations)];
    keywords = [... new Set(keywords)];

    let keywordsToRank: IKeywordViewModel[] = [];

    // Generate new keywords
    if (this.locationsControl.value.trim().length === 0) {
      keywordsToRank = keywords.map((keyword: string) => {
        return {keywordSuggestion: this.createKeywordSuggestion(null, keyword), hidden: false,
          selected:false, rankingTerm: keyword} as IKeywordViewModel});
    } else {
      keywords.forEach((keyword: string) => {
        locations.forEach((location: string) => {
          keywordsToRank.push({keywordSuggestion: this.createKeywordSuggestion(location, keyword), 
            hidden: false, selected: false, rankingTerm: keyword + ' ' + location} as IKeywordViewModel);
        })
      })
    }

    // Un-hide keywords that have already had their ranks requested & do not request them again
    this.generatedKeywords.map(existingKeyword => {
      if (keywordsToRank.some((newKeyword) => newKeyword.rankingTerm === existingKeyword.rankingTerm)) {
        existingKeyword.hidden = false;

        if(existingKeyword.keywordSuggestion.KeywordSuggestionTypeId !== undefined) {
          existingKeyword.keywordSuggestion.KeywordSuggestionTypeId = undefined;
        }
      }
    });
    keywordsToRank = keywordsToRank.filter(keyword => this.isNewRankRequest(keyword));

    if (keywordsToRank.length > 0) {
      let rankingTerms = keywordsToRank.map(keywordRanks => keywordRanks.rankingTerm);
      this.nonRecurringRanksService.requestRanks(this.customer.Url, rankingTerms, this.customer.CountryId, NonRecurringRanksRequestSources.keywordResearchLegacy).subscribe(() => {})
      this.generatedKeywords = this.generatedKeywords.concat(keywordsToRank);
    }
  }

  assignKeywordsToHalo() {
    if (!this.generatedKeywords.every((keyword) => !keyword.selected || keyword.keywordSuggestion.Rank !== null)) {
      toastr.error("Keywords must be ranked before adding to page");
      return;
    }
    this.generatedKeywords.forEach(keyword => {
      if(keyword.selected) {
        keyword.keywordSuggestion.KeywordSuggestionTypeId = KeywordSuggestionTypes.Halo;
      }
    });

    this.haloKeywordsAssigned.emit(this.generatedKeywords.filter(x => x.selected).map(x => x.keywordSuggestion));

    this.generatedKeywords.forEach(keyword => {
      if (keyword.selected) {
        keyword.hidden = true;
        keyword.selected = false;
      }
    });
    this.allKeywordToggle = false;
  }

  assignKeywordsToPage() {
    this.addToPageFormGroup.markAllAsTouched();
    let hasErrors = false;

    if (!this.addToPageFormGroup.valid) {
      hasErrors = true;
    }
    
    if (this.generatedKeywords.every((keyword) => !keyword.selected)) {
      toastr.error("Select at least one keyword");
      hasErrors = true;
    }

    if (!this.generatedKeywords.every((keyword) => !keyword.selected || keyword.keywordSuggestion.Rank !== null)) {
      toastr.error("Keywords must be ranked before adding to page");
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    this.keywordsAssigned.emit({
      CustomerId: this.customer.CustomerId,
      Url: this.targetUrlControl.value, 
      KeywordSuggestions: this.generatedKeywords.filter(x => x.selected).map(x => x.keywordSuggestion)
    } as UrlSuggestion);
    this.generatedKeywords.forEach(keyword => {
      if (keyword.selected) {
        keyword.hidden = true;
        keyword.selected = false;
      }
    });
    this.allKeywordToggle = false;
  }

  toggleSelection(keyword: IKeywordViewModel) {
    keyword.selected = !keyword.selected;
    if (this.generatedKeywords.some(x => !x.selected)) {
      this.allKeywordToggle = false;
    }
  }

  toggleAll() {
    this.generatedKeywords.forEach((keyword) => {
      if(!keyword.hidden && keyword.keywordSuggestion.Rank !== undefined) {
        keyword.selected = !this.allKeywordToggle;
      }
    });
  }

  clearSelected() {
    this.generatedKeywords.map(keyword => {
      if (keyword.selected) {
        keyword.selected = false;
        keyword.hidden = true;
      }
    });
    this.allKeywordToggle = false;
  }

  clearInput() {
    this.keywordsControl.setValue('');
    this.locationsControl.setValue('');
    this.keywordsControl.markAsUntouched();
    this.locationsControl.markAsUntouched();
  }

  updateCampaignType(isLocalCampaign: boolean) {
    this.campaignTypeChanged.emit(!isLocalCampaign);
    this.isLocalCampaign.next(isLocalCampaign);
  }

  populateRanks(e: IInstaCheckReport) {
    if (e && e.termsInfo !== undefined && e.termsInfo.length > 0 && e.termsInfo[0].url === this.customer.Url) {
      e.termsInfo.forEach((termInfo: ITermInfo) => {
        var rank = parseInt(termInfo.searchResults[0].rank.replace('+', ''));
        var page = Math.floor(rank / 10) + (rank % 10 ? 1 : 0);
        var term = termInfo.term;
        var matchedKeyword = this.generatedKeywords.find(keyword => keyword.rankingTerm === term);
        
        if (matchedKeyword === undefined) {
          toastr.error(`Ranks were returned but we could not find a match for "${term}" (Rank: ${rank}).`, "", {timeOut: 0});
          return;
        }

        matchedKeyword.keywordSuggestion.Rank = rank;
        matchedKeyword.keywordSuggestion.Page = page;
      });
    }
    else if (e && e.termsInfo) {
      toastr.error(`Ranks were returned but no terms were listed`, "", {timeOut: 0});
    }
  }

  private createKeywordSuggestion(area: string, keyword: string): KeywordSuggestion {
    return {
      Area: area,
      CreatedByTaskId: this.taskId,
      CustomerId: this.customer.CustomerId,
      IsAreaFirst: false,
      KeywordSuggestionStatusId: Boo.Objects.Enums.KeywordSuggestionStatus.Proposed,
      Keyword: keyword,
    } as KeywordSuggestion
  }

  private isNewRankRequest(newKeyword: IKeywordViewModel) {
    return !this.generatedKeywords.some((existingKeyword) => existingKeyword.rankingTerm === newKeyword.rankingTerm)
  }
}

interface IInstaCheckReport {
  termsInfo: ITermInfo[]
}

interface ITermInfo {
  searchResults: ISearchResults[]
  term: string;
  url: string;
  volume: string;
}

interface ISearchResults {
  rank: string;
}

interface IKeywordViewModel {
  keywordSuggestion: Boo.Objects.LegacyKeywordSuggestion;
  hidden: boolean;
  selected: boolean;
  rankingTerm: string;
}