<div class="card">
	<div class="card-header">
		New Price Level
		<button (click)="close(true)" class="btn-close" title="Close"></button>
	</div>
	<div class="card-body" [formGroup]="form">
		<div class="form-group">
			<label class="control-label">Name</label>
			<input formControlName="name" class="form-control" type="text"/>
		</div>
		<div class="form-group">
			<label class="control-label">Price</label>
			<input formControlName="price" class="form-control" type="text"/>
		</div>
		<div class="form-group">
			<label class="control-label">SEO Minutes</label>
			<input formControlName="seoMinutes" class="form-control" type="number"/>
		</div>
		<div class="card card-body bg-light d-block text-end">
			<button (click)="close(true)" class="btn btn-link">Cancel</button>
			<button (click)="save()" class="btn btn-success" data-accept="true">
				<i class="fa fa-check"></i>
				Save
			</button>
		</div>
	</div>
</div>
