import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import FloatingPanel = app.components.interfaces.FloatingPanel;
import FloatingTabPanelSize = app.components.enums.FloatingTabPanelSize;
import FloatingTabPanelIcon = app.components.enums.FloatingTabPanelIcon;
import IFloatingTabPanelPublicApi = app.components.interfaces.IFloatingTabPanelPublicApi;
import ISave = app.interfaces.ISave;
import ICancel = app.interfaces.ICancel;
import IValidate = app.interfaces.IValidate;

@Component({
	selector: 'app-components-ticket-notefloatingpanel',
	templateUrl: './NoteFloatingPanel.component.html'
})
export class NoteFloatingPanelComponent extends BaseComponent {
    private notePanel: FloatingPanel;
    private floatingTabPanelApi: KnockoutObservable<IFloatingTabPanelPublicApi>;
    private ticketNoteParams: ITicketNoteActivationParams;

    public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        return super.activate(params)
            .then(() => {
                if (params.canEdit) {
                    this.floatingTabPanelApi = params.floatingTabPanelApi;
                    this.ticketNoteParams = (params as ITicketNoteActivationParams);
                    this.ticketNoteParams.callback = (api) => this.addApi(api);
                    if (params.floatingTabPanelApi().addPanel) {
                        this.addPanel();
                    }
                    params.floatingTabPanelApi.subscribe((api) => {
                        this.addPanel();
                    });
                }
            });
    }

    public addPanel(): void {
        this.notePanel = {
            title: 'Ticket Note',
            titleIcon: FloatingTabPanelIcon.StickyNote,
            size: FloatingTabPanelSize.Small,
            isScrollable: false,
            composition: {
                selector: 'app-components-ticket-note',
                options: this.ticketNoteParams,
            }
        };
        this.floatingTabPanelApi().addPanel(this.notePanel);
    }

    public addApi(api: ISave | ICancel | IValidate): void {
        this.floatingTabPanelApi().addChild({
            panelId: this.notePanel.panelId,
            api: api
        });
    }
}

export interface ITicketNoteActivationParams extends app.ticket.components.interfaces.ITicketComponentActivateParams {
    callback: (api: ISave | ICancel | IValidate) => void;
}
