import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { KeywordResearchService } from '../../../../services/keyword-research.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-components-shared-keyword-research-redo',
  templateUrl: './redo.component.html'
})
export class RedoComponent implements OnInit {
  @Input() customer: Boo.Objects.Customer;
  @Output() redoRequested = new EventEmitter<void>();

  @ViewChild('areaInput') areaInput: NgSelectComponent;

  areaControl: UntypedFormControl;
  requestingRedo: boolean = false;
  redoRequestSubmitted: boolean = false;

  areaInput$ = new Subject<string>();
  areasLoading = false;
  areas$: Observable<Boo.GooglePlaces.PlaceDescription[]> = of([]);

  constructor(private keywordResearchService: KeywordResearchService) { }

  ngOnInit(): void {
    this.setupForm();

    this.areas$ = this.areaInput$.pipe(
      filter(x => !!x),
      distinctUntilChanged(),
      debounceTime(800),
      tap(() => this.areasLoading = true),
      switchMap(input => this.keywordResearchService.findAreas(input)
        .pipe(
          catchError(() => of([])),
          tap(() => this.areasLoading = false)
        ))
    );
  }

  toggleRequestingRedo(): void {
    this.requestingRedo = !this.requestingRedo;

    if (this.requestingRedo) {
      this.focus();
    } else {
      this.areaControl.reset(null);
    }
  }

  setupForm(): void {
    this.areaControl = new UntypedFormControl(null, [
      Validators.required
    ]);
  }

  requestRedo(): void {
    this.areaControl.markAsDirty();
    this.areaControl.updateValueAndValidity();

    if (!this.areaControl.valid) {
      toastr.error("Please correct errors before requesting a redo.");
      return;
    }

    this.redoRequestSubmitted = true;
    this.areaControl.disable();
    this.keywordResearchService.redo(this.customer.CustomerId, this.customer.IsCampaignNational, this.areaControl.value)
      .subscribe({complete: () => this.redoRequested.emit(), error: (e) => toastr.error(e)});
  }


  private focus() {
    setTimeout(() => {
      if (this.areaInput) {
        this.areaInput.focus();
      }
    }, 0);
  }
}