import Check from 'framework/Check';
import permissions from 'app/models/Permissions';
import System from 'framework/System';
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from "app/models/enums/SaveTypes";

export default class BaseComponent implements app.ticket.components.interfaces.ITicketComponentViewModel {
    public canCancel: boolean = false;
    public canComplete: boolean = false;
    public canEdit: boolean = false;
    public customer: CustomerObservable;
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);
    public isTicketLoading: KnockoutComputed<boolean>;
    public partner: Boo.Objects.Partner;
    public partnerUser: Boo.Objects.PartnerUser;
    public partnerUsers: Boo.Objects.PartnerUser[];
    public ticket: TicketObservable;
    public triggerTicketCancel: () => void;
    public triggerTicketSave: (saveType: SaveTypes, closeTicket: boolean) => JQueryPromise<void>;
    public user: Boo.Objects.User;
    public validation: KnockoutObservable<any> = ko.validatedObservable<any>();
    
    protected modifiedDataFlags: app.ticket.interfaces.IModifiedDataFlags = { customer: false };
    protected shouldValidateOnUpdate: boolean = true;
    protected saveType: KnockoutObservable<SaveTypes> = ko.observable(SaveTypes.Update);

    public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        Check.isNotEmpty(params.ticket, 'ticket param is required for component.');
        Check.isNotEmpty(params.customer, 'customer param is required for component.');
        Check.isNotEmpty(params.user, 'partnerUser param is required for component.');
        Check.isNotEmpty(params.partnerUser, 'partnerUser param is required for component.');
        Check.isNotEmpty(params.partnerUsers, 'partnerUsers param is required for component.');
        Check.isNotEmpty(params.featureConfig, 'featureConfig param is required for component.');
        Check.isNotEmpty(params.triggerTicketSave, 'triggerTicketSave param is required for component.');
        Check.isNotEmpty(params.triggerTicketCancel, 'triggerTicketCancel is required for component.');
        Check.isNotEmpty(params.isLoading, 'isLoading param is required for component.');

        this.ticket = params.ticket;
        this.customer = params.customer;
        this.user = params.user;
        this.partner = params.partner;
        this.partnerUser = params.partnerUser;
        this.partnerUsers = params.partnerUsers;
        this.canEdit = params.canEdit === true ? true : false;
        this.canComplete = params.canComplete === true ? true : false;
        this.canCancel = params.canCancel === true ? true : false;
        this.triggerTicketSave = params.triggerTicketSave;
        this.triggerTicketCancel = params.triggerTicketCancel;
        this.isTicketLoading = params.isLoading;

        return System.emptyPromise();
    }

    public validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
        // set observable for validators to depend on to determine what save type the last validate call was for
        this.saveType(saveType);

        let isValid: boolean = saveType === SaveTypes.Complete || this.shouldValidateOnUpdate ? this.validation.isValid() : true;
        let errorMessages: string[] = [];

        if (this.validation.errors) {
            this.validation.errors.showAllMessages(!isValid);
            errorMessages = ko.mapping.toJS(this.validation.errors()) as string[];
        }

        return System.resolvedPromise<IValidatedResult>({ isValid: isValid, errorMessages: errorMessages });
    }

    public isValid(saveType: SaveTypes): boolean {
        return this.validation.isValid();
    }

    public confirmSave(saveType: SaveTypes): JQueryPromise<boolean> {
        return System.resolvedPromise(true);
    }

    public save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
        return System.emptyPromise();
    }

    public cancel(): JQueryPromise<void> {
        if (!this.canCancel) {
            throw new Error('Cancel is an invalid operation.');
        }

        return System.emptyPromise();
    }

    /**
     * Retruns an IModifiedDataFlags that indicates which objects the component modified,
     * so that the parent control can refresh those objects if needed. Note that this does
     * not apply if the component modifies some data and then throws it away on cancel--which
     * is the preferred approach. This is to accomodate old components that save their own data
     * outside the ticket/component save process.
     */
    public getModifiedDataFlags(): app.ticket.interfaces.IModifiedDataFlags {
        return this.modifiedDataFlags;
    }

    protected userHasTicketType(): boolean {
        return launchpad.hasPermission(this.partner, this.partnerUsers, permissions.HasAllTicketTypes, this.user) ||
            _.contains(_.pluck(this.partnerUser.TicketTypes, 'TicketTypeId'), this.ticket.TicketTypeId());
    }

    protected userHasTeam(): boolean {
        return launchpad.hasPermission(this.partner, this.partnerUsers, permissions.HasAllTeams, this.user) ||
            _.contains(_.pluck(this.partnerUser.Teams, 'TeamId'), this.customer.TeamId());
    }
}
