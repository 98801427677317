<div class="d-flex align-items-center">
  <div>
    <button class="btn btn-secondary me-4 btn-sm" type="button" data-toggle="dropdown" [disabled]="websiteUrl?.IsReadOnly || restrictions.includes(customerCampaignRestrictions_ReadOnly)">
      <i class="fas fa-solid fa-bars fa-no-margin"></i>
    </button>
    <ul class="dropdown-menu" role="menu">
      <ng-container *ngFor="let destinationWebsiteUrl of websiteUrls">
        <li>
          <ng-container *ngIf="destinationWebsiteUrl === websiteUrl; else moveToWebsiteUrl">
            <button *ngIf="isActiveStatus(destinationWebsiteUrl)" class="btn-link dropdown-item disabled">
            {{ websiteUrl.Url }}</button>
          </ng-container>
          <ng-template #moveToWebsiteUrl>
            <button *ngIf="isActiveStatus(destinationWebsiteUrl)" class="btn-link dropdown-item"
              (click)="moveKeywordToWebsiteUrl(websiteUrlKeyword, destinationWebsiteUrl)"
              [disabled]="destinationWebsiteUrl.IsReadOnly">{{ destinationWebsiteUrl.Url }}</button>
          </ng-template>
        </li>
      </ng-container>
      <div class="dropdown-divider"></div>
      <li>
        <button class="btn-link dropdown-item" (click)="moveKeywordToHalo(websiteUrlKeyword)">Halo</button>
      </li>
      <div class="dropdown-divider"></div>
      <li>
        <button class="btn-link dropdown-item" (click)="archiveKeyword(websiteUrlKeyword, websiteUrl)">Archive</button>
      </li>
    </ul>
  </div>
  <div>
    {{ websiteUrlKeyword.KeywordPhrase }}
  </div>
  <div class="badge rounded-pill bg-secondary pill-text pill-toggle">
    <span (click)="toggleIsRecommended(websiteUrlKeyword)">
    @if (websiteUrlKeyword.IsRecommended) {
      <i class="fas fa-toggle-on"></i>Recommended
    } @else {
      <i class="fas fa-toggle-off"></i>Customer Choice
    }
    </span>
  </div>
  @if (websiteUrlKeyword.Rank > 0) {
    <div class="badge rounded-pill bg-secondary rank-text pill-text">
      Rank: {{ websiteUrlKeyword.Rank }}
    </div>
  }
  @if (validationMessages.length > 0) {
    <div class="flex-grow-1 text-danger fw-normal me-3 text-end">
      {{ validationMessages[0] }}
    </div>
  }
</div>