<div>
	<app-components-togglepanel [(isOpen)]="isOpen" title="Seo Content Survey" toggleBody="true">
		<div class="row">
			<div class="col-lg-12">
				<div class="form-group">
					<label class="control-label">SEO Content Survey</label>
					<div class="card card-body bg-light text-center">
						<div>
							<ng-container *ngIf="!(hasSeoContent())">
								<div class="alert alert-warning">
									<strong>
										<i class="fas fa-exclamation-triangle"></i>
									</strong>
									SEO content survey not completed for the customer.
								</div>
							</ng-container>
						</div>
						<div>
							<button (click)="editSeoContentSurvey()" class="btn btn-primary">
								<i class="fa fa-edit"></i>
								<span>
									<ng-container *ngIf="!(hasSeoContent())">
										Create
									</ng-container>
								</span>
								<span>
									<ng-container *ngIf="hasSeoContent()">
										Edit
									</ng-container>
								</span>
								SEO Content Survey
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</app-components-togglepanel>
</div>
