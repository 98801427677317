<div [loadingOverlay]="isLoading">
  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-info">
        Notifications are reformatted for display. Formatting may differ from what the customer recieved.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-table [items]="notifications">
        <ng-template #tableHeader>
          <tr>
            <th sort field="NotificationDefinitionName">Type</th>
            <th sort field="RouteTo">To</th>
            <th sort field="RouteFrom">From</th>
            <th sort field="MessageTitle">Title</th>
            <th>Body</th>
            <th sort desc field="SentDate">Date</th>
          </tr>
        </ng-template>
        <ng-template #tableBody let-item>
          <tr>
            <td>{{item.NotificationDefinitionName}}</td>
            <td>{{item.RouteTo}}</td>
            <td>{{item.RouteFrom}}</td>
            <td>{{item.MessageTitle}}</td>
            <td>
              <button class="btn btn-info" (click)="displayNotification(item)">
                <i class="fa fa-eye"></i>
                View
              </button>
            </td>
            <td>{{item.SentDate | date: 'short'}}</td>
          </tr>
        </ng-template>
      </app-table>
    </div>
  </div>
</div>