/* tslint:disable */

import { Component, AfterViewInit, OnInit } from '@angular/core';
import permissions from 'app/models/Permissions';
import partneruser from 'app/models/partneruser';
import userpermission from 'app/models/userpermission';
import { ViewLocatorService } from '../../../services/view-locator.service';
import { FrontService } from '../../../services/front.service';
import { PermissionService } from '../../../services/permission.service';
import { OrganizationalTeamService } from '../../../services/organizational-team.service';
import { PartnerUserService } from '../../../services/partner-user.service';
import { PartnerTicketTypeService } from '../../../services/partner-ticket-type.service';
import { unassignableTaskTypes } from '../../../../Scripts/app/models/collections/TaskTypeCollections';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-manager-partneruser',
	templateUrl: './PartnerUser.component.html'
})
export class PartnerUserComponent implements OnInit, AfterViewInit {
    public title = 'Partner User';
    public frontTeammates: app.tsmodels.interfaces.FrontTeammate[];
    public frontTeammateId: string;
    private dfd: any;
    private autoPermissionsById: any;
    private accessLevels = {
        names: {
            1: 'Administrator',
            2: 'SEO Specialist',
            4: 'Sales',
            5: 'Manager',
            6: 'Customer Service'
        },
        ids: {
            'Administrator': window.launchpad.config.keys.adminUserLevelId,
            'SEO Specialist': window.launchpad.config.keys.seoSpecialistUserLevelId,
            'Sales': window.launchpad.config.keys.salesUserLevelId,
            'Manager': window.launchpad.config.keys.managerUserLevelId,
            'Customer Service': window.launchpad.config.keys.customerServiceUserLevelId
        }
    };
    private partnerIdsThatAllowAllTicketTypesPermission = [].concat(launchpad.config.partners.boostability);
    private partnerIdsThatAllowAllTeamsPermission = [].concat(launchpad.config.partners.boostability);

    constructor (private viewLocatorService: ViewLocatorService,
      private frontService: FrontService,
      private organizationalTeamService: OrganizationalTeamService,
      private permissionService: PermissionService,
      private partnerUserService: PartnerUserService,
      private partnerTicketTypeService: PartnerTicketTypeService,
      private sessionStorageService: SessionStorageService) { }

    ngOnInit(): void {
      this.userEmployeeCode.extend({
        validation: { validator: this.validateEmployeeCode.bind(this), message: 'Employee Code is required' }
      });

      // These are hardcoded user level ids that must match exactly table UserLevels in the Empire Database
      // set up permissions that are automatically added/removed based on UI selections
      this.autoPermissionsById = {}; // { permissionId: functionThatReturnsTrueIfUserHasPermission }
      this.autoPermissionsById[permissions.HasAllTicketTypes] = () => { return this.hasAllTicketTypes && this.hasAllTicketTypes(); };
      this.autoPermissionsById[permissions.HasAllTeams] = () => { return this.hasAllTeams && this.hasAllTeams(); };

      this.username.extend({
          required: {
              value: true,
              message: 'Username is a required field.'
          },
          validation: { validator: this.emailAddressIsValid.bind(this), message: 'A valid Email address is required.' }
      });
      this.userPassword.extend({
          validation: {
              validator: (val: any) => {
                  if (this.isInEditMode()) {
                      return true;
                  } else {
                      const result = val.match('^((?=.*[A-Z])(?=.*[a-z]).*){8,}$');
                      return !!result;
                  }
              },
              message: 'Passwords must be 8 characters long, have at least 1 uppercase letter, and at least 1 lower case letter'
          }
      });
      this.userFirstName.extend({
          required: { message: 'First Name is a required field.' },
          maxLength: 50
      });
      this.userLastName.extend({
          required: { message: 'Last Name is a required field.' },
          maxLength: 50
      });
      this.languageSelectionIsValid.extend({
          equal: { message: 'At least one language must be selected' }
      });
      const businessEntityIdIsValid = (val: any) => {
            return this.partnerId() != 1 || (_.isNumber(val) && val > 0);
      };
      this.businessEntityId.extend({
          validation: { validator: businessEntityIdIsValid, message: 'Business Entity is required.' }
      });
    }

    public tabIdPrepend = 'PartnerUserId_';
    // #region Private Variables and Functions
    public businessEntities = ko.observableArray([]);
    public isBusinessEntityLocked = ko.observable(false);

    private save(): any {
        // for new user, make sure there is at least one user level; otherwise, save won't do anything
        if (!this.isInEditMode() && this.currentUserLevels().length === 0) {
            toastr.error('You must add at least one user level.');
            this.isLoading(false);
            return;
        }
        // remove partner users without a current user level
        _.each(this.partnerUsers(),
            (partnerUser, i) => {
                if (!_.any(this.currentUserLevels(),
                    (userLevel) => {
                        return partnerUser.UserLevelId === userLevel.id;
                    })) {
                    this.partnerUsers.splice(i, 1);
                }
            });
        // loop through current user levels and setup partner user for each
        _.each(this.currentUserLevels(),
            (userLevel) => {
                let partnerUser, isNew = false;
                if (!_.any(this.partnerUsers(),
                    (pu) => {
                        return pu.UserLevelId === userLevel.id;
                    })) {
                    isNew = true;
                    partnerUser = new partneruser();
                } else {
                    partnerUser = _.find(this.partnerUsers(),
                        (pu) => {
                            return pu.UserLevelId === userLevel.id;
                        });
                }
                // Generic Info
                partnerUser.PartnerId = this.partnerId();
                partnerUser.UserId = this.userId();
                partnerUser.UserLevelId = userLevel.id;
                partnerUser.FirstName = this.userFirstName();
                partnerUser.LastName = this.userLastName();
                partnerUser.PhoneExtension = this.userPhoneExtension();
                partnerUser.IsActive = this.userIsActive();
                partnerUser.Username = this.username();
                partnerUser.IsReadonly = this.userIsReadonly();
                partnerUser.CanViewAdultContent = this.canViewAdultContent();
                partnerUser.BusinessEntityId = this.businessEntityId();
                partnerUser.EmployeeCode = this.userEmployeeCode() || null;
                if (this.isDefined(this.userPassword()) && !this.isInEditMode()) {
                    partnerUser.SetPassword = this.userPassword();
                }
                switch (userLevel.id) {
                    case window.launchpad.config.keys.seoSpecialistUserLevelId:
                        // Nothing here yet...
                        break;
                    case window.launchpad.config.keys.salesUserLevelId:
                        partnerUser.IsReseller = this.userIsReseller();
                        break;
                    case window.launchpad.config.keys.managerUserLevelId:
                        // Nothing here yet...
                        break;
                    case window.launchpad.config.keys.customerServiceUserLevelId:
                        partnerUser.IsCsr = this.userIsCsr();
                        break;
                }
                if (isNew) {
                    this.partnerUsers.push(partnerUser);
                }
            });
        // determine permissions, and save them to the first partner user.
        // we only care about the first partner user because they all inherit the same user.
        let userPermissions = [];
        if (this.partnerUsers().length > 0) {
            let firstPartnerUser = this.partnerUsers()[0];
            let userPermissionIds = this.userSelectedPermissions();
            userPermissions = firstPartnerUser.UserPermissions || [];
            // automatically add/remove permissions that are controlled by UI selections
            _.each(this.autoPermissionsById,
                (hasPermissionFunc: Function, permissionIdString: string) => {
                    const permissionId = parseInt(permissionIdString, 10);
                    if (_.isNaN(permissionId)) {
                        throw new Error('Invalid auto permissionId');
                    }
                    const shouldHavePermission = hasPermissionFunc();
                    if (shouldHavePermission && userPermissionIds.indexOf(permissionId) === -1) {
                        userPermissionIds.push(permissionId);
                    } else if (!shouldHavePermission) {
                        userPermissionIds = _.without(userPermissionIds, permissionId);
                    }
                });
            let missingPermissions = _.filter(userPermissions,
                (p: any) => {
                    return p.PermissionId !== permissions.CanAssignUserPermissions && p.PermissionId !== permissions.CanAdjustFreeSeoMinutes && !_.contains(userPermissionIds, p.PermissionId);
                });
            let currentPermissionIds = _.pluck(userPermissions, 'PermissionId');
            let newPermissionsIds = _.filter(userPermissionIds,
                (p) => {
                    return !_.contains(currentPermissionIds, p);
                });
            _.each(missingPermissions,
                (p) => {
                    p.Deleted = true;
                });
            _.each(newPermissionsIds,
                (p) => {
                    const userPerm = new userpermission();
                    userPerm.PermissionId = p;
                    userPerm.UserId = firstPartnerUser.UserId;
                    userPermissions.push(userPerm);
                });
            firstPartnerUser.UserPermissions = userPermissions;
        }

        let saveUserDfd;
        if(this.isInEditMode()) {
          saveUserDfd = Utils.wrapDfd(this.partnerUserService.update(
            this.partnerUsers(),
            this.userSelectedLanguageIds(),
            this.userSelectedTicketTypeIds(),
            this.taskTypes(),
            this.userSelectedTaskTypeIds(),
            this.userSelectedTeamIds(),
            this.frontTeammateId, //todo dean update this going down
            this.organizationalTeamId() || null
          ));
        } else {
          saveUserDfd = Utils.wrapDfd(this.partnerUserService.create(
            this.username(),
            this.userPassword(),
            this.userFirstName(),
            this.userLastName(),
            this.userPhoneExtension(),
            this.partnerUsers(),
            this.userSelectedLanguageIds(),
            this.userSelectedTicketTypeIds(),
            this.userSelectedTaskTypeIds(),
            this.userSelectedTeamIds(),
            this.hasCustomerServiceLevel() ? this.userIsCsr() : false,
            this.userIsReseller(),
            this.userEmployeeCode() || null,
            this.frontTeammateId, //todo dean update this going down
            this.businessEntityId() || 0,
            _.pluck(userPermissions, 'PermissionId'),
            this.organizationalTeamId() || null
          ));
        }

        $.when<any>(Utils.wrapDfd(this.sessionStorageService.getUser()), saveUserDfd)
          .then((user: any) => {
              // If editing logged in user alert them that changes won't occur until next login
              if (_.isObject(user) && user.UserId === this.userId()) {
                  toastr.warning('Some changes made to current user won\'t take effect until you next log in.');
              }
              this.saveSucceeded();
          }).fail((message) => {
              this.saveFailed(message);
          });
    }


    private saveSucceeded(): void {
        toastr.success('User saved successfully.');
        if (this.dfd) {
            this.dfd.resolve();
        }
    }
    private saveFailed(message: any): void {
        toastr.error(message || 'Error saving user.');
        if (this.dfd) {
            this.dfd.reject();
        }
    }
    private remove(id: any): void {
        const userLevel = {
            id: id,
            name: this.accessLevels.names[id]
        };
        this.availableUserLevels.push(userLevel);
        _.each(this.currentUserLevels(),
            (level, i) => {
                if (level.id === id) {
                    this.currentUserLevels.splice(i, 1);
                }
            });
        this.currentUserLevels(this.currentUserLevels.sort(this.sortUserLevels.bind(this)) as any);
        this.availableUserLevels(this.availableUserLevels.sort(this.sortUserLevels.bind(this)) as any);
        // Remove the tab
        $(`#${this.tabIdPrepend}${id}`).removeClass('active');
        $(`a[href="#${this.tabIdPrepend}${id}"]`).parent().removeClass('active');
        // Show the first tab, if we have one available
        if (this.currentUserLevels().length > 0) {
            $(`#${this.tabIdPrepend}${this.currentUserLevels()[0].id}`).addClass('active');
            $(`a[href="#${this.tabIdPrepend}${this.currentUserLevels()[0].id}"]`).parent().addClass('active');
        }
    }
    private add(data: any): void {
        this.currentUserLevels.push(data);
        _.each(this.availableUserLevels(),
            (level, i) => {
                if (level.id === data.id) {
                    this.availableUserLevels.splice(i, 1);
                }
            });
        this.currentUserLevels(this.currentUserLevels.sort(this.sortUserLevels.bind(this)) as any);
        this.availableUserLevels(this.availableUserLevels.sort(this.sortUserLevels.bind(this)) as any);
        $(`#${this.tabIdPrepend}${data.id}`).addClass('active').siblings().removeClass('active');
        $(`a[href="#${this.tabIdPrepend}${data.id}"]`).parent().addClass('active').siblings().removeClass('active');
    }
    private sortUserLevels(left: any, right: any): any {
        return (left.name > right.name) ? 1 : -1;
    }
    // #endregion

    // #region Observables
    public isLoading = ko.observable(false);
    public isInEditMode = ko.observable(false);
    public currentUserId = ko.observable(0);
    // Partner
    public partnerId = ko.observable(0);
    public partnerName = ko.observable('');
    // User
    public userId = ko.observable(-1);
    public username = ko.observable('');
    public userPassword = ko.observable('');
    public userFirstName = ko.observable('');
    public userLastName = ko.observable('');
    public userEmployeeCode = ko.observable('');
    public userPhoneExtension = ko.observable(null);
    public userIsActive = ko.observable(true);
    public userIsReseller = ko.observable(false);
    public userIsCsr = ko.observable(false);
    public userIsReadonly = ko.observable(false);
    public canViewAdultContent = ko.observable(false);
    public partnerAllowsAllTicketTypesPermission = ko.observable(false);
    public hasAllTicketTypes = ko.observable(false);
    public partnerAllowsAllTeamsPermission = ko.observable(false);
    public hasAllTeams = ko.observable(false);
    public businessEntityId = ko.observable(null);
    public accessToken = ko.observable('');
    public allowEditEmployeeCode = ko.observable(true);
    public organizationalTeamId: KnockoutObservable<number> = ko.observable(null);
    // #endregion

    // #region Observable Arrays and Arrays
    // User Lists
    public partnerUsers = ko.observableArray([]);
    public currentUserLevels = ko.observableArray([]);
    public availableUserLevels = ko.observableArray([]);
    // User Data
    public userSelectedLanguageIds = ko.observableArray([]);
    public userSelectedTicketTypeIds = ko.observableArray([]);
    public userSelectedTeamIds = ko.observableArray([]);
    public userSelectedTaskTypeIds = ko.observableArray([]);
    public userSelectedPermissions = ko.observableArray([]);
    // Static Lists
    public languages = ko.observableArray([]);
    public taskTypes = ko.observableArray([]);
    public teams = ko.observableArray([]);
    public ticketTypes = ko.observableArray([]);
    public permissions = ko.observableArray([]);
    public organizationalTeams: KnockoutObservableArray<Boo.OrganizationalTeams.Models.OrganizationalTeam> = ko.observableArray([]);
    // #endregion

    // #region Computeds
    public userMode = ko.computed(() => {
        return this.isInEditMode() ? 'Edit User' : 'Add User';
    });

    public languageSelectionIsValid = ko.computed(() => {
        return this.userSelectedLanguageIds().length > 0;
    });

    public hasCustomerServiceLevel = ko.computed(() => {
        return _.any(this.currentUserLevels(),
            (userLevel) => {
                return userLevel.id === this.accessLevels.ids['Customer Service'];
            });
    });

    public filteredTaskTypes = ko.computed(() => {
        return _.filter(this.taskTypes(),
            (taskType) => {
                return !unassignableTaskTypes.includes(taskType.TaskTypeEnum);
            });
    });
    // #endregion

    // #region Validation
    private emailAddressIsValid(val: any): any {
        return launchpad.utils.validation.emailAddressIsValid(val);
    }
    private validateEmployeeCode(val: any): any {
        let result = true;
        // Require only for boost partner
        const required = launchpad.config.partners.boostability === this.partnerId() && this.userIsActive();
        if (required) {
            result = $.trim(val) !== '';
        }
        return result;
    }
    public pageValidation = ko.validatedObservable(this);
    // #endregion

    // #region Functions
    public addTab(data: any): void {
        if (data.id == this.accessLevels.ids['Sales']) {
            bootbox.confirm('You are adding a Sales User Level, which cannot be deleted. Proceed?',
                (result: any) => {
                    if (result) {
                        this.add(data);
                    }
                });
        } else {
            this.add(data);
        }
    }

    public removeTab(event: any): void {
        const $elem = $(event.target);
        const id = $elem.data('id');
        bootbox.confirm('Are you sure you want to delete this User Level?',
            (response: any) => {
                if (response) {
                    this.remove(id);
                }
            });
    }

    public loadGenericUserInfo(user: any) {
        this.username(user.Username);
        this.userFirstName(user.FirstName);
        this.userLastName(user.LastName);
        this.userPhoneExtension(user.PhoneExtension);
        this.userIsActive(user.IsActive);
        const permissionIds = user.UserPermissions ? _.pluck(user.UserPermissions, 'PermissionId') : [];
        this.userSelectedPermissions(permissionIds);
        this.userSelectedLanguageIds(this.isDefined(user.Languages) ? _.pluck(ko.mapping.toJS(user.Languages), 'LanguageId') : []);
        this.businessEntityId(user.BusinessEntityId);
        this.isBusinessEntityLocked(user.BusinessEntityId && user.BusinessEntityId > 0);
        this.userEmployeeCode(user.EmployeeCode);
        if (this.userEmployeeCode()) {
            this.allowEditEmployeeCode(false);
        }
    }

    public isDefined(value: any) {
        return typeof value !== 'undefined' && value !== null;
    }

    public cancel(): void {
        if (this.dfd) {
            this.dfd.reject();
        }
    }

    public saveUpdateUser(): void {
        if (this.pageValidation.isValid()) {
            this.isLoading(true);
            this.save();
        } else {
            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
            this.pageValidation.errors.showAllMessages();
            this.isLoading(false);
        }
    }

    public canActivate(user: any) {
        this.currentUserId(user.UserId);
        return _.any(user.PartnerUsers,
            (item: any) => {
                return item.UserLevelId === 1 || item.UserLevelId === 5;
            });
    }

    public activate(options: any) {
        // Todo: fix the types. Comes in from selectedUser as a number and from options as a string.
        if (options.selectedUser != null) {
            this.userId(ko.utils.unwrapObservable(options.selectedUser.UserId));
        } else if (options.userId != null) {
            this.userId(options.userId);
        }
        this.isLoading(true);

        return $.when<any>(
            Utils.wrapDfd(this.permissionService.getUserAssignable()),
            Utils.wrapDfd(this.sessionStorageService.getPartner()),
            Utils.wrapDfd(this.partnerUserService.selectByUserId(this.userId())),
            Utils.wrapDfd(this.sessionStorageService.getStaticData()),
            Utils.wrapDfd(this.organizationalTeamService.getAssignment(this.userId())),
            Utils.wrapDfd(this.organizationalTeamService.getActiveTeams()),
            Utils.wrapDfd(this.frontService.getUserTeammateId(this.userId())),
            Utils.wrapDfd(this.frontService.getTeammates()))
            .then((
                assignablePermissions,
                currentPartner,
                partnerUsers,
                staticData,
                organizationalTeamAssignment: Boo.OrganizationalTeams.Models.OrganizationalTeamAssignment,
                organizationalTeams: Boo.OrganizationalTeams.Models.OrganizationalTeam[],
                frontTeammateId: string,
                frontTeammates: app.tsmodels.interfaces.FrontTeammate[]) => {
                
                this.permissions(assignablePermissions);

                this.partnerId(currentPartner.PartnerId);
                this.partnerName(currentPartner.Name);
                this.partnerAllowsAllTicketTypesPermission(this.partnerIdsThatAllowAllTicketTypesPermission.indexOf(currentPartner.PartnerId) !== -1);
                this.partnerAllowsAllTeamsPermission(this.partnerIdsThatAllowAllTeamsPermission.indexOf(currentPartner.PartnerId) !== -1);
                // Set some static data
                this.languages(staticData.Languages.filter((x: any) =>
                    x.SupportStatusId === window.launchpad.config.supportStatus.supported ||
                    x.SupportStatusId === window.launchpad.config.supportStatus.settingUp));
                this.ticketTypes(staticData.TicketTypes);
                this.teams(staticData.Teams);
                this.taskTypes(_.where(staticData.TaskTypes, { IsUIVisible: true }));
                this.businessEntities(staticData.BusinessEntities);
                if (_.isArray(partnerUsers) && partnerUsers.length > 0) {
                    this.partnerUsers(partnerUsers);
                    this.isInEditMode(true);
                    this.loadGenericUserInfo(partnerUsers[0]);
                    if (partnerUsers.some((x: any) => x.IsWriter === true)) {
                        toastr.error('This user cannot be edited.');
                        this.cancel();
                    }
                    let usPartnerUsers = _(partnerUsers);
                    if (usPartnerUsers.findWhere({ UserLevelId: 1 })) {
                        this.accessToken(usPartnerUsers.findWhere({ UserLevelId: 1 }).AccessToken);
                    } else if (usPartnerUsers.findWhere({ UserLevelId: 5 })) {
                        this.accessToken(usPartnerUsers.findWhere({ UserLevelId: 5 }).AccessToken);
                    } else if (usPartnerUsers.findWhere({ UserLevelId: 6 })) {
                        this.accessToken(usPartnerUsers.findWhere({ UserLevelId: 6 }).AccessToken);
                    } else if (usPartnerUsers.findWhere({ UserLevelId: 2 })) {
                        this.accessToken(usPartnerUsers.findWhere({ UserLevelId: 2 }).AccessToken);
                    } else if (usPartnerUsers.findWhere({ UserLevelId: 4 })) {
                        this.accessToken(usPartnerUsers.findWhere({ UserLevelId: 4 }).AccessToken);
                    }
                    _.each(partnerUsers,
                        (user: any) => {
                            const userLevel = {
                                id: user.UserLevelId,
                                name: this.accessLevels.names[user.UserLevelId]
                            };
                            this.currentUserLevels.push(userLevel);
                            this.userIsReadonly(this.userIsReadonly() || user.IsReadonly);
                            this.canViewAdultContent(user.CanViewAdultContent);
                            switch (user.UserLevelId) {
                                case window.launchpad.config.keys.seoSpecialistUserLevelId:
                                    const taskTypeIds = this.isDefined(user.TaskTypes) ? _.pluck(ko.mapping.toJS(user.TaskTypes), 'TaskTypeEnum') : [];
                                    this.userSelectedTaskTypeIds(taskTypeIds);
                                    break;
                                case window.launchpad.config.keys.salesUserLevelId:
                                    this.userIsReseller(user.IsReseller);
                                    break;
                                case window.launchpad.config.keys.managerUserLevelId:
                                    // No additional data is currently needed for managers. This case is here in case we want to add data later.
                                    break;
                                case window.launchpad.config.keys.customerServiceUserLevelId:
                                    this.userIsCsr(user.IsCsr);
                                    const ticketTypeIds = this.isDefined(user.TicketTypes) ? _.pluck(ko.mapping.toJS(user.TicketTypes), 'TicketTypeId') : [];
                                    this.userSelectedTicketTypeIds(ticketTypeIds);
                                    this.hasAllTicketTypes(this.hasUserLevelPermission(user, permissions.HasAllTicketTypes));
                                    const teamIds = this.isDefined(user.Teams) ? _.pluck(ko.mapping.toJS(user.Teams), 'TeamId') : [];
                                    this.userSelectedTeamIds(teamIds);
                                    this.hasAllTeams(this.hasUserLevelPermission(user, permissions.HasAllTeams));
                                    break;
                            }
                        });
                    _.each(this.accessLevels.ids,
                        (level) => {
                            if (!_.any(this.currentUserLevels(),
                                (currentUserLevel) => {
                                    return currentUserLevel.id === level;
                                })) {
                                let userLevel = null;
                                if (level === this.accessLevels.ids['Administrator'] && this.partnerId() === 1) {
                                    userLevel = {
                                        id: level,
                                        name: this.accessLevels.names[level]
                                    };
                                } else if (level !== 1) {
                                    userLevel = {
                                        id: level,
                                        name: this.accessLevels.names[level]
                                    };
                                }
                                if (userLevel !== null) {
                                    this.availableUserLevels.push(userLevel);
                                }
                            }
                        });
                } else {
                    this.isInEditMode(false);
                    _.each(this.accessLevels.ids,
                        (level) => {
                            const userLevel = {
                                id: level,
                                name: this.accessLevels.names[level]
                            };
                            this.availableUserLevels.push(userLevel);
                        });
                }

                this.organizationalTeams(organizationalTeams);
                if (organizationalTeamAssignment) {
                    this.organizationalTeamId(organizationalTeamAssignment.OrganizationalTeamId);
                }

                this.currentUserLevels(this.currentUserLevels.sort(this.sortUserLevels.bind(this)) as any);
                this.availableUserLevels(this.availableUserLevels.sort(this.sortUserLevels.bind(this)) as any);
                this.pageValidation.errors.showAllMessages(false);
                // update route
                let isExistingUser = this.userId() > 0;
                if (isExistingUser) {
                    this.viewLocatorService.setDisplayedRoute(`manager/edituser/${this.userId()}`);
                }

                this.frontTeammateId = frontTeammateId;
                this.frontTeammates = frontTeammates;

                return Utils.wrapDfd(this.partnerTicketTypeService.get(this.partnerId())).then((ticketTypes: any) => this.ticketTypes(ticketTypes));
            }).always(() => {
                this.isLoading(false);
            });
    }

    public ngAfterViewInit(): void {
        // Show a tab, if we have one available to show.
        if (this.currentUserLevels().length > 0) {
            $(`#${this.tabIdPrepend}${this.currentUserLevels()[0].id}`).addClass('active');
        }
    }

    public hasUserLevelPermission(user: any, permissionId: any) {
        return user &&
            user.UserPermissions &&
            _.any(user.UserPermissions,
                (p: any) => {
                    return p.PermissionId === permissionId;
                });
    }

    // #endregion
}
