/* tslint:disable */

import permissions from 'app/models/Permissions';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { environment } from '../../../environments/environment';
import { TicketService } from '../../../app/services/ticket.service';
import { SessionStorageService } from '../../../app/services/session-storage.service';
import { ReloadService } from '../../../app/services/reload.service';
import Utils from '../../../app/shared/utils';

export class HeaderViewModel {
  public isBillingVisible: any = ko.observable(true);
  public isSwitchPartnerVisible: any = ko.observable(true);
  public isSingleSignonRequired: any = ko.observable(false);
  public fullName: any = ko.observable('');
  public activeBasePath: any = ko.observable();
  public salesTabText: any = ko.observable('Sales');
  public partnerId: any = ko.observable();
  public partnerName: any = ko.observable('');
  public logoUrl: any = ko.observable();
  public isReadonly: any = ko.observable(false);
  public isSalesVisible: any = ko.observable(false);
  public isSpecialistVisible: any = ko.observable(false);
  public isCustomerServiceVisible: any = ko.observable(false);
  public isManagerVisible: any = ko.observable(false);
  public isInternalVisible: any = ko.observable(false);
  public isAdminUser: any = ko.observable(false);
  public testMessage: any = ko.observable(environment.testConfig.testMessage);
  public showTestMessage: any = ko.observable(environment.testConfig.showTestMessage);
  public workStoppageTickets: any = ko.observableArray([]);
  public validTicketTypeIds: any = ko.observableArray([]);
  public canViewMyTickets: any = ko.observable(false);
  public partnerGroups: any = ko.observableArray([]);
  private workBlockageInterval: any;
  private allowTimerStart = false;
  private hasAllTicketTypes = false;

  constructor(
    private app: any,
    private ticketService: TicketService,
    private sessionStorageService: SessionStorageService,
    private reloadService: ReloadService) { }

  public editTicket(ticket: any): void {
    PriorityViewModel.showTicket(ticket.CustomerId, ticket.TicketId);
  }

  public reloadWorkStoppageTickets: any = () => {
    Utils.wrapDfd(this.ticketService.selectWorkStoppage())
      .then((data: any) => {
        if (data !== null && data !== undefined && data.length > 0) {
          const tickets = (this.hasAllTicketTypes)
            ? data
            : _.filter(data,
              (ticket: any) => {
                return _.contains(this.validTicketTypeIds(), ticket.TicketTypeId);
              });
          this.workStoppageTickets(tickets);
        } else {
          this.workStoppageTickets([]);
        }
      }).fail((displayMessage) => {
        toastr.error(displayMessage);
      });
  };

  public customerServiceUrl: any = ko.computed(() => {
    return launchpad.config.partners.boostability === this.partnerId() || this.canViewMyTickets() ? '#/customerservice/helpnextcustomer' : '/#/customerservice/customersearch';
  });
  public isChangePasswordVisible: any = ko.observable(true);

  public isLoadingVisible: any = ko.computed(() => {
    return !this.isSalesVisible() &&
      !this.isSpecialistVisible() &&
      !this.isCustomerServiceVisible() &&
      !this.isManagerVisible() &&
      !this.isInternalVisible();
  });

  public refresh: any = () => {
    this.workStoppageTickets([]);
    this.allowTimerStart = true;
    this.isSalesVisible(false);
    this.isSpecialistVisible(false);
    this.isCustomerServiceVisible(false);
    this.isManagerVisible(false);
    this.isInternalVisible(false);
    return $.when<any>(
        Utils.wrapDfd(this.sessionStorageService.getUser()),
        Utils.wrapDfd(this.sessionStorageService.getPartner()),
        Utils.wrapDfd(this.sessionStorageService.getPartners()),
        Utils.wrapDfd(this.sessionStorageService.getPartnerUsers()),
        Utils.wrapDfd(this.sessionStorageService.getStaticData()))
      .then((user, partner, partners, partnerUsers, staticData) => {
        this.isAdminUser(_.any(partnerUsers,
          (pu: any) => {
            return pu.UserLevelId === 1;
          }));
        this.hasAllTicketTypes = launchpad.hasPermission(partner, partnerUsers, permissions.HasAllTicketTypes, user);
        this.validTicketTypeIds([]);
        this.partnerGroups(staticData.PartnerGroups);
        _.each(partnerUsers,
          (partnerUser: any) => {
            _.each(partnerUser.TicketTypes,
              (ticketType: any) => {
                this.validTicketTypeIds.push(ticketType.TicketTypeId);
              });
          });
        // Only for CSRs and users that have customer service user show work stoppage tickets
        const shouldShowWorkStoppage = _.any(partnerUsers,
          (partnerUser: any) => {
            return partnerUser.IsCsr && partnerUser.UserLevelId === window.launchpad.config.keys.customerServiceUserLevelId;
          });
        if (shouldShowWorkStoppage === true) {
          this.startWorkBlockageTicketTimer(window.launchpad.config.workStoppage.refreshFrequency);
        }
        this.fullName(user.FirstName + ' ' + user.LastName);
        // There needs to be a partner to go further here
        if (!partner) {
          return;
        }
        if (partner.LogoSmallS3Url && partner.LogoSmallS3Url.length > 0) {
          this.logoUrl(partner.LogoSmallS3Url);
        } else {
          this.logoUrl('https://s3staticfiles.s3.amazonaws.com/logos/1/318f410e-7bcf-4feb-9632-e27b58d17d1a.png');
        }
        this.partnerId(partner.PartnerId);
        this.partnerName(partner.Name);
        if (partners.length > 1) {
          this.isSwitchPartnerVisible(true);
        } else {
          this.isSwitchPartnerVisible(false);
        }
        // Show/Hide tabs based on the Partner Users found in the user passed in
        const applicablePartnerUsers = _.filter(user.PartnerUsers,
          (item: any) => {
            return item.PartnerId === partner.PartnerId;
          });
        const isSensis = window.launchpad.utils.partnerInPartnerGroup(partner.PartnerId, window.launchpad.config.PartnerGroupEnum.Yellow, this.partnerGroups());
        this.isChangePasswordVisible(!isSensis);
        _.each(applicablePartnerUsers,
          (pu) => {
            if (pu.UserLevelId === 4) {
              this.isSalesVisible(true);
              if (pu.IsReseller) {
                this.salesTabText('Reseller');
              } else {
                this.salesTabText('Sales');
              }
            } else if (pu.UserLevelId === 2 && partner.PartnerId === 1) {
              this.isSpecialistVisible(true);
            } else if (pu.UserLevelId === 6) {
              this.isCustomerServiceVisible(true);
            } else if (pu.UserLevelId === 5 || pu.UserLevelId === 1) {
              this.isManagerVisible(true);
              if (pu.UserLevelId === 1 && pu.PartnerId === 1) // Only Boostability Admins can see Admin tab
              {
                this.isInternalVisible(true);
              }
          }
        });
    });
  };

  public getSearchOptions: any = (query: any, process: any) => {
    const options = [];
    if (query.length > 2) {
      const isNumber = !isNaN(query);
      if (isNumber === true) {
        options.push(`Go to Customer: ${query}`);
        if (this.isAdminUser()) {
          options.push(`Go to User: ${query}`);
        }
      }
      if (this.isCustomerServiceVisible() === true) {
        options.push(`Search Customers for: ${query}`);
      }
      if (this.isManagerVisible() === true) {
        options.push(`Search Users for: ${query}`);
      }
    } else {
      options.push(query + ' (minimum 3 characters)');
    }
    process(options);
  };

  public changePassword: any = () => {
    PriorityViewModel.show('app-components-changepassword', {}, false);
  };

  public workStoppageTicketsToShow: any = ko.computed(() => {
    return _.first(this.workStoppageTickets(), 5);
  });

  public workStoppageTicketsShown: any = ko.computed(() => {
    return this.workStoppageTicketsToShow().length;
  });
  private searchTerm: any;

  public searchOptionSelected: any = (item: any) => {
    // if reload is required, store item and reload. item will be read after reload in activate().
    if (this.reloadService.isReloadRequired()) {
      this.reloadService.storeDataForReload(item);
      this.reloadService.reload(`Auto reload from header search: ${item}`);
      return;
    }
    const searchPrefix = 'Go to Customer: ';
    if (item.indexOf(searchPrefix) > -1) {
      let customerId = item.replace(searchPrefix, '');
      if (isNaN(customerId) === false) {
        customerId = parseFloat(customerId);
        if (isNaN(customerId) || customerId > 2147483647) { // must be less than maxsize of an int
          toastr.error('Invalid Customer ID');
          return;
        }
      }
      PriorityViewModel.showManageCustomer(customerId);
    } else if (item.indexOf('Go to User:') > -1) {
      const userId = item.replace('Go to User: ', '');
      if (isNaN(userId) === false) {
        const number = parseFloat(userId);
        if (isNaN(number) || number > 2147483647) { // must be less than maxsize of an int
          toastr.error('Invalid User ID');
          return;
        }
      }
      PriorityViewModel.show('app-components-manager-partneruser', { userId: userId });
    } else if (item.indexOf('Search Customers for:') > -1) {
      this.searchTerm = item.replace('Search Customers for: ', '');
      this.app.setLocation(`customerservice/customersearch/${this.searchTerm}`);
    } else if (item.indexOf('Search Users for:') > -1) {
      this.searchTerm = item.replace('Search Users for: ', '');
      this.app.setLocation(`manager/manageusers/${this.searchTerm}`);
    }
  };

  public urlChanged: any = (newUrl: any) => {
    newUrl = newUrl.replace('#/', '');
    this.activeBasePath(newUrl.substr(0, newUrl.indexOf('/')));
    // Put this here to make sure that the typeahead actually works
    $('#header-search').typeahead({
      source: this.getSearchOptions,
      updater: this.searchOptionSelected
    });
  };

  public logOut: any = () => {
    this.logOutFromGoogle();
    clearInterval(this.workBlockageInterval);
    this.allowTimerStart = false;
    this.app.setLocation('logout');
  };

  public logOutFromGoogle: any = () => {
    google.accounts.id.disableAutoSelect();
  }

  public switchPartner: any = () => {
    clearInterval(this.workBlockageInterval);
    this.allowTimerStart = false;
    this.app.setLocation('switchpartner');
  };

  public sessionHasExpired: any = () => {
    clearInterval(this.workBlockageInterval);
    this.allowTimerStart = false;
  };

  public activate(): JQueryPromise<any> {
    google.accounts.id.initialize(null);
    this.refresh().then(() => {
      // auto search after reload if needed
      // hackety hack: do this in a timeout just to let things finish loading a little. this is not absolutely necessary,
      // but is a preventative measure as we've had lots of bugs based on not waiting for promises and expecting
      // things to be loaded. since this could run on so many different pages, the timeout is some extra safety.
      setTimeout(() => {
        const searchOptionForReload = this.reloadService.getDataStoredForReload();
        if (searchOptionForReload) {
          this.searchOptionSelected(searchOptionForReload);
        }
      },
        500);
    });
    return $.when<any>(
      Utils.wrapDfd(this.sessionStorageService.getPartner()),
      Utils.wrapDfd(this.sessionStorageService.getPartnerUsers()),
      Utils.wrapDfd(this.sessionStorageService.getUser()))
      .done((partner, partnerUsers, user) => {
        this.canViewMyTickets(launchpad.hasPermission(partner, partnerUsers, permissions.CanViewMyTickets, user));
      });
  };
  private scheduledRefreshTime: any;

  public startWorkBlockageTicketTimer: any = (refreshFrequency: any) => {
    if (_.isUndefined(this.scheduledRefreshTime)) {
      this.scheduledRefreshTime = moment(); // Save first Scheduled Refresh Time
    }
    const secondsSinceLastScheduledStart = this.scheduledRefreshTime.diff(moment(), 's');
    let secondsTillNextScheduledStart = secondsSinceLastScheduledStart + 5;
    if (secondsTillNextScheduledStart >= 0) {
      this.scheduledRefreshTime = this.scheduledRefreshTime.add(secondsTillNextScheduledStart, 's');
    } else {
      this.scheduledRefreshTime = moment().add(5, 's');
      secondsTillNextScheduledStart = 5;
    }
    setTimeout(() => {
      if (this.allowTimerStart === true) {
        this.reloadWorkStoppageTickets();
        clearInterval(this.workBlockageInterval);
        this.workBlockageInterval = setInterval(this.reloadWorkStoppageTickets, refreshFrequency);
      }
    },
      (secondsTillNextScheduledStart * 1000));
  };
}
