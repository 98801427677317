<div [loadingOverlay]="isLoading">
  <div class="row">
    <div class="col-md-12">
      <div *ngIf="currentAction().Customer.SeoContentSurvey.Limitations">
        <label class="control-label">Restrictions</label>
        <div class="alert alert-info">{{currentAction().Customer.SeoContentSurvey.Limitations}}</div>
      </div>
      <div *ngIf="currentAction().Customer.SeoContentSurvey.BlogConsiderations">
        <label class="control-label">Onsite Blog Restrictions and Considerations</label>
        <div class="alert alert-info">{{currentAction().Customer.SeoContentSurvey.BlogConsiderations}}</div>
      </div>
      <form role="form">
        <div class="form-group" [formValidation]="pageTitle">
          <label class="control-label">Page Title</label>
          <p class="float-end help-block">Condense the title within the blog content to provide an effective Page Title, please then add Company Name, separated by a pipe.</p>
          <input type="text" class="form-control" placeholder="Enter Page Title" [formControl]="pageTitle"/>
        </div>
        <div class="form-group" [formValidation]="metaDescription">
          <label class="control-label">Meta Description</label>
          <p class="float-end help-block">This will be auto-populated as you write the Page Title and Content. Please review for any truncated words, and ensure it does not terminate inappropriately.</p>
          <input type="text" class="form-control" placeholder="Enter Meta Description" [formControl]="metaDescription"/>
        </div>
        <div class="form-group" [formValidation]="contentTitle">
          <label class="control-label">Title / H1</label>
          <input type="text" class="form-control" placeholder="Enter Title/H1" [formControl]="contentTitle"/>
        </div>
        <div class="form-group" [formValidation]="contentTitle2">
          <label class="control-label">H2</label>
          <input type="text" class="form-control" placeholder="Enter H2" [formControl]="contentTitle2" />
        </div>
        <div class="form-group">
          <label class="control-label">Content</label>
          <span class="float-end help-block">{{requiredWordCount()}} word minimum</span>
          <div class="clear-fix"></div>
          <div [formValidation]="content">
            <quill-editor [(formControl)]="content" [modules]="quillModules" (onEditorCreated)="contentEditorCreated($event)"></quill-editor>
          </div>
        </div>
        <div [formValidation]="duplicateContentChecked">
          <app-components-content-audit [content]="content.value" [isVisible]="content.valid" [delay]="30" (contentChecked)="duplicateContentChecked.patchValue(true)"></app-components-content-audit>
        </div>
        <div class="card card-info">
          <div class="card-header">
            <a data-toggle="collapse" href="#previousTasks">
              <h5>Previous Articles</h5>
            </a>
          </div>
          <div id="previousTasks" class="collapse show">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <app-table [items]="otherContentPosts" [pageSize]="5" [exportFilename]="'content-posts'">
                    <ng-template #tableHeader>
                      <tr>
                        <th export sort desc field="TaskDate">Date</th>
                        <th export sort field="Topic">Topic & Instructions</th>
                        <th export field="Instructions">Instructions</th>
                        <th export sort field="TaskStatus">Status</th>
                      </tr>
                    </ng-template>
                    <ng-template #tableBody let-post>
                      <tr>
                        <td style="width: 20%;">{{post.TaskDate | dateText }}</td>
                        <td style="width: 60%;">
                          <a [attr.data-target]="'#instr' + post.TaskId.toString()" data-toggle="collapse" style="cursor: pointer;">
                            <span>{{post.Topic}}</span>
                          </a>
                          <div [attr.id]="'instr' + post.TaskId.toString()" class="card-collapse collapse">
                            <br/>
                            <span>{{post.Instructions}}</span>
                          </div>
                        </td>
                        <td></td>
                        <td style="width: 20%;">{{post.TaskStatus}}</td>
                      </tr>
                    </ng-template>
                  </app-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <div class="card card-info">
          <div class="card-header">
            <a data-toggle="collapse" href="#pastImages">
              <h5>8 Month Image History</h5>
            </a>
          </div>
          <div id="pastImages" class="collapse show">
            <div class="card-body">
              <div class="row" style="margin: 0px 10px">
                <ng-container *ngFor="let $data of pastImageUrls">
                    <img src={{$data}} style="max-height: 120px; max-width: 24%; object-fit: cover; margin: 2px"/>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Image</label>
          <ng-container *ngIf="!imageUrl.value">
            <div>
              <button [formValidation]="imageUrl" (click)="uploadImage()" class="btn btn-sm">
                <span class="fa fa-camera"></span>
                Upload
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="imageUrl.value">
            <div class="btn-group">
              <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown">
                <img [attr.src]="imageUrl.value" class="img-fluid"/>
              </button>
              <ul class="dropdown-menu">
                <li class="dropdown-item">
                  <a (click)="uploadImage()" href="javascript:void(0)">
                    <span class="fa fa-camera"></span>
                    Upload
                  </a>
                </li>
                <li role="separator" class="dropdown-divider"></li>
                <li class="dropdown-item">
                  <a (click)="removeImage()" href="javascript:void(0)">Remove</a>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
  <app-floating-panels>
    <app-floating-panel
      [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
      <app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="taskDetails.TaskNotes">
      </app-components-specialist-actioncomponents-comments>
    </app-floating-panel>
  </app-floating-panels>
</div>
