<div>
  <app-components-togglepanel isOpen="true" title="Retention">
    <ng-container *ngIf="formIsReady">
      <div class="row">
        <div class="col-md-5">
          <div class="form-group">
            <label class="control-label">Reason for Cancellation Request</label>
            <ng-select [items]="displayRetentionReasons" placeholder="Choose..." bindLabel="Name" [clearable]="false"
              [formControl]="retentionReasonCtrl" [virtualScroll]="true"
              [searchable]="true" [searchFn]="searchFn" [groupBy]="getParentName"
              bindLabel="Name" lpValidationMsg>
              <ng-template ng-option-tmp let-item="item">
                <div>
                  <span class="ml-2">- {{item.Name}}</span>
                </div>
              </ng-template>
            </ng-select>
            <ng-container *ngIf="retentionReasonCtrl.value">
              <span><b>{{ getParentName(retentionReasonCtrl.value) }}</b> -> {{ retentionReasonCtrl.value.Name }}</span><br>
              <small>{{ retentionReasonCtrl.value.Description }}</small>
            </ng-container>
          </div>
          <div class="form-group">
            <label class="control-label">Resolution Type</label>
            <ng-select [items]="retentionResolutionTypes" placeholder="Choose..." bindLabel="Name" [clearable]="false"
              bindValue="RetentionResolutionTypeId" [formControl]="retentionResolutionTypeIdCtrl" [virtualScroll]="true"
              lpValidationMsg></ng-select>
          </div>
          <div class="form-group">
            <label class="control-label">Contact Customer at Future Date?</label>
            <ng-select [items]="canContactInMonthsOptions" bindLabel="Name" [clearable]="false"
              [readonly]="canContactInMonthsCtrlIsReadOnly" bindValue="Value" [formControl]="canContactInMonthsCtrl"
              [virtualScroll]="true" lpValidationMsg></ng-select>
          </div>
          <div class="form-group" *ngIf="!workblockAdded else workblockExists">
            <button class="btn btn-primary" (click)="createWorkblock()" [disabled]="isLoading()"><i class="fas fa-ban"></i>Add Workblock</button>
            <span>* Stops all work from being fulfilled for this customer and cancels all current subscriptions.</span>
          </div>
        </div>
      </div>
    </ng-container>
  </app-components-togglepanel>
</div>

<ng-template #workblockExists>
  <div class="alert alert-success">
    <span>Workblock added <i class="far fa-thumbs-up"></i></span><br>
    <span>Work will not be fulfilled for this customer and current subscriptions have been canceled.</span>
  </div>
</ng-template>
