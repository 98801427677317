<div [loadingOverlay]="isLoading">
  <div class="row">
    <div class="col-md-12">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Description</strong>
            </div>
            <div class="col-md-10">
              {{ taskDescription }} Please implement the attached copy to the following page as body copy on the URL below. If the page does not currently exist, create the page using the path indicated by the URL. For more specific instruction, see the notes from the CSR.
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Instructions</strong>
            </div>
            <div class="col-md-10 preserve-whitespace">{{ taskInstructions }}</div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Page Type</strong>
            </div>
            <div class="col-md-10">
              {{ pageType }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>URL</strong>
            </div>
            <div class="col-md-10">
              <a [externalLink]="{ url: url, text: url}" rel="noreferrer" style="cursor: pointer;"></a>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Content</strong>
            </div>
            <div class="col-md-10">
              <div class="onsiteCopyContent" [innerHTML]="content"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New Title</strong>
            </div>
            <div class="col-md-10">
              {{ newTitle }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New Description</strong>
            </div>
            <div class="col-md-10">
              {{ newDescription }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New H1</strong>
            </div>
            <div class="col-md-10">
              {{ newH1 }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New H2</strong>
            </div>
            <div class="col-md-10">
              {{ newH2 }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <br />
  <br />
  <div class="row">
    <div class="col-md-6">
      <div>
        <app-components-specialist-taskfiles [options]="{
          taskId: currentAction().Task.TaskId,
          sources: taskFileSources,
          allowUpload: true,
          apiCallback: taskFilesApiCallback,
          useSmallTitle: true
        }"></app-components-specialist-taskfiles>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          Publish URL
        </div>
        <div class="card-body">
          <input class="form-control" [formControl]="publishUrlCtrl" lpValidationMsg />
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          Note to Reviewer
        </div>
        <div class="card-body">
          <textarea class="form-control" style="min-width: 100%" [formControl]="noteToReviewerCtrl" rows="5"
            lpValidationMsg></textarea>
        </div>
      </div>
    </div>
  </div>
  <app-floating-panels *ngIf="!isLoading">
    <app-floating-panel [displayConfig]="{ title: 'Information', icon: 'fa-info-circle', size: 'floating-panel-md' }">
      <compose *floatingPanelContent Selector="app-components-managecustomer-readonlyexternalaccess" [Model]="{ customer: currentAction().Customer }">
      </compose>
    </app-floating-panel>
    <app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
      <app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="currentAction().TaskDetails.TaskNotes">
      </app-components-specialist-actioncomponents-comments>
    </app-floating-panel>
    <app-floating-panel *ngIf="rejection" [displayConfig]="{ title: 'Rejection Information', icon: 'fa-clipboard'}">
      <app-components-specialist-actioncomponents-rejection *floatingPanelContent [rejection]="rejection.Rejection">
      </app-components-specialist-actioncomponents-rejection>
    </app-floating-panel>
  </app-floating-panels>
</div>
