import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { KeywordSuggestionConfig } from 'app/models/KeywordSuggestionConfig';
import { KeywordSuggestionConfigFactory } from '../../../../factories/KeywordSuggestionConfigFactory';

@Component({
  selector: 'app-components-shared-keywords-keyword-research-summary',
  templateUrl: './keyword-research-summary.component.html'
})
export class KeywordResearchSummaryComponent implements OnInit {
  @Input() customer: Boo.Objects.Customer;
  @Input() isReadOnly: boolean = false;
  @Output() isNationalChanged = new EventEmitter<boolean>();
  keywordSuggestionConfig: KeywordSuggestionConfig;
  keywordTotal: number;

  constructor(private keywordSuggestionConfigFactory: KeywordSuggestionConfigFactory) {}

  ngOnInit(): void {
    this.updateCounts();
  }

  setIsNational(isNational: boolean): void {
    this.customer.IsCampaignNational = isNational;
    this.updateCounts();
    this.isNationalChanged.emit(isNational);
  }

  updateCounts(): void {
    this.keywordSuggestionConfig = this.keywordSuggestionConfigFactory.create(this.customer.SeoMinutesPaidRecurring, this.customer.IsCampaignNational, this.customer.PartnerId);
    this.keywordTotal = this.keywordSuggestionConfig.minimumHaloKeywordSuggestions + this.keywordSuggestionConfig.minimumMainKeywords;
  }
}
