<div [loadingOverlay]="isLoading()" class="card">
	<div class="card-header">
		<span>{{title}}</span>
		<button (click)="close()" class="btn-close" title="Close"></button>
	</div>
	<div class="card-body">
		<ng-container *ngIf="verticalTranslation()">
			<div class="form-group">
				<label class="control-label">Language</label>
				<select [dataValidation]="{ placement: 'top', value: verticalTranslation().LanguageId }" [ngModel]="verticalTranslation().LanguageId()" (ngModelChange)="verticalTranslation().LanguageId($event)" [ngModelOptions]="{ standalone: true }" [disabled]="editMode()" class="form-select">
					<option *ngFor="let option of languages()" [ngValue]="option.LanguageId">{{ option.Name }}</option>
				</select>
			</div>
			<div class="form-group">
				<label class="control-label">Translation</label>
				<input [dataValidation]="{ placement: 'top', value: verticalTranslation().Name }" [ngModel]="verticalTranslation().Name()" (ngModelChange)="verticalTranslation().Name($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
			</div>
		</ng-container>
	</div>
	<div class="card-footer text-end">
		<button (click)="cancel()" class="btn btn-link">Cancel</button>
		<button (click)="save()" class="btn btn-success">
			<i class="fa fa-save"></i>
			Save
		</button>
	</div>
</div>
