<div>
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h4 id="skip-customer-label" class="modal-title">Reject Task</h4>
				<button (click)="cancel()" type="button" class="btn-close" title="Close"></button>
			</div>
			<div class="modal-body">
				<div class="form-group">
					<label class="control-label">Comment</label>
					<textarea [formControl]="comment" class="form-control" id="description"
						name="description"></textarea>
				</div>
			</div>
			<div class="modal-footer">
				<button (click)="cancel()" class="btn btn-link" aria-hidden="true">Cancel</button>
				<button (click)="reject()" class="btn btn-danger">
					<i class="fa fa-times"></i>
					Reject
				</button>
			</div>
		</div>
	</div>
</div>