export default class SeoContentSurvey implements Boo.Objects.SeoContentSurvey {
    public ShouldPostBlogsForCustomer: boolean;
    public SeoContentSurveyId: number;
    public CustomerId: number;
    public HasPhysicalLocation: boolean;
    public History: string;
    public BusinessDescription: string;
    public Limitations: string;
    public Demographic: string;
    public SellingPoints: string;
    public Competition: string;
    public BlogConsiderations: string;
    public PublishUser: string;
    public BlogUrl: string;
    public BlogLoginUrl: string;
    public BlogAdminUsername: string;
    public BlogAdminPassword: string;
    public BlogEditorUsername: string;
    public BlogEditorPassword: string;
    public CmsLoginUrl: string;
    public CmsUsername: string;
    public CmsPassword: string;
    public HostingLoginUrl: string;
    public HostingUsername: string;
    public HostingPassword: string;
    public InsertedDate: Date;
    public Persona: string;
    public GoalsTactics: string;
    public ContactUsUrl: string;
    public ShouldUseContactUrl: boolean;
    public ProfileThemeColors: string;
    public ProfileThemeColorsToAvoid: string;
    public YearFounded: number;
    public MarketToBusiness: boolean;

    constructor() {
        this.BlogAdminPassword = null;
        this.BlogAdminUsername = null;
        this.BlogConsiderations = null;
        this.BlogEditorPassword = null;
        this.BlogEditorUsername = null;
        this.BlogLoginUrl = null;
        this.BlogUrl = null;
        this.BusinessDescription = null;
        this.CmsLoginUrl = null;
        this.CmsPassword = null;
        this.CmsUsername = null;
        this.ContactUsUrl = null;
        this.CustomerId = 0;
        this.SeoContentSurveyId = 0;
        this.Demographic = null;
        this.GoalsTactics = null;
        this.HostingLoginUrl = null;
        this.HostingPassword = null;
        this.HostingUsername = null;
        this.InsertedDate = null;
        this.Limitations = null;
        this.Persona = null;
        this.ProfileThemeColors = null;
        this.ProfileThemeColorsToAvoid = null;
        this.PublishUser = null;
        this.SellingPoints = null;
        this.ShouldPostBlogsForCustomer = null;
        this.ShouldUseContactUrl = false;
        this.YearFounded = 0;
        this.HasPhysicalLocation = true;
        this.MarketToBusiness = false;
    }
}
