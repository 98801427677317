<div class="card">
  <div class="card-header">
    Pending Work
  </div>
  <div [loadingOverlay]="isLoading" class="card-body">
    <div class="row">
      <div class="col-md-3 offset-md-9 text-end">
        <div class="input-group">
          <input [formControl]="customerIdCrtl" [returnKey]="getTasksForCustomer.bind(this)" type="number" class="form-control" placeholder="Customer ID" />
          <button [disabled]="isLoading" (click)="getTasksForCustomer()" class="btn btn-success">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-table [items]="tasks" [pageSize]="50" [exportFilename]="'pending-work'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="Status">Status</th>
              <th export sort field="TaskName">Name</th>
              <th export sort field="LanguageName">Language</th>
              <th export sort field="TaskId">Task Id</th>
              <th export sort field="InsertedDate">Inserted Date</th>
              <th export sort field="TaskDate">Task Date</th>
              <th export sort field="StatusDate">Status Date</th>
              <th export sort field="Keyword">Keyword</th>
              <th export sort field="Area">Area</th>
              <th export sort field="IsAreaFirst">Is Area First?</th>
              <th export sort field="Url">URL</th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-task>
            <tr>
              <td>{{task.Status}}</td>
              <td>
                <ng-container *ngIf="task.CustomName">
                  <span>{{task.CustomName}}</span>
                </ng-container>
                <ng-container *ngIf="!(task.CustomName)">
                  <span>{{task.TaskName}}</span>
                </ng-container>
              </td>
              <td>{{task.LanguageName}}</td>
              <td>{{task.TaskId}}</td>
              <td>{{task.InsertedDate | dateText }}</td>
              <td>{{task.TaskDate | dateText }}</td>
              <td>{{task.StatusDate | dateText }}</td>
              <td>{{task.Keyword}}</td>
              <td>{{task.Area}}</td>
              <td>{{task.IsAreaFirst| yesNo}}</td>
              <td>
                <a [attr.href]="task.Url" target="_blank" rel="noreferrer">{{task.Url}}</a>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>