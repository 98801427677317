import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerPricingToolPartnerHeadingGroupService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/partnerpricingtoolpartnerheadinggroups`;

  constructor(private http: HttpClient) { }

  getByPartnerId(partnerId: number): Observable<Boo.Objects.SEOPresence.PartnerPricingToolPartnerHeadingGroup[]> {
    const params = new HttpParams().set('partnerId', partnerId);

    return this.http.get<Boo.Objects.SEOPresence.PartnerPricingToolPartnerHeadingGroup[]>(`${this.baseUrl}/GetByPartnerId`, { params: params });
  }

  replaceAllForPartnerId(partnerPricingToolHeadingGroupIds: number[], partnerId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/ReplaceAllForPartnerId`, {
        partnerPricingToolHeadingGroupIds: partnerPricingToolHeadingGroupIds,
        partnerId: partnerId
    });
  }
}
