/* tslint:disable */

import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-components-progressrating',
	templateUrl: './ProgressRating.component.html'
})
export class ProgressRatingComponent {
  @Input() public Value: number;
}
