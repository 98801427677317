import { Component, OnInit, ViewChild } from '@angular/core';
import { SessionStorageService } from '../../../../services/session-storage.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject, forkJoin } from 'rxjs';
import { UrlSuggestionsComponent } from '../../../shared/Keywords/url-suggestions/url-suggestions.component';
import { KeywordSuggestionService } from '../../../../services/keyword-suggestion.service';
import { KeywordResearchNoteComponent } from '../KeywordResearchNote/KeywordResearchNote.component';
import KeywordSuggestionStatus = Boo.Objects.Enums.KeywordSuggestionStatus;
import { HaloKeywordSuggestionsComponent } from '../../../shared/Keywords/halo-keyword-suggestions/halo-keyword-suggestions.component';
import System from 'framework/System';
import { finalize } from 'rxjs/operators';
import { CustomerService } from '../../../../services/customer.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import CustomValidators from '../../../../shared/custom-validators';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { KeywordSuggestionConfig } from 'app/models/KeywordSuggestionConfig';
import { KeywordSuggestionConfigFactory } from '../../../../factories/KeywordSuggestionConfigFactory';
import { FloatingPanelsComponent } from '../../../../shared/components/floating-panels/floating-panels.component';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

type KeywordResearchStrategyUpdateInput = Boo.Tasks.Inputs.Models.KeywordResearchStrategyUpdateInput;
type KeywordResearchStrategyUpdateOutput = Boo.Tasks.Outputs.Models.KeywordResearchStrategyUpdateOutput;

@Component({
  selector: 'app-components-specialist-actioncomponents-keyword-research-strategy-update',
  templateUrl: './keyword-research-strategy-update.component.html'
})
export class KeywordResearchStrategyUpdateComponent implements IActivatable, IActionWorkspace, OnInit {
  isLoading: boolean = false;
  panelsLoading: boolean = false;
  customer: Boo.Objects.Customer;
  taskId: number;
  isLocalCampaign: boolean = undefined;
  keywordSuggestionConfig: KeywordSuggestionConfig;
  totalPageCount: number;
  totalKeywordCount: number;
  haloKeywordCount: number;
  verticals: Boo.Objects.Vertical[] = [];
  countries: Boo.Objects.Country[] = [];
  verticalHelpTextKey: string = '';
  selectedCountryId: UntypedFormControl = new UntypedFormControl('', { validators: [Validators.required] });
  selectedVerticalId: UntypedFormControl = new UntypedFormControl('', { validators: [Validators.required] });
  urlSuggestionAdded: Subject<Boo.Objects.LegacyUrlSuggestion[]> = new Subject<Boo.Objects.LegacyUrlSuggestion[]>();
  haloKeywordSuggestionsAdded: ReplaySubject<Boo.Objects.LegacyKeywordSuggestion[]> = new ReplaySubject<Boo.Objects.LegacyKeywordSuggestion[]>();
  historicalKeywordSuggestions: Boo.Objects.LegacyKeywordSuggestion[] = [];
  keywordSuggestionTypes: Boo.Objects.KeywordSuggestionType[];
  formGroup: UntypedFormGroup = new UntypedFormGroup({ countryId: this.selectedCountryId, verticalId: this.selectedVerticalId })
  newPageIsExisting?: boolean;
  newPageControl: UntypedFormControl = new UntypedFormControl('', [CustomValidators.validUrl(), Validators.required, Validators.maxLength(512)]);
  newPageFormGroup: UntypedFormGroup = new UntypedFormGroup({ newPage: this.newPageControl });
  input: KeywordResearchStrategyUpdateInput;
  output: KeywordResearchStrategyUpdateOutput;
  unorderedUrls: Boo.Objects.WebsiteUrl[] = [];
  activeHaloKeywords: Boo.Objects.WebsiteKeywordTracking[] = [];
  activeHaloKeywordsCount: number = 0;

  private keywordResearchNoteComponent: KeywordResearchNoteComponent;
  keywordResearchNoteCallback = (keywordResearchNoteComponent: KeywordResearchNoteComponent) => {
    this.keywordResearchNoteComponent = keywordResearchNoteComponent;
  }
  @ViewChild(UrlSuggestionsComponent) urlSuggestionComponent: UrlSuggestionsComponent;
  @ViewChild(HaloKeywordSuggestionsComponent) haloSuggestionComponent: HaloKeywordSuggestionsComponent;
  @ViewChild(FloatingPanelsComponent) floatingPanelsComponent: FloatingPanelsComponent;

  constructor(
    private sessionStorageService: SessionStorageService,
    private keywordSuggestionService: KeywordSuggestionService,
    private customerService: CustomerService,
    private modalService: NgbModal,
    private keywordSuggestionConfigFactory: KeywordSuggestionConfigFactory,
    private customerCampaignService: CustomerCampaignService
  ) { }

  // Declared but intentionally not used in this component
  actionViewModel: KnockoutObservable<IActionWorkspace>;

  currentAction: KnockoutObservable<Boo.Objects.Action>;

  isStepReadyToReject(): boolean {
    return false;
  }

  isStepReadyToSave(): JQueryPromise<boolean> {
    return this.validateAndSave(SaveTypes.Update);
  }

  isStepReadyToComplete(): boolean | JQueryPromise<boolean> {
    return this.validateAndSave(SaveTypes.Complete);
  }

  isStepReadyToDelete(): boolean {
    return false
  }

  canActivate?(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: IActionWorkspaceActivationParams): boolean {
    this.currentAction = params.currentAction;
    this.input = params.input as KeywordResearchStrategyUpdateInput;
    this.output = params.output as KeywordResearchStrategyUpdateOutput;
    params.controlViewModel(this);
    return true;
  }

  ngOnInit(): void {
    this.isLoading = true;
    
    forkJoin([
      this.sessionStorageService.getStaticData(),
      this.customerService.get(this.input.Customer.CustomerId),
      this.keywordSuggestionService.getSummary(this.input.Customer.CustomerId),
      this.customerCampaignService.getWebsiteUrls(this.input.Customer.CustomerId, true, this.customerCampaignService.activeStatuses()), // excludeInactiveKeywords: true
      this.customerCampaignService.getActiveHaloKeywords(this.input.Customer.CustomerId)
    ])
    .pipe(finalize(() => {
      this.isLoading = false;
    }))
    .subscribe(([staticData, customer, keywordSuggestionSummary, activeUrls, activeHaloKeywords]): void => {
      this.customer = customer;
      this.isLocalCampaign = !this.customer.IsCampaignNational;
      this.taskId = this.currentAction().Task.TaskId;
      this.keywordSuggestionTypes = staticData.KeywordSuggestionTypes;
      keywordSuggestionSummary.HaloKeywordSuggestions = keywordSuggestionSummary.HaloKeywordSuggestions.filter(x => x.KeywordSuggestionStatusId !== KeywordSuggestionStatus.Historic);
      keywordSuggestionSummary.HaloKeywordSuggestions.map(suggestion => suggestion.KeywordSuggestionType = this.keywordSuggestionTypes.find(x => x.KeywordSuggestionTypeId === suggestion.KeywordSuggestionTypeId));
      keywordSuggestionSummary.HistoricalKeywordSuggestions.map(suggestion => suggestion.KeywordSuggestionType = this.keywordSuggestionTypes.find(x => x.KeywordSuggestionTypeId === suggestion.KeywordSuggestionTypeId));
      keywordSuggestionSummary.UrlSuggestions.flatMap(x => x.KeywordSuggestions).map(suggestion => suggestion.KeywordSuggestionType = this.keywordSuggestionTypes.find(x => x.KeywordSuggestionTypeId === suggestion.KeywordSuggestionTypeId));
      this.urlSuggestionAdded.next(keywordSuggestionSummary.UrlSuggestions);
      this.haloKeywordSuggestionsAdded.next(keywordSuggestionSummary.HaloKeywordSuggestions);
      this.historicalKeywordSuggestions = keywordSuggestionSummary.HistoricalKeywordSuggestions;
      this.historicalKeywordSuggestions.map(suggestion => suggestion.KeywordSuggestionType = this.keywordSuggestionTypes.find(x => x.KeywordSuggestionTypeId === suggestion.KeywordSuggestionTypeId));
      this.verticals = staticData.Verticals;
      this.countries = staticData.Countries;
      this.selectedCountryId.setValue(this.customer.CountryId > 0 ? this.customer.CountryId : undefined);
      this.selectedVerticalId.setValue(this.customer.VerticalId > 0 ? this.customer.VerticalId : undefined);
      this.newPageControl.addValidators(CustomValidators.domainMustMatch(customer.Url, `Must contain customer's base domain (${customer.Url})`))
      this.unorderedUrls = activeUrls.filter(x => !this.input.OrderedWebsiteUrlIds.includes(x.WebsiteUrlId));
      this.activeHaloKeywords = activeHaloKeywords;
      // Exclude keywords that are already on the first page since they do not count against the limit in keywords and urls
      this.activeHaloKeywordsCount = this.activeHaloKeywords.filter(x => !x.WasFirstPage).length;
      this.haloKeywordCount = this.activeHaloKeywordsCount;
      this.keywordSuggestionConfig = this.keywordSuggestionConfigFactory.createForStrategyUpdate(this.customer.SeoMinutesPaidRecurring, this.input.OrderedWebsiteUrlIds.length);
    });
  }

  updateNewPageIsExisting(newPageIsExisting: boolean) {
    this.newPageIsExisting = newPageIsExisting;
  }

  addNewPage(modal: NgbModalRef = null): any {
    Object.keys(this.newPageFormGroup.controls).forEach((key) => {
      this.newPageFormGroup.controls[key].markAsDirty();
      this.newPageFormGroup.controls[key].updateValueAndValidity();
    });

    if (this.newPageIsExisting == null) {
      toastr.error("Must select whether the page is existing or new.");
    }

    if (this.newPageControl.errors && this.newPageControl.errors.mismatchedDomain) {
      toastr.error(this.newPageControl.errors.mismatchedDomain.alternateMessage);
    }

    if (this.newPageFormGroup.valid && this.newPageIsExisting != null) {
      this.urlSuggestionAdded.next([{ Url: this.newPageControl.value, IsExisting: this.newPageIsExisting } as Boo.Objects.LegacyUrlSuggestion]);
      modal.close({} as Boo.Objects.LegacyUrlSuggestion);
    } else {
      toastr.error("Please correct the errors on the page.");
    }
  }

  openModal(template: any): void {
    this.modalService.open(template).result.finally(() => {
      this.newPageControl.setValue('');
      this.newPageIsExisting = null;
      Object.keys(this.newPageFormGroup.controls).forEach((key) => {
        this.newPageFormGroup.controls[key].markAsPristine();
        this.newPageFormGroup.controls[key].markAsUntouched();
      })
    });
  }

  validateAndSave(saveType: SaveTypes): JQueryPromise<boolean> {
    if (!this.isValid(saveType) || !this.urlSuggestionComponent.isValid(saveType) || !this.haloSuggestionComponent.isValid(saveType)) {
      return System.resolvedPromise(false);
    }

    this.output.Customer = this.input.Customer;
    this.output.Customer.VerticalId = this.selectedVerticalId.value;
    this.output.Customer.CountryId = this.selectedCountryId.value;
    if (this.keywordResearchNoteComponent && this.keywordResearchNoteComponent.keywordResearchNote) {
      this.output.KeywordResearchNote = this.keywordResearchNoteComponent.keywordResearchNote;
    }

    if (saveType === SaveTypes.Complete && (this.keywordResearchNoteComponent && !this.keywordResearchNoteComponent.isValid())) {
      this.floatingPanelsComponent.expandPanelByTitle("Keyword Research Note"); 
      return System.resolvedPromise(false);
    }
    this.floatingPanelsComponent.setLoading(true);

    this.output.UrlSuggestions = this.urlSuggestionComponent.get(saveType);
    this.output.KeywordSuggestions = this.haloSuggestionComponent.get();
    this.output.ActiveTrackingKeywords = this.activeHaloKeywords;
    this.output.UnorderedUrls = this.unorderedUrls;

    return System.resolvedPromise(true);
  }

  onUrlSuggestionAdded($event: Boo.Objects.LegacyUrlSuggestion): void {
    this.urlSuggestionAdded.next([$event]);
  }

  onHaloKeywordSuggestionsAdded($event: Boo.Objects.LegacyKeywordSuggestion[]): void {
    this.haloKeywordSuggestionsAdded.next($event);
  }

  private isValid(saveType: SaveTypes): boolean {
    if (saveType == SaveTypes.Update) {
      return true;
    }

    Object.keys(this.formGroup.controls).forEach((key) => {
      this.formGroup.controls[key].markAsDirty();
      this.formGroup.controls[key].updateValueAndValidity();
    });
    this.formGroup.updateValueAndValidity();
    
    return this.formGroup.valid;
  }
}