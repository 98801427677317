import TaskTypes = Boo.Objects.Enums.TaskTypeEnum;
import RejectionReasonTypes = Boo.Objects.Enums.RejectionReasonTypes;

export default class RejectionFactory {
  private static taskRejectionConfig: Map<TaskTypes, RejectionReasonTypes[]> = new Map<TaskTypes, RejectionReasonTypes[]>(
    [
      [
        TaskTypes.OnsiteRecommendationImplementationReview,
        [
          RejectionReasonTypes.ClarificationNeeded,
          RejectionReasonTypes.MissingOrWrongContent,
          RejectionReasonTypes.DesignOrAestheticIssues
        ]
      ],
      [
        TaskTypes.OnsiteCopyImplementationReview,
        [
          RejectionReasonTypes.ClarificationNeeded,
          RejectionReasonTypes.MissingOrWrongContent,
          RejectionReasonTypes.DesignOrAestheticIssues
        ]
      ],
      [
        TaskTypes.OnsiteBloggingImplementationReview,
        [
          RejectionReasonTypes.ClarificationNeeded,
          RejectionReasonTypes.MissingOrWrongContent,
          RejectionReasonTypes.DesignOrAestheticIssues
        ]
      ],
      [
        TaskTypes.OnsiteImplementationReview,
        [
          RejectionReasonTypes.ClarificationNeeded,
          RejectionReasonTypes.MissingOrWrongContent,
          RejectionReasonTypes.DesignOrAestheticIssues
        ]
      ],
    ]);

    static getRejectionReasonTypes(taskType: TaskTypes): RejectionReasonTypes[] {
      return this.taskRejectionConfig.get(taskType);
    }

    static createRejectionFromTypes(userId: number, rejectionTypes: RejectionReasonTypes[], note: string = null): Boo.Objects.Rejection {
      return {
        Reasons: rejectionTypes.map((type) => ({ RejectionReasonTypeId: type } as Boo.Objects.RejectionReason)),
        Note: note,
        UserId: userId
      } as Boo.Objects.Rejection;
    }

    static createRejectionFromReasons(userId: number, rejectionReasons: Boo.Objects.RejectionReason[], note: string = null): Boo.Objects.Rejection {
      return {
        Reasons: rejectionReasons,
        Note: note,
        UserId: userId
      } as Boo.Objects.Rejection;
    }

    static createRejectionReason(reasonType: RejectionReasonTypes, note: string = null): Boo.Objects.RejectionReason {
      return {
        RejectionReasonTypeId: reasonType,
        Note: note
      } as Boo.Objects.RejectionReason;
    }
}