/* tslint:disable */

import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, ViewChildren, QueryList, NgZone } from '@angular/core';

@Component({
    selector: 'app-components-manager-partnerpricingtoolfeaturelist',
    templateUrl: './PartnerPricingToolFeatureList.component.html'
})
export class PartnerPricingToolFeatureListComponent implements AfterViewInit, OnDestroy {
    private static FEATURE_KEY = "feature";
    private static SOURCE_INDEX_KEY = "source_index";
    private static FEATURE_LIST_KEY = "feature_list";

    @ViewChild('containerElement', { static: false })
    private containerElement: ElementRef;

    @ViewChildren('listItem')
    private listItems: QueryList<ElementRef>;

    @Input() public afterMoveCallback: () => void;
    @Input() public featureList: KnockoutObservableArray<Boo.Objects.SEOPresence.PartnerPricingToolFeature> = ko.observableArray([]);

    constructor(private ngZone: NgZone) {
    }

    private attachDataToListItems(): void {
        let items = this.listItems.toArray();
        for (let i = 0; i < items.length; ++i) {
            let listItem = items[i];
            let data = this.featureList()[i];
            let $element = $(listItem.nativeElement);
            $element.data(PartnerPricingToolFeatureListComponent.FEATURE_KEY, data);
            $element.data(PartnerPricingToolFeatureListComponent.FEATURE_LIST_KEY, this.featureList);
            $element.data(PartnerPricingToolFeatureListComponent.SOURCE_INDEX_KEY, i);
        }
    }

    public ngAfterViewInit(): void {
        this.listItems.changes.subscribe(() => this.attachDataToListItems());
        this.attachDataToListItems();

        let featureList = this.featureList;
        let afterMoveCallback = this.afterMoveCallback;

        let ngZone = this.ngZone;
        $(this.containerElement.nativeElement).sortable({
            update: function (event: Event, ui: any) {
                ngZone.run(() => {
                    var element = ui.item[0],
                        parentElement = ui.item.parent()[0],
                        feature = $(element).data(PartnerPricingToolFeatureListComponent.FEATURE_KEY);

                    //make sure that moves only run once, as update fires on multiple containers
                    if (feature && (this === parentElement || $.contains(this, parentElement))) {
                        let sourceFeatureList = $(element).data(PartnerPricingToolFeatureListComponent.FEATURE_LIST_KEY);
                        let sourceIndex = $(element).data(PartnerPricingToolFeatureListComponent.SOURCE_INDEX_KEY);
                        let targetFeatureList = featureList;
                        let targetIndex = ui.item.parent().children().toArray().indexOf(element);

                        if (targetIndex >= 0) {
                            sourceFeatureList().splice(sourceIndex, 1);
                            sourceFeatureList.notifySubscribers();
                            targetFeatureList().splice(targetIndex, 0, feature);
                            targetFeatureList.notifySubscribers();
                        }

                        if (afterMoveCallback) {
                            afterMoveCallback();
                        }
                    }
                });
            },
            connectWith: '.feature_list_container'
        });
    }

    public ngOnDestroy(): void {
        $(this.containerElement.nativeElement).sortable('destroy');
    }
}