import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dayName"
})
export class DayNamePipe implements PipeTransform {
  transform(dayOfWeek: any, ...args: any[]): any {
    if(isNaN(dayOfWeek) || Number(dayOfWeek) < 0 || Number(dayOfWeek) > 6) {
      return '';
    }

    return moment.weekdays()[dayOfWeek];
  }
}