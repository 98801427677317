<div class="card">
    <div class="card-body" style="overflow-x: auto;">
        <app-tab-panel [displayMode]="tabPanelDisplayMode.horizontal">
            <app-tab [title]="'Files'">
                <app-components-customerfiles *tabContent [options]="{
                        customerId: customerId,
                        fileSectionTypeId: window.launchpad.config.FileSectionTypeEnum.General,
                        fileSectionTypeIds: [window.launchpad.config.FileSectionTypeEnum.BoostSite,
                            window.launchpad.config.FileSectionTypeEnum.General,
                            window.launchpad.config.FileSectionTypeEnum.KeywordResearch,
                            window.launchpad.config.FileSectionTypeEnum.LocalProfile,
                            window.launchpad.config.FileSectionTypeEnum.Marketing,
                            window.launchpad.config.FileSectionTypeEnum.Ranks],
                        canDelete: true,
                        canUpload: true
                }"></app-components-customerfiles>
            </app-tab>
            <app-dynamic-tab [title]="'Custom Reports'">
                <compose *tabContent Selector="app-components-customreports" [ActivateOptions]="{
                        allowDelete: true,
                        customer: customer,
                        showAddNew: true
                    }"></compose>
            </app-dynamic-tab>
        </app-tab-panel>
    </div>
</div>