import { Component, Input } from '@angular/core';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-archived-keywords',
  templateUrl: './archived-keywords.component.html',
  styleUrls: ['../customer-campaign/customer-campaign.component.scss']
})
export class ArchivedKeywordsComponent {
  @Input() customer: Boo.Objects.Customer;
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];
  @Input() haloKeywords: Boo.Objects.WebsiteKeywordTracking[];
  @Input() archivedKeywords: Boo.Objects.ArchivedKeyword[];
  @Input() readOnlyReasons: string;
  @Input() firstPageKeywordPhrases: string[];
  @Input() restrictions: Boo.Objects.Enums.CustomerCampaignRestrictions[];

  customerCampaignRestrictions_ReadOnly = Boo.Objects.Enums.CustomerCampaignRestrictions.ReadOnly;

  constructor(private customerCampaignService: CustomerCampaignService) { }

  isActiveStatus(websiteUrl: Boo.Objects.WebsiteUrl): boolean {
    return this.customerCampaignService.isActiveStatus(websiteUrl);
  }

  moveArchivedKeywordToHalo(archivedKeyword: Boo.Objects.ArchivedKeyword): void {
    this.customerCampaignService.moveArchivedKeywordToHalo(this.customer.CustomerId,this.haloKeywords, this.archivedKeywords, archivedKeyword, this.firstPageKeywordPhrases);
  }

  moveArchivedKeywordToWebsiteUrl(archivedKeyword: Boo.Objects.ArchivedKeyword, websiteUrl: Boo.Objects.WebsiteUrl): void {
    this.customerCampaignService.moveArchivedKeywordToWebsiteUrl(this.archivedKeywords, archivedKeyword, websiteUrl);
  }
}