import { Component } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { CustomerPriorityService } from '../../../services/customer-priority.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-customerservice-customerpriorityassignments',
  templateUrl: './CustomerPriorityAssignments.component.html'
})
export class CustomerPriorityAssignmentsComponent implements IActivatable {
    public filteredCustomerAssignments: KnockoutObservableArray<Boo.Objects.CustomerPriorityAssignment>;
    public assignedUsers: KnockoutObservableArray<string>;
    public customerTeams: KnockoutObservableArray<string>;
    public isFilterApplied: KnockoutComputed<boolean>;
    public customerIdFilter: KnockoutObservable<number>;
    public assignedUserFilter: KnockoutObservable<string>;
    public customerTeamFilter: KnockoutObservable<string>;

    constructor(private customerPriorityService: CustomerPriorityService) { }

    public activate(): JQueryPromise<any> {
        this.filteredCustomerAssignments = ko.observableArray([]);
        this.assignedUsers = ko.observableArray([]);
        this.customerTeams = ko.observableArray([]);
        this.customerIdFilter = ko.observable();
        this.assignedUserFilter = ko.observable();
        this.customerTeamFilter = ko.observable();

        this.isFilterApplied = ko.computed(() => {
            return !!this.customerIdFilter() || !!this.assignedUserFilter() || !!this.customerTeamFilter();
        });

        return this.getCurrentAssignments();
    }

    public clearSearch(): JQueryPromise<void> {
        this.customerIdFilter(undefined);
        this.assignedUserFilter(undefined);
        this.customerTeamFilter(undefined);

        return this.getCurrentAssignments();
    }

    public getCurrentAssignments(): JQueryPromise<void> {
        return Utils.wrapDfd(this.customerPriorityService.getCurrentAssignments())
            .then((assignments: Boo.Objects.CustomerPriorityAssignment[]) => {
                this.assignedUsers(_.chain(assignments)
                    .pluck('UserFullName')
                    .uniq()
                    .sortBy((x) => x)
                    .value());

                this.customerTeams(_.chain(assignments)
                    .filter(assignment => { return !!assignment.CustomerTeam; })
                    .pluck('CustomerTeam')
                    .pluck('Name')
                    .uniq()
                    .sortBy((x) => x)
                    .value());

                this.filteredCustomerAssignments(_.filter(assignments, (assignment: Boo.Objects.CustomerPriorityAssignment) => {
                    return (!this.customerIdFilter() || assignment.CustomerId === this.customerIdFilter())
                        && (!this.assignedUserFilter() || assignment.UserFullName === this.assignedUserFilter())
                        && (!this.customerTeamFilter() || assignment.CustomerTeam.Name === this.customerTeamFilter());
                }));
            });
    }

    public endAssignment(assignment: Boo.Objects.CustomerPriorityAssignment): JQueryPromise<void> {
        return Utils.wrapDfd(this.customerPriorityService.endAssignment(assignment.CustomerId, assignment.UserId))
            .then(() => {
                return this.getCurrentAssignments();
            });
    }

    public openCustomer(assignment: Boo.Objects.CustomerPriorityAssignment): JQueryPromise<void> {
        return PriorityViewModel.showManageCustomer(assignment.CustomerId)
            .always(() => {
                return this.getCurrentAssignments();
            });
    }
}
