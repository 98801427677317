import { Component, OnInit } from '@angular/core';
import System from 'framework/System';
import ITaskFilesPublicApi = app.components.interfaces.ITaskFilesPublicApi;
import ITaskFilesPublicApiCallback = app.components.interfaces.ITaskFilesPublicApiCallback;
import { OnsiteImplementationService } from '../../../../../services/onsite-implementation.service';

@Component({
  selector: 'app-components-specialist-actioncomponents-onsiteimplementationreview',
  templateUrl: './OnsiteImplementationReview.component.html'
})
export class OnsiteImplementationReviewComponent implements OnInit, IActionWorkspace, IActivatable {

  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;
  taskFilesApi: ITaskFilesPublicApi;
  taskFilesApiCallback: ITaskFilesPublicApiCallback;
  onsiteImplementationSummary: Boo.Objects.OnsiteImplementation.OnsiteImplementationSummary;

  constructor(private onsiteImplementationService: OnsiteImplementationService) {}

  ngOnInit(): void {
    this.onsiteImplementationService.getImplementationSummary(this.currentAction().Task.TaskId)
      .subscribe(summary => {
        this.onsiteImplementationSummary = summary;
      });

    this.taskFilesApiCallback = (api: ITaskFilesPublicApi) => { this.taskFilesApi = api; }
  }

  activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {
    params.controlViewModel(this);
    this.currentAction = params.currentAction;
    this.actionViewModel = ko.observable(null);
    return System.emptyPromise();
  }

  isStepReadyToReject(): boolean {
    return true;
  }

  isStepReadyToSave(): boolean {
    return this.currentAction().Task.TaskTypeSummary.IsPersistable;
  }

  isStepReadyToDelete(): boolean {
    return false;
  }

  isStepReadyToComplete(): boolean {
    return true
  }
}
