export default class CustomerHelper {
  public static getIsWebsiteManagedByPartner(keyValuePairs: Boo.Objects.CustomerKeyValuePair[]): boolean {
    return CustomerHelper.getValue(keyValuePairs, Boo.Objects.Enums.KeyEnum.IsWebsiteManagedByPartner).toLowerCase() === "true";
  }
  public static setIsWebsiteManagedByPartner(keyVlauePairs: Boo.Objects.CustomerKeyValuePair[], customerId: number, value: boolean, userId: number): Boo.Objects.CustomerKeyValuePair {
    return CustomerHelper.setValue(keyVlauePairs, Boo.Objects.Enums.KeyEnum.IsWebsiteManagedByPartner, customerId, String(value), userId);
  }

  public static customerKvpFactory(customerId: number, userId: number, value: string, key: Boo.Objects.Enums.KeyEnum): Boo.Objects.CustomerKeyValuePair {
    return {
      CustomerId: customerId,
      CustomerKeyValuePairId: 0,
      InsertedDate: null,
      InsertedUserId: userId,
      KeyValuePair: {
        Key: {
          KeyId: key
        } as Boo.Objects.Key,
        KeyId: key,
        KeyValuePairId: 0,
        Value: value
      },
      KeyValuePairId: 0
    }
  }

  private static getValue(customerKeyValuePairs: Boo.Objects.CustomerKeyValuePair[], key: Boo.Objects.Enums.KeyEnum): string {
    let kvp = _.find(_.sortBy(customerKeyValuePairs, k => k.InsertedDate).reverse(), x => x.KeyValuePair.KeyId === key);
    return kvp ? kvp.KeyValuePair.Value : '';
  }

  private static setValue(keyVlauePairs: Boo.Objects.CustomerKeyValuePair[], key: Boo.Objects.Enums.KeyEnum, customerId: number, value: string, userId: number): Boo.Objects.CustomerKeyValuePair {
    let kvp = _.find(_.sortBy(keyVlauePairs, k => k.InsertedDate).reverse(), x => x.KeyValuePair.KeyId === key);
    if (kvp && kvp.KeyValuePair) {
      kvp.KeyValuePair.Value = value;
    }
    else {
      kvp = this.customerKvpFactory(customerId, userId, value, key);
    }

    return kvp;
  }
}