import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-components-shared-keywords-website-urls-readonly',
  templateUrl: './website-urls-readonly.component.html',
  styleUrls: ['./website-urls-readonly.component.scss']
})
export class WebsiteUrlsReadonlyComponent {
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[] = [];

  constructor() { }
}
