import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { QaGradingArea } from '../../../../qa/qa-grading-area/qa-grading-area.component';
import { Subject, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SessionStorageService } from '../../../../../services/session-storage.service';
import { CustomerService } from '../../../../../services/customer.service';
import { CustomerNoteService } from '../../../../../services/customer-note.service';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';
import { InternalActionToken } from '../../internal-action.token';
import { DeletableWorkspace } from 'app/interfaces/deletable-workspace';

@Component({
  selector: 'app-components-specialist-internal-tasks-keyword-research-redo-qa',
  templateUrl: './keyword-research-redo-qa.component.html'
})
export class KeywordResearchRedoQaComponent implements OnInit, CompletableWorkspace, UnlockableWorkspace, DeletableWorkspace, ClosableWorkspace {
  showCompleteButton: boolean = false;
  showUnlockButton: boolean = true;
  showCloseButton: boolean = true;
  complete = new Subject<void>();
  unlock = new Subject<void>();
  close = new Subject<void>();

  launchpad = launchpad;
  isLoading: boolean;
  taskType: Boo.Objects.Enums.TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaKeywordResearchRedo;
  qaCriteriaGroups: Boo.Qa.QaCriteriaGroup[] = [];
  vertical: Boo.Objects.Vertical;
  country: Boo.Objects.Country;
  isMoneyBackGuarantee: boolean;
  associatedTask: Boo.Objects.Task;
  customer: Boo.Objects.Customer;
  salesNote: string;
  keywordDiscoveryNote: string;
  keywordResearchNote: string;
  suggestedHaloKeywords: Boo.Objects.QaKeywordSuggestion[];
  suggestedUrls: Boo.Objects.QaUrlSuggestion[];
  historicalSuggestions: Boo.Objects.LegacyKeywordSuggestion[];
  keywordSuggestionsTypes: Boo.Objects.KeywordSuggestionType[];
  rejectionNote: string;
  @ViewChild('gradingArea') gradingArea: QaGradingArea;
  @ViewChild('noteGridTemplate') noteGridTemplate: TemplateRef<any>;

  textFilter: string = '';
  dataTable: app.components.interfaces.DataTablePublicApi;
  dataTableOptions: app.components.interfaces.DataTableOptions;

  constructor(
    @Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction,
    private sessionStorageService: SessionStorageService,
    private customerService: CustomerService,
    private customerNoteService: CustomerNoteService) { }

  ngOnInit(): void {
    //Create the criteria float panel
    (<any>$('#internal-task-qa-criteria-float-panel')).floatPanel({ collapsed: false });

    this.isLoading = true;

    this.associatedTask = this.internalAction.InternalTaskDetails.AssociatedTask;
    this.qaCriteriaGroups = this.internalAction.InternalTaskDetails.QaCriteriaGroups;

    this.keywordDiscoveryNote = this.internalAction.InternalTaskDetails.KeywordDiscoveryNote;
    this.salesNote = _.pluck(_.filter(this.internalAction.InternalTaskDetails.AssociatedCustomerNotes, (note: Boo.Objects.CustomerNote) => { return note.Text !== ''; }), 'Text').reverse().join('\n--------\n');
    this.suggestedHaloKeywords = this.internalAction.InternalTaskDetails.KeywordSuggestionSummary.HaloKeywordSuggestions;
    this.suggestedUrls = this.internalAction.InternalTaskDetails.KeywordSuggestionSummary.UrlSuggestions;
    this.historicalSuggestions = this.internalAction.InternalTaskDetails.KeywordSuggestionSummary.HistoricalKeywordSuggestions;
    this.keywordResearchNote = this.internalAction.InternalTaskDetails.KeywordResearchNote;
    this.rejectionNote = this.internalAction.InternalTaskDetails.AssociatedTaskNotes.find(x => x.TaskNoteContactTypeId === Boo.Objects.Enums.TaskNoteContactTypesEnum.CsrRejection)?.Note;

    forkJoin([
      this.sessionStorageService.getStaticData(),
      this.customerService.get(this.internalAction.InternalTaskDetails.AssociatedTaskCustomer.CustomerId)
    ])
      .pipe(finalize(() => {
        this.configureNoteDataTable();
        this.configureKeywordSuggestionTypes();
        this.isLoading = false;
      }))
      .subscribe(([staticData, customer]): void => {
        this.customer = customer;
        this.country = staticData.Countries.find(x => x.CountryId === this.customer.CountryId);
        this.vertical = staticData.Verticals.find(x => x.VerticalId === this.customer.VerticalId);
        this.keywordSuggestionsTypes = staticData.KeywordSuggestionTypes;
        const moneyBackGroup = staticData.PartnerGroups.find(x => x.GroupId == Boo.Objects.Enums.PartnerGroupEnum.ThryvMoneyBack);
        this.isMoneyBackGuarantee = moneyBackGroup.PartnerGroups.some(x => x.PartnerId == this.customer.PartnerId);
      });
  }

  configureKeywordSuggestionTypes(): void {
    this.suggestedUrls.forEach(url => {
      url.QaKeywordSuggestions.forEach(keyword => {
        keyword.KeywordSuggestionType = this.keywordSuggestionsTypes.find(x => x.KeywordSuggestionTypeId === keyword.KeywordSuggestionTypeId);
      });
    });

    let haloType = this.keywordSuggestionsTypes.find(x => x.KeywordSuggestionTypeId === Boo.Objects.Enums.KeywordSuggestionTypes.Halo);
    this.suggestedHaloKeywords.forEach(keyword => {
      keyword.KeywordSuggestionType = haloType;
    });

    this.historicalSuggestions.forEach(keyword => {
      keyword.KeywordSuggestionType = this.keywordSuggestionsTypes.find(x => x.KeywordSuggestionTypeId === keyword.KeywordSuggestionTypeId);
    });
  }

  search(): void {
    this.dataTable.reload();
  }

  clear(): void {
    this.textFilter = '';
    this.dataTable.reload();
  }

  isReadyToComplete(): boolean {
    if (!this.gradingArea.validate()) {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return false;
    }

    this.internalAction.InternalTaskDetails.QaCriteriaDetails = this.gradingArea.getDetails();
    this.internalAction.InternalTaskDetails.QaGrade.Comment = this.gradingArea.gradeComment;
    return true;
  }
  
  isReadyToDelete(): boolean {
    return true;
  }

  private configureNoteDataTable(): void {
    this.dataTableOptions = {
      loader: (page, pageSize, sortOrder): JQueryPromise<framework.data.IPageResult<Boo.Objects.CustomerNote>> => {
        if (!sortOrder) {
          sortOrder = 'InsertedDate desc';
        }

        let filters = [`ContactTypeId ne ${Boo.Objects.Enums.ContactTypeEnum.Sales}`];

        let term = this.textFilter.trim();

        if (term) {
          filters.push(`contains(Text, '${term}')`);
        }

        let query = this.customerNoteService.get(this.customer.CustomerId)
          .orderBy(sortOrder)
          .page(page, pageSize)
          .filter(filters.join(' and '));

        return query.load();
      },
      columns: [
        { id: 'inserted_date', value: 'InsertedDate', name: 'Date' },
        { id: 'note', value: 'Text', name: 'Note', canSort: false },
      ],
      pageSize: 5,
      rowTemplate: this.noteGridTemplate,
      apiCallback: (api): void => {
        this.dataTable = api;
      }
    }
  }
}
