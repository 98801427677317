import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LinkLocationBlogDetailService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/linklocationblogdetails`;

  constructor(private http: HttpClient) { }

  searchBlogs(domain: string, verticalId: number, languageId: number, countryId: number, amazonDeploymentStatusId: number, linkLocationStatus: number, linkLocationId: number): Observable<Boo.Objects.BlogSitesBlog[]> {
    let params = new HttpParams()
      .set('domain', domain)
      .set('verticalId', verticalId)
      .set('languageId', languageId)
      .set('countryId', countryId)
      .set('amazonDeploymentStatusId', amazonDeploymentStatusId)
      .set('linkLocationStatus', linkLocationStatus)
      .set('linkLocationId', linkLocationId);

    return this.http.get<Boo.Objects.BlogSitesBlog[]>(`${this.baseUrl}/SearchBlogs`, { params: params });
  }

  getByLinkLocationId(linkLocationId: number): Observable<Boo.Objects.LinkLocationBlogDetail> {
    let params = new HttpParams().set('linkLocationId', linkLocationId);
    return this.http.get<Boo.Objects.LinkLocationBlogDetail>(`${this.baseUrl}/GetByLinkLocationId`, { params: params });
  }

  downBlogCount(): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/DownBlogCount`);
  }

  downBlogs(): Observable<Boo.Objects.BlogSitesBlog[]> {
    return this.http.get<Boo.Objects.BlogSitesBlog[]>(`${this.baseUrl}/DownBlogs`);
  }

  save(linkLocationBlogDetails: Boo.Objects.LinkLocationBlogDetail): Observable<void> {
    return this.http.post<void>(this.baseUrl, linkLocationBlogDetails)
  }

  deploy(linkLocationBlogDetail: Boo.Objects.LinkLocationBlogDetail): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/DeployStaticSite`, linkLocationBlogDetail)
  }
}
