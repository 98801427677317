import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Notification } from '../../../shared/models/notifications/notifications';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html'
})
export class NotificationModalComponent {
  @Input() notification: Notification;

  constructor(public activeModal: NgbActiveModal) { }
}
