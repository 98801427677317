<div id="statsContainer" class="card">
	<div id="metricStats">
		<div class="card-header">
			Blog Metrics
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-md-4">
          <div class="card card-body bg-light"><h4>{{downBlogsCount}}</h4></div>
					<h5>Down Blogs</h5>
				</div>
			</div>
		</div>
	</div>
</div>
