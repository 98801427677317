import { Component } from '@angular/core';
import System from 'framework/System';
import INotesAndTicketsPublicApi = app.managecustomer.interfaces.INotesAndTicketsPublicApi;
import IOpenTicketsPublicApi = app.managecustomer.components.interfaces.IOpenTicketsPublicApi;
import IOpenTicketsPublicApiCallback = app.managecustomer.components.interfaces.IOpenTicketsPublicApiCallback;
import NotesPublicApi = app.managecustomer.components.interfaces.NotesPublicApi;
import NotesPublicApiCallback = app.managecustomer.components.interfaces.NotesPublicApiCallback;
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';

@Component({
	selector: 'app-components-managecustomer-notesandtickets',
	templateUrl: './notes-and-tickets.component.html'
})
export class NotesAndTicketsComponent implements IActivatable {    
    public customerObservable: CustomerObservable;
    public customer: Boo.Objects.Customer;
    public notesPublicApi: NotesPublicApi;
    public notesPublicApiCallback: NotesPublicApiCallback;
    public openTicketsPublicApi: IOpenTicketsPublicApi;
    public openTicketsPublicApiCallback: IOpenTicketsPublicApiCallback;
    public parent: any;
    public tabPanelDisplayMode: typeof TabPanelDisplayMode = TabPanelDisplayMode;

    public activate(params: any): JQueryPromise<any> {
        this.customerObservable = params.customer;
        this.customer = ko.mapping.toJS(params.customer);
        this.parent = params.parent;

        if (params.apiCallback) {
            params.apiCallback(this.getPublicApi());
        }

        this.openTicketsPublicApiCallback = (api: IOpenTicketsPublicApi) => { this.openTicketsPublicApi = api; };
        this.notesPublicApiCallback = (api: NotesPublicApi) => { this.notesPublicApi = api; };

        return System.emptyPromise();
    }

    public reloadNotes(): void {
        this.getPublicApi().reloadNotes();
    }

    private publicApi = {
        reloadNotes: (): void => {
            if (this.notesPublicApi) {
                this.notesPublicApi.reloadNotes();
            }
        },
        reloadTickets: (): void => {
            if (this.openTicketsPublicApi) {
                this.openTicketsPublicApi.reloadTickets();
            }
        },
        refreshCustomer: (): void => {
            if (this.parent) {
                this.parent.refreshCustomer();
            }
        },
    };
    public getPublicApi(): INotesAndTicketsPublicApi {
        return this.publicApi;
    }
}
