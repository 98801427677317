import { Component, ViewChild, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbInputDatepicker, NgbDateAdapter, NgbDateNativeAdapter, NgbDate, NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './dateformat';

@Component({
	selector: 'shared-components-datepicker',
  templateUrl: './datePicker.component.html',
  styleUrls: ['./datePicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    },
    {
      provide: NgbDateAdapter, useClass: NgbDateNativeAdapter
    },
    {
      provide: NgbDatepickerConfig, useClass: NgbDatepickerConfig
    },
    {
      provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter
    }
  ]
})
export class DatePickerComponent implements ControlValueAccessor {
  @ViewChild(NgbInputDatepicker, { static: true }) picker: NgbInputDatepicker;
  @Input() min: Date = new Date();
  @Input() max: Date;
  @Input() maxDayOfMonth?: number;
  @Input() clearable = false;
  @Input() onlyAllowedDay: number;
  date: Date;
  onChange = (_: any) => { };
  onTouched = () => { };

  constructor(public dateAdapter: NgbDateAdapter<Date>, config: NgbDatepickerConfig) {
    config.outsideDays = 'hidden';
    config.markDisabled = (date: NgbDate, _current) => this.setDisables(date);
  }

  writeValue(obj: any): void {
    this.date = obj;
    const ngbDate = this.dateAdapter.fromModel(obj);
    this.picker.navigateTo({ ...ngbDate });
    this.onChange(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.picker.disabled = isDisabled;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  dateSelect() {
    this.onChange(this.date);
  }

  show() {
    this.picker.open();
  }

  private setDisables(date: NgbDate): boolean {
    const maxDays = !!this.maxDayOfMonth && date.day > this.maxDayOfMonth;
    const billDay = this.onlyAllowedDay !== date.day;

    return this.onlyAllowedDay ? billDay : maxDays;
  }
}
