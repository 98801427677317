<div class="card">
	<div class="card-header">
		Specialist Overview
		<button (click)="refresh()" class="btn btn-primary btn-sm float-end">
			<i class="fas fa-sync"></i>
			Refresh
		</button>
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body">
		<div class="row">
			<div class="col-md-3">
				<h4 class="card card-body bg-light d-block">
					<i class="far fa-clock"></i>
					<span>{{todaysSubscriptionHours()}}</span>
				</h4>
				<h5>Today's Subscription Hours</h5>
			</div>
			<div class="col-md-3">
				<h4 class="card card-body bg-light d-block">
					<i class="far fa-clock"></i>
					<span>{{todaysHours()}}</span>
				</h4>
				<h5>Today's Hours</h5>
			</div>
			<div class="col-md-3">
				<h4 class="card card-body bg-light d-block">
					<i class="fas fa-cogs"></i>
					<span>{{todaysEfficiency() | numericText }}</span>
					%
				</h4>
				<h5>Today's Efficiency</h5>
			</div>
			<div class="col-md-3">
				<h4 class="card card-body bg-light d-block">
					<i class="fa fa-tasks"></i>
					<span>{{todaysActions()}}</span>
				</h4>
				<h5>Today's Actions</h5>
			</div>
		</div>
	</div>
</div>
