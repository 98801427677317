<div *ngIf="onsiteCopyWillBoostPublishContent()">
	<app-components-togglepanel [(isOpen)]="isOpen" title="Onsite Copy Implementation" toggleBody="true">
		<div>
			<div class="row">
				<div class="col-md-9" style="margin-top:30px;" *ngIf="isCmsBlackListed">
					<label class="text-warning">SEO onsite implementation is not supported for this CMS.</label>
				</div>
        <div class="col-md-9" style="margin-top:30px;" *ngIf="isCmsNotSet">
					<label class="text-warning">Set CMS for this customer to access SEO onsite implementation.</label>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
						<label class="control-label">Notes for Implementation Specialist</label>
						<textarea [dataValidation]="{ placement: 'top', value: implementationInstructions }"
							[ngModel]="implementationInstructions()" (ngModelChange)="implementationInstructions($event)"
							[ngModelOptions]="{ standalone: true }" rows="5" class="form-control"
							placeholder="Where should this copy be placed on the page? If the copy is for a new page that is being created, where should the page be created on the site? Please place any other notes regarding implementation here."></textarea>
					</div>
				</div>
			</div>
		</div>
	</app-components-togglepanel>
</div>