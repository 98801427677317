<div id="keywords-component">
  <app-components-togglepanel [(isOpen)]="isOpen" title="Keyword Research" toggleBody="true">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div data-toggle="collapse" href="#salesNoteBody" role="button">
              Sales Note
              <i class="only-collapsed fa-caret-square-down far float-end"></i>
              <i class="only-expanded fa-caret-square-up far float-end"></i>
            </div>
          </div>
          <div class="collapse hide" id="salesNoteBody">
            <div class="card-body line-breaks">{{ salesNote }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-components-shared-keyword-research
          [customer]="customerJS"
          (redoRequested)="redo()">
        </app-components-shared-keyword-research>
      </div>
    </div>
  </app-components-togglepanel>
</div>