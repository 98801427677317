<div class="card">
  <div class="card-header">
    <div>
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-1 mt-2">Files</div>
            <div class="col-md-4" style="padding-left:0;">
              <select [(ngModel)]="fileSectionTypeId" (ngModelChange)="loadFiles()"
                [ngModelOptions]="{ standalone: true }" [disabled]="!(fileSectionTypes.length > 1)"
                name="fileSectionTypes" class="form-select float-end">
                <option *ngFor="let option of fileSectionTypes" [ngValue]="option.FileSectionTypeId">{{ option.Name }}
                </option>
              </select>
            </div>
            <div [hidden]="!(allowFilteringByTicket && !isFileUploadRequired)" class="col-md-4 btn-group"
              style="min-width: 150px;">
              <button (click)="setFilterByTicket(true);" [class.active]="filterByTicket" type="button"
                style="min-width: 70px;" class="btn btn-outline-secondary">Only Ticket Files</button>
              <button (click)="setFilterByTicket(false);" [class.active]="!filterByTicket" type="button"
                style="min-width: 70px;" class="btn btn-outline-secondary">All</button>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-end">
            <button (click)="loadFiles()" class="btn btn-primary">
              <i class="fas fa-sync"></i>
              Refresh
            </button>
            <button [hidden]="!allowUpload" (click)="upload()" class="btn btn-primary">
              <i class="fa fa-plus"></i>
              Upload File
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div [dataValidation]="{ placement: 'top', value: files }">
      <app-table [items]="files()" [exportFilename]="'customer-files'">
        <ng-template #tableHeader>
          <tr>
            <th export sort field="Name">Name</th>
            <th export sort field="InsertedDate">Upload Date</th>
            <ng-container *ngIf="showUsers">
              <th export sort field="User.FullName">Uploaded By</th>
            </ng-container>
            <th export field="S3Url"></th>
            <th *ngIf="allowDelete"></th>
          </tr>
        </ng-template>
        <ng-template #tableBody let-file>
          <tr>
            <td>
              <div>
                {{file.Name}}
                <ng-container *ngIf="file.Comment">
                  <div class="float-end">
                    <span [ngbPopover]="file.Comment" popoverTitle="Comment" placement="right auto"
                      triggers="mouseenter:mouseleave">
                      <i class="fa fa-sticky-note fa-2x text-info"></i>
                    </span>
                  </div>
                </ng-container>
              </div>
            </td>
            <td>{{file.InsertedDate | dateText }}</td>
            <ng-container *ngIf="showUsers">
              <td>{{file.User.FullName}}</td>
            </ng-container>
            <td>
              <a [externalLink]="{ url: file.S3Url, text:'View File'}" class="btn btn-primary"></a>
            </td>
            <ng-container *ngIf="allowDelete">
              <td>
                <a (click)="delete(file)" class="btn btn-danger">
                  <i class="far fa-trash-alt"></i>
                  Delete
                </a>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </app-table>
    </div>
  </div>
</div>