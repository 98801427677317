<app-components-specialist-internal-tasks-action-information
	[internalAction]="internalAction"></app-components-specialist-internal-tasks-action-information>
<div class="row">
	<div [loadingOverlay]="isLoading" class="col-md-7">
		<ng-container *ngIf="internalAction.InternalTaskDetails">
			<div [hidden]="!(internalAction.InternalTaskDetails.ContentAudits.length > 0)" class="card card-danger">
				<div class="card-header">
					Duplicate Content Warning
				</div>
				<div class="card-body">
					<ng-container *ngFor="let $data of internalAction.InternalTaskDetails.ContentAudits">
						<ng-container *ngIf="$data.Url1 !== null || $data.Url2 !== null || $data.Url3 !== null">
							<label class="control-label">External Content</label>
							<table class="table table-bordered table-sm">
								<thead>
									<tr>
										<th>Url</th>
										<th>Percentage</th>
										<th>Matched Text</th>
									</tr>
								</thead>
								<tbody>
									<tr [hidden]="!($data.Url1 !== null)">
										<td>{{$data.Url1}}</td>
										<td>{{$data.Url1Percentage | percent}}</td>
										<td>
											<span [ngbPopover]="$data.Url1MatchedText" popoverTitle="Matched Text" placement="left auto"
												triggers="mouseenter:mouseleave">
												<i class="far fa-file-alt"></i>
												(Hover for Matched Text)
											</span>
										</td>
									</tr>
									<tr [hidden]="!($data.Url2 !== null)">
										<td>{{$data.Url2}}</td>
										<td>{{$data.Url2Percentage | percent}}</td>
										<td>
											<span [ngbPopover]="$data.Url2MatchedText" popoverTitle="Matched Text" placement="left auto"
												triggers="mouseenter:mouseleave">
												<i class="far fa-file-alt"></i>
												(Hover for Matched Text)
											</span>
										</td>
									</tr>
									<tr [hidden]="!($data.Url3 !== null)">
										<td>{{$data.Url3}}</td>
										<td>{{$data.Url3Percentage | percent}}</td>
										<td>
											<span [ngbPopover]="$data.Url3MatchedText" popoverTitle="Matched Text" placement="left auto"
												triggers="mouseenter:mouseleave">
												<i class="far fa-file-alt"></i>
												(Hover for Matched Text)
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</ng-container>
					</ng-container>
				</div>
			</div>
			<div>
				<ng-container *ngIf="internalAction.InternalTaskDetails.BlogThemeContent">
					<div class="form-group has-feedback">
						<label class="control-label">Site Title</label>
						<input [ngModel]="siteTitle" [disabled]="true" [readonly]="true" type="text" class="form-control" />
						<span class="help-block">Site Title must be between 3 and 10 words.</span>
					</div>
					<div class="form-group has-feedback">
						<label class="control-label">Site Purpose</label>
						<textarea [ngModel]="sitePurpose" [disabled]="true" [readonly]="true" rows="10"
							class="form-control"></textarea>
						<span class="help-block">Site Purpose must be between 100 and 150 words.</span>
					</div>
					<div class="form-group has-feedback">
						<label class="control-label">H1 Field</label>
						<input [formControl]="h1Field" type="text" class="form-control" />
						<span class="help-block">H1 Field must be between 3 and 10 words.</span>
					</div>
					<div class="form-group has-feedback">
						<label class="control-label">Meta-Description</label>
						<textarea [formControl]="metaDescription" rows="10" class="form-control"></textarea>
						<span class="form-control-feedback small">
							Character Count:
							<span>{{metaDescriptionCharacterCount}}</span>
						</span>
						<span class="help-block">Meta-Description may have a maximum of 160 characters.</span>
					</div>
					<div class="form-group has-feedback">
						<label class="control-label">Revised Site Purpose</label>
						<textarea [formControl]="revisedSitePurpose" rows="10" class="form-control"></textarea>
						<span class="form-control-feedback small">
							Word Count:
							<span>{{revisedSitePurposeWordCount}}</span>
						</span>
						<span class="help-block">Revised Site Purpose must be between 100 and 150 words.</span>
					</div>
				</ng-container>
			</div>
		</ng-container>
	</div>
	<div class="col-md-5">
		<app-components-specialist-internal-tasks-additional-info
			[internalAction]="internalAction"></app-components-specialist-internal-tasks-additional-info>
	</div>
</div>
