import { Component, ViewChild } from '@angular/core';
import Check from 'framework/Check';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import IOpenTicketsPublicApi = app.managecustomer.components.interfaces.IOpenTicketsPublicApi;
import { TicketGroupService } from '../../../services/ticket-group.service';
import Utils from '../../../shared/utils';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';

@Component({
	selector: 'app-components-managecustomer-opentickets',
	templateUrl: './OpenTickets.component.html'
})
export class OpenTicketsComponent implements IActivatable {
    public activeTab: string;
    public customer: CustomerObservable;
    public isLoading: boolean;
    public parent: any;
    public ticketGroups: Boo.Objects.TicketGroup[];
    private title: string = 'Tickets';
    public notesAndTicketsPublicApi: app.managecustomer.interfaces.INotesAndTicketsPublicApi;
    public tabPanelDisplayMode = TabPanelDisplayMode;

    constructor(private ticketGroupService: TicketGroupService) { }

    public activate(params: app.managecustomer.components.interfaces.IOpenTicketsActivateParams): JQueryPromise<any> {
        Check.isNotEmpty(params, 'Params cannot be empty');
        Check.isNotEmpty(params.customer, 'Customer cannot be null');
        Check.isNotEmpty(params.parent, 'Parent cannot be null');
        this.activeTab = null;
        this.isLoading = false;
        this.customer = params.customer;
        this.parent = params.parent;
        this.notesAndTicketsPublicApi = params.notesAndTicketsPublicApi;
        this.ticketGroups = [];

        if (params.apiCallback) {
            params.apiCallback(this.getPublicApi());
        }

        return this.loadTickets();
    }

    public loadTickets(): JQueryPromise<any> {
        this.isLoading = true;

        return Utils.wrapDfd(this.ticketGroupService.getActive(this.customer.CustomerId()))
            .then((ticketGroups: Boo.Objects.TicketGroup[]) => {
                this.ticketGroups = ticketGroups;
            })
            .always(() => this.isLoading = false);
    }

    public newTicket(): void {
        PriorityViewModel.show('app-components-managecustomer-newticket', { customer: this.customer, parent: this.parent.parent })
            .always((result: app.ticket.interfaces.IPriorityViewResult) => {
                this.loadTickets();
            });
    }

    private getPublicApi(): IOpenTicketsPublicApi {
        return {
            reloadTickets: (): JQueryPromise<any> => this.loadTickets()
        };
    }
}
