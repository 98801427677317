import { Component, Directive, HostBinding, Input } from '@angular/core';

/**
 * @Remarks This is the base class for a tab panel tab. Extend this to get the basic functionality of a tab.
 */
@Directive()
export abstract class TabBase {
  @Input() title: string;
  @Input() titleBadge: string;
  @Input() titleIcon: string;
  @HostBinding('class.active')
  @Input() active: boolean = false;

  @HostBinding('class.tab-pane') private tabPane: boolean = true;
  @HostBinding('id') private _id: string;

  get id(): string {
    return this._id;
  }

  constructor() { 
    this._id = _.uniqueId("TabPanel_");
  }

  selected(): void {
    // NOOP
  }

  deselected(): void {
    // NOOP
  }
}