import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';

type CustomerFilesOptions = app.components.interfaces.CustomerFilesOptions;

@Component({
	selector: 'app-components-ticket-files',
	templateUrl: './files.component.html'
})
export class FilesComponent extends BaseComponent {
    public options: CustomerFilesOptions;

    public activate(params: app.ticket.components.interfaces.IFilesViewModelActivationParams): JQueryPromise<void> {
        this.shouldValidateOnUpdate = false;

        if (params.featureConfig.availableFileSections.indexOf(params.featureConfig.defaultFileSection) === -1) {
            throw new Error(`Configuration error. Default section (${params.featureConfig.defaultFileSection}) does not exist in the available sections (${params.featureConfig.availableFileSections}).`);
        }

        this.options = {
            customerId: params.ticket.CustomerId(),
            fileSectionTypeId: params.featureConfig.defaultFileSection,
            fileSectionTypeIds: params.featureConfig.availableFileSections,
            canDelete: params.featureConfig.canDelete,
            canUpload: params.featureConfig.canUpload,
            ticketFilteringConfig: {
                defaultFilterByTicket: params.featureConfig.filterByTicket != null ? params.featureConfig.filterByTicket : true,
                ticketId: params.ticket.TicketId()
            },
            showOnlyCurrentFiles: params.featureConfig.showOnlyCurrentFiles != null ? params.featureConfig.showOnlyCurrentFiles : false,
            ticketTypeId: params.featureConfig.ticketTypeId,
            isFileUploadRequired: params.featureConfig.isFileUploadRequired,
            publicApiCallback: (api: app.components.interfaces.CustomerFilesPublicApi): void => {
                this.validation = api.getRequiredFileValidation();
            }
        }

        return super.activate(params);
    }
}
