
import { Component, OnInit, Input, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { SessionStorageService } from '../../../../services/session-storage.service';

@Component({
  selector: 'app-components-shared-view-local-profile-list',
  templateUrl: './view-local-profile-list.component.html'
})
export class ViewLocalProfileListComponent implements OnInit, AfterViewInit {
  @ViewChildren('localProfileList') localProfileList: QueryList<any>;
  @Input() localProfiles: Boo.Objects.LocalProfiles.LocalProfile[];
  @Input() customer: Boo.Objects.Customer;

  activeLocalProfile: Boo.Objects.LocalProfiles.LocalProfile;
  states: Boo.Objects.State[];
  isInitialized: boolean = false;

  constructor(private sessionStorageService: SessionStorageService) { }

  ngOnInit() {
    this.sessionStorageService.getStaticData()
      .subscribe(staticData => {
        this.states = staticData.States as Boo.Objects.State[];
      },
        (err) => toastr.error(err)
      );
  }

  ngAfterViewInit() {
    this.localProfileList.changes.subscribe(_ => {
      this.localProfileListRendered();
    });
  }

  setActiveLocalProfile(localProfile: Boo.Objects.LocalProfiles.LocalProfile) {
    this.activeLocalProfile = localProfile;
  }

  setFirstLocalProfileAsActive() {
    if (this.localProfiles && this.localProfiles.length > 0) {
      this.setActiveLocalProfile(this.localProfiles[0]);
    }
  }

  getStateName(stateId: number): string {
    return this.states.find(x => x.StateId === stateId)?.Name ?? '';
  }

  private localProfileListRendered() {
    if (!this.isInitialized) {
      setTimeout(() => {
        this.setFirstLocalProfileAsActive();
      }, 0);
    }
  }
}