<div>
	<ng-container *ngIf="merchantFields && merchantFieldLabelMap()">
		<div class="card">
			<div class="card-header">
				{{title}}
			</div>
			<div class="card-body">
				<div class="col-md-6">
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().SourceLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantSource" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().CategoryLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantCategory" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().CategoryIdLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantCategoryId" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().SubCategoryLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantSubCategory" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().SubCategoryIdLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantSubCategoryId" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().ProductCodeLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantProductCode" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().ConsultantLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantConsultant" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().ConsultantContactLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantConsultantContact" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().CampaignManagerLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantCampaignManager" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().CampaignManagerContactLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantCampaignManagerContact" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().SitePublishDateLabel.Value}}</label>
						<div class="col-md-12">
							<input id="datetime" [dateTimePicker]="{ date: merchantSitePublishDate, displayAsUtc: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().ItemIdLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantItemId" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().IdLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantId" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().ProposalIdLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantProposalId" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().UdacLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantUdac" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().MiscIdLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantMiscId" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().RegionLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantRegion" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().CustomerValueLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantCustomerValue" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().ContractEndDateLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantContractEndDate" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-md-12 control-label">{{merchantFieldLabelMap().ServiceLevelLabel.Value}}</label>
						<div class="col-md-12">
							<input [(ngModel)]="merchantFields().MerchantServiceLevel" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<div class="row">
					<div class="col-md-12">
						<button (click)="save()" type="button" class="btn btn-success float-end">
							<i class="fa fa-save"></i>
							Save
						</button>
						<button (click)="close()" type="button" class="btn btn-link float-end">Cancel</button>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>
