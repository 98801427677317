<div class="modal-header">
  {{ notification.MessageTitle }}
  <button type="button" class="btn btn-close" (click)="activeModal.close()"></button>
</div>
<div class="modal-body">
  <div [innerHTML]="notification.MessageBody | purifyHtml"></div>
  <ng-container *ngIf="!!notification.Files?.length">
    <hr />
    <app-table [items]="notification.Files">
      <ng-template #tableHeader>
        <tr>
          <th sort field="Filename">File Name</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-item>
        <tr>
          <td>{{item.Filename}}</td>
          <td><a [externalLink]="{ url: item.Url, text:'View File'}" class="btn btn-primary"></a></td>
        </tr>
      </ng-template>
    </app-table>
  </ng-container>
</div>