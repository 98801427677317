import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerPricingToolDetailService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/partnerpricingtooldetails`;

  constructor(private http: HttpClient) { }

  getByPartnerId(partnerId: number): Observable<Boo.Objects.SEOPresence.PartnerPricingToolDetail[]> {
    const params = new HttpParams().set('partnerId', partnerId);

    return this.http.get<Boo.Objects.SEOPresence.PartnerPricingToolDetail[]>(`${this.baseUrl}/GetByPartnerId`, { params: params });
  }

  save(partnerPricingToolDetail: Boo.Objects.SEOPresence.PartnerPricingToolDetail): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/Save`, partnerPricingToolDetail);
  }

  delete(partnerPricingToolDetailId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/Delete`, partnerPricingToolDetailId);
  }

  deleteLogo(partnerPricingToolDetailId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/DeleteLogo`, partnerPricingToolDetailId);
  }
}
