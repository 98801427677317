import System from 'framework/System';
import Check from 'framework/Check';
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

/**
 * Base class for customer account view models. 
 */
export default class CustomerAccountBase implements app.managecustomer.components.interfaces.CustomerAccountViewModel {
    public title: string = '';
    public customerAccount: CustomerAccountBaseObservable;
    public validation: KnockoutObservable<KnockoutObservable<any>[]>;
    public isEnabled: boolean;
    public canOpenUrl: boolean;
    public editableNotes: boolean;

    private mockPassword: string;
    private customerAccountService: app.services.interfaces.ICustomerAccountService;
    private entityId: number;

    constructor(private sessionStorageService: SessionStorageService) { }

    public activate(params: app.managecustomer.components.interfaces.CustomerAccountActivationParams): JQueryPromise<void> {
        Check.isNotEmpty(params, 'Parameters cannot be null');
        Check.isNotEmpty(params.customerAccountBase, 'Customer account cannot be null');
        this.canOpenUrl = params.canOpenUrl ?? true;
        this.isEnabled = params.isEnabled ?? true;
        this.title = params.title;
        this.customerAccountService = params.customerAccountService;
        this.entityId = params.entityId;
        this.editableNotes = params.EditableNotes ?? this.isEnabled;

        this.customerAccount = params.customerAccountBase;

        return Utils.wrapDfd(this.sessionStorageService.getStaticData())
            .then((staticData: Boo.Objects.LaunchPadStaticData) => {
                this.mockPassword = staticData.MockPassword;
                if (this.customerAccount.IsPasswordSet()) {
                    this.customerAccount.Password = ko.observable(staticData.MockPassword);
                    this.customerAccount.SetPassword = ko.observable(staticData.MockPassword);
                } else {
                    this.customerAccount.Password = ko.observable('');
                }

                this.customerAccount.Password.subscribe(() => {
                    this.customerAccount.SetPassword(this.customerAccount.Password());
                });

                // set base validation plus any extra passed in from params.extraValidationCallbacks
                let completeMessage: string = 'The customer account must have both a username and password.';
                let validation: KnockoutObservable<any>[] = [
                    this.customerAccount.Password.extend({
                        maxLength: 150,
                        required: {
                            message: completeMessage,
                            onlyIf: (): boolean => { return !!this.customerAccount.Url() || !!this.customerAccount.Username(); }
                        }
                    }),
                    this.customerAccount.Username.extend({
                        maxLength: 128,
                        required: {
                            message: completeMessage,
                            onlyIf: (): boolean => { return !!this.customerAccount.Url() || !!this.customerAccount.Password(); }
                        }
                    })
                ];
                _.each(params.extraValidationCallbacks, (cb) => {
                    _.each(cb(this.customerAccount) as KnockoutObservable<any>[], (v) => {
                        if (v) {
                            validation.push(v);
                        }
                    });
                });
                this.validation = ko.validatedObservable(validation);
            });
    }

    public isValid(saveType: SaveTypes): boolean {
        return this.validation.isValid();
    }

    public validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
        if (!this.validation) {
            return System.resolvedPromise({isValid: true});
        }
        
        let isValid = this.validation.isValid();
        let errorMessages: string[] = [];

        if (this.validation.errors) {
            this.validation.errors.showAllMessages(true);
            errorMessages = <string[]>ko.mapping.toJS(this.validation.errors());
        }

        return System.resolvedPromise<IValidatedResult>({ isValid: isValid, errorMessages: errorMessages });
    }

    /**
     * Fetches the password from the database and logs an attempt.
     */
    public getPassword(): void {
        if (!this.customerAccount.IsPasswordSet() || this.customerAccount.Password() !== this.mockPassword) {
            return;
        }

        // Prevent a little blip.
        this.customerAccount.Password('');

        Utils.wrapDfd(this.customerAccountService.selectPasswordByAccountType(this.entityId, this.customerAccount.CustomerAccountTypeId()))
            .then((password: string) => {
                this.customerAccount.Password(password);
            }).fail((message: string) => {
                toastr.error(message);
            });
    }
}
