<div class="card">
	<div class="card-header">
		SEO Content Survey
	</div>
	<div class="card-body" style="min-height: 100px;">
        <app-tab-panel [displayMode]="tabPanelDisplayMode.horizontal">
            <app-lazy-tab [title]="'Survey'">
                <compose *tabContent Selector="app-components-managecustomer-seocontentsurvey-survey" [ActivateOptions]="{
                                canClose: canClose,
                                canEdit: canEditSeoContentSurvey,
                                customerId: customer().CustomerId(),
                                seoContentSurvey: seoContentSurvey,
                                priorityViewApi: priorityViewApi
                            }"></compose>
            </app-lazy-tab>
            <app-lazy-tab *ngIf="showBloggingInformation" [title]="'Blogging Details'">
                <compose *tabContent Selector="app-components-managecustomer-seocontentsurvey-bloggingdetails" [ActivateOptions]="{
                                canClose: canClose,
                                canEdit: canEditSeoContentSurvey,
                                customerId: customer().CustomerId(),
                                customer: customer(),
                                seoContentSurvey: seoContentSurvey,
                                priorityViewApi: priorityViewApi,
                                partner: partner
                            }"></compose>
            </app-lazy-tab>
        </app-tab-panel>
	</div>
</div>
