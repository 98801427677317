import { Component } from '@angular/core';
import System from 'framework/System';
import BaseComponent from '../BaseComponent';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { SeoContentSurveyService } from '../../../services/seo-content-survey.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-ticket-seocontentsurvey',
  templateUrl: './SeoContentSurvey.component.html'
})
export class SeoContentSurveyComponent extends BaseComponent {
    hasSeoContent: KnockoutObservable<boolean>;
    isOpen = false;

    /**
     * Opens the SEO content survey tab in a modal window.
     */
    public editSeoContentSurvey(): void {
        PriorityViewModel.show('app-components-managecustomer-seocontentsurvey', { customer: this.customer })
            .then((contentSurvey: any) => {
                // Note: If the user cancels, we reject the defered and this code isn't hit. We don't need to repull the SEO content survey.
                this.hasSeoContent(true);
            });
    }

    constructor(private seoContentSurveyService: SeoContentSurveyService) {
      super();
    }

    activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        return super.activate(params)
            .then(() => {
                this.hasSeoContent = ko.observable(false);

                // Load the content survey.
                return Utils.wrapDfd(this.seoContentSurveyService.getByCustomerId(this.customer.CustomerId()))
                    .then((contentSurvey) => {
                        if (contentSurvey) {
                            this.hasSeoContent(true);
                        }
                    });
            });
    }

    validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
        return super.validate(saveType)
            .then((validationResult) => {
                if (!validationResult.isValid) {
                    // open toggle panel and clear suggestion filter so errors are visible
                    this.isOpen = true;
                }

                return validationResult;
            });
    }

    save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData> {
        let saveData: app.ticket.interfaces.ISaveData;
        return System.resolvedPromise(saveData);
    }
}
