<div class="card" [loadingOverlay]="isLoading">
  <div class="card-header">
    <span>
      <strong>{{internalAction.InternalTaskDetails.TaskTypeName}}</strong>
    </span>
    <button (click)="close()" class="btn-close" title="Close"></button>
    <button (click)="timer.toggle()" style="margin-right: 20px;" class="btn btn-primary btn-sm float-end">
      <i [class.fa-play]="timer.isPaused" [class.fa-pause]="!timer.isPaused" class="fas"></i>
      <span><shared-components-timer [timer]="timer" [showSeconds]="true"></shared-components-timer></span>
    </button>
  </div>
  <div class="card-body">
    <ng-template #workspace></ng-template>
    <div *ngIf="!hideActionBar" class="card card-body bg-light">
      <form class="form-inline" role="form">
        <div class="col-md-12">
          <div class="float-end">
            <button *ngIf="showDeleteButton" (click)="delete()" class="btn btn-danger">
              <i class="fa fa-times"></i>
              Delete
            </button>
            <button *ngIf="showCloseButton" (click)="close()" class="btn btn-primary">Close</button>
            <button *ngIf="showSaveButton" (click)="save()" class="btn btn-primary">Save &amp;
              Close</button>
            <button *ngIf="showRejectButton" (click)="reject()" class="btn btn-danger">
              <i class="fa fa-times"></i>
              Reject
            </button>
            <button *ngIf="showUnlockButton" (click)="unlock()" class="btn btn-info">
              <i class="fa fa-unlock"></i>
              Unlock &amp; Unassign
            </button>
            <button *ngIf="showCompleteButton" (click)="complete()" class="btn btn-success">
              <i class="fa fa-check"></i>
              Complete
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>