<div>
  <div class="row align-items-center">
    <div class="col-md-1 d-flex justify-content-end gap-1">
      <ng-container *ngIf="urlForm.enabled">
        <a class="fa fa-save" (click)="updateUrl()"></a>
        <a class="fa fa-undo" (click)="cancelEditUrl()"></a>
      </ng-container>
      <ng-container *ngIf="urlForm.disabled && !isRedoRequested">
        <a class="fa fa-edit" (click)="urlForm.enable()"></a>
      </ng-container>
    </div>
    <ng-container *ngIf="urlSuggestion.IsExisting || urlForm.enabled">
      <div class="col-md-6">
        <input class="form-control" [(formControl)]="urlForm" lpValidationMsg>
      </div>
    </ng-container>
    <ng-container *ngIf="!urlSuggestion.IsExisting && urlForm.disabled">
      <div class="col-md-6">
        <input class="form-control" [value]="'Create New Page'" [disabled]="true">
      </div>
    </ng-container>
    <div class="col-md-5 d-flex justify-content-end gap-1">
      <button class="btn btn-info" (click)="copyKeywordsToClipboard()">
        <i class="fa-regular fa-clipboard" style="margin: 0px"></i>
      </button>
      <button class="btn btn-info" [disabled]="newKeywordForm.enabled || isRedoRequested"
        (click)="openModal(addKeywordModal)">+ Add Customer Choice Keyword</button>
      <ng-container *ngIf="csrSuggestedUrl">
        <button class="btn btn-danger" (click)="deleteUrl()">Delete Url</button>
      </ng-container>
    </div>
    <ng-container *ngFor="let group of keywordGroups">
      <div class="mt-3">
        <app-components-shared-keywords-keyword-suggestion-group [suggestions]="group.keywordSuggestions"
          [redoRequested]="redoRequested" [recommendationSelected]="recommendationSelected" [type]="group.type"
          [(isApproved)]="group.isApproved" [(isRejected)]="group.isRejected"
          (approved)="approveType($event)" (removedKeyword)="removeKeyword($event)">
        </app-components-shared-keywords-keyword-suggestion-group>
      </div>
    </ng-container>
    <div class="row mt-1">
      <div class="col-md-12">
        <ng-container *ngIf="!isRedoRequested && !csrSuggestedUrl">
          <button class="btn btn-danger" (click)="keywordRedoForm.enable()"
            [disabled]="keywordRedoForm.enabled">Redo</button>
        </ng-container>
        <ng-container *ngIf="isRedoRequested && canCancelRedo">
          <button class="btn btn-info" (click)="cancelRedo()">Cancel Redo</button>
        </ng-container>
        <ng-container *ngIf="approvedType && !isRedoRequested">
          <button class="btn btn-info" (click)="undoApproval()">Undo</button>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="keywordRedoForm.enabled">
      <div class="row mt-1">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <strong>Request a Keyword Research Redo</strong>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <textarea class="form-control" [(formControl)]="keywordRedoForm" lpValidationMsg></textarea>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-12 d-flex justify-content-end gap-1">
                  <button class="btn btn-danger" (click)="resetRedoRequestForm()">Cancel</button>
                  <button class="btn btn-success" (click)="requestRedo()">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #addKeywordModal let-modal>
  <div class="modal-content" [(formGroup)]="newKeywordForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Customer Choice Keyword</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body" style="min-height: 290px;" [formValidation]="newKeywordForm">
      <div class="form-group">
        <label class="control-label">Keyword</label>
        <input type="text" class="form-control" formControlName="keyword" lpValidationMsg />
      </div>
      <div *ngIf="!keywordSuggestionValidator.isNational" class="form-group">
        <label class="control-label">Area</label>
        <input type="text" class="form-control" formControlName="area" lpValidationMsg />
      </div>
      <div class="form-group">
        <label class="control-label">Keyword Type</label>
        <ng-select [items]="keywordTypes" formControlName="type" placeholder="Select a keyword type" [clearable]="false"
          bindLabel="Name" bindValue="KeywordSuggestionTypeId"></ng-select>
      </div>
      <div *ngIf="!keywordSuggestionValidator.isNational" class="form-group">
        <label class="control-label">Display area last?</label>
        <div>
          <shared-components-customchoice [value]="newKeywordForm.get('isAreaLast').value" [choiceTrue]="'Yes'"
            [choiceFalse]="'No'"
            (valueChange)="newKeywordForm.get('isAreaLast').patchValue($event)"></shared-components-customchoice>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
      <button type="button" class="btn btn-success" (click)="saveNewKeyword(modal)">Save</button>
    </div>
  </div>
</ng-template>