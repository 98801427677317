<div class="card">
  <div class="card-header">
    Published Custom Blogs
  </div>
  <div class="card-body">
    <div style="min-height: 300px;">
      <div class="row">
        <div class="col-md-12">

          <app-table [items]="blogPosts" [exportFilename]="'published-custom-blogs'">
            <ng-template #tableHeader>
              <tr>
                <th export sort field="ContentTitle">Post Title</th>
                <th export sort desc field="PostedDate">Published Date</th>
                <th>Action</th>
              </tr>
            </ng-template>
            <ng-template #tableBody let-blog>
              <tr>
                <td [externalLink]="{ url: blog.Task.TaskActionUrl, text: blog.ContentTitle }"></td>
                <td>{{blog.PostedDate | dateText: 'MM/DD/YYYY' }}</td>
                <td>
                  <button (click)="editBlog(blog)" class="btn btn-primary">
                    <i class="far fa-edit"></i>
                    Edit
                  </button>
                </td>
              </tr>
            </ng-template>
          </app-table>
        </div>
      </div>
    </div>
  </div>
</div>