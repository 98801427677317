<div class="row">
  <span class="col-md-12">Add Task Type</span>
  <div class="col-md-6">
    <div class="row g-0 my-3">
      <div class="col-md-9">
        <ng-select [items]="orderableTaskTypes" [multiple]="false" [closeOnSelect]="true" [searchable]="true" [clearable]="false"
          bindLabel="Name" placeholder="Choose..." groupBy="IsCustom" [(ngModel)]="selectedTaskType"
          [searchFn]="searchTaskTypes">

          <ng-template ng-label-tmp let-item="item">
            <i [ngClass]="getIconClass(item)"></i>
            <b class="ms-2">{{item.Name}}</b>
            <span> ({{ item.Minutes }} minutes)</span>
          </ng-template>

          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.IsCustom ? 'Manual' : 'Automated' }}
            <div class="float-end">
              Minutes
            </div>
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            <i [ngClass]="getIconClass(item)"
              title="{{ getTitle(item) }}"></i>
            <b class="ms-2">{{item.Name}}</b>
            <span class="float-end">{{ item.Minutes }}</span>
          </ng-template>

        </ng-select>
      </div>
      <div class="col-md-3">
        <label class="control-label"></label>
        <button (click)="add()" class="btn btn-primary"
          [disabled]="!selectedTaskType || !canBeOrdered(selectedTaskType.TaskTypeId)"><i class="fa fa-plus"></i>Add</button>
      </div>
    </div>
  </div>
  <div class="col-md-6" *ngIf="selectedTaskType">
    <div *ngFor="let flag of selectedTaskType.Flags">
      <ng-container *ngIf="!isNonworkableFlag(flag)">
        <app-components-managecustomer-allocatework-tasktypeflag [flag]="flag">
        </app-components-managecustomer-allocatework-tasktypeflag>
      </ng-container>
      <ng-container *ngIf="isNonworkableFlag(flag)">
        <app-components-managecustomer-allocatework-nonworkabletasktypeflag
          [flag]="flag"
          [customerId]="customerId"
          [taskTypeId]="selectedTaskType.TaskTypeId"
          [taskTypeName]="selectedTaskType.Name"
          (refresh)="refresh.emit()">
        </app-components-managecustomer-allocatework-nonworkabletasktypeflag>
      </ng-container>
    </div>
  </div>
</div>
