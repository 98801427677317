import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import Password from '../../../components/system/password'
import { PartnerUserService } from '../../../services/partner-user.service';
import { SessionStorageService } from '../../../services/session-storage.service';

@Component({
  selector: 'app-components-changepassword',
  templateUrl: './ChangePassword.component.html'
})
export class ChangePasswordComponent implements OnInit {
  title = 'Change Password';
  user: Boo.Objects.User;
  isLoading: boolean = false;
  changePasswordForm: UntypedFormGroup;
  private dfd: JQueryDeferred<void>;

  constructor(
    private fb: UntypedFormBuilder,
    private partnerUserService: PartnerUserService,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group(
      {
        currentPassword: [
          '',
          Validators.compose([Validators.required])
        ],
        password: [
          '',
          Validators.compose(Password.validators)
        ],
        confirmPassword: ['', Validators.compose([Validators.required])]
      },
      {
        validator: Password.passwordMatchValidator
      }
    );

    this.isLoading = true;
    this.sessionStorageService.getUser()
    .pipe(finalize(() => this.isLoading = false))
      .subscribe((user) => this.user = user);
  }

  cancel(): void {
    if (this.dfd) {
      this.dfd.reject();
    }
  }

  update(): void {
    this.isLoading = true;

    if (!this.changePasswordForm.valid) {
      toastr.error('Password is not valid.');
    }

    const fControls = this.changePasswordForm.controls;
    this.partnerUserService.changePassword(this.user.Username, fControls['currentPassword'].value, fControls['password'].value)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(() => {
        toastr.success('Password was changed successfully.');
        if (this.dfd) {
          this.dfd.resolve();
        }
      }, (err) => toastr.error('Failed to change password. Ensure your current password was entered correctly.'));
  }
}
