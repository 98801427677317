import { Component } from '@angular/core';
import Check from 'framework/Check';
import Grid from 'app/components/Grid';
import IWebsiteTemplateSelectorActivateParams = app.components.websites.interfaces.IWebsiteTemplateSelectorActivateParams;
import IPriorityViewApi = app.interfaces.IPriorityViewPublicApi;
import System from 'framework/System';
import { WebsiteService } from '../../../services/website.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-websites-websitetemplateselector',
  templateUrl: './WebsiteTemplateSelector.component.html'
})
export class WebsiteTemplateSelectorComponent implements IActivatable {
    public title = 'Select Template'; // for priority view. i hate this. add a better way?
    public currentTemplateId: number;
    public grid: KnockoutObservable<Grid<Boo.Objects.Websites.WebsiteTemplate>>;
    public pagerOptions: app.components.interfaces.IPagerActivateParams;
    public isLoading: KnockoutObservable<boolean>;
    public areThumbnailsEnabled: KnockoutObservable<boolean>;

    private websiteId: number;
    private priorityViewApi: IPriorityViewApi;
    private pagerApi: app.components.interfaces.IPagerPublicApi;

    constructor(private websiteService: WebsiteService) { }

    public activate(params: IWebsiteTemplateSelectorActivateParams): JQueryPromise<void> {
        Check.isNotEmpty(params.websiteId, 'websiteId cannot be empty');
        Check.isNotEmpty(params.priorityViewApi, 'WebsiteTemplateSelectorViewModel must be launched in a priority view');

        this.websiteId = params.websiteId;
        this.priorityViewApi = params.priorityViewApi;
        this.currentTemplateId = params.templateId || null;
        this.grid = ko.observable(new Grid<Boo.Objects.Websites.WebsiteTemplate>([]));
        this.isLoading = ko.observable(false);
        this.areThumbnailsEnabled = ko.observable(true);

        this.pagerOptions = {
            apiCallback: (api): void => { 
                this.pagerApi = api;

                // after pager api is ready, load first page of templates.
                this.loadTemplates();
            },
            stateChangeCallback: (page: number, pageSize: number): JQueryPromise<void> => this.loadTemplates(page, pageSize),
            pageSize: 48,
            pageSizeOptions: [24, 48, 96]
        };

        return System.emptyPromise();
    }

    public isCurrentTemplate(template: Boo.Objects.Websites.WebsiteTemplate): boolean {
        // the view may set the ids as strings, so parse them both to be sure this works.
        return template.Id && this.currentTemplateId &&
            parseInt(template.Id as any, 10) === parseInt(this.currentTemplateId as any, 10);
    }

    public selectTemplate(template: Boo.Objects.Websites.WebsiteTemplate): void {
        Check.isNotEmpty(template, 'template cannot be empty');
        this.priorityViewApi.resolve(template.Id);
    }

    public close(): void {
        this.priorityViewApi.reject();
    }

    private loadTemplates(page?: number, pageSize?: number): JQueryPromise<void> {
        if (!this.pagerApi) {
            throw new Error('Pager must be ready before calling loadTemplates');
        }
        
        this.isLoading(true);

        page = Math.max(1, page || this.pagerApi.getPage());
        pageSize = Math.max(1, pageSize || this.pagerApi.getPageSize());

        return Utils.wrapDfd(this.websiteService.getTemplates(this.websiteId, page, pageSize))
            .then((result) => {
                this.areThumbnailsEnabled(this.hasThumbnails(result.Items));
                this.grid(new Grid<Boo.Objects.Websites.WebsiteTemplate>(result.Items || [], 4));    
                this.pagerApi.setState(result.Count, page, pageSize);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    private hasThumbnails(templates: Boo.Objects.Websites.WebsiteTemplate[]): boolean {
        if (templates && templates.length > 0) {
            // check first, last, middle rows and assume the rest match. this is faster and thorough enough.
            let templatesToCheck = [templates[0], templates[templates.length - 1], templates[Math.floor(templates.length / 2)]];
            return templatesToCheck.some(x => !!x.ThumbnailUrl);
        }
        return false;
    }
}
