import ITodoObservable = app.tsmodels.interfaces.ITodoObservable;

export default class TodoFactory {
    public createLegacy(todoListId: number, userId: number, content: string = ''): ITodoObservable {
        return {
            Content: ko.observable(content),          
            InsertedDate: ko.observable(null),
            InsertedUserId: ko.observable(userId),
            Status: ko.observable(Boo.Objects.Todo.Enums.TodoStatus.New),
            StatusDate: ko.observable(moment.utc().toDate()),
            StatusUserId: ko.observable(userId),
            TodoGroup: ko.observable(null),
            TodoGroupId: ko.observable(null),
            TodoId: ko.observable(0),
            TodoListId: ko.observable(todoListId),
            TodoNotes: ko.observableArray([]),
            SourceTodoId: ko.observable(null),
            Delegated: ko.observable(false)
        } as ITodoObservable;
    }

  public create(todoListId: number, userId: number, content: string = '', sourceTodoId: number = null): Boo.Objects.Todo.Todo {
    return <Boo.Objects.Todo.Todo>({
      Content: content,
      InsertedDate: null,
      InsertedUserId: userId,
      Status: Boo.Objects.Todo.Enums.TodoStatus.New,
      StatusDate: moment.utc().toDate(),
      StatusUserId: userId,
      TodoGroup: null,
      TodoGroupId: null,
      TodoId: 0,
      TodoListId: todoListId,
      TodoNotes: [],
      SourceTodoId: sourceTodoId,
      Delegated: false,
    });
  }
}
