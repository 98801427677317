import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-components-specialist-internal-tasks-additional-info',
	templateUrl: './site-themes-additional-info.component.html'
})
export class SiteThemesAdditionalInfoComponent implements OnInit {
    @Input() internalAction: Boo.Objects.InternalAction;
    notes: Boo.Objects.InternalTaskNote[];

    ngOnInit() {
        this.notes = this.internalAction.InternalTaskDetails.InternalTaskNotes;
    }
}
