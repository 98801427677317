<div>
	<div id="customer-note-float-panel" class="float-panel float-panel-legacy">
		<div class="float-panel-tab">
			<button class="float-panel-btn-expand btn btn-lg btn-primary">
				<i class="fa fa-sticky-note"></i>
			</button>
		</div>
		<div class="float-panel-main card">
			<div class="float-panel-draggable card-header">
				<i class="fa fa-sticky-note"></i>
				Note
				<i class="float-panel-btn-collapse fa fa-minus float-end"></i>
			</div>
			<div class="card-body">
				<textarea [dataValidation]="{ placement: 'top', value: noteText }" [ngModel]="noteText()" (ngModelChange)="noteText($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" name="note" maxlength="4000" placeholder="Enter note (4,000 characters max)"></textarea>
			</div>
			<div class="card-footer">
				<div class="row">
					<div class="col-sm-5">
						<select [dataValidation]="{ placement: 'top', value: contactTypeId }" [ngModel]="contactTypeId()" (ngModelChange)="contactTypeId($event)" [ngModelOptions]="{ standalone: true }" class="form-select input-sm" name="contactTypeId">
							<option [ngValue]="undefined">Contact Type</option>
							<option *ngFor="let option of contactTypes" [ngValue]="option.ContactTypeId">{{ option.Name }}</option>
						</select>
					</div>
					<div class="col-sm-7 text-end">
						<div class="btn-group">
							<button (click)="save()" class="btn btn-sm btn-primary">
								<i class="fa fa-save"></i>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
