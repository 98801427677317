<ng-container *ngFor="let url of websiteUrls">
  <div class="row">
    <div class="col-md-12">
      <table class="table table-bordered table-hover table-primary">
        <thead>
          <tr>
            <th>{{url.Url}}</th>
          </tr>
        </thead>
        <ng-container *ngFor="let keyword of url.Keywords">
          <tbody>
            <tr>
              <td>{{keyword.KeywordPhrase}}</td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </div>
  </div>
</ng-container>