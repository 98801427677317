import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';

@Component({
	selector: 'app-components-ticket-customreports',
	templateUrl: './CustomReports.component.html'
})
export class CustomReportsComponent extends BaseComponent {
    public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        return super.activate(params);
    }
}
