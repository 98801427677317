import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "emailRecipientType"
})
export class EmailRecipientTypePipe implements PipeTransform {
  private typeMap: Record<Boo.Objects.Enums.EmailRecipientTypes, string>  = {
    [Boo.Objects.Enums.EmailRecipientTypes.To] :  'to',
    [Boo.Objects.Enums.EmailRecipientTypes.Cc] :  'cc',
    [Boo.Objects.Enums.EmailRecipientTypes.Bcc] :  'bcc'
  }

  transform(value: Boo.Objects.Enums.EmailRecipientTypes, ...args: any[]): any {
    return value ? this.typeMap[value] : '';
  }
}