import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskRejectionService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/taskrejections`;

  constructor(private http: HttpClient) { }

  get(taskId: number): Observable<Boo.Objects.TaskRejection[]> {
    return this.http.get<Boo.Objects.TaskRejection[]>(`${this.baseUrl}/${taskId}`);
  }

  getLatest(taskId: number): Observable<Boo.Objects.TaskRejection> {
    return this.http.get<Boo.Objects.TaskRejection>(`${this.baseUrl}/latest/${taskId}`);
  }

  save(taskRejection: Boo.Objects.TaskRejection): Observable<any> {
    return this.http.post(`${this.baseUrl}/save`, taskRejection);
  }
}
