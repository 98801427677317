<app-components-specialist-internal-tasks-qa-action-information
  [internalAction]="internalAction"></app-components-specialist-internal-tasks-qa-action-information>
<div [loadingOverlay]="isLoading" id="internal-task-qa-criteria-float-panel" class="float-panel">
  <div class="float-panel-tab">
    <button class="float-panel-btn-expand btn btn-lg btn-primary">
      <i class="fa fa-check"></i>
    </button>
  </div>
  <div class="float-panel-main card">
    <div class="float-panel-draggable card-header">
      <i class="fa fa-check"></i>
      Profile Submission Quality
      <i class="float-panel-btn-collapse fa fa-minus float-end"></i>
    </div>
    <div class="card-body" style="overflow-y: scroll; max-height: 550px;">
      <div class="row">
        <div class="col-md-12">
          <ul class="list-group">
            <li class="list-group-item">
              <a [externalLink]="{ url: rubricUrl, text: 'Rubric' }"></a>
            </li>
            <li class="list-group-item">
              <a [externalLink]="{ url: diffCheckerUrl, text: 'DiffChecker' }"></a>
            </li>
          </ul>
        </div>
      </div>
      <app-components-qa-grading-area-panel [taskType]="taskType"
        [qaCriteriaGroups]="qaCriteriaGroups" #gradingArea>
      </app-components-qa-grading-area-panel>
    </div>
    <div class="card-footer">
      <div class="text-end">
        <button (click)="close.next()" class="btn btn-primary">Close</button>
        <button (click)="unlock.next()" class="btn btn-info">
          <i class="fa fa-unlock"></i>
          Unlock &amp; Unassign
        </button>
        <button (click)="complete.next()" class="btn btn-success">
          <i class="fa fa-check"></i>
          Complete
        </button>
      </div>
    </div>
  </div>
</div>
<div [loadingOverlay]="isLoading">
  <div [hidden]="!showPlagiarismTable" class="row">
    <div class="col-md-12">
      <div class="card card-danger">
        <div class="card-header">
          <div>Duplicate Content Warning</div>
        </div>
        <div class="card-body">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th>Url</th>
                <th>Percentage</th>
                <th>Matched Text</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="contentReviewSnapshot.Url1">
                <td>
                  <a [externalLink]="{ url: contentReviewSnapshot.Url1, text:contentReviewSnapshot.Url1}"></a>
                </td>
                <td class="text-danger">{{(contentReviewSnapshot.Url1Percentage * 100) + '%'}}</td>
                <td>
                  <span [ngbPopover]="contentReviewSnapshot.Url1MatchedText" popoverTitle="Matched Text"
                    placement="right auto" triggers="mouseenter:mouseleave">
                    <i class="far fa-file-alt"></i>
                    (Hover for Matched Text)
                  </span>
                </td>
              </tr>
              <tr *ngIf="contentReviewSnapshot.Url2">
                <td>
                  <a [externalLink]="{ url: contentReviewSnapshot.Url2, text:contentReviewSnapshot.Url2}"></a>
                </td>
                <td class="text-danger">{{(contentReviewSnapshot.Url2Percentage * 100) + '%'}}</td>
                <td>
                  <span [ngbPopover]="contentReviewSnapshot.Url2MatchedText" popoverTitle="Matched Text"
                    placement="right auto" triggers="mouseenter:mouseleave">
                    <i class="far fa-file-alt"></i>
                    (Hover for Matched Text)
                  </span>
                </td>
              </tr>
              <tr *ngIf="contentReviewSnapshot.Url3">
                <td>
                  <a [externalLink]="{ url: contentReviewSnapshot.Url3, text:contentReviewSnapshot.Url3}"></a>
                </td>
                <td class="text-danger">{{(contentReviewSnapshot.Url3Percentage * 100) + '%'}}</td>
                <td>
                  <span [ngbPopover]="contentReviewSnapshot.Url3MatchedText" popoverTitle="Matched Text"
                    placement="right auto" triggers="mouseenter:mouseleave">
                    <i class="far fa-file-alt"></i>
                    (Hover for Matched Text)
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div [hidden]="!showSpecialConsiderations" class="alert alert-info">
    <strong>Special Considerations:</strong>
    <span>{{specialConsiderations}}</span>
  </div>
  <ng-container *ngIf="wasAccepted">
    <div class="row pt-4">
      <div class="col-md-2">
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Country</span>
            </div>
          </div>
          <div class="card-body card-body-small">
            <ng-container *ngIf="customerCountry">
              <span>{{customerCountry.Name}}</span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Language</span>
            </div>
          </div>
          <div class="card-body card-body-small">
            <ng-container *ngIf="customerLanguage">
              <span>{{customerLanguage.Name}}</span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Business Category</span>
            </div>
          </div>
          <div class="card-body card-body-small">
            <span>{{businessCategory}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Target Keyword</span>
            </div>
          </div>
          <div class="card-body card-body-small">
            <span>{{targetKeyword}}</span>
          </div>
        </div>
      </div>
      <div [hidden]="!showTargetArea" class="col-md-3">
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Target Service/Area Location</span>
            </div>
          </div>
          <div class="card-body card-body-small">
            <span>{{targetArea}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Link Strategy Type</span>
            </div>
          </div>
          <div class="card-body card-body-small">
            <div>{{linkStrategyType}}</div>
          </div>
        </div>
      </div>
      <div [hidden]="!showRecommendedAnchorText" class="col-md-2">
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Recommended Anchor Text</span>
            </div>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item list-group-item-small cursor-pointer">
                <span>{{recommendedAnchorText}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div [hidden]="!showAdditionalKeywords" class="col-md-2">
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Additional Keywords</span>
            </div>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <ng-container *ngFor="let $data of additionalKeywords">
                <li class="list-group-item list-group-item-small cursor-pointer">
                  <span>{{$data}}</span>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="title" class="control-label">Original {{blogTitle}}</label>
          <input readonly [ngModel]="originalContentTitle" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            name="title" class="form-control" placeholder="Enter Title" />
        </div>
        <div class="form-group">
          <label for="title" class="control-label">Original {{standardBacklinkContent}}</label>
          <div class="clear-fix"></div>
          <div>
            <textarea #editorRichText
              [richText]="{ content: originalContent, options: editorToolbar, wordCount: originalWordCount, autoGrow: true }"
              name="originalContent" rows="15" style="height: 300px;" class="form-control"
              placeholder="Enter Content"></textarea>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="title" class="control-label">Reviewed {{blogTitle}}</label>
          <input readonly [ngModel]="reviewedContentTitle" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            name="title" class="form-control" placeholder="Enter Title" />
        </div>
        <div class="form-group">
          <label class="control-label">Reviewed {{standardBacklinkContent}}</label>
          <div class="clear-fix"></div>
          <div>
            <textarea #editorRichText
              [richText]="{ content: reviewedContent, options: editorToolbar, wordCount: reviewedWordCount, autoGrow: true }"
              name="reviewedContent" rows="15" style="height: 300px;" class="form-control" placeholder="Enter Content">
              </textarea>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!wasAccepted">
    <div class="row">
      <div class="col-md-8">
        <div [hidden]="!showSpecialConsiderations" class="alert alert-info">
          <strong>Special Considerations:</strong>
          <span>{{specialConsiderations}}</span>
        </div>
        <div class="form-group">
          <label for="title" class="control-label">{{blogTitle}}</label>
          <input readonly [ngModel]="originalContentTitle" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            name="title" class="form-control" placeholder="Enter Title" />
        </div>
        <div class="form-group">
          <label class="control-label">{{standardBacklinkContent}}</label>
          <div class="clear-fix"></div>
          <div>
            <textarea #editorRichText
              [richText]="{ content: originalContent, options: editorToolbar, wordCount: originalWordCount, autoGrow: true }"
              name="content" rows="15" style="height: 300px;" class="form-control"
              placeholder="Enter Content"></textarea>
          </div>
        </div>
      </div>
      <div class="col-md-4" style="padding-left: 3px;">
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Country</span>
            </div>
          </div>
          <div class="card-body card-body-small">
            <ng-container *ngIf="customerCountry">
              <span>{{customerCountry.Name}}</span>
            </ng-container>
          </div>
        </div>
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Language</span>
            </div>
          </div>
          <div class="card-body card-body-small">
            <ng-container *ngIf="customerLanguage">
              <span>{{customerLanguage.Name}}</span>
            </ng-container>
          </div>
        </div>
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Business Category</span>
            </div>
          </div>
          <div class="card-body card-body-small">
            <span>{{businessCategory}}</span>
          </div>
        </div>
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Target Keyword</span>
            </div>
          </div>
          <div class="card-body card-body-small">
            <span>{{targetKeyword}}</span>
          </div>
        </div>
        <div [hidden]="!showTargetArea" class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Target Service/Area Location</span>
            </div>
          </div>
          <div class="card-body card-body-small">
            <span>{{targetArea}}</span>
          </div>
        </div>
        <div class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Link Strategy Type</span>
            </div>
          </div>
          <div class="card-body card-body-small">
            <div>{{linkStrategyType}}</div>
          </div>
        </div>
        <div [hidden]="!showRecommendedAnchorText">
          <div class="card">
            <div class="card-header card-header-small">
              <div>
                <span>Recommended Anchor Text</span>
              </div>
            </div>
            <ul class="list-group">
              <li class="list-group-item list-group-item-small cursor-pointer">
                <span>{{recommendedAnchorText}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div [hidden]="!showAdditionalKeywords" class="card">
          <div class="card-header card-header-small">
            <div>
              <span>Additional Keywords</span>
            </div>
          </div>
          <ul class="list-group">
            <ng-container *ngFor="let $data of additionalKeywords">
              <li class="list-group-item list-group-item-small cursor-pointer">
                <span>{{$data}}</span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="col-md-8">
      <div class="form-group">
        <label for="contentTags" class="control-label">Tags</label>
        <input readonly [ngModel]="tags" [ngModelOptions]="{ standalone: true }" name="contentTags" class="form-control"
          placeholder="Enter Tags" />
      </div>
      <div class="form-group">
        <label class="control-label">Reviewer Comments</label>
        <div class="clear-fix"></div>
        <div>
          <textarea [richText]="{ content: reviewerComments, options: reviewerToolbar }" name="reviewerComment" rows="5"
            class="form-control" placeholder="Enter Reviewer Comments" maxlength="4000"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>