import Mustache, { TemplateSpanType, TemplateSpans } from 'mustache';
import { MustacheArrayTag, MustacheBooleanTag, MustacheEmptyTag, MustacheImplicitArrayTag, MustacheTag, MustacheTagTypes, MustacheTextTag, MustacheRenderValue } from './models/mustache';

/**
 * MustacheHelper is a utility class that provides methods for retrieving Mustache tags from a template string as well as flattening the tags into a single object for rendering.
 */
export class MustacheHelper {
  /**
   * Get the Mustache tags for an array of templates.
   * @param templates The array of templates to parse.
   * @returns An array of Mustache tags.
   */
  static getTagsForTemplates(templates: string[]): MustacheTag[] {
    return this.pruneTree(templates.flatMap(template => this.getTagsForTemplate(template)));
  }
  /**
   * Gets the Mustache tags for a template.
   * @param template The template to parse.
   * @returns An array of Mustache tags.
   */
  static getTagsForTemplate(template: string): MustacheTag[] {
    const tags = Mustache.parse(template);
    return this.parseTags(tags);
  }
  /**
   * Flattens an array of Mustache tags into an object the Mustache library can use to render the template.
   * @param tags An array of Mustache tags to flatten.
   * @returns An object that contains all the data needed to render the template.
   */
  static flattenTags(tags: MustacheTag[]): MustacheRenderValue {
    let x: MustacheRenderValue = {};
    tags.forEach(tag => {
      switch (tag.type) {
        case MustacheTagTypes.text:
          x[tag.key] = (tag as MustacheTextTag).value;
          break;
        case MustacheTagTypes.boolean:
          x[tag.key] = (tag as MustacheBooleanTag).value;
          break;
        case MustacheTagTypes.array:
          x[tag.key] = (tag as MustacheArrayTag).value?.map(y => this.flattenTags(y));
          break;
        case MustacheTagTypes.implicitArray:
          x[tag.key] = (tag as MustacheImplicitArrayTag).value?.map(x => x.value);
          break;
      }
    });
    return x;
  }
  /**
   * Retrieves all the keys from an array of Mustache tags.
   * @param tags An array of Mustache tags.
   * @returns All the keys from the tags and their subtags.
   */
  static getKeys(tags: MustacheTag[]): string[] {
    let x: Set<string> = new Set<string>();
    tags.forEach(tag => {
      switch (tag.type) {
        case MustacheTagTypes.text:
        case MustacheTagTypes.boolean:
        case MustacheTagTypes.implicitArray:
          x.add(tag.key);
          break;
        case MustacheTagTypes.array:
          x.add(tag.key);
          this.getKeys((tag as MustacheArrayTag).subKeys).forEach(key => x.add(key));
          break;
      }
    });
    return Array.from(x);
  }
  /**
   * Parses the Mustache tags from a template and then prunes them to remove any duplicates.
   * @param tags TemplateSpans from the Mustache library parser.
   * @returns An array of Mustache tags with duplicates removed.
   */
  private static parseTags(tags: TemplateSpans): MustacheTag[] {
    const ast = tags.flatMap(tag => this.parseTag(tag)).filter(node => node.type !== MustacheTagTypes.empty);
    return this.pruneTree(ast);
  }
  /**
   * Parses a single TemplateSpan from the Mustache library parser into a MustacheTag or MustacheTag array.
   * @param tag A TemplateSpan from the Mustache library parser, which is a tuple with a specific structure based on the tag type.
   * @returns A MustacheTag or MustacheTagArray.
   */
  private static parseTag(tag: [TemplateSpanType, string, number, number]
    | [TemplateSpanType, string, number, number, TemplateSpans, number]
    | [TemplateSpanType, string, number, number, string, number, boolean]): MustacheTag | MustacheTag[] {
    switch (tag[0]) {
      case "name":
      case "&":
        return new MustacheTextTag(tag[1]);
      case "^":
        return [new MustacheBooleanTag(tag[1]), ...this.parseTags(tag[4] as TemplateSpans)];
      case "#":
        let subKeys = this.parseTags(tag[4] as TemplateSpans);

        if (subKeys.length === 0) {
          return new MustacheBooleanTag(tag[1]);
        }
        else if (subKeys.length === 1 && subKeys[0].key === '.') {
          return new MustacheImplicitArrayTag(tag[1])
        }
        else {
          return new MustacheArrayTag(tag[1], subKeys);
        }
      default:
        return new MustacheEmptyTag();
    }
  }
  /**
   * Prunes the Mustache tags to remove any duplicates. Condenses certain types of tags into a single tag, and errors on any invalid tag combinations.
   * @param ast An array of Mustache tags to prune.
   * @returns A pruned array of Mustache tags.
   */
  private static pruneTree(ast: MustacheTag[]): MustacheTag[] {
    let acc: MustacheTag[] = [];
    ast.forEach(node => {
      let existing = acc.find(t => t.key === node.key);
      // No handler means no change needed.
      if (!!existing) {
        if (node.type === MustacheTagTypes.boolean) {
          return;
        }
        else if (existing.type === MustacheTagTypes.boolean
          || (existing.type === MustacheTagTypes.implicitArray && node.type === MustacheTagTypes.text)
          || (existing.type === MustacheTagTypes.implicitArray && node.type === MustacheTagTypes.array))
        {
          acc[acc.indexOf(existing)] = node;
        }
        else if (existing.type === MustacheTagTypes.array && node.type === MustacheTagTypes.array) {
          (existing as MustacheArrayTag).subKeys = !!existing.subKeys ? existing.subKeys.concat((node as MustacheArrayTag).subKeys) : (node as MustacheArrayTag).subKeys;
        }
        else if ((existing.type === MustacheTagTypes.text && node.type === MustacheTagTypes.array)
          || (existing.type === MustacheTagTypes.array && node.type === MustacheTagTypes.text)) {
          throw new Error(`Invalid template tag: ${existing.key}. Template key is used as text and an array.`);
        }
      }
      else {
        acc.push(node);
      }
    });

    acc.forEach(node => {
      if (node.type === MustacheTagTypes.array) {
        node.subKeys = this.pruneTree((node as MustacheArrayTag).subKeys);
      }
    });

    return acc;
  }
}