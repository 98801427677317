<div [loadingOverlay]="isLoading">
  <div class="row">
    <div class="col-md-12">
      <ul class="list-group">
        <li class="list-group-item" *ngIf="onsiteRecommendationImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Description</strong>
            </div>
            <div class="col-md-10">
              {{ onsiteRecommendationImplementationSummary.Description }}
            </div>
          </div>
        </li>
        <li class="list-group-item" *ngIf="onsiteRecommendationImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Instructions</strong>
            </div>
            <div class="col-md-10 preserve-whitespace">{{ onsiteRecommendationImplementationSummary.Instructions }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Onsite Changes</strong>
            </div>
            <div class="col-md-10">
              <ng-container *ngIf="onsiteChangeFileUrl">
                <a [externalLink]="{ url: onsiteChangeFileUrl, text:'View File' }" name="blogUrl" rel="noreferrer"></a>
              </ng-container>
              <ng-container *ngIf="!onsiteChangeFileUrl">
                Not found
              </ng-container>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New Title</strong>
            </div>
            <div class="col-md-10">
              {{ newTitle }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New Description</strong>
            </div>
            <div class="col-md-10">
              {{ newDescription }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New H1</strong>
            </div>
            <div class="col-md-10">
              {{ newH1 }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New H2</strong>
            </div>
            <div class="col-md-10">
              {{ newH2 }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>New Alt Image</strong>
            </div>
            <div class="col-md-10">
              {{ newAltImage }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <br />
  <br />
  <div class="row">
    <div class="col-md-6">
      <div>
        <app-components-specialist-taskfiles [options]="{
          taskId: currentAction().Task.TaskId,
          allowUpload: true,
          apiCallback: taskFilesApiCallback,
          useSmallTitle: true
        }"></app-components-specialist-taskfiles>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          Note to Reviewer
        </div>
        <div class="card-body">
          <textarea class="form-control" style="min-width: 100%" [formControl]="noteToReviewerCtrl" rows="5"
            lpValidationMsg></textarea>
        </div>
      </div>
    </div>
  </div>
  <app-floating-panels *ngIf="!isLoading">
    <app-floating-panel [displayConfig]="{ title: 'External Access', icon: 'fa-key', size: 'floating-panel-md' }">
      <compose *floatingPanelContent Selector="app-components-managecustomer-readonlyexternalaccess" [Model]="{ customer: currentAction().Customer }">
      </compose>
    </app-floating-panel>
    <app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
      <app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="currentAction().TaskDetails.TaskNotes">
      </app-components-specialist-actioncomponents-comments>
    </app-floating-panel>
    <app-floating-panel *ngIf="rejection" [displayConfig]="{ title: 'Rejection Information', icon: 'fa-clipboard'}">
      <app-components-specialist-actioncomponents-rejection *floatingPanelContent [rejection]="rejection.Rejection">
      </app-components-specialist-actioncomponents-rejection>
    </app-floating-panel>
  </app-floating-panels>
</div>
