<div *ngIf="activeChild().currentAction().Customer?.IsTopWorkPriority" class="alert alert-danger"
  style="background-color: #f2ab3a; color: white; border: 1px solid #e89612;">
  <strong>
    <i class="fa fa-exclamation"></i>
    TOP WORK PRIORITY CUSTOMER
  </strong>
  <span>This customer is set to Top Work Priority. If you are unable to fulfill it, please contact your manager.</span>
</div>

<div [loadingOverlay]="isLoading()" class="card">
  <div class="card-header">
    <span>{{title}}</span>
    <span class="float-end">
      <ng-container *ngIf="canUnlockAndUnassign() && (isPersistable() === false)">
        <button (click)="cancelTask(true)" type="button" class="btn-close" title="Close"></button>
      </ng-container>
      <ng-container *ngIf="isPersistable()">
        <button (click)="saveTask()" type="button" class="btn-close" title="Close"></button>
      </ng-container>
      <ng-container *ngIf="(canUnlockAndUnassign() === false) && (isPersistable() === false)">
        <button (click)="closeTask()" type="button" class="btn-close" title="Close"></button>
      </ng-container>
    </span>
  </div>
  <div class="card-body">
    <div [hidden]="!showTimer()" class="row">
      <div class="col-sm-12 text-end">
        <div>{{taskTime()}}</div>
      </div>
    </div>
    <br />
    <div [hidden]="!actionIsLoaded()" class="row">
      <div class="col-md-6">
        <ul class="list-group">
          <li class="list-group-item">
            <strong>Task Type</strong>
            <span class="float-end" name="taskType">{{actionName()}}</span>
          </li>
          <li class="list-group-item">
            <strong>Task Date</strong>
            <span class="float-end">{{actionTaskDate() | dateText }}</span>
          </li>
          <li class="list-group-item">
            <strong>Task ID</strong>
            <span class="float-end" (click)="window.launchpad.utils.selectText(undefined, $event)">{{activeChild().currentAction().Task.TaskId}}</span>
          </li>
          <li [hidden]="!this.actionIsLoaded()" class="list-group-item">
            <strong>Estimated Time</strong>
            <span class="float-end">{{estimatedTime()}}</span>
          </li>
          <ng-container *ngIf="showTaskId">
          <li class="list-group-item">
            <strong>Task ID</strong>
            <span class="float-end" (click)="window.launchpad.utils.selectText(undefined, $event)" class="float-end">{{this.activeChild().currentAction().Task.TaskId}}</span>
          </li>
          </ng-container>
        </ul>
      </div>
      <div class="col-md-6">
        <ul class="list-group">
          <ng-container *ngIf="showCustomerName">
            <li class="list-group-item">
              <strong>Company Name</strong>
              <span class="float-end" (click)="window.launchpad.utils.selectText(undefined, $event)" >{{customerName()}}</span>
            </li>
          </ng-container>
          <li class="list-group-item">
            <strong>Customer URL</strong>
            <a class="float-end" [externalLink]="{ url: customerUrl(), text:customerUrl() }" name="customerUrl" rel="noreferrer"></a>
          </li>
          <li class="list-group-item">
            <strong>Customer ID</strong>
            <span class="float-end" (click)="window.launchpad.utils.selectText(undefined, $event)" >{{customerId()}}</span>
          </li>
          <li class="list-group-item">
            <strong>Customer Language</strong>
            <span class="float-end" (click)="window.launchpad.utils.selectText(undefined, $event)" >{{customerLanguage()}}</span>
          </li>
          <ng-container *ngIf="showCustomerCountry">
          <li class="list-group-item">
            <strong>Customer Country</strong>
            <span class="float-end" (click)="window.launchpad.utils.selectText(undefined, $event)" >{{customerCountry()}}</span>
          </li>
          </ng-container>
          <li class="list-group-item">
            <strong>Business Category</strong>
            <span class="float-end" (click)="window.launchpad.utils.selectText(undefined, $event)" >{{customerVertical()}}</span>
          </li>
          <ng-container *ngIf="showPartnerName">
          <li class="list-group-item">
            <strong>Partner Name</strong>
            <span class="float-end" (click)="window.launchpad.utils.selectText(undefined, $event)" class="float-end">{{this.activeChild().currentAction().Customer.Partner.Name}}</span>
          </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <hr [hidden]="!actionIsLoaded()" />
    <div class="row">
      <ng-container *ngIf="activeChild()">
        <div [hidden]="!actionIsLoaded()" class="col-md-12" id="content">
          <compose [Selector]="activeChild().selector" [ActivateOptions]="{
                        currentAction: activeChild().currentAction,
                        cancelTaskCallBack: cancelTask.bind(this),
                        controlViewModel: activeChild().actionViewModel,
                        input: activeChild().input,
                        output: activeChild().output
                     }"></compose>
        </div>
      </ng-container>
    </div>
    <div [hidden]="!actionIsLoaded()" class="card card-body bg-light d-block" style="margin-bottom: 0px;">
      <div class="row">
        <ng-container *ngIf="!isReadOnly()">
          <div class="col-md-6">
            <ng-container *ngIf="canUnlockAndUnassign() && (isPersistable() === false)">
              <button (click)="cancelTask(true)" id="bottom-close-button" class="btn btn-link">Cancel</button>
            </ng-container>
            <ng-container *ngIf="(canUnlockAndUnassign() === false) && (isPersistable() === false)">
              <button (click)="closeTask()" id="bottom-close-button" class="btn btn-link">Cancel</button>
            </ng-container>
            <button [hidden]="!canDelete()" [disabled]="isLoading()" (click)="deleteTask()" class="btn btn-danger" style="margin-left: 15px;">
              <i class="fa fa-times"></i>
              Delete
            </button>
            <ng-container *ngIf="canTaskTypeSkip()">
              <a (click)="openModal(skipCustomerModal, 'skip customer')" class="btn btn-primary">
                <i class="fa fa-forward"></i>
                Skip Customer
              </a>
            </ng-container>
            <ng-container *ngIf="canWorkblock">
              <a (click)="openModal(workblockModal, 'workblock')" class="btn btn-primary">
                <i class="fa fa-forward"></i>
                Workblock
              </a>
            </ng-container>
          </div>
          <div class="col-md-6 text-end">
            <!-- check to see if sub control has a button to display on this view -->
            <ng-container *ngIf="canRejectWithoutProvidingReason()">
              <button [disabled]="isLoading()" (click)="rejectTask()" class="btn btn-danger" style="margin-left: 15px;">
                <i class="fa fa-times"></i>
                Reject
              </button>
            </ng-container>
            <ng-container *ngIf="canReject()">
              <a (click)="openModal(rejectModal, 'reject')" class="btn btn-danger" style="margin-left: 15px;">
                <i class="fa fa-times"></i>
                Reject
              </a>
            </ng-container>
            <ng-container *ngIf="isPersistable()">
              <button [disabled]="isLoading()" (click)="saveTask()" class="btn btn-primary" style="margin-left: 15px;">
                <i class="fa fa-save"></i>
                Save
              </button>
            </ng-container>
            <button [disabled]="isLoading()" (click)="completeTask()" class="btn btn-success btn-lg" style="margin-left: 15px;">
              <i class="fa fa-check"></i>
              Complete
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #rejectModal let-modal>
  <app-components-specialist-rejecttask
    [rejectionCtrl]="rejectionCtrl"
    [rejectableByRejectionType]="rejectableByRejectionType"
    [taskType]="activeChild().currentAction().Task.TaskTypeEnum"
    (canceled)="modal.dismiss()"
    (saved)="rejectTask(modal)"></app-components-specialist-rejecttask>
</ng-template>
<ng-template #workblockModal let-modal>
  <app-components-specialist-workblocktask
    [action]="activeChild().currentAction()"
    [reason]="workblockReason"
    [note]="workblockNote"
    (canceled)="modal.dismiss()"
    (saved)="workblockSaved(modal)"></app-components-specialist-workblocktask>
</ng-template>
<ng-template #skipCustomerModal let-modal>
  <app-components-specialist-skipcustomer
    [customerId]="customerId()"
    [taskId]="activeChild().currentAction().Task.TaskId"
    [taskTypeSummary]="activeChild().currentAction().Task.TaskTypeSummary"
    [customerIsTopWorkPriority]="activeChild().currentAction().Customer?.IsTopWorkPriority"
    (canceled)="modal.dismiss()"
    (saved)="customerSkipSaved(modal)"></app-components-specialist-skipcustomer>
</ng-template>
