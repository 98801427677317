import { Component } from '@angular/core';
import MerchantFieldLabelMap from 'app/models/typescript/MerchantFieldLabelMap';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { CustomerService } from '../../../services/customer.service';
import { StoreService } from '../../../services/store.service';
import { PartnerCountryService } from '../../../services/partner-country.service';
import { MerchantFieldsService } from '../../../services/merchant-fields.service';
import { LeadSourceService } from '../../../services/lead-source.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-sales-addcustomer',
  templateUrl: './AddCustomer.component.html'
})
export class AddCustomerComponent implements IActivatable {
    isLoading: KnockoutObservable<boolean> = ko.observable(false);
    viewAdvancedMerchantInfo: KnockoutObservable<boolean> = ko.observable(false);
    partner: Boo.Objects.Partner;
    user: Boo.Objects.User;
    newCustomer: ICustomerCreate;
    countries: Boo.Objects.Country[] = null;
    countryLanguages: Boo.Objects.CountryLanguage[] = null;
    languages: Boo.Objects.Language[] = null;
    leadSources: Boo.Objects.LeadSource[] = null;
    validation: KnockoutObservable<any>;
    customerusers: KnockoutObservableArray<Boo.Objects.User> = ko.observableArray([]);

    merchantFieldLabelMap: KnockoutObservable<MerchantFieldLabelMap> = ko.observable();
    countryAbbreviation: KnockoutComputed<string>;

    private addedCustomer: Boo.Objects.Customer;

    constructor(
      private customerService: CustomerService,
      private storeService: StoreService,
      private partnerCountryService: PartnerCountryService,
      private merchantFieldsService: MerchantFieldsService,
      private leadSourceService: LeadSourceService,
      private sessionStorageService: SessionStorageService) { }

    activate(): JQueryPromise<void> {
        this.isLoading(true);
        this.initNewCustomer();
        return $.when<any>(
            Utils.wrapDfd(this.leadSourceService.get()),
            Utils.wrapDfd(this.partnerCountryService.get(this.partner.PartnerId)),
            Utils.wrapDfd(this.sessionStorageService.getStaticData()))
          .then((leadSources: Boo.Objects.LeadSource[], partnerCountries: Boo.Objects.PartnerCountry[], staticData: Boo.Objects.LaunchPadStaticData) => {
              this.leadSources = leadSources;
              this.languages = staticData.Languages;
              this.countryLanguages = staticData.CountryLanguages;
              this.countries = staticData.Countries.filter(x => partnerCountries.some(pc => pc.CountryId === x.CountryId)
                  && x.SupportStatusId === launchpad.config.supportStatus.supported);
              return this.merchantFieldsService.generateLabelMapping(this.partner.PartnerId);
          })
          .then((map: MerchantFieldLabelMap) => {
              this.merchantFieldLabelMap(map);
              this.initValidation();
              this.isLoading(false);
          });
    }

    canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner): boolean {
        this.partner = partner;
        this.user = user;
        return _.any(user.PartnerUsers, (item) => {
            return item.UserLevelId === 1 || // Admin
                item.UserLevelId === 5 || // Manager
                item.UserLevelId === 4; // Sales
        });
    }

    OnCountrySelected (countryId: number){
        this.newCustomer.CountryId(countryId);
        this.languages = this.countryLanguages.filter(x => x.CountryId === countryId).map(x => x.Language);
    }

    save(): void {
        this.isLoading(true);
        if (!this.validation.isValid()) {
            this.isLoading(false);
            this.validation.errors.showAllMessages();
            return;
        }

        this.createCustomer()
            .then((newCustomer: Boo.Objects.Customer) => {
                toastr.success(`Customer ${newCustomer.CustomerId} added`);
                return Utils.wrapDfd(this.customerService.get(newCustomer.CustomerId));
            })
            .then((customer: Boo.Objects.Customer) => {
                this.addedCustomer = customer;
                return PriorityViewModel.show(
                    'app-components-managecustomer-customerusers',
                    {
                        customer: ko.mapping.fromJS(this.addedCustomer),
                        user: ko.observable(this.user),
                        partner: ko.observable(this.partner)
                    },
                    false);
            })
            .then(() => Utils.wrapDfd(this.storeService.sso(this.addedCustomer.CustomerId, `/place-order`, `#/sales/addcustomer`)))
            .then(url => window.location.href = url)
            .fail(err => toastr.error(err))
            .always(() => this.isLoading(false));
    }

    showAdvancedMerchantInfo(): void {
        this.viewAdvancedMerchantInfo(!this.viewAdvancedMerchantInfo());
    }

    private createCustomer(): JQueryPromise<Boo.Objects.Customer> {
        const customer = <Boo.Objects.Customer>{
            Name: this.newCustomer.Company(),
            PartnerId: this.partner.PartnerId,
            Email: this.newCustomer.Email(),
            Phone: this.newCustomer.Phone(),
            LanguageId: this.newCustomer.LanguageId(),
            CountryId: this.newCustomer.CountryId(),
            Url: this.newCustomer.Url(),
            ReferredBy: this.newCustomer.ReferredBy(),
            LeadSourceId: this.newCustomer.LeadSourceId()
        };

        const merchantFields = <Boo.Objects.CustomerMerchantFields>{
            MerchantId: this.newCustomer.MerchantId(),
            MerchantSource: this.newCustomer.MerchantSource(),
            MerchantProposalId: this.newCustomer.MerchantProposalId(),
            MerchantCategory: this.newCustomer.MerchantCategory(),
            MerchantCategoryId: this.newCustomer.MerchantCategoryId(),
            MerchantSubCategory: this.newCustomer.MerchantSubCategory(),
            MerchantSubCategoryId: this.newCustomer.MerchantSubCategoryId(),
            MerchantConsultant: this.newCustomer.MerchantConsultant(),
            MerchantConsultantContact: this.newCustomer.MerchantConsultantContact(),
            MerchantUdac: this.newCustomer.MerchantUdac(),
            MerchantProductCode: this.newCustomer.MerchantProductCode(),
            MerchantItemId: this.newCustomer.MerchantItemId(),
            MerchantMiscId: this.newCustomer.MerchantMiscId(),
            MerchantRegion: this.newCustomer.MerchantRegion(),
            MerchantCustomerValue: this.newCustomer.MerchantCustomerValue(),
            MerchantSitePublishDate: this.newCustomer.MerchantSitePublishDate(),
            MerchantContractEndDate: this.newCustomer.MerchantContractEndDate(),
            MerchantServiceLevel: this.newCustomer.MerchantServiceLevel(),
            MerchantCampaignManager: this.newCustomer.MerchantCampaignManager(),
            MerchantCampaignManagerContact: this.newCustomer.MerchantCampaignManagerContact()
        };

        const user = <Boo.Objects.User>{
            FirstName: this.newCustomer.FirstName(),
            LastName: this.newCustomer.LastName(),
            Username: this.newCustomer.Email()
        };

        return Utils.wrapDfd(this.customerService.create(user, customer, merchantFields));
    }

    private initValidation(): void {
        this.countryAbbreviation = ko.computed(() => {
            const country = this.countries?.find((x) => x.CountryId === this.newCustomer.CountryId());
            return country?.Abbreviation ?? 'US';
        });

        this.newCustomer.Company.extend({
            required: { message: 'Required' }
        });

        this.newCustomer.FirstName.extend({
            required: { message: 'Required' }
        });

        this.newCustomer.LastName.extend({
            required: { message: 'Required' }
        });

        this.newCustomer.Email.extend({
            required: { message: 'Required' },
            emailAddress: true
        });

        this.newCustomer.Phone.extend({
            required: { message: 'Required' },
            phoneNumber: this.countryAbbreviation
        });

        this.newCustomer.LanguageId.extend({
            required: { message: 'Required' },
            validation: [{
                validator: (languageId: number): boolean => {
                    return languageId !== 0;
                }
            }]
        });

        this.newCustomer.CountryId.extend({
            required: { message: 'Required' }
        });

        this.newCustomer.Url.extend({
            required: { message: 'Required' },
            validation: {
                validator: (url: string): boolean => {
                    return launchpad.utils.isValidUrl(url);
                },
                message: 'Please enter a valid and complete Url.'
            }
        });

        this.validation = ko.validatedObservable([
            this.newCustomer.Company,
            this.newCustomer.FirstName,
            this.newCustomer.LastName,
            this.newCustomer.Email,
            this.newCustomer.Phone,
            this.newCustomer.LanguageId,
            this.newCustomer.CountryId,
            this.newCustomer.Url
        ]);
        this.validation.errors.showAllMessages(false);
    }

    private initNewCustomer(): void {
        this.newCustomer = {
            Company: ko.observable(''),
            FirstName: ko.observable(''),
            LastName: ko.observable(''),
            Email: ko.observable(''),
            Phone: ko.observable(''),
            LanguageId: ko.observable(this.partner.DefaultLanguageId),
            CountryId: ko.observable(this.partner.CountryId),
            Url: ko.observable(''),
            MerchantId: ko.observable(''),
            ReferredBy: ko.observable(''),
            LeadSourceId: ko.observable(null),
            MerchantSource: ko.observable(''),
            MerchantProposalId: ko.observable(''),
            MerchantCategory: ko.observable(''),
            MerchantCategoryId: ko.observable(''),
            MerchantSubCategory: ko.observable(''),
            MerchantSubCategoryId: ko.observable(''),
            MerchantConsultant: ko.observable(''),
            MerchantConsultantContact: ko.observable(''),
            MerchantUdac: ko.observable(''),
            MerchantProductCode: ko.observable(''),
            MerchantItemId: ko.observable(''),
            MerchantMiscId: ko.observable(''),
            MerchantRegion: ko.observable(''),
            MerchantCustomerValue: ko.observable(''),
            MerchantSitePublishDate: ko.observable(undefined),
            MerchantContractEndDate: ko.observable(''),
            MerchantServiceLevel: ko.observable(''),
            MerchantCampaignManager: ko.observable(''),
            MerchantCampaignManagerContact: ko.observable('')
        };
    }
}

interface ICustomerCreate {
    Company: KnockoutObservable<string>;
    FirstName: KnockoutObservable<string>;
    LastName: KnockoutObservable<string>;
    Email: KnockoutObservable<string>;
    Phone: KnockoutObservable<string>;
    LanguageId: KnockoutObservable<number>;
    CountryId: KnockoutObservable<number>;
    Url: KnockoutObservable<string>;
    MerchantId: KnockoutObservable<string>;
    ReferredBy: KnockoutObservable<string>;
    LeadSourceId: KnockoutObservable<number>;
    MerchantSource: KnockoutObservable<string>;
    MerchantProposalId: KnockoutObservable<string>;
    MerchantCategory: KnockoutObservable<string>;
    MerchantCategoryId: KnockoutObservable<string>;
    MerchantSubCategory: KnockoutObservable<string>;
    MerchantSubCategoryId: KnockoutObservable<string>;
    MerchantConsultant: KnockoutObservable<string>;
    MerchantUdac: KnockoutObservable<string>;
    MerchantProductCode: KnockoutObservable<string>;
    MerchantItemId: KnockoutObservable<string>;
    MerchantMiscId: KnockoutObservable<string>;
    MerchantSitePublishDate: KnockoutObservable<Date>;
    MerchantConsultantContact: KnockoutObservable<string>;
    MerchantRegion: KnockoutObservable<string>;
    MerchantCustomerValue: KnockoutObservable<string>;
    MerchantContractEndDate: KnockoutObservable<string>;
    MerchantServiceLevel: KnockoutObservable<string>;
    MerchantCampaignManager: KnockoutObservable<string>;
    MerchantCampaignManagerContact: KnockoutObservable<string>;
}

interface IMerchantLabels {
    MerchantIdLabel: KnockoutObservable<string>;
    MerchantSourceLabel: KnockoutObservable<string>;
    MerchantProposalIdLabel: KnockoutObservable<string>;
    MerchantCategoryLabel: KnockoutObservable<string>;
    MerchantCategoryIdLabel: KnockoutObservable<string>;
    MerchantSubCategoryLabel: KnockoutObservable<string>;
    MerchantSubCategoryIdLabel: KnockoutObservable<string>;
    MerchantConsultantLabel: KnockoutObservable<string>;
    MerchantUdacLabel: KnockoutObservable<string>;
    MerchantProductCodeLabel: KnockoutObservable<string>;
    MerchantItemIdLabel: KnockoutObservable<string>;
    MerchantMiscIdLabel: KnockoutObservable<string>;
    MerchantSitePublishDateLabel: KnockoutObservable<string>;
    MerchantConsultantContactLabel: KnockoutObservable<string>;
    MerchantRegionLabel: KnockoutObservable<string>;
    MerchantCustomerValueLabel: KnockoutObservable<string>;
    MerchantContractEndDateLabel: KnockoutObservable<string>;
    MerchantServiceLevelLabel: KnockoutObservable<string>;
    MerchantCampaignManagerLabel: KnockoutObservable<string>;
    MerchantCampaignManagerContactLabel: KnockoutObservable<string>;
}
