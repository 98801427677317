import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/googleanalytics`;

  constructor(private http: HttpClient) { }

  checkAuthorization(customerId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/CheckAuthorization`, {
      params: new HttpParams().set('customerId', customerId)
    });
  }

  revokeAccess(customerId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/RevokeAccess`, customerId);
  }

  getGoogleTags(customerId: number): Observable<Boo.ApisExternal.Google.Analytics.GoogleTagDetail[]> {
    return this.http.get<Boo.ApisExternal.Google.Analytics.GoogleTagDetail[]>(`${this.baseUrl}/GetGoogleTags`, {
      params: new HttpParams().set('customerId', customerId)
    });
  }
}
