import { Component, OnInit, ViewChild } from '@angular/core';
import BaseComponent from '../BaseComponent';
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import TaskHelper from 'app/specialist/TaskHelper';
import { OnsiteCopyDetailService } from '../../../services/onsite-copy-detail.service';
import { CustomerCampaignComponent as SharedCustomerCampaignComponent } from '../../shared/customer-campaign/customer-campaign/customer-campaign.component';

@Component({
  selector: 'app-components-ticket-customer-campaign',
  templateUrl: './customer-campaign.component.html'
})
export class CustomerCampaignComponent extends BaseComponent implements OnInit {
  @ViewChild(SharedCustomerCampaignComponent) sharedCustomerCampaignComponent: SharedCustomerCampaignComponent;

  customerJS: Boo.Objects.Customer;
  reviewSource: Boo.OnsiteRecommendations.Models.Enums.ReviewSources;
  autoEnableLinkBuilding: boolean = false;
  autoEnableLinkBuildingUrls: string[] = [];

  customerCampaignRestrictions: Boo.Objects.Enums.CustomerCampaignRestrictions[] = [
    Boo.Objects.Enums.CustomerCampaignRestrictions.HideArchivedTab,
    Boo.Objects.Enums.CustomerCampaignRestrictions.DisablePageUpdate,
    Boo.Objects.Enums.CustomerCampaignRestrictions.DisableCustomerCampaignType
  ];

  constructor(private onsiteCopyDetailService: OnsiteCopyDetailService) {
    super();
  }

  ngOnInit(): void {
    if (this.ticket.AssociatedTaskId())
    {
      this.onsiteCopyDetailService.getByTaskId(this.ticket.AssociatedTaskId())
        .subscribe(onsiteCopyDetails =>
          {
             if (TaskHelper.getOnsiteCopyLinkBuildAfterPublish(onsiteCopyDetails.BillingTask)) {
              this.parseTicketReasonForUrls().forEach(url => this.sharedCustomerCampaignComponent.updateWebsiteUrlStatusToActive(url));
             }
          });
    }
  }

  canActivate?(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: app.ticket.components.interfaces.ICustomerCampaignComponentActivateParams): boolean {
    this.customerJS = ko.mapping.toJS(params.customer);
    this.reviewSource = params.featureConfig.reviewSource;
    return true;
  }

  validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
    return super.validate(saveType)
        .then((validationResult) => {
            if (this.sharedCustomerCampaignComponent.modifiedCount > 0)
            {
              validationResult.isValid = false;
              validationResult.errorMessages = validationResult.errorMessages || [];
              validationResult.errorMessages.push('The \'Customer Campaign\' tab has unsaved changes');
            }

            return validationResult;
        });
  }

  private parseTicketReasonForUrls(): string[] {
    let urls: string[] = [];
    const prefixes = ['Requested URL: ', 'Published URL: '];
    const lines = (this.ticket.Reason() || '').split(new RegExp('\\r|\\n'));

    lines.forEach(line => {
      const lowerCaseLine = line.toLowerCase();
      prefixes.forEach(prefix => {
        const lowerCasePrefix = prefix.toLowerCase();
        if (lowerCaseLine.startsWith(lowerCasePrefix)) {
          urls.push(line.substring(prefix.length));
        }
      });
    });

    return urls;
  }
}
