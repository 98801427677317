import System from 'framework/System';

export default class BounceAnimation implements IAnimation {
    public in(element: any): JQueryPromise<any> {
        let $element: JQuery = $(element);
        $element.removeClass();

        $element.addClass('animated bounceInDown');

        return System.resolvePromiseAfter(150, System.noop).then(() => {
            $element.removeClass();
        });
    }

    public out(element: any): JQueryPromise<any> {
        let $element: JQuery = $(element);
        $element.removeClass();
        $element.addClass('animated bounceOutUp');
        return System.resolvePromiseAfter(150, System.noop);
    }
}
