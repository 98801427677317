<div>
	<ng-container *ngIf="parent()">
		<div class="card">
			<div (click)="toggleCustomerInfoCollapsed(!toggleCustomerInfoCollapsed())" class="card-header cursor-pointer">
				<strong>{{partnerName()}}</strong>
				— Revise Note
				<i [hidden]="!(toggleCustomerInfoCollapsed() === false)" class="far fa-caret-square-up float-end"></i>
				<i [hidden]="!toggleCustomerInfoCollapsed()" class="far fa-caret-square-down float-end"></i>
			</div>
			<div [class.show]="toggleCustomerInfoCollapsed() === false" class="collapse">
				<compose Selector="app-components-managecustomer-customerinformation" [ActivateOptions]="{parent: parent(), page: 'ReviseNote', showTicketSaveTimer:false }"></compose>
			</div>
		</div>
	</ng-container>
	<div class="card">
		<div class="card-header">
			Note Details
		</div>
		<div class="card-body">
			<div>
				<ng-container *ngIf="originalNote()">
					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<label class="control-label">Existing Note</label>
								<textarea [ngModel]="originalNote().Text()" (ngModelChange)="originalNote().Text($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" name="originalNoteBody" readonly="readonly" maxlength="4000" style="height: 120px;"></textarea>
								<span class="help-block">Maximum Length is 4000 Characters</span>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label class="control-label">Contact Type</label>
								<select [ngModel]="originalNote().ContactTypeId()" (ngModelChange)="originalNote().ContactTypeId($event)" [ngModelOptions]="{ standalone: true }" class="form-select" name="originalContactType" disabled="true">
									<option [ngValue]="undefined">Contact Type</option>
									<option *ngFor="let option of contactTypes()" [ngValue]="option.ContactTypeId">{{ option.Name }}</option>
								</select>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
			<div>
				<ng-container *ngIf="revisedNote()">
					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<label class="control-label">Revised Note</label>
								<textarea [dataValidation]="{ placement: 'top', value: revisedNote().Text }" [ngModel]="revisedNote().Text()" (ngModelChange)="revisedNote().Text($event)" [ngModelOptions]="{ standalone: true }" class="form-control" name="revisedNoteBody" maxlength="4000" style="height: 120px;"></textarea>
								<span class="help-block">Maximum Length is 4000 Characters</span>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label class="control-label">Revised Contact Type</label>
								<select [dataValidation]="{ placement: 'top', value: revisedNote().ContactTypeId }" [ngModel]="revisedNote().ContactTypeId()" (ngModelChange)="revisedNote().ContactTypeId($event)" [ngModelOptions]="{ standalone: true }" class="form-select" name="revisedContactType">
									<option [ngValue]="undefined">Contact Type</option>
									<option *ngFor="let option of contactTypes()" [ngValue]="option.ContactTypeId">{{ option.Name }}</option>
								</select>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
			<div class="card card-body bg-light d-block text-end">
				<form class="form-inline" role="form">
					<div class="col-md-12">
						<div class="form-group">
							<button (click)="close(true)" class="btn btn-danger">Cancel</button>
							<button (click)="saveNote()" class="btn btn-success">Save</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
