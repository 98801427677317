import { AbstractControl, ValidatorFn, Validators, UntypedFormControl } from '@angular/forms';
import CustomValidators from '../../shared/custom-validators';

export default class Password {
  private static minLength: number = 8;
  private static hasNumberRegex: RegExp = /\d/;
  private static hasCapitalCaseRegex: RegExp = /[A-Z]/;
  private static hasLowerCaseRegex: RegExp = /[a-z]/;
  private static hasSpecialCharRegex: RegExp = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

  static validators: ValidatorFn[] = [
    Validators.required,
    Validators.minLength(this.minLength),
    CustomValidators.patternValidator(this.hasNumberRegex, { hasNumber: true }),
    CustomValidators.patternValidator(this.hasCapitalCaseRegex, { hasCapitalCase: true }),
    CustomValidators.patternValidator(this.hasLowerCaseRegex, { hasSmallCase: true }),
    CustomValidators.patternValidator(this.hasSpecialCharRegex, { hasSpecialCharacters: true }),
  ];

  static valid(password: string): boolean {
    return new UntypedFormControl(password, Validators.compose(Password.validators)).valid;
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('confirmPassword').value;

    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
    }
  }
}
