<div>
	<ng-container *ngIf="merchantFields && merchantFieldLabelMap()">
		<div class="row">
			<div class="col-md-3">
				<ul class="list-group">
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().SourceLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantSource}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().CategoryLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantCategory}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().CategoryIdLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantCategoryId}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().SubCategoryLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantSubCategory}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().SubCategoryIdLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantSubCategoryId}}</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="col-md-3">
				<ul class="list-group">
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().ProductCodeLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantProductCode}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().ConsultantLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantConsultant}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().ConsultantContactLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantConsultantContact}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().SitePublishDateLabel.Value}}</strong>
								<span [hidden]="!merchantFields.MerchantSitePublishDate" class="float-end">{{merchantFields.MerchantSitePublishDate | dateText : 'L' : true }}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().ItemIdLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantItemId}}</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="col-md-3">
				<ul class="list-group">
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().IdLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantId}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().ProposalIdLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantProposalId}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().UdacLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantUdac}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().MiscIdLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantMiscId}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().RegionLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantRegion}}</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="col-md-3">
				<ul class="list-group">
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().CustomerValueLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantCustomerValue}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().ContractEndDateLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantContractEndDate}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().ServiceLevelLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantServiceLevel}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().CampaignManagerLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantCampaignManager}}</span>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row">
							<div class="col-md-12">
								<strong>{{merchantFieldLabelMap().CampaignManagerContactLabel.Value}}</strong>
								<span class="float-end">{{merchantFields.MerchantCampaignManagerContact}}</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 mt-3">
				<button (click)="editMerchantFields()" class="btn btn-primary float-end">
					<i class="fa fa-edit"></i>
					Edit
				</button>
			</div>
		</div>
	</ng-container>
</div>
