<div>
	<div [loadingOverlay]="isLoading()">
		<ng-container *ngIf="currentAction()">
			<div class="row">
				<ng-container *ngIf="currentAction().Task">
					<div class="col-md-6">
						<ul class="list-group">
							<li class="list-group-item">
								<ng-container *ngIf="currentAction()">
									<span>
										<ng-container *ngIf="currentAction().TaskDetails">
											<span>
												<ng-container *ngIf="currentAction().TaskDetails.WebsiteUrl">
													<strong>Website Url</strong>
													<a [externalLink]="{url : currentAction().TaskDetails.WebsiteUrl.Url, text: currentAction().TaskDetails.WebsiteUrl.Url}" class="float-end" rel="noreferrer"></a>
												</ng-container>
											</span>
										</ng-container>
									</span>
								</ng-container>
							</li>
						</ul>
					</div>
				</ng-container>
			</div>
			<div class="row form-group">
				<ng-container *ngIf="currentAction().Task">
					<div class="col-md-12">
						<ng-container *ngIf="currentAction().Task.TaskTypeSummary">
							<label class="control-label">Definition of Task</label>
							<textarea [(ngModel)]="currentAction().Task.TaskTypeSummary.Tips" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" disabled="" class="form-control" rows="15" style="height: 150px;"></textarea>
						</ng-container>
					</div>
				</ng-container>
			</div>
			<div class="row form-group">
				<div class="col-md-12">
					<label class="control-label">Description</label>
					<textarea [(ngModel)]="customDescriptionFromTask" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" disabled="" class="form-control" rows="15" style="height: 150px;"></textarea>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label">Task Definition Files</label>
						<table [hidden]="!showTaskDefinitionFiles()" class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>Name</th>
									<th>Created Date</th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngFor="let $data of taskDefinitionFiles()">
									<tr>
										<td>
											<a [externalLink]="{ url: $data.S3Url, text: $data.Name}"></a>
										</td>
										<td>{{$data.InsertedDate | dateText }}</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
						<div [hidden]="!(showTaskDefinitionFiles() === false)" class="alert alert-info" style="padding:8px;margin-bottom: 5px;">No results to display</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label">Uploaded Files</label>
						<table [hidden]="!showTaskFiles()" class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>Name</th>
									<th>Created Date</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngFor="let $data of taskFiles()">
									<tr>
										<td>
											<a [externalLink]="{ url: $data.S3Url, text: $data.Name}"></a>
										</td>
										<td>{{$data.InsertedDate | dateText }}</td>
										<td class="text-center">
											<button (click)="deleteFile($data)" class="btn btn-danger">
												<i class="fa fa-times"></i>
												Delete
											</button>
										</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
						<div [hidden]="!(showTaskFiles() === false)" class="alert alert-info" style="padding:8px;margin-bottom: 5px;">No results to display</div>
					</div>
					<div class="form-group">
						<ng-container *ngIf="taskFiles().length < 1">
							<button (click)="uploadFile()" class="btn btn-primary">
								<i class="fa fa-upload"></i>
								Upload New File
							</button>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label class="control-label">Resolve Action Work Block - Ticket History</label>
        <app-table [items]="tickets()" [pageSize]="5" exportFilename="'custom-implementation-action-tickets'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="TicketTypeId">Ticket Type</th>
              <th export sort asc field="ScheduledDate">Last Action Date</th>
              <th export sort field="AssignedUser.FullName">Assigned User</th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-ticket>
            <tr>
              <td>
                <button (click)="viewTicket(ticket.TicketId);" class="btn btn-primary">
                  <i class="fas fa-external-link-square-alt"></i>
                  <span>{{ticket.TicketType.Name}}</span>
                </button>
              </td>
              <td>{{ticket.ScheduledDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
              <td>{{(ticket.AssignedUser) ? ticket.AssignedUser.FullName : ''}}</td>
            </tr>
          </ng-template>
        </app-table>
			</div>
			<br/>
			<div class="form-group">
				<label class="control-label">External Access</label>
				<div class="text-warning float-end">Notice: All password retrieval requests are recorded for security purposes.</div>
				<ng-container *ngIf="showGoogleAnalyticsLink()">
					<div style="margin-bottom: 15px;">
						<button [hidden]="hasGoogleAnalyticsAccess()" (click)="grantGoogleAnalyticsAccess()" class="btn btn-warning">
							<i class="fa fa-google"></i>
							Connect Google Analytics
							<span [hidden]="!(gaAttempt() > 0)">{{'('+gaAttempt()+')'}}</span>
						</button>
						<button [hidden]="!hasGoogleAnalyticsAccess()" class="btn btn-success" disabled="">
							<i class="fa fa-google"></i>
							Connected to Google Analytics
						</button>
					</div>
				</ng-container>
				<app-components-managecustomer-customeraccountgroup [options]="{
						isEnabled: customerAccountGroupIsEnabled(),
						EditableNotes: true,
						customerId: customerId,
						customerAccountTypes: customerAccountTypes
				}"></app-components-managecustomer-customeraccountgroup>
			</div>
			<div [hidden]="!notifyOnCompletion()" class="form-group">
				<label class="control-label">Note to CSR</label>
				<p class="float-end help-block">4000 character maximum</p>
				<textarea [ngModel]="noteToCsr()" (ngModelChange)="noteToCsr($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="customActionNote" class="form-control" rows="15" style="height: 150px;" placeholder="Note to CSR"></textarea>
			</div>
		</ng-container>
	</div>
	<app-floating-panels>
		<app-floating-panel [displayConfig]="{ title: 'Locations', icon: 'fa-map-marker', size: 'floating-panel-lg' }">
			<app-components-shared-view-local-profiles *floatingPanelContent [customer]="customer"></app-components-shared-view-local-profiles>
		</app-floating-panel>
	</app-floating-panels>
</div>
