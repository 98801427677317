import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AdminComponent as AdminComponent1 } from './Admin/Admin.component';
import { CustomerServiceComponent as CustomerServiceComponent1 } from './CustomerService/CustomerService.component';
import { ManagerComponent as ManagerComponent1 } from './Manager/Manager.component';
import { SalesComponent as SalesComponent1 } from './Sales/Sales.component';
import { SpecialistComponent as SpecialistComponent1 } from './Specialist/Specialist.component';


@NgModule({
  declarations: [
    AdminComponent1,
    CustomerServiceComponent1,
    ManagerComponent1,
    SalesComponent1,
    SpecialistComponent1
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class NavigationModule { }
