import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskImportExportService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/taskimportexport`;

  constructor(private http: HttpClient) { }

  export(userId: number, taskTypeId: number, languageIds: number[], taskCount: number): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/Export`, { userId: userId, taskTypeId: taskTypeId, languageIds: languageIds, taskCount: taskCount });
  }

  import(url: string, taskTypeId: number): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/Import`, {
        url: url,
        taskTypeId: taskTypeId
    });
  }

  getExports(taskTypeIds: Boo.Objects.Enums.TaskTypeEnum[]): Observable<Boo.Objects.ExportedTasksDownload[]> {
    return this.http.post<Boo.Objects.ExportedTasksDownload[]>(`${this.baseUrl}/Exports`, taskTypeIds);
  }

  getTaskSubmissions(batchId: string): Observable<Boo.Objects.TaskExport.TaskSubmission[]> {
    const params = new HttpParams().set('batchId', batchId);

    return this.http.get<Boo.Objects.TaskExport.TaskSubmission[]>(`${this.baseUrl}/TaskSubmissions`, { params: params });
  }

  getTaskSubmissionFailures(): Observable<Boo.Objects.TaskExport.TaskSubmission[]> {
    return this.http.get<Boo.Objects.TaskExport.TaskSubmission[]>(`${this.baseUrl}/TaskSubmissionFailures`);
  }

  completeTaskSubmission(taskSubmissionId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/CompleteSubmission`, taskSubmissionId);
  }
}
