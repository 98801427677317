<div class="d-flex align-items-center">
  <div>
    <button class="btn btn-secondary me-4 btn-sm" type="button" data-toggle="dropdown" [disabled]="restrictions.includes(customerCampaignRestrictions_ReadOnly)">
      <i class="fas fa-solid fa-bars fa-no-margin"></i>
    </button>
    <ul class="dropdown-menu" role="menu">
      <ng-container *ngFor="let destinationWebsiteUrl of websiteUrls">
        <li>
          <button *ngIf="isActiveStatus(destinationWebsiteUrl)" class="btn-link dropdown-item"
            (click)="moveHaloKeywordToWebsiteUrl(haloKeyword, destinationWebsiteUrl)"
            [disabled]="destinationWebsiteUrl.IsReadOnly">{{ destinationWebsiteUrl.Url }}</button>
        </li>
      </ng-container>
      <div class="dropdown-divider"></div>
      <li>
        <button class="btn-link dropdown-item" (click)="archiveHaloKeyword(haloKeyword)">Archive</button>
      </li>
    </ul>
  </div>
  <div>
    {{ haloKeyword.KeywordPhrase }}
  </div>
  <div *ngIf="haloKeyword.Rank > 0" class="badge rounded-pill bg-secondary pill-text">
    Rank: {{ haloKeyword.Rank }}
  </div>
  <div *ngIf="haloKeyword.WasFirstPage" class="badge rounded-pill bg-info pill-text">
    First Page
  </div>
  <div *ngIf="validationMessages.length > 0" class="flex-grow-1 text-danger fw-normal me-3 text-end">
    {{ validationMessages[0] }}
  </div>
</div>