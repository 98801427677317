<div class="animated fadein" style="display: flex; flex-wrap: nowrap; width:100%; height:100%">
  <div *ngIf="localProfileControls && localProfileControls.length > 1" style="flex-basis: 25%; min-height:1000px;">
    <div class="card card-body bg-light">
      <div class="list-group">
        <ng-container *ngFor="let localProfile of localProfileControls">
          <a class="list-group-item active" (click)="setActiveLocalProfile(localProfile)" href="javascript:void(0)"
            [ngClass]="{
              'list-group-item-warning': localProfile.valid && !localProfile.pristine && activeLocalProfileForm !== localProfile,
              'list-group-item-danger': localProfile.invalid && localProfile.touched && activeLocalProfileForm !== localProfile,
              'list-group-item-active-warning': localProfile.valid && !localProfile.pristine && activeLocalProfileForm === localProfile,
              'list-group-item-active-danger': localProfile.invalid && localProfile.touched && activeLocalProfileForm === localProfile,
              'active': activeLocalProfileForm === localProfile && ((localProfile.valid && localProfile.pristine) || (localProfile.invalid && localProfile.untouched)) }">
            <ng-container *ngIf="!(localProfile.value.localProfileId)">
              <strong>&lt;New Profile&gt;</strong>
            </ng-container>
            <app-components-shared-local-profile-address
              [localProfileFormGroup]="localProfile"></app-components-shared-local-profile-address>
            <ng-container *ngIf="!(localProfile.value.localProfileId)">
              <button (click)="remove(localProfile)" class="btn btn-sm btn-secondary float-end">
                <i class="fas fa-trash-alt"></i> Remove
              </button>
            </ng-container>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
  <div style="flex: 1; padding: 15px; padding-top:0; padding-right:0; overflow-wrap: anywhere;">
    <div #localProfileList *ngFor="let localProfile of localProfileControls">
      <div [hidden]="activeLocalProfileForm != localProfile">
        <app-components-shared-minimum-local-profile *ngIf="isNewLocalProfile(localProfile) && !requireFullLocalProfileOnAdd"
          [localProfileForm]="localProfile"
          [customer]="customer"
          [readOnly]="isReadOnly"
          [showRegistration]="showRegistration"
          [disableBusinessCitationEligibilityControls]="disableBusinessCitationEligibilityControls"
          [(registrationPhoneNumber)]="registrationPhoneNumber"></app-components-shared-minimum-local-profile>
        <app-components-shared-local-profile *ngIf="isExistingLocalProfile(localProfile) || requireFullLocalProfileOnAdd"
          [localProfileForm]="localProfile"
          [customer]="customer"
          [readOnly]="isReadOnly"
          [showRegistration]="showRegistration"
          [disableBusinessCitationEligibilityControls]="disableBusinessCitationEligibilityControls"
          [(registrationPhoneNumber)]="registrationPhoneNumber"
          [requireFullValidation]="requireFullValidation"></app-components-shared-local-profile>
      </div>
    </div>
    <div class="row" [hidden]="activeLocalProfileForm">
      <div class="col-6">
        <div class="alert alert-info">
          <strong>No location to display</strong>
        </div>
      </div>
    </div>
  </div>
</div>