<div>
  <ul class="list-unstyled">
    <ng-container *ngFor="let todo of todos; let $index = index">
      <ng-container *ngIf="todo.Status === window.launchpad.config.todoStatus.new">
        <ng-container *ngTemplateOutlet="createNewTodoTemplate;context:{$context:todo,$index:$index}"></ng-container>
      </ng-container>
    </ng-container>
    <li>
      <span (click)="add()" class="cursor-pointer">
        <i class="fa fa-plus"></i>
        Add
      </span>
    </li>
  </ul>
  <ul class="list-group">
    <ng-container *ngFor="let todo of todos; let $index = index">
      <ng-container *ngIf="todo.Status !== window.launchpad.config.todoStatus.new">
        <ng-container *ngTemplateOutlet="createTodoTemplate;context:{$context:todo}"></ng-container>
      </ng-container>
    </ng-container>
  </ul>
  <div [attr.id]="todoModalId" class="modal fade" id="todoModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <ng-container *ngIf="selectedTodo">
          <compose Selector="app-components-todo-readonlytodo" [ActivateOptions]="{
                            todo: selectedTodo,
                            userId: userId,
                            modalId: todoModalId
                         }"></compose>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-template #createTodoTemplate let-$context="$context">
    <li class="list-group-item">
      <div class="row">
        <div [class.text-success]="$context.Status === window.launchpad.config.todoStatus.complete"
          [class.text-warning]="$context.Status === window.launchpad.config.todoStatus.incomplete" class="col-md-1">
          <i [class.far]="$context.Status === window.launchpad.config.todoStatus.new"
            [class.fa-circle]="$context.Status === window.launchpad.config.todoStatus.new"
            [class.fa-check-circle]="$context.Status === window.launchpad.config.todoStatus.complete"
            [class.fa-minus-circle]="$context.Status === window.launchpad.config.todoStatus.incomplete"
            [class.fas]="$context.Status === window.launchpad.config.todoStatus.complete || $context.Status === window.launchpad.config.todoStatus.incomplete"></i>
        </div>
        <div class="col-md-11">
          <div class="row">
            <div class="col-md-12">
              <span [class.text-success]="$context.Status === window.launchpad.config.todoStatus.complete"
                [class.text-warning]="$context.Status === window.launchpad.config.todoStatus.incomplete">{{$context.Content}}</span>
              <ng-container *ngIf="$context.TodoNotes.length > 0">
                <span style="margin-left: 10px;" class="cursor-pointer text-muted">
                  <i (click)="openTodo($context)" class="text-muted fa fa-sticky-note">
                    <span>{{$context.TodoNotes.length}}</span>
                  </i>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ng-template>
  <ng-template #createNewTodoTemplate let-$context="$context" let-$index="$index">
    <li style="margin-bottom: 10px;">
      <div class="row">
        <div class="col-md-11">
          <textarea lpValidationMsg focusOnCreate [formControl]="$context.ContentCtrl"
            (keyup.enter)="onEnter($index, $event, $context)" class="form-control" style="resize: none;" maxlength="400"></textarea>
        </div>
        <div class="col-md-1">
          <span (click)="delete($index)" tabindex="-1">
            <i class="fas fa-trash-alt cursor-pointer"></i>
          </span>
        </div>
      </div>
    </li>
  </ng-template>
</div>
