<div class="row">
  <div class="col-md-12">
    <app-table [items]="tickets" [exportFilename]="'open-tickets'">
      <ng-template #tableHeader>
        <tr>
          <th export sort field="TicketTypeName">Ticket Type</th>
          <th export sort field="StartDate">Next Action Date</th>
          <th export sort field="SlaDate">SLA Date</th>
          <th export sort field="AssignedUserName">Assigned User</th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-ticket>
        <tr>
          <td>
            <div class="row">
              <div class="col-md-10">
                <button (click)="editTicket(ticket)" class="btn btn-primary">
                  <i class="fas fa-external-link-square-alt"></i>
                  <span>{{ticket.TicketTypeName}}</span>
                </button>
              </div>
              <ng-container *ngIf="ticket.LatestNote">
                <div class="col-md-2 text-end">
                  <div>
                    <ng-container *ngIf="ticket.LatestNote">
                      <span [ngbPopover]="ticket.LatestNote.Text.substring(0, 400)" [popoverTitle]="'Latest Note (' + moment(ticket.LatestNote.InsertedDate).format('MM/DD/YYYY') + ')'" placement="right auto" triggers="mouseenter:mouseleave">
                        <i class="fa fa-sticky-note fa-2x text-info"></i>
                      </span>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </td>
          <td>{{ticket.StartDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
          <td [class.text-danger]="ticket.SlaDate < Date.now()">{{ticket.SlaDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
          <td>{{ticket.AssignedUserName}}</td>
        </tr>
      </ng-template>
    </app-table>
  </div>
</div>