import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CustomerContactPreferencesService } from '../../../services/customer-contact-preferences.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-managecustomer-customercontactpreferences',
  templateUrl: './CustomerContactPreferences.component.html'
})
export class CustomerContactPreferencesComponent implements IActivatable {
  public isLoading: boolean = false;
  public customerContactPreferences: Boo.Objects.CustomerContactPreferences;
  public noteCtrl: UntypedFormControl;
  public isReadOnly: boolean;
  public contactTypes: {id: Boo.Objects.Enums.ContactPreferences, name: string} [] = [
    { id: Boo.Objects.Enums.ContactPreferences.Call, name: 'Call' },
    { id: Boo.Objects.Enums.ContactPreferences.Email, name: 'Email' }
  ];

  constructor(private customerContactPreferencesService: CustomerContactPreferencesService) { }

  public activate(params: any): JQueryPromise<void> {
    this.isReadOnly = params.isReadOnly;
    this.noteCtrl = new UntypedFormControl({value: null, disabled: this.isReadOnly}, [Validators.maxLength(1000)]);

    return Utils.wrapDfd(this.customerContactPreferencesService.get(ko.utils.unwrapObservable(params.customerId)))
      .then((customerContactPreferences: Boo.Objects.CustomerContactPreferences) => {
        this.customerContactPreferences = customerContactPreferences;

        this.noteCtrl.setValue(customerContactPreferences.Note);
        this.noteCtrl.valueChanges.subscribe(x => this.customerContactPreferences.Note = x);
      });
  }

  public save(): void {
    this.isLoading = true;

    if (this.noteCtrl.invalid) {
      this.isLoading = false;
      toastr.error('Please fix errors');
      return;
    }

    Utils.wrapDfd(this.customerContactPreferencesService.save(this.customerContactPreferences))
      .then((customerContactPreferences: Boo.Objects.CustomerContactPreferences) => {
        this.customerContactPreferences = customerContactPreferences;
        toastr.success('Customer contact preferences saved.');
      })
      .fail((err) => toastr.error(err))
      .always(() => this.isLoading = false);
  }
}
