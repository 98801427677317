<div class="animated fadein" style="display: flex; flex-wrap: nowrap; width:100%; height:100%;">
  <div *ngIf="localProfiles && localProfiles.length > 1" style="flex: 30; min-height: 1000px;">
    <div class="card card-body bg-light">
      <div class="list-group">
        <ng-container *ngFor="let localProfile of localProfiles">
          <a class="list-group-item active" (click)="setActiveLocalProfile(localProfile)" href="javascript:void(0)"
          [ngClass]="{'active': activeLocalProfile === localProfile }">
            <app-components-shared-local-profile-address
                [localProfile]="localProfile"></app-components-shared-local-profile-address>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
  <div style="flex: 70; padding: 15px; padding-top:0; padding-right:0; overflow-wrap: anywhere;">
    <div #localProfileList *ngFor="let localProfile of localProfiles">
      <div [hidden]="activeLocalProfile !== localProfile">
        <app-components-shared-view-local-profile [localProfile]="localProfile" [customer]="customer">
        </app-components-shared-view-local-profile>
      </div>
    </div>
    <div class="row" [hidden]="activeLocalProfile">
      <div class="col-6">
        <div class="alert alert-info">
          <strong>No location to display</strong>
        </div>
      </div>
    </div>
  </div>
</div>