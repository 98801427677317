import { Directive, Input, ElementRef, AfterViewInit, OnChanges } from '@angular/core';

@Directive({
  selector: '[autoExpand]'
})
export class AutoExpandDirective implements AfterViewInit, OnChanges {
  @Input('autoExpand') public AutoExpand: boolean;

  private element: HTMLElement;
  constructor(elementRef: ElementRef) {
    this.element = elementRef.nativeElement;
  }

  public ngAfterViewInit(): void {
    this.updateControl();
  }

  public ngOnChanges(): void {
    this.updateControl();
  }

  private updateControl(): void {
    if (!this.element.scrollHeight) {
      return;
    }

    if (this.AutoExpand) {
      this.element.style.height = (this.element.scrollHeight + 15) + 'px';
    }
  }
}