import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerPricingToolLocationService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/partnerpricingtoollocation`;

  constructor(private http: HttpClient) { }

  save(location: Boo.Objects.SEOPresence.PartnerPricingToolLocation): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/Save`, location);
  }

  searchMarkets(term: string, marketIds: number[], take: number = 100): Observable<Boo.Objects.SEOPresence.PartnerPricingToolLocation[]> {
    return this.http.post<Boo.Objects.SEOPresence.PartnerPricingToolLocation[]>(`${this.baseUrl}/SearchMarkets`, {
      term: term,
      marketIds: marketIds,
      take: take
    });
  }
}