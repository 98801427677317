import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryLanguageService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/countryLanguage`;

  constructor(private http: HttpClient) { }

  save(countryLanguage: Boo.Objects.CountryLanguage): Observable<any> {
    return this.http.post(`${this.baseUrl}/save`, countryLanguage);
  }

  delete(countryLanguageId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/delete`, countryLanguageId);
  }
}
