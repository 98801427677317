<div class="form-group row">
  <div class="col-md-4 col-form-label" for="channelSelect">
    Select Front App channel for partner
  </div>
  <div class="col-md-8">
    <ng-select [items]="channels" [(ngModel)]="selectedChannel" placeholder="Select Channel" bindLabel="SendAs" [multiple]="false">
    </ng-select>
  </div>
</div>
<div class="row" style="margin: 10px;">
  <div class="col-md-12">
    <div class="row" *ngIf="selectedChannel">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th><span>Name</span></th>
            <th><span>Address</span></th>
            <th><span>Send As</span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ selectedChannel.Name }}</td>
            <td>{{ selectedChannel.Address }}</td>
            <td>{{ selectedChannel.SendAs }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
