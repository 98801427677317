<div [loadingOverlay]="isLoading()">
	<div class="row">
		<div class="col-md-12">
			<table class="table table-bordered table-hover">
				<thead>
					<tr>
						<ng-container *ngFor="let $data of getColumns()">
							<th (click)="sortByColumn($data.id)" [class.cursor-pointer]="isColumnSortable($data.id)">
								<span>{{getHeaderValue($data.id)}}</span>
								<ng-container *ngIf="isSortedByColumn($data.id)">
									<i [hidden]="!isSortedAsc()" class="fa fa-caret-up"></i>
									<i [hidden]="isSortedAsc()" class="fa fa-caret-down"></i>
								</ng-container>
							</th>
						</ng-container>
					</tr>
				</thead>
				<ng-container *ngIf="getRows().length > 0">
					<ng-container *ngIf="rowTemplate">
						<tbody>
							<ng-container *ngFor="let $data of getRows()">
								<ng-container *ngTemplateOutlet="rowTemplate;context:{ViewModel:$data,$context:this}"></ng-container>
							</ng-container>
						</tbody>
					</ng-container>
					<ng-container *ngIf="!rowTemplate">
						<tbody>
							<ng-container *ngFor="let row of getRows()">
								<tr>
									<ng-container *ngFor="let $data of getColumns()">
										<td>{{getValue(row, $data.id)}}</td>
									</ng-container>
								</tr>
							</ng-container>
						</tbody>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="!(getRows().length > 0)">
					<tbody>
						<tr>
							<td [attr.colspan]="getColumns().length" class="text-muted">No records found</td>
						</tr>
					</tbody>
				</ng-container>
				<tfoot></tfoot>
			</table>
		</div>
	</div>
	<div [hidden]="!isPagingVisible()">
		<compose Selector="app-components-pager" [ActivateOptions]="pagerOptions"></compose>
	</div>
</div>