<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-3">
        <div class="row">
          <div class="col-md-11">
            <label class="control-label">View</label>
            <select select2 [formControl]="selectedFilter" class="form-control">
              <option *ngFor="let filter of filters" [ngValue]="filter">{{ filter }}</option>
            </select>
          </div>
          <div class="col-md-1">
            <i class="fas fa-chevron-right" style="padding-top: 40px;" ></i>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <label class="control-label">Filter by Group</label>
        <select select2 [formControl]="selectedTicketGroup" class="form-control">
          <option [ngValue]=null>All Groups</option>
          <option *ngFor="let group of ticketGroups|async" [ngValue]="group.TicketTypeGroupId">{{ group.Name }}</option>
        </select>
      </div>
      <div class="col-md-2">
        <label class="control-label">Filter by Team</label>
        <select select2 [formControl]="selectedCustomerTeam" class="form-control">
          <option [ngValue]=null>All Teams</option>
          <option *ngFor="let team of customerTeams|async" [ngValue]="team.TeamId">{{ team.Name }}</option>
        </select>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12">
        <app-table [items]="filteredtickets" [exportFilename]="'assigned-tickets'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="CustomerId">Customer</th>
              <th export sort asc field="NextActionDate">Next Action Date</th>
              <th export sort field="InsertedDate">Created Date</th>
              <th export sort field="CustomerName">Company Name</th>
              <th export sort field="InsertUserFullName">Inserted By</th>
              <th export sort field="AssignedUserFullName">Assigned User</th>
              <th export sort field="PartnerName">Partner</th>
              <th export sort field="IsAppointment">Appointment</th>
              <th export sort field="IsWorkStoppage">Work Stoppage</th>
              <th export sort field="TicketTypeName">Type</th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-ticket>
            <tr>
              <td>
                <button (click)="openCustomer(ticket)" class="btn btn-primary">
                  <i class="fas fa-external-link-square-alt"></i>
                  <span>{{ticket.CustomerId}}</span>
                </button>
              </td>
              <td>{{ticket.NextActionDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
              <td>{{ticket.InsertedDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
              <td>{{ticket.CustomerName}}</td>
              <td>{{ticket.InsertUserFullName}}</td>
              <td>{{ticket.AssignedUserFullName}}</td>
              <td>{{ticket.PartnerName}}</td>
              <td>{{ticket.IsAppointment ? 'Yes' : 'No'}}</td>
              <td>{{ticket.IsWorkStoppage ? 'Yes' : 'No'}}</td>
              <td>
                <div class="row">
                  <div class="col-md-10">
                    <button (click)="editTicket(ticket)" class="btn btn-primary">
                      <i class="fas fa-external-link-square-alt"></i>
                      <span>{{ticket.TicketTypeName}}</span>
                    </button>
                  </div>
                  <ng-container *ngIf="ticket.LatestNote">
                    <div class="col-md-2 text-end">
                      <span [ngbPopover]="ticket.LatestNote.Note"
                        [popoverTitle]="'Latest Note (' + moment(ticket.LatestNote.InsertedDate).format('MM/DD/YYYY') + ')'"
                        placement="left auto" triggers="mouseenter:mouseleave">
                        <i class="fa fa-sticky-note fa-2x text-info"></i>
                      </span>
                    </div>
                  </ng-container>
                </div>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>
