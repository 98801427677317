import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GuaranteeService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/guarantee`;

  constructor(private http: HttpClient) { }

  getForCustomer(customerId: number): Observable<Boo.Objects.Guarantee[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.Guarantee[]>(`${this.baseUrl}/GetForCustomer`, { params: params });
  }

  addViolation(guaranteeId: number, reason: string, type: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/AddViolation`, {
        guaranteeId: guaranteeId,
        reason: reason,
        type: type
    });
  }

  getViolationTypes(): Observable<Boo.Objects.GuaranteeViolationType[]> {
    return this.http.get<Boo.Objects.GuaranteeViolationType[]>(`${this.baseUrl}/GetViolationTypes`);
  }
}
