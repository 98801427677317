import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import { UniqueKeywordSuggestionValidator } from '../unique-keyword-suggestion-validator';
import { RedoRequest } from '../redo-request';
import { AddKeywordSuggestionRequest } from '../add-keyword-suggestion-request';

@Component({
  selector: 'app-components-shared-keywords-ticket-url-suggestions',
  templateUrl: './url-suggestions-ticket.component.html'
})
export class UrlSuggestionsTicketComponent implements OnInit {
  @Input() urlSuggestions: Boo.Objects.LegacyUrlSuggestion[];
  @Input() keywordSuggestionValidator: UniqueKeywordSuggestionValidator;
  @Input() customer: Boo.Objects.Customer;
  @Input() isNationalChanged: EventEmitter<boolean>;
  @Input() addKeywordToUrlSuggestion: EventEmitter<AddKeywordSuggestionRequest>;
  @Input() keywordTypes: Boo.Objects.KeywordSuggestionType[];
  @Input() readonlyUrls: Boo.Objects.WebsiteUrl[] = [];
  @Output() isRedoRequested = new EventEmitter<boolean>();
  @Output() keywordCountChanged = new EventEmitter<void>();
  @Output() urlApproved = new EventEmitter<Boo.Objects.LegacyUrlSuggestion>();
  @Output() urlApprovalUndone = new EventEmitter<Boo.Objects.LegacyUrlSuggestion>();
  redoUrlSuggestionIds: number[] = [];
  newUrlSuggestionForm: UntypedFormGroup = new UntypedFormGroup({
    url: new UntypedFormControl('', [Validators.required, Validators.maxLength(512), CustomValidators.validUrl()]),
    needsCreation: new UntypedFormControl(false)
  });

  ngOnInit(): void {
    this.newUrlSuggestionForm.disable();
    this.newUrlSuggestionForm.controls['url']
      .addValidators([CustomValidators.domainMustMatch(this.customer.Url, `Must contain customer's base domain (${this.customer.Url})`), CustomValidators.uniqueUrlSuggestionsValidator(this.urlSuggestions, this.readonlyUrls)]);
  }

  addUrlSuggestion(): void {
    if (this.newUrlSuggestionForm.invalid) {
      this.newUrlSuggestionForm.markAllAsTouched();
      return;
    }

    this.urlSuggestions.push({
      CustomerId: this.customer.CustomerId,
      InsertedDate: new Date(),
      IsExisting: !this.newUrlSuggestionForm.get('needsCreation').value,
      KeywordSuggestions: [],
      UpdatedDate: new Date(),
      Url: this.newUrlSuggestionForm.get('url').value,
      UrlSuggestionId: 0,
      RejectionNote: ''
    });

    this.resetNewUrlSuggestionForm();
  }

  resetNewUrlSuggestionForm(): void {
    this.newUrlSuggestionForm.disable();
    this.newUrlSuggestionForm.reset();
    this.newUrlSuggestionForm.get('url').setValue('');
    this.newUrlSuggestionForm.get('needsCreation').setValue(false);
  }

  updateRedos(request: RedoRequest) {
    if (request.isRedoRequested) {
      this.redoRequested(request.urlSuggestionId);
    } else {
      this.redoCancelled(request.urlSuggestionId);
    }
  }

  redoRequested(urlSuggestionId: number): void {
    if (this.redoUrlSuggestionIds.includes(urlSuggestionId)) {
      return;
    }

    if (this.redoUrlSuggestionIds.length === 0) {
      this.isRedoRequested.emit(true);
    }

    this.redoUrlSuggestionIds.push(urlSuggestionId);
  }

  redoCancelled(urlSuggestionId: number): void {
    let index = this.redoUrlSuggestionIds.indexOf(urlSuggestionId);

    if (index !== -1) {
      this.redoUrlSuggestionIds.splice(index, 1);
    }

    if (this.redoUrlSuggestionIds.length === 0) {
      this.isRedoRequested.emit(false);
    }
  }

  deleteUrl(urlSuggestion: Boo.Objects.LegacyUrlSuggestion) {
    this.urlSuggestions.splice(this.urlSuggestions.indexOf(urlSuggestion), 1);
    this.keywordCountChanged.emit();
  }

  handleKeywordCountChange() {
    this.keywordCountChanged.emit();
  }
}

