import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-components-shared-keywords-halo-keywords-readonly',
  templateUrl: './halo-keywords-readonly.component.html',
  styleUrls: ['./halo-keywords-readonly.component.scss']
})
export class HaloKeywordsReadonlyComponent {
  @Input() haloKeywords: Boo.Objects.WebsiteKeywordTracking[] = [];
  @Input() haloKeywordPhrases: string[];

  constructor() { }
}
