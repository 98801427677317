import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-add-keyword',
  templateUrl: './add-keyword.component.html'
})
export class AddKeywordComponent implements OnInit {
  @Input() websiteUrl?: Boo.Objects.WebsiteUrl;
  @Input() customer: Boo.Objects.Customer;
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];
  @Input() haloKeywords: Boo.Objects.WebsiteKeywordTracking[];
  @Input() archivedKeywords: Boo.Objects.ArchivedKeyword[];
  @Input() restrictions: Boo.Objects.Enums.CustomerCampaignRestrictions[];

  @ViewChild('keywordInput') keywordInput: ElementRef;
  @ViewChild('areaInput') areaInput: ElementRef;

  keywordControl: UntypedFormControl;
  areaControl: UntypedFormControl;
  formGroup: UntypedFormGroup;
  addingKeyword: boolean = false;
  isAreaFirst: boolean = false;

  customerCampaignRestrictions_ReadOnly = Boo.Objects.Enums.CustomerCampaignRestrictions.ReadOnly;

  constructor(private customerCampaignService: CustomerCampaignService) { }

  ngOnInit(): void {
    this.setupForm();
  }

  toggleAddingKeyword(): void {
    this.addingKeyword = !this.addingKeyword;

    if (this.addingKeyword) {
      this.isAreaFirst = false;
      this.keywordControl.reset('');
      this.areaControl.reset('');
      this.focus();
    }
  }

  toggleAreaPosition(): void {
    this.isAreaFirst = !this.isAreaFirst;
    this.focus();
  }

  setupForm(): void {
    this.keywordControl = new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(128),
      CustomValidators.mustNotBeWhitespace()
    ]);
    this.areaControl = new UntypedFormControl('', [
      CustomValidators.requiredIfValidator(() => !this.customer.IsCampaignNational),
      Validators.maxLength(256),
      CustomValidators.mustNotBeWhitespace()
    ]);
    this.formGroup = new UntypedFormGroup({ keyword: this.keywordControl, area: this.areaControl }, {
      validators: [CustomValidators.keywordsCannotBeDuplicated(this.customerCampaignService, () => this.isAreaFirst, this.getUnavailableKeywords.bind(this), 'This keyword already exists and cannot be duplicated.')]
    });
    this.focus();
  }

  getUnavailableKeywords(): string[] {
    if (!this.websiteUrl || (this.websiteUrl && this.websiteUrl.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active)) {
      return this.websiteUrls.filter(x => x.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active)
        .flatMap(x => x.Keywords)
        .filter(x => x.IsActive)
        .map(x => x.KeywordPhrase.toLowerCase().trim())
        .concat(
          this.haloKeywords.filter(x => x.IsActive).map(x => x.KeywordPhrase.toLowerCase().trim())
        );
    } else {
      return this.websiteUrl.Keywords
        .filter(x => x.IsActive)
        .map(x => x.KeywordPhrase.toLowerCase().trim());
    }
  }

  addKeyword(): void {
    this.keywordControl.markAsDirty();
    this.keywordControl.updateValueAndValidity();

    if (!this.customer.IsCampaignNational) {
      this.areaControl.markAsDirty();
      this.areaControl.updateValueAndValidity();
    } else {
      this.areaControl.reset('');
      this.isAreaFirst = false;
    }

    if (!this.formGroup.valid) {
      toastr.error("Please correct errors before adding a keyword.");
      return;
    }

    let phrase = this.customerCampaignService.getKeywordPhrase(this.keywordControl.value, this.areaControl.value, this.isAreaFirst);

    if (this.websiteUrl) {
      this.addWebsiteUrlKeyword(phrase);
    } else {
      this.addHaloKeyword(phrase);
    }

    let archivedKeyword = this.archivedKeywords.find(x => x.KeywordPhrase.toLowerCase().trim() === phrase.toLowerCase());
    if (archivedKeyword) {
      this.archivedKeywords.splice(this.archivedKeywords.indexOf(archivedKeyword), 1);
    }

    this.toggleAddingKeyword();
  }

  private addHaloKeyword(phrase: string): void {
    let existingKeyword = this.haloKeywords.find(x => x.KeywordPhrase.toLowerCase().trim() === phrase.toLowerCase());
    if (existingKeyword) {
      existingKeyword.IsActive = true;
      existingKeyword.IsModified = true;
    } else {
      this.haloKeywords.push({
        Area: this.areaControl.value ? { AreaId: 0, Name: this.customerCampaignService.normalizeArea(this.areaControl.value) } as Boo.Objects.Area : null,
        AreaId: 0,
        CustomerId: this.customer.CustomerId,
        IsActive: true,
        IsAreaFirst: this.isAreaFirst,
        Keyword: { KeywordId: 0, Name: this.customerCampaignService.normalizeKeyword(this.keywordControl.value) } as Boo.Objects.Keyword,
        KeywordId: 0,
        KeywordPhrase: phrase,
        IsModified: true
      } as Boo.Objects.WebsiteKeywordTracking);
    }
  }

  private addWebsiteUrlKeyword(phrase: string): void {
    let existingKeyword = this.websiteUrl.Keywords.find(x => x.KeywordPhrase.toLowerCase().trim() === phrase.toLowerCase());
    if (existingKeyword) {
      existingKeyword.IsActive = true;
      existingKeyword.IsModified = true;
    } else {
      this.websiteUrl.Keywords.push({
        Area: this.areaControl.value ? { AreaId: 0, Name: this.customerCampaignService.normalizeArea(this.areaControl.value) } as Boo.Objects.Area : null,
        AreaId: 0,
        IsActive: true,
        IsAreaFirst: this.isAreaFirst,
        IsRecommended: false,
        KeywordId: 0,
        Keyword: { KeywordId: 0, Name: this.customerCampaignService.normalizeKeyword(this.keywordControl.value) } as Boo.Objects.Keyword,
        KeywordPhrase: phrase,
        WebsiteUrlId: this.websiteUrl.WebsiteUrlId,
        IsModified: true
      } as Boo.Objects.WebsiteUrlKeyword);
    }
  }

  private focus() {
    setTimeout(() => {
      if (this.isAreaFirst && this.areaInput) {
        this.areaInput.nativeElement.focus();
      } else if (this.keywordInput) {
        this.keywordInput.nativeElement.focus();
      }
    }, 0);
  }
}