import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  pure: true,
})
export class SortPipe<T> implements PipeTransform {

  // length parameter is used to detect items.push()
  transform(items: T[], length: number, field: string, direction: string): any {
    if (!items) {
      return [];
    }

    if (!field || !direction) {
      return [...items];
    }

    return [...items].sort((a, b) => {
      let leftVal = this.getProperty(a, field);
      let rightVal = this.getProperty(b, field);

      if(typeof leftVal === 'string') {
        leftVal = leftVal.toLowerCase();
      }

      if(typeof rightVal === 'string') {
        rightVal = rightVal.toLowerCase();
      }

      const result = leftVal < rightVal ? -1 : leftVal > rightVal ? 1 : 0;
      return direction === 'asc' ? result : -result;
    });
  }

  private getProperty(item: any, field: string): any {
    const segments = field.split('.');
    let value = item;
    for (const segment of segments) {
      // This line accounts for knockout observables and should be removed once no more observables are used in tables
      value = segment.indexOf('()') > -1 ? value[segment.replace('()', '')]() : value[segment];
      if (value === null) {
        return '';
      }
    }
    return value;
  }
}
