<div>
  <div class="card">
    <div class="card-header">
      My Internal Task Stats
      <app-components-helptext [key]="'InternalTasks_UpdateTime'"></app-components-helptext>
    </div>
    <div [loadingOverlay]="areStatsLoading" class="card-body" id="statsContainer">
      <div class="row">
        <div class="col-md-2">
          <ng-container *ngIf="stats">
            <h4 class="card card-body bg-light d-block">
              <i class="fa fa-check"></i>
              <span>{{stats.CompletedTasks}}</span>
            </h4>
            <h5>Completed Tasks</h5>
          </ng-container>
        </div>
        <div class="col-md-2">
          <ng-container *ngIf="stats">
            <h4 class="card card-body bg-light d-block">
              <i class="fa fa-lock"></i>
              <span>{{stats.LockedTasks}}</span>
            </h4>
            <h5>Locked Tasks</h5>
          </ng-container>
        </div>
        <div class="col-md-2">
          <ng-container *ngIf="stats">
            <h4 class="card card-body bg-light d-block">
              <i class="fas fa-sync"></i>
              <span>{{stats.Revisions}}</span>
            </h4>
            <h5>Revision Tasks</h5>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div [loadingOverlay]="isLoading" class="card">
    <div class="card-header">
      My Internal Tasks
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Task Type</label>
            <select [(ngModel)]="selectedTaskTypeId" [ngModelOptions]="{ standalone: true }" name="showFilter"
              class="form-select">
              <option [ngValue]="null">All</option>
              <option *ngFor="let taskType of taskTypes" [ngValue]="taskType.taskTypeId">{{ taskType.taskTypeName }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-8 text-end">
          <button (click)="getNext()" class="btn btn-primary" style="font-size: 25px;">Get Next Task</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-table [items]="filteredTasks()" [exportFilename]="'my-internal-tasks'">
            <ng-template #tableHeader>
              <tr>
                <th export sort field="InternalTaskId">Task ID</th>
                <th export sort field="InsertedDate">Created Date</th>
                <th export sort field="TaskTypeName">Task Type</th>
                <th export sort field="LinkLocationUrl">Website URL</th>
                <th export sort desc field="IsRevision">Is Revision</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template #tableBody let-task>
              <tr [class.danger]="task.IsRevision">
                <td>{{task.InternalTaskId}}</td>
                <td>{{task.InsertedDate | dateText }}</td>
                <td>{{task.TaskTypeName}}</td>
                <td>{{task.LinkLocationUrl}}</td>
                <td>{{(task.IsRevision) ? 'Yes' : 'No'}}</td>
                <td>
                  <button (click)="confirmUnlock(task)" class="btn btn-info">
                    <i class="fa fa-unlock"></i>
                    Unlock &amp; Unassign
                  </button>
                  <button (click)="openTask(task)" class="btn btn-primary">
                    <i class="fas fa-external-link-square-alt"></i>
                    Open
                  </button>
                </td>
            </ng-template>
          </app-table>
        </div>
      </div>
    </div>
  </div>
</div>