export let onsiteCopyOrderTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteCopyCreationContentOnly,
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteCopyCreationAndImplementation,
  Boo.Objects.Enums.TaskTypeEnum.OnsiteCopy
];

// todo: Onsite copy includes implementation when applicable. We only need one collection after deprecated tasks are completed.
// murder: After StandardOnsiteCopyCreationContentOnly and StandardOnsiteCopyCreationAndImplementation tasks/workflows are complete and there aren't future allocations.
export let onsiteCopyOrderWithImplementationTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteCopyCreationAndImplementation,
  Boo.Objects.Enums.TaskTypeEnum.OnsiteCopy
];

export let onsiteBloggingOrderTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteBlogContentOnly,
  Boo.Objects.Enums.TaskTypeEnum.PremiumOnsiteBlogContentOnly,
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteBlogAndImplementation,
  Boo.Objects.Enums.TaskTypeEnum.PremiumOnsiteBlogAndImplementation,
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteBlogPost,
  Boo.Objects.Enums.TaskTypeEnum.PremiumOnsiteBlogPost
];
// onsiteblogging: TODO Remove when seperate blogging implementation tasks are depreciated
export let onsiteBloggingOrderingWithImplementationTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteBlogAndImplementation,
  Boo.Objects.Enums.TaskTypeEnum.PremiumOnsiteBlogAndImplementation
];
// onsiteblogging: TODO Remove when seperate blogging implementation tasks are depreciated
export let onsiteBloggingOrderingContentOnlyTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteBlogContentOnly,
  Boo.Objects.Enums.TaskTypeEnum.PremiumOnsiteBlogContentOnly
];

export let unassignableTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [
  Boo.Objects.Enums.TaskTypeEnum.OffsiteContentMarketing,
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteCopyCreationContentOnly,
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteCopyCreationAndImplementation,
  Boo.Objects.Enums.TaskTypeEnum.OnsiteCopy,
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteBlogPost,
  Boo.Objects.Enums.TaskTypeEnum.PremiumOnsiteBlogPost,
  Boo.Objects.Enums.TaskTypeEnum.PremiumOnsiteBlogContentOnly,
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteBlogContentOnly,
  Boo.Objects.Enums.TaskTypeEnum.PremiumOnsiteBlogAndImplementation,
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteBlogAndImplementation,
  Boo.Objects.Enums.TaskTypeEnum.KeywordResearchStrategyUpdate,
  Boo.Objects.Enums.TaskTypeEnum.PremiumBacklink
];
