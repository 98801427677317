import 'quill';
import Quill from 'quill';
import Utils from '../utils';

export interface Config {
  container: string
  unit: 'word' | 'char'
}

export default class Counter {
  quill: Quill
  options: Config

  constructor(quill: Quill, options: Config) {
    this.quill = quill;
    this.options = options;

    let container: Element = null;

    // sometimes quill has not rendered before this constructor
    // if we wait to initialize the counter until after the first editor-change event, our selectors can always find it
    this.quill.once('editor-change', () => {
      this.quill.root.parentElement.parentElement.querySelector('.ql-toolbar').insertAdjacentHTML('beforeend', '<span id="wordcount"></span>')
      container = this.quill.root.parentElement.parentElement.querySelector(this.options.container);
    });

    this.quill.on('editor-change', () => {
      const length = this.calculate();
      const countText = length + ' ' + this.options.unit + 's';
      container.innerHTML = countText;
    });
  }

  calculate() {
    const text = this.quill.getText().trim()

    if (this.options.unit === 'word') {
      return Utils.wordCount(text);
    }

    return text.length;
  }
}
