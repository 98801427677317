/* tslint:disable */

interface KnockoutValidationRuleDefinitions {
    minimumWords: KnockoutValidationRuleDefinition;
    maximumWords: KnockoutValidationRuleDefinition;
    custom: KnockoutValidationRuleDefinition;
    emailAddress: KnockoutValidationRuleDefinition;
    nonEmptyArray: KnockoutValidationRuleDefinition;
    maxNumberOfLines: KnockoutValidationRuleDefinition;
    itemNotInList: KnockoutValidationRuleDefinition;
    url: KnockoutValidationRuleDefinition;
    matchUrlHostname: KnockoutValidationRuleDefinition;
    mustEqual: KnockoutValidationRuleDefinition;
}

class CustomKnockoutValidationRules {
    public static register() {
        ko.validation.rules.minimumWords = {
            validator: function (val, minimumWords) {
                return launchpad.wordCount(val) >= minimumWords;
            },
            message: 'This field requires a minimum of {0} words.'
        };

        ko.validation.rules.maximumWords = {
            validator: function (val, maximumWords) {
                return launchpad.wordCount(val) <= maximumWords;
            },
            message: 'This field requires a maximum of {0} words.'
        };

        ko.validation.rules.custom = {
            validator: function (val, validationFunction) {
                return validationFunction(val);
            },
            message: 'The field is invalid.'
        };


        ko.validation.rules.emailAddress = {
            validator: function (val, validate) {
                return ko.validation.rules.email.validator($.trim(val), validate);
            },
            message: ko.validation.rules.email.message
        };

        ko.validation.rules.nonEmptyArray = {
            validator: function (val, fields) {
                if (fields === true) {
                    return val.length > 0;
                } else {
                    return val.length <= 0;
                }
            },
            message: 'One of these fields is required'
        };

        ko.validation.rules.maxNumberOfLines = {
            validator: function (val, numberOfLines) {
                const lines = val.split('\n');
                return lines.length <= numberOfLines;
            },
            message: 'You have exceeded the max number of lines.'
        };

        ko.validation.rules.itemNotInList = {
            validator: function (val, listToSearch) {
                const self = this;
                const list = ko.utils.unwrapObservable(listToSearch);
                if (val && _.isObject(list)) {
                    const isContained = _.contains(list, val);
                    return !isContained;
                } else {
                    return true;
                }

            },
            message: 'Value is not in the list.'
        };

        ko.validation.rules.url = {
            validator: function (val, required) {
                if (!val) {
                    return required;
                }
                return val.match(/^((?:https?):\/\/)[^ "]+$/);
            },
            message: 'This field must be a valid URL.'
        };

        ko.validation.rules.matchUrlHostname = {
            validator: function (value, params) {
                const url = new URI(value);
                const match = new URI(params.urlToMatch);
                const override = _.isFunction(params.override) && params.override();
                return override || (url.normalize().hostname() === match.normalize().hostname() && url.normalize().protocol() === match.normalize().protocol());
            },
            message: ''
        };

        ko.validation.rules.mustEqual = {
            validator: function (val, params) {
                return val === params.comparison;
            },
            message: 'The field must equal {0}'
        };


        ko.validation.rules.phoneNumber = {
            validator: function (val, countryAbbreviation) {
                if (!val) {
                    return true;
                }

                try {
                    const countryCode = ko.utils.unwrapObservable(countryAbbreviation);
                    const phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance();
                    const phoneNumber = phoneNumberUtil.parse(val, countryCode);

                    return phoneNumberUtil.isValidNumber(phoneNumber);
                } catch (error) {
                    return false;
                }
            },
            message: function (countryAbbreviation: any) {
                const countryCode = ko.utils.unwrapObservable(countryAbbreviation);
                const phoneNumberUtil: any = libphonenumber.PhoneNumberUtil.getInstance();
                const exampleNumber = phoneNumberUtil.getExampleNumber(countryCode);

                return `This field must be a valid phone number, e.g., ${phoneNumberUtil.format(exampleNumber, libphonenumber.PhoneNumberFormat.NATIONAL)} or ${phoneNumberUtil.format(exampleNumber, libphonenumber.PhoneNumberFormat.INTERNATIONAL)}.`
                    + ` Extensions should follow the phone number and be preceded by an \'x\', eg. ${phoneNumberUtil.format(exampleNumber, libphonenumber.PhoneNumberFormat.NATIONAL)} x 000 or ${phoneNumberUtil.format(exampleNumber, libphonenumber.PhoneNumberFormat.INTERNATIONAL)} x 000`;
            } as any
        };

        ko.validation.registerExtenders();
    }
}
CustomKnockoutValidationRules.register();