<div class="card">
	<div class="card-header cursor-pointer">
    <span>{{title}}</span>
	</div>
	<div class="card-body">
    <div class="container-fluid">
			<ng-container *ngIf="customerAccount">
				<form class="form-group row mb-0">
					<div class="col-md-5">
						<label class="control-form-label">Username</label>
						<div>
							<input autocomplete="off" [dataValidation]="{placement: 'top', value: customerAccount.Username }" [ngModel]="customerAccount.Username()" (ngModelChange)="customerAccount.Username($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="!isEnabled" [readonly]="!isEnabled" type="text" class="form-control"/>
						</div>
					</div>
					<div class="col-md-7">
						<label class="control-form-label">Password</label>
						<div (click)="getPassword()">
							<input autocomplete="off" [dataValidation]="{placement: 'top', value: customerAccount.Password }" [ngModel]="customerAccount.Password()" (ngModelChange)="customerAccount.Password($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="!isEnabled" passwordToggle type="password" class="form-control"/>
						</div>
					</div>
				</form>
				<div class="form-group row mb-0">
					<div class="col-md-12">
						<label class="control-from-label">Notes</label>
						<textarea style="height: 4rem;" [dataValidation]="{placement: 'top', value: customerAccount.Note }" [ngModel]="customerAccount.Note()" (ngModelChange)="customerAccount.Note($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="!editableNotes" type="text" class="form-control" maxlength="2048"></textarea>	
					</div>
				</div>
			</ng-container>
		</div>
  </div>
</div>
