import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numericText"
})
export class NumericTextPipe implements PipeTransform {
  transform(value: any): any {
    value = ko.utils.unwrapObservable(value);
    if (typeof value === 'number') {
      return value.toFixed(2);
    }

    return '';
  }
}