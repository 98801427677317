import { Component, Input, OnInit } from '@angular/core';
import { WebsiteService } from '../../../services/website.service';

@Component({
	selector: 'app-components-managecustomer-readonlyboostsiteaccounts',
	templateUrl: './ReadOnlyBoostSiteAccounts.component.html'
})
export class ReadOnlyBoostSiteAccountsComponent implements OnInit {
  @Input() public customerId: number;

  websites: Boo.Objects.Websites.Website[];

  constructor(
    private websiteService: WebsiteService,
  ) { }

  ngOnInit(): void {
    this.websiteService.getManyByCustomerId(this.customerId)
      .subscribe(websites => this.websites = websites);
  }
}
