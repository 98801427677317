<div [loadingOverlay]="isLoading" class="card">
  <div class="card-header">
    <span>Add File</span>
    <button (click)="close()" type="button" class="btn-close float-end"></button>
  </div>
  <div class="card-body">
    <div class="form-horizontal" role="form">
      <div class="form-group">
        <div class="col-md-12">
          <div class="input-group mb-0">
            <input [ngModel]="file?.name" class="form-control" [ngClass]="{'has-error': invalidFile}" type="text" disabled />
            <span class="btn btn-primary btn-file">
              Select a File
              <input type="file" [accept]="displayFileExtensions" class="file-input" (change)="updateFile($event)" class="btn btn-outline-secondary" style="width: 100%;" #fileUpload />
            </span>
          </div>
          <small class="ms-2">Accepted file types: {{ displayFileExtensions }}</small>

          <ng-container *ngIf="validFileType">
            <div class="card card-body bg-light mt-4">
              <img class="img-thumbnail" *ngIf="base64" [attr.src]="base64" src="" class="img-upload-container" />
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row">
      <div class="col-md-12">
        <button (click)="upload()" type="button" class="btn btn-success float-end">
          <i class="fa fa-save"></i>
          Save
        </button>
        <button (click)="close()" type="button" class="btn btn-link float-end">Cancel</button>
      </div>
    </div>
  </div>
</div>
