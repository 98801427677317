import { Injectable } from '@angular/core';
import { OnsiteCopyPageType } from '../shared/models/onsite-copy-page-type';
import { onsiteCopyOrderWithImplementationTaskTypes } from 'app/models/collections/TaskTypeCollections';

@Injectable({
  providedIn: 'root'
})
export default class OnsiteCopyPageTypeFactory {
  private static defaultWordCount = 300;
  private static pageTypes: OnsiteCopyPageType[] = [
    { name: 'Home', value: 'Home Page', wordCount: 250 },
    { name: 'About Us', value: 'About Us Page', wordCount: 250 },
    { name: 'Contact Us', value: 'Contact Us Page', wordCount: 150 },
    { name: 'Category Overview (Pathway)', value: 'Category Overview (Pathway) Page', wordCount: 200 },
    { name: 'Product/Service Description', value: 'Product/Service Description', wordCount: 300 },
    { name: 'Location', value: 'Location Page', wordCount: 200 },
    { name: 'Info', value: 'Info Page', wordCount: 400 }
];

  constructor() { }

  getWordCount(pageType: string): number {
    const selectedPageType = OnsiteCopyPageTypeFactory.pageTypes.find(pt => pt.value === pageType);
    return selectedPageType?.wordCount || OnsiteCopyPageTypeFactory.defaultWordCount;
  }

  getPageTypes(): any[] {
    return OnsiteCopyPageTypeFactory.pageTypes;
  }
}