<div *withPermissions="let allow of ['CanManageGoogleAccess']" class="card">
  <div class="card-header">
    Customer Contact
  </div>
  <div [loadingOverlay]="isLoading()" class="card-body">
    <div class="card card-body bg-light">
      <div class="row">
        <div class="col-md-12">
          <compose Selector="app-components-managecustomer-customercontactpreferences" [ActivateOptions]="{
                       customerId: customer.CustomerId
                    }"></compose>
        </div>
      </div>
    </div>
    <hr />
    <app-table [items]="users()" [exportFilename]="'customer-contact'">
      <ng-template #tableHeader>
        <tr>
          <ng-container *ngIf="canSelectUsers() && customer.StatusId() !== 5">
            <th style="width:10px"></th>
          </ng-container>
          <th export sort field="IsCustomerUserActive()">Status</th>
          <th export sort field="IsCustomerUserPartner()">Type</th>
          <th export sort field="FullName()">Name</th>
          <th export sort field="EmailRecipientType()">Email Recipient</th>
          <th>Primary Phone #</th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-customerUser>
        <tr [class.alert-warning]="customerUser.IsDirty()">
          <ng-container *ngIf="canSelectUsers() && customer.StatusId() !== 5">
            <td>
              <a (click)="editUser(customerUser)" title="Edit User" style="cursor: pointer;">
                <i class="fas fa-pencil-alt fa-lg text-primary"></i>
              </a>
            </td>
          </ng-container>
          <td>{{customerUser.IsCustomerUserActive() ? 'Active' : 'Disabled'}}</td>
          <td>{{customerUser.IsCustomerUserPartner() ? 'Partner' : 'Customer'}}</td>
          <td>
            <span>{{customerUser.FirstName() + ' ' + customerUser.LastName()}}</span>
            <ng-container *ngIf="!(customerUser.CustomerUserId() === 0)">
              <a [attr.href]="'mailto:' + customerUser.Username()">
                <i class="fa fa-envelope fa-lg float-end"></i>
              </a>
            </ng-container>
          </td>
          <td>
            <span>{{customerUser.EmailRecipientType() | emailRecipientType}}</span>
          </td>
          <td>
            <shared-components-clicktocall *ngIf="customerUser.CanContact()" [Phone]="customerUser.PrimaryPhoneNumber()"
              [CountryCode]="customerUser.IsCustomerUserPartner() ? partnerCountryAbbreviation : customerCountryAbbreviation"></shared-components-clicktocall>
            <i *ngIf="customerUser.CanReceiveTextMessages() && customerUser.PrimaryPhoneNumber()"
              class="fas fa-mobile-alt float-end text-primary" title="Can Receive Text Messages"
              style="font-size:1.2em"></i>
            <ng-container *ngIf="!customerUser.CanContact()">DO NOT CONTACT</ng-container>
          </td>
          <td>
            <ng-container *ngIf="!(customerUser.CustomerUserId() === 0)">
              <a (click)="impersonateUser(customerUser)" title="Login" style="text-decoration: none; cursor: pointer;">
                <i class="fas fa-sign-in-alt fa-2x right" style="margin-right: 7%"></i>
              </a>
              <ng-container *ngIf="showResendLogin && customerUser.CanContact()">
                <a (click)="sendPasswordResetEmail(customerUser)" title="Send Password Reset Email"
                  style="text-decoration: none; cursor: pointer;">
                  <i class="fa fa-paper-plane fa-2x" style="margin-right: 7%"></i>
                </a>
              </ng-container>
              <a [attr.href]="customer.Partner.ReportingUrl() + '/?y=' + customerUser.AccessToken()"
                title="Copy SSO Link" onclick="return false"
                style="cursor: default; text-decoration: none; cursor: pointer;">
                <i class="fa fa-link fa-2x" style="margin-right: 7%"></i>
              </a>
            </ng-container>
            <ng-container *ngIf="customer.StatusId() !== 5">
              <a (click)="deleteUser(customerUser)" rel="noreferrer" target="_blank" title="Delete User"
                style="cursor: pointer;">
                <i class="fas fa-trash-alt fa-2x text-danger float-end"></i>
              </a>
            </ng-container>
          </td>
        </tr>
        <tr [hidden]="!(customerUser.Editing())">
          <td colspan="7">
            <compose Selector="app-components-managecustomer-customeruser" [ActivateOptions]="{
                              customer: customer,
                              customerUser: customerUser,
                              partner: partner,
                              customerCountryAbbreviation: customerCountryAbbreviation,
                              partnerCountryAbbreviation: partnerCountryAbbreviation,
                              refreshCustomer: refreshCustomer,
                              deleteUser: deleteUser.bind(this)
                           }"></compose>
          </td>
        </tr>
      </ng-template>
    </app-table>
    <div>
      <button (click)="addUser()" class="btn btn-success float-end">
        <i class="fa fa-plus"></i>
        Add New User
      </button>
    </div>
  </div>
  <ng-container *ngIf="priorityViewApi">
    <div class="row" style="padding-top:30px">
      <div class="col-lg-12">
        <div class="card card-body bg-light d-block text-end">
          <div *ngIf="isNotSaved" class="text-danger">All user information must be saved to proceed.</div>
          <div *ngIf="noContactInfo" class="text-danger">All users are marked as "Do Not Contact" - Please add a user
            for the person we should contact for this campaign.</div>
          <span style="padding-right:30px">Please ensure the details above are correct for the point of contact for
            setup and management of the account.</span>
          <button [disabled]="noContactInfo || isNotSaved" (click)="close()" type="button"
            class="btn btn-primary">Done</button>
        </div>
      </div>
    </div>
  </ng-container>
</div>