import { Component } from '@angular/core';
import System from 'framework/System';
import app from 'App';
import { PriorityViewModel } from 'app/models/PriorityViewModel';

/**
 * Launches the blogsitedetails priority view from a route that specifies the link location id (link location ID).
 * Example route: specialist/editblog/296139
 */
@Component({
    selector: 'app-components-specialist-editblog',
    templateUrl: './EditBlog.component.html'
})
export class EditBlogComponent implements IActivatable {
    public activate(params: any): JQueryPromise<void> {
        const linkLocationId = params ? parseInt(params[0], 10) : null;
        if (!linkLocationId || linkLocationId <= 0) {
            toastr.error('Invalid Link Location ID');
            return System.emptyPromise();
        }

        return PriorityViewModel.show('app-components-specialist-blogsitedetails', { LinkLocationId: linkLocationId })
            .then(null, () => {
                // priority views are "rejected" when they're closed. This will cause CompositionEngine to show an error thinking it couldn't load the route.
                return $.Deferred().resolve();
            });
    }
}
