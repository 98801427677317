import { Component } from '@angular/core';
import System from 'framework/System';
import Check from 'framework/Check';
import VerticalParams = app.components.interfaces.IVerticalSelectorActivationParams;
import OptionsSelector from 'app/components/OptionsSelector';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-verticalselector',
	templateUrl: './VerticalSelector.component.html'
})
export class VerticalSelectorComponent extends OptionsSelector<Boo.Objects.Vertical, number> {
    public filteredVerticals: KnockoutComputed<Boo.Objects.Vertical[]>;

    public getItems(): JQueryPromise<Boo.Objects.Vertical[]> {
        return Utils.wrapDfd(this.sessionStorageService.getStaticData())
            .then((staticData: any) => {
                Check.isNotEmpty(staticData, 'StaticData is empty.');
                Check.hasSequence(staticData.Verticals, 'Verticals are null or empty.');
                return System.resolvedPromise(staticData.Verticals);
            });
    }

    constructor(private sessionStorageService: SessionStorageService) {
      super();
    }

    public activate(params: VerticalParams): JQueryPromise<void> {
        return super.activate(params)
            .then(() => {
                this.filteredVerticals = ko.computed(
                    () => {
                        let verticals: Boo.Objects.Vertical[] = [];
                        verticals.push(...this.items());

                        // Add empty option if allowed
                        if (params.allowPlaceholderOption || params.placeholderOptionText) {
                            verticals.unshift(<Boo.Objects.Vertical>{ VerticalId: 0, Name: params.placeholderOptionText || 'Choose...' });
                        }

                        return verticals;
                    },
                    this);

                return System.emptyPromise();
            }).then(() => {
                // Let's sort the verticals. 
                let sorter = new launchpad.utils.pSort();
                sorter.key = 'VerticalId';
                sorter.parentKey = 'ParentVerticalId';
                this.items(sorter.sort(this.items()));
                _.each(this.items(), (vert) => {
                    vert.Name = this.getSpaces(vert.NestedLevel - 1) + vert.Name;
                });

                return System.emptyPromise();
            });
    }

    private getSpaces(numSpaces: number): string {
        let spaces = '';

        if (numSpaces === 0) {
            return spaces;
        }

        _.times(numSpaces * 2, () => {
            spaces += '&nbsp;';
        });

        return spaces;
    };
}
