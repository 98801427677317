import { signal, WritableSignal, Signal } from '@angular/core';

export class TrackedChecklistItem {
  checklistItem: Boo.Objects.Checklist.ChecklistItem;
  valueSignal: WritableSignal<boolean>;
  shouldHide: Signal<boolean>;
  groupConditionsSatisfied: Signal<boolean>;

  set Value(value: boolean) {
    this.valueSignal.set(value);
    this.checklistItem.Value = value;
  };

  get Value(): boolean {
    return this.checklistItem.Value;
  }

  constructor(checklistItem: Boo.Objects.Checklist.ChecklistItem) {
    this.checklistItem = checklistItem;
    this.valueSignal = signal(checklistItem?.Value);
  }
}