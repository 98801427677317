<div>
    <app-components-specialist-actioncomponents-customertargeturls [options]="{
        validationState: validationState,
        customer: customer,
        taskId: taskId,
        isMoneyBack: isMoneyBack
    }"></app-components-specialist-actioncomponents-customertargeturls>
    <app-components-specialist-actioncomponents-customertasktypes [options]="{
        customer: customer
    }"></app-components-specialist-actioncomponents-customertasktypes>
    <div class="card">
      <div class="card-header">
        Customer Campaign
      </div>
      <div class="card-body">
        <app-components-shared-customer-campaign 
          [customer]="customer"
          [restrictions]="customerCampaignRestrictions">
        </app-components-shared-customer-campaign>
      </div>
    </div>
    <app-components-upa-checklist [(upaChecklists)]="upaChecklists"
        [(validationApi)]="validationApi"></app-components-upa-checklist>
    <app-components-specialist-taskfiles [options]="{
        taskId: taskId,
        allowUpload: true,
        apiCallback: getFilesApi
    }"></app-components-specialist-taskfiles>
    <app-floating-panels>
        <app-floating-panel [displayConfig]="{ title: 'External Access', icon: 'fa-key', size: 'floating-panel-md' }">
            <compose *floatingPanelContent Selector="app-components-managecustomer-readonlyexternalaccess"
                [Model]="{ customer: currentAction().Customer }">
            </compose>
        </app-floating-panel>
    </app-floating-panels>
</div>