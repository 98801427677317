import { Component, OnInit } from '@angular/core';
import Todo = Boo.Objects.Todo.Todo;

@Component({
  selector: 'app-components-todo-readonlytodo',
  templateUrl: './ReadonlyTodo.component.html'
})
export class ReadonlyTodoComponent implements OnInit, IActivatable {
  public window = window;
  public launchpad = launchpad;
  public todo: Todo;
  public modalId: string;
  public userId: number;

  ngOnInit(): void {
  }

  canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: ITodoRActivationParams): boolean {
    this.todo = params.todo;
    this.userId = params.userId;
    this.modalId = params.modalId;
    return true;
  }

  public closeTodo(todo: Todo): void {
    $('#' + this.modalId).modal('hide');
  }
}

export interface ITodoRActivationParams {
  todo: Todo;
  userId: number;
  modalId: string;
}
