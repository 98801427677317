<div [loadingOverlay]="isLoading()">
	<div class="row" style="margin-top: 10px;">
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-md-4 col-form-label">First Name</label>
        <div class="col-md-8">
          <input [dataValidation]="{ placement: 'right', value: customerUser.FirstName }" [ngModel]="customerUser.FirstName()" (ngModelChange)="customerUser.FirstName($event)" [ngModelOptions]="{ standalone: true }" type="text" class="form-control"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Last Name</label>
        <div class="col-md-8">
          <input [dataValidation]="{ placement: 'right', value: customerUser.LastName }" [ngModel]="customerUser.LastName()" (ngModelChange)="customerUser.LastName($event)" [ngModelOptions]="{ standalone: true }" type="text" class="form-control"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Email/Username</label>
        <div class="col-md-8">
          <input [dataValidation]="{ placement: 'right', value: customerUser.Username }" [ngModel]="customerUser.Username()" (ngModelChange)="customerUser.Username($event)" [ngModelOptions]="{ standalone: true }" type="text" class="form-control"/>
        </div>
      </div>
      <div>
        <ng-container *ngFor="let $data of customerUser.CustomerUserPhoneNumbers()">
          <compose Selector="app-components-managecustomer-customeruserphonenumber" [ActivateOptions]="{
                            customerUser: customerUser,
                            customerUserPhoneNumber: $data,
                            countryAbbreviation: countryAbbreviation,
                            deletePhoneNumber: deletePhoneNumber.bind(this),
                            validationApi: childComponentValidationApi
                          }"></compose>
        </ng-container>
      </div>
      <div class="form-group">
        <div class="col-md-12 offset-md-4">
          <button (click)="addPhoneNumber()" class="btn btn-primary">
            <i class="fa fa-plus"></i>
            Add New Number
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Is Active</label>
        <div class="col-md-8">
          <span style="width:141px;">
            <shared-components-customchoicelegacy [Settings]="{ value: customerUser.IsCustomerUserActive }"></shared-components-customchoicelegacy>
          </span>
          <app-components-helptext [key]="'CustomerUser_IsActive'"></app-components-helptext>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Is Partner</label>
        <div class="col-md-8">
          <span style="width:141px;">
            <shared-components-customchoicelegacy [Settings]="{ value: customerUser.IsCustomerUserPartner }"></shared-components-customchoicelegacy>
          </span>
          <app-components-helptext [key]="'CustomerUser_IsPartner'"></app-components-helptext>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Email Recipient</label>
        <div class="col-md-8">
          <ng-select [ngModel]="customerUser.EmailRecipientType()" (ngModelChange)="customerUser.EmailRecipientType($event)" [items]="emailRecipientOptions" placeholder="Select email recipient type" [searchable]="false">
            <ng-template ng-label-tmp let-item="item">
              {{ item | emailRecipientType }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{ item | emailRecipientType }}
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">Can Contact</label>
        <div class="col-md-8">
          <span style="width:141px;">
            <shared-components-customchoicelegacy [Settings]="{ value: customerUser.CanContact }"></shared-components-customchoicelegacy>
          </span>
          <app-components-helptext [key]="'CustomerUser_CanContact'"></app-components-helptext>
        </div>
      </div>
      <div class="form-group row" *ngIf="partner.AllowsTextMessaging">
        <label class="col-md-4 col-form-label">Can Receive Texts</label>
        <div class="col-md-8">
          <span style="width:141px;">
            <shared-components-customchoicelegacy [Settings]="{ value: customerUser.CanReceiveTextMessages, disable: !canConfigureTextMessaging() }">
            </shared-components-customchoicelegacy>
          </span>
          <app-components-helptext [key]="'CustomerUser_CanReceiveTextMessages'"></app-components-helptext>
        </div>
      </div>
    </div>
    <div class="col-md-10 offset-md-1">
    <div class="card card-body bg-light d-block text-end">
      <button (click)="cancelEdit()" type="submit" class="btn btn-link" style="margin-left:5px;">Cancel</button>
      <button (click)="save()" type="submit" class="btn btn-success" style="margin-left:5px;">
        <i class="fa fa-save"></i>
        Save
      </button>
    </div>
    </div>
	</div>
</div>
