<div class="card">
	<div class="card-header">
		Workflow Internal Tasks
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body">
		<div class="row">
			<div class="col-md-3">
				<div class="form-group">
					<label class="control-label">Search Text</label>
					<input [ngModel]="searchText()" (ngModelChange)="searchText($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="number"/>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group">
					<label class="control-label">Search By</label>
					<select [ngModel]="selectedSearchFilter()" (ngModelChange)="selectedSearchFilter($event)" [ngModelOptions]="{ standalone: true }" name="currentAcctMgr" class="form-select">
						<option value="0">Link Location ID</option>
						<option value="1">Internal Task ID</option>
					</select>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group">
					<button [disabled]="isLoading()" (click)="search()" style="margin-top: 25px;" class="btn btn-primary">
						<i class="fa fa-search"></i>
						Search
					</button>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="form-group col-md-3">
				<ng-container *ngIf="workflow()">
					<label class="control-label">Workflow Definition</label>
					<span>
						<ng-container *ngIf="workflow().WorkflowDefinition">
							<input [(ngModel)]="workflow().WorkflowDefinition.DisplayName" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control" readonly="readonly"/>
						</ng-container>
					</span>
				</ng-container>
			</div>
			<div class="form-group col-md-4">
				<ng-container *ngIf="workflow()">
					<label class="control-label">Current Workflow State ID</label>
					<div class="form-group">
						<!-- TODO: we no longer load the state with the workflow. We will show the ID for now because this is an admin screen -->
						<input [(ngModel)]="workflow().WorkflowStateId" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control" readonly="readonly"/>
					</div>
				</ng-container>
			</div>
		</div>
		<ng-container *ngIf="internalTasks().length > 0">
			<div class="row">
				<ng-container *ngTemplateOutlet="internal_task_history_with_details;context:{$context:{ internalTaskHistory: internalTasks() }}"></ng-container>
			</div>
		</ng-container>
		<ng-template #internal_task_history_with_details let-$context="$context">
			<div class="col-md-12">
				<table class="table table-bordered">
					<thead>
						<tr>
							<th>Status</th>
							<th>Internal Task ID</th>
							<th>Task</th>
							<th>Link Location ID</th>
							<th>Workflow ID</th>
							<th>Inserted Date</th>
							<th>Status Date</th>
							<th>Assigned User</th>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngFor="let $data of $context.internalTaskHistory">
							<tr>
								<td>
									<span [hidden]="!($data.TaskStatusId === window.launchpad.config.TaskStatusEnum.pending)" class="text-primary">
										<i class="fa fa-thumbs-up"></i>
										Pending
									</span>
									<span [hidden]="!($data.TaskStatusId === window.launchpad.config.TaskStatusEnum.locked)" class="text-warning">
										<i class="far fa-clock"></i>
										Locked
									</span>
									<span [hidden]="!($data.TaskStatusId === window.launchpad.config.TaskStatusEnum.complete)" class="text-success">
										<i class="fa fa-check"></i>
										Completed
									</span>
									<span [hidden]="!($data.TaskStatusId === window.launchpad.config.TaskStatusEnum.deleted)" class="text-warning">
										<i class="fa fa-minus"></i>
										Completed (But Rejected)
									</span>
									<span [hidden]="!($data.TaskStatusId === window.launchpad.config.TaskStatusEnum.needsData)" class="text-danger">
										<i class="fa fa-times"></i>
										Rejected
									</span>
								</td>
								<td>{{$data.InternalTaskId}}</td>
								<td>{{$data.TaskName}}</td>
								<td>{{$data.LinkLocationId}}</td>
								<td>{{$data.WorkflowId}}</td>
								<td>{{$data.InsertedDate | dateText: 'M/DD/YYYY h:mm:ss a' }}</td>
								<ng-container *ngIf="_.isString($data.CompletedBy)">
									<td>{{$data.CompletedDate | dateText: 'M/DD/YYYY h:mm:ss a' }}</td>
								</ng-container>
								<ng-container *ngIf="!(_.isString($data.CompletedBy))">
									<td></td>
								</ng-container>
								<td>{{$data.CompletedBy}}</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>
		</ng-template>
	</div>
</div>
