<app-components-specialist-internal-tasks-action-information
  [internalAction]="internalAction"></app-components-specialist-internal-tasks-action-information>
<div class="form-group mt-3" [formValidation]="siteTitle">
  <label class="control-label">Site Title</label>
  <input [formControl]="siteTitle" type="text" class="form-control" />
  <span class="help-block">Site Title must be between 3 and 10 words.</span>
</div>
<div class="form-group" [formValidation]="sitePurpose">
  <label class="control-label">Site Purpose</label>
  <textarea [formControl]="sitePurpose" rows="10" class="form-control"></textarea>
  <span class="help-block">Site Purpose must be between 100 and 150 words.<div class="float-end">Word Count: {{sitePurposeWordCount}}</div></span>
</div>