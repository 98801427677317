import { Component } from '@angular/core';
import System from 'framework/System';

@Component({
	selector: 'app-components-languagequestiongroup',
	templateUrl: './LanguageQuestionGroup.component.html'
})
export class LanguageQuestionGroupComponent implements IActivatable {
    public ComplexityObservable: KnockoutObservable<string>;
    public EmotionObservable: KnockoutObservable<string>;
    public EnergyObservable: KnockoutObservable<string>;
    public StyleObservable: KnockoutObservable<string>;
    public ToneObservable: KnockoutObservable<string>;

    public activate(params: app.components.interfaces.ILanguageQuestionGroupActivateParams): JQueryPromise<void> {
        this.ComplexityObservable = params.ComplexityObservable || ko.observable(undefined);
        this.EmotionObservable = params.EmotionObservable || ko.observable(undefined);
        this.EnergyObservable = params.EnergyObservable || ko.observable(undefined);
        this.StyleObservable = params.StyleObservable || ko.observable(undefined);
        this.ToneObservable = params.ToneObservable || ko.observable(undefined);
        return System.emptyPromise();
    }
}
