import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ITranslatedTableService } from 'app/admin/config/TranslatedTableConfig';

@Injectable({
  providedIn: 'root'
})
export class VerticalTranslationService implements ITranslatedTableService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/verticaltranslations`;

  constructor(private http: HttpClient) { }

  getByVerticalId(verticalId: number): Observable<Boo.Objects.VerticalTranslation> {
    const params = new HttpParams().set('verticalId', verticalId);

    return this.http.get<Boo.Objects.VerticalTranslation>(`${this.baseUrl}/GetByVerticalId`, { params: params });
  }

  get(verticalTranslationId: number): Observable<Boo.Objects.VerticalTranslation> {
    const params = new HttpParams().set('verticalTranslationId', verticalTranslationId);

    return this.http.get<Boo.Objects.VerticalTranslation>(`${this.baseUrl}/Get`, { params: params });
  }

  save(verticalTranslation: Boo.Objects.VerticalTranslation): Observable<Boo.Objects.VerticalTranslation> {
    return this.http.post<Boo.Objects.VerticalTranslation>(`${this.baseUrl}/Save`, verticalTranslation);
  }

  getMany(languageId: number, includeMissing: boolean): Observable<Boo.Objects.VerticalTranslation[]> {
    const params = new HttpParams()
      .set('languageId', languageId)
      .set('includeMissing', includeMissing);

    return this.http.get<Boo.Objects.VerticalTranslation[]>(`${this.baseUrl}/GetMany`, { params: params });
  }

  saveMany(translations: Boo.Objects.VerticalTranslation[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveMany`, translations);
  }
}
