import { Subject } from "rxjs";

export interface CompletableWorkspace {
  isReadyToComplete(): boolean;
  showCompleteButton?: boolean;
  complete?: Subject<void>;
}

export function isCompletable(workspace: CompletableWorkspace): workspace is CompletableWorkspace {
  return workspace.isReadyToComplete !== undefined;
}