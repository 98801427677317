<div>
  <strong>Rejection Reasons</strong>
  <ul class="mt-2">
    <li *ngFor="let reason of rejection.Reasons">
      {{ reason.Type.Name }}
    </li>
  </ul>
  <div>
    <strong>Comments</strong>
    <div class="mt-1" *ngIf="rejection.Note">
      {{ rejection.Note }}
    </div>
  </div>
</div>