import { Component, Input, AfterViewInit, OnChanges, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'shared-components-chart',
  templateUrl: './chart.component.html'
})
export class ChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('containerElement', { static: false })
  private containerElement: ElementRef;

  @Input()
  public Settings:any;

  private initialized = false;
  public ngAfterViewInit(): void {
    this.initPlugin();
    this.initialized = true;
  }

  public ngOnChanges(): void {
    if (!this.initialized) {
      return;
    }
    
    this.initPlugin();
  }

  private initPlugin(): void {
    $(this.containerElement.nativeElement).highcharts(this.Settings);
  }
}