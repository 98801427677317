import PushNotificationConfig from '../../Scripts/app/PushNotificationConfig';
import System from 'framework/System';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../environments/environment';
import { SessionStorageService } from './session-storage.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConnectedUsersService {
    private longRetryCutoff = 60000;
    private longRetry = 60000;
    private shortRetry = 10000;
    private subscriptions: { [subscriber: string]: { [event: string]: ((data: any) => void)[] } } = {};

    private userCache: string[] = [];

    private connection: signalR.HubConnection;

    constructor(private sessionStorageService: SessionStorageService) { }

    public connect(subscriberId: string, customerId: number, username: string): JQueryPromise<void> {
        if (!!this.connection) {
            return System.emptyPromise();
        }

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.urlConfig.signalRBaseUrl}/${PushNotificationConfig.channels.connectedUser.name}?customerId=${encodeURIComponent(customerId)}&userName=${encodeURIComponent(username)}&PartnerUserAccessToken=${encodeURIComponent(this.sessionStorageService.getAccessTokenValue())}`, {
                // if we use websockets and skip negotiation, we don't need to worry about signalr sticky session requirements
                transport: signalR.HttpTransportType.WebSockets,
                skipNegotiation: true
            })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    // attempt to reconnect every 10 seconds until it has been a minute, then attempt to reconnect once a minute
                    // this should help us reconnect if the user has a blip in their internet connection or we roll service
                    // this may not work correctly in ecs as the client won't be able to reconnect to the same server
                    if (retryContext.elapsedMilliseconds < this.longRetryCutoff){
                        return this.shortRetry;
                    } else {
                        return this.longRetry;
                    }
                }
            })
            .configureLogging(signalR.LogLevel.Error)
            .build();
        const events = PushNotificationConfig.channels.connectedUser.events;

        this.connection.on(
            events.onUsersSet,
            (e) => {
                if (!this.subscriptions) {
                    return System.emptyPromise();
                }
                for (let subscriptionId in this.subscriptions) {
                    if (!this.subscriptions.hasOwnProperty(subscriptionId)) {
                        continue;
                    }
                    this.subscriptions[subscriptionId][events.onUsersSet].forEach((h: any) => {
                        this.userCache = e;
                        h(e);
                    });
                }
            });

        this.connection.on(
            events.onUserAdded,
            (e) => {
                if (!this.subscriptions) {
                    return System.emptyPromise();
                }

                let userIsActive = this.userCache.some(((x: string) => x === e));
                if (!userIsActive) {
                    this.userCache.push(e);
                }
                for (let subscriptionId in this.subscriptions) {
                    if (!this.subscriptions.hasOwnProperty(subscriptionId)) {
                        continue;
                    }
                    this.subscriptions[subscriptionId][events.onUserAdded].forEach((h: any) => {
                        h(this.userCache);
                    });
                }
            });

        this.connection.on(
            events.onUserRemove,
            (e) => {
                if (!this.subscriptions) {
                    return System.emptyPromise();
                }
                this.userCache = this.userCache.filter(((x: string) => x !== e));
                for (let subscriptionId in this.subscriptions) {
                    if (!this.subscriptions.hasOwnProperty(subscriptionId)) {
                        continue;
                    }
                    this.subscriptions[subscriptionId][events.onUserRemove].forEach((h: any) => {
                        h(this.userCache);
                    });
                }
            });

        this.connection.onreconnected(connectionId => {
            this.connection.invoke(PushNotificationConfig.channels.connectedUser.methods.reconnect);
        })

        this.connection.start();
    }

    public subscribe(subscriberId: string, event: string, handler: (data: any) => void): void {
        this.subscriptions[subscriberId] = this.subscriptions[subscriberId] || {};
        this.subscriptions[subscriberId][event] = this.subscriptions[subscriberId][event] || [];
        this.subscriptions[subscriberId][event].push(handler);
        handler(this.userCache);
    }

    public disconnect(subscriberId: string): void {
        delete this.subscriptions[subscriberId];

        if (!Object.keys(this.subscriptions).length) {
            this.connection.stop();
            this.connection = null;
        }
    }
}