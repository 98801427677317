import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[floatingPanelContent]'
})
export class FloatingPanelContentDirective {
  constructor(public template: TemplateRef<unknown>) { }
}


