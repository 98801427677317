<div>
  <ng-container *ngIf="files">
    <div class="col-md-12">
      <app-table [items]="files" [exportFilename]="'files'">
        <ng-template #tableHeader>
          <tr>
            <th export sort field="Name">Name</th>
            <th export sort field="Comment">Comment</th>
            <th export sort field="InsertedDate">Upload Date</th>
            <th export field="S3Url"></th>
          </tr>
        </ng-template>
        <ng-template #tableBody let-file>
          <tr>
            <td>{{file.Name}}</td>
            <td>
              <span [hidden]="!(file.Comment)" [ngbPopover]="file.Comment" popoverTitle="Comment" placement="right auto"
                triggers="mouseenter:mouseleave">
                <i class="far fa-file-alt"></i>
                (Hover for Comment)
              </span>
            </td>
            <td>{{file.InsertedDate | dateText }}</td>
            <td style="width:50px;">
              <a [externalLink]="{ url: file.S3Url, text:'View File'}" class="btn btn-primary"></a>
            </td>
          </tr>
        </ng-template>
      </app-table>
    </div>
  </ng-container>
</div>