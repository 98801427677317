<div *ngIf="ticket.AssociatedTaskId()">
  <app-components-togglepanel isOpen="true" title="Action Items" toggleBody="true">
    <div>
      <div *ngIf="!upaChecklists.length">
        No failed items
      </div>
      <div *ngIf="upaChecklists.length">
        <app-components-upa-checklist [upaChecklists]="upaChecklists" [isReadOnly]="true"
        [hidePassedAnswers]="true" [hideSecondaryAnswers]="true" [displayNotesInline]="true"></app-components-upa-checklist>
      </div>
    </div>
  </app-components-togglepanel>
</div>