import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import System from 'framework/System';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { QaGradingArea } from '../../../../qa/qa-grading-area/qa-grading-area.component';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';
import { Subject } from 'rxjs';
import { InternalActionToken } from '../../internal-action.token';
import QaCriteriaGroup = Boo.Qa.QaCriteriaGroup;
import FloatingPanel = app.components.interfaces.FloatingPanel;
import IFloatingTabPanelPublicApi = app.components.interfaces.IFloatingTabPanelPublicApi;
import FloatingTabPanelSize = app.components.enums.FloatingTabPanelSize;
import FloatingTabPanelIcon = app.components.enums.FloatingTabPanelIcon;
import TitleBadgeClass = app.components.enums.FloatingTabPanelTitleBadgeClass;

@Component({
  selector: 'app-components-specialist-internal-tasks-onsite-blogging-review-qa',
  templateUrl: './onsite-blogging-review-qa.component.html'
})
export class OnsiteBloggingReviewQaComponent implements OnInit, CompletableWorkspace, UnlockableWorkspace, ClosableWorkspace {
  showCompleteButton: boolean = false;
  showUnlockButton: boolean = true;
  showCloseButton: boolean = true;
  complete = new Subject<void>();
  unlock = new Subject<void>();
  close = new Subject<void>();
  
  @ViewChild('gradingArea')
  gradingArea: QaGradingArea;
  taskType: Boo.Objects.Enums.TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaOnsiteBloggingReview;
  qaCriteriaGroups: QaCriteriaGroup[] = [];
  window: Window = window;

  editorToolbar: any = {
    toolbar: [
      {
        name: 'document',
        items: ['Source', 'Maximize']
      },
      {
        name: 'editing',
        items: ['SelectAll']
      },
      {
        name: 'clipboard',
        items: ['Copy']
      }
    ],
    readOnly: true,
    allowedContent: true
  };

  copyScapeUrl: string = 'https://www.copyscape.com/';

  autoFailLabel: string = 'Auto Fail';
  editorsFeedbackLabel: string = 'Editor\'s Feedback';
  editingQualityLabel: string = 'Editing Quality';

  gradeComment: string = '';

  showPlagiarismTable: boolean;
  contentAuditUrl1: string;
  contentAuditUrl2: string;
  contentAuditUrl3: string;
  contentAuditUrl1Percentage: number;
  contentAuditUrl2Percentage: number;
  contentAuditUrl3Percentage: number;
  contentAuditUrl1MatchedText: string;
  contentAuditUrl2MatchedText: string;
  contentAuditUrl3MatchedText: string;
  customer: Boo.Objects.Customer;
  notes: Boo.Objects.TaskNote[];
  wasAccepted: boolean;
  associatedTaskId: number;
  originalContentTitle: string;
  originalContent: string;
  originalWordCount: number;
  reviewedContentTitle: string;
  reviewedContent: string;
  reviewedWordCount: number;
  pageTitle: string;
  metaDescription: string;
  h2: string;
  researchSources: string[];
  imageUrl: string;
  feedback: string;
  noteToQa: string;
  floatingTabPanelApi: IFloatingTabPanelPublicApi;

  private informationFloatingTabPanels: FloatingPanel[];

  constructor(@Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction) { }

  floatingTabPanelApiCallback(api: IFloatingTabPanelPublicApi): void {
    this.floatingTabPanelApi = api;
    this.informationFloatingTabPanels.forEach((floatingTabPanel) => {
      this.floatingTabPanelApi.addPanel(floatingTabPanel);
    });
  }

  ngOnInit() {
    // Create the criteria float panel
    (<any>$('#internal-task-qa-criteria-float-panel')).floatPanel({ collapsed: false });

    this.qaCriteriaGroups = this.internalAction.InternalTaskDetails.QaCriteriaGroups;
    let snapshot = this.internalAction.InternalTaskDetails.OnsiteBloggingReviewSnapshot;

    this.wasAccepted = this.internalAction.InternalTaskDetails.AssociatedTask.TaskStatusEnum === Boo.Objects.Enums.TaskStatusEnum.Complete;
    this.associatedTaskId = this.internalAction.InternalTaskDetails.AssociatedTask.TaskId;
    this.customer = this.internalAction.InternalTaskDetails.AssociatedTaskCustomer;
    this.notes = this.internalAction.InternalTaskDetails.AssociatedTaskNotes;
    this.originalContentTitle = snapshot.OriginalContentTitle;
    this.originalContent = snapshot.OriginalContent;
    this.originalWordCount = window.launchpad.wordCount(snapshot.OriginalContent);
    this.reviewedContentTitle = snapshot.ReviewedContentTitle;
    this.reviewedContent = snapshot.ReviewedContent;
    this.reviewedWordCount = window.launchpad.wordCount(snapshot.ReviewedContent);
    this.pageTitle = snapshot.PageTitle;
    this.metaDescription = snapshot.MetaDescription;
    this.h2 = snapshot.H2;
    this.researchSources = snapshot.ResearchSources?.split(/\r?\n/) ?? [];
    this.imageUrl = this.internalAction.InternalTaskDetails.OnsiteBloggingReviewSnapshot.ImageUrl;
    this.feedback = snapshot.Feedback;
    this.noteToQa = this.internalAction.InternalTaskDetails.NoteToQa;
    
    this.showPlagiarismTable = (snapshot.Url1 !== null
    || snapshot.Url2 !== null
    || snapshot.Url3 !== null);

    if (this.showPlagiarismTable) {
      this.contentAuditUrl1 = snapshot.Url1;
      this.contentAuditUrl2 = snapshot.Url2;
      this.contentAuditUrl3 = snapshot.Url3;
      this.contentAuditUrl1Percentage = snapshot.Url1Percentage;
      this.contentAuditUrl2Percentage = snapshot.Url2Percentage;
      this.contentAuditUrl3Percentage = snapshot.Url3Percentage;
      this.contentAuditUrl1MatchedText = snapshot.Url1MatchedText;
      this.contentAuditUrl2MatchedText = snapshot.Url2MatchedText;
      this.contentAuditUrl3MatchedText = snapshot.Url3MatchedText;
    }

    this.informationFloatingTabPanels = [];

    this.informationFloatingTabPanels.push(
      {
        title: 'Information',
        titleIcon: FloatingTabPanelIcon.Info,
        size: FloatingTabPanelSize.Medium,
        composition: {
          selector: 'app-components-specialist-actioncomponents-information',
          options: { customer: this.internalAction.InternalTaskDetails.AssociatedTaskCustomer }
        }
      });

    if (this.internalAction.InternalTaskDetails.AssociatedTaskNotes) {
      this.informationFloatingTabPanels.push(
        {
          title: 'Comments',
          titleIcon: FloatingTabPanelIcon.Comments,
          titleBadge: () => this.internalAction.InternalTaskDetails.AssociatedTaskNotes.length,
          titleBadgeClass: TitleBadgeClass.Warning,
          composition: {
            selector: 'app-components-specialist-actioncomponents-comments',
            model: { comments: this.internalAction.InternalTaskDetails.AssociatedTaskNotes }
          }
        });
    }

    this.informationFloatingTabPanels.push(
      {
        title: 'Files',
        titleIcon: FloatingTabPanelIcon.File,
        titleBadge: () => this.internalAction.InternalTaskDetails.Files.length,
        titleBadgeClass: TitleBadgeClass.Warning,
        size: FloatingTabPanelSize.Medium,
        composition: {
          selector: 'app-components-specialist-actioncomponents-files',
          model: { files: this.internalAction.InternalTaskDetails.Files }
        }
      });

    return System.emptyPromise();
  }
  
  isReadyToComplete() {
    if (!this.gradingArea.validate()) {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return false;
    }

    this.internalAction.InternalTaskDetails.QaCriteriaDetails = this.gradingArea.getDetails();
    this.internalAction.InternalTaskDetails.QaGrade.Comment = this.gradingArea.gradeComment;
    return true;
  }

  isUrl(url: string): Boolean {
    return window.launchpad.utils.isValidUrl(url);
  }
}
