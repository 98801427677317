import { Component, Input } from '@angular/core';
import { WebsiteService } from '../../../services/website.service';

@Component({
  selector: 'app-components-managecustomer-readonlyboostsiteaccount',
  templateUrl: './ReadOnlyBoostSiteAccount.component.html'
})
export class ReadOnlyBoostSiteAccountComponent {
  @Input() public website: Boo.Objects.Websites.Website;

  window = window;
  url: string;

  constructor(
    private websiteService: WebsiteService
  ) { }

  removeUrl(): void {
    this.url = '';
  }

  getUrl(): void {
    this.websiteService.getSsoUrlForAdmin(this.website.WebsiteId).subscribe(url => this.url = url);
  }

  get boostSiteAccountContainsData(): boolean {
    return this.website && !!(this.website.WebsiteAccount.AccountId || this.website.SiteId);
  }
}
