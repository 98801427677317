import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerAccountDefaultService implements app.services.interfaces.ICustomerAccountService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/customeraccountdefaults`;

  constructor(private http: HttpClient) { }

  getOrCreate(partnerId: number, customerAccountTypes: number[]): Observable<Boo.Objects.CustomerAccountDefault[]> {
    return this.http.post<Boo.Objects.CustomerAccountDefault[]>(`${this.baseUrl}/SelectByType`, {
        partnerId: partnerId,
        customerAccountTypes: customerAccountTypes
    });
  }

  selectPasswordByAccountType(partnerId: number, customerAccountTypeId: number): Observable<string> {
    const params = new HttpParams()
      .set('partnerId', partnerId)
      .set('customerAccountTypeId', customerAccountTypeId);

    return this.http.get<string>(`${this.baseUrl}/SelectPasswordByAccountType`, { params: params });
  }

  save(accounts: Boo.Objects.CustomerAccountDefault[]): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/Save`, accounts);
  }
}
