<div *ngIf="isInitialized" class="card">
  <div class="card-header">
    <span>{{title}}</span>
    <button (click)="close()" class="btn-close" title="Close"></button>
  </div>
  <div [loadingOverlay]="isLoading" class="card-body">
    <div *ngIf="isBoostabilityPartner" class="row">
      <div class="col-md-12 alert alert-warning">
        <i class="fas fa-circle-exclamation"></i> Boostability default allocations are copied for all new partners.
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item" *ngFor="let $hour of hours;">
            <a href="#allocationTab{{$hour}}" data-toggle="tab" class="nav-link" [ngClass]="{'active': activeTab === $hour, 'invalid-allocation': !isAllocationValid($hour)}" (click)="setActiveTab($hour)">{{ $hour }} {{ $hour > 1 ? 'hours' : 'hour'}}</a>
          </li>
          <li *ngIf="!isReadOnly" class="nav-item">
            <a href="#newAllocationTab" data-toggle="tab" class="nav-link" [ngClass]="{'active': activeTab === newAllocationTabIndex}" (click)="setActiveTab(newAllocationTabIndex)"><i class="fas fa-plus no-right-margin"></i></a>
          </li>
          <li *ngIf="!isReadOnly" class="nav-item ms-auto">
            <a href="#copyAllocationsTab" data-toggle="tab" class="nav-link" [ngClass]="{'active': activeTab === copyAllocationsTabIndex}" (click)="setActiveTab(copyAllocationsTabIndex)"><i class="fas fa-clone"></i>Copy Allocations</a>
          </li>
          <li class="nav-item" [ngClass]="{'ms-auto': isReadOnly}">
            <a href="#testAllocationTab" data-toggle="tab" class="nav-link" [ngClass]="{'active': activeTab === testAllocationTabIndex}" (click)="setActiveTab(testAllocationTabIndex); testAllocationInput.focus();"><i class="fas fa-vial"></i> Test Allocations</a>
          </li>
        </ul>
        <div class="tab-content" *ngFor="let $hour of hours;">
          <div class="tab-pane" [ngClass]="{'active': activeTab === $hour }" id="allocationTab{{$hour}}">
            <app-components-manager-partner-seo-work-defaults-by-hours
              [isReadOnly]="isReadOnly"
              [hours]="$hour"
              [partnerId]="partner.PartnerId"
              [partnerDefaults]="partnerDefaults"
              [partnerSeoMinuteReservationDefaults]="partnerSeoMinuteReservationDefaults"
              [taskTypes]="taskTypes"
              (removeHourAllocation)="removeHourAllocation($event)">
            </app-components-manager-partner-seo-work-defaults-by-hours>
          </div>
        </div>
        <div class="tab-content">
          <div class="tab-pane" [ngClass]="{'active': activeTab === newAllocationTabIndex }" id="newAllocationTab">
            <span class="col-md-12">Add an Allocation</span>
            <div class="row no-gutters mb-4">
              <div class="col-md-2">
                <input #newAllocationInput [(ngModel)]="newAllocationHours" class="form-control" type="number" min="1" placeholder="How many hours?" (keyup.enter)="addAllocation()"/>
              </div>
              <div *ngIf="!isReadOnly" class="col-md-3">
                <button (click)="addAllocation()" class="btn btn-primary" [disabled]="!newAllocationHours"><i class="fa fa-plus"></i>Add</button>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-content">
          <div class="tab-pane" [ngClass]="{'active': activeTab === copyAllocationsTabIndex }" id="copyAllocationsTab">
            <div class="row mb-2 mt-5 justify-content-center">
              <div class="col-md-5">
                <div class="row">
                  <div class="col-md-8">
                    <ng-select [items]="configuredPartners" [multiple]="false" [closeOnSelect]="true" [searchable]="true" [clearable]="true" bindLabel="Name" placeholder="Copy Allocations from..." [(ngModel)]="selectedPartner" ></ng-select>
                  </div>
                  <div class="col-md-4">
                    <button (click)="copyDefaults()" class="btn btn-primary" [disabled]="!selectedPartner"><i class="fa fa-clone"></i>Copy</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-content">
          <div class="tab-pane" [ngClass]="{'active': activeTab === -1 }" id="testAllocationTab">
            <div class="row mb-2 mt-2">
              <div class="col-md-5">
                <span class="col-md-12">Test an Allocation</span>
                <div class="row">
                  <div class="col-md-4">
                    <input #testAllocationInput [(ngModel)]="testHours" class="form-control" type="number" min="1" placeholder="How many hours?" (keyup.enter)="testAllocation()"/>
                  </div>
                  <div class="col-md-4">
                    <button (click)="testAllocation()" class="btn btn-primary" [disabled]="!testHours || isTesting"><i class="fa fa-vial"></i>Test Allocation</button>
                  </div>
                </div>
                <div *ngIf="unusedAllocations && unusedAllocations.length" class="row mb-2 mt-5">
                  <div class="col-md-12">
                    <span class="col-md-12">Unused Allocations <button class="btn btn-secondary btn-super-small ms-3" (click)="removeAllUnusedAllocations()">Remove All</button></span>
                    <ul class="list-group" *ngFor="let $unused of unusedAllocations">
                      <li class="list-group-item" >
                        <i class="fas fa-ban text-secondary" title="Unused Allocation"></i>
                        <span class="text-secondary">{{ $unused }} {{ $unused > 1 ? 'hours' : 'hour'}} <button class="btn btn-secondary btn-super-small" (click)="removeUnusedAllocation($unused)">Remove</button></span>
                      </li>
                    </ul>
                  </div>
                </div>  
                <div class="row mb-2 mt-5">
                  <div class="col-md-12">
                    <span class="col-md-12">Store Products</span>
                    <div class="row">
                      <div class="col-md-6">
                        <ul class="list-group" *ngFor="let $group of productGroups | slice: 0 : math.ceil(productGroups.length / 2)">
                          <li class="list-group-item">
                            <ng-container *ngTemplateOutlet="productGroupTemplate; context:{ group: $group, isAllocationValid: isAllocationValid($group.Hours), isAllocated: isAllocated($group.Hours)}">
                            </ng-container> 
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-6">
                        <ul class="list-group" *ngFor="let $group of productGroups | slice: math.ceil(productGroups.length / 2) : productGroups.length">
                          <li class="list-group-item">
                            <ng-container *ngTemplateOutlet="productGroupTemplate; context:{ group: $group, isAllocationValid: isAllocationValid($group.Hours), isAllocated: isAllocated($group.Hours)}">
                            </ng-container> 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <ng-template let-group="group" let-isAllocationValid="isAllocationValid" let-isAllocated="isAllocated" #productGroupTemplate>
                      <span (click)="isAllocated && setActiveTab(group.Hours)" [ngClass]="{'cursor-pointer': isAllocated }">
                        <i *ngIf="!isAllocated" class="fas fa-circle-exclamation text-warning" title="No Allocation"></i>
                        <i *ngIf="isAllocated && isAllocationValid" class="fas fa-check-square text-success" title="Valid Allocation"></i>
                        <i *ngIf="isAllocated && !isAllocationValid" class="fas fa-stop-circle text-danger" title="Invalid Allocation"></i>
                        <span 
                          [ngClass]="{'text-warning': !isAllocated, 'text-success': isAllocated && isAllocationValid, 'text-danger': isAllocated && !isAllocationValid}"
                          [ngbPopover]="productListTemplate"
                          popoverTitle="{{ group.Hours }} {{ group.Hours > 1 ? 'Hours' : 'Hour'}} - {{ group.Products.length }} {{ group.Products.length > 1 ? 'Products' : 'Product'}}"
                          placement="right auto"
                          triggers="mouseenter:mouseleave">
                          <span *ngIf="!isAllocated">{{ group.Hours }} {{ group.Hours > 1 ? 'hours' : 'hour'}} <button class="btn btn-warning btn-super-small" (click)="addAllocationFromProductGroup(group)">Add</button></span>
                          <span *ngIf="isAllocated"><u>{{ group.Hours }} {{ group.Hours > 1 ? 'hours' : 'hour'}}</u></span>
                        </span>
                      </span>
                      <ng-template #productListTemplate>
                        <ul>
                          <li *ngFor="let $product of group.Products;">
                            {{ $product.StoreProductName }}
                          </li>
                        </ul>
                      </ng-template>
                    </ng-template>
                    <hr />
                    <div class="row">
                      <div class="col-md-4">
                          <ul class="list-group">
                            <li class="list-group-item"><i class="fas fa-check-square text-success" title="Valid Allocation"></i>Allocation is valid</li>
                            <li class="list-group-item"><i class="fas fa-circle-exclamation text-warning" title="No Allocation"></i>No allocation is defined</li>
                          </ul>
                      </div>
                      <div class="col-md-4">
                        <ul class="list-group">
                          <li class="list-group-item"><i class="fas fa-stop-circle text-danger" title="Invalid Allocation"></i>Allocation is invalid</li>
                          <li class="list-group-item"><i class="fas fa-ban text-secondary" title="Unused Allocation"></i>Allocation is not used</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div class="col-md-7">
                <div class="row">
                  <div class="col-md-12">
                    <app-components-manager-allocation-test-results [seoWorkDefaults]="seoWorkDefaultsToTest" [reservedMinutes]="reservedMinutesToTest" [testedHours]="testedHours" >
                    </app-components-manager-allocation-test-results>
                  </div>
                </div>
                <div *ngIf="seoWorkDefaultsToTest.length && !isReadOnly" class="row mb-2 mt-2 text-end">
                  <div class="col-md-12">
                    <button (click)="addAllocationFromTestResults()" class="btn btn-primary" [disabled]="!canAddNewAllocation"><i class="fa fa-plus"></i>Add {{ testedHours }} Hour Allocation</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer text-end">
    <button (click)="close()" class="btn btn-link">Close</button>
    <button *ngIf="!isReadOnly" class="btn btn-success" (click)="save()">Save</button>
  </div>
</div>
