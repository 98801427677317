<div class="card" [loadingOverlay]="isLoading">
  <div class="card-header">
    Organizational Teams
    <button class="btn btn-primary float-end" (click)="addTeam()"><i class="fa fa-plus"></i>Add Team</button>
  </div>
  <div class="card-body" >
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Filter by Name</label>
          <input type="text" class="form-control" [(ngModel)]="filterString" (keyup)="filter()" placeholder="" />
        </div>
      </div>
    </div>
    <hr />
    <app-table [items]="filteredOrganizationalTeams">
      <ng-template #tableHeader>
        <tr>
          <th sort field="Organization">Organization</th>
          <th sort field="Name">Team Name</th>
          <th sort field="ParentTeam.Name">Parent Team</th>
          <th sort field="IsActive">IsActive</th>
          <th>Edit</th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-team>
        <tr>
          <td>{{ team.Organization }}</td>
          <td>{{ team.Name }}</td>
          <td>{{ team.ParentTeam?.Name }}</td>
          <td>{{ team.IsActive ? 'Yes' : 'No' }}</td>
          <td>
            <button class="btn btn-primary" (click)="editTeam(team);"><i class="fas fa-external-link-square-alt"></i>Edit</button>
          </td>
        </tr>
      </ng-template>
    </app-table>
  </div>
</div>
