<div [loadingOverlay]="isLoading">
  <ng-container *ngIf="canManageSubscriptions">
    <div class="row">
      <div class="col-10">
        <ng-container *ngIf="requiredAction">
          <div class="alert alert-danger">
            <strong>Required Action:</strong>
            <span>{{ requiredAction }}</span>
          </div>
        </ng-container>
      </div>
      <div class="col-2">
        <span class="float-end">
          <ng-container *ngIf="subscriptionModifications.length > 0">
            <button (click)="undo()" class="btn btn-warning">
              <i class="fa fa-undo"></i>
            </button>
            <ng-container *ngIf="showSaveButton">
              <button (click)="saveSubcriptions()" class="btn btn-success">Save</button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="subscriptionModifications.length === 0">
            <button class="btn btn-outline-secondary" disabled style="pointer-events: auto;" title="Nothing to undo">
              <i class="fa fa-undo"></i>
            </button>
            <ng-container *ngIf="showSaveButton">
              <button class="btn btn-outline-secondary" disabled style="pointer-events: auto;" title="Nothing to save">Save</button>
            </ng-container>
          </ng-container>
        </span>
      </div>
    </div>
  </ng-container>
  <div class="row" [ngClass]="{'mt-5': !canManageSubscriptions }">
    <div class="offset-1 col-11">
      <div class="list-group">
        <ng-container *ngFor="let localProfile of localProfiles">
          <div [class.text-muted]="!localProfile.CanSubscribe" class="row" style="margin-bottom: 20px;">
            <div class="col-3">
              <app-components-shared-local-profile-address
                [localProfile]="localProfile"></app-components-shared-local-profile-address>
            </div>
            <ng-container *ngIf="localProfile.BusinessCitationSubscription !== null">
              <div class="col-5">
                <div class="alert alert-success">
                  <div class="d-flex align-items-center me-4">
                    <ng-container
                      *ngIf="localProfile.BusinessCitationSubscription.BusinessCitationProviderId === uberallProviderId">
                      <img src="../../../../Images/uberall.png" class="me-3" style="max-height:45px" />
                    </ng-container>
                    <div class="flex-grow-1">
                      <span class="my-1 badge bg-primary">{{ localProfile.BusinessCitationSubscription.Status }}</span>
                      <span class="my-1 badge bg-secondary">{{ localProfile.BusinessCitationSubscription.Origin
                        }}</span>
                      <ng-container *ngIf="localProfile.BusinessCitationSubscription.UpdatedDate">
                        <span class="my-1 badge bg-secondary">Updated {{
                          localProfile.BusinessCitationSubscription.UpdatedDate | dateText: 'M/DD/YYYY h:mm a' }}</span>
                      </ng-container>
                      <ng-container *ngIf="localProfile.BusinessCitationSubscription.HasIssues">
                        <span class="my-1 badge bg-warning">Resolving Issues</span>
                      </ng-container>
                      <ng-container
                        *ngIf="localProfile.BusinessCitationSubscription.StatusId === activeSubscriptionStatusId">
                        <button (click)="openProviderSSO(localProfile.LocalProfileId)" type="button"
                          class="my-1 btn btn-success btn-sm badge bg-success">
                          <i class="fab fa-google fa-fw"></i>Connect
                        </button>
                        <ng-container *ngIf="allowValidationAndAugmentationOrdering">
                          <ng-container *ngIf="localProfile.ValidationAndAugmentationStatus.CanOrder">
                            <button (click)="orderValidationAndAugmentation(localProfile)" type="button"
                              class="my-1 btn btn-success btn-sm badge bg-success">
                              <i class="fa fa-map-marker fa-fw"></i>Order Validation and Augmentation
                            </button>
                          </ng-container>
                          <ng-container *ngIf="!localProfile.ValidationAndAugmentationStatus.CanOrder">
                            <span
                              [ngbPopover]="'Not currently available: ' + localProfile.ValidationAndAugmentationStatus.Reason"
                              placement="auto" triggers="mouseenter:mouseleave">
                              <button type="button" class="my-1 btn btn-secondary btn-sm badge bg-secondary" disabled
                                style="pointer-events: auto;"
                                [attr.data-title]="localProfile.ValidationAndAugmentationStatus.Reason">
                                <i class="fa fa-map-marker fa-fw"></i>Order Validation and Augmentation
                              </button>
                            </span>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="localProfile.BusinessCitationSubscription === null && localProfile.BusinessCitationSubscriptionTaskId !== null">
              <ng-container *ngIf="localProfile.BusinessCitationProviderId === boostabilityProviderId">
                <div class="col-5">
                  <div class="alert alert-warning">
                    <div class="d-flex align-items-center">
                      <div class="float-start me-4">
                        <img src="../../../../Images/boostability.png" class="media-object" style="width:45px" />
                      </div>
                      <div class="flex-grow-1">
                        <span class="badge bg-warning">Provider: Boostability</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="localProfile.BusinessCitationProviderId !== boostabilityProviderId">
                <div class="col-5">
                  <div class="alert alert-warning">
                    <div class="d-flex align-items-center">
                      <div class="float-start me-4">
                        <ng-container *ngIf="localProfile.BusinessCitationProviderId === uberallProviderId">
                          <img src="../../../../Images/uberall.png" class="media-object" style="width:45px" />
                        </ng-container>
                      </div>
                      <div class="flex-grow-1">
                        <span class="badge bg-warning">Pending Subscription</span>
                        <span class="badge bg-warning">{{ getOrigin(localProfile) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div class="col-4" *ngIf="canManageSubscriptions">
              <div class="btn-group">
                <ng-container *ngIf="!(localProfile.HasSubscriptionModification)">
                  <ng-container
                    *ngIf="unassignedTaskCount > 0 && localProfile.BusinessCitationSubscriptionTaskId === null && localProfile.BusinessCitationSubscription === null">
                    <ng-container
                      *ngIf="localProfile.CanSubscribe && localProfile.BusinessCitationProviderId !== boostabilityProviderId">
                      <button (click)="subscribe(localProfile)" type="button" class="btn btn-success">Subscribe</button>
                    </ng-container>
                    <ng-container *ngIf="!(localProfile.CanSubscribe)">
                      <button type="button" class="btn btn-outline-secondary" disabled style="pointer-events: auto;"
                        title="To subscribe this Local Profile, set Can Subscribe to Yes">Subscribe</button>
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="localProfile.BusinessCitationSubscriptionTaskId === null && localProfile.BusinessCitationSubscription !== null && canManageSubscription(localProfile.BusinessCitationSubscription)">
                    <ng-container
                      *ngIf="unassignedTaskCount > 0 && localProfile.BusinessCitationProviderId !== boostabilityProviderId">
                      <ng-container *ngIf="localProfile.CanSubscribe">
                        <button (click)="confirmSubscription(localProfile)" type="button"
                          class="btn btn-success">Confirm Subscription</button>
                      </ng-container>
                      <ng-container *ngIf="!(localProfile.CanSubscribe)">
                        <button type="button" class="btn btn-outline-secondary" disabled style="pointer-events: auto;"
                          title="To confirm this subscription for this Local Profile, set Can Subscribe to Yes">Confirm
                          Subscription</button>
                      </ng-container>
                    </ng-container>
                    <button (click)="cancel(localProfile)" type="button" class="btn btn-danger">Cancel
                      Subscription</button>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>