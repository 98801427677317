import { Component, OnInit } from '@angular/core';
import { TaskStewardshipService } from '../../../services/task-stewardship.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { forkJoin } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { PartnerUserService } from '../../../services/partner-user.service';

@Component({
  selector: 'app-components-manager-taskstewardships',
  templateUrl: './TaskStewardships.component.html'
})
export class TaskStewardshipsComponent implements OnInit {
  public loading: boolean = false;
  public filterSearchCtrl: UntypedFormControl;
  public filterTaskTypeCtrl: UntypedFormControl;
  public filterLanguageCtrl: UntypedFormControl;
  public taskStewardshipResponsibilities: Boo.TaskStewardship.Models.TaskStewardshipResponsibility[];
  public filteredTaskStewardshipResponsibilities: Boo.TaskStewardship.Models.TaskStewardshipResponsibility[] =[];
  public taskTypes: Boo.Objects.TaskType[];
  public languages: Boo.Objects.Language[];
  public boostabilityUsers: Boo.Objects.PartnerUserFragment[];

  constructor(
    private partnerUserService: PartnerUserService,
    private taskStewardshipService: TaskStewardshipService,
    private sessionStorageService: SessionStorageService) {
  }

  public ngOnInit(): void {
    this.loading = true;

    forkJoin([this.sessionStorageService.getStaticData(), this.taskStewardshipService.get(), this.partnerUserService.getActiveBoostabilityUsers()])
      .subscribe(([staticData, taskStewardshipResponsibilities, boostabilityUsers]) => {
        this.taskTypes = staticData.TaskTypes;
        this.languages = staticData.Languages;
        this.taskStewardshipResponsibilities = taskStewardshipResponsibilities;
        this.boostabilityUsers = boostabilityUsers;

        this.filterSearchCtrl = new UntypedFormControl('', []);
        this.filterSearchCtrl.valueChanges.subscribe(_ => { this.filterTeams(); });
        this.filterTaskTypeCtrl = new UntypedFormControl(null, []);
        this.filterTaskTypeCtrl.valueChanges.subscribe(_ => { this.filterTeams(); });
        this.filterLanguageCtrl = new UntypedFormControl(null, []);
        this.filterLanguageCtrl.valueChanges.subscribe(_ => { this.filterTeams(); });
        this.filterTeams();
        this.loading = false;
      });
  }

  public loadStewardshipResponsibilities(): void {
    this.taskStewardshipService.get()
      .subscribe(taskStewardshipResponsibilities => {
        this.taskStewardshipResponsibilities = taskStewardshipResponsibilities;
        this.filterTeams();
    });
  }

  public edit(taskStewardshipResponsibility: Boo.TaskStewardship.Models.TaskStewardshipResponsibility): void {
    this.taskStewardshipService.get()
      .subscribe(taskStewardshipResponsibilities => {
        this.taskStewardshipResponsibilities = taskStewardshipResponsibilities;
        this.filterTeams();

        let responsibilitiesToEdit = taskStewardshipResponsibilities.filter(x => x.TaskStewardshipId === taskStewardshipResponsibility.TaskStewardshipId);
        if  (taskStewardshipResponsibilities.length) {
          this.openEditTaskStewardship({taskStewardshipResponsibility: responsibilitiesToEdit[0], taskStewardshipResponsibilities: responsibilitiesToEdit});
        } else {
          toastr.error('Task stewardship is no longer available');
        }
      });
  }

  public addStewardship(): void {
    this.openEditTaskStewardship({});
  }

  public filterTeams(): void {
    this.filteredTaskStewardshipResponsibilities =
      this.taskStewardshipResponsibilities.filter((taskStewardshipResponsibility) => 
      (this.filterSearchCtrl.value.trim() === '' 
      || taskStewardshipResponsibility.TaskStewardship.Name.trim().toLowerCase().indexOf(this.filterSearchCtrl.value.trim().toLowerCase()) >= 0 
      || taskStewardshipResponsibility.TaskStewardship.StewardName.trim().toLowerCase().indexOf(this.filterSearchCtrl.value.trim().toLowerCase()) >= 0)
      && (!this.filterTaskTypeCtrl.value || taskStewardshipResponsibility.TaskTypeId === this.filterTaskTypeCtrl.value)
      && (!this.filterLanguageCtrl.value || taskStewardshipResponsibility.LanguageId === this.filterLanguageCtrl.value));
  }

  public filter(): void {
    this.filterTeams();
  }

  get formIsReady(): boolean {
    return !!this.taskTypes && !!this.languages;
  }

  private openEditTaskStewardship(model: any): void {
    model.available = this.getAvailable();
    model.usedNames = this.getUsedNames(model.taskStewardshipResponsibility ? model.taskStewardshipResponsibility.TaskStewardshipId : 0);
    model.boostabilityUsers = this.boostabilityUsers;
    model.languages = this.languages;
    model.taskTypes = this.taskTypes;
    PriorityViewModel.ngShow('app-components-manager-taskstewardship', model).always(() => this.loadStewardshipResponsibilities());
  }

  private getAvailable(): any[] {
    let available: any[] = [];
    this.taskTypes.forEach(taskType => {
      this.languages.forEach(language => {
          if (!this.taskStewardshipResponsibilities.find(stewardship => { return stewardship.TaskTypeId === taskType.TaskTypeId && stewardship.LanguageId === language.LanguageId; })) {
            available.push({TaskTypeId: taskType.TaskTypeId, LanguageId: language.LanguageId });
      }
      });
    });
    return available;
  }

  private getUsedNames(excludeedTaskStewardshipId: number): string[] {
    let names: string[] = [];
    this.taskStewardshipResponsibilities
    .filter(x => x.TaskStewardshipId !== excludeedTaskStewardshipId)
    .forEach(x => {
      if (names.indexOf(x.TaskStewardship.Name) === -1) {
        names.push(x.TaskStewardship.Name);
      }
     });
    return names;
  }
}
