<div *withPermissions="let allow of ['CanDeleteTickets']" class="card">
  <div class="card-header">
    Manage Tickets
  </div>
  <div [loadingOverlay]="isLoading()" class="card-body">
    <div class="row">
      <div class="col-md-2">
        <div class="form-group ">
          <label for="startDate">Start Date</label>
          <input [dateTimePicker]="{ date: startDate }" name="startDate" class="form-control" type="text" />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group ">
          <label for="endDate">End Date</label>
          <input [dateTimePicker]="{ date: endDate }" name="endDate" class="form-control" type="text" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group ">
          <label for="searchText">Search Text</label>
          <input [ngModel]="searchText()" (ngModelChange)="searchText($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [returnKey]="search.bind(this)" type="text" class="form-control" name="searchText" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group ">
          <label class="d-block">&nbsp;</label>
          <button [disabled]="isLoading()" (click)="search()" class="btn btn-primary">
            <i class="fa fa-search"></i>
            Search
          </button>
          <button (click)="reset()" class="btn btn-link">Reset</button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        Additional Search Options
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-horizontal">
              <div [hidden]="!isTeamSelectVisible()" class="form-group row">
                <label class="col-md-4 col-form-label">Team:</label>
                <div class="col-md-8">
                  <select select2 [ngModel]="selectedTeam()" (ngModelChange)="selectedTeam($event)" [ngModelOptions]="{ standalone: true }" name="teamFilter" class="form-control">
                    <option [ngValue]="undefined">Select Team</option>
                    <option *ngFor="let option of teams()" [ngValue]="option.TeamId">{{ option.Name }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-4 col-form-label">User:</label>
                <div class="col-md-8">
                  <select select2 [ngModel]="selectedUserFilter()" (ngModelChange)="selectedUserFilter($event)" [ngModelOptions]="{ standalone: true }" name="userFilter" class="form-control">
                    <option [ngValue]="undefined">{{showInactiveUsers() ? 'Select Inactive User' : 'Select Active User'}}</option>
                    <option *ngFor="let option of userList()" [ngValue]="option.UserId">{{ option.FullName }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Show Inactive Users:</label>
                <div class="col-md-8">
                  <shared-components-customchoicelegacy [Settings]="{ value: showInactiveUsers }"></shared-components-customchoicelegacy>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Assigned State:</label>
                <div class="col-md-8">
                  <div class="btn-group">
                    <button (click)="toggleAssignedState(selectedAssignedState(), 'Either', $event)" [class.active]="selectedAssignedState() === assignedStates['Either']" class="btn btn-outline-secondary">
                      <i [class.fa]="selectedAssignedState() === assignedStates['Either']" [class.fa-check]="selectedAssignedState() === assignedStates['Either']" class="fa fa-check"></i>
                      Either
                    </button>
                    <button (click)="toggleAssignedState(selectedAssignedState(), 'Assigned', $event)" [class.active]="selectedAssignedState() === assignedStates['Assigned']" class="btn btn-outline-secondary">
                      <i [class.fa]="selectedAssignedState() === assignedStates['Assigned']" [class.fa-check]="selectedAssignedState() === assignedStates['Assigned']" class="fa fa-check"></i>
                      Assigned
                    </button>
                    <button (click)="toggleAssignedState(selectedAssignedState(), 'NotAssigned', $event)" [class.active]="selectedAssignedState() === assignedStates['NotAssigned']" class="btn btn-outline-secondary">
                      <i [class.fa]="selectedAssignedState() === assignedStates['NotAssigned']" [class.fa-check]="selectedAssignedState() === assignedStates['NotAssigned']" class="fa fa-check"></i>
                      Not Assigned
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Customer Status:</label>
                <div class="col-md-8">
                  <select [ngModel]="selectedStatus()" (ngModelChange)="selectedStatus($event)" [ngModelOptions]="{ standalone: true }" name="customerStatuses" id="customerStatuses" class="form-select">
                    <option *ngFor="let option of customerStatuses" [ngValue]="option.value">{{ option.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-horizontal">
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Ticket Types:</label>
                <div class="col-md-9">
                  <select [ngModel]="selectedTicketTypes()" (ngModelChange)="selectedTicketTypes($event)" [ngModelOptions]="{ standalone: true }" select2 class="form-control" multiple="multiple">
                    <option *ngFor="let option of ticketTypes()" [ngValue]="option.TicketTypeId">{{ option.Name }} </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-3">Minimum Spend:</label>
                <div class="col-md-9">
                  <input [ngModel]="minSpend()" (ngModelChange)="minSpend($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="number" class="form-control" min="0.00" step="0.01" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-3">Maximum Spend:</label>
                <div class="col-md-9">
                  <input [ngModel]="maxSpend()" (ngModelChange)="maxSpend($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="number" class="form-control" min="0.00" step="0.01" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-3">Customer Language:</label>
                <div class="col-md-9">
                  <select [ngModel]="selectedLanguageIds()" (ngModelChange)="selectedLanguageIds($event)" [ngModelOptions]="{ standalone: true }" select2 multiple="multiple" class="form-control" name="customerLanguageSelection">
                    <option *ngFor="let option of languages()" [ngValue]="option.LanguageId">{{ option.Name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="!ticketCount()" class="card card-body bg-light d-block" style="margin-bottom: 15px;">
      <div class="row">
        <div class="col-md-6">
          <button (click)="selectAllTickets()" class="btn btn-primary">
            <i class="far fa-check-square"></i>
            All
          </button>
          <button (click)="deselectAllTickets()" class="btn btn-info">
            <i class="far fa-square"></i>
            None
          </button>
        </div>
        <div class="col-md-6 text-end">
          <span>{{ticketCount()}}</span>
          &nbsp;result(s) found.
        </div>
      </div>
    </div>
    <div [hidden]="!ticketCount()" style="margin-top: 20px;">
      <app-table [items]="tickets()" [exportFilename]="'manage-tickets'">
        <ng-template #tableHeader>
          <tr>
            <th export sort field="TypeName">Ticket Type</th>
            <th export sort field="PartnerName">Partner</th>
            <th export sort field="CustomerLanguage">Language</th>
            <th export sort field="CreationDate">Created Date</th>
            <th export sort field="StartDate">Next Action Date</th>
            <th export sort asc field="DueDate">SLA Date</th>
            <th export sort field="InsertUserName">Created By</th>
            <th export sort field="AssignedUserName">Assigned User</th>
            <th export sort field="CustomerName">Company Name</th>
            <th export sort field="CustomerSpend">Customer Spend</th>
            <th export sort field="CustomerId">Customer</th>
            <th *ngIf="userCanDeleteTickets()">Actions</th>
          </tr>
        </ng-template>
        <ng-template #tableBody let-ticket>
          <tr (click)="selectTicket(ticket)" [class.table-warning]="ticket.isSelected()">
            <td>
              <button (click)="editTicket(ticket)" class="btn btn-primary">
                <i class="fas fa-external-link-square-alt"></i>
                <span>{{ticket.TypeName}}</span>
              </button>
            </td>
            <td>{{ticket.PartnerName}}</td>
            <td>{{ticket.CustomerLanguage}}</td>
            <td>{{ticket.CreationDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
            <td>{{ticket.StartDate | dateText: 'M/DD/YYYY h:mm a': false }}</td>
            <td>{{ticket.DueDate | dateText: 'M/DD/YYYY h:mm a': false }}</td>
            <td>{{ticket.InsertUserName}}</td>
            <td>{{ticket.AssignedUserName}}</td>
            <td>{{ticket.CustomerName}}</td>
            <td>{{ticket.CustomerSpend | currency }}</td>
            <td>
              <button (click)="openCustomer(ticket)" class="btn btn-primary">
                <i class="fas fa-external-link-square-alt"></i>
                <span>{{ticket.CustomerId}}</span>
              </button>
            </td>
            <ng-container *ngIf="userCanDeleteTickets()">
              <td>
                <button (click)="deleteTicket(ticket, $event)" class="btn btn-danger">
                  <i class="fa fa-times"></i>
                  Delete
                </button>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </app-table>
      <div class="card card-body bg-light d-block">
				<div class="row">
					<div class="col-md-4">
						<select [ngModel]="selectedUser()" (ngModelChange)="selectedUser($event)" [ngModelOptions]="{ standalone: true }" name="users" class="form-select" id="users">
							<option *ngFor="let option of users()" [ngValue]="option.UserId">{{ option.FullName }}</option>
						</select>
					</div>
					<div [hidden]="isViewOnly()" class="col-md-6">
						<button [disabled]="isLoading()" (click)="assignSelectedTickets()" class="btn btn-primary">
							<i class="fa fa-lock"></i>
							Assign
						</button>
						<button [disabled]="isLoading()" (click)="unassignSelectedTickets()" class="btn btn-info">
							<i class="fa fa-unlock"></i>
							Unassign
						</button>
					</div>
				</div>
			</div>
    </div>
  </div>
</div>