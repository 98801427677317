import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import TaskHelper from 'app/specialist/TaskHelper';
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { TaskKeyValuePairService } from '../../../services/task-keyvaluepair.service';
import { WorkflowKeyValuePairService } from '../../../services/workflow-keyvaluepair.service';
import WorkflowHelper from '../../../shared/WorkflowHelper';
import { PreAuditDetailService } from '../../../services/pre-audit-detail.service';
import { OnsiteCopyDetailService } from '../../../services/onsite-copy-detail.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-ticket-onsitecopyimplementationnotes',
  templateUrl: './OnsiteCopyImplementationNotes.component.html'
})
export class OnsiteCopyImplementationNotesComponent extends BaseComponent {
  public implementationInstructions: KnockoutObservable<string> = ko.observable();
  public onsiteCopyWillBoostPublishContent: KnockoutObservable<boolean> = ko.observable();
  public billingTask: KnockoutObservable<Boo.Objects.Task> = ko.observable();
  public isCmsBlackListed: boolean;
  public isCmsNotSet: boolean;
  public isOpen: boolean = true;
  public boostToPublish: boolean;

  private associatedTaskId: number;

  constructor(
    private taskKeyValuePairService: TaskKeyValuePairService,
    private workflowKeyValuePairService: WorkflowKeyValuePairService,
    private preAuditDetailService: PreAuditDetailService,
    private onsiteCopyDetailService: OnsiteCopyDetailService,
    private sessionStorageService: SessionStorageService) {
    super();
  }

  public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
    this.associatedTaskId = params.ticket.AssociatedTaskId();

    return $.when<any>(
      Utils.wrapDfd(this.onsiteCopyDetailService.getForOrderTaskByWorkflowTask(this.associatedTaskId)),
      Utils.wrapDfd(this.preAuditDetailService.getByCustomerId(params.customer.CustomerId())),
      Utils.wrapDfd(this.sessionStorageService.getStaticData()))
        .then((details: Boo.Objects.OnsiteCopyDetails, preAudit, staticData) => {
          this.isCmsBlackListed = preAudit && preAudit.ContentManagementSystemId ? (staticData.ContentManagementSystems.find((i: { ContentManagementSystemId: any; }) => i.ContentManagementSystemId === preAudit.ContentManagementSystemId) as Boo.Objects.ContentManagementSystem).IsBlacklisted : false;
          this.isCmsNotSet = !preAudit || (preAudit && !preAudit.ContentManagementSystemId);
          this.billingTask(details.BillingTask);
          this.workflowKeyValuePairService.getByTaskId(this.billingTask().TaskId)
            .subscribe((keyValuePairs: Boo.Objects.WorkflowKeyValuePair[]) => {
            this.onsiteCopyWillBoostPublishContent(WorkflowHelper.getIsBoostImplementingByResponsibility(keyValuePairs));
            });

          this.implementationInstructions = ko.observable(TaskHelper.getOnsiteCopyImplementationInstructions(details.BillingTask));
          this.implementationInstructions.extend({
            required: {
              onlyIf: (): boolean => {
                return this.onsiteCopyWillBoostPublishContent();
              },
              maxLength: 4000,
              message: 'Implementation instructions are required if Boostability will publish copy.'
            }
          });

          this.validation = ko.validatedObservable({
            Implement: this.onsiteCopyWillBoostPublishContent,
            ImplementationInstructions: this.implementationInstructions
          });
          return super.activate(params);
        });
  }

  public save(saveType: SaveTypes): JQueryPromise<void> {
    TaskHelper.setOnsiteCopyImplementationInstructions(this.billingTask(), this.implementationInstructions(), this.user.UserId);
    return Utils.wrapDfd(this.taskKeyValuePairService.save(this.billingTask().KeyValuePairs))
      .then(
        () => {
          // noop
        },
        (displayMessage) => {
          toastr.error(displayMessage);
        });
  }

  public validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
    return super.validate(saveType)
      .then((validationResult) => {
        if (!validationResult.isValid) {
          // open toggle panel and clear suggestion filter so errors are visible
          this.isOpen = true;
        }

        return validationResult;
      });
  }
}
