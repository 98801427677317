<div [loadingOverlay]="isLoading" *withPermissions="let allow of ['CanReserveSeoMinutes']">

  <app-components-managecustomer-allocatework-tasktypeselection
    [customerId]="customer.CustomerId()"
    [seoWorkSettings]="seoWorkSettings"
    [taskTypes]="taskTypes"
    [isCurrentPeriod]="selectedPeriod.index == 0"
    (addTaskType)="addItem($event)"
    (refresh)="refresh.emit()">
  </app-components-managecustomer-allocatework-tasktypeselection>

  <table class="table table-bordered"
    id="work-table">
    <thead>
      <tr>
        <th style="width: 80px"></th>
        <th>Task Name</th>
        <th>Status</th>
        <th class="num-col">Minutes Per Task</th>
        <th class="num-col">Qty</th>
        <th class="num-col">Total Minutes</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let group of taskTypeWorkGroupings">
        <tr *ngIf="group.workItems.length" [ngClass]="{'whitesmoke':group.expanded, 'alert-warning':isNonWorkable(group)}">
          <td class="text-center">
            <ng-container *ngIf="group.isManual">
              <a (click)="group.expanded = !group.expanded" class="cursor">
                <i [ngClass]="group.expanded ? 'fa-caret-square-up' : 'fa-caret-square-down'" class="far fa-2x"></i>
              </a>
            </ng-container>
          </td>
          <td>
            <span [ngbPopover]="group.taskTypeDescription" popoverTitle="Description" placement="left auto" triggers="mouseenter:mouseleave">
              <i class="fa fa-info-circle"></i>
            </span>
            <span>{{ group.taskTypeName }}</span>
            <span *ngIf="isNonWorkable(group)"> - This task type is exhausted</span>
          </td>
          <td>{{ getGroupStatus(group.workItems) }}</td>
          <td class="text-center">{{ getMinutesPerTask(group.workItems) }}</td>
          <td class="text-center">
            <ng-container *ngIf="!group.isManual; else qtyTemplate">
              <ng-select [multiple]="false" [closeOnSelect]="true" [clearable]="false" placeholder="Qty"
                (change)="groupQtyChange($event, group)" class="qty-select" [ngModel]="group.qty">
                <ng-option *ngIf="maxCount(group) < maxQtyNumber" [disabled]="true">Max: {{ maxCount(group) }}</ng-option>
                <ng-option *ngIf="canDeleteGroup(group)" value="0">0</ng-option>
                <ng-option *ngFor="let qty of qtyNumbers" [value]="qty" [disabled]="qtyDisabled(qty, group)" class="text-center">
                  {{qty}}
                </ng-option>
              </ng-select>
            </ng-container>
            <ng-template #qtyTemplate>
              {{ group.workItems.length }}
            </ng-template>
          </td>
          <td class="text-center">{{ group.workItems.length ? getGroupTotalMinutes(group.workItems) : 0 }}</td>
        </tr>
        <ng-container *ngIf="group.expanded">
          <tr *ngFor="let item of group.workItems">
            <td class="text-center">
              <a [hidden]="!item.CanDelete" (click)="deleteSpecificItem(item)" title="Delete" class="cursor">
                <i class="fas fa-trash-alt fa-2x text-danger"></i>
              </a>
            </td>
            <td>
              <ng-container *ngIf="item.CanEdit; else uneditableItem">
                <a (click)="editItem(item)" title="Edit" class="cursor">
                  {{ item.WorkDisplayName }} <span *ngIf="item.WorkRequestWebsiteUrl">- {{ item.WorkRequestWebsiteUrl}}</span>
                </a>
              </ng-container>
              <ng-template #uneditableItem>
                {{ item.WorkDisplayName }} <span *ngIf="item.WorkRequestWebsiteUrl">- {{ item.WorkRequestWebsiteUrl}}</span>
              </ng-template>
            </td>
            <td>{{ item.WorkStatusDisplayName }}</td>
            <td class="text-center">{{ item.WorkRequest.WorkQuantity }}</td>
            <td class="text-center">1</td>
            <td class="text-center">{{ item.WorkRequest.WorkQuantity }}</td>
          </tr>
        </ng-container>
      </ng-container>
      <tr class="whitesmoke" *ngIf="reservedMinutes">
        <td class="text-end" colspan="5">
          Minutes to Reserve
        </td>
        <td class="text-center">
          <input [disabled]="!allow().CanReserveSeoMinutes()" [(ngModel)]="reservedMinutes.reservedMinutes.Quantity" 
            [ngModelOptions]="{ standalone: true }" 
            type="number" 
            class="form-control text-center"/>
        </td>
      </tr>
      <tr class="whitesmoke">
        <td class="text-end" colspan="5">
          <div *ngIf="!validTotalMinutes" class="alert alert-danger mb-0">
            Invalid Minutes:
            {{ minuteDifferenceMessage }}
            <i class="fas fa-arrow-right"></i>
          </div>
        </td>
        <td class="text-center">
          <span [ngClass]="{'text-danger': !validTotalMinutes}">{{ totalMinutes }}</span>/{{ availableMinutes }} 
          <span *ngIf="reservedMinutesDescription" [ngbPopover]="reservedMinutesDescription"
                        [popoverTitle]="'Reserved Minutes'"
                        placement="left auto" triggers="mouseenter:mouseleave">
                        <i class="fa fa-info-circle"></i>
                      </span>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="row mb-2 mt-2 text-end">
    <div class="col-md-12">
      <button class="btn btn-warning" *ngIf="selectedPeriod.index > 0" (click)="clearPeriod.emit()">
        <i class="fas fa-eraser"></i>Clear Allocations
      </button>
      <button class="btn btn-danger" *ngIf="selectedPeriod.index > 0" (click)="deletePeriod.emit()" >
        <i class="fas fa-trash-alt"></i> Delete Allocation
      </button>
    </div>
</div>
