import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-components-shared-keywords-url-suggestion-readonly',
  templateUrl: './url-suggestion-readonly.component.html'
})
export class UrlSuggestionReadonlyComponent implements OnInit {
  @Input() urlSuggestion: Boo.Objects.QaUrlSuggestion;
  isApproved: boolean;

  constructor() { }

  ngOnInit(): void {
    this.isApproved = this.urlSuggestion.QaKeywordSuggestions.some(keyword => keyword.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Approved);
  }

  getKeywordDisplayText(keyword: Boo.Objects.QaKeywordSuggestion): string {
    if (keyword.Area) {
      return keyword.IsAreaFirst ? keyword.Area + ' ' + keyword.Keyword : keyword.Keyword + ' ' + keyword.Area;
    } else {
      return keyword.Keyword;
    }
  }

  textIsApproved(keyword: Boo.Objects.QaKeywordSuggestion): boolean {
    return keyword.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Approved;
  }

  textIsRejected(keyword: Boo.Objects.LegacyKeywordSuggestion): boolean {
    return keyword.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Rejected;
  }
}