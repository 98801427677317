import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { RejectableWorkspace } from 'app/interfaces/rejectable-workspace';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import Utils from '../../../../../shared/utils';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../../../shared/custom-validators';
import { InternalActionToken } from '../../internal-action.token';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-components-specialist-internal-tasks-site-themes-review-content',
  templateUrl: './site-themes-review-content.component.html'
})
export class SiteThemesReviewContentComponent implements OnInit, OnDestroy, CompletableWorkspace, UnlockableWorkspace, RejectableWorkspace, ClosableWorkspace {
  showUnlockButton: boolean = true;
  showCloseButton: boolean = true;

  siteTitle: string;
  sitePurpose: string;
  h1Field: UntypedFormControl = new UntypedFormControl('', [Validators.required, CustomValidators.minWordCountValidator(3), CustomValidators.maxWordCountValidator(10)]);
  metaDescription: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(160)]);
  revisedSitePurpose: UntypedFormControl = new UntypedFormControl('', [Validators.required, CustomValidators.minWordCountValidator(100), CustomValidators.maxWordCountValidator(150)]);

  siteThemesForm: UntypedFormGroup = new UntypedFormGroup({
    h1Field: this.h1Field,
    metaDescription: this.metaDescription,
    revisedSitePurpose: this.revisedSitePurpose
  });

  metaDescriptionCharacterCount: number = 0;
  revisedSitePurposeWordCount: number = 0;
  title = "Internal Task - Site Themes Review Content";
  isLoading: boolean = false;

  private metaDescriptionSubscription: Subscription;
  private revisedSitePurposeSubscription: Subscription;

  constructor(@Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction) { }

  ngOnInit() {
    this.metaDescriptionSubscription = this.metaDescription.valueChanges
      .subscribe((value: string) => this.metaDescriptionCharacterCount = value.length);
    this.revisedSitePurposeSubscription = this.revisedSitePurpose.valueChanges
      .subscribe((value: string) => this.revisedSitePurposeWordCount = Utils.wordCount(value));

    this.siteTitle = this.internalAction.InternalTaskDetails.BlogThemeContent.SiteTitle;
    this.sitePurpose = this.internalAction.InternalTaskDetails.BlogThemeContent.SitePurpose;
    this.h1Field.setValue(this.internalAction.InternalTaskDetails.BlogThemeContent.H1Field);
    this.metaDescription.setValue(this.internalAction.InternalTaskDetails.BlogThemeContent.MetaDescription);
    this.revisedSitePurpose.setValue(this.internalAction.InternalTaskDetails.BlogThemeContent.RevisedSitePurpose);
  }

  ngOnDestroy(): void {
    this.metaDescriptionSubscription.unsubscribe();
    this.revisedSitePurposeSubscription.unsubscribe();
  }

  isReadyToComplete(): boolean {
    if (this.siteThemesForm.valid) {
      this.internalAction.InternalTaskDetails.BlogThemeContent.H1Field = this.h1Field.value;
      this.internalAction.InternalTaskDetails.BlogThemeContent.MetaDescription = this.metaDescription.value;
      this.internalAction.InternalTaskDetails.BlogThemeContent.RevisedSitePurpose = this.revisedSitePurpose.value;
      return true;
    } else {
      this.siteThemesForm.markAllAsTouched();
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return false;
    }
  }

  isReadyToReject(): boolean {
    return true;
  }
}
