import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-components-shared-keywords-ticket-website-urls-readonly',
  templateUrl: './website-urls-readonly-ticket.component.html',
  styleUrls: ['./website-urls-readonly-ticket.component.scss']
})
export class WebsiteUrlsReadonlyTicketComponent {
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];

  constructor() { }
}
