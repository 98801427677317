import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { AddCustomerComponent } from './AddCustomer/AddCustomer.component';
import { SearchCustomersComponent } from './SearchCustomers/SearchCustomers.component';


@NgModule({
  declarations: [
    AddCustomerComponent,
    SearchCustomersComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedComponentsModule
  ]
})
export class SalesModule { }
