<div>
  <div class="row">
    <span>{{this.publishResponsibilityMapToString(this.implementationResponsibility)}} is responsible for publishing content to the site</span>
  </div>
  <br>
  <div class="row">
    <div class="col-md-9">
      <div class="row form-group">
        <div class="col-md-12">
          <label class="control-label">Where will this copy be published?</label>
          <div [formValidation]="publishToExistingPage" [placement]="Position.top" >
            <shared-components-customchoice [formControl]="publishToExistingPage" [choiceTrue]="'Existing Page'" [choiceFalse]="'New Page'">
            </shared-components-customchoice>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-12">
          <ng-container>
            <label class="control-label">Should we target the URL for linkBuild after the copy is published?</label>
            <div [dataValidation]="{ placement:'top', value: linkBuildingAfterPublish }">
              <shared-components-customchoicelegacy [Settings]="{ disable: !canEdit, value: linkBuildingAfterPublish }">
              </shared-components-customchoicelegacy>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-container>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">Page Type</label>
              <select [ngModel]="onsiteCopyPageType()" (ngModelChange)="onsiteCopyPageType($event)"
                [dataValidation]="{ placement: 'top', value: onsiteCopyPageType }" [ngModelOptions]="{ standalone: true }"
                [disabled]="!canEdit" class="form-select" name="selectedPageType" id="selectedPageType">
                <option [ngValue]="undefined">- Select Page Type -</option>
                <option *ngFor="let option of pageTypes()" [ngValue]="option.value">{{ option.name }}</option>
              </select>
            </div>
          </div>
        </div> 
        <div *ngIf="boostToPublish" class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">Notes for Implementation Specialist</label>
              <textarea [dataValidation]="{ placement: 'top', value: implementationInstructions }"
                [ngModel]="implementationInstructions()" (ngModelChange)="implementationInstructions($event)"
                [ngModelOptions]="{ standalone: true }" [disabled]="!canEdit" [readonly]="!canEdit" rows="5"
                class="form-control"
                placeholder="Where should this copy be placed on the page? If the copy is for a new page that is being created, where should the page be created on the site? Please place any other notes regarding implementation here."></textarea>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-md-3">
      <ng-container *ngIf="activeUrl">
        <div class="card">
          <div class="card-header">
            Active Keywords
          </div>
          <div class="card-body">
            <div>
              <ng-container *ngIf="activeUrl">
                <ul class="list-group">
                  <ng-container *ngFor="let $data of activeUrl.Keywords">
                    <ng-container *ngTemplateOutlet="onsiteCopyKeywords;context:{$context:$data}"></ng-container>
                  </ng-container>
                </ul>
                <ng-template #onsiteCopyKeywords let-$context="$context">
                  <ng-container *ngIf="!($context.Area)">
                    <li class="list-group-item">
                      <span>{{$context.Keyword.Name}}</span>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="$context.Area">
                    <ng-container *ngIf="$context.IsAreaFirst">
                      <li class="list-group-item">
                        <span>{{$context.Area.Name}}</span>
                        &nbsp;
                        <span>{{$context.Keyword.Name}}</span>
                        <span style="margin-top: 0" class="help-block">
                          <small>{{$context.Area.Name ? 'Separate location displayed first' : 'No Location'}}</small>
                        </span>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="!($context.IsAreaFirst)">
                      <li class="list-group-item">
                        <span>{{$context.Keyword.Name}}</span>
                        &nbsp;
                        <span>{{$context.Area.Name}}</span>
                        <span style="margin-top: 0" class="help-block">
                          <small>{{$context.Area.Name ? 'Separate location displayed last' : 'No Location'}}</small>
                        </span>
                      </li>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>  
</div>
