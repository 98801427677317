<div *withPermissions="let allow of ['CanAssignCsr']" class="card">
	<div class="card-header">
		Assign CSRs
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body">
		<div class="card-body">
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label" for="currentAcctMgr">Current CSR</label>
						<select [dataValidation]="{ placement: 'top', value: selectedCurrentCsr }" [ngModel]="selectedCurrentCsr()" (ngModelChange)="selectedCurrentCsr($event)" [ngModelOptions]="{ standalone: true }" name="currentAcctMgr" class="form-select" id="currentAcctMgr">
							<option [ngValue]="undefined">No current CSR</option>
							<option *ngFor="let option of csrs()" [ngValue]="option.UserId">{{ option.FullName }}</option>
						</select>
					</div>
					<div class="form-group">
						<label class="control-label" for="currentAcctMgr">Partner Team</label>
						<select [dataValidation]="{ placement: 'top', value: selectedTeamId }" [ngModel]="selectedTeamId()" (ngModelChange)="selectedTeamId($event)" [ngModelOptions]="{ standalone: true }" name="currentAcctMgr" class="form-select" id="currentAcctMgr">
							<option *ngFor="let option of teams()" [ngValue]="option.TeamId">{{ option.Name }}</option>
						</select>
					</div>
					<div class="form-group">
						<label class="control-label">Aggregate Monthly Reoccurring Spend</label>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="input-group">
								<span class="input-group-text">Min: $</span>
								<input [ngModel]="minSpend()" (ngModelChange)="minSpend($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="input-group">
								<span class="input-group-text">Max: $</span>
								<input [ngModel]="maxSpend()" (ngModelChange)="maxSpend($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
							</div>
						</div>
					</div>
					<div class="form-group">
						<button [disabled]="isLoading()" (click)="search()" class="btn btn-primary">
							<i class="fa fa-search"></i>
							Search
						</button>
					</div>
				</div>
				<div [hidden]="!customerCount()" class="col-md-6">
					<div class="text-center">
						<h4 class="card card-body bg-light d-block">
							<i class="fa fa-exclamation-triangle"></i>
							<span>{{customerCount()}}</span>
						</h4>
						<h5>Customers Assigned</h5>
						<div></div>
						<div></div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<label class="control-label">Do you want to re-assign these customers?</label>
						</div>
					</div>
					<div class="row">
						<div class="col-md-8">
							<select [dataValidation]="{ placement: 'top', value: selectedNewCsr }" [ngModel]="selectedNewCsr()" (ngModelChange)="selectedNewCsr($event)" [ngModelOptions]="{ standalone: true }" name="newAcctMgr" class="form-select" id="newAcctMgr">
								<option [ngValue]="undefined">No CSR</option>
								<option *ngFor="let option of activeCsrs()" [ngValue]="option.UserId">{{ option.FullName }}</option>
							</select>
						</div>
						<div class="col-md-4 text-end">
							<button [disabled]="isLoading()" (click)="reassignCsr()" class="btn btn-success">
								<i class="fa fa-lock"></i>
								Assign
							</button>
							&nbsp;
							<button (click)="reset()" class="btn btn-info">
								<i class="fa fa-undo"></i>
								Reset
							</button>
						</div>
					</div>
					<div class="form-group"></div>
				</div>
			</div>
		</div>
	</div>
</div>
