<div>
  <app-tab-panel [displayMode]="tabPanelDisplayMode.horizontal">
    <app-lazy-tab [title]="'Website'">
      <app-components-managecustomer-readonlycustomeraccountgroup *tabContent
        [customer]="customer"
        [customerAccountTypes]="website"
        [showBoostSiteAccounts]="true"
        [checkThryvDudaSso]="true">
      </app-components-managecustomer-readonlycustomeraccountgroup>
    </app-lazy-tab>
    <app-lazy-tab [title]="'SEO'">
      <app-components-managecustomer-readonlycustomeraccountgroup *tabContent
        [customer]="customer"
        [customerAccountTypes]="seo">
      </app-components-managecustomer-readonlycustomeraccountgroup>
    </app-lazy-tab>
    <app-lazy-tab [title]="'Blog'">
      <app-components-managecustomer-readonlycustomeraccountgroup *tabContent
        [customer]="customer"
        [customerAccountTypes]="blog">
      </app-components-managecustomer-readonlycustomeraccountgroup>
    </app-lazy-tab>
  </app-tab-panel>
</div>