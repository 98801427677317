<ng-container *ngIf="!historicalKeywordSuggestions || historicalKeywordSuggestions.length === 0">
  <div class="row">
    <div class="col-md-12">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No historical keywords available</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<div class="row" *ngIf="historicalKeywordSuggestions && historicalKeywordSuggestions.length > 0">
  <div class="col-md-12">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th>Keyword</th>
          <th>Keyword Type</th>
          <th>Date Added</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let historicalkeywordSuggestion of historicalKeywordSuggestions">
          <td>
            <ng-container *ngIf="isNational ? !historicalkeywordSuggestion.Area : !!historicalkeywordSuggestion.Area">
              <i class="fa fa-clone" (click)="reAddKeyword(historicalkeywordSuggestion)"></i>
            </ng-container>
            {{historicalkeywordSuggestion.IsAreaFirst ? (historicalkeywordSuggestion.Area || '') + ' ' + historicalkeywordSuggestion.Keyword : historicalkeywordSuggestion.Keyword + ' ' + (historicalkeywordSuggestion.Area || '') }}
          </td>
          <td>
            {{historicalkeywordSuggestion.KeywordSuggestionType.Name}}
          </td>
          <td>
            {{historicalkeywordSuggestion.InsertedDate | date:'shortDate'}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>