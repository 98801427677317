<div id="keywords-component">
  <app-components-togglepanel [(isOpen)]="isOpen" title="Keyword Research" toggleBody="true">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div data-toggle="collapse" href="#noteBody" role="button">
              Keyword Research Notes
              <i class="only-collapsed fa-caret-square-down far float-end"></i>
              <i class="only-expanded fa-caret-square-up far float-end"></i>
            </div>
          </div>
          <div class="collapse hide" id="noteBody">
            <ng-container *ngFor="let note of keywordResearchNotes">
              <div class="p-3">
                <h6>{{ note.InsertedDate | date: 'MM/dd/yyyy HH:mm a' }} - {{ note.Type }}</h6>
                <textarea disabled class="w-100 p-2" rows="8">{{ note.Note }}</textarea>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div data-toggle="collapse" href="#salesNoteBody" role="button">
              Sales Note
              <i class="only-collapsed fa-caret-square-down far float-end"></i>
              <i class="only-expanded fa-caret-square-up far float-end"></i>
            </div>
          </div>
          <div class="collapse show" id="salesNoteBody">
            <div class="card-body line-breaks">{{ salesNote }}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div data-toggle="collapse" href="#discoveryNoteBody" role="button">
              Discovery Note
              <i class="only-collapsed fa-caret-square-down far float-end"></i>
              <i class="only-expanded fa-caret-square-up far float-end"></i>
            </div>
          </div>
          <div class="collapse show" id="discoveryNoteBody">
            <div class="card-body line-breaks">{{ discoveryNote }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-components-shared-keywords-keyword-research-summary
          [(customer)]="nonKnockoutCustomer"
          [isReadOnly]="!canChangeCampaignType"
          (isNationalChanged)="updateIsNational($event)">
        </app-components-shared-keywords-keyword-research-summary>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 float-end">
        <div class="float-end">
          <span class="badge pill bg-secondary" style="font-size: 90%; padding: 10px; margin:10px; font-weight: 400;">Target Pages: {{summary.UrlSuggestions.length}}/{{keywordSuggestionConfig?.minimumPages}}</span>
          <span class="badge pill bg-secondary" style="font-size: 90%; padding: 10px; margin:10px; font-weight: 400;">Required Keywords: {{keywordSuggestionCount}}/{{keywordSuggestionConfig?.minimumKeywordSuggestions}}</span>
          <app-components-helptext [key]="'KeywordResearch_RecommendedKeywords'" [noMargin]="true"></app-components-helptext>
          <span class="badge pill bg-secondary" style="font-size: 90%; padding: 10px; margin:10px; font-weight: 400;">Required Halo Keywords: {{approvedHaloKeywordCount}}/{{keywordSuggestionConfig?.minimumHaloKeywordSuggestions}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a href="#pages" data-toggle="tab" class="nav-link active">Pages / Keywords</a>
          </li>
          <li class="nav-item">
            <a href="#halo" data-toggle="tab" class="nav-link">Halo Keywords</a>
          </li>
          <li class="nav-item">
            <a href="#history" data-toggle="tab" class="nav-link">History</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" id="pages">
            <app-components-shared-keywords-ticket-url-suggestions
              [urlSuggestions]="summary.UrlSuggestions"
              [keywordSuggestionValidator]="keywordSuggestionValidator"
              [customer]="nonKnockoutCustomer"
              [isNationalChanged]="isNationalChanged"
              [addKeywordToUrlSuggestion]="addKeywordToUrlSuggestion"
              [keywordTypes]="keywordTypes"
              [readonlyUrls]="unorderedUrls"
              (isRedoRequested)="redoStateChange($event)"
              (keywordCountChanged)="updateKeywordCount()"
              (urlApproved)="urlApproved($event)"
              (urlApprovalUndone)="urlApprovalUndone($event)">
            </app-components-shared-keywords-ticket-url-suggestions>
          </div>
          <div class="tab-pane" id="halo">
            <app-components-shared-keywords-ticket-halo-keyword-suggestions
              [(haloKeywords)]="summary.HaloKeywordSuggestions"
              [(newHaloKeywords)]="newHaloKeywords"
              [isNationalChanged]="isNationalChanged"
              (updateApprovedCount)="updateHaloKeywordCount()">
            </app-components-shared-keywords-ticket-halo-keyword-suggestions>
            <ng-container>
              <app-components-shared-keywords-halo-keywords-readonly class="pb-3" [haloKeywords]="activeHaloKeywords">
              </app-components-shared-keywords-halo-keywords-readonly>
            </ng-container>
          </div>
          <div class="tab-pane" id="history">
            <app-components-shared-keywords-ticket-historical-keyword-suggestions 
              [historicalKeywordSuggestions]="summary.HistoricalKeywordSuggestions"
              [isNational]="originalIsNational"
              (historicalKeywordReAdded)="openHistoricKeywordModal(historicKeywordModal, $event)">
            </app-components-shared-keywords-ticket-historical-keyword-suggestions>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="redoNoteForm.enabled && isEnabled">
      <button class="btn btn-danger mt-2" (click)="openRedoModal(redoModal)">Request Redo</button>
    </ng-container>
  </app-components-togglepanel>
</div>

<ng-template #redoModal let-modal>
  <div [loadingOverlay]="isLoading()" class="modal-content">
    <div class="modal-header">
      Redo Reason
    </div>
    <div class="modal-body">
      <textarea class="form-control" [(formControl)]="redoNoteForm"></textarea>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-end gap-1">
        <button class="btn btn-info" (click)="modal.dismiss()">Cancel</button>
        <button class="btn btn-danger" (click)="redo(modal)">Confirm Redo</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #historicKeywordModal let-modal>
  <div class="modal-content" [(formGroup)]="hisoricKeywordForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Re-Add Keyword</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label>Keyword: </label>
        {{getKeywordDisplayText(historicKeyword)}}
      </div>
      <div class="form-group">
        <label>Url</label>
        <ng-select [clearable]="false" formControlName="urlSuggestion" [searchable]="false">
          <ng-option *ngFor="let urlSuggestion of getNonRedoUrlSuggestions()" [value]="urlSuggestion">{{urlSuggestion.Url}}</ng-option>
        </ng-select>
      </div>
      <div class="form-group pb-5">
        <label>Type</label>
        <ng-select [items]="keywordTypes" [clearable]="false" bindLabel="Name" bindValue="KeywordSuggestionTypeId" formControlName="keywordType" [searchable]="false" [disabled]="hisoricKeywordForm.get('keywordType').disabled"></ng-select>
      </div>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-end gap-1">
        <button class="btn btn-info" (click)="modal.dismiss()">Cancel</button>
        <button class="btn btn-success" (click)="confirmReAddHistoricKeyword(modal)">Confirm</button>
      </div>
    </div>
  </div>
</ng-template>