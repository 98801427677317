/* tslint:disable */

import { Component, OnInit } from '@angular/core';
import timer from 'app/managecustomer/Timer';
import { PartnerCountryService } from '../../../services/partner-country.service';
import partner from 'app/models/partner';
import vertical from 'app/models/vertical';
import permissions from 'app/models/Permissions';
import { CustomerService } from '../../../services/customer.service';
import { CustomerOptionService } from '../../../services/customer-option.service';
import { PartnerUserService } from '../../../services/partner-user.service';
import { TicketService } from '../../../services/ticket.service';
import { LocalProfileService } from '../../../services/local-profile.service';
import Utils from '../../../shared/utils';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { finalize, switchMap } from 'rxjs/operators';
import { SessionStorageService } from '../../../services/session-storage.service';
import PartnerGroupEnum = Boo.Objects.Enums.PartnerGroupEnum;
import { forkJoin, of } from 'rxjs';
import { OptionTypes, CustomerOption } from '../../../shared/models/options/options';
import { LeadSourceService } from '../../../services/lead-source.service';
import Permissions from 'app/models/Permissions';

@Component({
    selector: 'app-components-managecustomer-basicinformation',
    templateUrl: './BasicInformation.component.html'
})
export class BasicInformationComponent implements OnInit {
    title: string = 'Basic Information';
    dfd: any;
    wasVerticalSet: boolean;
    isLoading: boolean;
    isManager: boolean = false;
    refreshCustomer: any = null;
    canChangeCountry: boolean = true;
    customer: Boo.Objects.Customer;
    partner: Boo.Objects.Partner = new partner();
    partnerUsers: Boo.Objects.PartnerUser[] = [];
    shouldSeoMoneyBackGuaranteeBeShown: boolean = false;
    csrUserId: UntypedFormControl;
    timeZone: string;
    leadSources: Boo.Objects.LeadSource[] = [];
    referredBy: UntypedFormControl = new UntypedFormControl('');
    isTopWorkPriority: boolean;
    isHighProfile: boolean = false;
    isBoostSitesCustomer: boolean;
    hasHadUrlUnreachableTicket: boolean = false;
    canClose: boolean = false;
    teams: Boo.Objects.Team[] = [];
    verticals: vertical[] = [];
    countries: Boo.Objects.Country[] = [];
    languages: Boo.Objects.Language[] = [];
    timeZones: TimeZoneWithAbbr[] = [];
    filteredTimeZones: TimeZoneWithAbbr[] = [];
    csrs: Boo.Objects.PartnerUser[] = [];
    countryLanguages: Boo.Objects.CountryLanguage[];
    emailOption: CustomerOption<boolean>;
    forms: UntypedFormGroup;
    userPermissions: Boo.Objects.UserPermission[] = [];
    partnerUserLevelPermissions: Boo.Objects.PartnerUserLevelPermission[] = [];

    private unalteredVerticals: Boo.Objects.Vertical[] = [];

    constructor(
        private customerService: CustomerService,
        private customerOptionService: CustomerOptionService,
        private partnerUserService: PartnerUserService,
        private ticketService: TicketService,
        private partnerCountryService: PartnerCountryService,
        private localProfileService: LocalProfileService,
        private sessionStorageService: SessionStorageService,
        private leadSourceService: LeadSourceService) { }

    ngOnInit(): void {
        this.isLoading = true;
        // Let's get the user and determine if some functionality should be shown or not
        forkJoin([
            this.sessionStorageService.getPartner(),
            this.sessionStorageService.getPartnerUsers(),
            this.sessionStorageService.getStaticData(),
            this.leadSourceService.get(),
            this.localProfileService.fragments(this.customer.CustomerId),
            this.sessionStorageService.getUser()
        ])
            .subscribe(([partner, partnerUsers, staticData, leadSources, fragments, user]) => {
                this.unalteredVerticals = jQuery.extend(true, {}, staticData.Verticals);
                this.countryLanguages = staticData.CountryLanguages;
                this.partner = partner;
                this.partnerUsers = partnerUsers;
                this.userPermissions = user.UserPermissions
                this.partnerUserLevelPermissions = partner.PartnerUserLevelPermissions;
                this.isManager = partnerUsers.some((pu: any) => {
                    return pu.UserLevelId === 5;
                });
                this.teams = staticData.Teams;
                this.leadSources = leadSources;
                this.setupForm();
                this.loadCustomer(staticData);
                const activeLocalProfile = fragments.find((localProfile) => localProfile.IsActive);
                if (activeLocalProfile) {
                    this.canChangeCountry = false;
                    this.forms.get('countryId').disable();
                }
            });

        this.ticketService.hasHadUrlUnreachableTicket(this.customer.CustomerId)
            .subscribe((hasHadUrlUnreachableTicket) => {
                this.hasHadUrlUnreachableTicket = hasHadUrlUnreachableTicket;
            }, err => {
                toastr.error(err);
            });
        this.canClose = this.dfd ? true : false;
    }

    canActivate(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, options: any) {
        if (options.refreshCustomer) {
            this.refreshCustomer = options.refreshCustomer;
        }
        if (options && options.customer) {
            this.customer = ko.mapping.toJS(options.customer);
        }
        return true;
    }

    save(): void {
        timer.resetUIActivityTime();
        Utils.showAllMessages(this.forms);
        if (this.forms.valid) {
            this.persistCustomer();
        } else {
            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
            this.forms.markAllAsTouched();
        }
    }

    cancel(): void {
        if (this.dfd) {
            this.dfd.reject();
        }
    }

    countryChanged() {
        this.languages = this.countryLanguages.filter(x => x.CountryId === this.forms.get('countryId').value).map(x => x.Language);
        this.forms.get('languageId').setValue(undefined);
        this.forms.get('languageId').updateValueAndValidity();
        this.timeZone = undefined;
        this.filteredTimeZones = this.filterTimeZones();
    }

    //made for use on textboxes
    conditionalRequiredValidator(errMessage: string, conditionalFn: () => boolean): ValidatorFn {
        return (control: AbstractControl): { [key: string]: string } | null => {
            if (conditionalFn()) {
                if (control.value && control.value.length != 0) {
                    return null;
                }
                else {
                    return { 'required': errMessage };
                }
            }
            else {
                return null;
            }
        };
    }

    //TODO: replace with a general implementation for all form groups or wait for angular to do something to solve this problem
    refreshAll() {
        this.forms.get('companyName').updateValueAndValidity();
        this.forms.get('heading').updateValueAndValidity();
        this.forms.get('description').updateValueAndValidity();
        this.forms.get('teamId').updateValueAndValidity();
        this.forms.get('verticalId').updateValueAndValidity();
        this.forms.get('languageId').updateValueAndValidity();
        this.forms.get('countryId').updateValueAndValidity();
        this.forms.get('highProfileReason').updateValueAndValidity();
        this.forms.get('leadSourceId').updateValueAndValidity();
    }

    csrIsValid(partnerUser: any) {
        return launchpad.hasPermission(this.partner, [partnerUser], permissions.HasAllTeams, partnerUser)
            || _.pluck(partnerUser.Teams, 'TeamId').includes(this.forms.get('teamId').value);
    }

    countryAbbreviation() {
        const country = _.find(this.countries,
            (c: any) => {
                return c.CountryId === this.forms.get('countryId').value;
            });
        return country ? country.Abbreviation : 'US';
    };

    csrName() {
        let name = 'No Team Set';
        if (this.customer) {
            if (this.customer.Csr) {
                name = this.customer.Csr.FullName;
            } else if (this.customer.Team) {
                name = this.customer.Team.Name;
            }
        }
        return name;
    };

    onTimeZoneSelected(timeZone: string) {
        this.timeZone = timeZone;
    }

    private persistCustomer(): void {
        this.isLoading = true;
        this.customer.Name = this.forms.get('companyName').value;
        this.customer.Heading = this.forms.get('heading').value;
        this.customer.Description = this.forms.get('description').value;
        this.customer.CsrUserId = this.csrUserId.value;
        this.customer.TeamId = this.forms.get('teamId').value;
        this.customer.VerticalId = this.forms.get('verticalId').value;
        let vertical = this.forms.get('verticalId').value ? _.findWhere(this.unalteredVerticals, { 'VerticalId': this.forms.get('verticalId').value }) : {};
        this.customer.Vertical = ko.mapping.fromJS(vertical);
        this.customer.CountryId = this.forms.get('countryId').value;
        this.customer.TimeZone = this.timeZone;
        this.customer.LanguageId = this.forms.get('languageId').value;
        let language = this.forms.get('languageId').value ? _.findWhere(this.languages, { 'LanguageId': this.forms.get('languageId').value }) : {};
        this.customer.Language = ko.mapping.fromJS(language);
        this.customer.ReferredBy = this.referredBy.value;
        this.customer.IsTopWorkPriority = this.isTopWorkPriority;
        this.customer.IsHighProfile = this.isHighProfile;
        this.customer.HighProfileReason = this.forms.get('highProfileReason').value;
        this.customer.LeadSourceId = this.forms.get('leadSourceId').value;
        forkJoin([
            this.customerService.saveBasicInfo(this.customer),
        ]).pipe(
            switchMap(() => {
                if (!this.forms.get('emails').dirty) {
                    return of(null);
                }

                this.emailOption.Values = this.forms.get('emails').value;
                return this.customerOptionService.Save([this.emailOption]);
            }),
            finalize(() => {
                this.isLoading = false;

                if (this.refreshCustomer) {
                    this.refreshCustomer();
                }
            })).subscribe({
                next: () => {
                    toastr.success('Customer saved successfully.');

                    if (this.dfd) {
                        this.dfd.resolve({
                            'customer': this.customer
                        });
                    }
                },
                error: err => {
                    toastr.error(err);
                }
            });
    }

    private setupForm(): void {
        let teamIdValidators = this.customer.TeamId ?
            (control: AbstractControl): { [key: string]: string } => {
                if (this.customerTeamIdIsValid(control.value)) {
                    return null;
                }
                return { 'required': 'A team must be selected' }
            } : [];

        let verticalIdValidators = this.customer.VerticalId ?
            (control: AbstractControl): { [key: string]: string } => {
                if (control.value != 0) {
                    return null;
                }
                return { 'required': 'Vertical cannot be left unassigned' }
            } : [];

        let headingValidators = [Validators.maxLength(65)];
        if (this.customer.Heading) {
            if (this.customer.Heading.length >= 25) {
                headingValidators.unshift(Validators.minLength(25));
            }

            headingValidators.unshift(Validators.required);
        }

        let descriptionValidators = [Validators.maxLength(155)];
        if (this.customer.Description) {
            if (this.customer.Description.length >= 75) {
                descriptionValidators.unshift(Validators.minLength(75));
            }

            descriptionValidators.unshift(Validators.required);
        }
        this.csrUserId = new UntypedFormControl({value: 0, disabled: !this.hasPermission(Permissions.CanAssignCsr)});
        this.forms = new UntypedFormGroup({
            companyName: new UntypedFormControl('', this.customer.Name ? Validators.required : []),
            teamId: new UntypedFormControl({value: 0, disabled: !this.hasPermission(Permissions.CanAssignCsr)}, teamIdValidators),
            verticalId: new UntypedFormControl(0, verticalIdValidators),
            heading: new UntypedFormControl('', headingValidators),
            description: new UntypedFormControl('', descriptionValidators),
            languageId: new UntypedFormControl(null, this.customer.LanguageId ? Validators.required : []),
            countryId: new UntypedFormControl(null, this.customer.CountryId ? Validators.required : []),
            highProfileReason: new UntypedFormControl('', this.conditionalRequiredValidator('Field is required', () => {
                return this.isHighProfile;
            })),
            emails: new UntypedFormControl(null, Validators.required),
            leadSourceId: new UntypedFormControl({value: null, disabled: !this.hasPermission(Permissions.CanManageLeadSources)}, [])
        });
    }

    private loadCustomer(staticData: Boo.Objects.LaunchPadStaticData): void {
        // First we fetch all of our data and then we want to bind our customer.
        this.timeZones = staticData.TimeZones as any;
        forkJoin([
            this.partnerCountryService.get(this.customer.PartnerId),
            this.customerOptionService.GetOption<boolean>(this.customer.CustomerId, OptionTypes.canReceiveEmails)
        ]).pipe(
            finalize(() => this.isLoading = false)
        ).subscribe({
            next: ([partnerCountries, emailOption]) => {
                this.loadStaticDataValues(staticData, partnerCountries);
                if (this.customer) {
                    this.forms.get('companyName').setValue(this.customer.Name);
                    this.forms.get('heading').setValue(this.customer.Heading);
                    this.forms.get('description').setValue(this.customer.Description);
                    this.csrUserId.setValue(this.customer.CsrUserId);
                    this.forms.get('teamId').setValue(this.customer.TeamId);
                    this.forms.get('verticalId').setValue(this.customer.VerticalId);
                    this.wasVerticalSet = this.forms.get('verticalId').value !== 0;
                    this.forms.get('countryId').setValue(this.customer.CountryId);
                    this.timeZone = this.customer.TimeZone;
                    this.forms.get('languageId').setValue(this.customer.LanguageId);
                    this.referredBy.setValue(this.customer.ReferredBy);
                    this.languages = this.countryLanguages.filter(x => x.CountryId === this.forms.get('countryId').value).map(x => x.Language);
                    this.isTopWorkPriority = this.customer.IsTopWorkPriority;
                    this.isHighProfile = this.customer.IsHighProfile;
                    this.forms.get('highProfileReason').setValue(this.customer.HighProfileReason);
                    this.forms.get('emails').setValue(emailOption.Values);
                    this.isBoostSitesCustomer = this.customer.Spends &&
                        _.some(this.customer.Spends,
                            (spend: any) => {
                                return spend && spend.LegacyProductId && spend.LegacyProductId === Boo.Objects.Enums.ProductEnum.BoostSite;
                            });
                    const moneyBackGroup = staticData.PartnerGroups.filter(x => x.GroupId == PartnerGroupEnum.ThryvMoneyBack)[0];
                    this.shouldSeoMoneyBackGuaranteeBeShown = moneyBackGroup.PartnerGroups.some(x => x.PartnerId === this.customer.PartnerId);
                    this.refreshAll();
                    this.filteredTimeZones = this.filterTimeZones();
                    this.emailOption = emailOption;
                    this.forms.get('leadSourceId').setValue(this.customer.LeadSourceId);
                }
            },
            error: (err) => toastr.error(err)
        })
        // CSRs are loaded separately for performance reasons.
        this.partnerUserService.getActiveCustomerReps()
            .subscribe((mgrData: any) => {
                let partnerUserSort = (a: any, b: any) => {
                    if (a.FirstName < b.FirstName) {
                        return -1;
                    }
                    if (a.FirstName > b.FirstName) {
                        return 1;
                    }
                    return 0;
                };
                mgrData.sort(partnerUserSort);
                this.csrs = mgrData.filter(this.csrIsValid.bind(this));
            });
    }

    private loadStaticDataValues(staticData: Boo.Objects.LaunchPadStaticData, partnerCountries: any): void {
        // Get verticals sorted
        const sorter = new launchpad.utils.pSort();
        sorter.key = 'VerticalId';
        sorter.parentKey = 'ParentVerticalId';
        this.verticals = sorter.sort(staticData.Verticals);
        const defaultVertical = new vertical();
        defaultVertical.Name = 'Choose...';
        this.verticals.splice(0, 0, defaultVertical);
        _.each(this.verticals,
            (vert: any) => {
                vert.Name = this.getSpaces(vert.NestedLevel - 1) + vert.Name;
            });
        this.countries = staticData.Countries.filter(
            (country: any) => {
                return partnerCountries.some(
                    (partnerCountry: any) => {
                        return partnerCountry.CountryId === country.CountryId && country.SupportStatusId === window.launchpad.config.supportStatus.supported;
                    });
            });
    }

    private getSpaces(numSpaces: any): any {
        let spaces = '';
        if (numSpaces === 0) {
            return spaces;
        } //For some reason ng-select removes leading whitespace.
        for (let i = 0; i <= (numSpaces * 2); i++) {
            if (i === 0)
                spaces += '>';
            else
                spaces += ' ';
        }
        return spaces;
    }

    private customerTeamIdIsValid(val: any): boolean {
        return !this.partnerUsers.some(
            (partnerUser: any) => {
                return partnerUser.PartnerId === 1;
            }) ||
            (_.isNumber(val) && val > 0);
    }

    private filterTimeZones(): TimeZoneWithAbbr[] {
        if (!this.customer) {
            return [];
        }
        const result = this.timeZones.filter((timeZone) => {
            return timeZone.CountryId === this.forms.get('countryId').value;
        }).sort();
        const timeZones = _.map(result,
            (timeZone) => {
                const z: any = moment.tz(timeZone.Name);
                timeZone.NameWithAbbr = timeZone.Name + ' (' + z.zoneAbbr() + ')';
                return timeZone;
            });
        return timeZones;
    }

    private hasPermission(permission: number): boolean {;
        return this.userPermissions.some((x: any) => x.PermissionId === permission) 
        || this.partnerUserLevelPermissions.some((x: any) => x.PermissionId === permission);
    }
}

interface TimeZoneWithAbbr extends Boo.Objects.TimeZone {
    NameWithAbbr: string;
}