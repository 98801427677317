<div class="card">
	<div class="card-header">
		<div class="row">
			<div class="col-md-2">Configure QA Tasks</div>
		</div>
	</div>
	<div class="card-body" [loadingOverlay]="isLoading">
		<div [formGroup]="qaTaskConfigForm">
			<div class="row">
				<div class="col-md-6">
					<label class="col-form-label auto-grow">QA Task Type</label>
					<ng-select [items]="qaTaskTypeConfigurations" [ngModel]="selectedConfiguration"
						(ngModelChange)="updateForm($event)" [ngModelOptions]="{standalone: true}" bindLabel="Name"
						[searchable]="false" [clearable]="false">
					</ng-select>
				</div>
				<div class="col-md-6">
					<label class="col-form-label auto-grow">Percent of User Tasks Graded</label>
					<div class="input-group">
						<input formControlName="gradingPercentage" percentageValue class="form-control" type="number">
						<span class="input-group-text">%</span>
					</div>
				</div>
			</div>
			<ng-container>
				<div class="row">
					<div class="col-md-6">
						<div class="card">
							<div class="card-header">New Users</div>
							<div class="card-body">
								<div class="row">
									<div class="col-md-12">
										<div>
											<label class="control-label">Grade new users at different rate?</label>
										</div>
										<shared-components-customchoice [value]="useNewUserPercentage"
											(valueChange)="updateNewUsersControls($event)"></shared-components-customchoice>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<label class="col-form-label auto-grow">New User History Months</label>
										<input formControlName="newUserMonths" class="form-control" type="number">
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<label class="col-form-label auto-grow">Percent of New User Tasks Graded</label>
										<div class="input-group">
											<input formControlName="newUserGradingPercentage" percentageValue class="form-control"
												type="number">
											<span class="input-group-text">%</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card">
							<div class="card-header">Underperforming Users</div>
							<div class="card-body">
								<div class="row">
									<div class="col-md-12">
										<div>
											<label class="control-label">Grade underperforming users at different rate?</label>
										</div>
										<shared-components-customchoice [value]="useUnderperformingPercentage"
											(valueChange)="updateUnderperformingControls($event)"></shared-components-customchoice>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<label class="col-form-label auto-grow">Target Average Grade</label>
										<input formControlName="targetAverageGrade" class="form-control" type="number">
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<label class="col-form-label auto-grow">Percent of Underperforming User Tasks Graded</label>
										<div class="input-group">
											<input formControlName="underperformingGradingPercentage" percentageValue class="form-control"
												type="number">
											<span class="input-group-text">%</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="card-footer" [loadingOverlay]="isLoading">
		<div class="text-end">
			<button (click)="save()" class="btn btn-success">
				<i class="fa fa-check"></i>
				Save
			</button>
		</div>
	</div>
</div>