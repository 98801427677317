export class ReservedMinutesNode {
  reservedMinutes: Boo.Objects.Work.SeoMinute.SeoMinuteReservation;
  next: ReservedMinutesNode | null;
  previous: ReservedMinutesNode | null;
  constructor(reservedMinutes: Boo.Objects.Work.SeoMinute.SeoMinuteReservation) {
    this.reservedMinutes = reservedMinutes;
    this.next = null;
    this.previous = null;
  }

  get quantity(): number {
    return this.reservedMinutes?.Quantity || 0;
  }

  get previousQuantity(): number {
    return this.previous?.quantity || 0;
  }

  get hasPrevious() {
    return this.previous ? true : false;
  }

  get hasNext() {
    return this.next ? true : false;
  }
}