<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        Partner Users
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input [ngModel]="userId()" (ngModelChange)="userId($event)"
                [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="isLoading()"
                [returnKey]="findPartnerUser.bind(this)" type="number" class="form-control" name="searchText"
                placeholder="Enter user ID" />
            </div>
          </div>
          <div class="col-md-1">
            <div class="form-group">
              <label class="control-label d-block">&nbsp;</label>
              <button [disabled]="isLoading()" (click)="findPartnerUser()" class="btn btn-primary">
                <i class="fa fa-search"></i>
                Search
              </button>
            </div>
          </div>
          <div [hidden]="!hasPartnerUsers()" class="col-md-3">
            <div class="form-group">
              <label class="control-label">Full Name</label>
              <input [ngModel]="fullName()" (ngModelChange)="fullName($event)"
                [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"
                readonly="readonly" />
            </div>
          </div>
          <div [hidden]="!hasPartnerUsers()" class="col-md-3">
            <div class="form-group">
              <label class="control-label">Username</label>
              <input [ngModel]="username()" (ngModelChange)="username($event)"
                [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"
                readonly="readonly" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <app-table [items]="partnerUsers()">
              <ng-template #tableHeader>
                <tr>
                  <th sort field="PartnerUserId">Partner User ID</th>
                  <th sort field="Partner.Name">Partner</th>
                  <th sort field="UserLevel.Name">User Level</th>
                  <th sort field="InsertedDate">Inserted Date</th>
                  <th>Action</th>
                </tr>
              </ng-template>
              <ng-template #tableBody let-user>
                <tr>
                <tr>
                  <td>{{user.PartnerUserId}}</td>
                  <td>{{user.Partner.Name}}</td>
                  <td>{{user.UserLevel.Name}}</td>
                  <td>{{user.InsertedDate | dateText: 'M/DD/YYYY h:mm a' }}</td>
                  <td>
                    <div class="btn-group" style="min-width: 150px;">
                      <button (click)="delete(user)" class="btn btn-danger">
                        <i class="fa fa-times"></i>
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </app-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>