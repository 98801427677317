import { Component } from '@angular/core';
import { GuaranteeService } from '../../../services/guarantee.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-guarantees-list',
  templateUrl: './List.component.html'
})
export class ListComponent implements IActivatable {
    public guarantees: Boo.Objects.Guarantee[];
    public api: any;

    constructor(private guaranteeService: GuaranteeService) { }

    public activate(params: app.components.guarantees.interfaces.IGuaranteeCustomerScopeActivationParams): JQueryPromise<void> {
        this.api = params.api;

        return Utils.wrapDfd(this.guaranteeService.getForCustomer(params.customerId))
          .then(guarantees => {
              this.guarantees = guarantees;
          });
    }
}
