import { Component } from '@angular/core';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import CustomerAccountTypeGroup from 'app/customeraccount/CustomerAccountTypeGroup';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import TaskHelper from 'app/specialist/TaskHelper';
import { GoogleAnalyticsService } from '../../../../services/google-analytics.service';
import { TaskFileService } from '../../../../services/task-file.service';
import { CustomerService } from '../../../../services/customer.service';
import { TicketService } from '../../../../services/ticket.service';
import { UserService } from '../../../../services/user.service';
import Utils from '../../../../shared/utils';
import Constants from '../../../../shared/constants';

import { DisplayModes } from 'app/models/enums/DisplayModes';

type IUploadFileActivateParams = app.interfaces.IUploadFileActivateParams;

@Component({
    selector: 'app-components-specialist-actioncomponents-customimplementationaction',
    templateUrl: './CustomImplementationAction.component.html'
})
export class CustomImplementationActionComponent implements IActionWorkspace, IActivatable {
    DisplayModes = DisplayModes;
    window = window;
    launchpad = launchpad;
    title: string = 'Custom Task';
    actionViewModel: KnockoutObservable<IActionWorkspace>;
    currentAction: KnockoutObservable<Boo.Objects.Action>;
    isLoading: KnockoutObservable<boolean>;
    notifyOnCompletion: KnockoutObservable<boolean>;
    taskFiles: KnockoutObservableArray<Boo.Objects.TaskFile> = ko.observableArray([]);
    taskDefinitionFiles: KnockoutObservableArray<Boo.Objects.TaskFile> = ko.observableArray([]);
    tickets: KnockoutObservableArray<Boo.Objects.Ticket> = ko.observableArray([]);
    noteToCsr: KnockoutObservable<string>;
    hasGoogleAnalyticsAccess: KnockoutObservable<boolean> = ko.observable(false);
    gaAttempt: KnockoutObservable<number> = ko.observable(0);
    customerAccountGroupIsEnabled = ko.observable(false);
    showLocationPanel: boolean = false;

    customer: Boo.Objects.Customer;
    customerId: number;
    customerAccountTypes: number[];
    customDescriptionFromTask: string;

    constructor(
        private customerService: CustomerService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private taskFileService: TaskFileService,
        private ticketService: TicketService,
        private userService: UserService) { }

    showTaskFiles: KnockoutComputed<boolean>;
    showTaskDefinitionFiles: KnockoutComputed<boolean>;
    showGoogleAnalyticsLink: KnockoutComputed<boolean>;

    private taskActionUrl: string;

    activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {
        this.showLocationPanel = params.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.PremiumPreliminaryAudit;
        params.controlViewModel(this);
        this.currentAction = params.currentAction;
        this.customDescriptionFromTask = TaskHelper.getCustomDescription(this.currentAction().Task);
        this.actionViewModel = ko.observable(null);
        this.isLoading = ko.observable(false);
        this.noteToCsr = ko.observable('');

        this.showTaskFiles = ko.computed((): boolean => {
            return this.taskFiles().length > 0;
        });

        this.showTaskDefinitionFiles = ko.computed((): boolean => {
            return this.taskDefinitionFiles().length > 0;
        });
        this.customerAccountTypes = CustomerAccountTypeGroup.customImplementationLogins;

        this.customer = this.currentAction().Customer;
        this.customerId = this.currentAction().Task.CustomerId;
        const loadTaskFilesDfd = this.loadTaskFiles(this.currentAction().Task.TaskId);
        const loadTicketsDfd = this.loadTickets(this.customerId, this.currentAction().Task.TaskId);
        this.notifyOnCompletion = ko.observable(this.currentAction().Task.NotifyOnCompletion);
        this.showGoogleAnalyticsLink = ko.computed((): boolean => this.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.GoogleAnalyticsCreationAndImplementation);

        let checkGoogleAccessDfd = undefined;
        let getFullCustomerDfd = undefined;
        if (this.showGoogleAnalyticsLink()) {
            checkGoogleAccessDfd = this.checkGoogleAccess(this.customerId);
            getFullCustomerDfd = this.getFullCustomer(this.customerId);
        }

        return $.when(loadTaskFilesDfd, loadTicketsDfd, checkGoogleAccessDfd, getFullCustomerDfd);
    }

    isStepReadyToReject(): boolean {
        return true;
    }

    isStepReadyToSave(): boolean {
        return this.currentAction().Task.TaskTypeSummary.IsPersistable;
    }

    isStepReadyToDelete(): boolean {
        return false;
    }

    isStepReadyToComplete(): boolean {
        if (this.notifyOnCompletion()) {
            this.currentAction().CustomActionDetails.NoteToCsr = this.noteToCsr();
            this.currentAction().CustomActionDetails.NoteText = 'The ordered action ' + this.currentAction().Task.TaskId + ' has been completed for this customer. A record of this task may be found in the customer reporting. ' + this.noteToCsr();
        }
        if (_.isArray(this.taskFiles()) && this.taskFiles().length > 0) {
            this.currentAction().CustomActionDetails.TaskFileUploaded = true;
            this.currentAction().Task.TaskActionUrl = this.taskActionUrl;
        } else {
            this.currentAction().CustomActionDetails.TaskFileUploaded = false;
            this.currentAction().Task.TaskActionUrl = 'Not Applicable';
        }


        const result = !this.showGoogleAnalyticsLink() || (this.hasGoogleAnalyticsAccess() || (this.gaAttempt() > 0));

        if (!result) {
            if (!this.hasGoogleAnalyticsAccess() && !this.gaAttempt()) {
                toastr.error('Please attempt to connect customer\'\s Google Analytics to reporting site');
            }
        }

        return result;
    }

    uploadFile(): void {

        PriorityViewModel.show('app-components-shared-uploadfile', this.getUploadFileOptions())
            .then((data: any) => {
                let taskFile = <Boo.Objects.TaskFile>{
                    Name: data.FileName,
                    TaskFileType: Boo.Objects.Enums.TaskFileTypeEnum.TaskActionFile,
                    IsDeleted: false,
                    TaskId: this.currentAction().Task.TaskId,
                    S3Url: data.FullUrl
                };

                this.taskActionUrl = taskFile.S3Url;
                return Utils.wrapDfd(this.taskFileService.insertUpdate(taskFile));
            }).then((taskFile: Boo.Objects.TaskFile) => {
                this.taskFiles.push(taskFile);
                this.taskFiles.valueHasMutated();
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading(false);
            });
    }

    viewTicket(ticketId: number): void {
        let ticketOptions = {
            // don't pass customer so that ticket vm will load customer.
            ticketId: ticketId
        };
        PriorityViewModel.show('app-components-ticket', ticketOptions);
    }

    deleteFile(file: Boo.Objects.TaskFile): void {
        bootbox.confirm('Are you sure? This action cannot be undone.', (result: boolean) => {
            if (result === true) {
                file.IsDeleted = true;
                if (this.taskActionUrl === file.S3Url) {
                    this.taskActionUrl = undefined;
                }

                Utils.wrapDfd(this.taskFileService.insertUpdate(file))
                    .then(() => {
                        this.taskFiles(_.without(this.taskFiles(), _.findWhere(this.taskFiles(), { 'TaskFileId': file.TaskFileId }))); // remove deleted file from taskfiles
                        toastr.success('Task File Deleted');
                    }).fail((displayMessage: string) => {
                        toastr.error(displayMessage);
                    });
            }
        });
    }

    grantGoogleAnalyticsAccess(): JQueryPromise<void> {
        return Utils.wrapDfd(this.userService.impersonate(_.first(this.customer.Users).CustomerUserId))
            .then((token: string) => {
                window.open(`${this.customer.Partner.ReportingUrl}/?t=${token}&path=${Constants.MyMarketInfoProfilePath}`, '_blank');

                let msg = 'Did you attempt to connected Google Analytics to the customer\'\ reporting?';
                bootbox.confirm(msg, (result: boolean) => {
                    if (result) {
                        this.gaAttempt(this.gaAttempt() + 1);
                        this.checkGoogleAccess(this.customer.CustomerId);
                    }
                });
            })
            .fail((error: string) => {
                toastr.error(error, 'User impersonation for GA access failed');
            });
    }

    private loadTaskFiles(taskId: number): JQueryPromise<any> {
        this.isLoading(true);

        return Utils.wrapDfd(this.taskFileService.selectByTaskId(taskId))
            .then((data: Array<Boo.Objects.TaskFile>) => {
                if (data !== null && data !== undefined && data.length > 0) {
                    _.each(data, (fileItem: Boo.Objects.TaskFile) => {
                        if (fileItem.TaskFileType === 2) {
                            this.taskDefinitionFiles().push(fileItem);
                        } else {
                            this.taskFiles().push(fileItem);
                            this.taskActionUrl = fileItem.S3Url;
                        }
                    });
                    this.taskFiles.valueHasMutated();
                    this.taskDefinitionFiles.valueHasMutated();
                }
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading(false);
            });
    }

    private loadTickets(customerId: number, taskId: number): JQueryPromise<any> {
        return Utils.wrapDfd(this.ticketService.getByCustomer(customerId, false))
            .then((data: Array<Boo.Objects.Ticket>) => {
                data = _(data).where({ AssociatedTaskId: taskId, StatusId: 3 });
                this.tickets(data);
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading(false);
            });
    }

    private checkGoogleAccess(customerId: number): JQueryPromise<any> {
        return Utils.wrapDfd(this.googleAnalyticsService.checkAuthorization(customerId))
            .then((hasAccess) => this.hasGoogleAnalyticsAccess(hasAccess));
    }

    private getFullCustomer(customerId: number): JQueryPromise<any> {
        return Utils.wrapDfd(this.customerService.get(customerId))
            .then((fullCustomer) => {
                this.customer = fullCustomer;
            });
    }

    private getUploadFileOptions(): IUploadFileActivateParams {
        let params: IUploadFileActivateParams = {
            preSignedURLRequest: new PresignedUrlRequest()
        };

        params.preSignedURLRequest.BucketName = launchpad.config.S3Buckets.s3staticfiles;
        params.preSignedURLRequest.Folder = 'taskfile/' + this.currentAction().Task.TaskId;
        params.preSignedURLRequest.UseUniqueFileName = true;

        if (this.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.PremiumPreliminaryAudit) {
            params.acceptedFileExtensions = ['.xlsx', '.csv', '.docx'];
        }

        return params;
    }

}
