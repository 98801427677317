<div class="card">
	<div class="card-header">
		<span>Change Specialist Score</span>
	</div>
	<div [loadingOverlay]="isLoading" class="card-body">
		<div class="row">
			<div class="col-md-3">
				<div class="form-group">
					<label class="control-label">Task Id</label>
					<input [(ngModel)]="taskId" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"
						type="number" />
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group">
					<button [disabled]="isLoading" (click)="loadGrade()" style="margin-top: 25px;" class="btn btn-primary">
						<i class="fa fa-search"></i>
						Change Score
					</button>
				</div>
			</div>
		</div>
		<div *ngIf="showTool">
			<div class="row">
				<div class="col-md-6">
					<ul class="list-group">
						<li class="list-group-item">
							<strong>Task Type</strong>
							<span class="float-right">{{qaGrade.Task.TaskTypeSummary.Name}}</span>
						</li>
						<li class="list-group-item">
							<strong>Specialist Name</strong>
							<span class="float-right">{{user.FullName}}</span>
						</li>
					</ul>
				</div>
				<div class="col-md-6">
					<ul class="list-group">
						<li class="list-group-item">
							<strong>Task Date</strong>
							<span class="float-right">{{dateString}}</span>
						</li>
						<li class="list-group-item">
							<strong>Score</strong>
							<span class="float-right">{{qaGrade.Points}}</span>
						</li>
					</ul>
				</div>
			</div>
			<br/>
			<app-components-qa-grading-area-row [taskType]="qaTaskType"
				[qaCriteriaGroups]="qaCriteriaGroups" [initialCriteria]="qaGrade.QaCriteria" [initialGradeComment]="qaGrade.Comment" #gradingArea>
			</app-components-qa-grading-area-row>
			<div class="card card-body bg-light d-block text-end">
				<button (click)="clearGrade()" class="btn btn-link">Cancel</button>
				<button (click)="changeGrade()" class="btn btn-success btn-lg">
					<i class="fas fa-check"></i>
					Change Grade
				</button>
			</div>
		</div>
	</div>
</div>