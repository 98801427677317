import { Component, EventEmitter, Input, Output } from '@angular/core';
import FlagTypes = Boo.Objects.Work.SeoMinute.Enums.FlagTypes;

@Component({
  selector: 'app-components-managecustomer-allocatework-tasktypeselection',
  templateUrl: './TaskTypeSelection.component.html',
  styleUrls: ['../../AllocateWork.component.scss']
})
export class TaskTypeSelectionComponent {
  @Input() customerId: number;
  @Input() seoWorkSettings: Boo.Objects.Work.SeoMinute.SeoWorkSettings;
  @Input() taskTypes: Boo.Objects.Work.SeoMinute.SeoWorkTaskType[];
  @Input () isCurrentPeriod: boolean;
  @Output() addTaskType: EventEmitter<Boo.Objects.Work.SeoMinute.SeoWorkTaskType> = new EventEmitter<Boo.Objects.Work.SeoMinute.SeoWorkTaskType>();
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  selectedTaskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType;
  orderableTaskTypes: Boo.Objects.Work.SeoMinute.SeoWorkTaskType[];

  ngOnInit(): void {
    this.orderableTaskTypes = this.taskTypes.filter(x => !x.Flags.some(f => f.Type === FlagTypes.NotVisibleForOrdering));
  }

  add(): void {
    this.addTaskType.emit(this.selectedTaskType);
    this.selectedTaskType = undefined;
  }

  canBeOrdered(taskTypeId: number): boolean {
    return !this.orderableTaskTypes
      .find(x => x.TaskTypeId === taskTypeId)
      .Flags
      .some(x => x.Type === FlagTypes.Nonworkable
        || x.Type === FlagTypes.Unavailable
        || (x.Type === FlagTypes.ExaustedForPeriod && this.isCurrentPeriod));
  }
 
  isNonworkableFlag(flag: Boo.Objects.Work.SeoMinute.TaskTypeFlag): boolean {
    return flag.Type === FlagTypes.Nonworkable || (flag.Type === FlagTypes.ExaustedForPeriod && this.isCurrentPeriod);
  }

  getTitle(taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType): string {
    return !this.canBeOrdered(taskType.TaskTypeId) ? this.getFlagMessages(taskType, [FlagTypes.Nonworkable, FlagTypes.Unavailable]) :
      this.hasWarnings(taskType) ? this.getFlagMessages(taskType, [FlagTypes.Warning]) :
      'Available';
  }

  getIconClass(taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType): string {
    return !this.canBeOrdered(taskType.TaskTypeId) ? 'fas fa-stop-circle text-danger' :
    this.hasWarnings(taskType) ? 'fas fa-exclamation-circle text-warning' :
    'fas fa-check-square text-success';
  }

  searchTaskTypes(term: string, item: Boo.Objects.Work.SeoMinute.SeoWorkTaskType): boolean {
    term = term.toLowerCase();
    return item.Name.toLowerCase().indexOf(term) > -1 || item.Minutes.toString().indexOf(term) > -1;
  }

  private hasWarnings(taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType): boolean {
    return taskType.Flags.some(x => x.Type === FlagTypes.Warning);
  }

  private getFlagMessages(taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType, flagTypes: FlagTypes[]): string {
    return this.orderableTaskTypes
      .find(x => x.TaskTypeId === taskType.TaskTypeId)
      .Flags
      .filter(x => flagTypes.includes(x.Type))
      .map(x => x.Message)
      .join(', ');
  }
}
