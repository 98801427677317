import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LinkLocationService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/linklocations`;

  constructor(private http: HttpClient) { }

  get(linkLocationId: number): Observable<Boo.Objects.LinkLocation> {
    let params = new HttpParams().set('linkLocationId', linkLocationId);
    return this.http.get<Boo.Objects.LinkLocation>(this.baseUrl, { params: params });
  }

  getByType(linkTypeId: number): Observable<Boo.Objects.LinkLocation[]> {
    let params = new HttpParams().set('linkTypeId', linkTypeId);
    return this.http.get<Boo.Objects.LinkLocation[]>(`${this.baseUrl}/GetByType`, { params: params });
  }

  getPostSummary(linkLocationId: number): Observable<Boo.Objects.LinkLocationBlogPostSummary> {
    let params = new HttpParams().set('linkLocationId', linkLocationId);
    return this.http.get<Boo.Objects.LinkLocationBlogPostSummary>(`${this.baseUrl}/Summary`, { params: params });
  }

  save(linkLocation: Boo.Objects.LinkLocation): Observable<any> {
    return this.http.post<void>(this.baseUrl, linkLocation);
  }

  saveBlogOrArticle(linkLocation: Boo.Objects.LinkLocation): Observable<any> {
    return this.http.post<void>(`${this.baseUrl}/SaveBlogOrArticle`, linkLocation);
  }

  saveBatch(linkLocations: Boo.Objects.LinkLocation[]): Observable<any> {
    return this.http.post<void>(`${this.baseUrl}/SaveBatch`, { linkLocations: linkLocations });
  }

  getAmazonDeploymentHistory(linkLocationId: number): Observable<Boo.Objects.AmazonDeploymentStatusHistoryItem[]> {
    let params = new HttpParams().set('linkLocationId', linkLocationId);
    return this.http.get<Boo.Objects.AmazonDeploymentStatusHistoryItem[]>(`${this.baseUrl}/GetAmazonDeploymentHistory`, { params: params });
  }

  retireBlog(linkLocationId: number, isDeindexed: boolean, shouldRepublish: boolean): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/RetireBlog`, {
      linkLocationId: linkLocationId,
      isDeindexed: isDeindexed,
      shouldRepublish: shouldRepublish
    });
  }

  repostPosts(linkLocationId: number, repostToSameBlog: boolean): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/RepostPosts`, {
      linkLocationId: linkLocationId,
      repostToSameBlog: repostToSameBlog
    });
  }

  resetDNS(linkLocationId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/ResetDNS`, { linkLocationId: linkLocationId });
  }
}
