<div class="card card-body">
	<div style="min-height: 500px;">
		<div class="card">
			<div class="card-header">
				{{title}}
			</div>
			<div id="ProTips" class="card-collapse">
				<div class="card-body">
					<div class="row">
						<ng-container *ngIf="proTip">
							<div class="col-md-6">
								<div class="form-group">
									<label class="control-label">Partner</label>
									<input [(ngModel)]="partnerName" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" readonly/>
								</div>
								<div class="form-group">
									<label class="control-label">Language</label>
									<select [ngModel]="proTip.LanguageId()" (ngModelChange)="proTip.LanguageId($event)" [ngModelOptions]="{ standalone: true }" name="languages" id="languages" class="form-select">
										<option *ngFor="let option of languages" [ngValue]="option.LanguageId">{{ option.Name }}</option>
									</select>
								</div>
								<div class="form-group">
									<label class="control-label">Title</label>
									<input [ngModel]="proTip.Title()" (ngModelChange)="proTip.Title($event)" [ngModelOptions]="{ standalone: true }" class="form-control" type="text" maxlength="250"/>
								</div>
								<div class="form-group">
									<label class="control-label">Body</label>
									<textarea [dataValidation]="{ placement: 'top', value: proTip.Body }" [ngModel]="proTip.Body()" (ngModelChange)="proTip.Body($event)" [ngModelOptions]="{ standalone: true }" style="height:55px" class="form-control" maxlength="500" rows="3"></textarea>
								</div>
								<div class="form-group">
									<label class="control-label">Hyperlink</label>
									<input [dataValidation]="{ placement: 'top', value: proTip.Hyperlink }" [ngModel]="proTip.Hyperlink()" (ngModelChange)="proTip.Hyperlink($event)" [ngModelOptions]="{ standalone: true }" type="text" class="form-control" name="hyperlink" maxlength="250"/>
								</div>
								<div class="form-group">
									<label class="control-label">Hyperlink Text</label>
									<input [dataValidation]="{ placement: 'top', value: proTip.HyperlinkText }" [ngModel]="proTip.HyperlinkText()" (ngModelChange)="proTip.HyperlinkText($event)" [ngModelOptions]="{ standalone: true }" class="form-control" type="text" maxlength="500"/>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label class="control-label">Start Date</label>
											<input [dateTimePicker]="{ date: startDate }" type="text" name="startDate" class="form-control"/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label class="control-label">
												Start Time
												<small>(Local Time)</small>
											</label>
											<input type="text" timePicker [DateTime]="startDate"/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label class="control-label">End Date</label>
											<input [dateTimePicker]="{ date: endDate }" type="text" name="endDate" class="form-control"/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label class="control-label">
												End Time
												<small>(Local Time)</small>
											</label>
											<input type="text" timePicker [DateTime]="endDate"/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label>Image</label>
											<div>
												<button [dataValidation]="{ placement: 'right', value: proTip.ImageUrl}" [hidden]="proTip.ImageUrl()" (click)="uploadImage()" class="btn btn-sm">
													<span class="fa fa-camera"></span>
													Upload
												</button>
											</div>
											<div class="btn-group">
												<ng-container *ngIf="proTip.ImageUrl()">
													<button type="button" class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown">
														<img [hidden]="!proTip.ImageUrl()" [attr.src]="proTip.ImageUrl()" class="img-fluid"/>
													</button>
													<ul class="dropdown-menu">
														<li class="dropdown-item">
															<a (click)="uploadImage()" href="javascript:void(0)">
																<span class="fa fa-camera"></span>
																Upload
															</a>
														</li>
														<li role="separator" class="dropdown-divider"></li>
														<li class="dropdown-item">
															<a (click)="removeImage()" href="javascript:void(0)">Remove</a>
														</li>
													</ul>
												</ng-container>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
					<hr/>
					<div class="row">
						<ng-container *ngIf="proTip">
							<div class="col-md-12">
								<div class="col-md-4 offset-md-4 text-center">
									<h2>Preview</h2>
									<h4 *ngIf="proTip.Title()" class="text-white">{{proTip.Title()}}</h4>
									<div>
										<ng-container *ngIf="proTip.ImageUrl()">
											<img [attr.src]="proTip.ImageUrl()" style="max-width: 100%; min-width:100%" class="img-fluid"/>
										</ng-container>
										<h5 *ngIf="proTip.Body()" class="text-muted my-2">{{proTip.Body()}}</h5>
										<ng-container *ngIf="proTip.Hyperlink()">
											<a *ngIf="proTip.Hyperlink()" [externalLink]="{ url: proTip.Hyperlink(), text:proTip.HyperlinkText()}" class="btn btn-warning"></a>
										</ng-container>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row text-end">
		<div class="col-md-12 text-end">
			<button (click)="close()" class="btn btn-danger">
				<i class="fa fa-times"></i>
				Cancel
			</button>
			<button (click)="save()" class="btn btn-success">
				<i class="fa fa-save"></i>
				Save
			</button>
		</div>
	</div>
</div>
