<div class="card">
  <div class="card-header">
    Tools
    <button (click)="newLinkLocation()" class="btn btn-primary float-end">
      <i class="fa fa-plus"></i>
      New Link Location
    </button>
  </div>
  <div [loadingOverlay]="isLoading()" class="card-body">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Link Type</label>
          <select [ngModel]="selectedLinkTypeId()" (ngModelChange)="selectedLinkTypeId($event)"
            [ngModelOptions]="{ standalone: true }" class="form-select" name="types">
            <option [ngValue]="undefined">Select Link Type</option>
            <option *ngFor="let option of filteredLinkTypes()" [ngValue]="option.LinkTypeId">{{ option.Name }}</option>
          </select>
        </div>
      </div>
      <div [hidden]="!(links().length > 0)" class="col-md-4">
        <div class="form-group">
          <label class="control-label">Filter List</label>
          <input [ngModel]="urlFilter()" (ngModelChange)="urlFilter($event)" [ngModelOptions]="{ standalone: true }"
            type="text" class="form-control" />
        </div>
      </div>
    </div>
    <hr />
    <app-table [items]="filteredLinks()" [exportFilename]="'link-type-activation'">
      <ng-template #tableHeader>
        <tr>
          <th export sort asc field="Url">Url</th>
          <ng-container *ngIf="showVertical()">
            <th export sort field="linkVertical">Vertical</th>
          </ng-container>
          <ng-container *ngIf="showSubmitOrder()">
            <th export sort field="SubmitOrder">Submit Order</th>
          </ng-container>
          <th export sort field="linkLanguage">Language</th>
          <th export sort field="linkCountry">Country</th>
          <th export sort field="linkIsActive()">Is Active</th>
          <th>Action</th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-link>
        <tr>
          <td>{{link.Url}}</td>
          <ng-container *ngIf="showVertical()">
            <td>{{link.linkVertical}}</td>
          </ng-container>
          <ng-container *ngIf="showSubmitOrder()">
            <td>{{link.SubmitOrder}}</td>
          </ng-container>
          <td>{{link.linkLanguage}}</td>
          <td>{{link.linkCountry}}</td>
          <td>
            <div class="btn-group" style="min-width: 150px;">
              <button [disabled]="isLoading()" (click)="setIsActive(link)" [class.active]="link.linkIsActive() === true"
                type="button" style="min-width: 70px;" class="btn btn-outline-secondary">
                <i [class.fa]="link.linkIsActive()" [class.fa-check]="link.linkIsActive()" class="fa fa-check"></i>
                Yes
              </button>
              <button [disabled]="isLoading()" (click)="setIsActive(link)"
                [class.active]="link.linkIsActive() === false" type="button" style="min-width: 70px;"
                class="btn btn-outline-secondary">
                <i [class.fa]="!link.linkIsActive()" [class.fa-check]="!link.linkIsActive()" class="fa fa-check"></i>
                No
              </button>
            </div>
          </td>
          <td>
            <button [disabled]="isLoading()" (click)="editLinkLocation(link)" class="btn btn-primary">
              <i class="fa fa-edit"></i>
              Edit
            </button>
          </td>
        </tr>
      </ng-template>
    </app-table>
  </div>
</div>