export enum PreAuditReportSection {
  OrganicSearch = 0,
  WebsiteBuild = 1,
  Local = 2,
  Mobile = 3,
}

export const PreAuditReportConfig: { [key: string]: IPreAuditReportItemDisplayConfig[] } = {
  "Mobile": [{ 
    title: "Mobile friendly",
    generateDisplayData: (data: any) => {
      return !data.IsMobile 
        || data.HasSmallText 
        || data.HasSmallLinks 
        || data.HasPagesWithFlash 
        || data.HasHorizontalScroll ? "Mobile issues" : "Optimized for mobile"; },
    generateStatus: (data: any) => {
      return !data.IsMobile 
        || data.HasSmallText 
        || data.HasSmallLinks 
        || data.HasPagesWithFlash 
        || data.HasHorizontalScroll ? PreAuditReportItemStatus.Fail : PreAuditReportItemStatus.Pass;
    },
    section: PreAuditReportSection.Mobile
  }],
  "AmountOfContent": [
    {
      title: "Amount of content",
      generateDisplayData: (data: any) => { return `${data.TotalWordCount} words on ${data.PagesTested} pages`; },
      generateStatus: (data: any) => PreAuditReportItemStatus.Info,
      section: PreAuditReportSection.OrganicSearch
    },
    {
      title: "Website size",
      generateDisplayData: (data: any) => { return `${data.PagesFound} pages found`; },
      generateStatus: (data: any) => PreAuditReportItemStatus.Info,
      section: PreAuditReportSection.WebsiteBuild
    }
  ],
  "Backlinks": [{
    title: "Backlinks",
    generateDisplayData: (data: any) => { return `${data.TotalBacklinks} backlinks`; },
    generateStatus: (data: any) => PreAuditReportItemStatus.Info,
    section: PreAuditReportSection.OrganicSearch
  }],
  "PageTitlesAndDescriptions": [{
    title: "Titles and descriptions",
    generateDisplayData: (data: any) => data.MissingItems || data.DuplicatedItems ? "Missing or duplicated items" : "All items present and unique",
    generateStatus: (data: any) => data.MissingItems || data.DuplicatedItems ? PreAuditReportItemStatus.Fail : PreAuditReportItemStatus.Pass,
    section: PreAuditReportSection.WebsiteBuild
  }],
  "Headings": [{
    title: "Headings",
    generateDisplayData: (data: any) => 
      {
        return `${data.HeadingsCount - data.BadHeadingsCount}/${data.HeadingsCount} headings optimized`;
      },
    generateStatus: (data: any) =>
      { 
        let percentBadHeadings = data.HeadingsCount === 0 ? 1 : data.BadHeadingsCount / data.HeadingsCount;
        if (percentBadHeadings > 0 && percentBadHeadings < 0.5) {
          return PreAuditReportItemStatus.Warning;
        } else if (percentBadHeadings >= 0.5) {
          return PreAuditReportItemStatus.Fail;
        } else if (percentBadHeadings === 0) {
          return PreAuditReportItemStatus.Pass;
        }
      },
    section: PreAuditReportSection.WebsiteBuild
  }],
  "BrokenLinks": [{
    title: "Broken links",
    generateDisplayData: (data: any) => `${data.LinksBrokenCount}/${data.LinksAnalysedCount} links broken`,
    generateStatus: (data: any) => data.LinksBrokenCount === 0 ? PreAuditReportItemStatus.Pass : PreAuditReportItemStatus.Fail,
    section: PreAuditReportSection.WebsiteBuild
  }],
  "ServerBehaviour": [{
    title: "SSL Encryption",
    generateDisplayData: (data: any) => data.HasSsl ? "SSL Encryption is enabled" : "SSL Encryption is not enabled",
    generateStatus: (data: any) => data.HasSsl ? PreAuditReportItemStatus.Pass : PreAuditReportItemStatus.Fail,
    section: PreAuditReportSection.WebsiteBuild
  }],
  "Sitemap": [{
    title: "Sitemap",
    generateDisplayData: (data: any) => !data.HasSitemap ? "No Sitemap found" : data.SitemapIssues ? "Sitemap issues" : "Valid Sitemap",
    generateStatus: (data: any) => !data.HasSitemap ? PreAuditReportItemStatus.Fail : data.SitemapIssues ? PreAuditReportItemStatus.Warning : PreAuditReportItemStatus.Pass,
    section: PreAuditReportSection.WebsiteBuild
  }]
}

export interface IPreAuditReportItemDisplayConfig {
  title: string;
  generateDisplayData: (data: string) => string;
  generateStatus: (data: string) => PreAuditReportItemStatus;
  section: PreAuditReportSection;
}

export enum PreAuditReportItemStatus {
  Fail = 0,
  Pass = 1, 
  Info = 2,
  Warning = 3
}

export interface PreAuditReport {
  ReportData: any
}