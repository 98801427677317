import { Component, } from '@angular/core';
import { QaTaskTypeConfigurationService } from '../../../services/qa-task-type-configuration.service';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { SessionStorageService } from '../../../services/session-storage.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
@Component({
  selector: 'app-components-manager-configureqatasks',
  templateUrl: './ConfigureQaTasks.component.html'
})
export class ConfigureQaTasksComponent {
  qaTaskConfigForm = new UntypedFormGroup({
    gradingPercentage: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(1),
      this.precisionValidator(2)
    ]),
    newUserMonths: new UntypedFormControl('', [
      Validators.required
    ]),
    newUserGradingPercentage: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(1),
      this.precisionValidator(2)
    ]),
    targetAverageGrade: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(100),
      this.precisionValidator(0)
    ]),
    underperformingGradingPercentage: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(1),
      this.precisionValidator(2)
    ])
  });

  isLoading: boolean = false;
  qaTaskTypeConfigurations: NamedConfiguration[] = [];
  selectedConfiguration: NamedConfiguration;
  useNewUserPercentage: boolean;
  useUnderperformingPercentage: boolean;

  constructor(
    private qaTaskTypeConfigurationService: QaTaskTypeConfigurationService,
    private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    this.isLoading = true;

    forkJoin([
      this.qaTaskTypeConfigurationService.get(),
      this.sessionStorageService.getStaticData()
    ])
    .pipe(finalize(() => this.isLoading = false))
    .subscribe(([qaTaskConfigs, staticData]) => {
      this.qaTaskTypeConfigurations = qaTaskConfigs.map(x => {
        return {
          Name: staticData.TaskTypes.find(y => y.TaskTypeEnum === x.QaTaskTypeEnum).Name,
          ...x
        }
      });
      this.updateForm(this.qaTaskTypeConfigurations[0]);
    });
  }

  updateForm(config: NamedConfiguration) {
    this.updateNewUsersControls(config.NewUserMonths !== null && config.NewUserGradingPercentage !== null);
    this.updateUnderperformingControls(config.TargetAverageGrade !== null && config.UnderperformingGradingPercentage !== null);

    this.selectedConfiguration = config;
    this.qaTaskConfigForm.setValue(
      {
        gradingPercentage: config.GradingPercentage,
        newUserMonths: config.NewUserMonths,
        newUserGradingPercentage: config.NewUserGradingPercentage,
        targetAverageGrade: config.TargetAverageGrade,
        underperformingGradingPercentage: config.UnderperformingGradingPercentage
      }
    );
  }

  updateNewUsersControls(enabled: boolean) {
    this.useNewUserPercentage = enabled;
    if (enabled) {
      this.qaTaskConfigForm.get('newUserMonths').enable();
      this.qaTaskConfigForm.get('newUserMonths').markAsTouched();
      this.qaTaskConfigForm.get('newUserGradingPercentage').enable();
      this.qaTaskConfigForm.get('newUserGradingPercentage').markAsTouched();
    } else {
      this.qaTaskConfigForm.get('newUserMonths').disable();
      this.qaTaskConfigForm.get('newUserGradingPercentage').disable();
    }
  }

  updateUnderperformingControls(enabled: boolean) {
    this.useUnderperformingPercentage = enabled;
    if (enabled) {
      this.qaTaskConfigForm.get('targetAverageGrade').enable();
      this.qaTaskConfigForm.get('targetAverageGrade').markAsTouched();
      this.qaTaskConfigForm.get('underperformingGradingPercentage').enable();
      this.qaTaskConfigForm.get('underperformingGradingPercentage').markAsTouched();
    } else {
      this.qaTaskConfigForm.get('targetAverageGrade').disable();
      this.qaTaskConfigForm.get('underperformingGradingPercentage').disable();
    }
  }

  precisionValidator(precision: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value?.toString().split(".")[1]?.length > precision ? { wholePercentage: { value: control.value } } : null;
    };
  }

  save(): void {
    if (!this.qaTaskConfigForm.valid) {
      toastr.error('Please fix the errors on the page and try again.');
      return;
    }

    this.qaTaskConfigForm.value.gradingPercentage;

    this.selectedConfiguration.GradingPercentage = this.qaTaskConfigForm.value.gradingPercentage;
    this.selectedConfiguration.NewUserGradingPercentage = this.qaTaskConfigForm.value.newUserGradingPercentage;
    this.selectedConfiguration.NewUserMonths = this.qaTaskConfigForm.value.newUserMonths;
    this.selectedConfiguration.TargetAverageGrade = this.qaTaskConfigForm.value.targetAverageGrade;
    this.selectedConfiguration.UnderperformingGradingPercentage = this.qaTaskConfigForm.value.underperformingGradingPercentage;

    this.qaTaskTypeConfigurationService.save(this.selectedConfiguration).subscribe(_ => {
      toastr.success('Configuration successfully saved');
    }, _ => toastr.error('Could not save configuration'));
  }
}

interface NamedConfiguration extends Boo.Qa.QaTaskTypeConfiguration {
  Name: string;
}
