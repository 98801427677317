<div [loadingOverlay]="isLoading">
  <div class="card">
    <div class="card-header">
      <span>Task Details</span>
      <button (click)="cancel()" class="btn-close" title="Close"></button>
    </div>
    <div class="card-body">
      <ng-container *ngIf="task">
        <div class="row form-group">
          <div class="col-md-6">
            <ul class="list-group">
              <li class="list-group-item">
                <strong>Task Type</strong>
                <span class="float-end">{{taskTypeName}}</span>
              </li>
              <li class="list-group-item">
                <strong>Task Description</strong>
                <p [innerHTML]="taskTypeTips"></p>
              </li>
            </ul>
          </div>
          <ng-container *ngIf="task.TaskTypeEnum">
            <div class="col-md-6">
              <ul class="list-group">
                <li class="list-group-item">
                  <strong>Billable Minute</strong>
                  <span class="float-end">{{billableMinutes}}</span>
                </li>
                <li class="list-group-item">
                  <strong>Website URL</strong>
                  <span class="float-end">{{selectedUrl?.Url}}</span>
                  <div [hidden]="!selectedUrl || selectedUrl.StatusId !== websiteUrlStatuses_Active" class="alert alert-warning mt-3">The selected url is not enabled for link building.</div>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="isCustomOther">
          <div class="row form-group">
            <div class="col-md-12">
              <label class="control-label">Task Type Name</label>
              <p>{{customTaskTypeName}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="control-label">Action Name</label>
              <p>{{customName}}</p>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <label class="control-label">Billed Minutes</label>
              <p>{{customBillableMinutes}}</p>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <label class="control-label">Task Action URL</label>
              <p>{{taskActionUrl}}</p>
            </div>
          </div>
        </ng-container>
        <div class="card">
          <div class="card-header">
            Description <app-components-helptext [hidden]="!isOnsiteCopy" [key]="'AddEditCustomAction_OnsiteCopyInstructions'"></app-components-helptext>
          </div>
          <div class="card-body">{{customDescription}}</div>
        </div>
        <ng-container *ngIf="showNotifyCSRWhenComplete">
          <div class="card">
            <div class="card-body">
              <label class="control-label">Notify CSR When Complete</label>
              <p>{{notifyOnCompletion ? 'Yes' : 'No'}}</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isOnsiteCopy">
          <div class="card">
            <div class="card-header">Onsite Copy</div>
            <div class="card-body">
              <div class="col-md-9">
                <div class="row form-group">
                  <div class="col-md-12">
                    <label class="control-label">Where will this copy be published?</label>
                    <p>{{onsiteCopyOrderReason}}</p>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-md-12">
                    <ng-container>
                      <label class="control-label">Should we target the URL for linkBuild after the copy is published?</label>
                      <p>{{onsiteCopyLinkBuildAfterPublish ? 'Yes' : 'No'}}</p>
                    </ng-container>
                  </div>
                </div>
                <ng-container>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Page Type</label>
                        <p>{{onsiteCopyPageType}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <ng-container>
                        <div class="form-group">
                          <label class="control-label">Will Boostability implement this piece of copy?</label>
                          <p>{{onsiteCopyBoostToPublish ? 'Yes' : 'No'}}</p>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div [hidden]="!onsiteCopyBoostToPublish" class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Notes for Implementation Specialist</label>
                        <p>{{onsiteCopyImplementationInstructions}}</p>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="col-md-3">
                <ng-container *ngIf="selectedUrl">
                  <div class="card card-primary">
                    <div class="card-header">
                      Active Keywords
                    </div>
                    <div class="card-body">
                      <div>
                        <ul class="list-group">
                          <ng-container *ngFor="let $data of selectedUrl.Keywords">
                            <ng-container *ngTemplateOutlet="onsiteCopyKeywords;context:{$context:$data}"></ng-container>
                          </ng-container>
                        </ul>
                        <ng-template #onsiteCopyKeywords let-$context="$context">
                          <ng-container *ngIf="!($context.Area)">
                            <li class="list-group-item">
                              <span>{{$context.Keyword.Name}}</span>
                            </li>
                          </ng-container>
                          <ng-container *ngIf="$context.Area">
                            <ng-container *ngIf="$context.IsAreaFirst">
                              <li class="list-group-item">
                                <span>{{$context.Area.Name}}</span>
                                &nbsp;
                                <span>{{$context.Keyword.Name}}</span>
                                <span style="margin-top: 0" class="help-block">
                                  <small>{{$context.Area.Name ? 'Separate location displayed first' : 'No Location'}}</small>
                                </span>
                              </li>
                            </ng-container>
                            <ng-container *ngIf="!($context.IsAreaFirst)">
                              <li class="list-group-item">
                                <span>{{$context.Keyword.Name}}</span>
                                &nbsp;
                                <span>{{$context.Area.Name}}</span>
                                <span style="margin-top: 0" class="help-block">
                                  <small>{{$context.Area.Name ? 'Separate location displayed last' : 'No Location'}}</small>
                                </span>
                              </li>
                            </ng-container>
                          </ng-container>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isOnsiteRecommendationImplementation">
          <div class="card">
            <div class="card-header">
              Onsite Implementation Note To Reviewer
            </div>
            <div class="card-body">
              {{onsiteImplementationNoteToReviewer}}
            </div>
          </div>
        </ng-container>
        <div class="row" style="margin-bottom: 4px;">
          <div class="col-md-12">
            <compose Selector="app-components-specialist-taskfiles" [ActivateOptions]="{
                    taskId: task.TaskId,
                    allowUpload: false,
                    useSmallTitle: true }"></compose>
          </div>
        </div>
      </ng-container>

      <div class="card card-body bg-light d-block text-end mt-5">
        <button (click)="cancel()" class="btn btn-danger">
          <i class="fas fa-times"></i>
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
