import { Component } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { LaunchpadThemeService, themes } from '../../../services/launchpad-theme.service';
import { CustomerPriorityService } from '../../../services/customer-priority.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-customerservice-customerpriorityassignment',
  templateUrl: './CustomerPriorityAssignment.component.html',
  styleUrls: ['./CustomerPriorityAssignment.component.scss', '../../../shared/action-button.scss']
})
export class CustomerPriorityAssignmentComponent implements IActivatable {
  public theme: themes;
  public themes = themes;
  public icon1: string;
  public icon2: string;
  public customerButtonCss: string;
  public currentAssignment: KnockoutObservable<Boo.Objects.CustomerPriorityAssignment>;
  private partnerUser: Boo.Objects.PartnerUser;
  private user: Boo.Objects.User;

  constructor(
    private themeService: LaunchpadThemeService,
    private customerPriorityService: CustomerPriorityService) { }

  public activate(): JQueryPromise<any> {
    this.currentAssignment = ko.observable();
    this.theme = this.themeService.getTheme();
    this.customerButtonCss = this.themeService.helpNextButtonStyles[this.theme];
    this.icon1 = this.themeService.getHelpNextIcon1();
    this.icon2 = this.themeService.getHelpNextIcon2();
    return this.getCurrentAssignment();
  }

  public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner): boolean {
    this.user = user;
    this.partnerUser = _.find(user.PartnerUsers, (partnerUser: Boo.Objects.PartnerUser) => {
      return partnerUser.UserLevelId === Boo.Objects.Enums.UserLevelEnum.CustomerService && partnerUser.PartnerId === partner.PartnerId;
    });

    return !!this.partnerUser;
  }

  public getNewAssignment(): JQueryPromise<void> {
    return Utils.wrapDfd(this.customerPriorityService.getNewAssignment(this.partnerUser.PartnerUserId))
      .then((customerId: number) => {
        if (customerId > 0) {
          this.openCustomer(customerId);
        } else {
          this.currentAssignment(undefined);
          toastr.warning('No more customers available.');
        }
      });
  }

  public getCurrentAssignment(): JQueryPromise<void> {
    return Utils.wrapDfd(this.customerPriorityService.getCurrentAssignment(this.user.UserId))
      .then((assignment: Boo.Objects.CustomerPriorityAssignment) => {
        this.currentAssignment(assignment);
      });
  }

  public endAssignment(): JQueryPromise<void> {
    return Utils.wrapDfd(this.customerPriorityService.endAssignment(this.currentAssignment().CustomerId, this.currentAssignment().UserId))
      .then(() => {
        return this.getCurrentAssignment();
      });
  }

  public openCustomer(customerId: number): JQueryPromise<void> {
    return PriorityViewModel.showManageCustomer(customerId)
      .always(() => {
        return this.getCurrentAssignment();
      });
  }

  public toggleEasterEgg(): void {
    this.themeService.toggleEasterEgg();
    this.icon1 = this.themeService.getHelpNextIcon1();
    this.icon2 = this.themeService.getHelpNextIcon2();
  }
}
