<div [loadingOverlay]="isLoading()" class="card">
	<div class="card-header">
		Assign Ticket Types
	</div>
	<div class="card-body">
		<div style="min-height: 200px;">
			<p>This screen allows you to assign selected Ticket Types to members of specific Teams.  Please be aware that this action cannot be undone.</p>
			<div class="row">
				<div class="col-md-6">
					<div class="form-horizontal">
						<div class="form-group">
							<label class="col-md-2 control-label">Teams:</label>
							<div class="col-md-10">
								<select [ngModel]="selectedTeams()" (ngModelChange)="selectedTeams($event)" [ngModelOptions]="{ standalone: true }" select2 class="form-control" multiple="multiple">
									<option *ngFor="let option of teams()" [ngValue]="option.TeamId">{{ option.Name }}</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-horizontal">
						<div class="form-group">
							<label class="col-md-3 control-label">Ticket Types:</label>
							<div class="col-md-9">
								<select [ngModel]="selectedTicketTypes()" (ngModelChange)="selectedTicketTypes($event)" [ngModelOptions]="{ standalone: true }" select2 class="form-control" multiple="multiple">
									<option *ngFor="let option of ticketTypes()" [ngValue]="option.TicketTypeId">{{ option.Name }}</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card card-body bg-light d-block text-end">
			<button (click)="assignTicketTypes()" type="button" class="btn btn-primary">Assign Tickets Types</button>
		</div>
	</div>
</div>
