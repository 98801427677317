<div [loadingOverlay]="isLoading">
	<div [hidden]="!contentAudit" class="row">
		<div class="col-md-12">
			<div class="card card-danger">
				<div class="card-header">
					Duplicate Content Warning
				</div>
				<div class="card-body">
					<table [hidden]="!showPlagiarismTable" class="table table-bordered table-sm">
						<thead>
							<tr>
								<th>Url</th>
								<th>Percentage</th>
								<th>Matched Text</th>
							</tr>
						</thead>
						<tbody>
							<tr [hidden]="!(contentAudit && contentAudit.Url1)">
								<ng-container *ngIf="contentAudit">
									<td>
										<a [externalLink]="{ url: contentAudit.Url1, text:contentAudit.Url1}"></a>
									</td>
									<td class="text-danger">{{(contentAudit.Url1Percentage * 100) + '%'}}</td>
									<td>
										<span [ngbPopover]="contentAudit.Url1MatchedText" popoverTitle="Matched Text" placement="right auto" triggers="mouseenter:mouseleave">
											<i class="far fa-file-alt"></i>
											(Hover for Matched Text)
										</span>
									</td>
								</ng-container>
							</tr>
							<tr [hidden]="!(contentAudit && contentAudit.Url2)">
								<ng-container *ngIf="contentAudit">
									<td>
										<a [externalLink]="{ url: contentAudit.Url2, text:contentAudit.Url2}"></a>
									</td>
									<td class="text-danger">{{(contentAudit.Url2Percentage * 100) + '%'}}</td>
									<td>
										<span [ngbPopover]="contentAudit.Url2MatchedText" popoverTitle="Matched Text" placement="right auto" triggers="mouseenter:mouseleave">
											<i class="far fa-file-alt"></i>
											(Hover for Matched Text)
										</span>
									</td>
								</ng-container>
							</tr>
							<tr [hidden]="!(contentAudit && contentAudit.Url3)">
								<ng-container *ngIf="contentAudit">
									<td>
										<a [externalLink]="{ url: contentAudit.Url3, text:contentAudit.Url3}"></a>
									</td>
									<td class="text-danger">{{(contentAudit.Url3Percentage * 100) + '%'}}</td>
									<td>
										<span [ngbPopover]="contentAudit.Url3MatchedText" popoverTitle="Matched Text" placement="right auto" triggers="mouseenter:mouseleave">
											<i class="far fa-file-alt"></i>
											(Hover for Matched Text)
										</span>
									</td>
								</ng-container>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-7">
			<form role="form">
				<div >
					<ng-container *ngIf="currentAction()">
						<div class="card card-info">
							<div class="card-header">
								<a data-toggle="collapse" href="#previousTasks">
									<h4>Previous Articles</h4>
								</a>
							</div>
							<div id="previousTasks" class="collapse show">
								<div class="card-body">
									<div class="row">
										<div class="col-md-12">
                      <app-table [items]="otherContentPosts" [pageSize]="5" [exportFilename]="'content-posts'">
                        <ng-template #tableHeader>
                          <tr>
                            <th export sort desc field="TaskDate">Date</th>
                            <th export sort field="Topic">Topic & Instructions</th>
                            <th export field="Instructions">Instructions</th>
                            <th export sort field="TaskStatus">Status</th>
                          </tr>
                        </ng-template>
                        <ng-template #tableBody let-post>
                          <tr>
                            <td style="width: 20%;">{{post.TaskDate | dateText }}</td>
                            <td style="width: 60%;">
                              <a [attr.data-target]="'#instr' + post.TaskId.toString()" data-toggle="collapse" style="cursor: pointer;">
                                <span>{{post.Topic}}</span>
                              </a>
                              <div [attr.id]="'instr' + post.TaskId.toString()" class="card-collapse collapse">
                                <br/>
                                <span>{{post.Instructions}}</span>
                              </div>
                            </td>
                            <td></td>
                            <td style="width: 20%;">{{post.TaskStatus}}</td>
                          </tr>
                        </ng-template>
                      </app-table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<br/>
						<div class="card card-info">
							<div class="card-header">
								<a data-toggle="collapse" href="#pastImages">
									<h4>8 Month Image History</h4>
								</a>
							</div>
							<div id="pastImages" class="collapse show">
								<div class="card-body">
									<div class="row" style="margin: 0px 10px">
										<ng-container *ngFor="let $data of pastImageUrls">
												<img src={{$data}} style="max-height: 120px; max-width: 24%; object-fit: cover; margin: 2px"/>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
						<div class="card card-info">
							<div class="card-header">
								<a data-toggle="collapse" href="#workspace">
									<h4>Workspace</h4>
								</a>
							</div>
							<div id="workspace"class="collapse show">
								<div class="card-body">
									<div class="form-group">
										<strong>Page Title</strong>
										<div>{{onsiteRecommendationsPageTitle}}</div>
									</div>
									<div class="form-group">
										<strong>Meta Description</strong>
										<div>{{onsiteRecommendationsMetaDescription}}</div>
									</div>
									<div class="form-group">
										<strong>Title / H1</strong>
										<div>{{contentTitle}}</div>
									</div>
									<div class="form-group">
										<strong>H2</strong>
										<div>{{contentTitle2}}</div>
									</div>
									<div class="form-group" [formValidation]="content">
										<strong>Content</strong>
										<quill-editor [(formControl)]="content" [modules]="quillModules"  (onEditorCreated)="contentEditorCreated($event)"></quill-editor>
									</div>
									<div class="form-group">
										<img [attr.src]="imageUrl" class="img-fluid"/>
									</div>
									<div class="form-group" [formValidation]="editorFeedback">
										<strong>Editor Feedback</strong>
										<quill-editor [(formControl)]="editorFeedback"></quill-editor>
									</div>
									<div class="form-group">
										<strong>Note to QA</strong>
										<input [(ngModel)]="noteToQa" type="text" class="form-control" name="noteToQa" autocomplete="off"/>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</form>
		</div>
		<div class="col-md-5">
			<div class="card card-info">
				<div class="card-header">
					<h4>Additional Information</h4>
				</div>
				<div class="card-body">
					<ul class="nav nav-tabs">
						<li class="nav-item">
							<a href="#info" class="nav-link active" data-toggle="tab">
								<i class="fa fa-info-circle"></i>
								Info
							</a>
						</li>
						<li class="nav-item">
							<a href="#notes" class="nav-link" data-toggle="tab">
								<i class="fa fa-comments"></i>
								Comments
								<span style="margin-left:15px;" class="badge bg-secondary">{{noteCount}}</span>
							</a>
						</li>
						<li class="nav-item">
							<a href="#files" class="nav-link" data-toggle="tab">
								<i class="fa fa-file"></i>
								Files
								<span style="margin-left:15px;" class="badge bg-secondary">{{fileCount}}</span>
							</a>
						</li>
					</ul>
					<div class="tab-content">
						<div class="tab-pane active" id="info">
							<div class="row">
								<div class="col-md-12">
									<ng-container *ngIf="currentAction()">
										<div class="card">
											<div class="card-header">
												<a data-toggle="collapse" href="#customer">
													<h4>
														Customer Information
													</h4>
												</a>
											</div>
											<div id="customer" class="collapse show">
												<div class="card-body">
													<div class="row">
														<div class="col-md-12">
															<ul class="list-group">
																<li class="list-group-item">
																	<strong>Customer</strong>
																	<span (click)="window.launchpad.utils.selectText(undefined, $event)" class="float-end">{{currentAction().Customer.Name}}</span>
																</li>
																<li class="list-group-item">
																	<strong>Customer ID</strong>
																	<span (click)="window.launchpad.utils.selectText(undefined, $event)" class="float-end" name="customerId">{{currentAction().Customer.CustomerId}}</span>
																</li>
																<ng-container *ngIf="currentAction().Customer.Vertical">
																	<li class="list-group-item">
																		<ng-container *ngIf="currentAction().Customer.Vertical">
																			<strong>Vertical</strong>
																			<span (click)="window.launchpad.utils.selectText(undefined, $event)" class="float-end">{{currentAction().Customer.Vertical.Name}}</span>
																		</ng-container>
																	</li>
																</ng-container>
																<li class="list-group-item">
																	<ng-container *ngIf="currentAction().Task">
																		<strong>Task ID</strong>
																		<span (click)="window.launchpad.utils.selectText(undefined, $event)" class="float-end" name="taskId">{{currentAction().Task.TaskId}}</span>
																	</ng-container>
																</li>
																<li class="list-group-item">
																	<strong>Partner</strong>
																	<span class="float-end">{{currentAction().Customer.Partner.Name}}</span>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
										<ng-container *ngIf="currentAction().Customer.SeoContentSurvey">
											<div class="card">
												<ng-container *ngIf="currentAction().Customer.SeoContentSurvey">
													<div class="card-header">
														<a data-toggle="collapse" href="#customerDetails">
															<h4>Customer Details</h4>
														</a>
													</div>
													<div id="customerDetails" class="collapse show">
														<div class="card-body">
															<div class="row">
																<div class="col-md-12">
																	<ul class="list-group">
																		<li class="list-group-item">
																			<strong>Business Overview</strong>
																			<div>{{currentAction().Customer.SeoContentSurvey.BusinessDescription}}</div>
																		</li>
																		<li class="list-group-item">
																			<strong>Restrictions</strong>
																			<div>{{currentAction().Customer.SeoContentSurvey.Limitations}}</div>
																		</li>
																		<li class="list-group-item">
																			<strong>Audience</strong>
																			<div>{{currentAction().Customer.SeoContentSurvey.Demographic}}</div>
																		</li>
																		<li class="list-group-item">
																			<strong>Value Propositions and Unique Selling Points</strong>
																			<div>{{currentAction().Customer.SeoContentSurvey.SellingPoints}}</div>
																		</li>
																		<li class="list-group-item" *ngIf="currentAction().Customer.SeoContentSurvey.BlogConsiderations">
																			<strong>Onsite Blog Restrictions and Considerations</strong>
																			<div (click)="window.launchpad.utils.selectText(undefined, $event)">{{currentAction().Customer.SeoContentSurvey.BlogConsiderations}}</div>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</ng-container>
											</div>
										</ng-container>
									</ng-container>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="notes">
							<div class="row">
								<div class="col-md-12">
									<ng-container *ngIf="!hasComments">
										<div class="alert alert-info">
											<strong>No Comments</strong>
											Comments are created when tasks are rejected.
										</div>
									</ng-container>
									<ul class="list-group">
										<ng-container *ngFor="let $data of notes">
											<li class="list-group-item">
												<div class="row">
													<div class="col-md-7">
														<div>
															<strong>{{$data.FromUserFullName}}</strong>
														</div>
														<small>
															to
															<span>{{$data.ToUserId}}</span>
														</small>
													</div>
													<div class="col-md-5 text-end">
														<small>{{$data.InsertedDate | dateText: 'M/DD/YYYY h:mm a' }}</small>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
														<div class="list-group-item-text">
															<div [innerHtml]="$data.Note" style="padding-top:15px; padding-bottom:15px;"></div>
														</div>
													</div>
												</div>
											</li>
										</ng-container>
									</ul>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="files">
							<div class="col-md-12">
                <app-table [items]="files" [exportFilename]="'onsite-blogging-revision-files'">
                  <ng-template #tableHeader>
                    <tr>
                      <th export sort field="Name">Name</th>
                      <th export sort field="Comment">Comment</th>
                      <th export sort field="InsertedDate">Upload Date</th>
                      <th export field="S3Url"></th>
                    </tr>
                  </ng-template>
                  <ng-template #tableBody let-file>
                    <tr>
											<td>{{file.Name}}</td>
											<td>
												<span [hidden]="!(file.Comment)" [ngbPopover]="file.Comment" popoverTitle="Comment" placement="right auto" triggers="mouseenter:mouseleave">
													<i class="far fa-file-alt"></i>
													(Hover for Comment)
												</span>
											</td>
											<td>{{file.InsertedDate | dateText }}</td>
											<td style="width:50px;">
												<a [externalLink]="{ url: file.S3Url, text:'View File'}" class="btn btn-primary"></a>
											</td>
										</tr>
                  </ng-template>
                </app-table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
