import { Component, AfterViewInit } from '@angular/core';
import permissions from 'app/models/Permissions';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { LinkLocationBlogDetailService } from '../../../services/link-location-blog-detail.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-specialist-blogsites',
  templateUrl: './BlogSites.component.html'
})
export class BlogSitesComponent implements IActivatable, AfterViewInit {
    amazonDeploymentStatuses: KnockoutObservableArray<{ Name: string, Id: Number }>;
    amazonDeploymentStatusId: KnockoutObservable<number>;
    blogs: Boo.Objects.BlogSitesBlog[] = [];
    blogUrl: KnockoutObservable<string>;
    countries: Boo.Objects.Country[];
    countryId: KnockoutObservable<number>;
    isLoading: KnockoutObservable<boolean>;
    languageId: KnockoutObservable<number>;
    languages: Boo.Objects.Language[];
    linkLocationId: KnockoutObservable<number | {}>;
    linkLocationStatus: KnockoutObservable<number>;
    searchCollapsed: KnockoutObservable<boolean>;
    verticalId: KnockoutObservable<number>;
    verticals: Boo.Objects.Vertical[];
    pageValidation: KnockoutObservable<{}>;

    constructor(
      private linkLocationBlogDetailService: LinkLocationBlogDetailService,
      private sessionStorageService: SessionStorageService) { }

    activate(params: any): JQueryPromise<void> {
        this.isLoading = ko.observable(true);
        this.searchCollapsed = ko.observable(false);
        this.blogUrl = ko.observable('');
        this.linkLocationId = ko.observable();
        this.linkLocationStatus = ko.observable(0);
        this.amazonDeploymentStatusId = ko.observable(0);
        this.countryId = ko.observable(0);
        this.languageId = ko.observable(0);
        this.verticalId = ko.observable(0);

        let blogDetailsStatuses = _.map(launchpad.config.AmazonDeploymentStatusEnum.names, (name: string) => {
            return { 'Name': name, 'Id': launchpad.config.AmazonDeploymentStatusEnum.ids[name.split(' ').join('')] as number };
        });

        this.amazonDeploymentStatuses = ko.observableArray(blogDetailsStatuses);

        return Utils.wrapDfd(this.sessionStorageService.getStaticData())
            .then((data: any) => {
                this.countries = data.Countries;
                this.languages = data.Languages;
                this.countries.unshift({ Name: 'All', CountryId: 0 } as Boo.Objects.Country);
                this.languages.unshift({ Name: 'All', LanguageId: 0, Abbreviation: '' } as Boo.Objects.Language);

                let sorter = new launchpad.utils.pSort();
                sorter.key = 'VerticalId';
                sorter.parentKey = 'ParentVerticalId';
                let sortedVerticals = sorter.sort(data.Verticals);
                sortedVerticals.unshift({ Name: 'All', VerticalId: 0 });

                this.verticals = sortedVerticals;
                this.initValidation();
            }).always(() => {
                // Hard coded lists bind after the activate. This is a temp solution to stop showing the error message.
                setTimeout(() => { this.pageValidation.errors.showAllMessages(false); }, 100);
                this.isLoading(false);
            });
    }

    ngAfterViewInit(): void {
        this.amazonDeploymentStatuses().unshift({ 'Name': 'All', 'Id': 0 });
    }

    canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        let hasAdminUser = _.any(user.PartnerUsers, (pu) => {
            return pu.UserLevelId === 1;
        });

        let hasCanManageBlogSitesPermission = launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanManageBlogSites, user);
        return hasAdminUser || hasCanManageBlogSitesPermission;
    }

    collapse(): void {
      this.searchCollapsed(!this.searchCollapsed());
      $('.collapse').collapse('toggle');
    }

    editBlog(blog: Boo.Objects.BlogSitesBlog): void {
        PriorityViewModel.show('app-components-specialist-blogsitedetails', { LinkLocationId: blog.LinkLocationId });
    }

    searchBlogs(): void {
        if (!this.pageValidation.isValid()) {
            this.pageValidation.errors.showAllMessages();
            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
            return;
        }

        this.isLoading(true);
        Utils.wrapDfd(this.linkLocationBlogDetailService.searchBlogs(this.blogUrl(), this.verticalId(), this.languageId(), this.countryId(), this.amazonDeploymentStatusId(), this.linkLocationStatus(), (this.linkLocationId() || 0) as number))
            .then((results: Boo.Objects.BlogSitesBlog[]) => {
                this.blogs = results;
            })
            .fail((msg: string) => {
                toastr.error(msg);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    getDownBlogs(): void {
        this.isLoading(true);
        Utils.wrapDfd(this.linkLocationBlogDetailService.downBlogs())
            .then((results: Boo.Objects.BlogSitesBlog[]) => {
                this.blogs = results;
            })
            .fail((msg: string) => {
                toastr.error(msg);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    private initValidation(): void {
        let somethingIsSelected = (): boolean => {
            let blogurl = this.blogUrl().trim();
            return !!blogurl
                || !!parseInt(this.linkLocationStatus().toString(), 10)
                || !!parseInt(this.amazonDeploymentStatusId().toString(), 10)
                || !!parseInt(this.countryId().toString(), 10)
                || !!parseInt(this.languageId().toString(), 10)
                || !!parseInt(this.verticalId().toString(), 10)
                || !!(this.linkLocationId() && parseInt(this.linkLocationId().toString(), 10));
        };

        let fields: KnockoutObservable<any>[] = [
            this.blogUrl,
            this.linkLocationStatus,
            this.amazonDeploymentStatusId,
            this.countryId,
            this.languageId,
            this.verticalId,
            this.linkLocationId
        ];


        let validatedObject: any = {};

        fields.forEach((x, index) => {
            x.extend({
                validation: {
                    validator: somethingIsSelected,
                    message: 'Please enter a URL, Link Location ID, or use a filter'
                }
            });

            validatedObject[index.toString()] = x;
        });

        this.pageValidation = ko.validatedObservable(validatedObject);
    }
}
