import { Component } from '@angular/core';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { reduceLength } from 'app/extensionmethods/StringExtensions';
import Utils from '../../../shared/utils';
import { PartnerFileService } from '../../../services/partner-file.service';

@Component({
	selector: 'app-components-manager-partnerfiles',
	templateUrl: './PartnerFiles.component.html'
})
export class PartnerFilesComponent implements IActivatable {
    files: Boo.Objects.PartnerFile[] = [];
    partnerId: number;
    maxNameDisplayLength: number;
    private user: Boo.Objects.User;

    constructor(private partnerFileService: PartnerFileService) { }

    activate(params: IPartnerFilesViewModelActivateParams): JQueryPromise<void> {
        this.partnerId = params.partnerId;
        this.maxNameDisplayLength = params.maxNameDisplayLength || 80;
        return this.loadFiles();
    }

    canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        this.user = user;
        return true;
    }

    loadFiles(): JQueryPromise<any> {
        return Utils.wrapDfd(this.partnerFileService.get(this.partnerId))
            .then((files: Boo.Objects.PartnerFile[]) => {
                files.forEach(file => {
                    file.Name = reduceLength(file.Name, this.maxNameDisplayLength);
                });
                this.files = files;
            })
            .fail((displayMessage: string) => {
                toastr.error(displayMessage);
            });
    }

    upload(): void {
        let file: Boo.Objects.PartnerFile = <Boo.Objects.PartnerFile>{
            PartnerId: this.partnerId,
            UploadedByUserId: this.user.UserId
        };

        let request = new PresignedUrlRequest();
        request.BucketName = launchpad.config.S3Buckets.s3staticfiles;
        request.Folder = 'Partnerfiles/' + file.PartnerId;
        request.UseUniqueFileName = true;

        PriorityViewModel.show('app-components-shared-uploadfile', { preSignedURLRequest: request, acceptAllFileTypes: true })
            .then((uploadResult: Boo.Objects.Amazon.PreSignedUrl) => {
                file.Name = uploadResult.FileName;
                file.S3Url = uploadResult.FullUrl;
                file.IsDeleted = false;
                Utils.wrapDfd(this.partnerFileService.save(file))
                    .then((PartnerFile: Boo.Objects.PartnerFile) => {
                        this.files.splice(0, 0, PartnerFile);
                    });
            });
    }

    delete(file: Boo.Objects.PartnerFile): void {
        file.IsDeleted = true;
        Utils.wrapDfd(this.partnerFileService.save(file))
            .then(() => {
                this.loadFiles();
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            });
    }
}

interface IPartnerFilesViewModelActivateParams {
    partnerId: number;
    maxNameDisplayLength?: number;
}
