import { Component, ElementRef, Input, OnChanges, AfterViewInit, ComponentFactoryResolver, ViewContainerRef, ViewChild, OnDestroy } from '@angular/core';
import { AddJobComponent } from '../../../components/admin/AddJob/AddJob.component';
import { SeoCustomerAccountComponent } from '../../../components/managecustomer/seo-customer-accounts/seo-customer-accounts.component';
import { ApplicationManagementComponent } from '../../../components/admin/ApplicationManagement/ApplicationManagement.component';
import { InternalTaskHistoryComponent } from '../../../components/admin/InternalTaskHistory/InternalTaskHistory.component';
import { JobListComponent } from '../../../components/admin/job-list/job-list.component';
import { JobsByTypeComponent } from '../../../components/admin/JobsByType/JobsByType.component';
import { PartnerUsersComponent } from '../../../components/admin/PartnerUsers/PartnerUsers.component';
import { TranslationsComponent } from '../../../components/admin/Translations/Translations.component';
import { UpdateJobsComponent } from '../../../components/admin/UpdateJobs/UpdateJobs.component';
import { VerticalComponent } from '../../../components/admin/Vertical/Vertical.component';
import { VerticalManagementComponent } from '../../../components/admin/VerticalManagement/VerticalManagement.component';
import { VerticalTranslationComponent } from '../../../components/admin/VerticalTranslation/VerticalTranslation.component';
import { ViewTaskWorkSpaceComponent } from '../../../components/specialist/ViewTaskWorkSpace/ViewTaskWorkSpace.component';
import { ViewTaskFeedbackComponent } from '../../../components/specialist/ViewTaskFeedback/ViewTaskFeedback.component';
import { StandardBacklinkFeedback } from '../../../components/specialist/ViewTaskFeedback/feedbackcomponents/standard-backlink-feedback/standard-backlink-feedback.component';
import { OnsiteRecommendationsFeedback } from '../../../components/specialist/ViewTaskFeedback/feedbackcomponents/onsite-recommendations-feedback/onsite-recommendations-feedback.component';
import { ChangeSpecialistScoreComponent } from '../../../components/specialist/change-specialist-score/change-specialist-score.component';
import { WorkflowsComponent } from '../../../components/admin/Workflows/Workflows.component';
import { ChangePasswordComponent } from '../../../components/system/ChangePassword/ChangePassword.component';
import { LoginComponent } from '../../../components/system/Login/Login.component';
import { LogoutComponent } from '../../../components/system/Logout/Logout.component';
import { ResetPasswordComponent } from '../../../components/system/ResetPassword/ResetPassword.component';
import { SwitchPartnerComponent } from '../../../components/system/SwitchPartner/SwitchPartner.component';
import { ValidationChecklistComponent } from '../../../components/shared/ValidationChecklist/ValidationChecklist.component';
import { CredentialsComponent } from '../../../components/shared/customer-account/credentials/credentials.component';
import { SiteCredentialsComponent } from '../../../components/shared/customer-account/site-credentials/site-credentials.component';
import { ContentAuditComponent } from '../../../components/shared/content-audit/content-audit.component';
import { CsrsComponent } from '../../../components/customerservice/Csrs/Csrs.component';
import { ManagerToolsComponent } from '../../../components/customerservice/ManagerTools/ManagerTools.component';
import { AssignedTicketsComponent } from '../../../components/customerservice/AssignedTickets/AssignedTickets.component';
import { AssignTicketTypesComponent } from '../../../components/customerservice/AssignTicketTypes/AssignTicketTypes.component';
import { CreateTicketBatchComponent } from '../../../components/customerservice/CreateTicketBatch/CreateTicketBatch.component';
import { TimeZoneDateTimeComponent } from '../../../components/shared/time-zone-date-time/time-zone-date-time.component';
import { CustomerPriorityAssignmentComponent } from '../../../components/customerservice/CustomerPriorityAssignment/CustomerPriorityAssignment.component';
import { CustomerSearchComponent } from '../../../components/customerservice/CustomerSearch/CustomerSearch.component';
import { EditTicketComponent } from '../../../components/customerservice/EditTicket/EditTicket.component';
import { HelpNextCustomerComponent } from '../../../components/customerservice/HelpNextCustomer/HelpNextCustomer.component';
import { ManageCustomerComponent as ManageCustomerComponent1 } from '../../../components/customerservice/ManageCustomer/ManageCustomer.component';
import { ManageTicketsComponent } from '../../../components/customerservice/ManageTickets/ManageTickets.component';
import { PerformanceMetricsComponent } from '../../../components/customerservice/PerformanceMetrics/PerformanceMetrics.component';
import { CustomReportsComponent as CustomReportsComponent1 } from '../../../components/shared/CustomReports/CustomReports.component';
import { AddComponent } from '../../../components/guarantees/Add/Add.component';
import { ListComponent as ListComponent1 } from '../../../components/guarantees/List/List.component';
import { MoneyBackGuaranteeComponent } from '../../../components/guarantees/MoneyBackGuarantee/MoneyBackGuarantee.component';
import { SingleComponent } from '../../../components/guarantees/Single/Single.component';
import { LanguageQuestionGroupComponent } from '../../../components/shared/LanguageQuestionGroup/LanguageQuestionGroup.component';
import { AddCustomReportComponent } from '../../../components/managecustomer/AddCustomReport/AddCustomReport.component';
import { WorkRequestEditComponent } from '../../../components/managecustomer/WorkRequestEdit/WorkRequestEdit.component';
import { AddNoteComponent } from '../../../components/managecustomer/AddNote/AddNote.component';
import { AllocateWorkComponent } from '../../../components/managecustomer/AllocateWork/AllocateWork.component';
import { BasicInformationComponent } from '../../../components/managecustomer/BasicInformation/BasicInformation.component';
import { BlogPostsComponent } from '../../../components/managecustomer/BlogPosts/BlogPosts.component';
import { CompletedTasksComponent } from '../../../components/managecustomer/CompletedTasks/CompletedTasks.component';
import { ConnectedUsersComponent } from '../../../components/managecustomer/ConnectedUsers/ConnectedUsers.component';
import { ConnectedUsersSidePanelComponent } from '../../../components/managecustomer/ConnectedUsersSidePanel/ConnectedUsersSidePanel.component';
import { CustomerAccountGroupComponent } from '../customer-account-group/customer-account-group.component';
import { CustomerAccountGroupModalComponent } from '../../../components/managecustomer/customer-account-group-modal/customer-account-group-modal.component';
import { WebsiteAccessModalComponent } from '../../../components/managecustomer/WebsiteAccess/modal/website-access-modal.component';
import { CustomerContactPreferencesComponent } from '../../../components/managecustomer/CustomerContactPreferences/CustomerContactPreferences.component';
import { CustomerWorkHistoryComponent } from '../../../components/managecustomer/CustomerWorkHistory/CustomerWorkHistory.component';
import { CustomerInformationComponent as CustomerInformationComponent1 } from '../../../components/managecustomer/CustomerInformation/CustomerInformation.component';
import { CustomerInformationLimitedComponent } from '../../../components/managecustomer/CustomerInformationLimited/CustomerInformationLimited.component';
import { CustomerSynopsisComponent as CustomerSynopsisComponent1 } from '../../../components/managecustomer/CustomerSynopsis/CustomerSynopsis.component';
import { CustomerTodoHistoryComponent } from '../../../components/managecustomer/CustomerTodoHistory/CustomerTodoHistory.component';
import { CustomerUserComponent } from '../../../components/managecustomer/CustomerUser/CustomerUser.component';
import { CustomerUserPhoneNumberComponent } from '../../../components/managecustomer/CustomerUserPhoneNumber/CustomerUserPhoneNumber.component';
import { CustomerUsersComponent } from '../../../components/managecustomer/CustomerUsers/CustomerUsers.component';
import { EditBlogPostComponent } from '../../../components/managecustomer/EditBlogPost/EditBlogPost.component';
import { EditMerchantFieldsComponent } from '../../../components/managecustomer/EditMerchantFields/EditMerchantFields.component';
import { LoginsComponent } from '../../../components/managecustomer/logins/logins.component';
import { FilesComponent as FilesComponent1 } from '../../../components/managecustomer/Files/Files.component';
import { FloatingCustomerNoteComponent } from '../../../components/managecustomer/FloatingCustomerNote/FloatingCustomerNote.component';
import { FloatingCustomerTodoListComponent } from '../../../components/managecustomer/FloatingCustomerTodoList/FloatingCustomerTodoList.component';
import { ManageCustomerComponent as ManageCustomerComponent2 } from '../../../components/managecustomer/ManageCustomer.component';
import { BloggingDetailsComponent } from '../../../components/managecustomer/SeoContentSurvey/BloggingDetails/BloggingDetails.component';
import { SurveyComponent } from '../../../components/managecustomer/SeoContentSurvey/Survey/Survey.component';
import { SeoContentSurveyComponent as SeoContentSurveyComponent1 } from '../../../components/managecustomer/SeoContentSurvey/SeoContentSurvey.component';
import { MerchantFieldsComponent as MerchantFieldsComponent1 } from '../../../components/managecustomer/MerchantFields/MerchantFields.component';
import { MerchantFieldsEditComponent } from '../../../components/managecustomer/MerchantFieldsEdit/MerchantFieldsEdit.component';
import { NewTicketComponent } from '../../../components/managecustomer/NewTicket/NewTicket.component';
import { NotesComponent } from '../../../components/managecustomer/notes/notes.component';
import { NotesAndTicketsComponent } from '../../../components/managecustomer/notes-and-tickets/notes-and-tickets.component';
import { OnsiteCopyCustomActionComponent } from '../../../components/managecustomer/OnsiteCopyCustomAction/OnsiteCopyCustomAction.component';
import { WorkflowTaskHistoryComponent } from '../../../components/managecustomer/WorkflowTaskHistory/WorkflowTaskHistory.component';
import { EditImplementationInstructions } from '../../../components/managecustomer/EditImplementationInstructions/EditImplementationInstructions.component';
import { EditRejectionInstructions } from '../../../components/managecustomer/EditRejectionInstructions/EditRejectionInstructions.component';
import { OpenTicketsComponent } from '../../../components/managecustomer/OpenTickets/OpenTickets.component';
import { ReviseNoteComponent } from '../../../components/managecustomer/ReviseNote/ReviseNote.component';
import { TicketGroupComponent } from '../../../components/managecustomer/TicketGroup/TicketGroup.component';
import { UploadCustomerFileComponent } from '../../../components/managecustomer/UploadCustomerFile/UploadCustomerFile.component';
import { AddPartnerPricingToolPriceLevelComponent } from '../../../components/manager/AddPartnerPricingToolPriceLevel/AddPartnerPricingToolPriceLevel.component';
import { PreviewPartnerPricingToolPriceLevel } from '../../../components/manager/preview-partner-pricing-tool-price-level/preview-partner-pricing-tool-price-level.component';
import { CustomerAccountDefaultGroupComponent } from '../../../components/manager/CustomerAccountDefaultGroup/CustomerAccountDefaultGroup.component';
import { CustomerPriorityAssignmentsComponent } from '../../../components/customerservice/CustomerPriorityAssignments/CustomerPriorityAssignments.component';
import { EditUserComponent } from '../../../components/manager/EditUser/EditUser.component';
import { TaskImportExportComponent } from '../../../components/manager/TaskImportExport/TaskImportExport.component';
import { OrganizationalTeamComponent } from '../../../components/manager/OrganizationalTeam/OrganizationalTeam.component';
import { OrganizationalTeamsComponent } from '../../../components/manager/OrganizationalTeams/OrganizationalTeams.component';
import { CloseWorkflowComponent } from '../../../components/managecustomer/CloseWorkflow/CloseWorkflow.component';
import { LeadSourceComponent } from '../../../components/manager/LeadSource/LeadSource.component';
import { LeadSourcesComponent } from '../../../components/manager/LeadSources/LeadSources.component';
import { LinkLocationComponent } from '../../../components/manager/LinkLocation/LinkLocation.component';
import { LinkTypeActivationComponent } from '../../../components/manager/LinkTypeActivation/LinkTypeActivation.component';
import { ManagePartnerPricingToolComponent } from '../../../components/manager/ManagePartnerPricingTool/ManagePartnerPricingTool.component';
import { ManagePartnersComponent } from '../../../components/manager/ManagePartners/ManagePartners.component';
import { ManageReportingComponent } from '../../../components/manager/ManageReporting/ManageReporting.component';
import { ManageUsersComponent } from '../../../components/manager/ManageUsers/ManageUsers.component';
import { MerchantFieldsComponent as MerchantFieldsComponent2 } from '../../../components/manager/MerchantFields/MerchantFields.component';
import { PremiumBacklinkComponent } from '../../../components/manager/PremiumBacklink/PremiumBacklink.component';
import { PartnerComponent } from '../../../components/manager/Partner/Partner.component';
import { PartnerFilesComponent } from '../../../components/manager/PartnerFiles/PartnerFiles.component';
import { PartnerPricingToolDetailComponent } from '../../../components/manager/PartnerPricingToolDetail/PartnerPricingToolDetail.component';
import { PartnerPricingToolFeatureListComponent } from '../../../components/manager/PartnerPricingToolFeatureList/PartnerPricingToolFeatureList.component';
import { PartnerUserComponent } from '../../../components/manager/PartnerUser/PartnerUser.component';
import { PendingWorkComponent } from '../../../components/managecustomer/PendingWork/PendingWork.component';
import { ProTipComponent } from '../../../components/manager/ProTip/ProTip.component';
import { ProTipsComponent } from '../../../components/manager/ProTips/ProTips.component';
import { RegistrationPhoneNumbersComponent } from '../../../components/manager/RegistrationPhoneNumbers/RegistrationPhoneNumbers.component';
import { ReportingCustomizationsComponent } from '../../../components/manager/ReportingCustomizations/ReportingCustomizations.component';
import { RepublishPostComponent } from '../../../components/manager/RepublishPost/RepublishPost.component';
import { BlacklistDomainsComponent } from '../../../components/manager/BlacklistDomains/BlacklistDomains.component';
import { ConfigurePartnerPricingToolComponent } from '../../../components/manager/ConfigurePartnerPricingTool/ConfigurePartnerPricingTool.component';
import { ConfigureQaTasksComponent } from '../../../components/manager/ConfigureQaTasks/ConfigureQaTasks.component';
import { TransferAccountsComponent } from '../../../components/manager/TransferAccounts/TransferAccounts.component';
import { TaskStewardshipComponent } from '../../../components/manager/TaskStewardship/TaskStewardship.component';
import { TaskStewardshipsComponent } from '../../../components/manager/TaskStewardships/TaskStewardships.component';
import { PartnerEmailsComponent } from '../../../components/manager/partner-emails/partner-emails.component';
import { NotificationsComponent } from '../../../components/manager/notifications/notifications.component';
import { AdminComponent as AdminComponent1 } from '../../../components/navigation/Admin/Admin.component';
import { CustomerServiceComponent as CustomerServiceComponent1 } from '../../../components/navigation/CustomerService/CustomerService.component';
import { ManagerComponent as ManagerComponent1 } from '../../../components/navigation/Manager/Manager.component';
import { SalesComponent as SalesComponent1 } from '../../../components/navigation/Sales/Sales.component';
import { SpecialistComponent as SpecialistComponent1 } from '../../../components/navigation/Specialist/Specialist.component';
import { OptionToggleComponent } from '../../../components/shared/OptionToggle/OptionToggle.component';
import { PagerComponent } from '../../../components/shared/Pager/Pager.component';
import { RecentOrdersComponent } from '../../../components/shared/RecentOrders/RecentOrders.component';
import { AddCustomerComponent } from '../../../components/sales/AddCustomer/AddCustomer.component';
import { SearchCustomersComponent } from '../../../components/sales/SearchCustomers/SearchCustomers.component';
import { UploadFileComponent } from '../../../components/shared/UploadFile/UploadFile.component';
import { BusinessDirectoriesComponent } from '../../../components/specialist/actioncomponents/BusinessDirectories/BusinessDirectories.component';
import { ChooseLocalProfileCategoryComponent } from '../../../components/specialist/actioncomponents/ChooseLocalProfileCategory/ChooseLocalProfileCategory.component';
import { ClassifiedBusinessListingsComponent } from '../../../components/specialist/actioncomponents/ClassifiedBusinessListings/ClassifiedBusinessListings.component';
import { CommentsComponent } from '../../../components/specialist/actioncomponents/Comments/Comments.component';
import { CustomManualComponent } from '../../../components/specialist/actioncomponents/CustomManual/CustomManual.component';
import { OnsiteRecommendationComponent as OnsiteRecommendationComponent1 } from '../../../components/specialist/actioncomponents/components/OnsiteRecommendation/OnsiteRecommendation.component';
import { ContentReviewComponent } from '../../../components/specialist/actioncomponents/ContentReview/ContentReview.component';
import { StandardBacklinkComponent } from '../../../components/specialist/actioncomponents/StandardBacklink/standard-backlink.component';
import { CustomerInformationComponent as CustomerInformationComponent2 } from '../../../components/specialist/actioncomponents/CustomerInformation/CustomerInformation.component';
import { SeoContentSurveyComponent } from '../../../components/specialist/actioncomponents/SeoContentSurvey/SeoContentSurvey.component';
import { CustomImplementationActionComponent } from '../../../components/specialist/actioncomponents/CustomImplementationAction/CustomImplementationAction.component';
import { FilesComponent as FilesComponent2 } from '../../../components/specialist/actioncomponents/Files/Files.component';
import { LockedTasksComponent } from '../../../components/specialist/LockedTasks/LockedTasks.component';
import { InformationComponent } from '../../../components/specialist/actioncomponents/Information/Information.component';
import { KeywordResearchComponent } from '../../../components/specialist/actioncomponents/KeywordResearch/KeywordResearch.component';
import { LocalBusinessCitationsComponent } from '../../../components/specialist/actioncomponents/LocalBusinessCitations/LocalBusinessCitations.component';
import { OnsiteBloggingReviewComponent } from '../../../components/specialist/actioncomponents/OnsiteBloggingReview/OnsiteBloggingReview.component';
import { OnsiteBloggingRevisionComponent } from '../../../components/specialist/actioncomponents/OnsiteBloggingRevision/OnsiteBloggingRevision.component';
import { OnsiteCopyCreateContentComponent } from '../../../components/specialist/actioncomponents/OnsiteCopyCreateContent/OnsiteCopyCreateContent.component';
import { OnsiteCopyReviewComponent } from '../../../components/specialist/actioncomponents/OnsiteCopyReview/OnsiteCopyReview.component';
import { OnsiteBloggingImplementationComponent } from '../../../components/specialist/actioncomponents/OnsiteImplementation/OnsiteBloggingImplementation/OnsiteBloggingImplementation.component';
import { OnsiteCopyImplementationComponent } from '../../../components/specialist/actioncomponents/OnsiteImplementation/OnsiteCopyImplementation/OnsiteCopyImplementation.component';
import { OnsiteImplementationComponent } from '../../../components/specialist/actioncomponents/OnsiteImplementation/OnsiteImplementation/OnsiteImplementation.component';
import { OnsiteRecommendationImplementationComponent } from '../../../components/specialist/actioncomponents/OnsiteImplementation/OnsiteRecommendationImplementation/OnsiteRecommendationImplementation.component';
import { OnsiteBloggingImplementationReviewComponent } from '../../../components/specialist/actioncomponents/OnsiteImplementationReview/OnsiteBloggingImplementationReview/OnsiteBloggingImplementationReview.component';
import { OnsiteCopyImplementationReviewComponent } from '../../../components/specialist/actioncomponents/OnsiteImplementationReview/OnsiteCopyImplementationReview/OnsiteCopyImplementationReview.component';
import { OnsiteImplementationReviewComponent } from '../../../components/specialist/actioncomponents/OnsiteImplementationReview/OnsiteImplementationReview/OnsiteImplementationReview.component';
import { OnsiteRecommendationImplementationReviewComponent } from '../../../components/specialist/actioncomponents/OnsiteImplementationReview/OnsiteRecommendationImplementationReview/OnsiteRecommendationImplementationReview.component';
import { GoogleAnalyticsAccessComponent } from '../../../components/specialist/actioncomponents/GoogleAnalyticsAccess/GoogleAnalyticsAccess.component';
import { OnsiteRecommendationComponent as OnsiteRecommendationComponent2 } from '../../../components/specialist/actioncomponents/OnsiteRecommendation/OnsiteRecommendation.component';
import { OnsiteRecommendationReviewComponent } from '../../../components/specialist/actioncomponents/OnsiteRecommendationReview/OnsiteRecommendationReview.component';
import { BusinessProfileValidationAndAugmentationComponent } from '../../../components/specialist/actioncomponents/BusinessProfileValidationAndAugmentation/BusinessProfileValidationAndAugmentation.component';
import { StandaloneBusinessProfileValidationAndAugmentationComponent } from '../../../components/specialist/actioncomponents/StandaloneBusinessProfileValidationAndAugmentation/StandaloneBusinessProfileValidationAndAugmentation.component';
import { ProactiveUnderPerformingKeywordComponent } from '../../../components/specialist/actioncomponents/ProactiveUnderPerformingKeyword/ProactiveUnderPerformingKeyword.component';
import { ResolveBusinessProfileIssuesComponent } from '../../../components/specialist/actioncomponents/ResolveBusinessProfileIssues/ResolveBusinessProfileIssues.component';
import { WebsiteBookmarkingComponent } from '../../../components/specialist/actioncomponents/WebsiteBookmarking/WebsiteBookmarking.component';
import { KeywordResearchNoteComponent } from '../../../components/specialist/actioncomponents/KeywordResearchNote/KeywordResearchNote.component';
import { KeywordResearchStrategyUpdateComponent } from '../../../components/specialist/actioncomponents/keyword-research-strategy-update/keyword-research-strategy-update.component';
import { ActionWorkspaceComponent } from '../../../components/specialist/ActionWorkspace/ActionWorkspace.component';
import { AmazonDeploymentStatusHistoryComponent } from '../../../components/specialist/AmazonDeploymentStatusHistory/AmazonDeploymentStatusHistory.component';
import { AssignedTasksComponent } from '../../../components/specialist/AssignedTasks/AssignedTasks.component';
import { BlogDiagnosticsComponent } from '../../../components/specialist/BlogDiagnostics/BlogDiagnostics.component';
import { BlogMetricsComponent } from '../../../components/specialist/BlogMetrics/BlogMetrics.component';
import { BlogSiteDetailsComponent } from '../../../components/specialist/BlogSiteDetails/BlogSiteDetails.component';
import { BlogSitesComponent } from '../../../components/specialist/BlogSites/BlogSites.component';
import { ContentFeedbackComponent } from '../../../components/specialist/ContentFeedback/ContentFeedback.component';
import { EditBlogComponent } from '../../../components/specialist/EditBlog/EditBlog.component';
import { InternalActionRejectComponent } from '../../../components/specialist/internal-tasks/reject-modal/reject-modal.component';
import { InternalActionWorkspaceComponent } from '../../../components/specialist/internal-tasks/internal-action-workspace/internal-action-workspace.component';
import { QaGradingAreaPanel } from '../../../components/qa/qa-grading-area-panel/qa-grading-area-panel.component';
import { QaGradingAreaRow } from '../../../components/qa/qa-grading-area-row/qa-grading-area-row.component';
import { QaCriteriaButtonGroup } from '../../../components/qa/qa-criteria-button-group/qa-criteria-button-group.component';
import { QaCriteriaSelect } from '../../../components/qa/qa-criteria-select/qa-criteria-select.component';
import { QaStockCommentSelect } from '../../../components/qa/qa-stock-comment-select/qa-stock-comment-select.component';
import { InternalTasksComponent } from '../../../components/specialist/internal-tasks/internal-tasks.component';
import { LockedInternalTasksComponent } from '../../../components/specialist/LockedInternalTasks/LockedInternalTasks.component';
import { SkipCustomerComponent } from '../../../components/specialist/SkipCustomer/SkipCustomer.component';
import { SpecialistWorkspaceComponent } from '../../../components/specialist/SpecialistWorkspace/SpecialistWorkspace.component';
import { ProgressRatingComponent } from '../../../components/manager/ProgressRating/ProgressRating.component';
import { AdminComponent as AdminComponent2 } from '../../../components/stats/Admin/Admin.component';
import { CustomerServiceComponent as CustomerServiceComponent2 } from '../../../components/stats/CustomerService/CustomerService.component';
import { SalesComponent as SalesComponent2 } from '../../../components/stats/Sales/Sales.component';
import { SpecialistComponent as SpecialistComponent2 } from '../../../components/stats/Specialist/Specialist.component';
import { UserTicketSummaryComponent } from '../../../components/stats/UserTicketSummary/UserTicketSummary.component';
import { BlogAndCopyContentReviewComponent } from '../../../components/ticket/blog-and-copy-content-review/blog-and-copy-content-review.component';
import { BasicInfoComponent } from '../../../components/ticket/BasicInfo/BasicInfo.component';
import { ConnectedUsersFloatingPanelComponent } from '../../../components/ticket/ConnectedUsersFloatingPanel/ConnectedUsersFloatingPanel.component';
import { ContactAttemptsComponent } from '../../../components/ticket/ContactAttempts/ContactAttempts.component';
import { CustomActionsReportComponent } from '../../../components/ticket/CustomActionsReport/CustomActionsReport.component';
import { CustomerNotesComponent } from '../../../components/ticket/customer-notes/customer-notes.component';
import { CustomerSynopsisComponent as CustomerSynopsisComponent2 } from '../../../components/ticket/CustomerSynopsis/CustomerSynopsis.component';
import { CustomerTodoListFloatingPanelComponent } from '../../../components/ticket/CustomerTodoListFloatingPanel/CustomerTodoListFloatingPanel.component';
import { CustomerTodoListTicketComponent } from '../../../components/ticket/CustomerTodoListTicket/CustomerTodoListTicket.component';
import { CustomReportsComponent as CustomReportsComponent2 } from '../../../components/ticket/CustomReports/CustomReports.component';
import { DescendantsComponent } from '../../../components/ticket/Descendants/Descendants.component';
import { DiscoveryEmailComponent } from '../../../components/ticket/discovery-email/discovery-email.component';
import { FeaturesComponent } from '../../../components/ticket/Features/Features.component';
import { FilesComponent as FilesComponent3 } from '../../../components/ticket/files/files.component';
import { ForceCustomerRefreshComponent } from '../../../components/ticket/ForceCustomerRefresh/ForceCustomerRefresh.component';
import { KeywordDiscoveryComponent } from '../../../components/ticket/KeywordDiscovery/KeywordDiscovery.component';
import { KeywordsComponent } from '../../../components/ticket/Keywords/Keywords.component';
import { KeywordResearchComponent as TicketKeywordResearchComponent} from '../../../components/ticket/KeywordResearch/KeywordResearch.component';
import { CustomerCampaignComponent } from '../../../components/ticket/customer-campaign/customer-campaign.component';
import { LinkBuildingComponent } from '../../../components/ticket/LinkBuilding/LinkBuilding.component';
import { LocalProfilesComponent as TicketLocalProfilesComponent  } from '../../../components/ticket/local-profiles/LocalProfiles.component';
import { LockConfirmationComponent } from '../../../components/ticket/LockConfirmation/LockConfirmation.component';
import { SeoContentSurveyComponent as SeoContentSurveyComponent2 } from '../../../components/ticket/SeoContentSurvey/SeoContentSurvey.component';
import { NoteComponent } from '../../../components/ticket/Note/Note.component';
import { NoteFloatingPanelComponent } from '../../../components/ticket/NoteFloatingPanel/NoteFloatingPanel.component';
import { OnsiteCopyImplementationNotesComponent } from '../../../components/ticket/OnsiteCopyImplementationNotes/OnsiteCopyImplementationNotes.component';
import { OnsiteCopyRequestComponent } from '../../../components/ticket/OnsiteCopyRequest/OnsiteCopyRequest.component';
import { OnsiteRecommendationsComponent } from '../../../components/ticket/OnsiteRecommendations/OnsiteRecommendations.component';
import { OnsiteRecommendationsCompleteComponent } from '../../../components/ticket/OnsiteRecommendationsComplete/OnsiteRecommendationsComplete.component';
import { PreAuditReportComponent } from '../../../components/ticket/pre-audit-report/pre-audit-report.component';
import { ReportingAccessComponent } from '../../../components/ticket/ReportingAccess/ReportingAccess.component';
import { RetentionComponent } from '../../../components/ticket/Retention/Retention.component';
import { ReviewComponent } from '../../../components/ticket/Review/Review.component';
import { TicketComponent } from '../../../components/ticket/Ticket.component';
import { TicketCompleteConfirmationComponent } from '../../../components/ticket/TicketCompleteConfirmation/TicketCompleteConfirmation.component';
import { TicketNavigationComponent } from '../../../components/ticket/TicketNavigation/TicketNavigation.component';
import { TicketNavigationFloatingPanelComponent } from '../../../components/ticket/TicketNavigationFloatingPanel/TicketNavigationFloatingPanel.component';
import { TicketTimerComponent } from '../../../components/ticket/TicketTimer/TicketTimer.component';
import { UpaChecklistComponent } from '../../../components/ticket/upa-checklist/upa-checklist.component';
import { CreateTodoListLegacyComponent } from '../../../components/todo/CreateTodoListLegacy/CreateTodoListLegacy.component';
import { CreateTodoListComponent } from '../../../components/todo/CreateTodoList/CreateTodoList.component';
import { CustomerTodoListComponent } from '../../../components/todo/CustomerTodoList/CustomerTodoList.component';
import { ReadonlyTodoLegacyComponent } from '../../../components/todo/ReadonlyTodoLegacy/ReadonlyTodoLegacy.component';
import { ReadonlyTodoComponent } from '../../../components/todo/ReadonlyTodo/ReadonlyTodo.component';
import { ReadonlyTodoListLegacyComponent } from '../../../components/todo/ReadonlyTodoListLegacy/ReadonlyTodoListLegacy.component';
import { ReadonlyTodoListComponent } from '../../../components/todo/ReadonlyTodoList/ReadonlyTodoList.component';
import { TodoLegacyComponent } from '../../../components/todo/TodoLegacy.component';
import { TodoComponent } from '../../../components/todo/Todo.component';
import { TodoListLegacyComponent } from '../../../components/todo/TodoListLegacy/TodoListLegacy.component';
import { TodoListComponent } from '../../../components/todo/TodoList/TodoList.component';
import { VerticalSelectorComponent } from '../../../components/shared/VerticalSelector/VerticalSelector.component';
import { SiteInformationComponent } from '../../../components/websites/SiteInformation/SiteInformation.component';
import { WebsiteComponent } from '../../../components/websites/Website/Website.component';
import { WebsiteAdjustmentsComponent } from '../../../components/websites/WebsiteAdjustments/WebsiteAdjustments.component';
import { BoostSiteAdminSSOButtonComponent } from '../../../components/websites/BoostSiteAdminSSOButton/BoostSiteAdminSSOButton.component';
import { DudaSiteAdminSSOButtonComponent } from '../../../components/websites/DudaSiteAdminSSOButton/DudaSiteAdminSSOButton.Component';
import { WebsiteDetailsComponent } from '../../../components/websites/WebsiteDetails/WebsiteDetails.component';
import { WebsiteDomainComponent } from '../../../components/websites/WebsiteDomain/WebsiteDomain.component';
import { WebsiteReportingComponent } from '../../../components/websites/WebsiteReporting/WebsiteReporting.component';
import { WebsitesComponent } from '../../../components/websites/Websites.component';
import { WebsiteTemplateSelectorComponent } from '../../../components/websites/WebsiteTemplateSelector/WebsiteTemplateSelector.component';
import { CompositionRouteGridComponent } from '../../../components/shared/CompositionRouteGrid/CompositionRouteGrid.component';
import { WordpressWebsiteReportsComponent } from '../../../components/managecustomer/WordpressWebsiteReports/WordpressWebsiteReports.component';
import { AdminHelpTextEditorComponent } from '../../../components/admin/AdminHelpTextEditor/AdminHelpTextEditor.component';
import { ReadOnlyExternalAccessComponent } from '../../../components/managecustomer/ReadOnlyExternalAccess/ReadOnlyExternalAccess.component';
import { ReadOnlyCustomerAccountGroupComponent } from '../../../components/managecustomer/ReadOnlyCustomerAccountGroup/ReadOnlyCustomerAccountGroup.component';
import { ReadOnlyCustomerAccountComponent } from '../../../components/managecustomer/ReadOnlyCustomerAccount/ReadOnlyCustomerAccount.component';
import { ReadOnlyBoostSiteAccountsComponent } from '../../../components/managecustomer/ReadOnlyBoostSiteAccounts/ReadOnlyBoostSiteAccounts.component';
import { ReadOnlyBoostSiteAccountComponent } from '../../../components/managecustomer/ReadOnlyBoostSiteAccount/ReadOnlyBoostSiteAccount.component';
import { TaskDetailsComponent } from '../../../components/managecustomer/TaskDetails/TaskDetails.component';
import { PremiumMonthlyCampaignUpdateComponent } from '../../../components/specialist/actioncomponents/premium-monthly-campaign-update/premium-monthly-campaign-update.component';
import { CountryAndLanguageManagementComponent } from '../../../components/admin/CountryAndLanguageManagement/CountryAndLanguageManagement.component';
import { CountryAndLanguageComponent } from '../../../components/admin/CountryAndLanguageManagement/CountryAndLanguage.component';
import { PartnerTaskTypeExclusionsComponent } from '../../../components/manager/partner-tasktype-exclusions/partner-tasktype-exclusions.component';
import { AllocationTestResultsComponent } from '../../../components/manager/PartnerSeoWorkDefaults/allocation-test-results/allocation-test-results.component';
import { PartnerSeoWorkDefaultsComponent } from '../../../components/manager/PartnerSeoWorkDefaults/partner-seo-work-defaults/partner-seo-work-defaults.component';
import { GoogleAnalyticsCustomActionComponent } from '../../../components/managecustomer/GoogleAnalyticsCustomAction/GoogleAnalyticsCustomAction.component';
import { KeywordResearchStrategyUpdateCustomActionComponent } from '../../../components/managecustomer/keyword-research-strategy-update-custom-action/keyword-research-strategy-update-custom-action.component';
import { OnsiteBloggingCreateContentComponent } from '../../../components/specialist/actioncomponents/onsite-blogging-create-content/onsite-blogging-create-content.component';
import { ViewLocalProfilesComponent  } from '../../../components/shared/local-profiles/view-local-profiles/view-local-profiles.component';
import { LocalProfilesComponent as SharedLocalProfilesComponent  } from '../../../components/shared/local-profiles/local-profiles/local-profiles.component';
import { LocalProfilesComponent as ManageCustomerLocalProfilesComponent } from '../../../components/managecustomer/local-profiles/local-profiles.component';
import { SessionStorageService } from '../../../services/session-storage.service';
import { NotificationTemplateComponent } from '../../../components/shared/notification-template/notification-template.component';
import { NotificationDefinitionComponent } from '../../../components/shared/notification-definition/notification-definition.component';
import Utils from '../../../shared/utils';
import { CustomerCampaignComponent as SharedCustomerCampaignComponent } from '../../../components/shared/customer-campaign/customer-campaign/customer-campaign.component';
import { CustomerCampaignComponent as ManageCustomerCustomerCampaignComponent } from '../../../components/managecustomer/customer-campaign/customer-campaign.component';
import { BacklinkParametersComponent } from '../../../components/ticket/backlink-parameters/backlink-parameters.component';
import { KeywordResearchComponent as SharedKeywordResearchComponent} from '../../../components/shared/keyword-research/keyword-research/keyword-research.component';


@Component({
  selector: 'compose',
  templateUrl: './compose.component.html'
})
export class ComposeComponent implements AfterViewInit, OnChanges, OnDestroy {
  private originalDisplay: string;

  private static ANGULAR_COMPONENTS_AVAILABLE_VIA_COMPOSE: any[] = [
    AdminHelpTextEditorComponent,
    WordpressWebsiteReportsComponent,
    ReadOnlyExternalAccessComponent,
    ReadOnlyCustomerAccountGroupComponent,
    ReadOnlyCustomerAccountComponent,
    ReadOnlyBoostSiteAccountsComponent,
    ReadOnlyBoostSiteAccountComponent,
    CompositionRouteGridComponent,
    AddJobComponent,
    ApplicationManagementComponent,
    InternalTaskHistoryComponent,
    JobListComponent,
    JobsByTypeComponent,
    PartnerUsersComponent,
    TranslationsComponent,
    UpdateJobsComponent,
    VerticalComponent,
    VerticalManagementComponent,
    VerticalTranslationComponent,
    ViewTaskWorkSpaceComponent,
    ViewTaskFeedbackComponent,
    StandardBacklinkFeedback,
    OnsiteRecommendationsFeedback,
    ChangeSpecialistScoreComponent,
    WorkflowsComponent,
    ChangePasswordComponent,
    LoginComponent,
    LogoutComponent,
    ResetPasswordComponent,
    SwitchPartnerComponent,
    ValidationChecklistComponent,
    CredentialsComponent,
    SiteCredentialsComponent,
    ContentAuditComponent,
    CsrsComponent,
    ManagerToolsComponent,
    AssignedTicketsComponent,
    AssignTicketTypesComponent,
    CreateTicketBatchComponent,
    TimeZoneDateTimeComponent,
    CustomerPriorityAssignmentComponent,
    CustomerSearchComponent,
    EditTicketComponent,
    HelpNextCustomerComponent,
    ManageCustomerComponent1,
    ManageTicketsComponent,
    PerformanceMetricsComponent,
    CustomReportsComponent1,
    AddComponent,
    ListComponent1,
    MoneyBackGuaranteeComponent,
    SingleComponent,
    LanguageQuestionGroupComponent,
    AddCustomReportComponent,
    WorkRequestEditComponent,
    AddNoteComponent,
    AllocateWorkComponent,
    BasicInformationComponent,
    BlogPostsComponent,
    CompletedTasksComponent,
    ConnectedUsersComponent,
    ConnectedUsersSidePanelComponent,
    CustomerAccountGroupComponent,
    CustomerAccountGroupModalComponent,
    WebsiteAccessModalComponent,
    CustomerContactPreferencesComponent,
    CustomerWorkHistoryComponent,
    CustomerInformationComponent1,
    CustomerInformationLimitedComponent,
    CustomerSynopsisComponent1,
    CustomerTodoHistoryComponent,
    CustomerUserComponent,
    CustomerUserPhoneNumberComponent,
    CustomerUsersComponent,
    EditBlogPostComponent,
    EditMerchantFieldsComponent,
    ViewLocalProfilesComponent,
    SharedLocalProfilesComponent,
    ManageCustomerLocalProfilesComponent,
    LoginsComponent,
    FilesComponent1,
    FloatingCustomerNoteComponent,
    FloatingCustomerTodoListComponent,
    ManageCustomerComponent2,
    BloggingDetailsComponent,
    SurveyComponent,
    SeoContentSurveyComponent1,
    MerchantFieldsComponent1,
    MerchantFieldsEditComponent,
    NewTicketComponent,
    NotesComponent,
    NotesAndTicketsComponent,
    OnsiteCopyCustomActionComponent,
    WorkflowTaskHistoryComponent,
    EditImplementationInstructions,
    EditRejectionInstructions,
    OpenTicketsComponent,
    ReviseNoteComponent,
    TicketGroupComponent,
    UploadCustomerFileComponent,
    AddPartnerPricingToolPriceLevelComponent,
    PreviewPartnerPricingToolPriceLevel,
    CustomerAccountDefaultGroupComponent,
    CustomerPriorityAssignmentsComponent,
    EditUserComponent,
    TaskImportExportComponent,
    OrganizationalTeamComponent,
    OrganizationalTeamsComponent,
    CloseWorkflowComponent,
    LeadSourceComponent,
    LeadSourcesComponent,
    LinkLocationComponent,
    LinkTypeActivationComponent,
    LockedTasksComponent,
    ManagePartnerPricingToolComponent,
    ManagePartnersComponent,
    ManageReportingComponent,
    ManageUsersComponent,
    MerchantFieldsComponent2,
    PremiumBacklinkComponent,
    PartnerComponent,
    PartnerFilesComponent,
    PartnerPricingToolDetailComponent,
    PartnerPricingToolFeatureListComponent,
    PartnerUserComponent,
    PartnerTaskTypeExclusionsComponent,
    AllocationTestResultsComponent,
    PartnerSeoWorkDefaultsComponent,
    PendingWorkComponent,
    ProTipComponent,
    ProTipsComponent,
    RegistrationPhoneNumbersComponent,
    ReportingCustomizationsComponent,
    RepublishPostComponent,
    BlacklistDomainsComponent,
    ConfigurePartnerPricingToolComponent,
    ConfigureQaTasksComponent,
    TransferAccountsComponent,
    AdminComponent1,
    TaskStewardshipComponent,
    TaskStewardshipsComponent,
    CustomerServiceComponent1,
    ManagerComponent1,
    SalesComponent1,
    SpecialistComponent1,
    OptionToggleComponent,
    PagerComponent,
    RecentOrdersComponent,
    AddCustomerComponent,
    SearchCustomersComponent,
    UploadFileComponent,
    BusinessDirectoriesComponent,
    ChooseLocalProfileCategoryComponent,
    ClassifiedBusinessListingsComponent,
    CommentsComponent,
    CustomManualComponent,
    OnsiteRecommendationComponent1,
    ContentReviewComponent,
    StandardBacklinkComponent,
    CustomerInformationComponent2,
    SeoContentSurveyComponent,
    CustomImplementationActionComponent,
    FilesComponent2,
    InformationComponent,
    KeywordResearchComponent,
    LocalBusinessCitationsComponent,
    OnsiteBloggingCreateContentComponent,
    OnsiteBloggingReviewComponent,
    OnsiteBloggingRevisionComponent,
    OnsiteCopyCreateContentComponent,
    OnsiteCopyReviewComponent,
    OnsiteBloggingImplementationComponent,
    OnsiteCopyImplementationComponent,
    OnsiteImplementationComponent,
    OnsiteRecommendationImplementationComponent,
    OnsiteBloggingImplementationReviewComponent,
    OnsiteCopyImplementationReviewComponent,
    OnsiteImplementationReviewComponent,
    OnsiteRecommendationImplementationReviewComponent,
    GoogleAnalyticsAccessComponent,
    OnsiteRecommendationComponent2,
    OnsiteRecommendationReviewComponent,
    BusinessProfileValidationAndAugmentationComponent,
    StandaloneBusinessProfileValidationAndAugmentationComponent,
    ProactiveUnderPerformingKeywordComponent,
    ResolveBusinessProfileIssuesComponent,
    WebsiteBookmarkingComponent,
    ActionWorkspaceComponent,
    AmazonDeploymentStatusHistoryComponent,
    AssignedTasksComponent,
    BlogDiagnosticsComponent,
    BlogMetricsComponent,
    BlogSiteDetailsComponent,
    BlogSitesComponent,
    ContentFeedbackComponent,
    EditBlogComponent,
    InternalActionRejectComponent,
    InternalActionWorkspaceComponent,
    QaGradingAreaPanel,
    QaGradingAreaRow,
    QaCriteriaButtonGroup,
    QaCriteriaSelect,
    QaStockCommentSelect,
    InternalTasksComponent,
    LockedInternalTasksComponent,
    SkipCustomerComponent,
    SpecialistWorkspaceComponent,
    ProgressRatingComponent,
    AdminComponent2,
    CustomerServiceComponent2,
    SalesComponent2,
    SpecialistComponent2,
    UserTicketSummaryComponent,
    BlogAndCopyContentReviewComponent,
    BasicInfoComponent,
    ConnectedUsersFloatingPanelComponent,
    ContactAttemptsComponent,
    CustomActionsReportComponent,
    CustomerNotesComponent,
    CustomerSynopsisComponent2,
    CustomerTodoListFloatingPanelComponent,
    CustomerTodoListTicketComponent,
    CustomReportsComponent2,
    DescendantsComponent,
    FeaturesComponent,
    FilesComponent3,
    ForceCustomerRefreshComponent,
    KeywordDiscoveryComponent,
    KeywordsComponent,
    TicketKeywordResearchComponent,
    CustomerCampaignComponent,
    LinkBuildingComponent,
    TicketLocalProfilesComponent,
    LockConfirmationComponent,
    SeoContentSurveyComponent2,
    NoteComponent,
    NoteFloatingPanelComponent,
    OnsiteCopyImplementationNotesComponent,
    OnsiteCopyRequestComponent,
    OnsiteRecommendationsComponent,
    OnsiteRecommendationsCompleteComponent,
    PreAuditReportComponent,
    ReportingAccessComponent,
    RetentionComponent,
    ReviewComponent,
    TicketComponent,
    TicketCompleteConfirmationComponent,
    TicketNavigationComponent,
    TicketNavigationFloatingPanelComponent,
    TicketTimerComponent,
    CreateTodoListLegacyComponent,
    CreateTodoListComponent,
    CustomerTodoListComponent,
    ReadonlyTodoLegacyComponent,
    ReadonlyTodoComponent,
    ReadonlyTodoListLegacyComponent,
    ReadonlyTodoListComponent,
    TodoLegacyComponent,
    TodoComponent,
    TodoListLegacyComponent,
    TodoListComponent,
    VerticalSelectorComponent,
    SiteInformationComponent,
    WebsiteComponent,
    WebsiteAdjustmentsComponent,
    WebsiteDetailsComponent,
    BoostSiteAdminSSOButtonComponent,
    DudaSiteAdminSSOButtonComponent,
    WebsiteDomainComponent,
    WebsiteReportingComponent,
    WebsitesComponent,
    WebsiteTemplateSelectorComponent,
    TaskDetailsComponent,
    PremiumMonthlyCampaignUpdateComponent,
    CountryAndLanguageManagementComponent,
    CountryAndLanguageComponent,
    SeoCustomerAccountComponent,
    UpaChecklistComponent,
    GoogleAnalyticsCustomActionComponent,
    KeywordResearchNoteComponent,
    KeywordResearchStrategyUpdateCustomActionComponent,
    KeywordResearchStrategyUpdateComponent,
    PartnerEmailsComponent,
    NotificationTemplateComponent,
    NotificationDefinitionComponent,
    NotificationsComponent,
    SharedCustomerCampaignComponent,
    DiscoveryEmailComponent,
    ManageCustomerCustomerCampaignComponent,
    BacklinkParametersComponent,
    SharedKeywordResearchComponent
  ];

  private static angularSelectorToComponentMap: { [key: string]: any } = (<any>Object).fromEntries(ComposeComponent.ANGULAR_COMPONENTS_AVAILABLE_VIA_COMPOSE.map(component => {
    if (component['ɵcmp']) {
      for (let selector of <string[]>_.flatten(component['ɵcmp'].selectors)) {
        selector = selector.trim().toLowerCase();
        return [selector, component];
      }
    }

    return [];
  }));

  private destroyComponentRef(componentRef: any): void {
    if (componentRef?.instance?.deactivate) {
      componentRef?.instance?.deactivate();
    }
    this.viewContainerRef.clear();
    componentRef?.destroy();
  }

  public ngOnDestroy(): void {
    this.destroyComponentRef(this.componentRef);
  }

  private initialized = false;
  public ngAfterViewInit(): void {
    setTimeout(() => { // without this we get ExpressionChangedAfterItWasChecked error everywhere
      this.updateBindings();
      this.initialized = true;
    }, 0);
  }

  public ngOnChanges(): void {
    setTimeout(() => { // without this we get ExpressionChangedAfterItWasChecked error everywhere
      if (!this.initialized) {
        return;
      }

      this.updateBindings();
    }, 0);
  }

  private componentRef: any;
  @Input() public Selector: string;
  @Input() public ActivateOptions: any;
  @Input() public Model: any;
  @Input() public Callback: (component: any, element: HTMLElement) => void;
  @Input() public FailureCallback: () => void;
  @Input() public HideErrors: boolean;
  @ViewChild('container', { read: ViewContainerRef, static: true }) public viewContainerRef: ViewContainerRef;

  constructor(
    private hostElement: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private sessionStorageService: SessionStorageService) {
  }

  private updateBindings(): void {
    this.destroyComponentRef(this.componentRef);

    if (!this.Selector) {
      return;
    }

    let componentConstructor = ComposeComponent.angularSelectorToComponentMap[(this.Selector || '').trim().toLowerCase()];
    if (!componentConstructor) {
      throw 'Component not found for selector: ' + this.Selector;
    }

    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentConstructor);
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);
    this.componentRef.hostView.detach();

    let component: any = this.componentRef.instance;

    let activateOptions: any = ko.utils.unwrapObservable(this.ActivateOptions) || {};

    if (this.Model) {
      $.extend(component, this.Model);
    }

    let canActivate = $.when();
    if (component.canActivate) {
      canActivate = $.when<any>(
        Utils.wrapDfd(this.sessionStorageService.getUser()),
        Utils.wrapDfd(this.sessionStorageService.getPartner()))
        .then((user: Boo.Objects.User, partner: Boo.Objects.Partner) => {
          if (!component.canActivate(user, partner, activateOptions)) {
            throw 'Cannot activate';
          }
        });
    }

    let handleFailure = () => {
      if (!this.HideErrors) {
        toastr.error('Unable to compose the interface. System is configured incorrectly');
      }

      if (this.FailureCallback) {
        this.FailureCallback();
      }
    };

    let componentRef = this.componentRef;
    if (this.originalDisplay === undefined) {
      this.originalDisplay = this.hostElement.nativeElement.style.display;
    }
    this.hostElement.nativeElement.style.display = 'none';
    canActivate
      .then(() => $.when(component.activate ? component.activate(activateOptions) : null))
      .then(() => {
        this.hostElement.nativeElement.style.display = this.originalDisplay;
        this.originalDisplay = undefined;
        componentRef.hostView.reattach();

        if (this.Callback) {
          this.Callback(component, this.hostElement.nativeElement);
        }
      })
      .fail(handleFailure);
  }
}
