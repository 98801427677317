import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import { finalize } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-components-ticket-reportingaccess',
  templateUrl: './ReportingAccess.component.html'
})
export class ReportingAccessComponent extends BaseComponent {
    public users: CustomerUserObservable[];
    public isLoading: KnockoutObservable<any>;

    constructor(private userService: UserService) {
      super();
    }

    public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        this.isLoading = ko.observable();
        this.users = params.customer.Users();
        return super.activate(params);
    }

    public sendPasswordResetEmail(params: any ): void {
      this.userService.sendPasswordResetEmail(params.Username())
        .pipe(finalize(() => this.isLoading(false))).subscribe(
          () => toastr.success('Password reset instructions have been sent to the email address.', 'Password Reset'),
          (error) => toastr.error(error, 'Password Reset')
        );
    }
}
