import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import { BacklinkParametersCorrectionService } from '../../../services/backlink-parameters-correction.service';
import { CustomerCampaignService } from '../../../services/customer-campaign.service';
import { SaveTypes } from "app/models/enums/SaveTypes";
import System from 'framework/System';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-components-ticket-backlink-parameters',
  templateUrl: './backlink-parameters.component.html'
})
export class BacklinkParametersComponent extends BaseComponent {

  backlinkParametersCorrections: Boo.Objects.BacklinkParameterCorrection[] = [];
  websiteUrls: Boo.Objects.WebsiteUrl[] = [];

  constructor(private backlinkParametersCorrectionService: BacklinkParametersCorrectionService, private customerCampaignService: CustomerCampaignService) {
    super();
  }

  ngOnInit(): void {
    this.backlinkParametersCorrectionService.get(this.ticket.TicketId())
      .subscribe(backlinkParametersCorrections => {
        this.backlinkParametersCorrections = backlinkParametersCorrections;
      });

    this.customerCampaignService.getWebsiteUrls(this.customer.CustomerId())
      .subscribe(websiteUrls => {
        this.websiteUrls = websiteUrls.filter(w => w.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active && w.Keywords.some(x => x.IsActive));
        this.websiteUrls.forEach(websiteUrl => websiteUrl.Keywords = websiteUrl.Keywords.filter(x => x.IsActive));
      });
  }

  save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
    this.backlinkParametersCorrectionService.save(this.backlinkParametersCorrections)
    .subscribe(() => {});
    return System.emptyPromise();
  }

  getWebsiteUrlKeywords(backlinkParameterCorrection: Boo.Objects.BacklinkParameterCorrection): Boo.Objects.WebsiteUrlKeyword[] {
    return this.websiteUrls.find(x => x.WebsiteUrlId === backlinkParameterCorrection.WebsiteUrlId)?.Keywords;
  }
}
