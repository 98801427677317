import { environment } from '../../../environments/environment';
import { CustomerCampaignService } from '../../../app/services/customer-campaign.service';
import { TaskService } from '../../../app/services/task.service';
import Utils from '../../../app/shared/utils';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export default class WebsiteUrlsAndTasksService {

    private deletableWorkflowStates: Boo.Objects.Enums.WorkflowStates[] = [Boo.Objects.Enums.WorkflowStates.Unknown, Boo.Objects.Enums.WorkflowStates.Initial, Boo.Objects.Enums.WorkflowStates.Ready, Boo.Objects.Enums.WorkflowStates.ReadyForContent, Boo.Objects.Enums.WorkflowStates.Fulfilling];

    constructor(
      private customerCampaignService: CustomerCampaignService,
      private taskService: TaskService) { }

    isHistoricalTask(taskTypeId: number): boolean {
        return taskTypeId === 704 // 704 = HistoricOnsiteRecommendation, deprecated
            || taskTypeId === 611 // 611 = PremiumOnsiteCopyCreation, deprecated
            || taskTypeId === 612 // 612 = StandardOnsiteCopyCreation, deprecated
            || taskTypeId === 1004 // This is a retired onsite copy task type that is still in our system in some places (notably customer 2744)
            || taskTypeId === Boo.Objects.Enums.TaskTypeEnum.HistoricStandardOnsiteBlogPost
            || taskTypeId === Boo.Objects.Enums.TaskTypeEnum.HistoricPremiumOnsiteBlogPost;
    }

    loadUrls(customerId: number): JQueryPromise<WebsiteUrlViewModel[]> {
        return Utils.wrapDfd(this.customerCampaignService.getWebsiteUrls(customerId, true)) // excludeInactiveKeywords: true
            .then((urls: Boo.Objects.WebsiteUrl[]) => {
                // sort using array as key uses string comparison, so we have to padStart to make number sorting accurate
                const sortedUrls = _.sortBy(urls || [], x => [(x.StatusId !== Boo.Objects.Enums.WebsiteUrlStatuses.New && x.StatusId !== Boo.Objects.Enums.WebsiteUrlStatuses.Limited && x.StatusId !== Boo.Objects.Enums.WebsiteUrlStatuses.Active), x.WebsiteUrlId.toString().padStart(9, '0')]);
                return _.map(sortedUrls, x => this.extendUrlProperties(x));
            });
    }

    CanDelete(task: Boo.Objects.OnsiteOptimizationTask): boolean {
        if (!_.contains(this.deletableWorkflowStates, task.WorkflowStateId)) {
            return false;
        }

        const taskStatusId = (task as Boo.Objects.OnsiteOptimizationTask).TaskStatusId;
        if (taskStatusId) {
            return taskStatusId === Boo.Objects.Enums.TaskStatusEnum.Pending || taskStatusId === Boo.Objects.Enums.TaskStatusEnum.NeedsData;
        }

        const taskStatus = task.TaskStatus;
        if (taskStatus) {
            return taskStatus === 'Pending' || taskStatus === 'NeedsData';
        }

        return false;
    }
    
    loadTasks(customerId: number, includeOnsiteBlogTasks: boolean, includeHistorical: boolean): JQueryPromise<IOnsiteRecommendation[]> {
        return Utils.wrapDfd(this.taskService.getOnsiteOptimizationTasks(customerId, includeOnsiteBlogTasks))
            .then((tasks: Boo.Objects.OnsiteOptimizationTask[]) => {
                tasks = tasks || [];
                if (!includeOnsiteBlogTasks) {
                    tasks = _.filter(tasks, x => x.WorkflowDefinitionId !== Boo.Objects.Enums.WorkflowDefinitionsEnum.OnsiteBloggingV2 as number && x.WorkflowDefinitionId !== ObsoleteWorkflowDefinitions.ObsoleteOnsiteBloggingWorkflow);
                }

                for (const task of tasks) {
                    this.extendTaskProperties(task, customerId);
                }

                if (includeHistorical) {
                    return <any>_.sortBy(tasks, x => x.TaskId).reverse();
                } else {
                    const groups = _.groupBy(_.filter(tasks, x => x.TaskStatus !== 'Deleted'), x => x.WebsiteUrlId);
                    return <any>_.compact(_.map(groups, group => _.first(_.sortBy(group, x => x.TaskId).reverse())));
                }
            });
    }

    workflowDefinitionIdToOriginString (definition: number): string {
        switch (definition) {
            case Boo.Objects.Enums.WorkflowDefinitionsEnum.OnsiteBloggingV2:
            case ObsoleteWorkflowDefinitions.ObsoleteOnsiteBloggingWorkflow:
                return 'Onsite Blog Post';
            case Boo.Objects.Enums.WorkflowDefinitionsEnum.OnsiteCopy:
                return 'Onsite Copy';
            case Boo.Objects.Enums.WorkflowDefinitionsEnum.OnsiteRecommendation:
                return 'Onsite Recommendation';
            default:
                return '';
        }
    }

    private extendTaskProperties(original: Boo.Objects.OnsiteOptimizationTask, customerId: number): void {
        const task: IOnsiteRecommendation = <any>original;
        this.setPublishProperties(task, customerId);

        task.Comment = (task.Comment || '').trim();
        task.Note = (task.Note || '').trim();

        task.DisplayStatus = this.calculateDisplayStatus(task);

        task.CanOrder = (task.WorkflowStateId === launchpad.config.workflowStateEnum.Unknown && task.TaskStatus === 'Not Created')
            || (task.WorkflowStateId === launchpad.config.workflowStateEnum.Completed && task.TaskStatus === 'Complete')
            || (task.WorkflowStateId === launchpad.config.workflowStateEnum.ReconcileCompleted && task.TaskStatus === 'Complete')
            || (task.WorkflowStateId === launchpad.config.workflowStateEnum.ReconcileCompleted && task.TaskStatus === 'ReconcileComplete')
            // We filter deleted tasks later, so if a workflow has non-deleted tasks it erroneously marks this variable false for a url if we don't do this
            || (task.WorkflowStateId === launchpad.config.workflowStateEnum.Deleted)
            // The two scenarios below are possible for older recommendations that were auto-completed.
            || (task.WorkflowStateId === launchpad.config.workflowStateEnum.Completed && task.TaskStatus === 'ReconcileComplete')
            // Handle old OSRs that did not have a workflow created.
            || (task.WorkflowStateId === launchpad.config.workflowStateEnum.Unknown && task.TaskStatus === 'Complete')
            || (task.WorkflowStateId === launchpad.config.workflowStateEnum.Unknown && task.TaskStatus === 'ReconcileComplete');

        task.CanApproveAndReject = task.WorkflowStateId === launchpad.config.workflowStateEnum.ReadyForAMApproval && task.TaskStatus !== 'ReconcileComplete';
        task.CanDelete = this.CanDelete(task);

        const onsiteRecommendationContentTypes: Boo.Objects.Enums.TaskTypeEnum[] = [Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendation, Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendationCreateContent];
        task.CanShowApprovalReason = ko.computed(() => onsiteRecommendationContentTypes.includes(task.TaskTypeId));

        task.Assignment = ko.computed(() => {
            if (task.WorkflowStateId === launchpad.config.workflowStateEnum.ReconcileCompleted) {
                return 'Reconcile Complete';
            }

            if (task.WorkflowStateId === launchpad.config.workflowStateEnum.Completed) {
                return 'Completed';
            }

            return task.AssignedTo !== '' ? 'Locked' : 'Pending';
        });
        task.Origin = this.workflowDefinitionIdToOriginString(task.WorkflowDefinitionId);
    }
    
    private calculateDisplayStatus(task: Boo.Objects.OnsiteOptimizationTask): string {
        if (task.WorkflowDefinitionId === Boo.Objects.Enums.WorkflowDefinitionsEnum.OnsiteBloggingV2 as number || task.WorkflowDefinitionId === ObsoleteWorkflowDefinitions.ObsoleteOnsiteBloggingWorkflow) {
            const externalStatus = (task.ExternalStatus || '').trim().toLowerCase();
            const approved = externalStatus && externalStatus !== 'save as draft' && externalStatus !== 'revise' && externalStatus !== 'reject';
            const autoApproved = externalStatus === 'system approved';

            if (task.TaskPublishedDate) {
                return autoApproved ? 'Draft' : 'Published';
            }

            if (approved) {
                return autoApproved ? 'Auto-Approved' : 'Approved';
            }

            return task.WorkflowStatus;
        }

        if (task.TaskVerifiedDate) {
            return 'Verified';
        }
        if (task.TaskPublishedDate || task.WorkflowStatus === 'Completed') {
            return 'Not Verified';
        }
        if (!task.TaskInsertedDate) {
            return 'Not Created';
        }

        return task.WorkflowStatus;
    }

    private extendUrlProperties(original: Boo.Objects.WebsiteUrl): WebsiteUrlViewModel {
        const url: WebsiteUrlViewModel = <any>ko.mapping.fromJS(original);
        url.Status = ko.computed(() => (url.StatusId() === Boo.Objects.Enums.WebsiteUrlStatuses.Active || url.StatusId() === Boo.Objects.Enums.WebsiteUrlStatuses.Limited || url.StatusId() === Boo.Objects.Enums.WebsiteUrlStatuses.New) ? 'Active' : 'Archived');
        url.LinkBuild = ko.observable(url.StatusId() === Boo.Objects.Enums.WebsiteUrlStatuses.Active);
        url.IsActiveStatus = ko.computed(() => url.StatusId() === Boo.Objects.Enums.WebsiteUrlStatuses.Active || url.StatusId() === Boo.Objects.Enums.WebsiteUrlStatuses.Limited || url.StatusId() === Boo.Objects.Enums.WebsiteUrlStatuses.New);
        return url;
    }

    private setPublishProperties(task: IOnsiteRecommendation, customerId: number): void {
        if (task.WorkflowDefinitionId !== Boo.Objects.Enums.WorkflowDefinitionsEnum.OnsiteBloggingV2 as number && task.WorkflowDefinitionId !== ObsoleteWorkflowDefinitions.ObsoleteOnsiteBloggingWorkflow) {
            task.FileUrl = task.TaskActionUrl;
            return;
        }
        const isS3Url = (task.TaskActionUrl || '').toLowerCase().indexOf('//s3.amazonaws.com/') !== -1;
        if (task.BoostToPublish) {
            if (task.TaskPostId) {
                const baseUrl = environment.urlConfig.serviceUrl;
                task.FileUrl = baseUrl.toLowerCase().replace('/launchpad/v1', '/articlependingservice/v2/taskposts/document/') + task.TaskPostId;
                if (task.TaskActionUrl) {
                    task.PublishUrl = task.TaskActionUrl;
                }
            }
        } else {
            if (isS3Url) {
                task.FileUrl = task.TaskActionUrl;
                task.PublishUrl = '(Client Publishing)';
                return;
            }
        }
    }
}

export interface WebsiteUrlViewModel extends WebsiteUrlObservable {
    Status: KnockoutComputed<string>;
    LinkBuild: KnockoutObservable<boolean>;
    IsActiveStatus: KnockoutComputed<boolean>;
}

export interface IOnsiteRecommendation extends Boo.Objects.OnsiteOptimizationTask {
    CanOrder: boolean;
    CanApproveAndReject: boolean;
    DisplayStatus: string;
    CanDelete: boolean;
    PublishUrl: string;
    FileUrl: string;
    CanShowApprovalReason: KnockoutComputed<boolean>;
    Origin: string;
    Assignment: KnockoutComputed<string>;
    TaskCompletedDate: Date;
    TaskInsertedDate: Date;
    TaskPublishedDate: Date;
    TaskVerifiedDate: Date;
}

// This is a collection of workflow definitions that we previously used
// They are still in the system in some old workflows, but are not in the standard enums
export enum ObsoleteWorkflowDefinitions {
    ObsoleteOnsiteBloggingWorkflow = 2
}