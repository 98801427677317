<div class="row">
	<div class="col-md-12">
		<div class="card">
			<div class="card-header">
				<span>Export Translations</span>
			</div>
			<div [loadingOverlay]="isLoading()" class="card-body">
				<div class="row">
					<div class="col-md-3">
						<div class="form-group">
							<label class="control-label">Table Name</label>
							<select [ngModel]="exportSelectedTranslatedTableId()" (ngModelChange)="exportSelectedTranslatedTableId($event)" [ngModelOptions]="{ standalone: true }" name="table-name" class="form-select">
								<option *ngFor="let option of translatedTables" [ngValue]="option.translatedTableType">{{ option.name }}</option>
							</select>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label>Language</label>
							<span>
								<select [dataValidation]="{ placement: 'top', value: exportSelectedLanguageId }" [ngModel]="exportSelectedLanguageId()" (ngModelChange)="exportSelectedLanguageId($event)" [ngModelOptions]="{ standalone: true }" class="form-select">
									<option [ngValue]="undefined">Choose...</option>
									<option *ngFor="let option of languages" [ngValue]="option.LanguageId">{{ option.Name }}</option>
								</select>
							</span>
						</div>
					</div>
					<div class="col-md-1">
						<label class="control-label">&nbsp;</label>
						<button (click)="exportTranslations()" class="btn btn-primary">
							<i class="fa fa-download"></i>
							Export
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-12">
		<div class="card">
			<div class="card-header">
				Import Translations
			</div>
			<div [loadingOverlay]="isLoading()" class="card-body">
				<div class="row">
					<div class="col-md-4">
						<div class="form-group">
							<div class="form-group">
								<label class="control-label">File Upload</label>
								<div class="input-group">
									<input [ngModel]="filename()" (ngModelChange)="filename($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" disabled=""/>
									<span class="btn btn-primary btn-file">
										<!-- The default encoding is windows-1252 because these are edited in excel.  This will also accept utf-8 encoding with a utf-8 Byte order mark -->
										Select a File
										<input [fileReader]="{ data: content, filename: filename, file: file, accept: '.csv', method: 'readAsText', encoding: 'windows-1252'}" type="file" class="form-control float-end"/>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<ng-container *ngIf="importSelectedTranslatedTable()">
							<div class="form-group">
								<label class="control-label">Table Name</label>
								<input [(ngModel)]="importSelectedTranslatedTable().name" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" readonly class="form-control"/>
							</div>
						</ng-container>
					</div>
					<div class="col-md-3">
						<ng-container *ngIf="importSelectedLanguage()">
							<div class="form-group">
								<label>Language</label>
								<input [(ngModel)]="importSelectedLanguage().Name" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" readonly class="form-control"/>
							</div>
						</ng-container>
					</div>
					<div class="col-md-2">
						<ng-container *ngIf="displayData()">
							<div class="form-group">
								<label class="control-label">&nbsp;</label>
								<button (click)="importTranslations()" class="form-control btn btn-success">
									<i class="fa fa-save"></i>
									Save Translations
								</button>
							</div>
						</ng-container>
					</div>
				</div>
				<ng-container *ngIf="displayData()">
					<hr/>
					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<label class="control-label">Number of rows detected:</label>
								<span>{{csvRows()}}</span>
							</div>
							<div class="form-group">
								<label class="control-label">Import Sample (showing a max of 100 rows taken from the beginning and end of the file)</label>
							</div>
							<div>
								<table class="table table-bordered table-hover">
									<thead>
										<tr>
											<ng-container *ngFor="let $data of importDataHeader()">
												<th>{{$data}}</th>
											</ng-container>
										</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let $data of displayData()">
											<tr>
												<ng-container *ngFor="let $data2 of $data | keyvalue">
													<td>{{$data2.value}}</td>
												</ng-container>
											</tr>
										</ng-container>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
