<div class="card">
  <div class="card-header">
    <span>Onsite Recommendations Feedback</span>
    <button (click)="close()" type="button" class="btn-close float-end"></button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="originalTitle" class="control-label">Original New Title Text</label>
          <input readonly [ngModel]="input.OnsiteRecommendation.TitleNew"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="originalTitle" class="form-control"/>
        </div>
        <div class="form-group">
          <label for="originalDescription" class="control-label">Original New Description Text</label>
          <textarea [(ngModel)]="input.OnsiteRecommendation.DescriptionNew" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            name="originalDescription" readonly style="height:auto" class="form-control"></textarea>
        </div>
        <div class="form-group">
          <label for="originalH1" class="control-label">Original New H1 Tag</label>
          <input readonly [ngModel]="input.OnsiteRecommendation.H1New"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="originalH1" class="form-control"/>
        </div>
        <div class="form-group">
          <label for="originalH2" class="control-label">Original New H2 Tag</label>
          <input readonly [ngModel]="input.OnsiteRecommendation.H2New"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="originalH2" class="form-control"/>
        </div>
        <div class="form-group">
          <label for="originalAltImageTag" class="control-label">Original New Alt Image Tag</label>
          <textarea [(ngModel)]="input.OnsiteRecommendation.AltImageNew" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            name="originalAltImageTag" readonly style="height:auto" class="form-control"></textarea>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="reviewedTitle" class="control-label">Reviewed New Title Text</label>
          <input readonly [ngModel]="output.OnsiteRecommendation.TitleNew"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="reviewedTitle" class="form-control"/>
        </div>
        <div class="form-group">
          <label for="reviewedDescription" class="control-label">Reviewed New Description Text</label>
          <textarea [(ngModel)]="output.OnsiteRecommendation.DescriptionNew" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            name="originalDescription" readonly style="height:auto" class="form-control"></textarea>
        </div>
        <div class="form-group">
          <label for="reviewedH1" class="control-label">Reviewed New H1 Tag</label>
          <input readonly [ngModel]="output.OnsiteRecommendation.H1New"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="reviewedH1" class="form-control"/>
        </div>
        <div class="form-group">
          <label for="reviewedH2" class="control-label">Reviewed New H2 Tag</label>
          <input readonly [ngModel]="output.OnsiteRecommendation.H2New"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="reviewedH2" class="form-control"/>
        </div>
        <div class="form-group">
          <label for="reviewedAltImageTag" class="control-label">Reviewed New Alt Image Tag</label>
          <textarea [(ngModel)]="output.OnsiteRecommendation.AltImageNew" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            name="reviewedAltImageTag" readonly style="height:auto" class="form-control"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 offset-md-6">
        <div class="form-group">
          <label for="title" class="control-label">Editor Feedback</label>
          <div class="clear-fix"></div>
          <div>
            <textarea #editorRichText [richText]="{ content: output.RejectionReason, options: editorToolbar }"
              name="editorsFeedback" rows="15" style="height: 300px;" class="form-control"
              placeholder="Enter Content"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer text-end">
    <button (click)="close()" class="btn btn-link">Close</button>
  </div>
</div>