<div class="row">
	<div class="col-md-9">
		<div class="form-horizontal">
			<div class="form-group">
				<label class="col-md-3 control-label">Billed Minutes:</label>
				<p class="col-md-9 form-control-static">
					<span>{{taskMinutes}}</span>
				</p>
			</div>
			<div class="form-group">
				<label class="col-md-3 control-label">Category:</label>
				<p class="col-md-9 form-control-static">
					<span>{{taskCategory}}</span>
				</p>
			</div>
			<div class="form-group">
				<label class="col-md-3 control-label">Description:</label>
				<div class="col-md-9">
					<p class="col-md-9 form-control-static">
						<span>{{taskDescription}}</span>
					</p>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-3">
		<div class="card card-danger">
			<div class="card-header">
				Notes
			</div>
			<ul class="list-group">
				<li class="list-group-item">Complete this action once the work has been completed.</li>
			</ul>
		</div>
	</div>
</div>
