<ng-container *ngIf="!archivedWebsiteUrls || archivedWebsiteUrls.length === 0; else showInactiveUrls">
  <div class="row">
    <div class="col-md-12">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Inactive Urls
            <i *ngIf="readOnlyReasons" class="fas fa-lock text-danger ms-2" triggers="mouseenter:mouseleave" [ngbPopover]="readOnlyReasons"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No inactive urls</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<ng-template #showInactiveUrls>
  <div class="row">
    <div class="col-md-12">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              Inactive Urls
              <i *ngIf="readOnlyReasons" class="fas fa-lock text-danger ms-2" triggers="mouseenter:mouseleave" [ngbPopover]="readOnlyReasons"></i>
            </th>
            <th>Created</th>
            <th>Archived</th>
          </tr>
        </thead>
        <tbody *ngFor="let archivedWebsiteUrl of archivedWebsiteUrls">
          <tr>
            <td>
              <button class="btn btn-danger dropdown-toggle me-4" type="button" data-toggle="dropdown" [disabled]="restrictions.includes(customerCampaignRestrictions_ReadOnly)">
                Archived
              </button>
              <ul class="dropdown-menu" role="menu">
                <li>
                  <button class="btn-link dropdown-item" (click)="updateWebsiteUrlStatus(archivedWebsiteUrl, websiteUrlStatuses_New)">New</button>
                </li>
                <li>
                  <button class="btn-link dropdown-item" (click)="updateWebsiteUrlStatus(archivedWebsiteUrl, websiteUrlStatuses_Limited)">Limited</button>
                </li>
                <li>
                  <button class="btn-link dropdown-item" (click)="updateWebsiteUrlStatus(archivedWebsiteUrl, websiteUrlStatuses_Active)">Active</button>
                </li>
                <div class="dropdown-divider"></div>
                <li>
                  <button class="btn-link dropdown-item disabled">Archive</button>
                </li>
              </ul>
              {{ archivedWebsiteUrl.Url }}
            </td>
            <td>
              {{ archivedWebsiteUrl.InsertedDate | dateText: 'MM/DD/YYYY' }}
            </td>
            <td>
              <ng-container *ngIf="archivedWebsiteUrl.LatestArchiveDate">{{ archivedWebsiteUrl.LatestArchiveDate | dateText: 'MM/DD/YYYY' }}</ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>