<div class="card">
  <div class="card-header">
    Customer Assignments
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="customerId" class="control-label">Customer ID</label>
          <input [ngModel]="customerIdFilter()" (ngModelChange)="customerIdFilter($event)"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" name="customerId" class="form-control"
            placeholder="Enter Customer ID" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Assigned User</label>
          <select select2 [ngModel]="assignedUserFilter()" (ngModelChange)="assignedUserFilter($event)"
            [ngModelOptions]="{ standalone: true }" class="form-control">
            <option [ngValue]="undefined">Select a User...</option>
            <option *ngFor="let option of assignedUsers()" [ngValue]="option">{{ option }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Team</label>
          <select select2 [ngModel]="customerTeamFilter()" (ngModelChange)="customerTeamFilter($event)"
            [ngModelOptions]="{ standalone: true }" class="form-control">
            <option [ngValue]="undefined">Select a Team...</option>
            <option *ngFor="let option of customerTeams()" [ngValue]="option">{{ option }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label d-block">&nbsp;</label>
          <button (click)="getCurrentAssignments()" class="btn btn-primary">
            <i class="fa fa-search"></i>
            Search
          </button>
          <button [disabled]="!isFilterApplied()" (click)="clearSearch()" type="submit" class="btn btn-link">Clear
            Search</button>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12">
        <app-table [items]="filteredCustomerAssignments()" [pageSize]="20">
          <ng-template #tableHeader>
            <tr>
              <th sort field="CustomerId">Customer ID</th>
              <th sort field="Customer.Name">Customer Name</th>
              <th sort field="CustomerTeam.Name">Team</th>
              <th sort field="UserFullName">Assigned User</th>
              <th sort desc field="ExpirationDate">Expiration Date</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-assignment>
            <tr>
              <td>
                <button (click)="openCustomer(assignment)" class="btn btn-primary">
                  <i class="fas fa-external-link-square-alt"></i>
                  <span>{{assignment.CustomerId}}</span>
                </button>
              </td>
              <td>{{assignment.CustomerName}}</td>
              <td>{{assignment.CustomerTeam.Name}}</td>
              <td>{{assignment.UserFullName}}</td>
              <td>{{assignment.ExpirationDate | dateText: 'M/DD/YYYY h:mm a' }}</td>
              <td>
                <button (click)="endAssignment(assignment)" class="btn btn-danger">
                  <i class="fas fa-trash-alt"></i>
                  Unassign
                </button>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>