import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { TicketService } from '../../../services/ticket.service';
import { PartnerUserService } from '../../../services/partner-user.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-customerservice-createticketbatch',
  templateUrl: './CreateTicketBatch.component.html'
})
export class CreateTicketBatchComponent implements OnInit {
  loading = false;
  ticketForm: UntypedFormGroup;
  partnerUsers: Boo.Objects.PartnerUser[] = [];
  ticketTypes: Boo.Objects.TicketType[] = [];
  invalidCustomers: number[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private ticketService: TicketService,
    private partnerUserService: PartnerUserService,
    private sessionStorageService: SessionStorageService) { }

  ngOnInit() {
    this.loading = true;
    this.ticketForm = this.fb.group({
      ticketTypeId: [null, [Validators.required]],
      assignedUserId: [null],
      note: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      customerIds: [null, [Validators.required]]
    });

    forkJoin([
      this.partnerUserService.getByUserLevel(Boo.Objects.Enums.UserLevelEnum.CustomerService),
      this.sessionStorageService.getStaticData()
      ])
      .subscribe(([partnerUsers, staticData]) => {
        this.partnerUsers = this.sortUsers(partnerUsers);
        this.ticketTypes = staticData.TicketTypes.filter(x => x.IsUIVisible && x.IsActive);
        this.loading = false;
    });
  }

  createBatch(): void {
    if (!this.ticketForm.valid) {
      toastr.error('Please fix the errors on the screen');
      return;
    }

    this.loading = true;
    const f = this.ticketForm.value;
    const parsedCustomerIds = this.parseCSV(f.customerIds);
    const ticketTypeName = this.ticketTypes.find(x => x.TicketTypeId === f.ticketTypeId).Name;

    this.ticketService.createTicketBatch(parsedCustomerIds, f.ticketTypeId, f.startDate, f.note, f.assignedUserId)
      .subscribe(res => {
        if (res.InvalidCustomers.length) {
          toastr.error(`(${res.InvalidCustomers.length}) invalid customers`, 'Error', { timeOut: 0 });
          this.invalidCustomers.push(...res.InvalidCustomers);
        }

        toastr.success(`(${res.TicketsCreated}) ${ticketTypeName} tickets created`, 'Success', { timeOut: 0 });
        this.ticketForm.disable();
        this.loading = false;
      }, err => toastr.error(err));
  }

  reset(): void {
    this.ticketForm.reset();
    this.ticketForm.enable();
    this.invalidCustomers = [];
  }

  private parseCSV(str: string): number[] {
    return str
      .replace(/\s/g, '')
      .split(',')
      .map(x => parseInt(x, 10))
      .filter(x => !isNaN(x));
  }

  private sortUsers(users: Boo.Objects.PartnerUser[]): Boo.Objects.PartnerUser[] {
    // Some last names in the db start with a blank space. This helps the sorter work more accurately
    users.map(x => x.LastName = x.LastName.replace(/\s+/g, ''));
    return users.sort((a, b) => a.LastName.localeCompare(b.LastName));
  }
}
