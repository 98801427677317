import { Component } from '@angular/core';
import System from 'framework/System';
import permissions from 'app/models/Permissions';
import BaseComponent from '../BaseComponent';
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';

@Component({
	selector: 'app-components-ticket-customersynopsis',
	templateUrl: './CustomerSynopsis.component.html'
})
export class CustomerSynopsisComponent extends BaseComponent {
    public isEnabled: boolean;
    public customerSynopsis: KnockoutObservable<string>;
    public canEditCustomerSynopsis: KnockoutObservable<boolean>;
    isOpen = false;

    public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        return super.activate(params).then(() => {
            this.isEnabled = params.canEdit;

            this.customer.Synopsis.extend({
                maxLength: 4000,
            });
            
            this.validation = ko.validatedObservable([
                this.customer.Synopsis
            ]);
        });
    }

    public validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
        if (!this.isEnabled || !this.canEditCustomerSynopsis()) {
            return System.resolvedPromise<IValidatedResult>({ isValid: true, errorMessages: [] });
        }
        return super.validate(saveType);
    }

    public save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData> {
        return super.save(saveType)
            .then(() => {
                return {
                    customer: {
                        Synopsis: this.customer.Synopsis()
                    }
                };
            });
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        this.canEditCustomerSynopsis = ko.observable((<Launchpad>launchpad).hasPermission(partner, user.PartnerUsers, permissions.CanUpdateSynopsis, user));
        return true;
    }
}
