import Check from 'framework/Check';
import SeoContentSurvey from 'app/models/typescript/SeoContentSurvey';
import System from 'framework/System';
import { SeoContentSurveyService } from '../../../services/seo-content-survey.service';
import Utils from '../../../shared/utils';

export default class BaseSeoContentSurveyTab implements IActivatable {
    public canClose: KnockoutObservable<boolean>;
    public canEdit: KnockoutObservable<boolean>;
    public customerId: number;
    public isLoading: KnockoutObservable<boolean>;
    public seoContentSurvey: KnockoutObservable<SeoContentSurveyObservable>;

    protected priorityViewApi: app.interfaces.IPriorityViewPublicApi;

    constructor(public seoContentSurveyService: SeoContentSurveyService) { }

    public activate(params: any): JQueryPromise<void> {
        Check.isNotEmpty(params.customerId, 'This tab needs to have a customerId.');
        Check.isNotEmpty(params.seoContentSurvey, 'This tab needs SEO content.');
        Check.isNotEmpty(params.canClose, 'This tab needs to know if it canClose.');
        Check.isNotEmpty(params.canEdit, 'This tab needs to know if it canEdit.');

        this.customerId = params.customerId;
        this.seoContentSurvey = params.seoContentSurvey;
        // the survey could come in empty, and needs a customer id
        this.seoContentSurvey().CustomerId(this.customerId);
        this.canClose = params.canClose;
        this.canClose(this.canClose() && params.priorityViewApi);
        this.canEdit = params.canEdit;

        this.priorityViewApi = params.priorityViewApi;
        this.isLoading = ko.observable(false);

        return System.emptyPromise();
    }

    public cancel(): void {
        if (this.canClose()) {
            this.priorityViewApi.reject();
        }
    }

    public loadSeoContentSurvey(): JQueryPromise<void> {
        return Utils.wrapDfd(this.seoContentSurveyService.getByCustomerId(this.customerId))
            .then((seoContentSurvey: Boo.Objects.SeoContentSurvey) => {
                this.seoContentSurvey(ko.mapping.fromJS(seoContentSurvey || new SeoContentSurvey()));

                if (!this.seoContentSurvey().CustomerId()) {
                    this.seoContentSurvey().CustomerId(this.customerId);
                }
            })
            .fail((displayMessage: string) => {
                toastr.error(displayMessage);
            })
            .always(() => {
                this.isLoading(false);
                this.seoContentSurvey.notifySubscribers();
            });
    }

    protected save(reasonForUpdate: string, sectionUpdated: string, successMessage: string): JQueryPromise<void> {
        this.isLoading(true);
        return Utils.wrapDfd(this.seoContentSurveyService.save(ko.mapping.toJS(this.seoContentSurvey), reasonForUpdate, sectionUpdated))
            .then(() => {
                // Reload the data because we may have had an insert and we don't want to double insert the records (the id would still be 0).
                this.loadSeoContentSurvey();
                toastr.success(successMessage);
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading(false);
                if (this.canClose()) {
                    this.priorityViewApi.resolve();
                }
            });
    }
}
