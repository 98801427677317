<div class="row">
	<div class="col-md-12">
		<div class="card">
			<div class="card-header">
				Help Next Customer
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-12 text-center">
						<em class="text-muted" style="font-size: 24px;">We connect with our customers, share the wins, and do what we say.</em>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<compose Selector="app-components-customerservice-performancemetrics"></compose>
						<div class="help-next-customer-container">
							<compose Selector="app-components-customerservice-customerpriorityassignment"></compose>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				Assigned Tickets
			</div>
			<div class="card-body">
				<compose Selector="app-components-customerservice-assignedtickets"></compose>
			</div>
		</div>
	</div>
</div>
