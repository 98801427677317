<div [loadingOverlay]="isLoading()">
	<div class="row">
		<div [class.col-md-7]="hasRevisions()" class="col-md-12">
			<div class="card">
				<div class="card-header">
					<h4>Customer Information</h4>
				</div>
				<div class="card-body">
					<div [hidden]="!showComment" class="alert alert-warning">
						<strong>Special Instructions for this URL:</strong>
						<br/>
						<br/>
						<span [innerHtml]="comment"></span>
					</div>
					<ul class="list-group">
						<li class="list-group-item">
							<strong>Partner Name</strong>
							<span class="float-end" name="partnerName">{{partnerName}}</span>
						</li>
						<li class="list-group-item">
							<strong>Company Name</strong>
							<span class="float-end">{{companyName}}</span>
						</li>
						<li class="list-group-item">
							<strong>Full URL</strong>
							<a [externalLink]="{ url: fullUrl, text: fullUrl }" class="float-end" name="fullUrl" rel="noreferrer"></a>
						</li>
					</ul>
					<ul [hidden]="!showCsrNotes" class="list-group">
						<li class="list-group-item">
							<strong>Notes from CSR</strong>
							<textarea [(ngModel)]="csrNotes" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" style="height: auto;" class="form-control" name="csrNotes"></textarea>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div [hidden]="!hasRevisions()" [class.col-md-5]="hasRevisions()" class="animated slideInRight">
			<div class="card card-info">
				<div class="card-header">
					<h4>Revision Information</h4>
				</div>
				<div class="card-body">
					<ul class="nav nav-tabs">
						<li class="nav-item">
							<a class="active" href="#notes" data-toggle="tab">
								<i class="fa fa-comments"></i>
								Comments
								<span style="margin-left:15px;" class="badge bg-secondary">{{noteCount()}}</span>
							</a>
						</li>
						<li class="nav-item">
							<a href="#files" class="nav-link" data-toggle="tab">
								<i class="fa fa-file"></i>
								Files
								<span style="margin-left:15px;" class="badge bg-secondary">{{fileCount()}}</span>
							</a>
						</li>
					</ul>
					<div class="tab-content">
						<div class="tab-pane active" id="notes">
							<div class="row">
								<div class="col-md-12">
									<ng-container *ngIf="!hasComments()">
										<div class="alert alert-info">
											<strong>No Comments</strong>
											Comments are created when tasks are rejected.
										</div>
									</ng-container>
									<ul class="list-group">
										<ng-container *ngFor="let $data of notes()">
											<li class="list-group-item">
												<div class="row">
													<div class="col-md-8">
														<div>
															<strong>{{$data.FromUserFullName}}</strong>
														</div>
														<small>
															to
															<span>{{$data.ToUserFullName}}</span>
														</small>
													</div>
													<div class="col-md-4 text-end">
														<small>{{$data.InsertedDate | dateText }}</small>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
														<div class="list-group-item-text">
															<div style="padding-top:15px; padding-bottom:15px;">{{$data.Note}}</div>
														</div>
													</div>
												</div>
											</li>
										</ng-container>
									</ul>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="files">
							<div class="col-md-12">
                <app-table [items]="files" [exportFilename]="'onsite-recommendation-files'">
                  <ng-template #tableHeader>
                    <tr>
                      <th export sort field="Name">Name</th>
                      <th export sort field="InsertedDate">Upload Date</th>
                      <th export field="S3Url"></th>
                    </tr>
                  </ng-template>
                  <ng-template #tableBody let-file>
                    <tr>
                      <td>{{file.Name}}</td>
                      <td>{{file.InsertedDate | dateText }}</td>
                      <td style="width:50px;">
                        <a [externalLink]="{ url: file.S3Url, text: 'View File' }" class="btn btn-primary"></a>
                      </td>
                    </tr>
                  </ng-template>
                </app-table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<app-components-specialist-actioncomponents-changed-keywords [websiteUrl]="this.input.WebsiteUrl" [since]="currentAction().Task.InsertedDate"></app-components-specialist-actioncomponents-changed-keywords>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header">
					<div class="float-end">
						<ng-container *ngIf="hasRevisions()">
							Revisions
							<span class="badge alert-danger">{{revisionInformation().NumberOfRevisions}}</span>
						</ng-container>
					</div>
					<h4>Text Analysis</h4>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<div>
								<ng-container *ngIf="latestNote()">
									<div class="alert alert-warning">
										<div class="row">
											<div class="col-md-8">
												<div>
													<strong>{{latestNote().FromUserFullName}}</strong>
												</div>
												<small>
													to
													<span>{{latestNote().ToUserFullName}}</span>
												</small>
											</div>
											<div class="col-md-4 text-end">
												<small>{{latestNote().InsertedDate | dateText }}</small>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<div class="list-group-item-text">
													<div style="padding-top:15px; padding-bottom:15px;">{{latestNote().Note}}</div>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
							</div>
							<table id="textAnalysisTable" border="0" class="table table-striped table-bordered table-sm">
								<thead>
									<tr>
										<th>Keyword(s)</th>
										<th>Area</th>
										<th>Title</th>
										<th>Description</th>
										<th>H1</th>
										<th>H2-H6</th>
										<th>Body</th>
										<th>Bold/Strong</th>
										<th>Image Alt</th>
									</tr>
								</thead>
								<tbody>
									<ng-container *ngFor="let $data of textAnalysis()">
										<tr>
											<td name="keyword">{{$data.Keyword}}</td>
											<td name="area">{{$data.Area}}</td>
											<td>{{$data.TitleCount}}</td>
											<td>{{$data.DescriptionCount}}</td>
											<td>{{$data.H1Count}}</td>
											<td>{{$data.H2H6Count}}</td>
											<td>{{$data.BodyCount}}</td>
											<td>{{$data.StrongCount}}</td>
											<td>{{$data.ImgAltCount}}</td>
										</tr>
									</ng-container>
								</tbody>
							</table>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label class="control-label">Current Title Text</label>
								<textarea [ngModel]="currentTitle()" (ngModelChange)="currentTitle($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" cols="65" rows="3" style="height:65px;" class="form-control" name="currentTitle"></textarea>
							</div>
							<div class="form-group">
								<label class="control-label">New Title Text</label>
								<textarea [dataValidation]="{placement:'top', value: newTitle }" [ngModel]="newTitle()" (ngModelChange)="newTitle($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" cols="65" rows="3" style="height:65px;" class="form-control" name="newTitle"></textarea>
								<span class="help-block">{{newTitleCharacterCount()}}</span>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label class="control-label">Current Description Text</label>
								<textarea [ngModel]="currentDescription()" (ngModelChange)="currentDescription($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" cols="65" rows="3" style="height:65px;" class="form-control" name="currentDescription"></textarea>
							</div>
							<div class="form-group">
								<label class="control-label">New Description Text</label>
								<textarea [dataValidation]="{ placement: 'top', value: newDescription }" [ngModel]="newDescription()" (ngModelChange)="newDescription($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" cols="65" rows="3" style="height:65px;" class="form-control" name="newDescription"></textarea>
								<span class="help-block">{{newDescriptionCharacterCount()}}</span>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<table id="keywordDensityTable" border="0" class="table table-striped table-bordered table-sm">
								<thead>
									<tr>
										<th>Keyword(s)</th>
										<th>Title Prominent</th>
										<th>Title Count</th>
										<th>Desc. Prominent</th>
										<th>Desc. Count</th>
									</tr>
								</thead>
								<tbody>
									<ng-container *ngFor="let $data of keywordDensity()">
										<tr>
											<td>{{$data.keyword}}</td>
											<td>{{$data.prominentTitle ? 'True' : 'False'}}</td>
											<td>{{$data.titleCount}}</td>
											<td>{{$data.prominentDescription ? 'True' : 'False'}}</td>
											<td>{{$data.descriptionCount}}</td>
										</tr>
									</ng-container>
								</tbody>
							</table>
						</div>
					</div>
					<div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label class="control-label">Current H1-H6 Tags</label>
									<textarea [ngModel]="currentHeadingTags()" (ngModelChange)="currentHeadingTags($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" style="height:65px;" class="form-control" name="currentHeadingTags"></textarea>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label class="control-label">New H1 Tag</label>
									<textarea [dataValidation]="{ placement: 'top', value: newH1Tag }" [ngModel]="newH1Tag()" (ngModelChange)="newH1Tag($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" style="height:65px;" class="form-control" name="newH1"></textarea>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label class="control-label">New H2 Tag</label>
									<textarea [dataValidation]="{ placement: 'top', value: newH2Tag }" [ngModel]="newH2Tag()" (ngModelChange)="newH2Tag($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" style="height:65px;" class="form-control" name="newH2"></textarea>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label class="control-label">Current Alt Image Tags</label>
									<textarea [ngModel]="currentAltImageTags()" (ngModelChange)="currentAltImageTags($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" class="form-control" name="currentAltImageTags"></textarea>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label class="control-label">New Alt Image Tag</label>
									<textarea [dataValidation]="{ placement: 'top', value: newAltImageTag }" [ngModel]="newAltImageTag()" (ngModelChange)="newAltImageTag($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" name="newAltImageTag"></textarea>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label class="control-label">Onsite Changes File</label>
								<div [dataValidation]="{ value: filename, placement: 'right' }" style="padding: 3px;">
									<input [fileReader]="{ data: base64Data, filename: filename, file: file, accept: 'All Files (*.*)|*.*' }" type="file" class="btn btn-outline-secondary"/>
									<span class="text-success">{{uploadMessage()}}</span>
								</div>
							</div>
						</div>
						<div class="col-md-6 text-end">
							<p>
								<strong>Time Elapsed:</strong>
								&nbsp;
								<span><shared-components-timer [timer]="timer"></shared-components-timer></span>
							</p>
							<p>
								<button (click)="timer.toggle()" class="btn btn-primary">
									<span [hidden]="timer.isPaused">
										<i class="fa fa-pause"></i>
										Pause Timer
									</span>
									<span [hidden]="!timer.isPaused">
										<i class="fa fa-play"></i>
										Start Timer
									</span>
								</button>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<app-floating-panels>
		<app-floating-panel [displayConfig]="{ title: 'Information', icon: 'fa-info-circle', size: 'floating-panel-md' }">
			<compose *floatingPanelContent Selector="app-components-specialist-actioncomponents-information" [ActivateOptions]="{
				customer: input.Customer,
				taskDetails: this.currentAction().TaskDetails
			}">
			</compose>
		</app-floating-panel>
		<app-floating-panel [displayConfig]="{ title: 'Locations', icon: 'fa-map-marker', size: 'floating-panel-lg' }">
			<app-components-shared-view-local-profiles *floatingPanelContent [customer]="input.Customer"></app-components-shared-view-local-profiles>
		</app-floating-panel>
		<app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: input.TaskNotes.length }">
			<app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="input.TaskNotes">
			</app-components-specialist-actioncomponents-comments>
		</app-floating-panel>
	</app-floating-panels>
</div>
