import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-navigation-manager',
  templateUrl: './Manager.component.html'
})
export class ManagerComponent implements OnInit, OnDestroy, IActivatable {
  isLoading: boolean = false;
  showToolsSubMenu: boolean = false;
  showPartnerSubMenu: boolean = false;
  showUserSubMenu: boolean = false;
  isToolsVisible: boolean = false;

  private urlChangedHandler: (newHash: string) => void = (newHash: string) => {
    this.urlUpdated(newHash);
  };

  private toolsPages: string[] = [
    'manager/linktypeactivation',
    'manager/registrationphonenumbers',
    'manager/premiumbacklink',
    'manager/merchantfields',
    'manager/taskimportexport',
    'manager/republishpost',
    'manager/blacklistdomains',
    'manager/configurepartnerpricingtool',
    'manager/configureqatasks',
    'manager/notifications'
  ];

  private partnerPages: string[] = [
    'manager/managepartners',
    'manager/transferaccounts',
    'manager/leadsources'
  ];

  private userPages: string[] = [
    'manager/manageusers',
    'manager/taskstewardships',
    'manager/organizationalteams'
  ];

  constructor(private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    amplify.subscribe(launchpad.config.events.urlChanged, this.urlChangedHandler);
  }

  ngOnDestroy(): void {
    amplify.unsubscribe(launchpad.config.events.urlChanged, this.urlChangedHandler);
  }

  activate(): JQueryPromise<void> {
    return Utils.wrapDfd(this.sessionStorageService.getPartner())
      .then((partner: Boo.Objects.Partner) => {
        if (partner.PartnerId === 1) {
          this.isToolsVisible = true;
        } else {
          this.isToolsVisible = false;
        }
      });
  }

  private urlUpdated(newHash: string): void {
    this.showToolsSubMenu = this.toolsPages.indexOf(newHash) > -1;
    this.showPartnerSubMenu = this.partnerPages.indexOf(newHash) > -1;
    this.showUserSubMenu = this.userPages.indexOf(newHash) > -1;
  }
}
