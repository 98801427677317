import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[searchText]'
})
export class SearchTextDirective implements AfterViewInit {
  @Input('searchText') public Settings: { regexString: KnockoutObservable<string>, performSearch: KnockoutObservable<boolean>, caretPosition: KnockoutObservable<number>, selectionStart: KnockoutObservable<number>, selectionEnd: KnockoutObservable<number>, selectedText: KnockoutObservable<string> };

  private $textArea: JQuery;
  private textArea: HTMLTextAreaElement;
  constructor(elementRef: ElementRef) {
    this.textArea = elementRef.nativeElement;
    this.$textArea = $(this.textArea);
  }

  public ngAfterViewInit(): void {
    this.$textArea.on('blur', () => {
      this.Settings.caretPosition(this.textArea.selectionEnd);
    });

    this.$textArea.on('select', () => {
      let selectionStart = this.textArea.selectionStart;
      let selectionEnd = this.textArea.selectionEnd;
      let selectedText = this.textArea.value.substring(this.textArea.selectionStart, this.textArea.selectionEnd);

      //Remove extra whitespace on end
      const textTrimEnd = window.launchpad.utils.trimEnd(selectedText);
      selectionEnd = selectionEnd - (selectedText.length - textTrimEnd.length);
      selectedText = textTrimEnd;

      //Remove extra Whitespace at beginning
      const textTrimStart = window.launchpad.utils.trimStart(selectedText);
      selectionStart = selectionStart - (selectedText.length - textTrimStart.length);
      selectedText = textTrimStart;

      this.Settings.selectionStart(selectionStart);
      this.Settings.selectionEnd(selectionEnd);
      this.Settings.selectedText(selectedText);
    });

    this.Settings.performSearch.subscribe(() => {
      if (this.Settings.performSearch()) {
        this.performSearch();
        this.Settings.performSearch(false);
      }
    });
  }

  public performSearch(): void {
    const selectRange = (elem: any, start: any, end: any) => {
      elem.focus();
      elem.setSelectionRange(start, end);
    };

    const regex = new RegExp(this.Settings.regexString(), 'i');
    const caretPosition = this.Settings.caretPosition();
    const content = this.textArea.value;
    const contentAfterCaret = content.substring(caretPosition, content.length);

    let match = regex.exec(contentAfterCaret);
    if (match) {
      const selectionStart = match.index + caretPosition;
      const selectionEnd = match.index + match[0].length + caretPosition;
      selectRange(this.textArea, selectionStart, selectionEnd);
      this.Settings.selectionStart(selectionStart);
      this.Settings.selectionEnd(selectionEnd);
    } else {
      match = regex.exec(content);
      if (match) {
        selectRange(this.textArea, match.index, match.index + match[0].length);
        this.Settings.selectionStart(match.index);
        this.Settings.selectionEnd(match.index + match[0].length);
      } else {
        alert('No matches found.');
      }
    }
  }
}