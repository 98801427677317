import { Component } from '@angular/core';
import System from 'framework/System';

@Component({
	selector: 'app-components-validationchecklist',
	templateUrl: './ValidationChecklist.component.html'
})
export class ValidationChecklistComponent implements IActivatable {
    public items: IValidationChecklistItemObservable[];
    public mode: any;
    public css: { [key: string]: string };
    public completeValidator: KnockoutObservable<void> = ko.validatedObservable<void>().extend({
        validation: { validator: this.isComplete.bind(this), message: 'Checklist must be completed.' }
    });

    public activate(params: app.components.interfaces.IValidationChecklistActivateParams): JQueryPromise<void> {
        if (!params.items || !_.isArray(params.items)) {
            throw new Error('Invalid items for ChecklistViewModel');
        }

        // convert IChecklistItem[] to IChecklistItemObservable[] so that isComplete is always observable
        this.items = [];

        /* tslint:disable:typedef */
        for (let paramItem of params.items) {
            this.items.push({
                title: paramItem.title,
                isComplete: ko.observable(ko.utils.unwrapObservable(paramItem.isComplete))
            });
        }

        // send public api back to parent control
        if (params.apiCallback) {
            params.apiCallback(this.getPublicApi());
        }

        return System.emptyPromise();
    }

    public hasItems(): boolean {
        return this.items && this.items.length > 0;
    }

    public toggleItem(item: IValidationChecklistItemObservable): void {
        item.isComplete(!item.isComplete());
    };

    public isComplete(): boolean {
        return _.every(this.items, (item: IValidationChecklistItemObservable) => {
            return item.isComplete();
        });
    }

    protected getPublicApi(): app.components.interfaces.IValidationChecklistPublicApi {
        return {
            isComplete: this.isComplete.bind(this)
        };
    }
}

/**
 * Private interface to extend IChecklistItem to make isComplete observable
 */
interface IValidationChecklistItemObservable extends app.components.interfaces.IValidationChecklistItem {
    isComplete: KnockoutObservable<boolean>;
}
