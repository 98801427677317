import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CustomerFileService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/customerfiles`;

  constructor(private http: HttpClient) { }

  insert(customerFile: Boo.Objects.CustomerFile): Observable<Boo.Objects.CustomerFile> {
    return this.http.post<Boo.Objects.CustomerFile>(`${this.baseUrl}/Insert`, customerFile);
  }

  insertForTicket(customerFile: Boo.Objects.CustomerFile, ticketId: number): Observable<Boo.Objects.CustomerFile> {
    return this.http.post<Boo.Objects.CustomerFile>(`${this.baseUrl}/InsertForTicket`, {
        customerFile: customerFile,
        ticketId: ticketId
    });
  }

  getMany(customerId: number, section: number): Observable<Boo.Objects.CustomerFile[]> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('section', section);

    return this.http.get<Boo.Objects.CustomerFile[]>(`${this.baseUrl}/GetMany`, { params: params });
  }

  getManyForTicket(customerId: number, section: number, ticketId: number): Observable<Boo.Objects.CustomerFile[]> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('section', section)
      .set('ticketId', ticketId);

    return this.http.get<Boo.Objects.CustomerFile[]>(`${this.baseUrl}/GetManyForTicket`, { params: params });
  }

  update(customerFile: Boo.Objects.CustomerFile): Observable<Boo.Objects.CustomerFile> {
    return this.http.post<Boo.Objects.CustomerFile>(`${this.baseUrl}/Update`, customerFile);
  }
}
