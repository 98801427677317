import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { PartnerSeoWorkDefaultsService } from '../../../../services/partner-seo-work-defaults.service';
import { finalize } from 'rxjs/operators';
import PartnerSeoWorkDefault = Boo.Objects.Work.PartnerSeoWorkDefault;
import SeoWorkDefault = Boo.Objects.Work.SeoMinute.SeoWorkDefault;
import SeoWorkTaskType = Boo.Objects.Work.SeoMinute.SeoWorkTaskType;
import PartnerProductSummary = Boo.Objects.Work.PartnerProductSummary;

@Component({
  selector: 'app-components-manager-partner-seo-work-defaults',
  templateUrl: './partner-seo-work-defaults.component.html',
  styleUrls: ['./partner-seo-work-defaults.component.scss']
})
export class PartnerSeoWorkDefaultsComponent implements OnInit {
  @Input() partner: Boo.Objects.PartnerMinimal;
  @Input() isReadOnly: boolean;
  @ViewChild('newAllocationInput', {static: false}) newAllocationInput: ElementRef;
  @ViewChild('testAllocationInput', {static: false}) testAllocationInput: ElementRef;
  dfd: any;
  math: Math = Math;
  isBoostabilityPartner: boolean = false;
  isLoading: boolean = false;
  isInitialized: boolean = false;
  title: string = 'Partner Default Task Type Allocations';
  hours: number[] = [];
  activeTab: number = 0;
  newAllocationTabIndex: number = 0;
  testAllocationTabIndex: number = -1;
  copyAllocationsTabIndex: number = -2;
  newAllocationHours: number = null;
  testHours: number = null;
  testedHours: number = null;
  isTesting: boolean = false;
  seoWorkDefaultsToTest: SeoWorkDefault[] = [];
  reservedMinutesToTest: number = 0;
  partnerDefaults: PartnerSeoWorkDefault[];
  partnerSeoMinuteReservationDefaults: Boo.Objects.Work.PartnerSeoMinuteReservationDefault[];
  taskTypes: SeoWorkTaskType[];
  products: PartnerProductSummary[];
  productGroups: ProductGroup[] = [];
  configuredPartners: Boo.Objects.PartnerFragment[] = [];
  selectedPartner: Boo.Objects.PartnerFragment = null;
  unusedAllocations: number[] = [];

  constructor(private partnerSeoWorkDefaultsService: PartnerSeoWorkDefaultsService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.isBoostabilityPartner = this.partner.PartnerId === Boo.Objects.Enums.PartnerEnum.Boostability;
    this.title = `Partner Default Task Type Allocations - ${this.partner.PartnerName}`;
    forkJoin([this.partnerSeoWorkDefaultsService.get(this.partner.PartnerId),
      this.partnerSeoWorkDefaultsService.getPartnerSeoMinuteReservationDefaults(this.partner.PartnerId),
      this.partnerSeoWorkDefaultsService.getTaskTypes(this.partner.PartnerId),
      this.partnerSeoWorkDefaultsService.getProducts(this.partner.PartnerId),
      this.partnerSeoWorkDefaultsService.getConfiguredPartners()])
      .pipe(finalize(() => { this.isLoading = false; this.isInitialized = true; }))
      .subscribe(([partnerDefaults, partnerSeoMinuteReservationDefaults, taskTypes, products, configuredPartners]) => {
        this.configuredPartners = configuredPartners;
        this.partnerDefaults = partnerDefaults;
        this.partnerSeoMinuteReservationDefaults = partnerSeoMinuteReservationDefaults;
        this.taskTypes = taskTypes;
        this.products = products
          .sort((a, b) => b.HourQuantity - a.HourQuantity)
          .sort((a, b) => a.StoreProductName.localeCompare(b.StoreProductName));

        this.hours.push(
          ...Array.from(
            new Set([
              ...this.partnerDefaults.map(item => item.Hours),
              ...this.partnerSeoMinuteReservationDefaults.map(x => x.Hours)
            ])
          )
          .sort((a, b) => a - b)
        );

        this.productGroups = [...new Set(this.products.map(item => item.HourQuantity))]
          .map(x => ({ Hours: x, Products: this.products.filter(y => y.HourQuantity === x) } as ProductGroup))
          .sort((a, b) => a.Hours - b.Hours);

        this.detectUnusedAllocations();

        if ( this.hours.length > 0) {
          this.setActiveTab(this.hours[0]);
        }
      });
  }

  copyDefaults(): void {
    if (!this.selectedPartner) {
      toastr.error('Please select a partner to copy allocations.');
      return;
    }

    // Clear allocations before copying from another partner
    // This ensures that the ui can reload properly
    this.partnerDefaults = [];
    this.partnerSeoMinuteReservationDefaults = [];
    this.hours = [];
    this.seoWorkDefaultsToTest = [];
    this.reservedMinutesToTest = 0;

    this.isLoading = true;

    forkJoin([this.partnerSeoWorkDefaultsService.get(this.selectedPartner.PartnerId),
      this.partnerSeoWorkDefaultsService.getPartnerSeoMinuteReservationDefaults(this.selectedPartner.PartnerId)])
      .pipe(finalize(() => { this.isLoading = false; this.isInitialized = true; }))
      .subscribe(([partnerDefaults, partnerSeoMinuteReservationDefaults]) => {
        this.partnerDefaults = partnerDefaults.map(partnerDefault => {
          partnerDefault.PartnerSeoWorkDefaultId = 0;
          partnerDefault.PartnerId = this.partner.PartnerId;
          return partnerDefault;
        });
        
        this.partnerSeoMinuteReservationDefaults = partnerSeoMinuteReservationDefaults.map(reservationDefault => {
          reservationDefault.PartnerId = this.partner.PartnerId;
          return reservationDefault;
        });

        this.hours = [...new Set(this.partnerDefaults.map(item => item.Hours))];
        this.detectUnusedAllocations();

        toastr.info(`Allocations copied from ${this.selectedPartner.Name}`);
        this.selectedPartner = null;
      }, () => toastr.error(`Allocations could not be copied from ${this.selectedPartner.Name}`));
  }

  close(): void {
    if (this.dfd) {
      this.dfd.resolve();
    }
  }

  save(): void {
    if (this.validate()) {
      this.isLoading = true;
      forkJoin([this.partnerSeoWorkDefaultsService.save(this.partner.PartnerId, this.partnerDefaults.filter(x => x.TaskCount > 0)),
        this.partnerSeoWorkDefaultsService.saveDefaultMinuteReservations(this.partner.PartnerId, this.partnerSeoMinuteReservationDefaults.filter(x => x.Quantity > 0))])
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(() => {
          toastr.success('Partner default task type allocations saved successfully.');
          if (this.dfd) {
            this.dfd.resolve();
          }
        });
    }
  }

  validate(): boolean {
    let isValid = true;
    let invalidAllocations: number[] = [];
    this.hours.forEach(hours => {
      let minutesToReserve = this.partnerSeoMinuteReservationDefaults.find(x => x.Hours === hours)?.Quantity ?? 0;
      let defaults = this.partnerDefaults.filter(x => x.Hours === hours && x.TaskCount > 0);
      let totalMinutes = defaults.map(item => item.TaskType.BillableMinutes * item.TaskCount).reduce((a, b) => a + b, 0);
      if ((totalMinutes + minutesToReserve) !== hours * 60) {
        invalidAllocations.push(hours);
        isValid = false;
      }
    });

    if (!isValid) {
      toastr.error(`Cannot save allocations. The following hours have not been correctly allocated: ${invalidAllocations.join(', ')}`);
      if (invalidAllocations.indexOf(this.activeTab) === -1) {
        this.setActiveTab(invalidAllocations[0]);
      }
    }

    return isValid;
  }

  setActiveTab(index: number): void {
    this.activeTab = index;

    // setTimeout is currently required for this to work well, although another solution is welcome
    switch (index) {
      case this.newAllocationTabIndex:
        setTimeout(() => { this.newAllocationInput.nativeElement.focus(); }, 0);
        break;
      case this.testAllocationTabIndex:
        setTimeout(() => { this.testAllocationInput.nativeElement.focus(); }, 0);
        break;
      default:
    }
  }

  removeHourAllocation(hours: number): void {
    this.partnerDefaults = this.partnerDefaults.filter(x => x.Hours !== hours);
    this.partnerSeoMinuteReservationDefaults = this.partnerSeoMinuteReservationDefaults.filter(x => x.Hours !== hours);
    let index = Math.max(0, this.hours.indexOf(hours) - 1);
    this.hours = this.hours.filter(x => x !== hours);
    this.detectUnusedAllocations();
    this.setActiveTab(this.hours[index]);
    toastr.info(`Allocation for ${ hours } ${ hours > 1 ? 'hours' : 'hour'} removed.`);
  }

  removeUnusedAllocation(hours: number): void {
    this.partnerDefaults = this.partnerDefaults.filter(x => x.Hours !== hours);
    this.partnerSeoMinuteReservationDefaults = this.partnerSeoMinuteReservationDefaults.filter(x => x.Hours !== hours);
    this.hours = this.hours.filter(x => x !== hours);
    this.detectUnusedAllocations();
    toastr.info(`Allocation for ${ hours } ${ hours > 1 ? 'hours' : 'hour'} removed.`);
  }

  removeAllUnusedAllocations(): void {
    this.partnerDefaults = this.partnerDefaults.filter(x => !this.unusedAllocations.includes(x.Hours));
    this.partnerSeoMinuteReservationDefaults = this.partnerSeoMinuteReservationDefaults.filter(x => !this.unusedAllocations.includes(x.Hours));
    this.hours = this.hours.filter(x => !this.unusedAllocations.includes(x));
    this.detectUnusedAllocations();
    toastr.info(`All unused allocations removed.`);
  }

  addAllocation(): void {
    if (this.newAllocationHours) {
      this.isLoading = true;
      if (!this.hours.find(x => x === this.newAllocationHours)) {
        forkJoin([
          this.partnerSeoWorkDefaultsService.generateSeoWorkDefaults(this.partner.PartnerId, this.newAllocationHours, this.partnerDefaults.filter(x => x.TaskCount > 0)),
          this.partnerSeoWorkDefaultsService.getPartnerSeoMinuteReservationDefaults(this.partner.PartnerId)
        ])
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(([seoWorkDefaults, dbPartnerSeoMinuteReservationDefaults]) => {
          this.partnerDefaults.push(...this.generateNewAllocation(seoWorkDefaults, this.newAllocationHours));
          this.ensureReservationDefaultExists(this.newAllocationHours, this.partner.PartnerId, dbPartnerSeoMinuteReservationDefaults);
          this.hours.push(this.newAllocationHours);
          this.hours = this.hours.sort((a, b) => a - b);
          this.detectUnusedAllocations();
          this.setActiveTab(this.newAllocationHours);

          toastr.info(`Allocation generated for ${ this.newAllocationHours } ${ this.newAllocationHours > 1 ? 'hours' : 'hour'}`);
          this.newAllocationHours = null;
        });

        return;
      }
      this.setActiveTab(this.newAllocationHours);
      toastr.info(`An allocation for ${ this.newAllocationHours } ${ this.newAllocationHours > 1 ? 'hours' : 'hour'} exists.`);
      this.newAllocationHours = null;
      this.isLoading = false;
    }
  }

  ensureReservationDefaultExists(newAllocationHours: number, partnerId: number, dbPartnerSeoMinuteReservationDefaults: Boo.Objects.Work.PartnerSeoMinuteReservationDefault[]) {
    if (this.partnerSeoMinuteReservationDefaults.some(x => x.Hours === newAllocationHours)) {
      return;
    }
  
    let reservationDefault = dbPartnerSeoMinuteReservationDefaults.find(x => x.Hours === newAllocationHours);

    if (!reservationDefault) {
      reservationDefault = { 
        PartnerSeoMinuteReservationDefaultId: 0,
        PartnerId: partnerId,
        Hours: newAllocationHours, 
        Quantity: 0,
        InsertedByUserId: 0,
        InsertedDate: new Date()
      } as Boo.Objects.Work.PartnerSeoMinuteReservationDefault;
    }
  
    this.partnerSeoMinuteReservationDefaults.push(reservationDefault);
  }

  isAllocationValid(hours: number): boolean {
    let defaults = this.partnerDefaults.filter(x => x.Hours === hours);
  
    let allocatedMinutes = defaults.length ? defaults.map(item => item.TaskType.BillableMinutes * item.TaskCount).reduce((a, b) => a + b, 0) : 0;
    let reservedMinutes = this.partnerSeoMinuteReservationDefaults.find(x => x.Hours === hours)?.Quantity ?? 0;
    return allocatedMinutes + reservedMinutes === hours * 60;
  }

  isAllocated(hours: number): boolean {
    return this.hours.indexOf(hours) !== -1;
  }

  testAllocation(): void {
    if (this.testHours) {
      this.isTesting = true;
      this.seoWorkDefaultsToTest = [];
      forkJoin([
        this.partnerSeoWorkDefaultsService.generateSeoWorkDefaults(this.partner.PartnerId, this.testHours, this.partnerDefaults.filter(x => x.TaskCount > 0)),
        this.partnerSeoWorkDefaultsService.getPartnerSeoMinuteReservationDefaults(this.partner.PartnerId)
      ])
      .pipe(finalize(() => this.isTesting = false))
      .subscribe(([seoWorkDefaults, partnerSeoMinuteReservationDefaults]) => {
        this.seoWorkDefaultsToTest = seoWorkDefaults.sort((a: Boo.Objects.Work.SeoMinute.SeoWorkDefault, b: Boo.Objects.Work.SeoMinute.SeoWorkDefault) => a.TaskType.Name.localeCompare(b.TaskType.Name))
          .sort((a: Boo.Objects.Work.SeoMinute.SeoWorkDefault, b: Boo.Objects.Work.SeoMinute.SeoWorkDefault) => a.TaskType.BillableMinutes - b.TaskType.BillableMinutes);
        this.reservedMinutesToTest = partnerSeoMinuteReservationDefaults.find(x => x.Hours === this.testHours)?.Quantity ?? 0;
        this.testedHours = this.testHours;
      });
    }
  }

  addAllocationFromTestResults(): void {
    this.newAllocationHours = this.testedHours;
    this.addAllocation();
  }

  addAllocationFromProductGroup(productGroup: ProductGroup): void {
    this.newAllocationHours = productGroup.Hours;
    this.addAllocation();
  }

  clearResults(): void {
    this.seoWorkDefaultsToTest = [];
    this.reservedMinutesToTest = 0;
  }

  get canAddNewAllocation(): boolean {
    return this.testedHours && !this.hours.find(x => x === this.testedHours);
  }

  private detectUnusedAllocations(): void {
    this.unusedAllocations = this.hours.filter(hours => !this.productGroups.find(x => x.Hours === hours));
  }

  private generateNewAllocation(seoWorkDefaults: PartnerSeoWorkDefault[], hours: number): PartnerSeoWorkDefault[] {
    return seoWorkDefaults.map(x => { return this.partnerSeoWorkDefaultFactory(x, hours); });
  }

  private partnerSeoWorkDefaultFactory(seoWorkDefault: PartnerSeoWorkDefault, hours: number): Boo.Objects.Work.PartnerSeoWorkDefault {
    return {
      PartnerSeoWorkDefaultId: 0,
      PartnerId: this.partner.PartnerId,
      TaskTypeId: seoWorkDefault.TaskType.TaskTypeId,
      TaskType: {
        TaskTypeId: seoWorkDefault.TaskType.TaskTypeId,
        Name: seoWorkDefault.TaskType.Name,
        Description: seoWorkDefault.TaskType.Description,
        BillableMinutes: seoWorkDefault.TaskType.BillableMinutes
      },
      TaskCount: seoWorkDefault.TaskCount,
      Hours: hours
    } as PartnerSeoWorkDefault;
  }
}

class ProductGroup {
  Hours: number;
  Products: PartnerProductSummary[];
}
