import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import { TaskService } from '../../../services/task.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-ticket-customactionsreport',
  templateUrl: './CustomActionsReport.component.html'
})
export class CustomActionsReportComponent extends BaseComponent {
    launchpad = launchpad;
    completedCustomActions: Boo.Objects.Task[] = [];
    endDate: KnockoutObservable<string>;
    startDate: KnockoutObservable<string>;
    
    constructor(private taskService: TaskService) {
      super();
    }

    public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        this.startDate = ko.observable(moment().subtract(1, 'months').format('L'));
        this.endDate = ko.observable(moment(this.startDate()).add(1, 'months').format('L'));

        return super.activate(params);
    }

    public loadCompletedCustomActions(params: any): JQueryPromise<void> {
        return Utils.wrapDfd(this.taskService.getCustomActionsByCustomerIdAndDateRange(params.customer.CustomerId(), moment(this.startDate()), moment(this.endDate())))
            .then((tasks: Boo.Objects.Task[]) => {
                this.completedCustomActions = tasks;
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            });
    }

}
