import { MustacheTag } from "./mustache-tag.model";
import { MustacheTagTypes } from "./mustache-tag-types.model";

export class MustacheBooleanTag implements MustacheTag {
  type = MustacheTagTypes.boolean;
  key: string;
  subKeys: null;
  value: boolean;

  constructor(key: string) {
    this.key = key;
  }
}