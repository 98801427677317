import { Component, Input, OnInit } from '@angular/core';
import { LocalProfileService } from '../../../../services/local-profile.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-components-shared-view-local-profiles',
  templateUrl: './view-local-profiles.component.html'
})
export class ViewLocalProfilesComponent implements OnInit {
  @Input() customer: Boo.Objects.Customer;
  @Input() showInactiveProfiles: boolean = false;

  isLoading: boolean = false;
  localProfiles: Boo.Objects.LocalProfiles.LocalProfile[];

  constructor(
    private localProfileService: LocalProfileService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.load();
  }

  load() {
    (this.showInactiveProfiles ? this.localProfileService.byCustomerId(this.customer.CustomerId) :
      this.localProfileService.activeByCustomerId(this.customer.CustomerId))
    .pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(localProfiles => {
        this.localProfiles = localProfiles.sort(this.localProfileService.sort.bind(this));
    },
      (err) => toastr.error(err)
    );
  }
}