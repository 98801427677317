import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

/**
 * Sanitize HTML while preserving most of the formatting.
 * Angular already sanitizes content passed to [innerHtml], but it is significantly more strict than necessary.
 * If the HTML has no "fancy" formatting that needs to be preserved, Angular's default sanitizer works fine.
 */
@Pipe({
  name: 'purifyHtml'
})
export class PurifyHtmlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(text: any) {
    return this.domSanitizer.bypassSecurityTrustHtml(DOMPurify.sanitize(text));
  }
}