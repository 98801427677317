<div>
  <app-components-togglepanel title="Reporting Access" toggleBody="true">
    <app-table [items]="users" [disablePaging]="true">
      <ng-template #tableHeader>
        <tr>
          <th></th>
          <th sort field="Username">Username/Email</th>
          <th sort field="FirstName">First Name</th>
          <th sort field="LastName">Last Name</th>
          <th sort field="PhoneNumber">Customer Personal Phone</th>
          <th sort field="InsertedDate">Inserted Date</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-user>
        <tr>
          <td>
            <button (click)="sendPasswordResetEmail(user)" class="btn btn-primary">
              <i class="fa fa-envelope"></i>
              Send Password Reset Email
            </button>
          </td>
          <td>
            <a [attr.href]="'mailto:' + user.Username()">
              <i class="fa fa-envelope"></i>
              <span>{{user.Username()}}</span>
            </a>
          </td>
          <td>{{user.FirstName()}}</td>
          <td>{{user.LastName()}}</td>
          <td>{{user.PrimaryPhoneNumber()}}</td>
          <td>{{user.InsertedDate() | dateText }}</td>
          <td>
            <a [attr.href]="customer.Partner.ReportingUrl() + '/?y=' + customer.Users()[0].AccessToken()"
              class="btn btn-primary" rel="noreferrer" target="_blank">
              <i class="fas fa-external-link-square-alt"></i>
              Login
            </a>
          </td>
        </tr>
      </ng-template>
    </app-table>
  </app-components-togglepanel>
</div>