<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Create Workblock for this Task</h4>
    <button type="button" class="btn-close" title="Close" (click)="cancel()"></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label class="control-label" for="note">Reason for Workblock (required)</label>
      <ng-select [items]="reasons" required placeholder="Choose..." bindLabel="Name" [clearable]="false" bindValue="WorkblockReasonId" [formControl]="reason" [virtualScroll]="true" lpValidationMsg></ng-select>
    </div>
    <div class="form-group">
      <label class="control-label" for="note">Note (required)</label>
      <textarea [formControl]="note" required maxlength="4000" lpValidationMsg class="form-control" id="note" name="note"></textarea>
    </div>
    <div class="form-group" *ngIf="canWorkblockTaskType">
      <label class="control-label">
        Workblock all
        <span>{{ action.Task.TaskTypeSummary.Name }}</span>
        tasks for this customer?
      </label>
      <div>
        <shared-components-customchoice [(value)]="workblockThisTaskType"></shared-components-customchoice>
      </div>
    </div>
    <strong>Note: Workblocks will be in place until resolved</strong>
  </div>
  <div class="modal-footer">
    <button (click)="cancel()" class="btn btn-link">Cancel</button>
    <button [disabled]="isLoading" (click)="save()" class="btn btn-primary">
      <i class="fa fa-save"></i>
      Save
    </button>
  </div>
</div>
