import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationMsgService {
  getValidationMsg(validationId: string, condition: string, alternateMessage: string): string {
    const msg: (condition: string, alternateMessage: string) => string = this.errorMessages[validationId];

    return msg(condition, alternateMessage);
  }

  private errorMessages = {
    'invalid': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : 'Field is invalid',
    'required': (condition: string, alternateMessage: string) => 'Field is required',
    'min': (condition: string, alternateMessage: string) => `Field must have a minimum value: ${condition}`,
    'minlength': (condition: string, alternateMessage: string) => `Please enter at least ${condition} characters`,
    'maxlength': (condition: string, alternateMessage: string) => `Please enter no more than ${condition} characters`,
    'maxLengthArray': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : `Please select fewer items`,
    'informationRequiredAfterColon': (condition: string, alternateMessage: string) => 'Additional information is required after a colon (:)',
    'incompleteStatus': (condition: string, alternateMessage: string) => 'Note is required',
    'keywordNotPresent': (condition: string, alternateMessage: string) => 'Keyword is not present',
    'todoInvalid': (condition: string, alternateMessage: string) => 'Each item requires a response',
    'stringsNotAllowed': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : 'Value is not allowed',
    'urlInvalid': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : 'Not a valid Url.',
    'leadingOrTrailingSpace': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : 'Cannot contain leading or trailing spaces',
    'containsUrl': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : 'Value cannot contain a Url',
    'mismatchedDomain': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : 'Domain is not correct.',
    'email': (condition: string, alternateMessage: string) => 'Field must be an email',
    'invalidPhoneNumber': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : 'Invalid phone number',
    'urlAlreadyExists': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : 'Urls must be unique',
    'wordCountMin': (condition: string, alternateMessage: string) => `Content must have at least ${condition} words`,
    'wordCountMax': (condition: string, alternateMessage: string) => `Content must have no more than ${condition} words`,
    'invalidPostalCode': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : 'Must be a valid postal code format',
    'containsPOBox': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : 'PO Box is not allowed',
    'facebookPage': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : `Invalid Facebook page`,
    'instagramHandle': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : `Invalid Instagram handle`,
    'twitterHandle': (condition: string, alternateMessage: string) => alternateMessage ? alternateMessage : `Invalid Twitter handle`,
    'minDate': (condition: string, alternateMessage: string) => `Field must have a minimum value: ${condition}`,
    'maxDate': (condition: string, alternateMessage: string) => `Field must have a max value: ${condition}`,
  }
}
