<div>
	<div class="collapse d-block">
		<div class="card-body">
			<ng-container *ngIf="seoContentSurvey()">
				<div class="row">
					<div class="col-md-9">
						<div class="form-group">
							<label class="control-label">Business Overview</label>
							<textarea [dataValidation]="{ placement: 'right', value: seoContentSurvey().BusinessDescription }" [ngModel]="seoContentSurvey().BusinessDescription()" (ngModelChange)="seoContentSurvey().BusinessDescription($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" rows="5" placeholder="We are a... We offer our customers..."></textarea>
						</div>
						<div [dataValidation]="{ placement: 'right', value: seoContentSurvey().GoalsTactics }" class="form-group col-md-12">
							<label class="control-label">Site Goals and Tactics</label>
							<textarea [ngModel]="seoContentSurvey().GoalsTactics()" (ngModelChange)="seoContentSurvey().GoalsTactics($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" placeholder="What are you hoping to achieve with your website? What action should a prospective customer take after visiting your site? What special offers or tactics are helping you achieve those goals on your site? These may include boosters, two-for-ones, Groupons, time triggers, promotions, location-based specials, seasonal specials, or any other marketing efforts"></textarea>
						</div>
						<div [dataValidation]="{ placement: 'right', value: seoContentSurvey().Demographic }" class="form-group col-md-12">
							<label class="control-label">
								Audience
								<small>—Describe your customer's typical audience or customer base (age range, gender, geographical areas, single vs. family, and so on).</small>
							</label>
							<textarea [ngModel]="seoContentSurvey().Demographic()" (ngModelChange)="seoContentSurvey().Demographic($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" placeholder="Describe your customer's typical audience or customer base (age range, geographical areas, single vs. family, and so on)."></textarea>
						</div>
						<div class="form-group">
							<label class="control-label">Restrictions</label>
							<app-components-helptext [key]="'CompanyInformation_Restrictions'"></app-components-helptext>
							<textarea [dataValidation]="{ placement: 'right', value: seoContentSurvey().Limitations }" [ngModel]="seoContentSurvey().Limitations()" (ngModelChange)="seoContentSurvey().Limitations($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" placeholder="Explain any relevant restrictions, legal requirements, or key business challenges here."></textarea>
						</div>
						<div [dataValidation]="{ placement: 'right', value: seoContentSurvey().Persona }" class="form-group col-md-12">
							<label class="control-label">Tone and Company Persona</label>
							<textarea [ngModel]="seoContentSurvey().Persona()" (ngModelChange)="seoContentSurvey().Persona($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" placeholder="If your company were a person, what sort of person would it be? Provide personality adjectives, or any other descriptions that capture the brand of your business ."></textarea>
						</div>
						<div [dataValidation]="{ placement: 'right', value: seoContentSurvey().SellingPoints }" class="form-group col-md-12">
							<label class="control-label">Value Propositions and Unique Selling Points</label>
							<textarea [ngModel]="seoContentSurvey().SellingPoints()" (ngModelChange)="seoContentSurvey().SellingPoints($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" placeholder="What are the most profitable services or products you offer? What makes your services or products unique? Which products or services do you want us to highlight?"></textarea>
						</div>
					</div>
				</div>
				<div class="row">
					
				</div>
			</ng-container>
		</div>
		<div class="card card-body bg-light d-block text-end">
			<div class="form-group">
				<label class="control-label">Reason for update</label>
				<textarea [dataValidation]="{ placement: 'right', value: surveyUpdateReason }" [ngModel]="surveyUpdateReason()" (ngModelChange)="surveyUpdateReason($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" placeholder="Please provide a reason for updating SEO content survey"></textarea>
			</div>
			<button [hidden]="!canClose()" (click)="cancel()" class="btn btn-link">Cancel</button>
			<button [hidden]="!canEdit()" [disabled]="isLoading()" (click)="saveSurvey()" class="btn btn-success">
				<i class="fa fa-save"></i>
				Update SEO Content Survey
			</button>
		</div>
	</div>
</div>
