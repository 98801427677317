import { HttpClient } from '@angular/common/http';
import PushNotificationConfig from 'app/PushNotificationConfig';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../../../app/services/session-storage.service';
import { NonRecurringRanksRequestSources } from '../../../app/components/shared/Keywords/non-recurring-ranks-request-sources';

@Injectable({
    providedIn: 'root'
})
export class NonRecurringRanksService {

    private baseUrl: string = `${environment.urlConfig.serviceUrl}`;
    constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) { }

    private subscriptions: { [subscriber: string]: { [event: string]: ((data: any) => void)[] } } = {};

    private connection: signalR.HubConnection;

    public connect(url: string): Promise<void> {
        if (!!this.connection) {
            return Promise.resolve();
        }

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.urlConfig.signalRBaseUrl}/${PushNotificationConfig.channels.nonRecurringRanks.name}?url=${encodeURIComponent(url)}&PartnerUserAccessToken=${encodeURIComponent(this.sessionStorageService.getAccessTokenValue())}`, {
                // if we use websockets and skip negotiation, we don't need to worry about signalr sticky session requirements
                transport: signalR.HttpTransportType.WebSockets,
                skipNegotiation: true
            })
            .configureLogging(signalR.LogLevel.Error)
            .build();

        const events = PushNotificationConfig.channels.nonRecurringRanks.events;

        this.connection.on(
            events.onRanksComplete,
            (e) => {
                if (!this.subscriptions) {
                    return Promise.resolve;
                }
                for (let subscriptionId in this.subscriptions) {
                    if (!this.subscriptions.hasOwnProperty(subscriptionId)) {
                        continue;
                    }
                    this.subscriptions[subscriptionId][events.onRanksComplete].forEach((h: any) => {
                        h(e);
                    });
                }
            });

        return this.connection.start();
    }

    public subscribe(subscriberId: string, event: string, handler: (data: any) => void): void {
        this.subscriptions[subscriberId] = this.subscriptions[subscriberId] || {};
        this.subscriptions[subscriberId][event] = this.subscriptions[subscriberId][event] || [];
        this.subscriptions[subscriberId][event].push(handler);
        handler(event);
    }

    public disconnect(subscriberId: string): void {
        delete this.subscriptions[subscriberId];

        if (!Object.keys(this.subscriptions).length) {
            this.connection.stop();
            this.connection = null;
        }
    }

    public requestRanks(url: string, keywords: string[], country: number, source: NonRecurringRanksRequestSources): Observable<boolean> {
        if (keywords.length > 0) {
            return this.http.post<any>(`${this.baseUrl}/NonRecurringRanks`, {url: url, keywords: keywords, country: country, requestSource: source});
        }
    }
}