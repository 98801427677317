import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import FloatingPanel = app.components.interfaces.FloatingPanel;
import FloatingTabPanelSize = app.components.enums.FloatingTabPanelSize;
import FloatingTabPanelIcon = app.components.enums.FloatingTabPanelIcon;
import IFloatingTabPanelPublicApi = app.components.interfaces.IFloatingTabPanelPublicApi;
import { CustomerTodoListTicketComponent } from '../CustomerTodoListTicket/CustomerTodoListTicket.component';

@Component({
	selector: 'app-components-ticket-customertodolistfloatingpanel',
	templateUrl: './CustomerTodoListFloatingPanel.component.html'
})
export class CustomerTodoListFloatingPanelComponent extends BaseComponent {
  private customerTodoListPanel: FloatingPanel;
  private floatingTabPanelApi: KnockoutObservable<IFloatingTabPanelPublicApi>;
  private customerTodoListTicketViewModel: CustomerTodoListTicketComponent;
  private unresolved: KnockoutObservable<number> = ko.observable(0);

  public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
    return super.activate(params)
      .then(() => {
        this.floatingTabPanelApi = params.floatingTabPanelApi;

        if (this.floatingTabPanelApi().addPanel) {
          this.addPanel();
        }

        params.floatingTabPanelApi.subscribe(() => {
          this.addPanel();
        });

      });
  }

  public addPanel(): void {
    this.customerTodoListPanel = {
      title: 'To Do List',
      titleIcon: FloatingTabPanelIcon.Tasks,
      titleBadge: this.unresolved,
      titleBadgeClass: 'bg-danger',
      size: FloatingTabPanelSize.Small,
      isScrollable: false,
      wobbleIf: () => this.unresolved() > 0,
      composition: {
        selector: 'app-components-ticket-customertodolistticket',
        callback: this.customerTodoListTicketComposeBinding.bind(this),
        options: {
          customer: this.customer
        }
      }
    };
    this.floatingTabPanelApi().addPanel(this.customerTodoListPanel);
  }

  // tslint:disable-next-line
  public customerTodoListTicketComposeBinding(model: CustomerTodoListTicketComponent): void {
    this.customerTodoListTicketViewModel = model;

    // number of unresolved todos
    this.unresolved(this.customerTodoListTicketViewModel.unresolved());
    this.customerTodoListTicketViewModel.unresolved.subscribe(x => this.unresolved(x));
  }
}
