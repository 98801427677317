<div>
	<div class="card">
		<div class="card-header">
			{{title}}
		</div>
		<div class="card-body">
			<div class="form-group row">
				<ng-container *ngIf="customer()">
					<div class="col-md-6">
						<ng-container *ngIf="customer().MerchantFields">
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantSourceLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantSource()" (ngModelChange)="customer().MerchantFields.MerchantSource($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantCategoryLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantCategory()" (ngModelChange)="customer().MerchantFields.MerchantCategory($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantCategoryIdLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantCategoryId()" (ngModelChange)="customer().MerchantFields.MerchantCategoryId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantSubCategoryLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantSubCategory()" (ngModelChange)="customer().MerchantFields.MerchantSubCategory($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantSubCategoryIdLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantSubCategoryId()" (ngModelChange)="customer().MerchantFields.MerchantSubCategoryId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantProductCodeLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantProductCode()" (ngModelChange)="customer().MerchantFields.MerchantProductCode($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantConsultantLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantConsultant()" (ngModelChange)="customer().MerchantFields.MerchantConsultant($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantConsultantContactLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantConsultantContact()" (ngModelChange)="customer().MerchantFields.MerchantConsultantContact($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantCampaignManagerLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantCampaignManager()" (ngModelChange)="customer().MerchantFields.MerchantCampaignManager($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantCampaignManagerContactLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantCampaignManagerContact()" (ngModelChange)="customer().MerchantFields.MerchantCampaignManagerContact($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
						</ng-container>
					</div>
					<div class="col-md-6">
						<ng-container *ngIf="customer().MerchantFields">
							<div class="form-group">
								<div class="col-md-12">
									<label class="control-label">Merchant Site Publish Date</label>
									<input id="datetime" [dateTimePicker]="{ date: displayMerchantSitePublishDate }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantItemIdLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantItemId()" (ngModelChange)="customer().MerchantFields.MerchantItemId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantIdLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantId()" (ngModelChange)="customer().MerchantFields.MerchantId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantProposalIdLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantProposalId()" (ngModelChange)="customer().MerchantFields.MerchantProposalId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantUdacLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantUdac()" (ngModelChange)="customer().MerchantFields.MerchantUdac($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantMiscIdLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantMiscId()" (ngModelChange)="customer().MerchantFields.MerchantMiscId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantRegionLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantRegion()" (ngModelChange)="customer().MerchantFields.MerchantRegion($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantCustomerValueLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantCustomerValue()" (ngModelChange)="customer().MerchantFields.MerchantCustomerValue($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantContractEndDateLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantContractEndDate()" (ngModelChange)="customer().MerchantFields.MerchantContractEndDate($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-12 control-label">{{MerchantServiceLevelLabel()}}</label>
								<div class="col-md-12">
									<input [ngModel]="customer().MerchantFields.MerchantServiceLevel()" (ngModelChange)="customer().MerchantFields.MerchantServiceLevel($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"/>
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="card-footer">
			<div class="row">
				<div class="col-md-12">
					<button (click)="saveView()" type="button" class="btn btn-success float-end">
						<i class="fa fa-save"></i>
						Save
					</button>
					<button (click)="closeView()" type="button" class="btn btn-link float-end">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</div>
