import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkflowVisualizationService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/workflowvizualization`;

  constructor(private http: HttpClient) { }

  getAll(): Observable<Boo.Objects.WorkflowVisualization[]> {
    return this.http.get<Boo.Objects.WorkflowVisualization[]>(`${this.baseUrl}/GetAll`);
  }
}
