<div class="card">
  <div class="card-header">
    Find Customer
  </div>
  <div class="card-body" [loadingOverlay]="isLoading">
    <div class="row">
      <div class="form" style="width: 100%;">
        <div class="col-md-4">
          <div class="form-group">
            <input type="text" class="form-control" [value]="searchString" (input)="searchString = $event.target.value"
              (keyup.enter)="searchCustomers();" placeholder="Search by id, url, name, phone, etc" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <button class="btn btn-primary" (click)="searchCustomers();" [disabled]="isLoading"><i
                class="fa fa-search"></i>Search</button>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <app-table [items]="customers">
      <ng-template #tableHeader>
        <tr>
          <th></th>
          <th sort field="CustomerId">Customer Id</th>
          <th sort field="MerchantId">Merchant Id</th>
          <th sort field="Name">Name</th>
          <th sort field="Email">Email</th>
          <th sort field="Phone">Phone</th>
          <th sort field="SignupDate">Signup Date</th>
          <th sort field="Status">Status</th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-customer>
        <tr>
          <td>
            <button class="btn btn-primary" (click)="addProducts(customer);"><i
                class="fas fa-external-link-square-alt"></i>Place Order</button>
          </td>
          <td>{{ customer.CustomerId }}</td>
          <td>{{ customer.MerchantId }}</td>
          <td>{{ customer.Name }}</td>
          <td><a [href]="'mailto:' + customer.Email"><i class="fa fa-envelope"></i><span>{{ customer.Email }}</span></a>
          </td>
          <td>{{ customer.Phone }}</td>
          <td>{{ customer.SignupDate }}</td>
          <td>{{ customer.Status }}</td>
        </tr>
      </ng-template>
    </app-table>
  </div>
</div>