<div>
	<!-- THIS IS A PRIORITY VIEW -->
	<div class="card">
		<div class="card-header">
			<span>{{title}}</span>
			<button (click)="closeView()" type="button" class="btn-close float-end"></button>
		</div>
		<div [loadingOverlay]="isLoading()" class="card-body">
			<div class="row">
				<div class="col-md-2">
					<div class="form-group">
						<label class="control-label">Job Type</label>
						<select [ngModel]="selectedJobTypeId()" (ngModelChange)="selectedJobTypeId($event)" [ngModelOptions]="{ standalone: true }" name="teams" class="form-select">
							<option *ngFor="let option of jobTypes()" [ngValue]="option.JobTypeId">{{ option.Name }}</option>
						</select>
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<label class="control-label">Status</label>
						<select [ngModel]="selectedJobStatusId()" (ngModelChange)="selectedJobStatusId($event)" [ngModelOptions]="{ standalone: true }" name="teams" class="form-select">
							<option *ngFor="let option of jobStatuses" [ngValue]="option.JobStatusId">{{ option.Name }}</option>
						</select>
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<label class="control-label">Priority</label>
						<select [ngModel]="selectedJobPriorityId()" (ngModelChange)="selectedJobPriorityId($event)" [ngModelOptions]="{ standalone: true }" name="teams" class="form-select">
							<option *ngFor="let option of jobPriorities" [ngValue]="option.JobPriorityId">{{ option.Name }}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-2">
					<div class="form-group">
						<label class="control-label">Start Date</label>
						<input [dateTimePicker]="{ date: startDate }" type="text" class="form-control" placeholder="Start Date"/>
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<label class="control-label">Start Time</label>
						<input type="text" class="form-control" placeholder="Start Time" timePicker [DateTime]="startDate"/>
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<label class="control-label">End Date</label>
						<input [dateTimePicker]="{ date: endDate }" type="text" class="form-control" placeholder="Start Date"/>
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<label class="control-label">End Time</label>
						<input type="text" class="form-control" placeholder="End Time" timePicker [DateTime]="endDate"/>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label class="control-label">&nbsp;</label>
						<div>
							<button (click)="getJobCount()" class="btn btn-primary">
								<i class="fa fa-search"></i>
								Find Jobs
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
					<div [hidden]="!(jobCount() >= 0)" [class]="alertStyle()" class="alert">
						<span>
							Number of job(s) detected:
							<span>{{jobCount()}}</span>
						</span>
						<ng-container *ngIf="jobCount() && jobCount() > 0">
							<button (click)="updateJobs()" [class]="persistButtonStyle()" style="margin-top: -7px;" class="float-end btn">
								<i [class]="persistButtonIcon()" class="fas"></i>
								<span>{{title}}</span>
							</button>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="card card-body bg-light d-block text-end">
				<button (click)="closeView()" class="btn btn-outline-secondary">
					<i class="fas fa-times"></i>
					Close
				</button>
			</div>
		</div>
	</div>
</div>
