import { Component } from '@angular/core';
import timer from 'app/managecustomer/Timer';
import BaseComponent from '../BaseComponent';

@Component({
	selector: 'app-components-ticket-tickettimer',
	templateUrl: './TicketTimer.component.html'
})
export class TicketTimerComponent extends BaseComponent {
    private id: number;
    private ticketTimeOut: number;
    private ticketTimeOutSeconds: number;

    public activate(params: any): JQueryPromise<void> {
        this.ticketTimeOut = params.featureConfig.ticketTimeOut;

        this.startTimer();

        return super.activate(params);
    }

    public deactivate(): void {
        this.stopTimer();
    }

    private startTimer(): void {
        let ticketTimeOutSeconds = this.ticketTimeOut * 60;
        let count = 0;

        this.id = window.setInterval(
            () => {
            count++;

            if (count !== ticketTimeOutSeconds) {
                return;
            }

            this.showNotification();
            this.restartTimer();
            },
            1000);
    }

    private stopTimer(): void {
        clearInterval(this.id);
    }

    private restartTimer(): void {
        this.stopTimer();
        this.startTimer();
    }

    private showNotification(): void {
        bootbox.alert(`You have been inactive for ${this.ticketTimeOut} minutes on this ticket. Please update ticket`);
        timer.browserNotification();
    }
}
