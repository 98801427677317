<div [loadingOverlay]="isLoading()">
	<div [hidden]="!contentAudit()" class="row">
		<div *ngIf="showDuplicateWarning" class="col-md-12">
			<div class="card card-danger">
				<div class="card-header">
					Duplicate Content Warning
				</div>
				<div class="card-body">
					<table [hidden]="!showPlagiarismTable" class="table table-bordered table-sm">
						<thead>
							<tr>
								<th>Url</th>
								<th>Percentage</th>
								<th>Matched Text</th>
							</tr>
						</thead>
						<tbody>
							<tr [hidden]="!(contentAudit() && contentAudit().Url1)">
								<ng-container *ngIf="contentAudit()">
									<td>
										<a [externalLink]="{ url: contentAudit().Url1, text:contentAudit().Url1}"></a>
									</td>
									<td class="text-danger">{{(contentAudit().Url1Percentage * 100) + '%'}}</td>
									<td>
										<span [ngbPopover]="contentAudit().Url1MatchedText" popoverTitle="Matched Text" placement="right auto" triggers="mouseenter:mouseleave">
											<i class="far fa-file-alt"></i>
											(Hover for Matched Text)
										</span>
									</td>
								</ng-container>
							</tr>
							<tr [hidden]="!(contentAudit() && contentAudit().Url2)">
								<ng-container *ngIf="contentAudit()">
									<td>
										<a [externalLink]="{ url: contentAudit().Url2, text:contentAudit().Url2}"></a>
									</td>
									<td class="text-danger">{{(contentAudit().Url2Percentage * 100) + '%'}}</td>
									<td>
										<span [ngbPopover]="contentAudit().Url2MatchedText" popoverTitle="Matched Text" placement="right auto" triggers="mouseenter:mouseleave">
											<i class="far fa-file-alt"></i>
											(Hover for Matched Text)
										</span>
									</td>
								</ng-container>
							</tr>
							<tr [hidden]="!(contentAudit() && contentAudit().Url3)">
								<ng-container *ngIf="contentAudit()">
									<td>
										<a [externalLink]="{ url: contentAudit().Url3, text:contentAudit().Url3}"></a>
									</td>
									<td class="text-danger">{{(contentAudit().Url3Percentage * 100) + '%'}}</td>
									<td>
										<span [ngbPopover]="contentAudit().Url3MatchedText" popoverTitle="Matched Text" placement="right auto" triggers="mouseenter:mouseleave">
											<i class="far fa-file-alt"></i>
											(Hover for Matched Text)
										</span>
									</td>
								</ng-container>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-8">
			<form role="form">
				<div *ngIf="isTaskImported" class="alert alert-warning">
					<strong>This task was imported into the system and cannot be deleted. Please fix the content and complete the task.</strong>
				</div>
				<div *ngIf="revisionMessage()" class="alert alert-warning">
					<strong>Revision Notification:</strong>
					<span>{{revisionMessage()}}</span>
				</div>
				<div [hidden]="!showSpecialConsiderations()" class="alert alert-info">
					<strong>Special Considerations:</strong>
					<span>{{specialConsiderations()}}</span>
				</div>
				<div class="form-group">
					<label>Writer User Id</label>
					<div>{{writerUserId ? writerUserId : 'Unknown'}}</div>
				</div>
				<div class="form-group">
					<label for="title" class="control-label">{{titleLabel()}}</label>
					<p class="float-end help-block">128 character maximum</p>
					<input [dataValidation]="{ placement: 'top', value: contentTitle }" [ngModel]="contentTitle()" (ngModelChange)="contentTitle($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="title" class="form-control" placeholder="Enter Title"/>
				</div>
				<div class="form-group">
					<label class="control-label">{{contentLabel()}}</label>
					<span class="float-end help-block">8000 character maximum</span>
					<div class="clear-fix"></div>
					<div [dataValidation]="{ placement: 'top', value: content }">
						<textarea #editorRichText [richText]="{ content: content, options: editorToolbar, wordCount: wordCount, autoGrow: true }" [searchText]="{ regexString: keywordAreaRegexPattern, performSearch: findTargetKeyword, caretPosition: caretPosition, selectionStart: selectionStart, selectionEnd: selectionEnd, selectedText: selectedText }" name="content" rows="15" style="height: 300px;" class="form-control" placeholder="Enter Content"></textarea>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<div class="col-md-12">
							<p class="help-block">
								<button (click)="timer.toggle()" [class.btn-danger]="passedGoalSeconds()" [class.btn-primary]="!passedGoalSeconds()" class="btn">
									<i [class.fa-play]="timer.isPaused" [class.fa-pause]="!timer.isPaused" class="fas"></i>
									<span><shared-components-timer [timer]="timer" [showSeconds]="true"></shared-components-timer></span>
								</button>
							</p>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label">Submit Date</label>
					<div class="form-control">{{contentSubmitDate()}}</div>
				</div>
				<div class="form-group">
					<label for="contentTags" class="control-label">Tags</label>
					<p class="float-end help-block">256 character maximum</p>
					<input [dataValidation]="{ placement: 'top', value: tags }" [ngModel]="tags()" (ngModelChange)="tags($event)" [ngModelOptions]="{ standalone: true }" name="contentTags" class="form-control" placeholder="Enter Tags"/>
				</div>
				<table [hidden]="!(reviewTaskDetails().length > 0)" class="table table-bordered table-striped">
					<thead>
						<tr>
							<th>Reviewer</th>
							<th>Review Date</th>
							<th>Comment</th>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngFor="let $data of reviewTaskDetails()">
							<tr>
								<td>{{$data.AssignedUser}}</td>
								<td>{{$data.StatusDate | dateText: 'L LT' }}</td>
								<td class="line-breaks">{{$data.Comment}}</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
				<div class="form-group">
					<label class="control-label">Reviewer Comments</label>
					<p class="float-end help-block">4000 character maximum</p>
					<div class="clear-fix"></div>
					<div [dataValidation]="{ placement: 'top', value: reviewerComments }">
						<textarea [richText]="{ content: reviewerComments, options: reviewerToolbar }" name="reviewerComments" rows="5" class="form-control" placeholder="Enter Reviewer Comments" maxlength="4000"></textarea>
					</div>
				</div>
			</form>
		</div>
		<div class="col-md-4" style="padding-left: 3px;">
			<div class="card">
				<div class="card-header">
          <span>Country</span>
				</div>
				<div class="card-body">
					<ng-container *ngIf="customerCountry">
						<span>{{customerCountry.Name}}</span>
					</ng-container>
				</div>
			</div>
			<div class="card">
				<div class="card-header">
          <span>Language</span>
				</div>
				<div class="card-body">
					<ng-container *ngIf="customerLanguage">
						<span>{{customerLanguage.Name}}</span>
					</ng-container>
				</div>
			</div>
			<div class="card">
				<div class="card-header">
          <span>Business Category</span>
				</div>
				<div class="card-body">
					<span>{{businessCategory()}}</span>
				</div>
			</div>
			<div class="card">
				<div class="card-header">
					<span>Target Keyword</span>
				</div>
				<div class="card-body">
					<span>{{targetKeyword()}}</span>
				</div>
			</div>
			<div [hidden]="!showTargetArea()" class="card">
				<div class="card-header">
          <span>Target Service/Area Location</span>
				</div>
				<div class="card-body">
					<span>{{targetArea()}}</span>
				</div>
			</div>
			<div class="card">
				<div class="card-header">
          <span>Link Strategy Type</span>
				</div>
				<div class="card-body">
					<div>{{linkStrategyType()}}</div>
				</div>
			</div>
			<div [hidden]="!showRecommendedAnchorText()" class="card">
				<div class="card-header">
          <span>Recommended Anchor Text (click to copy)</span>
				</div>
				<ul class="list-group">
					<li class="list-group-item cursor-pointer">
						<input [ngModel]="recommendedAnchorText()" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" style="cursor: pointer; background-color: transparent; border: none; width: 100%; height: 100%;" onclick="this.select();" readonly="readonly" title="Click and press CTRL+C to copy..." type="text"/>
					</li>
				</ul>
			</div>
			<div [hidden]="!showAdditionalKeywords()" class="card">
				<div class="card-header">
          <span>Additional Keywords</span>
				</div>
				<ul class="list-group">
					<ng-container *ngFor="let $data of additionalKeywords()">
						<li class="list-group-item cursor-pointer">
							<span>{{$data}}</span>
						</li>
					</ng-container>
				</ul>
			</div>
		</div>
	</div>
</div>
