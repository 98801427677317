/* tslint:disable */

export default class linklocation {
    public Country = <any>null
    public CountryId = 0
    public Description = <any>null
    public Domain = <any>null
    public ExpirationDate = <any>null
    public InsertedDate = "0001-01-01T00:00:00"
    public IsActive = false
    public IsRetired = false
    public IsUrlAccessible = false
    public Language = <any>null
    public LanguageId = 0
    public LastResponseTimeInMilliseconds = 0
    public LinkLocationId = 0
    public LinkLocationSearchTermId = 0
    public LinkTypeId = 0
    public MajesticRank = 0
    public Name = <any>null
    public SpecialNotes = <any>null
    public SubmitOrder = 0.0
    public Title = <any>null
    public Url = <any>null
    public Vertical = <any>null
    public VerticalId = 0
    public RetirementDate = "0001-01-01T00:00:00"
}
