export default class OnsiteRecommendationDetail implements Boo.Objects.OnsiteRecommendationDetail {
    public CMSName: string;
    public CMSPassword: string;
    public CMSUrl: string;
    public CMSUsername: string;
    public OnsiteRecommendationDetailId: number;
    public CustomerId: number;
    public InsertedDate: Date;
    public Note: string;
    public ContactTypeId: number;
    public ContactType: Boo.OnsiteRecommendations.Models.Enums.ContactTypes;
    public Responsibility: Boo.OnsiteRecommendations.Models.Enums.Responsibilities;
    public TechnicalContactEmail: string;
    public TechnicalContactName: string;
    public TechnicalContactPhone: string;
    public Enabled: boolean;
    public EnabledUpdatedDate: Date;
    
    constructor();
    constructor(customerId: number);
    constructor(customerId?: number) {
        this.CMSName = null;
        this.CMSUrl = null;
        this.CMSUsername = null;
        this.CMSPassword = null;
        this.CustomerId = customerId || 0;
        this.OnsiteRecommendationDetailId = 0;
        this.InsertedDate = null;
        this.Note = null;
        this.ContactType = Boo.OnsiteRecommendations.Models.Enums.ContactTypes.NotSpecified;
        this.Responsibility = Boo.OnsiteRecommendations.Models.Enums.Responsibilities.NotSelected;
        this.ContactTypeId = Boo.OnsiteRecommendations.Models.Enums.ContactTypes.NotSpecified;
        this.TechnicalContactName = null;
        this.TechnicalContactEmail = null;
        this.TechnicalContactPhone = null;
    }
}
