<div [loadingOverlay]="isLoading">
	<div class="form-group">
		<button class="btn btn-success" (click)="saveChanges()">Save Changes</button>
		<button class="btn btn-info" (click)="suggestChanges()">Suggest Changes</button>
	</div>
	<div cdkDropListGroup class="row">
		<div class="col-md-6">
			<div class="card">
				<div class="card-header">
					SEO Minute Levels
				</div>
				<div *ngIf="minuteLevels.length === 0">
					<ul class="list-group feature-group-list">
						<li class="list-group-item drag-list-item">
							<span>No minute levels available.</span>
						</li>
					</ul>
				</div>
				<ng-container *ngFor="let levelFeatures of minuteLevels">
					<section class="feature-group-header">
						<strong>{{ levelFeatures.Minutes }}+ Minutes</strong>
					</section>
					<ul class="list-group feature-group-list" cdkDropList [cdkDropListData]="levelFeatures.Features"
						(cdkDropListDropped)="dropListDropped($event)"
						[cdkDropListEnterPredicate]="createMutualExclusionPredicate()">
						<ng-container *ngFor="let feature of levelFeatures.Features">
							<li class="list-group-item drag-list-item" cdkDrag [cdkDragData]="feature">
								<i class="fa fa-lg fa-grip-lines-vertical drag-line"></i>
								<span>{{ feature.Title }}</span>
								<i class="fa fa-info-circle" [ngbPopover]="feature.Definition" triggers="mouseenter:mouseleave"></i>
							</li>
						</ng-container>
					</ul>
				</ng-container>
			</div>
		</div>
		<div class="col-md-6">
			<div class="card">
				<div class="card-header">
					Available Features
				</div>
				<div *ngIf="featureGroups.length === 0">
					<ul class="list-group feature-group-list">
						<li class="list-group-item drag-list-item">
							<span>No features available.</span>	
						</li>
					</ul>
				</div>
				<ng-container *ngFor="let group of featureGroups">
					<section class="feature-group-header">
						<span [attr.data-target]="'#group' + group.FeatureGroupId.toString()" data-toggle="collapse"
							[attr.aria-expanded]="!group.MutuallyExclusive">
							<i class="fa fa-chevron-down"></i>
							<i class="fa fa-chevron-right"></i>
						</span>
						<strong>{{ group.Name }}</strong>
					</section>
					<section [attr.id]="'group' + group.FeatureGroupId.toString()" class="collapse"
						[ngClass]="{'show': !group.MutuallyExclusive }">
						<ul class="list-group feature-group-list" cdkDropList [cdkDropListData]="group.PartnerPricingToolFeatures"
							(cdkDropListDropped)="dropListDropped($event)"
							[cdkDropListEnterPredicate]="createFeatureGroupPredicate(group.FeatureGroupId)"
							[cdkDropListSortingDisabled]="true">
							<ng-container *ngFor="let feature of group.PartnerPricingToolFeatures">
								<li class="list-group-item drag-list-item" cdkDrag [cdkDragData]="feature">
									<i class="fa fa-lg fa-grip-lines-vertical drag-line"></i>
									<span>{{ feature.Title }}</span>
									<i class="fa fa-info-circle" [ngbPopover]="feature.Definition" triggers="mouseenter:mouseleave"></i>
								</li>
							</ng-container>
						</ul>
					</section>
				</ng-container>
			</div>
		</div>
	</div>
</div>