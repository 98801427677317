import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize, switchMap } from 'rxjs/operators';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-components-shared-customer-campaign',
  templateUrl: './customer-campaign.component.html'
})
export class CustomerCampaignComponent implements OnInit {

  @Input() customer: Boo.Objects.Customer;
  @Input() restrictions: Boo.Objects.Enums.CustomerCampaignRestrictions[] = [];
  @Input() reviewSource?: Boo.OnsiteRecommendations.Models.Enums.ReviewSources = Boo.OnsiteRecommendations.Models.Enums.ReviewSources.CustomerWorkspace;
  @Output() refreshCustomer = new EventEmitter<void>();

  tabPanelDisplayMode = TabPanelDisplayMode;
  loading: boolean = false;
  websiteUrls: Boo.Objects.WebsiteUrl[];
  websiteUrlKeywordRanks: Boo.Objects.WebsiteUrlKeywordRank[];
  archivedWebsiteUrls: Boo.Objects.ArchivedWebsiteUrl[];
  archivedKeywords: Boo.Objects.ArchivedKeyword[];
  haloKeywords: Boo.Objects.WebsiteKeywordTracking[];
  websiteKeywordTrackingRanks: Boo.Objects.WebsiteKeywordTrackingRank[];
  websiteUrlTasks: Boo.Objects.WebsiteUrlTask[];
  firstPageKeywordPhrases: string[];
  existingCampaignTypeIsNational: boolean;
  readOnlyReasons: string;

  customerCampaignRestrictions_ReadOnly = Boo.Objects.Enums.CustomerCampaignRestrictions.ReadOnly;
  customerCampaignRestrictions_HideHaloTab = Boo.Objects.Enums.CustomerCampaignRestrictions.HideHaloTab;
  customerCampaignRestrictions_HideArchivedTab = Boo.Objects.Enums.CustomerCampaignRestrictions.HideArchivedTab;

  constructor(private customerCampaignService: CustomerCampaignService) { }

  ngOnInit(): void {
    this.loading = true;
    this.load()
      .pipe(finalize(() => this.loading = false))
      .subscribe({error: (e) => toastr.error(e)});
  }

  load(): Observable<void> {
    this.existingCampaignTypeIsNational = this.customer.IsCampaignNational;
    return this.customerCampaignService.getSummary(this.customer.CustomerId)
    .pipe(
      switchMap(summary => {
        this.websiteUrls = summary.WebsiteUrls.map(websiteUrl => {
          websiteUrl.Keywords = websiteUrl.Keywords.sort((a, b) => a.KeywordPhrase.localeCompare(b.KeywordPhrase));
          return websiteUrl;
        }).sort((a, b) => a.Url.localeCompare(b.Url));
        this.websiteUrlKeywordRanks = summary.WebsiteUrlKeywordRanks;
        this.archivedWebsiteUrls = summary.ArchivedWebsiteUrls;
        this.archivedKeywords = summary.ArchivedKeywords;
        this.haloKeywords = summary.TrackingKeywords.sort((a, b) => Number(a.WasFirstPage) - Number(b.WasFirstPage) || a.KeywordPhrase.localeCompare(b.KeywordPhrase));
        this.websiteKeywordTrackingRanks = summary.WebsiteKeywordTrackingRanks;
        this.websiteUrlTasks = summary.WebsiteUrlTasks;
        this.readOnlyReasons = summary.ReadOnlyReasons.join('\n');
        this.firstPageKeywordPhrases = summary.FirstPageKeywordPhrases;
  
        this.populateRanks();
        this.populateTasks();
        this.configureReadOnlyRestrictions();
        return of(null);
      })
    );
  }

  populateRanks(): void {
    this.websiteUrls.flatMap(x => x.Keywords).forEach(keyword => {
      keyword.Rank = this.websiteUrlKeywordRanks.find(x => x.WebsiteUrlKeywordId === keyword.WebsiteUrlKeywordId)?.CurrentRank ?? 0;
    });

    this.haloKeywords.forEach(keyword => {
      keyword.Rank = this.websiteKeywordTrackingRanks.find(x => x.WebsiteKeywordTrackingId === keyword.WebsiteKeywordTrackingId)?.CurrentRank ?? 0;
    });
  }

  populateTasks(): void {
    this.websiteUrls.forEach(url => {
      url.WebsiteUrlTask = this.websiteUrlTasks.sort((a, b) => b.TaskId - a.TaskId).find(x => x.WebsiteUrlId === url.WebsiteUrlId);
    });
  }

  configureReadOnlyRestrictions(): void {
    if (this.readOnlyReasons) {
      this.restrictions.push(Boo.Objects.Enums.CustomerCampaignRestrictions.ReadOnly);
    }

    this.websiteUrls.filter(websiteUrl => websiteUrl.WebsiteUrlTask).forEach(websiteUrl => this.customerCampaignService.calculateReadOnlyState(websiteUrl));
  }

  save(): void {
    this.loading = true;

    if (this.validationMessages.length > 0) {
      this.loading = false;
      toastr.error(this.validationMessages.join(' '));
      return;
    }

    if (this.modifiedCount === 0) {
      toastr.success('Customer campaign saved.');
      this.load()
        .pipe(finalize(() => this.loading = false))
        .subscribe({error: (e) => toastr.error(e)});
      return;
    }

    this.customerCampaignService.save(
      this.customer.CustomerId,
      this.websiteUrls.filter(websiteUrl => this.isWebsiteUrlModified(websiteUrl)).map(websiteUrl => {
        websiteUrl.Keywords = websiteUrl.Keywords.filter(keyword => keyword.IsModified);
        return websiteUrl;
      }),
      this.haloKeywords.filter(x => x.IsModified),
      this.isCampaignTypeModified() ? this.customer.IsCampaignNational : null
    ).pipe(
      switchMap(_ => {
        if (this.isCampaignTypeModified()) {
          this.refreshCustomer.emit();
        }

        toastr.success('Customer campaign saved.');
        return this.load();
      }),
      finalize(() => this.loading = false))
    .subscribe({error: (e) => toastr.error(e)});
  }

  updateWebsiteUrlStatusToActive(url: string) {
    let websiteUrl = this.websiteUrls.find(x => x.Url.toLowerCase().trim() === url.toLowerCase().trim());
    if (websiteUrl && websiteUrl.StatusId !== Boo.Objects.Enums.WebsiteUrlStatuses.Active) {
      this.customerCampaignService.updateWebsiteUrlStatus(this.websiteUrls, this.haloKeywords, this.archivedWebsiteUrls, this.archivedKeywords, this.firstPageKeywordPhrases, websiteUrl, Boo.Objects.Enums.WebsiteUrlStatuses.Active);
    }
  }

  get modifiedCount(): number {
    let customerModificationCount = this.isCampaignTypeModified() ? 1 : 0;
    let pageAndKeywordModificationCount = (this.websiteUrls && this.haloKeywords) ? this.websiteUrls.filter(x => this.isWebsiteUrlModified(x)).length + this.haloKeywords.filter(x => x.IsModified).length : 0
    return customerModificationCount + pageAndKeywordModificationCount;
  }

  get validationMessages(): string[] {
    let messages: string[] = [];

    if (this.websiteUrls.some(page => this.customerCampaignService.isActiveStatus(page) && !this.customerCampaignService.domainMatches(this.customer.Url, page.Url))) {
      messages.push('All page domains must match the customer domain.');
    }

    if (!this.websiteUrls.some(page => page.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active)) {
      messages.push('At least one page must be active.');
    }

    var urls = this.websiteUrls.filter(x => this.customerCampaignService.isActiveStatus(x)).map(x => x.Url.toLowerCase().trim());
    if (new Set(urls).size !== urls.length) {
      messages.push('Pages can not be duplicated.');
    }

    if (this.websiteUrls.some(page => page.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active && page.Keywords.filter(keyword => keyword.IsActive).length === 0)) {
      messages.push('Active pages must have at least one keyword.');
    }

    var keywordPhrases = this.websiteUrls.filter(x => x.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active)
      .flatMap(page => page.Keywords)
      .filter(x => x.IsActive)
      .map(x => x.KeywordPhrase.toLowerCase().trim())
      .concat(this.haloKeywords.filter(x => x.IsActive).map(x => x.KeywordPhrase.toLowerCase().trim()));

    if (new Set(keywordPhrases).size !== keywordPhrases.length) {
      messages.push('Keywords can not be duplicated.');
    }

    return messages;
  }

  private isCampaignTypeModified(): boolean {
    return this.customer.IsCampaignNational !== this.existingCampaignTypeIsNational;
  }

  private isWebsiteUrlModified(websiteUrl: Boo.Objects.WebsiteUrl): boolean {
    return websiteUrl.IsModified || websiteUrl.Keywords.some(x => x.IsModified);
  }
}
