import { Component, Input, OnInit } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { ProTipService } from '../../../services/pro-tip.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-manager-protips',
  templateUrl: './ProTips.component.html'
})
export class ProTipsComponent implements OnInit {
    @Input() partnerId: number;
    @Input() partnerName: string;
    isLoading: KnockoutObservable<boolean>;
    title: string;
    languages: Boo.Objects.Language[] = null;
    selectedLanguage: KnockoutObservable<number>;
    proTips: Boo.Objects.ProTip[] = [];

    constructor(
      private proTipService: ProTipService,
      private sessionStorageService: SessionStorageService) { }

    public ngOnInit(): void {
        this.isLoading = ko.observable(true);
        this.title = `Pro Tips - ${this.partnerName}`;
        this.selectedLanguage = ko.observable(launchpad.config.LanguageIdEnum.English);
        this.selectedLanguage.subscribe(() => {
            this.loadProTips();
        });
        Utils.wrapDfd(this.sessionStorageService.getStaticData())
            .then((staticData: any) => {
                this.languages = staticData.Languages;
            })
            .then(this.loadProTips.bind(this))
            .fail((displayMessage: string) => {
                toastr.error(displayMessage);
            })
            .always(() => this.isLoading(false));
    }

    public delete(proTip: Boo.Objects.ProTip): void {
        this.isLoading(true);
        bootbox.confirm('Are you sure? This action cannot be undone.', (result: boolean) => {
            if (result === true) {
                Utils.wrapDfd(this.proTipService.delete(proTip.ProTipId))
                    .then(() => {
                        this.proTips = this.proTips.filter(x => x !=proTip);
                    }).fail((displayMessage: string) => {
                        toastr.error(displayMessage);
                    }).always(() => {
                        this.isLoading(false);
                    });
            }
        });
    }

    public createNewProTip(): void {
        PriorityViewModel.show('app-components-manager-protip', {
            partnerName: this.partnerName,
            partnerId: this.partnerId,
            languageId: this.selectedLanguage()
        })
        .then(this.loadProTips.bind(this));
    }

    public openProTip(proTip: Boo.Objects.ProTip): void {
        PriorityViewModel.show('app-components-manager-protip', {
            partnerName: this.partnerName,
            partnerId: this.partnerId,
            languageId: this.selectedLanguage(),
            proTip: proTip
        })
        .then(this.loadProTips.bind(this));
    }

    private loadProTips(): JQueryPromise<any> {
        return Utils.wrapDfd(this.proTipService.get(this.partnerId, this.selectedLanguage()))
            .then((proTips: Boo.Objects.ProTip[]): void => {
                this.proTips = proTips;
                return;
            });
    }
}
