import { Component, computed, effect, input, output, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Mustache from 'mustache';
import { DraftValue, ExampleNotificationRequest, NotificationChannel, NotificationTemplate, NotificationTemplateEditor, NotificationTemplateHistory } from '../../../shared/models/notifications/notifications';
import { MustacheHelper } from '../../../shared/mustache/mustache-helper';
import { MustacheTag } from '../../../shared/mustache/models/mustache';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-notification-template',
  templateUrl: './notification-template.component.html'
})
export class NotificationTemplateComponent implements NotificationTemplateEditor {
  template = input.required<NotificationTemplate>();
  history = input<NotificationTemplateHistory>();
  draftValues = input<DraftValue[]>([]);
  canEdit = input<boolean>(false);
  partnerId = input<number>();
  recipient = input<string>();
  definitionActive = input<boolean>(true);
  templateSaved = output<NotificationTemplate>();
  templateCreated = output<NotificationTemplate>();
  sendNotification = output<ExampleNotificationRequest>();
  revertToVersion = output<NotificationTemplateHistory>();
  recipientSaved = output<string>();
  canSendEmail = computed(() => 
    this.template().Channel === null
    || this.template().Channel === NotificationChannel.Email);
  canSendFrontApp = computed(() => this.template().Channel === NotificationChannel.FrontApp);
  canRevert = computed(() => !!this.history());
  isEditing: boolean = false;
  forms = this.formBuilder.group({
    title:['', [Validators.maxLength(256), Validators.required]],
    body:['', Validators.required]
  });
  renderedTitle: string;
  renderedBody: string;
  templateTags: MustacheTag[] = [];
  domParser: DOMParser = new DOMParser();
  recipientFormControl = this.formBuilder.control('', Validators.required);

  get title(): AbstractControl {
    return this.forms.get('title');
  }

  get body(): AbstractControl {
    return this.forms.get('body');
  }

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder) {
    effect(() => {
      this.cancel();

      if (!this.template().NotificationTemplateId) {
        this.edit();
      }
    });
  }

  // Templates are cached after parsing to save time on subsequent renders, should be cleared on destroy to save memory.
  ngOnDestroy(): void {
    Mustache.templateCache.clear();
  }

  openRenderModal(ref: TemplateRef<any>): void {
    try {
      this.templateTags = MustacheHelper.getTagsForTemplates([this.title.value, this.body.value])
      this.modalService.open(ref, { size: 'lg', backdrop: 'static' }).closed.subscribe(() => {
        this.renderHtml(MustacheHelper.flattenTags(this.templateTags));
      });
    } catch (error) {
      toastr.error(error);
    }
  }

  openModal(ref: TemplateRef<any>): void {
    this.modalService.open(ref, { size: 'lg' });
  }

  renderHtml(values: any): void {
    this.renderedTitle = Mustache.render(this.title.value, values);
    this.renderedBody = Mustache.render(this.body.value, values);
  }

  renderHtmlRaw(): void {
    this.renderedTitle = this.title.value;
    this.renderedBody = this.body.value;
  }

  sendRendered(sendFromUser: boolean): void {
    this.sendNotification.emit({
      Title: this.renderedTitle,
      Body: this.renderedBody,
      Channel: this.template().Channel,
      PartnerId: this.partnerId(),
      SendFromUser: sendFromUser
    });
  }

  edit(): void {
    this.title.enable();
    this.body.enable();
    this.isEditing = true;

    if (!this.definitionActive()) {
      toastr.warning('Disabled templates are not checked for tag validity.');      
    }
  }

  editWithValues(title: string, body: string): void {
    this.title.setValue(title);
    this.body.setValue(body);
    this.edit();
  }

  cancel(): void {
    this.title.setValue(!!this.history() ? this.history().Title : this.template()?.Title);
    this.body.setValue(!!this.history() ?  this.history().Body : this.template()?.Body);
    this.stopEditing();
  }

  save(): void {
    if (this.forms.invalid || !this.isTemplateValid(this.title.value, this.body.value)) {
      toastr.error('Please fix any errors before saving.');
      return;
    }

    this.template().Title = this.title.value;
    this.template().Body = this.body.value;

    this.stopEditing();

    if (this.template().NotificationTemplateId) {
      this.templateSaved.emit(this.template());
    } else {
      this.templateCreated.emit(this.template());
    }
  }

  revert(): void {
    if (!this.isTemplateValid(this.history().Title, this.history().Body)) {
      toastr.error('Failed to revert template.');
      return;
    }

    this.template().Title = this.history().Title;
    this.template().Body = this.history().Body;
    this.revertToVersion.emit(this.history());
  }

  handleKeydown(event:any) {
    if (event.key == 'Tab') {
        event.preventDefault();
        var start = event.target.selectionStart;
        var end = event.target.selectionEnd;
        event.target.value = event.target.value.substring(0, start) + '\t' + event.target.value.substring(end);
        event.target.selectionStart = event.target.selectionEnd = start + 1;
    }
  }

  saveRecipient(): void {
    this.recipientSaved.emit(this.recipientFormControl.value);
  }

  private stopEditing(): void {
    this.title.disable();
    this.body.disable();
    this.isEditing = false;
  }

  private isTemplateValid(title: string, body: string): boolean {
    // We cannot guarantee a disabled template has an object configured in service.
    if (!this.definitionActive())
    {
      return true;
    }

    const draftValueNames = this.draftValues().map(v => v.Name.toUpperCase());
    const templateTags = MustacheHelper.getTagsForTemplates([title, body]);
    const invalidTags = MustacheHelper.getKeys(templateTags).map(k => k.toUpperCase()).filter(k => !draftValueNames.includes(k));

    if (invalidTags.length > 0) {
      toastr.error(`The following tags are not configured: ${invalidTags.join(', ')}`);
      return false;
    }

    return true;
  }
}
