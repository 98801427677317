import { Directive, Input, ElementRef, OnChanges, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[slideVisible]'
})
export class SlideVisibleDirective implements AfterViewInit, OnChanges {
  @Input('slideVisible') public SlideVisible: KnockoutObservable<boolean>;

  private $element: JQuery;
  constructor(elementRef: ElementRef) {
    this.$element = $(elementRef.nativeElement);
  }

  public ngAfterViewInit(): void {
    this.$element.toggle(ko.utils.unwrapObservable(this.SlideVisible));
  }

  public ngOnChanges(): void {
    if (ko.utils.unwrapObservable(this.SlideVisible)) {
      this.$element.slideDown('fast');
    } else {
      this.$element.slideUp('fast');
    }
  }
}