import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import OnsiteRecommendationDetail = Boo.Objects.OnsiteRecommendationDetail;

@Injectable({
  providedIn: 'root'
})
export class OnsiteRecommendationDetailService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/onsiterecommendationdetails`;

  constructor(private http: HttpClient) { }

  getByCustomerId(customerId: number): Observable<Boo.Objects.OnsiteRecommendationDetail> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<OnsiteRecommendationDetail>(`${this.baseUrl}/GetByCustomerId`, { params: params });
  }

  save(detail: OnsiteRecommendationDetail){
    return this.http.post(this.baseUrl, detail);
  }

  // TODO: Create factories for creating objects
  createOnsiteRecommendationDetail(customerId: number): Boo.Objects.OnsiteRecommendationDetail {
    return <Boo.Objects.OnsiteRecommendationDetail> {
        OnsiteRecommendationDetailId: 0,
        CustomerId: customerId,
        TechnicalContactName: null,
        TechnicalContactEmail: null,
        TechnicalContactPhone: null,
        Note: null,
        Enabled: true,
        ContactType: Boo.OnsiteRecommendations.Models.Enums.ContactTypes.CustomerContact,
        Responsibility: Boo.OnsiteRecommendations.Models.Enums.Responsibilities.NotSelected
    };
  }
}
