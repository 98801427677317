import System from 'framework/System';
import { ReloadAnalyticsService } from './reload-analytics.service';
import ReloadRequiredType = app.services.models.enums.ReloadRequiredType;
import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ReloadService {
    protected static defaultConfig: IReloadServiceConfig = {
        shouldReloadCallback: (): void => { System.noop; },
        pollUrl: '/src/Scripts/reload.json',
        pollSeconds: 60,
        pollErrorLimit: 10,
        showMessageOnForceReload: true,
        eventualReloadSeconds: 600, // 10 mins
        reshowMessageOnCloseSeconds: 300, // 5 mins
        messageText: 'There is a newer version of this application available. Please reload when convenient.',
        reloadButtonText: 'Reload',
        closeButtonText: 'Close'
    };
    protected static reloadDataStorageKey: string = 'ReloadService_reload_data';

    protected config: IReloadServiceConfig = ReloadService.defaultConfig;
    protected originalResponse: IReloadResponse;
    protected eventualReloadTimestamp: number;
    protected forceReloadTimestamp: number;
    protected pollHandle: number;
    protected errorCount: number;
    protected hasMessageBeenShown: boolean = false;
    protected isMessageScheduled: boolean = false;
    protected showMessageTimeoutHandle: number;

    constructor(
      private reloadAnalyticsService: ReloadAnalyticsService,
      private sessionStorageService: SessionStorageService) { }

    public start(): void {
        if (this.pollHandle) {
            throw new Error('ReloadService is already started.');
        }
        this.errorCount = 0;
        this.pollHandle = window.setInterval(() => this.poll(), this.config.pollSeconds * 1000);
    }

    public stop(): void {
        if (this.pollHandle) {
            clearInterval(this.pollHandle);
            this.pollHandle = null;
        }
    }

    /**
     * Returns true if a force reload has been triggered or if an eventual reload has been triggered
     * and the eventualReloadSeconds has elapsed.
     * @returns
     */
    public isReloadRequired(): boolean {
        if (this.forceReloadTimestamp) {
            return true;
        }
        if (!this.eventualReloadTimestamp) {
            return false;
        }

        let staleSeconds = (Date.now() - this.eventualReloadTimestamp) / 1000;
        return staleSeconds >= this.config.eventualReloadSeconds;
    }

    /**
     * Reloads the application
     */
    public reload(logMsg?: string, isManualReload?: boolean): void {
        this.reloadAnalyticsService.trackReload(isManualReload);
        window.location.reload();
    }

    /**
     * Stores some data long enough to survive a single reload. In general, you should try to store
     * ids and params in the route before reload because this is more fool-proof. Storing objects
     * that could actually need to get reloaded themselves could be problematic. You should only use this method
     * when the route you need to reload is too crappy to easily persist a param in the route for reload.
     * @param {any} data
     */
    public storeDataForReload(data: any): void {
        this.sessionStorageService.setReloadData(data);
    }

    /**
     * Returns the data stored by storeDataForReload() and remove it from the cache
     * @returns
     */
    public getDataStoredForReload(): any {
      let data = this.sessionStorageService.getReloadData();
      this.sessionStorageService.clearReloadData();
      return data;
    }

    /**
     * Shows the reload message ONCE and then ignores all subsequent calls because then
     * the user has to temporarily close/ignore the message or reload.
     */
    public showMessage(): void {
        if (this.hasMessageBeenShown) {
            return;
        }
        this.hasMessageBeenShown = true;

        let show = () => {
            let toastElem: JQuery;
            let innerElem = $(`
                <div>${this.config.messageText}<div>
                <div class="text-right" style="margin-top: 5px;">
                    <button class="btn btn-info btn-sm">${this.config.closeButtonText}</button>
                    <button class="btn btn-danger btn-sm">${this.config.reloadButtonText}</button>
                </div>
            `);

            // close button
            innerElem.find('.btn-info').click(() => {
                toastr.clear(toastElem);
                window.setTimeout(() => show(), this.config.reshowMessageOnCloseSeconds * 1000);
            });

            // reload button
            innerElem.find('.btn-danger').click(() => {
                this.reload('Manual reload from Reload button', true);
            });

            // toastr.info type is wrong. it accepts an element, so we'll use a type assertion.
            toastElem = toastr.info(innerElem as any, null, {
                timeOut: 0,
                extendedTimeOut: 0,
                closeButton: false,
                tapToDismiss: false,
                onclick: null
            });
        };

        show();
        this.reloadAnalyticsService.messageWasShown();
    }

    protected poll(): void {
        // create URL with unique param so it's never cached
        let url = this.config.pollUrl;
        if (url.indexOf('?') > 0) {
            url += '&v=' + Date.now();
        } else {
            url += '?v=' + Date.now();
        }

        $.getJSON(url)
            .then((response: IReloadResponse) => {
                this.errorCount = 0; // reset on every successful response
                let clearCache = false;
                if (!this.originalResponse) {
                    this.originalResponse = response;
                    return;
                }

                // if forceKey does not match, stop polling and call shouldReloadCallback
                if (response.forceKey !== this.originalResponse.forceKey) {
                    clearCache = true;
                    this.stop();
                    this.forceReloadTimestamp = Date.now();

                    if (this.config.showMessageOnForceReload) {
                        this.showMessage();
                    }
                    if (this.config.shouldReloadCallback) {
                        this.config.shouldReloadCallback(true); // force reload param of true
                    }

                    launchpad.utils.log('App force reload triggered');
                    this.reloadAnalyticsService.trackReloadNeeded(ReloadRequiredType.forced, response.forceKey);
                }

                // if reload is not yet required, check eventualKey and call shouldReloadCallback if needed.
                // in this case, we'll keep polling because a force reload could come later.
                if (!this.forceReloadTimestamp && !this.eventualReloadTimestamp && response.eventualKey !== this.originalResponse.eventualKey) {
                    this.eventualReloadTimestamp = Date.now();

                    if (this.config.shouldReloadCallback) {
                        this.config.shouldReloadCallback(false);  // force reload param of false
                    }

                    launchpad.utils.log('App eventual reload triggered');
                    let reloadTimestampString: string = response.eventualKey.replace('GENERATED?rel=', '');
                    let reloadTimestamp: number = parseInt(reloadTimestampString, 10);
                    this.reloadAnalyticsService.trackReloadNeeded(ReloadRequiredType.eventual, moment(reloadTimestamp).utc().format('MM/DD/YYYY h:mm A'));
                }

                if (clearCache) {
                  this.sessionStorageService.clearForReload();
                }
            })
            .fail((xhr: JQueryXHR, status: string, error: string) => {
                this.errorCount++;
                if (this.errorCount >= this.config.pollErrorLimit) {
                    this.stop();
                    window.setTimeout(() => this.start(), this.config.pollSeconds * 10 * 1000);
                    launchpad.utils.log(`ReloadService paused because error limit of ${this.config.pollErrorLimit} was reached. Last error: ${error}`);
                }
            });
    }
}

interface IReloadResponse {
    forceKey: any;
    eventualKey: any;
}

interface IReloadServiceConfig {
    shouldReloadCallback?: (force: boolean) => void;
    pollUrl?: string;
    pollSeconds?: number;
    pollErrorLimit?: number;
    showMessageOnForceReload?: boolean;
    eventualReloadSeconds?: number;
    reshowMessageOnCloseSeconds?: number;
    messageText?: string;
    reloadButtonText?: string;
    closeButtonText?: string;
}
