<div>
	<!-- THIS IS A PRIORITY VIEW -->
	<div class="card">
		<div class="card-header">
			<span>Add New Job</span>
			<button (click)="closeView()" type="button" class="btn-close float-end"></button>
		</div>
		<div [loadingOverlay]="isLoading()" class="card-body">
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label for="type" class="control-label">Job Type</label>
						<select [dataValidation]="{ placement: 'top', value: selectedJobTypeId }" [ngModel]="selectedJobTypeId()" (ngModelChange)="selectedJobTypeId($event)" [ngModelOptions]="{ standalone: true }" name="type" class="form-select">
							<option [ngValue]="undefined">Select Job Type</option>
							<option *ngFor="let option of jobTypes()" [ngValue]="option.JobTypeId">{{ option.Name }}</option>
						</select>
					</div>
					<div class="form-group">
						<label for="type" class="control-label">Job Priority</label>
						<select [dataValidation]="{ placement: 'top', value: selectedJobPriorityId }" [ngModel]="selectedJobPriorityId()" (ngModelChange)="selectedJobPriorityId($event)" [ngModelOptions]="{ standalone: true }" name="type" class="form-select">
							<option [ngValue]="undefined">Select Job Priority</option>
							<option *ngFor="let option of jobPriorities" [ngValue]="option.JobPriorityId">{{ option.Name }}</option>
						</select>
					</div>
					<div class="form-group">
						<label for="version" class="control-label">Version</label>
						<input [dataValidation]="{ placement: 'top', value: version }" [numericValue]="{ value:version, precision: 0 }" name="version" class="form-control" type="number"/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label for="payload" class="control-label">Payload</label>
						<textarea [dataValidation]="{ placement: 'top', value: payload }" [ngModel]="payload()" (ngModelChange)="payload($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" rows="4"></textarea>
					</div>
				</div>
			</div>
			<div class="card card-body bg-light d-block text-end">
				<button (click)="closeView()" class="btn btn-outline-secondary">
					<i class="fas fa-times"></i>
					Cancel
				</button>
				<button (click)="addJob()" class="btn btn-primary">
					<i class="fas fa-times"></i>
					Save
				</button>
			</div>
		</div>
	</div>
</div>
