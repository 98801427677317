import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { FrontService } from '../../../services/front.service';

@Component({
  selector: 'app-components-manager-partner-front-channel',
  templateUrl: './partner-front-channel.component.html'
})
export class PartnerFrontChannelComponent implements OnInit {
  channels: app.tsmodels.interfaces.FrontChannel[] = [];
  selectedChannel: any = null;

  @Input() partnerId: number;
  constructor(private frontService: FrontService) { }

  ngOnInit(): void {
    forkJoin({
      partnerChannel: this.frontService.getPartnerChannel(this.partnerId),
      allChannels: this.frontService.getChannels()
    }).subscribe(x => {
      this.channels = x.allChannels;
      this.selectedChannel = x.partnerChannel;
    });
  }

  save(partnerId: number): void {
    this.frontService.savePartnerChannel(partnerId, this.selectedChannel).subscribe(()=>{});
  }

  unselectChannel(): void {
    this.selectedChannel = null;
  }
}