import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import { TicketService } from '../../../services/ticket.service';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import Utils from '../../../shared/utils';
import permissions from 'app/models/Permissions';

/**
 * This class shows how many times we have called the customer. We display the humanized form (10 minutes ago) and the exact date.
 * The times are derived from the inserted dates on contact notes.
 */
@Component({
    selector: 'app-components-ticket-contactattempts',
    templateUrl: './ContactAttempts.component.html'
})
export class ContactAttemptsComponent extends BaseComponent {
    moment = moment;
    numberOfContactAttemptsContactMade: KnockoutComputed<number>;
    numberOfContactAttemptsContactNotMade: KnockoutComputed<number>;
    totalAttempts: KnockoutComputed<number>;
    lastContactMadeNote: CustomerNoteObservable;
    lastContactNotMadeNote: CustomerNoteObservable;
    elapsedHumanizedTimeContactMade: KnockoutObservable<string> = ko.observable('');
    elapsedHumanizedTimeContactNotMade: KnockoutObservable<string> = ko.observable('');
    ticketNotes: Boo.Objects.CustomerNote[];
    lastTicketNote: Boo.Objects.CustomerNote;
    ticketNotesLength: number;
    ticketNotesIsEmpty: boolean;
    canReviseNotes: boolean;

    // Store our interval so that we can turn it off later. 
    private refreshHumanizedTimeInterval: number;

    constructor(private ticketService: TicketService) {
        super();
    }

    activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        return super.activate(params).then(() => {
            // ContactTypes that indicate a successful conversation. 
            let contactMadeTypes = [Boo.Objects.Enums.ContactTypeEnum.OutboundCallContactMade, Boo.Objects.Enums.ContactTypeEnum.InboundContact];
            this.numberOfContactAttemptsContactMade = ko.computed(() => {
                return this.contactAttemptCount(contactMadeTypes);
            });

            this.lastContactMadeNote = this.latestContactType(contactMadeTypes);

            // Contact types that indicate a contact attempt.
            let contactNotMadeTypes = [Boo.Objects.Enums.ContactTypeEnum.OutboundCallNoContact];
            this.numberOfContactAttemptsContactNotMade = ko.computed(() => {
                return this.contactAttemptCount(contactNotMadeTypes);

            });

            this.lastContactNotMadeNote = this.latestContactType(contactNotMadeTypes);

            this.setHumanizedTimes();

            this.totalAttempts = ko.computed(() => {
                return this.numberOfContactAttemptsContactNotMade() + this.numberOfContactAttemptsContactMade();
            });

            this.refreshHumanizedTimeInterval = window.setInterval(
                () => {
                    this.setHumanizedTimes();
                },
                10000);

            let notes = ko.toJS(this.ticket.TicketNotes) as Boo.Objects.CustomerNote[];
            this.ticketNotes = notes;
            this.canReviseNotes = this.getCanReviseNotes();

            let sortedNotes: Boo.Objects.CustomerNote[] = _.sortBy(this.ticketNotes, (note: Boo.Objects.CustomerNote) => {
                return note.InsertedDate;
            });

            this.lastTicketNote = sortedNotes.length > 0 ? sortedNotes[sortedNotes.length - 1] : null;

            this.ticketNotesLength = this.ticketNotes.length;
            this.ticketNotesIsEmpty = this.ticketNotesLength === 0;
        });
    }

    deactivate(): void {
        // turn off our refresh
        clearInterval(this.refreshHumanizedTimeInterval);
    }


    isNoteRevisable(note: Boo.Objects.CustomerNote): boolean {
        if (!this.canReviseNotes) {
            return false;
        }

        let revisableContactTypeIds: number[] = [
            launchpad.config.ContactTypeEnum.CampaignSummary,
            launchpad.config.ContactTypeEnum.Email,
            launchpad.config.ContactTypeEnum.InboundContact,
            launchpad.config.ContactTypeEnum.OutboundCallContactMade,
            launchpad.config.ContactTypeEnum.OutboundCallNoContact
        ];

        return _.contains(revisableContactTypeIds, note.ContactTypeId);
    }

    reviseNote(note: Boo.Objects.CustomerNote): void {
        if (!this.isNoteRevisable(note)) {
            throw new Error('You cannot revise this note.');
        }

        PriorityViewModel.show('app-components-managecustomer-revisenote', { note: ko.mapping.toJS(note), customer: this.customer, parent: this })
            .done(() => {
                // reselect ticket to get revised notes
                Utils.wrapDfd(this.ticketService.get(this.ticket.TicketId()))
                    .then((ticketData: Boo.Objects.Ticket) => {
                        let tempTicket: TicketObservable = ko.mapping.fromJS(ticketData);
                        this.ticket.TicketNotes(tempTicket.TicketNotes());
                        this.ticketNotes = ko.toJS(this.ticket.TicketNotes) as Boo.Objects.CustomerNote[];
                    }).fail(() => {
                        toastr.error('Unable to retrieve ticket');
                    });
            });
    }

    /**
     * Gets the number of contact attempts.
     * @param {number[]} contactTypes
     * @returns
     */
    private contactAttemptCount(contactTypes: number[]): number {
        return this.getContactAttempts(contactTypes).length;
    }

    /**
     * Gets the most recent contact attempt (by type). Always returns an initialized array. 
     * @param {number[]} contactTypes
     * @returns
     */
    private latestContactType(contactTypes: number[]): CustomerNoteObservable {
        return _.chain(this.getContactAttempts(contactTypes)).sortBy((note) => note.InsertedDate).last().value();
    }

    /**
     * Gets the contact attempst for the type from the internal memory store. 
     * @param {number[]} contactTypes
     * @returns
     */
    private getContactAttempts(contactTypes: number[]): CustomerNoteObservable[] {
        let notes: CustomerNoteObservable[] = [];
        let allNotes: CustomerNoteObservable[] = <CustomerNoteObservable[]>this.ticket.TicketNotes();

        if (allNotes) {
            notes = _.filter(allNotes, (note: any) => {
                return _.contains(contactTypes, note.ContactTypeId());
            });
        }

        return notes;
    }

    /**
     * The elapsed time in a humanized form (for example, 10 minutes ago). 
     * @param {Date} date
     * @returns
     */
    private humanizeElapsedTime(date: Date): string {
        let result: string = '';
        if (date) {
            result = moment(date).from(moment(), true) + ' ago';
        }

        return result;
    }

    /**
     * Sets the humanized for for each date. This is only a method so that the interval can call back and update both dates. 
     */
    private setHumanizedTimes(): void {
        if (this.lastContactMadeNote) {
            this.elapsedHumanizedTimeContactMade(this.humanizeElapsedTime(this.lastContactMadeNote.InsertedDate()));
        }

        if (this.lastContactNotMadeNote) {
            this.elapsedHumanizedTimeContactNotMade(this.humanizeElapsedTime(this.lastContactNotMadeNote.InsertedDate()));
        }
    }

    private getCanReviseNotes(): boolean {
        if (launchpad.hasPermission(this.partner, this.partnerUsers, permissions.CanReviseNotes, this.user)) {
            return this.partnerUser.PartnerId === 1
                && _.any(this.partnerUsers, (x) => { return x.UserLevelId === launchpad.config.keys.managerUserLevelId; }); // is manager
        }
        return false;
    }
}
