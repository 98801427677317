import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ESignatureDocumentService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/esignaturedocuments`;

  constructor(private http: HttpClient) { }

  getLatest(customerId: number, type: Boo.ESignature.Models.Enums.ESignatureDocumentTypes ): Observable<Boo.ESignature.Models.ESignatureDocument> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('type', type);
    
    return this.http.get<Boo.ESignature.Models.ESignatureDocument>(`${this.baseUrl}/getLatest`, {params: params});
  }
}
