import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpaChecklistService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/upachecklist`;

  constructor(private http: HttpClient) { }

  getOrCreate(taskId: number): Observable<Boo.Objects.Checklist.UpaChecklist[]> {
    const params = new HttpParams().set('taskId', taskId);
    return this.http.get<Boo.Objects.Checklist.UpaChecklist[]>(`${this.baseUrl}/getOrCreate`, { params: params });
  }

  get(taskId: number): Observable<Boo.Objects.Checklist.UpaChecklist[]> {
    const params = new HttpParams().set('taskId', taskId);
    return this.http.get<Boo.Objects.Checklist.UpaChecklist[]>(`${this.baseUrl}/get`, { params: params });
  }

  save(checklists: Boo.Objects.Checklist.UpaChecklist[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/save`, checklists);
  }

  delete(taskId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/delete`, taskId);
  }
}
