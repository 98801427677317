<div class="card">
	<div class="card-header">
			Workflows
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-md-4 offset-md-8">
				<div class="form-group">
					<label>Workflows</label>
					<select [ngModel]="selectedWorkflowDefinition()" (ngModelChange)="selectedWorkflowDefinition($event)" [ngModelOptions]="{ standalone: true }" (change)="load()" class="form-select">
						<option *ngFor="let option of workflowDefinitions()" [ngValue]="option">{{ option.Name }}</option>
					</select>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<iframe style="height:2000px; width:2000px; overflow:hidden" frameborder="0" scrolling="no" id="workflowGraph" src="/Static/Graph.html"></iframe>
			</div>
		</div>
	</div>
</div>
