import 'quill';
import Quill from 'quill';

export interface Config {
  customerUrl: string
}

export default class LinkCustomerUrl {
  quill: Quill
  options: Config

  constructor(quill: Quill, options: Config) {
    this.quill = quill;
    this.options = options;

    var toolbarSections = this.quill.root.parentElement.parentElement.querySelectorAll('.ql-formats');
    if (toolbarSections.length <= 0) {
      return;
    }

    toolbarSections[toolbarSections.length - 1].insertAdjacentHTML('beforeend', '<button class=\'link-customer\'><span class="link-customer-icon" style="background-image:url(./Images/linkcustomer.png);"></span></button>');
    let linkCustomerButton = document.querySelector('.link-customer');
    linkCustomerButton.addEventListener('click', () => {
      let selection = this.quill.getSelection();
      if (!selection || selection.index < 0) {
        return;
      }

      let text = this.quill.getText(selection.index, selection.length);
      quill.deleteText(selection.index, selection.length);
      quill.insertText(selection.index, text, 'link', options.customerUrl, "user");
    });
  }
}