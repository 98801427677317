import { Component, OnInit } from '@angular/core';
import BusinessCitationProviderCategory = Boo.BusinessCitations.Models.BusinessCitationProviderCategory;
import { BusinessCitationService } from '../../../../services/business-citation.service';
import { LocalProfileService } from '../../../../services/local-profile.service';
import { PartnerSsoService } from '../../../../services/partner-sso.service';
import { finalize, switchMap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import System from 'framework/System';

@Component({
  selector: 'app-components-specialist-actioncomponents-chooselocalprofilecategory',
  templateUrl: './ChooseLocalProfileCategory.component.html'
})
export class ChooseLocalProfileCategoryComponent implements IActionWorkspace, IActivatable, OnInit {
  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;
  categories: BusinessCitationProviderCategory[];
  localProfile: Boo.Objects.LocalProfiles.LocalProfile;
  partnerRequiresSso: boolean = false;
  partnerSsoLabel: string = '';
  isReadOnly: boolean;
  isLoading: boolean;
  form: UntypedFormGroup;

  private partnerId: number;

  constructor(
    private businessCitationService: BusinessCitationService,
    private localProfileService: LocalProfileService,
    private partnerSsoService: PartnerSsoService,
    private formBuilder: UntypedFormBuilder) { }

  canActivate?(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: IActionWorkspaceActivationParams): boolean {
    params.controlViewModel(this);
    this.actionViewModel = ko.observable(null);
    this.currentAction = params.currentAction;
    return true;
  }

  ngOnInit(): void {
    this.partnerId = this.currentAction().Customer.PartnerId;
    this.partnerRequiresSso = this.partnerSsoService.localProfilesRequireSso(this.partnerId);
    this.partnerSsoLabel = this.partnerSsoService.getLocalProfileSsoLabel(this.partnerId);
    this.isReadOnly = this.partnerRequiresSso;

    this.loadLocalProfile();
  }

  isStepReadyToReject(): boolean {
    return false;
  }

  isStepReadyToSave(): boolean {
    return false;
  }

  isStepReadyToComplete(): JQueryPromise<boolean> {
    let selectedCategories = this.form.getRawValue().categories;
    this.localProfile.Categories = this.categories.filter(c => selectedCategories.indexOf(c.BusinessCitationProviderCategoryId) !== -1);
    this.currentAction().LocalProfile = this.localProfile;

    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return System.resolvedPromise(false);
    }

    return System.resolvedPromise(true);
  }

  isStepReadyToDelete(): boolean {
    return false;
  }

  editOnPartnerSite(): void {
    this.isLoading = true;

    (this.localProfile.Identifier ?
      this.partnerSsoService.getLocalProfileSso(this.partnerId, this.localProfile.Identifier) :
      this.partnerSsoService.getCustomerSso(this.partnerId, this.localProfile.CustomerId))
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(url => {
        if (url) {
          window.open(url, '_blank');
        } else {
          toastr.error('Could not log into partner site. Please ensure sso configuration is correct.');
        }
      }, (err) => toastr.error(err));
  }

  refresh() {
    this.loadLocalProfile('Local Profile data refreshed');
  }

  private loadLocalProfile(message: string = null) {
    this.isLoading = true;

    this.localProfileService.by(this.currentAction().Task.LocalProfileId)
      .pipe(
        switchMap(localProfile => {
          this.localProfile = localProfile;

          this.form = this.formBuilder.group({
            categories: [localProfile.Categories.flatMap(x => x.BusinessCitationProviderCategoryId), [Validators.required, CustomValidators.maxLengthArrayValidator(10)]],
          });

          return this.businessCitationService.getBusinessCitationProviderCategories(this.localProfile.BusinessCitationProviderId);
        }),
        finalize(() => this.isLoading = false)
      ).subscribe(categories => {
        this.categories = categories;

        if (message) {
          toastr.success(message);
        }
      }, (err) => toastr.error(err));
  }
}
