<div class="row">
  <div class="col-md-9">
    <h3>Customer Default Allocation</h3>
  </div>
  <div class="col-md-3 text-end">
    <button class="btn btn-primary" (click)="resetToPartnerDefaults()">Reset to Partner Defaults</button>
  </div>
</div>

<app-components-managecustomer-allocatework-tasktypeselection [customerId]="customer.CustomerId()"
  [seoWorkSettings]="seoWorkSettings" [taskTypes]="defaultTaskTypes" [isCurrentPeriod]="false"
  (addTaskType)="addDefaultItem($event)" (refresh)="refresh.emit()">
</app-components-managecustomer-allocatework-tasktypeselection>

<table class="table table-bordered" id="work-table" *withPermissions="let allow of ['CanReserveSeoMinutes']">
  <thead>
    <tr>
      <th>Task Name</th>
      <th class="num-col">Minutes Per Task</th>
      <th class="num-col">Qty</th>
      <th class="num-col">Total Minutes</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let default of defaults">
      <tr *ngIf="default.TaskCount" [ngClass]="{'alert-danger': isNonWorkable(default.TaskTypeId)}">
        <td>
          <span [ngbPopover]="default.TaskType.Description" popoverTitle="Description" placement="left auto"
            triggers="mouseenter:mouseleave">
            <i class="fa fa-info-circle"></i>
          </span>
          <span>{{ default.TaskType.Name }}</span>
          <span *ngIf="isNonWorkable(default.TaskTypeId)"> - This task is currently a non-workable task</span>
        </td>
        <td class="text-center">{{ default.MinutesPerTask }}</td>
        <td class="text-center">
          <ng-select [multiple]="false" [closeOnSelect]="true" [clearable]="false" placeholder="Qty" class="qty-select"
            [(ngModel)]="default.TaskCount" (change)="updateDefaults()">
            <ng-option *ngIf="maxCount(default) < maxQtyNumber" [disabled]="true">Max: {{ maxCount(default)
              }}</ng-option>
            <ng-option value="0">0</ng-option>
            <ng-option *ngFor="let qty of qtyNumbers" [value]="qty" [disabled]="qtyDisabled(qty, default)"
              class="text-center">
              {{qty}}
            </ng-option>
          </ng-select>
        </td>
        <td class="text-center">{{ (default.MinutesPerTask * default.TaskCount) }}</td>
      </tr>
    </ng-container>
    <tr class="whitesmoke">
      <td class="text-end" colspan="3">
        <span [ngbPopover]="reservedMinutesDescription" popoverTitle="Reserved Minutes" placement="left auto"
          triggers="mouseenter:mouseleave">
          <i class="fa fa-info-circle"></i>
        </span>
        Minutes to Reserve
      </td>
      <td class="text-center">
        <input [disabled]="!allow().CanReserveSeoMinutes()" [(ngModel)]="seoWorkSettings.ReservedSeoMinutesDefault"
          [ngModelOptions]="{ standalone: true }" type="number" class="form-control text-center" />
      </td>
    </tr>
    <tr class="whitesmoke">
      <td class="text-end" colspan="3">
        <div *ngIf="!validTotalMinutes" class="alert alert-danger mb-0">
          Invalid Minutes:
          {{ minuteDifferenceMessage }}
          <i class="fas fa-arrow-right"></i>
        </div>
      </td>
      <td class="text-center ">
        <span [ngClass]="{'text-danger': !validTotalMinutes}">{{ totalMinutes }}</span>/
        {{ this.seoWorkSettings.AllotedSeoMinutesForDefaults }}
      </td>
    </tr>
  </tbody>
</table>