/* tslint:disable */

import { Component } from '@angular/core';
import { PartnerService } from '../../../services/partner.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-stats-specialist',
	templateUrl: './Specialist.component.html'
})
export class SpecialistComponent {
  public isLoading: any = ko.observable(false);
  public todaysSubscriptionHours: any = ko.observable();
  public todaysHours: any = ko.observable();
  public todaysEfficiency: any = ko.observable();
  public todaysActions: any = ko.observable();

  public refresh(): void {
    this.isLoading(true);
    Utils.wrapDfd(this.partnerService.getTaskWorkerSummary())
    .then((summary: Boo.Objects.PartnerTaskWorkerTotals) => {
      this.todaysSubscriptionHours(summary.TodaysSubscriptionHours);
      this.todaysHours(summary.TodaysActualHours);
      this.todaysEfficiency(summary.TodaysEfficiency * 100);
      this.todaysActions(summary.TodaysCompletedTaskCount);
      this.isLoading(false);
    }).fail((displayMessage) => {
      toastr.error(displayMessage);
      this.isLoading(false);
    });
  }

  constructor(private partnerService: PartnerService) { }

  public activate(): void {
    this.refresh();
  }
}
