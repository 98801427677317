import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import CustomValidators from '../../../../shared/custom-validators';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UniqueKeywordSuggestionValidator } from '../unique-keyword-suggestion-validator';

@Component({
  selector: 'app-components-shared-keywords-halo-keyword-suggestions',
  templateUrl: './halo-keyword-suggestions.component.html',
  styleUrls: ['./halo-keyword-suggestions.component.scss']
})
export class HaloKeywordSuggestionsComponent implements OnInit, OnDestroy {
  @Input() haloKeywordSuggestionsAdded: Observable<Boo.Objects.LegacyKeywordSuggestion[]>;
  @Input() isLocalCampaign: boolean = undefined;
  @Input() customerId: number;
  @Input() taskId: number;
  @Input() mimimumHaloKeywordSuggestions: number;
  @Input() maximumHaloKeywordSuggestions: number;
  @Input() existingHaloKeywords: Boo.Objects.WebsiteKeywordTracking[] = [];
  @Output() haloKeywordCountChange: EventEmitter<number> = new EventEmitter<number>();
  haloKeywordSuggestions: Boo.Objects.LegacyKeywordSuggestion[] = [];
  
  newKeywordIsAreaLast: boolean = true;
  newKeywordControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]);
  newAreaControl: UntypedFormControl = new UntypedFormControl('', [Validators.maxLength(50), CustomValidators.requiredIfValidator(() => this.isLocalCampaign)]);
  newRankControl: UntypedFormControl = new UntypedFormControl(101, [Validators.required, Validators.min(1), Validators.max(101)]);
  newKeywordFormGroup: UntypedFormGroup = new UntypedFormGroup({
    keyword: this.newKeywordControl,
    area: this.newAreaControl,
    rank: this.newRankControl
  });

  sortingOptions = [
    { display: "Keyword", method: (a: Boo.Objects.LegacyKeywordSuggestion, b: Boo.Objects.LegacyKeywordSuggestion) => a.Keyword.localeCompare(b.Keyword), disableIfNational: false },
    { display: "Area", method: (a: Boo.Objects.LegacyKeywordSuggestion, b: Boo.Objects.LegacyKeywordSuggestion) => a.Area.localeCompare(b.Area), disableIfNational: true },
    { display: "Rank", method: (a: Boo.Objects.LegacyKeywordSuggestion, b: Boo.Objects.LegacyKeywordSuggestion) => a.Rank - b.Rank, disableIfNational: false }
  ]

  private haloKeywordSuggestionAddedSubscription: Subscription;
  private uniqueKeywordSuggestionValidator: UniqueKeywordSuggestionValidator;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.uniqueKeywordSuggestionValidator = new UniqueKeywordSuggestionValidator([], this.haloKeywordSuggestions, () => !this.isLocalCampaign);
    this.uniqueKeywordSuggestionValidator.addHaloKeywords(this.existingHaloKeywords);
    this.newKeywordFormGroup.addValidators(this.uniqueKeywordSuggestionValidator.getValidator());
    this.haloKeywordSuggestionAddedSubscription = this.haloKeywordSuggestionsAdded.subscribe((haloKeywordSuggestions) => {
      // Need to make a new keyword suggestion and copy over data to prevent overwriting the type
      let newHaloKeywordSuggestions = haloKeywordSuggestions
        .filter((haloKeywordSuggestion) => this.uniqueKeywordSuggestionValidator.isKeywordUnique(haloKeywordSuggestion))
        .map((haloKeywordSuggestion) => {
          let newHaloKeywordSuggestion = new Object() as Boo.Objects.LegacyKeywordSuggestion;
          Object.assign(newHaloKeywordSuggestion, haloKeywordSuggestion);
          return newHaloKeywordSuggestion;
        });
      this.haloKeywordSuggestions.push(...newHaloKeywordSuggestions);
      this.uniqueKeywordSuggestionValidator.addKeywordSuggestions(newHaloKeywordSuggestions);
      this.haloKeywordCountChange.emit(this.haloKeywordSuggestions.length);
    });
  }

  ngOnDestroy() {
    this.haloKeywordSuggestionAddedSubscription.unsubscribe();
  }

  isValid(saveType: SaveTypes): boolean {
    if (saveType === SaveTypes.Update) {
      return true;
    }

    let isValid = true;

    if (this.haloKeywordSuggestions.length < this.mimimumHaloKeywordSuggestions) {
      toastr.error("Must add " + (this.mimimumHaloKeywordSuggestions - this.haloKeywordSuggestions.length) + " more halo keyword(s)");
      isValid = false;
    }

    if (this.haloKeywordSuggestions.length > this.maximumHaloKeywordSuggestions) {
      toastr.error(`A maximum of ${this.maximumHaloKeywordSuggestions} halo keywords can be added. Must remove ${this.haloKeywordSuggestions.length - this.maximumHaloKeywordSuggestions} halo keyword(s)`);
      isValid = false;
    }

    return isValid;
  }

  get() : Boo.Objects.LegacyKeywordSuggestion[] {
    this.haloKeywordSuggestions.forEach((keywordSuggestion) => {
      keywordSuggestion.CustomerId = this.customerId;
      keywordSuggestion.KeywordSuggestionStatusId = Boo.Objects.Enums.KeywordSuggestionStatus.Proposed;
    });
    return this.haloKeywordSuggestions;
  }

  removeKeywordSuggestion(keyword: Boo.Objects.LegacyKeywordSuggestion) {
    bootbox.confirm('Are you sure you want to remove this keyword? This action cannot be undone.',
      (result: any) => {
        if (result !== true) {
          return;
        }
        this.haloKeywordSuggestions.splice(this.haloKeywordSuggestions.indexOf(keyword), 1);
        this.uniqueKeywordSuggestionValidator.removeKeywordSuggestion(keyword);
        this.haloKeywordCountChange.emit(this.haloKeywordSuggestions.length);
      });
  }

  updateKeywordIsAreaLast(newKeywordIsAreaLast: boolean) {
    this.newKeywordIsAreaLast = newKeywordIsAreaLast;
  }

  addKeyword(modal: NgbModalRef = null): any {
    Object.keys(this.newKeywordFormGroup.controls).forEach((key) => {
      this.newKeywordFormGroup.controls[key].markAsDirty();
      this.newKeywordFormGroup.controls[key].updateValueAndValidity();
    });

    if (this.newKeywordFormGroup.valid) {
      let newKeywordSuggestion = {
        Area: this.newAreaControl.value,
        CreatedByTaskId: this.taskId,
        CustomerId: this.customerId,
        IsAreaFirst: this.newKeywordIsAreaLast ? false : true,
        Keyword: this.newKeywordControl.value,
        KeywordSuggestionTypeId: Boo.Objects.Enums.KeywordSuggestionTypes.Halo,
        Rank: this.newRankControl.value,
      } as Boo.Objects.LegacyKeywordSuggestion;

      this.haloKeywordSuggestions.push(newKeywordSuggestion);
      this.uniqueKeywordSuggestionValidator.addKeywordSuggestion(newKeywordSuggestion);
      this.haloKeywordCountChange.emit(this.haloKeywordSuggestions.length);
      modal.close({} as Boo.Objects.LegacyUrlSuggestion);
    } else {
      toastr.error("Please correct the errors on the page.");
    }
  }

  openModal(template: any): void {
    this.modalService.open(template).result.finally(() => {
      this.newAreaControl.setValue('');
      this.newKeywordIsAreaLast = true;
      this.newKeywordControl.setValue('');
      this.newRankControl.setValue(101);
      Object.keys(this.newKeywordFormGroup.controls).forEach((key) => {
        this.newKeywordFormGroup.controls[key].markAsPristine();
        this.newKeywordFormGroup.controls[key].markAsUntouched();
      })
    });
  }

  dropListDropped(event: CdkDragDrop<Boo.Objects.LegacyKeywordSuggestion[]>): void {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  sort(comparisonFunction: (a: Boo.Objects.LegacyKeywordSuggestion, b: Boo.Objects.LegacyKeywordSuggestion) => number): void {
    this.haloKeywordSuggestions.sort((a, b) => comparisonFunction(a, b));
  }
}
