<div>
  <app-components-togglepanel [(isOpen)]="isOpen" title="Pre-Audit Report" toggleBody="true">
    <ng-container *ngIf="reportLoaded; else reportNotLoaded">
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
              Organic Search
            </div>
            <div class="card-body p-1">
              <ul class="list-group list-group-flush">
                <ng-container *ngFor="let item of organicSearchItems">
                  <ng-container *ngTemplateOutlet="reportItem; context: { $implicit: item }"></ng-container>
                </ng-container>
              </ul>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              Mobile
            </div>
            <div class="card-body p-1">
              <ul class="list-group list-group-flush">
                <ng-container *ngFor="let item of mobileItems">
                  <ng-container *ngTemplateOutlet="reportItem; context: { $implicit: item }"></ng-container>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
              Website Build
            </div>
            <div class="card-body p-1">
              <ul class="list-group list-group-flush">
                <ng-container *ngFor="let item of websiteBuildItems">
                  <ng-container *ngTemplateOutlet="reportItem; context: { $implicit: item }"></ng-container>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <!--Local card might go here if we add stuff to it-->
      </div>
    </ng-container>
  </app-components-togglepanel>
</div>

<ng-template #reportNotLoaded>
Pre-Audit Report is not available
</ng-template>

<ng-template #reportItem let-item>
  <li class="list-group-item ps-2">
    <div class="row">
      <div class="col-md-6">
        <span class="fa-stack fa-2xs me-1">
          @switch (item.status) {
          @case (PreAuditReportItemStatus.Warning) {
          <i class="fa fa-circle fa-stack-2x text-warning"></i>
          <i class="fa fa-exclamation fa-stack-1x fa-inverse"></i>
          }
          @case (PreAuditReportItemStatus.Pass) {
          <i class="fa fa-circle fa-stack-2x text-success"></i>
          <i class="fa fa-check fa-stack-1x fa-inverse"></i>
          }
          @case (PreAuditReportItemStatus.Fail) {
          <i class="fa fa-circle fa-stack-2x text-danger"></i>
          <i class="fa fa-xmark fa-stack-1x fa-inverse"></i>
          }
          @case (PreAuditReportItemStatus.Info) {
          <i class="fa fa-circle fa-stack-2x text-secondary"></i>
          <i class="fa fa-info fa-stack-1x fa-inverse"></i>
          }
          }
        </span>
        <span>{{item.title}}</span>
      </div>
      <div class="col-md-6">
        <span>{{item.displayData}}</span>
      </div>
    </div>
  </li>
</ng-template>