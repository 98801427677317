import { Component, Input, OnInit } from '@angular/core';
import System from 'framework/System';
import Check from 'framework/Check';
import { CustomerAccountRouteFactory } from 'app/customeraccount/CustomerAccountRouteFactory';
import Grid from 'app/components/Grid';
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { WebsiteService } from '../../../services/website.service';
import { CustomerAccountService } from '../../../services/customer-account.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../utils';
import { forkJoin } from 'rxjs';

type CustomerAccountGroupOptions = app.managecustomer.components.interfaces.CustomerAccountGroupOptions;

/**
 * Displays, saves, and validates, a group of customer accounts.
 */
@Component({
    selector: 'app-components-managecustomer-customeraccountgroup',
    templateUrl: './customer-account-group.component.html'
})
export class CustomerAccountGroupComponent implements OnInit, app.interfaces.ISaveAndValidate {
    @Input() options: CustomerAccountGroupOptions;
    grid: KnockoutObservable<Grid<ICompositionRoute>> = ko.observable(null);
    isLoading: boolean = false;
    isEnabled: boolean;
    showSaveButton: boolean;
    canClose: boolean = false;
    hasAccountAndSiteId: boolean = false;

    components: app.managecustomer.components.interfaces.CustomerAccountViewModel[] = [];

    private maxNumberOfColumns: number;
    private dfd: JQueryDeferred<any>;
    getPublicApi(): app.interfaces.ISave {
        return {
            save: (saveType?: SaveTypes): JQueryPromise<void> => { return this.save(saveType); },
            confirmSave: (saveType?: SaveTypes): JQueryPromise<boolean> => { return System.resolvedPromise(true); }
        };
    }

    constructor(
        private customerAccountRouteFactory: CustomerAccountRouteFactory,
        private customerAccountService: CustomerAccountService,
        private websiteService: WebsiteService,
        protected sessionStorageService: SessionStorageService) { }

    ngOnInit() {
        Check.isNotEmpty(this.options.customerId, 'Customer ID is required.');
        Check.hasSequence(this.options.customerAccountTypes, 'Customer account groups cannot be empty');

        if (this.options.publicApiCallback) {
            this.options.publicApiCallback(this.getPublicApi());
        }

        this.isEnabled = this.options.isEnabled ?? true;
        this.showSaveButton = this.options.EditableNotes === true ? true : (this.isEnabled === false || this.options.showSaveButton === false) ? false : true;
        this.maxNumberOfColumns = this.options.columns || 2;
        Check.notNegative(this.maxNumberOfColumns);

        this.dfd = this.options.dfd;
        this.canClose = this.dfd ? true : false;

        forkJoin([
            this.customerAccountService.getOrCreate(this.options.customerId, this.options.customerAccountTypes),
            this.websiteService.getManyByCustomerId(this.options.customerId),
            this.sessionStorageService.getPartner()
        ]).subscribe(([accounts, websites, partner]) => {
            // The accounts don't come back in the order that the options specify. Sort them.
            accounts = _.sortBy(accounts, (account) => {
                return this.options.customerAccountTypes.indexOf(account.CustomerAccountTypeId);
            });

            // Collect all the promises, so we can resolve them together.
            let compositions: ICompositionRoute[] = accounts.map(x => this.customerAccountRouteFactory.create(x, this.customerAccountService, x.CustomerId, this.options.extraValidationCallbacks, this.options.EditableNotes));

            _.each(compositions, (route) => {
                // Disable if necessary.
                route.options.isEnabled = this.isEnabled;
                // Keep a references to all the resolved models. We want to validate them all before we save.
                route.callback = component => this.components.push(component);
            });

            if (!this.options.excludeBoostSites) {
                for (let website of websites) {
                    compositions.unshift({
                        selector: 'boost-site-admin-sso-button',
                        model: {
                            websiteId: website.WebsiteId,
                            showSiteInfo: true,
                            hideSsoUrl: partner.PartnerId !== Boo.Objects.Enums.PartnerEnum.Boostability,
                        }
                    });
                    this.hasAccountAndSiteId = this.hasAccountAndSiteId || (!!website.WebsiteAccount.AccountId && !!website.SiteId);
                }
            }

            // Chunk these into rows.
            this.grid(new Grid(compositions, this.maxNumberOfColumns));
        });
    }

    confirmSave(saveType: SaveTypes): JQueryPromise<boolean> {
        return System.resolvedPromise(true);
    }

    cancel(): void {
        if (this.canClose) {
            (<any>this).dfd.reject();
        }
    }

    save(saveType?: SaveTypes): JQueryPromise<void> {
        if (!this.isEnabled && !this.showSaveButton) {
            // Prevent a disabled screen from saving.
            return System.emptyPromise();
        }

        this.isLoading = true;

        return $.when(this.validate(SaveTypes.Complete))
            .then((result) => {
                // If we are valid, continue, else reject the promise as invalid.
                if (result.isValid) {
                    // Collect all the customer account objects.
                    let accounts: any[] = _.map(this.components, (c: app.managecustomer.components.interfaces.CustomerAccountViewModel) => {
                        return ko.mapping.toJS(c.customerAccount);
                    });

                    return Utils.wrapDfd(this.customerAccountService.insertUpdateMultiple(accounts));
                } else {
                    return System.autoRejectedPromise('Customer accounts are invalid.');

                }
            })
            .then(() => {
                if (!this.options.supressSaveMessage) {
                    toastr.success('External access has been successfully saved.');
                }
                
                if (this.canClose) {
                    (<any>this).dfd.resolve();
                }
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading = false;
            });
    }

    isValid(saveType: SaveTypes): boolean {
        // This component has no validation.
        return true;
    }

    validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
        // Collect all the validation methods.
        let componentPromises: JQueryPromise<IValidatedResult>[] = _.map(this.components, (c: app.interfaces.IValidate) => {
            return c.validate(saveType === SaveTypes.Complete);
        });

        // Validate components.
        return $.when.apply(this, componentPromises)
            .then((...results: IValidatedResult[]) => {
                return {
                    isValid: results.every(x => x.isValid),
                    errorMessages: results.flatMap(x => x.errorMessages)
                };
            });
    }
}
