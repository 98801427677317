<div *withPermissions="let allow of ['CanManagePartners', 'CanTransferAccounts', 'CanSetHighProfile', 'CanManageLeadSources', 'CanViewCustomerPendingWork', 'CanRepublishPost', 'CanManagePremiumBacklink', 'CanManageMerchantFields', 'CanEditLinkLocationUrls', 'CanManageTaskExport', 'CanManageOrganizationalTeams', 'CanCloseWorkflow', 'CanManageBlacklistDomains', 'CanConfigurePartnerPricingTool', 'CanConfigureQaTasks', 'CanViewNotifications']" class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body py-2">
        <ul navigationHandler class="nav nav-pills">
          <li class="nav-item">
            <a href="/#/manager/manageusers" class="nav-link">
              <i class="fa fa-users"></i>
              Manage Users
            </a>
          </li>
          <li *ngIf="allow().CanManagePartners()" class="nav-item">
            <a href="/#/manager/managepartners" class="nav-link">
              <i class="far fa-building"></i>
              Manage Partners
            </a>
          </li>
          <li *ngIf="isToolsVisible" class="nav-item">
            <a href="/#/manager/registrationphonenumbers" class="nav-link">
              <i class="fa fa-wrench"></i>
              Tools
            </a>
          </li>
        </ul>
      </div>
      <div [slideVisible]="showToolsSubMenu" class="card-footer py-2" style="display:none">
        <ul navigationHandler class="nav nav-pills">
          <li class="nav-item">
            <a href="/#/manager/registrationphonenumbers" class="nav-link">Registration Phone Numbers</a>
          </li>
          <li *ngIf="allow().CanEditLinkLocationUrls()" class="nav-item">
            <a href="/#/manager/linktypeactivation" class="nav-link">Link Type Activation</a>
          </li>
          <li *ngIf="allow().CanRepublishPost()" class="nav-item">
            <a href="/#/manager/republishpost" class="nav-link">Republish Post</a>
          </li>
          <li *ngIf="allow().CanManagePremiumBacklink()" class="nav-item">
            <a href="/#/manager/premiumbacklink" class="nav-link">Premium Backlinks</a>
          </li>
          <li *ngIf="allow().CanManageMerchantFields()" class="nav-item">
            <a href="/#/manager/merchantfields" class="nav-link">Merchant Fields</a>
          </li>
          <li *ngIf="allow().CanManageTaskExport()" class="nav-item">
            <a href="/#/manager/taskimportexport" class="nav-link">Task Import/Export</a>
          </li>
          <li *ngIf="allow().CanManageBlacklistDomains()" class="nav-item">
            <a href="/#/manager/blacklistdomains" class="nav-link">Blacklist Domains</a>
          </li>
          <li *ngIf="allow().CanConfigurePartnerPricingTool()" class="nav-item">
            <a href="/#/manager/configurepartnerpricingtool" class="nav-link">Configure Partner Pricing Tool</a>
          </li>
          <li *ngIf="allow().CanConfigureQaTasks()" class="nav-item">
            <a href="/#/manager/configureqatasks" class="nav-link">Configure QA Tasks</a>
          </li>
          <li *ngIf="allow().CanViewNotifications()" class="nav-item">
            <a href="/#/manager/notifications" class="nav-link">Notifications</a>
          </li>
        </ul>
      </div>
      <div [slideVisible]="showPartnerSubMenu" class="card-footer py-2" style="display:none">
        <ul navigationHandler class="nav nav-pills">
          <li class="nav-item">
            <a href="/#/manager/managepartners" class="nav-link">Manage Partners</a>
          </li>
          <li *ngIf="allow().CanTransferAccounts()" class="nav-item">
            <a href="/#/manager/transferaccounts" class="nav-link">Transfer Accounts</a>
          </li>
          <li *ngIf="allow().CanManageLeadSources()" class="nav-item">
            <a href="/#/manager/leadsources" class="nav-link">Manage Lead Sources</a>
          </li>
        </ul>
      </div>
      <div [slideVisible]="showUserSubMenu" class="card-footer py-2" style="display:none">
        <ul navigationHandler class="nav nav-pills">
          <li class="nav-item">
            <a href="/#/manager/manageusers" class="nav-link">Manage Users</a>
          </li>
          <li class="nav-item">
            <a href="/#/manager/taskstewardships" class="nav-link">Task Stewardships</a>
          </li>
          <li [hidden]="!allow().CanManageOrganizationalTeams()" class="nav-item">
            <a href="/#/manager/organizationalteams" class="nav-link">Organizational Teams</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
