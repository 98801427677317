import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import CustomValidators from '../../../../shared/custom-validators';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { SessionStorageService } from '../../../../services/session-storage.service';
import { finalize } from 'rxjs/operators';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-keywords-url-suggestions',
  templateUrl: './url-suggestions.component.html',
  styleUrls: ['./url-suggestions.component.scss']
})
export class UrlSuggestionsComponent implements OnInit, OnDestroy {
  @Input() isLocalCampaign: boolean = undefined;
  @Input() customerId: number;
  @Input() taskId: number;
  @Input() urlSuggestionAdded: Observable<Boo.Objects.LegacyUrlSuggestion[]>;
  @Input() minimumPages: number;
  @Input() maximumPages: number;
  @Input() totalPageCount: number;
  @Output() totalPageCountChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() minimumKeywordSuggestions: number;
  @Input() totalKeywordCount: number;
  @Output() totalKeywordCountChange: EventEmitter<number> = new EventEmitter<number>();

  newKeywordUrlSuggestion: ValidatedUrlSuggestion;
  newKeywordIsAreaLast: boolean = true;
  newKeywordControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(128), CustomValidators.mustNotBeWhitespace()]);
  newAreaControl: UntypedFormControl = new UntypedFormControl('', [Validators.maxLength(256), CustomValidators.requiredIfValidator(() => this.isLocalCampaign), CustomValidators.mustNotBeWhitespace()]);
  newRankControl: UntypedFormControl = new UntypedFormControl(101, [Validators.required, Validators.min(1), Validators.max(101)]);
  newKeywordTypeControl: UntypedFormControl = new UntypedFormControl('', Validators.required);

  newKeywordFormGroup: UntypedFormGroup = new UntypedFormGroup({
    keyword: this.newKeywordControl,
    area: this.newAreaControl,
    rank: this.newRankControl,
    keywordType: this.newKeywordTypeControl
  },
  {
    validators: [CustomValidators.keywordsCannotBeDuplicated(this.customerCampaignService, () => !this.newKeywordIsAreaLast, this.getUnavailableKeywords.bind(this), 'This keyword already exists on this URL.')]
  })

  sortingOptions = [
    { display: "Keyword", method: (a: ValidatedKeywordSuggestion, b: ValidatedKeywordSuggestion) => a.Keyword.localeCompare(b.Keyword), disableIfNational: false },
    { display: "Area", method: (a: ValidatedKeywordSuggestion, b: ValidatedKeywordSuggestion) => a.Area.localeCompare(b.Area), disableIfNational: true },
    { display: "Recommendation Type", method: (a: ValidatedKeywordSuggestion, b: ValidatedKeywordSuggestion) => this.formGroup.get(a.formControlName).value - this.formGroup.get(b.formControlName).value, disableIfNational: false },
    { display: "Rank", method: (a: ValidatedKeywordSuggestion, b: ValidatedKeywordSuggestion) => a.Rank - b.Rank, disableIfNational: false }
  ]

  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  
  keywordTypes: Boo.Objects.KeywordSuggestionType[];
  validatedUrlSuggestions: ValidatedUrlSuggestion[] = [];
  isLoading: boolean = false;
  private urlSuggestionsAddedSubscription: Subscription;
  private index: number = 0;

  constructor(
    private modalService: NgbModal,
    private sessionStorageService: SessionStorageService,
    private customerCampaignService: CustomerCampaignService) { }

  ngOnInit() {
    this.urlSuggestionsAddedSubscription = this.urlSuggestionAdded.subscribe((urlSuggestions) => this.addKeywordSuggestions(urlSuggestions));
    this.getStaticData();
  }

  ngOnDestroy() {
    this.urlSuggestionsAddedSubscription.unsubscribe();
  }

  getStaticData(): void {
    this.isLoading = true;
    this.sessionStorageService.getStaticData()
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe((staticData: Boo.Objects.LaunchPadStaticData) => {
        const assignableKeywordTypes = [Boo.Objects.Enums.KeywordSuggestionTypes.RecommendedOne, Boo.Objects.Enums.KeywordSuggestionTypes.RecommendedTwo];
        this.keywordTypes = staticData.KeywordSuggestionTypes.filter((type: Boo.Objects.KeywordSuggestionType) => assignableKeywordTypes.indexOf(type.KeywordSuggestionTypeId) > -1);
      }, (err: any) => toastr.error(err));
  }

  createValidatedUrlSuggestion(u: Boo.Objects.LegacyUrlSuggestion): ValidatedUrlSuggestion {
    let urlSuggestion = new ValidatedUrlSuggestion(u);
    urlSuggestion.validatedKeywordSuggestions = this.createValidatedKeywordSuggestions(u.KeywordSuggestions);
    return urlSuggestion;
  }

  createValidatedKeywordSuggestions(KeywordSuggestions: Boo.Objects.LegacyKeywordSuggestion[]): ValidatedKeywordSuggestion[] {
    if (!KeywordSuggestions) {
      return [];
    }

    let validatedKeywordSuggestions = KeywordSuggestions.map((keywordSuggestion) => new ValidatedKeywordSuggestion(keywordSuggestion));
    validatedKeywordSuggestions.forEach((ks) => {
      ks.formControlName = 'keywordSuggestionTypeId' + this.index++;
      ks.KeywordSuggestionTypeId = ks.KeywordSuggestionTypeId || Boo.Objects.Enums.KeywordSuggestionTypes.NeedsSelection;
      this.formGroup.addControl(ks.formControlName, new UntypedFormControl(ks.KeywordSuggestionTypeId, [
        (control: AbstractControl) => control.value === Boo.Objects.Enums.KeywordSuggestionTypes.NeedsSelection ? { 'required': true } : null
      ]));
    });
    return validatedKeywordSuggestions;
  }

  addKeywordSuggestions(urlSuggestions: Boo.Objects.LegacyUrlSuggestion[]) {
    let shouldSort = false;
    urlSuggestions.forEach((urlSuggestion) => {
      let existingUrlSuggestion = this.validatedUrlSuggestions.find((k) => k.Url.toLowerCase() === urlSuggestion.Url.toLowerCase());

      // If there is an existing url suggestion, add the keyword suggestions to it
      if (existingUrlSuggestion && !existingUrlSuggestion.canEdit) {
        toastr.error('You cannot add keywords to a page where keywords have already been approved.');
      } else if (existingUrlSuggestion) {
        existingUrlSuggestion.validatedKeywordSuggestions.splice(0, 0, ...this.createValidatedKeywordSuggestions(urlSuggestion.KeywordSuggestions));
      } else {
        this.validatedUrlSuggestions.push(this.createValidatedUrlSuggestion(urlSuggestion));
        shouldSort = true;
      }
    });
    this.keywordSuggestionsChanged();
    
    if (shouldSort) {
      this.validatedUrlSuggestions.sort((a, b) => Number(b.canEdit) - Number(a.canEdit));
    }
  }

  removeUrlSuggestion(urlSuggestion: ValidatedUrlSuggestion) {
    bootbox.confirm('Are you sure you want to remove this page and keywords? This action cannot be undone.',
      (result: any) => {
        if (result !== true) {
          return;
        }
        urlSuggestion.validatedKeywordSuggestions.forEach((keywordSuggestion) => {
          this.formGroup.removeControl(keywordSuggestion.formControlName);
        });
        this.validatedUrlSuggestions.splice(this.validatedUrlSuggestions.indexOf(urlSuggestion), 1);
        this.keywordSuggestionsChanged();
      });
  }

  removeKeywordSuggestion(keyword: ValidatedKeywordSuggestion, urlSuggestion: ValidatedUrlSuggestion) {
    bootbox.confirm('Are you sure you want to remove this keyword? This action cannot be undone.',
      (result: any) => {
        if (result !== true) {
          return;
        }
        urlSuggestion.validatedKeywordSuggestions.splice(urlSuggestion.validatedKeywordSuggestions.indexOf(keyword), 1);
        this.formGroup.removeControl(keyword.formControlName);
        this.keywordSuggestionsChanged();
      });
  }

  updateIsExisting(isExistingPage: boolean, urlSuggestion: ValidatedUrlSuggestion) {
    urlSuggestion.IsExisting = isExistingPage;
  }

  isValid(saveType: SaveTypes): boolean {
    if (saveType === SaveTypes.Update) {
      return true;
    }

    Object.keys(this.formGroup.controls).forEach((key) => {
      this.formGroup.controls[key].markAsDirty();
      this.formGroup.controls[key].updateValueAndValidity();
    });

    this.prepareUrlSuggestionsForSave(this.validatedUrlSuggestions, false);

    let urlHasMinimumKeywords = true;

    if (this.validatedUrlSuggestions.some((u) => u.IsExisting == null)) {
      toastr.error("Must indicate whether each page is existing or not");
      return false;
    }

    if (this.totalPageCount < this.minimumPages) {
      toastr.error("Must add " + (this.minimumPages - this.totalPageCount) + " more page(s)");
      return false;
    }

    if (this.totalPageCount > this.maximumPages) {
      toastr.error("Cannot add more than " + this.maximumPages + " pages");
      return false;
    }

    if (this.totalKeywordCount < this.minimumKeywordSuggestions) {
      toastr.error("Must add " + (this.minimumKeywordSuggestions - this.totalKeywordCount) + " more keyword(s)");
      return false;
    }

    this.validatedUrlSuggestions.forEach((u) => {
      if (!u.canEdit) {
        return;
      }

      if (!u.KeywordSuggestions.some((k) => k.KeywordSuggestionTypeId == Boo.Objects.Enums.KeywordSuggestionTypes.RecommendedOne)) {
        toastr.error("Must add at least one 'Recommended 1' keyword for " + u.Url);
        urlHasMinimumKeywords = false;
      }

      if (!u.KeywordSuggestions.some((k) => k.KeywordSuggestionTypeId == Boo.Objects.Enums.KeywordSuggestionTypes.RecommendedTwo)) {
        toastr.error("Must add at least one 'Recommended 2' keyword for " + u.Url);
        urlHasMinimumKeywords = false;
      }
    });

    return this.formGroup.valid && urlHasMinimumKeywords;
  }

  changeEditMode(urlSuggestion: ValidatedUrlSuggestion) {
    urlSuggestion.editMode = !urlSuggestion.editMode;
  }

  get(saveType: SaveTypes): Boo.Objects.LegacyUrlSuggestion[] {
    this.prepareUrlSuggestionsForSave(this.validatedUrlSuggestions, saveType === SaveTypes.Complete);
    if (!this.validatedUrlSuggestions || this.validatedUrlSuggestions.length === 0) {
      return [];
    }
    return this.validatedUrlSuggestions;
  }

  addKeyword(modal: NgbModalRef = null): any {
    Object.keys(this.newKeywordFormGroup.controls).forEach((key) => {
      this.newKeywordFormGroup.controls[key].markAsDirty();
      this.newKeywordFormGroup.controls[key].updateValueAndValidity();
    });

    if (this.newKeywordFormGroup.valid) {
      let newKeywordSuggestion = this.createValidatedKeywordSuggestions([{
        Area: this.customerCampaignService.normalizeArea(this.newAreaControl.value),
        CreatedByTaskId: this.taskId,
        CustomerId: this.customerId,
        IsAreaFirst: this.newKeywordIsAreaLast ? false : true,
        IsCustomerChoice: false,
        Keyword: this.customerCampaignService.normalizeKeyword(this.newKeywordControl.value),
        KeywordSuggestionTypeId: this.newKeywordTypeControl.value,
        KeywordSuggestionStatusId: Boo.Objects.Enums.KeywordSuggestionStatus.Proposed,
        Rank: this.newRankControl.value,
      } as Boo.Objects.LegacyKeywordSuggestion])[0];

      this.newKeywordUrlSuggestion.validatedKeywordSuggestions.splice(0, 0, newKeywordSuggestion);
      this.keywordSuggestionsChanged();
      modal.close({} as Boo.Objects.LegacyUrlSuggestion);
    } else {
      toastr.error("Please correct the errors on the page.");
    }
  }

  openModal(template: any, urlSuggestion: ValidatedUrlSuggestion): void {
    this.newKeywordUrlSuggestion = urlSuggestion;
    this.modalService.open(template).result.finally(() => {
      this.newAreaControl.setValue('');
      this.newKeywordIsAreaLast = true;
      this.newKeywordControl.setValue('');
      this.newKeywordTypeControl.setValue(null);
      this.newRankControl.setValue(101);
      Object.keys(this.newKeywordFormGroup.controls).forEach((key) => {
        this.newKeywordFormGroup.controls[key].markAsPristine();
        this.newKeywordFormGroup.controls[key].markAsUntouched();
      })
    });
  }

  getUnavailableKeywords(): string[] {
    return this.newKeywordUrlSuggestion ? this.newKeywordUrlSuggestion.validatedKeywordSuggestions.map((x) => this.customerCampaignService.getKeywordPhrase(x.Keyword, x.Area, x.IsAreaFirst)) : [];
  }

  updateKeywordIsAreaLast(newKeywordIsAreaLast: boolean): void {
    this.newKeywordIsAreaLast = newKeywordIsAreaLast;
  }

  dropListDropped(event: CdkDragDrop<ValidatedKeywordSuggestion[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      event.item.data.KeywordSuggestionId = 0;
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  enterPredicate(drag: CdkDrag<ValidatedKeywordSuggestion>, drop: CdkDropList<ValidatedKeywordSuggestion[]>): boolean {
    return !drop.data.some(keywordSuggestion => keywordSuggestion.isApproved);
  }

  sort(comparisonFunction: (a: ValidatedKeywordSuggestion, b: ValidatedKeywordSuggestion) => number, suggestions: ValidatedKeywordSuggestion[]): void {
    suggestions.sort((a, b) => comparisonFunction(a, b) + (a.KeywordSuggestionStatusId - b.KeywordSuggestionStatusId) * 500);
  }

  private prepareUrlSuggestionsForSave(validatedUrlSuggestions: ValidatedUrlSuggestion[], shouldUpdateRejectedStatus: boolean): void {
    validatedUrlSuggestions.forEach((urlSuggestion: ValidatedUrlSuggestion) => {
      urlSuggestion.CustomerId = this.customerId;
      urlSuggestion.KeywordSuggestions = urlSuggestion.validatedKeywordSuggestions.map((keywordSuggestion) => {
        keywordSuggestion.KeywordSuggestionStatusId = shouldUpdateRejectedStatus && urlSuggestion.canEdit && keywordSuggestion.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Rejected ? Boo.Objects.Enums.KeywordSuggestionStatus.Historic : keywordSuggestion.KeywordSuggestionStatusId;
        keywordSuggestion.UrlSuggestionId = urlSuggestion.UrlSuggestionId;
        keywordSuggestion.KeywordSuggestionTypeId = this.formGroup.get(keywordSuggestion.formControlName).value;
        return keywordSuggestion;
      });
      return urlSuggestion;
    })
  }

  private keywordSuggestionsChanged() {
    // We do not want to count rejected keywords in the total count if they are part of a redo. We should count rejected if they are pard of an approved url
    let keywordSuggestionsToCount = this.validatedUrlSuggestions.reduce((count, urlSuggestion) => count + (!urlSuggestion.canEdit ? urlSuggestion.validatedKeywordSuggestions.length : urlSuggestion.validatedKeywordSuggestions.filter((k) => k.KeywordSuggestionStatusId !== Boo.Objects.Enums.KeywordSuggestionStatus.Rejected).length), 0);
    // MURDER 8/17/2023 There is no pre-release data about rejected keyword suggestions so we need to double count urlSuggestions that contain only approved keywords
    let missingRejectedCount = this.validatedUrlSuggestions.reduce((count, urlSuggestion) => count + (urlSuggestion.validatedKeywordSuggestions.every((k) => k.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Approved) ? urlSuggestion.validatedKeywordSuggestions.length : 0), 0);
    keywordSuggestionsToCount += missingRejectedCount;
    this.totalKeywordCountChange.emit(keywordSuggestionsToCount);
    this.totalPageCountChange.emit(this.validatedUrlSuggestions.length);
  }
}

class ValidatedUrlSuggestion implements Boo.Objects.LegacyUrlSuggestion {
  constructor(urlSuggestion: Boo.Objects.LegacyUrlSuggestion) {
    Object.assign(this, urlSuggestion);
  }
  CustomerId: number;
  InsertedDate: Date;
  IsExisting: boolean;
  KeywordSuggestions: Boo.Objects.LegacyKeywordSuggestion[];
  UpdatedDate: Date;
  Url: string;
  UrlSuggestionId: number;
  validatedKeywordSuggestions: ValidatedKeywordSuggestion[];
  editMode: boolean = false;
  RejectionNote: string;
  get canEdit(): boolean { return !this.KeywordSuggestions || !this.KeywordSuggestions.some((k) => k.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Approved); }
}

class ValidatedKeywordSuggestion implements Boo.Objects.LegacyKeywordSuggestion {
  constructor(keywordSuggestion: Boo.Objects.LegacyKeywordSuggestion) {
    Object.assign(this, keywordSuggestion);
  }
  Area: string;
  CreatedByTaskId: number;
  CustomerId: number;
  InsertedDate: Date;
  IsAreaFirst: boolean;
  Keyword: string;
  KeywordSuggestionId: number;
  KeywordSuggestionStatusId: number;
  KeywordSuggestionType: Boo.Objects.KeywordSuggestionType;
  KeywordSuggestionTypeId: number;
  Page: number;
  Rank: number;
  SearchVolume: number;
  UpdatedDate: Date;
  UrlSuggestionId: number;
  IsCustomerChoice: boolean;
  formControlName: string;
  get keywordAndArea(): string {
    if (!this.Area) {
      return this.Keyword;
    } else {
      return this.IsAreaFirst ? `${this.Area} ${this.Keyword}` : `${this.Keyword} ${this.Area}`;
    }
  }
  get isApproved(): boolean { return this.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Approved; }
  get isRejected(): boolean { return this.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Rejected; }
  get isProposed(): boolean { return this.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Proposed; }
}
