<div>
	<ng-container *ngIf="isEnabled()">
		<app-components-togglepanel [(isOpen)]="isOpen" title="Descendant Tickets" [titleBadge]="descendantTicketsLength()" toggleBody="true">
			<table class="table table-striped table-bordered">
				<thead>
					<tr>
						<th style="width:400px">Ticket Type</th>
						<th style="width:30px;">
							<span [hidden]="!canEdit">Create Ticket</span>
							<span [hidden]="canEdit">Ticket Created</span>
						</th>
						<th>First Note</th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngFor="let $data of descendantTickets">
						<tr>
							<td>{{$data.DisplayText()}}</td>
							<td>
								<div [hidden]="!canEdit">
									<shared-components-customchoicelegacy [Settings]="{ value: $data.CreateDescendantTicket, enable: canEdit }"></shared-components-customchoicelegacy>
								</div>
								<span [hidden]="canEdit">{{$data.CreateDescendantTicket() ? 'Yes' : 'No'}}</span>
							</td>
							<td>
								<textarea [dataValidation]="{ placement: 'top', value: $data.InitialNote }" [ngModel]="$data.InitialNote()" (ngModelChange)="$data.InitialNote($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [hidden]="!($data.CreateDescendantTicket() && canEdit)" class="form-control" style="height: 70px; width: 400px;"></textarea>
								<span [hidden]="canEdit">{{$data.InitialNote()}}</span>
							</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</app-components-togglepanel>
	</ng-container>
</div>