<ng-container *ngIf="updatingPage; else notUpdatingPage">
  <div class="row justify-content-start ms-4">
    <div class="col-6">
      <input #pageInput type="text" class="form-control" [formControl]="pageControl" [formValidation]="pageControl" (keyup.enter)="updatePage()" />
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary btn-sm" (click)="updatePage()">Update</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-warning btn-sm" (click)="toggleUpdatingPage()">Cancel</button>
    </div>
  </div>
</ng-container>

<ng-template #notUpdatingPage>
  <span class="pull-right mb-0 ms-4">
    <i *ngIf="readOnlyReasons" class="fas fa-lock text-danger" triggers="mouseenter:mouseleave" [ngbPopover]="readOnlyReasons"></i>
    <a class="fas fa-external-link-square-alt" [attr.href]="websiteUrl.Url" target="_blank" rel="noreferrer"></a>
    <label class="fw-boldtext-break">{{websiteUrl.Url}}</label>
    <button type="button" class="btn btn-primary btn-sm ms-2" (click)="toggleUpdatingPage()"
      [disabled]="websiteUrl?.IsReadOnly || restrictions.includes(customerCampaignRestrictions_ReadOnly) || restrictions.includes(customerCampaignRestrictions_DisablePageUpdate)"><i class="fa fa-edit fa-no-margin"></i></button>
  </span>
</ng-template>