import { Component } from '@angular/core';
import { ApplicationService } from '../../../services/application.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-stats-admin',
	templateUrl: './Admin.component.html'
})
export class AdminComponent implements IActivatable {
    public statusSummary: KnockoutObservable<Boo.Objects.ApplicationOverview> = ko.observable(null);
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);
  
    constructor(private applicationService: ApplicationService) { }

    public loadData(): JQueryPromise<any> {
        return Utils.wrapDfd(this.applicationService.getApplicationOverview(moment().startOf('day'), moment().add(1, 'day')))
            .then((data: Boo.Objects.ApplicationOverview) => {
                this.statusSummary(data);
            })
            .fail((displayMessage: string): void => {
                toastr.error(displayMessage);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    public activate(params: any): JQueryPromise<any> {
        return this.loadData();
    }
}
