<div [loadingOverlay]="loading" class="card">
  <div class="card-header">
    Task Stewardships
    <button class="btn btn-primary float-end" (click)="addStewardship()"><i class="fa fa-plus"></i>Add Stewardship</button>
  </div>
  <div class="card-body">
    <ng-container *ngIf="formIsReady">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Search <app-components-helptext [key]="'TaskStewardshipSearch'"></app-components-helptext></label>
            <input type="text" class="form-control" [formControl]="filterSearchCtrl" placeholder="" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Task Type </label>
            <ng-select [items]="taskTypes" [clearable]="true" placeholder="Choose..." bindLabel="Name"
              bindValue="TaskTypeId" [formControl]="filterTaskTypeCtrl" [virtualScroll]="true"></ng-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Language</label>
            <ng-select [items]="languages" [clearable]="true" placeholder="Choose..." bindLabel="Name"
              bindValue="LanguageId" [formControl]="filterLanguageCtrl" [virtualScroll]="true"></ng-select>
          </div>
        </div>
      </div>
      <hr />
      <app-table [items]="filteredTaskStewardshipResponsibilities">
        <ng-template #tableHeader >
          <tr>
            <th sort field="TaskStewardship.Name">Task Stewardship</th>
            <th sort field="TaskStewardship.BusinessEntityName">Business Entity</th>
            <th sort field="TaskStewardship.StewardName">Steward</th>
            <th sort field="TaskTypeName">Task Type</th>
            <th sort field="LanguageName">Language</th>
          </tr>
        </ng-template>
        <ng-template #tableBody let-stewardship>
          <tr>
            <td>
              <button class="btn btn-primary" (click)="edit(stewardship);"><i
                  class="fas fa-external-link-square-alt"></i>{{ stewardship.TaskStewardship.Name }}</button>
            </td>
            <td>{{ stewardship.TaskStewardship.BusinessEntityName }}</td>
            <td>{{ stewardship.TaskStewardship.StewardName }}</td>
            <td>{{ stewardship.TaskTypeName }}</td>
            <td>{{ stewardship.LanguageName }}</td>
          </tr>
        </ng-template>
      </app-table>
    </ng-container>
  </div>
</div>
