import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';
import { KeywordSuggestion, KeywordSuggestionSources, PageSuggestion } from '../../../../shared/models/keywords/keywords';

@Component({
  selector: 'app-components-shared-keyword-research-add-keyword',
  templateUrl: './add-keyword.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class AddKeywordComponent implements OnInit {
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestion: PageSuggestion;
  @Input() pageSuggestions: PageSuggestion[];

  @ViewChild('keywordInput') keywordInput: ElementRef;
  @ViewChild('areaInput') areaInput: ElementRef;

  keywordControl: UntypedFormControl;
  areaControl: UntypedFormControl;
  formGroup: UntypedFormGroup;
  addingKeyword: boolean = false;
  isAreaFirst: boolean = false;

  unknownRank: number = 101;
  keywordSuggestionSources_OpenAI = KeywordSuggestionSources.OpenAI;

  constructor(private customerCampaignService: CustomerCampaignService) { }

  ngOnInit(): void {
    this.setupForm();
  }

  toggleAddingKeyword(): void {
    this.addingKeyword = !this.addingKeyword;

    if (this.addingKeyword) {
      this.isAreaFirst = false;
      this.keywordControl.reset('');
      this.areaControl.reset('');
      this.focus();
    }
  }

  setupForm(): void {
    this.keywordControl = new UntypedFormControl('', [
      Validators.required,
      CustomValidators.mustNotBeWhitespace(),
      Validators.maxLength(128),
    ]);

    this.keywordControl = new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(128),
      CustomValidators.mustNotBeWhitespace()
    ]);
    this.areaControl = new UntypedFormControl('', [
      CustomValidators.requiredIfValidator(() => !this.customer.IsCampaignNational),
      Validators.maxLength(256),
      CustomValidators.mustNotBeWhitespace()
    ]);
    this.formGroup = new UntypedFormGroup({ keyword: this.keywordControl, area: this.areaControl }, {
      validators: [CustomValidators.keywordsCannotBeDuplicated(this.customerCampaignService, () => this.isAreaFirst, this.getUnavailableKeywords.bind(this), 'This keyword already exists and cannot be duplicated.')]
    });
  }

  getUnavailableKeywords(): string[] {
    return this.pageSuggestions
      ? this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
          .flatMap(x => x.KeywordSuggestions)
          .filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
          .map(x => x.KeywordPhrase.toLowerCase().trim())
      : [];
  }

  keywordSort = (a: KeywordSuggestion, b: KeywordSuggestion): number => {
    const aBelongsToUrl = a.SourcePageSuggestionId === this.pageSuggestion.PageSuggestionId;
    const bBelongsToUrl = b.SourcePageSuggestionId === this.pageSuggestion.PageSuggestionId;

    if (aBelongsToUrl && bBelongsToUrl) {
      return a.Priority - b.Priority;
    }
    if (aBelongsToUrl && !bBelongsToUrl) {
      return -1;
    }
    if (!aBelongsToUrl && bBelongsToUrl) {
      return 1;
    }
    if (!aBelongsToUrl && !bBelongsToUrl) {
      return b.Volume - a.Volume;
    }
  }

  get availableKeywordSuggestions(): KeywordSuggestion[] {
    return this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
      .flatMap(x => x.KeywordSuggestions)
      .filter(x => !x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
      .concat(
        this.pageSuggestions.filter(x => !x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
          .filter(x => x.IsCampaignNational === this.customer.IsCampaignNational)
          .flatMap(x => x.KeywordSuggestions)
      );
  }

  addKeyword(): void {
    this.keywordControl.markAsDirty();
    this.keywordControl.updateValueAndValidity();

    if (!this.customer.IsCampaignNational) {
      this.areaControl.markAsDirty();
      this.areaControl.updateValueAndValidity();
    } else {
      this.areaControl.reset('');
      this.isAreaFirst = false;
    }

    if (!this.formGroup.valid) {
      toastr.error("Please correct errors before adding a keyword.");
      return;
    }

    let phrase = this.customerCampaignService.getKeywordPhrase(this.keywordControl.value, this.areaControl.value, this.isAreaFirst);

    let existingKeywordSuggestion = this.pageSuggestions.filter(x => x.IsCampaignNational === this.customer.IsCampaignNational)
      .flatMap(x => x.KeywordSuggestions)
      .find(x => x.KeywordPhrase.toLowerCase().trim() === phrase.toLowerCase() && x.IsCampaignNational === this.customer.IsCampaignNational);
    if (existingKeywordSuggestion) {
      let oldPage = this.pageSuggestions.find(x => x.PageSuggestionId === existingKeywordSuggestion.PageSuggestionId);
      oldPage.KeywordSuggestions.splice(oldPage.KeywordSuggestions.indexOf(existingKeywordSuggestion), 1);

      existingKeywordSuggestion.PageSuggestionId = this.pageSuggestion.PageSuggestionId;
      existingKeywordSuggestion.IsSelected = true;
      this.pageSuggestion.KeywordSuggestions.push(existingKeywordSuggestion);
    } else {
      let keywordSuggestion = {
        KeywordSuggestionId: 0,
        PageSuggestionId: this.pageSuggestion.PageSuggestionId,
        SourcePageSuggestionId: null,
        SourceId: KeywordSuggestionSources.User,
        Priority: null,
        IsCampaignNational: this.customer.IsCampaignNational,
        KeywordPhrase: phrase,
        Keyword: this.customerCampaignService.normalizeKeyword(this.keywordControl.value),
        Area: this.areaControl.value ? this.customerCampaignService.normalizeArea(this.areaControl.value) : null,
        IsAreaFirst: this.isAreaFirst,
        IsSelected: true,
        Volume: null,
        Competition: null,
        Rank: null,
        InsertedDate: null
      } as KeywordSuggestion;

      this.pageSuggestion.KeywordSuggestions.push(keywordSuggestion);
    }

    this.toggleAddingKeyword();
  }

  addKeywordSuggestion(keywordSuggestion: KeywordSuggestion){
    if (keywordSuggestion.PageSuggestionId === this.pageSuggestion.PageSuggestionId) {
      keywordSuggestion.IsSelected = true;
    } else {
      let oldPage = this.pageSuggestions.find(x => x.PageSuggestionId === keywordSuggestion.PageSuggestionId);
      oldPage.KeywordSuggestions.splice(oldPage.KeywordSuggestions.indexOf(keywordSuggestion), 1);

      keywordSuggestion.PageSuggestionId = this.pageSuggestion.PageSuggestionId;
      keywordSuggestion.IsSelected = true;
      this.pageSuggestion.KeywordSuggestions.push(keywordSuggestion);
    }
  }

  private focus() {
    setTimeout(() => {
      this.keywordInput.nativeElement.focus();
    }, 0);
  }
}