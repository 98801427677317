<div class="card">
  <div class="card-header">
    Customer Campaign
  </div>
  <div class="card-body">
    <app-components-shared-customer-campaign
      [customer]="customer"
      (refreshCustomer)="tryRefreshCustomer()">
    </app-components-shared-customer-campaign>
  </div>
</div>
