import { Subscription, interval } from 'rxjs';

export default class Timer {
  elapsedSeconds: number = 0;
  isPaused: boolean = true;
  private currentInterval: Subscription;

  start() {
    if (!this.isPaused) {
      return;
    }

    this.isPaused = false;
    this.currentInterval = interval(1000)
      .subscribe(() => {
        this.elapsedSeconds++;
      });
  }

  pause() {
    if (this.isPaused) {
      return;
    }

    this.isPaused = true;
    this.currentInterval.unsubscribe();
    this.currentInterval = null;
  }

  toggle() {
    this.isPaused ? this.start() : this.pause();
  }
}