import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AdminComponent } from './Admin/Admin.component';
import { CustomerServiceComponent  } from './CustomerService/CustomerService.component';
import { SalesComponent } from './Sales/Sales.component';
import { SpecialistComponent } from './Specialist/Specialist.component';
import { UserTicketSummaryComponent } from './UserTicketSummary/UserTicketSummary.component';


@NgModule({
  declarations: [
    AdminComponent,
    CustomerServiceComponent,
    SalesComponent,
    SpecialistComponent,
    UserTicketSummaryComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class StatsModule { }
