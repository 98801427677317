<div class="card card-body">
	<div style="min-height: 500px;">
		<div class="card">
			<div class="card-header">
				<h4>{{title}}</h4>
			</div>
			<div id="ManageReporting" class="card-collapse">
				<div class="card-body">
                    <app-tab-panel [displayMode]="tabPanelDisplayMode.horizontal">
                        <app-dynamic-tab [title]="'Pro Tips'">
                            <app-components-manager-protips *tabContent
                                [partnerId]="partner.PartnerId"
                                [partnerName]="partner.PartnerName">
                            </app-components-manager-protips>
                        </app-dynamic-tab>
                        <app-dynamic-tab [title]="'Reporting Customizations'">
                            <app-components-manager-reportingcustomizations *tabContent
                                [partnerId]="partner.PartnerId">
                            </app-components-manager-reportingcustomizations>
                        </app-dynamic-tab>
                    </app-tab-panel>
				</div>
			</div>
		</div>
	</div>
	<div class="row text-end">
		<div class="col-md-12 text-end">
			<button (click)="close()" class="btn btn-danger">
				<i class="fa fa-times"></i>
				Close
			</button>
		</div>
	</div>
</div>
