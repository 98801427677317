<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-md-2">Blacklist Domains</div>
      <div class="col-md-4 offset-md-6">
        <div class="input-group">
          <input [(ngModel)]="domainToAdd" type="text" class="form-control" placeholder="example.com" />
          <button class="btn btn-success float-end" (click)="addDomain()">
            <i class="fa fa-plus"></i>
            Add Blacklist Domain
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div [loadingOverlay]="isLoading">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="input-group">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
              <input type="text" class="form-control" [(ngModel)]="filterString" (keyup)="filter()" placeholder="Filter" />
            </div>
            <app-table [items]="filteredBlacklistDomains" [pageSize]="25">
              <ng-template #tableHeader>
                <tr>
                  <th sort field="Domain">Domain</th>
                  <th sort desc field="InsertedDate">Inserted Date</th>
                  <th>Action</th>
                </tr>
              </ng-template>
              <ng-template #tableBody let-domain>
                <tr>
                  <td>{{ domain.Domain }}</td>
                  <td>{{ domain.InsertedDate | dateText: 'M/DD/YYYY h:mm:ss a' }}</td>
                  <td>
                    <button (click)="delete(domain.BlacklistDomainId)" class="btn btn-danger">
                      <i class="fa fa-save"></i>
                      Delete
                    </button>
                  </td>
                </tr>
              </ng-template>
            </app-table>
          </div>
        </div>
      </div>
    </div>
  </div>