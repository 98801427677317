<div [loadingOverlay]="isLoading()" *withPermissions="let allow of ['CanTransferAccounts']" class="main">
  <div class="card">
    <div class="card-header">
      Transfer Accounts
    </div>
    <div class="card-body">
      <ng-container *ngIf="allow().CanTransferAccounts()">
        <div class="alert alert-danger">
          <button type="button" class="btn-close" title="Close" data-dismiss="alert"></button>
          <h4>Warning!</h4>
          <p>Please exercise extreme caution while using this tool.</p>
        </div>
        <div class="row">
          <div class="col-md-1">
            <div class="form-group">
              <label>Customer ID</label>
              <input [dataValidation]="{ placement: 'top', value: customerId }" [ngModel]="customerId()"
                (ngModelChange)="customerId($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReferredBy()"
                class="form-control" type="number" name="customerId" title="" data-original-title="" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Referred By</label>
              <input [dataValidation]="{ placement: 'top', value: referredBy }" [ngModel]="referredBy()"
                (ngModelChange)="referredBy($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isCustomerId()"
                [readonly]="isCustomerId()" class="form-control" type="text" name="referredBy" title=""
                data-original-title="" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>From Partner</label>
              <select [dataValidation]="{ placement: 'top', value: selectedFromPartner }"
                [ngModel]="selectedFromPartner()" (ngModelChange)="selectedFromPartner($event)"
                [ngModelOptions]="{ standalone: true }" [disabled]="isCustomerId()" class="form-select">
                <option [ngValue]="undefined">Choose...</option>
                <option *ngFor="let option of partners()" [ngValue]="option">{{ option.Name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>To Partner</label>
              <select [dataValidation]="{ placement: 'top', value: selectedToPartner }" [ngModel]="selectedToPartner()"
                (ngModelChange)="selectedToPartner($event)" [ngModelOptions]="{ standalone: true }" class="form-select">
                <option [ngValue]="undefined">Choose...</option>
                <option *ngFor="let option of partners()" [ngValue]="option">{{ option.Name }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">&nbsp;</label>
              <button [disabled]="isLoading()" (click)="findCustomers()" class="btn btn-primary">
                <i class="fa fa-search"></i>
                Search
              </button>
            </div>
          </div>
        </div>
        <hr />
        <app-table [items]="customers()" [pageSize]="100" [exportFilename]="'transfer-customers'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="CustomerId">Customer ID</th>
              <th export sort field="Name">Name</th>
              <th export sort field="Url">URL</th>
              <th>Edit Partner</th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-customer>
            <tr [class.lightSpeedOut]="customer.isDeleting()" class="animated">
              <td>{{customer.CustomerId}}</td>
              <td>{{customer.Name}}</td>
              <td>{{customer.Url}}</td>
              <td>
                <button (click)="transfer(customer)" class="btn btn-success">Transfer</button>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </ng-container>
    </div>
    <div class="card-body">
      <ng-container *ngIf="!allow().CanTransferAccounts()">
        <div class="alert alert-danger">
          <button type="button" class="btn-close" title="Close" data-dismiss="alert"></button>
          <h4>Access Denied</h4>
          <p>You do not have permission to transfer accounts.</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>