import { Component } from '@angular/core';
import System from 'framework/System';

@Component({
  selector: 'app-components-specialist-actioncomponents-classifiedbusinesslistings',
  templateUrl: './ClassifiedBusinessListings.component.html'
})
export class ClassifiedBusinessListingsComponent implements IActionWorkspace, IActivatable {
    actionViewModel: KnockoutObservable<IActionWorkspace>;
    currentAction: KnockoutObservable<Boo.Objects.Action>;
    customer: Boo.Objects.Customer;
    files: Boo.Objects.File[] = [];
    isLoading: KnockoutObservable<boolean>;
    localProfile: Boo.Objects.LocalProfiles.LocalProfile;
    targetKeywords: string[];
    taskActionUrl: KnockoutObservable<string>;
    noteCount: KnockoutComputed<number>;
    notes: KnockoutObservableArray<Boo.Objects.TaskNote>;

    private pageValidation: KnockoutObservable<any>;

    activate(params: IActionWorkspaceActivationParams): JQueryPromise<void> {

        params.controlViewModel(this);
        this.actionViewModel = ko.observable(null);
        this.currentAction = params.currentAction;

        this.isLoading = ko.observable(false);
        this.customer = this.currentAction().Customer;
        this.localProfile = this.currentAction().LocalProfile;
        this.taskActionUrl = ko.observable(this.currentAction().Task.TaskActionUrl).extend({
            required: { message: 'Task Action Url is required' }
        });
        this.pageValidation = ko.validatedObservable([this.taskActionUrl]);
        this.targetKeywords = _.map(this.currentAction().TaskDetails.WebsiteUrl.Keywords, (websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword) => {
            return websiteUrlKeyword.Keyword.Name;
        });

        this.files = this.currentAction().TaskDetails.Files;

        return System.emptyPromise();
    }

    isStepReadyToComplete(): boolean {
        this.currentAction().Task.TaskActionUrl = this.taskActionUrl();
        this.pageValidation.errors.showAllMessages();
        return this.pageValidation.isValid();
    }

    isStepReadyToDelete(): boolean {
        return true;
    }

    isStepReadyToReject(): boolean {
        return false;
    }

    isStepReadyToSave(): boolean {
        return false;
    }

    openTaskActionUrl(): void {
        window.open(this.currentAction().Task.TaskActionUrl, '_blank');
    }
}
