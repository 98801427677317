import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationalTeamService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/organizationalTeams`;

  constructor(private http: HttpClient) { }

  getAllTeams(): Observable<Boo.OrganizationalTeams.Models.OrganizationalTeam[]> {
    return this.http.get<Boo.OrganizationalTeams.Models.OrganizationalTeam[]>(`${this.baseUrl}/getAllTeams`);
  }

  getTeam(organizationalTeamId: number): Observable<Boo.OrganizationalTeams.Models.OrganizationalTeam> {
    const params = new HttpParams().set('organizationalTeamId', organizationalTeamId);
    return this.http.get<Boo.OrganizationalTeams.Models.OrganizationalTeam>(`${this.baseUrl}/getTeam`, {params: params});
  }

  saveTeam(organizationalTeam: Boo.OrganizationalTeams.Models.OrganizationalTeam): Observable<Boo.OrganizationalTeams.Models.OrganizationalTeam> {
    return this.http.post<Boo.OrganizationalTeams.Models.OrganizationalTeam>(`${this.baseUrl}/saveTeam `, organizationalTeam);
  }

  getActiveTeams(): Observable<Boo.OrganizationalTeams.Models.OrganizationalTeam[]> {
    return this.http.get<Boo.OrganizationalTeams.Models.OrganizationalTeam[]>(`${this.baseUrl}/getActiveTeams`);
  }

  getAssignment(userId: number): Observable<Boo.OrganizationalTeams.Models.OrganizationalTeamAssignment> {
    const params = new HttpParams().set('userId', userId);
    return this.http.get<Boo.OrganizationalTeams.Models.OrganizationalTeamAssignment>(`${this.baseUrl}/getAssignment`, {params: params});
  }
}
