<div [loadingOverlay]="isLoading" class="container">
	<div>
		<ng-container *ngIf="wordpressWebsiteReportSummary">
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label>Preview URL</label>
						<div class="input-group">
							<input [(ngModel)]="wordpressWebsiteReportSummary.PreviewUrl" class="form-control" type="text" #previewUrl="ngModel"/>
								<a [attr.href]="wordpressWebsiteReportSummary.PreviewUrl" [class.disabled]="!wordpressWebsiteReportSummary.PreviewUrl" class="btn btn-primary" target="_blank">
									<i class="fas fa-external-link-square-alt"></i>
									Preview
								</a>
								<ng-container *ngIf="previewUrl.dirty">
									<button class="btn btn-danger" (click)="savePreviewUrl()">Save</button>
								</ng-container>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="card">
						<div class="card-header">
							Website Maintenance
						</div>
						<ul class="list-group">
							<li class="list-group-item">
								<strong>Hours Available</strong>
								<span class="float-end">{{window.launchpad.utils.timeFormatFromDuration(wordpressWebsiteReportSummary.MaintenanceMinutesAvailable * (1000 * 60), 'hh:mm')}}</span>
							</li>
							<li class="list-group-item">
								<strong>Hours In Progress this Quarter</strong>
								<span class="float-end">{{window.launchpad.utils.timeFormatFromDuration(wordpressWebsiteReportSummary.MaintenanceMinutesReservedForQuarter * (1000 * 60), 'hh:mm')}}</span>
							</li>
							<li class="list-group-item">
								<strong>Hours Completed this Quarter</strong>
								<span class="float-end">{{window.launchpad.utils.timeFormatFromDuration(wordpressWebsiteReportSummary.MaintenanceMinutesCompletedForQuarter * (1000 * 60), 'hh:mm')}}</span>
							</li>
							<li class="list-group-item">
								<strong>Hours Completed All Time</strong>
								<span class="float-end">{{window.launchpad.utils.timeFormatFromDuration(wordpressWebsiteReportSummary.MaintenanceMinutesCompletedToDate * (1000 * 60), 'hh:mm')}}</span>
							</li>
							<li class="list-group-item">
								<strong>Maintenance Period End Date</strong>
								<span class="float-end">{{wordpressWebsiteReportSummary.MaintenancePeriodEndDate | dateText }}</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-header">
					Ticket Summary
				</div>
				<div class="card-body">
					<table class="table table-striped table-bordered table-sm">
						<thead>
							<tr>
								<td>Ticket/Status</td>
								<td>Start Date</td>
								<td>Due Date</td>
								<td>Completed Date</td>
								<td>SLA (bus. days)</td>
								<td>Biz Days Elapsed</td>
								<td>Over/Under</td>
							</tr>
						</thead>
						<tbody>
							<ng-container *ngFor="let $data of wordpressWebsiteReportSummary.WordpressWebsiteTicketSummaries">
								<ng-container *ngTemplateOutlet="reportRow;context:{$context:$data}"></ng-container>
							</ng-container>
						</tbody>
					</table>
				</div>
			</div>
		</ng-container>
	</div>
	<ng-template #reportRow let-$context="$context">
		<tr [class]="window.parseInt($context.NumberOfBusinessDaysOverOrUnder) < 0 ? 'danger' : 'success'">
			<td>{{$context.TicketTypeName}}</td>
			<td>{{$context.StartDate | dateText }}</td>
			<td>{{$context.DueDate | dateText }}</td>
			<td>{{$context.CompletedDate | dateText }}</td>
			<td>{{$context.NumberOfBusinessDaysToComplete}}</td>
			<td>{{$context.NumberOfBusinessDaysElapsed}}</td>
			<td>{{$context.NumberOfBusinessDaysOverOrUnder}}</td>
		</tr>
	</ng-template>
</div>
