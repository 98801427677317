<div [loadingOverlay]="isLoading">
	<compose *ngIf="grid()" Selector="app-components-compositionroutegrid" [Model]="{ grid: grid }"></compose>
	<div [hidden]="!showSaveButton" class="row">
		<div class="col-lg-12">
			<div class="card card-body bg-light d-block text-end">
				<button [hidden]="!canClose" (click)="cancel()" class="btn btn-link">Cancel</button>
				<button [disabled]="!showSaveButton" (click)="save()" class="btn btn-success">
					<i class="fa fa-save"></i>
					Save
				</button>
			</div>
		</div>
	</div>
</div>
