import { Component } from '@angular/core';
import Timer from '../../../../shared/models/timer';
import { SeoContentSurveyService } from '../../../../services/seo-content-survey.service';
import { CustomerQuickUrlContentAnalysisService } from '../../../../services/customer-quick-url-content-analysis.service';
import { OnsiteRecommendationBase } from '../OnsiteRecommendationBase/OnsiteRecommendationBase';

type OnsiteRecommendationInput = Boo.Tasks.Inputs.Models.OnsiteRecommendationInput;
type OnsiteRecommendationReviewOutput = Boo.Tasks.Outputs.Models.OnsiteRecommendationReviewOutput;

@Component({
	selector: 'app-components-specialist-actioncomponents-onsiterecommendationreview',
	templateUrl: './OnsiteRecommendationReview.component.html'
})
export class OnsiteRecommendationReviewComponent extends OnsiteRecommendationBase implements IActionWorkspace, IActivatable {
    currentFile: KnockoutComputed<Boo.Objects.File>;
    output: OnsiteRecommendationReviewOutput;
    timer: Timer = new Timer();
    actionViewModel: KnockoutObservable<IActionWorkspace>;
    
    constructor(
        protected seoContentSurveyService: SeoContentSurveyService,
        protected customerQuickUrlContentAnalysisService: CustomerQuickUrlContentAnalysisService) {
        super(seoContentSurveyService, customerQuickUrlContentAnalysisService);
    }

    activate(params: IActionWorkspaceActivationParams): JQueryPromise<void> {
        params.controlViewModel(this);
        this.actionViewModel = ko.observable(null);
        this.output = params.output as OnsiteRecommendationReviewOutput;
        this.timer.start();
        return super.initialize(params.currentAction, params.input as OnsiteRecommendationInput)
            .then(() => {
                this.currentFile = ko.computed(() => {
                    if (this.files.length > 0) {
                        return _.first(this.files);
                    }
                    return null;
                });

                this.revisionInformation = ko.observable(
                    this.input.OnsiteRecommendation && this.input.OnsiteRecommendation.RevisionInformation
                        ? this.input.OnsiteRecommendation.RevisionInformation
                        : null);

                if (this.keywordDensity().length <= 0) {
                    toastr.error('An error occurred. Could not load keywords.');
                }
            });
    }

    isStepReadyToReject(): boolean {
        this.output.OnsiteRecommendation = this.input.OnsiteRecommendation || {} as Boo.Objects.OnsiteRecommendation;
        // We are always ready to reject. The specialist workspace does all the hard work to require the comment.
        return true;
    }

    isStepReadyToSave(): boolean {
        return false;
    }

    isStepReadyToDelete(): boolean {
        // Never ever ever ever allowed to do this.
        return false;
    }

    isStepReadyToComplete(): boolean {
        if (!this.validation.isValid()) {
            this.validation.errors.showAllMessages();
            return false;
        }

        this.currentAction().Task.KeywordId = this.keywordId();
        this.currentAction().Task.AreaId = this.areaId();
        this.currentAction().Task.IsAreaFirst = this.isAreaFirst();
        
        this.output.OnsiteRecommendation = this.input.OnsiteRecommendation;

        if (!this.output.OnsiteRecommendation) {
            this.output.OnsiteRecommendation = {} as Boo.Objects.OnsiteRecommendation;
        }

        this.output.OnsiteRecommendation.TitleNew = $.trim(this.newTitle());
        this.output.OnsiteRecommendation.TitleOld = $.trim(this.currentTitle());
        this.output.OnsiteRecommendation.DescriptionNew = $.trim(this.newDescription());
        this.output.OnsiteRecommendation.DescriptionOld = $.trim(this.currentDescription());
        this.output.OnsiteRecommendation.H1Old = this.currentHeadingTags();
        this.output.OnsiteRecommendation.H1New = this.newH1Tag();
        this.output.OnsiteRecommendation.H2New = this.newH2Tag();
        this.output.OnsiteRecommendation.AltImageNew = this.newAltImageTag();

        // Pull out any image tag that have the same source as the new tag.
        if (this.newAltImageTag()) {
            let pattern = new RegExp('<img.+?src=["\'](.+?)["\'].+?>', 'i');
            let altImageNewSrc = pattern.exec(this.newAltImageTag());

            if (altImageNewSrc) {
                let altImageNewSrcString = $.trim(altImageNewSrc ? altImageNewSrc.toString() : '').toLowerCase();
                let imgTags = this.currentAltImageTags().split('\r\n');
                let oldAltImg = '';
                if (imgTags.length > 0) {
                    _.each(imgTags, (tag) => {
                        let tagPattern = new RegExp('<img.+?src=["\'](.+?)["\'].+?>', 'i');
                        let tmpStr = tagPattern.exec(tag);
                        if (tmpStr && $.trim(tmpStr ? tmpStr.toString() : '').toLowerCase() === altImageNewSrcString) {
                            oldAltImg += (tag + '\r\n');
                        }
                    });
                    this.output.OnsiteRecommendation.AltImageOld = oldAltImg;
                }
            }
        }

        let fileExtension = (/[.]/.exec(this.filename())) ? /[^.]+$/.exec(this.filename()) : '';
        if (fileExtension.length > 0) {
            this.output.OnsiteRecommendation.FileExtension = '.' + fileExtension;
        }

        let dataSplits = this.base64Data().split('base64,');
        if (dataSplits.length > 1) {
            this.output.ChangesFileBytes = dataSplits[1];
        }

        return true;
    }
}
