<div [loadingOverlay]="isLoading">
  <form (ngSubmit)="search()">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
            <input [(ngModel)]="textFilter" [ngModelOptions]="{ standalone: true }"
            class="form-control" placeholder="Search note" type="text" />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
            <select [(ngModel)]="contactTypeFilter"
            [ngModelOptions]="{ standalone: true }" name="contactTypeFilter" class="form-select">
            <option [ngValue]="undefined">All Notes</option>
              <option *ngFor="let option of contactTypes" [ngValue]="option.ContactTypeId">{{ option.Name }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <a (click)="clear()" href="javascript:void(0)" class="btn btn-outline-secondary btn-sm">
            <i class="fa fa-times"></i>
            Reset
          </a>
          <button (click)="search()" type="submit" class="btn btn-success">
            <i class="fa fa-search"></i>
            Search
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <button [disabled]="!canActionComponent" (click)="addNote()" class="btn btn-primary float-end">
          <i class="fa fa-plus"></i>
          New Note
        </button>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12">
        <shared-components-odatagrid *ngIf="dataTableOptions" [options]="dataTableOptions">
        </shared-components-odatagrid>
      <ng-template #row_template let-ViewModel="ViewModel">
        <tr>
          <td>{{ViewModel.InsertedDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
          <td>{{(ViewModel.TicketId && ViewModel.TicketId > 0) ? ViewModel.TicketId : ''}}</td>
          <td>
            <ng-container *ngIf="(ViewModel.TicketId && ViewModel.TicketId > 0)">
                <button [disabled]="!(canActionComponent)" (click)="openTicket(ViewModel)" class="btn btn-primary">
                <i class="fa fa-tag"></i>
                <span>{{ViewModel.TicketTypeName}}</span>
              </button>
            </ng-container>
            <span class="line-breaks">
              <shared-components-textwithlinks [Text]="ViewModel.Text"></shared-components-textwithlinks>
            </span>
          </td>
          <td>{{ViewModel.UserFullName}}</td>
          <td>
            <span>{{ViewModel.ContactTypeName}}</span>
          </td>
          <td>
            <ng-container *ngIf="canReviseNotes && isNoteEditable(ViewModel)">
                <button [disabled]="!(canActionComponent)" (click)="reviseNote(ViewModel)"
                class="btn btn-primary">Revise</button>
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </div>
  </div>
</div>