<div *withPermissions="let allow of ['CanViewKeywordBudget']" class="card">
	<div class="card-body py-2">
		<ul navigationHandler class="nav nav-pills">
			<li class="nav-item">
				<a href="/#/sales/addcustomer" class="nav-link">Add Customer</a>
			</li>
			<li class="nav-item">
				<a href="/#/sales/searchcustomers" class="nav-link">Find Customer</a>
			</li>
		</ul>
	</div>
</div>
