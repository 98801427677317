import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { OptionTypes } from '../shared/models/options/option-types.model';
import { CustomerOption } from '../shared/models/options/customer-option.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerOptionService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/customeroptions`;

  constructor(private http: HttpClient) { }

  GetOption<T>(customerId: number, optionType: OptionTypes): Observable<CustomerOption<T>> {
    return this.http.get<CustomerOption<T>>(`${this.baseUrl}/${customerId}/${optionType}`);
  }

  GetAll(customerId: number): Observable<CustomerOption<any>[]> {
    return this.http.get<CustomerOption<any>[]>(`${this.baseUrl}/${customerId}`);
  }

  Save(options: CustomerOption<any>[]): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}`, options);
  }
}
