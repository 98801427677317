<div class="row text-center">
	<div class="col-md-12">
		<div class="inline-block-divs">
			<h3>{{performanceSummary.CustomersActioned}}</h3>
			<span>
				<i class="far fa-check-circle"></i>
				Customers Actioned
			</span>
		</div>
		<div class="inline-block-divs">
			<h3>{{performanceSummary.TicketValueCompleted}}</h3>
			<span>
				<i class="fa fa-balance-scale"></i>
				Ticket Value Completed
			</span>
		</div>
		<ng-container *ngIf="customersInQueue() === undefined">
			<div class="inline-block-divs">
				<button (click)="getQueueSize()" class="btn btn-primary" style="margin-top: -20px;">
					<i class="fas fa-sync"></i>
					Customers in Queue
				</button>
			</div>
		</ng-container>
		<ng-container *ngIf="customersInQueue() >= 0">
			<div class="inline-block-divs">
				<h3>{{customersInQueue()}}</h3>
				<span>
					<i class="fa fa-list"></i>
					Customers in Queue
				</span>
			</div>
		</ng-container>
	</div>
</div>
