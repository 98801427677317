import { Component } from '@angular/core';
import System from 'framework/System';
import BaseComponent from '../BaseComponent';
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import Utils from '../../../shared/utils';
import { CustomerNoteService } from '../../../services/customer-note.service';

@Component({
	selector: 'app-components-ticket-keyworddiscovery',
	templateUrl: './KeywordDiscovery.component.html'
})
export class KeywordDiscoveryComponent extends BaseComponent {
    public isCampaignNationalOptions: { name: string, value: boolean }[] = [
        { name: 'Choose...', value: null },
        { name: 'National', value: true },
        { name: 'Local', value: false }
    ];
    public maxLength: number = 1950;
    public locationsNote: KnockoutObservable<string>;
    public productsAndServicesNote: KnockoutObservable<string>;
    public exclusionNote: KnockoutObservable<string>;
    public additionalInformationNote: KnockoutObservable<string>;
    isOpen = true;

    protected isCampaignNationalDirty: KnockoutComputed<boolean>;

    private keywordDiscoveryTag: string  = 'Keyword Discovery: ';
    private locationsTag: string = 'Locations to be researched: ';
    private productsAndServicesTag: string = 'Products and services to be researched: ';
    private exclusionTag: string = 'Locations, products, or services to be EXCLUDED from research: ';
    private additionalInformationTag: string = 'Additional information to be considered: ';
    private savedNote: Boo.Objects.CustomerNote;

    constructor(private customerNoteService: CustomerNoteService) {
      super();
    }

    public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        this.locationsNote = ko.observable('');
        this.productsAndServicesNote = ko.observable('');
        this.exclusionNote = ko.observable('');
        this.additionalInformationNote = ko.observable('');

        return super.activate(params).then(() => {
            return this.customerNoteService.get(this.customer.CustomerId())
            .filter(`startswith(Text, '${this.keywordDiscoveryTag}')`)
            .orderBy('InsertedDate desc')
            .top(1)
            .load();
        }).then((notes: framework.data.IPageResult<Boo.Objects.CustomerNote> )=> {
            if(notes.Items.length) {
                this.parseNotes(notes);
            }

            this.isCampaignNationalDirty = ko.dirtyFlagComputed(this.customer.IsCampaignNational);

            this.validation = ko.validatedObservable({
                campaign: this.customer.IsCampaignNational.extend({
                    validation: {
                        validator: (val: boolean): boolean => {
                            // only validate on complete
                            return this.saveType() !== SaveTypes.Complete || val === true || val === false;
                        },
                        message: 'Customer campaign must be verified as either local or national.'
                    }
                }),
                locationsNote: this.locationsNote.extend({
                    required: {
                        onlyIf: (): boolean => { return this.saveType() === SaveTypes.Complete && !this.customer.IsCampaignNational() }
                    },
                    maxLength: this.maxLength
                }),
                productsAndServicesNote: this.productsAndServicesNote.extend({
                    required: {
                        onlyIf: (): boolean => { return this.saveType() === SaveTypes.Complete; }
                    },
                    maxLength: this.maxLength
                }),
                exclusionNote: this.exclusionNote.extend({
                    maxLength: this.maxLength
                }),
                additionalInformationNote: this.additionalInformationNote.extend({
                    maxLength: this.maxLength
                }),
            });
        });
    }

    public confirmSave(saveType: SaveTypes): JQueryPromise<boolean> {
        return System.resolvedPromise(true);
    }

    public save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
        return super.save(saveType)
        .then(() => {
            let note: Boo.Objects.CustomerNote =
                this.savedNote ?
                this.savedNote :
                <Boo.Objects.CustomerNote>{
                    CustomerId: this.customer.CustomerId(),
                    CustomerNoteId: 0,
                    IsSystemGenerated: false,
                    ContactTypeId: launchpad.config.ContactTypeEnum.None,
                    UserId: this.user.UserId
                };

            note.Text = this.generateKeywordDiscoveryNote();

            return Utils.wrapDfd(this.customerNoteService.save(note));
        }).then(() => {
            if (this.isCampaignNationalDirty()) {
                return <app.ticket.interfaces.ISaveData>{
                    customer: {
                        IsCampaignNational: this.customer.IsCampaignNational()
                    }
                };
            }
        });
    }

    public validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
        return super.validate(saveType)
            .then((validationResult) => {
                if (!validationResult.isValid) {
                    // open toggle panel
                    this.isOpen = true;
                }
                return validationResult;
            });
    }

    private parseNotes(notes: framework.data.IPageResult<Boo.Objects.CustomerNote>)
    {
        this.savedNote = notes.Items[0];
        let note = this.savedNote.Text;
        this.locationsNote(note.substring(note.indexOf(this.locationsTag) + this.locationsTag.length, note.indexOf(this.productsAndServicesTag)).trim());
        this.productsAndServicesNote(note.substring(note.indexOf(this.productsAndServicesTag) + this.productsAndServicesTag.length, note.indexOf(this.exclusionTag)).trim());
        this.exclusionNote(note.substring(note.indexOf(this.exclusionTag) + this.exclusionTag.length, note.indexOf(this.additionalInformationTag)).trim());
        this.additionalInformationNote(note.substring(note.indexOf(this.additionalInformationTag) + this.additionalInformationTag.length, note.length).trim());
    }

    private generateKeywordDiscoveryNote() : string {
return `${this.keywordDiscoveryTag}
${this.locationsTag}
${this.customer.IsCampaignNational() ? 'National' : this.locationsNote().trim()}

${this.productsAndServicesTag}
${this.productsAndServicesNote().trim()}

${this.exclusionTag}
${this.exclusionNote().trim()}

${this.additionalInformationTag}
${this.additionalInformationNote().trim()}`;
    }
}
