<div>
	<hr/>
	<div class="row">
		<div class="col-lg-4">
			<div class="card">
				<div class="card-header">
					Pages
					<span class="badge bg-secondary">{{pageCount()}}</span>
				</div>
				<div class="card-body" style="min-height: 675px">
					<ng-container *ngIf="data().length === 0 && !isReadOnly">
						<div class="alert alert-info text-center">
							<strong>
								<button (click)="addContentPage()" class="btn btn-sm btn-primary">
									<i class="fa fa-plus"></i>
									Page
								</button>
								<button (click)="addNavigation()" class="btn btn-sm btn-primary">
									<i class="fa fa-plus"></i>
									Navigation
								</button>
							</strong>
						</div>
					</ng-container>
					<shared-components-tree [Settings]="options" (select_node)="onSelected.apply(this, $event);" (move_node)="onMove.apply(this, $event);"></shared-components-tree>
				</div>
				<ng-container *ngIf="!isReadOnly">
					<div class="card-footer text-end">
						<button (click)="addContentPage()" class="btn btn-sm btn-primary">
							<i class="fa fa-plus"></i>
							Page
						</button>
						<button (click)="addNavigation()" class="btn btn-sm btn-primary">
							<i class="fa fa-plus"></i>
							Navigation
						</button>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="col-lg-8">
			<ng-container *ngIf="selected()">
				<div class="animated fadeIn">
					<ng-container *ngTemplateOutlet="pageTemplate;context:{$context:selected()}"></ng-container>
				</div>
			</ng-container>
		</div>
	</div>
	<ng-container *ngIf="!isReadOnly">
		<div class="row top10">
			<div class="col-md-12">
				<div class="card card-body bg-light d-block text-end">
					<button (click)="save()" class="btn btn-success">
						<i class="fa fa-save"></i>
						Save
					</button>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-template #pageTemplate let-$context="$context">
		<div class="row">
			<ng-container *ngIf="$context">
				<ng-container *ngIf="!($context.IsContent())">
					<div class="col-lg-6">
						<div class="form-group">
							<label>Name</label>
							<input [dataValidation]="{placement:'top', value: $context.Name }" [ngModel]="$context.Name()" (ngModelChange)="$context.Name($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" type="text" class="form-control"/>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="$context.IsContent()">
					<div class="col-lg-6">
						<div class="form-group">
							<label>Name</label>
							<input [dataValidation]="{placement:'top', value: $context.Name }" [ngModel]="$context.Name()" (ngModelChange)="$context.Name($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" type="text" class="form-control"/>
						</div>
						<div class="form-group">
							<label>Current URL</label>
							<input [dataValidation]="{placement:'top', value: $context.CurrentUrl }" [ngModel]="$context.CurrentUrl()" (ngModelChange)="$context.CurrentUrl($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" type="text" class="form-control"/>
						</div>
						<div class="form-group">
							<label>New URL</label>
							<input [dataValidation]="{placement:'top', value: $context.NewUrl }" [ngModel]="$context.NewUrl()" (ngModelChange)="$context.NewUrl($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" type="text" class="form-control"/>
						</div>
						<div class="form-group">
							<label>301 Redirects</label>
							<input [dataValidation]="{placement:'top', value: $context.Redirects301 }" [ngModel]="$context.Redirects301()" (ngModelChange)="$context.Redirects301($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" type="text" class="form-control"/>
						</div>
						<div class="form-group">
							<label>Type</label>
							<select [ngModel]="$context.WebsitePageTypeId()" (ngModelChange)="$context.WebsitePageTypeId($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" class="form-select">
								<option [ngValue]="undefined">Choose page type</option>
								<option *ngFor="let option of pagetypes" [ngValue]="option.WebsitePageTypeId">{{ option.Name }}</option>
							</select>
						</div>
						<div class="row">
							<div class="col-lg-6">
								<div class="form-group">
									<label>Use Existing Content?</label>
									<div>
										<shared-components-customchoicelegacy [Settings]="{ value: $context.UseExistingContent, disable: isReadOnly }"></shared-components-customchoicelegacy>
									</div>
								</div>
							</div>
							<div class="col-lg-6">
								<div class="form-group">
									<label>Use Existing Images?</label>
									<div>
										<shared-components-customchoicelegacy [Settings]="{ value: $context.UseExistingImages, disable: isReadOnly }"></shared-components-customchoicelegacy>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-5">
						<div class="form-group">
							<label>Unique Selling Points</label>
							<textarea [dataValidation]="{placement:'top', value: $context.UniqueSellingPoints }" [ngModel]="$context.UniqueSellingPoints()" (ngModelChange)="$context.UniqueSellingPoints($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control"></textarea>
						</div>
						<div class="form-group">
							<label>Features</label>
							<textarea [dataValidation]="{placement:'top', value: $context.Features }" [ngModel]="$context.Features()" (ngModelChange)="$context.Features($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control"></textarea>
						</div>
						<div class="form-group">
							<label>Requirements</label>
							<textarea [dataValidation]="{placement:'top', value: $context.Requirements }" [ngModel]="$context.Requirements()" (ngModelChange)="$context.Requirements($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" placeholder="Any specific requests? Preferred terminology, advertising restrictions, legal requirements, etc." class="form-control"></textarea>
						</div>
						<div class="form-group">
							<label>Builder Instructions</label>
							<textarea [dataValidation]="{placement:'top', value: $context.BuilderInstructions }" [ngModel]="$context.BuilderInstructions()" (ngModelChange)="$context.BuilderInstructions($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" placeholder="Instructions to builder specific to the design. What are the client's expectations for design features, photos and colors on this page?" class="form-control"></textarea>
						</div>
						<div class="form-group">
							<label>Writer Instructions</label>
							<textarea [dataValidation]="{placement:'top', value: $context.WriterInstructions }" [ngModel]="$context.WriterInstructions()" (ngModelChange)="$context.WriterInstructions($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" placeholder="Instructions to writer specific to the content. Would the client like any specific sections or topics on this page?" class="form-control"></textarea>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</div>
		<ng-container *ngIf="!isReadOnly">
			<div class="row">
				<div class="col-lg-6 d-grid gap-2">
					<ng-container *ngIf="selectedCanBeDeleted()">
						<button [disabled]="!selectedCanBeDeleted()" (click)="delete()" class="btn btn-danger">
							<i class="fas fa-trash-alt"></i>
							Delete
						</button>
					</ng-container>
					<ng-container *ngIf="!selectedCanBeDeleted()">
						<div class="alert alert-warning">This page cannot be deleted until the sub-pages are removed.</div>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</ng-template>
</div>
