<div [loadingOverlay]="isLoading">
	<div class="card">
		<div class="card-header">
			Notifications
		</div>
		<div class="card-body">
			<app-table [items]="notifications">
				<ng-template #tableHeader>
					<tr>
						<th sort field="NotificationDefinitionName">Type</th>
						<th sort desc field="SentDate">Sent</th>
						<th sort field="RouteTo">To</th>
						<th sort field="RouteFrom">From</th>
						<th sort field="MessageTitle">Subject</th>
						<th field="MessageBody">Body</th>
					</tr>
				</ng-template>
				<ng-template #tableBody let-notification>
					<tr>
						<td>{{ notification.NotificationDefinitionName }}</td>
						<td>{{ notification.SentDate | dateText: 'M/DD/YYYY h:mm:ss a' }}</td>
						<td>{{ notification.RouteTo }}</td>
						<td>{{ notification.RouteFrom }}</td>
						<td>{{ notification.MessageTitle }}</td>
						<td *ngIf="shownBodyNotification === notification">
							<button class="btn" (click)="hideBody(notification)">
								<i class="fas fa-eye-slash" style="margin-right: 0px;"></i> Hide
							</button>
							<div style="max-width: 300px; word-wrap: break-word !important;" [innerHTML]="notification.MessageBody">
							</div>
						</td>
						<td *ngIf="shownBodyNotification !== notification">
							<button class="btn" (click)="showBody(notification)">
								<i class="fas fa-eye" style="margin-right: 0px;"></i> Show
							</button>
						</td>
					</tr>
				</ng-template>
			</app-table>
		</div>
	</div>
</div>