import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/tasks`;

  constructor(private http: HttpClient) { }

  getCustomActionTaskIdForWorkflow(taskId: number): Observable<number> {
    const params = new HttpParams().set('taskId', taskId);

    return this.http.get<number>(`${this.baseUrl}/GetCustomActionTaskIdForWorkflow`, { params: params });
  }

  getLocked(customerId: number, assignedUser: string, taskTypeTitle: string): Observable<Boo.Objects.LockedTask[]> {
    return this.http.post<Boo.Objects.LockedTask[]>(`${this.baseUrl}/GetLocked`, {
        customerId: customerId,
        assignedUser: assignedUser,
        taskTypeTitle: taskTypeTitle
    });
  }

  getLockedAssignedUsers(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/GetLockedAssignedUsers`);
  }

  getLockedTaskTypeTitle(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/GetLockedTaskTypeTitle`);
  }

  unlockAndUnassign(taskId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/UnlockAndUnassign`, taskId);
  }

  getCustomActionsByCustomerIdAndDateRange(customerId: number, startDate: moment.Moment, endDate: moment.Moment): Observable<Boo.Objects.Task[]> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('startDate', startDate.toISOString())
      .set('endDate', endDate.toISOString());

    return this.http.get<Boo.Objects.Task[]>(`${this.baseUrl}/GetCustomActionsByCustomerIdAndDateRange`, { params: params });
  }

  getById(taskId: number): Observable<Boo.Objects.Task> {
    const params = new HttpParams().set('taskId', taskId);

    return this.http.get<Boo.Objects.Task>(`${this.baseUrl}/GetById`, { params: params });
  }

  getOnsiteTaskDefinitionHistory(customerId: number): Observable<Boo.Objects.OnsiteBloggingTaskHistory[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.OnsiteBloggingTaskHistory[]>(`${this.baseUrl}/GetOnsiteTaskDefinitionHistory`, { params: params });
  }

  onsiteRecommendationCsrApproval(taskId: number, isApproved: boolean, reason: string, source: number): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/OnsiteRecommendationCsrApproval`, {
        taskId: taskId,
        isApproved: isApproved,
        reason: reason,
        source: source
    });
  }

  getPendingWorkForCustomer(customerId: number): Observable<Boo.Objects.PendingTaskDetail[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.PendingTaskDetail[]>(`${this.baseUrl}/GetPendingWorkForCustomer`, { params: params });
  }

  assignedTasks(): Observable<Boo.Objects.AssignedTask[]> {
    return this.http.get<Boo.Objects.AssignedTask[]>(`${this.baseUrl}/AssignedTasks`);
  }

  verify(taskId: number): Observable<Boo.Objects.VerificationResult> {
    return this.http.post<Boo.Objects.VerificationResult>(`${this.baseUrl}/Verify`, taskId);
  }

  forceVerifyTask(taskId: number, userId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/ForceVerifyTask`, {
        taskId: taskId,
        userId: userId
    });
  }

  isActionableByUser(taskId: number, userId: number): Observable<boolean> {
    const params = new HttpParams()
      .set('taskId', taskId)
      .set('userId', userId);

    return this.http.get<boolean>(`${this.baseUrl}/IsActionableByUser`, { params: params });
  }

  unassignAndUnlockNonPersistableAssignedTask(taskId: number, userId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/UnassignAndUnlockNonPersistableAssignedTask`, {
        taskId: taskId,
        userId: userId
    });
  }

  getOnsiteOptimizationTasks(customerId: number, shouldIncludeBloggingTasks: boolean): Observable<Boo.Objects.OnsiteOptimizationTask[]> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('shouldIncludeBloggingTasks', shouldIncludeBloggingTasks);

    return this.http.get<Boo.Objects.OnsiteOptimizationTask[]>(`${this.baseUrl}/GetOnsiteOptimizationTasks`, { params: params });
  }
}
