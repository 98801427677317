import System from 'framework/System';
import Check from 'framework/Check';
import OptionsParams = app.components.interfaces.IOptionsSelectorParams;

/**
 * This is an abstract class to make lists easier to implement.      
 */
export default class OptionsSelector<ListType, ValueType> implements IActivatable {
    public items: KnockoutObservableArray<ListType>;
    public value: KnockoutObservable<ValueType>;
    private optionsText: string;
    private optionsValue: string;

    public activate(params: OptionsParams<ValueType>): JQueryPromise<void> {
        return this.getItems()
            .then((items: ListType[]) => {
                this.items = ko.observableArray(items);
                this.value = params.value;
                this.optionsText = params.optionsText;
                this.optionsValue = params.optionsValue;

                return System.emptyPromise();
            });
    }

    public getItems(): JQueryPromise<ListType[]> {
        Check.notImplemented();
        return System.resolvedPromise(this.items());
    }
}
