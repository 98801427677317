import { Component } from '@angular/core';
import Check from 'framework/Check';
import MerchantFieldLabelMap from 'app/models/typescript/MerchantFieldLabelMap';
import Utils from '../../../shared/utils';
import { MerchantFieldsService } from '../../../services/merchant-fields.service';

@Component({
	selector: 'app-components-managecustomer-merchantfieldsedit',
	templateUrl: './MerchantFieldsEdit.component.html'
})
export class MerchantFieldsEditComponent implements IActivatable {
    public title = 'Partner - Edit Merchant Fields';
    public customer: Boo.Objects.Customer;
    public isLoading: KnockoutObservable<boolean>;
    public merchantFieldLabelMap: KnockoutObservable<MerchantFieldLabelMap>;
    public merchantFields: KnockoutObservable<Boo.Objects.CustomerMerchantFields>;
    public merchantSitePublishDate: KnockoutObservable<moment.Moment>;
    protected priorityViewApi: app.interfaces.IPriorityViewPublicApi;

    constructor(private merchantFieldsService: MerchantFieldsService) { }

    public activate(params: app.managecustomer.interfaces.IMerchantFieldsEditParams): JQueryPromise<any> {
        Check.isNotEmpty(params.customer, 'Customer cannot be empty');
        Check.isNotEmpty(params.priorityViewApi, 'Priority view api is missing');
        this.priorityViewApi = params.priorityViewApi || null;
        this.isLoading = ko.observable(true);
        this.customer = ko.mapping.toJS(params.customer);
        this.merchantFieldLabelMap = ko.observable(null);
        this.merchantFields = ko.observable(null);
        this.merchantSitePublishDate = ko.observable(null);

        return $.when<any>(this.merchantFieldsService.generateLabelMapping(this.customer.PartnerId), Utils.wrapDfd(this.merchantFieldsService.get(this.customer.CustomerId)))
            .then((map: MerchantFieldLabelMap, merchantFields: Boo.Objects.CustomerMerchantFields) => {
                this.merchantFieldLabelMap = ko.observable(map);
                this.merchantFields = ko.observable(merchantFields);

                this.merchantSitePublishDate = ko.observable(moment(merchantFields.MerchantSitePublishDate));
            })
            .fail((displayMessage: string) => toastr.error(displayMessage))
            .always(() => this.isLoading(false));

    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        return _.any(user.PartnerUsers, (partnerUser: Boo.Objects.PartnerUser) => {
            // User has a customer service user and not readonly then they can edit merchant fields
            return partnerUser.UserLevelId === launchpad.config.keys.customerServiceUserLevelId && partnerUser.IsReadonly === false;
        });
    }

    public save(): JQueryPromise<void> {
        this.isLoading(true);
        this.merchantFields().MerchantSitePublishDate = this.merchantSitePublishDate().isValid() ? this.merchantSitePublishDate().toDate() : null;
        return Utils.wrapDfd(this.merchantFieldsService.save(ko.mapping.toJS(this.merchantFields)))
            .then(() => {
                if (this.priorityViewApi) {
                    this.priorityViewApi.resolve();
                }
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading(false);
            });
    }

    public close(): void {
        this.priorityViewApi.reject();
    }
}
