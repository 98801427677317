<div [loadingOverlay]="isLoading()">
	<ng-container *ngIf="floatingTabPanelApi()">
		<ng-container *ngFor="let $data of componentControlOptions">
			<compose [attr.id]="$data.options.name + 'TicketComponent'" [Selector]="$data.selector"
				[ActivateOptions]="$data.options" [Model]="$data.model" [Callback]="$data.callback?.bind(this)"
				[FailureCallback]="$data.failureCallback?.bind(this)"></compose>
		</ng-container>
	</ng-container>
	<div class="card card-body bg-light d-block text-end">
		<ng-container *ngIf="canEdit">
			<button [hidden]="!canCancel" (click)="cancel()" class="btn btn-link">Cancel</button>
			<div class="btn-group">
				<button (click)="save(SaveTypes.Update, false)" class="btn btn-primary">
					<i class="fa fa-save"></i>
					Update
				</button>
				<ng-container *ngIf="canUpdateAndKeepOpen">
					<button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
						aria-expanded="false">
						<span class="caret"></span>
						<span class="sr-only">Toggle Dropdown</span>
					</button>
					<ul class="dropdown-menu">
						<li>
							<button (click)="save(SaveTypes.Update, false)" class="btn btn-link">Update &amp; Keep Open</button>
						</li>
					</ul>
				</ng-container>
			</div>
			<button [hidden]="!canComplete" (click)="save(SaveTypes.Complete, false)" class="btn btn-success">
				<i class="fa fa-check-square"></i>
				Complete
			</button>
		</ng-container>
		<ng-container *ngIf="!canEdit && canCancel">
			<button (click)="cancel()" class="btn btn-primary">Close</button>
		</ng-container>
	</div>
	<app-components-floatingtabpanel [options]="{
		apiCallback: floatingTabPanelApiCallback.bind(this),
		isLoading: isLoading		
	}"></app-components-floatingtabpanel>
</div>