import { Component } from '@angular/core';
import System from 'framework/System';
import app from 'App';
import { PriorityViewModel } from 'app/models/PriorityViewModel';

/**
 * Launches the partneruser priority view from a route that specifies the user id.
 * Example route: manager/edituser/296139
 */
@Component({
    selector: 'app-components-manager-edituser',
    templateUrl: './EditUser.component.html'
})
export class EditUserComponent implements IActivatable {
    public activate(params: any): JQueryPromise<void> {
        const userId = params ? parseInt(params[0], 10) : null;
        if (!userId || userId <= 0) {
            toastr.error('Invalid User ID');
            return System.emptyPromise();
        }

        return PriorityViewModel.show('app-components-manager-partneruser', { userId: userId }, false, false)
            .then(null, () => {
                // priority views are "rejected" when they're closed. This will cause CompositionEngine to show an error thinking it couldn't load the route.
                return $.Deferred().resolve();
            });
    }
}
