import TicketTypeComponents from 'app/ticket/config/TicketTypeComponents';
import ITicketComponentActivateParams = app.ticket.components.interfaces.ITicketComponentActivateParams;

export default class ComponentFactory {
    /**
     * Return an ICompositionRoute for the component where the model is a resolved model instance.
     * @param {app.ticket.interfaces.IComponentConfig} componentConfig
     * @param {ITicketComponentActivateParams} params
     * @returns JQueryPromise<ICompositionRoute>
     */
    public static component(name: string, activateParams: ITicketComponentActivateParams): JQueryPromise<ICompositionRoute> {
        return $.when()
            .then((): ICompositionRoute => {
                return {
                    selector: 'app-components-ticket-' + name,
                    options: activateParams
                };
            });
    }

    /**
     * Helper that call components() with the configured component names for the ticketTypeId and partnerId
     * @param {number} ticketTypeId
     * @param {number} partnerId
     * @param {ITicketComponentActivateParams} params
     * @returns JQueryPromise<ICompositionRoute[]>
     */
    public static create(ticketType: Boo.Objects.TicketType, params: ITicketComponentActivateParams): JQueryPromise<ICompositionRoute[]> {
        // Find the correct component config based on parameters.
        let componentConfig: app.ticket.interfaces.IComponentConfig[] = TicketTypeComponents.get(ticketType, params.partner.PartnerId);

        let promises: JQueryPromise<ICompositionRoute>[] = _.map(componentConfig, (component: app.ticket.interfaces.IComponentConfig) => {
            let extendedParams: ITicketComponentActivateParams = _.extend({}, params, { featureConfig: component.componentFeatureConfig || {}, name: component.name });
            return ComponentFactory.component(component.name, extendedParams);
        });

        return $.when(...promises).then((...results: ICompositionRoute[]) => {
            return _.compact(results);
        });
    }
}
