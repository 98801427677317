<div class="row">
  <div class="col-md-12">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th class="d-flex justify-content-between align-items-center">
            <span>
              <span [ngClass]="{'text-danger text-decoration-line-through': urlSuggestion.WasDeleted}">{{urlSuggestion.Url}}</span>
              <ng-container *ngIf="urlSuggestion.RejectionNote && !isApproved">
                <i class="fa fa-sticky-note text-danger control-labe ml-2" [ngbPopover]="urlSuggestion.RejectionNote"
                  popoverTitle="Rejection Note" placement="right auto" triggers="mouseenter:mouseleave"></i>
              </ng-container>
            </span>
            <shared-components-customchoice class="ml-auto" [value]="urlSuggestion.IsExisting" [choiceTrue]="'Existing'"
              [choiceFalse]="'New'" [disable]="true">
            </shared-components-customchoice>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let keyword of urlSuggestion.QaKeywordSuggestions">
          <td
            [ngClass]="{'bg-success bg-gradient': textIsApproved(keyword), 'text-danger text-decoration-line-through': textIsRejected(keyword)}">
            <div class="row">
              <div class="col-md-9">
                {{ getKeywordDisplayText(keyword) }}
                <span class="badge rounded-pill bg-secondary">{{"rank: " + keyword.Rank}}</span>
                <span class="badge rounded-pill bg-secondary">{{"volume: " + (keyword.searchVolume | searchVolumeDisplay)}}</span>
              </div>
              <div class="col-md-3">
                {{ keyword.KeywordSuggestionType.Name }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>