<div>
	<ng-container *ngIf="websites.length > 1">
		<div class="card">
			<div class="card-header">
				Websites
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<select [ngModel]="selectedWebsiteId()" (ngModelChange)="selectedWebsiteId($event)" [ngModelOptions]="{ standalone: true }" name="selectedWebsite" class="form-select" id="selectedWebsite">
								<option *ngFor="let option of websites" [ngValue]="option.WebsiteId">{{ option.Domain }}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
	<compose Selector="app-components-websites-website" [ActivateOptions]="{
                            website: selectedWebsite(),
                            customer: customer,
                            isReadOnly: isReadOnly,
                            isAdjustmentTabVisible: isAdjustmentTabVisible
                        }"></compose>
</div>
