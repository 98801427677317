<div [loadingOverlay]="isLoading()" *withPermissions="let allow of ['IsLaunchPadAdmin', 'CanEditWebsiteIds']">
	<ng-container *ngIf="website.IsDomainPreOwned()">
		<div class="row">
			<div class="col-md-12">
				<div class="alert alert-warning">
					<i class="fa fa-exclamation-triangle"></i>
					<strong>Pre-Owned Domain:</strong>
					The domain
					<span>{{website.Domain()}}</span>
					was previously registered by the customer, so you must manually configure this domain.
				</div>
			</div>
		</div>
	</ng-container>
	<div class="row">
		<div class="col-md-6">
			<div class="form-group">
				<label>Domain</label>
				<p [hidden]="!website.Domain()" class="form-control-static">
					<span>{{website.Domain()}}</span>
					<span [class.bg-success]="website.IsDomainRegistered()" [class.bg-warning]="!website.IsDomainRegistered()" class="badge">{{website.IsDomainRegistered() ? 'Registered' : 'Not registered'}}</span>
					<span [hidden]="!website.IsDomainPreOwned()" class="badge bg-warning">Pre-Owned</span>
				</p>
				<p [hidden]="website.Domain()" class="form-control-static">None</p>
			</div>
			<div class="form-group">
				<label>Site Host</label>
				<p class="form-control-static">{{website.WebsiteAccount.HostName()}}</p>
			</div>
			<div class="form-group">
				<label>Account Id</label>
				<ng-container *ngIf="!(allow().IsLaunchPadAdmin() || allow().CanEditWebsiteIds())">
					<p class="form-control-static">{{website.WebsiteAccount.AccountId() || 'Account not yet created'}}</p>
				</ng-container>
				<ng-container *ngIf="allow().IsLaunchPadAdmin() || allow().CanEditWebsiteIds()">
					<input [dataValidation]="{ placement: 'top', value: website.WebsiteAccount.AccountId }" [ngModel]="website.WebsiteAccount.AccountId()" (ngModelChange)="website.WebsiteAccount.AccountId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text"/>
				</ng-container>
			</div>
			<div class="form-group">
				<label>
					Template Id
					<ng-container *ngIf="!canSetTemplate">
						<span ngbPopover="The template cannot be changed after the site is created." popoverTitle="" placement="top auto" triggers="mouseenter:mouseleave">
							<span class="fa fa-info-circle text-warning"></span>
						</span>
					</ng-container>
				</label>
				<ng-container *ngIf="canSetTemplate">
					<div class="input-group">
						<input [dataValidation]="{ placement: 'top', value: website.TemplateId }" [ngModel]="website.TemplateId()" (ngModelChange)="website.TemplateId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="number" min="0" class="form-control"/>
						<button [disabled]="!canSetTemplate" (click)="openTemplateSelector()" class="btn btn-primary">
							<i class="far fa-file-image"></i>
							Select
						</button>
					</div>
				</ng-container>
				<ng-container *ngIf="!canSetTemplate">
					<p class="form-control-static">
						<span>
							<span>{{website.TemplateId() || 'Unknown'}}</span>
						</span>
					</p>
				</ng-container>
			</div>
			<div class="form-group">
				<label>Site Id</label>
				<ng-container *ngIf="!(allow().IsLaunchPadAdmin() || allow().CanEditWebsiteIds())">
					<p class="form-control-static">{{website.SiteId() || 'Site not yet created'}}</p>
				</ng-container>
				<ng-container *ngIf="allow().IsLaunchPadAdmin() || allow().CanEditWebsiteIds()">
					<div class="input-group">
						<input [dataValidation]="{ placement: 'top', value: website.SiteId }" [ngModel]="website.SiteId()" (ngModelChange)="website.SiteId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text"/>
						<button (click)="loadSiteInfo()" class="btn btn-danger">
							<i class="fas fa-sync"></i>
							Load Site Info
						</button>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label>Preview URL</label>
				<div class="input-group">
					<ng-container *ngIf="!(allow().IsLaunchPadAdmin() || allow().CanEditWebsiteIds())">
						<input [ngModel]="website.PreviewUrl()" (ngModelChange)="website.PreviewUrl($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" disabled="" class="disabled form-control"/>
					</ng-container>
					<ng-container *ngIf="allow().IsLaunchPadAdmin() || allow().CanEditWebsiteIds()">
						<input [dataValidation]="{ placement: 'top', value: website.PreviewUrl }" [ngModel]="website.PreviewUrl()" (ngModelChange)="website.PreviewUrl($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text"/>
					</ng-container>
					<a [attr.href]="website.PreviewUrl()" [class.disabled]="!website.PreviewUrl()" class="btn btn-primary" target="_blank">
						<i class="fas fa-external-link-square-alt"></i>
						Preview
					</a>
				</div>
			</div>
			<div *ngIf="canViewAdminSso" class="form-group">
				<boost-site-admin-sso-button [websiteId]="website.WebsiteId()"></boost-site-admin-sso-button>
			</div>
			<div class="form-group">
				<label>Customer Single Sign-On URL</label>
				<div class="input-group">
					<input [ngModel]="ssoUrlForCustomer()" (ngModelChange)="ssoUrlForCustomer($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" disabled="" placeholder="Click Load or Sign-On..." class="disabled form-control"/>
					<button (click)="loadSsoUrlForCustomer()" class="btn btn-primary">
						<i class="fas fa-sync"></i>
						Load
					</button>
					<button (click)="signOnWithSsoUrlForCustomer()" class="btn btn-primary">
						<i class="fas fa-external-link-square-alt"></i>
						Sign-On
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="row top10">
		<div class="col-md-12">
			<div class="card card-body bg-light d-block text-end">
				<button (click)="save()" class="btn btn-success">
					<i class="fa fa-save"></i>
					Save
				</button>
			</div>
		</div>
	</div>
</div>
