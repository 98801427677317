<div>
  <app-components-togglepanel [(isOpen)]="isOpen" [title]="partnerName + ' – Ticket Type – ' + ticket.TicketType.Name()">
    <div *withPermissions="let allow of ['CanAssignTickets']">
      <div class="row">
        <ng-container *ngIf="ticket">
          <div class="col-md-6">
            <strong>Reason</strong>
            <div *ngIf="isFulfillmentWorkblockTicket">
              Notes left in the Fulfillment Work Block ticket cannot be seen by Fulfillment specialists. Based on the work block reason, please update the necessary fields on the customer. If you have any questions, please contact your manager.
              <app-components-helptext key="BasicInfo_WorkblockTicketReason"></app-components-helptext>
            </div>
            <textarea [ngModel]="ticket.Reason()" (ngModelChange)="ticket.Reason($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="!canEdit" [readonly]="!canEdit" class="form-control line-breaks" name="ticketReason" maxlength="4000" style="height: 212px; margin-bottom: 8px;"></textarea>
            <ng-container *ngIf="isOpen">
              <div>
              <ul class="list-group">
                <li class="list-group-item">
                  <strong>Assigned User</strong>
                  <span [hidden]="!(canChangeAssignedUser && !canChangeAssignedUser() && canEdit && allow().CanAssignTickets())" [ngbPopover]="'In order to assign this ticket to a user, \'Is Appointment\' must be set to \'Yes\'.'" placement="left auto" triggers="mouseenter:mouseleave" class="fas fa-exclamation-triangle text-warning"></span>
                  <span class="float-end" style="min-width:300px;">
                    <select select2 [dataValidation]="{ placement: 'top', value: ticket.AssignedUserId }" [ngModel]="ticket.AssignedUserId()" (ngModelChange)="ticket.AssignedUserId($event)" [ngModelOptions]="{ standalone: true }" [disabled]="!(canChangeAssignedUser() && canEdit && allow().CanAssignTickets())" style="margin-top: -4px;" class="form-control" name="assignedUser">
                      <option [ngValue]="0">Unassigned</option>
                      <option *ngFor="let option of userOptions" [ngValue]="option.UserId">{{ option.FullName }}</option>
                    </select>
                  </span>
                </li>
                <li class="list-group-item">
                  <strong>Is Appointment?</strong>
                  <span class="float-end">
                    <ng-container *ngIf="canEdit">
                      <div style="margin-top: -4px; margin-right: -10px">
                        <shared-components-customchoicelegacy [Settings]="{ value: ticket.IsAppointment }"></shared-components-customchoicelegacy>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!canEdit">
                      {{(ticket.IsAppointment() === true ? 'Yes' : 'No')}}
                    </ng-container>
                  </span>
                </li>
                <li class="list-group-item">
                  <strong>Status</strong>
                  <span class="float-end">{{ticket.StatusName()}}</span>
                </li>
              </ul>
            </div>
            </ng-container>
          </div>
          <div class="col-md-6">
            <ul class="list-group">
              <li *ngIf="customActionTaskId()" class="list-group-item">
                <strong>Associated Task</strong>
                <button (click)="showTaskDetails(customActionTaskId())" class="btn btn-sm btn-primary float-end">
									{{customActionTaskId()}}
								</button>
              </li>
              <li class="list-group-item">
                <strong>SLA Date <small>(Local Time)</small></strong>
                <span [class.text-danger]="ticket.DueDate() < moment().toDate()" class="form-control-static float-end">{{ticket.DueDate() | dateText: dateTimeFormat : false }}</span>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-md-6">
                    <div><strong>Next Action Date <small>(Local Time)</small></strong></div>
                    <input [readonly]="!(canEdit && isChangingDatesAllowed)" [dateTimePicker]="{ date: startDate }" type="text" name="startDate"/>
                  </div>
                  <div class="col-md-6">
                    <div><strong>Next Action Time <small>(Local Time)</small></strong></div>
                    <input timePicker [disabled]="!(canEdit && isChangingDatesAllowed)" [readonly]="!(canEdit && isChangingDatesAllowed)" [DateTime]="startTime" type="text"/>
                  </div>
                </div>
                <ng-container *ngIf="isOpen">
                  <div class="row" style="margin-top:10px;">
                    <div class="col-md-6">
                      <strong>Next Action Date <small>(Customer Time)</small></strong>
                    </div>
                    <div class="col-md-6">
                      <span class="form-control-static float-end">{{customerStartDateTime() | dateText: dateTimeFormat }}</span>
                    </div>
                  </div>
                </ng-container>
              </li>
              <li class="list-group-item">
                <strong>Customer Current Time</strong>
                <compose Selector="app-shared-components-timezonedatetime" [Model]="{ timeZone: customer.TimeZone() }" class="float-end"></compose>
              </li>
              <ng-container *ngIf="isOpen">
                <li class="list-group-item">
                  <strong>Estimated Time</strong>
                  <span class="float-end">{{estimatedTime()}}</span>
                </li>
                <li class="list-group-item">
                  <strong>Total Time Spent</strong>
                  <span class="float-end">{{completedTime()}}</span>
                </li>
                <li class="list-group-item">
                  <strong>Created By</strong>
                  <span class="float-end">{{(_.isObject(ticket.InsertUser) && ticket.InsertUser.FullName()) ? ticket.InsertUser.FullName() : ''}}</span>
                </li>
                <li class="list-group-item">
                  <strong>Created Date <small>(Local Time)</small></strong>
                  <span class="float-end">{{ticket.InsertedDate() | dateText: dateTimeFormat }}</span>
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </app-components-togglepanel>
</div>
