import { ImplicitMustacheValue } from "./implicit-mustache-value.type";
import { MustacheTag } from "./mustache-tag.model";
import { MustacheTagTypes } from "./mustache-tag-types.model";

export class MustacheImplicitArrayTag implements MustacheTag {
  type = MustacheTagTypes.implicitArray;
  key: string;
  subKeys: null;
  value: ImplicitMustacheValue[];

  constructor(key: string) {
    this.key = key;
  }
}