import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import IValidatedResult = app.interfaces.IValidatedResult;

@Component({
  selector: 'app-components-ticket-linkbuilding',
  templateUrl: './LinkBuilding.component.html'
})
export class LinkBuildingComponent extends BaseComponent {

  public heading: KnockoutObservable<string>;
  public description: KnockoutObservable<string>;
  isOpen = false;

  public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
    if (!params || !params.customer) {
      throw new Error('No customer provided for Link Building Info');
    }

    this.heading = params.customer.Heading;
    this.description = params.customer.Description;
    this.heading.extend({
      required: {
        message: 'Link Building  Heading - Please enter between 25 and 65 characters.'
      },
      maxLength: 65,
      minLength: 25
    });
    this.description.extend({
      required: {
        message: 'Link Building Description - Please enter between 75 and 155 characters.'
      },
      maxLength: 155,
      minLength: 75
    });
    this.validation = ko.validatedObservable([
      this.heading,
      this.description
    ]);

    return super.activate(params);
  }

  public validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
    return super.validate(saveType)
      .then((validationResult) => {

        if(saveType !== SaveTypes.Complete)
        {
          validationResult.isValid = true;
        }

        if (!validationResult.isValid) {
          this.isOpen = true;
        }

        return validationResult;
      });
  }

  public save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData> {
    return super.save(saveType)
      .then(() => {
        return {
          customer: {
            Heading: this.customer.Heading(),
            Description: this.customer.Description()
          }
        };
      });
  }
}
