/* tslint:disable */

import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import WorkRequestHelper from 'app/managecustomer/WorkRequestHelper';
import { PreAuditDetailService } from '../../../services/pre-audit-detail.service';
import { OnsiteRecommendationDetailService } from '../../../services/onsite-recommendation-detail.service';
import { Position } from '../../../shared/models/position';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';
import OnsiteCopyPageTypeFactory from '../../../factories/onsite-copy-page-type.factory';

@Component({
  selector: 'app-components-managecustomer-onsitecopycustomaction',
  templateUrl: './OnsiteCopyCustomAction.component.html'
})
export class OnsiteCopyCustomActionComponent {
  private onsiteCopyForm: UntypedFormGroup;
  Position = Position;
  dfd: any;

  canEdit: boolean = true;
  isNew: boolean = true;
  isCmsBlackListed: boolean;
  isCmsNotSet: boolean;
  workRequestDetail: Boo.Objects.Work.SeoMinute.WorkRequestDetail;
  selectedWebsiteUrl: Boo.Objects.WebsiteUrl;
  hasValidAccessDetails: boolean = false;
  implementationResponsibility: Boo.OnsiteRecommendations.Models.Enums.Responsibilities;
  boostToPublish: boolean;

  user: any;
  pageTypes: any = ko.observableArray(this.onsiteCopyPageTypeFactory.getPageTypes());
  validation: any = ko.validatedObservable();

  publishToExistingPage: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  linkBuildingAfterPublish: KnockoutObservable<boolean> = ko.observable();
  onsiteCopyPageType: KnockoutObservable<string> = ko.observable();
  implementationInstructions: KnockoutObservable<string> = ko.observable();

  get activeUrl(): Boo.Objects.WebsiteUrl {
    return this.selectedWebsiteUrl && this.selectedWebsiteUrl.WebsiteUrlId ? this.selectedWebsiteUrl : null;
  }

  get hasActiveKeyword(): boolean {
    return this.activeUrl && this.activeUrl.Keywords.length > 0;
  };

  constructor(
    private preAuditDetailService: PreAuditDetailService,
    private onsiteRecommendationDetailService: OnsiteRecommendationDetailService,
    private sessionStorageService: SessionStorageService,
    private onsiteCopyPageTypeFactory: OnsiteCopyPageTypeFactory) { }

  activate(options: any): any {
    if (options.controlViewModel) {
      options.controlViewModel(this);
    }

    this.dfd = options.parentWindowDfd;

    this.workRequestDetail = options.workRequestDetail;
    this.selectedWebsiteUrl = options.selectedWebsiteUrl;
    this.canEdit = options.canEdit();
    this.isNew = options.isNew;
    this.hasValidAccessDetails = options.hasValidAccessDetails;

    return $.when<any>(
        Utils.wrapDfd(this.sessionStorageService.getUser()),
        Utils.wrapDfd(this.preAuditDetailService.getByCustomerId(this.workRequestDetail.WorkRequest.CustomerId)),
        Utils.wrapDfd(this.sessionStorageService.getStaticData()))
      .then((loggedInUser: any, preAudit: Boo.Objects.PreAuditDetail, staticData: Boo.Objects.LaunchPadStaticData) => {
        this.isCmsBlackListed = preAudit && preAudit.ContentManagementSystemId ? staticData.ContentManagementSystems.find((i: { ContentManagementSystemId: any; }) => i.ContentManagementSystemId == preAudit.ContentManagementSystemId).IsBlacklisted : false;
        this.isCmsNotSet = !preAudit || (preAudit && !preAudit.ContentManagementSystemId);

        this.user = loggedInUser;
        return Utils.wrapDfd(this.onsiteRecommendationDetailService.getByCustomerId(this.workRequestDetail.WorkRequest.CustomerId));
      }).then((osrDetails: Boo.Objects.OnsiteRecommendationDetail) => {
        if (osrDetails) {
          this.implementationResponsibility = this.isNew ? osrDetails.Responsibility : WorkRequestHelper.getImplementationResponsibility(this.workRequestDetail.WorkRequest);
          this.boostToPublish = this.implementationResponsibility === Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Boostability;
          this.implementationInstructions(osrDetails.Note);
        }
        this.completeActivation();
      }).fail((displayMessage) => {
        toastr.error(displayMessage);
      });
  }

  selectedUrlChanged(newUrl: Boo.Objects.WebsiteUrl): void {
    this.selectedWebsiteUrl = newUrl;
    this.linkBuildingAfterPublish(this.selectedWebsiteUrl && this.selectedWebsiteUrl.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active ? true : undefined);
  }

  private completeActivation(): void {
    this.onsiteCopyForm = new UntypedFormGroup({
      publishToExistingPage: this.publishToExistingPage
    });

    if (!this.canEdit) {
      this.onsiteCopyForm.disable();
    }
    
    this.implementationInstructions.extend({
      required: {
        onlyIf: (): boolean => {
          return this.boostToPublish;
        },
        maxLength: 4000,
        message: 'Implementation instructions are required because Boostability will publish copy.'
      }
    })

    this.onsiteCopyPageType.extend({ required: true, maxLength: 4000 });
    this.validation = ko.validatedObservable({
      LinkBuild: this.linkBuildingAfterPublish,
      PageType: this.onsiteCopyPageType,
      ImplementationInstructions: this.implementationInstructions
    });

    this.selectedUrlChanged(this.selectedWebsiteUrl);

    if (this.workRequestDetail && this.workRequestDetail.WorkRequest.KeyValuePairs && !this.isNew) {
      this.publishToExistingPage.setValue(WorkRequestHelper.getOnsiteCopyOrderReason(this.workRequestDetail.WorkRequest).indexOf('Existing Page') !== -1);
      this.linkBuildingAfterPublish(WorkRequestHelper.getOnsiteCopyLinkBuildAfterPublish(this.workRequestDetail.WorkRequest));
      this.onsiteCopyPageType(WorkRequestHelper.getOnsiteCopyPageType(this.workRequestDetail.WorkRequest));
      this.implementationInstructions(WorkRequestHelper.getOnsiteCopyImplementationInstructions(this.workRequestDetail.WorkRequest));
      this.implementationResponsibility = WorkRequestHelper.getImplementationResponsibility(this.workRequestDetail.WorkRequest);
      this.boostToPublish = this.implementationResponsibility === Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Boostability;
    }

    this.validation.errors.showAllMessages(false);
  }

  canSave(): any {
    Utils.showAllMessages(this.onsiteCopyForm);
    let result = this.validation.isValid() && this.onsiteCopyForm.valid;

    if (!this.hasActiveKeyword) {
      toastr.error('Cannot write copy until a keyword is defined for this URL.');
      result = false;
    }

    if (!this.hasValidAccessDetails && this.boostToPublish) {
      toastr.error('If Boostability is going to publish copy, you cannot save this task until CMS or FTP details have been completed.');
      result = false;
    }

    if(this.boostToPublish && (this.isCmsBlackListed || this.isCmsNotSet)) {
      toastr.error('Unsupported CMS. Boostability is publishing changes for this site so a supported CMS is required. Please adjust the customer Publish Options & Access settings before allocating work.');
      result = false;
    }

    if (!result && this.validation && this.validation.errors) {
      this.validation.errors.showAllMessages();
    }
    return result;
  }

  save(workRequest: Boo.Objects.Work.WorkRequest): any {
    WorkRequestHelper.setOnsiteCopyOrderReason(workRequest, this.publishToExistingPage.value ? 'Existing Page' : 'New Page', this.user.UserId);
    WorkRequestHelper.setOnsiteCopyLinkBuildAfterPublish(workRequest, this.linkBuildingAfterPublish(), this.user.UserId);
    WorkRequestHelper.setOnsiteCopyPageType(workRequest, this.onsiteCopyPageType(), this.user.UserId);
    WorkRequestHelper.setOnsiteCopyImplementationInstructions(workRequest, this.implementationInstructions(), this.user.UserId);
    WorkRequestHelper.setImplementationResponsibility(workRequest, this.implementationResponsibility, this.user.UserId);
  }

  publishResponsibilityMapToString(responsibility: Boo.OnsiteRecommendations.Models.Enums.Responsibilities): string {
    switch(responsibility) {
      case Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Boostability:
        return "Boostability";
      case Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Partner:
        return "Partner";
      case Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Customer:
        return "Customer";
      default:
        return "No one";
    }
  }
}

