import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TaskFileService {
    private baseUrl: string = `${environment.urlConfig.serviceUrl}/taskfiles`;

    constructor(private http: HttpClient) { }

    public insertUpdate(taskFile: Boo.Objects.TaskFile): Observable<Boo.Objects.TaskFile> {
      return this.http.post<Boo.Objects.TaskFile>(`${this.baseUrl}/InsertUpdate`, taskFile);
    }

    public selectByTaskId(taskId: number): Observable<Boo.Objects.TaskFile[]> {
      return this.http.get<Boo.Objects.TaskFile[]>(`${this.baseUrl}/GetByTaskId`, { params: new HttpParams().set('taskId', taskId) });
    }

    public selectByTaskIds(taskIds: number[]): Observable<Boo.Objects.TaskFile[]> {
      return this.http.post<Boo.Objects.TaskFile[]>(`${this.baseUrl}/GetByTaskIds`, Array.from(taskIds));
    }
}
