import { Component } from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { TabBase } from '../tab-base';
/**
 * @Component LazyTabComponent
 * @Remarks Extended TabComponent that will initialize the tab content when selected. Requires *tabContent directive to be present on the content.
 */
@Component({
  selector: 'app-lazy-tab',
  templateUrl: './lazy-tab.component.html',
  providers: [{provide: TabBase, useExisting: LazyTabComponent}]
})
export class LazyTabComponent extends TabComponent {
  loaded: boolean = false;

  constructor() { 
    super();
  }

  selected(): void {
    this.loaded = true;
  }
}
