import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { QaGradingArea } from '../../../../qa/qa-grading-area/qa-grading-area.component';
import { UpaChecklistService } from '../../../../../services/upa-checklist.service';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';
import { Subject } from 'rxjs';
import { InternalActionToken } from '../../internal-action.token';
import QaCriteriaGroup = Boo.Qa.QaCriteriaGroup;
import TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum;

@Component({
  selector: 'app-components-specialist-internal-tasks-proactive-underperforming-keyword-qa',
  templateUrl: './proactive-underperforming-keyword-qa.component.html'
})
export class ProactiveUnderperformingKeywordQaComponent implements OnInit, CompletableWorkspace, UnlockableWorkspace, ClosableWorkspace {
  showCompleteButton: boolean = false;
  showUnlockButton: boolean = true;
  showCloseButton: boolean = true;
  complete = new Subject<void>();
  unlock = new Subject<void>();
  close = new Subject<void>();

  @ViewChild('gradingArea')
  gradingArea: QaGradingArea;
  taskType: TaskTypeEnum = TaskTypeEnum.InternalTaskQaProactiveUnderperformingKeyword;
  qaCriteriaGroups: QaCriteriaGroup[] = [];

  partner: string;
  customer: Boo.Objects.Customer;
  taskId: number;
  noteMaxCharacters = 500;
  isMoneyBack: boolean;
  upaChecklists: Boo.Objects.Checklist.UpaChecklist[];
  customerCampaignRestrictions: Boo.Objects.Enums.CustomerCampaignRestrictions[] = [
    Boo.Objects.Enums.CustomerCampaignRestrictions.ReadOnly,
    Boo.Objects.Enums.CustomerCampaignRestrictions.HideHaloTab,
    Boo.Objects.Enums.CustomerCampaignRestrictions.HideArchivedTab,
    Boo.Objects.Enums.CustomerCampaignRestrictions.DisableTaskActions
  ];

  constructor(
    @Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction,
    private upaChecklistService: UpaChecklistService) { }

  ngOnInit(): void {
    //Create the criteria float panel
    (<any>$('#internal-task-qa-criteria-float-panel')).floatPanel({ collapsed: false });

    this.qaCriteriaGroups = this.internalAction.InternalTaskDetails.QaCriteriaGroups;

    this.partner = this.internalAction.InternalTaskDetails.PartnerName;
    this.customer = this.internalAction.InternalTaskDetails.AssociatedTaskCustomer;
    this.taskId = this.internalAction.InternalTaskDetails.AssociatedTask.TaskId;
    this.isMoneyBack = this.internalAction.InternalTaskDetails.AssociatedTask.TaskTypeEnum ===
      TaskTypeEnum.ProactiveUnderPerformingKeywordMoneyBack;

    this.upaChecklistService.get(this.taskId)
      .subscribe(checklists => this.upaChecklists = checklists);
  }

  isReadyToComplete() {
    if (!this.gradingArea.validate()) {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return false;
    }

    this.internalAction.InternalTaskDetails.QaCriteriaDetails = this.gradingArea.getDetails();
    this.internalAction.InternalTaskDetails.QaGrade.Comment = this.gradingArea.gradeComment;
    return true;
  }
}
