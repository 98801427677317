
<div class="row">
  <div class="col-md-12">
    <app-components-manager-task-type-selection [isReadOnly]="isReadOnly" [taskTypes]="taskTypes" (addTaskType)="addDefaultItem($event)"></app-components-manager-task-type-selection>
  </div>
</div>

<table class="table table-bordered" id="default-table">
  <thead>
    <tr>
      <th>Task Name</th>
      <th class="num-col">Minutes Per Task</th>
      <th class="num-col">Qty</th>
      <th class="num-col">Total Minutes</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let $default of defaults">
      <tr *ngIf="$default.TaskCount">
        <td>
          <span [ngbPopover]="$default.TaskType.Description" popoverTitle="Description" placement="left auto" triggers="mouseenter:mouseleave">
            <i class="fa fa-info-circle"></i>
          </span>
          <span>{{ $default.TaskType.Name }}</span>
        </td>
        <td class="text-center">{{ $default.TaskType.BillableMinutes }}</td>
        <td class="text-center">
          <ng-select [multiple]="false" [closeOnSelect]="true" [clearable]="false" placeholder="Qty" class="qty-select" [disabled]="isReadOnly"
            [(ngModel)]="$default.TaskCount">
            <ng-option *ngFor="let qty of qtyNumbers" [value]="qty"
               class="text-center">
              {{qty}}
            </ng-option>
          </ng-select>
        </td>
        <td class="text-center">{{ ($default.TaskType.BillableMinutes * $default.TaskCount) }}</td>
      </tr>
    </ng-container>
    <tr class="whitesmoke">
      <td class="text-end" colspan="3">
        <div class="mb-0">
          Minutes to Reserve
        </div>
      </td>
      <td class="text-center">
        <input [(ngModel)]="partnerSeoMinuteReservationDefault.Quantity" [ngModelOptions]="{ standalone: true }" type="number" class="form-control text-center"/>
      </td>
    </tr>
    <tr class="whitesmoke">
      <td class="text-end" colspan="3">
        <div *ngIf="!isAllocationValid" class="alert alert-danger mb-0">
          Invalid Minutes: {{ minuteDifferenceMessage }}
          <i class="fas fa-arrow-right"></i>
        </div>
      </td>
      <td class="text-center ">
        <span [ngClass]="{'text-danger': !isAllocationValid}">{{ allocatedMinutes }}</span>/{{ minutes }}
      </td>
    </tr>
  </tbody>
</table>

<div class="row mb-2 mt-2 text-end">
  <div class="col-md-12">
    <button *ngIf="!isReadOnly" class="btn btn-danger"(click)="deleteAllocation()" >
      <i class="fas fa-trash-alt"></i> Delete Allocation
    </button>
  </div>
</div>
