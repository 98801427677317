<div>
  <h5>{{ customerAccountTypeName }}</h5>
  <ng-container *ngIf="!customerAccountContainsData">
    None
  </ng-container>
  <ng-container *ngIf="customerAccountContainsData">
    <div class="row">
      <ng-container *ngIf="customerAccount.Url">
      <div class="col-md-2">Login Url</div>
      <div class="col-md-10">
        <ng-container *ngIf="customerAccount.Port">
          <span (click)="window.launchpad.utils.selectText(undefined, $event)">{{customerAccount.Url}}</span>
        </ng-container>
        <ng-container *ngIf="!customerAccount.Port">
          <a rel="noreferrer" target="_blank" href="{{loginHref}}" style="cursor: pointer; white-space: nowrap;">
            <i class="fas fa-external-link-square-alt"></i><span class="externalLink">{{(customerAccount.Url.length > 45) ? (customerAccount.Url | slice:0:45)+'...' : customerAccount.Url}}</span>
          </a>
        </ng-container>
      </div>
    </ng-container>
    </div>
    <ng-container *ngIf="customerAccount.Port">
      <div class="row">
        <div class="col-md-2">Port</div>
        <div class="col-md-10"><span
            (click)="window.launchpad.utils.selectText(undefined, $event)">{{customerAccount.Port}}</span>
        </div>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-md-2">Username</div>
      <div class="col-md-10"><span
          (click)="window.launchpad.utils.selectText(undefined, $event)">{{customerAccount.Username}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">Password</div>
      <div class="col-md-10">
        <ng-container *ngIf="customerAccount.Password">
          <span (click)="window.launchpad.utils.selectText(undefined, $event)">{{customerAccount.Password}}</span>
          <button class="btn btn-sm btn-danger" (click)="removePassword()">
            <i class="fas fa-times" style="margin-right: 0px;"></i>
            Hide
          </button>
        </ng-container>
        <ng-container *ngIf="!customerAccount.Password">
          <button class="btn btn-sm btn-info" (click)="getPassword()">
            <i class="fas fa-copy" style="margin-right: 0px;"></i>
            Show
          </button>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="customerAccount.Note">
        <div class="col-md-2">Notes</div>
        <div class="col-md-10">
          <textarea [disabled]="true" [readonly]="true" class="form-control-static col-md-12" rows="3">{{customerAccount.Note}}</textarea>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>