<div>
	<ng-container *ngFor="let panel of panels()">
		<div [hidden]="!(!panel.showPanel || panel.showPanel())">
			<div [loadingOverlay]="isLoading()" [attr.id]="panel.panelId" (mousedown)="bringToFront(panel)" class="floating-panel" [@wobbleIf]="{value:panel.wobbleIf ? panel.wobbleIf() : undefined, params: {timing: 1, delay: 1}}">
				<div class="floating-panel-tab">
					<button class="floating-panel-btn-expand btn btn-lg btn-primary" [class.icon-with-badge]="panel.titleBadge() > 0">
						<ng-container *ngIf="(panel.titleBadge() || 0) === 0">
							<i [class]="panel.titleIcon" class="fa fa-fw"></i>
						</ng-container>
						<ng-container *ngIf="panel.titleBadge() > 0">
							<i [class]="panel.titleIcon" class="fa fa-fw" style="margin: 0 -9px 0 00px"></i>
							<span [class]="panel.titleBadgeClass" class="badge rounded-pill" style="font-size: 60%;">{{panel.titleBadge()}}</span>
						</ng-container>
					</button>
				</div>
				<div class="floating-panel-main card float-end" style="width:100%">
					<div class="floating-panel-draggable card-header">
						<ng-container *ngIf="(panel.titleBadge() || 0) === 0">
							<i [class]="panel.titleIcon" class="fa fa-fw"></i>
						</ng-container>
						<ng-container *ngIf="panel.titleBadge() > 0">
							<i [class]="panel.titleIcon" class="fa fa-fw" style="margin: 0 -9px 0 3px; font-size: 135%;"></i>
							<span [class]="panel.titleBadgeClass" class="badge rounded-pill" style="font-size: 70%;">{{panel.titleBadge()}}</span>
						</ng-container>
						<span>{{panel.title}}</span>
						<i class="floating-panel-btn-collapse fa fa-minus float-end"></i>
					</div>
					<div [class]="panel.isScrollable !== false ? 'card-body card-body-scroll' : ''">
						<compose [Selector]="panel.composition.selector" [ActivateOptions]="panel.composition.options" [Model]="panel.composition.model" [Callback]="panel.composition.callback?.bind(this)" [FailureCallback]="panel.composition.failureCallback?.bind(this)"></compose>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>
