import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LocalProfileSpecialHourFactory {

  constructor(private formBuilder: UntypedFormBuilder) { }

  create(localProfileId: number): Boo.Objects.LocalProfiles.LocalProfileSpecialHour {
    return {
      Date: '',
      Description: '',
      From1: '',
      From2: '',
      IsClosed: true,
      IsOpen24Hours: false,
      LocalProfileSpecialHourId: 0,
      LocalProfileId: localProfileId,
      To1: '',
      To2: ''
    };
  }

  createNewFormGroup(localProfileId: number): UntypedFormGroup {
    return this.createFormGroup(this.create(localProfileId));
  }

  createFormArray(specialHours: Boo.Objects.LocalProfiles.LocalProfileSpecialHour[]): UntypedFormArray {
    return this.formBuilder.array(specialHours.flatMap(x => this.createFormGroup(x)));
  }

  createFormGroup(specialHour: Boo.Objects.LocalProfiles.LocalProfileSpecialHour): UntypedFormGroup {
    return this.formBuilder.group({
      date: [specialHour.Date, null],
      description: [specialHour.Description, null],
      from1: [specialHour.From1, null],
      from2: [specialHour.From2, null],
      isClosed: [specialHour.IsClosed, null],
      isOpen24Hours: [specialHour.IsOpen24Hours, null],
      localProfileId: [specialHour.LocalProfileId, null],
      localProfileSpecialHourId: [specialHour.LocalProfileSpecialHourId, null],
      to1: [specialHour.To1, null],
      to2: [specialHour.To2, null]
    });
  }
}
