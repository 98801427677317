<ng-container *ngFor="let url of websiteUrls">
  <div class="mb-5">
    <div class="row">
      <div class="col-md-6 offset-md-1">
        <input class="form-control" [disabled]="true" [value]="url.Url">
      </div>
    </div>
    <ng-container *ngIf="url.Keywords.Length !== 0">
      <div class="row">
        <div class="col-md-11 offset-md-1">
          <ul class="list-group mt-3">
            <ng-container *ngFor="let keyword of url.Keywords">
              <li class="list-group-item">
                {{keyword.KeywordPhrase}}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>