<div [loadingOverlay]="isLoading()">
	<div *ngIf="showSiteInfo && website">
		<ul class="list-group">
			<li class="list-group-item">
				<strong>Account Id</strong>
				<span class="float-end">{{website.WebsiteAccount.AccountId()}}</span>
			</li>
			<li class="list-group-item">
				<strong>Site Id</strong>
				<span class="float-end">{{website.SiteId()}}</span>
			</li>
		</ul>
	</div>
	<ng-container *ngIf="!hideSsoUrl">
		<label class="mt-3">
			Admin Single Sign-On URL
			<span class="small text-danger">(Do not share with customer)</span>
		</label>
		<div class="input-group">
			<input [ngModel]="ssoUrlForAdmin()" (ngModelChange)="ssoUrlForAdmin($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" disabled="" placeholder="Click Load or Sign-On..." class="disabled form-control"/>
			<button (click)="loadSsoUrlForAdmin()" class="btn btn-danger">
				<i class="fas fa-sync"></i>
				Load
			</button>
			<button (click)="signOnWithSsoUrlForAdmin()" class="btn btn-danger">
				<i class="fas fa-external-link-square-alt"></i>
				Sign-On
			</button>
		</div>
	</ng-container>
</div>
