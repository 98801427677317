import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { CsrsComponent } from './Csrs/Csrs.component';
import { ManagerToolsComponent } from './ManagerTools/ManagerTools.component';
import { AssignedTicketsComponent } from './AssignedTickets/AssignedTickets.component';
import { AssignTicketTypesComponent } from './AssignTicketTypes/AssignTicketTypes.component';
import { CreateTicketBatchComponent } from './CreateTicketBatch/CreateTicketBatch.component';
import { CustomerPriorityAssignmentComponent } from './CustomerPriorityAssignment/CustomerPriorityAssignment.component';
import { CustomerPriorityAssignmentsComponent } from './CustomerPriorityAssignments/CustomerPriorityAssignments.component';
import { CustomerSearchComponent } from './CustomerSearch/CustomerSearch.component';
import { EditTicketComponent } from './EditTicket/EditTicket.component';
import { HelpNextCustomerComponent } from './HelpNextCustomer/HelpNextCustomer.component';
import { ManageCustomerComponent as ManageCustomerComponent1 } from './ManageCustomer/ManageCustomer.component';
import { ManageTicketsComponent } from './ManageTickets/ManageTickets.component';
import { PerformanceMetricsComponent } from './PerformanceMetrics/PerformanceMetrics.component';

@NgModule({
  declarations: [
    CsrsComponent,
    ManagerToolsComponent,
    AssignedTicketsComponent,
    AssignTicketTypesComponent,
    CreateTicketBatchComponent,
    CustomerPriorityAssignmentComponent,
    CustomerPriorityAssignmentsComponent,
    CustomerSearchComponent,
    EditTicketComponent,
    HelpNextCustomerComponent,
    ManageCustomerComponent1,
    ManageTicketsComponent,
    PerformanceMetricsComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class CustomerServiceModule { }
