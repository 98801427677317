import { Component } from '@angular/core';
import BaseSeoContentSurveyTab from '../BaseSeoContentSurveyTab';
import { SeoContentSurveyService } from '../../../../services/seo-content-survey.service';
import Utils from '../../../../shared/utils';
import { CustomerService } from '../../../../services/customer.service';
import { forkJoin } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-components-managecustomer-seocontentsurvey-bloggingdetails',
  templateUrl: './BloggingDetails.component.html',
	styleUrls: ['./BloggingDetails.scss']
})
export class BloggingDetailsComponent extends BaseSeoContentSurveyTab implements IActivatable {
  bloggingDetailsUpdateReason: KnockoutObservable<string>;
  onsiteBloggingDetailsValidation: KnockoutObservable<any>;
  customer: CustomerObservable;
  considerationsFormControl: UntypedFormControl;
  partner: Boo.Objects.Partner;

  constructor(public seoContentSurveyService: SeoContentSurveyService,
    public customerService: CustomerService) {
    super(seoContentSurveyService);
  }

  activate(params: any): JQueryPromise<void> {
    return super.activate(params)
      .then(() => {
        this.customer = params.customer;
        this.bloggingDetailsUpdateReason = ko.observable('');
        this.partner = params.partner;

        this.considerationsFormControl = new UntypedFormControl({ 
            value: this.customer.Considerations(), 
            disabled: !(this.partner.PartnerId === 1 || this.partner.PartnerId === 152)
          }, 
          Validators.maxLength(512)
        );

        this.prepareValidation();
      });
  }

  saveOnsiteBloggingDetails(): void {
    if (this.validate()) {
      this.customer.Considerations(this.considerationsFormControl.value);
      forkJoin([
        this.save(this.bloggingDetailsUpdateReason(), 'onsite blogging details', 'Blogging Details saved.'), 
        this.customerService.saveConsiderations(this.customer.CustomerId(), this.customer.Considerations())
      ]).subscribe(() => {
          this.bloggingDetailsUpdateReason('');
          this.onsiteBloggingDetailsValidation.errors.showAllMessages(false);
        });
    } else {
      toastr.error('Please correct the errors in the onsite blogging details section.');
      this.onsiteBloggingDetailsValidation.errors.showAllMessages();
    }
  }

  checkForBlog(): void {
    this.isLoading(true);
    Utils.wrapDfd(this.seoContentSurveyService.getAvailableBlogPath(this.customerId))
      .then((blogUrl: string) => {
        if (blogUrl.trim() !== '') {
          toastr.success('Blog was found!<br/><br/>Blog Url: <a target="_blank" rel="noreferrer" href="' + blogUrl + '">' + blogUrl + '</a>');
        } else {
          toastr.error('Blog was not found for ' + this.customer.Url());
        }
      }).fail((displayMessage: string) => {
        toastr.error(displayMessage);
      }).always(() => {
        this.isLoading(false);
      });
  }

  private prepareValidation(): void {
    this.bloggingDetailsUpdateReason.extend({ required: { message: 'Reason for update is required.' } });
    this.seoContentSurvey().PublishUser.extend({ maxLength: 100 });
    this.onsiteBloggingDetailsValidation = ko.validatedObservable([
      this.seoContentSurvey().PublishUser,
      this.bloggingDetailsUpdateReason
    ]);
  }

  private validate(): boolean {
    return this.onsiteBloggingDetailsValidation.isValid() && (this.considerationsFormControl.valid || this.considerationsFormControl.disabled);
  }
}
