import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NgZone } from '@angular/core';
import { Routes, RouterModule, UrlSerializer } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './main.component';
import { ViewLocatorService } from './services/view-locator.service';
import { EmptyComponent } from './components/shared/empty/empty.component';
import { CustomUrlSerializer } from './services/CustomUrlSerializer.service';
import { HttpInterceptorService } from './core/http-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { AdminModule } from './components/admin/admin.module';
import { CustomerServiceModule } from './components/customerservice/customer-service.module';
import { ComponentsModule } from './components/components.module';
import { GuaranteesModule } from './components/guarantees/guarantees.module';
import { ManagerModule } from './components/manager/manager.module';
import { ManageCustomerModule } from './components/managecustomer/manage-customer.module';
import { NavigationModule } from './components/navigation/navigation.module';
import { TicketModule } from './components/ticket/ticket.module';
import { TodoModule } from './components/todo/todo.module';
import { SharedComponentsModule } from './components/shared/shared-components.module';
import { SpecialistModule } from './components/specialist/specialist.module';
import { WebsitesModule } from './components/websites/websites.module';
import { SalesModule } from './components/sales/sales.module';
import { StatsModule } from './components/stats/stats.module';
import { SystemModule } from './components/system/system.module';
import { InternalTasksModule } from './components/specialist/internal-tasks/internal-tasks.module';
import { HotjarService, hotjarServiceFactory } from './services/hotjar.service';

const routes: Routes = [
  { path: '**', component: EmptyComponent }
];

@NgModule({
    declarations: [MainComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        AdminModule,
        CustomerServiceModule,
        ComponentsModule,
        GuaranteesModule,
        ManagerModule,
        ManageCustomerModule,
        NavigationModule,
        TicketModule,
        TodoModule,
        InternalTasksModule,
        SharedComponentsModule,
        SpecialistModule,
        WebsitesModule,
        SalesModule,
        StatsModule,
        SystemModule,
        RouterModule.forRoot(routes, { useHash: true })
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: UrlSerializer,
            useClass: CustomUrlSerializer
        },
        {
            provide: HotjarService,
            useFactory: hotjarServiceFactory,
            deps: [NgZone]
        },
        ViewLocatorService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [MainComponent]
})
export class AppModule { }
