/* tslint:disable */

import { Component } from '@angular/core';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-managecustomer-editmerchantfields',
  templateUrl: './EditMerchantFields.component.html'
})
export class EditMerchantFieldsComponent {
  public dfd: any;
  public title = 'Partner - Edit Merchant Fields';
  public customer: any = ko.observable();
  public displayMerchantSitePublishDate: any = ko.observable();
  public partnerGroups: any = ko.observableArray([]);
  // Customer Merchant Field Labels
  public MerchantIdLabel: any = ko.observable('Merchant Id');
  public MerchantSourceLabel: any = ko.observable('Merchant Source');
  public MerchantProposalIdLabel: any = ko.observable('Merchant Proposal Id');
  public MerchantCategoryLabel: any = ko.observable('Merchant Category');
  public MerchantCategoryIdLabel: any = ko.observable('Merchant Category Id');
  public MerchantSubCategoryLabel: any = ko.observable('Merchant Subcategory');
  public MerchantSubCategoryIdLabel: any = ko.observable('Merchant Subcategory Id');
  public MerchantConsultantLabel: any = ko.observable('Merchant Consultant');
  public MerchantConsultantContactLabel: any = ko.observable('Merchant Consultant Email');
  public MerchantUdacLabel: any = ko.observable('Merchant UDAC');
  public MerchantProductCodeLabel: any = ko.observable('Product Code');
  public MerchantItemIdLabel: any = ko.observable('Merchant Item Id');
  public MerchantMiscIdLabel: any = ko.observable('Merchant Misc Id');
  public MerchantSitePublishDateLabel: any = ko.observable('Merchant Site Publish Date');
  public MerchantRegionLabel: any = ko.observable('Merchant Region');
  public MerchantCustomerValueLabel: any = ko.observable('Merchant Customer Value');
  public MerchantContractEndDateLabel: any = ko.observable('Merchant Contract End Date');
  public MerchantServiceLevelLabel: any = ko.observable('Merchant Service Level');
  public MerchantCampaignManagerLabel: any = ko.observable('Merchant Campaign Manager');
  public MerchantCampaignManagerContactLabel: any = ko.observable('Merchant Campaign Manager Contact');

  constructor(private sessionStorageService: SessionStorageService) { }

  public saveView(): void {
    if (this.displayMerchantSitePublishDate()) {
      this.customer().MerchantFields.MerchantSitePublishDate(this.displayMerchantSitePublishDate());
    }
    if (this.dfd) {
      this.dfd.resolve({
        'customer': this.customer()
      });
    }
  }

  public closeView(): void {
    if (this.dfd) {
      this.dfd.reject();
    }
  }

  public activate(options: any): void {
    if (options.customer) {
      this.customer(options.customer);

      $.when(Utils.wrapDfd(this.sessionStorageService.getStaticData()))
      .done((staticData) => {
        this.partnerGroups(staticData.PartnerGroups);
        this.displayMerchantSitePublishDate(this.isValidDate(options.customer.MerchantFields.MerchantSitePublishDate())
          ? window.launchpad.utils.getMomentAsUTC(options.customer.MerchantFields.MerchantSitePublishDate())
          : null);
      });
    }
    this.fixPartnerInformationLabels();
  }
  // Changes to merchant labels should also be added to customerinformation.js and MerchantFieldsService.ts

  public fixPartnerInformationLabels(): void {
    if (this.partnerInPartnerGroup(window.launchpad.config.PartnerGroupEnum.Thryv)) {
      this.MerchantIdLabel('Thryv Customer Id');
      this.MerchantProductCodeLabel('Product Code');
      this.MerchantCategoryIdLabel('Company Id');
      this.MerchantItemIdLabel('Item Id');
      this.MerchantCategoryLabel('Listing Id');
      this.MerchantSubCategoryLabel('Category');
      this.MerchantSubCategoryIdLabel('Enterprise Account Id');
      this.MerchantConsultantLabel('Marketing Consultant');
      this.MerchantSitePublishDateLabel('Site Publish Date');
      this.MerchantUdacLabel('UDAC');
      this.MerchantSourceLabel('Source (Local/National)');
      this.MerchantProposalIdLabel('Merchant ID');
      this.MerchantMiscIdLabel('SEO Proposal ID');
      this.MerchantConsultantContactLabel('Marketing Consultant Email');
      this.MerchantRegionLabel('Sales Region');
      this.MerchantCustomerValueLabel('Total Contract Value');
      this.MerchantContractEndDateLabel('Contract End Date');
      this.MerchantServiceLevelLabel('Client Service Level');
      this.MerchantCampaignManagerLabel('Campaign Manager');
      this.MerchantCampaignManagerContactLabel('Campaign Manager Email');
    } else if (this.partnerInPartnerGroup(window.launchpad.config.PartnerGroupEnum.Yellow)) {
      this.MerchantIdLabel('Advertiser ID:');
      this.MerchantSourceLabel('BC Product ID:');
      this.MerchantProposalIdLabel('MSA Name:');
      this.MerchantCategoryLabel('MISC:');
      this.MerchantConsultantContactLabel('MSA/MC Email:');
      this.MerchantCategoryIdLabel('MSA Phone:');
      this.MerchantSubCategoryLabel('Heading');
      this.MerchantSubCategoryIdLabel('SiteSmart(Y/N):');
      this.MerchantConsultantLabel('SiteSmart Customisation (Y/N):');
      this.MerchantUdacLabel('Need Click Manager PPC Campaign (Y/N):');
      this.MerchantProductCodeLabel('Product Code:');
      this.MerchantItemIdLabel('Web Specialist:');
      this.MerchantMiscIdLabel('Web Specialist Email Address:');
    } else if (this.customer().PartnerId() === window.launchpad.config.partners.foundDigital) {
      this.MerchantIdLabel('Advertiser ID:');
      this.MerchantSourceLabel('BC Product ID:');
      this.MerchantProposalIdLabel('MSA Name:');
      this.MerchantCategoryLabel('MSA Email:');
      this.MerchantCategoryIdLabel('MSA Phone:');
      this.MerchantSubCategoryLabel('Heading');
      this.MerchantSubCategoryIdLabel('SiteSmart(Y/N):');
      this.MerchantConsultantLabel('SiteSmart Customisation (Y/N):');
      this.MerchantUdacLabel('Need Click Manager PPC Campaign (Y/N):');
      this.MerchantProductCodeLabel('Product Code:');
      this.MerchantItemIdLabel('Web Specialist:');
      this.MerchantMiscIdLabel('Web Specialist Email Address:');
    } else if (this.partnerInPartnerGroup(window.launchpad.config.PartnerGroupEnum.GoDaddy)) {
      this.MerchantCustomerValueLabel('GoDaddy ID');
      this.MerchantSourceLabel('Plan Name');
      this.MerchantProductCodeLabel('Term Length');
      this.MerchantContractEndDateLabel('Pre-Pay Plan Expiration');
      this.MerchantCategoryLabel('NPS Score');
      this.MerchantConsultantLabel('NPS Score Updated');
      this.MerchantProposalIdLabel('Auto-Renew Status');
    } else if (this.customer().PartnerId() === window.launchpad.config.partners.Herold) {
      this.MerchantConsultantContactLabel('Customer Email for Reports');
    } else if (this.customer().PartnerId() === window.launchpad.config.partners.heiseRegioConcept) {
      this.MerchantConsultantContactLabel('Customer Email for Reports');
    } else if (this.customer().PartnerId() === window.launchpad.config.partners.heiseRegioConceptÖsterreich) {
      this.MerchantConsultantContactLabel('Customer Email for Reports');
    } else if (this.customer().PartnerId() === window.launchpad.config.partners.heiseRegioConceptSchweiz) {
      this.MerchantConsultantContactLabel('Customer Email for Reports');
    }
  }

  public isValidDate(item: any): any {
    return !window.launchpad.utils.isDefaultDate(ko.utils.unwrapObservable(item));
  }

  public partnerInPartnerGroup(partnerGroup: any): any {
    return window.launchpad.utils.partnerInPartnerGroup(this.customer().PartnerId(), partnerGroup, this.partnerGroups());
  }
}
