import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ReportingSiteService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/reportingSites`;

  constructor(private http: HttpClient) { }

  get(partnerId: number): Observable<Boo.Objects.ReportingSite> {
    const params = new HttpParams().set('partnerId', partnerId);

    return this.http.get<Boo.Objects.ReportingSite>(this.baseUrl, { params: params });
  }

  save(reportingSite: Boo.Objects.ReportingSite): Observable<Boo.Objects.ReportingSite> {
    return this.http.post<Boo.Objects.ReportingSite>(this.baseUrl, reportingSite);
  }
}
