import { Component, Input } from '@angular/core';
import { MustacheTag, MustacheArrayTag, MustacheImplicitArrayTag, MustacheBooleanTag, MustacheTagTypes } from '../../models/mustache';

@Component({
  selector: 'app-mustache-value-display',
  templateUrl: './mustache-value-display.component.html'
})
export class MustacheValueDisplayComponent {
  @Input() tags: MustacheTag[] = [];
  types = MustacheTagTypes;

  constructor() { }

  addSubkeys(node: MustacheArrayTag): void {
    if (!node.value) {
      node.value = [];
    }

    node.value.push(node.subKeys.map(subKey => { return { type: subKey.type, key: subKey.key, subKeys: subKey.subKeys, value: subKey.value }}));
  }

  addImplicitValue(node: MustacheImplicitArrayTag): void {
    if (!node.value) {
      node.value = [];
    }

    node.value.push({ value: '' });
  }

  markTrue(tag: MustacheBooleanTag): void {
    tag.value = true;
  }

  clearValue(tag: MustacheTag): void {
    tag.value = null;
  }
}
