import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskStewardshipService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/taskStewardships`;

  constructor(private http: HttpClient) { }

  public get(): Observable<Boo.TaskStewardship.Models.TaskStewardshipResponsibility[]> {
    return this.http.get<Boo.TaskStewardship.Models.TaskStewardshipResponsibility[]>(`${this.baseUrl}`);
  }

  public save(taskStewardshipResponsibilities: Boo.TaskStewardship.Models.TaskStewardshipResponsibility[]): Observable<any> { 
    return this.http.post(`${this.baseUrl}`, taskStewardshipResponsibilities);
  }

  public delete(taskStewardshipId: number): Observable<any> { 
    return this.http.post(`${this.baseUrl}/delete`, taskStewardshipId);
  }
}
