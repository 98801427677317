<div>
  <div class="row">
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <strong>Minutes</strong>
          <span class="float-end">{{taskMinutes| seoMinutes}}</span>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <strong>Boost Minutes</strong>
          <span class="float-end">{{boostMinutes| seoMinutes}}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="card">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Task Type</th>
          <th>Allocated Items</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let $data of seoWorkDefaults">
          <tr>
            <td>
              <strong>{{$data.TaskType.Name}}</strong>
            </td>
            <td>
              <span>{{$data.TaskCount}}</span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
