<div class="card">
    <div class="card-body" style="overflow-x: auto;">
        <app-tab-panel [displayMode]="tabPanelDisplayMode.horizontal">
            @if (showWorkflowTaskHistoryTab) {
                <app-tab [title]="'Workflow Task History'">
                    <app-components-managecustomer-workflowtaskhistory *tabContent [customer]="customer">
                    </app-components-managecustomer-workflowtaskhistory>
                </app-tab>
            }
            <app-tab [title]="'Completed Task History'">
                <app-components-managecustomer-completedtasks *tabContent [customer]="customer">
                </app-components-managecustomer-completedtasks>
            </app-tab>
            @if (canViewPendingWork) {
                <app-tab [title]="'View Pending Tasks'">
                    <app-components-managecustomer-pendingwork *tabContent [customerId]="customer.CustomerId">
                    </app-components-managecustomer-pendingwork>
                </app-tab>
            }
            @if (canCloseWorkflow) {
                <app-tab [title]="'Close Workflow'">
                    <app-components-managecustomer-closeworkflow *tabContent [customerId]="customer.CustomerId">
                    </app-components-managecustomer-closeworkflow>
                </app-tab>
            }
        </app-tab-panel>
    </div>
</div>