import { Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import IPriorityViewPublicApi = app.interfaces.IPriorityViewPublicApi;
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-components-specialist-internal-tasks-reject-modal',
    templateUrl: './reject-modal.component.html'
})
export class InternalActionRejectComponent {
    @Input() action: Boo.Objects.InternalAction;
    @Input() priorityViewApi: IPriorityViewPublicApi;
    title = 'Reject Internal Action';    

    comment = new UntypedFormControl('', [
        Validators.required
    ]);

    constructor(private modal: NgbActiveModal) {}

    cancel(): void {
        this.modal.dismiss();
    }

    reject(): void {
        if (!this.comment.valid) {
            this.comment.markAsTouched();
            return;
        }

        this.modal.close(this.comment.value);
    }
}
