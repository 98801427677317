import { Component, Input, OnInit } from '@angular/core';
import FloatingPanel = app.components.interfaces.FloatingPanel;
import IFloatingPanelChild = app.components.interfaces.IFloatingPanelChild;
import IFloatingTabPanelPublicApi = app.components.interfaces.IFloatingTabPanelPublicApi;
import FloatingTabPanelSize = app.components.enums.FloatingTabPanelSize;
import System from 'framework/System';
import ISave = app.interfaces.ISave;
import IValidate = app.interfaces.IValidate;
import ICancel = app.interfaces.ICancel;
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { wobbleIf } from '../../animations';

@Component({
    selector: 'app-components-floatingtabpanel',
    templateUrl: './floating-tab-panel.component.html',
    animations: [wobbleIf]
})
export class FloatingTabPanelComponent implements OnInit {
    @Input() options: FloatingTabPanelViewModelOptions;
    public ko = ko;
    public panels: KnockoutObservableArray<FloatingPanel>;
    public isLoading: KnockoutObservable<boolean>;

    private panelTopSeed: number = 215;
    private panelSpacing: number = 60;
    private zIndex: number = 100;
    private children: IFloatingPanelChild[] = [];

    public ngOnInit() {
        this.panels = ko.observableArray([]);
        this.panels.subscribe(() => {
            this.initializePanels();
        });
        this.isLoading = this.options.isLoading ? this.options.isLoading : ko.observable(false);

        let verticalPanelOffset = this.options.verticalPanelOffset || 0;
        this.panelTopSeed = this.panelTopSeed + verticalPanelOffset * this.panelSpacing;

        if (this.options.apiCallback) {
            this.options.apiCallback(this.getPublicApi());
        }
    }

    public addPanel(panel: FloatingPanel): void {
        panel.panelId = _.uniqueId('floatingPanel_');
        // We can't currently shift panels dynamically if they aren't shown.
        // To make this situation slightly better, we place all possibly unshown panels at top.

        if (!panel.showPanel) {
            panel.titleBadge = panel.titleBadge ?? ko.observable<number>();
            this.panels.push(panel);
        } else {
            this.panels.unshift(panel);
        }
    }

    public validatePanels(saveType: SaveTypes): JQueryPromise<IValidatedResult>[] {
        return this.children.map((x) => {
            return (x.api as IValidate).validate(saveType).then((result: IValidatedResult) => {
                if (!result.isValid) {
                    this.expandPanel(x.panelId);
                    this.bringPanelToFront(x.panelId);
                }
                return result;
            });
        });
    }

    public confirmSavePanels(saveType: SaveTypes): JQueryPromise<boolean>[] {
        return this.children.map(x => (x.api as ISave).confirmSave(saveType));
    }

    public savePanels(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void>[] {
        return this.children.map(x => (x.api as ISave).save(saveType));
    }

    public cancelPanels(): JQueryPromise<void>[] {
        return this.children.map(x => (x.api as ICancel).canCancel ? (x.api as ICancel).cancel() : System.emptyPromise());
    }

    public addChild(child: IFloatingPanelChild): void {
        // Default undefined apis
        if (!(child.api as ISave).save) {
            (child.api as ISave).confirmSave = () => System.resolvedPromise(true);
            (child.api as ISave).save = () => System.emptyPromise();
        }

        if (!(child.api as IValidate).validate) {
            (child.api as IValidate).isValid = () => true;
            (child.api as IValidate).validate = () =>
                System.resolvedPromise<IValidatedResult>({
                    isValid: true,
                    errorMessages: []
                });
        }

        if (!(child.api as ICancel).cancel) {
            (child.api as ICancel).cancel = () => System.emptyPromise();
        }

        this.children.push(child);
    }

    public initializePanels(): void {
        for (let index = 0; index < this.panels().length; ++index) {
            let panel = this.panels()[index];
            let $element = (<any>$('#' + panel.panelId));
            if ($element.data('floatPanelDefaultStyle')) {
                continue;
            }

            if ($element.length === 0) {
                setTimeout(() => this.initializePanels(), 0);
                return;
            }

            $element.floatingTabPanel({
                collapsed: true,
                collapseBtnSelector: '.floating-panel-btn-collapse',
                expandBtnSelector: '.floating-panel-btn-expand',
                defaultStyle: 'top: ' + (this.panelTopSeed + (index * this.panelSpacing)) + 'px; z-index: ' + (this.zIndex - index),
                handleSelector: '.floating-panel-draggable',
                sizeClass: panel.size || FloatingTabPanelSize.Small
            });
        }
    }

    public focusOnPanel(panel: FloatingPanel) {
        this.expandPanel(panel.panelId);
        this.bringPanelToFront(panel.panelId);
    }

    public bringToFront(panel: FloatingPanel): boolean {
        return this.bringPanelToFront(panel.panelId);
    }

    public expandPanel(panelId: string): void {
        $('#' + panelId).floatPanel('expand');
    }

    public setLoading(isLoading: boolean): void {
        if (isLoading) {
            this.panels().forEach(panel => {
                $('#' + panel.panelId).floatPanel('collapse');
            })
        }
        this.isLoading(isLoading);
    }

    protected getPublicApi(): IFloatingTabPanelPublicApi {
        return {
            addChild: (child: IFloatingPanelChild): void => this.addChild(child),
            addPanel: (panel: FloatingPanel): void => this.addPanel(panel),
            validatePanels: (saveType: SaveTypes): JQueryPromise<IValidatedResult>[] => this.validatePanels(saveType),
            confirmSavePanels: (saveType: SaveTypes): JQueryPromise<boolean>[] => this.confirmSavePanels(saveType),
            savePanels: (saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void>[] => this.savePanels(saveType),
            cancelPanels: (): JQueryPromise<void>[] => this.cancelPanels(),
            expandPanel: (panel: FloatingPanel): void => this.focusOnPanel(panel),
            setLoading: (isLoading: boolean): void => this.setLoading(isLoading)
        };
    }

    private bringPanelToFront(panelId: string): boolean {
        $('#' + panelId).css('z-index', this.zIndex);
        this.zIndex++;
        return true;
    }
}
export interface FloatingTabPanelViewModelOptions {
    panels: FloatingPanel[];
    verticalPanelOffset?: number;
    isLoading: KnockoutObservable<boolean>;
    apiCallback: (api: IFloatingTabPanelPublicApi) => void;
}
