import { Injectable, OnInit } from '@angular/core';
import CustomerAccountTypes = Boo.Objects.Enums.CustomerAccountTypes;
import permissions from 'app/models/Permissions';
import { SessionStorageService } from './session-storage.service';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerAccountTypeService {
  private defaultConfig: ICustomerAccountTypeConfig = { name: 'Account' };
  private configs: { [id: number]: ICustomerAccountTypeConfig } = {};

  constructor(
    private sessionStorageService: SessionStorageService
  ) {
    // ngOnInit does not get called for injected services
    this.init();
  }

  init(): void {
    if (Object.keys(this.configs).length > 0) {
      return;
    }

    this.configs[CustomerAccountTypes.GoogleAccount] = { name: 'Google Account' };
    this.configs[CustomerAccountTypes.GoogleSearchConsole] = { name: 'Google Search Console' };
    this.configs[CustomerAccountTypes.GoogleAnalytics] = { name: 'Google Analytics' };
    this.configs[CustomerAccountTypes.GoogleTagManager] = { name: 'Google Tag Manager' };
    this.configs[CustomerAccountTypes.GoogleMyBusiness] = { name: 'Google My Business' };
    this.configs[CustomerAccountTypes.BlogAdmin] = { name: 'Blog Admin' };
    this.configs[CustomerAccountTypes.BlogEditor] = { name: 'Blog Editor' };
    this.configs[CustomerAccountTypes.BlogHosting] = { name: 'Blog Hosting' };
    this.configs[CustomerAccountTypes.CMSCustomerAccess] = { name: 'CMS Customer Access' };
    this.configs[CustomerAccountTypes.CMSAdminAccess] = { name: 'CMS Admin Access', permission: permissions.CanAccessCMSAdmin };
    this.configs[CustomerAccountTypes.FTP] = { name: 'FTP' };
    this.configs[CustomerAccountTypes.DomainRegistrar] = { name: 'Domain Registrar' };
    this.configs[CustomerAccountTypes.WebsiteHosting] = { name: 'Website Hosting' };
  }

  public getName(customerAccountTypeId: number): string {
    return this.getConfig(customerAccountTypeId).name;
  }

  public filterByPermissions(customerAccountTypeIds: number[]): Observable<number[]> {
    let filterableCustomerAccountTypeIds = _.filter(customerAccountTypeIds, (customerAccountTypeId: number) => {
      let config: ICustomerAccountTypeConfig = this.getConfig(customerAccountTypeId);
      return !!config.permission;
    });

    if (filterableCustomerAccountTypeIds.length === 0) {
      return of(customerAccountTypeIds);
    }

    return forkJoin([
      this.sessionStorageService.getPartner(),
      this.sessionStorageService.getPartnerUsers(),
      this.sessionStorageService.getUser()
    ])
    .pipe(
      switchMap(([partner, partnerUsers, user]) => {
        let filteredAccountTypeIds = _.filter(filterableCustomerAccountTypeIds, (filterableCustomerAccountTypeId: number) => {
          let config: ICustomerAccountTypeConfig = this.getConfig(filterableCustomerAccountTypeId);
          return !(<Launchpad>launchpad).hasPermission(partner, partnerUsers, config.permission, user);
        });
    
        return of(_.filter(customerAccountTypeIds, (customerAccountTypeId: number) => {
          return !_.contains(filteredAccountTypeIds, customerAccountTypeId);
        }));
      }));
  }

  public isCustomerAccountPermitted(customerAccountTypeId: number): Observable<boolean> {
    let config: ICustomerAccountTypeConfig = this.getConfig(customerAccountTypeId);
    if (!!config.permission) {
      return forkJoin([
        this.sessionStorageService.getPartner(),
        this.sessionStorageService.getPartnerUsers(),
        this.sessionStorageService.getUser()
      ])
      .pipe(
        switchMap(([partner, partnerUsers, user]) => {
          return of((<Launchpad>launchpad).hasPermission(partner, partnerUsers, config.permission, user));
        })
      );
    } else {
      return of(true);
    }
  }

  private getConfig(customerAccountTypeId: number): ICustomerAccountTypeConfig {
    return this.configs[customerAccountTypeId] || this.defaultConfig;
  }
}

interface ICustomerAccountTypeConfig {
  name: string;
  permission?: number;
}