import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { CreateTodoListLegacyComponent } from './CreateTodoListLegacy/CreateTodoListLegacy.component';
import { CreateTodoListComponent } from './CreateTodoList/CreateTodoList.component';
import { CustomerTodoListComponent } from './CustomerTodoList/CustomerTodoList.component';
import { ReadonlyTodoLegacyComponent } from './ReadonlyTodoLegacy/ReadonlyTodoLegacy.component';
import { ReadonlyTodoComponent } from './ReadonlyTodo/ReadonlyTodo.component';
import { ReadonlyTodoListLegacyComponent } from './ReadonlyTodoListLegacy/ReadonlyTodoListLegacy.component';
import { ReadonlyTodoListComponent } from './ReadonlyTodoList/ReadonlyTodoList.component';
import { TodoLegacyComponent } from './TodoLegacy.component';
import { TodoComponent } from './Todo.component';
import { TodoListLegacyComponent } from './TodoListLegacy/TodoListLegacy.component';
import { TodoListComponent } from './TodoList/TodoList.component';



@NgModule({
  declarations: [
    CreateTodoListLegacyComponent,
    CreateTodoListComponent,
    CustomerTodoListComponent,
    ReadonlyTodoLegacyComponent,
    ReadonlyTodoComponent,
    ReadonlyTodoListLegacyComponent,
    ReadonlyTodoListComponent,
    TodoLegacyComponent,
    TodoComponent,
    TodoListLegacyComponent,
    TodoListComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class TodoModule { }
