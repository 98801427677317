<div class="row">
  <div class="col-md-8">
    <ng-container *ngIf="!!userType">
    <div>
      <strong>{{userType}}</strong>
    </div>
    </ng-container>
    <ng-container *ngIf="!userType">
    <div>
      <strong>{{comment.FromUserFullName}}</strong>
    </div>
    </ng-container>
    <small>
      to
      <span>{{comment.ToUserFullName}}</span>
    </small>
  </div>
  <div class="col-md-4 text-end">
    <small>{{comment.InsertedDate | dateText }}</small>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="list-group-item-text">
      <div *ngIf="displayAsHtml" style="padding-top:15px; padding-bottom:15px;" [innerHTML]="comment.Note"></div>
      <div *ngIf="!displayAsHtml" style="padding-top:15px; padding-bottom:15px;">{{comment.Note}}</div>
    </div>
  </div>
</div>
