import { NgZone } from '@angular/core';
import Hotjar from '@hotjar/browser';
import { environment } from '../../environments/environment';


export abstract class HotjarService {
  abstract trigger(trigger: string): void;
}

export function hotjarServiceFactory(ngZone: NgZone): HotjarService {
  return environment.enableHotjar ? new LiveHotjarService(ngZone) : { trigger(_) { } };
}

class LiveHotjarService implements HotjarService {
  constructor(private ngZone: NgZone) {
    this.ngZone.runOutsideAngular(() => Hotjar.init(environment.hotjarSiteId, 6));
  }

  trigger(trigger: string): void {
    this.ngZone.runOutsideAngular(() => Hotjar.event(trigger));
  }
}
