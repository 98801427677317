<ng-container *ngIf="sortBy else noitems">
  <span class="cursor-pointer" (click)="sortItems()">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    <i *ngIf="sortDirection" class="fa"
      [class.fa-sort-up]="sortDirection == 'asc'" 
      [class.fa-sort-down]="sortDirection == 'desc'">
    </i>
  </span>
</ng-container>

<ng-template #noitems>
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</ng-template>

<ng-template #contentTemplate>
  <span #content>
    <ng-content></ng-content>
  </span>
</ng-template>