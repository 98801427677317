import { Component, ElementRef, NgZone, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import app from 'App';
import { finalize, switchMap } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import Password from '../password';
import CustomValidators from '../../../shared/custom-validators';
import { SessionStorageService } from '../../../services/session-storage.service';

@Component({
  selector: 'app-components-login',
  templateUrl: './Login.component.html'
})

export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('googleSignInButton') googleSignInButton: ElementRef;
  @ViewChild('updatePasswordModal') updatePasswordModal: TemplateRef<any>;
  app = app;
  isLoading = false;
  partnerId: number;
  partnerName: string;
  showForgotPasswordView = false;
  isLoggingIn = false;
  errorMessage = '';
  passwordCtrl = new UntypedFormControl('', [Validators.required]);
  usernameCtrl = new UntypedFormControl('', [Validators.required, CustomValidators.email]);
  forgotPasswordUsernameCtrl = new UntypedFormControl('', [Validators.required, CustomValidators.email]);

  constructor(
    private zone: NgZone,
    private userService: UserService,
    private modalService: NgbModal,
    private sessionStorageService: SessionStorageService) {
  }

  ngOnInit(): void {
    app.showContent(true);
    this.partnerId = app.partnerId();
    this.partnerName = app.partnerName();
    this.initializeSignInWithGoogle();
  }

  ngAfterViewInit(): void {
    google.accounts.id.renderButton(
      this.googleSignInButton.nativeElement,
      {
        type: 'standard',
        theme: 'filled_blue',
        size: 'medium'
      }
    );
    google.accounts.id.prompt();
  }

  login() {
    this.clearValidationErrors();

    if (!this.usernameCtrl.valid || !this.passwordCtrl.valid) {
      this.usernameCtrl.markAsTouched();
      this.passwordCtrl.markAsTouched();
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return;
    }

    this.isLoggingIn = true;
    this.userService.login(this.usernameCtrl.value, this.passwordCtrl.value)
      .pipe(
        finalize(() => this.isLoggingIn = false),
        switchMap(token => {
          if (token.IsError) {
            throw 'Invalid username or password';
          }
          this.sessionStorageService.setBearerToken(token);
          return this.sessionStorageService.getCurrentUser();
        }))
      .subscribe(
        user => this.handleSuccessfulLogin(user),
        err => this.errorMessage = err
      );
  }

  onGoogleSignIn(googleUser: GoogleCredentialResponse) {
    this.clearValidationErrors();

    this.isLoggingIn = true;
    this.userService.sso(googleUser.credential)
      .pipe(
        finalize(() => this.isLoggingIn = false),
        switchMap(token => {
          if (token.IsError) {
            throw 'Google login failed';
          }
          this.sessionStorageService.setBearerToken(token);
          return this.sessionStorageService.getCurrentUser();
        }))
      .subscribe(
        user => {
          if (user) {
            this.sessionStorageService.setUser(user);
            if (user.PartnerUsers.length === 0) {
              this.errorMessage = 'Login provided does not have any active partner users.';
            } else {
              app.handleLogin(user);
            }
          } else {
            this.errorMessage = "Login failed. Could not locate user. Please retry.";
          }
        },
        err => {
          this.signOutOfGoogle();
          this.errorMessage = err;
        }
      );
  }

  submitForgotPassword() {
    this.isLoading = true;
    if (!this.forgotPasswordUsernameCtrl.valid) {
      this.forgotPasswordUsernameCtrl.markAsTouched();
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      this.isLoading = false;
      return;
    }

    this.userService.sendPasswordResetEmail(this.forgotPasswordUsernameCtrl.value)
      .pipe(finalize(() => {
        toastr.success('Instructions for resetting your password have been sent to your email address.', 'Password Reset');

        this.showForgotPasswordView = false;
        this.forgotPasswordUsernameCtrl.setValue('');
        this.clearValidationErrors();
        this.isLoading = false;
      }))
      .subscribe();
  }

  forgotPassword() {
    this.showForgotPasswordView = true;
  }

  clearValidationErrors() {
    this.errorMessage = '';
    this.usernameCtrl.markAsUntouched();
    this.passwordCtrl.markAsUntouched();
    this.forgotPasswordUsernameCtrl.markAsUntouched();
  }

  cancelForgotPassword() {
    this.clearValidationErrors();
    this.showForgotPasswordView = false;
  }

  private initializeSignInWithGoogle() {
    google.accounts.id.initialize({
      client_id: '602837832805-cikc5mdnvs0dpt51bp1j4oiitbardnbm.apps.googleusercontent.com',
      auto_select: true,
      callback: (credentialResponse) => {
        this.zone.run(() => {
          this.onGoogleSignIn(credentialResponse);
        })
      }
    });
  }

  private signOutOfGoogle() {
    google.accounts.id.disableAutoSelect();
  }

  private handleSuccessfulLogin(user: Boo.Objects.User): void {
    if (!user) {
      this.errorMessage = 'User Not Found';
      return;
    }

    if (user.PartnerUsers.length === 0) {
      this.errorMessage = 'Login provided does not have any active partner users.';
      return;
    }

    this.sessionStorageService.setUser(user);

    if (!Password.valid(this.passwordCtrl.value)) {
      this.modalService.open(this.updatePasswordModal).result
        .then(() => {
          PriorityViewModel.show('app-components-changepassword', {}, false)
            .always(() => app.handleLogin(user));
        }, () => app.handleLogin(user));
    } else {
      app.handleLogin(user);
    }
  }
}
