<div [loadingOverlay]="isLoading">
	<div [dataValidation]="{ placement: 'top', value: validation }">
		<div class="row">
			<div class="col-lg-12" *ngIf="showContentManagementSystem && !isLoading">
				<app-components-managecustomer-cms [customerId]="customer.CustomerId()" [partnerId]="customer.PartnerId()"
				[canEditContentManagementSystem]="canEditContentManagementSystem" [isManagedByPartner]="isManagedByPartner"
				[showBoostabilityAccessOption]="showBoostabilityAccessOption" [(isExternalAccessShared)]="isExternalAccessShared"
				(cmsChange)="ContentManagementSystemChanged($event)" (isManagedByPartnerChange)="isManagedByPartner = $event">
				</app-components-managecustomer-cms>
			</div>
		</div>
		<compose Selector="app-components-compositionroutegrid" *ngIf="grid()" [Model]="{ grid: grid }"></compose>
	</div>
	<div class="row">
		<ng-container *ngIf="showSaveButton">
			<div class="col-lg-12">
				<div class="card card-body bg-light d-block text-end">
					<button [hidden]="!canClose" (click)="cancel()" class="btn btn-link">Cancel</button>
					<button (click)="save()" class="btn btn-success">
						<i class="fa fa-save"></i>
						Save
					</button>
				</div>
			</div>
		</ng-container>
	</div>
</div>
