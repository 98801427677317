<div class="card">
	<div class="card-header">Merchant Fields</div>
	<div class="card-body">
		<div class="row">
			<div class="col-md-4">
				<div class="card">
					<div class="card-header">Export</div>
					<div [loadingOverlay]="isLoading()" class="card-body">
						<div class="row">
							<div class="col-md-12">
								Customer Status
								<div [dataValidation]="{ position: 'top', value: statuses}">
									<ng-container *ngFor="let $data of statuses()">
										<div>
											<label class="checkbox-inline">
												<input [ngModel]="$data.IsChecked()" (ngModelChange)="$data.IsChecked($event)" [ngModelOptions]="{ standalone: true }" type="checkbox"/>
												<span>{{$data.Name()}}</span>
											</label>
										</div>
									</ng-container>
								</div>
								<button (click)="exportMerchantFields()" class="btn btn-primary float-end">
									<i class="fa fa-download"></i>
									Export
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card">
					<div class="card-header">
						Import
						<small>
							(
							<span>{{importLimit()}}</span>
							record limit)
						</small>
					</div>
					<div [loadingOverlay]="isLoading()" class="card-body">
						<div class="row">
							<div class="col-md-12">
								<div class="input-group">
									<input [dataValidation]="{ placement: 'top', value: importFilename}" [fileReader]="{ data: importContent, filename: importFilename, file: importFile, accept: '.csv', method: 'readAsText'}" type="file" class="form-control float-end"/>
									<button (click)="importMerchantFields()" class="btn btn-primary">
										<i class="fa fa-upload"></i>
										Import
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card">
					<div class="card-header">Field Mapping</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-12">
								<table>
									<ng-container *ngFor="let $data of merchantFieldLabels().ToArray()">
										<tbody>
											<tr>
												<td>
													<strong>
														<span>{{$data.Default}}</span>
													</strong>
												</td>
												<td>{{$data.Value}}</td>
											</tr>
										</tbody>
									</ng-container>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
