<div class="card">
	<div class="card-header">
		Price Level Features
		<button (click)="close()" class="btn-close" title="Close"></button>
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label class="control-label">Price</label>
					<input type="text" class="form-control" readonly="readonly"
						[(ngModel)]="partnerPricingToolPriceLevel.Price" />
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label class="control-label">SEO Minutes</label>
					<input type="text" class="form-control" readonly="readonly"
						[(ngModel)]="partnerPricingToolPriceLevel.SeoMinutes" />
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<ul class="list-group feature_list_container" style="min-height: 50px;">
					<li class="list-group-item" *ngIf="partnerPricingToolPriceLevel.Features.length == 0">
						<p>No features are associated with this price level.</p>
					</li>
					<li class="list-group-item" *ngFor="let feature of partnerPricingToolPriceLevel.Features">
						<h4 *ngIf="feature.Title">{{ feature.Title }}</h4>
						<h4 *ngIf="!feature.Title">{{ feature.PartnerPricingToolFeature.Title }}</h4>
						<p *ngIf="feature.Definition">{{ feature.Definition }}</p>
						<p *ngIf="!feature.Definition">{{ feature.PartnerPricingToolFeature.Definition }}</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="card-footer text-end">
		<button (click)="close()" class="btn btn-link">Close</button>
	</div>
</div>