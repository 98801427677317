@if (canSendDiscoveryEmail) {
  <app-components-togglepanel title="Discovery Email" [toggleBody]="true" [(isOpen)]="isOpen">
    @if (frontConfigurationIsValid) {
      <div class="row" [(formGroup)]="forms">
        <div class="col-md-8">
          <div class="form-group">
            <app-time-zone-picker formControlName="timeZone" dataValidation [countryId]="customer.CountryId()"></app-time-zone-picker>
          </div>
          <div class="form-group">
            Note To Customer
            <textarea formControlName="frontNote" dataValidation class="form-control"></textarea>
          </div>
          <div class="form-group">
            Onboarding Appointment Time
            <app-date-time-picker formControlName="date" [min]="minDate"></app-date-time-picker>
            <small class="mt-0 pt-0">Times are in your local timezone.</small>
          </div>
        </div>
      </div>
    }
    @else {
      <div class="alert alert-warning">
        <strong>Unable to automatically send discovery email to this customer</strong>
        <ul>
          @if(!isPartnerConfigured) {
            <li>Partner has no Front channel configured.</li>
          }
  
          @if (!isUserConfigured) {
            <li>Your Launchpad account is not associated with a Front teammate.</li>
          }
  
          @if (!isCustomerConfigured) {
            <li>None of the customer's contacts are configured to recieve emails through Front.</li>
          }
        </ul>
      </div>
    }
  </app-components-togglepanel>
}