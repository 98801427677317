<app-components-specialist-internal-tasks-qa-action-information
  [internalAction]="internalAction"></app-components-specialist-internal-tasks-qa-action-information>
<div id="internal-task-qa-criteria-float-panel" class="float-panel">
  <div class="float-panel-tab">
    <button class="float-panel-btn-expand btn btn-lg btn-primary">
      <i class="fa fa-check"></i>
    </button>
  </div>
  <div class="float-panel-main card">
    <div class="float-panel-draggable card-header">
      <i class="fa fa-check"></i>
      Profile Submission Quality
      <i class="float-panel-btn-collapse fa fa-minus float-end"></i>
    </div>
    <div class="card-body" style="overflow-y: scroll; max-height: 550px;">
      <app-components-qa-grading-area-panel [taskType]="taskType"
        [qaCriteriaGroups]="qaCriteriaGroups" #gradingArea>
      </app-components-qa-grading-area-panel>
    </div>
    <div class="card-footer">
      <div class="text-end">
        <button (click)="close.next()" class="btn btn-primary">Close</button>
        <button (click)="unlock.next()" class="btn btn-info">
          <i class="fa fa-unlock"></i>
          Unlock &amp; Unassign
        </button>
        <button (click)="complete.next()" class="btn btn-success">
          <i class="fa fa-check"></i>
          Complete
        </button>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="row">
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <span>
            <strong>Partner</strong>
            <span class="float-end">{{ partner }}</span>
          </span>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <span>
            <strong>QA Task Created Date</strong>
            <span class="float-end">{{ internalAction.InternalTaskDetails.InsertedDate }}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
  <app-components-specialist-actioncomponents-customertargeturls [options]="{
      customer: customer,
      taskId: taskId,
      isMoneyBack: isMoneyBack
  }"></app-components-specialist-actioncomponents-customertargeturls>
  <app-components-specialist-actioncomponents-customertasktypes
    [options]="{ customer: customer }"></app-components-specialist-actioncomponents-customertasktypes>
  <div class="card">
    <div class="card-header">
      Customer Campaign
    </div>
    <div class="card-body">
      <app-components-shared-customer-campaign 
        [customer]="customer"
        [restrictions]="customerCampaignRestrictions">
      </app-components-shared-customer-campaign>
    </div>
  </div>
  <app-components-upa-checklist [upaChecklists]="upaChecklists" [isReadOnly]="true"></app-components-upa-checklist>
  <div class="card">
    <div class="card-header">
      Notes
    </div>
    <div class="card-body">
      <app-components-managecustomer-notes [options]="{
        customer: customer,
        customerId: customer.CustomerId,
        isReadOnly: true
      }"></app-components-managecustomer-notes>
    </div>
  </div>
  <app-components-specialist-taskfiles [options]="{
      taskId: taskId,
      allowUpload: false
  }"></app-components-specialist-taskfiles>
</div>