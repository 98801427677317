import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.disableAngularDebugChecks) {
  enableProdMode();
}

window['paceOptions'] = {
  ajax: {
    trackMethods: ['GET', 'POST', 'DELETE', 'PUT', 'PATCH'],
    ignoreURLs: ['src/Scripts/reload.json']
  },
  elements: false,
  restartOnRequestAfter: true,
  eventLag: false
};

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));