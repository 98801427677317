<div>
	<!-- THIS IS A PRIORITY VIEW -->
	<div class="card">
		<div class="card-header">
			<span>Job Type Summary</span>
			<button (click)="closeView()" type="button" class="btn-close float-end"></button>
		</div>
		<div [loadingOverlay]="isLoading()" class="card-body">
			<div class="row">
				<compose Selector="app-components-admin-joblist" [ActivateOptions]="{
                                        jobTypeId: defaultJobTypeId
                                    }"></compose>
			</div>
			<div class="card card-body bg-light d-block text-end">
				<button (click)="closeView()" class="btn btn-primary">
					<i class="fas fa-times"></i>
					Close
				</button>
			</div>
		</div>
	</div>
</div>
