<div class="card">
	<div class="card-header">
		Edit Blog Post
		<button (click)="close()" class="btn-close" title="Close"></button>
	</div>
	<div class="card-body">
		<div class="form-group">
			<ng-container *ngIf="taskPost()">
				<label class="control-label">Title</label>
				<textarea [(ngModel)]="taskPost().ContentTitle" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" readonly style="height:auto" class="form-control"></textarea>
				<br/>
				<label class="control-label">Content</label>
				<textarea [(ngModel)]="taskPost().Content" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" style="height:600px" class="form-control"></textarea>
			</ng-container>
		</div>
		<div class="card card-body bg-light d-block text-end">
			<button (click)="close()" class="btn btn-link">Cancel</button>
			<button (click)="saveBlog()" class="btn btn-success" data-accept="true">
				<i class="fa fa-save"></i>
				Save
			</button>
		</div>
	</div>
</div>
