<div [loadingOverlay]="isLoading()">
	<form>
		<div class="row">
			<div class="col-md-8">
				<div class="row form-group">
					<label class="col-md-1 offset-md-2 control-label text-end">Name</label>
					<div class="col-md-4">
						<input [dataValidation]="{ placement: 'right', value: leadSource.Name }" [ngModel]="leadSource.Name()" (ngModelChange)="leadSource.Name($event)" [ngModelOptions]="{ standalone: true }" type="text" class="form-control"/>
					</div>
					<label class="col-md-3 control-label text-end">Boostability Rev. Share</label>
					<div class="col-md-2">
						<input [value]="(leadSource.RevsharePercent() * 100) + '%'" type="text" disabled="disabled" class="form-control"/>
					</div>
				</div>
				<div class="row form-group">
					<label class="col-md-1 offset-md-2 control-label text-end">Category</label>
					<div class="col-md-4">
						<select [ngModel]="leadSource.CategoryId()" (ngModelChange)="leadSource.CategoryId($event)" [ngModelOptions]="{ standalone: true }" class="form-select">
							<option *ngFor="let option of categories" [ngValue]="option.CategoryId">{{ option.Name }}</option>
						</select>
					</div>
					<label class="col-md-3 control-label text-end">Lead Source Rev. Share</label>
					<div class="col-md-2">
						<input [value]="(100 - (leadSource.RevsharePercent() * 100)) + '%'" type="text" disabled="disabled" class="form-control"/>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card card-body bg-light text-end">
					<button (click)="save()" type="submit" class="btn btn-success" style="margin-bottom:5px;">
						<i class="fa fa-save"></i>
						Save
					</button>
					<button (click)="delete()" type="submit" class="btn btn-danger">
						<i class="fa fa-trash"></i>
						Delete
					</button>
					<button (click)="cancelEdit()" type="submit" class="btn btn-link" style="margin-left:5px;">Cancel</button>
				</div>
			</div>
		</div>
	</form>
</div>

