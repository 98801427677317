<div>
	<app-components-togglepanel title="Customer Notes" toggleBody="true">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						Sales Note
					</div>
					<div class="card-body">
						<ng-container *ngIf="salesNote()">
							<div class="line-breaks">{{salesNote()}}</div>
						</ng-container>
						<ng-container *ngIf="!(salesNote())">
							<div class="line-breaks alert-warning">No sales note available</div>
						</ng-container>
					</div>
				</div>
				<div class="form-group">
					<label>Customer Notes</label>
					<shared-components-odatagrid *ngIf="dataTableOptions"
						[options]="dataTableOptions"></shared-components-odatagrid>
				</div>
			</div>
		</div>
	</app-components-togglepanel>
	<ng-template #noteGridTemplate let-ViewModel="ViewModel">
		<tr>
			<td>{{ViewModel.InsertedDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
			<td class="line-breaks">{{ViewModel.Text}}</td>
			<td>{{ViewModel.UserFullName}}</td>
		</tr>
	</ng-template>
</div>