<div *withPermissions="let allow of ['CanViewOnsiteRecommendations', 'CanViewCustomReports', 'CanViewCustomActions', 'CanEditCustomerPartnerInformation']">
  <div [loadingOverlay]="isLoading()">
    <div id="details" style="min-height: 150px;">
      <ng-container *ngIf="customer()">
        <div class="row">
          <div class="col-md-6">
            <ul class="list-group">
              <li class="list-group-item">
                <strong>Company Name</strong>
                <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                  class="float-end">{{customer().Name()}}</span>
              </li>
              <li class="list-group-item">
                <strong>Website</strong>
                <a [externalLink]="{ url: customer().Url, text:customer().Url}" class="float-end" rel="noreferrer"></a>
              </li>
              <li class="list-group-item">
                <strong>Customer ID</strong>
                <div class="float-end">
                  <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                    class="float-end">{{customer().CustomerId()}}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <strong class="col-md-4">Vertical</strong>
                  <div class="col-md-8">
                    <ng-container *ngIf="ko.utils.unwrapObservable(customer().Vertical)">
                      <span class="float-end">{{customer().Vertical.Name()}}</span>
                    </ng-container>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <strong class="col-md-4">Language</strong>
                  <div class="col-md-8">
                    <ng-container *ngIf="customer().Language">
                      <span class="float-end">{{customer().Language.Name()}}</span>
                    </ng-container>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <strong>Minutes</strong>
                <span class="float-end">{{customer().SeoMinutesTotal()| seoMinutes}}</span>
              </li>
              <li class="list-group-item">
                <strong>Renewal Date</strong>
                <span (click)="window.launchpad.utils.selectText(undefined, $event)" class="float-end">{{nextRenewalDate | date : 'shortDate' : 'UTC'}}</span>
              </li>
            </ul>
            <ul class="list-group">
              <li class="list-group-item">
                <strong>Partner</strong>
                <div class="float-end">
                  <span>{{customer().Partner.Name()}}</span>
                </div>
              </li>
              <li class="list-group-item">
                <strong>Partner Full White Label Support?</strong>
                <div class="float-end">
                  <span>{{customer().Partner.IsWhiteLabel() ? 'Yes' : 'No'}}</span>
                </div>
              </li>
              <li class="list-group-item">
                <strong>Partner Support Tier</strong>
                <div class="float-end">
                  <span>{{partnerSupportLevelName()}}</span>
                </div>
              </li>
              <li class="list-group-item">
                <strong>Partner Support Phone Number</strong>
                <div class="float-end">
                  <span><shared-components-clicktocall [Phone]="customer().Partner.SupportPhoneNumber()"
                      [CountryCode]="supportPhoneNumberCountryCode()"></shared-components-clicktocall></span>
                </div>
              </li>
              <li class="list-group-item">
                <strong>Partner Support Email Address</strong>
                <div class="float-end">
                  <span>{{customer().Partner.SupportEmailAddress()}}</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <ul class="list-group">
              <li class="list-group-item">
                <div class="row">
                  <strong class="col-md-4">Company Email</strong>
                  <div class="col-md-8">
                    <a [attr.href]="'mailto:' + customer().Email()" class="float-end">
                      <i class="fa fa-envelope"></i>
                      <span>{{customer().Email()}}</span>
                    </a>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <strong>Company Phone</strong>
                <span class="float-end"><shared-components-clicktocall [Phone]="customer().Phone()"
                    [CountryCode]="customer().Country.Abbreviation()"></shared-components-clicktocall></span>
              </li>
              <ng-container *ngIf="customerUser()">
                <li class="list-group-item">
                  <strong>Contact Name</strong>
                  <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                    class="float-end">{{_.isObject(customerUser()) ? (customerUser().FirstName() + ' ' +
                    customerUser().LastName()) : ''}}</span>
                </li>
                <li class="list-group-item">
                  <div class="row">
                    <strong class="col-md-4">Contact Username/Email</strong>
                    <div class="col-md-8">
                      <a [attr.href]="'mailto:' + customerUser().Username()" class="float-end">
                        <i class="fa fa-envelope"></i>
                        <span>{{customerUser().Username()}}</span>
                      </a>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <strong>Contact Phone</strong>
                  <span class="float-end"><shared-components-clicktocall [Phone]="customerUser().PrimaryPhoneNumber()"
                      [CountryCode]="customer().Country.Abbreviation()"></shared-components-clicktocall></span>
                </li>
                <li class="list-group-item">
                  <compose Selector="app-components-managecustomer-customercontactpreferences" [ActivateOptions]="{
                                        customerId: customer().CustomerId(),
                                        isReadOnly: true
                                    }"></compose>
                </li>
              </ng-container>
              <li class="list-group-item">
                <ng-container *ngIf="customer().Partner">
                  <strong>Sales Rep</strong>
                  <span [hidden]="!(customer().Partner.IsBillingVisible())"
                    (click)="window.launchpad.utils.selectText(undefined, $event)"
                    class="float-end">{{customer().SalesRep()}}</span>
                  <span [hidden]="!(customer().Partner.IsBillingVisible() === false)" class="float-end">N/A</span>
                </ng-container>
              </li>
              <li class="list-group-item">
                <strong>Partner Name</strong>
                <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                  class="float-end">{{customer().Partner.Name()}}</span>
              </li>
              <li class="list-group-item">
                <strong>Referred By</strong>
                <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                  class="float-end">{{customer().ReferredBy()}}</span>
              </li>
              <li class="list-group-item">
                <strong>Lead Source</strong>
                <span (click)="window.launchpad.utils.selectText(undefined, $event)"
                  class="float-end">{{customer().LeadSource.Name}}</span>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <strong class="col-md-4">Country</strong>
                  <div class="col-md-8">
                    <ng-container *ngIf="customer().Country">
                      <span class="float-end">{{customer().Country.Name()}}</span>
                    </ng-container>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <strong class="col-md-4">Time Zone</strong>
                  <div class="col-md-8">
                    <ng-container *ngIf="customer().TimeZone()">
                      <span class="float-end">{{customer().TimeZone()}}</span>
                    </ng-container>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <app-components-recentorders [orders]="ordersSnapshot"></app-components-recentorders>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-end pt-3">
          <button (click)="editBasicInformation()" class="btn btn-primary">
            <i class="fa fa-edit"></i>
            Edit Basic Account Information
          </button>
          <button (click)="editCustomerContacts()" class="btn btn-primary">
            <i class="fa fa-edit"></i>
            Edit Customer Contact
          </button>
          <ng-container *ngIf="canResetWebsite">
            <button (click)="resetCustomerWebsite()" class="btn btn-primary">
              <i class="fa fa-edit"></i>
              Reset Customer Website (Save Ticket)
            </button>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>