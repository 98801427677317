import { Component } from '@angular/core';

@Component({
  selector: 'app-components-managecustomer-customer-campaign',
  templateUrl: './customer-campaign.component.html'
})
export class CustomerCampaignComponent implements IActivatable {
  customer: Boo.Objects.Customer;
  refreshCustomer: (callback?: any) => void;

  canActivate?(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: any): boolean {
    this.customer = ko.mapping.toJS(params.customer);
    if (params.refreshCustomer) {
      this.refreshCustomer = params.refreshCustomer;
    }
    return true;
  }

  tryRefreshCustomer(): void {
    if (this.refreshCustomer) {
      this.refreshCustomer();
    }
  }
}
