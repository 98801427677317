import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-components-shared-keywords-url-suggestions-readonly',
  templateUrl: './url-suggestions-readonly.component.html'
})
export class UrlSuggestionsReadonlyComponent implements OnInit {
  @Input() urlSuggestions: Boo.Objects.QaUrlSuggestion[];

  constructor() { }

  ngOnInit(): void {
  }
}
