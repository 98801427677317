<div class="row">
  <div class="col-md-12">
    <div class="card card-warning">
      <div class="card-header">
        Instructions
      </div>
      <div class="card-body ">
        <p>Using only the official verified sources (in order of authority), review and fill out every field in the listing that you possibly can. If you have reviewed all verified sources and cannot find a piece of information, leave it blank. If more than half of this listing is blank, or if additional unique sources are needed, workblock this ticket to request more information.</p>
        <p>Official Verified Sources (in order of authority):</p>
          <ul>
            <li><a [externalLink]="{ url: customerUrl, text: 'Customer\’s Website' }" rel="noreferrer"></a></li>
            <li><a [externalLink]="{ url: gbpUrl, text: 'Google Business Profile' }" rel="noreferrer"></a></li>
            <li><a [externalLink]="{ url: appleMapsUrl, text: 'Apple Maps' }" rel="noreferrer"></a></li>
            <li><a [externalLink]="{ url: bingUrl, text: 'Bing Local' }" rel="noreferrer"></a></li>
            <li><a [externalLink]="{ url: yelpUrl, text: 'Yelp' }" rel="noreferrer"></a></li>
            <li><a [externalLink]="{ url: facebookUrl, text: 'Facebook' }" rel="noreferrer"></a> (login required)</li>
          </ul>
      </div>
    </div>
  </div>
</div> 
<div class="row">
  <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          Local Profiles
        </div>
        <div class="card-body" style="min-height: 700px">
          <app-components-shared-local-profiles
            [requireFullLocalProfileOnAdd]="true"
            [canAddLocalProfile]="true"
            [customer]="customer"
            [localProfileId]="localProfile.LocalProfileId">
          </app-components-shared-local-profiles>
        </div>
      </div>
  </div>
</div>
<app-floating-panels>
  <app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
    <app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="taskDetails.TaskNotes">
    </app-components-specialist-actioncomponents-comments>
  </app-floating-panel>
  <app-floating-panel [displayConfig]="{ title: 'Locations', icon: 'fa-map-marker', size: 'floating-panel-lg' }">
    <app-components-shared-view-local-profiles *floatingPanelContent [customer]="customer">
    </app-components-shared-view-local-profiles>
  </app-floating-panel>
</app-floating-panels>
