/* tslint:disable */

export default class ticket {
    public Csr = <any>null
    public AssignedUser = <any>null
    public AssignedUserId = 0
    public AssociatedTask = <any>null
    public AssociatedTaskId = 0
    public CompletedMinutes = 0
    public CustomerId = 0
    public DescendantTickets = <any>null
    public DueDate = <any>null
    public EstimatedMinutes = 0
    public InsertUser = <any>null
    public InsertUserId = 0
    public InsertedDate = "0001-01-01T00:00:00"
    public IsAppointment = false
    public IsApproved = <any>null
    public NewNote = <any>null
    public ParentTicketId = 0
    public Priority = 0
    public Reason = <any>null
    public ScheduledDate = "0001-01-01T00:00:00"
    public ScheduledEndDate = "0001-01-01T00:00:00"
    public StatusDate = "0001-01-01T00:00:00"
    public StatusId = 0
    public StatusName = <any>null
    public TicketId = 0
    public TicketNotes = <any>null
    public TicketType = <any>null
    public TicketTypeId = 0
    public UploadedFile = <any>null
}