export default class CsvConversionService {
    // Converts a list of objects to comma separated strings with a header
    public convert(columnMapping: app.interfaces.ICsvColumn[], dataSource: any[]): string {
        let headerData: string[] = [];
        _.each(columnMapping, (column: app.interfaces.ICsvColumn) => {
            headerData.push(column.Name);
        });

        let rowData: Array<string[]> = [];
        let items = ko.utils.unwrapObservable(dataSource);
        for (let i = 0; i < items.length; i++) {
            let row: Array<string> = [];
            for (let c = 0; c < columnMapping.length; c++) {
                let dataSourceName = columnMapping[c].Value;
                // Allow nesting one level
                let value: any = null;
                if (dataSourceName.indexOf('.') >= 0) {
                    let splitDataSource = dataSourceName.split('.');
                    value = ko.utils.unwrapObservable(items[i][splitDataSource[0]][splitDataSource[1]]);
                } else {
                    value = ko.utils.unwrapObservable(items[i][dataSourceName]);
                }
                if (_.isString(value)) {
                    // CSV quotes are escaped by using 2 quotes
                    value = value.split('"').join('""');
                }
                row.push(value);
            }
            rowData.push(row);
        }
        let data = `"${headerData.join('","')}"\r\n`;
        for (let i = 0; i < rowData.length; i++) {
            data += `"${rowData[i].join('","')}"\r\n`;
        }
        return data;
    }
}
