import { Component } from '@angular/core';
import System from 'framework/System';
import Utils from '../../../shared/utils';
import { LeadSourceService } from '../../../services/lead-source.service';

@Component({
	selector: 'app-components-manager-leadsource',
	templateUrl: './LeadSource.component.html'
})
export class LeadSourceComponent implements IActivatable {
    public leadSource: LeadSourceObservable;
    public isLoading: KnockoutObservable<boolean>;
    public validation: KnockoutObservable<any>;
    public categories: Boo.ApisExternal.Store.Objects.Category[];

    private deleteLeadSource: (leadSource: number) => {};
    private originalLeadSource: LeadSourceObservable;

    constructor(private leadSourceService: LeadSourceService) { }

    public activate(params: ILeadSourceViewModelActivateParams): JQueryPromise<void> {
        this.isLoading = ko.observable(true);

        this.deleteLeadSource = params.deleteLeadSource;
        this.originalLeadSource = params.leadSource;
        this.leadSource = ko.mapping.fromJS(ko.mapping.toJS(params.leadSource));

        this.leadSource.Name.subscribe(() => this.setDirty(true));
        this.leadSource.Name.extend({
            required: true
        });

        this.leadSource.RevsharePercent.subscribe(() => this.setDirty(true));

        this.leadSource.CategoryId.subscribe(() => this.mapCategoryToRevsharePercent());
        this.leadSource.CategoryId.extend({
            required: true
        });

        this.validation = ko.validatedObservable([this.leadSource.Name]);

        this.isLoading = ko.observable(false);
        return Utils.wrapDfd(this.leadSourceService.getCategories())
            .then((categories) => {
                this.categories = _.sortBy(categories, (category) => {
                    return category.Name;
                });
            });
    }

    public cancelEdit(): void {
        if (this.originalLeadSource.LeadSourceId() === 0) {
            this.deleteLeadSource(this.originalLeadSource.LeadSourceId());
        } else {
            if (this.originalLeadSource.IsDirty()) {
                this.resetLeadSource();
            }
            this.originalLeadSource.Editing(false);
        }
    }

    public save(): JQueryPromise<any> {
        this.isLoading(true);

        if (!this.validation.isValid()) {
            this.validation.errors.showAllMessages();
            this.isLoading(false);
            return System.emptyPromise();
        }

        return Utils.wrapDfd(this.leadSourceService.save(ko.mapping.toJS(this.leadSource)))
            .then((leadSourceId) => {
                if (this.leadSource.LeadSourceId() === 0) {
                    this.leadSource.LeadSourceId(leadSourceId);
                }
                this.updateLeadSource();
                this.originalLeadSource.Editing(false);
                toastr.success('Your lead source was saved successfully.');
            })
            .fail((displayMessage) => toastr.error(displayMessage))
            .always(() => this.isLoading(false));
    }

    public delete(): void {
        this.leadSourceService.delete(this.leadSource.LeadSourceId())
            .subscribe(() => {
                this.deleteLeadSource(this.leadSource.LeadSourceId());
                toastr.success('Your lead source was deleted successfully.');
            })
    }

    private mapCategoryToRevsharePercent(): void {
        let category = _.findWhere(this.categories, { CategoryId: this.leadSource.CategoryId() });
        if (!category) {
            this.leadSource.RevsharePercent(50);
            this.leadSource.CategoryName('');
        } else {
            let categoryValue = parseFloat(category.Name.replace('Affiliate-', ''));
            this.leadSource.RevsharePercent((100 - categoryValue) / 100);
            this.leadSource.CategoryName(category.Name);
        }
    }

    private resetLeadSource(): void {
        this.leadSource.Name(this.originalLeadSource.Name());
        this.leadSource.CategoryId(this.originalLeadSource.CategoryId());
        this.leadSource.CategoryName(this.originalLeadSource.CategoryName());
        this.leadSource.RevsharePercent(this.originalLeadSource.RevsharePercent());
        this.setDirty(false);
    }

    private updateLeadSource(): void {
        this.originalLeadSource.LeadSourceId(this.leadSource.LeadSourceId());
        this.originalLeadSource.Name(this.leadSource.Name());
        this.originalLeadSource.CategoryId(this.leadSource.CategoryId());
        this.originalLeadSource.CategoryName(this.leadSource.CategoryName());
        this.originalLeadSource.RevsharePercent(this.leadSource.RevsharePercent());
        this.setDirty(false);
    }

    private setDirty(isDirty: boolean): void {
        this.leadSource.IsDirty(isDirty);
        this.originalLeadSource.IsDirty(isDirty);
    }
}

interface ILeadSourceViewModelActivateParams {
    leadSource: LeadSourceObservable;
    deleteLeadSource: (leadSource: number) => {};
}
