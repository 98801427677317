<ng-container *ngIf="!haloKeywordSuggestions || haloKeywordSuggestions.length === 0">
  <div class="row">
    <div class="col-md-12">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th colspan="2">
              <button class="btn btn-primary float-end" (click)="openModal(addKeywordSuggestionModal)"><i class="fas fa-plus"></i>Add Keyword</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No halo keywords have been defined yet</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<div class="row" *ngIf="haloKeywordSuggestions.length > 0">
  <div class="col-md-12">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th colspan="2">
            <div class="d-flex float-end gap-1">
              <div ngbDropdown class="btn-group">
                <button class="btn btn-primary dropdown-toggle" type="button" ngbDropdownToggle>
                  Sort
                </button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <ng-container *ngFor="let sortOption of sortingOptions">
                    <button *ngIf="!(sortOption.disableIfNational && !isLocalCampaign)" ngbDropdownItem (click)="sort(sortOption.method)">{{sortOption.display}}</button>
                  </ng-container>
                </div>
              </div>
              <button class="btn btn-primary float-end" (click)="openModal(addKeywordSuggestionModal)"><i class="fas fa-plus"></i>Add Keyword</button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody cdkDropList [cdkDropListData]="haloKeywordSuggestions" (cdkDropListDropped)="dropListDropped($event)">
        <tr cdkDrag [cdkDragData]="keywordSuggestion" *ngFor="let keywordSuggestion of haloKeywordSuggestions">
          <td>
            <i cdkDragHandle class="fa-solid fa-arrows-up-down-left-right ml-3" style="cursor: move;"></i>
            <i class="fas fa-trash-alt cursor-pointer" (click)="removeKeywordSuggestion(keywordSuggestion)"></i>
            {{keywordSuggestion.IsAreaFirst ? (keywordSuggestion.Area || '') + ' ' + keywordSuggestion.Keyword :
            keywordSuggestion.Keyword + ' ' + (keywordSuggestion.Area || '') }}
            <span class="badge rounded-pill bg-secondary" style="font-size: 100%;margin-left: 10px;">{{"rank: " + keywordSuggestion.Rank}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #addKeywordSuggestionModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add New Halo Keyword</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body" style="min-height: 290px;">
      <div class="form-group">
        <label class="control-label">Keyword</label>
        <input type="text" class="form-control" [formControl]="newKeywordControl" [formValidation]="newKeywordControl" lpValidationMsg />
      </div>
      <div *ngIf="isLocalCampaign" class="form-group">
        <label class="control-label">Location</label>
        <input type="text" class="form-control" [formControl]="newAreaControl" [formValidation]="newAreaControl" lpValidationMsg />
      </div>
      <div *ngIf="newKeywordFormGroup.errors?.keywordNotUnique">
        <div class="alert alert-danger" role="alert">
          <strong>Keyword already exists!</strong> Please enter a unique keyword.
        </div>
      </div>
      <div class="form-group">
        <label class="control-label">Rank</label>
        <input type="number" class="form-control" [formControl]="newRankControl" [formValidation]="newRankControl" lpValidationMsg/>
      </div>
      <div *ngIf="isLocalCampaign" class="form-group">
        <label class="control-label">Display location last?</label>
        <div>
          <shared-components-customchoice [value]="newKeywordIsAreaLast" [choiceTrue]="'Yes'"
            [choiceFalse]="'No'" (valueChange)="updateKeywordIsAreaLast($event)">
          </shared-components-customchoice>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
      <button type="button" class="btn btn-success" (click)="addKeyword(modal)">Save</button>
    </div>
  </div>
</ng-template>