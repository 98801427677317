<div [loadingOverlay]="isLoading">
	<div class="row">
		<ng-container *ngIf="onsiteRecommendationDetail">
			<div class="row">
				<div class="col-lg-4">
					<div class="form-group" [formGroup]="recommendationForm">
						<label class="control-label">Note to onsite recommendation specialist</label>
						<textarea formControlName="Note" class="form-control" rows="6"></textarea>
					</div>
				</div>
				<div class="col-lg-4" [formGroup]="recommendationForm">
					<label>Who is implementing recommendations on the client's site?</label>
					<div class="form-group">
						<div class="radio">
							<label>
								<input formControlName="Responsibility" type="radio" name="Responsibility" [value]="2" />
								Boostability edits the client's site
							</label>
						</div>
					</div>
					<div class="form-group">
						<div class="radio">
							<label>
								<input formControlName="Responsibility" type="radio" name="Responsibility" [value]="1" />
								Customer edits their own site with Boostability recommendations
							</label>
						</div>
					</div>
					<div class="form-group">
						<div class="radio">
							<label>
								<input formControlName="Responsibility" type="radio" name="Responsibility" [value]="3" />
								Partner edits the site with Boostability recommendations
							</label>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="animated fadeIn">
						<ng-container *ngIf="allowsCustomerContact">
							<div class="form-group">
								<label>If the customer is set to edit their own site should the system automatically send onsite recommendation emails?</label>
								<span>
									<shared-components-customchoice [(value)]="isSendAutomaticallyChecked"
										(valueChange)="isSendAutomaticallyCheckedChanged($event)">
									</shared-components-customchoice>
								</span>
							</div>
						</ng-container>
						<ng-container *ngIf="!allowsCustomerContact">
							<div>
								<label class="control-label">
									LaunchPad will not send automatic emails to this customer. On approval of the
									Onsite Recommendations, send them according to the partner procedure.
								</label>
							</div>
						</ng-container>
					</div>
					<div *ngIf="isSendAutomaticallyChecked && allowsCustomerContact" class="card card-body bg-light d-block text-start animated fadeIn">
						<div class="form-group" [formGroup]="contactForm">
							<label class="checkbox-inline">
								<input formControlName="customerContactChecked" type="checkbox" lpValidationMsg />
								Send to Customer Contact
							</label>
						</div>
						<div class="form-group" [formGroup]="contactForm">
							<label class="checkbox-inline">
								<input formControlName="technicalContactChecked" type="checkbox" lpValidationMsg />
								Send to Technical Contact
							</label>
						</div>
						<div *ngIf="isTechnicalContactChecked" class="animated fadeInDown">
							<div [formGroup]="technicalContactForm" class="form-group">
								<label class="control-label" for="name">Technical Contact Name</label>
								<input formControlName="technicalContactName" type="text" class="form-control" id="name"
									lpValidationMsg />
							</div>
							<div [formGroup]="technicalContactForm" class="form-group">
								<label class="control-label" for="email">Technical Contact Email</label>
								<input formControlName="technicalContactEmail" type="text" class="form-control"
									id="technicalContactEmail" lpValidationMsg />
							</div>
							<div [formGroup]="technicalContactForm" class="form-group">
								<label class="control-label" for="phone">Technical Contact Phone</label>
								<input formControlName="technicalContactPhone" type="text" class="form-control"
									id="technicalContactPhone" lpValidationMsg />
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>