<div>
  <div [loadingOverlay]="isLoading">
    <div class="row" style="margin-bottom: 4px;">
      <div class="col-md-6">
        <ng-container *ngIf="useSmallTitle">
          <strong>Task Files</strong>
        </ng-container>
        <ng-container *ngIf="!useSmallTitle">
          <h3 class="control-label" style="margin-top: 0;">Task Files</h3>
        </ng-container>
      </div>
      <div class="col-md-6 text-end">
        <ng-container *ngIf="allowUpload">
          <ng-container *ngIf="useSmallTitle">
            <button (click)="uploadFile()" class="btn btn-success btn-sm">
              <i class="fa fa-plus"></i>
              Upload
            </button>
          </ng-container>
          <ng-container *ngIf="!useSmallTitle">
            <button (click)="uploadFile()" class="btn btn-success btn-sm">
              <i class="fa fa-plus"></i>
              Upload
            </button>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-table [items]="files">
          <ng-template #tableHeader>
            <tr>
              <th sort field="Name">Name</th>
              <th sort field="Upload Date">Upload Date</th>
              <th *ngIf="isSourced" sort field="Source">Source</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-file>
            <tr>
              <td>{{file.Name}}</td>
              <td>{{file.InsertedDate | dateText }}</td>
              <td *ngIf="isSourced">{{file.source}}</td>
              <td style="width:50px;">
                <a [externalLink]="{ url: file.S3Url, text:'View File'}" class="btn btn-primary"></a>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>