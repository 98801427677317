<div class="card">
	<div class="card-header">
		<span>Application Overview</span>
		<button (click)="loadData()" class="btn btn-primary btn-sm float-end">
			<i class="fas fa-sync"></i>
			Refresh
		</button>
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body">
		<div class="row">
			<ng-container *ngIf="statusSummary()">
				<div class="col-md-2">
					<h4 class="card card-body bg-light d-block">
						<i class="fa fa-spinner"></i>
						<span>{{statusSummary().PendingJobCount}}</span>
					</h4>
					<h5>Pending  Jobs</h5>
				</div>
				<div class="col-md-2">
					<h4 class="card card-body bg-light d-block">
						<i class="fa fa-check-square"></i>
						<span>{{statusSummary().CompletedJobCount}}</span>
					</h4>
					<h5>Today's Completed Jobs</h5>
				</div>
				<div class="col-md-2">
					<h4 class="card card-body bg-light d-block">
						<i class="fa fa-bomb"></i>
						<span>{{statusSummary().FailureCount}}</span>
					</h4>
					<h5>Today's Failed Jobs</h5>
				</div>
				<div class="col-md-2">
					<h4 class="card card-body bg-light d-block">
						<i class="fas fa-cog"></i>
						<span>{{statusSummary().ProcessingCount}}</span>
					</h4>
					<h5>Processing Jobs</h5>
				</div>
				<div class="col-md-2">
					<h4 class="card card-body bg-light d-block">
						<i class="fa fa-wrench"></i>
						<span>{{statusSummary().DeletedCount}}</span>
					</h4>
					<h5>Deleted Count</h5>
				</div>
			</ng-container>
		</div>
	</div>
</div>
