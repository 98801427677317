import { Component, EventEmitter, Input, Output } from '@angular/core';
import TaskUserSkipRequest = Boo.UserSkips.Objects.TaskUserSkipRequest;
import CustomerTaskTypeUserSkipRequest = Boo.UserSkips.Objects.CustomerTaskTypeUserSkipRequest;
import { UserSkipService } from '../../../services/user-skip.service';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-components-specialist-skipcustomer',
    templateUrl: './SkipCustomer.component.html'
})
export class SkipCustomerComponent {
    @Input() customerId: number;
    @Input() customerIsTopWorkPriority: boolean;
    @Input() taskId: number;
    @Input() taskTypeSummary: Boo.Objects.TaskTypeSummary;
    @Output() public saved: EventEmitter<any> = new EventEmitter();
    @Output() public canceled: EventEmitter<any> = new EventEmitter();

    public reason: UntypedFormControl = new UntypedFormControl('');
    public isLoading: boolean = false;
    public skipThisTaskType: boolean = false;

    constructor(private userSkipService: UserSkipService) { }

    public cancel(): void {
        this.clearForm();
        this.canceled.emit();
    }

    public save(): void {
        this.reason.markAsTouched();
        if (!this.reason.valid) {
            return;
        }

        this.isLoading = true;

        let $skip: Observable<any>;
        if (this.skipThisTaskType) {
            let customerTaskTypeUserSkipRequest: CustomerTaskTypeUserSkipRequest = {
                CustomerId: this.customerId,
                Note: this.reason.value,
                TaskTypeEnum: this.taskTypeSummary.TaskTypeId,
                OriginationTaskId: this.taskId
            };
            $skip = this.userSkipService.skipCustomerTaskType(customerTaskTypeUserSkipRequest);
        } else {
            let customerUserSkipRequest: TaskUserSkipRequest = {
                    CustomerId: this.customerId,
                    Note: this.reason.value,
                    TaskId: this.taskId
                };
            $skip = this.userSkipService.skipTask(customerUserSkipRequest);
        }

        $skip.subscribe(
            () => {
                if (this.skipThisTaskType) {
                    toastr.success(`All customer ${this.taskTypeSummary.Name} tasks will now be skipped for you for 1 business day.`, 'User Task Type Customer Skip', { timeOut: 6000, closeButton: true });
                } else {
                    toastr.success('Customer has been skipped for you for 1 business day.', 'Skip Customer', { timeOut: 4000, closeButton: true });
                }

                this.clearForm();
                this.saved.emit();
            },
            (err) => toastr.error(err),
            () => this.isLoading = false);
    }

    private clearForm(): void {
        this.reason.setValue('');
        this.reason.markAsUntouched();
        this.skipThisTaskType = false;
    }
}
