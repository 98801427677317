import { Component, effect, forwardRef, input, OnInit, signal } from '@angular/core';
import { SessionStorageService } from '../../../services/session-storage.service';
import { finalize } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const US_COUNTRY_ID = 1;

@Component({
  selector: 'app-time-zone-picker',
  templateUrl: './time-zone-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeZonePickerComponent),
      multi: true
    }
  ],
  host: {
    '(blur)': '_onTouched()'
  }
})
export class TimeZonePickerComponent implements OnInit, ControlValueAccessor {
  countryId = input<number>(US_COUNTRY_ID);
  timeZones: TimeZoneWithAbbr[] = [];
  filteredTimeZones: TimeZoneWithAbbr[] = [];
  isLoading: boolean = false;
  timeZone = signal<string>('');
  disabled: boolean = false;
  private _onChange = (_: any) => { };
  private _onTouched = () => { };

  constructor(private sessionStorageService: SessionStorageService) {
    effect(() => this.filterTimeZones());
    effect(() => this._onChange(this.timeZone()));
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.sessionStorageService.getStaticData()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((data: Boo.Objects.LaunchPadStaticData) => {
        this.timeZones = data.TimeZones.map(x => {
          var timeZone = x as TimeZoneWithAbbr;
          timeZone.NameWithAbbr =  timeZone.Name + ' (' + moment.tz(x.Name).format('z') + ')';
          return timeZone;
        });
        this.filterTimeZones();
      });
  }

  writeValue(obj: any): void {
    this.timeZone.set(obj);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private filterTimeZones(): void {
    this.filteredTimeZones = this.timeZones.filter((timeZone) => {
        return timeZone.CountryId === this.countryId();
    })
}
}

interface TimeZoneWithAbbr extends Boo.Objects.TimeZone {
  NameWithAbbr: string;
}