<app-components-specialist-internal-tasks-qa-action-information
  [internalAction]="internalAction"></app-components-specialist-internal-tasks-qa-action-information>
<div [loadingOverlay]="isLoading" id="internal-task-qa-criteria-float-panel" class="float-panel">
  <div class="float-panel-tab">
    <button class="float-panel-btn-expand btn btn-lg btn-primary">
      <i class="fa fa-check"></i>
    </button>
  </div>
  <div class="float-panel-main card">
    <div class="float-panel-draggable card-header">
      <i class="fa fa-check"></i>
      Profile Submission Quality
      <i class="float-panel-btn-collapse fa fa-minus float-end"></i>
    </div>
    <div class="card-body" style="overflow-y: scroll; max-height: 550px;">
      <app-components-qa-grading-area-panel [taskType]="taskType"
        [qaCriteriaGroups]="qaCriteriaGroups" #gradingArea>
      </app-components-qa-grading-area-panel>
    </div>
    <div class="card-footer">
      <div class="text-end">
        <button (click)="close.next()" class="btn btn-primary">Close</button>
        <button (click)="unlock.next()" class="btn btn-info">
          <i class="fa fa-unlock"></i>
          Unlock &amp; Unassign
        </button>
        <button (click)="complete.next()" class="btn btn-success">
          <i class="fa fa-check"></i>
          Complete
        </button>
      </div>
    </div>
  </div>
</div>
<div [loadingOverlay]="isLoading">
  <div class="row"> 
    <ng-container *ngIf="customer">
      <div class="col-md-6">
        <ul class="list-group">
          <li class="list-group-item">
            <strong>Minutes</strong>
            <span class="float-end">{{customer.SeoMinutesPaidRecurring | seoMinutes}}</span>
          </li>
          <li class="list-group-item">
            <strong>Products</strong>
            <ul>
              <ng-container *ngFor="let $data of customer.Spends">
                <li class="form-control-static">
                  <span>{{$data.ProductName}}</span>
                  :
                  <span>{{ $data.RetailSpend | currency }}</span>
                </li>
              </ng-container>
            </ul>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label">Vertical</label>
        <input [value]="vertical?.Name" class="form-control" type="text" readonly />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Country</label>
        <input [value]="country?.Name" class="form-control" type="text" readonly />
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div data-toggle="collapse" href="#task-description" role="button">
            <span>Description</span>
            <i class="only-collapsed fa-caret-down fas fa-2x float-end"></i>
            <i class="only-expanded fa-caret-up fas fa-2x float-end"></i>
          </div>
        </div>
        <div id="task-description" class="collapse show">
          <div class="card-body">
            <div class="form-group">
              <div class="line-breaks">{{customDescription}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="customer">
    <div class="col-md-4">
      <div class="form-group">
        <label>Keyword Research Note</label>
        <textarea [value]="keywordResearchNote" class="form-control" readonly></textarea>
      </div>
    </div>
  </div>
  <div class="form-group">
    <app-components-togglepanel [title]="'Customer Notes'" isOpen="true" toggleBody="true">
      <div class="form-group">
        <form (ngSubmit)="search()">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <input [(ngModel)]="textFilter" [ngModelOptions]="{ standalone: true }" class="form-control"
                  placeholder="Search note" type="text" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <a (click)="clear()" href="javascript:void(0)" class="btn btn-outline-secondary btn-sm">
                  <i class="fa fa-times"></i>
                  Reset
                </a>
                <button (click)="search()" type="submit" class="btn btn-success">
                  <i class="fa fa-search"></i>
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <shared-components-odatagrid *ngIf="dataTableOptions" [options]="dataTableOptions"></shared-components-odatagrid>
      <ng-template #noteGridTemplate let-ViewModel="ViewModel">
        <tr>
          <td>{{ViewModel.InsertedDate | dateText: 'M/DD/YYYY h:mm a' : false }}</td>
          <td>{{ViewModel.Text}}</td>
        </tr>
      </ng-template>
    </app-components-togglepanel>
  </div>
  <div id="keywords-component" *ngIf="customer">
    <app-components-shared-keywords-keyword-research-summary [customer]="customer" [isReadOnly]="true">
    </app-components-shared-keywords-keyword-research-summary>
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a href="#pages" data-toggle="tab" class="nav-link active">Pages / Keywords</a>
          </li>
          <li class="nav-item">
            <a href="#halo" data-toggle="tab" class="nav-link">Halo Keywords</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" id="pages">
            <app-components-shared-keywords-url-suggestions-readonly [urlSuggestions]="suggestedUrls">
            </app-components-shared-keywords-url-suggestions-readonly>
            <ng-container *ngIf="unorderedUrls.length !== 0">
              <app-components-shared-keywords-website-urls-readonly [websiteUrls]="unorderedUrls"></app-components-shared-keywords-website-urls-readonly>
            </ng-container>
          </div>
          <div class="tab-pane" id="halo">
            <app-components-shared-keywords-halo-keyword-suggestions-readonly [haloKeywords]="suggestedHaloKeywords">
            </app-components-shared-keywords-halo-keyword-suggestions-readonly>
            <ng-container *ngIf="activeHaloKeywords.length !== 0">
              <app-components-shared-keywords-halo-keywords-readonly class="pb-3" [haloKeywordPhrases]="activeHaloKeywords"></app-components-shared-keywords-halo-keywords-readonly>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
</div>