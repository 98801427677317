import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerSeoWorkDefaultsService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/partnerseoworkdefaults`;

  constructor(private http: HttpClient) { }

  get(partnerId: number): Observable<Boo.Objects.Work.PartnerSeoWorkDefault[]> {
    return this.http.get<Boo.Objects.Work.PartnerSeoWorkDefault[]>(`${this.baseUrl}/${partnerId}`);
  }

  getPartnerSeoMinuteReservationDefaults(partnerId: number): Observable<Boo.Objects.Work.PartnerSeoMinuteReservationDefault[]> {
    return this.http.get<Boo.Objects.Work.PartnerSeoMinuteReservationDefault[]>(`${this.baseUrl}/${partnerId}/seominutereservationdefaults`);
  }

  saveDefaultMinuteReservations(partnerId: number, reservations: Boo.Objects.Work.PartnerSeoMinuteReservationDefault[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/${partnerId}/seominutereservationdefaults`, reservations);
  }

  save(partnerId: number, partnerSeoWorkDefaults: Boo.Objects.Work.PartnerSeoWorkDefault[]): Observable<any> {
    return this.http.post(`${this.baseUrl}`, {
        partnerId: partnerId,
        partnerSeoWorkDefaults: partnerSeoWorkDefaults
    });
  }

  getTaskTypes(partnerId: number): Observable<Boo.Objects.Work.SeoMinute.SeoWorkTaskType[]> {
    return this.http.get<Boo.Objects.Work.SeoMinute.SeoWorkTaskType[]>(`${this.baseUrl}/${partnerId}/tasktypes`);
  }

  getProducts(partnerId: number): Observable<Boo.Objects.Work.PartnerProductSummary[]> {
    return this.http.get<Boo.Objects.Work.PartnerProductSummary[]>(`${this.baseUrl}/${partnerId}/products`);
  }

  generateSeoWorkDefaults(partnerId: number, hours: number, partnerSeoWorkDefaults: Boo.Objects.Work.PartnerSeoWorkDefault[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/${partnerId}/generateSeoWorkDefaults`, {
        partnerId: partnerId,
        hours: hours,
        partnerSeoWorkDefaults: partnerSeoWorkDefaults
    });
  }

  getConfiguredPartners(): Observable<Boo.Objects.PartnerFragment[]> {
    return this.http.get<Boo.Objects.PartnerFragment[]>(`${this.baseUrl}/configuredPartners`);
  }
}
