import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { finalize } from 'rxjs/operators';
import { NotificationDefinition } from '../../../shared/models/notifications/notification-definition.model';
import { PriorityViewModel } from 'app/models/PriorityViewModel';

@Component({
  selector: 'app-components-manager-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
  isLoading: boolean = false;
  notifications: NotificationDefinition[] = [];

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.notificationService.getDefinitions()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        notifications => this.notifications = notifications,
        err => toastr.error(err)
      );
  }

  openTemplates(definition: NotificationDefinition): void {
    if (definition.Templates) {
      PriorityViewModel.ngShow('app-notification-definition', { definition: definition });
      return;
    }

    this.isLoading = true;
    this.notificationService.getTemplates(definition.NotificationDefinitionId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        templates => {
          definition.Templates = templates;
          PriorityViewModel.ngShow('app-notification-definition', { definition: definition });
        },
        err => toastr.error(err)
      );  
  }
}