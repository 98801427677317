import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FeatureGroupService } from '../../../services/feature-group.service';
import { SeoMinuteFeatureService } from '../../../services/seo-minute-feature.service';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { finalize, forkJoin } from 'rxjs';
import { KeywordSuggestionConfigFactory } from '../../../factories/KeywordSuggestionConfigFactory';
import Utils from '../../../shared/utils';

type PartnerPricingToolFeature = Boo.Objects.SEOPresence.PartnerPricingToolFeature;

@Component({
    selector: 'app-components-manager-seominutefeatures',
    templateUrl: './edit-seo-minute-features.html',
    styleUrls: ['./edit-seo-minute-features.scss']
})
export class EditSeoMinuteFeaturesComponent implements OnChanges {
    @Input() priceLevels: Boo.Objects.SEOPresence.PartnerPricingToolPriceLevel[];
    @Input() partnerId: number;
    @Input() languageId: number;

    featureGroups: Boo.Objects.SEOPresence.FeatureGroup[];
    seoMinuteFeatures: Boo.Objects.SEOPresence.SeoMinuteFeature[];

    minuteLevels: MinuteLevel[] = [];

    isLoading: boolean = false;

    private keywordSuggestionConfigFactory = new KeywordSuggestionConfigFactory();

    constructor(private featureGroupService: FeatureGroupService, private seoMinuteFeatureService: SeoMinuteFeatureService) { }

    ngOnChanges(): void {
        this.resetMinuteLevels();
    }

    suggestChanges() {
        this.resetFeatureGroup(Boo.Objects.SEOPresence.FeatureGroups.Pages);
        this.resetFeatureGroup(Boo.Objects.SEOPresence.FeatureGroups.MainKeywords);
        this.resetFeatureGroup(Boo.Objects.SEOPresence.FeatureGroups.HaloKeywords);

        let keywordValues: FeatureGroupValue[] = [];
        let pageValues: FeatureGroupValue[] = [];
        let haloKeywordValues: FeatureGroupValue[] = [];

        this.minuteLevels
            .map(x => x.Minutes)
            .forEach(minutes => {
                let config = this.keywordSuggestionConfigFactory.create(minutes, false, this.partnerId);
                keywordValues.push({
                    minutes: minutes,
                    groupValue: config.minimumMainKeywords
                });

                pageValues.push({
                    minutes: minutes,
                    groupValue: config.minimumPages
                });

                haloKeywordValues.push({
                    minutes: minutes,
                    // If the minimum halo keywords is 50 we display it as 100 in the pricing tool
                    groupValue: config.minimumHaloKeywordSuggestions === 50
                        ? 100
                        : config.minimumHaloKeywordSuggestions
                });
            });

        keywordValues = Utils.filterDuplicates(keywordValues, (x, y) => x.groupValue === y.groupValue);
        pageValues = Utils.filterDuplicates(pageValues, (x, y) => x.groupValue === y.groupValue);
        haloKeywordValues = Utils.filterDuplicates(haloKeywordValues, (x, y) => x.groupValue === y.groupValue);

        this.implementFeatureGroupValues(haloKeywordValues, Boo.Objects.SEOPresence.FeatureGroups.HaloKeywords);
        this.implementFeatureGroupValues(keywordValues, Boo.Objects.SEOPresence.FeatureGroups.MainKeywords);
        this.implementFeatureGroupValues(pageValues, Boo.Objects.SEOPresence.FeatureGroups.Pages);
    }

    saveChanges() {
        this.isLoading = true;

        let mutuallyExclusiveGroupDisplayOrder: number[] = [];
        let results: Boo.Objects.SEOPresence.SeoMinuteFeature[] = [];
        let index = 0;

        this.minuteLevels.forEach(level => {
            level.Features.forEach(feature => {
                let group = this.featureGroups.find(x => x.FeatureGroupId == feature.FeatureGroupId);

                if (!group.MutuallyExclusive) {
                    results.push(this.createSeoMinuteFeature(
                        feature.PartnerPricingToolFeatureId,
                        index++,
                        level.Minutes
                    ));
                    return;
                }

                if (mutuallyExclusiveGroupDisplayOrder[feature.FeatureGroupId] === undefined) {
                    mutuallyExclusiveGroupDisplayOrder[feature.FeatureGroupId] = index++;
                }

                results.push(this.createSeoMinuteFeature(
                    feature.PartnerPricingToolFeatureId,
                    mutuallyExclusiveGroupDisplayOrder[feature.FeatureGroupId],
                    level.Minutes
                ));
            });
        });

        this.seoMinuteFeatureService.replace(results, this.partnerId, this.languageId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: () => {
                    toastr.success('Changes saved successfully.');
                },
                error: () => {
                    toastr.error('An error occurred while saving changes.');
                }
            });
    }

    dropListDropped(event: CdkDragDrop<PartnerPricingToolFeature[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    createFeatureGroupPredicate(featureGroupId: number) {
        return (drag: CdkDrag<PartnerPricingToolFeature>) => drag.data.FeatureGroupId === featureGroupId;
    }

    createMutualExclusionPredicate() {
        return ((drag: CdkDrag<PartnerPricingToolFeature>, drop: CdkDropList<PartnerPricingToolFeature[]>) => {
            if (!(this.featureGroups.find(x => x.FeatureGroupId == drag.data.FeatureGroupId).MutuallyExclusive)) {
                return true;
            }

            return !drop.data.some(x => x.FeatureGroupId == drag.data.FeatureGroupId);
        }).bind(this);
    }

    private resetMinuteLevels() {
        this.isLoading = true;
        this.featureGroups = [];
        this.seoMinuteFeatures = [];

        forkJoin([this.featureGroupService.getAllTranslated(this.languageId), this.seoMinuteFeatureService.getTranslated(this.partnerId, this.languageId)])
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(([featureGroups, seoMinuteFeatures]) => {
                this.featureGroups = featureGroups;
                this.seoMinuteFeatures = seoMinuteFeatures;

                //Remove features already added to a level
                this.featureGroups.forEach(group => {
                    group.PartnerPricingToolFeatures = group.PartnerPricingToolFeatures.filter(feature => !this.seoMinuteFeatures.some(seoFeature => seoFeature.PartnerPricingToolFeatureId === feature.PartnerPricingToolFeatureId));
                });

                let priceLevelMinuteLevels = this.priceLevels
                    .filter(x => x.PartnerPricingToolPriceLevelId !== 0 && x.SeoMinutes)
                    .map(x => x.SeoMinutes);
                let featureMinuteLevels = this.seoMinuteFeatures.map(x => x.MinimumSeoMinutes);

                this.minuteLevels = Utils.filterDuplicates(priceLevelMinuteLevels.concat(featureMinuteLevels))
                    .sort((a, b) => a - b)
                    .map(x => {
                        return {
                            Minutes: x,
                            Features: this.seoMinuteFeatures.filter(y => y.MinimumSeoMinutes === x).map(y => y.PartnerPricingToolFeature)
                        };
                    });
            });
    }

    private createSeoMinuteFeature(partnerPricingToolFeatureId: number, displayOrder: number, minimumSeoMinutes: number): Boo.Objects.SEOPresence.SeoMinuteFeature {
        return {
            PartnerId: this.partnerId,
            LanguageId: this.languageId,
            PartnerPricingToolFeatureId: partnerPricingToolFeatureId,
            DisplayOrder: displayOrder,
            MinimumSeoMinutes: minimumSeoMinutes
        };
    }

    private moveFeature<T>(feature: T, fromArray: T[], toArray: T[], appendToStart?: boolean): void {
        const index = fromArray.findIndex(x => x === feature);
        if (index !== -1) {
            if (appendToStart) {
                toArray.unshift(fromArray.splice(index, 1)[0]);
            } else {
                toArray.push(fromArray.splice(index, 1)[0]);
            }
        }
    }

    private resetFeatureGroup(featureGroupId: number): void {
        let featureGroup = this.featureGroups.find(x => x.FeatureGroupId === featureGroupId);
        this.minuteLevels.forEach(level => {
            level.Features.forEach(feature => {
                if (feature.FeatureGroupId === featureGroupId) {
                    this.moveFeature(feature, level.Features, featureGroup.PartnerPricingToolFeatures);
                }
            });
        });
    }

    private implementFeatureGroupValues(values: FeatureGroupValue[], featureGroupId: number): void {
        let groupFeatures = this.featureGroups.find(x => x.FeatureGroupId === featureGroupId)?.PartnerPricingToolFeatures;

        if (!groupFeatures) {
            return;
        }

        values.forEach(value => {
            let level = this.minuteLevels.find(x => x.Minutes === value.minutes);
            let feature = groupFeatures.find(x => x.FeatureGroupValue === value.groupValue);
            this.moveFeature(feature, groupFeatures, level.Features, true);
        });
    }
}

interface MinuteLevel {
    Minutes: number;
    Features: PartnerPricingToolFeature[];
}

interface FeatureGroupValue {
    minutes: number;
    groupValue: number;
}