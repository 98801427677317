<div class="col-md-12">
  <table class="table table-hover mb-4">
    <thead>
      <tr>
        <th>
          <div class="d-flex align-items-center">
            <div>
              <app-components-shared-page-status
                [websiteUrl]="websiteUrl"
                [websiteUrls]="websiteUrls"
                [haloKeywords]="haloKeywords"
                [archivedWebsiteUrls]="archivedWebsiteUrls"
                [archivedKeywords]="archivedKeywords"
                [firstPageKeywordPhrases]="firstPageKeywordPhrases"
                [restrictions]="restrictions">
              </app-components-shared-page-status>
            </div>
            <div class="flex-grow-1">
              <app-components-shared-page-update
                [websiteUrl]="websiteUrl"
                [websiteUrls]="websiteUrls"
                [readOnlyReasons]="allReadOnlyReasons"
                [restrictions]="restrictions">
              </app-components-shared-page-update>
            </div>
            <div *ngIf="validationMessages.length > 0" class="text-danger fw-normal me-3">
              {{ validationMessages[0] }}
            </div>
            <div *ngIf="websiteUrl.WebsiteUrlTask">
              <app-components-shared-onsite-task-information 
                [websiteUrl]="websiteUrl"
                [customerId]="customer.CustomerId"
                [restrictions]="restrictions"
                [reviewSource]="reviewSource">
              </app-components-shared-onsite-task-information>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let websiteUrlKeyword of websiteUrl.Keywords">
        <ng-container *ngIf="websiteUrlKeyword.IsActive">
          <tr>
            <td>
              <app-components-shared-keyword
                [customer]="customer"
                [websiteUrlKeyword]="websiteUrlKeyword"
                [websiteUrl]="websiteUrl"
                [websiteUrls]="websiteUrls"
                [haloKeywords]="haloKeywords"
                [archivedWebsiteUrls]="archivedWebsiteUrls"
                [archivedKeywords]="archivedKeywords"
                [firstPageKeywordPhrases]="firstPageKeywordPhrases"
                [restrictions]="restrictions">
              </app-components-shared-keyword>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <tr>
        <td>
          <div class="row">
            <div class="col-auto">
              <app-components-shared-add-keyword
                [websiteUrl]="websiteUrl"
                [customer]="customer"
                [websiteUrls]="websiteUrls"
                [haloKeywords]="haloKeywords"
                [archivedKeywords]="archivedKeywords"
                [restrictions]="restrictions">
              </app-components-shared-add-keyword>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>