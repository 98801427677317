<div class="row">
	<div class="col-lg-12">
		<ng-container *ngIf="guarantee">
			<ng-container *ngIf="canAddGuaranteeViolation()">
				<div class="row text-end" style="margin-right: 0px;">
					<button (click)="add()" class="btn btn-sm btn-danger">
						<i class="fa fa-plus"></i>
						Violation
					</button>
				</div>
			</ng-container>
			<div class="row">
				<div class="col-lg-1">
					<div>
						<h3 class="text-success">
							<ng-container *ngIf="guarantee.HasBeenAchieved">
								<i class="fa fa-check"></i>
							</ng-container>
						</h3>
					</div>
				</div>
				<div class="col-lg-11">
					<strong>Guarantee</strong>
					<div>
						<ng-container *ngIf="!guarantee.HasStarted">
							<small>Has not started</small>
						</ng-container>
					</div>
					<div>
						<ng-container *ngIf="guarantee.HasStarted">
							<small>
								<strong>From</strong>
								<span>{{guarantee.Start | dateText : 'L' : true }}</span>
								–
								<span>{{guarantee.End | dateText : 'L' : true }}</span>
							</small>
						</ng-container>
					</div>
					<div>
						<ng-container *ngIf="guarantee.HasBeenAchieved">
							<small>
								<strong>Achieved on</strong>
								<span>{{guarantee.AchievedOn | dateText : 'L' : true }}</span>
							</small>
						</ng-container>
					</div>
					<div>
						<ng-container *ngIf="!guarantee.HasBeenAchieved">
							<small>Has not been achieved</small>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="row top10">
				<div class="offset-lg-1 col-lg-8">
					<strong>Violations</strong>
					<span class="badge bg-secondary">{{violations().length}}</span>
				</div>
			</div>
			<ng-container *ngIf="violations().length > 0">
				<div class="row">
					<div class="offset-lg-1 col-lg-11">
						<ul class="list-group">
							<ng-container *ngFor="let $data of violations()">
								<li class="list-group-item">
									<strong>
										<span>{{getTypeName($data.Type)}}</span>
									</strong>
									:
									<span>{{$data.Description}}</span>
								</li>
							</ng-container>
						</ul>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</div>
</div>
