import { Directive, Input, ElementRef, OnChanges, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[numericValue]'
})
export class NumericValueDirective implements AfterViewInit, OnChanges {
  private static DefaultPrecision = 2;
  @Input('numericValue') public Settings: { value: KnockoutObservable<number>, valueUpdate: string, precision: number };

  private element: HTMLInputElement;
  constructor(elementRef: ElementRef) {
    this.element = elementRef.nativeElement;
  }

  public ngAfterViewInit(): void {
    let updateEvents = [];
    //keyup and change only works if precision is set to 0.
    if (this.Settings.valueUpdate === 'afterkeydown' && this.Settings.precision === 0) {
      //updateEvents.push('keyup');
      updateEvents.push('input');
      updateEvents.push('change');
    } else {
      updateEvents.push('blur');
    }
    for (let updateEvent of updateEvents) {
      this.element.addEventListener(updateEvent, () => {
        const value = parseFloat(this.element.value) || 0;
        let precision = ko.utils.unwrapObservable(this.Settings.precision);
        if (precision === undefined) {
          precision = NumericValueDirective.DefaultPrecision;
        }
        let formattedValue = value.toFixed(precision);
        if ($.isFunction(this.Settings.value)) {
          this.Settings.value(parseFloat(formattedValue));
          this.element.value = formattedValue;
        }
      });
    }
  }

  public ngOnChanges(): void {
    let value = ko.utils.unwrapObservable(this.Settings.value);
    let formattedValue = '0';

    if (typeof value === 'number') {
      let precision = ko.utils.unwrapObservable(this.Settings.precision);
      if (precision === undefined) {
        precision = NumericValueDirective.DefaultPrecision;
      }
      formattedValue = value.toFixed(precision);
    }

    this.element.value = formattedValue;
  }
}