import { Component, Input, output } from '@angular/core';
import { PageSuggestion } from '../../../../shared/models/keywords/keywords';
import { KeywordConfig } from '../../../../shared/models/keywords/keywords';

@Component({
  selector: 'app-components-shared-keyword-research-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class SummaryComponent{
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestions: PageSuggestion[];
  @Input() keywordConfig: KeywordConfig;
  campaignTypeChanged = output();

  get pageCount(): number {
    return this.pageSuggestions
      ? this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).length
      : 0;
  }

  get keywordCount(): number {
    return this.pageSuggestions
      ? this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
        .flatMap(x => x.KeywordSuggestions)
        .filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).length
      : 0;
  }
}
