<div [class.card]="urls.length > 0" [class.card-warning]="urls.length === 0" class="card">
	<div class="card-body">
		<p>The following keywords have been flagged as underperforming based on age and performance in LaunchPad.</p>
		<button class="btn btn-primary" (click)="viewReportingSite()">
			<i class="fas fa-chart-line"></i>
			View Current Rankings on Dashboard
		</button>
	</div>
	<table class="table table-striped">
		<thead>
			<tr>
				<th>Status</th>
				<th>Target URL</th>
				<th>
					Keywords (
					<span class="text-danger">Underperforming</span>
					)
				</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngFor="let $data of urls">
				<tr>
					<td>{{$data.Status}}</td>
					<td>
						<a [attr.href]="$data.Url" target="_blank" rel="noreferrer">
							<span>{{$data.Url}}</span>
						</a>
					</td>
					<td>
						<ul class="list-group">
							<ng-container *ngFor="let $data2 of $data.Keywords">
								<li [class.text-danger]="$data2.IsUnderPerforming" class="list-group-item">{{$data2.Keyword}}</li>
							</ng-container>
						</ul>
					</td>
				</tr>
			</ng-container>
		</tbody>
	</table>
	<div [hidden]="!(urls.length === 0 && isLoaded)" class="card-body">
		<p class="text-warning">No Underperforming Keywords. Keywords that were flagged as underperforming may have been moved to Halo or removed from the customer. Please complete the task.</p>
	</div>
</div>
