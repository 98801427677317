<div class="card">
  <div class="card-header">
    <span>Blog Feedback</span>
    <button (click)="close()" type="button" class="btn-close float-end"></button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="oldTitle" class="control-label">Original Title</label>
          <input readonly [ngModel]="input.TaskPost.ContentTitle"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="oldTitle" class="form-control"
            placeholder="Enter Title" />
        </div>
        <div class="form-group">
          <label class="control-label">Original Content</label>
          <textarea [richText]="{ content: input.TaskPost.Content, options: editorToolbar, autoGrow: true }"></textarea>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="newTitle" class="control-label">Reviewed Title</label>
          <input readonly [ngModel]="output.TaskPost.ContentTitle"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }" name="newTitle" class="form-control"
            placeholder="Enter Title" />
        </div>
        <div class="form-group">
          <label class="control-label">Reviewed Content</label>
          <textarea
            [richText]="{ content: output.TaskPost.Content, options: editorToolbar, autoGrow: true }"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 offset-md-6">
        <div class="form-group">
          <label for="title" class="control-label">Editor Feedback</label>
          <div class="clear-fix"></div>
          <div>
            <textarea #editorRichText [richText]="{ content: output.TaskPost.ReviewerComment, options: editorToolbar }"
              name="editorsFeedback" rows="15" style="height: 300px;" class="form-control"
              placeholder="Enter Content"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer text-end">
    <button (click)="close()" class="btn btn-link">Close</button>
  </div>
</div>