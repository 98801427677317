    <div class="card">
      <div class="card-header">
        Publish Options
      </div>
      <div class="card-body" [loadingOverlay]="isLoading">
        <div class="row">
          <div class ="col-lg-12"> 
						<div class="card-body">
							<app-components-publishoptions [customer]="customer">
              </app-components-publishoptions>
						</div>
          </div>
        </div>
      </div>
    </div>
<div class="card">
  <div class="card-header">
    External Access
    <small class="text-warning float-end">
      <strong>Notice</strong>
      All password retrieval requests are recorded for security purposes.
    </small>
  </div>
  <div class="card-body">
    <app-tab-panel [displayMode]="tabPanelDisplayMode.horizontal">
      <app-dynamic-tab [title]="'Website'">
        <app-components-managecustomer-websiteaccess *tabContent [options]="{
          customerId: customer.CustomerId(),
          customerAccountTypes: websites,
          customer: customer,
          showSaveButton: false,
          getCurrentImplementationResponsibility: getCurrentImplementationResponsibility.bind(this),
          publicApiCallback: customerAccountGroupApiCallback.bind(this),
          showContentManagementSystem: true,
          showBoostabilityAccessOption: true
        }"></app-components-managecustomer-websiteaccess>
      </app-dynamic-tab>
      <app-dynamic-tab [title]="'SEO'">
        <compose *tabContent Selector="app-components-managecustomer-seocustomeraccounts" [ActivateOptions]="{
          customerId: customer.CustomerId(),
          customerAccountTypes: seo,
          customer: customer,
          showSaveButton: false,
          isEnabled: isNotIonosPartner,
          publicApiCallback: customerAccountGroupApiCallback.bind(this)
        }"></compose>
      </app-dynamic-tab>
      <app-dynamic-tab [title]="'Blog'">
        <app-components-managecustomer-customeraccountgroup *tabContent  [options]="{
          customerId: customer.CustomerId(),
          customerAccountTypes: blog,
          showSaveButton: false,
          isEnabled: isNotIonosPartner(),
          publicApiCallback: customerAccountGroupApiCallback.bind(this)
        }"></app-components-managecustomer-customeraccountgroup>
      </app-dynamic-tab>
    </app-tab-panel>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-body bg-light d-block text-end">
          <button (click)="save()" class="btn btn-success" [disabled]="isLoading">
            <i class="fa fa-save"></i>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>