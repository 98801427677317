<div class="card">
  <div class="card-header">
    Customer Search
  </div>
  <div [loadingOverlay]="isLoading()" class="card-body">
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label class="control-label">Customer Status</label>
          <select [ngModel]="selectedStatus()" (ngModelChange)="selectedStatus($event)"
            [ngModelOptions]="{ standalone: true }" name="customerStatuses" id="customerStatuses" class="form-select">
            <option *ngFor="let option of customerStatuses()" [ngValue]="option.value">{{ option.name }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="control-label">Language</label>
          <select [ngModel]="selectedLanguage()" (ngModelChange)="selectedLanguage($event)"
            [ngModelOptions]="{ standalone: true }" name="languages" id="languages" class="form-select">
            <option *ngFor="let option of languages()" [ngValue]="option.LanguageId">{{ option.Name }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label class="control-label">Show Only</label>
          <select [ngModel]="selectedFilter()" (ngModelChange)="selectedFilter($event)"
            [ngModelOptions]="{ standalone: true }" class="form-select">
            <option value="All">All</option>
            <option value="My Customers">My Customers</option>
          </select>
        </div>
      </div>
      <ng-container *ngIf="canSelectPartner">
        <div class="col-md-2">
          <div class="form-group">
            <label class="control-label">Partner</label>
            <ng-select class="form" [(ngModel)]="selectedPartnerId" [clearable]="false">
              <ng-option [value]="0">All Partners</ng-option>
              <ng-option *ngFor="let partner of partners" [value]="partner.PartnerId">{{ partner.PartnerName
                }}</ng-option>
            </ng-select>
          </div>
        </div>
      </ng-container>
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">Search</label>
          <input [dataValidation]="{ placement: 'top', value: searchText }" [ngModel]="searchText()"
            (ngModelChange)="searchText($event)" [ngModelOptions]="{ standalone: true }"
            [attr.placeholder]="'Minimum 3 characters'" [returnKey]="search.bind(this)" type="text"
            id="customerSearchText" class="form-control" name="searchText" />
        </div>
      </div>
      <div class="col-md-1">
        <label class="control-label d-block">&nbsp;</label>
        <button [disabled]="isLoading()" (click)="search()" class="btn btn-primary">
          <i class="fa fa-search"></i>
          Search
        </button>
      </div>
    </div>
    <div class="card card-body bg-light">NOTE: Searching with "All Statuses" may take several seconds. Results are
      limited to 10,000.</div>
    <hr />

    <div class="row">
      <div class="col-md-12">
        <h5 [hidden]="!hasResults()">
          <i class="fa fa-search"></i>
          &nbsp;
          <span>{{resultCount()}}</span>
          &nbsp;results for
          <span>{{searchContextText()}}</span>
        </h5>
        <app-table [items]="customers()" [exportFilename]="'customer-search'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="CustomerId">ID</th>
              <th export sort field="Name">Name</th>
              <th export sort field="Url">Website</th>
              <th export sort field="Products">Products</th>
              <th export sort field="SignupDate">Signup Date</th>
              <th export sort field="Status">Status</th>
              <th export sort field="CsrName">CSR</th>
              <th export sort field="TeamName">Team</th>
              <th export sort field="DaysTillRenewal">Days to Renew</th>
              <th export sort field="Partner">Partner</th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-customer>
            <tr>
              <td>
                <button (click)="openCustomer(customer)" class="btn btn-primary">
                  <i class="fas fa-external-link-square-alt"></i>
                  <span>{{customer.CustomerId}}</span>
                </button>
              </td>
              <td>{{customer.Name}}</td>
              <td>
                <a [externalLink]="{ url: customer.Url, text:customer.Url}" rel="noreferrer"></a>
              </td>
              <td [innerHtml]="customer.Products"></td>
              <td>{{customer.SignupDate | dateText : 'L' : true }}</td>
              <td>{{customer.Status}}</td>
              <td>{{customer.CsrName}}</td>
              <td>{{customer.TeamName}}</td>
              <td>{{customer.DaysTillRenewal}}</td>
              <td>{{customer.Partner}}</td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>