import { Component, computed, effect, forwardRef, input, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDate, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    }
  ]
})
export class DateTimePickerComponent implements ControlValueAccessor {
  date = signal<Date>(new Date());
  min = input(null, {
    transform: (date: Date | null) => {
      if (!date) {
        date = new Date();
      }
      return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
    }
  });
  max = input(null, {
    transform: (date: Date | null) => {
      if (!date) {
        return null;
      }
      return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
    }
  });
  ngbDate = computed(() => new NgbDate(this.date().getFullYear(), this.date().getMonth() + 1, this.date().getDate()));
  ngbTime = computed<NgbTimeStruct>(() => {
    return { hour: this.date().getHours(), minute: this.date().getMinutes(), second: this.date().getSeconds() };
  })
  displayString = computed(() => this.date().toLocaleString());
  disabled = signal<boolean>(false);
  private _onChange = (_: any) => { };
  private _onTouched = () => { };

  constructor() {
    effect(() => this._onChange(this.date()));
  }

  updateDate(date: NgbDate) {
    this.date.set(new Date(date.year, date.month - 1, date.day, this.ngbTime().hour, this.ngbTime().minute));
    this._onTouched();
  }

  updateTime(time: NgbTimeStruct) {
    this.date.set(new Date(this.ngbDate().year, this.ngbDate().month - 1, this.ngbDate().day, time.hour, time.minute));
    this._onTouched();
  }

  writeValue(obj: any): void {
    if (!obj) {
      return;
    }
    obj.setSeconds(0);
    this.date.set(obj);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }
}
