<div class="card">
  <div class="card-header">
    To Do History
  </div>
  <div [loadingOverlay]="loading()" class="card-body">
    <div>

      <app-table [items]="todoHistory()" [pageSize]="50">
        <ng-template #tableHeader>
          <tr>
            <th sort field="Status">Status</th>
            <th sort field="InsertedDate">Inserted Date</th>
            <th sort field="FullName">Inserted By</th>
            <th sort field="Content">To Do</th>
            <th sort field="StatusDate">Status Updated Date</th>
            <th sort field="StatusFullName">Status Updated By</th>
          </tr>
        </ng-template>
        <ng-template #tableBody let-todoHistory>
          <tr>
            <td>{{getStatusName(todoHistory.Status)}}</td>
            <td>{{todoHistory.InsertedDate | dateText: 'M/DD/YYYY h:mm a' }}</td>
            <td>{{todoHistory.FullName}}</td>
            <td>
              <span>{{todoHistory.Content}}</span>
              <button [hidden]="!(todoHistory.HasNotes)" (click)="getNotes(todoHistory)"
                class="btn btn-primary btn-sm float-end">View Notes</button>
            </td>
            <td>{{todoHistory.StatusDate | dateText: 'M/DD/YYYY h:mm a' }}</td>
            <td>{{todoHistory.StatusFullName}}</td>
          </tr>
        </ng-template>
      </app-table>
    </div>
    <div class="row">
      <div class="col-md-4 offset-md-4 text-center">
        <button [hidden]="!hasMore()" (click)="loadMore()" class="btn btn-primary">
          <i class="fas fa-arrow-down"></i>
          Load More
        </button>
      </div>
    </div>
    <div class="modal fade" id="todoNotesModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3>To Do Notes</h3>
          </div>
          <div class="modal-body">
            <ng-container *ngFor="let todoNote of loadedNotes()">
              <div class="row">
                <div class="col-md-12">
                  <strong>
                    <span>{{todoNote.InsertedDate | dateText }}</span>
                  </strong>
                  <br />
                  <span>{{todoNote.Content}}</span>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="modal-footer">
            <button (click)="closeNotesModal()" class="btn btn-primary float-end mt-3">Done</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>