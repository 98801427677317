/* tslint:disable */

import { Component } from '@angular/core';
import timer from 'app/managecustomer/Timer';
import { CustomerNoteService } from '../../../services/customer-note.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-managecustomer-revisenote',
	templateUrl: './ReviseNote.component.html'
})
export class ReviseNoteComponent {
    public dfd: any;
    public title = 'Note Editor';
    public isLoading: any = ko.observable(false);
    public parent: any = ko.observable();
    public customer: any = ko.observable();
    public revisedNote: any = ko.observable();
    public originalNote: any = ko.observable();
    public contactTypes: any = ko.observableArray([]);
    public partnerAssignedUsers: any = ko.observableArray([]);
    public completeValidation: any = ko.validatedObservable();
    public toggleCustomerInfoCollapsed: any = ko.observable(false);

    constructor(
      private customerNoteService: CustomerNoteService,
      private sessionStorageService: SessionStorageService) { }

    public close(isSuccess: any): void {
        if (this.dfd) {
            if (isSuccess === true) {
                this.dfd.resolve();
            } else {
                this.dfd.reject();
            }
        }
    }

    public partnerName: any = ko.computed(() => {
        const c = ko.mapping.toJS(this.customer);
        if (!c) {
            return '';
        }
        return c.Partner ? c.Partner.Name : '';
    });

    public saveNote(): void {
        this.completeValidation.errors.showAllMessages(false);
        if (this.completeValidation.isValid()) {
            if (this.revisedNote().Text() === this.originalNote().Text() && this.revisedNote().ContactTypeId() === this.originalNote().ContactTypeId()) {
                toastr.error('No changes have been made to Note Text or Note Contact Type.');
            } else {

              Utils.wrapDfd(this.customerNoteService.revise(ko.mapping.toJS(this.revisedNote())))
                .then(() => {
                    toastr.success('The revised note has been saved.');
                    timer.resetTicketSaveTime();
                    this.close(true);
                })
                .fail((displayMessage) => {
                    toastr.error(displayMessage);
                });
            }
        }
    }

    public activate(options: any): void {
        $.when(Utils.wrapDfd(this.sessionStorageService.getStaticData())).done((staticData) => {
            options = options || {};
            if (_.isObject(options) && _.isObject(options.parent)) {
                this.parent(options.parent);
            }
            if (_.isObject(options) && _.isObject(options.customer)) {
                this.customer(options.customer);
            } else {
                toastr.error('Customer is undefined.');
                return;
            }
            if (_.isObject(options.note) && this.staticDataIsValid(staticData)) {
                this.contactTypes(_.filter(staticData.ContactTypes, (x: any) => { return x.ContactTypeId !== window.launchpad.config.ContactTypeEnum.NoteRevision; }));
                this.revisedNote(ko.mapping.fromJS(options.note));
                this.originalNote(ko.mapping.fromJS(options.note));
                this.setupValidation();
            }
        });
    }

    private staticDataIsValid(staticData: any): any {
        return _.isObject(staticData) && _.isArray(staticData.ContactTypes);
    }

    private contactTypeIdIsValid(val: any): any {
        let result = true;
        if (_.isString(this.revisedNote()) && $.trim(this.revisedNote()) !== '') {
            result = _.isUndefined(val) === false && _.isNull(val) === false;
        }
        return result;
    }

    public setupValidation(): void {
        this.revisedNote().Text.extend({
            required: { message: 'Additional Comment is a required field.' },
            validation: { validator: window.launchpad.utils.containsNoHtml, message: 'Html tags are not allowed.  You cannot have a < followed by a >' },
            maxLength: 4000
        });
        this.revisedNote().ContactTypeId.extend({
            validation: { validator: this.contactTypeIdIsValid.bind(this), message: 'Contact Type selection is required.' }
        });

        this.completeValidation = ko.validatedObservable({
            note: this.revisedNote().Text,
            contactType: this.revisedNote().ContactType
        });
    }
}
