<div>
  <div class="row">
    <div class="col-md-12">
      <ul class="list-group">
        <li class="list-group-item" *ngIf="onsiteBloggingImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Implementation Task</strong>
            </div>
            <div class="col-md-10">
              {{ onsiteBloggingImplementationSummary.TaskTypeName }}
            </div>
          </div>
        </li>
        <li class="list-group-item" *ngIf="onsiteBloggingImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Description</strong>
            </div>
            <div class="col-md-10">
              {{ onsiteBloggingImplementationSummary.Description }}
            </div>
          </div>
        </li>
        <li class="list-group-item" *ngIf="onsiteBloggingImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Instructions</strong>
            </div>
            <div class="col-md-10">
              <div class="preserve-whitespace" *ngIf="onsiteBloggingImplementationSummary.Instructions">{{ onsiteBloggingImplementationSummary.Instructions }}</div>
              <ng-container *ngIf="!onsiteBloggingImplementationSummary.Instructions && !onsiteBloggingImplementationSummary.SaveAsDraft">
                Publish: This content is ready to be published on the customer's blog
              </ng-container>
              <ng-container *ngIf="!onsiteBloggingImplementationSummary.Instructions && onsiteBloggingImplementationSummary.SaveAsDraft">
                <div class="alert alert-danger">
                  <strong>Save as Draft:</strong> This content is ready to be saved on the customer's blog. <strong>Do not</strong> post this content in a live post.
                </div>
              </ng-container>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Blog Url</strong>
            </div>
            <div class="col-md-10">
              <ng-container *ngIf="blogUrl">
                <a [externalLink]="{ url: blogUrl, text:blogUrl }" name="blogUrl"></a>
              </ng-container>
              <ng-container *ngIf="!blogUrl">
                Not found
              </ng-container>
            </div>
          </div>
        </li>
        <li class="list-group-item"  *ngIf="publishUrl">
          <div class="row">
            <div class="col-md-2">
              <strong>Publish Url</strong>
            </div>
            <div class="col-md-10">
              <a [externalLink]="{ url: publishUrl, text: publishUrl}" rel="noreferrer" style="cursor: pointer;"></a>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Publish User</strong>
            </div>
            <div class="col-md-10">
              <ng-container *ngIf="publishUser">
                The customer would like the Publish User to be: <strong>{{ publishUser }}</strong>
              </ng-container>
              <ng-container *ngIf="!publishUser">
                No preference
              </ng-container>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Date to Publish</strong>
            </div>
            <div class="col-md-10">
              <ng-container *ngIf="publishDate">
                {{ publishDate | dateText }}
              </ng-container>
              <ng-container *ngIf="!publishDate">
                N/A
              </ng-container>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Page Title</strong>
            </div>
            <div class="col-md-10">
              {{ pageTitle }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Image</strong>
            </div>
            <div class="col-md-10">
              <ng-container *ngIf="imageUrl">
                <a [externalLink]="{ url: imageUrl, text:'Image' }" name="imageUrl"></a>
              </ng-container>
              <ng-container *ngIf="!imageUrl">
                Not found
              </ng-container>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Meta Description</strong>
            </div>
            <div class="col-md-10">
              {{ newDescription }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Content Title / H1</strong>
            </div>
            <div class="col-md-10">
              {{ contentTitle }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>H2</strong>
            </div>
            <div class="col-md-10">
              <ng-container *ngIf="newH2">
                {{ newH2 }}
              </ng-container>
              <ng-container *ngIf="!newH2">
                N/A
              </ng-container>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Content Body</strong>
            </div>
              <div class="col-md-10" [innerHTML]="content"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <br />
  <br />
  <div class="row" *ngIf="onsiteBloggingImplementationSummary">
    <div class="col-md-6">
      <app-components-specialist-taskfiles [options]="{
        taskId: onsiteBloggingImplementationSummary.TaskId,
        allowUpload: true,
        apiCallback: taskFilesApiCallback,
        useSmallTitle: true
      }"></app-components-specialist-taskfiles>
    </div>
  </div>
  <app-floating-panels>
    <app-floating-panel [displayConfig]="{ title: 'External Access', icon: 'fa-key', size: 'floating-panel-md' }">
      <compose *floatingPanelContent Selector="app-components-managecustomer-readonlyexternalaccess" [Model]="{ customer: currentAction().Customer }">
      </compose>
    </app-floating-panel>
    <app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
      <app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="currentAction().TaskDetails.TaskNotes">
      </app-components-specialist-actioncomponents-comments>
    </app-floating-panel>
  </app-floating-panels>
</div>
