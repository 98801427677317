import { Component } from '@angular/core';
import System from 'framework/System';
import BaseComponent from '../BaseComponent';
import { SaveTypes } from 'app/models/enums/SaveTypes';

@Component({
	selector: 'app-components-ticket-ticketcompleteconfirmation',
	templateUrl: './TicketCompleteConfirmation.component.html'
})
export class TicketCompleteConfirmationComponent extends BaseComponent {
    private defaults: app.ticket.interfaces.ITicketCompleteConfirmationComponentFeatureConfig = {
        message: 'Are you sure?',
        yesButtonLabel: 'Yes',
        noButtonLabel: 'No'
    };

    private message: string;
    private yesButtomLabel: string;
    private noButtonLabel: string;

    public activate(params: app.ticket.components.interfaces.ITicketCompleteConfirmationViewModelActivateParams): JQueryPromise<void> {
        return super.activate(params)
            .then(() => {
                this.message = params.featureConfig.message || this.defaults.message;
                this.yesButtomLabel = params.featureConfig.yesButtonLabel || this.defaults.yesButtonLabel;
                this.noButtonLabel = params.featureConfig.noButtonLabel || this.defaults.noButtonLabel;
            });
    }

    public confirmSave(saveType: SaveTypes): JQueryPromise<boolean> {
        return saveType === SaveTypes.Complete ? $.Deferred<boolean>((dfd) => {
            bootbox.dialog({
                message: this.message,
                closeButton: false,
                buttons: {
                    yes: {
                        label: this.yesButtomLabel,
                        className: 'btn-success',
                        callback: (): any => {
                            dfd.resolve(true);
                        }
                    },
                    no: {
                        label: this.noButtonLabel,
                        className: 'btn-danger',
                        callback: (): any => {
                            dfd.resolve(false);
                        }
                    }
                }
            });
        }).promise() : System.resolvedPromise(true);
    }
}
