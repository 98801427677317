import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskHistoryService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/taskhistory`;

  constructor(private http: HttpClient) { }

  get(id: number): Observable<Boo.Objects.TaskHistory> {
    const params = new HttpParams().set('id', id);

    return this.http.get<Boo.Objects.TaskHistory>(`${this.baseUrl}/Get`, { params: params });
  }
}
