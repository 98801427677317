import { Component } from '@angular/core';
import { PreAuditReportService } from '../../../services/pre-audit-report.service';
import BaseComponent from '../BaseComponent';
import Utils from '../../../shared/utils';
import { PreAuditReportConfig, PreAuditReportSection, PreAuditReportItemStatus } from './pre-audit-report-config';

@Component({
    selector: 'app-components-ticket-preauditreport',
    templateUrl: './pre-audit-report.component.html',
})
export class PreAuditReportComponent extends BaseComponent {
  public mobileItems: PreAuditReportItem[] = [];
  public localItems: PreAuditReportItem[] = [];
  public organicSearchItems: PreAuditReportItem[] = [];
  public websiteBuildItems: PreAuditReportItem[] = [];
  public unconfiguredItems: string[] = [];
  public PreAuditReportItemStatus = PreAuditReportItemStatus;
  reportLoaded = false;

  public isOpen = false;
  constructor(private preAuditReportService: PreAuditReportService) { 
    super();
  }

  public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
    return super.activate(params)
        .then(() => {
            return Utils.wrapDfd(this.preAuditReportService.getLatest(this.customer.CustomerId()));
        }).then((preAuditReport) => {
            if (!preAuditReport) {
              return;
            }

            let data = preAuditReport.ReportData;
            Object.keys(data).forEach((key) => {
              if (PreAuditReportConfig[key]) {
                PreAuditReportConfig[key].forEach((config) => {
                  let item = {
                    section: config.section,
                    title: config.title,
                    displayData: data[key] ? config.generateDisplayData(data[key]) : "No data",
                    status: data[key] ? config.generateStatus(data[key]) : PreAuditReportItemStatus.Warning
                  }
                  switch (item.section) {
                    case PreAuditReportSection.Mobile:
                      this.mobileItems.push(item);
                      break;
                    case PreAuditReportSection.Local:
                      this.localItems.push(item);
                      break;
                    case PreAuditReportSection.OrganicSearch:
                      this.organicSearchItems.push(item);
                      break;
                    case PreAuditReportSection.WebsiteBuild:
                      this.websiteBuildItems.push(item);
                      break;
                    default:
                      break;
                  }
                });
              }
            })

            this.reportLoaded = true;
        });
  }
}

class PreAuditReportItem {
  public title: string;
  public displayData: string;
  public status: PreAuditReportItemStatus;
}
