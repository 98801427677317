<div>
  <label class="control-label" *ngIf="showTitle"><strong>{{name}}:</strong></label>
  <div *ngIf="showTitle && description !== null">
    <label class="control-label">{{description}}</label>
  </div>
  <ng-select [items]="labeledDefinitions" bindLabel="label" [(ngModel)]="selectedDefinition"
    [groupBy]="isGrouped ? 'group' : null" (change)="definitionChange()" [clearable]="!required" [searchable]="false"
    [ngClass]="{'has-error' : validated && !validateCriteria()}">
  </ng-select>
  <app-components-qa-stock-comment-select *ngIf="showStockComments"
    [stockComments]="selectedStockComments" (insertStockComment)="insertStockComment($event)">
  </app-components-qa-stock-comment-select>
  <textarea *ngIf="showComment" [(ngModel)]="comment" class="spaced-content form-control"
    [ngClass]="{'has-error' : validated && !validateComment()}">
</textarea>
</div>