/* tslint:disable */

import { Component } from '@angular/core';
import { TicketService } from '../../../services/ticket.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-stats-userticketsummary',
	templateUrl: './UserTicketSummary.component.html'
})
export class UserTicketSummaryComponent {
  public dfd: any;
  public title = 'User Ticket Summary';
  public isLoading: any = ko.observable(false);
  public startDate: any = ko.observable(moment(moment().format('YYYY-MM-01')));
  public endDate: any = ko.observable(moment());
  public showCallsMade: any = ko.observable(true);
  public showEmailsSent: any = ko.observable(true);
  public showNotesEntered: any = ko.observable(true);
  public showTicketsCompleted: any = ko.observable(true);

  public chart: any = ko.observable({});
  public data: any = ko.observableArray([]);
  public taskTypes: any = ko.observableArray([]);

  constructor(private ticketService: TicketService) { }

  public loadStats(): any {
    // make sure user doesn't load more than a year's data or the graph may blow up
    if (this.startDate() && this.endDate()) {
      const maxDays = 365;
      const actualDays = this.endDate().diff(this.startDate(), 'days');
      if (actualDays > maxDays) {
        toastr.error('You cannot load more than a year\'s worth of data at a time.');
        return;
      }
    }
    this.isLoading(true);
    Utils.wrapDfd(this.ticketService.getUserTicketSummary(this.startDate().format('L'), (this.endDate().format('L') + ' 23:59:59')))
      .done((data: any) => {
        data = _.map(data,
          (row: any) => {
            const newRow: any = {};
            newRow.MetricDate = moment.utc(row.MetricDate).format('L');
            // We're only going to map the items we want to show
            if (this.showCallsMade()) {
              newRow.CallsMade = row.CallsMade;
            }
            if (this.showEmailsSent()) {
              newRow.EmailsSent = row.EmailsSent;
            }
            if (this.showNotesEntered()) {
              newRow.NotesEntered = row.NotesEntered;
            }
            if (this.showTicketsCompleted()) {
              newRow.TicketsCompleted = row.TicketsCompleted;
            }
            return newRow;
          });
        this.chart({
          title: {
            text: 'User Ticket Summary',
            x: -20
          },
          xAxis: {
            categories: _.map(data,
              (row: any) => {
                return row.MetricDate;
              }),
            labels: {
              rotation: 45
            }
          },
          yAxis: {
            title: {
              text: ''
            },
            plotLines: [
              {
                value: 0,
                width: 1,
                color: '#808080'
              }
            ],
            min: 0
          },
          legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            borderWidth: 0
          },
          series: (() => {
            const s = [];
            if (this.showCallsMade()) {
              s.push({
                name: 'Calls Made',
                data: _.map(data,
                  (row: any) => {
                    return row.CallsMade;
                  })
              });
            }
            if (this.showEmailsSent()) {
              s.push({
                name: 'Emails Sent',
                data: _.map(data,
                  (row: any) => {
                    return row.EmailsSent;
                  })
              });
            }
            if (this.showNotesEntered()) {
              s.push({
                name: 'Notes Entered',
                data: _.map(data,
                  (row: any) => {
                    return row.NotesEntered;
                  })
              });
            }
            if (this.showTicketsCompleted()) {
              s.push({
                name: 'Tickets Completed',
                data: _.map(data,
                  (row: any) => {
                    return row.TicketsCompleted;
                  })
              });
            }
            return s;
          })()
        });
      }).fail((displayMessage) => {
        toastr.error(displayMessage);
      }).always(() => {
        this.isLoading(false);
      });
  }

  public close(): void {
    if (this.dfd) {
      this.dfd.resolve();
    }
  }

  public activate(): void {
    this.loadStats();
  }
}
