import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-components-shared-keywords-ticket-historical-keyword-suggestions',
  templateUrl: './historical-keyword-suggestions-ticket.component.html'
})
export class HistoricalKeywordSuggestionsTicketComponent {
  @Input() historicalKeywordSuggestions: Boo.Objects.LegacyKeywordSuggestion[];
  @Input() isNational: boolean;
  @Output() historicalKeywordReAdded: EventEmitter<Boo.Objects.LegacyKeywordSuggestion> = new EventEmitter<Boo.Objects.LegacyKeywordSuggestion>();

  reAddKeyword(suggestion: Boo.Objects.LegacyKeywordSuggestion) {
    this.historicalKeywordReAdded.emit(suggestion);
  }
}
