import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-components-manager-allocation-test-results',
  templateUrl: './allocation-test-results.component.html',
  styleUrls: ['./allocation-test-results.component.scss']
})
export class AllocationTestResultsComponent {
  @Input() seoWorkDefaults: Boo.Objects.Work.SeoMinute.SeoWorkDefault[];
  @Input() reservedMinutes: number;
  @Input() testedHours: number;

  get minutes(): number {
    return this.testedHours * 60;
  }

  get isAllocationValid(): boolean {
    return this.allocatedMinutes + this.reservedMinutes === this.minutes;
  }

  get allocatedMinutes(): number {
    if (!this.seoWorkDefaults.length) {
      return 0;
    }

    return this.seoWorkDefaults.map(item => item.TaskType.BillableMinutes * item.TaskCount).reduce((a, b) => a + b);
  }

  get minuteDifferenceMessage(): string {
    if (this.minutes > this.allocatedMinutes + this.reservedMinutes) {
      return `${ this.minutes - this.allocatedMinutes } Under`;
    } else {
      return `${ this.allocatedMinutes - this.minutes } Over`;
    }
  }

  get showTable(): boolean {
    return this.seoWorkDefaults?.some(x => x.TaskCount) || this.reservedMinutes > 0;
  }
}