/* tslint:disable */

export default class customer {
    public Csr = <any>null
    public CsrUserId = 0
    public CampaignEndDate = "0001-01-01T00:00:00"
    public CampaignStartDate = "0001-01-01T00:00:00"
    public Considerations = <any>null
    public Country = <any>null
    public CountryId = 0
    public CustomerId = 0
    public SeoContentSurvey = <any>null
    public Description = <any>null
    public Email = <any>null
    public GoogleAccessToken = <any>null
    public GoogleProfileId = <any>null
    public GoogleRefreshToken = <any>null
    public Heading = <any>null
    public HighProfileDate = "0001-01-01T00:00:00"
    public HighProfileReason = <any>null
    public IgnoreKeywordMisspellings = false
    public InsertedDate = "0001-01-01T00:00:00"
    public IsGettingStartedFinished = false
    public IsHighProfile = false
    public IsTopWorkPriority = false
    public Language = <any>null
    public LanguageId = 0
    public LastBilledDate = "0001-01-01T00:00:00"
    public MinimumContractLength = 0
    public Name = <any>null
    public Partner = <any>null
    public PartnerCsr = <any>null
    public PartnerCsrUserId = 0
    public PartnerId = 0
    public PartnerName = <any>null
    public Phone = <any>null
    public Products = <any>null
    public MerchantFields = <any>null
    public SeoMinutesRollover = 0
    public SalesRep = <any>null
    public StatusHistory = <any>null
    public StatusId = 0
    public Synopsis = <any>null
    public SeoMinutesFree = 0
    public SeoMinutesTotal = 0
    public Team = <any>null
    public TeamId = 0
    public TimeZone = <any>null
    public UnpauseDate = "0001-01-01T00:00:00"
    public Url = <any>null
    public UseTwentyFourHourClock = false
    public Users = <any>null
    public VerifyUrlsAreAccessible = false
    public Vertical = <any>null
    public VerticalId = 0
    public WebsiteMaintenanceHours = 0
}
