<div class="card">
  <div class="card-header">
    <span>Vertical Management</span>
    <button (click)="addVertical()" class="btn btn-primary btn-sm float-end">
      <i class="fa fa-plus"></i>
      Add
    </button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label class="control-label">
            Filter&nbsp;
            <app-components-helptext [key]="'VerticalManagement_Filter'"></app-components-helptext>
          </label>
          <input [ngModel]="searchText()" (ngModelChange)="searchText($event)" [ngModelOptions]="{ standalone: true }"
            class="form-control" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-table [items]="filteredVerticals()" [exportFilename]="'verticals'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="Name">Name</th>
              <th export sort field="ParentVerticalName">Parent Name</th>
              <th export sort field="CustomersInVertical">Customers In Vertical</th>
              <th export sort field="InsertedDate">Inserted Date</th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-vertical>
            <tr>
              <td>
                <button (click)="openVertical(vertical)" class="btn btn-primary">
                  <i class="fas fa-external-link-square-alt"></i>
                  <span>{{vertical.Name}}</span>
                </button>
              </td>
              <td>
                <ng-container *ngIf="vertical.ParentVerticalId > 0">
                  <button (click)="openParentVertical(vertical)" class="btn btn-primary">
                    <i class="fas fa-external-link-square-alt"></i>
                    <span>{{vertical.ParentVerticalName}}</span>
                  </button>
                </ng-container>
              </td>
              <td>{{vertical.CustomersInVertical}}</td>
              <td>{{vertical.InsertedDate | dateText: 'MM/DD/YYYY h:mm:ss a' }}</td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>