import { Component } from '@angular/core';
import permissions from 'app/models/Permissions';
import { GuaranteeService } from '../../../services/guarantee.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-guarantees-single',
  templateUrl: './Single.component.html'
})
export class SingleComponent implements IActivatable {
    public canAddGuaranteeViolation: KnockoutObservable<boolean>;
    public guarantee: Boo.Objects.Guarantee;
    public types: Boo.Objects.GuaranteeViolationType[];
    public violations: KnockoutComputed<Boo.Objects.GuaranteeViolation[]>;
    private api: app.components.guarantees.interfaces.IGuaranteeComponentPublicApi;

    constructor(private guaranteeService: GuaranteeService) { }

    public activate(params: app.components.guarantees.interfaces.IGuaranteeSingleScopeActivationParams): JQueryPromise<void> {
        this.api = params.api;
        this.guarantee = params.guarantee;

        this.violations = ko.computed(() => {
            return this.guarantee.Violations.filter(x => !this.guarantee.Start || (x.InsertedDate >= this.guarantee.Start && x.InsertedDate <= this.guarantee.End));
        });

        return Utils.wrapDfd(this.guaranteeService.getViolationTypes())
          .then(types => {
              this.types = types;
          });
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        this.canAddGuaranteeViolation = ko.observable((<Launchpad>launchpad).hasPermission(partner, user.PartnerUsers, permissions.CanAddGuaranteeViolation, user));
        return true;
    }

    public add(): void {
        this.api.add(this.guarantee);
    }

    public getTypeName(guaranteeViolationTypeId: number): string {
        return _.first(this.types.filter(x => { return x.GuaranteeViolationTypeId === guaranteeViolationTypeId; })).Name;
    }
}
