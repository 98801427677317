import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import TabPanelCssFactory from '../../../../factories/TabPanelCssFactory';
import { TabPanelCssConfig } from '../../../../../Scripts/app/models/TabPanelCssConfig';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';
import { TabBase } from '../tab-base';

@Component({
  selector: 'app-tab-panel',
  templateUrl: './tab-panel.component.html',
  styleUrls: ['./tab-panel.component.scss'],
})
export class TabPanelComponent implements OnInit, AfterViewInit {
  @Input() displayMode: TabPanelDisplayMode;
  @Input() activeTab: string;
  @Input() style: TabPanelCssConfig;
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  css: TabPanelCssConfig;
  private activeTabId: string;

  @ContentChildren(TabBase) public tabs: QueryList<TabBase>;

  ngOnInit(): void {
    this.css = TabPanelCssFactory.getCss(this.displayMode ?? TabPanelDisplayMode.smallVertical, this.style);
  }

  ngAfterViewInit(): void {
    if (this.activeTab) {
      // This forces the code to wait until after angular's change detection has finished, avoiding ChangedAfterChecked errors
      setTimeout(() => {
        this.tabs.forEach(x => x.active = x.title === this.activeTab);
        this.setInitialTab(this.tabs.find(x => x.title === this.activeTab));
      }, 0)
    } else if (!this.tabs.reduce((x, y) => x || y.active, false)) {
      setTimeout(() => {
        this.tabs.first.active = true;
        this.setInitialTab(this.tabs.first);
      }, 0);
    } else {
      setTimeout(() => {
        this.setInitialTab(this.tabs.find(x => x.active));
      }, 0);
    }
  }

  tabSelected(id: string): void {
    if (id === this.activeTabId) {
      return;
    }

    var previousTab = this.tabs.find(x => x.id === this.activeTabId);
    var tab = this.tabs.find(x => x.id === id);
    this.activeTabId = id;
    this.selected.emit(tab.title);

    setTimeout(() => {
      previousTab.deselected();
      tab.selected();
    })
  }

  isTitleBadgeDanger(titleBadge: string): boolean {
    return !!Number(titleBadge);
  }

  private setInitialTab(tab: TabBase): void {
    this.activeTab = tab.title;
    this.activeTabId = tab.id;
    tab.selected();
  }
}
