import { Component, Input, OnInit } from '@angular/core';
import TaskHelper from 'app/specialist/TaskHelper';
import { TaskKeyValuePairService } from '../../../services/task-keyvaluepair.service';
import { WorkflowService } from '../../../services/workflow.service';
import IPriorityViewPublicApi = app.interfaces.IPriorityViewPublicApi;
import { SessionStorageService } from '../../../services/session-storage.service';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-components-managecustomer-editimplementationinstructions',
  templateUrl: './EditImplementationInstructions.component.html'
})
export class EditImplementationInstructions implements OnInit {
  @Input() previewTaskId: number;
  @Input() requestTaskId: number;
  @Input() isReadOnly: boolean;
  @Input() workflowDefinitionId: Boo.Objects.Enums.WorkflowDefinitionsEnum;
  user: Boo.Objects.User;
  isLoading: boolean;
  title: string = 'Edit Implementation Instructions';
  instructionsKeyValuePair: Boo.Objects.TaskKeyValuePair;
  originalInstructions: string = '';
  additionalInstructions: string = '';
  private priorityViewApi: IPriorityViewPublicApi;

  constructor(
    private taskKeyValuePairService: TaskKeyValuePairService,
    private workflowService: WorkflowService,
    private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    this.isLoading = true;
    if (this.previewTaskId != null && this.workflowDefinitionId === Boo.Objects.Enums.WorkflowDefinitionsEnum.OnsiteBloggingV2) {
      forkJoin([
        this.taskKeyValuePairService.getByTaskIdAndKeyId(this.previewTaskId, Boo.Objects.Enums.KeyEnum.OnsiteBloggingImplementationInstructions),
        this.sessionStorageService.getUser()
      ])
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(([instructionsKeyValuePair, user]) => {
          this.user = user;
          if (instructionsKeyValuePair) {
            this.instructionsKeyValuePair = instructionsKeyValuePair;
            this.originalInstructions = this.instructionsKeyValuePair.KeyValuePair.Value;
          }
          this.isLoading = false;
        });
    } else {
      forkJoin([
        this.workflowService.getOrderingTaskByTaskId(this.requestTaskId),
        this.sessionStorageService.getUser()
      ])
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(([orderingTask, user]) => {
          this.user = user;
          if (orderingTask) {
            this.taskKeyValuePairService.getByTaskIdAndKeyId(orderingTask.TaskId, Boo.Objects.Enums.KeyEnum.OnsiteCopyImplementationInstructions)
              .subscribe(result => {
                if (result) {
                  this.instructionsKeyValuePair = result;
                  this.originalInstructions = this.instructionsKeyValuePair.KeyValuePair.Value;
                }
                this.isLoading = false;
              });
          } else {
            // This is for some historical tasks that fell through the cracks. The data is in all sorts of weird situations, so just make it readonly
            this.isReadOnly = true;
            this.taskKeyValuePairService.getByTaskIdAndKeyId(this.requestTaskId, Boo.Objects.Enums.KeyEnum.OnsiteCopyImplementationInstructions)
              .subscribe((result) => {
                if (result) {
                  this.instructionsKeyValuePair = result;
                  this.originalInstructions = this.instructionsKeyValuePair.KeyValuePair.Value;
                }
                this.isLoading = false;
              });
          }
        });
    }
  }

  saveInstructions(): void {
    this.isLoading = true;

    let newKeyValuePair = this.instructionsKeyValuePair ? this.updateKeyValuePair() : this.createKeyValuePair();

    this.taskKeyValuePairService.save([newKeyValuePair])
      .subscribe(() => {
        this.priorityViewApi.resolve();
      });
  }

  close(): void {
    this.priorityViewApi.reject();
  }

  private createKeyValuePair(): Boo.Objects.TaskKeyValuePair {
    if (this.workflowDefinitionId === Boo.Objects.Enums.WorkflowDefinitionsEnum.OnsiteCopy) {
      return TaskHelper.taskKvpFactory(this.requestTaskId, this.user.UserId, Boo.Objects.Enums.KeyEnum.OnsiteCopyImplementationInstructions, this.additionalInstructions);
    } else {
      return TaskHelper.taskKvpFactory(this.previewTaskId, this.user.UserId, Boo.Objects.Enums.KeyEnum.OnsiteBloggingImplementationInstructions, this.additionalInstructions);
    }
  }

  private updateKeyValuePair(): Boo.Objects.TaskKeyValuePair {
    this.instructionsKeyValuePair.KeyValuePair.Value += '\r\n' + 'Update:' + '\r\n' + this.additionalInstructions;
    return this.instructionsKeyValuePair;
  }
}