<div class="row mb-2">
  <div class="col text-end">
    <app-components-shared-add-page
      [customer]="customer"
      [websiteUrls]="websiteUrls"
      [archivedWebsiteUrls]="archivedWebsiteUrls"
      [restrictions]="restrictions">
    </app-components-shared-add-page>
  </div>
</div>

<ng-container *ngIf="activeWebsiteUrlCount === 0; else showWebsiteUrls">
  <div class="row">
    <div class="col-md-12">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No pages or keywords have been defined yet</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

<ng-template #showWebsiteUrls>
  <div class="row" *ngFor="let websiteUrl of websiteUrls">
    <ng-container *ngIf="isActiveStatus(websiteUrl)">
      <app-components-shared-page
        [customer]="customer"
        [websiteUrl]="websiteUrl"
        [websiteUrls]="websiteUrls"
        [haloKeywords]="haloKeywords"
        [archivedWebsiteUrls]="archivedWebsiteUrls"
        [archivedKeywords]="archivedKeywords"
        [readOnlyReasons]="readOnlyReasons"
        [firstPageKeywordPhrases]="firstPageKeywordPhrases"
        [restrictions]="restrictions"
        [reviewSource]="reviewSource">
      </app-components-shared-page>
    </ng-container>
  </div>
</ng-template>