import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-components-togglepanel',
	templateUrl: './TogglePanel.component.html'
})
export class TogglePanelComponent implements OnInit {
    @Input() isOpen: boolean = false;
    @Output() isOpenChange = new EventEmitter<boolean>();
    @Input() title: string = '';
    @Input() titleBadge: string;
    @Input() toggleBody: boolean = false;

    @Input() isEmpty: boolean;
    @Input() emptyText: string;
    @Input() keepBodyInDomWhenClosed: boolean;
    public showFooter: boolean;
    public css: { [key: string]: string };
    public panelDataValidationOptions: app.components.interfaces.IDataValidationOptions;

    ngOnInit(): void {
        this.showFooter = true;
        this.panelDataValidationOptions = { placement: 'top', value: ko.observable() };

        let defaultCss = {
            wrapper: 'card',
            heading: 'card-header cursor-pointer',
            body: 'card-body',
            footer: 'card-footer text-center',
            btnToggle: 'btn btn-outline-dark btn-sm'
        };
        this.css = defaultCss;
    }

    public hasTitleBadge(): boolean {
        return this.titleBadge && this.titleBadge.toString().length > 0;
    }

    public toggle(): void {
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
    }
}
