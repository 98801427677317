<div class="row">
	<div (click)="toggleTodo()" [attr.title]="setTodoHelpText()" [class]="setTodoClass()" class="cursor-pointer col-md-1">
		<i [class]="setTodoIcon()"></i>
	</div>
	<div class="col-md-11">
		<div class="row">
			<div class="col-md-12">
				<span (click)="toggleTodo()" [class]="setTodoClass()" class="cursor-pointer">
          {{todo.Content()}}
        </span>
				<small [hidden]="!isCustomerTodo()">
					-
					<span>{{todo.InsertedDate() | dateText }}</span>
				</small>

        <div id="todo-actions">
          <span class="cursor-pointer text-muted" title="View/Add Notes">
            <i (click)="openTodo()" [class.text-primary]="hadEditableNote()" class="fa fa-sticky-note fa-lg">
              <ng-container *ngIf="noteCount() > 0">
                <span>{{noteCount()}}</span>
              </ng-container>
            </i>
          </span>
          <ng-container *ngIf="todo.SourceTodoId()">
            <span class="text-info cursor-pointer" title="History">
              <i (click)="loadHistory()" [attr.data-target]="'#' + todoModalId + 'History'" class="fa fa-th-list fa-lg" data-toggle="collapse" data-target="#" data-backdrop="static" data-keyboard="false"></i>
            </span>
          </ng-container>
          <ng-container *ngIf="todo.Status() !== window.launchpad.config.todoStatus.incomplete">
            <span class="text-danger cursor-pointer" title="Cannot complete">
              <i (click)="markTodoIncomplete()" class="fa fa-times-circle fa-lg" data-backdrop="static" data-keyboard="false"></i>
            </span>
          </ng-container>
          <span class="text-info cursor-pointer" title="Copy">
            <i (click)="copyTodo()" class="fas fa-copy fa-lg"></i>
          </span>
        </div>

			</div>
		</div>
		<div [attr.id]="todoModalId + 'History'" class="collapse" style="margin-top: 15px;">
			<ng-container *ngIf="history().length > 0">
				<div class="row">
					<div class="col-md-1"></div>
					<div class="col-md-11">
						<ul class="list-unstyled">
							<ng-container *ngFor="let $data of history()">
								<li class="list-group-item bg-default small">
									<strong>
										<span>{{$data.InsertedDate | dateText }}</span>
										:
									</strong>
									<span>{{$data.Content}}</span>
								</li>
							</ng-container>
						</ul>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div [attr.id]="todoModalId" class="modal fade" id="todoModal">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h3>To Do Status and Notes</h3>
				</div>
				<div class="modal-body">
					<div class="row">
						<ng-container *ngIf="!isCustomerTodo()">
							<div (click)="toggleTodo()" [attr.title]="setTodoHelpText()" [class]="setTodoClass()" class="cursor-pointer col-md-1">
								<i [class]="setTodoIcon()"></i>
							</div>
							<div class="col-md-11">
								<div class="row">
									<div class="col-md-12">
										<span (click)="toggleTodo()" [class]="setTodoClass()" class="cursor-pointer">{{todo.Content()}}</span>
										<ng-container *ngIf="todo.Status() !== window.launchpad.config.todoStatus.incomplete">
											<span class="text-warning cursor-pointer">
												<i (click)="markTodoIncomplete()" class="fa fa-minus-circle"></i>
											</span>
										</ng-container>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="isCustomerTodo()">
							<div [attr.title]="statusMap[todo.Status()].helpText" [class]="statusMap[todo.Status()].class" class="col-md-1">
								<i [class]="statusMap[todo.Status()].icon"></i>
							</div>
							<div class="col-md-11">
								<div class="row">
									<div class="col-md-12">
										<span [class]="statusMap[todo.Status()].class">{{todo.Content()}}</span>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
					<hr/>
					<ng-container *ngIf="todo.TodoNotes().length > 0">
						<div class="row" style="max-height: 200px; overflow-y: auto; overflow-x: hidden;">
							<div class="col-md-12">
								<ng-container *ngFor="let todoNote of todo.TodoNotes()">
									<ng-container *ngIf="!todoNote.IsEditable()">
										<div>
											<ng-container *ngTemplateOutlet="todoNoteTemplate;context:{$context:todoNote}"></ng-container>
										</div>
									</ng-container>
								</ng-container>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="(newNote() && todo.TodoNotes().length > 1) || (!newNote() && todo.TodoNotes().length > 0)">
						<hr/>
					</ng-container>
					<div class="row">
						<div class="col-md-12">
							<ng-container *ngIf="!newNote()">
								<span (click)="addNote()" class="cursor-pointer">
									<i class="fa fa-plus"></i>
									Add Note
								</span>
							</ng-container>
							<ng-container *ngFor="let todoNote of todo.TodoNotes(); let $index = index">
								<ng-container *ngIf="todoNote.IsEditable()">
									<div class="col-md-11">
										<label>Note</label>
										<textarea [dataValidation]="{ placement: 'top', value: todoNote.Content }" [ngModel]="todoNote.Content()" (ngModelChange)="todoNote.Content($event)" [ngModelOptions]="{ standalone: true }" class="form-control" style="resize: none;" maxlength="400"></textarea>
									</div>
									<div class="col-md-1">
										<ng-container *ngIf="todo.Status() !== window.launchpad.config.todoStatus.incomplete">
											<span (click)="deleteNote($index)" tabindex="-1">
												<i class="fas fa-trash-alt cursor-pointer"></i>
											</span>
										</ng-container>
									</div>
								</ng-container>
							</ng-container>
						</div>
					</div>
				</div>
				<div class="modal-footer" style="min-height: 45px;">
					<button [hidden]="!isCustomerTodo()" (click)="cancel()" class="btn btn-primary" title="Cancel">Cancel</button>
					<button (click)="closeTodo()" type="button" class="btn btn-primary" title="Close">OK</button>
				</div>
			</div>
		</div>
	</div>
	<ng-template #todoNoteTemplate let-$context="$context">
		<div class="row">
			<div class="col-md-12">
				<strong>
					<span>{{$context.InsertedDate() | dateText }}</span>
					:
				</strong>
				<span>{{$context.Content()}}</span>
			</div>
		</div>
	</ng-template>
</div>
