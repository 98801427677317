<div *withPermissions="let allow of ['CanViewOnsiteRecommendations', 'CanViewCustomReports', 'CanViewCustomActions', 'CanUpdateAdvancedSettings', 'CanRepublishBlogs', 'CanManageCustomerCampaign']">
	<compose Selector="app-components-managecustomer-floatingcustomernote" [ActivateOptions]="{
                            customerId: customerId,
                            manageCustomerPublicApi: manageCustomerPublicApi
                        }"></compose>
	<compose Selector="app-components-managecustomer-floatingcustomertodolist" [ActivateOptions]="{
														customer: customer,
                            manageCustomerPublicApi: manageCustomerPublicApi
                        }"></compose>
	<compose Selector="app-components-managecustomer-connecteduserssidepanel" [ActivateOptions]="{
                            customerId: customerId,
                            manageCustomerPublicApi: manageCustomerPublicApi
                        }"></compose>
	<div class="card">
		<div class="card-header">
				<strong>{{partnerName()}}</strong>
				— Customer Information
				<button (click)="close()" class="btn-close" title="Close"></button>
		</div>
		<ng-container *ngIf="customer() && partner()">
      <app-components-managecustomer-customerinformation
        [context]="this"
        pageNg="ManageCustomer"
				[showStoreLink]="showStoreLink"
        (pageChangeEvent)="openTab($event)">
      </app-components-managecustomer-customerinformation>
		</ng-container>
	</div>
	<div [hidden]="isLoading()" class="row">
		<div class="col-md-2" style="margin-bottom: 15px;">
			<div [loadingOverlay]="isMenuLoading()" class="card card-body bg-light">
				<ul class="nav nav-pills flex-column">
					<ng-container *ngFor="let $data of children()">
						<ng-container *ngIf="$data.permission === true || allow()[$data.permission]()">
							<li class="nav-item">
								<a (click)="activateItem($data)" href="javascript:void(0)" [class.active]="$data.isActive()" class="nav-link">{{$data.title}}</a>
							</li>
						</ng-container>
					</ng-container>
				</ul>
			</div>
		</div>
		<ng-container *ngIf="activeChild()">
			<div class="col-md-10">
				<compose [Selector]="$any(activeChild()).selector" [ActivateOptions]="{
                                                customer: customer(),
                                                user: user,
                                                partner: partner,
                                                partnerUser: partnerUser,
                                                refreshCustomer: refreshCustomer.bind(this),
                                                parent: this,
                                                apiCallback: $any(activeChild()).apiCallback
                                            }"></compose>
			</div>
		</ng-container>
	</div>
	<div id="activationModal" class="modal fade" role="dialog">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-body">
					<span>Remember to configure the products in the Billing and Products screen after activating the customer.</span>
				</div>
				<div class="modal-footer">
					<button (click)="redirectToConfigureProducts()" type="button" class="btn btn-primary">Configure Products</button>
					<button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</div>
