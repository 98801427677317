<div>
  <div id="blogDetails" class="collapse d-block">
    <div class="card-body">
      <div>
        <ng-container *ngIf="seoContentSurvey()">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="form-group">
                  <label class="control-label">Onsite Blog Restrictions and Considerations</label>
                  <button (click)="checkForBlog()" class="btn btn-primary btn-sm float-end">Check for Blog</button>
                </div>
                <textarea [dataValidation]="{ placement: 'right', value: seoContentSurvey().BlogConsiderations }"
                  [ngModel]="seoContentSurvey().BlogConsiderations()"
                  (ngModelChange)="seoContentSurvey().BlogConsiderations($event)"
                  [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">Publish User <app-components-helptext
                    [key]="'PublishUser'"></app-components-helptext></label>
                <input [dataValidation]="{ placement: 'right', value: seoContentSurvey().PublishUser }"
                  [ngModel]="seoContentSurvey().PublishUser()" (ngModelChange)="seoContentSurvey().PublishUser($event)"
                  [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" />
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">
              Offsite Content Considerations
              <app-components-helptext [key]="'BasicInformation_OffsiteContent_Considerations'"></app-components-helptext>
            </label>
            <textarea [formControl]="considerationsFormControl" lpValidationMsg class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Reason for update</label>
            <textarea [dataValidation]="{ placement: 'right', value: bloggingDetailsUpdateReason }"
              [ngModel]="bloggingDetailsUpdateReason()" (ngModelChange)="bloggingDetailsUpdateReason($event)"
              [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"
              placeholder="Please provide a reason for updating the blogging details"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-body bg-light d-block text-end">
      <button [hidden]="!canClose()" (click)="cancel()" class="btn btn-link">Cancel</button>
      <button [hidden]="!canEdit()" [disabled]="isLoading()" (click)="saveOnsiteBloggingDetails()"
        class="btn btn-success">
        <i class="fa fa-save"></i>
        Update Onsite Blogging Details
      </button>
    </div>
  </div>
</div>