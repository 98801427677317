import { Component } from '@angular/core';

@Component({
  selector: 'app-components-managecustomer-local-profiles',
  templateUrl: './local-profiles.component.html'
})
export class LocalProfilesComponent implements IActivatable {
  customer: Boo.Objects.Customer;

  canActivate?(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: any): boolean {
    this.customer = ko.mapping.toJS(params.customer);
    return true;
  }
}
