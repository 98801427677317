<div class="col-lg-8 offset-lg-2">
  <div class="card">
    <div [loadingOverlay]="isLoading" class="card-body">
      <div class="text-center">
        <img [attr.src]="app.largeLogoUrl()" style="margin: 10px auto;" />
      </div>
      <hr />
      <div class="row">
        <div class="form-horizontal col-md-12" role="form">
          <h4 class="text-center my-4">Reset Password</h4>
          <form class="form-horizontal my-3">
            <div class="form-group row">
              <label class="col-lg-3 col-form-label" for="username">New Password</label>
              <div class="col-lg-9">
                <input [dataValidation]="{ placement: 'top', value: newPassword }" [ngModel]="newPassword()"
                  (ngModelChange)="newPassword($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
                  type="password" class="form-control" />

              </div>
            </div>
            <div class="form-group row" style="margin-bottom: 15px;">
              <label class="col-form-label col-lg-3" for="password">Confirm Password</label>
              <div class="col-lg-9">
                <input [dataValidation]="{ placement: 'top', value: newPasswordVerify }" [ngModel]="newPasswordVerify()"
                  (ngModelChange)="newPasswordVerify($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
                  type="password" class="form-control" />
              </div>
            </div>
          </form>

          <div class="card card-body bg-light d-block text-end mt-5">
            <button (click)="backToLogin()" class="btn btn-link">Cancel</button>
            <button (click)="save()" class="btn btn-primary" [disabled]="isLoading">Submit</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
