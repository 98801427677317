<br />
<div class="row">
  <div class="col-md-6">
    <ul class="list-group">
      <li class="list-group-item">
        <strong>Task Type</strong>
        <span class="float-end">{{internalAction.InternalTaskDetails.TaskTypeName}}</span>
      </li>
      <li class="list-group-item">
        <strong>Customer Name</strong>
        <span class="float-end">{{associatedTaskCustomer.Name}}</span>
      </li>
      <li class="list-group-item">
        <strong>Customer Language</strong>
        <div class="float-end">
          <span class="float-end">{{associatedTaskCustomer.Language.Name}}</span>
        </div>
      </li>
    </ul>
  </div>
  <div class="col-md-6">
    <ul class="list-group">
      <li class="list-group-item">
        <strong>Customer URL</strong>
        <a [externalLink]="{ url: associatedTaskCustomer.Url, text: associatedTaskCustomer.Url }" class="float-end" name="customerUrl"></a>
      </li>
      <li class="list-group-item">
        <strong>Customer Country</strong>
        <div class="float-end">
          <span class="float-end">{{associatedTaskCustomer.Country.Name}}</span>
        </div>
      </li>
      <li class="list-group-item">
        <strong>Customer ID</strong>
        <span class="float-end">{{associatedTaskCustomer.CustomerId}}</span>
      </li>
      <li class="list-group-item">
        <strong>Task Completion</strong>
        <span class="float-end">{{dateString}}</span>
      </li>
    </ul>
  </div>
</div>
