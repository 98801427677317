<div class="card">
	<div class="card-header">
		<span>{{title}}</span>
		<button (click)="close()" class="btn-close" title="Close"></button>
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body">
		<div class="row">
			<div class="col-md-3">
				<div class="form-group d-grid gap-2">
					<button (click)="loadStats()" class="btn btn-primary">
						<i class="fas fa-sync"></i>
						Reload
					</button>
				</div>
				<div class="form-group">
					<label class="control-label">Start Date</label>
					<input [dateTimePicker]="{ date: startDate }" type="text" class="form-control"/>
				</div>
				<div class="form-group">
					<label class="control-label">End Date</label>
					<input [dateTimePicker]="{ date: endDate }" type="text" class="form-control"/>
				</div>
				<p>
					<strong>What metrics would you like to show?</strong>
				</p>
				<div class="form-group">
					<label class="checkbox">
						<input [ngModel]="showCallsMade()" (ngModelChange)="showCallsMade($event)" [ngModelOptions]="{ standalone: true }" type="checkbox"/>
						Calls Made
					</label>
				</div>
				<div class="form-group">
					<label class="checkbox">
						<input [ngModel]="showEmailsSent()" (ngModelChange)="showEmailsSent($event)" [ngModelOptions]="{ standalone: true }" type="checkbox"/>
						Emails Sent
					</label>
				</div>
				<div class="form-group">
					<label class="checkbox">
						<input [ngModel]="showNotesEntered()" (ngModelChange)="showNotesEntered($event)" [ngModelOptions]="{ standalone: true }" type="checkbox"/>
						Notes Entered
					</label>
				</div>
				<div class="form-group">
					<label class="checkbox">
						<input [ngModel]="showTicketsCompleted()" (ngModelChange)="showTicketsCompleted($event)" [ngModelOptions]="{ standalone: true }" type="checkbox"/>
						Tickets Completed
					</label>
				</div>
			</div>
			<div class="col-md-9">
				<div class="chart"><shared-components-chart [Settings]="chart()"></shared-components-chart></div>
			</div>
		</div>
	</div>
</div>
