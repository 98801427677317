import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlacklistDomainService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/blacklistdomain`;

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Boo.Objects.BlacklistDomain[]>(`${this.baseUrl}/GetAll`);
  }

  save(domain: string) {
    return this.http.post<any>(`${this.baseUrl}/Save`, { domain });
  }

  delete(blacklistDomainId: number) {
    return this.http.post<any>(`${this.baseUrl}/Delete`, blacklistDomainId);
  }
}
