<div class="row">
  <span class="col-md-12">Add Task Type</span>
  <div class="col-md-6">
    <div class="row g-0 my-3">
      <div class="col-md-9">
        <ng-select [items]="taskTypes" [multiple]="false" [closeOnSelect]="true" [searchable]="true" [clearable]="false"
          bindLabel="Name" placeholder="Choose..." groupBy="IsCustom" [(ngModel)]="selectedTaskType"
          [searchFn]="searchTaskTypes">

          <ng-template ng-label-tmp let-item="item">
            <i class="fas fa-check-square text-success"></i>
            <b class="ms-2">{{item.Name}}</b>
            <span> ({{ item.Minutes }} minutes)</span>
          </ng-template>

          <ng-template ng-optgroup-tmp let-item="item">
            Automated
            <div class="float-end">
              Minutes
            </div>
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            <i class="fas fa-check-square text-success" title="Available"></i>
            <b class="ms-2">{{item.Name}}</b>
            <span class="float-end">{{ item.Minutes }}</span>
          </ng-template>

        </ng-select>
      </div>
      <div *ngIf="!isReadOnly" class="col-md-3">
        <label class="control-label"></label>
        <button (click)="add()" class="btn btn-primary" [disabled]="!selectedTaskType"><i class="fa fa-plus"></i>Add</button>
      </div>
    </div>
  </div>
</div>
