<small>
  <ng-content></ng-content>
</small>
<div class="input-group">
  <button class="btn btn-outline-secondary dp-btn" [disabled]="picker.disabled" (click)="show()">
    <i class="fas fa-calendar-alt me-0"></i>
  </button>
  <input type="text" readonly class="form-control" ngbDatepicker placeholder="MM/DD/YYYY" firstDayOfWeek="7"
    [minDate]="dateAdapter.fromModel(min)" [maxDate]="dateAdapter.fromModel(max)" (dateSelect)="dateSelect()"
    [(ngModel)]="date" [footerTemplate]="footer" (blur)="onTouched()"/>
  <button class="btn btn-outline-secondary dp-btn close-btn" *ngIf="clearable && !!date" (click)="writeValue(undefined)" title="Clear"><i class="fas fa-times me-0"></i></button>
</div>

<ng-template #footer>
  <hr class="my-0">
  <ng-content select=".footer"></ng-content>
</ng-template>
