<div class="card">
  <div class="card-header">
    <span>Update Implementation Instructions</span>
    <button (click)="close()" type="button" class="btn-close float-end"></button>
  </div>
  <div [loadingOverlay]="isLoading" class="card-body">
    <div class="form-horizontal">
      <h3 *ngIf="isReadOnly">This form is read only because the associated task is Locked, Complete, or Reconcile Complete</h3>
      <ng-container *ngIf="originalInstructions">
        <label class="control-label">Current Instructions to Implementation Team</label>
        <textarea [ngModel]="originalInstructions" class="form-control" readonly>
        </textarea>
      </ng-container>
      <div>
        <label *ngIf="originalInstructions && !isReadOnly" class="control-label">Additional Instructions</label>
        <label *ngIf="!originalInstructions" class="control-label">Instructions</label>
      </div>
      <textarea *ngIf="!isReadOnly || !originalInstructions" [(ngModel)]="additionalInstructions" class="form-control" [disabled]="isReadOnly">
			</textarea>
    </div>
  </div>
  <div class="card-footer text-end">
    <button (click)="close()" class="btn btn-link">Cancel</button>
    <button [disabled]="additionalInstructions.length === 0 || isReadOnly" (click)="saveInstructions()" class="btn btn-success">
      <i class="fa fa-save"></i>
      Update Instructions
    </button>
  </div>
</div>