import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize, forkJoin, Observable, of, switchMap } from 'rxjs';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';
import { KeywordResearchService } from '../../../../services/keyword-research.service';
import { KeywordConfigService } from '../../../../services/keyword-config.service';
import { PageSuggestion } from '../../../../shared/models/keywords/keywords';
import { KeywordConfig } from '../../../../shared/models/keywords/keywords';

@Component({
  selector: 'app-components-shared-keyword-research',
  templateUrl: './keyword-research.component.html',
  styleUrls: ['./keyword-research.component.scss']
})
export class KeywordResearchComponent implements OnInit {
  @Input() customer: Boo.Objects.Customer;
  @Output() redoRequested = new EventEmitter<void>();

  pageSuggestions: PageSuggestion[];
  keywordConfigs: KeywordConfig[];
  keywordConfig: KeywordConfig;
  originalIsCampaignNational?: boolean;
  loading: boolean = false;

  constructor(
    private customerCampaignService: CustomerCampaignService,
    private keywordResearchService: KeywordResearchService,
    private keywordConfigService: KeywordConfigService) { }

  ngOnInit(): void {
    this.loading = true;
    this.load()
      .pipe(finalize(() => this.loading = false))
      .subscribe({ error: (e) => toastr.error(e) });
  }

  load(): Observable<void> {
    this.originalIsCampaignNational = this.customer.IsCampaignNational;

    if (this.customer.IsCampaignNational == null) {
      this.customer.IsCampaignNational = false; // Default to local if not set
    }

    return forkJoin([
      this.keywordResearchService.get(this.customer.CustomerId),
      this.keywordConfigService.getAll(this.customer.CustomerId)
    ]).pipe(
      switchMap(([pageSuggestions, keywordConfigs]) => {
        this.pageSuggestions = pageSuggestions;
        this.keywordConfigs = keywordConfigs;
        this.getKeywordConfig();
        return of(null);
      })
    );
  }

  getKeywordConfig(): void {
    this.keywordConfig = this.keywordConfigs.find(x => x.IsCampaignNational === this.customer.IsCampaignNational);
  }

  pageSort = (a: PageSuggestion, b: PageSuggestion): number => {
    if (a.Priority && b.Priority) {
      return a.Priority - b.Priority;
    }
    if (a.Priority && !b.Priority) {
      return -1;
    }
    if (!a.Priority && b.Priority) {
      return 1;
    }
    if (a.Url && !b.Url) {
      return -1;
    }
    if (!a.Url && b.Url) {
      return 1;
    }

    return a.Url.toLowerCase().localeCompare(b.Url.toLowerCase());
  }

  isValid(): boolean {
    return this.validationMessage === '';
  }

  save(): Observable<void> {
    return this.keywordResearchService.save(
      this.customer.CustomerId,
      this.pageSuggestions,
      this.isCampaignTypeModified() ? this.customer.IsCampaignNational : null
    );
  }

  redo(): void {
    this.redoRequested.emit();
  }

  get selectedPageSuggestions(): PageSuggestion[] {
    return this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational);
  }

  get validationMessage(): string {
    if (this.pageSuggestions.some(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational && x.Url && !this.customerCampaignService.domainMatches(this.customer.Url, x.Url))) {
      return 'All page domains must match the customer domain.';
    }

    let requiredPages = this.keywordConfig?.Pages || 1;
    let requiredKeywordsPerPage = (this.keywordConfig?.MainKeywords || 1) / requiredPages;

    if (this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).length < requiredPages) {
      return `At least ${requiredPages} pages must be selected.`;
    }

    if (this.pageSuggestions.some(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational && x.KeywordSuggestions.filter(y => y.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).length < requiredKeywordsPerPage)) {
      return 'All pages must have at least ' + requiredKeywordsPerPage + ' keywords.';
    }

    return '';
  }

  private isCampaignTypeModified(): boolean {
    return this.customer.IsCampaignNational !== this.originalIsCampaignNational;
  }
}