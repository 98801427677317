import { Component, Input, OnInit } from '@angular/core';
import IPriorityViewPublicApi = app.interfaces.IPriorityViewPublicApi;
import { TaskNoteService } from '../../../services/task-note.service';
import { ActionService } from '../../../services/action.service';

@Component({
    selector: 'app-components-managecustomer-editrejectioninstructions',
    templateUrl: './EditRejectionInstructions.component.html'
})
export class EditRejectionInstructions implements OnInit {
    @Input() taskId: number;
    @Input() isReadOnly: boolean;
    @Input() workflowDefinitionId: Boo.Objects.Enums.WorkflowDefinitionsEnum;
    isLoading: boolean;
    title: string = 'Edit Rejection Instructions';
    rejectionNote: Boo.Objects.TaskNote;
    originalInstructions: string = '';
    additionalInstructions: string = '';
    user: Boo.Objects.User;
    private priorityViewApi: IPriorityViewPublicApi;

    constructor(
        private taskNoteService: TaskNoteService,
        private actionService: ActionService
        ) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.actionService.getByTaskId(this.taskId).subscribe(action => {
            this.rejectionNote = action.TaskDetails.TaskNotes.filter(x => x.TaskNoteContactTypeId === Boo.Objects.Enums.TaskNoteContactTypesEnum.CustomerRejection)
                .sort((x, y) => y.InsertedDate.getTime() - x.InsertedDate.getTime())[0];
            // If rejected via the ticket, it will generate a slightly different message. Since this message gets generated whether or not the customer generates it first, we need to prioritize the customer's rejection first
            if (!this.rejectionNote) {
                this.rejectionNote = action.TaskDetails.TaskNotes.filter(x => x.TaskNoteContactTypeId === Boo.Objects.Enums.TaskNoteContactTypesEnum.CsrRejection)
                    .sort((x, y) => y.InsertedDate.getTime() - x.InsertedDate.getTime())[0];
            }
            this.originalInstructions = this.rejectionNote.Note;
            this.isLoading = false;
        });
    }

    saveInstructions(): void {
        this.isLoading = true;
        this.rejectionNote.Note += '\r\n' + 'Update:' + '\r\n' + this.additionalInstructions;
        this.taskNoteService.save(this.rejectionNote)
            .subscribe(() => {
                this.priorityViewApi.resolve();
            });
    }

    close(): void {
        this.priorityViewApi.reject();
    }
}