<div [dataValidation]="panelDataValidationOptions" [class]="css.wrapper">
	<div (click)="toggle()" [class]="css.heading">
		<div style="min-height: 22px;">
			<ng-container *ngIf="!isEmpty">
				<i [hidden]="isOpen" class="far fa-caret-square-down float-end"></i>
				<i [hidden]="!isOpen" class="far fa-caret-square-up float-end"></i>
			</ng-container>
			<span>{{title}}</span>
			<span [hidden]="!hasTitleBadge()" class="badge bg-secondary" style="margin:0 6px; position: relative; top:-1px;">{{titleBadge}}</span>
		</div>
	</div>
	<ng-container *ngIf="!toggleBody || isOpen || keepBodyInDomWhenClosed">
		<div [hidden]="!(!toggleBody || isOpen)" [class]="css.body">
			<ng-container *ngIf="isEmpty">
				<div>{{emptyText}}</div>
			</ng-container>
			<ng-container *ngIf="!isEmpty">
				<div>
					<ng-container>
						<div>
							<ng-content></ng-content>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</ng-container>
	<ng-container *ngIf="!isEmpty">
		<div [hidden]="!showFooter" [class]="css.footer">
			<button (click)="toggle()" [class]="css.btnToggle">
				<span [hidden]="isOpen">
					<i class="far fa-caret-square-down"></i>
					Show More
				</span>
				<span [hidden]="!isOpen">
					<i class="far fa-caret-square-up"></i>
					Show Less
				</span>
			</button>
		</div>
	</ng-container>
</div>
