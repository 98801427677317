<div>
	<!-- THIS IS A PRIORITY VIEW -->
	<div class="card">
		<div class="card-header">
			<h4>
				<span>{{isInEditMode() ? 'Edit ' : 'Add '}}</span>
				Link Location
			</h4>
			<button (click)="cancel()" class="btn-close" title="Close"></button>
		</div>
		<div [loadingOverlay]="isLoading()" class="card-body">
			<div class="form">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label class="control-label">Link Type</label>
							<ng-container *ngIf="isInEditMode()">
								<p class="form-control-static">{{linkTypeName()}}</p>
							</ng-container>
							<ng-container *ngIf="!isInEditMode()">
								<select [ngModel]="selectedLinkTypeId()" (ngModelChange)="selectedLinkTypeId($event)" [ngModelOptions]="{ standalone: true }" name="types" class="form-select">
									<option *ngFor="let option of filteredLinkTypes()" [ngValue]="option.LinkTypeId">{{ option.Name }}</option>
								</select>
							</ng-container>
						</div>
						<div class="form-group">
							<label class="control-label">Url</label>
							<ng-container *ngIf="isInEditMode() && !canEditLinkLocationUrls()">
								<p class="form-control-static">
									<span id="url">{{url()}}</span>
								</p>
							</ng-container>
							<ng-container *ngIf="!isInEditMode() || canEditLinkLocationUrls()">
								<div class="input-group">
									<span class="input-group-text">http://</span>
									<input [dataValidation]="{ placement: 'top', value: url }" [ngModel]="url()" (ngModelChange)="url($event)" [ngModelOptions]="{ standalone: true }" type="text" class="form-control" name="newUrl"/>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<ng-container *ngIf="lastResponseTimeInMilliseconds()">
								<label class="control-label">Last Response Time</label>
								<p [class.text-success]="(lastResponseTimeInMilliseconds() <= 3000)" [class.text-error]="(lastResponseTimeInMilliseconds() > 3000)" class="form-control-static">
									<span>{{lastResponseTimeInMilliseconds()}}</span>
									ms
								</p>
							</ng-container>
						</div>
					</div>
				</div>
				<div class="row" style="margin-top: 5px;">
					<div class="col-md-3">
						<label>Link Cultures</label>
					</div>
				</div>
				<div class="row" style="margin-top: 5px;">
					<div [dataValidation]="{ placement: 'top', value: linkCultureCount }" class="col-md-12">
						<table class="table table-striped table-bordered table-sm">
							<thead>
								<tr>
									<th>Language</th>
									<th>Country</th>
									<th>Submit Order</th>
									<ng-container *ngIf="isClassifiedListing()">
										<th>Post Expiration Days</th>
									</ng-container>
									<th>Is Active</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngFor="let $data of links()">
									<tr>
										<td>
											<select [ngModel]="$data.LanguageId()" (ngModelChange)="$data.LanguageId($event)" [ngModelOptions]="{ standalone: true }" [disabled]="true" class="form-select">
												<option *ngFor="let option of languages()" [ngValue]="option.LanguageId">{{ option.Name }}</option>
											</select>
										</td>
										<td>
											<select [ngModel]="$data.CountryId()" (ngModelChange)="$data.CountryId($event)" [ngModelOptions]="{ standalone: true }" [disabled]="true" class="form-select">
												<option *ngFor="let option of countries()" [ngValue]="option.CountryId">{{ option.Name }}</option>
											</select>
										</td>
										<td>
											<input [dataValidation]="{ placement: 'top', value: $data.SubmitOrder }" [ngModel]="$data.SubmitOrder()" (ngModelChange)="$data.SubmitOrder($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" style="width: 50px;" type="text" class="form-control"/>
										</td>
										<ng-container *ngIf="isClassifiedListing()">
											<td>
												<ng-container *ngIf="$data.LinkLocationPostExpiration">
													<input [dataValidation]="{ placement: 'top', value: $data.LinkLocationPostExpiration.ExpirationDays }" [ngModel]="$data.LinkLocationPostExpiration.ExpirationDays()" (ngModelChange)="$data.LinkLocationPostExpiration.ExpirationDays($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" style="width: 50px;" type="text" class="form-control"/>
												</ng-container>
											</td>
										</ng-container>
										<td>
											<div>
												<shared-components-customchoicelegacy [Settings]="{ value: $data.IsActive }"></shared-components-customchoicelegacy>
											</div>
										</td>
										<td>
											<a [hidden]="!($data.IsNew())" (click)="deleteLink($data)" class="btn btn-danger">Delete</a>
										</td>
									</tr>
								</ng-container>
							</tbody>
							<tfoot>
								<tr>
									<td>
										<select [dataValidation]="{ placement: 'top', value: newLinkCultureLanguageId }" [ngModel]="newLinkCultureLanguageId()" (ngModelChange)="newLinkCultureLanguageId($event)" [ngModelOptions]="{ standalone: true }" class="form-select" name="language" id="languages">
											<option [ngValue]="0">Select Language</option>
											<option *ngFor="let option of languages()" [ngValue]="option.LanguageId">{{ option.Name }}</option>
										</select>
									</td>
									<td>
										<select [dataValidation]="{ placement: 'top', value: newLinkCultureCountryId }" [ngModel]="newLinkCultureCountryId()" (ngModelChange)="newLinkCultureCountryId($event)" [ngModelOptions]="{ standalone: true }" class="form-select" name="country" id="countries">
											<option [ngValue]="0">Select Country</option>
											<option *ngFor="let option of countries()" [ngValue]="option.CountryId">{{ option.Name }}</option>
										</select>
									</td>
									<td>
										<input [dataValidation]="{ placement: 'top', value: newLinkCultureSubmitOrder }" [ngModel]="newLinkCultureSubmitOrder()" (ngModelChange)="newLinkCultureSubmitOrder($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text"/>
									</td>
									<ng-container *ngIf="isClassifiedListing()">
										<td>
											<input [dataValidation]="{ placement: 'top', value: newLinkLocationPostExpirationDays }" [ngModel]="newLinkLocationPostExpirationDays()" (ngModelChange)="newLinkLocationPostExpirationDays($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text"/>
										</td>
									</ng-container>
									<td>
										<div>
											<shared-components-customchoicelegacy [Settings]="{ value: newLinkCultureIsActive }"></shared-components-customchoicelegacy>
										</div>
									</td>
									<td>
										<button (click)="addNewLinkCulture()" class="btn btn-primary">Add Link Culture</button>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
			<div class="card card-body bg-light d-block text-end">
				<button (click)="cancel()" class="btn btn-link">Cancel</button>
				<button (click)="save()" class="btn btn-success">
					<i class="fa fa-save"></i>
					Save
				</button>
				<div style="display: none;" id="linkLocationConfirmMessage">
					<h3>Please confirm your settings.</h3>
					<div class="form-group">
						<ng-container *ngIf="$any(_.findWhere(filteredLinkTypes(), { LinkTypeId: selectedLinkTypeId() }))">
							<label class="col-md-4">Link Type:&nbsp;</label>
							<span>{{$any(_.findWhere(filteredLinkTypes(), { LinkTypeId: selectedLinkTypeId() })).Name}}</span>
						</ng-container>
					</div>
					<div class="form-group">
						<label class="col-md-4">Url:&nbsp;</label>
						<span>{{url()}}</span>
					</div>
					<div [hidden]="!selectedVerticalId()" class="form-group">
						<label class="col-md-4">Vertical:&nbsp;</label>
						<span>{{verticalName()}}</span>
					</div>
					<div class="form-group">
						<label class="col-md-12">Active Cultures</label>
						<ul>
							<ng-container *ngFor="let $data of activeLinks">
								<li>{{$data.linkLanguage() + ' , ' + $data.linkCountry()}}</li>
							</ng-container>
						</ul>
					</div>
					<div [hidden]="!(inactiveLinks.length > 0)" class="form-group">
						<label class="col-md-12">Inactive Cultures</label>
						<ul>
							<ng-container *ngFor="let $data of inactiveLinks">
								<li>{{$data.linkLanguage() + ' , ' + $data.linkCountry()}}</li>
							</ng-container>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
