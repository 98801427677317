<div class="row">
	<div class="col-md-9">
		<div class="form-horizontal">
			<div class="form-group">
				<label class="col-md-3 control-label">Directory:</label>
				<div class="col-md-9">
					<a [externalLink]="{ url: directoryUrl, text:directoryDomain}" class="btn btn-primary" name="directoryLink"></a>
				</div>
			</div>
			<div class="form-group">
				<label class="col-md-3 control-label">Vertical/Category:</label>
				<p class="col-md-9 form-control-static">
					<span>{{verticalName}}</span>
				</p>
			</div>
			<div class="form-group">
				<label class="col-md-3 control-label">Site Information:</label>
				<div class="col-md-9">
					<textarea [(ngModel)]="siteInformation" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" class="form-control" rows="15" id="siteInformation" name="siteInformation"></textarea>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-3">
		<div class="card card-danger">
			<div class="card-header">
				Notes
			</div>
			<ul class="list-group">
				<li class="list-group-item">Don't use a URL in the title.</li>
				<li class="list-group-item">Compose a relevant and proper description for the link, no keyword stuffing.</li>
				<li class="list-group-item">Make sure to select the correct category for the link.</li>
			</ul>
		</div>
	</div>
</div>
