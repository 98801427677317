<div>
	<div class="card">
		<div class="card-body">
			<div>
				<ng-container *ngFor="let $data of guarantees">
					<compose Selector="app-components-guarantees-single" [ActivateOptions]="{
                                guarantee: $data,
                                api: api
                            }"></compose>
				</ng-container>
			</div>
		</div>
	</div>
</div>
