import { Subject } from "rxjs";

export interface RejectableWorkspace {
  isReadyToReject(): boolean;
  showRejectButton?: boolean;
  reject?: Subject<void>;
}

export function isRejectable(workspace: RejectableWorkspace): workspace is RejectableWorkspace {
  return workspace.isReadyToReject !== undefined;
}