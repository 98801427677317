<div class="card-primary">
	<div class="card-body">
		<compose Selector="app-components-todo-customertodolist" [ActivateOptions]="{
              customer: customer
            }" [Callback]="customerTodoListComposeBinding?.bind(this)"></compose>
	</div>
	<div class="card-footer">
		<div class="row">
			<ng-container *ngIf="!isUserPartner()">
				<div class="col-sm-8">
					<ng-container *ngIf="unsavedChanges()">
						<span class="text-warning">
							<i class="fas fa-exclamation-triangle"></i>
							You have unsaved to do changes
						</span>
					</ng-container>
				</div>
				<div class="col-sm-4 text-end">
					<div class="btn-group">
						<button (click)="saveTodoList()" class="btn btn-sm btn-primary">
							<i class="fa fa-save"></i>
							Save To Dos
						</button>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="isUserPartner()">
				<div class="col-sm-12 text-center">
					<span class="text-warning">Partner Readonly View</span>
				</div>
			</ng-container>
		</div>
	</div>
</div>
