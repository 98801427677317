import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'page',
  pure: true
})
export class PagePipe<T> implements PipeTransform {

  // length parameter is used to detect items.push()
  transform(items: T[], length: number, enabled: boolean, pageSize: number, page: number): any {
    if (enabled) {
      const index = (page - 1) * pageSize;
      return items.slice(index, index + pageSize);
    }
    return items;
  }
}
