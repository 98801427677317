<div>
	<ng-container *ngIf="internalAction">
		<div class="card">
			<div class="card-header">
				<h4>Task Document</h4>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-12 cursor-pointer" style="text-align: center;">
						<a [attr.href]="$any(internalAction.InternalTaskDetails).TemplateUrl" target="_blank">
							<span class="fa-stack fa-lg">
								<i class="fa fa-file fa-stack-2x"></i>
								<i class="fa fa-stack-2x"></i>
							</span>
							<br/>
							Site Theme Template
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				<h4>Additional Information</h4>
			</div>
			<div class="card-body" style="max-height: 350px;overflow-y: scroll">
				<ul class="nav nav-tabs">
					<li class="active nav-item">
						<a href="#notes" class="nav-link" data-toggle="tab">
							<i class="fa fa-comments"></i>
							Comments
							<span style="margin-left:15px;" class="badge bg-secondary">{{notes.length}}</span>
						</a>
					</li>
				</ul>
				<div class="tab-content">
					<div class="tab-pane active" id="notes">
						<div class="row">
							<div class="col-md-12">
								<ng-container *ngIf="!notes.length">
									<div class="alert alert-info">
										<strong>No Comments:</strong>
										Comments are created when tasks are rejected.
									</div>
								</ng-container>
								<ul class="list-group">
									<ng-container *ngFor="let $data of notes">
										<li class="list-group-item">
											<div class="row">
												<div class="col-md-8">
													<div>
														<strong>{{$data.FromUserFullName}}</strong>
													</div>
													<small>
														<span [hidden]="!$data.TaskTypeDescription">to</span>
														<span>{{$data.TaskTypeDescription}}</span>
													</small>
												</div>
												<div class="col-md-4 text-end">
													<small>{{$data.InsertedDate | dateText: 'M/DD/YYYY h:mm a' }}</small>
												</div>
											</div>
											<div class="row">
												<div class="col-md-12">
													<div class="list-group-item-text">
														<div style="padding-top:15px; padding-bottom:15px;">{{$data.Note}}</div>
													</div>
												</div>
											</div>
										</li>
									</ng-container>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>
