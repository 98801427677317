import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-components-specialist-internal-tasks-action-information',
	templateUrl: './action-information.component.html'
})
export class ActionInformationComponent {
    @Input() internalAction: Boo.Objects.InternalAction;
    @Input() showPenName: boolean = false;
}
