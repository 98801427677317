<div class="card">
  <div class="card-header">
    Premium Backlink
  </div>
  <div class="card-body">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a href="#reporting" class="active nav-link" data-toggle="tab">Reporting</a>
      </li>
      <li class="nav-item">
        <a href="#download" class="nav-link" data-toggle="tab">Download</a>
      </li>
      <li class="nav-item">
        <a href="#upload" class="nav-link" data-toggle="tab">Upload</a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane active" id="reporting">
        <div class="row">
          <div [class.col-md-12]="!showOverdueOrders()" [class.col-md-8]="showOverdueOrders()" class="col-md-12">
            <app-table [items]="outstandingOrders()">
              <ng-template #tableHeader>
                <tr>
                  <th sort field="Partner">Partner</th>
                  <th sort field="PendingGuestBlogs">Pending Guest Blogs</th>
                  <th sort field="OldestTask">Oldest Task</th>
                  <th sort field="OverdueTasks">Overdue Tasks</th>
                </tr>
              </ng-template>
              <ng-template #tableBody let-order>
                <tr>
                  <td>{{order.Partner}}</td>
                  <td>{{order.PendingGuestBlogs}}</td>
                  <td>{{order.OldestTask | dateText }}</td>
                  <td>
                    <button [disabled]="order.OverdueTasks <= 0"
                      (click)="viewOverdueOrders(order.PartnerId, order.Partner)" class="btn btn-primary">
                      <span>{{order.OverdueTasks}}</span>
                      <i class="fa fa-bolt"></i>
                    </button>
                  </td>
                </tr>
              </ng-template>
            </app-table>
          </div>
          <div [hidden]="!showOverdueOrders()" class="col-md-4">
            <div class="card">
              <div class="card-header">
                Overdue Orders for
                <span>{{overdueOrderPartnerName()}}</span>
                <button (click)="showOverdueOrders(false)" type="button" class="btn-close float-end"></button>
              </div>
              <app-table [items]="filteredOverdueOrders()">
                <ng-template #tableHeader>
                  <tr>
                    <th sort field="OrderedDate">Ordered Date</th>
                    <th sort field="BoostabilityIdentifier">Boostability Identifier</th>
                  </tr>
                </ng-template>
                <ng-template #tableBody let-order>
                  <tr>
                    <td>{{order.OrderedDate | dateText }}</td>
                    <td>{{order.BoostabilityIdentifier}}</td>
                  </tr>
                </ng-template>
              </app-table>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="download">
        <div class="row">
          <div class="col-md-12">
            <button (click)="preview()" class="btn btn-primary btn-lg">
              <i class="far fa-eye"></i>
              Preview
            </button>
            <button (click)="download()" class="btn btn-success btn-lg">
              <i class="fa fa-download"></i>
              Download
            </button>
          </div>
        </div>
        <div [hidden]="!(previewDownloadData().length <= 0 && downloadUrl() !== '')" class="row">
          <hr />
          <div class="col-md-12">
            You can now download the .CSV at
            <a [attr.href]="downloadUrl()" target="_blank">{{downloadUrl()}}</a>
          </div>
        </div>
        <div [hidden]="!(previewDownloadData().length > 0)" class="row">
          <div class="col-md-12" style="overflow-y: scroll;">
            <app-table [items]="previewDownloadData()">
              <ng-template #tableHeader>
                <tr>
                  <th sort field="Partner">Partner</th>
                  <th sort field="CustomerId">CustomerId</th>
                  <th sort field="CustomerName">Customer Name</th>
                  <th sort field="TargetUrl">Target URL</th>
                  <th sort field="TaskId">TaskId</th>
                  <th sort field="TaskStatusId">TaskStatusId</th>
                  <th sort field="InsertedDate">InsertedDate</th>
                  <th sort field="StatusDate">StatusDate</th>
                  <th sort field="CustomerConsiderations">Offsite Content Considerations</th>
                  <th sort field="Vertical">Vertical</th>
                </tr>
              </ng-template>
              <ng-template #tableBody let-preview>
                <tr>
                  <td>{{preview.Partner}}</td>
                  <td>{{preview.CustomerId}}</td>
                  <td>{{preview.CustomerName}}</td>
                  <td>{{preview.TargetUrl}}</td>
                  <td>{{preview.TaskId}}</td>
                  <td>{{preview.TaskStatusId}}</td>
                  <td>{{preview.InsertedDate | dateText }}</td>
                  <td>{{preview.StatusDate | dateText }}</td>
                  <td>
                    <div class="col-md-2 text-end">
                      <ng-container *ngIf="preview.CustomerConsiderations">
                        <span [ngbPopover]="preview.CustomerConsiderations" popoverTitle="Offsite Content Considerations"
                          placement="right auto" triggers="mouseenter:mouseleave">
                          <i class="fa fa-sticky-note fa-2x text-info"></i>
                        </span>
                      </ng-container>
                    </div>
                  </td>
                  <td>{{preview.Vertical}}</td>
                </tr>
              </ng-template>
            </app-table>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12" style="overflow-x: auto;">
            <app-table [items]="downloads()" [pageSize]="5">
              <ng-template #tableHeader>
                <tr>
                  <th sort desc field="InsertedDate">Download Date</th>
                  <th sort field="User.Username">User</th>
                  <th sort field="Url">File</th>
                </tr>
              </ng-template>
              <ng-template #tableBody let-download>
                <tr>
                  <td>{{download.InsertedDate | dateText }}</td>
                  <td>{{download.User.FirstName + ' ' + download.User.LastName}}</td>
                  <td>
                    <a [attr.href]="download.Url" target="_blank" class="btn btn-primary">
                      <i class="fas fa-external-link-square-alt"></i>
                      Download
                    </a>
                  </td>
                </tr>
              </ng-template>
            </app-table>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="upload">
        <div class="input-group">
          <input [fileReader]="{ data: content, filename: filename, file: file, accept: '.csv', method: 'readAsText'}"
            type="file" class="form-control float-end" />
          <button (click)="parseCsv()" class="btn btn-primary" [disabled]="isLoading">
            <i class="fa fa-upload"></i>
            Upload CSV
          </button>
        </div>
      </div>
    </div>
  </div>
</div>