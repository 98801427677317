<div class="card">
	<div class="card-header">
		<h4>Content Recommendations</h4>
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-md-12">
				<table id="keywordsTable" border="0" class="table table-striped table-bordered table-sm">
					<thead>
						<tr>
							<th>Keyword(s)</th>
							<th>Area</th>
							<th>Title</th>
							<th>Description</th>
							<th>H1</th>
							<th>H2-H6</th>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngFor="let $data of keywords()">
							<tr>
								<td>
									<a [attr.href]="googleSearchUrl + 'search?pws=0&q=' +  ($data.IsAreaFirst ? $data.Area + ' ' + $data.Keyword : $data.Keyword + ' ' + $data.Area).trim()" target="_blank" name="keyword">{{$data.Keyword}}</a>
								</td>
								<td name="area">{{$data.Area}}</td>
								<td>{{$data.TitleCount}}</td>
								<td>{{$data.DescriptionCount}}</td>
								<td>{{$data.H1Count}}</td>
								<td>{{$data.H2H6Count}}</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label class="control-label">Current Title Tag Text</label>
					<textarea [ngModel]="recommendation.TitleOld()" (ngModelChange)="recommendation.TitleOld($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" cols="65" rows="3" style="height:65px; resize: vertical;" class="form-control"></textarea>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label class="control-label">New Title Tag Text</label>
					<textarea [dataValidation]="{placement:'top', value: recommendation.TitleNew }" [ngModel]="recommendation.TitleNew()" (ngModelChange)="recommendation.TitleNew($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" cols="65" rows="3" style="height:65px;" class="form-control" name="newTitle"></textarea>
					<span class="help-block">{{(recommendation.TitleNew() || '').length + '/' + titleMaxCharacterCount}}</span>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label class="control-label">Current Meta Description</label>
					<textarea [ngModel]="recommendation.DescriptionOld()" (ngModelChange)="recommendation.DescriptionOld($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" cols="65" rows="3" style="height:65px; resize: vertical;" class="form-control"></textarea>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group">
					<label class="control-label">New Meta Description</label>
					<textarea [dataValidation]="{placement:'top', value: recommendation.DescriptionNew }" [ngModel]="recommendation.DescriptionNew()" (ngModelChange)="recommendation.DescriptionNew($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" cols="65" rows="3" style="height:65px;" class="form-control" name="newMetaDescription"></textarea>
					<span class="help-block">{{(recommendation.DescriptionNew() || '').length + '/' + descriptionMaxCharacterCount}}</span>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="form-group">
					<label class="control-label">Current Heading Tags</label>
					<textarea [ngModel]="recommendation.H1Old()" (ngModelChange)="recommendation.H1Old($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" cols="65" rows="3" style="height:65px; resize: vertical;" class="form-control"></textarea>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="form-group">
					<label class="control-label">New H1 Tag</label>
					<textarea [dataValidation]="{placement:'top', value: recommendation.H1New }" [ngModel]="recommendation.H1New()" (ngModelChange)="recommendation.H1New($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" cols="65" rows="3" style="height:65px; resize: vertical;" class="form-control" name="newH1"></textarea>
					<span class="help-block">{{(recommendation.H1New() || '').length + '/' + h1MaxCharacterCount}}</span>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="form-group">
					<label class="control-label">New H2 Tag</label>
					<textarea [dataValidation]="{placement:'top', value: recommendation.H2New }" [ngModel]="recommendation.H2New()" (ngModelChange)="recommendation.H2New($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isReadOnly" [readonly]="isReadOnly" cols="65" rows="3" style="height:65px; resize: vertical;" class="form-control" name="newH2"></textarea>
					<span class="help-block">{{(recommendation.H2New() || '').length + '/' + h2MaxCharacterCount}}</span>
				</div>
			</div>
		</div>
	</div>
</div>
