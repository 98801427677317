<div [loadingOverlay]="isLoading">
  <ng-container *ngIf="validatedUrlSuggestions.length === 0">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>No pages or keywords have been defined yet</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
  <div cdkDropListGroup>
    <div class="row" [formGroup]="formGroup" *ngFor="let urlSuggestion of validatedUrlSuggestions">
      <div class="col-md-12">
        <table class="table table-bordered table-hover" cdkDropList [cdkDropListData]="urlSuggestion.validatedKeywordSuggestions" [cdkDropListDisabled]="!urlSuggestion.canEdit" (cdkDropListDropped)="dropListDropped($event)" [cdkDropListEnterPredicate]="enterPredicate">
          <thead>
            <tr>
              <th colspan="2">
                <ng-container *ngIf="urlSuggestion.canEdit">
                  <button style="padding:0" class="btn pull-right" (click)="removeUrlSuggestion(urlSuggestion)"><i class="fas fa-trash-alt"></i></button>
                  <ng-container *ngIf="!urlSuggestion.editMode">
                    <label class="pull-right fw-bold mb-0 text-break" (click)="changeEditMode(urlSuggestion)"
                      >{{urlSuggestion.Url}} <i class="fas fa-pencil-alt ml-1"></i></label>
                  </ng-container>
                  <ng-container *ngIf="urlSuggestion.editMode">
                    <input style="width: 550px; display:inline-block" type="text" (focusout)="changeEditMode(urlSuggestion)"
                      class="form-control" [ngModelOptions]="{ standalone: true }" [(ngModel)]="urlSuggestion.Url" [maxlength]="512"/>
                  </ng-container>
                  <shared-components-customchoice
                    [value]="urlSuggestion.IsExisting"
                    [choiceTrue]="'Existing'"
                    [choiceFalse]="'New'"
                    (valueChange)="this.updateIsExisting($event, urlSuggestion)">
                  </shared-components-customchoice>
                  <span *ngIf="urlSuggestion.RejectionNote" style="margin-left:10px;" class="control-label" [ngbPopover]="urlSuggestion.RejectionNote" popoverTitle="Rejection Note" placement="right auto" triggers="mouseenter:mouseleave">
                    <i style="font-size: 20px;" class="fa fa-sticky-note text-danger"></i>
                  </span>
                  <div class="d-flex float-end gap-1">
                    <div ngbDropdown class="btn-group">
                      <button class="btn btn-primary dropdown-toggle" type="button" ngbDropdownToggle>
                        Sort
                      </button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <ng-container *ngFor="let sortOption of sortingOptions">
                          <button *ngIf="!(sortOption.disableIfNational && !isLocalCampaign)" ngbDropdownItem (click)="sort(sortOption.method, urlSuggestion.validatedKeywordSuggestions)">{{sortOption.display}}</button>
                        </ng-container>
                      </div>
                    </div>
                    <button class="btn btn-primary" (click)="openModal(addKeywordSuggestionModal, urlSuggestion)">
                      <i class="fas fa-plus"></i>Add Keyword
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="!urlSuggestion.canEdit">
                  <i class="fas fa-check text-success"></i><span class="text-success" style="margin-left: 10px;margin-right:10px;padding:0.375rem 0.75rem">{{urlSuggestion.Url}}</span>
                  <shared-components-customchoice
                    [value]="urlSuggestion.IsExisting"
                    [choiceTrue]="'Existing'"
                    [choiceFalse]="'New'"
                    [disable]="true"
                    (valueChange)="this.updateIsExisting($event, urlSuggestion)">
                  </shared-components-customchoice>
                </ng-container>
              </th>
            </tr>
          </thead>
          <tbody *ngFor="let keywordSuggestion of urlSuggestion.validatedKeywordSuggestions" cdkDrag [cdkDragDisabled]="!keywordSuggestion.isProposed" [cdkDragData]="keywordSuggestion">
            <div *cdkDragPreview>
              <i class="fa-solid fa-arrows-up-down-left-right ml-3" style="cursor: move;"></i>
              <i class="fas fa-trash-alt cursor-pointer"></i>
              {{ keywordSuggestion.keywordAndArea }}
              <span class="badge rounded-pill bg-secondary" style="font-size: 100%;margin-left: 10px;">{{keywordSuggestion.Rank}}</span>
              <span class="badge rounded-pill bg-secondary" *ngIf="keywordSuggestion.IsCustomerChoice" style="font-size: 100%;margin-left: 10px;">Customer Choice</span>
            </div>
            <tr>
              <td style="width: 88%">
                <ng-container *ngIf="keywordSuggestion.isRejected">
                  <i class="fas fa-times text-danger" style="padding:0.375rem 0.75rem"></i>
                  <span class="text-danger text-decoration-line-through">{{ keywordSuggestion.keywordAndArea }}</span>
                  <span class="badge rounded-pill bg-secondary" style="font-size: 100%;margin-left: 10px;">{{"rank: " + keywordSuggestion.Rank}}</span>
                </ng-container>
                <ng-container *ngIf="keywordSuggestion.isApproved">
                  <i class="fas fa-check text-success" style="padding:0.375rem 0.75rem"></i>
                  <span class="text-success">{{ keywordSuggestion.keywordAndArea }}</span>
                  <span class="badge rounded-pill bg-secondary" style="font-size: 100%;margin-left: 10px;">{{"rank:" + keywordSuggestion.Rank}}</span>
                </ng-container>
                <ng-container *ngIf="keywordSuggestion.isProposed">
                  <i cdkDragHandle class="fa-solid fa-arrows-up-down-left-right ml-3" style="cursor: move;"></i>
                  <i class="fas fa-trash-alt cursor-pointer" (click)="removeKeywordSuggestion(keywordSuggestion, urlSuggestion)"></i>
                  {{ keywordSuggestion.keywordAndArea }}
                  <span class="badge rounded-pill bg-secondary" style="font-size: 100%;margin-left: 10px;">{{"rank:" + keywordSuggestion.Rank}}</span>
                  <span class="badge rounded-pill bg-secondary" *ngIf="keywordSuggestion.IsCustomerChoice" style="font-size: 100%;margin-left: 10px;">Customer Choice</span>
                </ng-container>
              </td>
              <td style="width: 12%; min-width: 180px">
                <ng-container *ngIf="!keywordSuggestion.isProposed">
                  <span [ngClass]="{'text-success bg-gradient': keywordSuggestion.isApproved, 'text-danger text-decoration-line-through': keywordSuggestion.isRejected}">{{keywordSuggestion.KeywordSuggestionType.Name}}</span>
                </ng-container>
                <ng-container *ngIf="keywordSuggestion.isProposed">
                  <ng-select
                    [formValidation]="this.formGroup.get(keywordSuggestion.formControlName)"
                    [formControl]="this.formGroup.get(keywordSuggestion.formControlName)"
                    formControlName="{{keywordSuggestion.formControlName}}"
                    [items]="keywordTypes"
                    placeholder="Select a keyword type"
                    [clearable]="false"
                    bindLabel="Name"
                    bindValue="KeywordSuggestionTypeId">
                  </ng-select>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #addKeywordSuggestionModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add New Keyword</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body" style="min-height: 290px;" [formValidation]="newKeywordFormGroup">
      <div class="form-group">
        <label class="control-label">Keyword</label>
        <input type="text" class="form-control" [formControl]="newKeywordControl" [formValidation]="newKeywordControl" lpValidationMsg />
      </div>
      <div *ngIf="isLocalCampaign" class="form-group">
        <label class="control-label">Location</label>
        <input type="text" class="form-control" [formControl]="newAreaControl" [formValidation]="newAreaControl" lpValidationMsg />
      </div>
      <div class="form-group">
        <label class="control-label">Keyword Type</label>
        <ng-select [items]="keywordTypes" [formControl]="newKeywordTypeControl" [formValidation]="newKeywordTypeControl" placeholder="Select a keyword type" [clearable]="false" bindLabel="Name"
          bindValue="KeywordSuggestionTypeId"></ng-select>
      </div>
      <div class="form-group">
        <label class="control-label">Rank</label>
        <input type="number" class="form-control" [formControl]="newRankControl" [formValidation]="newRankControl" lpValidationMsg/>
      </div>
      <div *ngIf="isLocalCampaign" class="form-group">
        <label class="control-label">Display location last?</label>
        <div>
          <shared-components-customchoice [value]="newKeywordIsAreaLast" [choiceTrue]="'Yes'"
            [choiceFalse]="'No'" (valueChange)="updateKeywordIsAreaLast($event)">
          </shared-components-customchoice>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
      <button type="button" class="btn btn-success" (click)="addKeyword(modal)">Save</button>
    </div>
  </div>
</ng-template>