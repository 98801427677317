<div>
	<app-components-togglepanel isOpen="true" title="Onsite Copy Request" toggleBody="true">
		<div>
			<div class="form-group">
				<label [hidden]="!(orderReason() !== 'New Page' && orderReason() !== 'Existing Page')">Reason for ordering the copy</label>
				<label [hidden]="!(orderReason() === 'New Page' || orderReason() === 'Existing Page')">Where will this copy be published?</label>
				<div>{{orderReason()}}</div>
			</div>
			<div class="form-group">
				<label>Will Boostability implement this piece of copy?</label>
				<div>{{boostToPublish() ? 'Yes' : 'No'}}</div>
			</div>
			<div class="form-group">
				<label>Should we target the URL for linkBuild after the copy is published?</label>
				<div>{{linkBuildAfterPublish()}}</div>
			</div>
		</div>
	</app-components-togglepanel>
</div>