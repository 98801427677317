<div class="row" *ngIf="!isLoading">
  <div class="col-md-6">
    <div class="form-group">
      <label class="control-label">Previous Categories</label>
      <ul class="list-group">
        <ng-container *ngIf="localProfile.PreviousCategory1">
          <li class="list-group-item">{{localProfile.PreviousCategory1}}</li>
        </ng-container>
        <ng-container *ngIf="localProfile.PreviousCategory2">
          <li class="list-group-item">{{localProfile.PreviousCategory2}}</li>
        </ng-container>
        <ng-container *ngIf="localProfile.PreviousCategory3">
          <li class="list-group-item">{{localProfile.PreviousCategory3}}</li>
        </ng-container>
        <ng-container *ngIf="localProfile.PreviousCategory4">
          <li class="list-group-item">{{localProfile.PreviousCategory4}}</li>
        </ng-container>
        <ng-container *ngIf="localProfile.PreviousCategory5">
          <li class="list-group-item">{{localProfile.PreviousCategory5}}</li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div class="col-md-6">
    <form [formGroup]="form">
      <div class="form-group">
        <label class="form-label" for="categories">Categories</label>
        <ng-select [formValidation]="form.controls.categories" id="categories" [items]="categories" [clearable]="true"
          [multiple]="true" [closeOnSelect]="false" bindLabel="FullName" bindValue="BusinessCitationProviderCategoryId"
          [virtualScroll]="true" formControlName="categories" placeholder="Categories"></ng-select>
        <div *ngIf="partnerRequiresSso" class="mt-2">
          <button (click)="editOnPartnerSite()" [disabled]="isLoading" class="btn btn-primary">
            <i class="fas fa-sign-in-alt"></i>{{ partnerSsoLabel }}</button>
          <button (click)="refresh()" [disabled]="isLoading" class="btn btn-primary">
            <i class="fas fa-sync"></i> Refresh
          </button>
        </div>
      </div>
    </form>
  </div>
</div>