<div>
	<ng-container *ngIf="isEnabled">
		<app-components-togglepanel [(isOpen)]="isOpen" title="Ticket Features" toggleBody="true">
			<ng-container *ngIf="isOpen">
				<div class="row">
					<ng-container *ngIf="ticket">
						<div class="col-md-6">
							<div [dataValidation]="{ value: ticket.IsApproved, placement: 'top' }" class="form-group">
								<ul style="padding-left:0;margin:0">
									<li class="list-group-item">
										<div class="form-inline" style="margin-left: 0;" role="form">
											<div class="form-group">
												<label>{{approvalQuestionText}}</label>
											</div>
											<div class="form-group float-end">
												<ng-container *ngIf="canEdit">
													<div class="form-group">
														<div style="margin-top: -4px; margin-right: -10px;">
															<shared-components-customchoicelegacy [Settings]="{ value: ticket.IsApproved }"></shared-components-customchoicelegacy>
														</div>
													</div>
												</ng-container>
												<ng-container *ngIf="!canEdit">
													<p class="form-control-static">{{ticket.IsApproved() ? 'Yes' : 'No'}}</p>
												</ng-container>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</app-components-togglepanel>
	</ng-container>
</div>
