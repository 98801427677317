import ICustomerTodoList = app.tsmodels.interfaces.ICustomerTodoListObservable;

export default class CustomerTodoListFactory {
  public create(customerId: number, type: Boo.Objects.Todo.Enums.CustomerTodoListTypes): ICustomerTodoList {
    return <ICustomerTodoList>({
      CustomerTodoListId: ko.observable(0),
      CustomerId: ko.observable(customerId),
      Type: ko.observable(type)
    });
  }
}
