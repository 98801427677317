<div [loadingOverlay]="isLoading">
  <div class="row pb-3">
    <div class="col-md-12">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Description</strong>
            </div>
            <div class="col-md-10" [innerHtml]="taskDescription"></div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Instructions</strong>
            </div>
            <div class="col-md-10 preserve-whitespace">{{ taskInstructions }}</div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Blog Url</strong>
            </div>
            <div class="col-md-10">
              <ng-container *ngIf="blogUrl">
                <a [externalLink]="{ url: blogUrl, text:blogUrl }" name="blogUrl" rel="noreferrer"></a>
              </ng-container>
              <ng-container *ngIf="!blogUrl">
                Not found
              </ng-container>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Publish User</strong>
            </div>
            <div class="col-md-10">
              <ng-container *ngIf="publishUser">
                The customer would like the Publish User to be: <strong>{{ publishUser }}</strong>
              </ng-container>
              <ng-container *ngIf="!publishUser">
                No preference
              </ng-container>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Date to Publish</strong>
            </div>
            <div class="col-md-10">
              <ng-container *ngIf="publishDate">
                {{ publishDate | dateText }}
              </ng-container>
              <ng-container *ngIf="!publishDate">
                N/A
              </ng-container>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row pb-3">
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Page Title</strong>
            </div>
            <div class="col-md-10">
              {{ pageTitle }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Image</strong>
            </div>
            <div class="col-md-10">
              <ng-container *ngIf="imageUrl">
                <a [externalLink]="{ url: imageUrl, text:'Image' }" name="imageUrl"></a>
              </ng-container>
              <ng-container *ngIf="!imageUrl">
                Not found
              </ng-container>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Meta Description</strong>
            </div>
            <div class="col-md-10">
              {{ newDescription }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Content Title / H1</strong>
            </div>
            <div class="col-md-10">
              {{ contentTitle }}
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>H2</strong>
            </div>
            <div class="col-md-10">
              <ng-container *ngIf="newH2">
                {{ newH2 }}
              </ng-container>
              <ng-container *ngIf="!newH2">
                N/A
              </ng-container>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Content Body</strong>
            </div>
            <div class="col-md-10">
              <div class="onsiteBloggingContent" [innerHTML]="content"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row pb-3">
    <div class="col-md-6">
      <div>
        <app-components-specialist-taskfiles [options]="{
          taskId: currentAction().Task.TaskId,
          allowUpload: true,
          apiCallback: taskFilesApiCallback,
          useSmallTitle: true
        }"></app-components-specialist-taskfiles>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          Publish URL
        </div>
        <div class="card-body">
          <input class="form-control" [formControl]="publishUrlCtrl" lpValidationMsg />
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          Note to Reviewer
        </div>
        <div class="card-body">
          <textarea class="form-control" style="min-width: 100%" [formControl]="noteToReviewerCtrl" rows="5"
            lpValidationMsg></textarea>
        </div>
      </div>
    </div>
  </div>
  <app-floating-panels *ngIf="!isLoading">
    <app-floating-panel [displayConfig]="{ title: 'External Access', icon: 'fa-key', size: 'floating-panel-md' }">
      <compose *floatingPanelContent Selector="app-components-managecustomer-readonlyexternalaccess" [Model]="{ customer: currentAction().Customer }">
      </compose>
    </app-floating-panel>
    <app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
      <app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="currentAction().TaskDetails.TaskNotes">
      </app-components-specialist-actioncomponents-comments>
    </app-floating-panel>
    <app-floating-panel *ngIf="rejection" [displayConfig]="{ title: 'Rejection Information', icon: 'fa-clipboard'}">
      <app-components-specialist-actioncomponents-rejection *floatingPanelContent [rejection]="rejection.Rejection">
      </app-components-specialist-actioncomponents-rejection>
    </app-floating-panel>
  </app-floating-panels>
</div>
