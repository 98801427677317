import KnockoutExtensions from "app/KnockoutExtensions";

export default class Constants {
  public static MyMarketInfoProfilePath: string = 'user/profile';
  
  public static CustomerMapping = {
    'Csr': {
      create: (item: any) => {
        return KnockoutExtensions.createNullableChildObject(item);
      }
    },
    'Team': {
      create: (item: any) => {
        return KnockoutExtensions.createNullableChildObject(item);
      }
    }
  };

  private static UrlRegex =
    '((https?|s?ftp):\\/\\/)?' + // protocol
    '((([\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zà-öø-ÿß\\d]+(-+[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zà-öø-ÿß\\d-]*[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zà-öø-ÿß\\d])*)\\.)+[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-zà-öø-ÿß]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?' + // port
    '(\\/[-a-zà-öø-ÿß\\d%_&,\\:\'.~+]*)*' + // path
    '(\\?[*@;:&a-zà-öø-ÿß\\d%_,\\.\/\'~+=-]*)?' + // query string
    '(#[-!a-zà-öø-ÿß\\d_\/]*)?'; //fragment locator

  public static IsUrlRegex = new RegExp('^' + Constants.UrlRegex + '$', 'i');
  public static ContainsUrlRegex = new RegExp(Constants.UrlRegex, 'i');
}