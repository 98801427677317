<div>
	<div [loadingOverlay]="isLoading()" class="card">
		<form enctype="multipart/form-data">
			<div class="card-header">
				Upload File
				<button (click)="close()" class="btn-close" title="Close"></button>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-4 form-group d-grid gap-2">
						<span [dataValidation]="{ placement: 'top', value: file }" class="btn btn-primary btn-file">
							Select a File
							<input [file]="{ data: file, accept: '.xls, .xla, .xlt, .xlsx, .csv, .pdf, .doc, .docx, .xml, .txt, .jpeg, .jpg, .gif, .png' }" type="file" class="form-control js-fileapi-wrapper float-end"/>
						</span>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 form-group">
						<label class="control-label">Filename</label>
						<input [dataValidation]="{ placement: 'top', value: filename }" [ngModel]="filename()" (ngModelChange)="filename($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" disabled=""/>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 form-group">
						<label class="control-label">File Type</label>
						<select [ngModel]="fileSectionTypeId()" (ngModelChange)="fileSectionTypeId($event)" [ngModelOptions]="{ standalone: true }" [disabled]="!(fileSectionTypes.length > 1)" class="form-select" name="fileSectionTypes">
							<option *ngFor="let option of fileSectionTypes" [ngValue]="option.FileSectionTypeId">{{ option.Name }}</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 form-group">
						<label class="control-label">Comment</label>
						<textarea [dataValidation]="{ placement: 'top', value: comment }" [ngModel]="comment()" (ngModelChange)="comment($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control"></textarea>
					</div>
				</div>
				<div class="card card-body bg-light d-block text-end">
					<button (click)="close()" class="btn btn-link">Cancel</button>
					<button (click)="save()" class="btn btn-success" data-accept="true">
						<i class="fas fa-cloud-upload-alt"></i>
						Upload File
					</button>
				</div>
			</div>
		</form>
	</div>
</div>
