import { Component, OnInit } from '@angular/core';
import System from 'framework/System';
import { ITaskFileSource } from '../../../../shared/task-files/task-files.component';
import ITaskFilesPublicApi = app.components.interfaces.ITaskFilesPublicApi;
import ITaskFilesPublicApiCallback = app.components.interfaces.ITaskFilesPublicApiCallback;
import TaskHelper from 'app/specialist/TaskHelper';
import { OnsiteCopyService } from '../../../../../services/onsite-copy.service';

@Component({
  selector: 'app-components-specialist-actioncomponents-onsitecopyimplementationreview',
  templateUrl: './OnsiteCopyImplementationReview.component.html'
})
export class OnsiteCopyImplementationReviewComponent implements OnInit, IActionWorkspace, IActivatable {

  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;
  taskFileSources: ITaskFileSource[];
  taskFilesApi: ITaskFilesPublicApi;
  taskFilesApiCallback: ITaskFilesPublicApiCallback;
  onsiteCopyImplementationSummary: Boo.Objects.OnsiteImplementation.OnsiteCopyImplementationSummary;
  taskInstructions:string;
  pageType:string;
  url: string;
  content: string;
  newTitle: string;
  newDescription: string
  newH1: string;
  newH2: string;

  constructor(private onsiteCopyService: OnsiteCopyService) {}

  ngOnInit(): void {
    this.onsiteCopyService.getImplementationSummary(this.currentAction().Task.TaskId)
      .subscribe(summary => {
        this.onsiteCopyImplementationSummary = summary;

        this.taskFileSources = [
          { taskId: this.onsiteCopyImplementationSummary.TaskId, source: "Implementation Task" },
          { taskId: this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask.TaskId, source: "Work Details" }
        ];
      });

    this.taskInstructions = TaskHelper.getOnsiteCopyImplementationInstructions(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask);

    this.pageType = TaskHelper.getOnsiteCopyPageType(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask);
    this.url = this.currentAction().TaskDetails.WebsiteUrl.Url;
    this.content = this.currentAction().TaskDetails.TaskPost.Content;
    this.newTitle = this.currentAction().TaskDetails.OnsiteRecommendation.TitleNew;
    this.newDescription = this.currentAction().TaskDetails.OnsiteRecommendation.DescriptionNew;
    this.newH1 = this.currentAction().TaskDetails.OnsiteRecommendation.H1New;
    this.newH2 = this.currentAction().TaskDetails.OnsiteRecommendation.H2New;

    this.taskFilesApiCallback = (api: ITaskFilesPublicApi) => { this.taskFilesApi = api; }
  }

  activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {
    params.controlViewModel(this);
    this.currentAction = params.currentAction;
    this.actionViewModel = ko.observable(null);
    return System.emptyPromise();
  }

  isStepReadyToReject(): boolean {
    return true;
  }

  isStepReadyToSave(): boolean {
    return this.currentAction().Task.TaskTypeSummary.IsPersistable;
  }

  isStepReadyToDelete(): boolean {
    return false;
  }

  isStepReadyToComplete(): boolean {
    return true
  }
}
