import { Pipe, PipeTransform } from '@angular/core';
import { Schedules } from '../models/schedules';

@Pipe({
  name: 'frequency'
})
export class FrequencyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case Schedules.monthly:
        return 'Monthly';
      case Schedules.bimonthly:
        return 'Bi-Monthly';
      case Schedules.quarterly:
        return 'Quarterly';
      case Schedules.oneTime:
        return 'One-Time';
      default:
        return '--';
    }
  }

}
