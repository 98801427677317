<div *withPermissions="let allow of ['CanManageBlogSites', 'CanAccessAwsBlogSites', 'CanManageProxies', 'CanViewTaskWorkspaces']" class="card">
	<div class="card-body py-2">
		<ul navigationHandler class="nav nav-pills">
			<li class="nav-item">
				<a href="/#/specialist/specialistworkspace" class="nav-link">
					<i class="fa fa-tasks"></i>
					Specialist Workspace
				</a>
			</li>
			<li class="nav-item">
				<a href="/#/specialist/assignedtasks" class="nav-link">
					<i class="fa fa-lock"></i>
					Assigned Tasks
				</a>
			</li>
			<li class="nav-item">
				<a href="/#/specialist/contentfeedback" class="nav-link">
					<i class="far fa-comment"></i>
					Content Feedback
				</a>
			</li>
			<li class="nav-item">
				<a href="/#/specialist/viewtaskfeedback" class="nav-link">
					<i class="far fa-comment"></i>
					View Task Feedback
				</a>
			</li>
			<li [hidden]="!canAccessInternalTasks" class="nav-item">
				<a href="/#/specialist/internal-tasks" class="nav-link">
					<i class="fa fa-tasks"></i>
					Internal Tasks
				</a>
			</li>
			<li [hidden]="!(allow().CanManageBlogSites() || isBlogSitesVisible)" class="nav-item">
				<a href="/#/specialist/blogsites" class="nav-link">
					<i class="fab fa-rebel"></i>
					Blogs
				</a>
			</li>
      <li *ngIf="allow().CanViewTaskWorkspaces()" class="nav-item">
        <a href="/#/specialist/viewtaskworkspace" class="nav-link">
          <i class="fas fa-link"></i>
          View Task Workspace
        </a>
      </li>
			<li *ngIf="isLockedTasksVisible" class="nav-item">
				<a href="/#/specialist/lockedtasks" class="nav-link">
					<i class="fa fa-globe"></i>
					Locked Tasks
				</a>
			</li>
		</ul>
	</div>
	<ng-container *ngIf="isManager">
		<div [slideVisible]="showInternalTasksSubMenu" class="card-footer py-2" style="display: none;">
			<ul navigationHandler class="nav nav-pills">
				<li class="nav-item">
					<a href="/#/specialist/internal-tasks" class="nav-link">My Internal Tasks</a>
				</li>
				<li class="nav-item">
					<a href="/#/specialist/lockedinternaltasks" class="nav-link">Locked Internal Tasks</a>
				</li>
			</ul>
		</div>
	</ng-container>
	<ng-container *ngIf="isManager">
		<div [slideVisible]="showViewTaskWorkspaceSubMenu" class="card-footer py-2" style="display: none;">
			<ul navigationHandler class="nav nav-pills">
				<li class="nav-item">
					<a href="/#/specialist/viewtaskworkspace" class="nav-link">View Task Workspace</a>
				</li>
				<li class="nav-item">
					<a href="/#/specialist/changespecialistscore" class="nav-link">Change Specialist Score</a>
				</li>
			</ul>
		</div>
	</ng-container>
</div>
