<div class="row">
  <div class="col-md-12">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th style="padding: 0px 0px 0px 8px">
            <div class="row" style="display: flex; align-items: center;">
              <div class="col-md-6">
                Halo Keywords
              </div>
              <div class="col-md-6 d-flex justify-content-end gap-1">
                <button class="btn btn-info" (click)="copyKeywordsToClipboard()">
                  <i class="fa-regular fa-clipboard" style="margin: 0px"></i>
                </button>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let keyword of haloKeywords">
          <td>
            <div [ngClass]="{ 'text-danger text-decoration-line-through': isRejected(keyword) }">
              <ng-container *ngIf="isRejected(keyword) && !disableEditing">
                <a class="fa fa-undo" (click)="unRejectHaloKeyword(keyword)"></a>
              </ng-container>
              <ng-container *ngIf="!isRejected(keyword) && !disableEditing">
                <a class="fa fa-times" (click)="rejectHaloKeyword(keyword)"></a>
              </ng-container>
              {{ getKeywordDisplayText(keyword) }}
              <span class="badge rounded-pill bg-secondary">{{"rank: " + keyword.Rank}}</span>
              <span class="badge rounded-pill bg-secondary" *ngIf="keyword.IsCustomerChoice">Customer Choice</span>
            </div>
          </td>
        </tr>
        <tr *ngFor="let keyword of newHaloKeywords">
          <td [ngClass]="{ 'bg-warning bg-gradient': !isRejected(keyword) && isDuplicate(keyword) }">
            <div [ngClass]="{ 'text-danger text-decoration-line-through': isRejected(keyword) }">
              <ng-container *ngIf="isRejected(keyword)">
                <a class="fa fa-undo" (click)="unRejectHaloKeyword(keyword)"></a>
              </ng-container>
              <ng-container *ngIf="!isRejected(keyword)">
                <a class="fa fa-times" (click)="rejectHaloKeyword(keyword)"></a>
              </ng-container>
              {{ getKeywordDisplayText(keyword) }}
              <span class="badge rounded-pill bg-secondary">{{keyword.Rank}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>