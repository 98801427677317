<ng-container *ngIf="requestingRedo; else notRequestingRedo">
  <div class="row justify-content-start">
    <div class="col-6">
      <ng-select #areaInput [formValidation]="areaControl" id="area"
        [items]="areas$ | async" [clearable]="false" bindLabel="Description" bindValue="PlaceId" [formControl]="areaControl"
        [loading]="areasLoading" [minTermLength]="3" [typeahead]="areaInput$" [multiple]="false"
        placeholder="Select a location for research">
      </ng-select>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary" [disabled]="redoRequestSubmitted" (click)="requestRedo()"><i class="fas fa-repeat"></i>Confirm Redo Request</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-warning" [disabled]="redoRequestSubmitted" (click)="toggleRequestingRedo()">Cancel</button>
    </div>
  </div>
</ng-container>

<ng-template #notRequestingRedo>
  <button class="btn btn-primary btn-sm" type="button" (click)="toggleRequestingRedo()">
    <i class="fas fa-repeat"></i>Redo Local Research
  </button>
</ng-template>