import { Component, TemplateRef, ContentChild, Input, OnInit } from '@angular/core';
import { FloatingPanelContentDirective } from '../../directives/floating-panel-content.directive';

@Component({
  selector: 'app-floating-panel',
  template: ''
})
export class FloatingPanelComponent implements OnInit {
  @Input() displayConfig?: IDisplayConfig;

  @ContentChild(FloatingPanelContentDirective, {read: TemplateRef, static: true}) floatingPanelContent: TemplateRef<FloatingPanelContentDirective>;

  ngOnInit(): void {
    this.displayConfig.titleBadgeClass ??= 'bg-warning';
    this.displayConfig.badgeCount ??= 0;
    this.displayConfig.size ??= 'floating-panel-sm';
    this.displayConfig.wobble ??= false;
  }
}

interface IDisplayConfig {
  icon: string;
  title: string;
  titleBadgeClass?: string;
  badgeCount?: number;
  wobble?: boolean;
  size?: string;
}