<div *withPermissions="let allow of ['CanAssignUserPermissions', 'IsLaunchPadAdmin', 'CanAssignFrontTeammate']">
	<!-- THIS IS A PRIORITY VIEW -->
	<div class="card">
		<div class="card-header">
			<span>{{userMode()}}</span>
			<button (click)="cancel()" class="btn-close" title="Close"></button>
		</div>
		<div [loadingOverlay]="isLoading()" class="card-body">
			<div class="row">
				<div class="col-md-4">
					<div class="form-group">
						<label class="control-label" for="username">Username (Email)</label>
						<input [dataValidation]="{ placement: 'top', value: username }" [ngModel]="username()" (ngModelChange)="username($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="!(allow().IsLaunchPadAdmin() || partnerId() !== 1)" [readonly]="!(allow().IsLaunchPadAdmin() || partnerId() !== 1)" class="form-control" type="text" id="partnerUsername" name="username"/>
					</div>
					<ng-container *ngIf="!isInEditMode()">
						<div class="form-group">
							<label class="control-label" for="password">Password</label>
							<input [dataValidation]="{ placement: 'top', value: userPassword }" [ngModel]="userPassword()" (ngModelChange)="userPassword($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="password" id="partnerUserpassword" name="password"/>
						</div>
					</ng-container>
					<div class="form-group">
						<label class="control-label" for="firstName">First Name</label>
						<input [dataValidation]="{ placement: 'top', value: userFirstName }" [ngModel]="userFirstName()" (ngModelChange)="userFirstName($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" id="firstName" name="firstName"/>
					</div>
					<div class="form-group">
						<label class="control-label" for="lastName">Last Name</label>
						<input [dataValidation]="{ placement: 'top', value: userLastName }" [ngModel]="userLastName()" (ngModelChange)="userLastName($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" id="lastName" name="lastName"/>
					</div>
					<div [hidden]="partnerId() !== 1" class="form-group">
						<label class="control-label">Employee Code</label>
						<input [dataValidation]="{ placement: 'top', value: userEmployeeCode }" [ngModel]="userEmployeeCode()" (ngModelChange)="userEmployeeCode($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="!allowEditEmployeeCode()" [readonly]="!allowEditEmployeeCode()" type="text" class="form-control"/>
					</div>
					<div class="form-group">
						<label class="control-label" for="phoneExtension">Extension</label>
						<input [dataValidation]="{ placement: 'top', value: userPhoneExtension }" [ngModel]="userPhoneExtension()" (ngModelChange)="userPhoneExtension($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" id="phoneExtension" name="phoneExtension"/>
					</div>
					<div class="form-group">
						<label class="control-label">Languages</label>
						<br/>
						<div [dataValidation]="{ placement: 'top', value: languageSelectionIsValid }">
							<select [ngModel]="userSelectedLanguageIds()" (ngModelChange)="userSelectedLanguageIds($event)" [ngModelOptions]="{ standalone: true }" select2 multiple="multiple" class="form-control" name="userLanguageSelection">
								<option *ngFor="let option of languages(); let $index = index" [ngValue]="option.LanguageId">{{ option.Name }}</option>
							</select>
						</div>
					</div>
					<div [hidden]="!(isInEditMode() && (allow().IsLaunchPadAdmin() || partnerId() !== 1))" class="form-group">
						<label class="control-label">Status</label>
						<br/>
						<div class="btn-group">
							<button (click)="userIsActive(true);" [class.active]="userIsActive() === true" type="button" style="width:130px;" class="btn btn-outline-secondary">
								<i [class.fa]="userIsActive() === true" [class.fa-check]="userIsActive() === true" class="fa fa-check"></i>
								Active
							</button>
							<button (click)="userIsActive(false);" [class.active]="userIsActive() === false" type="button" style="width: 130px;" class="btn btn-outline-secondary">
								<i [class.fa]="userIsActive() === false" [class.fa-check]="userIsActive() === false" class="fa fa-check"></i>
								Not Active
							</button>
						</div>
					</div>
					<div class="form-group">
						<ng-container *ngIf="partnerId() === 1">
							<label class="control-label">Business Entity</label>
							<select [dataValidation]="{ placement: 'top', value: businessEntityId }" [ngModel]="businessEntityId()" (ngModelChange)="businessEntityId($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isBusinessEntityLocked()" class="form-select">
								<option [ngValue]="undefined">The business entity this partner will be invoiced from</option>
								<option *ngFor="let option of businessEntities(); let $index = index" [ngValue]="option.BusinessEntityId">{{ option.Name }}</option>
							</select>
							<p class="help-block">
								<strong>NOTE:</strong>
								Once this field is set, it cannot be undone.
							</p>
						</ng-container>
					</div>
					<div class="form-group">
						<label class="control-label">Is Read-only</label>
						<div>
							<div>
								<shared-components-customchoicelegacy [Settings]="{ value: userIsReadonly }"></shared-components-customchoicelegacy>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="control-label">Can View Adult Content</label>
						<div>
							<div>
								<shared-components-customchoicelegacy [Settings]="{ value: canViewAdultContent }"></shared-components-customchoicelegacy>
							</div>
						</div>
					</div>
					<ng-container *ngIf="partnerId() === 1">
						<div class="form-group">
							<label class="control-label">Organizational Team</label>
							<select [ngModel]="organizationalTeamId()" (ngModelChange)="organizationalTeamId($event)" [ngModelOptions]="{ standalone: true }" class="form-select">
								<option [ngValue]="null">Choose...</option>
								<option *ngFor="let option of organizationalTeams(); let $index = index" [ngValue]="option.OrganizationalTeamId">{{ option.DisplayName }}</option>
							</select>
						</div>
					</ng-container>
					<ng-container *ngIf="allow().CanAssignFrontTeammate() && partnerId() === 1">
						<div class="form-group">
							<label class="control-label">Front Teammate</label>
							<ng-select [items]="frontTeammates" [(ngModel)]="frontTeammateId" bindLabel="Email" bindValue="Id" placeholder="Choose..."></ng-select>
						</div>
					</ng-container>
					<div class="form-group">
						<ng-container *ngIf="allow().CanAssignUserPermissions()">
							<label class="control-label">Permissions</label>
							<div>
								<select [ngModel]="userSelectedPermissions()" (ngModelChange)="userSelectedPermissions($event)" [ngModelOptions]="{ standalone: true }" select2 class="form-control" multiple="multiple">
									<option *ngFor="let option of permissions(); let $index = index" [ngValue]="option.PermissionId">{{ option.DisplayName }}</option>
								</select>
							</div>
						</ng-container>
					</div>
				</div>
				<div class="col-md-8">
					<ul class="nav nav-tabs">
						<ng-container *ngFor="let $data of currentUserLevels(); let $index = index">
							<li class="nav-item">
								<a [attr.href]="'#PartnerUserId_' + $data.id" [class.active]="$index == 0" class="nav-link" data-toggle="tab">{{$data.name}}</a>
							</li>
						</ng-container>
						<li [hidden]="!(availableUserLevels().length > 0 && (allow().IsLaunchPadAdmin() || partnerId() !== 1))" class="dropdown nav-item">
							<a class="dropdown-toggle nav-link" data-toggle="dropdown" href="javascript:void(0)">
								<i class="fa fa-plus"></i>
								Add User Level
								<span class="caret"></span>
							</a>
							<ul class="dropdown-menu">
								<ng-container *ngFor="let $data of availableUserLevels()">
									<li>
										<a (click)="addTab($data)" href="javascript:void(0);" class="dropdown-item">{{$data.name}}</a>
									</li>
								</ng-container>
							</ul>
						</li>
					</ul>
					<div class="tab-content form-horizontal">
						<div class="tab-pane" id="PartnerUserId_1">
							<p>No more data is required for this user.</p>
							<div [hidden]="!(allow().IsLaunchPadAdmin() || partnerId() !== 1)" class="form-group row">
								<label class="col-md-3 col-form-label">Delete this User Level?</label>
								<div class="col-md-8">
									<button (click)="removeTab($event)" class="btn btn-danger" data-id="1">Delete</button>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="PartnerUserId_2">
							<div class="form-group">
								<div class="col-md-4 control-label">
									<label for="editUserTaskTypes">Action Task Types</label>
								</div>
								<div class="col-md-8">
									<select [ngModel]="userSelectedTaskTypeIds()" (ngModelChange)="userSelectedTaskTypeIds($event)" [ngModelOptions]="{ standalone: true }" [select2]="{ closeOnSelect: false }" multiple="multiple" class="form-control" name="editUserTaskTypes">
										<option *ngFor="let option of filteredTaskTypes(); let $index = index" [ngValue]="option.TaskTypeEnum">{{ option.Name }}</option>
									</select>
								</div>
								<div class="col-md-8">
									<p><strong>*Note: Removing a user from a task type assignment does NOT unlock or unassign tasks that are already locked to them. If the user needs to be unlocked from task(s) they were already locked to, please manually unlock the task(s) by going to Specialist > Locked Tasks > Filter Assigned User.</strong></p>
								</div>
							</div>
							<div [hidden]="!(allow().IsLaunchPadAdmin() || partnerId() !== 1)" class="form-group">
								<label class="col-md-4 control-label">Delete this User Level?</label>
								<div class="col-md-8">
									<button (click)="removeTab($event)" class="btn btn-danger" data-id="2">Delete</button>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="PartnerUserId_4">
							<div class="form-group">
								<label class="col-md-4 control-label">Is Reseller</label>
								<div class="col-md-8">
									<div>
										<shared-components-customchoicelegacy [Settings]="{ value: userIsReseller }"></shared-components-customchoicelegacy>
									</div>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="PartnerUserId_5">
							<div [hidden]="hasCustomerServiceLevel()" class="alert alert-danger">
								<p>
									It looks like this user doesn't have a
									<strong>Customer Service</strong>
									user level. This can cause issues when trying to open and work with a Customer.
								</p>
							</div>
							<p>No more data is required for this user.</p>
							<div [hidden]="!(allow().IsLaunchPadAdmin() || partnerId() !== 1)" class="form-group">
								<label class="col-md-4 control-label">Delete this User Level?</label>
								<div class="col-md-8">
									<button (click)="removeTab($event)" class="btn btn-danger" data-id="5">Delete</button>
								</div>
							</div>
						</div>
						<div class="tab-pane" id="PartnerUserId_6">
							<div class="form-group">
								<label class="col-md-4 control-label">Is CSR</label>
								<div class="col-md-8">
									<div>
										<shared-components-customchoicelegacy [Settings]="{ value: userIsCsr }"></shared-components-customchoicelegacy>
									</div>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-4 control-label" for="editUserTicketType">Ticket Types</label>
								<div class="col-md-8">
									<div [hidden]="!partnerAllowsAllTicketTypesPermission()" style="margin-bottom:5px;">
										<shared-components-customchoicelegacy [Settings]="{ value: hasAllTicketTypes, choiceTrue: 'All Ticket Types', choiceFalse: 'Selected Ticket Types' }"></shared-components-customchoicelegacy>
									</div>
									<div [hidden]="!(!partnerAllowsAllTicketTypesPermission() && hasAllTicketTypes())" style="margin-top:7px;">
										<span>All Ticket Types</span>
									</div>
									<div [slideVisible]="!hasAllTicketTypes()">
										<select [ngModel]="userSelectedTicketTypeIds()" (ngModelChange)="userSelectedTicketTypeIds($event)" [ngModelOptions]="{ standalone: true }" [select2]="{ closeOnSelect: false }" class="form-control" multiple="multiple" name="editUserTicketType">
											<option *ngFor="let option of ticketTypes(); let $index = index" [ngValue]="option.TicketTypeId">{{ option.Name }}</option>
										</select>
									</div>
								</div>
							</div>
							<div class="form-group">
								<label class="col-md-4 control-label" for="editUserTeams">Teams</label>
								<div class="col-md-8">
									<div [hidden]="!partnerAllowsAllTeamsPermission()" style="margin-bottom:5px;">
										<shared-components-customchoicelegacy [Settings]="{ value: hasAllTeams, choiceTrue: 'All Teams', choiceFalse: 'Selected Teams' }"></shared-components-customchoicelegacy>
									</div>
									<div [hidden]="!(!partnerAllowsAllTeamsPermission() && hasAllTeams())" style="margin-top:7px;">
										<span>All Teams</span>
									</div>
									<div [slideVisible]="!hasAllTeams()">
										<select [ngModel]="userSelectedTeamIds()" (ngModelChange)="userSelectedTeamIds($event)" [ngModelOptions]="{ standalone: true }" [select2]="{ closeOnSelect: false }" class="form-control" multiple="multiple" name="editUserTeams">
											<option *ngFor="let option of teams(); let $index = index" [ngValue]="option.TeamId">{{ option.Name }}</option>
										</select>
									</div>
								</div>
							</div>
							<div [hidden]="!(allow().IsLaunchPadAdmin() || partnerId() !== 1)" class="form-group">
								<label (click)="removeTab($event)" class="col-md-4 control-label" data-id="6">Delete this User Level?</label>
								<div class="col-md-8">
									<button (click)="removeTab($event)" class="btn btn-danger" data-id="6">Delete</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="card card-body bg-light d-block text-end">
						<button (click)="cancel()" class="btn btn-link">Cancel</button>
						<button (click)="saveUpdateUser()" class="btn btn-success">
							<i class="fa fa-save"></i>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
