<div class="card" [loadingOverlay]="isLoading">
  <div class="card-header">
    Close Workflow
  </div>
  <div class="card-body">
    <div class="row mb-20">
      <div class="col-md-2">
        <input type="text" class="form-control" placeholder="Customer Id" (keyup.enter)="getWorkflows()" [(ngModel)]="customerId" disabled="true">
      </div>
      <div class="col-md-2">
        <button class="btn btn-primary btn-sm" (click)="getWorkflows()">Search</button>
      </div>
    </div>
    <ng-container *ngIf="workflows.length">
      <div class="row" *ngFor="let workflow of workflows">
        <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            {{ workflow.WorkflowId }} - {{ workflow.WorkflowDefinitionName }} <span class="badge bg-secondary">{{ workflow.WorkflowStateName }}</span>
            <button class="btn btn-primary btn-sm float-end" [disabled]="!workflow.CanClose"
              (click)="openCloseWorkflowModal(closeWorkflowModal, workflow)">
              {{ workflow.CanClose ? 'Close' : 'Cannot Close' }}
            </button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <ul class="list-group">
                  <li class="list-group-item">
                    <strong>Language</strong>
                    <span class="float-end">{{ workflow.LanguageName }}</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Keyword</strong>
                    <span class="float-end">{{ workflow.Keyword || 'N/A' }}</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Area</strong>
                    <span class="float-end">{{ workflow.Area || 'N/A' }}</span>
                  </li>
                  <li class="list-group-item">
                    <strong>Url</strong>
                    <span class="float-end">{{ workflow.Url }}</span>
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="list-group">
                  <li class="list-group-item" *ngFor="let task of workflow.Tasks">
                    <strong>Task</strong> {{ task.TaskId }} - {{ task.TaskTypeName }} <small>{{ task.InsertedDate | dateText:'MM/DD/YYYY' }}</small><span
                      class="badge bg-secondary">{{ task.TaskStatusName }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </ng-container>
  </div>

  <ng-template #closeWorkflowModal let-modal>
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Close Workflow - {{ workflowToClose.WorkflowId }} - {{ workflowToClose.WorkflowDefinitionName }}</h4>
      </div>
      <div class="modal-body">
        <label class="form-label">Note (required)</label>
        <textarea type="text" [(ngModel)]="closeNote" ngbAutofocus class="form-control"></textarea>
      </div>
      <div class="modal-footer">
        <button class="btn btn-danger" (click)="closeModal(modal)">Cancel</button>
        <button (click)="closeWorkflow(modal)" class="btn btn-primary" [disabled]="!closeNote">Save</button>
      </div>
    </div>
  </ng-template>
</div>
