<div>
  <ng-container *ngIf="showDudaSsoUrl">
    <duda-site-admin-sso-button [showSiteInfo]="true">
    </duda-site-admin-sso-button>
  </ng-container>
  <ng-container *ngIf="showBoostSiteAccounts">
    <app-components-managecustomer-readonlyboostsiteaccounts [customerId]="customer.CustomerId">
    </app-components-managecustomer-readonlyboostsiteaccounts>
  </ng-container>
  <ul class="list-group">
    <ng-container *ngFor="let customerAccount of customerAccounts">
      <app-components-managecustomer-readonlycustomeraccount [customerAccount]="customerAccount"
        class="list-group-item">
      </app-components-managecustomer-readonlycustomeraccount>
    </ng-container>
  </ul>
</div>