import TicketTypeEnum = Boo.Objects.Enums.TicketTypeEnum;

export const ticketTypeConfig = {
  alwaysRequiresAssignedUser: [
    TicketTypeEnum.ManagerActionRequired
  ],
  permanent: [
    TicketTypeEnum.OnsiteBlogReadyForPreview,
    TicketTypeEnum.OnsiteBlogContentRejected,
    TicketTypeEnum.SendOnsiteBlogPostToCustomer,
    TicketTypeEnum.ClientConcerns,
    TicketTypeEnum.FulfillmentWorkblock,
    TicketTypeEnum.SeoOnboardingWorkblock,
    TicketTypeEnum.BusinessProfileWorkblock
  ]
}
