import { Component, ViewChild } from '@angular/core';
import Check from 'framework/Check';
import CustomerAccountTypeGroup from 'app/customeraccount/CustomerAccountTypeGroup';
import System from 'framework/System';
import partner from 'app/models/partner';
import OnsiteRecommendationDetail from 'app/models/typescript/OnsiteRecommendationDetail';
import { PublishOptionsComponent } from '../../shared/PublishOptions/PublishOptions.component';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';


@Component({
  selector: 'app-components-managecustomer-logins',
  templateUrl: './logins.component.html'
})
export class LoginsComponent implements IActivatable {
  isLoading: boolean;
  customer: CustomerObservable;
  customerAccountGroupPublicApi: app.interfaces.ISave;
  hasGoogleAnalyticsAccess: boolean;
  accounts: app.managecustomer.components.interfaces.CustomerAccountViewModel[];
  refreshCustomer: () => {};
  blog: number[] = CustomerAccountTypeGroup.blog;
  seo: number[] = CustomerAccountTypeGroup.seo;
  websites: number[] = CustomerAccountTypeGroup.website;
  showSaveButton: boolean;
  title: string = 'Logins';
  onsiteRecommendationDetail: OnsiteRecommendationDetail;
  partner: any = ko.observable(new partner());
  @ViewChild(PublishOptionsComponent) publishOptionsComponent: PublishOptionsComponent;
  contentManagementSystem: Boo.Objects.ContentManagementSystem;
  enableWebsiteAccessTab: KnockoutComputed<boolean>;
  isNotIonosPartner: KnockoutObservable<boolean>;
  tabPanelDisplayMode = TabPanelDisplayMode;

  canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
    Check.isNotEmpty(params, 'Activation parameters are required');
    Check.isNotEmpty(params.customer, 'Customer is required for activation');

    this.isLoading = true;
    if (params.refreshCustomer) {
      this.refreshCustomer = params.refreshCustomer;
    }
    this.customer = params.customer;
    return params.customer;
  }

  ngOnInit(): void {
    this.showSaveButton = true;
    this.isNotIonosPartner = ko.observable(this.customer.PartnerId() !== window.launchpad.config.partners.ionos);    

    this.isLoading = false;

    Check.isNotEmpty(this.customer, 'A customer is required to edit Onsite Recommendation settings.');
  }

  customerAccountGroupApiCallback(publicApi: app.interfaces.ISave): void {
    this.customerAccountGroupPublicApi = publicApi;
  }

  save(): JQueryPromise<void> {
    this.isLoading = true;
    if (!this.customerAccountGroupPublicApi) {
      toastr.error('Could not save external access. Missing public api');
      this.isLoading = false;

      return System.emptyPromise();
    }

    let validatedResult = this.publishOptionsComponent.validate(SaveTypes.Complete);
    if (!validatedResult.isValid) {
      validatedResult.errorMessages.forEach(message => toastr.error(message));
      this.isLoading = false;
      
      return System.emptyPromise();
    }

    this.customerAccountGroupPublicApi.save()
      .then(() => this.publishOptionsComponent.save())
      .then(() => toastr.success('Publish options saved.'))
      .fail((displayMessage: string) => {
        toastr.error(displayMessage);
      })
      .always(() => {
        this.isLoading = false;
      });
  }

  getCurrentImplementationResponsibility(): Boo.OnsiteRecommendations.Models.Enums.Responsibilities {
    return this.publishOptionsComponent.getCurrentImplementationResponsibility();
  }
}
