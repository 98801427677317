import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddPartnerPricingToolPriceLevelComponent } from './AddPartnerPricingToolPriceLevel/AddPartnerPricingToolPriceLevel.component';
import { PreviewPartnerPricingToolPriceLevel } from './preview-partner-pricing-tool-price-level/preview-partner-pricing-tool-price-level.component';
import { CustomerAccountDefaultGroupComponent } from './CustomerAccountDefaultGroup/CustomerAccountDefaultGroup.component';
import { EditUserComponent } from './EditUser/EditUser.component';
import { TaskImportExportComponent } from './TaskImportExport/TaskImportExport.component';
import { OrganizationalTeamComponent } from './OrganizationalTeam/OrganizationalTeam.component';
import { OrganizationalTeamsComponent } from './OrganizationalTeams/OrganizationalTeams.component';
import { LeadSourceComponent } from './LeadSource/LeadSource.component';
import { LeadSourcesComponent } from './LeadSources/LeadSources.component';
import { LinkLocationComponent } from './LinkLocation/LinkLocation.component';
import { LinkTypeActivationComponent } from './LinkTypeActivation/LinkTypeActivation.component';
import { ManagePartnerPricingToolComponent } from './ManagePartnerPricingTool/ManagePartnerPricingTool.component';
import { ManagePartnersComponent } from './ManagePartners/ManagePartners.component';
import { ManageReportingComponent } from './ManageReporting/ManageReporting.component';
import { ManageUsersComponent } from './ManageUsers/ManageUsers.component';
import { MerchantFieldsComponent as MerchantFieldsComponent2 } from './MerchantFields/MerchantFields.component';
import { PremiumBacklinkComponent } from './PremiumBacklink/PremiumBacklink.component';
import { PartnerComponent } from './Partner/Partner.component';
import { PartnerFilesComponent } from './PartnerFiles/PartnerFiles.component';
import { PartnerPricingToolDetailComponent } from './PartnerPricingToolDetail/PartnerPricingToolDetail.component';
import { PartnerPricingToolFeatureListComponent } from './PartnerPricingToolFeatureList/PartnerPricingToolFeatureList.component';
import { EditSeoMinuteFeaturesComponent } from './edit-seo-minute-features/edit-seo-minute-features.component';
import { PartnerUserComponent } from './PartnerUser/PartnerUser.component';
import { ProTipComponent } from './ProTip/ProTip.component';
import { ProTipsComponent } from './ProTips/ProTips.component';
import { TaskStewardshipComponent } from './TaskStewardship/TaskStewardship.component';
import { TaskStewardshipsComponent } from './TaskStewardships/TaskStewardships.component';
import { RegistrationPhoneNumbersComponent } from './RegistrationPhoneNumbers/RegistrationPhoneNumbers.component';
import { ReportingCustomizationsComponent } from './ReportingCustomizations/ReportingCustomizations.component';
import { RepublishPostComponent } from './RepublishPost/RepublishPost.component';
import { BlacklistDomainsComponent } from './BlacklistDomains/BlacklistDomains.component';
import { ConfigurePartnerPricingToolComponent } from './ConfigurePartnerPricingTool/ConfigurePartnerPricingTool.component';
import { TransferAccountsComponent } from './TransferAccounts/TransferAccounts.component';
import { ProgressRatingComponent } from './ProgressRating/ProgressRating.component';
import { PartnerFrontChannelComponent } from './partner-front-channel/partner-front-channel.component';
import { PartnerTaskTypeExclusionsComponent } from './partner-tasktype-exclusions/partner-tasktype-exclusions.component';
import { AllocationTestResultsComponent } from './PartnerSeoWorkDefaults/allocation-test-results/allocation-test-results.component';
import { PartnerSeoWorkDefaultsComponent } from './PartnerSeoWorkDefaults/partner-seo-work-defaults/partner-seo-work-defaults.component';
import { PartnerSeoWorkDefaultsByHoursComponent } from './PartnerSeoWorkDefaults/partner-seo-work-defaults-by-hours/partner-seo-work-defaults-by-hours.component';
import { TaskTypeSelectionComponent } from './PartnerSeoWorkDefaults/task-type-selection/task-type-selection.component';
import { PartnerTaskTypeSelectionComponent } from './partner-tasktype-exclusions/partner-tasktype-selection/partner-tasktype-selection.component';
import { ConfigureQaTasksComponent } from './ConfigureQaTasks/ConfigureQaTasks.component';
import { PartnerEmailsComponent } from './partner-emails/partner-emails.component';
import { NotificationsComponent } from './notifications/notifications.component';


@NgModule({
  declarations: [
    AddPartnerPricingToolPriceLevelComponent,
    PreviewPartnerPricingToolPriceLevel,
    CustomerAccountDefaultGroupComponent,
    EditUserComponent,
    TaskImportExportComponent,
    OrganizationalTeamComponent,
    OrganizationalTeamsComponent,
    LeadSourceComponent,
    LeadSourcesComponent,
    LinkLocationComponent,
    LinkTypeActivationComponent,
    ManagePartnerPricingToolComponent,
    ManagePartnersComponent,
    ManageReportingComponent,
    ManageUsersComponent,
    MerchantFieldsComponent2,
    PremiumBacklinkComponent,
    PartnerComponent,
    PartnerFilesComponent,
    PartnerPricingToolDetailComponent,
    PartnerPricingToolFeatureListComponent,
    EditSeoMinuteFeaturesComponent,
    PartnerUserComponent,
    ProgressRatingComponent,
    ProTipComponent,
    ProTipsComponent,
    RegistrationPhoneNumbersComponent,
    ReportingCustomizationsComponent,
    RepublishPostComponent,
    BlacklistDomainsComponent,
    ConfigurePartnerPricingToolComponent,
    TaskStewardshipComponent,
    TaskStewardshipsComponent,
    TransferAccountsComponent,
    PartnerFrontChannelComponent,
    PartnerTaskTypeExclusionsComponent,
    AllocationTestResultsComponent,
    PartnerSeoWorkDefaultsComponent,
    PartnerSeoWorkDefaultsByHoursComponent,
    TaskTypeSelectionComponent,
    PartnerTaskTypeSelectionComponent,
    ConfigureQaTasksComponent,
    PartnerEmailsComponent,
    NotificationsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedComponentsModule,
    DragDropModule
  ]
})
export class ManagerModule { }
