<div *withPermissions="let allow of ['CanViewMyTickets', 'CanSearchKeywordCustomers', 'CanLookupCreditCards', 'CanCreateTicketBatch']" class="card">
	<div class="card-body py-2">
		<ul navigationHandler class="nav nav-pills">
			<li class="nav-item">
				<a [hidden]="!isHelpNextCustomerVisible" href="/#/customerservice/helpnextcustomer" class="nav-link">
					<i class="fa fa-briefcase"></i>
					Help Next Customer
				</a>
			</li>
			<li class="nav-item">
				<a [hidden]="!isManageTicketsVisible" href="/#/customerservice/managetickets" class="nav-link">
					<i class="fa fa-tags"></i>
					Manage Tickets
				</a>
			</li>
			<li class="nav-item">
				<a href="/#/customerservice/customersearch" class="nav-link">
					<i class="fa fa-search"></i>
					Customer Search
				</a>
			</li>
			<li class="nav-item">
				<a [hidden]="!isManagerToolsVisible" href="/#/customerservice/managertools" class="nav-link">
					<i class="fa fa-star"></i>
					Manager Tools
				</a>
			</li>
		</ul>
	</div>
	<div [slideVisible]="showManagerToolsSubMenu" class="card-footer py-2" style="display: none;">
		<ul navigationHandler class="nav nav-pills">
			<li class="nav-item" [hidden]="!isResetCustomerVisible">
				<a href="/#/customerservice/managertools" class="nav-link">Reset Customer Website</a>
			</li>
			<li class="nav-item" [hidden]="!isCsrsVisible">
				<a href="/#/customerservice/csrs" class="nav-link">Assign CSRs</a>
			</li>
			<li [hidden]="!isCustomerAssignmentsVisible">
				<a href="/#/customerservice/customerpriorityassignments" class="nav-link">Help Next Customer Assignments</a>
			</li>
		</ul>
	</div>
	<div [hidden]="!canAssignTicketTypes">
		<div [slideVisible]="showManageTicketsSubMenu" class="card-footer py-2" style="display: none;">
			<ul navigationHandler class="nav nav-pills">
				<li class="nav-item">
					<a href="/#/customerservice/managetickets" class="nav-link">Manage Tickets</a>
				</li>
				<li class="nav-item">
					<a href="/#/customerservice/assigntickettypes" class="nav-link">Assign Ticket Types</a>
				</li>
				<li [hidden]="!allow().CanCreateTicketBatch()" class="nav-item">
					<a href="/#/customerservice/createticketbatch" class="nav-link">Create Ticket Batch</a>
				</li>
			</ul>
		</div>
	</div>
</div>
