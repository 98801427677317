import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { QaGradingArea } from '../../../../qa/qa-grading-area/qa-grading-area.component';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';
import { Subject } from 'rxjs';
import { InternalActionToken } from '../../internal-action.token';
import OnsiteCopyPageTypeFactory from '../../../../../factories/onsite-copy-page-type.factory';
import QaCriteriaGroup = Boo.Qa.QaCriteriaGroup;
import FloatingPanel = app.components.interfaces.FloatingPanel;
import IFloatingTabPanelPublicApi = app.components.interfaces.IFloatingTabPanelPublicApi;
import FloatingTabPanelSize = app.components.enums.FloatingTabPanelSize;
import FloatingTabPanelIcon = app.components.enums.FloatingTabPanelIcon;
import TitleBadgeClass = app.components.enums.FloatingTabPanelTitleBadgeClass;

@Component({
  selector: 'app-components-specialist-internal-tasks-onsite-copy-review-qa',
  templateUrl: './onsite-copy-review-qa.component.html'
})
export class OnsiteCopyReviewQaComponent implements OnInit, CompletableWorkspace, UnlockableWorkspace, ClosableWorkspace {
  showCompleteButton: boolean = false;
  showUnlockButton: boolean = true;
  showCloseButton: boolean = true;
  complete = new Subject<void>();
  unlock = new Subject<void>();
  close = new Subject<void>();
  
  @ViewChild('gradingArea')
  gradingArea: QaGradingArea;
  taskType: Boo.Objects.Enums.TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaOnsiteCopyReview;
  qaCriteriaGroups: QaCriteriaGroup[] = [];

  editorToolbar: any = {
    toolbar: [
      {
        name: 'document',
        items: ['Source', 'Maximize']
      },
      {
        name: 'editing',
        items: ['SelectAll']
      },
      {
        name: 'clipboard',
        items: ['Copy']
      }
    ],
    readOnly: true,
    allowedContent: true
  };

  copyScapeUrl: string = 'https://www.copyscape.com/';

  autoFailLabel: string = 'Auto Fail';
  editorsFeedbackLabel: string = 'Editor\'s Feedback';
  editingQualityLabel: string = 'Editing Quality';
  lastNote: Boo.Objects.TaskNote;
  url: string;
  wasAccepted: boolean;
  pageType: string;
  recommendedWordCount: number;
  orderReason: string;
  instructions: string;
  boostToPublish: boolean;
  pageStrategy: string;
  originalContent: string;
  originalWordCount: number;
  reviewedContent: string;
  reviewedWordCount: number;
  feedback: string;
  noteToQa: string;
  keywordSiteAnalyses: Boo.Objects.KeywordSiteAnalysis[];
  googleSearchUrl: string;
  originalTitleTag: string;
  reviewedTitleTag: string;
  originalMetaDescription: string;
  reviewedMetaDescription: string;
  originalHeaders: string;
  reviewedH1: string;
  reviewedH2: string;
  hasOnsiteRecommendationEditHistory: boolean;
  submittedTitleTag: string;
  submittedMetaDescription: string;
  submittedH1: string;
  submittedH2: string;
  implementationInstructions: string;
  floatingTabPanelApi: IFloatingTabPanelPublicApi;
  floatingTabPanelApiCallback(api: IFloatingTabPanelPublicApi): void {
    this.floatingTabPanelApi = api;
    this.informationFloatingTabPanels.forEach((floatingTabPanel) => {
      this.floatingTabPanelApi.addPanel(floatingTabPanel);
    });
  }

  private informationFloatingTabPanels: FloatingPanel[];

    constructor(
        @Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction,
        private onsiteCopyPageTypeFactory: OnsiteCopyPageTypeFactory) { }

  ngOnInit() {
    //Create the criteria float panel
    (<any>$('#internal-task-qa-criteria-float-panel')).floatPanel({ collapsed: false });

    this.qaCriteriaGroups = this.internalAction.InternalTaskDetails.QaCriteriaGroups;

    let snapshot = this.internalAction.InternalTaskDetails.OnsiteCopyReviewSnapshot;

    this.lastNote = this.internalAction.InternalTaskDetails.AssociatedTaskNotes.length !== 0 ? this.internalAction.InternalTaskDetails.AssociatedTaskNotes[0] : null;
    this.url = this.internalAction.InternalTaskDetails.WebsiteUrl.Url;
    this.wasAccepted = this.internalAction.InternalTaskDetails.AssociatedTask.TaskStatusEnum === Boo.Objects.Enums.TaskStatusEnum.Complete;
    this.pageType = snapshot.PageType;
    this.recommendedWordCount = this.onsiteCopyPageTypeFactory.getWordCount(snapshot.PageType);
    this.orderReason = snapshot.OrderReason;
    this.instructions = snapshot.Instructions;
    this.boostToPublish = snapshot.BoostToPublish;
    this.pageStrategy = snapshot.PageStrategy;
    this.originalContent = snapshot.OriginalContent;
    this.originalWordCount = window.launchpad.wordCount(snapshot.OriginalContent);
    this.reviewedContent = snapshot.ReviewedContent;
    this.reviewedWordCount = window.launchpad.wordCount(snapshot.ReviewedContent);
    this.feedback = snapshot.Feedback
    this.noteToQa = this.internalAction.InternalTaskDetails.NoteToQa;
    this.keywordSiteAnalyses = snapshot.KeywordSiteAnalyses;
    this.googleSearchUrl = this.internalAction.InternalTaskDetails.AssociatedTaskCustomer.Country.GoogleSearchUrl;
    this.originalTitleTag = snapshot.OriginalTitleTag;
    this.reviewedTitleTag = snapshot.ReviewedTitleTag;
    this.originalMetaDescription = snapshot.OriginalMetaDescription;
    this.reviewedMetaDescription = snapshot.ReviewedMetaDescription;
    this.originalHeaders = snapshot.OriginalHeaders;
    this.reviewedH1 = snapshot.ReviewedH1;
    this.reviewedH2 = snapshot.ReviewedH2;
    this.implementationInstructions = this.internalAction.InternalTaskDetails.ImplementationInstructions;

    this.hasOnsiteRecommendationEditHistory = this.internalAction.InternalTaskDetails.OnsiteRecommendationEditHistory !== null;

    if (this.hasOnsiteRecommendationEditHistory) {
      this.submittedTitleTag = this.internalAction.InternalTaskDetails.OnsiteRecommendationEditHistory.TitleTag;
      this.submittedMetaDescription = this.internalAction.InternalTaskDetails.OnsiteRecommendationEditHistory.MetaDescription;
      this.submittedH1 = this.internalAction.InternalTaskDetails.OnsiteRecommendationEditHistory.H1;;
      this.submittedH2 = this.internalAction.InternalTaskDetails.OnsiteRecommendationEditHistory.H2;
    }

    this.informationFloatingTabPanels = [
      {
        title: 'Information',
        titleIcon: FloatingTabPanelIcon.Info,
        size: FloatingTabPanelSize.Medium,
        composition: {
          selector: 'app-components-specialist-actioncomponents-information',
          options: { customer: this.internalAction.InternalTaskDetails.AssociatedTaskCustomer }
        }
      }, {
        title: 'Comments',
        titleIcon: FloatingTabPanelIcon.Comments,
        titleBadge: () => this.internalAction.InternalTaskDetails.AssociatedTaskNotes.length,
        titleBadgeClass: TitleBadgeClass.Warning,
        composition: {
          selector: 'app-components-specialist-actioncomponents-comments',
          model: { comments: this.internalAction.InternalTaskDetails.AssociatedTaskNotes }
        }
      }, {
        title: 'Files',
        titleIcon: FloatingTabPanelIcon.File,
        titleBadge: () => this.internalAction.InternalTaskDetails.Files.length,
        titleBadgeClass: TitleBadgeClass.Warning,
        size: FloatingTabPanelSize.Medium,
        composition: {
          selector: 'app-components-specialist-actioncomponents-files',
          model: { files: this.internalAction.InternalTaskDetails.Files }
        }
      }
    ];
  }

   isReadyToComplete() {
    if (!this.gradingArea.validate()) {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return false;
    }

    this.internalAction.InternalTaskDetails.QaCriteriaDetails = this.gradingArea.getDetails();
    this.internalAction.InternalTaskDetails.QaGrade.Comment = this.gradingArea.gradeComment;
    return true;
  }
}
