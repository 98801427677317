import { Component, TemplateRef } from '@angular/core';
import timer from 'app/managecustomer/Timer';
import permissions from 'app/models/Permissions';
import customer from 'app/models/customer';
import { CustomerService } from '../../../services/customer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SeoWorkService } from '../../../services/seo-work.service';
import { finalize } from 'rxjs/operators';
import FlagSources = Boo.Objects.Work.SeoMinute.Enums.FlagSources;
import FlagTypes = Boo.Objects.Work.SeoMinute.Enums.FlagTypes;
import { onsiteBloggingOrderingContentOnlyTaskTypes, onsiteBloggingOrderingWithImplementationTaskTypes } from 'app/models/collections/TaskTypeCollections';
import WorkRequestHelper from 'app/managecustomer/WorkRequestHelper';
import { ReservedMinutesDoublyLinkedList } from './Models/ReservedMinutesDoublyLinkedList';
import { ReservedMinutesNode } from './Models/ReservedMinutesNode';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-managecustomer-allocatework',
  templateUrl: './AllocateWork.component.html',
  styleUrls: ['./AllocateWork.component.scss']
})
export class AllocateWorkComponent {

  partner: KnockoutObservable<any> = ko.observable();
  partnerUser: KnockoutObservable<Boo.Objects.PartnerUser> = ko.observable(null);
  partnerUsers: KnockoutObservableArray<any> = ko.observableArray([]);
  customer: KnockoutObservable<any> = ko.observable(ko.mapping.fromJS(new customer()));
  user: KnockoutObservable<any> = ko.observable();

  isLoading: KnockoutObservable<boolean> = ko.observable();
  seoWorkSettings: Boo.Objects.Work.SeoMinute.SeoWorkSettings;
  defaults: Boo.Objects.Work.SeoMinute.SeoWorkDefault[] = [];
  taskTypes: Boo.Objects.Work.SeoMinute.SeoWorkTaskType[] = [];

  activeTab: number = 0;
  periods: number[] = [];
  allReservedMinutes: ReservedMinutesDoublyLinkedList = new ReservedMinutesDoublyLinkedList();
  billingPeriods: Period[] = [];
  activeBillingPeriods: Period[] = [];
  inactiveBillingPeriods: Period[] = [];
  selectedNewBillingPeriod: Period;
  selectedPeriodToDelete: Period;
  taskTypeGroupings: TaskTypeWorkGroup[] = [];
  defaultAllocationScreenId: number = -1;
  pastPeriodsScreenId: number = -2;
  allocateWorkErrors: ScreenErrors[] = [];
  checkingBlocks: boolean = false;

  refreshCustomer: any = null;
  configurationChangeNote: KnockoutObservable<string> = ko.observable('').extend({
    required: { message: 'Configuration change note is required.' }
  });

  pageValidation: any = ko.validatedObservable({
    configurationChangeNote: this.configurationChangeNote
  });

  constructor(
    private modalService: NgbModal,
    private seoWorkService: SeoWorkService,
    private customerService: CustomerService,
    private sessionStorageService: SessionStorageService) { }

  activate(options: any): JQueryPromise<void> {
    this.isLoading(true);
    if (options.refreshCustomer) {
      this.refreshCustomer = options.refreshCustomer;
    }
    return $.when<any>(
      Utils.wrapDfd(this.sessionStorageService.getPartnerUser()),
      Utils.wrapDfd(this.sessionStorageService.getUser()),
      Utils.wrapDfd(this.sessionStorageService.getPartnerUsers()),
      Utils.wrapDfd(this.sessionStorageService.getPartner())
    )
    .then((partnerUser, user, partnerUsers, partner) => {
      this.partnerUser(partnerUser);
      this.partner(partner);
      this.user(user);
      this.partnerUsers(partnerUsers);
      options = options || {};

      this.customer(options.customer);
      return this.loadSeoWorkSettings();
    });
  }

  loadSeoWorkSettings(): void {
    this.seoWorkService.getSeoWorkSettings(this.customer().CustomerId())
      .subscribe(
        (seoWorkSettings) => {
          // If the user does not have permission for custom other tasks then then flag as unavailable
          if (!launchpad.hasPermission(this.partner(), this.partnerUsers(), permissions.CanInsertCustomActionOther, this.user())) {
            let customTaskType = seoWorkSettings.TaskTypes.find(x => x.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.Custom);
            customTaskType.Flags.push({ Source: FlagSources.Permission, Type: FlagTypes.Unavailable, Message: 'Additional permission required.' } as Boo.Objects.Work.SeoMinute.TaskTypeFlag);
          }

          let onsiteRecommendationOrderingTypes = [
            Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendation,
            Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendationContentOnly,
            Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendationAndImplementation
          ];
          
          // If the user does not have permission for adding onsite recommendation tasks then flag as unavailable
          if (!launchpad.hasPermission(this.partner(), this.partnerUsers(), permissions.CanCreateOnsiteRecommendationTasks, this.user())) {
            let osrTaskType = seoWorkSettings.TaskTypes.find(x => onsiteRecommendationOrderingTypes.includes(x.TaskTypeEnum));
            osrTaskType.Flags.push({ Source: FlagSources.Permission, Type: FlagTypes.Unavailable, Message: 'Additional permission required.' } as Boo.Objects.Work.SeoMinute.TaskTypeFlag);
          }

          this.seoWorkSettings = seoWorkSettings;
          this.allReservedMinutes.load(this.seoWorkSettings.SeoMinuteReservations);
          this.setPeriods();

          // add missing reserved minutes to match workrequests
          this.periods.forEach(period => {
            let reservedMinutesNodeForPeriod = this.allReservedMinutes.findByPeriod(period);
            if (!reservedMinutesNodeForPeriod) {
              this.allReservedMinutes.add({
                SeoMinuteReservationId: 0,
                CustomerId: this.customer().CustomerId(),
                Period: period,
                Quantity: 0,
                UsedDate: null,
                InsertedByUserId: this.user().UserId,
                InsertedDate: new Date()
              });
            }
          });

          this.setBillingPeriods();
          this.taskTypes = seoWorkSettings.TaskTypes;
          this.generateTaskTypeGroupings();
          this.defaults = seoWorkSettings.Defaults;
          this.setActiveTab(this.firstAvailablePeriod());
        },
        (err) => toastr.error(err),
        () => this.isLoading(false));
  }

  save(): void {
    this.seoWorkSettings.SeoMinuteReservations = this.allReservedMinutes.getSeoMinuteReservations();
    this.seoWorkSettings.Defaults = this.defaults;

    if (!this.pageValidation.isValid()) {
      this.pageValidation.errors.showAllMessages();
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return;
    }

    this.validatePeriods();
    this.validateDefaults();
    if (this.allocateWorkErrors.length) {
      return;
    }
    this.saveSeoWorkSettings();
  }

  workByPeriod(period: number): Boo.Objects.Work.SeoMinute.WorkRequestDetail[] {
    return this.seoWorkSettings.WorkRequestDetails.filter(x => x.WorkRequest.Period === period);
  }

  removeGroup($event: TaskTypeWorkGroup): void {
    this.taskTypeGroupings = this.taskTypeGroupings
      .filter(x => x.period !== $event.period || x.taskTypeName !== $event.taskTypeName); // filter out the specific group
    this.setUpValidationFlags();
  }

  addGroup($event: TaskTypeWorkGroup): void {
    this.taskTypeGroupings.push($event);
    this.sortTaskGroupings();
    this.setUpValidationFlags();
  }

  editWorkRequest(): void {
    this.setUpValidationFlags();
  }

  defaultsChange(defaults: Boo.Objects.Work.SeoMinute.SeoWorkDefault[]): void {
    this.seoWorkSettings.Defaults = defaults;
    this.setUpValidationFlags();
  }

  reloadCustomer(): void {
    if (this.refreshCustomer) {
      this.refreshCustomer();
    }
  }

  refreshCurrentMinutes(): void {
    this.customerService.getCustomerOnly(this.customer().CustomerId())
      .subscribe(updatedCustomer => {
        this.seoWorkSettings.AllotedSeoMinutesForPeriods[Boo.WorkRequests.Period.Current] = updatedCustomer.SeoMinutesTotal;
      });
  }

  resetSeoMinutes(): void {
    bootbox.confirm('The customer\'s SEO minutes will be reset to match their most recent SEO product. Free minutes and roll over minutes will not be changed. Are you sure you want to reset minutes?',
      (result: any) => {
        if (result !== true) {
          return;
        }
        this.isLoading(true);
        Utils.wrapDfd(this.customerService.resetSeoMinutes(this.customer().CustomerId()))
          .then(() => {
            toastr.success('Customer\'s minutes were reset');
            this.handleSettingsSaved();
          }).fail((displayMessage) => {
            toastr.error(displayMessage);
          }).always(() => {
            this.isLoading(false);
          });
      });
  }

  clearPeriod(period: Period): void {
    this.taskTypeGroupings = this.taskTypeGroupings.filter(x => x.period !== period.index);
    if (this.defaults.some(x => x.TaskTypeId === Boo.Objects.Enums.TaskTypeEnum.LocalBusinessCitationSubscription)) {
      this.addLocalBusinessCitationSubscriptionToPeriod(period);
    }
  }

  deletePeriod(content: TemplateRef<any>, period: Period): void {
    this.selectedPeriodToDelete = period;
    this.modalService.open(content).result
      .then(() => {
        this.taskTypeGroupings = this.taskTypeGroupings.filter(x => x.period !== period.index);
        this.billingPeriods.find(x => x.index === period.index).active = false;
        this.updateActivePeriods();
        this.selectedPeriodToDelete = null;
        this.setActiveTab(this.firstAvailablePeriod());
        this.allReservedMinutes.removeByPeriod(period.index);
      }, () => {
        this.selectedPeriodToDelete = null;
      });
  }

  addBillingPeriod(): void {
    this.billingPeriods.find(x => x.index === this.selectedNewBillingPeriod.index).active = true;
    this.updateActivePeriods();
    this.implementDefaults(this.selectedNewBillingPeriod);
    this.setActiveTab(this.selectedNewBillingPeriod.index);
    this.selectedNewBillingPeriod = null
  }

  setBillingPeriods(): void {
    const startDateUtc = this.getStartDateUtc();

    // add active periods
    this.periods.forEach(period => {
      const displayDate = moment(startDateUtc).utc().add(period, 'month').format('L');
      const periodHasWork = this.seoWorkSettings.WorkRequestDetails.some(x => x.WorkRequest.Period === period);
      const periodHasReservedMinutes = this.allReservedMinutes.findQtyByPeriod(period) > 0;
      const billingPeriod = { index: period, displayDate: displayDate, active: periodHasWork || periodHasReservedMinutes } as Period;
      this.billingPeriods.push(billingPeriod);
    });

    // sort periods
    this.billingPeriods = this.billingPeriods.sort((a, b) => a.index - b.index);

    // add additional inactive periods for selection
    const additionalMonths = 12;
    for (let index = 1; index <= additionalMonths; index++) {
      const displayDate = moment(startDateUtc).utc().add(index, 'month').format('L');
      const additionalInactiveBillingPeriod = { index: index, displayDate: displayDate, active: false } as Period;

      if (!this.billingPeriods.map(x => x.index).includes(additionalInactiveBillingPeriod.index)) {
        this.billingPeriods.push(additionalInactiveBillingPeriod);
      }
    }

    // set active / inactive periods
    this.updateActivePeriods();
  }

  filterGroupsByPeriod(period: Period): TaskTypeWorkGroup[] {
    return this.taskTypeGroupings.filter(x => x.period === period.index);
  }

  filterReserveMinutesByPeriod(period: Period): ReservedMinutesNode {
    return this.allReservedMinutes.findByPeriod(period.index);
  }

  filterScreenErrors(screenId: number): ScreenErrors {
    return this.allocateWorkErrors.find(x => x.screenId === screenId);
  }

  screenHasErrors(screenId: number): boolean {
    if (!this.allocateWorkErrors.length) {
      return false;
    }

    const screenError = this.filterScreenErrors(screenId);
    return screenError ? !!screenError.errorMessages : false;
  }

  validateScreen(screenId: number): void {
    if (screenId === this.defaultAllocationScreenId) {
      this.validateDefaults();
      return;
    }

    this.validatePeriod(screenId);
  }

  activeScreenChange($event: number): void {
    this.setUpValidationFlags();
  }

  checkWorkOrderBlocks(): void {
    this.checkingBlocks = true;
    this.seoWorkService.checkWorkOrderBlocks(this.customer().CustomerId())
      .subscribe(
        () => toastr.success('Rechecking Validation, Please allow up to five minutes for this process to complete.'),
        err => toastr.error(err)
      );
  }

  private handleSettingsSaved(): void {
    this.configurationChangeNote();
    this.configurationChangeNote.isModified(false);
    if (this.refreshCustomer) {
      this.refreshCustomer();
    }
  }

  private saveSeoWorkSettings(): void {
    const itemsToDelete = this.itemsToDelete();
    this.seoWorkSettings.WorkRequestDetails = _.flatten(this.taskTypeGroupings.map(x => x.workItems));
    // sanitize ids
    this.seoWorkSettings.WorkRequestDetails.filter(x => x.WorkRequest.WorkRequestId < 0).forEach(y => y.WorkRequest.WorkRequestId = 0);
    const noteText = this.configurationChangeNote().trim();
    this.isLoading(true);

    this.seoWorkService.saveSeoWorkSettings(this.customer().CustomerId(), this.seoWorkSettings, itemsToDelete, noteText)
      .pipe(
        finalize(() => this.isLoading(false))
      )
      .subscribe(() => {
        toastr.success('The settings were saved successfully.');
        this.handleSettingsSaved();
        timer.resetTicketSaveTime();
      }, (err) => toastr.error(err));
  }

  private setPeriods(): void {
    this.periods.push(Boo.WorkRequests.Period.Current);
    
    if (!this.seoWorkSettings.WorkRequestDetails.length && !this.seoWorkSettings.SeoMinuteReservations.length) {
      return;
    }

    this.periods.push(
      ...Array.from(
        new Set([
          ...this.seoWorkSettings.WorkRequestDetails.map(x => x.WorkRequest.Period),
          ...this.seoWorkSettings.SeoMinuteReservations.map(x => x.Period)
        ])
      )
      .filter(x => x !== Boo.WorkRequests.Period.Current)
      .sort((a, b) => a - b)
    );
  }

  private generateTaskTypeGroupings(): void {
    this.seoWorkSettings.WorkRequestDetails.forEach(x => {
      // add group
      if (!this.taskTypeGroupings.some(y => y.taskTypeName === x.WorkDisplayName && y.period === x.WorkRequest.Period)) {
        this.taskTypeGroupings.push({
          taskTypeId: x.WorkRequest.WorkTypeCreationId,
          taskTypeName: x.WorkDisplayName,
          taskTypeDescription: x.WorkDescription,
          workItems: [],
          isManual: this.isTaskTypeManual(x.WorkRequest.WorkTypeCreationId),
          qty: 0,
          period: x.WorkRequest.Period
        });
      }

      // add work item
      const group = this.taskTypeGroupings.find(y => y.taskTypeName === x.WorkDisplayName && y.period === x.WorkRequest.Period);
      group.workItems.push(x);
      group.qty++;
    });

    this.sortTaskGroupings();
  }

  private sortTaskGroupings(): void {
    this.taskTypeGroupings
      // sort by name
      .sort((a, b) => a.taskTypeName.localeCompare(b.taskTypeName))
      // sort by minutes per task
      .sort((a, b) => a.workItems[0].WorkRequest.WorkQuantity - b.workItems[0].WorkRequest.WorkQuantity)
      // sort by manual tasks
      .sort((a, b) => +this.isTaskTypeManual(a.workItems[0].WorkRequest.WorkTypeCreationId) - +this.isTaskTypeManual(b.workItems[0].WorkRequest.WorkTypeCreationId));
  }

  private isTaskTypeManual(taskTypeId: number): boolean {
    return this.taskTypes.find(x => x.TaskTypeId === taskTypeId).IsCustom;
  }

  private updateActivePeriods(): void {
    this.activeBillingPeriods = this.billingPeriods.filter(x => x.active).sort((a, b) => a.index - b.index);
    this.inactiveBillingPeriods = this.billingPeriods.filter(x => !x.active);
  }

  private setActiveTab(index: number): void {
    this.activeTab = this.activeBillingPeriods.length ? index : this.defaultAllocationScreenId;
    this.activeScreenChange(this.activeTab);
  }

  private validatePeriod(periodIndex: number): void {
    const periodReservedMinutes = this.allReservedMinutes.findQtyByPeriod(periodIndex) || 0;
    const previousReservedMinutes = this.allReservedMinutes.findByPeriod(periodIndex)?.previous?.quantity || 0;
    const periodWork = this.taskTypeGroupings.filter(x => x.period === periodIndex);
    const allotedMinutes = this.seoWorkSettings.AllotedSeoMinutesForPeriods[periodIndex] + previousReservedMinutes;
    const allocatedMinutes: number = _.flatten(periodWork.map(x => x.workItems)).map(x => x.WorkRequest.WorkQuantity).reduce((a, b) => a + b) + periodReservedMinutes;
    

    if (allocatedMinutes !== allotedMinutes) {
      const period = this.activeBillingPeriods.find(x => x.index === periodIndex);
      this.handleMinutesValidationMessage(allocatedMinutes, allotedMinutes, period.index, period.displayDate);
    }

    // timeout is implemented to trigger angular change detection on the tabs
    setTimeout(() => {
      this.allocateWorkErrors = this.allocateWorkErrors.filter(x => x.screenId !== periodIndex);
    }, 1);
  }

  private validatePeriods(): boolean {
    const workByPeriod = _.groupBy(this.taskTypeGroupings.filter(x => x.workItems.length), x => x.period);
    for (const key in workByPeriod) {
      const workPeriod: TaskTypeWorkGroup[] = _.flatten(workByPeriod[key]);
      const periodIndex = workPeriod[0].period;
      const periodReservedMinutes = this.allReservedMinutes.findQtyByPeriod(periodIndex) || 0;
      const previousReservedMinutes = this.allReservedMinutes.findByPeriod(periodIndex)?.previous?.quantity || 0;
      const allotedMinutes = this.seoWorkSettings.AllotedSeoMinutesForPeriods[periodIndex] + previousReservedMinutes;
      const allocatedMinutes = _.flatten(workPeriod.map(x => x.workItems)).map(x => x.WorkRequest.WorkQuantity).reduce((a, b) => a + b) + periodReservedMinutes;
      const period = this.activeBillingPeriods.find(x => x.index === periodIndex);

      if (allocatedMinutes !== allotedMinutes) {
        this.handleMinutesValidationMessage(allocatedMinutes, allotedMinutes, period.index, period.displayDate);
      }
    }

    return !this.allocateWorkErrors.some(x => x.screenId >= Boo.WorkRequests.Period.Current);
  }

  private validateDefaults(): boolean {
    let valid = true;

    if (this.seoWorkSettings.AllotedSeoMinutesForDefaults === 0 && this.seoWorkSettings.ReservedSeoMinutesDefault === 0) {
      return valid = true;
    }

    if (!this.seoWorkSettings.Defaults.length && this.seoWorkSettings.ReservedSeoMinutesDefault === 0) {
      let errorMsg = '“Default” allocation must be configured.';
      toastr.error(errorMsg);
      this.addScreenError(this.defaultAllocationScreenId, errorMsg);
      this.setActiveTab(this.defaultAllocationScreenId);
      return valid = false;
    }

    // validate minutes
    const allocatedDefaultMinutes = (this.seoWorkSettings.Defaults.map(x => x.MinutesPerTask * x.TaskCount).reduce((a, b) => a + b, 0) ?? 0) + this.seoWorkSettings.ReservedSeoMinutesDefault;
    if (allocatedDefaultMinutes !== this.seoWorkSettings.AllotedSeoMinutesForDefaults) {
      this.handleMinutesValidationMessage(allocatedDefaultMinutes, this.seoWorkSettings.AllotedSeoMinutesForDefaults, this.defaultAllocationScreenId);
      valid = false;
    }

    // validate against non-workable task types
    const defaultsIds = this.seoWorkSettings.Defaults.map(x => x.TaskTypeId);
    const nonWorkablesIds = this.seoWorkSettings.TaskTypes.filter(x => x.Flags.some(y => y.Type === FlagTypes.Nonworkable)).map(x => x.TaskTypeId);
    if (defaultsIds.some(x => nonWorkablesIds.includes(x))) {
      const errorMsg = `Defaults cannot include non-workable task type(s)`;
      toastr.error(errorMsg);
      this.addScreenError(this.defaultAllocationScreenId, errorMsg);
      valid = false;
    }

    if (valid) {
      // timeout is implemented to trigger angular change detection on the tabs
      setTimeout(() => {
        this.allocateWorkErrors = this.allocateWorkErrors.filter(x => x.screenId !== this.defaultAllocationScreenId);
      }, 1);
    }
    return valid;
  }

  private handleMinutesValidationMessage(allocatedMinutes: number, allotedMinutes: number, screenId: number, periodDate: string = null): void {
    let errorMsg;
    if (allocatedMinutes > allotedMinutes) {
      errorMsg = 'There are too many minutes allocated ';
    } else if (allocatedMinutes < allotedMinutes) {
      errorMsg = 'There are too few minutes allocated ';
    }

    errorMsg = screenId === this.defaultAllocationScreenId ? `${errorMsg} in the customer default allocation` : `${errorMsg} for order date ${periodDate}`;

    toastr.error(errorMsg);
    this.addScreenError(screenId, errorMsg);
  }

  private itemsToDelete(): Boo.Objects.Work.SeoMinute.WorkRequestDetail[] {
    const groupItems: Boo.Objects.Work.SeoMinute.WorkRequestDetail[] = _.flatten(this.taskTypeGroupings.map(x => x.workItems));
    const existingItems = this.seoWorkSettings.WorkRequestDetails.filter(x => x.WorkRequest.WorkRequestId > 0);

    const itemsToDelete = existingItems.filter(x => !groupItems.some(y => y.WorkRequest.WorkRequestId === x.WorkRequest.WorkRequestId));
    return itemsToDelete;
  }

  private addLocalBusinessCitationSubscriptionToPeriod(period: Period): void {
    const taskType = this.taskTypes.find(x => x.TaskTypeId === Boo.Objects.Enums.TaskTypeEnum.LocalBusinessCitationSubscription);
    // add group
    if (!this.taskTypeGroupings.some(y => y.taskTypeName === taskType.Name && y.period === period.index)) {
      this.taskTypeGroupings.push({
        taskTypeId: taskType.TaskTypeId,
        taskTypeName: taskType.Name,
        taskTypeDescription: taskType.Description,
        workItems: [],
        isManual: false,
        qty: 0,
        period: period.index
      });
    }

    // add work item
    const subsToAdd = this.defaults
      .find(x => x.TaskTypeId === Boo.Objects.Enums.TaskTypeEnum.LocalBusinessCitationSubscription)
      .TaskCount;
    const group = this.taskTypeGroupings.find(y => y.taskTypeName === taskType.Name && y.period === period.index);

    for (let index = 0; index < subsToAdd; index++) {
      group.workItems.push(this.workRequestDetailFactory(taskType, period));
      group.qty = group.workItems.length;
    }

    toastr.warning('Local business citation subscriptions were automatically added because this customer has local profiles that are currently subscribed');
  }

  private implementDefaults(period: Period): void {
    let reservedMinutesNodeForPeriod = this.allReservedMinutes.findByPeriod(period.index);
    if (!reservedMinutesNodeForPeriod)
    {
      this.allReservedMinutes.add({
        SeoMinuteReservationId: 0,
        CustomerId: this.customer().CustomerId(),
        Period: period.index,
        Quantity: this.seoWorkSettings.ReservedSeoMinutesDefault,
        UsedDate: null,
        InsertedByUserId: this.user().UserId,
        InsertedDate: new Date()
      });
    } else {
      reservedMinutesNodeForPeriod.reservedMinutes.Quantity = this.seoWorkSettings.ReservedSeoMinutesDefault;
    }

    if (!this.validateDefaults()) {
      toastr.error('Could not implement defaults due to incorrect minute allocations.');
      return;
    }

    this.seoWorkSettings.Defaults.forEach(x => {
      // add group
      if (!this.taskTypeGroupings.some(y => y.taskTypeName === x.TaskType.Name && y.period === period.index)) {
        this.taskTypeGroupings.push({
          taskTypeId: x.TaskTypeId,
          taskTypeName: x.TaskType.Name,
          taskTypeDescription: x.TaskType.Description,
          workItems: [],
          isManual: false,
          qty: 0,
          period: period.index
        });
      }

      // add work item
      const group = this.taskTypeGroupings.find(y => y.taskTypeName === x.TaskType.Name && y.period === period.index);
      const task = this.taskTypes.find(y => y.TaskTypeId === x.TaskTypeId);
      for (let index = 0; index < x.TaskCount; index++) {
        var item = this.workRequestDetailFactory(task, period);
        this.createWorkRequestKeyValuePairs(item.WorkRequest);
        group.workItems.push(item);
        group.qty = group.workItems.length;
      }
    });
  }

  private createWorkRequestKeyValuePairs(workRequest: Boo.Objects.Work.WorkRequest): void {
    // onsiteblogging: TODO Remove when seperate blogging implementation tasks are depreciated
    if (onsiteBloggingOrderingWithImplementationTaskTypes.includes(workRequest.WorkTypeCreationId)) {
      WorkRequestHelper.setIsBoostImplementing(workRequest, true, (this.partnerUser() as any).UserId);
    }
    else if (onsiteBloggingOrderingContentOnlyTaskTypes.includes(workRequest.WorkTypeCreationId)) {
      WorkRequestHelper.setIsBoostImplementing(workRequest, false, (this.partnerUser() as any).UserId);
    }
  }

  private addScreenError(screenId: number, errorMsg: string): void {
    const existingErrors = this.allocateWorkErrors.find(x => x.screenId === screenId);
    if (existingErrors && existingErrors.errorMessages.length) {
      existingErrors.errorMessages.push(errorMsg);
      return;
    }

    this.allocateWorkErrors.push({ screenId: screenId, errorMessages: [errorMsg] });
  }

  private setUpValidationFlags(): void {
    this.clearValidationFlags();
    if (this.activeTab !== this.pastPeriodsScreenId) {
      this.createValidationFlags();
    }
  }

  private createValidationFlags(): void {
    this.createUrlCannotBeDuplicatedValidationFlags(this.seoWorkSettings.TaskTypes.filter(x => x.ValidationConfig?.UrlCannotBeDuplicatedRules.length));
  }

  private createUrlCannotBeDuplicatedValidationFlags(validatedTaskTypes: Boo.Objects.Work.SeoMinute.SeoWorkTaskType[]): void {
    let urlTaskTypes = [...new Set(validatedTaskTypes.flatMap(x => x.ValidationConfig.UrlCannotBeDuplicatedRules.flatMap(y => y.TaskTypeEnums)))];

    let taskTypeOrderedUrls = urlTaskTypes.map(taskTypeId => {
      let orderedUrls = [...new Set(
        this.taskTypeGroupings
          .filter(x => x.taskTypeId === taskTypeId)
          .flatMap(x => x.workItems)
          .filter(x => this.statusTaskIsOpen(x))
          .map(x => x.WorkRequestWebsiteUrl)
          .concat(this.seoWorkSettings.PastWorkRequestDetails.filter(x => x.WorkRequest.WorkTypeCreationId === taskTypeId).map(x => x.WorkRequestWebsiteUrl)))];

      return { TaskTypeId: taskTypeId, OrderedUrls: orderedUrls.filter(x => x != undefined) };
    });
    
    validatedTaskTypes.forEach(validatedTaskType => {
      validatedTaskType.ValidationConfig.UrlCannotBeDuplicatedRules.forEach(urlCannotBeDuplicated => {
        let restrictedUrls = [...new Set(taskTypeOrderedUrls.filter(x => urlCannotBeDuplicated.TaskTypeEnums.includes(x.TaskTypeId)).flatMap(x => x.OrderedUrls))];
        if (restrictedUrls.length) {
          validatedTaskType.Flags.push({ Source: FlagSources.Validation, Type: FlagTypes.RestrictedUrls, Data: restrictedUrls, Message: urlCannotBeDuplicated.Message } as Boo.Objects.Work.SeoMinute.TaskTypeFlag);
        }
      });
    });
  }

  private statusTaskIsOpen(x: Boo.Objects.Work.SeoMinute.WorkRequestDetail): unknown {
    return [0 /* new */, Boo.Objects.Enums.TaskStatusEnum.Pending, Boo.Objects.Enums.TaskStatusEnum.Locked, Boo.Objects.Enums.TaskStatusEnum.NeedsData].includes(x.StatusTaskStatusId);
  }

  private clearValidationFlags(): void {
    this.seoWorkSettings.TaskTypes.forEach(x => {
      x.Flags = x.Flags.filter(y => y.Source != FlagSources.Validation);
    });
  }

  private firstAvailablePeriod(): number {
    if (!this.activeBillingPeriods.length) {
      return this.defaultAllocationScreenId;
    }

    return this.activeBillingPeriods[0].index;
  }

  private getStartDateUtc(): string {
    let startDate;
    const today = moment().utc().format();
    const nextBillingDate = this.customer().NextBillingDate() ? moment(this.customer().NextBillingDate()).utc().format() : null;
    const campaignStartDate = this.customer().CampaignStartDate() ? moment(this.customer().CampaignStartDate()).utc().format() : null;

    if (!nextBillingDate && campaignStartDate >= today) {
      startDate = campaignStartDate;
    } else if (!nextBillingDate && campaignStartDate < today) {
      startDate = today;
    } else if (nextBillingDate < today) {
      startDate = moment().utc().set('date', moment(nextBillingDate).utc().date()).format();
    } else {
      startDate = moment(nextBillingDate).utc().add(-1, 'month').format();
    }

    return startDate;
  }

  private workRequestDetailFactory(taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType, period: Period): Boo.Objects.Work.SeoMinute.WorkRequestDetail {
    return <Boo.Objects.Work.SeoMinute.WorkRequestDetail>({
      WorkDisplayName: taskType.Name,
      WorkDescription: taskType.Description,
      WorkRequest: {
        CustomerId: this.customer().CustomerId(),
        Sku: 'SEO-MINUTE',
        Period: period.index,
        WorkTypeId: Fulfillment.Domain.WorkOrders.WorkTypes.Task,
        WorkTypeCreationId: taskType.TaskTypeId,
        WorkQuantity: taskType.Minutes,
        InsertedByUserId: this.partnerUser().UserId,
        WorkOrderItemId: null,
        InsertedDate: null,
        WorkRequestId: 0,
        WorkRequestFiles: [],
        KeyValuePairs: []
      },
      CanDelete: true,
      CanEdit: false,
      WorkOrderItemWorkId: 0,
      WorkOrderItemWorkTypeId: 0,
      WorkOrderItemStatusId: 0,
      OrderTaskId: 0,
      StatusTaskId: 0,
      StatusTaskStatusId: 0,
      WorkStatusDisplayName: 'New'
    });
  }
}

export interface Period {
  index: number;
  displayDate: string;
  active: boolean;
}

export interface TaskTypeWorkGroup {
  taskTypeId: number;
  taskTypeName: string;
  taskTypeDescription: string;
  workItems: Boo.Objects.Work.SeoMinute.WorkRequestDetail[];
  isManual: boolean;
  qty: number;
  period: number;
}

export interface ScreenErrors {
  screenId: number;
  errorMessages: string[];
}