<div>
  <ng-container *ngIf="!comments.length">
    <div class="alert alert-info">
      <strong>No Comments</strong>
      Comments are created when tasks are rejected.
    </div>
  </ng-container>
  <ul class="list-group">
    <ng-container *ngFor="let $data of comments">
      <li class="list-group-item">
        <app-components-specialist-actioncomponents-comment [comment]="$data" [displayAsHtml]="displayAsHtml">
        </app-components-specialist-actioncomponents-comment>
        <div>
          <span class="badge bg-secondary">{{ $data.TaskTypeName }}</span>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
