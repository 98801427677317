<div class="card">
  <div class="card-header">
    Manage Lead Sources
    <button (click)="addLeadSource()" class="btn btn-primary float-end">
      <i class="fa fa-plus"></i>
      New Lead Source
    </button>
  </div>
  <div [loadingOverlay]="isLoading()" class="card-body">
    <div class="row">
      <div class="col-md-3 form-group">
        <label>Filter by Name</label>
        <div class="input-group">
          <span class="input-group-text">
            <i class="fa fa-filter"></i>
          </span>
          <input [ngModel]="filter()" (ngModelChange)="filter($event)" [ngModelOptions]="{ standalone: true }"
            type="text" class="form-control" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-table [items]="filteredLeadSources()" [exportFilename]="'lead-sources'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="Name()">Name</th>
              <th export sort field="CategoryName()">Category</th>
              <th>Lead Source Rev. Share</th>
              <th export sort field="RevsharePercent()">Boostability Rev. Share</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-leadSource>
            <tr [class.alert-warning]="leadSource.IsDirty()">
              <td>{{leadSource.Name()}}</td>
              <td>{{leadSource.CategoryName()}}</td>
              <td>{{1 - leadSource.RevsharePercent()| percent}}</td>
              <td>{{leadSource.RevsharePercent()| percent}}</td>
              <td>
                <button [disabled]="!(!leadSource.Editing())" (click)="editLeadSource(leadSource)"
                  class="btn btn-primary btn-sm">
                  <i class="fa fa-edit"></i>
                  Edit
                </button>
              </td>
            </tr>
            <tr [hidden]="!(leadSource.Editing())">
              <td colspan="6">
                <compose Selector="app-components-manager-leadsource" [ActivateOptions]="{
                                          leadSource: leadSource,
                                          deleteLeadSource: deleteLeadSource.bind(this)
                                       }"></compose>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>