<div class="card" style="margin-bottom: 0px;">
  <div class="card-header">
    Help Text Editor
  </div>
  <div class="card-body">
    <div class="form-group" *ngIf="keyFilterInputVisible">
      <label class="control-label">Search</label>
      <input class="form-control" type="text" [(ngModel)]="keyFilter" />
    </div>
    <div class="form-group">
      <label class="control-label">Key</label>
      <select class="form-select" [(ngModel)]="selectedHelpText" (ngModelChange)="resetInputs()">
        <option *ngFor="let helpText of filteredHelpTexts" [ngValue]="helpText">{{ helpText.Key }}</option>
      </select>
    </div>
    <div *ngIf="selectedHelpText">
      <div class="form-group">
        <label class="control-label">Title</label>
        <input class="form-control" type="text" [(ngModel)]="title" />
      </div>
      <div class="form-group">
        <label class="control-label">Content</label>
        <textarea class="form-control" type="text" [(ngModel)]="content" rows="4" cols="50"></textarea>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="text-end">
      <button [disabled]="!selectedHelpText" type="button" class="btn btn-success" (click)="save()">Save</button>
      <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>
