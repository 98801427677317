import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { finalize } from 'rxjs/operators';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { NotificationDefinition } from '../../../shared/models/notifications/notifications';
import { PartnerService } from '../../../services/partner.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-components-manager-partner-emails',
  templateUrl: './partner-emails.component.html'
})
export class PartnerEmailsComponent implements OnInit {
  @Input() partner: Boo.Objects.PartnerMinimal;
  notificationDefinitions: NotificationDefinition[] = [];
  dfd: any;
  isLoading: boolean = false;
  arePartnerEmailsDisabled: boolean = false;
  areSeoProgressReportEmailsDisabled: boolean = false;

  constructor(
    private notificationService: NotificationService,
    private partnerService: PartnerService) { }

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin([
      this.notificationService.getPartnerDefinitions(this.partner.PartnerId),
      this.partnerService.get(this.partner.PartnerId)
    ])
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        ([notificationDefinitions, partner]) => {
          this.notificationDefinitions = notificationDefinitions;
          this.arePartnerEmailsDisabled = partner.IsUserAndContactRestricted;
          this.areSeoProgressReportEmailsDisabled = !partner.SendSeoProgressEmails;
        },
        err => toastr.error(err)
      );
  }

  close(): void {
    if (this.dfd) {
      this.dfd.resolve();
    }
  }

  save(): void {
    this.isLoading = true;
    this.notificationService.updatePartnerExclusions(this.partner.PartnerId, this.notificationDefinitions)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        () => {
          toastr.success('Notifications updated successfully');
          this.close();
        },
        err => toastr.error(err)
      );
  
  }

  openTemplates(definition: NotificationDefinition): void {
    PriorityViewModel.ngShow('app-notification-definition', { definition: definition, partnerId: this.partner.PartnerId });
  }

}
