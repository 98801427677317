<div>
  <div>
    <app-components-togglepanel title="Custom Actions Report" toggleBody="true">
      <div style="min-height: 300px;">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Start Date</label>
              <div>
                <input [dateTimePicker]="{ date: startDate }" type="text" class="form-control"
                  placeholder="Start Date" />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>End Date</label>
              <div>
                <input [dateTimePicker]="{ date: endDate }" type="text" class="form-control" placeholder="End Date" />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>&nbsp;</label>
              <div>
                <button (click)="loadCompletedCustomActions(this)" class="btn btn-success">Search</button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <app-table [items]="completedCustomActions" [exportFilename]="'completed-custom-actions'">
              <ng-template #tableHeader>
                <tr>
                  <th export sort field="TaskActionUrl">Action URL</th>
                  <th export sort field="CustomTaskTypeName">Task Type</th>
                  <th export sort field="TaskType.Name">Task Name</th>
                  <th export sort field="StatusDate">Task Completed Date</th>
                  <th export sort field="BilledMinutes">Billed Minutes</th>
                  <th export sort field="Keyword">Target Keyword</th>
                  <th export sort field="Area">Target Area</th>
                </tr>
              </ng-template>
              <ng-template #tableBody let-customAction>
                <tr>
                  <td>
                    <p class="linkEllipsis">
                      <a [externalLink]="{ url: customAction.TaskActionUrl, text: customAction.TaskActionUrl }"
                        rel="noreferrer"></a>
                    </p>
                  </td>
                  <td>{{customAction.CustomTaskTypeName}}</td>
                  <td>{{customAction.TaskTypeSummary.Name}}</td>
                  <td>
                    <ng-container *ngIf="customAction.TaskStatusEnum === launchpad.config.TaskStatusEnum.complete">
                      <span>{{customAction.StatusDate | dateText: 'MM/DD/YYYY h:mm:ss a' }}</span>
                    </ng-container>
                    <ng-container *ngIf="customAction.TaskStatusEnum !== launchpad.config.TaskStatusEnum.complete">Not Completed</ng-container>
                  </td>
                  <td>{{customAction.BilledMinutes}}</td>
                  <td>{{customAction.Keyword}}</td>
                  <td>{{customAction.Area}}</td>
                </tr>
              </ng-template>
            </app-table>
          </div>
        </div>
      </div>
    </app-components-togglepanel>
  </div>
</div>