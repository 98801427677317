import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "searchVolumeDisplay"
})
export class SearchVolumeDisplay implements PipeTransform {
  private readonly lowVolumeThreshold: number = 500;
  private readonly midVolumeThreshold: number = 10000;
  private readonly defaultVolume: number = 101;

  transform(volume: number): string {
    if (volume === undefined || volume === this.defaultVolume) {
      return 'Unchecked';
    }

    if (volume < this.lowVolumeThreshold) {
      return 'Low';
    }

    if (volume < this.midVolumeThreshold) {
      return 'Medium'
    }

    return 'High'
  }
}