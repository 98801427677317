import { Component, Input, OnInit} from '@angular/core';
import { wobbleIf } from '../../../shared/animations';

@Component({
  selector: 'app-shared-components-timezonedatetime',
  templateUrl: './time-zone-date-time.component.html',
  styleUrls: ['./time-zone-date-time.component.scss'],
  animations: [wobbleIf]
})
export class TimeZoneDateTimeComponent implements OnInit {
  @Input() public timeZone: string;
  @Input() public format?: string;

  uniqueId: string;
  outputText: string;
  isValid: boolean;
  isTimeZoneSet: boolean;
  isBusinessHours: boolean = false;
  defaultFormat: string = 'LLL';
  businessHoursStart: number = 8;
  businessHoursEnd: number = 18;

  constructor() { }

  ngOnInit() {
    this.uniqueId = _.uniqueId('tz_datetime');
    this.format = !!this.format ? this.format : this.defaultFormat;
    this.isTimeZoneSet = !!this.timeZone;

    if (this.isTimeZoneSet) {
       let current = moment().tz(this.timeZone);
       this.isBusinessHours = current.hour() >= this.businessHoursStart && current.hour() < this.businessHoursEnd;
       this.outputText = current.format(this.format);
    }
    else {
       this.outputText = 'Time Zone not set';
    }
    this.isValid = this.isTimeZoneSet && this.isBusinessHours;
  }
}
