import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-components-shared-keywords-halo-keyword-suggestions-readonly',
  templateUrl: './halo-keyword-suggestions-readonly.component.html'
})
export class HaloKeywordSuggestionsReadonlyComponent {
  @Input() haloKeywords: Boo.Objects.QaKeywordSuggestion[];

  getKeywordDisplayText(keyword: Boo.Objects.QaKeywordSuggestion): string {
    if (keyword.Area) {
      return keyword.IsAreaFirst ? keyword.Area + ' ' + keyword.Keyword : keyword.Keyword + ' ' + keyword.Area;
    } else {
      return keyword.Keyword;
    }
  }
}
