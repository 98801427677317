//NOTE: [ngModelOptions]="{ updateOn: 'blur'|'submit'|'change' }. Only change (default) is supported.
import { Directive, Input, ElementRef, AfterViewInit, OnChanges, OnDestroy, forwardRef, Inject, Optional } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, SelectMultipleControlValueAccessor, SelectControlValueAccessor } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Select2Directive),
  multi: true
};

@Directive({
  selector: '[select2]',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class Select2Directive implements AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input('select2') public Settings: any;

  private $element: JQuery;
  private controlValueAccessor: ControlValueAccessor;
  constructor(elementRef: ElementRef, @Optional() @Inject(SelectControlValueAccessor) selectControlValueAccessor: SelectControlValueAccessor, @Optional() @Inject(SelectMultipleControlValueAccessor) selectMultipleControlValueAccessor: SelectMultipleControlValueAccessor) {
    this.controlValueAccessor = selectControlValueAccessor || selectMultipleControlValueAccessor;
    this.$element = $(elementRef.nativeElement);
  }

  writeValue(newValue: any): void {
    this.controlValueAccessor.writeValue(newValue);
    this.$element.trigger('change');
  }
  private onChangeCallback: (_: any) => void = () => { };
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
    this.$element.prop('disabled', isDisabled);
  }

  private getValue(): any|any[] {
    if (this.controlValueAccessor instanceof SelectMultipleControlValueAccessor) {
      let selectedOptions = this.$element.select2('data');
      if (!Array.isArray(selectedOptions)) {
        return [];
      }

      return selectedOptions.map((x: any) => (this.controlValueAccessor as any)._getOptionValue(x.element[0].value));
    }

    let value = this.$element.val();
    if (!value) {
      return value;
    }

    return (this.controlValueAccessor as any)._getOptionValue(value);
  }

  public ngAfterViewInit(): void {
    let settings = this.$element.is(':disabled') ? { disabled: true } : {};
    _.extend(settings, this.Settings || {});
    this.$element.select2(settings);
    this.$element.on('change', () => {
      this.onChangeCallback(this.getValue());
    });
  }

  public ngOnChanges(): void {
    this.$element.trigger('change');
  }

  public ngOnDestroy(): void {
    this.$element.select2('destroy');
  }
}