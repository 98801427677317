<div class="modal-content">
  <div class="modal-header">
    <h4 id="skip-customer-label" class="modal-title">Skip Customer Task</h4>
    <button type="button" class="btn-close" title="Close" (click)="cancel()"></button>
  </div>
  <div [loadingOverlay]="isLoading" class="modal-body">
    <div *ngIf="customerIsTopWorkPriority" class="text-danger">
      This customer is set to Top Work Priority. Please contact your manager.
    </div>
    <div class="form-group">
      <label class="control-label" for="description">Description (required)</label>
      <textarea [formControl]="reason" required maxlength="4000" lpValidationMsg class="form-control" id="description" name="description"></textarea>
    </div>
    <ng-container *ngIf="taskTypeSummary.IsSkippable">
      <div class="form-group">
        <label class="control-label">
          Skip all
          <span>{{ taskTypeSummary.Name }}</span>
          tasks for this customer?
        </label>
        <div>
          <shared-components-customchoice [(value)]="skipThisTaskType"></shared-components-customchoice>
        </div>
      </div>
    </ng-container>
    <strong>Note: skipping task(s) is in effect for 24 hrs. for this or all tasks</strong>
  </div>
  <div class="modal-footer">
    <button (click)="cancel()" class="btn btn-link">Cancel</button>
    <button [disabled]="isLoading" (click)="save()" class="btn btn-success">
      <i class="fa fa-save"></i>
      Save
    </button>
  </div>
</div>
