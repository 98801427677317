<div class="modal-content" [loadingOverlay]="isLoading">
  <div class="modal-header">
    <h4 id="skip-customer-label" class="modal-title">Reason</h4>
    <button type="button" class="btn-close" title="Close" (click)="cancel()"></button>
  </div>
  <div class="modal-body" [(formGroup)]="rejectionCtrl">
    <div class="mb-2" *ngIf="rejectableByRejectionType">
      <ng-select formControlName="rejectionTypes" lpValidationMsg [multiple]="true" [closeOnSelect]="false" [items]="taskRejectionTypes" bindLabel="Name" bindValue="RejectionReasonTypeId"></ng-select>
    </div>
    <div class="form-group">
      <textarea formControlName="rejectionReason" lpValidationMsg class="form-control"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-link" (click)="cancel()" aria-hidden="true">Cancel</button>
    <button [disabled]="isLoading" (click)="save()" class="btn btn-danger">
      <i class="fa fa-times"></i>
      Reject
    </button>
  </div>
</div>