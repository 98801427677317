import Counter from './counter';
import LinkCustomerUrl from './linkCustomerUrl';

export class QuillConfig {
  static GetDefaults(): any {
    return {
      modules: {
        counter: {
          container: '#wordcount',
          unit: 'word'
        },
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],
    
          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction
    
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          // [{ 'font': [] }],
          [{ 'align': [] }],
    
          ['clean'],                                         // remove formatting button
    
          ['link']                         // link and image, video
        ]
      },
      customModules: [{
        implementation: Counter,
        path: 'modules/counter'
      },
      {
        implementation: LinkCustomerUrl,
        path: 'modules/linkCustomerUrl'
      }]
    }
  }
}
