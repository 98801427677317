import { Component } from '@angular/core';
import System from 'framework/System';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';

@Component({
	selector: 'app-components-manager-managereporting',
	templateUrl: './ManageReporting.component.html'
})
export class ManageReportingComponent {
    public isLoading: KnockoutObservable<boolean>;
    public title: string;
    public partner: Boo.Objects.PartnerMinimal;
    public tabPanelDisplayMode = TabPanelDisplayMode;
    protected priorityViewApi: app.interfaces.IPriorityViewPublicApi;

    public activate(params: IManageReportingViewModelActivateParams): JQueryPromise<void> {
        this.partner = params.partner;
        this.title = `Manage Reporting Site - ${this.partner.PartnerName}`;
        this.priorityViewApi = params.priorityViewApi;

        return System.emptyPromise();
    }

    public close(): void {
        this.priorityViewApi.resolve();
    }
}

interface IManageReportingViewModelActivateParams {
    partner: Boo.Objects.PartnerMinimal;
    priorityViewApi: app.interfaces.IPriorityViewPublicApi;
}
