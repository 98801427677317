import { Component, Input, Inject, Optional, EventEmitter, Output } from '@angular/core';
import { HelpTextService } from '../../../services/help-text.service';

@Component({
    selector: 'app-components-helptexteditor',
    templateUrl: './HelpTextEditor.component.html'
})
export class HelpTextEditorComponent {
    private _keyFilter: string;
    @Input() public get keyFilter(): string {
        return this._keyFilter;
    }
    public set keyFilter(value: string) {
        this._keyFilter = value;

        this.filteredHelpTexts = this.helpTextService.allHelpTexts;
        if (this.keyFilter) {
            this.filteredHelpTexts = this.filteredHelpTexts.filter(x => x.Key.toLowerCase().indexOf(this.keyFilter.toLowerCase()) >= 0);
        }

        if (this.filteredHelpTexts.length === 1) {
            this.selectedHelpText = this.filteredHelpTexts[0];
            this.resetInputs();
        }
    }

    @Output() public saved: EventEmitter<any> = new EventEmitter();
    @Output() public canceled: EventEmitter<any> = new EventEmitter();
    @Input() public keyFilterInputVisible: boolean = true;
    public title: string;
    public content: string;
    public selectedHelpText: Boo.Objects.HelpText;
    public filteredHelpTexts: Boo.Objects.HelpText[];

    constructor(private helpTextService: HelpTextService) {
        this.keyFilter = '';
    }

    public resetInputs(): void {
        this.title = this.selectedHelpText.Title;
        this.content = this.selectedHelpText.Content;
    }

    public save(): void {
        this.selectedHelpText.Title = this.title;
        this.selectedHelpText.Content = this.content;
        this.helpTextService.save(this.selectedHelpText).subscribe(_ => this.saved.emit());
    }

    public cancel(): void {
      this.resetInputs();
      this.canceled.emit();
    }
}