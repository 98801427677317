import { Component } from '@angular/core';
import System from 'framework/System';

@Component({
  selector: 'app-components-guarantees-moneybackguarantee',
  templateUrl: './MoneyBackGuarantee.component.html'
})
export class MoneyBackGuaranteeComponent implements IActivatable {
    public customerId: number;
    public isCreateMode: KnockoutObservable<boolean>;
    public api: app.components.guarantees.interfaces.IGuaranteeComponentPublicApi;
    public guarantee: Boo.Objects.Guarantee;

    public activate(params: app.components.guarantees.interfaces.IMoneyBackGuaranteeViewModelParams): JQueryPromise<any> {
        this.customerId = ko.mapping.toJS(params.customerId);
        this.isCreateMode = ko.observable(false);
        
        this.guarantee = null;

        this.api = {
            add: this.add.bind(this),
            end: this.end.bind(this)
        };

        return System.emptyPromise();
    }

    public add(guarantee: Boo.Objects.Guarantee): void {
        this.guarantee = guarantee;
        this.isCreateMode(true);
    }

    public end(): void {
        this.guarantee = null;
        this.isCreateMode(false);
    }
}
