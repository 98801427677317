<div class="card">
  <div class="card-header">
    <h6>Keyword Ideation Tool</h6>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
      <span style="margin-right: 10px;">Campaign Type</span>
        <shared-components-customchoice
        [value]="this.isLocalCampaign.value"
        [disable]="campaignTypeReadOnly"
        [choiceTrue]="'Local'"
        [choiceFalse]="'National'"
        (valueChange)="this.updateCampaignType($event)">
        </shared-components-customchoice>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Add Keywords</label>
              <textarea class="form-control" [formControl]="this.keywordsControl" placeholder="Keywords..."></textarea>
              <label>Count: {{ keywordsCount }}</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Add Locations</label>
              <textarea class="form-control" [formControl]="this.locationsControl" placeholder="Locations..."></textarea>
              <label>Count: {{ locationsCount }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-start text-center align-items-center">
          <div class="col-md-6">
            <div class="form-group">
              <label>Pages/Keywords <app-components-helptext [key]="'KeywordIdeationTool_PagesKeywords'"></app-components-helptext></label>
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Target Pages</th>
                    <th>Target Keywords</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{this.minimumPages}}</td>
                    <td>{{this.minimumMainKeywords}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Keyword Summary</label>
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Halo</th>
                    <th>Total Keywords</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{this.minimumHaloKeywordSuggestions}}</td>
                    <td>{{this.keywordTotal}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <form [formGroup]="generateKeywordsFormGroup" (ngSubmit)="generateKeywords()" style="display:inline-block">
              <button type="submit" class="btn btn-success">Generate & Check Rankings</button>
            </form>
            <button type="button" class="btn btn-danger" style="display: inline-block" (click)="clearInput()">Clear Input</button>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-md-6">
        <label>Keyword Rankings</label>
        <app-table [items]="generatedKeywords" [disablePaging]="true">
          <ng-template #tableHeader>
            <tr>
              <th sort field="rankingTerm">Keyword</th>
              <th sort field="keywordSuggestion.Rank">Rank</th>
              <th style="text-align: center;">
                <input [(ngModel)]="allKeywordToggle" type="checkbox" (click)="toggleAll()"/>
              </th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-keyword>
            <tr (click)="keyword.keywordSuggestion.Rank && toggleSelection(keyword)" [ngClass]="!keyword.keywordSuggestion.Rank ? 'table-active' : null">
              <ng-container *ngIf="!keyword.hidden">
                <td>{{keyword.rankingTerm}}</td>
                <td>{{keyword.keywordSuggestion.Rank ? keyword.keywordSuggestion.Rank : 'Loading...'}}</td>
                <td style="text-align: center; width: 10%">
                  <input [(ngModel)]="keyword.selected" type="checkbox" [disabled]="!keyword.keywordSuggestion.Rank"/>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </app-table>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <label>Target Page</label>
            <input type="text" class='form-control' [formControl]="targetUrlControl" [formValidation]="targetUrlControl" lpValidationMsg/>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <form [formGroup]="addToPageFormGroup" (ngSubmit)="assignKeywordsToPage()" style="display: inline-block">
              <button class="btn btn-success btn-md"  type="submit">Add Selected To Page</button>
            </form>
            <button class="btn btn-success btn-md" style="display: inline-block" (click)="assignKeywordsToHalo()">Add To Halo Terms</button>
            <button class="btn btn-danger btn-md" style="display: inline-block" (click)="clearSelected()">Clear Selected Rankings</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

