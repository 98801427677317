import TicketTypes = Boo.Objects.Enums.TicketTypeEnum;
import ComponentConfig from 'app/ticket/config/ComponentConfig';
import FileSections = Boo.Objects.Enums.FileSectionTypesEnum;

/**
 * Class to configure components per ticket type
 */
export default class TicketTypeComponents {
    /**
     * Returns a list of components for the ticket and specified parameters,
     * or the default set of components if a specific listing is not available.
     */
    public static get(ticketType: Boo.Objects.TicketType, partnerId?: number): app.ticket.interfaces.IComponentConfig[] {
        // Do not add to this pattern. If we need additional functionality, we should probably rework the configuration
        // to support specific defaults and optional overrides. We probably want overrides that support component addition,
        // removal, and changes. Currently this is all quite messy and spreads factory/config logic between this
        // factory and the TicketTypeComponents configuration.

        let componentConfig: app.ticket.interfaces.IComponentConfig[] =
            TicketTypeComponents.getByPartnerAndTicketType(partnerId, ticketType)
            || TicketTypeComponents.getByTicketType(ticketType)
            || TicketTypeComponents.getDefaults(ticketType);

        return componentConfig;
    }

    private static getDefaults(ticketType: Boo.Objects.TicketType): app.ticket.interfaces.IComponentConfig[] {
        let components = [
            new ComponentConfig('BasicInfo', <app.ticket.interfaces.IBasicInfoComponentFeatureConfig>{ isChangingDatesAllowed: ticketType.IsChangingDatesAllowed ? true : false }),
            new ComponentConfig('Descendants', <app.ticket.interfaces.IDescendantsComponentFeatureConfig>{ isApprovalRequired: ticketType.IsApprovalRequired ? true : false }),
            new ComponentConfig('ContactAttempts'),
            new ComponentConfig('Review'),
            new ComponentConfig('ConnectedUsersFloatingPanel'),
            new ComponentConfig('NoteFloatingPanel'),
            new ComponentConfig('CustomerTodoListFloatingPanel'),
            new ComponentConfig('Files', <app.ticket.interfaces.IFilesComponentFeatureConfig>{
                availableFileSections: [
                    FileSections.General
                ],
                defaultFileSection: FileSections.General,
                canDelete: false,
                canUpload: ticketType.HasFileUploadCapability ? true : false,
                isFileUploadRequired: ticketType.IsFileUploadRequired
            })
        ];

        if (ticketType.IsApprovalRequired) {
            components.splice(1, 0, new ComponentConfig('Features', <app.ticket.interfaces.IFeaturesComponentFeatureConfig>{
                isApprovalRequired: ticketType.IsApprovalRequired ? true : false,
                hasFileUploadCapability: ticketType.HasFileUploadCapability ? true : false,
                isFileUploadRequired: ticketType.IsFileUploadRequired ? true : false,
                approvalQuestionText: ticketType.ApprovalQuestionText || ''
            }));
        }

        return components;
    }

    /**
     * Returns a list of components by ticket type id.
     * Set up components that apply to all partners here.
     */
    private static getByTicketType(ticketType: Boo.Objects.TicketType): app.ticket.interfaces.IComponentConfig[] {
        let defaults = TicketTypeComponents.getDefaults(ticketType);

        let components: app.ticket.interfaces.IComponentConfig[] = null;
        switch (ticketType.TicketTypeId) {
            case TicketTypes.BoostListingsOnboarding:
                components = this.extendDefaults(ticketType, [
                    new ComponentConfig('ContactAttempts'),
                    new ComponentConfig('LockConfirmation', <app.ticket.interfaces.ILockConfirmationComponentFeatureConfig>{ lockOnAppointment: true })
                ]);
                break;
            case TicketTypes.CreateAndInstallSitemap:
                components = this.extendDefaults(ticketType, [
                    new ComponentConfig('BasicInfo', <app.ticket.interfaces.IBasicInfoComponentFeatureConfig>{ isChangingDatesAllowed: ticketType.IsChangingDatesAllowed ? true : false })
                ]);
                break;
            case TicketTypes.SeoOnboarding:
                components = [
                    new ComponentConfig('LockConfirmation', <app.ticket.interfaces.ILockConfirmationComponentFeatureConfig>{ lockOnAppointment: true }),
                    new ComponentConfig('BasicInfo', <app.ticket.interfaces.IBasicInfoComponentFeatureConfig>{ isChangingDatesAllowed: ticketType.IsChangingDatesAllowed ? true : false, requireAppointmentToChangeAssignedUser: true }),
                    new ComponentConfig('Review'),
                    new ComponentConfig('ContactAttempts'),
                    new ComponentConfig('ConnectedUsersFloatingPanel'),
                    new ComponentConfig('CustomerTodoListFloatingPanel'),
                    new ComponentConfig('PreAuditReport'),
                    new ComponentConfig('KeywordResearch'),
                    new ComponentConfig('SeoContentSurvey'),
                    new ComponentConfig('OnsiteRecommendations'),
                    new ComponentConfig('LinkBuilding'),
                    new ComponentConfig('Files', <app.ticket.interfaces.IFilesComponentFeatureConfig>{
                        availableFileSections: [FileSections.General, FileSections.KeywordResearch],
                        defaultFileSection: FileSections.KeywordResearch,
                        canDelete: false,
                        canUpload: ticketType.HasFileUploadCapability ? true : false,
                        isFileUploadRequired: ticketType.IsFileUploadRequired,
                        filterByTicket: false
                    }),
                    new ComponentConfig('Descendants', <app.ticket.interfaces.IDescendantsComponentFeatureConfig>{ isApprovalRequired: ticketType.IsApprovalRequired ? true : false }),
                    new ComponentConfig('TicketNavigationFloatingPanel', <app.ticket.interfaces.ITicketNavigationComponentFeatureConfig>{
                        navigationLinks: [
                            { id: 'BasicInfo', text: 'Basic Information' },
                            { id: 'Review', text: 'Review' },
                            { id: 'ContactAttempts', text: 'Contact Attempts' },
                            { id: 'LocalProfiles', text: 'Local Profiles' },
                            { id: 'PreAuditReport', text: 'Pre-Audit Report' },
                            { id: 'KeywordResearch', text: 'Keyword Research' },
                            { id: 'SeoContentSurvey', text: 'SEO Content Survey' },
                            { id: 'OnsiteRecommendations', text: 'Onsite Recommendations' },
                            { id: 'LinkBuilding', text: 'Link Building' },
                            { id: 'Files', text: 'Files' }
                        ]
                    })
                ];
                break;
            case TicketTypes.SeoDiscovery:
                components = [
                    new ComponentConfig('LockConfirmation', <app.ticket.interfaces.ILockConfirmationComponentFeatureConfig>{ lockOnAppointment: true }),
                    new ComponentConfig('BasicInfo', <app.ticket.interfaces.IBasicInfoComponentFeatureConfig>{ isChangingDatesAllowed: ticketType.IsChangingDatesAllowed ? true : false, requireAppointmentToChangeAssignedUser: true }),
                    new ComponentConfig('Review'),
                    new ComponentConfig('ContactAttempts'),
                    new ComponentConfig('ConnectedUsersFloatingPanel'),
                    new ComponentConfig('CustomerNotes'),
                    new ComponentConfig('CustomerTodoListFloatingPanel'),
                    new ComponentConfig('KeywordDiscovery'),
                    new ComponentConfig('discovery-email'),
                    new ComponentConfig('NoteFloatingPanel')
                ];
                break;
            case TicketTypes.FulfillmentWorkblock:
            case TicketTypes.BusinessProfileWorkblock:
            case TicketTypes.IncomingEscalation:
            case TicketTypes.OnsiteBlogContentRejected:
            case TicketTypes.OutgoingEscalation:
            case TicketTypes.SendOnsiteBlogPostToCustomer:
                components = defaults;
                break;
            case TicketTypes.CreateAndInstallRobotstxtFile:
            case TicketTypes.FixBrokenLinks:
            case TicketTypes.SetupGoogleAnalytics:
                components = this.extendDefaults(ticketType, [
                    new ComponentConfig('BasicInfo', <app.ticket.interfaces.IBasicInfoComponentFeatureConfig>{ isChangingDatesAllowed: ticketType.IsChangingDatesAllowed ? true : false })
                ]);
                break;
            case TicketTypes.SeoOnboardingWorkblock:
                components = this.extendDefaults(ticketType, [
                    new ComponentConfig('LockConfirmation', <app.ticket.interfaces.ILockConfirmationComponentFeatureConfig>{ lockOnAppointment: true })
                ]);
                break;
            case TicketTypes.MonthlyCheckup:
                components = [
                    new ComponentConfig('BasicInfo', <app.ticket.interfaces.IBasicInfoComponentFeatureConfig>{ isChangingDatesAllowed: ticketType.IsChangingDatesAllowed ? true : false }),
                    new ComponentConfig('Descendants', <app.ticket.interfaces.IDescendantsComponentFeatureConfig>{ isApprovalRequired: ticketType.IsApprovalRequired ? true : false }),
                    new ComponentConfig('ContactAttempts'),
                    new ComponentConfig('Review'),
                    new ComponentConfig('NoteFloatingPanel'),
                    new ComponentConfig('CustomerTodoListFloatingPanel'),
                    new ComponentConfig('ReportingAccess'),
                    new ComponentConfig('OnsiteRecommendations'),
                    new ComponentConfig('CustomActionsReport'),
                    new ComponentConfig('OnsiteRecommendationsComplete'),
                    new ComponentConfig('Files', <app.ticket.interfaces.IFilesComponentFeatureConfig>{
                        availableFileSections: [
                            FileSections.General
                        ],
                        defaultFileSection: FileSections.General,
                        canDelete: false,
                        canUpload: ticketType.HasFileUploadCapability ? true : false,
                        isFileUploadRequired: ticketType.IsFileUploadRequired
                    }),
                    new ComponentConfig('ContactAttempts'),
                    new ComponentConfig('CustomReports'),
                ];
                break;
            case TicketTypes.ProactiveUnderPerformingKeyword:
            case TicketTypes.ProactiveUnderPerformingKeywordMoneyBack:
                components = [
                    new ComponentConfig('BasicInfo', <app.ticket.interfaces.IBasicInfoComponentFeatureConfig>{ isChangingDatesAllowed: ticketType.IsChangingDatesAllowed ? true : false }),
                    new ComponentConfig('Descendants', <app.ticket.interfaces.IDescendantsComponentFeatureConfig>{ isApprovalRequired: ticketType.IsApprovalRequired ? true : false }),
                    new ComponentConfig('ContactAttempts'),
                    new ComponentConfig('Review'),
                    new ComponentConfig('NoteFloatingPanel'),
                    new ComponentConfig('CustomerTodoListFloatingPanel'),
                    new ComponentConfig('OnsiteRecommendations'),
                    new ComponentConfig('customer-campaign'),
                    new ComponentConfig('upa-checklist'),
                    new ComponentConfig('Files', <app.ticket.interfaces.IFilesComponentFeatureConfig>{
                        availableFileSections: [
                            FileSections.General,
                            FileSections.BoostSite,
                            FileSections.LocalProfile,
                            FileSections.Marketing,
                            FileSections.Ranks
                        ],
                        defaultFileSection: FileSections.General,
                        canDelete: true,
                        canUpload: ticketType.HasFileUploadCapability ? true : false,
                        isFileUploadRequired: ticketType.IsFileUploadRequired
                    })
                ];
                break;
            case TicketTypes.VivialOpoRenewal:
                components = [
                    new ComponentConfig('BasicInfo', <app.ticket.interfaces.IBasicInfoComponentFeatureConfig>{ isChangingDatesAllowed: true }),
                    new ComponentConfig('ContactAttempts'),
                    new ComponentConfig('Review'),
                    new ComponentConfig('NoteFloatingPanel'),
                    new ComponentConfig('CustomerTodoListFloatingPanel')
                ];
                break;
            case TicketTypes.OnsiteCopySendCopy:
            case TicketTypes.OnsiteCopyPublished:
                components = this.getDefaults(ticketType);
                components.splice(1, 0,
                  new ComponentConfig('OnsiteCopyRequest'),
                  new ComponentConfig('customer-campaign')
                );
                break;
            case TicketTypes.OnsiteRecommendationReview:
                components = this.getDefaults(ticketType);
                components.splice(1, 0, new ComponentConfig('customer-campaign', <app.ticket.interfaces.ICustomerCampaignComponentFeatureConfig>{ reviewSource: Boo.OnsiteRecommendations.Models.Enums.ReviewSources.ReviewTicket }));
                break;
            case TicketTypes.OnsiteRecommendationsNotImplemented:
                components = this.getDefaults(ticketType);
                components.splice(1, 0, new ComponentConfig('customer-campaign', <app.ticket.interfaces.ICustomerCampaignComponentFeatureConfig>{ reviewSource: Boo.OnsiteRecommendations.Models.Enums.ReviewSources.NotImplementedTicket })
                );
                break;
            case TicketTypes.ManageBusinessCitationSubscriptions:
                components = defaults.concat([
                    new ComponentConfig('LocalProfiles')
                ]);
                break;
            case TicketTypes.OnsiteCopyReadyForPreview:
                components = this.extendDefaults(
                    ticketType,
                    [
                      new ComponentConfig('OnsiteCopyImplementationNotes'),
                      new ComponentConfig('blog-and-copy-content-review'),
                      new ComponentConfig('SeoContentSurvey')
                    ],
                    true
                );
                break;
            case TicketTypes.OnsiteBlogReadyForPreview:
                components = defaults.concat([
                  new ComponentConfig('blog-and-copy-content-review'),
                  new ComponentConfig('SeoContentSurvey')
                ]);
                break;
            case TicketTypes.Retention:
                components = defaults.concat([
                    new ComponentConfig('Retention')
                  ]);
                break;
            case TicketTypes.KeywordResearchStrategyUpdate:
                  components = [
                    new ComponentConfig('BasicInfo', <app.ticket.interfaces.IBasicInfoComponentFeatureConfig>{ isChangingDatesAllowed: ticketType.IsChangingDatesAllowed ? true : false }),
                    new ComponentConfig('Descendants', <app.ticket.interfaces.IDescendantsComponentFeatureConfig>{ isApprovalRequired: ticketType.IsApprovalRequired ? true : false }),
                    new ComponentConfig('ContactAttempts'),
                    new ComponentConfig('Review'),
                    new ComponentConfig('ConnectedUsersFloatingPanel'),
                    new ComponentConfig('NoteFloatingPanel'),
                    new ComponentConfig('CustomerTodoListFloatingPanel'),
                    new ComponentConfig('Keywords', <app.ticket.interfaces.IKeywordsComponentFeatureConfig> { canChangeCampaignType: false, openOnLoad: true })
                  ];
                  break;
            case TicketTypes.CorrectBacklinkParameters:
                components = this.getDefaults(ticketType);
                components.splice(1, 0,
                    new ComponentConfig('customer-campaign'),
                    new ComponentConfig('backlink-parameters')
                );
                break;
            default:
                components = null;
                break;
        }
        return components;
    }

    /**
     * Returns a list of components by ticket type with partner conditions
     * Set up components that are specific to partners here.
     */
    private static getByPartnerAndTicketType(partnerId: number, ticketType: Boo.Objects.TicketType): app.ticket.interfaces.IComponentConfig[] {
        let components: app.ticket.interfaces.IComponentConfig[] = null;
        switch (partnerId) {
            case Boo.Objects.Enums.PartnerEnum.Boostability:
                switch (ticketType.TicketTypeId) {
                    case TicketTypes.SeoOnboarding:
                        // Adds KeywordResearch File type for Boostability partner
                        components = [
                            new ComponentConfig('LockConfirmation', <app.ticket.interfaces.ILockConfirmationComponentFeatureConfig>{ lockOnAppointment: true }),
                            new ComponentConfig('BasicInfo', <app.ticket.interfaces.IBasicInfoComponentFeatureConfig>{ isChangingDatesAllowed: ticketType.IsChangingDatesAllowed ? true : false, requireAppointmentToChangeAssignedUser: true }),
                            new ComponentConfig('Review'),
                            new ComponentConfig('ContactAttempts'),
                            new ComponentConfig('ConnectedUsersFloatingPanel'),
                            new ComponentConfig('CustomerTodoListFloatingPanel'),
                            new ComponentConfig('PreAuditReport'),
                            new ComponentConfig('KeywordResearch'),
                            new ComponentConfig('SeoContentSurvey'),
                            new ComponentConfig('OnsiteRecommendations'),
                            new ComponentConfig('LinkBuilding'),
                            new ComponentConfig('Files', <app.ticket.interfaces.IFilesComponentFeatureConfig>{
                                availableFileSections: [FileSections.General, FileSections.KeywordResearch],
                                defaultFileSection: FileSections.KeywordResearch,
                                canDelete: false,
                                canUpload: ticketType.HasFileUploadCapability ? true : false,
                                isFileUploadRequired: ticketType.IsFileUploadRequired,
                                filterByTicket: false
                            }),
                            new ComponentConfig('Descendants', <app.ticket.interfaces.IDescendantsComponentFeatureConfig>{ isApprovalRequired: ticketType.IsApprovalRequired ? true : false }),
                            new ComponentConfig('NoteFloatingPanel'),
                            new ComponentConfig('TicketNavigationFloatingPanel', <app.ticket.interfaces.ITicketNavigationComponentFeatureConfig>{
                                navigationLinks: [
                                    { id: 'BasicInfo', text: 'Basic Information' },
                                    { id: 'Review', text: 'Review' },
                                    { id: 'ContactAttempts', text: 'Contact Attempts' },
                                    { id: 'PreAuditReport', text: 'Pre-Audit Report' },
                                    { id: 'KeywordResearch', text: 'Keyword Research' },
                                    { id: 'SeoContentSurvey', text: 'SEO Content Survey' },
                                    { id: 'OnsiteRecommendations', text: 'Onsite Recommendations' },
                                    { id: 'LinkBuilding', text: 'Link Building' },
                                    { id: 'Files', text: 'Files' }
                                ]
                            })
                        ];
                        break;
                    case TicketTypes.SeoDiscovery:
                        components = [
                            new ComponentConfig('LockConfirmation', <app.ticket.interfaces.ILockConfirmationComponentFeatureConfig>{ lockOnAppointment: true }),
                            new ComponentConfig('BasicInfo', <app.ticket.interfaces.IBasicInfoComponentFeatureConfig>{ isChangingDatesAllowed: ticketType.IsChangingDatesAllowed ? true : false, requireAppointmentToChangeAssignedUser: true }),
                            new ComponentConfig('Review'),
                            new ComponentConfig('ContactAttempts'),
                            new ComponentConfig('CustomerNotes'),
                            new ComponentConfig('ConnectedUsersFloatingPanel'),
                            new ComponentConfig('CustomerTodoListFloatingPanel'),
                            new ComponentConfig('KeywordDiscovery'),
                            new ComponentConfig('discovery-email'),
                            new ComponentConfig('NoteFloatingPanel')
                        ];
                        break;
                    default:
                        components = null;
                        break;
                }
                break;
            default:
                components = null;
                break;
        }
        return components;
    }

    private static extendDefaults(ticketType: Boo.Objects.TicketType, components: app.ticket.interfaces.IComponentConfig[], appendToDefaults = false): app.ticket.interfaces.IComponentConfig[] {
        let defaults = TicketTypeComponents.getDefaults(ticketType);
        let result: app.ticket.interfaces.IComponentConfig[] = [];

        // Include defaults if not already in list
        _.each(defaults, (defaultComponent: app.ticket.interfaces.IComponentConfig) => {
            let matchingComponent = _.find(components, (overriddenComponent: app.ticket.interfaces.IComponentConfig) => {
                return overriddenComponent.name === defaultComponent.name;
            });
            if (!matchingComponent) {
                result.push(defaultComponent);
            }
        });

        if (appendToDefaults) {
            result.push(...components);
        } else {
            result.unshift(...components);
        }

        return result;
    }
}
