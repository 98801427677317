<div *withPermissions="let allow of ['CanUpdateSynopsis']" class="card">
	<div class="card-header">
		Synopsis
		<app-components-helptext [key]="'CustomerSynopsis_Purpose'"></app-components-helptext>
	</div>
	<div class="card-body">
		<div class="form-group">
			<textarea [ngModel]="customerSynopsis()" (ngModelChange)="customerSynopsis($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [autoExpand]="true" name="customerSynopsis" class="form-control" maxlength="4000"></textarea>
			<div style="margin-top: 7px;">
				<span class="help-block float-start">Maximum Length is 4000 Characters</span>
				<button [disabled]="!canUpdateSynopsis()" (click)="save()" class="btn btn-success float-end">
					<i class="fa fa-save"></i>
					Update Synopsis
				</button>
			</div>
		</div>
	</div>
</div>
