import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "removeComma"
})
export class RemoveCommaPipe implements PipeTransform {
  private static regex = new RegExp(',', 'g');
  transform(value: any, ...args: any[]): any {
    return value?.replace(RemoveCommaPipe.regex, '') || '';
  }
}