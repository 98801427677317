<div [class]="css.wrapper">
	<div [class]="css.tabsWrapper">
		<div [class]="css.tabs">
			<ul [class]="css.nav">
				<ng-container *ngFor="let tab of tabs">
					<li class="nav-item">
						<a class="nav-link" [class.active]="tab.active" [attr.href]="'#' + tab.id" data-toggle="tab" (click)="tabSelected(tab.id)">
							<ng-container *ngIf="tab.titleIcon">
								<i [class]="tab.titleIcon"></i>
							</ng-container>
							<span>{{tab.title}}</span>
							<ng-container *ngIf="!(tab.title)">
								<em>&lt;Empty&gt;</em>
							</ng-container>
							<ng-container *ngIf="tab.titleBadge">
								<span [class.bg-danger]="isTitleBadgeDanger(tab.titleBadge)" [class.bg-secondary]="!isTitleBadgeDanger(tab.titleBadge)" class="badge rounded-pill title-badge">{{tab.titleBadge}}</span>
							</ng-container>
						</a>
					</li>
				</ng-container>
			</ul>
		</div>
	</div>
	<div [class]="css.contentWrapper">
		<div class="tab-content">
			<ng-content></ng-content>
		</div>
	</div>
</div>
