<div *ngIf="!loading">
  <div class="d-flex align-items-center mb-4">
    <app-components-shared-keyword-research-summary
      [customer]="customer"
      [pageSuggestions]="pageSuggestions"
      [keywordConfig]="keywordConfig"
      (campaignTypeChanged)="getKeywordConfig()">
    </app-components-shared-keyword-research-summary>
    <div class="ms-auto">
      <ng-container *ngIf="validationMessage">
        <span class="text-danger me-3">
          {{ validationMessage }}
        </span>
      </ng-container>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col-6 text-start">
      @if (!customer.IsCampaignNational) {
        <app-components-shared-keyword-research-redo
          [customer]="customer"
          (redoRequested)="redo()">
        </app-components-shared-keyword-research-redo>
      }
    </div>
    <div class="col-6 text-end">
      <app-components-shared-keyword-research-add-page
        [customer]="customer"
        [pageSuggestions]="pageSuggestions">
      </app-components-shared-keyword-research-add-page>
    </div>
  </div>
  
  <ng-container *ngIf="selectedPageSuggestions.length === 0; else showSelectedPages">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>No pages or keywords have been selected yet</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
  
  <ng-template #showSelectedPages>
    <div class="row" *ngFor="let pageSuggestion of selectedPageSuggestions | orderBy: pageSort">
      <app-components-shared-keyword-research-page
        [customer]="customer"
        [pageSuggestion]="pageSuggestion"
        [pageSuggestions]="pageSuggestions"
        [keywordConfig]="keywordConfig">
      </app-components-shared-keyword-research-page>
    </div>
  </ng-template>
</div>