import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-components-upa-checklist',
  templateUrl: './upa-checklist.component.html'
})
export class UpaChecklistComponent {
  @Input() upaChecklists: Boo.Objects.Checklist.UpaChecklist[];
  @Input() validationApi: app.interfaces.IValidationPublicApi;
  @Input() isReadOnly: boolean;
  @Input() hidePassedAnswers: boolean;
  @Input() hideFailedAnswers: boolean;
  @Input() hideSecondaryAnswers: boolean;
  @Input() displayNotesInline: boolean;

  constructor() { }

  getHeader(checklist: Boo.Objects.Checklist.UpaChecklist) {
    switch(checklist.Checklist.ChecklistType) {
      case Boo.Objects.Checklist.Enums.ChecklistTypes.UpaLaunchpad:
        return 'Launchpad';
      case Boo.Objects.Checklist.Enums.ChecklistTypes.UpaGlobal:
        return 'Global';
      case Boo.Objects.Checklist.Enums.ChecklistTypes.UpaPageSpecific:
        return `Page Specific: ${checklist.WebsiteUrl?.Url}`
    }
  }
}
