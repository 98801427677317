<div [loadingOverlay]="isLoading">
  <div class="row">
    <div class="col-lg-6">
      <div style="margin-bottom:15px;">
        <a [hidden]="hasGoogleAnalyticsAccess" (click)="grantGoogleAnalyticsAccess()" class="btn btn-warning">
          <i class="fab fa-google"></i>
          Grant Analytics Access
        </a>
        <button [hidden]="!hasGoogleAnalyticsAccess" (click)="revokeAnalyticsAccess()" class="btn btn-danger">
          <i class="fa fa-times"></i>
          Revoke Analytics Access
        </button>
      </div>
    </div>
    <app-components-managecustomer-customeraccountgroup [options]="{
      customerId: customerId,
      customerAccountTypes: customerAccountTypes,
      showSaveButton: showSaveButton,
      isEnabled: isEnabled(),
      publicApiCallback: publicApiCallback
    }"></app-components-managecustomer-customeraccountgroup>
  </div>
</div>