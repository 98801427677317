import { Component } from '@angular/core';
import { CustomerTodoListService} from '../../../services/customer-todo-list.service';
import TodoStatus = Boo.Objects.Todo.Enums.TodoStatus;
import { TodoService } from '../../../services/todo.service';
import Utils from '../../../shared/utils';
import CustomerTodoListTypes = Boo.Objects.Todo.Enums.CustomerTodoListTypes;

@Component({
  selector: 'app-components-managecustomer-customertodohistory',
  templateUrl: './CustomerTodoHistory.component.html'
})
export class CustomerTodoHistoryComponent implements IActivatable {
  public loading: KnockoutObservable<boolean> = ko.observable(false);
  public customerId: number;
  public todoHistory: KnockoutObservableArray<Boo.Objects.Todo.CustomerTodoHistory> = ko.observableArray([]);
  public hasMore: KnockoutComputed<boolean>;
  public loadedNotes: KnockoutObservableArray<Boo.Objects.Todo.TodoNote> = ko.observableArray([]);

  private totalTodos: KnockoutObservable<number> = ko.observable(0);
  private loadedTodos: KnockoutObservable<number> = ko.observable(0);

  constructor(
    private todoService: TodoService,
    private customerTodoListService: CustomerTodoListService) { }

  public activate(params: any): JQueryPromise<any> {
    this.customerId = params.customer.CustomerId();

    this.hasMore = ko.computed(() => this.totalTodos() > this.loadedTodos());

    return this.loadHistory()
      .then(res => {
        this.todoHistory(res.Items);
        this.totalTodos(res.Count);
        this.loadedTodos(this.todoHistory().length);
      })
      .fail(err => toastr.error(err));
  }

  public loadMore(): void {
    if (!this.hasMore()) {
      toastr.error('There are no more todos to load');
      return;
    }

    this.loadHistory(this.loadedTodos())
      .then(res => {
        this.todoHistory.push(...res.Items);
        this.loadedTodos(this.todoHistory().length);
      });
  }

  public getNotes(todo: Boo.Objects.Todo.Todo): void {
    Utils.wrapDfd(this.todoService.getNotes(todo.TodoId))
      .then((notes) => {
        this.loadedNotes(notes);
        this.openNotesModal();
      });
  }

  public getStatusName(status: Boo.Objects.Todo.Enums.TodoStatus): string {
    switch (status) {
      case TodoStatus.New:
        return Status.new;
      case TodoStatus.Complete:
        return Status.complete;
      case TodoStatus.Incomplete:
        return Status.incomplete;
      default:
        return Status.unknown ;
    }
  }

  public closeNotesModal(): void {
    $('#todoNotesModal').modal('hide');
  }

  private loadHistory(skip: number = 0, take: number = 50): JQueryPromise<framework.data.ISimplePageResult<Boo.Objects.Todo.CustomerTodoHistory>> {
    return Utils.wrapDfd(this.customerTodoListService.getHistory(this.customerId, CustomerTodoListTypes.General, skip, take));
  }

  private openNotesModal(): void {
    $('#todoNotesModal').modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
  }
}

enum Status {
  new = 'New',
  complete = 'Complete',
  incomplete = 'Incomplete',
  unknown = 'Unknown Status'
}
