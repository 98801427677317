<app-components-specialist-internal-tasks-action-information [internalAction]="internalAction"
	[showPenName]="true"></app-components-specialist-internal-tasks-action-information>
<div class="row">
	<div class="col-lg-5 offset-lg-3">
		<div class="card card-info text-center">
			<div class="card-body">
				<h4>This task is completed in the Blog Editor application</h4>
				<p style="margin-top: 50px;" class="text-center">
					<a [attr.href]="url | safeUrl" class="btn btn-success">
						<i class="fas fa-external-link-square-alt" aria-hidden="true"></i>
						Open Editor
					</a>
				</p>
				<p class="text-center">
					<small>or</small>
				</p>
				<p class="text-center">
					<small>
						<a href="https://s3-us-west-2.amazonaws.com/blog-builder/releases/BlogBuilderSetup.exe">
							<i class="fa fa-download" aria-hidden="true"></i>
							Download Editor
						</a>
					</small>
				</p>
			</div>
		</div>
	</div>
</div>