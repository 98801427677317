/* tslint:disable */

import { Component, OnInit } from '@angular/core';
import app from 'App';
import { SessionStorageService } from '../../../services/session-storage.service';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-components-switchpartner',
	templateUrl: './SwitchPartner.component.html'
})
export class SwitchPartnerComponent implements OnInit {
  partners: Boo.Objects.Partner[] = [];
  partnerUser: Boo.Objects.PartnerUser;
  isLoading: boolean = false;

  constructor(private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    this.isLoading = true;

    forkJoin([
      this.sessionStorageService.getPartnerUser(),
      this.sessionStorageService.getPartners()
    ])
    .pipe(finalize(() => this.isLoading = false))
    .subscribe(([partnerUser, partners]) => {
      this.partnerUser = partnerUser;
      this.partners = _.sortBy(partners,
        (partner: any) => {
          return partner.Name;
        });
    });
  }

  public switchPartner(partner: Boo.Objects.Partner): void {
    this.isLoading = true;
    app.switchPartner(partner.PartnerId);
    this.isLoading = false;
  }
}
