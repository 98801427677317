<div class="card">
  <div class="card-header">
    Content Feedback
  </div>
  <div [loadingOverlay]="isLoading()" class="card-body">
    <app-table [items]="feedback()" [exportFilename]="'content-feedback'">
      <ng-template #tableHeader>
        <tr>
          <th export sort field="TaskActionUrl">Action URL</th>
          <th export sort field="PageUrl">Page URL</th>
          <th export sort field="TaskTypeName">Type</th>
          <th export sort field="Feedback">Feedback</th>
          <th export sort field="InsertedDate">Review Date</th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-feedback>
        <tr>
          <td style="max-width: 200px; text-overflow:ellipsis; overflow: hidden;">
            <ng-container *ngIf="feedback.TaskActionUrl">
              <a [externalLink]="{ url: feedback.TaskActionUrl, text: feedback.TaskActionUrl }" rel="noreferrer"></a>
            </ng-container>
            <ng-container *ngIf="!(feedback.TaskActionUrl)">Not Available</ng-container>
          </td>
          <td style="max-width: 200px; text-overflow:ellipsis; overflow: hidden;">
            <a [externalLink]="{ url: feedback.PageUrl, text: feedback.PageUrl}"></a>
          </td>
          <td>{{feedback.TaskTypeName}}</td>
          <td style="max-width: 300px;">{{feedback.Feedback}}</td>
          <td>{{feedback.InsertedDate | dateText }}</td>
        </tr>
      </ng-template>
    </app-table>
  </div>
</div>