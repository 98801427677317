/* tslint:disable */

import { Component } from '@angular/core';
import Utils from '../../../shared/utils';
import { InternalActionService } from '../../../services/internal-action.service';
import { InternalTaskService } from '../../../services/internal-task.service';

@Component({
	selector: 'app-components-specialist-lockedinternaltasks',
	templateUrl: './LockedInternalTasks.component.html'
})
export class LockedInternalTasksComponent {
    public isLoading: any = ko.observable(false);
    public selectedTaskTypeId: any = ko.observable(0);
    public selectedUserId: any = ko.observable(0);
    public lockedInternalTasks: any = ko.observableArray([]);
    public skipActionReason: any = ko.observable();

    constructor(
      private internalActionService: InternalActionService,
      private internalTaskService: InternalTaskService) { }

    public filteredTasks: any = ko.computed(() => {
        let result = this.lockedInternalTasks();
        if (this.selectedTaskTypeId() !== 0 && this.selectedUserId() !== 0) {
            result = _.where(this.lockedInternalTasks(), { TaskTypeId: this.selectedTaskTypeId(), FullName: this.users()[this.selectedUserId()].Name });
        } else if (this.selectedTaskTypeId() !== 0) {
            result = _.where(this.lockedInternalTasks(), { TaskTypeId: this.selectedTaskTypeId() });
        } else if (this.selectedUserId() !== 0) {
            result = _.where(this.lockedInternalTasks(), { FullName: this.users()[this.selectedUserId()].Name });
        }
        return result;
    });

    public canActivate(user: any, partner: any): any {
        return launchpad.utils.canAccessInternalTasks(user.PartnerUsers);
    }

    public unlock(internalTask: any): void {
        bootbox.confirm('Are you sure you want to unlock and unassign this task?',
            (result: any) => {
                if (result === true) {
                    this.isLoading(true);

                    Utils.wrapDfd(this.internalActionService.unlockAndUnassign(internalTask.InternalTaskId))
                      .fail((displayMessage) => {
                          toastr.error(displayMessage);
                      })
                      .always(() => {
                          this.loadLockedInternalTasks();
                      });
                }
            }
        );
    }

    public activate(): void {
        this.isLoading(false);
    }

    public loadLockedInternalTasks(): void {
      Utils.wrapDfd(this.internalTaskService.getAllLocked())
        .then((data: any) => {
            _.each(data,
                (taskDetail: any) => {
                    taskDetail.FullName = taskDetail.AssignedUserFirstName + ' ' + taskDetail.AssignedUserLastName;
                });
            this.lockedInternalTasks(data);
        }).fail((displayMessage) => {
            toastr.error(displayMessage);
        }).always(() => {
            this.isLoading(false);
        });
    }

    public taskTypes: any = ko.computed(() => {
        let taskTypes: any = [];
        _.each(this.lockedInternalTasks(),
            (task: any) => {
                const alreadyHasTaskType = _.any(taskTypes,
                    (taskType: any) => {
                        return taskType.TaskTypeEnum === task.TaskTypeEnum;
                    });
                if (!alreadyHasTaskType) {
                    taskTypes.push({
                        taskTypeId: task.TaskTypeId,
                        taskTypeName: task.TaskTypeName
                    });
                }
            });
        // Sort TaskTypes alphabetically
        taskTypes = _.sortBy(taskTypes,
            (type: any) => {
                return type.taskTypeName;
            });
        // push "All" to beginning of taskTypes array
        taskTypes.unshift({
            taskTypeId: 0,
            taskTypeName: 'All'
        });
        return taskTypes;
    });

    public users: any = ko.computed(() => {
        let users: any = [];
        _.each(this.lockedInternalTasks(),
            (task: any, i) => {
                const alreadyHasUser = _.any(users,
                    (user: any) => {
                        return task.FullName === user.Name;
                    });
                if (!alreadyHasUser) {
                    users.push({
                        "Name": task.FullName,
                        "Id": users.length + 1
                    });
                }
            });
        // Sort Users alphabetically
        users = _.sortBy(users,
            (user) => {
                return user;
            });
        // push "All" to beginning of users array
        users.unshift({ "Name": 'All', "Id": 0 });
        return users;
    });
}
