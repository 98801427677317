import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerCountryService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/partnercountries`;

  constructor(private http: HttpClient) { }

  get(partnerId: number): Observable<Boo.Objects.PartnerCountry[]> {
    let params = new HttpParams().set('partnerId', partnerId);
    return this.http.get<Boo.Objects.PartnerCountry[]>(this.baseUrl, { params: params });
  }

  saveMany(partnerId: number, countryIds: number[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/savemany`, { partnerId: partnerId, countryIds: countryIds });
  }
}
