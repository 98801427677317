import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import permissions from 'app/models/Permissions';
import { SessionStorageService } from '../../services/session-storage.service';
import { forkJoin } from 'rxjs';

@Directive({ selector: '[withPermissions]' })
export class WithPermissionsDirective {
  private context: WithPermissionsDirectiveContext = { $implicit: ko.observable({}) };
  private viewRef: EmbeddedViewRef<WithPermissionsDirectiveContext> = undefined;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<WithPermissionsDirectiveContext>,
    private sessionStorageService: SessionStorageService) {}

  @Input('withPermissionsOf')
  public set Permissions(permissionsToCheck: string[]) {
    let hasPermission = this.context.$implicit;
    for (let permission of permissionsToCheck) {
      hasPermission()[permission] = ko.observable(false);
    }
    forkJoin([
      this.sessionStorageService.getPartnerUsers(),
      this.sessionStorageService.getPartner(),
      this.sessionStorageService.getUser()
    ])
    .subscribe(([currentPartnerUsers, partner, user]) => {
      for (let permission of permissionsToCheck) {
        hasPermission()[permission](window.launchpad.hasPermission(partner, currentPartnerUsers, permissions[permission], user));
      }

      hasPermission.valueHasMutated();
    });

    if (!this.viewRef) {
      this.viewContainer.clear();
      this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef, this.context);
    }
  }
}

interface WithPermissionsDirectiveContext { $implicit: KnockoutObservable<{ [permission:string]:KnockoutObservable<boolean> }> }