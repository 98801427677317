<div class="card">
  <div class="card-header">
    <span>Update Rejection Reason</span>
    <button (click)="close()" type="button" class="btn-close float-end"></button>
  </div>
  <div [loadingOverlay]="isLoading" class="card-body">
    <div class="form-horizontal">
      <div *ngIf="isReadOnly">This form is read only because the associated task is Locked, Complete, or Reconcile Complete</div>
      <ng-container *ngIf="originalInstructions">
        <label class="control-label">Current Instructions</label>
        <textarea [ngModel]="originalInstructions" class="form-control" readonly>
        </textarea>
      </ng-container>
      <label *ngIf="originalInstructions" class="control-label">Additional Instructions</label>
      <label *ngIf="!originalInstructions" class="control-label">Instruction</label>
      <textarea [(ngModel)]="additionalInstructions" class="form-control" [disabled]="isReadOnly">
			</textarea>
    </div>
  </div>
  <div class="card-footer text-end">
    <button (click)="close()" class="btn btn-link">Cancel</button>
    <button [disabled]="additionalInstructions.length === 0 || isReadOnly" (click)="saveInstructions()" class="btn btn-success">
      <i class="fa fa-save"></i>
      Update Instructions
    </button>
  </div>
</div>