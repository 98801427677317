import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeoWorkService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/seowork`;

  constructor(private http: HttpClient) { }

  getSeoWorkSettings(customerId: number): Observable<Boo.Objects.Work.SeoMinute.SeoWorkSettings> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<Boo.Objects.Work.SeoMinute.SeoWorkSettings>(`${this.baseUrl}/GetSeoWorkSettings`, { params: params });
  }

  saveSeoWorkSettings(customerId: number, seoWorkSettings: Boo.Objects.Work.SeoMinute.SeoWorkSettings, itemsToDelete: Boo.Objects.Work.SeoMinute.WorkRequestDetail[], noteText: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveSeoWorkSettings`, { customerId: customerId, seoWorkSettings: seoWorkSettings, itemsToDelete: itemsToDelete, noteText: noteText });
  }

  deleteSeoWorkRequest(seoWorkItem: Boo.Objects.Work.SeoMinute.WorkRequestDetail): Observable<any> {
    return this.http.post(`${this.baseUrl}/DeleteSeoWorkRequest`, seoWorkItem);
  }

  deleteSeoWorkItems(seoWorkItems: Boo.Objects.Work.SeoMinute.WorkRequestDetail[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/DeleteSeoWorkRequests`, seoWorkItems);
  }
  
  getOpenWorkRequestsForPastPeriods(customerId: number): Observable<Boo.Objects.Work.SeoMinute.WorkRequestDetail[]> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<Boo.Objects.Work.SeoMinute.WorkRequestDetail[]>(`${this.baseUrl}/GetOpenWorkRequestsForPastPeriods`, { params: params });
  }

  getWorkRequest(workRequestId: number): Observable<Boo.Objects.Work.WorkRequest> {
    const params = new HttpParams().set('workRequestId', workRequestId);
    return this.http.get<Boo.Objects.Work.WorkRequest>(`${this.baseUrl}/GetWorkRequest`, { params: params });
  }

  getSeoWorkDefaults(customerId: number): Observable<Boo.Objects.Work.SeoMinute.SeoWorkDefault[]> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<Boo.Objects.Work.SeoMinute.SeoWorkDefault[]>(`${this.baseUrl}/GetSeoWorkDefaults`, { params: params });
  }

  getCustomerIdsWithTaskTypeInDefaults(partnerId: number, taskTypeId: number, amountToTake: number): Observable<number[]> {
    const params = new HttpParams()
      .set('partnerId', partnerId)
      .set('taskTypeId', taskTypeId)
      .set('amountToTake', amountToTake);
    return this.http.get<number[]>(`${this.baseUrl}/GetCustomerIdsWithTaskTypeInDefaults`, { params: params });
  }

  getCustomerIdsWithTaskTypeInFutureAllocations(partnerId: number, taskTypeId: number, amountToTake: number): Observable<number[]> {
    const params = new HttpParams()
      .set('partnerId', partnerId)
      .set('taskTypeId', taskTypeId)
      .set('amountToTake', amountToTake);
    return this.http.get<number[]>(`${this.baseUrl}/GetCustomerIdsWithTaskTypeInFutureAllocations`, { params: params });
  }

  getPartnerTaskTypeUsage(partnerId: number, numberOfCustomerIdsToTake: number): Observable<Boo.Objects.PartnerTaskTypeUsage[]> {
    const params = new HttpParams().set('partnerId', partnerId)
    .set('numberOfCustomerIdsToTake', numberOfCustomerIdsToTake);
    return this.http.get<Boo.Objects.PartnerTaskTypeUsage[]>(`${this.baseUrl}/GetPartnerTaskTypeUsage`, { params: params });
  }

  editSeoWorkRequest(seoWorkItem: Boo.Objects.Work.SeoMinute.WorkRequestDetail): Observable<any> {
    return this.http.post(`${this.baseUrl}/EditSeoWorkRequest`, seoWorkItem);
  }

  deleteWorkRequestFile(workRequestFile: Boo.Objects.Work.WorkRequestFile, workOrderItemWorkTypeId: number, workOrderItemWorkId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/DeleteWorkRequestFile`,
      {
          workRequestFile: workRequestFile,
          workOrderItemWorkTypeId: workOrderItemWorkTypeId,
          workOrderItemWorkId: workOrderItemWorkId
      });
  }

  checkWorkOrderBlocks(customerId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/CheckWorkOrderBlocks`, customerId);
  }

  getSeoWorkDefaultConfiguration(customerId: number): Observable<Boo.Objects.Work.SeoMinute.SeoWorkDefaultConfiguration> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<Boo.Objects.Work.SeoMinute.SeoWorkDefaultConfiguration>(`${this.baseUrl}/SeoWorkDefaultConfiguration`, { params: params });
  }
}
