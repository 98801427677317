import { Component, OnInit, Input, TemplateRef, computed, signal, Signal, input, EnvironmentInjector, effect, EffectRef, OnDestroy } from '@angular/core';
import { SaveTypes } from "app/models/enums/SaveTypes";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import System from 'framework/System';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TrackedChecklistItem } from '../models/tracked-checklist-item.model';

@Component({
  selector: 'app-components-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit, OnDestroy {
  @Input() checklist: Boo.Objects.Checklist.Checklist;
  @Input() header: string;
  @Input() isReadOnly: boolean;
  @Input() validationApi: app.interfaces.IValidationPublicApi;
  hidePassedAnswers = input<boolean>();
  hideFailedAnswers = input<boolean>();
  hideSecondaryAnswers = input<boolean>();
  @Input() displayNotesInline: boolean;
  noteControl: UntypedFormControl = new UntypedFormControl('', Validators.maxLength(512));
  uniqueId: string;
  items: TrackedChecklistItem[] = [];
  effects: EffectRef[] = [];

  private errorMessages: string[] = [];

  constructor(
    private modalService: NgbModal,
     private injector: EnvironmentInjector) { }

  ngOnInit(): void {
    this.uniqueId = _.uniqueId("Checklist_");
    this.checklist.Items.sort((a, b) => a.Order - b.Order);

    if (this.validationApi) {
      this.validationApi.add (
        {
          isValid: this.isValid.bind(this),
          validate: this.validate.bind(this)
        },
        this.uniqueId
      );
    }

    if (this.isReadOnly) {
      this.noteControl.disable();
    }
    this.setupGroupConditions();
  }

  ngOnDestroy(): void {
    this.effects.forEach(x => x.destroy());
  }

  isValid(saveType: SaveTypes): boolean {
    this.errorMessages = [];
    if (saveType && saveType === SaveTypes.Update) { // Allow updating checklists
      return true;
    }

    if (this.items.some(x => x.groupConditionsSatisfied() && x.Value === null)) {
      this.errorMessages.push(`There are unanswered questions in the ${this.header} checklist.`);
      return false;
    }
    
    return true;
  }

  validate(saveType: SaveTypes): JQueryPromise<app.interfaces.IValidatedResult> {
    return System.resolvedPromise<app.interfaces.IValidatedResult>({ isValid: this.isValid(saveType), errorMessages: this.errorMessages });
  }

  openNoteModal(content: TemplateRef<any>, item: Boo.Objects.Checklist.ChecklistItem) {
    if (this.isReadOnly || item.IsAutoChecked) {
      this.noteControl.disable();
    } else {
      this.noteControl.enable();
    }

    this.noteControl.patchValue(item.Note);
    this.modalService.open(content, { backdrop: 'static' }).closed
      .subscribe(note => item.Note = note);
  }

  saveNote(modal: NgbActiveModal) {
    if (this.noteControl.valid) {
      modal.close(this.noteControl.value);
    }

    this.noteControl.markAsDirty();
  }
  // Calculates group conditions and sets up signals for group visibility.
  private setupGroupConditions() {
    this.items = this.checklist.Items.map(x => new TrackedChecklistItem(x));

    var groupedItems = this.items.reduce<TrackedChecklistItem[][]>((acc: TrackedChecklistItem[][], item) => {
      acc[item.checklistItem.Group] = acc[item.checklistItem.Group]?.concat(item) ?? [item];
      return acc;
    }, []);

    var groupAllTrueSignals = groupedItems.reduce<Signal<boolean>[]>((acc: Signal<boolean>[], item) => {
      var signals = item.map(x => x.valueSignal);
      acc[item[0].checklistItem.Group] = computed(() => {
        return signals.every(x => x() === true)
      });
      return acc;
    }, []);

    var groupAllFalseSignals = groupedItems.reduce<Signal<boolean>[]>((acc: Signal<boolean>[], item) => {
      var signals = item.map(x => x.valueSignal);
      acc[item[0].checklistItem.Group] = computed(() => signals.every(x => x() === false));
      return acc;
    }, []);

    var groupConditionsSatisfied: Signal<boolean>[] = [];
    var groupsToCheck = new Set(this.checklist.Conditions.map(x => x.Group));

    while (groupsToCheck.size)
    {
      var current = [...groupsToCheck].filter(x => this.checklist.Conditions.filter(y => y.Group === x).every(y => !groupsToCheck.has(y.DependsOnGroup)));
      current.forEach(group => {
        groupsToCheck.delete(group);
        // Never allow a group to reference itself.
        var signals = this.checklist.Conditions.filter(x => x.Group === group && x.DependsOnGroup !== group)
          .flatMap(x => (x.Condition ? [groupAllTrueSignals[x.DependsOnGroup]] : [groupAllFalseSignals[x.DependsOnGroup]])
            .concat(groupConditionsSatisfied[x.DependsOnGroup] ?? []));
        groupConditionsSatisfied[group] = computed(() => signals.every(x => x()));
      });
    }
    
    this.items.forEach(item => {
      item.shouldHide = computed(() => (this.hidePassedAnswers() && item.valueSignal() === item.checklistItem.PassValue) 
        || (this.hideFailedAnswers() && item.valueSignal() === !item.checklistItem.PassValue)
        || (this.hideSecondaryAnswers() && item.checklistItem.IsSecondary));
      item.groupConditionsSatisfied = groupConditionsSatisfied[item.checklistItem.Group] ?? signal(true);
    });

    this.items.forEach(item => this.effects.push(effect(() => {
      if (!item.groupConditionsSatisfied() && !item.checklistItem.IsAutoChecked)
      {
        item.Value = null;
      }
    }, { injector: this.injector, allowSignalWrites: true, manualCleanup: true })));
  }
}