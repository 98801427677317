import { Component } from '@angular/core';
import System from 'framework/System';
import BaseComponent from '../BaseComponent';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import TicketTypes = Boo.Objects.Enums.TicketTypeEnum;
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import Utils from '../../../shared/utils';
import { StoreService } from '../../../services/store.service';
import { ReloadService } from '../../../services/reload.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { FormControl, Validators } from '@angular/forms';
import { CustomerService } from '../../../services/customer.service';

@Component({
  selector: 'app-components-ticket-review',
  templateUrl: './Review.component.html'
})
export class ReviewComponent extends BaseComponent {
  window = window;
  launchpad = launchpad;
  isEnabled: boolean;
  customerInformationControlParent: { [name: string]: any };
  customerUser: CustomerUserObservable;
  customerSpendSummaries: KnockoutObservableArray<CustomerSpendSummaryObservable>;
  canResetWebsite: boolean;
  ordersSnapshot: Boo.ApisExternal.Store.Objects.Order[];
  verticals: Boo.Objects.Vertical[];
  isOpen: boolean = false;
  verticalCtrl: FormControl = new FormControl(null, Validators.required);

  private websiteResetTicketTypes: TicketTypes[] = [TicketTypes.SeoDiscovery, TicketTypes.SeoOnboarding, TicketTypes.SeoOnboardingWorkblock];

  constructor(
    private storeService: StoreService,
    private customerService: CustomerService,
    private sessionStorageService: SessionStorageService,
    private reloadService: ReloadService) {
    super();
  }

  public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
    return super.activate(params)
      .then(() => {
        this.canResetWebsite = this.websiteResetTicketTypes.indexOf(params.ticket.TicketType.TicketTypeId()) > -1
          && params.partner.PartnerId === window.launchpad.config.partners.boostability
          && (params.ticket.StatusId() === window.launchpad.config.TaskStatusEnum.pending
            || params.ticket.StatusId() === window.launchpad.config.TaskStatusEnum.locked);
        this.triggerTicketSave = params.triggerTicketSave;
        this.customerSpendSummaries = params.customer.Spends;
        this.isEnabled = params.canEdit;
        if (!this.isEnabled) {
          return;
        }

        this.customerUser = this.customer.Users().find((x: CustomerUserObservable) => x.EmailRecipientType() == Boo.Objects.Enums.EmailRecipientTypes.To && x.CanContact()) ?? this.customer.Users().find((x: CustomerUserObservable) => x.CanContact());
        this.customerInformationControlParent = {
          customer: ko.observable(this.customer),
          customerUser: this.customerUser ? ko.observable(this.customerUser) : undefined,
          partner: ko.observable(ko.toJS(this.customer.Partner))
        };

        return Utils.wrapDfd(this.storeService.getOrdersSnapshot(params.customer.CustomerId()));
      })
      .then((snapshot) => {
        this.ordersSnapshot = snapshot;
        return Utils.wrapDfd(this.sessionStorageService.getStaticData());
      })
      .then(staticData => {
        const sorter = new launchpad.utils.pSort();
        sorter.key = 'VerticalId';
        sorter.parentKey = 'ParentVerticalId';
        this.verticals = sorter.sort(staticData.Verticals);
        this.verticals.forEach(v => {
          v.Name = this.getSpaces(v.NestedLevel - 1) + v.Name;
        })

        this.verticalCtrl.setValue(this.customer.VerticalId());
      });
  }

  /**
   * Callback for customer priority views to notify us they've saved the customer
   */
  public customerSavedCallback(): void {
    this.modifiedDataFlags.customer = true;
  }

  public resetCustomerWebsite(): void {
    this.triggerTicketSave(SaveTypes.UpdateWithoutTicketNoteValidation, false)
      .then(() => {
        PriorityViewModel.show('app-components-customerservice-managertools', {
          'customerId': this.customer.CustomerId()
        }).done((data: any) => {
          if (data && data.url) {
            this.customer.Url(data.url);
          }
          this.customerSavedCallback();
          this.reloadService.reload();
        });
      });
  }

  public save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
    return super.save(saveType)
      .then(() => {
        this.customer.VerticalId(this.verticalCtrl.value);
        return Utils.wrapDfd(this.customerService.save(ko.mapping.toJS(this.customer)));
      })
      .then(() => {
        return System.emptyPromise();
      });
  }

  public validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
    return super.validate(saveType)
      .then((validationResult) => {
        if (saveType === SaveTypes.Complete && !this.verticalCtrl.valid) {
          this.isOpen = true;
          validationResult.isValid = false;
          return System.resolvedPromise<IValidatedResult>({ isValid: false, errorMessages: ['Vertical is required'] });
        }
        return validationResult;
      });
  }

  private getSpaces(numSpaces: any): any {
    let spaces = '';
    if (numSpaces === 0) {
      return spaces;
    } //For some reason ng-select removes leading whitespace.
    for (let i = 0; i <= (numSpaces * 2); i++) {
      if (i === 0)
        spaces += '>';
      else
        spaces += ' ';
    }
    return spaces;
  }
}
