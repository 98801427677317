<div>
  <div>
    <ng-container *ngIf="todo">
      <div class="modal-header" style="min-height: 45px;"></div>
      <div class="modal-body">
        <div [class.text-success]="todo.Status === window.launchpad.config.todoStatus.complete"
          [class.text-warning]="todo.Status === window.launchpad.config.todoStatus.incomplete" class="row">
          <div class="col-md-1">
            <i [class.far]="todo.Status === window.launchpad.config.todoStatus.new"
              [class.fa-circle]="todo.Status === window.launchpad.config.todoStatus.new"
              [class.fa-check-circle]="todo.Status === window.launchpad.config.todoStatus.complete"
              [class.fa-minus-circle]="todo.Status === window.launchpad.config.todoStatus.incomplete"
              [class.fas]="todo.Status === window.launchpad.config.todoStatus.complete || todo.Status === window.launchpad.config.todoStatus.incomplete"></i>
          </div>
          <div class="col-md-11">
            <span>{{todo.Content}}</span>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngFor="let todoNote of todo.TodoNotes">
              <div>
                <ng-container *ngTemplateOutlet="todoNoteTemplate;context:{$context:todoNote}"></ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="min-height: 45px;">
        <button (click)="closeTodo(todo)" type="button" class="btn btn-primary" title="Close">OK</button>
      </div>
    </ng-container>
  </div>
  <ng-template #todoNoteTemplate let-$context="$context">
    <div class="row">
      <div class="col-md-12">
        <strong>
          <span>{{$context.InsertedDate | dateText }}</span>
          :
        </strong>
        <span>{{$context.Content}}</span>
      </div>
    </div>
  </ng-template>
</div>
