<div class="col-md-12">
  <table class="table table-hover mb-4">
    <thead>
      <tr>
        <th>
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <button class="btn btn-secondary me-4 btn-sm" type="button"  (click)="removePage()" title="Remove Url">
                <i class="fas fa-times fa-no-margin"></i>
              </button>
              @if (pageSuggestion.Priority) {
                <small><i class="text-primary fas fa-star" title="Recommended Page"></i></small>
              }
              @if (pageSuggestion.Url) {
              {{ pageSuggestion.Url }}
              } @else {
                New Page To Be Created
              }
              @if (!pageSuggestion.IsLive) {
                <span class="badge rounded-pill bg-secondary pill-text">New</span>
              }
            </div>
            <div *ngIf="validationMessage" class="text-danger fw-normal me-3">
              {{ validationMessage }}
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let keywordSuggestion of selectedKeywordSuggestions | orderBy: keywordSort">
        <tr>
          <td>
            <app-components-shared-keyword-research-keyword
              [pageSuggestion]="pageSuggestion"
              [keywordSuggestion]="keywordSuggestion">
            </app-components-shared-keyword-research-keyword>
          </td>
        </tr>
      </ng-container>
      <tr>
        <td>
          <app-components-shared-keyword-research-add-keyword
            [customer]="customer"
            [pageSuggestion]="pageSuggestion"
            [pageSuggestions]="pageSuggestions">
          </app-components-shared-keyword-research-add-keyword>
        </td>
      </tr>
    </tbody>
  </table>
</div>