export default class Job implements Boo.Objects.Jobs.Job {
    public JobId: number;
    public JobTypeId: Boo.Objects.Jobs.Enums.JobTypes;
    public InsertedDate: Date;
    public JobStatusId: Boo.Objects.Jobs.Enums.JobStatuses;
    public JobPriorityId: Boo.Objects.Jobs.Enums.JobPriorities;
    public Version: number;
    public Payload: string;
    public HangfireJobId: string;
    public JobStatus: string;
    public Delay: number;
    public JobType: Boo.Objects.Jobs.JobType;
    public Priority: string;
    public Type: Boo.Objects.Jobs.JobType;
    public ScheduledDate: Date;
    public StatusDate: Date;
}