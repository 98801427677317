<div class="card">
  <div class="card-header">
    Mass Ticket Creation
  </div>
  <div [loadingOverlay]="loading" class="card-body">
    <div [formGroup]="ticketForm" class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Ticket Type</label>
          <ng-select [items]="ticketTypes" placeholder="Select Ticket Type" [clearable]="false" bindLabel="Name"
            bindValue="TicketTypeId" formControlName="ticketTypeId" lpValidationMsg></ng-select>
        </div>
        <div class="form-group">
          <label class="control-label">Assigned User</label>
          <ng-select [items]="partnerUsers" placeholder="Assign to User (Optional)" [clearable]="false"
            bindLabel="FullName" bindValue="UserId" formControlName="assignedUserId" lpValidationMsg></ng-select>
        </div>
        <div class="form-group">
          <label class="control-label">Start Date</label>
          <shared-components-datepicker formControlName="startDate" clearable="true" lpValidationMsg></shared-components-datepicker>
        </div>

      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Ticket Reason Note</label>
          <textarea class="form-control" formControlName="note" rows="8" lpValidationMsg></textarea>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Customer Ids (Comma separated)</label>
          <textarea class="form-control" placeholder="1, 2, 3, 4, 5" formControlName="customerIds" rows="8"
            lpValidationMsg>
          </textarea>
        </div>
      </div>
    </div>
    <div *ngIf="invalidCustomers.length" class="alert alert-danger mb-3">
      <h6 class="fw-bold">Invalid Customers:</h6>
      <span>[ {{ invalidCustomers }} ]</span>
    </div>
    <div class="card card-body bg-light d-block text-end">
      <button class="btn btn-warning me-3 text-light" (click)="reset()">Reset</button>
      <button type="submit" class="btn btn-success" (click)="createBatch()" [disabled]="loading || ticketForm.disabled" lpFormSubmitValidation
        [form]="ticketForm">Create Batch</button>
    </div>
  </div>
</div>
