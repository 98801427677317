<div [loadingOverlay]="isLoading">
	<div class="row">
		<ng-container *ngIf="reportingSite">
			<div class="col-md-12">
				<div class="form-group">
					<label class="control-label">Hidden Elements</label>
					<textarea [ngModel]="reportingSite.HiddenContent()" (ngModelChange)="reportingSite.HiddenContent($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" rows="6"></textarea>
					<p class="text-start help-block">Comma separated list of elements to hide</p>
				</div>
				<div class="form-group">
					<label class="control-label">Custom CSS</label>
					<textarea [ngModel]="reportingSite.Css()" (ngModelChange)="reportingSite.Css($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" rows="6"></textarea>
				</div>
				<div>
					<ng-container *ngFor="let $data of reportingSite.ChartColors()">
						<div class="form-group">
							<label class="control-label">
								Chart Color
								<span>{{$data.Index()}}</span>
								.
							</label>
							<input [dataValidation]="{ placement: 'top', value: $data.Color }" [ngModel]="$data.Color()" (ngModelChange)="$data.Color($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
						</div>
					</ng-container>
				</div>
				<div class="form-group">
					<label class="control-label">Loading Icon</label>
					<div>
						<button (click)="uploadFile()" class="btn btn-primary btn-sm" data-logotype="jpg">
							<i class="fa fa-upload"></i>
							Upload File
						</button>
						<img [hidden]="!reportingSite.LoadingIconUrl()" [attr.src]="reportingSite.LoadingIconUrl()" alt="Loading Icon" class="img-rounded" height="100" width="100"/>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="row">
		<div class="col-md-12">
			<button (click)="save()" class="btn btn-success">
				<i class="fa fa-save"></i>
				Save
			</button>
		</div>
	</div>
</div>
