import { Component } from '@angular/core';
import { LazyTabComponent } from '../lazy-tab/lazy-tab.component';
import { TabBase } from '../tab-base';
/**
 * @Component DynamicTabComponent
 * @Remarks Extended TabComponent that will initialize the tab content when selected, and deinitialize when deselected. Requires *tabContent directive to be present on the content.
 */
@Component({
  selector: 'app-dynamic-tab',
  templateUrl: './dynamic-tab.component.html',
  providers: [{provide: TabBase, useExisting: DynamicTabComponent}]
})
export class DynamicTabComponent extends LazyTabComponent {

  constructor() {
    super();
   }

   deselected(): void {
      this.loaded = false;
   }
}
