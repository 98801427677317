import Check from 'framework/Check';
import IDataQuery = framework.data.IDataQuery;
import IODataSettings = framework.data.IODataSettings;
import Utils from '../../../app/shared/utils';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

export default class ODataQuery<T> implements IDataQuery<T> {
    protected settings: IODataSettings = {};
    protected request: ODataRequest;
    protected http: HttpClient;

    constructor(http: HttpClient, request: ODataRequest) {
      this.http = http;
      this.request = request;
    }

    public load(): JQueryPromise<T> {
        let data: any = {};

        if (this.settings.inlineCount === true) {
            data.$count = true
        }

        if (this.settings.skip > 0) {
            data.$skip = this.settings.skip;
        }

        if (this.settings.top > 0) {
            data.$top = this.settings.top;
        }

        if (this.settings.orderBy) {
            data.$orderby = this.settings.orderBy;
        }

        // todo: filtering needs some work so it's not just a string
        if (this.settings.filter) {
            data.$filter = this.settings.filter;
        }

        this.addOptionsToRequest(data);
        return Utils.wrapDfd(this.createHttpRequest<T>());
    }

    public count(): ODataQuery<T> {
        this.settings.inlineCount = true;
        return this;
    }

    public skip(skip: number): ODataQuery<T> {
        Check.isTrue(_.isNumber(skip) && skip >= 0, 'skip must be >= 0');
        this.settings.skip = Math.max(0, skip);
        return this;
    }

    public top(top: number): ODataQuery<T> {
        Check.isTrue(_.isNumber(top) && top > 0, 'take must be > 0');
        this.settings.top = Math.max(1, top);
        return this;
    }

    public page(page: number, pageSize: number): ODataQuery<T> {
        Check.isTrue(_.isNumber(page) && page > 0, 'page must be > 0');
        Check.isTrue(_.isNumber(pageSize) && pageSize > 0, 'pageSize must be > 0');

        this.skip((page - 1) * pageSize);
        this.top(pageSize);
        this.count();
        return this;
    }

    public orderBy(spec: string): ODataQuery<T> {
        spec = (spec || '').trim();
        this.settings.orderBy = spec ? spec : null;
        return this;
    }

    /**
     * Sets a simple string filter for now. This needs work.
     * 
     * TODO: type this so it's structured and generic for IDataQuery.
     */
    public filter(filter: string): ODataQuery<T> {
        filter = (filter || '').trim();
        filter = filter ? filter : null;
        this.settings.filter = filter.trim();
        return this;
    }

    private addOptionsToRequest(data: { [key: string]: any }): void {
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
            let value = data[key];
            this.request.parameters.push({ name: key, value: value });
        }
      }
    }

    private createHttpRequest<T>(): Observable<T> {
      let params = new HttpParams();
      this.request.parameters.forEach(param => {
        params = params.set(param.name, param.value);
      });
    
      return this.http.get<T>(this.request.url, { params: params });
    }
}

interface ODataRequest {
  parameters: ODataParameter[],
  url: string;
}

interface ODataParameter {
  name: string;
  value: string;
}
