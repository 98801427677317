enum PaymentOptions {
  AmericanExpress = 1,
  Cash = 2,
  Check = 3,
  DinersClub = 4,
  Discover = 5,
  Financing = 6,
  GoogleCheckout = 7,
  Invoice = 8,
  MasterCard = 9,
  Paypal = 10,
  TravelersCheck = 11,
  Visa = 12
}
export default PaymentOptions;

