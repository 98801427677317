<app-components-specialist-internal-tasks-qa-action-information
  [internalAction]="internalAction"></app-components-specialist-internal-tasks-qa-action-information>
<div id="internal-task-qa-criteria-float-panel" class="float-panel">
  <div class="float-panel-tab">
    <button class="float-panel-btn-expand btn btn-lg btn-primary">
      <i class="fa fa-check"></i>
    </button>
  </div>
  <div class="float-panel-main card">
    <div class="float-panel-draggable card-header">
      <i class="fa fa-check"></i>
      Profile Submission Quality
      <i class="float-panel-btn-collapse fa fa-minus float-end"></i>
    </div>
    <div class="card-body" style="overflow-y: scroll; max-height: 550px;">
      <div class="row">
        <div class="col-md-12">
          <ul class="list-group">
            <li class="list-group-item">
              <a [externalLink]="{ url: rubricUrl, text: 'Rubric' }"></a>
            </li>
          </ul>
        </div>
      </div>
      <ng-container *ngIf="!!taskType">
        <app-components-qa-grading-area-panel [taskType]="taskType"
          [qaCriteriaGroups]="qaCriteriaGroups" #gradingArea>
        </app-components-qa-grading-area-panel>
      </ng-container>
    </div>
    <div class="card-footer">
      <div class="text-end">
        <button (click)="close.next()" class="btn btn-primary">Close</button>
        <button (click)="unlock.next()" class="btn btn-info">
          <i class="fa fa-unlock"></i>
          Unlock &amp; Unassign
        </button>
        <button (click)="complete.next()" class="btn btn-success">
          <i class="fa fa-check"></i>
          Complete
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <ul class="list-group">
      <li class="list-group-item">
        <strong>Target URL</strong>
        <a [externalLink]="{ url: targetUrl, text: targetUrl }" class="float-end"></a>
      </li>
      <li class="list-group-item">
        <strong>Anchor Text</strong>
        <span class="float-end">{{anchorText}}</span>
      </li>
    </ul>
  </div>
  <div class="col-md-6">
    <ul class="list-group">
      <li class="list-group-item">
        <strong>Publish URL</strong>
        <a [externalLink]="{ url: publishUrl, text: publishUrl }" class="float-end"></a>
      </li>
      <li class="list-group-item">
        <strong>Publish Date</strong>
        <span class="float-end">{{publishDate | dateText }}</span>
      </li>
      <li class="list-group-item">
        <strong>Site DA</strong>
        <span class="float-end">{{siteDomainAuthority}}</span>
      </li>
    </ul>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="form-horizontal">
      <div class="form-group">
        <label class="control-label col-md-3">Special Considerations:</label>
        <p *ngIf="specialConsiderations" class="col-md-9 form-control-static">{{specialConsiderations}}</p>
        <p *ngIf="!specialConsiderations" class="col-md-9 form-control-static">None</p>
      </div>
      <div class="form-group">
        <label class="control-label col-md-3">Note to QA Specialist:</label>
        <p class="col-md-9 form-control-static">{{note}}</p>
      </div>
      <div class="form-group">
        <label class="control-label col-md-3">Title:</label>
        <p class="col-md-9 form-control-static">{{title}}</p>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-3 control-label">Content:</label>
          <div class="col-md-8">
            <div class="row" style="margin-left: -15px">
              <div class="col-md-8">
                <textarea class="form-control" rows="8" [ngModel]="content" readonly></textarea>
                <div>
                  <span>Word Count: {{wordCount}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>