/* tslint:disable */

import { Component } from '@angular/core';
import { PartnerUserService } from '../../../services/partner-user.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-stats-sales',
	templateUrl: './Sales.component.html'
})
export class SalesComponent {
  public isLoading: any = ko.observable(false);
  // Stats
  public sales: any = ko.observable();
  public salesMTD: any = ko.observable();
  public allTimeSales: any = ko.observable();

  constructor(private partnerUserService: PartnerUserService) { }

  public loadStats(): void {
    this.isLoading(true);
    Utils.wrapDfd(this.partnerUserService.getSalesOverview())
    .then(result => {
      this.sales(result.SalesToday);
      this.salesMTD(result.SalesMonthToDate);
      this.allTimeSales(result.SalesAllTime);
      this.isLoading(false);
    }).fail(displayMessage => toastr.error(displayMessage));
  }

  public activate(): void {
    this.loadStats();
  }
}
