<div *withPermissions="let allow of ['IsLaunchPadAdmin']">
  <div class="card">
    <div class="card-header">
      Manage Users
      <ng-container *ngIf="allow().IsLaunchPadAdmin() || partnerId !== boostabilityPartnerId">
        <button (click)="addUser()" class="btn btn-primary float-end">
          <i class="fa fa-plus"></i>
          Add User
        </button>
      </ng-container>
    </div>
    <div [loadingOverlay]="isLoading" class="card-body">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label class="control-label">Statuses</label>
            <select [(ngModel)]="selectedStatus" (ngModelChange)="filterUsers()" [ngModelOptions]="{ standalone: true }" [disabled]="!searchPerformed"
              class="form-select" name="types" id="statuses">
              <option [ngValue]="null">All Statuses</option>
              <option [ngValue]="true">Active</option>
              <option [ngValue]="false">Inactive</option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="control-label">Access Levels</label>
            <select [(ngModel)]="selectedAccessLevel" (ngModelChange)="filterUsers()" [ngModelOptions]="{ standalone: true }"
              [disabled]="!searchPerformed" class="form-select" name="types" id="accessLevels">
              <option [ngValue]="null">All Access Levels</option>
              <option *ngFor="let option of accessLevels" [ngValue]="option">{{ option.Name }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Permissions</label>
            <select [(ngModel)]="selectedPermission" (ngModelChange)="filterUsers()" [ngModelOptions]="{ standalone: true }"
              [disabled]="!searchPerformed" class="form-select">
              <option [ngValue]="null">All Permissions</option>
              <option *ngFor="let option of permissions" [ngValue]="option">{{ option.DisplayName }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Search</label>
            <input [(ngModel)]="searchString" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
              [returnKey]="searchUsers.bind(this)" type="text" class="form-control" id="manageUsersSearchText"
              name="searchText" placeholder="Search" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="control-label d-block">&nbsp;</label>
            <button [disabled]="isLoading" (click)="searchUsers()" class="btn btn-primary">
              <i class="fa fa-search"></i>
              Search
            </button>
          </div>
        </div>
      </div>
      <app-table [items]="usersFiltered" [exportFilename]="'manage-users'">
        <ng-template #tableHeader>
          <tr>
            <th export sort field="UserId">User ID</th>
            <th export sort field="Username">Username</th>
            <th export sort field="FirstName">First Name</th>
            <th export sort field="LastName">Last Name</th>
            <th export sort field="PhoneExtension">Phone Extension</th>
            <th export sort field="IsActive">Status</th>
            <th export sort field="AccessLevels">Access Levels</th>
            <th>Edit User</th>
          </tr>
        </ng-template>
        <ng-template #tableBody let-user>
          <tr>
            <td>{{ user.UserId }}</td>
            <td>{{ user.Username }}</td>
            <td>{{ user.FirstName }}</td>
            <td>{{ user.LastName }}</td>
            <td>{{ user.PhoneExtension }}</td>
            <td>{{ user.IsActive ? 'Active' : 'Not Active' }}</td>
            <td>{{ user.AccessLevels }}</td>
            <td>
              <button (click)="editUser(user)" class="btn btn-primary">
                <i class="fa fa-edit"></i>Edit
              </button>
            </td>
          </tr>
        </ng-template>
      </app-table>
      <hr />
      <div class="row">
        <div class="col-md-7"></div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Organizational Team</label>
            <select [(ngModel)]="selectedOrganizationalTeam" [ngModelOptions]="{standalone: true}" class="form-select">
              <option *ngFor="let team of activeTeams" [ngValue]="team">{{ team.DisplayName }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="control-label d-block">&nbsp;</label>
            <button [disabled]="isLoading" (click)="searchForOrganizationalTeam()" class="btn btn-primary">
              <i class="fa fa-search"></i>
              Search
            </button>
          </div>
        </div>
      </div>
      <app-table [items]="organizationalTeamUsersFiltered" [exportFilename]="'manage-users-organizational-teams'">
        <ng-template #tableHeader>
          <tr>
            <th export sort field="UserId">User ID</th>
            <th export sort field="Username">Username</th>
            <th export sort field="FirstName">First Name</th>
            <th export sort field="LastName">Last Name</th>
            <th export sort field="PhoneExtension">Phone Extension</th>
            <th export sort field="IsActive">Status</th>
            <th export sort field="AccessLevels">Access Levels</th>
            <th>Edit User</th>
          </tr>
        </ng-template>
        <ng-template #tableBody let-user>
          <tr>
            <td>{{ user.UserId }}</td>
            <td>{{ user.Username }}</td>
            <td>{{ user.FirstName }}</td>
            <td>{{ user.LastName }}</td>
            <td>{{ user.PhoneExtension }}</td>
            <td>{{ user.IsActive ? 'Active' : 'Not Active' }}</td>
            <td>{{ user.AccessLevels }}</td>
            <td>
              <button (click)="editUser(user)" class="btn btn-primary">
                <i class="fa fa-edit"></i>Edit
              </button>
            </td>
          </tr>
        </ng-template>
      </app-table>
    </div>
  </div>
</div>