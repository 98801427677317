import { Component } from '@angular/core';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import {UntypedFormControl, Validators } from '@angular/forms';
import WorkRequestHelper from 'app/managecustomer/WorkRequestHelper';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-managecustomer-googleanalyticscustomaction',
  templateUrl: './GoogleAnalyticsCustomAction.component.html',
})
export class GoogleAnalyticsCustomActionComponent {
  public googleTags: Boo.ApisExternal.Google.Analytics.GoogleTagDetail[];
  public user: Boo.Objects.User;
  public isAuthorized: boolean;
  public shouldCreateAnalytics: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  public selectedGoogleTag: UntypedFormControl = new UntypedFormControl(null, Validators.required);

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private sessionStorageService: SessionStorageService) { }

  activate(options: any): any {
    if (options.controlViewModel) {
      options.controlViewModel(this);
    }

    if (!options.isNew) {
      this.shouldCreateAnalytics.setValue(WorkRequestHelper.getCreateGoogleAnalytics(options.workRequestDetail.WorkRequest));
      this.selectedGoogleTag.setValue(WorkRequestHelper.getGoogleAnalyticsTag(options.workRequestDetail.WorkRequest));
    }

    return Utils.wrapDfd(this.googleAnalyticsService.checkAuthorization(options.workRequestDetail.WorkRequest.CustomerId))
      .then((isAuthorized: boolean) => {
        this.isAuthorized = isAuthorized;

        if (this.isAuthorized) {
          return $.when<any>(Utils.wrapDfd(this.googleAnalyticsService.getGoogleTags(options.workRequestDetail.WorkRequest.CustomerId)), Utils.wrapDfd(this.sessionStorageService.getUser()))
        } else {
          return $.when<any>([], Utils.wrapDfd(this.sessionStorageService.getUser()));
        }
      })
      .then((googleTags: Boo.ApisExternal.Google.Analytics.GoogleTagDetail[], user: Boo.Objects.User) => {
        this.googleTags = googleTags;
        this.user = user;

        if (options.isNew && this.googleTags.length === 1) {
          this.selectedGoogleTag.setValue(this.googleTags[0].GoogleTag);
        }
      }).fail((displayMessage) => {
        toastr.error(displayMessage);
      });
  }

  canSave(): boolean {
    if (!this.shouldCreateAnalytics.valid) {
      toastr.error('Please select whether or not to create a new Google Analytics account.');
      return false;
    }

    if (this.shouldCreateAnalytics.value === true) {
      return true;
    }

    if (!this.isAuthorized) {
      toastr.error('Google analytics must be connected for this customer.');
      return false;
    }

    if (this.googleTags.length === 0) {
      toastr.error('A data stream needs to be created for data to be collected.');
      return false;
    }

    this.selectedGoogleTag.markAsTouched();
    return this.selectedGoogleTag.valid;
  }

  save(workRequest: Boo.Objects.Work.WorkRequest): any {
    WorkRequestHelper.setCreateGoogleAnalytics(workRequest, this.shouldCreateAnalytics.value, this.user.UserId);

    if (this.shouldCreateAnalytics.value === false) {
      WorkRequestHelper.setGoogleAnalyticsTag(workRequest, this.selectedGoogleTag.value, this.user.UserId);
    }
  }
}
