<div class="form-group row">
	<span class="col-md-3 text-end col-form-label" style="line-height: 2em;">
		<span>{{title}}</span>
		:
	</span>
	<div class="col-sm-9 mt-1">
		<div [dataValidation]="{ placement: 'right', value: value }" class="btn-group">
			<button (click)="toggle(left)" [class.active]="value() === left" type="button" class="btn btn-outline-secondary">{{left}}</button>
			<button (click)="toggle(right)" [class.active]="value() === right" type="button" class="btn btn-outline-secondary">{{right}}</button>
		</div>
	</div>
</div>
