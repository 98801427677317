import { Component, Input } from '@angular/core';
import FlagTypes = Boo.Objects.Work.SeoMinute.Enums.FlagTypes;

@Component({
  selector: 'app-components-managecustomer-allocatework-tasktypeflag',
  templateUrl: './TaskTypeFlag.component.html',
  styleUrls: ['../../AllocateWork.component.scss']
})
export class TaskTypeFlagComponent {
  @Input() flag: Boo.Objects.Work.SeoMinute.TaskTypeFlag;

  isUnavailableFlag(): boolean {
    return this.flag.Type === FlagTypes.Unavailable;
  }

  isWarningFlag(): boolean {
    return this.flag.Type === FlagTypes.Warning;
  }

  isInformationFlag(): boolean {
    return this.flag.Type === FlagTypes.Information;
  }
}
