import { Component, Input } from '@angular/core';
import { KeywordSuggestion, KeywordSuggestionSources, PageSuggestion } from '../../../../shared/models/keywords/keywords';

@Component({
  selector: 'app-components-shared-keyword-research-keyword',
  templateUrl: './keyword.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class KeywordComponent {
  @Input() pageSuggestion: PageSuggestion;
  @Input() keywordSuggestion: KeywordSuggestion;

  unknownRank: number = 101;
  keywordSuggestionSources_OpenAI = KeywordSuggestionSources.OpenAI;

  removeKeyword() {
    if (this.keywordSuggestion.SourcePageSuggestionId) {
      this.keywordSuggestion.IsSelected = false;
    } else {
      this.pageSuggestion.KeywordSuggestions.splice(this.pageSuggestion.KeywordSuggestions.indexOf(this.keywordSuggestion), 1);
    }
  }
}
