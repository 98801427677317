<div *withPermissions="let allow of ['CanUpdateAdvancedSettings', 'CanEditActionConfiguration', 'CanResetSeoMinutes']"
  class="card">
  <div class="card-header">
    <span>Allocate Work</span>
    <button [hidden]="!allow().CanResetSeoMinutes()" (click)="resetSeoMinutes()"
      class="float-end btn btn-sm btn-primary">Reset Minutes</button>
  </div>
  <div [loadingOverlay]="isLoading()" class="card-body" style="min-height:200px;">
    <div *ngIf="seoWorkSettings && seoWorkSettings.UnresolvedWorkOrderBlocks.length > 0" class="alert alert-warning" role="alert">
      <strong>
        <i class="fas fa-exclamation-triangle"></i>
      </strong>
      This customer has the following issues that are preventing work from being created:
      <ul>
        <li *ngFor="let $workOrderBlock of seoWorkSettings.UnresolvedWorkOrderBlocks"> {{ $workOrderBlock }}</li>
      </ul>
      Please contact <a href="mailto:ops@boostability.com">ops&#64;boostability.com</a> if you are unsure how to resolve the Work Order Validation Issue{{ seoWorkSettings.UnresolvedWorkOrderBlocks.length > 1 ? '(s)' : '' }}.
      <div>
        <button class="btn btn-info mt-3" (click)="checkWorkOrderBlocks()" [disabled]="checkingBlocks">Recheck Validation Issues</button>
      </div>
    </div>
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (activeIdChange)="activeScreenChange($event)" class="nav-tabs">
      <li [ngbNavItem]="pastPeriodsScreenId">
        <a ngbNavLink>Past</a>
        <ng-template ngbNavContent>
          <app-components-managecustomer-pastperiods
            [customer]="customer()"
            [seoWorkSettings]="seoWorkSettings"
            (refreshSettings)="refreshCurrentMinutes()"
            (editWorkRequest)="editWorkRequest()">
          </app-components-managecustomer-pastperiods>
        </ng-template>
      </li>
      <li *ngFor="let period of activeBillingPeriods" [ngbNavItem]="period.index">
        <a ngbNavLink [ngClass]="{'alert-danger': screenHasErrors(period.index)}">
          {{ period.index === 0 ? 'Current' : period.displayDate }}
        </a>
        <ng-template ngbNavContent>
          <app-components-managecustomer-workperiods
            [customer]="customer()"
            [userId]="partnerUser().UserId"
            [selectedPeriod]="period"
            [screenErrors]="filterScreenErrors(period.index)"
            [seoWorkSettings]="seoWorkSettings"
            [taskTypeWorkGroupings]="filterGroupsByPeriod(period)"
            [reservedMinutes]="filterReserveMinutesByPeriod(period)"
            (removeGroup)="removeGroup($event)"
            (addGroup)="addGroup($event)"
            (editWorkRequest)="editWorkRequest()"
            (clearPeriod)="clearPeriod(period)"
            (deletePeriod)="deletePeriod(deletePeriodModal, period)"
            (validatePeriod)="validateScreen(period.index)"
            (refresh)="reloadCustomer()">
          </app-components-managecustomer-workperiods>
        </ng-template>
      </li>
      <li [ngbNavItem]>
        <a ngbNavLink><i class="fas fa-plus"></i></a>
        <ng-template ngbNavContent>
          <h3 class="mb-2 mt-2">Choose Order Date</h3>
          <div class="row no-gutters mb-4">
            <div class="col-md-4">
              <ng-select [items]="inactiveBillingPeriods" [multiple]="false" [closeOnSelect]="true"
                [clearable]="false" placeholder="Order Date" [(ngModel)]="selectedNewBillingPeriod" bindLabel="displayDate">
              </ng-select>


            </div>
            <div class="col-md-3">
              <button (click)="addBillingPeriod()" class="btn btn-primary" [disabled]="!selectedNewBillingPeriod"><i
                  class="fa fa-plus"></i>Add</button>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="defaultAllocationScreenId" class="ms-auto">
        <a ngbNavLink [ngClass]="{'alert-danger': screenHasErrors(defaultAllocationScreenId)}">Customer Default Allocation</a>
        <ng-template ngbNavContent>
          <app-components-managecustomer-workdefaults
            [customer]="customer()"
            [(defaults)]="defaults"
            [screenErrors]="filterScreenErrors(defaultAllocationScreenId)"
            [seoWorkSettings]="seoWorkSettings"
            (validateDefaults)="validateScreen(defaultAllocationScreenId)"
            (refresh)="reloadCustomer()"
            (defaultsChange)="defaultsChange($event)">
          </app-components-managecustomer-workdefaults>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>

    <div [hidden]="!allow().CanUpdateAdvancedSettings()">
      <div class="form-group">
        <label class="control-label">Configuration Change Note</label>
        <textarea [dataValidation]="{ placement:'top', value: configurationChangeNote }" [ngModel]="configurationChangeNote()" (ngModelChange)="configurationChangeNote($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" rows="5"></textarea>
      </div>
      <div class="card card-body bg-light d-block text-end">
        <button [disabled]="isLoading()" (click)="save()" class="btn btn-success">
          <i class="fa fa-save"></i>
          Update Configuration
        </button>
      </div>
    </div>
  </div>
</div>


<ng-template #deletePeriodModal let-modal>
  <div class="modal-content" *ngIf="selectedPeriodToDelete">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Confirm Delete</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
      Are you sure you want to delete the allocation for:
      {{ selectedPeriodToDelete.displayDate }}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="modal.close()">Delete</button>
    </div>
  </div>
</ng-template>
