<div class="card">
	<div class="card-header">
		Select Template
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body">
		<ng-container *ngFor="let $data of grid().rows">
			<div class="row animated fadeIn">
				<ng-container *ngFor="let $data2 of $data">
					<div class="col-sm-3">
						<div [class.active]="isCurrentTemplate($data)" class="thumbnail">
							<div [ngbPopover]="$data2.Description" [popoverTitle]="null" placement="top auto" triggers="mouseenter:mouseleave" class="caption text-center small">
								<strong>
									<span>{{$data2.Id}}</span>
									–
									<span>{{$data2.Name || 'Untitled'}}</span>
								</strong>
							</div>
							<ng-container *ngIf="areThumbnailsEnabled()">
								<img [attr.src]="$data2.ThumbnailUrl ? $data2.ThumbnailUrl : '/Images/placeholder-332x211.png'" class="img-rounded" style="height:180px;"/>
							</ng-container>
							<div class="caption text-end">
								<a [hidden]="!($data2.PreviewUrl)" [attr.href]="$data2.PreviewUrl" target="_blank" class="btn btn-outline-secondary btn-sm float-start">Preview</a>
								<button (click)="selectTemplate($data2)" class="btn btn-primary btn-sm">Select</button>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</ng-container>
		<div style="margin:5px auto 10px auto;">
			<compose Selector="app-components-pager" [ActivateOptions]="pagerOptions"></compose>
		</div>
		<div class="card card-body bg-light d-block text-end">
			<button (click)="close()" class="btn btn-primary">Close</button>
		</div>
	</div>
</div>
