<div [loadingOverlay]="isLoading()">
	<div class="card">
		<div class="card-header">
			New Custom Report
			<button (click)="close()" class="btn-close" title="Close"></button>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-md-4 form-group">
					<label class="control-label">Name</label>
					<input [dataValidation]="{ value: name, placement: 'top' }" [ngModel]="name()" (ngModelChange)="name($event)" [ngModelOptions]="{ standalone: true }" class="form-control"/>
				</div>
				<div class="col-md-2 form-group">
					<label class="control-label">Month</label>
					<select [ngModel]="month()" (ngModelChange)="month($event)" [ngModelOptions]="{ standalone: true }" class="form-select">
						<option>1</option>
						<option>2</option>
						<option>3</option>
						<option>4</option>
						<option>5</option>
						<option>6</option>
						<option>7</option>
						<option>8</option>
						<option>9</option>
						<option>10</option>
						<option>11</option>
						<option>12</option>
					</select>
				</div>
				<div class="col-md-2 form-group">
					<label class="control-label">Year</label>
					<select [ngModel]="year()" (ngModelChange)="year($event)" [ngModelOptions]="{ standalone: true }" class="form-select">
						<option *ngFor="let option of availableYears()" [ngValue]="option">{{ option }}</option>
					</select>
				</div>
				<div class="col-md-4 form-group">
					<label class="control-label">File</label>
					<div class="input-group">
						<input [ngModel]="filename()" (ngModelChange)="filename($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" disabled=""/>
						<span class="btn btn-primary btn-file">
							Select a File
							<input [dataValidation]="{ placement: 'top', value: file }" [file]="{ data: file, accept: '.xls, .xla, .xlt, .xlsx, .csv, .pdf, .doc, .docx, .xml, .txt' }" type="file" class="form-control js-fileapi-wrapper float-end"/>
						</span>
					</div>
				</div>
			</div>
			<div class="row"></div>
			<div class="card card-body bg-light d-block text-end">
				<button (click)="close()" class="btn btn-link">Cancel</button>
				<button (click)="save()" class="btn btn-success" data-accept="true">
					<i class="fas fa-cloud-upload-alt"></i>
					Upload Custom Report
				</button>
			</div>
		</div>
	</div>
</div>
