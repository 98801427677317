<div class="row" style="margin: 10px;">
  <div class="col-md-12" style="margin-bottom:10px;">
    <div class="text-end">
      <button (click)="loadFiles()" class="btn btn-primary">
        <i class="fas fa-sync"></i>
        Refresh
      </button>
      <button (click)="upload()" class="btn btn-primary">
        <i class="fa fa-plus"></i>
        Upload File
      </button>
    </div>
  </div>
  <div class="col-md-12">
    <app-table [items]="files" [exportFilename]="'partner-files'">
      <ng-template #tableHeader>
        <tr>
          <th export sort field="Name">Name</th>
          <th export sort field="InsertedDate">Upload Date</th>
          <th export field="S3Url"></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-file>
        <tr>
          <td>
            <div class="col-md-10">{{file.Name}}</div>
          </td>
          <td>{{file.InsertedDate | dateText }}</td>
          <td>
            <a [externalLink]="{ url: file.S3Url, text:'View File'}" class="btn btn-primary"></a>
          </td>
          <td>
            <a (click)="delete(file)" class="btn btn-danger">
              <i class="far fa-trash-alt"></i>
              Delete
            </a>
          </td>
        </tr>
      </ng-template>
    </app-table>
  </div>
</div>