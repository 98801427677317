import { Component } from '@angular/core';
import System from 'framework/System';
import permissions from 'app/models/Permissions';
import { CustomerService } from '../../../services/customer.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-managecustomer-customersynopsis',
	templateUrl: './CustomerSynopsis.component.html'
})
export class CustomerSynopsisComponent implements IActivatable {
    public customer: CustomerObservable;
    public customerSynopsis: KnockoutObservable<string>;
    public triggerReloadTickets: () => JQueryPromise<any>;
    public triggerRefreshCustomer: () => void; 
    public canUpdateSynopsis: KnockoutObservable<boolean>;
    private notesAndTicketsPublicApi: app.managecustomer.interfaces.INotesAndTicketsPublicApi;

    constructor(private customerService: CustomerService) { }

    public activate(params: app.managecustomer.components.interfaces.ICustomerSynopsisActivateParams): JQueryPromise<any> {
        this.customer = params.customer;
        this.customerSynopsis = ko.observable(this.customer.Synopsis());
        this.notesAndTicketsPublicApi = params.notesAndTicketsPublicApi;

        return System.emptyPromise();
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        this.canUpdateSynopsis = ko.observable(launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanUpdateSynopsis, user));
        return true;
    }

    public save(): JQueryPromise<void> {
        this.customerSynopsis($.trim(this.customerSynopsis()));
        this.customer.Synopsis(this.customerSynopsis());

        return Utils.wrapDfd(this.customerService.save(ko.mapping.toJS(this.customer)))
            .then(() => {
                toastr.success('Sucessfully updated Synopsis.');
                this.notesAndTicketsPublicApi.refreshCustomer();
            });
    }
}
