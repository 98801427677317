import { Directive, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ViewLocatorService } from '../../services/view-locator.service';

@Directive({
  selector: '[navigationHandler]'
})
export class NavigationHandlerDirective implements AfterViewInit, OnDestroy {
  private element: HTMLElement;
  private urlChangedHandler: (newHash: string) => void = (newHash: string) => {
    this.urlUpdated(newHash);
  };

  constructor(
    elementRef: ElementRef, 
    private viewLocatorService: ViewLocatorService) {
    this.element = elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    let viewLocatorService = this.viewLocatorService;

    $.each(this.element.children,
      function () {
        const isCurrentPage = viewLocatorService.hashesMatch($(this).children('a').attr('href'), viewLocatorService.getRoute());
        if (isCurrentPage) {
          $(this).addClass('active');
        }
      });

    amplify.subscribe(window.launchpad.config.events.urlChanged, this.urlChangedHandler);
  }

  ngOnDestroy(): void {
    amplify.unsubscribe(window.launchpad.config.events.urlChanged, this.urlChangedHandler);
  }

  private urlUpdated(newHash: any): void {
    let viewLocatorService = this.viewLocatorService;
    $.each(this.element.children,
      function () {
        const isCurrentPage = viewLocatorService.hashesMatch($(this).children('a').attr('href'), newHash);
        if (isCurrentPage) {
          $(this).children('a').addClass('active');
        } else {
          $(this).children('a').removeClass('active');
        }
      });
  }
}