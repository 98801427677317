import { Subject } from "rxjs";

export interface SavableWorkspace {
  isReadyToSave(): boolean;
  showSaveButton?: boolean;
  save?: Subject<void>;
}

export function isSavable(workspace: SavableWorkspace): workspace is SavableWorkspace {
  return workspace.isReadyToSave !== undefined;
}