<ng-container *ngIf="activeHaloKeywordCount === 0; else showHaloKeywords">
  <div class="row">
    <div class="col-md-12">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              Halo Keywords
              <i *ngIf="readOnlyReasons" class="fas fa-lock text-danger ms-2" triggers="mouseenter:mouseleave" [ngbPopover]="readOnlyReasons"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>No halo keywords have been defined yet</td>
          </tr>
          <tr>
            <td>
              <div class="row">
                <div class="col-auto">
                  <app-components-shared-add-keyword
                    [customer]="customer"
                    [websiteUrls]="websiteUrls"
                    [haloKeywords]="haloKeywords"
                    [archivedKeywords]="archivedKeywords"
                    [restrictions]="restrictions">
                  </app-components-shared-add-keyword>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<ng-template #showHaloKeywords>
  <div class="row">
    <div class="col-md-12">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              Halo Keywords
              <i *ngIf="readOnlyReasons" class="fas fa-lock text-danger ms-2" triggers="mouseenter:mouseleave" [ngbPopover]="readOnlyReasons"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let haloKeyword of haloKeywords">
            <ng-container *ngIf="haloKeyword.IsActive">
              <tr>
                <td>
                  <app-components-shared-halo-keyword
                    [customer]="customer"
                    [haloKeyword]="haloKeyword"
                    [websiteUrls]="websiteUrls"
                    [haloKeywords]="haloKeywords"
                    [archivedKeywords]="archivedKeywords"
                    [restrictions]="restrictions">
                  </app-components-shared-halo-keyword>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <tr>
            <td>
              <div class="row">
                <div class="col-auto">
                  <app-components-shared-add-keyword
                    [customer]="customer"
                    [websiteUrls]="websiteUrls"
                    [haloKeywords]="haloKeywords"
                    [archivedKeywords]="archivedKeywords"
                    [restrictions]="restrictions">
                  </app-components-shared-add-keyword>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
