import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { QaGradingArea } from '../../../../qa/qa-grading-area/qa-grading-area.component';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';
import { InternalActionToken } from '../../internal-action.token';
import { Subject } from 'rxjs';
import QaCriteriaGroup = Boo.Qa.QaCriteriaGroup;

@Component({
  selector: 'app-components-specialist-internal-tasks-premium-backlink-qa',
  templateUrl: './premium-backlink-qa.component.html'
})
export class PremiumBacklinkQaComponent implements OnInit, CompletableWorkspace, UnlockableWorkspace, ClosableWorkspace {
  showCompleteButton: boolean = false;
  showUnlockButton: boolean = true;
  showCloseButton: boolean = true;
  complete = new Subject<void>();
  unlock = new Subject<void>();
  close = new Subject<void>();

  @ViewChild('gradingArea')
  gradingArea: QaGradingArea;
  taskType: Boo.Objects.Enums.TaskTypeEnum;
  qaCriteriaGroups: QaCriteriaGroup[] = [];

  rubricUrl: string = 'https://docs.google.com/spreadsheets/d/1uaL4xDfkv8iQOrrH8lYdrwgVfDBe0sNLuzssUSV0GN4/edit?usp=sharing';

  performanceReportedCorrectlyLabel: string = 'Reported Correctly';
  performanceBlogPublisherQualityLabel: string = 'Blog/Publisher Quality';
  performanceClientLinkLabel: string = 'Client Link';
  relevancyPublisherLabel: string = 'Publisher';
  relevancyArticleParagraphLabel: string = 'Article/Paragraph';
  relevancyTitleLabel: string = 'Title';
  contentNegativityLabel: string = 'Negativity';
  contentFillerContentLabel: string = 'Filler Content';
  contentFormattingFlowLabel: string = 'Formatting/Flow';
  contentGrammarPunctuationTyposLabel: string = 'Grammar/Punctuation/Typos';
  contentReviewsAuthorityLabel: string = 'Reviews/Authority';

  gradeComment: string = '';

  targetUrl: string;
  anchorText: string;
  language: string;

  publishUrl: string;
  publishDate: string;
  siteDomainAuthority: number;

  specialConsiderations: string;
  note: string;
  title: string;
  content: string;
  wordCount: number;

  constructor(@Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction) { }

  ngOnInit() {
    //Create the criteria float panel
    (<any>$('#internal-task-qa-criteria-float-panel')).floatPanel({ collapsed: false });

    this.qaCriteriaGroups = this.internalAction.InternalTaskDetails.QaCriteriaGroups;
    this.taskType = this.internalAction.InternalTask.TaskTypeId;

    this.targetUrl = this.internalAction.InternalTaskDetails.PremiumBacklinkUpload.TargetUrl;
    this.anchorText = this.internalAction.InternalTaskDetails.PremiumBacklinkUpload.AnchorText;
    this.language = this.internalAction.InternalTaskDetails.Language.Name;

    this.publishUrl = this.internalAction.InternalTaskDetails.PremiumBacklinkUpload.TaskActionUrl;
    this.publishDate = window.launchpad.utils.getMomentAsUTC(this.internalAction.InternalTaskDetails.PremiumBacklinkUpload.PublishDate).format('MM/DD/YYYY');
    this.siteDomainAuthority = this.internalAction.InternalTaskDetails.PremiumBacklinkUpload.SiteDomainAuthority;

    this.specialConsiderations = this.internalAction.InternalTaskDetails.AssociatedTaskCustomer.Considerations;
    this.note = this.internalAction.InternalTaskDetails.PremiumBacklinkUpload.Note;
    this.title = this.internalAction.InternalTaskDetails.PremiumBacklinkUpload.Title;
    this.content = this.internalAction.InternalTaskDetails.PremiumBacklinkUpload.Content;
    this.wordCount = window.launchpad.wordCount(this.content);
  }
  
  isReadyToComplete(): boolean {
    if (!this.gradingArea.validate()) {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return false;
    }

    this.internalAction.InternalTaskDetails.QaCriteriaDetails = this.gradingArea.getDetails();
    this.internalAction.InternalTaskDetails.QaGrade.Comment = this.gradingArea.gradeComment;
    return true;
  }
}