<div class="row">
  <div class="col-md-4">
    <div class="card" style="margin-top: 2.65em;">
      <div class="card-header">Important Dates</div>
      <div class="card-body">
        <form>
          <ng-container *ngIf="isLandingPageLaunched">
            <div class="form-group">
              <label>Landing Page Launched</label>
              <p class="form-control-static">{{landingPageLaunched | dateText }}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="isPausedLaunch">
            <div class="form-group">
              <label>Site Paused-Launched</label>
              <p class="form-control-static">{{sitePausedLaunched | dateText }}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="isSiteLaunched">
            <div class="form-group">
              <label>Site Launched</label>
              <p class="form-control-static">{{siteLaunched | dateText }}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="!(isPausedLaunch || isLandingPageLaunched || isSiteLaunched)">
            <span>-</span>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
  <div class="col-md-8">
    <app-table [items]="summaries" [pageSize]="100" [exportFilename]="'website-summaries'">
      <ng-template #tableHeader>
        <tr>
          <th export sort field="Name">Name</th>
          <th export sort asc field="StartDate">Start Date</th>
          <th export sort field="CompletedDate">Completed Date</th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-website>
        <tr>
          <td>{{website.Name}}</td>
          <td>{{website.StartDate | dateText: 'M/DD/YYYY h:mm a' }}</td>
          <td>
            <span>{{website.CompletedDate | dateText: 'M/DD/YYYY h:mm a' }}</span>
          </td>
        </tr>
      </ng-template>
    </app-table>
  </div>
</div>