import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import ISimplePageResult = framework.data.ISimplePageResult;

@Injectable({
  providedIn: 'root'
})

export class CustomerTodoListService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/customertodolist`;

  constructor(private http: HttpClient) { }

  get(customerId: number, type: number): Observable<Boo.Objects.Todo.CustomerTodoList> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('type', type);

    return this.http.get<Boo.Objects.Todo.CustomerTodoList>(this.baseUrl, { params: params });
  }

  getHistory(customerId: number, type: number, skip: number, take: number): Observable<ISimplePageResult<Boo.Objects.Todo.CustomerTodoHistory>> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('type', type)
      .set('skip', skip)
      .set('take', take);

    return this.http.get<ISimplePageResult<Boo.Objects.Todo.CustomerTodoHistory>>(`${this.baseUrl}/History`, { params: params });
  }

  save(customerTodoList: Boo.Objects.Todo.CustomerTodoList): Observable<Boo.Objects.Todo.CustomerTodoList> {
    return this.http.post<Boo.Objects.Todo.CustomerTodoList>(this.baseUrl, customerTodoList);
  }
}
