import { Component } from '@angular/core';
import BaseSeoContentSurveyTab from '../BaseSeoContentSurveyTab';
import { SeoContentSurveyService } from '../../../../services/seo-content-survey.service';

@Component({
  selector: 'app-components-managecustomer-seocontentsurvey-survey',
  templateUrl: './Survey.component.html'
})
export class SurveyComponent extends BaseSeoContentSurveyTab implements IActivatable {
    surveyUpdateReason: KnockoutObservable<string>;

    private surveyValidation: KnockoutObservable<any>;

    constructor(public seoContentSurveyService: SeoContentSurveyService) {
      super(seoContentSurveyService);
    }

    public activate(params: any): JQueryPromise<void> {
        return super.activate(params)
            .then(() => {
                this.surveyUpdateReason = ko.observable(undefined);

                this.prepareValidation();
            });
    }

    public saveSurvey(): void {
        if (this.surveyValidation().isValid()) {
            super.save(this.surveyUpdateReason(), 'survey', 'Survey saved.')
                .then(() => {
                    this.surveyUpdateReason(undefined);
                    this.surveyValidation.errors.showAllMessages(false);
                });
        } else {
            toastr.error('Please correct the errors in the survey section.');
            this.surveyValidation.errors.showAllMessages();
        }
    }

    private prepareValidation(): void {
        this.seoContentSurvey().BusinessDescription.extend({ maxLength: 4000 });
        this.seoContentSurvey().Limitations.extend({ maxLength: 4000 });
        this.surveyUpdateReason.extend({ required: { message: 'Reason for update is required.' } });

        this.surveyValidation = ko.validatedObservable([
            this.seoContentSurvey().BusinessDescription,
            this.seoContentSurvey().Limitations,
            this.surveyUpdateReason
        ]);
    }
}
