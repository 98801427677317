<div class="row">
	<ng-container *ngIf="internalAction">
		<div class="col-md-6">
			<ng-container *ngIf="internalAction.InternalTaskDetails">
				<ul class="list-group">
					<li class="list-group-item">
						<strong>Task Type</strong>
						<span class="float-end">{{internalAction.InternalTaskDetails.TaskTypeName}}</span>
					</li>
					<li class="list-group-item">
						<strong>Website URL</strong>
						<span class="float-end">{{internalAction.InternalTaskDetails.LinkLocationUrl}}</span>
					</li>
					<li class="list-group-item">
						<strong>Language</strong>
						<span class="float-end">{{internalAction.InternalTaskDetails.Language.Name}}</span>
					</li>
					<li class="list-group-item">
						<strong>Country</strong>
						<span class="float-end">{{internalAction.InternalTaskDetails.Country.Name}}</span>
					</li>
					<li class="list-group-item">
						<strong>Vertical</strong>
						<span class="float-end">{{internalAction.InternalTaskDetails.Vertical.Name}}</span>
					</li>
				</ul>
			</ng-container>
		</div>
		<div class="col-md-6">
			<ng-container *ngIf="internalAction.InternalTaskDetails">
				<ul class="list-group">
					<li class="list-group-item">
						<strong>Partner Name</strong>
						<span class="float-end">{{internalAction.InternalTaskDetails.PartnerName}}</span>
					</li>
					<li class="list-group-item">
						<strong>Created Date</strong>
						<span class="float-end">{{internalAction.InternalTaskDetails.InsertedDate | dateText }}</span>
					</li>
					<li class="list-group-item">
						<ng-container *ngIf="internalAction.InternalTaskDetails.LinkLocationBlogDetail">
							<strong>Blog Theme Id</strong>
							<span class="float-end">{{internalAction.InternalTaskDetails.LinkLocationBlogDetail.BlogThemeId}}</span>
						</ng-container>
					</li>
					<li class="list-group-item">
						<strong>Link Type</strong>
						<span class="float-end">{{internalAction.InternalTaskDetails.LinkLocation.LinkTypeId === 2 ? 'Blog' : 'Article'}}</span>
					</li>
					<li [hidden]="!showPenName" class="list-group-item">
						<ng-container *ngIf="internalAction.InternalTaskDetails.LinkLocationBlogDetail">
							<strong>Pen Name</strong>
							<span class="float-end">{{internalAction.InternalTaskDetails.LinkLocationBlogDetail.PenName}}</span>
						</ng-container>
					</li>
				</ul>
			</ng-container>
		</div>
	</ng-container>
</div>
