/* tslint:disable */

import { Component } from '@angular/core';
import app from 'App';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { TicketService } from '../../../services/ticket.service';
import Utils from '../../../shared/utils';

/**
 * View model to launch the edit ticket priority view from a route that specifies the ticket id.
 * Example route: customerservice/editticket/1234
 */

@Component({
  selector: 'app-components-customerservice-editticket',
  templateUrl: './EditTicket.component.html'
})
export class EditTicketComponent {

  constructor(private ticketService: TicketService) { }

  public activate(options: any): any {
    const ticketId = options ? parseInt(options[0]) : null;
    if (_.isNaN(ticketId) || ticketId <= 0) {
      toastr.error('Invalid ticket id');
      return;
    }

    this.getTicketCustomerIdPromise(ticketId).done((customerId: any) => {
      PriorityViewModel.showTicket(customerId, ticketId)
        .then(null, () => {
          // priority views are "rejected" when they're closed. This will cause CompositionEngine to show an error thinking it couldn't load the route.
          return $.Deferred().resolve();
        });
    });
  }

  public getTicketCustomerIdPromise(ticketId: any): any {
    return $.Deferred((dfd) => {
      Utils.wrapDfd(this.ticketService.get(ticketId))
      .then(ticket => {
        if (!ticket || !ticket.CustomerId) {
          dfd.reject();
          return;
        }
        dfd.resolve(ticket.CustomerId);
      }).fail(() => dfd.reject);
    }).fail(() => {
      toastr.error(`Ticket ${ticketId} not found`);
    }).promise();
  }
}
