import { Component, OnInit } from '@angular/core';
import permissions from 'app/models/Permissions';
import ticket from 'app/models/ticket';
import { AmazonService } from '../../../services/amazon.service';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import { PartnerUserService } from '../../../services/partner-user.service';
import { TicketService } from '../../../services/ticket.service';
import { ticketTypeConfig } from '../../ticket/TicketTypeConfig';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-components-managecustomer-newticket',
  templateUrl: './NewTicket.component.html'
})
export class NewTicketComponent implements OnInit {
  moment: moment.MomentStatic = moment;
  dfd: any;
  title: string = 'New Ticket';
  allTicketTypes: any = ko.observableArray([]);
  parent: any = ko.observable();
  isLoading: any = ko.observable(false);
  customer: any = ko.observable();
  partnerUser: any = ko.observable();
  isFileUploadRequired: any = ko.observable(false);
  hasFileUploadCapability: any = ko.observable(false);
  file: any = ko.observable();
  pageValidation: KnockoutObservable<any>;
  filename: any = ko.computed(() => {
    let name = '';
    if (this.file()) {
      name = this.file().name;
    }
    return name;
  });
  isChangingDatesAllowed: any = ko.observable(false);
  ticket: any = ko.observable(ko.mapping.fromJS(new ticket()));
  estimatedMinutes: any = ko.observableArray([]);
  ticketStartDate: any = ko.observable(moment());
  ticketStartTime: any = ko.observable(moment().add(5, 'minutes'));
  partnerAssignedUsers: any = ko.observableArray([]);
  canCreateAnyTicketType: any = ko.observable(false);
  toggleCustomerInfoCollapsed: any = ko.observable(false);

  startDateTime: any = ko.computed(() => {
    return moment(moment(this.ticketStartDate()).format('L') + ' ' + moment(this.ticketStartTime()).format('hh:mm a'));
  });

  visibleTicketTypes: any = ko.computed(() => {
    let myTickets: any = [];
    if (this.partnerUser() && this.partnerUser().TicketTypes) {
      myTickets = this.partnerUser().TicketTypes;
    }
    if (this.canCreateAnyTicketType()) {
      // Return all ticket types.
      return _.sortBy(_.where(this.allTicketTypes(), { IsUIVisible: true, IsActive: true }), 'Name');
    } else if (myTickets.length > 0) {
      // Return ticket types that I can do.
      return _.sortBy(_.filter(this.allTicketTypes(), (item: any) => item.IsUIVisible && item.IsActive && (_.where(myTickets, { TicketTypeId: item.TicketTypeId }).length > 0 || item.Name === 'General')), 'Name');
    }

    return [];
  });

  ticketTypeSelectionIsAllowed: any = ko.computed(() => {
    let result: boolean | Object;
    // if tickettype is not visible then don't allow user to change
    if (_.isUndefined(this.ticket().TicketTypeId()) || this.ticket().TicketTypeId() <= 0) {
      result = true;
    } else {
      result = _.findWhere(this.visibleTicketTypes(), { TicketTypeId: this.ticket().TicketTypeId() });
    }
    return result;
  });

  changeToEstimatedMinutesIsAllowed: any = ko.computed(() => {
    const ticketType: any = _.findWhere(this.allTicketTypes(), { TicketTypeId: this.ticket().TicketTypeId() });
    return _.isObject(ticketType) && ticketType.CanSetEstimatedMinutes;
  });

  partnerName: any = ko.computed(() => {
    if (this.customer() && this.customer().Partner) {
      return ko.utils.unwrapObservable(this.customer().Partner.Name);
    } else {
      return '';
    }
  });

  ticketTypeId: any = ko.computed(() => {
    return this.ticket().TicketTypeId();
  });

  private ticketStatus: { name: string, value: number }[] = [
    { name: 'New', value: 1 },
    { name: 'InProgress', value: 2 },
    { name: 'Completed', value: 3 }
  ];
  private defaultAssignedUserId: any;

  constructor(
    private amazonService: AmazonService,
    private partnerUserService: PartnerUserService,
    private ticketService: TicketService,
    private sessionStorageService: SessionStorageService) {
  }

  ngOnInit(): void {
    this.ticket().TicketTypeId.extend({
      validation: { validator: (val: number): boolean => { return val > 0; }, message: 'Ticket Type selection is required.' }
    });
    this.ticket().Reason.extend({
      required: { message: 'Reason is a required field.' },
      validation: { validator: window.launchpad.utils.containsNoHtml, message: 'HTML tags are not allowed. You cannot have a "<" followed by a ">".' }
    });
    this.ticket().EstimatedMinutes.extend({
      validation: { validator: this.estimatedMinutesIsValid.bind(this), message: 'Estimated Minutes is a required field.' }
    });
    this.ticket().AssignedUserId.extend({
      required: {
        onlyIf: (): boolean => {
          return ticketTypeConfig.alwaysRequiresAssignedUser.includes(this.ticket().TicketTypeId());
        },
        message: 'Assigned User selection is required.'
      }
    });
    this.ticketStartDate.extend({
      required: { message: 'Next Action Date is a required field.' },
      validation: { validator: this.startDateIsValid.bind(this), message: 'Next Action Date & Time must be in the future.' }
    });

    this.pageValidation = ko.validatedObservable({
      ticketTypeId: this.ticket().TicketTypeId,
      reason: this.ticket().Reason,
      startDate: this.ticketStartDate,
      estimatedMinutes: this.ticket().EstimatedMinutes
    });

    this.pageValidation.errors.showAllMessages(false);

    this.ticketTypeId.subscribe((newValue: any) => {
      const currentTicketType = _.find(
        this.allTicketTypes(),
        (ticketType: any) => {
          return newValue === ticketType.TicketTypeId;
        });

      this.ticket().EstimatedMinutes(0);
      this.isChangingDatesAllowed(false);
      this.isFileUploadRequired(false);
      this.hasFileUploadCapability(false);
      if (currentTicketType) {
        this.ticket().IsAppointment(currentTicketType.IsAppointment);
        this.ticket().TicketType = currentTicketType;
        this.ticket().EstimatedMinutes(currentTicketType.EstimatedMinutes);
        this.hasFileUploadCapability(currentTicketType.HasFileUploadCapability);
        this.isFileUploadRequired(currentTicketType.IsFileUploadRequired);
        this.isChangingDatesAllowed(currentTicketType.IsChangingDatesAllowed);
        this.resetDates();
        this.makeAssignedUserSelection(newValue);
      }
      this.pageValidation.errors.showAllMessages(false);
    });
  }

  public makeAssignedUserSelection(ticketTypeId: any): void {
    if (!this.ticket().AssignedUserId()) {
      this.ticket().AssignedUserId(this.defaultAssignedUserId);
    }
  }

  public close(resolved: any): void {
    if (this.dfd && resolved === true) {
      this.dfd.resolve();
    } else {
      this.dfd.reject();
    }
  }

  public saveTicket(): void {
    if (this.pageValidation.isValid()) {
      const newTicket = ko.toJS(this.ticket());
      newTicket.ScheduledDate = this.startDateTime().toDate();
      newTicket.InsertedDate = moment().utc().toDate();
      newTicket.CustomerId = this.customer().CustomerId();
      newTicket.InsertUserId = this.partnerUser().UserId;
      newTicket.StatusName = this.ticketStatus[0].name;
      newTicket.StatusId = this.ticketStatus[0].value;
      newTicket.StatusDate = moment().utc().toDate();
      // assign insertuser
      const currentUser = _.findWhere(this.partnerAssignedUsers(), { PartnerUserId: this.partnerUser().PartnerUserId });
      if (_.isObject(currentUser)) {
        newTicket.InsertUser = currentUser;
      } else {
        this.ticket().InsertUser = this.partnerUser();
      }
      // If newTicket is an appointment then prompt user if a scheduling conflict will occur
      if (newTicket.IsAppointment === true && newTicket.AssignedUserId) {
        const endDateTime = moment(this.startDateTime()).add(this.ticket().EstimatedMinutes(), 'm').toDate();
        this.isLoading(true);
        Utils.wrapDfd(this.ticketService.getAppointmentIdsByAssignedUser(newTicket.AssignedUserId, newTicket.ScheduledDate, endDateTime))
          .then((data: any) => {
            let conflictingTicketExists = false;
            for (let x = 0; x < data.length && conflictingTicketExists === false; x++) {
              if (data[x] !== newTicket.TicketId) {
                conflictingTicketExists = true;
              }
            }
            if (conflictingTicketExists === true) {
              bootbox.confirm(
                'Assigned user already has an appointment during this time block. Are you sure you want to schedule this appointment?',
                (result: any) => {
                  if (result === true) {
                    this.persistTicket(newTicket);
                  }
                });
            } else {
              this.persistTicket(newTicket);
            }
          }).fail((displayMessage) => {
            toastr.error(displayMessage);
          }).always(() => {
            this.isLoading(false);
          });
      } else {
        this.persistTicket(newTicket);
      }
    } else {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      this.pageValidation.errors.showAllMessages();
    }
  }

  public activate(options: any): void { // options: { partnerUser : partnerUser, customer : customer, ticket : ticket, editmode: editMode },
    // Only Activate if static data exists
    this.isLoading(true);
    $.when(Utils.wrapDfd(this.sessionStorageService.getStaticData())).done((staticData) => {
      this.allTicketTypes(staticData.TicketTypes);
      if (_.isObject(options) && _.isObject(options.customer) && _.isObject(options.parent)) {
        this.customer(options.customer);
        this.parent(options.parent);
      } else {
        toastr.error('Options are invalid.');
        return;
      }
      forkJoin([
        this.sessionStorageService.getUser(),
        this.sessionStorageService.getPartnerUser(Boo.Objects.Enums.UserLevelEnum.CustomerService),
        this.partnerUserService.getByUserLevel(Boo.Objects.Enums.UserLevelEnum.CustomerService),
        this.sessionStorageService.getPartner()
      ])
      .pipe(finalize(() => this.isLoading(false)))
      .subscribe(([user, pUser, partnerUsers, partner]) => {
        this.partnerUser(pUser);
        this.partnerAssignedUsers(_.sortBy(partnerUsers, 'FullName'));
        this.ticket().AssignedUserId(pUser.UserId);
        this.defaultAssignedUserId = pUser.UserId;
        this.loadEstimatedMinutes();
        this.pageValidation.errors.showAllMessages(false);
        this.canCreateAnyTicketType(
          // ChooseAnyTicketType is a very specific partner-user-level permission to control creating tickets,
          // while HasAllTicketTypes can be user- or partner-user-level and is meant to be functionally
          // equivalent to assigning the user all ticket types everywhere that ticket types are checked.
          // This is the only context where they are both checked; most places just use HasAllTicketTypes.
          window.launchpad.hasPermission(partner, partnerUsers, permissions.CanChooseAnyTicketType) ||
          window.launchpad.hasPermission(partner, partnerUsers, permissions.HasAllTicketTypes, user)
        );
        this.ticket().TicketTypeId(options.ticketTypeId || 0);
      });
    });
  }

  private persistTicket(newTicket: any): void {
    this.isLoading(true);
    $.when(this.getPreSignedS3UrlDeferred(newTicket))
      .then(this.uploadFileDeferred.bind(this))
      .then(this.persistTicketDeferred.bind(this))
      .done(() => {
        toastr.success('Ticket was saved successfully.');
        this.close(true);
      })
      .fail((displayMessage) => {
        toastr.error(displayMessage);
      }).always(() => {
        this.isLoading(false);
      });
  }

  private getPreSignedS3UrlDeferred(ticketToSave: any): any {
    const urlDeferred = $.Deferred((preSignedDfd) => {
      if (this.hasFileUploadCapability() && this.file()) {
        let request: Boo.Objects.Amazon.PreSignedUrlRequest = new PresignedUrlRequest();
        request.Filename = this.filename();
        request.BucketName = window.launchpad.config.S3Buckets.s3staticfiles;
        request.Folder = `tickets/customers/${this.customer().CustomerId()}`;
        request.UseUniqueFileName = true;
        request.ContentType = 'multipart/form-data';

        if (this.file().type) {
          request.ContentType = this.file().type;
        }

        Utils.wrapDfd(this.amazonService.generatePreSignedUrl(request))
          .then(data => {
            preSignedDfd.resolve({
              ticketToUpdate: ticketToSave,
              preSignedUrlResponse: data
            });
          })
          .fail(() => preSignedDfd.reject);
      } else {
        // We resolve this as empty.
        preSignedDfd.resolve({ ticketToUpdate: ticketToSave });
      }
    });
    return urlDeferred;
  }

  private uploadFileDeferred(s3UrlDeferredResponse: any): any {
    return $.Deferred((uploadDfd) => {
      if (s3UrlDeferredResponse.preSignedUrlResponse) {
        let contentType = 'multipart/form-data';
        if (this.file().type) {
          contentType = this.file().type;
        }
        $.ajax({
          url: s3UrlDeferredResponse.preSignedUrlResponse.PreSignedUploadUrl,
          type: 'PUT',
          timeout: 300000,
          cache: false,
          contentType: contentType,
          processData: false,
          data: this.file(),
          success: () => {
            uploadDfd.resolve(s3UrlDeferredResponse);
          },
          error: () => {
            uploadDfd.reject('Unable to upload file.');
          }
        });
      } else {
        uploadDfd.resolve(s3UrlDeferredResponse);
      }
    });
  }

  private persistTicketDeferred(response: any): any {
    const newTicket = response.ticketToUpdate;
    return Utils.wrapDfd(this.ticketService.save(newTicket));
  }

  private loadEstimatedMinutes(): void {
    const intervals = (this.customer().WebsiteMaintenanceHours() * 60) / 15;
    let currentMinutes = 15;
    let minutes: any;
    let hours: number;
    let time;
    this.estimatedMinutes([]);
    for (let i = 0; i < intervals; i++) {
      minutes = (currentMinutes % 60);
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      hours = (currentMinutes - minutes) / 60;
      time = hours + ':' + minutes + '  (' + currentMinutes + ' minutes )';
      this.estimatedMinutes().push({ text: time, value: currentMinutes });
      currentMinutes += 15;
    }
  }

  private startDateIsValid(): any {
    let result: boolean;
    if (this.isChangingDatesAllowed() === true) {
      const now = moment();
      result = now.diff(this.startDateTime()) <= 0;
    } else {
      result = true;
    }
    return result;
  }

  private estimatedMinutesIsValid(val: any): any {
    let result: boolean;
    if (this.changeToEstimatedMinutesIsAllowed() === true) {
      result = _.isNumber(val);
    } else {
      result = true;
    }
    return result;
  }

  private resetDates(): void {
    this.ticketStartDate(moment());
    this.ticketStartTime(moment().add(5, 'minutes'));
  }
}
