<div class="card">
	<div class="card-header">
		{{title}}
	</div>
	<div class="card-body">
		<div class="container-fluid">
			<ng-container *ngIf="customerAccount">
				<div class="form-group d-flex gap-3 mb-0">
					<div class="flex-fill">
						<label class="control-form-label">Login Url</label>
						<ng-container *ngIf="!canOpenUrl">
							<input [dataValidation]="{placement: 'top', value: customerAccount.Url }" [ngModel]="customerAccount.Url()" (ngModelChange)="customerAccount.Url($event)" [ngModelOptions]="{ standalone: true }" [disabled]="!isEnabled" [readonly]="!isEnabled" type="text" class="form-control"/>
						</ng-container>
						<ng-container *ngIf="canOpenUrl">
							<div class="input-group">
								<input [dataValidation]="{placement: 'top', value: customerAccount.Url }" [ngModel]="customerAccount.Url()" (ngModelChange)="customerAccount.Url($event)" [ngModelOptions]="{ standalone: true }" [disabled]="!isEnabled" [readonly]="!isEnabled" type="text" class="form-control"/>
                <a [externalLink]="{ url: customerAccount.Url(), text: 'View' }" class="btn btn-primary" rel="noreferrer"></a>
							</div>
						</ng-container>
					</div>
					<ng-container *ngIf="showPort">
						<div>
							<label class="control-form-label">
								Port
								<small>(optional)</small>
							</label>
							<div class="input-group">
								<input [dataValidation]="{placement: 'top', value: customerAccount.Port }" [ngModel]="customerAccount.Port()" (ngModelChange)="customerAccount.Port($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="!isEnabled" style="min-width: 55px;" type="number" min="0" step="1" class="form-control"/>
							</div>
						</div>
					</ng-container>
				</div>
				<form class="form-group row mb-0">
					<div class="col-md-5">
						<label class="control-form-label">Username</label>
						<div>
							<input autocomplete="off" [dataValidation]="{placement: 'top', value: customerAccount.Username }" [ngModel]="customerAccount.Username()" (ngModelChange)="customerAccount.Username($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="!isEnabled" [readonly]="!isEnabled" type="text" class="form-control"/>
						</div>
					</div>
					<div class="col-md-7">
						<label class="control-form-label">Password</label>
						<div (click)="getPassword()">
							<input autocomplete="off" [dataValidation]="{placement: 'top', value: customerAccount.Password }" [ngModel]="customerAccount.Password()" (ngModelChange)="customerAccount.Password($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="!isEnabled" passwordToggle type="password" class="form-control"/>
						</div>
					</div>
				</form>
				<div class="form-group row mb-0">
					<div class="col-md-12">
						<label class="control-from-label">Notes</label>
						<textarea style="height: 4rem;" [dataValidation]="{placement: 'top', value: customerAccount.Note }" [ngModel]="customerAccount.Note()" (ngModelChange)="customerAccount.Note($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="!editableNotes" type="text" class="form-control" maxlength="2048"></textarea>	
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
