import { Component } from '@angular/core';
import { PartnerUserService } from '../../../services/partner-user.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-admin-partnerusers',
  templateUrl: './PartnerUsers.component.html'
})
export class PartnerUsersComponent implements IActivatable {
    public fullName: KnockoutObservable<string>;
    public hasPartnerUsers: KnockoutComputed<boolean>;
    public isLoading: KnockoutObservable<boolean>;
    public partnerUsers: KnockoutObservableArray<Boo.Objects.PartnerUser>;
    public userId: KnockoutObservable<number>;
    public username: KnockoutObservable<string>;

    private userLevels: Boo.Objects.UserLevel[];

    constructor(
      private partnerUserService: PartnerUserService,
      private sessionStorageService: SessionStorageService) { }

    public activate(): JQueryPromise<any> {
        this.fullName = ko.observable(null);
        this.isLoading = ko.observable(false);
        this.partnerUsers = ko.observableArray([]);
        this.hasPartnerUsers = ko.computed(() => {
            return this.partnerUsers().length > 0;
        });
        this.userId = ko.observable(null);
        this.username = ko.observable(null);
        return Utils.wrapDfd(this.sessionStorageService.getStaticData())
          .then((staticData: Boo.Objects.LaunchPadStaticData) => {
              this.userLevels = staticData.UserLevels;
          });
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        return _.any(user.PartnerUsers, (partnerUser: Boo.Objects.PartnerUser) => {
            return partnerUser.UserLevelId === launchpad.config.keys.adminUserLevelId;
        });
    }

    public findPartnerUser(): void {
        if (!this.userId()) {
            toastr.error('Please enter a user ID');
            return;
        }
        this.isLoading(true);
        Utils.wrapDfd(this.partnerUserService.getByUserId(this.userId()))
            .then((partnerUsers: Boo.Objects.PartnerUser[]) => {
                let firstPartnerUser = _.first(partnerUsers);
                if (firstPartnerUser) {
                    this.fullName(firstPartnerUser.FullName);
                    this.username(firstPartnerUser.Username);
                }
                _.each(partnerUsers, (partnerUser: IPartnerUserWithUserLevel) => {
                    partnerUser.UserLevel = _.findWhere(this.userLevels, { UserLevelId: partnerUser.UserLevelId });
                });
                this.partnerUsers(partnerUsers);
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading(false);
            });
    }

    public delete(partnerUser: Boo.Objects.PartnerUser): void {
        this.confirmDeletePartnerUser()
            .then(() => {
                Utils.wrapDfd(this.partnerUserService.delete(partnerUser.PartnerUserId))
                    .then(() => {
                        this.findPartnerUser();
                        toastr.success('PartnerUser successfully deleted');
                    })
                    .fail((displayMessage: string) => {
                        toastr.error(displayMessage);
                    });
            });
    }

    public confirmDeletePartnerUser(): JQueryPromise<boolean> {
        return $.Deferred<boolean>((dfd) => {
            let msg = 'Are you sure you would like to delete this partner user?';
            bootbox.confirm(msg, (result: boolean) => {
                if (result === true) {
                    dfd.resolve();
                } else {
                    dfd.reject();
                }
            });
        }).promise();
    }
}

interface IPartnerUserWithUserLevel extends Boo.Objects.PartnerUser {
    UserLevel: Boo.Objects.UserLevel;
}
