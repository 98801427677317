import { Component } from '@angular/core';
import Check from 'framework/Check';
import MerchantFieldLabelMap from 'app/models/typescript/MerchantFieldLabelMap';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import Utils from '../../../shared/utils';
import { MerchantFieldsService } from '../../../services/merchant-fields.service';

@Component({
    selector: 'app-components-managecustomer-merchantfields',
    templateUrl: './MerchantFields.component.html'
})
export class MerchantFieldsComponent implements IActivatable {
    public customer: Boo.Objects.Customer;
    public isLoading: KnockoutObservable<boolean>;
    public merchantFieldLabelMap: KnockoutObservable<MerchantFieldLabelMap>;
    public merchantFields: Boo.Objects.CustomerMerchantFields;

    private triggerRefreshCustomer: () => void;

    constructor(private merchantFieldsService: MerchantFieldsService) { }

    public activate(params: app.managecustomer.interfaces.IMerchantFieldsParams): JQueryPromise<any> {
        Check.isNotEmpty(params.customer, 'Customer cannot be empty');
        this.isLoading = ko.observable(true);
        this.customer = ko.mapping.toJS(params.customer);
        this.merchantFieldLabelMap = ko.observable(null);
        this.merchantFields = params.merchantFields;
        this.triggerRefreshCustomer = params.triggerRefreshCustomer;

        return this.merchantFieldsService.generateLabelMapping(this.customer.PartnerId)
            .then((map: MerchantFieldLabelMap) => {
                this.merchantFieldLabelMap = ko.observable(map);
            })
            .fail((displayMessage: string) => toastr.error(displayMessage))
            .always(() => this.isLoading(false));
    }

    public editMerchantFields(): void {
        PriorityViewModel.show('app-components-managecustomer-merchantfieldsedit', { customer: this.customer, })
            .then(() => {
                if (this.triggerRefreshCustomer) {
                    this.triggerRefreshCustomer();
                }
                return Utils.wrapDfd(this.merchantFieldsService.get(this.customer.CustomerId));
            }).then((merchantFields: Boo.Objects.CustomerMerchantFields) => {
                this.merchantFields = merchantFields;
            });

    }
}
