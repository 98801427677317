import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import TaskHelper from 'app/specialist/TaskHelper';
import System from 'framework/System';
import { finalize } from 'rxjs/operators';
import { TaskRejectionService } from '../../../../../services/task-rejection.service';
import ITaskFilesPublicApi = app.components.interfaces.ITaskFilesPublicApi;
import ITaskFilesPublicApiCallback = app.components.interfaces.ITaskFilesPublicApiCallback;
import TaskTypes = Boo.Objects.Enums.TaskTypeEnum;

@Component({
  selector: 'app-components-specialist-actioncomponents-onsiteimplementation',
  templateUrl: './OnsiteImplementation.component.html'
})
export class OnsiteImplementationComponent implements OnInit, IActionWorkspace, IActivatable {
  private validatedControls: UntypedFormControl[];

  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;
  taskFilesApi: ITaskFilesPublicApi;
  taskFilesApiCallback: ITaskFilesPublicApiCallback;
  taskDescription: string;
  taskInstructions: string;
  noteToReviewerCtrl: UntypedFormControl;
  creatingGoogleAnalytics: boolean;
  googleTag: string;
  rejection: Boo.Objects.TaskRejection = null;
  isLoading: boolean = false;
  acceptedFileExtensions: string[];

  constructor(private taskRejectionService: TaskRejectionService) { }

  ngOnInit(): void {
    this.taskDescription = this.currentAction().Task.TaskTypeSummary.Tips;
    this.taskInstructions = TaskHelper.getCustomDescription(this.currentAction().Task);

    this.taskFilesApiCallback = (api: ITaskFilesPublicApi) => { this.taskFilesApi = api; }

    if (this.currentAction().Task.TaskTypeEnum === TaskTypes.ThreeHundredOneRedirectMappingAndImplementation) {
      this.acceptedFileExtensions = ['.csv', '.xlsx'];
    }

    this.noteToReviewerCtrl = new UntypedFormControl(TaskHelper.getOnsiteImplementationNoteToReviewer(this.currentAction().Task), [Validators.maxLength(2000)]);
    this.validatedControls = [this.noteToReviewerCtrl];

    if (this.shouldShowGoogleAnalytics) {
      this.creatingGoogleAnalytics = TaskHelper.getCreateGoogleAnalytics(this.currentAction().Task);
      this.googleTag = TaskHelper.getGoogleAnalyticsTag(this.currentAction().Task);
    }

    let previousTaskId = TaskHelper.getPreviouslyCompletedImplementationTaskId(this.currentAction().Task);
    
    if (previousTaskId) {
      this.isLoading = true;
      this.taskRejectionService.getLatest(previousTaskId)
        .pipe(finalize(() =>
        { 
          this.isLoading = false;
          toastr.warning('This is an implementation revision');
        }))
        .subscribe(
          (rejection: Boo.Objects.TaskRejection) => this.rejection = rejection,
          (error: any) => toastr.error('Failed to load previous task rejection')
        );
    }
  }

  activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {
    params.controlViewModel(this);
    this.currentAction = params.currentAction;
    this.actionViewModel = ko.observable(null);
    return System.emptyPromise();
  }

  isStepReadyToReject(): boolean {
    return false;
  }

  isStepReadyToSave(): boolean {
    if (!this.currentAction().Task.TaskTypeSummary.IsPersistable ||
      !this.validatedControls.every(x => x.valid))
    {
      // show errors
      this.validatedControls.forEach(x => x.markAsTouched());
      return false;
    }

    TaskHelper.maybeSetOnsiteImplementationNoteToReviewer(this.currentAction().Task, this.noteToReviewerCtrl.value, this.currentAction().Task.AssignedUserId);
    return true;
  }

  isStepReadyToDelete(): boolean {
    return false;
  }

  isStepReadyToComplete(): boolean {
    let isValid = true;
    if (!this.validatedControls.every(x => x.valid))
    {
      this.validatedControls.forEach(x => x.markAsTouched());
      isValid = false;
    }

    TaskHelper.maybeSetOnsiteImplementationNoteToReviewer(this.currentAction().Task, this.noteToReviewerCtrl.value, this.currentAction().Task.AssignedUserId);
    return isValid;
  }

  get shouldShowGoogleAnalytics(): boolean {
    return [TaskTypes.GoogleAnalyticsCreationAndImplementation].includes(this.currentAction().Task.TaskTypeEnum);
  }
}
