<div class="row justify-content-start text-center align-items-center">
  <div class="col-md-4">
    <div>
      <label>Campaign Type</label>
    </div>
    <shared-components-customchoice [value]="this.customer.IsCampaignNational" [choiceTrue]="'National'"
      [choiceFalse]="'Local'" [disable]="isReadOnly" (valueChange)="setIsNational($event)">
    </shared-components-customchoice>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label>Pages/Keywords <app-components-helptext [key]="'KeywordIdeationTool_PagesKeywords'"></app-components-helptext></label>
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Target Pages</th>
            <th>Target Keywords</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{this.keywordSuggestionConfig.minimumPages}}</td>
            <td>{{this.keywordSuggestionConfig.minimumMainKeywords}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label>Keyword Summary</label>
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Halo</th>
            <th>Total Keywords</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{this.keywordSuggestionConfig.minimumHaloKeywordSuggestions}}</td>
            <td>{{this.keywordTotal}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>