import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationPhoneNumberService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/registrationphonenumbers`;

  constructor(private http: HttpClient) { }

  get(): Observable<Boo.Objects.RegistrationPhoneNumber[]> {
    return this.http.get<Boo.Objects.RegistrationPhoneNumber[]>(this.baseUrl);
  }

  import(phoneNumbers: string[]): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/Import`, { phoneNumbers: phoneNumbers});
  }

  revert(phone: Boo.Objects.AutoSelectedRegistrationPhoneNumber): Observable<Boo.Objects.RegistrationPhoneNumber> {
    return this.http.post<Boo.Objects.RegistrationPhoneNumber>(`${this.baseUrl}/Revert`, phone);
  }

  deactivateAndRequestNew(phone: Boo.Objects.AutoSelectedRegistrationPhoneNumber): Observable<Boo.Objects.AutoSelectedRegistrationPhoneNumber> {
    return this.http.post<Boo.Objects.AutoSelectedRegistrationPhoneNumber>(`${this.baseUrl}/DeactivateAndRequestNew`, phone);
  }
}
