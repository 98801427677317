<div class="row" *ngFor="let config of displayConfigs">
  <div class="col-md-12">
    <app-components-qa-criteria-button-group *ngIf="!config.useSelect" [displayConfig]="config"
      [displayConfig]="config" #criteriaControl>
    </app-components-qa-criteria-button-group>
    <app-components-qa-criteria-select *ngIf="config.useSelect" [displayConfig]="config"
      [displayConfig]="config" #criteriaControl>
    </app-components-qa-criteria-select>
  </div>
</div>
<h4 class="text-center">Grade Comment</h4>
<div class="row">
  <div class="col-md-12">
    <textarea class="form-control" [(ngModel)]="gradeComment"></textarea>
  </div>
</div>