<div>
  <app-components-togglepanel isOpen="true" title="Onsite Recommendations" toggleBody="true">
    <div class="card">
      <div class="card-header">
        Customer Campaign
      </div>
      <div class="card-body">
        <app-components-shared-customer-campaign
          [customer]="customerJS"
          [restrictions]="customerCampaignRestrictions">
        </app-components-shared-customer-campaign>
      </div>
    </div>
  </app-components-togglepanel>
</div>