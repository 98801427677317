import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import app from 'App';
import { ViewLocatorService } from '../../../services/view-locator.service';
import { UserService } from '../../../services/user.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-components-resetpassword',
  templateUrl: './ResetPassword.component.html'
})
export class ResetPasswordComponent implements OnInit {
  public app = app;
  public isLoading: boolean = false;
  public newPassword: KnockoutObservable<string>;
  public newPasswordVerify: KnockoutObservable<string>;
  public newPasswordValidation: KnockoutObservable<any>;
  private resetToken: string;
  private email: string;

  constructor(
    private viewLocatorService: ViewLocatorService,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    app.showContent(true);

    this.newPassword = ko.observable('').extend({
      required: true,
      minLength: 8,
      validation: {
        message: 'Passwords must be 8 characters long, have at least 1 uppercase letter, and at least 1 lower case letter',
        validator: (val: string): boolean => {
          let result = val.match('^((?=.*[A-Z])(?=.*[a-z]).*){8,}$');
          return !!result;
        }
      }
    });
    this.newPasswordVerify = ko.observable('').extend({
      validation: {
        message: 'Passwords do not match',
        validator: (val: string): boolean => {
          return this.newPassword() === val;
        }
      }
    });

    this.newPasswordValidation = ko.validatedObservable([this.newPassword, this.newPasswordVerify]);

    this.resetToken = this.route.snapshot.queryParams['token'];
    this.email = this.route.snapshot.queryParams['email'];
    if (!this.resetToken || !this.email) {
      toastr.error('Password Reset token is no longer valid');
      this.backToLogin();
      return;
    }

    this.viewLocatorService.setDisplayedRoute('resetpassword');
  }

  backToLogin(): void {
    app.setLocation('login');
  }

  save(): void {
    if (!this.newPasswordValidation.isValid()) {
      this.newPasswordValidation.errors.showAllMessages();
      return;
    }

    this.isLoading = true;

    this.userService.resetPassword(this.email, this.resetToken, this.newPassword(), this.newPasswordVerify()).pipe(
      finalize(() => {
        this.isLoading = false;
        this.backToLogin();
      })
    ).subscribe(
      () => toastr.success('Password was updated, please login using your new password.'),
      (err: string) => toastr.error('Failed to update password. Link may be invalid or expired.')
    );
  }
}
