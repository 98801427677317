import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpTextService } from '../../../services/help-text.service';

@Component({
    selector: 'app-components-helptext',
    templateUrl: './HelpText.component.html'
})
export class HelpTextComponent {
    private _key: string;
    @Input() public get key(): string {
        return this._key;
    }
    @Input() noMargin: boolean = false;

    public set key(value: string) {
        this._key = value;
        this.helpText = this.helpTextService.getByKey(this.key);
    }
    public helpText: Boo.Objects.HelpText;

    constructor(private modalService: NgbModal,
      private helpTextService: HelpTextService) {
    }

    public openModal(template: any): void {
        this.modalService.open(template);
    }
}