<div>
	<ng-container *ngIf="parent()">
		<div class="card">
			<div (click)="toggleCustomerInfoCollapsed(!toggleCustomerInfoCollapsed())" class="card-header cursor-pointer">
				<strong>{{partnerName()}}</strong>
				— New Note
				<i [hidden]="!(toggleCustomerInfoCollapsed() === false)" class="far fa-caret-square-up float-end"></i>
				<i [hidden]="!toggleCustomerInfoCollapsed()" class="far fa-caret-square-down float-end"></i>
			</div>
			<div [class.show]="toggleCustomerInfoCollapsed() === false" class="collapse">
				<compose Selector="app-components-managecustomer-customerinformation" [ActivateOptions]="{parent: parent(), page: 'NewNote', showTicketSaveTimer:false }"></compose>
			</div>
		</div>
	</ng-container>
	<div class="card">
		<div class="card-header">
			Note Details
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-md-12 form-group">
					<label class="control-label">Note Text</label>
					<textarea [dataValidation]="{ placement: 'top', value: newNote }" [ngModel]="newNote()" (ngModelChange)="newNote($event)" [ngModelOptions]="{ standalone: true }" name="newNote" class="form-control" maxlength="4000"></textarea>
					<span class="help-block">Maximum Length is 4000 Characters</span>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3 form-group">
					<label class="control-label">Contact Type</label>
					<select [dataValidation]="{placement:'top', value: selectedContactType }" [ngModel]="selectedContactType()" (ngModelChange)="selectedContactType($event)" [ngModelOptions]="{ standalone: true }" name="contactTypes" class="form-select">
						<option [ngValue]="undefined">Select Contact Type</option>
						<option *ngFor="let option of contactTypes()" [ngValue]="option.ContactTypeId">{{ option.Name }}</option>
					</select>
				</div>
			</div>
			<div class="card card-body bg-light d-block text-end">
				<button (click)="close()" class="btn btn-link">Cancel</button>
				<button (click)="addNewNote()" class="btn btn-success" data-accept="true">
					<i class="fa fa-check"></i>
					Save
				</button>
			</div>
		</div>
	</div>
</div>
