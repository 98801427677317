import { Component } from '@angular/core';
import { LinkLocationBlogDetailService } from '../../../services/link-location-blog-detail.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-specialist-blogmetrics',
  templateUrl: './BlogMetrics.component.html'
})
export class BlogMetricsComponent implements IActivatable {
  public downBlogsCount: number;

  constructor(private linkLocationBlogDetailService: LinkLocationBlogDetailService) { }

  public activate(params: any): JQueryPromise<void> {
    return Utils.wrapDfd(this.linkLocationBlogDetailService.downBlogCount())
      .then(result => {
        this.downBlogsCount = result;
      });
  }
}
