import { Component } from '@angular/core';
import PushNotificationConfig from 'app/PushNotificationConfig';
import { ConnectedUsersService } from '../../../services/connected-users.service';

interface IConnectedUsersActivationParams {
    customerId: number;
    onChanged?: (names: string[]) => void;
    scope: string;
}

@Component({
	selector: 'app-components-managecustomer-connectedusers',
	templateUrl: './ConnectedUsers.component.html'
})
export class ConnectedUsersComponent implements IActivatable {
    public users: Boo.Objects.User;
    public connectedUsers: KnockoutObservableArray<string>;
    private onChanged: (names: string[]) => void;
    private currentUser: Boo.Objects.User;
    private subscriberId: string;
    private scope: string;

    constructor(private connectedUsersService: ConnectedUsersService) { }
     
    public activate(params: IConnectedUsersActivationParams): JQueryPromise<void> {
        this.onChanged = params.onChanged;
        this.scope = params.scope;
        this.subscriberId = `connectedUsers:${this.scope}:${this.currentUser.UserId}:${params.customerId}`;
        this.connectedUsers = ko.observableArray([]);
        this.connectedUsers.subscribe(x => {
            this.changed();
        });

        if (!params.customerId) {
            return;
        }

        const events = PushNotificationConfig.channels.connectedUser.events;

        this.connectedUsersService.subscribe(
            this.subscriberId,
            events.onUsersSet,
            ((e: any) => this.connectedUsers(e))
        );

        this.connectedUsersService.subscribe(
            this.subscriberId,
            events.onUserAdded,
            ((e: any) => this.connectedUsers(e))
        );

        this.connectedUsersService.subscribe(
            this.subscriberId,
            events.onUserRemove,
            ((e: any) => this.connectedUsers(e))
        );

        return this.connectedUsersService.connect(this.subscriberId, params.customerId, this.currentUser.FullName);
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        this.currentUser = user;
        return true;
    }

    public deactivate(): void {
        this.connectedUsersService.disconnect(this.subscriberId);
    }

    private changed(): void {
        if (!this.onChanged) {
            return;
        }
        this.onChanged(this.connectedUsers());
    }
}
