import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class OnsiteCopyDetailService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/onsitecopydetails`;

  constructor(private http: HttpClient) { }

  getByTaskId(taskId: number): Observable<Boo.Objects.OnsiteCopyDetails> {
    let params = new HttpParams().set('taskId', taskId);
    return this.http.get<Boo.Objects.OnsiteCopyDetails>(`${this.baseUrl}/GetByTaskId`, { params: params });
  }

  getForOrderTaskByWorkflowTask(taskId: number): Observable<Boo.Objects.OnsiteCopyDetails> {
    let params = new HttpParams().set('taskId', taskId);
    return this.http.get<Boo.Objects.OnsiteCopyDetails>(`${this.baseUrl}/GetForOrderTaskByWorkflowTask`, { params: params });
  }
}
