

<div class="row" *ngIf="items.length && exportEnabled">
  <div class="col-md-12 form-group text-end">
      <button type="submit" class="btn btn-primary btn-sm"  (click)="export()">
          <i class="fa fa-download"></i>Export to CSV
      </button>
    </div>
</div>

<table class="table table-bordered table-hover">
  <thead>
    <ng-container *ngTemplateOutlet="thead"></ng-container>
  </thead>
  <tbody *ngIf="items?.length else noitems">
    <ng-container *ngFor="let item of items | sort: items.length:sortField:sortDirection | page: items.length:!disablePaging:pageSize:page" >
      <ng-container *ngTemplateOutlet="tbody; context: { $implicit: item }"></ng-container>
    </ng-container>
  </tbody>
  <ng-template #noitems>
    <tbody>
      <tr>
        <td class="alert alert-info" colspan="100%">No results to display</td>
      </tr>
    </tbody>
  </ng-template>
</table>

<div class="d-flex justify-content-between align-items-center" *ngIf="!disablePaging && !!(items?.length) && pageSizeOptions.length > 1 && items?.length > pageSizeOptions[0]">
  <div>
    <ul class="pagination" style="display: inline-flex">
      <li *ngFor="let pageSizeOption of pageSizeOptions" class="page-item" [class.active]="pageSize === pageSizeOption"
        (click)="setPageSize(pageSizeOption)">
        <button class="page-link">{{ pageSizeOption }}</button>
      </li>
    </ul>
    <span class="ms-2 text-primary">records per page</span>
  </div>
  <ngb-pagination [(page)]="page" [collectionSize]="items.length" [pageSize]="pageSize" [ellipses]="true" [maxSize]="5">
    <ng-template ngbPaginationPrevious><i class="fa fa-chevron-circle-left"></i></ng-template>
    <ng-template ngbPaginationNext><i class="fa fa-chevron-circle-right"></i></ng-template>
  </ngb-pagination>
</div>