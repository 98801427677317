import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { Observable, Subscription } from 'rxjs';
import { of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { TicketService } from '../../../services/ticket.service';
import Utils from '../../../shared/utils';
import { finalize } from 'rxjs/operators';

export enum Filters {
  UpcomingTickets = 'Upcoming Tickets',
  All = 'All',
  Appointments = 'Appointments',
  WorkStoppage = 'Work Stoppage'
}

@Component({
  selector: 'app-components-customerservice-assignedtickets',
  templateUrl: './AssignedTickets.component.html'
})
export class AssignedTicketsComponent implements IActivatable, OnInit {
  moment: moment.MomentStatic = moment;
  Date: DateConstructor = Date;
  filters: Filters[];
  selectedFilter: UntypedFormControl = new UntypedFormControl(Filters.UpcomingTickets, [], null);
  ticketGroups: Observable<Boo.Objects.TicketTypeGroup[]>;
  customerTeams: Observable<Boo.Objects.Team[]>;
  selectedTicketGroup: UntypedFormControl = new UntypedFormControl(null);
  selectedCustomerTeam: UntypedFormControl = new UntypedFormControl(null);
  tickets: Boo.Objects.AssignedTicketSummary[] = [];
  filteredtickets: Boo.Objects.AssignedTicketSummary[] = [];

  private user: Boo.Objects.User;

  constructor(private ticketService: TicketService) { }

  ngOnInit(): void {
    this.selectedFilter.valueChanges.subscribe(() => this.loadData());
    this.selectedTicketGroup.valueChanges.subscribe(() => this.filterTickets());
    this.selectedCustomerTeam.valueChanges.subscribe(() => this.filterTickets());
    this.filters = Object.keys(Filters).map(key => (Filters)[key]);
    this.loadData();
  }

  loadData(): Subscription {
    this.clearFilters();
    this.disable();
    return this.getTickets()
      .pipe(finalize(() => this.enable()))
      .subscribe(tickets => {
          this.tickets = tickets;

          const filterableTicketGroupIds = [ Boo.Objects.Enums.TicketTypeGroupEnum.SEO, Boo.Objects.Enums.TicketTypeGroupEnum.Websites];
          this.ticketGroups = of(
            Utils.distinctBy(
              'TicketTypeGroupId', 
              tickets.flatMap(x => x.TicketTypeGroups)
              .filter(x => filterableTicketGroupIds.includes(x.TicketTypeGroupId)))
              .sort((a, b) => (a.Name > b.Name) ? 1 : (a.Name < b.Name) ? -1 : 0 ));

          this.customerTeams = of(
            Utils.distinctBy(
              'TeamId',
              tickets.filter(x => { return !!x.CustomerTeam; })
              .flatMap(x => x.CustomerTeam))
              .sort((a, b) => (a.Name > b.Name) ? 1 : (a.Name < b.Name) ? -1 : 0 ));

          this.filterTickets();
        });
  }

  canActivate(user: Boo.Objects.User): boolean {
    this.user = user;
    return true;
  }

  openCustomer(ticketSummary: Boo.Objects.TicketSummary): JQueryPromise<void> {
    return PriorityViewModel.showManageCustomer(ticketSummary.CustomerId)
      .always(() => {
        return this.loadData();
      });
  }

  editTicket(ticketSummary: Boo.Objects.TicketSummary): JQueryPromise<void> {
    return PriorityViewModel.showTicket(ticketSummary.CustomerId, ticketSummary.TicketId)
      .always(() => {
        return this.loadData();
      });
  }

  private getTickets(): Observable<Boo.Objects.AssignedTicketSummary[]> {
    switch (this.selectedFilter.value) {
      case Filters.All:
        return this.ticketService.getAssigned(this.user.UserId);
      case Filters.Appointments:
        return this.ticketService.getAppointments(this.user.UserId);
      case Filters.UpcomingTickets:
        return this.ticketService.getUpcoming(this.user.UserId);
      case Filters.WorkStoppage:
        return this.ticketService.getWorkStoppage(this.user.UserId);
      default:
        throw new Error('Unrecognized filter value.');
    }
  }

  private filterTickets(): void {
    this.filteredtickets = this.tickets.filter(ticket =>
      (!this.selectedTicketGroup.value || ticket.TicketTypeGroups.some(x => x.TicketTypeGroupId === this.selectedTicketGroup.value)) &&
      (!this.selectedCustomerTeam.value || ticket.CustomerTeam.TeamId === this.selectedCustomerTeam.value));
  }

  private clearFilters(): void {
    this.selectedTicketGroup.setValue(null);
    this.selectedCustomerTeam.setValue(null);
  }

  private disable(): void {
    this.selectedTicketGroup.disable({ emitEvent: false });
    this.selectedCustomerTeam.disable({ emitEvent: false });
    this.selectedFilter.disable({ emitEvent: false });
  }

  private enable(): void {
    this.selectedTicketGroup.enable({ emitEvent: false });
    this.selectedCustomerTeam.enable({ emitEvent: false });
    this.selectedFilter.enable({ emitEvent: false });
  }
}
