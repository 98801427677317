/* tslint:disable */

import { Component } from '@angular/core';
import Utils from '../../../shared/utils';
import { WorkflowVisualizationService } from '../../../services/workflow-visualization.service';

declare var joint: any;

@Component({
	selector: 'app-components-admin-workflows',
	templateUrl: './Workflows.component.html'
})
export class WorkflowsComponent {
    public isLoading = ko.observable();
    public workflowDefinitions = ko.observableArray([]);
    public graph = {};
    public selectedWorkflowDefinition = ko.observable();

    constructor(private workflowVisualizationService: WorkflowVisualizationService) { }

    public canActivate(user: any) {
        return _.any(user.PartnerUsers,
            (item: any) => {
                return item.UserLevelId === 1; // Admin
            });
    }

    public activate(): JQueryPromise<any> {
        this.isLoading(true);
        return Utils.wrapDfd(this.workflowVisualizationService.getAll())
          .then((data: any) => {
              this.workflowDefinitions(data);
              setTimeout(() => {
                  this.selectedWorkflowDefinition(data[0]);
                  this.load();
              }, 0);
          }).fail((displayMessage) => {
              toastr.error(displayMessage, 'Error');
          }).always(() => {
              this.isLoading(false);
          });
    }

    public load(): void {
        let workflowGraph: any = document.getElementById('workflowGraph');
        if (!workflowGraph || !workflowGraph.contentWindow || !workflowGraph.contentWindow.load) {
            setTimeout(() => {
                this.load();
            }, 1000);
            return;
        }

        workflowGraph.contentWindow.load(this.selectedWorkflowDefinition());
    }

    public state(x: any, y: any, label: any) {
        const cell = new joint.shapes.fsa.State({
            position: { x: x, y: y },
            size: { width: 150, height: 50 },
            attrs: { text: { text: label } }
        });
        (window as any).g.addCell(cell);
        return cell;
    }

    public link(source: any, target: any, label: any, vertices: any) {
        const cell = new joint.shapes.fsa.Arrow({
            source: { id: source.id },
            target: { id: target.id },
            labels: [{ position: .5, attrs: { text: { text: label || '', 'font-weight': 'bold' } } }],
            vertices: vertices || []
        });
        (window as any).g.addCell(cell);
        return cell;
    }
}
