import { AbstractControl, Form, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

// Main Angular Utils File
export default class Utils {
  static wrapDfd<T>(observable: Observable<T>): JQueryPromise<T> {
    return $.Deferred<T>(dfd => {
      observable.subscribe(
        data => dfd.resolve(data),
        err => dfd.reject(err));
    }).promise();
  }

  static distinctBy(key: string, array: any[]): any[] {
    return [...new Map(array.map(item => [item[key], item])).values()];
  }

  static isDate(item: any): boolean {
    return Object.prototype.toString.call(item) === "[object Date]";
  }

  static isDefaultDate(item: any): boolean {
    return moment(item).year() <= 1;
  }

  static deepFreeze(object: object): object {
    // Retrieve the property names defined on object
    const propNames = Reflect.ownKeys(object as object);

    // Freeze properties before freezing self
    for (const name of propNames) {
      const value = object[name];

      if ((value && typeof value === "object") || typeof value === "function") {
        Utils.deepFreeze(value);
      }
    }

    return Object.freeze(object);
  }

  static showAllMessages(formControl: AbstractControl): void {
    if (formControl instanceof UntypedFormControl) {
      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    } else if (formControl instanceof UntypedFormGroup) {
      Object.keys(formControl.controls).forEach(key => {
        Utils.showAllMessages(formControl.controls[key]);
      });
    } else if (formControl instanceof UntypedFormArray) {
      formControl.controls.forEach(control => {
        Utils.showAllMessages(control);
      });
    }
  }

  static wordCount(value: string): number {
    if (!value || value.trim() === '') {
      return 0;
    }

    var normalizedText = value.
      replace(/(\r\n|\n|\r)/gm, " ").
      replace(/^\s+|\s+$/g, "").
      replace("&nbsp;", " ");

    var tempDiv = document.createElement('div');
    tempDiv.innerHTML = normalizedText;

    normalizedText = $(tempDiv).text();

    var words = normalizedText.split(/\s+/);

    return words.filter(x => !x.match(/^([\s\t\r\n]*)$/)).length;
  }

  static filterDuplicates<T>(array: T[], compareFn: (x: T, y: T) => boolean = (x, y) => x === y): T[] {
    return array.filter((value, index, self) => {
      return self.findIndex(item => compareFn(item, value)) === index;
    });
  }
}
