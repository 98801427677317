import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PremiumBacklinkService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/premiumbacklink`;

  constructor(private http: HttpClient) { }

  getPreviews(): Observable<Boo.Objects.PremiumBacklink.Preview[]> {
    return this.http.get<Boo.Objects.PremiumBacklink.Preview[]>(`${this.baseUrl}/preview`);
  }

  download(userId: number): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/download`, { userId: userId })
  }

  upload(uploadedUrl: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/upload`, { uploadedUrl: uploadedUrl })
  }

  getReport(): Observable<Boo.Objects.PremiumBacklink.Reporting> {
    return this.http.get<Boo.Objects.PremiumBacklink.Reporting>(`${this.baseUrl}/reporting`);
  }

  getDownloads(): Observable<Boo.Objects.ExportedTasksDownload[]> {
    return this.http.get<Boo.Objects.ExportedTasksDownload[]>(`${this.baseUrl}/getDownloads`);
  }
}
