<div>
  <app-components-togglepanel isOpen="true" title="Article Pending Review" toggleBody="true">
    <ng-container *ngIf="!isInConfirmationStep && currentStatus === publishStatus.Pending">
      <div class="card bg-light" *ngIf="!isEditMode">
        <div class="card-body">
          <a class="btn btn-info" [href]="documentUrl" target="_blank"><i class="fas fa-file-download"></i> Download DOCX</a>
          <button class="btn btn-info" (click)="copyCustomerLink()" [disabled]="isLinkExpired">
            <i class="far fa-copy"></i> {{ isLinkExpired ? 'Expired' : 'Customer Link' }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <ng-container *ngIf="isBlog">
              <label>Image</label> <br>
              <div *ngIf="taskPost.ImageUrl" style="width: 50%;">
                <img [src]="taskPost.ImageUrl" class="img-fluid"/>
              </div> <br>
              <button (click)="uploadFile()" class="btn btn-primary btn-sm mx-2" [disabled]="!isEditMode">
                <i class="fa fa-upload"></i>
                Upload File
              </button>
              <span>Adding this image to the blog post will help to increase user engagement</span> <br><br>
            </ng-container>
            <ng-container *ngIf="isCopy && websiteUrl">
              <label>Website Url</label> <br>
              <a [href]="websiteUrl.Url" rel="noreferrer"> {{ websiteUrl.Url }}</a><br><br>
              <span>Task Keywords: </span>
              <span>{{ keywordsAndAreas.join(', ') }}</span>
            </ng-container>
          </div>

          <form [formGroup]="osrForm">
            <div class="form-group">
              <label>
                Meta Title
                <ng-container *ngTemplateOutlet="charaterCount; context:{string: onsiteRecommendation.TitleNew}"></ng-container>
              </label>
              <input class="form-control" type="text" formControlName="titleNew" lpValidationMsg/>
            </div>
            <div class="form-group">
              <label>
                Meta Description
                <ng-container *ngTemplateOutlet="charaterCount; context:{string: onsiteRecommendation.DescriptionNew}"></ng-container>
              </label>
              <textarea class="form-control" formControlName="descriptionNew" rows="5" lpValidationMsg></textarea>
            </div>
            <ng-container *ngIf="isBlog">
              <div class="form-group">
                <label>
                  Title
                  <ng-container *ngTemplateOutlet="charaterCount; context:{string: taskPost.ContentTitle}"></ng-container>
                </label>
                <input class="form-control" type="text" formControlName="contentTitle" >
              </div>
            </ng-container>
            <ng-container *ngIf="isCopy">
              <div class="form-group">
                <label>
                  H1
                  <ng-container *ngTemplateOutlet="charaterCount; context:{string: onsiteRecommendation.H1New}"></ng-container>
                </label>
                <input class="form-control" type="text" formControlName="h1New" >
              </div>
            </ng-container>
            <div class="form-group" *ngIf="isCopy">
              <label>
                {{ isCopy ? 'H2' : 'Title' }}
                <ng-container *ngTemplateOutlet="charaterCount; context:{string: onsiteRecommendation.H2New}"></ng-container>
              </label>
              <input class="form-control" type="text" formControlName="h2New">
            </div>
          </form>
          <div class="form-group">
            <label>Body {{ isEditMode ? '' : '- readonly' }}</label>
            <quill-editor [(ngModel)]="taskPost.Content" [readOnly]="!isEditMode">
            </quill-editor>
          </div>
        </div>
      </div>

      <div class="card bg-light" *ngIf="!isTicketCompleted">
        <div class="card-body">
          <button class="btn btn-link" *ngIf="isEditMode || isInConfirmationStep"
            (click)="reset()">Cancel and Revert Changes</button>
          <button class="btn btn-danger" *ngIf="!isEditMode" (click)="isInRejectionStep = true">Reject</button>
          <button class="btn btn-success"
            (click)="triggerApproveStep()">{{ isEditMode ? 'Finish Editing & ' : '' }}Approve</button>
          <button class="btn btn-primary" *ngIf="!isEditMode" (click)="triggerEditMode()">Edit & Approve</button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isInRejectionStep">
      <form [formGroup]="rejectOptionsForm">

        <h3>Reject Reasons</h3>
        <div class="form-group">
          <div class="checkbox">
            <label>
              <input type="checkbox" formControlName="hasInaccurateInfo" />
              Inaccurate Information
            </label>
          </div>
          <ng-container *ngIf="rejectForm.hasInaccurateInfo.value">
            <label>What information is inaccurate? What is the correct information/terminology we should be
              using?</label>
            <textarea class="form-control" rows="5" formControlName="inaccurateInfo" lpValidationMsg></textarea>
          </ng-container>
        </div>

        <div class="form-group">
          <div class="checkbox">
            <label>
              <input type="checkbox" formControlName="hasMissingInfo" />
              Missing Information
            </label>
          </div>
          <ng-container *ngIf="rejectForm.hasMissingInfo.value">
            <label>What information/terminology should we include?</label>
            <textarea class="form-control" rows="5" formControlName="missingInfo" lpValidationMsg></textarea>
          </ng-container>
        </div>

        <div class="form-group">
          <div class="checkbox">
            <label>
              <input type="checkbox" formControlName="hasTone" />
              Tone
            </label>
          </div>
          <ng-container *ngIf="rejectForm.hasTone.value">
            <label>What tone should be used?</label>
            <textarea class="form-control" rows="5" formControlName="tone" lpValidationMsg></textarea>
          </ng-container>
        </div>

        <div class="form-group">
          <div class="checkbox">
            <label>
              <input type="checkbox" formControlName="hasGrammaticalErrors" />
              Grammatical errors/typos
            </label>
          </div>
          <ng-container *ngIf="rejectForm.hasGrammaticalErrors.value">
            <label>What words/phases need to be updated?</label>
            <textarea class="form-control" rows="5" formControlName="grammaticalErrors" lpValidationMsg></textarea>
          </ng-container>
        </div>

        <div class="form-group">
          <div class="checkbox">
            <label>
              <input type="checkbox" formControlName="hasOther" />
              Other
            </label>
          </div>
          <ng-container *ngIf="rejectForm.hasOther.value">
            <label>Explain the necessary changes with as much detail as possible.</label>
            <textarea class="form-control" rows="5" formControlName="other" lpValidationMsg></textarea>
          </ng-container>
        </div>
        <div class="card card-body bg-light d-block text-end">
          <button class="btn btn-link" (click)="cancelRejectionStep()">Cancel</button>
          <button class="btn btn-danger" (click)="rejectCopy()" [form]="rejectOptionsForm"
            lpFormSubmitValidation>Reject</button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="isInImplementationNoteStep">
      <h3>Implementation Note</h3>
      <div class="form-group">
        <label>Boost will implement this blog, include a note to the Implementation team</label>
        <textarea class="form-control" rows="5" maxlength="2000" [formControl]="implementationNoteCtrl"
          lpValidationMsg></textarea>
      </div>
      <div class="card card-body bg-light d-block text-end">
        <button class="btn btn-link" (click)="isInImplementationNoteStep = false; reset();">Cancel and Revert Changes</button>
        <button class="btn btn-success" (click)="approveCopy()">Save Approval</button>
      </div>
    </ng-container>

    <ng-container *ngIf="currentStatus !== publishStatus.Pending">
      <div class="alert alert-info">
        Copy has been set to: "{{ getStatus() }}". <br>
        WARNING: The ticket must be <u>completed</u> for the "{{ getStatus() }}" status to be saved.
      </div>
    </ng-container>
  </app-components-togglepanel>
</div>

<ng-template let-str="string" #charaterCount>
  <span *ngIf="str">- {{ str.length }} characters</span>
</ng-template>
