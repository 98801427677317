import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Notification } from '../shared/models/notifications/notifications';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/websites`;

  constructor(private http: HttpClient) { }

  getByCustomerId(customerId: number): Observable<Boo.Objects.Websites.Website> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.Websites.Website>(`${this.baseUrl}/GetByCustomerId`, { params: params });
  }

  isBuildComplete(customerId: number): Observable<boolean> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<boolean>(`${this.baseUrl}/IsBuildComplete`, { params: params });
  }

  getManyByCustomerId(customerId: number): Observable<Boo.Objects.Websites.Website[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.Websites.Website[]>(`${this.baseUrl}/GetManyByCustomerId`, { params: params });
  }

  getByTaskId(taskId: number): Observable<Boo.Objects.Websites.Website> {
    const params = new HttpParams().set('taskId', taskId);

    return this.http.get<Boo.Objects.Websites.Website>(`${this.baseUrl}/GetByTaskId`, { params: params });
  }

  getByTicketId(ticketId: number): Observable<Boo.Objects.Websites.Website> {
    const params = new HttpParams().set('ticketId', ticketId);

    return this.http.get<Boo.Objects.Websites.Website>(`${this.baseUrl}/GetByTicketId`, { params: params });
  }

  save(website: Boo.Objects.Websites.Website): Observable<any> {
    return this.http.post(`${this.baseUrl}/Save`, website);
  }

  savePreviewUrl(websiteId: number, previewUrl: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/SavePreviewUrl`, {
        websiteId: websiteId,
        previewUrl: previewUrl
    });
  }

  addPage(websiteId: number, isContent: boolean): Observable<Boo.Objects.Websites.WebsitePage> {
    return this.http.post<Boo.Objects.Websites.WebsitePage>(`${this.baseUrl}/addPage`, {
        websiteId: websiteId,
        isContent: isContent
    });
  }

  get(websiteId: number): Observable<Boo.Objects.Websites.Website> {
    const params = new HttpParams().set('websiteId', websiteId);

    return this.http.get<Boo.Objects.Websites.Website>(`${this.baseUrl}/Get`, { params: params });
  }

  getLatestAdjustment(customerId: number): Observable<Boo.Objects.Ticket> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.Ticket>(`${this.baseUrl}/LatestAdjustment`, { params: params });
  }

  getAdjustments(websiteId: number): Observable<Boo.Objects.Websites.AdjustmentTicket[]> {
    const params = new HttpParams().set('websiteId', websiteId);

    return this.http.get<Boo.Objects.Websites.AdjustmentTicket[]>(`${this.baseUrl}/Adjustments`, { params: params });
  }

  orderAdjustment(websiteId: number, customerId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/Adjustments/Add`, {
        websiteId: websiteId,
        customerId: customerId
    });
  }

  getSsoUrlForAdmin(websiteId: number): Observable<string> {
    const params = new HttpParams().set('websiteId', websiteId);

    return this.http.get<string>(`${this.baseUrl}/GetSsoUrlForAdmin`, { params: params });
  }

  getSsoUrlForCustomer(websiteId: number): Observable<string> {
    const params = new HttpParams().set('websiteId', websiteId);

    return this.http.get<string>(`${this.baseUrl}/GetSsoUrlForCustomer`, { params: params });
  }

  getTemplates(websiteId: number, page: number, pageSize: number): Observable<framework.data.ISimplePageResult<Boo.Objects.Websites.WebsiteTemplate>> {
    const params = new HttpParams()
      .set('websiteId', websiteId)
      .set('page', page)
      .set('pageSize', pageSize);

    return this.http.get<framework.data.ISimplePageResult<Boo.Objects.Websites.WebsiteTemplate>>(`${this.baseUrl}/GetTemplates`, { params: params });
  }

  getSiteInfo(websiteId: number, siteId: string): Observable<Boo.Objects.Websites.WebsiteSiteInfo> {
    const params = new HttpParams()
    .set('websiteId', websiteId)
    .set('siteId', siteId);

    return this.http.get<Boo.Objects.Websites.WebsiteSiteInfo>(`${this.baseUrl}/GetSiteInfo`, { params: params });
  }

  getWebsiteBuildSummaries(websiteId: number): Observable<Boo.Objects.Websites.WebsiteBuildSummary[]> {
    const params = new HttpParams().set('websiteId', websiteId);

    return this.http.get<Boo.Objects.Websites.WebsiteBuildSummary[]>(`${this.baseUrl}/GetWebsiteBuildSummaries`, { params: params });
  }

  isDomainRegistrationSupported(websiteId: number): Observable<boolean> {
    const params = new HttpParams().set('websiteId', websiteId);

    return this.http.get<boolean>(`${this.baseUrl}/IsDomainRegistrationSupported`, { params: params });
  }

  isDomainRegistrationSatisfied(websiteId: number): Observable<boolean> {
    const params = new HttpParams().set('websiteId', websiteId);

    return this.http.get<boolean>(`${this.baseUrl}/IsDomainRegistrationSatisfied`, { params: params });
  }

  checkDomainAvailability(websiteId: number, domain: string): Observable<boolean> {
    const params = new HttpParams()
      .set('websiteId', websiteId)
      .set('domain', domain);

    return this.http.get<boolean>(`${this.baseUrl}/CheckDomainAvailability`, { params: params });
  }

  registerDomain(websiteId: number, domain: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/RegisterDomain`, {
        websiteId: websiteId,
        domain: domain
    });
  }

  getTicketAdjustmentIdentifier(ticketId: number): Observable<number> {
    const params = new HttpParams().set('ticketId', ticketId);
    return this.http.get<number>(`${this.baseUrl}/TicketAdjustmentIdentifier`, { params: params });
  }

  getVendorNotifications(customerId: number): Observable<Notification[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Notification[]>(`${this.baseUrl}/GetVendorNotifications`, { params: params });
  }
}
