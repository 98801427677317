<div class="alert alert-danger">
  <strong><i class="fas fa-stop-circle"></i> {{ flag.Message }}</strong>
  <div class="mt-2">
    <div class="row">
      <div class="col-md-12">
        <p>{{ taskTypeName }} is deactivated. See customer notes for more information.</p>
        <p>Do you want to reactivate it for this customer?</p>
        <button class="btn btn-warning btn-sm" (click)="reactivateNonWorkableTaskType()">Reactivate</button>
      </div>
    </div>
  </div>
</div>