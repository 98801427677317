/* tslint:disable */

import { Component, OnInit } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { LinkTypeService } from '../../../services/link-type.service';
import { LinkLocationService } from '../../../services/link-location.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-manager-linktypeactivation',
	templateUrl: './LinkTypeActivation.component.html'
})
export class LinkTypeActivationComponent implements OnInit {
  public isLoading: any = ko.observable(false);
  public selectedLinkTypeId: any = ko.observable();
  public searchText: any = ko.observable();
  public isInEditMode: any = ko.observable(true);
  public urlFilter: any = ko.observable('');
  public linkTypes: any = ko.observableArray([]);
  public links: any = ko.observableArray([]);
  public languages: any = ko.observableArray([]);
  public countries: any = ko.observableArray([]);
  public verticals: any = ko.observableArray([]);
  public selectedLinks: any = ko.observable([]);

  public showVertical: any = ko.computed(() => {
    return this.selectedLinkTypeId() === Boo.Objects.Enums.LinkTypeEnum.StandardBacklink;
  });

  public filteredLinks: any = ko.computed(() => {
    return _.filter(this.links(),
      (link: any) => {
        return link.Url.toLowerCase().indexOf(this.urlFilter().toLowerCase()) >= 0;
      });
  });

  public showSubmitOrder: any = ko.computed(() => {
    return this.selectedLinkTypeId() !== Boo.Objects.Enums.LinkTypeEnum.StandardBacklink;
  });

  public filteredLinkTypes: any = ko.computed(() => {
    let result = _.filter(this.linkTypes(),
      (type: any) => {
        return type.LinkTypeId !== Boo.Objects.Enums.LinkTypeEnum.StandardBacklink
          && type.LinkTypeId !== 3 //Custom Article publish(Retired)
          && type.LinkTypeId !== 4 //Business Profile DIY(Retired)
          && type.LinkTypeId !== 6 //Competitive Link Building(Retired)
          && type.LinkTypeId !== 9; //Article Publish(Retired)
      });
    _.each(result,
      (type) => {
        // override linkType names for the following types
        if (type.LinkTypeId === Boo.Objects.Enums.LinkTypeEnum.BusinessDirectories) {
          type.Name = 'Directory Link Building';
        }
        if (type.LinkTypeId === Boo.Objects.Enums.LinkTypeEnum.LocalBusinessCitations) {
          type.Name = 'Business Profiles';
        }
      });
    result = _.sortBy(result,
      (type) => {
        return type.Name;
      });
    return result;
  });

  public linkTypeIsSelected: any = ko.computed(() => {
    return _.isNumber(this.selectedLinkTypeId()) ? this.selectedLinkTypeId() !== 0 : false;
  });

  public canActivate(user: any): any {
    return _.any(user.PartnerUsers,
      (item: any) => {
        return item.UserLevelId === 1 || item.UserLevelId === 5;
      });
  }

  public activate(): void {
    this.getStaticData();
    this.getAllLinkTypes();
  }

  public newLinkLocation(): void {
    this.isInEditMode(false);
    const options = {
      linkTypeId: this.selectedLinkTypeId(),
      selectedLinks: this.selectedLinks(),
      linkTypes: this.linkTypes(),
      isInEditMode: this.isInEditMode(),
      onCompleteCallBack: this.reloadLinks.bind(this),
      filteredLinkTypes: this.filteredLinkTypes()
    };
    PriorityViewModel.show('app-components-manager-linklocation', options).done(this.reloadLinks.bind(this));
  }

  constructor(
    private linkTypeService: LinkTypeService,
    private linkLocationService: LinkLocationService,
    private sessionStorageService: SessionStorageService) {
  }

  ngOnInit(): void {
    this.selectedLinkTypeId.subscribe(() => {
      this.reloadLinks();
    });
  }


  public getAllLinkTypes(): void {
    this.isLoading(true);
    Utils.wrapDfd(this.linkTypeService.getAll())
      .done((result) => {
        this.linkTypes(result);
      })
      .fail((displayMessage) => {
        toastr.error(displayMessage);
      })
      .always(() => {
        this.isLoading(false);
      });
  }

  public reloadLinks(): void {
    if (this.linkTypeIsSelected()) {
      this.isLoading(true);

      Utils.wrapDfd(this.linkLocationService.getByType(this.selectedLinkTypeId()))
        .then((data: any) => {
          _.each(data,
            (link: any) => {
              link.linkIsActive = ko.observable(link.IsActive);
              link.linkLanguage = this.getLanguage(link.LanguageId);
              link.linkCountry = this.getCountry(link.CountryId);
              link.linkVertical = this.getVertical(link.VerticalId);
            });
          this.links(data);
        }).fail((displayMessage) => {
          toastr.error(displayMessage);
        }).always(() => {
          this.isLoading(false);
        });
    }
  }

  public getLanguage(languageId: any): any {
    const foundLanguage: any = _.find(this.languages(), (language) => { return language.LanguageId === languageId; });
    return _.isObject(foundLanguage) ? foundLanguage.Name : '';
  }

  public getCountry(countryId: any): any {
    const foundCountry: any = _.find(this.countries(), (country) => { return country.CountryId === countryId; });
    return _.isObject(foundCountry) ? foundCountry.Name : '';
  }

  public getVertical(verticalId: any): any {
    const foundVertical: any = _.find(this.verticals(), (vertical) => { return vertical.VerticalId === verticalId; });
    return _.isObject(foundVertical) ? foundVertical.Name : '';
  }

  public getStaticData(): void {
    $.when(Utils.wrapDfd(this.sessionStorageService.getStaticData()))
      .then((staticData) => {
        this.languages(staticData.Languages);
        this.countries(staticData.Countries);
        this.verticals(staticData.Verticals);
      });
  }

  public editLinkLocation(data: any): void {
    this.isInEditMode(true);
    this.selectedLinks(_.filter(this.links(), (link: any) => { return link.Url === data.Url; }));
    const options = {
      linkTypeId: this.selectedLinkTypeId(),
      selectedLinks: this.selectedLinks(),
      linkTypes: this.linkTypes(),
      isInEditMode: this.isInEditMode(),
      onCompleteCallBack: this.reloadLinks.bind(this),
      filteredLinkTypes: this.filteredLinkTypes()
    };
    PriorityViewModel.show('app-components-manager-linklocation', options).done(this.reloadLinks.bind(this));
  }

  public setIsActive(link: any): any {
    const selectedLink = link;
    if (_.isObject(selectedLink)) {
      // Blogs, Articles and Manual Articles must have Vertical to be activated
      if (!selectedLink.IsActive && selectedLink.LinkTypeId === Boo.Objects.Enums.LinkTypeEnum.StandardBacklink) {
        if (!((_.isNumber(selectedLink.VerticalId) && selectedLink.VerticalId !== 0))) {
          toastr.error('Cannot activate blog or article link location without a Vertical');
          return;
        }
      }
      this.isLoading(true);
      selectedLink.IsActive = !selectedLink.IsActive;

      Utils.wrapDfd(this.linkLocationService.save(selectedLink as Boo.Objects.LinkLocation))
        .then(() => {
          selectedLink.linkIsActive(selectedLink.IsActive);
          toastr.success('Link Location Updated Successfully');
        })
        .fail((displayMessage) => {
          toastr.error(displayMessage);
        })
        .always(() => {
          this.isLoading(false);
        });
    }
  }
}
