import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobTypeService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/jobtypes`;

  constructor(private http: HttpClient) { }

  updateStatus(jobTypeId: number, isActive: boolean): Observable<any> {
    return this.http.post(`${this.baseUrl}/UpdateStatus`, {
        jobTypeId: jobTypeId,
        isActive: isActive
    });
  }

  getAll(): Observable<Boo.Objects.Jobs.JobType[]> {
    return this.http.get<Boo.Objects.Jobs.JobType[]>(`${this.baseUrl}/GetAll`);
  }
}
