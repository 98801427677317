<ng-container *ngIf="addingKeyword; else notAddingKeyword">
  <div class="row justify-content-start">
    <div class="col-auto">
      <ng-container *ngTemplateOutlet="keyword"></ng-container>
    </div>
    <div class="col-auto" *ngIf="!customer.IsCampaignNational">
      <ng-container *ngTemplateOutlet="area"></ng-container>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary" (click)="addKeyword()"><i class="fas fa-plus"></i>Keyword</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-warning" (click)="toggleAddingKeyword()">Cancel</button>
    </div>
  </div>
</ng-container>

<ng-template #notAddingKeyword>
  <div class="row">
    <div class="col-12">
      <button class="btn btn-primary me-4 btn-sm" type="button" data-toggle="dropdown">
        <i class="fas fa-plus"></i>Keyword
      </button>
      <ul class="dropdown-menu" role="menu">
        <li>
          <button class="btn-link dropdown-item" (click)="toggleAddingKeyword()">Custom Keyword</button>
        </li>
        @if (availableKeywordSuggestions.length) {
          <div class="dropdown-divider"></div>
          <h6 class="dropdown-header">Suggested Keywords</h6>
          <ng-container *ngFor="let keywordSuggestion of availableKeywordSuggestions | orderBy: keywordSort">
            <li>
              <button class="btn-link dropdown-item text-end"
              (click)="addKeywordSuggestion(keywordSuggestion)">
              <div class="d-flex justify-content-between">
                <span>
                  @if (keywordSuggestion.SourcePageSuggestionId === pageSuggestion.PageSuggestionId) {
                    <small><i class="text-primary fas fa-star" title="Recommended for this page"></i></small>
                  }
                  {{ keywordSuggestion.KeywordPhrase }}
                </span>
                <span style="margin-left: 30px;">
                  @if (keywordSuggestion.Rank) {
                    <span class="badge bg-secondary">Rank: {{ keywordSuggestion.Rank }}</span>
                  }
                  @if (!keywordSuggestion.Rank && keywordSuggestion.SourceId === keywordSuggestionSources_OpenAI) {
                    <span class="badge bg-secondary">Rank: {{ unknownRank }}</span>
                  }
                  @if (keywordSuggestion.VolumeDescription) {
                    <span class="badge" [ngClass]="{
                      'bg-warning': keywordSuggestion.VolumeDescription === 'Low' || keywordSuggestion.VolumeDescription === 'Medium',
                      'bg-success': keywordSuggestion.VolumeDescription === 'High'}">Vol: {{ keywordSuggestion.VolumeDescription }}</span>
                  }
                  @if (keywordSuggestion.CompetitionDescription) {
                    <span class="badge" [ngClass]="{
                      'bg-warning': keywordSuggestion.CompetitionDescription === 'High' || keywordSuggestion.CompetitionDescription === 'Medium',
                      'bg-success': keywordSuggestion.CompetitionDescription === 'Low'}">Cmp: {{ keywordSuggestion.CompetitionDescription }}</span>
                  }
                </span>
              </div>
            </button>
            </li>
          </ng-container>
        }
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #keyword>
  <div class="input-group mb-0" [formValidation]="formGroup">
    <span class="input-group-text">Keyword</span>
    <input #keywordInput type="text" class="form-control" [formControl]="keywordControl"
      [formValidation]="keywordControl" (keyup.enter)="addKeyword()"/>
  </div>
</ng-template>

<ng-template #area>
  <div class="input-group mb-0" [formValidation]="formGroup">
    <span class="input-group-text">Location</span>
    <input #areaInput type="text" class="form-control" [formControl]="areaControl"
      [formValidation]="areaControl" (keyup.enter)="addKeyword()" />
  </div>
</ng-template>