import { NgModule } from '@angular/core';
import { QaGradingArea } from './qa-grading-area/qa-grading-area.component';
import { QaGradingAreaPanel } from './qa-grading-area-panel/qa-grading-area-panel.component';
import { QaGradingAreaRow } from './qa-grading-area-row/qa-grading-area-row.component';
import { QaCriteriaControl } from './qa-criteria-control/qa-criteria-control.component';
import { QaCriteriaButtonGroup } from './qa-criteria-button-group/qa-criteria-button-group.component';
import { QaCriteriaSelect } from './qa-criteria-select/qa-criteria-select.component';
import { QaStockCommentSelect } from './qa-stock-comment-select/qa-stock-comment-select.component';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    QaGradingArea,
    QaGradingAreaPanel,
    QaGradingAreaRow,
    QaCriteriaControl,
    QaCriteriaButtonGroup,
    QaCriteriaSelect,
    QaStockCommentSelect,
  ],
  exports: [
    QaGradingArea,
    QaGradingAreaPanel,
    QaGradingAreaRow,
    QaCriteriaControl,
    QaCriteriaButtonGroup,
    QaCriteriaSelect,
    QaStockCommentSelect
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class QaModule { }
