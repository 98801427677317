<div [loadingOverlay]="isLoading()">
  <div class="card">
    <div class="card-header">
      <span>{{ title }}</span>
      <button (click)="cancel()" class="btn-close" title="Close"></button>
    </div>
    <div class="card-body">
      <ng-container *ngIf="workRequestDetail">
        <div class="row form-group">
          <div class="col-md-4">
            <label class="control-label">Task Type</label>
            <input [(ngModel)]="taskType.Name" type="text" disabled="" class="form-control" />
          </div>
          <ng-container *ngIf="taskType">
            <div class="col-md-4">
              <label class="control-label">Estimated Minutes</label>
              <input [ngModel]="billableMinutes()" (ngModelChange)="billableMinutes($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" disabled="" class="form-control" />
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="!childControl() || childControl().showTaskDescription">
          <div class="row form-group">
            <div class="col-md-12">
              <label class="control-label">Task Description</label>
              <div [innerHtml]="taskTypeTips" class="form-control disabled" style="height: auto;background-color:#eee;min-height:34px;"></div>
            </div>
          </div>
        </ng-container>
        <!-- This is the generic form to insert custom/Manual actions -->
        <ng-container *ngIf="isCustomOther">
          <div class="row form-group">
            <div class="col-md-12">
              <label class="control-label">Task Type Name</label>
              <input [dataValidation]="{ placement: 'top', value: customTaskTypeName }" [ngModel]="customTaskTypeName()" (ngModelChange)="customTaskTypeName($event)" [ngModelOptions]="{ standalone: true }" class="form-control" type="text" style="margin-top: 10px;" placeholder="Name for task type..." />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="control-label">Action Name</label>
              <input [dataValidation]="{ placement: 'top', value: customName }" [ngModel]="customName()" (ngModelChange)="customName($event)" [ngModelOptions]="{ standalone: true }" class="form-control" type="text" />
            </div>
          </div>
          <ng-container *ngIf="!isInEditMode">
            <div class="row form-group">
              <div class="col-md-12">
                <label class="control-label">Billed Minutes</label>
                <input [dataValidation]="{ placement: 'top', value: customBillableMinutes }" [ngModel]="customBillableMinutes()" (ngModelChange)="customBillableMinutes($event)" [ngModelOptions]="{ standalone: true }" class="form-control" type="number" />
              </div>
            </div>
          </ng-container>
          <div class="row form-group">
            <div class="col-md-12">
              <label class="control-label">Task Action URL</label>
              <input [ngModel]="taskActionUrl()" (ngModelChange)="taskActionUrl($event)" [ngModelOptions]="{ standalone: true }" class="form-control" />
            </div>
          </div>
        </ng-container>
        <div class="row" *ngIf ="config.shouldShowWebsiteUrl">
          <div class="col-md-12">
              <div>
                <label class="control-label">Website URL</label>
              </div>
              <div *ngIf="selectedWebsiteUrl && selectedWebsiteUrl.StatusId !== websiteUrlStatuses_Active" class="alert alert-warning">The URL you have selected is not enabled for link building.</div>
              <div class="row no-gutters mb-3">
                <div class="col-md-9">
                  <ng-select [dataValidation]="{ placement: 'top', value: websiteUrlId }" [items]="this.websiteUrls"
                             [multiple]="false" [closeOnSelect]="true" [clearable]="config.allowNoWebsiteOption" [disabled]="isInEditMode"
                             bindLabel="IsLinkBuilding" [placeholder]="websiteUrlPlaceholder" [groupBy]="groupWebsiteUrls"
                             [(ngModel)]="selectedWebsiteUrl" (change)="changeSelectedWebsiteUrl($event)">
                    <ng-template ng-label-tmp let-item="item">
                      <i [ngClass]="getWebsiteUrlIconClass(item)"></i> {{item.Url}}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <i [ngClass]="getWebsiteUrlIconClass(item)" title="{{ item.disabled ? item.disabledMessage : '' }}"></i> {{item.Url}}
                    </ng-template>
                    <ng-template ng-optgroup-tmp let-item="item">
                      {{ item.IsLinkBuilding ? 'LinkBuild Urls' : 'Other Urls' }}
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-md-3 ps-3">
                  <ng-container *ngIf="selectedWebsiteUrl && selectedWebsiteUrl.Url">
                    <a [attr.href]="selectedWebsiteUrl.Url" rel="noreferrer" target="_blank" class="btn btn-primary">
                      <i class="fas fa-external-link-square-alt" style="margin-right: 8px"></i> View
                    </a>
                  </ng-container>
                </div>
              </div>
          </div>
        </div>
        <div class="row" *ngIf="config.shouldShowDescription">
          <div [class.col-md-6]="isOnsiteCopy" [class.col-md-12]="!isOnsiteCopy" class="form-group">
            <label class="control-label">Instructions</label>
            <app-components-helptext [hidden]="!isOnsiteCopy" [key]="'AddEditCustomAction_OnsiteCopyInstructions'"></app-components-helptext>
            <textarea [dataValidation]="{ placement: 'top', value: customDescription }" [ngModel]="customDescription()" (ngModelChange)="customDescription($event)" [ngModelOptions]="{ standalone: true }" [disabled]="!canSave()" [readonly]="!canSave()" rows="5" class="form-control"></textarea>
          </div>
          <div [hidden]="!isOnsiteCopy" class="col-md-6">
            <label class="control-label">Related to the keywords we're creating copy for:</label>
            <p>Why should a customer pick you over a competitor?</p>
            <p>What are the main benefits, unique selling points, or features for the service/product featured on this page?</p>
            <p>Please list any common, incorrect assumptions that customers make about the service/product. (i.e. features assumed to be included which are not included)</p>
            <p><b>Copy will include a link to an internal page.</b></p>
          </div>
        </div>
        <ng-container *ngIf="!childControl() || childControl().showNotifyCsr">
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="control-label">Notify CSR When Complete</label>
              <div [dataValidation]="{ placement: 'top', value: notifyOnCompletion }" style="width: 150px">
                <shared-components-customchoicelegacy [Settings]="{ value: notifyOnCompletion, disable: !canSave() || isOnsiteCopyImplementationManual }"></shared-components-customchoicelegacy>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="childControl() && childControl().selector">
          <compose [Selector]="childControl().selector" [ActivateOptions]="{
																										 controlViewModel: childControlViewModel,
																										 parentWindowDfd: dfd,
                                                     workRequestDetail: workRequestDetail,
																										 selectedWebsiteUrl: selectedWebsiteUrl,
                                                     canEdit: canSave,
                                                     isNew: !isInEditMode,
																										 hasValidAccessDetails: hasValidAccessDetails
                                                 }"></compose>
        </ng-container>
        <div *ngIf="config.shouldShowFiles">
          <div class="row" style="margin-bottom: 4px;">
            <div class="col-md-6">
            <strong>File(s) for Task</strong>
            </div>
            <div class="col-md-6 text-end">
              <button (click)="uploadFile()" class="btn btn-primary btn-sm">
                <i class="fa fa-upload"></i>
                Upload File
              </button>
            </div>
          </div>
          <div class="card">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created Date</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let $data of workRequestFiles">
                  <tr>
                    <td>{{$data.Name}}</td>
                    <td>{{$data.InsertedDate | dateText }}</td>
                    <td>
                      <a [hidden]="!(($data.S3Url !== null))" [externalLink]="{ url: $data.S3Url, text: 'View' }" class="btn btn-primary"></a>
                    </td>
                    <td>
                      <button [disabled]="!canSave()" (click)="deleteFile($data)" class="btn btn-danger">
                        <i class="fa fa-times"></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card card-body bg-light d-block text-end">
          <button (click)="cancel()" class="btn btn-link">Cancel</button>
          <ng-container *ngIf="canSave()">
            <button (click)="save()" class="btn btn-success">
              <i class="fa fa-save"></i>
              Save
            </button>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
