import ITodoListObservable = app.tsmodels.interfaces.ITodoListObservable;
import TodoStatus = Boo.Objects.Todo.Enums.TodoStatus;
import TodoListActions = Boo.Objects.Todo.Enums.TodoListActions;

export default class TodoListService {
    public attachValidationRules(todoLists: KnockoutObservableArray<ITodoListObservable>): void {
        _.filter(todoLists(), (todoList: ITodoListObservable) => {
            return todoList.TodoListAction() === TodoListActions.Do;
        }).forEach(todoList => {

            todoList.Todos().forEach(todo => {
                todo.Status.extend(
                    {
                        validation: {
                            validator: (status: TodoStatus): boolean => {
                                return status !== TodoStatus.New;
                            },
                            message: 'Each item requires a response'
                        }
                    });
            });
        });
    }
}