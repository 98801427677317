<div class="container">
	<div class="row">
		<div class="col-md-6 offset-md-3">
			<div class="card">
				<div class="card-header">
					Select a LaunchPad Partner
				</div>
				<div [loadingOverlay]="isLoading" class="card-body pre-scrollable" style="max-height: 500px;">
					<table class="table table-striped">
						<thead>
							<tr>
								<th data-sortable="false">Partner Name</th>
								<th data-sortable="false">Login</th>
							</tr>
						</thead>
						<tbody>
							<ng-container *ngFor="let $data of partners">
								<ng-container *ngTemplateOutlet="partnerTemplate;context:{$context:$data}"></ng-container>
							</ng-container>
							<ng-template #partnerTemplate let-$context="$context">
								<tr>
									<td>{{$context.Name}}</td>
									<td style="text-align: right;">
										<button (click)="switchPartner($context)" class="btn btn-primary">Login</button>
									</td>
								</tr>
							</ng-template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
