import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'orderBy',
  pure: false
})
export class OrderByPipe implements PipeTransform {
  transform(value: object[], sortFunction?: (a: any, b: any, ...args: any[]) => number, ...args: any[]): any[] {
    if (!sortFunction) {
      return value;
    }
    return value.sort((a, b) => sortFunction(a, b, ...args));
  }
}
