<div class="form-group row">
	<ng-container *ngIf="customerUserPhoneNumber">
		<label class="col-md-4 col-form-label">{{customerUserPhoneNumber.PhoneNumber.Primary() ? 'Primary' : 'Alt'}}</label>
		<div class="col-md-7">
			<input [dataValidation]="{ placement: 'right', value: customerUserPhoneNumber.PhoneNumber.Number }" [ngModel]="customerUserPhoneNumber.PhoneNumber.Number()" (ngModelChange)="customerUserPhoneNumber.PhoneNumber.Number($event)" [ngModelOptions]="{ standalone: true }" type="text" class="form-control"/>
		</div>
		<ng-container *ngIf="!customerUserPhoneNumber.PhoneNumber.Primary()">
			<div class="col-md-1">
				<a (click)="delete()" title="Delete Number">
					<i class="fa fa-times fa-2x right text-danger"></i>
				</a>
			</div>
		</ng-container>
	</ng-container>
</div>
