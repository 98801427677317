import { Component, Input } from '@angular/core';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';
import { KeywordSuggestion, PageSuggestion } from '../../../../shared/models/keywords/keywords';
import { KeywordConfig } from '../../../../shared/models/keywords/keywords';

@Component({
  selector: 'app-components-shared-keyword-research-page',
  templateUrl: './page.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class PageComponent {
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestion: PageSuggestion;
  @Input() pageSuggestions: PageSuggestion[];
  @Input() keywordConfig: KeywordConfig;

  constructor(private customerCampaignService: CustomerCampaignService) { }

  keywordSort = (a: KeywordSuggestion, b: KeywordSuggestion): number => {
    const aBelongsToUrl = a.SourcePageSuggestionId === this.pageSuggestion.PageSuggestionId;
    const bBelongsToUrl = b.SourcePageSuggestionId === this.pageSuggestion.PageSuggestionId;

    if (aBelongsToUrl && bBelongsToUrl) {
      return a.Priority - b.Priority;
    }
    if (aBelongsToUrl && !bBelongsToUrl) {
      return -1;
    }
    if (!aBelongsToUrl && bBelongsToUrl) {
      return 1;
    }

    return a.Keyword.localeCompare(b.Keyword);
  }

  removePage() {
    this.pageSuggestion.IsSelected = false;

    if (!this.pageSuggestion.Priority) {
      this.pageSuggestion.KeywordSuggestions.forEach(x => {
        if (x.SourcePageSuggestionId) {
          x.IsSelected = false;
          x.PageSuggestionId = x.SourcePageSuggestionId;
          this.pageSuggestions.find(y => y.PageSuggestionId === x.SourcePageSuggestionId).KeywordSuggestions.push(x);
        } else {
          this.pageSuggestion.KeywordSuggestions.splice(this.pageSuggestion.KeywordSuggestions.indexOf(x), 1);
        }
      });
    }
  }

  get selectedKeywordSuggestions(): KeywordSuggestion[] {
    return this.pageSuggestion.KeywordSuggestions.filter(x => x.IsSelected);
  }

  get validationMessage(): string {
    if (this.pageSuggestion.Url && this.pageSuggestion.IsCampaignNational === this.customer.IsCampaignNational && !this.customerCampaignService.domainMatches(this.customer.Url, this.pageSuggestion.Url)) {
      return 'The page domain must match the customer domain.';
    }

    let requiredPages = this.keywordConfig?.Pages || 1;
    let requiredKeywordsPerPage = (this.keywordConfig?.MainKeywords || 1) / requiredPages;

    if (this.pageSuggestion.KeywordSuggestions.filter(y => y.IsSelected && y.IsCampaignNational === this.customer.IsCampaignNational).length < requiredKeywordsPerPage) {
      return 'Page must have at least ' + requiredKeywordsPerPage + ' keywords.';
    }

    return '';
  }
}
