import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-components-navigation-admin',
  templateUrl: './Admin.component.html'
})
export class AdminComponent implements OnInit, OnDestroy {
  showDevToolsSubMenu: boolean = false;
  showProductToolsSubMenu: boolean = false;

  private urlChangedHandler: (newHash: string) => void = (newHash: string) => {
    this.urlUpdated(newHash);
  };

  private devToolsPages: string[] = [
    'admin/workflows',
    'admin/internaltaskhistory',
    'admin/applicationmanagement',
    'admin/translations',
    'admin/partnerusers',
    'admin/viewtaskworkspace'
  ];

  private productToolsPages: string[] = [
    'admin/verticalmanagement',
    'admin/helptexteditor',
    'admin/countryandlanguagemanagement'
  ];

  ngOnInit(): void {
    amplify.subscribe(launchpad.config.events.urlChanged, this.urlChangedHandler);
  }

  ngOnDestroy(): void {
    amplify.unsubscribe(launchpad.config.events.urlChanged, this.urlChangedHandler);
  }

  private urlUpdated(newHash: string): void {
    if (this.devToolsPages.indexOf(newHash) > -1) {
      this.showDevToolsSubMenu = true;
      this.showProductToolsSubMenu = false;
    } else if (this.productToolsPages.indexOf(newHash) > -1) {
      this.showDevToolsSubMenu = false;
      this.showProductToolsSubMenu = true;
    } else {
      this.showDevToolsSubMenu = false;
      this.showProductToolsSubMenu = false;
    }
  }
}
