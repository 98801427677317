<div class="row justify-content-md-center">
  <div class="col-lg-8">
    <div [loadingOverlay]="isLoggingIn" class="card">
      <div class="card-body">
        <div class="text-center">
          <img [attr.src]="app.largeLogoUrl()" style="margin: 10px auto;"/>
        </div>
        <div [slideVisible]="(errorMessage != '')" class="alert alert-danger">{{errorMessage}}</div>
        <div [hidden]="showForgotPasswordView" class="row">
          <div class="text-center col-lg-6 form-group" style="margin-top: 35px;">
            <h4>Boostability Employee Login</h4>
              <div class="mt-5 d-inline-block" #googleSignInButton></div>
          </div>
          <div class="col-lg-6 form-group">
            <h4 class="text-center" style="margin-bottom: 35px;margin-top: 35px;">Partner Login</h4>
            <form class="form-horizontal" style="margin-bottom: 0px;">
              <div class="form-group row">
                <label class="col-lg-3 col-form-label" for="username">Username</label>
                <div class="col-lg-9">
                  <input [formControl]="usernameCtrl" [returnKey]="login.bind(this)" id="loginUsername" class="form-control" name="username" placeholder="username" type="text" autocomplete="off" lpValidationMsg/>
                </div>
              </div>
              <div class="form-group row" style="margin-bottom: 15px;">
                <label class="col-form-label col-lg-3" for="password">Password</label>
                <div class="col-lg-9">
                  <input [formControl]="passwordCtrl" [returnKey]="login.bind(this)" autocomplete="off" class="form-control" id="loginPassword" name="password" placeholder="password" type="password" lpValidationMsg/>
                </div>
              </div>
              <div class="form-group">
                <button (click)="forgotPassword()" class="btn btn-link">Forgot Password</button>
                <button (click)="login()" [returnKey]="login.bind(this)" style="margin-right:15px;" name="Login" class="btn btn-primary float-end">
                  <i class="fas fa-sign-in-alt"></i>
                  Log In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div *ngIf="showForgotPasswordView" class="row justify-content-md-center my-4" [loadingOverlay]="isLoading">
        <div class="col col-lg-5">
          <p class="text-center">Please enter the email address you use to sign in to your account.</p>
          <div class="control-group">
            <input [formControl]="forgotPasswordUsernameCtrl" autocomplete="off" class="form-control" id="forgotPasswordUserName" name="forgotPasswordUserName" placeholder="username" type="text" lpValidationMsg/>
          </div>
        </div>
      </div>
      <div *ngIf="showForgotPasswordView" class="card card-body bg-light d-block text-end" style="margin-top: 15px; margin-bottom: 0px;">
        <button (click)="cancelForgotPassword()" class="btn btn-link">Cancel</button>
        <button (click)="submitForgotPassword()" class="btn btn-primary" [disabled]="isLoading">Submit</button>
      </div>
    </div>
  </div>
</div>

<ng-template #updatePasswordModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Password</h4>
  </div>
  <div class="modal-body">
    <span>Your password does not meet security standards.</span>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()" class="btn btn-link">Nevermind, I'll do it later</button>
    <button (click)="modal.close(true)" class="btn btn-primary">Update Password</button>
  </div>
</ng-template>
