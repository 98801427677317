<div class="row">
  <div class="col-lg-6">
    <div style="margin-bottom:15px;">
      <ng-container *ngIf="!hasAccess">
      <a (click)="grantGoogleAnalyticsAccess()" class="btn btn-warning">
        <i class="fab fa-google"></i>
        Grant Analytics Access
      </a>
      </ng-container>
      <ng-container *ngIf="hasAccess">
      <button (click)="revokeAnalyticsAccess()" class="btn btn-danger">
        <i class="fa fa-times"></i>
        Revoke Analytics Access
      </button>
      </ng-container>
    </div>
  </div>
</div>