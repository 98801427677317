<div>
  <div class="row">
    <div class="col-md-12">
      <ul class="list-group">
        <li class="list-group-item" *ngIf="onsiteImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Implementation Task</strong>
            </div>
            <div class="col-md-10">
              {{ onsiteImplementationSummary.TaskTypeName }}
            </div>
          </div>
        </li>
        <li class="list-group-item" *ngIf="onsiteImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Description</strong>
            </div>
            <div class="col-md-10" [innerHTML]="onsiteImplementationSummary.Description"></div>
          </div>
        </li>
        <li class="list-group-item" *ngIf="onsiteImplementationSummary">
          <div class="row">
            <div class="col-md-2">
              <strong>Instructions</strong>
            </div>
            <div class="col-md-10 preserve-whitespace">{{ onsiteImplementationSummary.Instructions }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <br />
  <br />
  <div class="row" *ngIf="onsiteImplementationSummary">
    <div class="col-md-8">
      <app-components-specialist-taskfiles [options]="{
        taskId: onsiteImplementationSummary.TaskId,
        allowUpload: true,
        apiCallback: taskFilesApiCallback,
        useSmallTitle: true
      }"></app-components-specialist-taskfiles>
    </div>
  </div>
  <app-floating-panels>
    <app-floating-panel [displayConfig]="{ title: 'External Access', icon: 'fa-key', size: 'floating-panel-md' }">
      <compose *floatingPanelContent Selector="app-components-managecustomer-readonlyexternalaccess" [Model]="{ customer: currentAction().Customer }">
      </compose>
    </app-floating-panel>
    <app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
      <app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="currentAction().TaskDetails.TaskNotes">
      </app-components-specialist-actioncomponents-comments>
    </app-floating-panel>
  </app-floating-panels>
</div>
