
<address class="mb-0" *ngIf="localProfileFormGroup">
  <ng-container *ngIf="localProfileFormGroup.controls.companyName && localProfileFormGroup.controls.companyName.value">
    <strong>{{ localProfileFormGroup.controls.companyName.value }}</strong>
    <br />
  </ng-container>
  <ng-container *ngIf="localProfileFormGroup.controls.streetAddress.value">
    <strong>{{ localProfileFormGroup.controls.streetAddress.value }}</strong>
    <br />
  </ng-container>
  <ng-container *ngIf="localProfileFormGroup.controls.streetAddress2.value">
    <span>{{ localProfileFormGroup.controls.streetAddress2.value }}</span>
    <br />
  </ng-container>
  <ng-container *ngIf="localProfileFormGroup.controls.city && localProfileFormGroup.controls.city.value">
    <span>{{ localProfileFormGroup.controls.city.value }}</span>,
  </ng-container>
  <ng-container *ngIf="state">
    <span>{{ state }}</span>
  </ng-container>
  <ng-container *ngIf="localProfileFormGroup.controls.zip.value">
    <span>{{ localProfileFormGroup.controls.zip.value }}</span>
  </ng-container>
</address>

<address class="mb-0" *ngIf="localProfile">
  <ng-container *ngIf="localProfile.CompanyName">
    <strong>{{ localProfile.CompanyName }}</strong>
    <br />
  </ng-container>
  <ng-container *ngIf="localProfile.StreetAddress">
    <span>{{ localProfile.StreetAddress }}</span>
    <br />
  </ng-container>
  <ng-container *ngIf="localProfile.StreetAddress2">
    <span>{{ localProfile.StreetAddress2 }}</span>
    <br />
  </ng-container>
  <ng-container *ngIf="localProfile.City">
    <span>{{ localProfile.City }}</span>,
  </ng-container>
  <ng-container *ngIf="state">
    <span>{{ state }}</span>
  </ng-container>
  <ng-container *ngIf="localProfile.Zip">
    <span>{{ localProfile.Zip }}</span>
  </ng-container>
</address>