/* tslint:disable */

import { Component } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { VerticalService } from '../../../services/vertical.service';
import { VerticalTranslationService } from '../../../services/vertical-translation.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-admin-vertical',
	templateUrl: './Vertical.component.html'
})
export class VerticalComponent {
    public dfd: any;
    public title = 'Vertical';
    public isLoading = ko.observable(false);
    public verticalId = ko.observable(0);
    public selectedParentVerticalId = ko.observable(0);
    public verticalValidation = ko.validatedObservable([]);

    constructor(
      private verticalService: VerticalService,
      private verticalTranslationService: VerticalTranslationService,
      private sessionStorageService: SessionStorageService) { }

    public vertical = ko.observable(ko.mapping.fromJS({
        VerticalId: 0,
        Name: '',
        ParentVerticalId: 0,
        NestedLevel: 2,
        InsertedDate: new Date(),
        IsActive: true
    }));
    public parentVerticals = ko.observableArray([]);
    public languages = ko.observableArray([]);
    public translations = ko.observableArray([]);

    public editMode = ko.computed(() => {
        let editMode = false;
        if (this.verticalId() > 0) {
            editMode = true;
        }
        return editMode;
    });

    public isTopLevelVertical = ko.computed(() => {
        return this.vertical().ParentVerticalId() === 0 && this.verticalId() !== 0;
    });

    public loadParentVerticals(): any {
        this.isLoading(true);
        return Utils.wrapDfd(this.verticalService.getTopLevelOnly())
          .then((verticals: any) => {
              // Let's filter and sort the verticals
              verticals = _.sortBy(verticals,
                  (vertical: any) => {
                      return vertical.Name;
                  });
              verticals.unshift({ VerticalId: 0, Name: 'Choose...' });
              this.parentVerticals(verticals);
              // Now that we have our parent verticals, let's set our selected vertical id
              // This prevents issues with the way knockout binds the selected item before
              // our list of select items is populated.
              this.selectedParentVerticalId(this.vertical().ParentVerticalId());
          }).fail((displayMessage) => {
              toastr.error(displayMessage);
          }).always(() => {
              this.isLoading(false);
          });
    }
    public loadVertical(): any {
        this.isLoading(true);
        return Utils.wrapDfd(this.verticalService.get(this.verticalId()))
          .then((vertical) => {
            this.vertical(ko.mapping.fromJS(vertical));
            this.setUpValidation();
            // We only need to load the parent verticals if we're on a sub vertical.
            if (this.vertical().ParentVerticalId() > 0) {
                this.loadParentVerticals();
            }
          }).fail((displayMessage) => {
              toastr.error(displayMessage);
          }).always(() => {
              this.isLoading(false);
          });
    }
    public loadVerticalTranslations(): any {
        this.isLoading(true);
        return Utils.wrapDfd(this.verticalTranslationService.getByVerticalId(this.verticalId()))
          .then((translations: any) => {
            translations = _.sortBy(translations,
                (translation: any) => {
                    return translation.Name.toLowerCase();
                });
            _.each(translations,
                (translation: any) => {
                    const language = _.find(this.languages(),
                        (lang) => {
                            return lang.LanguageId === translation.LanguageId;
                        });
                    translation.languageName = language.Name || '';
                });
            this.translations(translations);
        }).fail((displayMessage) => {
            toastr.error(displayMessage);
        }).always(() => {
            this.isLoading(false);
        });
    }
    // #endregion

    // #region Functions
    public close() {
        if (this.dfd) {
            this.dfd.resolve();
        }
    }

    public cancel() {
        if (this.dfd) {
            this.dfd.reject();
        }
    }

    public save() {
        if (!this.verticalValidation.isValid()) {
            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
            this.verticalValidation.errors.showAllMessages();
            return;
        }
        // If the parent vertical has changed let's make sure we update it in the 
        // vertical object. This should only happen for NEW verticals.
        if (this.vertical().ParentVerticalId() !== this.selectedParentVerticalId()) {
            this.vertical().ParentVerticalId(this.selectedParentVerticalId());
        }
        this.isLoading(true);
        Utils.wrapDfd(this.verticalService.save(ko.mapping.toJS(this.vertical())))
          .then((verticalId: any) => {
            if (this.verticalId() === 0 && verticalId > 0) {
                this.verticalId(verticalId);

                const translation = {
                  VerticalTranslationId: 0,
                  InsertedDate: new Date(),
                  VerticalId: this.verticalId(),
                  LanguageId: launchpad.config.LanguageIdEnum.English, // English, North America
                  Name: this.vertical().Name()
                } as Boo.Objects.VerticalTranslation;

                Utils.wrapDfd(this.verticalTranslationService.save(translation))
                  .then(() => {
                      this.loadVertical();
                      this.loadVerticalTranslations();
                  }).fail((displayMessage) => {
                      toastr.error(displayMessage);
                  });
            } else {
                // We're done, let's close
                this.close();
            }
          }).fail((displayMessage) => {
              toastr.error(displayMessage);
          }).always(() => {
              this.isLoading(false);
          });
    }

    public editVerticalTranslation(data: any): void {
        PriorityViewModel.show('app-components-admin-verticaltranslation', { verticalTranslationId: data.VerticalTranslationId, verticalId: this.verticalId() }).done(() => {
            this.loadVerticalTranslations();
        });
    }

    public addVerticalTranslation(): void {
        PriorityViewModel.show('app-components-admin-verticaltranslation', { verticalId: this.verticalId() }).done(() => {
            this.loadVerticalTranslations();
        });
    }

    public activate(options: any) {
        return $.when(Utils.wrapDfd(this.sessionStorageService.getStaticData()))
          .then((staticData) => {
            let deferreds: any[] = [];
            this.languages(staticData.Languages);
            if (options.verticalId && !_.isNaN(options.verticalId)) {
                this.verticalId(parseInt(options.verticalId, 10));
                deferreds.push(this.loadVertical());
                deferreds.push(this.loadVerticalTranslations());
            } else { // This is a new vertical
                deferreds.push(this.loadParentVerticals());
                this.setUpValidation();
            }

            return $.when.apply(this, deferreds);
          });
    }
    // #endregion

    // #region Validation
    public setUpValidation(): void {
        this.selectedParentVerticalId.extend({
            validation: {
                message: 'Please select a parent vertical',
                validator: (val: any) => {
                    if (this.isTopLevelVertical() || this.editMode()) {
                        return true;
                    } else {
                        return parseInt(val, 10) > 0;
                    }
                }
            }
        });
        const verticalValidation = ko.validatedObservable([
            this.selectedParentVerticalId
        ]);
        this.verticalValidation.errors.showAllMessages(false);
    }
    // #endregion

}
