<div id="customer-todo-list-float-panel" [@wobbleIf]="{value:unresolved()>0, params: {timing: 1, delay: 1}}" class="float-panel-ctd">
	<div class="float-panel-ctd-tab">
		<button class="float-panel-btn-expand btn btn-lg btn-primary">
			<i class="fas fa-tasks ms-1" style="font-size: 135%;"></i>
			<span [hidden]="!(unresolved() > 0)" class="badge rounded-pill bg-danger" style="font-size:70%; margin-left: -20px">{{unresolved()}}</span>
		</button>
	</div>
	<div class="float-panel-ctd-main card">
		<div class="float-panel-ctd-draggable card-header">
			<i class="fas fa-tasks" style="font-size: 135%;"></i>
			<span [hidden]="!(unresolved() > 0)" class="badge rounded-pill bg-danger" style="font-size:70%; margin-left: -20px">{{unresolved()}}</span>
			To Do List
			<i class="float-panel-btn-collapse fa fa-minus float-end"></i>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-md-12" style="max-height: 420px; overflow: auto;">
					<compose Selector="app-components-todo-customertodolist" [ActivateOptions]="{
																customer: customer
                              }" [Callback]="customerTodoListComposeBinding?.bind(this)"></compose>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<div class="row">
				<ng-container *ngIf="!isUserPartner()">
					<div class="col-sm-8">
						<ng-container *ngIf="unsavedChanges()">
							<span class="text-warning">
								<i class="fas fa-exclamation-triangle"></i>
								You have unsaved to do changes
							</span>
						</ng-container>
					</div>
					<div class="col-sm-4 text-end">
						<div class="btn-group">
							<button (click)="save()" class="btn btn-sm btn-primary">
								<i class="fa fa-save"></i>
								Save To Dos
							</button>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="isUserPartner()">
					<div class="col-sm-12 text-center">
						<span class="text-warning">Partner Readonly View</span>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
