import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-components-manager-partner-seo-work-defaults-by-hours',
  templateUrl: './partner-seo-work-defaults-by-hours.component.html',
  styleUrls: ['./partner-seo-work-defaults-by-hours.component.scss']
})
export class PartnerSeoWorkDefaultsByHoursComponent implements OnInit {
  @Input() isReadOnly: boolean;
  @Input() hours: number;
  @Input() partnerId: number;
  @Input() partnerDefaults: Boo.Objects.Work.PartnerSeoWorkDefault[];
  @Input() taskTypes: Boo.Objects.Work.SeoMinute.SeoWorkTaskType[];
  @Input() partnerSeoMinuteReservationDefaults: Boo.Objects.Work.PartnerSeoMinuteReservationDefault[];
  @Output() removeHourAllocation: EventEmitter<number> = new EventEmitter<number>();
  minutes: number;
  defaults: Boo.Objects.Work.PartnerSeoWorkDefault[];
  qtyNumbers: number[];
  maxQuantity: number = 40;
  partnerSeoMinuteReservationDefault: Boo.Objects.Work.PartnerSeoMinuteReservationDefault;

  ngOnInit(): void {
    this.minutes = this.hours * 60;
    this.defaults = this.getDefaults();
    const existingReservation = this.partnerSeoMinuteReservationDefaults.find(reservation => reservation.Hours === this.hours);
    
    if (existingReservation) {
      this.partnerSeoMinuteReservationDefault = existingReservation;
    } else {
      this.partnerSeoMinuteReservationDefault = { Hours: this.hours, PartnerId: this.partnerId, Quantity: 0 } as Boo.Objects.Work.PartnerSeoMinuteReservationDefault;
      this.partnerSeoMinuteReservationDefaults.push(this.partnerSeoMinuteReservationDefault);
    }
    
    this.qtyNumbers = Array.from({length: this.maxQuantity + 1}, (_, i) => i);
  }

  addDefaultItem(taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType): void {
    const existingDefaultsIds = this.defaults.map(x => x.TaskTypeId);
    if (existingDefaultsIds.includes(taskType.TaskTypeId)) {
      const defaultTaskType = this.defaults.find(x => x.TaskTypeId === taskType.TaskTypeId);
      defaultTaskType.TaskCount++;
    } else {
      const newItem = this.workDefaultFactory(taskType);
      this.partnerDefaults.push(newItem);
      this.defaults = this.getDefaults();
    }
  }

  deleteAllocation(): void {
    let msg = `Are you sure you want do delete allocations for ${ this.hours } ${ this.hours > 1 ? 'hours' : 'hour'}?`;
    bootbox.confirm(msg, (result: boolean) => {
        if (result === true) {
          this.removeHourAllocation.emit(this.hours);
        }
    });
  }

  get isAllocationValid(): boolean {
    return this.allocatedMinutes === this.minutes;
  }

  get allocatedMinutes(): number {
    if (!this.defaults.length && this.partnerSeoMinuteReservationDefault.Quantity === 0) {
      return 0;
    }

    return this.partnerSeoMinuteReservationDefault.Quantity + (this.defaults.length ? this.defaults.map(item => item.TaskType.BillableMinutes * item.TaskCount).reduce((a, b) => a + b, 0) : 0);
  }

  get minuteDifferenceMessage(): string {
    if (this.minutes > this.allocatedMinutes) {
      return `${ this.minutes - this.allocatedMinutes } Under`;
    } else {
      return `${ this.allocatedMinutes - this.minutes } Over`;
    }
  }

  get showTable(): boolean {
    return this.defaults?.some(x => x.TaskCount);
  }

  private getDefaults(): Boo.Objects.Work.PartnerSeoWorkDefault[] {
    return this.partnerDefaults.filter(x => x.Hours === this.hours)
      .sort((a, b) => a.TaskType.Name.localeCompare(b.TaskType.Name))
      .sort((a, b) => a.TaskType.BillableMinutes - b.TaskType.BillableMinutes);
  }

  private workDefaultFactory(taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType): Boo.Objects.Work.PartnerSeoWorkDefault {
    return {
      PartnerId: this.partnerId,
      TaskTypeId: taskType.TaskTypeId,
      TaskType: {
        Name: taskType.Name,
        Description: taskType.Description,
        BillableMinutes: taskType.Minutes
      },
      TaskCount: 1,
      Hours: this.hours
    } as Boo.Objects.Work.PartnerSeoWorkDefault;
  }
}
