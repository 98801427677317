<div [loadingOverlay]="isLoading">
  <div class="row">
    <div class="col-md-12">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Description</strong>
            </div>
            <div class="col-md-10" [innerHtml]="taskDescription"></div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-2">
              <strong>Instructions</strong>
            </div>
            <div class="col-md-10 preserve-whitespace">{{ taskInstructions }}</div>
          </div>
        </li>
        <ng-container *ngIf="shouldShowGoogleAnalytics">
          <li class="list-group-item">
            <div class="row">
              <div class="col-md-2">
                <strong>Google Analytics</strong>
              </div>
              <div class="col-md-10">
                <app-components-specialist-actioncomponents-googleanalyticsaccess [customer]="currentAction().Customer">
                </app-components-specialist-actioncomponents-googleanalyticsaccess>
              </div>
            </div>
            <ng-container *ngIf="creatingGoogleAnalytics">
              <div class="row">
                <div class="col-md-10 offset-md-2">
                  <strong>Analytics need to be created</strong>
                </div>
              </div>
            </ng-container>
          </li>
          <ng-container *ngIf="!creatingGoogleAnalytics && googleTag">
            <li class="list-group-item">
              <div class="row">
                <div class="col-md-2">
                  <strong>Google Tag</strong>
                </div>
                <div class="col-md-10">
                  {{ googleTag }}
                </div>
              </div>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
  <br />
  <br />
  <div class="row">
    <div class="col-md-6">
      <div>
        <app-components-specialist-taskfiles [options]="{
          taskId: currentAction().Task.TaskId,
          allowUpload: true,
          apiCallback: taskFilesApiCallback,
          useSmallTitle: true,
          acceptedFileExtensions: this.acceptedFileExtensions
        }"></app-components-specialist-taskfiles>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          Note to Reviewer
        </div>
        <div class="card-body">
          <textarea class="form-control" style="min-width: 100%" [formControl]="noteToReviewerCtrl" rows="5"
            lpValidationMsg></textarea>
        </div>
      </div>
    </div>
  </div>
  <app-floating-panels *ngIf="!isLoading">
    <app-floating-panel [displayConfig]="{ title: 'External Access', icon: 'fa-key', size: 'floating-panel-md' }">
      <compose *floatingPanelContent Selector="app-components-managecustomer-readonlyexternalaccess" [Model]="{ customer: currentAction().Customer }">
      </compose>
    </app-floating-panel>
    <app-floating-panel [displayConfig]="{ title: 'Comments', icon: 'fa-comments', badgeCount: currentAction().TaskDetails.TaskNotes.length }">
      <app-components-specialist-actioncomponents-comments *floatingPanelContent [comments]="currentAction().TaskDetails.TaskNotes">
      </app-components-specialist-actioncomponents-comments>
    </app-floating-panel>
    <app-floating-panel *ngIf="rejection" [displayConfig]="{ title: 'Rejection Information', icon: 'fa-clipboard'}">
      <app-components-specialist-actioncomponents-rejection *floatingPanelContent [rejection]="rejection.Rejection">
      </app-components-specialist-actioncomponents-rejection>
    </app-floating-panel>
  </app-floating-panels>
</div>