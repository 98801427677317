import { Component } from '@angular/core';
import System from 'framework/System';
import IPriorityViewPublicApi = app.interfaces.IPriorityViewPublicApi;

type ContentReviewBlogInput = Boo.Tasks.Inputs.Models.ContentReviewBlogInput;
type ContentReviewBlogOutput = Boo.Tasks.Outputs.Models.ContentReviewBlogOutput;

@Component({
  selector: 'app-components-taskfeedback-standardbacklink',
  templateUrl: './standard-backlink-feedback.component.html'
})
export class StandardBacklinkFeedback implements IActivatable {
  input: ContentReviewBlogInput;
  output: ContentReviewBlogOutput;
  
  editorToolbar: any = {
    toolbar: [
      {
        name: 'document',
        items: ['Source', 'Maximize']
      },
      {
        name: 'editing',
        items: ['SelectAll']
      },
      {
        name: 'clipboard',
        items: ['Copy']
      }
    ],
    readOnly: true,
    allowedContent: true
  };
  
  private priorityViewApi: IPriorityViewPublicApi;
  
  activate(params: FeedbackActivateParams): JQueryPromise<any> {
    this.priorityViewApi = params.priorityViewApi;
    this.input = params.history.Input as ContentReviewBlogInput;
    this.output = params.history.Output as ContentReviewBlogOutput;
    return System.emptyPromise();
  }

  close() {
    this.priorityViewApi.resolve();
  }
}

interface FeedbackActivateParams {
  history: Boo.Objects.TaskHistory;
  priorityViewApi?: app.interfaces.IPriorityViewPublicApi;
}
