import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogDomainService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/blogdomain`;

  constructor(private http: HttpClient) { }

  status(workflowId: number): Observable<Boo.Blogs.DomainPurchaseStatus> {
    const params = new HttpParams().set('workflowId', workflowId);

    return this.http.get<Boo.Blogs.DomainPurchaseStatus>(`${this.baseUrl}/Status`, { params: params });
  }

  purchase(request: Boo.Blogs.Models.DomainPurchaseRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/Purchase`, request);
  }
}
