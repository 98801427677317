import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TaskService } from '../../../../services/task.service';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-components-shared-task-approval',
  templateUrl: './task-approval.component.html',
  styleUrls: ['../customer-campaign/customer-campaign.component.scss']
})
export class TaskApprovalComponent {
  @Input() websiteUrlTask: Boo.Objects.WebsiteUrlTask;
  @Input() restrictions: Boo.Objects.Enums.CustomerCampaignRestrictions[];
  @Input() reviewSource: Boo.OnsiteRecommendations.Models.Enums.ReviewSources;
  @ViewChild('approveModal', { static: false }) approveModal: TemplateRef<any>;
  @ViewChild('rejectModal', { static: false }) rejectModal: TemplateRef<any>;
  @Output() taskModified = new EventEmitter<number>();
  @Output() taskActionPending = new EventEmitter<boolean>();

  customerCampaignRestrictions_DisableTaskActions = Boo.Objects.Enums.CustomerCampaignRestrictions.DisableTaskActions;
  responsibilities_Boost = Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Boostability;
  rejectionReasonControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(4000)]);
  approvalNoteControl: UntypedFormControl = new UntypedFormControl('', [Validators.maxLength(4000)]);
  modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private taskService: TaskService) { }

  approveTask() {
    this.approvalNoteControl.markAsTouched();
    this.approvalNoteControl.updateValueAndValidity();
    if (this.approvalNoteControl.invalid) {
      return;
    }
    this.dismissReason();
    this.taskActionPending.emit(true);
    this.taskService.onsiteRecommendationCsrApproval(this.websiteUrlTask.TaskId, true, this.approvalNoteControl.value, this.reviewSource).subscribe(
      (result)=> {
        if (result) {
          toastr.success('Task approved');
          this.taskModified.emit(this.websiteUrlTask.TaskId);
        } else {
          toastr.error('There was an error approving the task.');
        }
      });
  }

  approveTaskWithNote() {
    this.modalRef = this.modalService.open(this.approveModal);
  }

  rejectTask() {
    this.rejectionReasonControl.markAsTouched();
    this.rejectionReasonControl.updateValueAndValidity();
    if (this.rejectionReasonControl.invalid) {
      return;
    }
    this.taskActionPending.emit(true);
    this.taskService.onsiteRecommendationCsrApproval(this.websiteUrlTask.TaskId, false, this.rejectionReasonControl.value, this.reviewSource).subscribe(
      (result)=> {
        if (result) {
          this.dismissReason();
          toastr.success('Task rejected');
          this.taskModified.emit(this.websiteUrlTask.TaskId);
        } else {
          toastr.error('There was an error rejecting the task.');
        }
      });
    this.dismissReason();
  }

  rejectTaskWithReason() {
    this.modalRef = this.modalService.open(this.rejectModal);
  }

  resetControls() {
    this.approvalNoteControl.reset('');
    this.rejectionReasonControl.reset('');
  }

  dismissReason() {
    this.resetControls();
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }
}
