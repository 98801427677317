<div id="keyworddiscovery-component">
	<app-components-togglepanel [(isOpen)]="isOpen" title="Keyword Discovery">
		<div class="row">
			<div class="col-md-4">
				<div class="form-group">
					<label>
						Verify if the campaign is local or national
						<app-components-helptext [key]="'Keywords_VerifyCampaignType'"></app-components-helptext>
					</label>
					<select [dataValidation]="{ placement: 'top', value: customer.IsCampaignNational }" [ngModel]="customer.IsCampaignNational()" (ngModelChange)="customer.IsCampaignNational($event)" [ngModelOptions]="{ standalone: true }" [disabled]="!(canEdit)" class="form-select">
						<option *ngFor="let option of isCampaignNationalOptions" [ngValue]="option.value">{{ option.name }}</option>
					</select>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-8">
				<ng-container *ngIf="!(customer.IsCampaignNational())">
					<div class="form-group">
						<label class="control-label">Specify at least one and up to five locations to be researched for SEO purposes (in order of preference) with the primary or physical location of the business listed first</label>
						<textarea [dataValidation]="{placement:'top', value: locationsNote }" [ngModel]="locationsNote()" (ngModelChange)="locationsNote($event)" [ngModelOptions]="{ standalone: true }" class="form-control" rows="5"></textarea>
					</div>
				</ng-container>
				<div class="form-group">
					<label class="control-label">Specify at least one and up to five products or services to be researched for SEO purposes in order of preference</label>
					<textarea [dataValidation]="{placement:'top', value: productsAndServicesNote }" [ngModel]="productsAndServicesNote()" (ngModelChange)="productsAndServicesNote($event)" [ngModelOptions]="{ standalone: true }" class="form-control" rows="5"></textarea>
				</div>
				<div class="form-group">
					<label class="control-label">Specify any locations, products or services that should be EXCLUDED from consideration in keyword research</label>
					<textarea [ngModel]="exclusionNote()" (ngModelChange)="exclusionNote($event)" [ngModelOptions]="{ standalone: true }" class="form-control" rows="5"></textarea>
				</div>
				<div class="form-group">
					<label class="control-label">Provide any additional information that you believe should be considered and would be useful in the keyword research process</label>
					<textarea [ngModel]="additionalInformationNote()" (ngModelChange)="additionalInformationNote($event)" [ngModelOptions]="{ standalone: true }" class="form-control" rows="5"></textarea>
				</div>
			</div>
		</div>
	</app-components-togglepanel>
</div>
