<app-components-specialist-internal-tasks-qa-action-information
  [internalAction]="internalAction"></app-components-specialist-internal-tasks-qa-action-information>
<div id="internal-task-qa-criteria-float-panel" class="float-panel">
  <div class="float-panel-tab">
    <button class="float-panel-btn-expand btn btn-lg btn-primary">
      <i class="fa fa-check"></i>
    </button>
  </div>
  <div class="float-panel-main card">
    <div class="float-panel-draggable card-header">
      <div class="card-title">
        <i class="fa fa-check"></i>
        Profile Submission Quality
        <i class="float-panel-btn-collapse fa fa-minus float-end"></i>
      </div>
    </div>
    <div class="card-body" style="overflow-y: scroll; max-height: 550px;">
      <div class="row">
        <div class="col-md-12">
          <ul class="list-group">
            <li class="list-group-item">
              <a [externalLink]="{ url: copyScapeUrl, text: 'Copyscape' }"></a>
            </li>
          </ul>
        </div>
      </div>
      <app-components-qa-grading-area-panel [taskType]="taskType"
        [qaCriteriaGroups]="qaCriteriaGroups" #gradingArea>
      </app-components-qa-grading-area-panel>
    </div>
    <div class="card-footer">
      <div class="text-end">
        <button (click)="close.next()" class="btn btn-primary">Close</button>
        <button (click)="unlock.next()" class="btn btn-info">
          <i class="fa fa-unlock"></i>
          Unlock &amp; Unassign
        </button>
        <button (click)="complete.next()" class="btn btn-success">
          <i class="fa fa-check"></i>
          Complete
        </button>
      </div>
    </div>
  </div>
</div>
<app-components-floatingtabpanel
  [options]="{  verticalPanelOffset: 1,  apiCallback: floatingTabPanelApiCallback.bind(this)}"></app-components-floatingtabpanel>
<div [hidden]="!showPlagiarismTable" class="row">
  <div class="col-md-12">
    <div class="card card-danger">
      <div class="card-header">
        <div>Duplicate Content Warning</div>
      </div>
      <div class="card-body">
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th>Url</th>
              <th>Percentage</th>
              <th>Matched Text</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="contentAuditUrl1">
              <td>
                <a [externalLink]="{ url: contentAuditUrl1, text:contentAuditUrl1}"></a>
              </td>
              <td class="text-danger">{{(contentAuditUrl1Percentage * 100) + '%'}}</td>
              <td>
                <span [ngbPopover]="contentAuditUrl1MatchedText" popoverTitle="Matched Text" placement="right auto"
                  triggers="mouseenter:mouseleave">
                  <i class="far fa-file-alt"></i>
                  (Hover for Matched Text)
                </span>
              </td>
            </tr>
            <tr *ngIf="contentAuditUrl2">
              <td>
                <a [externalLink]="{ url: contentAuditUrl2, text:contentAuditUrl2}"></a>
              </td>
              <td class="text-danger">{{(contentAuditUrl2Percentage * 100) + '%'}}</td>
              <td>
                <span [ngbPopover]="contentAuditUrl2MatchedText" popoverTitle="Matched Text" placement="right auto"
                  triggers="mouseenter:mouseleave">
                  <i class="far fa-file-alt"></i>
                  (Hover for Matched Text)
                </span>
              </td>
            </tr>
            <tr *ngIf="contentAuditUrl3">
              <td>
                <a [externalLink]="{ url: contentAuditUrl3, text:contentAuditUrl3}"></a>
              </td>
              <td class="text-danger">{{(contentAuditUrl3Percentage * 100) + '%'}}</td>
              <td>
                <span [ngbPopover]="contentAuditUrl3MatchedText" popoverTitle="Matched Text" placement="right auto"
                  triggers="mouseenter:mouseleave">
                  <i class="far fa-file-alt"></i>
                  (Hover for Matched Text)
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="form-group col-md-12">
    <label>Research Sources</label>
    <div name="researchSources" *ngFor="let researchSource of researchSources">
      <a *ngIf="isUrl(researchSource)" href="{{researchSource}}">{{researchSource}}</a>
      <span *ngIf="!isUrl(researchSource)">{{researchSource}}</span>
    </div>
  </div>
  <div class="form-group col-md-12">
    <label>Page Title - Character Count: {{pageTitle?.length || 0}}</label>
    <input readonly [ngModel]="pageTitle" type="text" class="form-control" />
  </div>
  <div class="form-group col-md-12">
    <label>Meta Description - Character Count: {{metaDescription?.length || 0}}</label>
    <textarea readonly [ngModel]="metaDescription" class="form-control" style="height:auto;" rows="3"></textarea>
  </div>
  <div class="form-group col-md-12">
    <label>H2 - Character Count: {{h2?.length || 0}}</label>
    <input readonly [ngModel]="h2" type="text" class="form-control" name="h2" />
  </div>
</div>
<div class="row">
  <ng-container *ngIf="wasAccepted">
    <div class="col-md-6">
      <div class="form-group">
        <label for="title" class="control-label">Writer's Title - Character Count: {{originalContentTitle?.length ||
          0}}</label>
        <input readonly [ngModel]="originalContentTitle" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          name="title" class="form-control" placeholder="Enter Title" />
      </div>
      <div class="form-group">
        <label for="title" class="control-label">Writer's Blog</label>
        <div class="clear-fix"></div>
        <div>
          <textarea #editorRichText
            [richText]="{ content: originalContent, options: editorToolbar, wordCount: originalWordCount, autoGrow: true }"
            name="originalContent" rows="15" style="height: 300px;" class="form-control"
            placeholder="Enter Content"></textarea>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="title" class="control-label">Editor's Title - Character Count: {{reviewedContentTitle?.length ||
          0}}</label>
        <input readonly [ngModel]="reviewedContentTitle" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          name="title" class="form-control" placeholder="Enter Title" />
      </div>
      <div class="form-group">
        <label class="control-label">Editor's Blog</label>
        <div class="clear-fix"></div>
        <div>
          <textarea #editorRichText [richText]="{ content: reviewedContent, options: editorToolbar, autoGrow: true }"
            name="reviewedContent" rows="15" style="height: 300px;" class="form-control" placeholder="Enter Content">
          </textarea>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!wasAccepted">
    <div class="col-md-12">
      <div class="form-group">
        <label for="title" class="control-label">Writer's Title - Character Count: {{originalContentTitle?.length ||
          0}}</label>
        <input readonly [ngModel]="originalContentTitle" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          name="title" class="form-control" placeholder="Enter Title" />
      </div>
      <div class="form-group">
        <label for="title" class="control-label">Writer's Blog</label>
        <div class="clear-fix"></div>
        <div>
          <textarea #editorRichText [richText]="{ content: originalContent, options: editorToolbar, autoGrow: true }"
            name="originalContent" rows="15" style="height: 300px;" class="form-control"
            placeholder="Enter Content"></textarea>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="row">
  <div *ngIf="imageUrl" class="form-group col-md-12">
    <label for="contentTags" class="control-label">Image</label>
    <div>
      <img class="img-thumbnail" src="{{imageUrl}}" width="645">
    </div>
  </div>
  <div class="form-group col-md-12">
    <label for="title" class="control-label">Editor Feedback</label>
    <div class="clear-fix"></div>
    <div>
      <textarea #editorRichText [richText]="{ content: feedback, options: editorToolbar, autoGrow: true }"
        name="editorsFeedback" rows="15" style="height: 300px;" class="form-control"
        placeholder="Enter Content"></textarea>
    </div>
  </div>
  <div *ngIf="noteToQa" class="form-group col-md-12">
    <label>Note to QA</label>
    <input readonly [ngModel]="noteToQa" type="text" class="form-control" name="noteToQa" />
  </div>
</div>
