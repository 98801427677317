<div class="form-group">
  <label class="control-label">Customer Contact Preferences <app-components-helptext [key]="'CustomerContactNote'">
    </app-components-helptext></label>
  <div [loadingOverlay]="isLoading" class="card-body">
    <div>
      <label>Notes</label>
      <textarea [formControl]="noteCtrl" name="customerUserNote" class="form-control" lpValidationMsg></textarea>
    </div>
    <div class="form-row pt-3">
      <div class="col-md-3">
        <label class="control-label">Monthly Checkup</label>
        <ng-select [items]="contactTypes" placeholder="Select Contact Preference" [clearable]="false" bindLabel="name"
          bindValue="id" [(ngModel)]="customerContactPreferences.MonthlyCheckup" [disabled]="isReadOnly"></ng-select>
      </div>
    </div>
    <button *ngIf="!isReadOnly" [disabled]="isLoading" (click)="save()" class="btn btn-success float-end">
      <i class="fa fa-save"></i>
      Update Preferences
    </button>
  </div>
</div>
