import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import ODataQuery from 'framework/data/ODataQuery';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  private baseUrl: string = `${environment.urlConfig.serviceUrl}/jobs`;

  constructor(private http: HttpClient) { }

  getMany(startDate: moment.Moment, endDate: moment.Moment): ODataQuery<framework.data.IPageResult<Boo.Objects.Jobs.Job>> {
    const request = {
      url: `${this.baseUrl}/getMany`,
      parameters: [
        { name: 'startDate', value: startDate.toISOString() },
        { name: 'endDate', value: endDate.toISOString() }
      ]
    };

    return new ODataQuery<framework.data.IPageResult<Boo.Objects.Jobs.Job>>(this.http, request);
  }

  getJobStatusCounts(statusIds: Boo.Objects.Jobs.Enums.JobStatuses[], startDate: moment.Moment, endDate: moment.Moment): Observable<Boo.Objects.Jobs.Projections.JobStatusDateTotal[]> {
    return this.http.post<Boo.Objects.Jobs.Projections.JobStatusDateTotal[]>(`${this.baseUrl}/getJobStatusCounts`, {
        startDate: new Date(launchpad.utils.getMomentAsUTC(startDate).format('MM/DD/YYYY hh:mm:ss')),
        endDate: new Date(launchpad.utils.getMomentAsUTC(endDate).format('MM/DD/YYYY hh:mm:ss')),
        statusIds: statusIds
    });
  }

  requeue(hangfireJobId: string, jobId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/requeue`, {
        hangfireJobId: hangfireJobId,
        jobId: jobId
    });
  }

  delete(jobId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/delete`, jobId);
  }

  getJobStatusSummaryByType(startDate: moment.Moment, endDate: moment.Moment): Observable<Boo.Objects.JobStatusSummaryByType[]> {
    const params = new HttpParams()
      .set('startDate', launchpad.utils.getMomentAsUTC(startDate).format('MM/DD/YYYY h:mm:ss'))
      .set('endDate', launchpad.utils.getMomentAsUTC(endDate).format('MM/DD/YYYY h:mm:ss'));

    return this.http.get<Boo.Objects.JobStatusSummaryByType[]>(`${this.baseUrl}/getJobStatusSummaryByType`, { params: params });
  }

  enqueue(job: Boo.Objects.Jobs.Job): Observable<any> {
    return this.http.post(`${this.baseUrl}/enqueue`, job);
  }

  getCount(jobTypeId: number, jobStatusId: Boo.Objects.Jobs.Enums.JobStatuses, jobPriorityId: Boo.Objects.Jobs.Enums.JobPriorities, startDate: moment.Moment, endDate: moment.Moment): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/getCount`, {
        jobTypeId: jobTypeId,
        jobStatusId: jobStatusId,
        jobPriorityId: jobPriorityId,
        startDate: new Date(launchpad.utils.getMomentAsUTC(startDate).format('MM/DD/YYYY h:mm:ss')),
        endDate: new Date(launchpad.utils.getMomentAsUTC(endDate).format('MM/DD/YYYY h:mm:ss'))
    });
  }

  requeueMany( jobTypeId: number, jobStatusId: Boo.Objects.Jobs.Enums.JobStatuses, jobPriorityId: Boo.Objects.Jobs.Enums.JobPriorities, startDate: moment.Moment, endDate: moment.Moment): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/requeueMany`, {
        jobTypeId: jobTypeId,
        jobStatusId: jobStatusId,
        jobPriorityId: jobPriorityId,
        startDate: new Date(launchpad.utils.getMomentAsUTC(startDate).format('MM/DD/YYYY h:mm:ss')),
        endDate: new Date(launchpad.utils.getMomentAsUTC(endDate).format('MM/DD/YYYY h:mm:ss'))
    });
  }

  deleteMany( jobTypeId: number, jobStatusId: Boo.Objects.Jobs.Enums.JobStatuses, jobPriorityId: Boo.Objects.Jobs.Enums.JobPriorities, startDate: moment.Moment, endDate: moment.Moment): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/deleteMany`, {
        jobTypeId: jobTypeId,
        jobStatusId: jobStatusId,
        jobPriorityId: jobPriorityId,
        startDate: new Date(launchpad.utils.getMomentAsUTC(startDate).format('MM/DD/YYYY h:mm:ss')),
        endDate: new Date(launchpad.utils.getMomentAsUTC(endDate).format('MM/DD/YYYY h:mm:ss'))
    });
  }
}
