import { Component } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { ActionService } from '../../../services/action.service';
import Utils from '../../../shared/utils';
import System from 'framework/System';

@Component({
  selector: 'app-components-specialist-viewtaskworkspace',
  templateUrl: './ViewTaskWorkSpace.component.html'
})
export class ViewTaskWorkSpaceComponent implements IActivatable {
  public isLoading: KnockoutObservable<boolean>;
  public taskId: KnockoutObservable<number>;

  constructor(
    private actionService: ActionService) { }

  public activate(): JQueryPromise<any> {
    this.taskId = ko.observable(null);
    this.isLoading = ko.observable(false);
    return System.emptyPromise();
  }

  public viewWorkSpace(): void {
    if (!this.taskId()) {
      toastr.error('Please enter a Task ID');
      return;
    }
    this.isLoading(true);
    Utils.wrapDfd(this.actionService.getByTaskId(this.taskId()))
      .then((data) => {
        if (!data || !data.Task) {
          toastr.warning('Task action not found.');
        } else {
          PriorityViewModel.show('app-components-specialist-actionworkspace', { currentAction: data, isReadOnly: true })
            .done((result: any) => {
              this.isLoading(false);
            });
        }
      })
      .fail((msg: string) => {
        toastr.error(msg);
      }).always(() => {
        this.isLoading(false);
      });
  }
}
