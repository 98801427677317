<div class="card">
  <div class="card-header">Registration Phone Numbers</div>
  <div [loadingOverlay]="isLoading()" class="card-body">
    <div class="row">
      <div class="col-md-8">
        <app-table [items]="registrationPhoneNumbers" [exportFilename]="'registration-phone-numbers'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="PhoneNumber">Phone Number</th>
              <th export sort field="TotalUsageGoogle">Google Total</th>
              <th export sort field="IsActiveGoogle">Google Is Active</th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-phoneNumber>
            <tr>
              <td>{{phoneNumber.PhoneNumber}}</td>
              <td>{{phoneNumber.TotalUsageGoogle}}</td>
              <td>{{phoneNumber.IsActiveGoogle| yesNo}}</td>
            </tr>
          </ng-template>
        </app-table>
      </div>
      <div class="col-md-4">
        <div class="card card-success">
          <div class="card-header">
            Upload
            <small>(Phone numbers must be 10 digits)</small>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="input-group">
                  <input
                    [fileReader]="{ data: contents, filename: filename, file: file, accept: 'CSV (*.csv)| *.csv', method: 'readAsText'}"
                    type="file" class="form-control float-end" />
                  <button [disabled]="!isUploadButtonEnabled()" (click)="upload()" class="btn btn-primary">
                    <i class="fa fa-upload"></i>
                    Upload
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="alert alert-warning">
                  <strong>Warning:</strong>
                  Uploading will deactivate all existing registration numbers.
                </div>
              </div>
            </div>
            <ng-container *ngIf="phoneNumbers().length > 0">
              <div class="row">
                <div class="col-md-12">
                  <p class="text-end">
                    <small>Count</small>
                    <span class="badge bg-secondary">{{phoneNumbers().length}}</span>
                  </p>
                </div>
              </div>
            </ng-container>
          </div>
          <ul class="list-group" style="max-height:300px; overflow:scroll; overflow-x:hidden">
            <ng-container *ngFor="let $data of phoneNumbers()">
              <li class="list-group-item">{{$data}}</li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>