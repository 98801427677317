import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import { FrontService } from '../../../services/front.service';
import { CustomerNotificationService } from '../../../services/customer-notification.service';
import Utils from '../../../shared/utils';
import { CustomerUserService } from '../../../services/customer-user.service';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import System from 'framework/System';
import IValidatedResult = app.interfaces.IValidatedResult;
import ISaveData = app.ticket.interfaces.ISaveData;
import { FormBuilder, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-components-ticket-discovery-email',
  templateUrl: './discovery-email.component.html'
})
export class DiscoveryEmailComponent extends BaseComponent {
  minDate: Date = new Date();
  isPartnerConfigured: boolean = false;
  isUserConfigured: boolean = false;
  isCustomerConfigured: boolean = false;
  frontConfigurationIsValid: boolean = false;
  canSendDiscoveryEmail: boolean = false;
  isOpen: boolean = true;

  forms = this.fb.group({
    frontNote: ['', Validators.required],
    date: [new Date(), Validators.required],
    timeZone: ['', Validators.required]
  })

  get timeZone() {
    return this.forms.get('timeZone');
  }

  get frontNote() {
    return this.forms.get('frontNote');
  }

  get date() {
    return this.forms.get('date');
  }

  constructor(
    private customerNotificationService: CustomerNotificationService,
    private customerUserService: CustomerUserService,
    private frontService: FrontService,
    private fb: FormBuilder
  ) { 
    super();
  }

  public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
    return super.activate(params).then(() => {
      return Utils.wrapDfd(forkJoin([
        this.frontService.getPartnerChannel(this.customer.PartnerId()),
        this.frontService.getUserTeammateId(this.user.UserId),
        this.customerUserService.getForCustomer(this.customer.CustomerId()),
        this.customerNotificationService.CanSendDiscoveryEmail(this.customer.CustomerId())
      ]));
    }).then(([partnerChannel, teammateId, customerUsers, canSendDiscoveryEmail]) => {
      this.timeZone.setValue(this.customer.TimeZone());
      this.isPartnerConfigured = !!partnerChannel;
      this.isUserConfigured = !!teammateId;
      this.isCustomerConfigured = customerUsers.some(x => !!x.CanContact && x.EmailRecipientType === Boo.Objects.Enums.EmailRecipientTypes.To)
      this.frontConfigurationIsValid = this.isPartnerConfigured && this.isUserConfigured && this.isCustomerConfigured;
      this.canSendDiscoveryEmail = canSendDiscoveryEmail;
    });
  }

  validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
    if (saveType !== SaveTypes.Complete) {
      return System.resolvedPromise<IValidatedResult>( { isValid: true } );
    }

    let validatedResult: IValidatedResult = { 
      isValid: !this.canSendDiscoveryEmail || !this.frontConfigurationIsValid || this.forms.valid,
      errorMessages: ['Email fields are required'] 
    }

    if (!validatedResult.isValid) {
      this.isOpen = true;
    }

    return System.resolvedPromise<IValidatedResult>(validatedResult);
  }

  public save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
    return super.save(saveType)
      .then(() => this.canSendDiscoveryEmail && this.frontConfigurationIsValid && saveType === SaveTypes.Complete
        ? Utils.wrapDfd(this.frontService.sendDiscoveryEmail(this.customer.CustomerId(), this.frontNote.value, this.date.value))
        : System.emptyPromise())
      .then(() => {
        return <ISaveData>{
          customer: {
              TimeZone: this.timeZone.value
          }
        };
      })
  }


  confirmSave(_: SaveTypes): JQueryPromise<boolean> {
    return System.resolvedPromise(true);
  }
}
