<div class="card">
	<div class="card-header">
		Republish Post
	</div>
	<div [loadingOverlay]="isLoading()" class="card-body">
		<div class="row">
			<div class="offset-md-2 col-md-8">
				<div class="input-group">
					<span class="input-group-text">Task Action URL</span>
					<input [dataValidation]="{ placement: 'top', value: taskActionURL }" [ngModel]="taskActionURL()" (ngModelChange)="taskActionURL($event)" [ngModelOptions]="{ standalone: true }" [returnKey]="republish.bind(this)" class="form-control" type="text"/>
					<button [disabled]="isLoading()" (click)="republish()" class="btn btn-success">
						<i class="fas fa-sync"></i>
						Republish Post
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
