<div class="card">
  <div class="card-header">
    Assigned Task
  </div>
  <div [loadingOverlay]="isLoading()" class="card-body">
    <div class="row">
      <div class="col-md-5 form-group">
        <label>Filter by Task ID, Customer ID, Name, or URL</label>
        <input [ngModel]="filter()" (ngModelChange)="filter($event)" [ngModelOptions]="{ standalone: true }" type="text"
          class="form-control" />
      </div>
      <div class="col-md-2 offset-md-5 text-end">
        <div class="form-group">
          <label>&nbsp;</label>
          <button (click)="getNextTaskInQueue()" class="btn btn-danger form-control">GET NEXT ACTION</button>
        </div>
      </div>
    </div>
    <hr />
    <app-table [items]="filteredTasks()" [pageSize]="25" [exportFilename]="'assigned-tasks'">
      <ng-template #tableHeader>
        <tr>
          <th export sort field="TaskId">Task ID</th>
          <th export sort field="CustomerId">Customer ID</th>
          <th export sort field="TaskTypeName">Task Type Name</th>
          <th export sort field="CustomerUrl">Customer URL</th>
          <th export sort field="TaskDate">Task Date</th>
          <th export sort field="StatusDate">Status Date</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-task>
        <tr [class.animated]="mostRecentSelectedTaskId() === task.TaskId"
          [class.flash]="mostRecentSelectedTaskId() === task.TaskId">
          <td>{{task.TaskId}}</td>
          <td>{{task.CustomerId}}</td>
          <td>{{task.TaskTypeName}}</td>
          <td>
            <a [externalLink]="{ url: task.CustomerUrl, text: task.CustomerUrl }" rel="noreferrer"></a>
          </td>
          <td>{{task.TaskDate | dateText }}</td>
          <td>{{task.StatusDate | dateText }}</td>
          <td>
            <button (click)="openAction(task)" class="btn btn-primary">
              <i class="fas fa-external-link-square-alt"></i>
              Open
            </button>
          </td>
        </tr>
      </ng-template>
    </app-table>
  </div>
</div>