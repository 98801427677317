<app-components-specialist-internal-tasks-qa-action-information
  [internalAction]="internalAction"></app-components-specialist-internal-tasks-qa-action-information>
<div id="internal-task-qa-criteria-float-panel" class="float-panel">
  <div class="float-panel-tab">
    <button class="float-panel-btn-expand btn btn-lg btn-primary">
      <i class="fa fa-check"></i>
    </button>
  </div>
  <div class="float-panel-main card">
    <div class="float-panel-draggable card-header">
      <div class="card-title">
        <i class="fa fa-check"></i>
        Profile Submission Quality
        <i class="float-panel-btn-collapse fa fa-minus float-end"></i>
      </div>
    </div>
    <div class="card-body" style="overflow-y: scroll; max-height: 550px;">
      <div class="row">
        <div class="col-md-12">
          <ul class="list-group">
            <li class="list-group-item">
              <a [externalLink]="{ url: copyScapeUrl, text: 'Copyscape' }"></a>
            </li>
          </ul>
        </div>
      </div>
      <app-components-qa-grading-area-panel [taskType]="taskType"
        [qaCriteriaGroups]="qaCriteriaGroups" #gradingArea>
      </app-components-qa-grading-area-panel>
    </div>
    <div class="card-footer">
      <div class="text-end">
        <button (click)="close.next()" class="btn btn-primary">Close</button>
        <button (click)="unlock.next()" class="btn btn-info">
          <i class="fa fa-unlock"></i>
          Unlock &amp; Unassign
        </button>
        <button (click)="complete.next()" class="btn btn-success">
          <i class="fa fa-check"></i>
          Complete
        </button>
      </div>
    </div>
  </div>
</div>
<app-components-floatingtabpanel [options]="{
  apiCallback: floatingTabPanelApiCallback.bind(this),
  verticalPanelOffset: 1
}"></app-components-floatingtabpanel>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Instructions</h3>
      </div>
      <div class="card-body">
        <div *ngIf="lastNote" class="form-group">
          <div class="alert alert-warning">
            <div class="row">
              <div class="col-md-8">
                <div>
                  <strong>{{lastNote.FromUserFullName}}</strong>
                </div>
                <small>
                  to
                  <span>{{lastNote.ToUserFullName}}</span>
                </small>
              </div>
              <div class="col-md-4 text-end">
                <small>{{lastNote.InsertedDate | dateText }}</small>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="list-group-item-text">
                  <div style="padding-top:15px; padding-bottom:15px;">{{lastNote.Note}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>URL</label>
          <div class="form-control" disabled="">
            <a [externalLink]="{ url: url, text: url }" rel="noreferrer" style="cursor: pointer;"></a>
          </div>
        </div>
        <div class="form-group">
          <label>Page Type</label>
          <div>{{pageType}}</div>
        </div>
        <div class="form-group">
          <label>Recommended Word Count</label>
          <div>{{recommendedWordCount}}</div>
        </div>
        <div class="form-group">
          <div class="form-group">
            <label [hidden]="!(orderReason !== 'New Page' && orderReason !== 'Existing Page')">Reason for ordering the
              copy</label>
            <label [hidden]="!(orderReason === 'New Page' || orderReason === 'Existing Page')">Where will this copy be
              published?</label>
            <div>{{orderReason}}</div>
          </div>
        </div>
        <div class="form-group">
          <label>Content Writer Instructions</label>
          <div>{{instructions}}</div>
        </div>
        <div class="form-group">
          <label>Will Boostability implement this piece of copy?</label>
          <div>{{boostToPublish ? 'Yes' : 'No'}}</div>
        </div>
        <div class="form-group">
          <label>Notes for Implementation Specialist</label>
          <div>{{implementationInstructions}}</div>
        </div>
        <div class="form-group">
          <label>Page Strategy</label>
          <div name="pageStrategy">{{pageStrategy}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label for="title" class="control-label">Writer's Copy</label>
      <div class="clear-fix"></div>
      <div>
        <textarea #editorRichText
          [richText]="{ content: originalContent, options: editorToolbar, wordCount: originalWordCount, autoGrow: true }"
          name="originalContent" rows="15" style="height: 300px;" class="form-control"
          placeholder="Enter Content"></textarea>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label class="control-label">Editor's Copy</label>
      <div class="clear-fix"></div>
      <div>
        <textarea #editorRichText
          [richText]="{ content: reviewedContent, options: editorToolbar, wordCount: reviewedWordCount, autoGrow: true }"
          name="reviewedContent" rows="15" style="height: 300px;" class="form-control" placeholder="Enter Content">
          </textarea>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-8">
    <div class="form-group">
      <div *ngIf="wasAccepted" class="alert alert-success">Accepted</div>
      <div *ngIf="!wasAccepted" class="alert alert-danger">Rejected</div>
    </div>
    <div class="form-group">
      <label>Writer Feedback</label>
      <textarea readonly [ngModel]="feedback" [ngModelOptions]="{ standalone: true }" class="form-control"
        name="editorsFeedback" style="height: 75px;"></textarea>
    </div>
    <div *ngIf="noteToQa" class="form-group">
      <label>Note to QA</label>
      <input readonly [(ngModel)]="noteToQa" type="text" class="form-control" name="noteToQa" autocomplete="off" />
    </div>
  </div>
</div>
<div class="card">
  <div class="card-header">
    <h3 class="card-title">Content Recommendations</h3>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <table id="keywordsTable" class="table table-striped table-bordered table-condensed">
          <thead>
            <tr>
              <th>Keyword(s)</th>
              <th>Area</th>
              <th>Title</th>
              <th>Description</th>
              <th>H1</th>
              <th>H2-H6</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let $data of keywordSiteAnalyses">
              <tr>
                <td>
                  <a [attr.href]="googleSearchUrl + 'search?pws=0&q=' +  ($data.IsAreaFirst ? $data.Area + ' ' + $data.Keyword : $data.Keyword + ' ' + $data.Area).trim()"
                    target="_blank" name="keyword">{{$data.Keyword}}</a>
                </td>
                <td>{{$data.Area}}</td>
                <td>{{$data.TitleCount}}</td>
                <td>{{$data.DescriptionCount}}</td>
                <td>{{$data.H1Count}}</td>
                <td>{{$data.H2H6Count}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <ng-container *ngIf="!hasOnsiteRecommendationEditHistory">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Current Title Tag Text - Character Count: {{originalTitleTag?.length ||
              0}}</label>
            <textarea [ngModel]="originalTitleTag" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px; resize: vertical;" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Reviewed Title Tag Text - Character Count: {{reviewedTitleTag?.length ||
              0}}</label>
            <textarea [ngModel]="reviewedTitleTag" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px;" class="form-control" name="newTitle"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Current Meta Description - Character Count: {{originalMetaDescription?.length
              || 0}}</label>
            <textarea [ngModel]="originalMetaDescription" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px; resize: vertical;" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Reviewed Meta Description - Character Count: {{reviewedMetaDescription?.length
              || 0}}</label>
            <textarea [ngModel]="reviewedMetaDescription" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px;" class="form-control" name="newMetaDescription"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Current Heading Tags</label>
            <textarea [ngModel]="originalHeaders" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px; resize: vertical;" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Reviewed H1 Tag - Character Count: {{reviewedH1?.length || 0}}</label>
            <textarea [ngModel]="reviewedH1" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px; resize: vertical;" class="form-control" name="newH1"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Reviewed H2 Tag - Character Count: {{reviewedH2?.length || 0}}</label>
            <textarea [ngModel]="reviewedH2" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px; resize: vertical;" class="form-control" name="newH2"></textarea>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="hasOnsiteRecommendationEditHistory">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Current Title Tag Text - Character Count: {{originalTitleTag?.length ||
              0}}</label>
            <textarea [ngModel]="originalTitleTag" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px; resize: vertical;" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Submitted Title Tag Text - Character Count: {{submittedTitleTag?.length ||
              0}}</label>
            <textarea [ngModel]="submittedTitleTag" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px;" class="form-control" name="newTitle"></textarea>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Reviewed Title Tag Text - Character Count: {{reviewedTitleTag?.length ||
              0}}</label>
            <textarea [ngModel]="reviewedTitleTag" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px;" class="form-control" name="newTitle"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Current Meta Description - Character Count: {{originalMetaDescription?.length
              || 0}}</label>
            <textarea [ngModel]="originalMetaDescription" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px; resize: vertical;" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Submitted Meta Description - Character Count:
              {{submittedMetaDescription?.length || 0}}</label>
            <textarea [ngModel]="submittedMetaDescription" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px;" class="form-control" name="newMetaDescription"></textarea>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Reviewed Meta Description - Character Count: {{reviewedMetaDescription?.length
              || 0}}</label>
            <textarea [ngModel]="reviewedMetaDescription" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px;" class="form-control" name="newMetaDescription"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Current Heading Tags</label>
            <textarea [ngModel]="originalHeaders" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px; resize: vertical;" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Submitted H1 Tag - Character Count: {{submittedH1?.length || 0}}</label>
            <textarea [ngModel]="submittedH1" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px; resize: vertical;" class="form-control" name="newH1"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Reviewed H1 Tag - Character Count: {{reviewedH1?.length || 0}}</label>
            <textarea [ngModel]="reviewedH1" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px; resize: vertical;" class="form-control" name="newH1"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Submitted H2 Tag - Character Count: {{submittedH2?.length || 0}}</label>
            <textarea [ngModel]="submittedH2" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px; resize: vertical;" class="form-control" name="newH2"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">Reviewed H2 Tag - Character Count: {{reviewedH2?.length || 0}}</label>
            <textarea [ngModel]="reviewedH2" [disabled]="true" [readonly]="true" cols="65" rows="3"
              style="height:65px; resize: vertical;" class="form-control" name="newH2"></textarea>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>