<div>
	<ng-container *ngIf="isEnabled">
		<app-components-togglepanel [(isOpen)]="isOpen" title="Customer Synopsis">
			<ng-container *ngIf="!isOpen">
				<textarea [dataValidation]="{ placement: 'top', value: customer.Synopsis }" [ngModel]="customer.Synopsis()" (ngModelChange)="customer.Synopsis($event)" [ngModelOptions]="{ standalone: true }" [disabled]="!(canEditCustomerSynopsis())" [readonly]="!(canEditCustomerSynopsis())" name="customerSynopsis" class="form-control"></textarea>
			</ng-container>
			<ng-container *ngIf="isOpen">
				<textarea [dataValidation]="{ placement: 'top', value: customer.Synopsis }" [ngModel]="customer.Synopsis()" (ngModelChange)="customer.Synopsis($event)" [ngModelOptions]="{ standalone: true }" [disabled]="!(canEditCustomerSynopsis())" [readonly]="!(canEditCustomerSynopsis())" [autoExpand]="isOpen" name="customerSynopsis" class="form-control" maxlength="4000" ,=""></textarea>
				<div style="margin-top: 7px;">
					<span class="help-block float-start">Maximum length is 4000 Characters</span>
				</div>
			</ng-container>
		</app-components-togglepanel>
	</ng-container>
</div>
