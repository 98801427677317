<div>
  <ng-container *ngFor="let panel of panels; index as i">
    <div 
      [ngClass]="[getPanelSize(panel), 'floating-panel', (panel.expanded ? '' : 'collapsed')]"
      [ngStyle]="{top: i * 60 + 215 + 'px', 'z-index': panel.zIndex}" 
      [@wobbleIf]="panel.wobble ? {value: true, params: {timing: 1, delay: 1}} : undefined" 
      cdkDrag
      [cdkDragFreeDragPosition]="panel.dragPosition"
      [cdkDragDisabled]="!panel.expanded"
      (mousedown)="focusPanel(panel.panelId)"
      [loadingOverlay]="isLoading">
      <div [loadingOverlay]="false" class="floating-panel-tab">
        <button class="floating-panel-btn-expand btn btn-lg btn-primary" (click)="expandPanel(panel.panelId)" [class.icon-with-badge]="true">
          <ng-container *ngIf="(panel.badgeCount || 0) === 0">
            <i [class]="panel.icon" class="fa fa-fw"></i>
          </ng-container>
          <ng-container *ngIf="panel.badgeCount > 0">
            <i [class]="panel.icon" class="fa fa-fw" style="margin: 0 -9px 0 00px"></i>
            <span [class]="panel.titleBadgeClass" class="badge rounded-pill" style="font-size: 60%;">{{panel.badgeCount}}</span>
          </ng-container>
        </button>
      </div>
      <div class="floating-panel-main card float-end" style="width:100%">
        <div class="floating-panel-draggable card-header" cdkDragHandle>
          <ng-container *ngIf="(panel.badgeCount || 0) === 0">
            <i [class]="panel.icon" class="fa fa-fw"></i>
          </ng-container>
          <ng-container *ngIf="panel.badgeCount > 0">
            <i [class]="panel.icon" class="fa fa-fw" style="margin: 0 -9px 0 3px; font-size: 135%;"></i>
            <span [class]="panel.titleBadgeClass" class="badge rounded-pill" style="font-size: 70%;">{{panel.badgeCount}}</span>
          </ng-container>
          <span>{{panel.title}}</span>
          <i class="floating-panel-btn-collapse fa fa-minus float-end" (click)="collapsePanel(panel.panelId)"></i>
        </div>
        <div [hidden]="!panel.expanded" [ngClass]="panel.isScrollable !== false ? 'card-body card-body-scroll' : ''">
          <ng-container [ngTemplateOutlet]="panel.templateRef"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>



