
<li>
  <button (click)="websiteUrlTask.Responsibility === responsibilities_Boost ? approveTaskWithNote() : approveTask()" class="btn-link dropdown-item" [disabled]="restrictions.includes(customerCampaignRestrictions_DisableTaskActions)">
    <i class="fas fa-check text-success"></i>
    Approve
  </button>
</li>
<li>
  <button class="btn-link dropdown-item" (click)="rejectTaskWithReason()" [disabled]="restrictions.includes(customerCampaignRestrictions_DisableTaskActions)">
    <i class="fas fa-times text-danger"></i>
    Reject
  </button>
</li>
<ng-template #approveModal>
  <div class="modal-header">
    <h4 class="modal-title">Approval Note</h4>
    <button (click)="dismissReason()" class="btn-close" title="Close"></button>
  </div>
  <div class="modal-body">
    <ul>
      <li>Task Type: {{websiteUrlTask.TaskTypeName}} </li>
      <li>Task ID: {{websiteUrlTask.TaskId}} </li>
    </ul>
    <div class="form-group">
      <textarea placeholder="Add an optional comment for implementation to make small changes based on feedback from the customer."
      [formControl]="approvalNoteControl" lpValidationMsg class="form-control"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="dismissReason()" class="btn btn-link" >Cancel</button>
    <button (click)="approveTask()" class="btn btn-success">
      <i class="fa fa-check"></i>
      Approve
    </button>
  </div>
</ng-template>
<ng-template #rejectModal>
  <div class="modal-header">
    <h4 class="modal-title">Rejection Reason</h4>
    <button (click)="dismissReason()" class="btn-close" title="Close"></button>
  </div>
  <div class="modal-body">
    <ul>
      <li>Task Type: {{websiteUrlTask.TaskTypeName}} </li>
      <li>Task ID: {{websiteUrlTask.TaskId}} </li>
    </ul>
    <div class="form-group">
      <textarea placeholder="Describe the required changes here." [formControl]="rejectionReasonControl" lpValidationMsg class="form-control" ></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="dismissReason()" class="btn btn-link">Cancel</button>
    <button (click)="rejectTask()" class="btn btn-danger">
      <i class="fa fa-times"></i>
      Reject
    </button>
  </div>
</ng-template>
