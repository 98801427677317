<div [loadingOverlay]="loading()">
	<ng-container *ngIf="!isUserPartner">
		<div [hidden]="unresolvedTodos()">
			<div class="alert alert-success">
				<span>Each to do for this customer is complete</span>
			</div>
		</div>
		<compose Selector="app-components-todo-todolistlegacy" [ActivateOptions]="{
                        todoLists: todoLists,
                        userId: userId,
                        validationApi: validationApi,
                        skipValidation: true,
                        isCustomerTodo: true
                      }" [Callback]="todoListActivated?.bind(this)"></compose>
		<div>
			<textarea [dataValidation]="{ placement: 'bottom', value: newTodo }" [ngModel]="newTodo()" (ngModelChange)="newTodo($event)" [ngModelOptions]="{ standalone: true }" (keypress)="onEnter($event)" class="form-control" rows="3" style="resize: none; height: auto" placeholder="Add new to do"></textarea>
		</div>
		<button (click)="add()" class="btn btn-sm btn-success" style="margin-top: 5px;">Add</button>
	</ng-container>
	<ng-container *ngIf="isUserPartner">
		<compose Selector="app-components-todo-readonlytodolistlegacy" [ActivateOptions]="{
                        todoLists: todoLists,
                        userId: userId,
                        isCustomerTodo: true
                      }"></compose>
	</ng-container>
</div>
