import { Component, Input } from '@angular/core';
import Grid from 'app/components/Grid';

@Component({
	selector: 'app-components-compositionroutegrid',
	templateUrl: './CompositionRouteGrid.component.html'
})
export class CompositionRouteGridComponent {
	@Input()
	public grid: KnockoutObservable<Grid<ICompositionRoute>>;
}
