<div class="row">
	<div class="col-md-6">
		<div class="card">
			<div class="card-header">
				<h4>Site Information</h4>
			</div>
			<div class="card-body">
				<div class="form-group">
					<label class="control-label">Url</label>
					<div class="controls">
						<span name="url">{{url()}}</span>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label">Title</label>
					<div class="controls">
						<span name="title">{{title}}</span>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label">Description</label>
					<div class="controls">
						<span name="description">{{description()}}</span>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label">Keywords</label>
					<div class="controls">
						<span name="keywords">{{keywords()}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div class="form-group row">
			<label class="control-label col-md-2">Link:</label>
			<div class="col-md-10 text-end">
				<a [externalLink]="{ url: link(), text:link()}" name="bookmarkLink" rel="noreferrer"></a>
			</div>
		</div>
		<div class="form-group row">
			<label class="control-label col-md-12" for="AccountNameUsed">Account Name Used</label>
			<div class="col-md-12">
				<input [dataValidation]="{placement:'top', value: accountNameUsed }" [ngModel]="accountNameUsed()" (ngModelChange)="accountNameUsed($event)" [ngModelOptions]="{ standalone: true }" type="text" name="AccountNameUsed" class="form-control"/>
			</div>
		</div>
		<div [hidden]="!(previousAccountsUsed().length > 0)" class="form-group row">
			<label class="control-label col-md-12">Previous Accounts Used</label>
			<div class="col-md-12">
				<textarea [value]="previousAccountsUsed().join('\r\n')" class="form-control" readonly="readonly" name="previousAccounts"></textarea>
			</div>
		</div>
	</div>
</div>
