import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { QaGradingArea } from '../../../../qa/qa-grading-area/qa-grading-area.component';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { DeletableWorkspace } from 'app/interfaces/deletable-workspace';
import { Subject } from 'rxjs';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { InternalActionToken } from '../../internal-action.token';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';
import QaCriteriaGroup = Boo.Qa.QaCriteriaGroup;
import FloatingPanel = app.components.interfaces.FloatingPanel;
import IFloatingTabPanelPublicApi = app.components.interfaces.IFloatingTabPanelPublicApi;
import FloatingPanelIcon = app.components.enums.FloatingTabPanelIcon;
import TitleBadgeClass = app.components.enums.FloatingTabPanelTitleBadgeClass;

@Component({
  selector: 'app-components-specialist-internal-tasks-classified-business-listing-qa',
  templateUrl: './classified-business-listing-qa.component.html'
})
export class ClassifiedBusinessListingQaComponent implements OnInit, CompletableWorkspace, UnlockableWorkspace, DeletableWorkspace, ClosableWorkspace {
  showCompleteButton: boolean = false;
  showUnlockButton: boolean = true;
  showDeleteButton: boolean = false;
  showCloseButton: boolean = true;
  complete = new Subject<void>();
  delete = new Subject<void>();

  @ViewChild('gradingArea')
  gradingArea: QaGradingArea;
  taskType: Boo.Objects.Enums.TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaClassifiedBusinessListings;
  qaCriteriaGroups: QaCriteriaGroup[] = [];
  isLoading: boolean;
  associatedTask: Boo.Objects.Task;
  citationUrl: string;
  taskActionUrl: string;
  customer: Boo.Objects.Customer;
  localProfile: Boo.Objects.LocalProfiles.LocalProfile;
  targetKeywords: string[];
  files: Boo.Objects.File[] = [];
  gradeComment: string = '';
  floatingTabPanelApi: IFloatingTabPanelPublicApi;

  private floatingTabPanels: FloatingPanel[];
  private firstClickOnTabPanelComponent: boolean = false;

  floatingTabPanelApiCallback(api: IFloatingTabPanelPublicApi): void {
    this.floatingTabPanelApi = api;

    this.floatingTabPanels.forEach((floatingTabPanel) => {
      this.floatingTabPanelApi.addPanel(floatingTabPanel);
    });
  }

  constructor(@Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction) { }

  ngOnInit() {
    this.isLoading = true;
    // Create the criteria float panel
    (<any>$('#internal-task-qa-criteria-float-panel')).floatPanel({ collapsed: false });
    document.getElementById('internal-task-qa-criteria-float-panel').style.zIndex = '100'; // Set default z-index to be over the other tab panel's
    this.qaCriteriaGroups = this.internalAction.InternalTaskDetails.QaCriteriaGroups;
    this.associatedTask = this.internalAction.InternalTaskDetails.AssociatedTask;
    this.citationUrl = this.internalAction.InternalTaskDetails.LinkLocation.Url;
    this.taskActionUrl = this.internalAction.InternalTaskDetails.AssociatedTask.TaskActionUrl;
    this.customer = this.internalAction.InternalTaskDetails.AssociatedTaskCustomer;
    this.localProfile = this.internalAction.InternalTaskDetails.LocalProfile;

    this.targetKeywords = _.map(this.internalAction.InternalTaskDetails.WebsiteUrl.Keywords, (websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword) => {
      return websiteUrlKeyword.Keyword.Name;
    });

    this.files = this.internalAction.InternalTaskDetails.Files;
    this.floatingTabPanels = [
      {
        title: 'Comments',
        titleIcon: FloatingPanelIcon.Comments,
        titleBadge: () => this.internalAction.InternalTaskDetails.AssociatedTaskNotes.length,
        titleBadgeClass: TitleBadgeClass.Warning,
        composition: {
          selector: 'app-components-specialist-actioncomponents-comments',
          model: { comments: this.internalAction.InternalTaskDetails.AssociatedTaskNotes }
        }
      }
    ];
    this.isLoading = false;
  }

  isReadyToComplete(): boolean {
    if (!this.gradingArea.validate()) {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return false;
    }

    this.internalAction.InternalTaskDetails.QaCriteriaDetails = this.gradingArea.getDetails();
    this.internalAction.InternalTaskDetails.QaGrade.Comment = this.gradingArea.gradeComment;
    return true;
  }

  isReadyToDelete(): boolean {
    return true;
  }

  openCitationUrl(): void {
    window.open(this.citationUrl, '_blank');
  }

  openTaskActionUrl(): void {
    window.open(this.taskActionUrl, '_blank');
  }

  // This is a hacky way to integrate the two types of tabpanels and keep a semblance of the functionality the "normal" tabpanel component uses
  increaseLocalTabPanelZIndex(): void {
    let localTabPanel = document.getElementById('internal-task-qa-criteria-float-panel');
    localTabPanel.style.zIndex = document.getElementById(this.floatingTabPanels[0].panelId).style.zIndex;
  }

  // Like above, this is a hacky way to emulate how other tab panels work, this time increasing the z index by 1 (note that z-indexes are stored as strings so + 1 doesn't work)
  // So it's brought to the front on the first click.
  increaseTabPanelComponentZIndex(): void {
    if (!this.firstClickOnTabPanelComponent) {
      document.getElementById(this.floatingTabPanels[0].panelId).style.zIndex = '101'; // Default z-index is 100
      this.firstClickOnTabPanelComponent = true;
    }
  }
}