import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Period, ScreenErrors, TaskTypeWorkGroup } from '../AllocateWork.component';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { onsiteBloggingOrderingContentOnlyTaskTypes, onsiteBloggingOrderingWithImplementationTaskTypes } from 'app/models/collections/TaskTypeCollections';
import FlagTypes = Boo.Objects.Work.SeoMinute.Enums.FlagTypes;
import WorkRequestHelper from 'app/managecustomer/WorkRequestHelper';
import { ReservedMinutesNode } from '../Models/ReservedMinutesNode';

@Component({
  selector: 'app-components-managecustomer-workperiods',
  templateUrl: './WorkPeriods.component.html',
  styleUrls: ['../AllocateWork.component.scss']
})
export class WorkPeriodsComponent {
  @Input() customer: CustomerObservable;
  @Input() userId: number;
  @Input() selectedPeriod: Period;
  @Input() screenErrors: ScreenErrors;
  @Input() seoWorkSettings: Boo.Objects.Work.SeoMinute.SeoWorkSettings;
  @Input() taskTypeWorkGroupings: TaskTypeWorkGroup[];
  @Input() reservedMinutes?: ReservedMinutesNode;
  @Output() removeGroup = new EventEmitter<TaskTypeWorkGroup>();
  @Output() addGroup = new EventEmitter<TaskTypeWorkGroup>();
  @Output() editWorkRequest = new EventEmitter();
  @Output() clearPeriod = new EventEmitter();
  @Output() deletePeriod = new EventEmitter();
  @Output() validatePeriod = new EventEmitter();
  @Output() refresh = new EventEmitter();
  isLoading = false;
  taskTypes: Boo.Objects.Work.SeoMinute.SeoWorkTaskType[];
  periodMinutesTotal: number = 0;
  selectedTaskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType;
  qtyNumbers: number[] = [];
  selectedQty: number = 1;
  maxQtyNumber = 99;
  reservedMinutesDescription: string;

  private manualTaskIdCount = 0;
  private existingErrors: boolean;

  constructor() { }

  get reservedMinutesQuantity(): number {
    return this.reservedMinutes?.quantity ?? 0;
  }

  get reservedMinutesPreviousQuantity(): number {
    return this.reservedMinutes?.previousQuantity ?? 0;
  }

  get totalMinutes(): number {
    if (!this.taskTypeWorkGroupings.length) {
      return 0 + this.reservedMinutesQuantity;
    }

    const items: Boo.Objects.Work.SeoMinute.WorkRequestDetail[] = _.flatten(
      this.taskTypeWorkGroupings
        .filter(x => x.workItems.length)
        .map(y => y.workItems));

    const workRequestMinutes = items
      .map(x => x.WorkRequest.WorkQuantity)
      .reduce(
        (a, b) => a + b,
        0);
    return workRequestMinutes + this.reservedMinutesQuantity;
  }

  get validTotalMinutes(): boolean {
    const isValid = this.totalMinutes === this.seoWorkSettings.AllotedSeoMinutesForPeriods[this.selectedPeriod.index] + this.reservedMinutesPreviousQuantity;

    if (this.existingErrors && isValid) {
      this.existingErrors = false;
      this.validatePeriod.emit();
    }

    return isValid;
  } 

  get availableMinutes(): number {
    return this.seoWorkSettings.AllotedSeoMinutesForPeriods[this.selectedPeriod.index] + this.reservedMinutesPreviousQuantity;
  }

  get minuteDifferenceMessage(): string {
    const periodMinutes = this.seoWorkSettings.AllotedSeoMinutesForPeriods[this.selectedPeriod.index] + this.reservedMinutesPreviousQuantity;

    if (periodMinutes > this.totalMinutes) {
      return (periodMinutes - this.totalMinutes).toString() + " Under";
    } else {
      return (this.totalMinutes - periodMinutes).toString() + " Over";
    }
  }

  ngOnInit(): void {
    if (this.selectedPeriod.index === 0 && this.seoWorkSettings.PreviouslyReservedMinutes) {
      this.reservedMinutesDescription = `${this.seoWorkSettings.PreviouslyReservedMinutes} minutes were reserved in past period(s)`;  
    } else if (this.reservedMinutesPreviousQuantity > 0) {
      this.reservedMinutesDescription = `${this.reservedMinutesPreviousQuantity} minutes are reserved in past period(s)`;
    }
    this.existingErrors = !!this.screenErrors;
    this.taskTypes = this.seoWorkSettings.TaskTypes;

    // todo: the qty dropdown might need to be more task type specific
    for (let number = 1; number <= 40; number++) {
      this.qtyNumbers.push(number);
    }

    this.handleExistingNonWorkableTaskTypes();
  }

  groupQtyChange(newQty: number, group: TaskTypeWorkGroup): void {
    const numberToAddSubtract = newQty - group.workItems.length;
    if (numberToAddSubtract > 0) {
      const taskType = this.taskTypes.find(x => x.Name === group.taskTypeName);
      this.addItems(numberToAddSubtract, taskType);
    } else {
      this.deleteAvailable(group, Math.abs(numberToAddSubtract));
    }
  }

  addItems(amount: number, taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType): void {
    for (let index = 0; index < amount; index++) {
      this.addItem(taskType);
    }
  }

  addItem(taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType): void {
    let group = this.findGroupByName(taskType.Name);
    const newItem = this.workRequestDetailFactory(taskType);
    this.createWorkRequestKeyValuePairs(newItem.WorkRequest);

    if (group) {
      // Validate against the TaskType's max count in the case of manual tasks
      if (taskType.MaxCount && group.qty >= taskType.MaxCount) {
        toastr.warning(`${taskType.Name} has reached the maximum quantity allowed for ${this.selectedPeriod.displayDate}`)
        return;
      }

      group.workItems.push(newItem);
      group.qty = group.workItems.length;
    } else {
      const newGroup = {
        taskTypeId: taskType.TaskTypeId,
        taskTypeName: taskType.Name,
        taskTypeDescription: taskType.Description,
        period: this.selectedPeriod.index,
        isManual: this.isTaskTypeManual(taskType.TaskTypeId),
        qty: 0,
        workItems: []
      } as TaskTypeWorkGroup;

      newGroup.workItems.push(newItem);
      newGroup.qty = newGroup.workItems.length;
      this.taskTypeWorkGroupings.push(newGroup);
      this.addGroup.emit(newGroup);
      group = newGroup;
    }

    if (taskType.IsCustom) {
      PriorityViewModel.show(
        'app-components-managecustomer-workrequestedit', {
        customer: this.customer,
        taskType: taskType,
        workRequestDetail: newItem,
        hasValidAccessDetails: this.seoWorkSettings.HasValidAccessDetails
      })
      .fail((canceledItem: Boo.Objects.Work.SeoMinute.WorkRequestDetail) => this.deleteSpecificItem(canceledItem))
      .always(() => this.editWorkRequest.emit());

      newItem.WorkRequest.WorkRequestId = this.manualTaskIdCount--;
    }
  }

  editItem(item: Boo.Objects.Work.SeoMinute.WorkRequestDetail): void {
    let taskType = this.taskTypes.find(x => x.TaskTypeId === item.WorkRequest.WorkTypeCreationId);
    PriorityViewModel.show(
      'app-components-managecustomer-workrequestedit',
      {
        customer: this.customer,
        workRequestDetail: item,
        taskType: taskType,
        hasValidAccessDetails: this.seoWorkSettings.HasValidAccessDetails
      }
    )
    .always(() => this.editWorkRequest.emit());
  }

  amountOfItemsCantBeDeleted(group: TaskTypeWorkGroup): number {
    return group.workItems.filter(x => !x.CanDelete).length;
  }

  deleteAvailable(group: TaskTypeWorkGroup, amount: number = 1): void {
    const selectedGroup = this.findGroupByName(group.taskTypeName);

    let deletionId = -1;
    let count = 1;

    selectedGroup.workItems
      .filter(y => y.CanDelete)
      .sort((a, b) => a.WorkOrderItemWorkId - b.WorkOrderItemWorkId)
      .every(x => {
        if (count > amount) {
          return false;
        }

        x.OrderTaskId = deletionId; // set a "new" item id to a negative number
        deletionId--; // want to go up in the negatives
        count++;
        return true;
      });

    selectedGroup.workItems = selectedGroup.workItems.filter(x => x.OrderTaskId >= 0);
    selectedGroup.qty = selectedGroup.workItems.length;

    if (!selectedGroup.workItems.length) {
      this.deleteGroup(selectedGroup);
    }
  }

  deleteSpecificItem(item: Boo.Objects.Work.SeoMinute.WorkRequestDetail): void {
    const group = this.findGroupByName(item.WorkDisplayName);

    group.workItems = group.workItems.filter(x => x.WorkRequest.WorkRequestId !== item.WorkRequest.WorkRequestId);
    group.qty = group.workItems.length;

    if (!group.workItems.length) {
      this.deleteGroup(group);
    }
  }

  canDeleteGroup(group: TaskTypeWorkGroup): boolean {
    return group.workItems.every(x => x.CanDelete);
  }

  getGroupStatus(group: Boo.Objects.Work.SeoMinute.WorkRequestDetail[]): string {
    if (group.some(x => x.WorkStatusDisplayName === DisplayStatuses.processing)) {
      return DisplayStatuses.processing;
    } else if (group.some(x => x.WorkStatusDisplayName === DisplayStatuses.pending)) {
      return DisplayStatuses.pending;
    } else if (group.some(x => x.WorkStatusDisplayName !== DisplayStatuses.complete)) {
      return DisplayStatuses.incomplete;
    } else {
      return DisplayStatuses.complete;
    }
  }

  getGroupTotalMinutes(group: Boo.Objects.Work.SeoMinute.WorkRequestDetail[]): number {
    return group.map(x => x.WorkRequest.WorkQuantity).reduce((a, b) => a + b);
  }

  getMinutesPerTask(workItems: Boo.Objects.Work.SeoMinute.WorkRequestDetail[]): number {
    if (!workItems.length) {
      return 0;
    }
    return workItems[0].WorkRequest.WorkQuantity;
  }

  maxCount(group: TaskTypeWorkGroup): number {
    let max = this.taskTypes.find(x => x.TaskTypeId === group.workItems[0].WorkRequest.WorkTypeCreationId).MaxCount;
    max = max && max >= 0 ? max : this.maxQtyNumber;
    return max;
  }

  qtyDisabled(qty: number, group: TaskTypeWorkGroup): boolean {
    if (this.isNonWorkable(group)) {
      return qty < this.amountOfItemsCantBeDeleted(group) || qty > group.qty;
    }

    return qty < this.amountOfItemsCantBeDeleted(group) || qty > this.maxCount(group);
  }

  isNonWorkable(group: TaskTypeWorkGroup): boolean {
    const taskType = this.taskTypes.find(x => x.Name === group.taskTypeName);
    return this.isTaskNonWorkable(taskType.TaskTypeId);
  }

  private isTaskNonWorkable(taskTypeId: number): boolean {
    return this.seoWorkSettings.TaskTypes
      .find(x => x.TaskTypeId === taskTypeId).Flags
      .some(y => this.isANonworkableTaskFlagType(y.Type));
  }

  private isANonworkableTaskFlagType(type: FlagTypes) {
    return (type === FlagTypes.Nonworkable 
      || (type === FlagTypes.ExaustedForPeriod && this.selectedPeriod.index == 0));
  }

  private isTaskTypeManual(taskTypeId: number): boolean {
    return this.findTaskType(taskTypeId).IsCustom;
  }

  private findTaskType(taskTypeId: number): Boo.Objects.Work.SeoMinute.SeoWorkTaskType {
    return this.taskTypes.find(x => x.TaskTypeId === taskTypeId);
  }

  private handleExistingNonWorkableTaskTypes(): void {
    this.taskTypeWorkGroupings.forEach(group => {
      const taskIdsToDeallocate = group.workItems
        .filter(item => this.isTaskNonWorkable(item.WorkRequest.WorkTypeCreationId) && !item.WorkOrderItemWorkId)
        .map(item => item.WorkRequest.WorkRequestId);

      group.workItems = group.workItems.filter(x => !taskIdsToDeallocate.includes(x.WorkRequest.WorkRequestId))
      group.qty = group.workItems.length;
      if (taskIdsToDeallocate.length) {
        toastr.warning(`The quantity of tasks allocated to ${group.taskTypeName} has been reduced to the max that can be fulfilled. Reallocate minutes to another task type`);
      }
    });
  }

  private deleteGroup(group: TaskTypeWorkGroup): void {
    const selectedGroup = this.findGroupByName(group.taskTypeName);
    this.taskTypeWorkGroupings = this.taskTypeWorkGroupings.filter(x => x.taskTypeName !== selectedGroup.taskTypeName);
    this.removeGroup.emit(selectedGroup);
  }

  private findGroupByName(groupName: string): TaskTypeWorkGroup {
    return this.taskTypeWorkGroupings.find(x => x.taskTypeName === groupName);
  }

  private workRequestDetailFactory(taskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType): Boo.Objects.Work.SeoMinute.WorkRequestDetail {
    return <Boo.Objects.Work.SeoMinute.WorkRequestDetail>({
      WorkDisplayName: taskType.Name,
      WorkDescription: taskType.Description,
      WorkRequest: {
        CustomerId: this.customer.CustomerId(),
        Sku: 'SEO-MINUTE',
        Period: this.selectedPeriod.index,
        WorkTypeId: Fulfillment.Domain.WorkOrders.WorkTypes.Task,
        WorkTypeCreationId: taskType.TaskTypeId,
        WorkQuantity: taskType.Minutes,
        InsertedByUserId: this.userId,
        WorkOrderItemId: null,
        InsertedDate: null,
        WorkRequestId: 0,
        WorkRequestFiles: [],
        KeyValuePairs: []
      },
      CanDelete: true,
      CanEdit: false,
      WorkOrderItemWorkId: 0,
      WorkOrderItemWorkTypeId: 0,
      WorkOrderItemStatusId: 0,
      OrderTaskId: 0,
      StatusTaskId: 0,
      StatusTaskStatusId: 0,
      WorkStatusDisplayName: DisplayStatuses.new

    });
  }

  private createWorkRequestKeyValuePairs(workRequest: Boo.Objects.Work.WorkRequest): void {
    // onsiteblogging: TODO Remove when seperate blogging implementation tasks are depreciated
    if (onsiteBloggingOrderingWithImplementationTaskTypes.includes(workRequest.WorkTypeCreationId)) {
      WorkRequestHelper.setIsBoostImplementing(workRequest, true, this.userId);
    }
    else if (onsiteBloggingOrderingContentOnlyTaskTypes.includes(workRequest.WorkTypeCreationId)) {
      WorkRequestHelper.setIsBoostImplementing(workRequest, false, this.userId);
    }
  }
}

enum DisplayStatuses {
  complete = 'Complete',
  incomplete = 'Incomplete',
  pending = 'Pending',
  processing = 'Processing',
  new = 'New'
}
