import { Component } from '@angular/core';
import System from 'framework/System';
import IOnsiteRecommendationPublicApi = app.components.interfaces.IOnsiteRecommendationPublicApi;
import TaskHelper from 'app/specialist/TaskHelper';
import OnsiteCopyPageTypeFactory from '../../../../factories/onsite-copy-page-type.factory';
import { WorkflowKeyValuePairService } from '../../../../services/workflow-keyvaluepair.service';
import WorkflowHelper from '../../../../shared/WorkflowHelper';
import { switchMap } from 'rxjs/operators';
import Utils from '../../../../shared/utils';
import { of } from 'rxjs';

type OnsiteCopyReviewOutput = Boo.Tasks.Outputs.Models.OnsiteCopyReviewOutput;

@Component({
	selector: 'app-components-specialist-actioncomponents-onsitecopyreview',
	templateUrl: './OnsiteCopyReview.component.html'
})
export class OnsiteCopyReviewComponent implements IActionWorkspace, IActivatable {
    public actionViewModel: KnockoutObservable<IActionWorkspace>;
    public currentAction: KnockoutObservable<Boo.Objects.Action>;
    public competitorTypes: any;
    public content: KnockoutObservable<string>;
    public contentWriterInstructions: KnockoutObservable<string>;
    public customer: KnockoutObservable<Boo.Objects.Customer>;
    public hasComments: boolean;
    public isLoading: KnockoutObservable<boolean> = ko.observable(true);
    public lastNote: KnockoutObservable<Boo.Objects.TaskNote>;
    public noteCount: KnockoutComputed<number>;
    public notes: KnockoutObservableArray<Boo.Objects.TaskNote>;
    public orderReason: KnockoutObservable<string>;
    public pageStrategy: KnockoutObservable<string>;
    public pageType: KnockoutObservable<string>;
    public pageValidation: KnockoutObservable<any>;
    public requiredWordCount: number = 100;
    public recommendedWordCount: number;
    public onsiteRecommendationApi: IOnsiteRecommendationPublicApi;
    public url: KnockoutObservable<string>;
    public wordCount: KnockoutObservable<number>;
    public writerFeedback: KnockoutObservable<string>;
    public customerAccountGroupIsEnabled = ko.observable(false);
    public writerUserId: number;
    public isTaskImported: boolean;
    public noteToQa: string;
    public taskInstructions: string;
    public isStandard: boolean;
    public boostToPublish: boolean;
    public implementationNotes: string;

    private output: OnsiteCopyReviewOutput;

    constructor(private onsiteCopyPageTypeFactory: OnsiteCopyPageTypeFactory,
        private workflowKeyValuePairService: WorkflowKeyValuePairService) {}

    public onsiteRecommendationApiCallback(api: IOnsiteRecommendationPublicApi): void {
        this.onsiteRecommendationApi = api;
        this.isLoading(false);
    }

    public activate(params: IActionWorkspaceActivationParams): JQueryPromise<void> {
        params.controlViewModel(this);
        this.actionViewModel = ko.observable(null);
        this.currentAction = params.currentAction;
        this.output = this.currentAction().Task.Output as OnsiteCopyReviewOutput;
        this.customer = ko.observable(this.currentAction().Customer);
        this.wordCount = ko.observable(0);
        this.competitorTypes = launchpad.config.CompetitorTypeMapEnum;
        this.notes = ko.observableArray(this.currentAction().TaskDetails && this.currentAction().TaskDetails.TaskNotes ? this.currentAction().TaskDetails.TaskNotes : []);
        this.pageType = ko.observable('');
        this.orderReason = ko.observable('');
        this.taskInstructions = this.currentAction().Task.TaskTypeSummary.Tips;
        this.isStandard = this.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteCopyReviewStandard;
        this.hasComments = this.currentAction()
            && this.currentAction().TaskDetails
            && this.currentAction().TaskDetails.TaskNotes
            && this.currentAction().TaskDetails.TaskNotes.length > 0;

        this.writerUserId = this.currentAction
                        && this.currentAction().TaskDetails
                        && this.currentAction().TaskDetails.TaskPost
                        && this.currentAction().TaskDetails.TaskPost.Task
                ? this.currentAction().TaskDetails.TaskPost.Task.AssignedUserId
                : 0;

        this.isTaskImported = this.writerUserId === launchpad.config.systemAdminUserId;

        this.writerFeedback = ko.observable(this.output.WriterFeedback ?? '');
        this.noteToQa = this.output.NoteToQa;

        if (this.currentAction().Task.TaskStatusEnum === Boo.Objects.Enums.TaskStatusEnum.Complete) {
          this.writerFeedback(this.currentAction().TaskDetails.TaskNotes.find(n => n.TaskNoteContactTypeId === Boo.Objects.Enums.TaskNoteContactTypesEnum.Accept).Note);
        }

        this.noteCount = ko.computed(() => {
            return this.notes().length;
        });

        this.lastNote = ko.observable();
        if (this.noteCount()) {
            this.lastNote(this.notes()[0]);
        }

        this.content = ko.observable(this.currentAction().TaskDetails.TaskPost.Content);

        this.pageStrategy = ko.observable(this.currentAction().TaskDetails.TaskPost.Synopsis || '');

        this.url = ko.observable(this.currentAction().TaskDetails && this.currentAction().TaskDetails.WebsiteUrl ? this.currentAction().TaskDetails.WebsiteUrl.Url : '');

        this.contentWriterInstructions = ko.observable(
            this.currentAction().TaskDetails &&
            this.currentAction().TaskDetails.OnsiteCopyDetails &&
            this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask ?
            TaskHelper.getCustomDescription(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask) : '');

        if (this.currentAction().TaskDetails &&
            this.currentAction().TaskDetails.OnsiteCopyDetails &&
            this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask &&
            this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask.KeyValuePairs) {

            this.pageType(TaskHelper.getOnsiteCopyPageType(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask));
            this.orderReason(TaskHelper.getOnsiteCopyOrderReason(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask));
            
            if (this.orderReason() === 'Other') {
              this.orderReason('Other: ' + TaskHelper.getOnsiteCopyOrderReasonOther(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask));
            }

            this.implementationNotes = TaskHelper.getOnsiteCopyImplementationInstructions(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask);
        }

        this.recommendedWordCount = this.onsiteCopyPageTypeFactory.getWordCount(this.pageType());

        this.content.extend(
            {
                required: true,
                validation: {
                    validator: (value: string): boolean => {
                        return this.wordCount() >= this.requiredWordCount;
                    }, message: `Content must contain at least ${this.requiredWordCount} words.`
                }
            });

        this.writerFeedback.extend({
            required: {
                message: 'Reviewer must provide writer feedback.'
            },
            maxLength: 4000
        });

        this.pageValidation = ko.validatedObservable([
            this.content,
            this.writerFeedback
        ]);

        return Utils.wrapDfd(this.workflowKeyValuePairService.getByTaskId(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask.TaskId)
            .pipe(switchMap((keyValuePairs: Boo.Objects.WorkflowKeyValuePair[]) => { 
                this.boostToPublish = WorkflowHelper.getIsBoostImplementingByResponsibility(keyValuePairs); 
                return of(null);
            })));
    }

    public isStepReadyToReject(): boolean {
        this.currentAction().TaskDetails.TaskPost.Content = this.content();
        this.output.NoteToQa = this.noteToQa;
        return true;
    }

    public isStepReadyToSave(): boolean {
        this.currentAction().TaskDetails.TaskPost.Content = this.content();
        this.currentAction().TaskDetails.OnsiteRecommendation = this.onsiteRecommendationApi.getOnsiteRecommendation();
        this.currentAction().TaskDetails.KeywordSiteAnalyses = this.onsiteRecommendationApi.getCompleteKeywordInformation();
        this.output.WriterFeedback = this.writerFeedback();
        this.output.NoteToQa = this.noteToQa;
        return true;
    }

    public isStepReadyToComplete(): boolean {
        if (!this.onsiteRecommendationApi.isValid() || !this.pageValidation.isValid()) {
            this.onsiteRecommendationApi.showAllErrorMessages();
            this.pageValidation.errors.showAllMessages();
            return false;
        }

        this.currentAction().TaskDetails.TaskPost.Content = this.content();
        this.currentAction().TaskDetails.OnsiteRecommendation = this.onsiteRecommendationApi.getOnsiteRecommendation();
        this.output.WriterFeedback = this.writerFeedback();
        this.output.NoteToQa = this.noteToQa;

        this.currentAction().TaskDetails.KeywordSiteAnalyses = this.onsiteRecommendationApi.getCompleteKeywordInformation();
        let keywordInformation = this.onsiteRecommendationApi.getKeywordInformation();
        this.currentAction().Task.KeywordId = keywordInformation.KeywordId;
        this.currentAction().Task.AreaId = keywordInformation.AreaId;
        this.currentAction().Task.IsAreaFirst = keywordInformation.IsAreaFirst;
        return true;
    }

    public isStepReadyToDelete(): boolean {
        return false;
    }
}
