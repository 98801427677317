<div [loadingOverlay]="isLoading">
  <form [formGroup]="localProfileForm">
    <div class="d-flex flex-row mb-3">
      <div class="p-2">
        <label class="control-label d-block" for="isActive">Active</label>
        <div>
          <shared-components-customchoice id="isActive" formControlName="isActive"
            [disable]="isReadOnly || disableBusinessCitationEligibilityControls"></shared-components-customchoice>
        </div>
      </div>
      <div class="p-2" [formValidation]="localProfileForm.controls.canSubscribe">
        <label class="control-label d-block" for="canSubscribe">
          Can Subscribe
          <ng-container *ngIf="!isReadOnly && !disableBusinessCitationEligibilityControls">
            <app-components-helptext [key]="'LocalProfile_CanSubscribe'"></app-components-helptext>
          </ng-container>
        </label>
        <div>
          <shared-components-customchoice id="canSubscribe" formControlName="canSubscribe"
            [disable]="isReadOnly || disableBusinessCitationEligibilityControls"></shared-components-customchoice>
        </div>
      </div>
      <div class="p-2 ms-5">
        <label class="control-label d-block" for="isClosed">Location Is Closed</label>
        <div>
          <shared-components-customchoice id="isClosed" formControlName="isClosed"
            [disable]="isReadOnly"></shared-components-customchoice>
        </div>
      </div>
      <ng-container *ngIf="partnerRequiresSso">
        <div class="p-2 ms-auto align-self-end">
          <div>
            <button class="btn btn-primary" (click)="editOnPartnerSite()" [disabled]="isLoading">
              <i class="fas fa-sign-in-alt"></i>{{ partnerSsoLabel }}</button>
          </div>
        </div>
      </ng-container>
    </div>
    <hr />
    <h4>General Info</h4>
    <div class="row mb-3">
      <div class="col-6">
        <div class="row g-3">
          <div class="col-12">
            <label class="form-label" for="streetAddress">Street Address</label>
            <input [formValidation]="localProfileForm.controls.streetAddress" type="text"
              formControlName="streetAddress" [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control"
              id="streetAddress" placeholder="Street Address" />
          </div>
          <div class="col-12">
            <label class="form-label" for="streetAddress2">Street Address 2</label>
            <input [formValidation]="localProfileForm.controls.streetAddress2" type="text"
              formControlName="streetAddress2" [disabled]="isReadOnly" [readonly]="isReadOnly" class="form-control"
              id="streetAddress2" placeholder="Street Address 2" />
          </div>
          <div class="col-4">
            <label class="form-label" for="zip">Postal Code</label>
            <input [formValidation]="localProfileForm.controls.zip" type="text" formControlName="zip"
              class="form-control" id="zip" placeholder="Postal Code" [disabled]="isReadOnly" [readonly]="isReadOnly" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row g-3">
          <div class="col-12">
            <label class="form-label d-block" for="isStreetAddressHidden">Hide Street Address</label>
            <shared-components-customchoice id="isStreetAddressHidden" formControlName="isStreetAddressHidden"
              [disable]="isReadOnly"></shared-components-customchoice>
          </div>
          <div class="col-12">
            <label class="form-label" for="serviceArea">Service Areas</label>
            <ng-select [formValidation]="localProfileForm.controls.serviceAreas" id="serviceArea"
              [items]="serviceAreas$ | async" [clearable]="true" bindLabel="Description" formControlName="serviceAreas"
              [loading]="serviceAreaLoading" [minTermLength]="3" [typeahead]="serviceAreaInput$" [multiple]="true"
              placeholder="Service Areas" maxSelectedItems="20">
            </ng-select>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>