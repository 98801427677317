/* tslint:disable */

import { Component } from '@angular/core';
import { VerticalTranslationService } from '../../../services/vertical-translation.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-admin-verticaltranslation',
	templateUrl: './VerticalTranslation.component.html'
})
export class VerticalTranslationComponent {
  public dfd: any;
  public title = 'Vertical Translation';
  public isLoading: any = ko.observable(false);

  public verticalTranslation: any = ko.observable(ko.mapping.fromJS({
    VerticalTranslationId: 0,
    InsertedDate: new Date(),
    VerticalId: 0,
    LanguageId: 0,
    Name: ''
  }));
  public languages: any = ko.observableArray([]);

  constructor(
    private verticalTranslationService: VerticalTranslationService,
    private sessionStorageService: SessionStorageService) { }

  public editMode: any = ko.computed(() => {
    return this.verticalTranslation().VerticalTranslationId() > 0;
  });

  private loadVerticalTranslation(verticalTranslationId: any): void {
    this.isLoading(true);
    Utils.wrapDfd(this.verticalTranslationService.get(verticalTranslationId))
      .then((verticalTranslation) => {
        this.verticalTranslation(ko.mapping.fromJS(verticalTranslation));
        this.setUpValidation();
      }).fail((displayMessage) => {
        toastr.error(displayMessage);
      }).always(() => {
        this.isLoading(false);
      });
  }

  public close(): void {
    if (this.dfd) {
      this.dfd.resolve();
    }
  }

  public cancel(): void {
    if (this.dfd) {
      this.dfd.reject();
    }
  }

  public save(): any {
    if (!this.pageValidation.isValid()) {
      this.pageValidation.errors.showAllMessages();
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return;
    }
    this.isLoading(true);
    const verticalTranslation = ko.mapping.toJS(this.verticalTranslation());
    Utils.wrapDfd(this.verticalTranslationService.save(verticalTranslation))
      .then(() => {
        toastr.success(`Translation "${verticalTranslation.Name}" has been saved`);
        this.close();
      }).fail((displayMessage) => {
        toastr.error(displayMessage);
      }).always(() => {
        this.isLoading(false);
      });
  }

  public activate(options: any): void {
    $.when(Utils.wrapDfd(this.sessionStorageService.getStaticData()))
      .then((staticData) => {
        this.languages(staticData.Languages);
        if (options.verticalTranslationId) {
          this.loadVerticalTranslation(options.verticalTranslationId);
        } else {
          // We don't need to load anything else since this is a new vertical translation
          // so we're just going to go ahead and set up the validation. In the other scenrio
          // we wait until after we've loaded the new vertical to do this part.
          this.setUpValidation();
          if (options.verticalId) {
            this.verticalTranslation().VerticalId(options.verticalId);
          }
        }
      });
  }

  private setUpValidation(): void {
    this.verticalTranslation().Name.extend({
      required: true
    });
    this.verticalTranslation().LanguageId.extend({
      validation: {
        message: 'Please choose a Language',
        validator: (val: any) => {
          if (_.isNaN(val)) {
            return false;
          }
          return parseInt(val) > 0;
        }
      }
    });
    this.pageValidation = ko.validatedObservable([
      this.verticalTranslation().Name,
      this.verticalTranslation().LanguageId
    ]);
  }
  public pageValidation: any = ko.validatedObservable([]);
}
