<div [loadingOverlay]="isLoading">
  <label>
    Partner Admin Access
    <span class="small text-danger">(Do not share with customer)</span>
  </label>
  <div class="input-group">
    <input [ngModel]="ssoUrlForAdmin" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" disabled=""
      placeholder="Click Load or Sign-On..." class="disabled form-control" />
    <button (click)="loadDudaSsoUrlForThryv()" class="btn btn-danger">
      <i class="fas fa-sync"></i>
      Load
    </button>
    <button (click)="signOnWithSsoUrlForAdmin()" class="btn btn-danger">
      <i class="fas fa-external-link-square-alt"></i>
      Sign-On
    </button>
  </div>
</div>