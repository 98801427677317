<div class="card">
	<div class="card-header">
		Configure Partner Pricing Tool
	</div>
	<div class="card-body" [loadingOverlay]="isLoading">
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label class="col-form-label auto-grow">Search by Partner</label>
					<ng-select [items]="partners" bindLabel="PartnerName" [searchable]="true" [virtualScroll]="true"
						(change)="setSearchValues($event)">
					</ng-select>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="card">
						<div class="card-header">
							Search Options
						</div>
						<div class="card-body">
							<div class="form-group">
								<label class="col-form-label">Heading Groups</label>
								<ng-select [items]="headingGroups" bindLabel="Name" [multiple]="true"
									[(ngModel)]="selectedHeadingGroups" (ngModelChange)="clearHeadings()">
								</ng-select>
							</div>
							<div class="form-group">
								<label class="col-form-label">Heading</label>
								<ng-select [disabled]="selectedHeadingGroups.length === 0" [items]="headings$ | async"
									[(ngModel)]="selectedHeadings" bindLabel="Name" name="heading" [minTermLength]="minSearchTermLength"
									[loading]="headingLoading" [minTermLength]="3" [typeahead]="headingInput$" [searchable]="true"
									[multiple]="true" placeholder="Please enter 3 or more characters">
								</ng-select>
							</div>
							<div class="form-group">
								<label class="col-form-label">Markets</label>
								<ng-select bindLabel="Name" [multiple]="true" [(ngModel)]="selectedMarkets"
									(ngModelChange)="clearLocations()">
									<ng-option *ngFor="let market of markets" [value]="market" [disabled]="marketDisabled(market)"
										class="text-center">
										{{market.Name}}
									</ng-option>
								</ng-select>
							</div>
							<div class="form-group">
								<label class="col-form-label">Location</label>
								<ng-select [disabled]="selectedMarkets.length === 0" [items]="locations$ | async"
									[(ngModel)]="selectedLocations" bindLabel="Name" name="location" [minTermLength]="minSearchTermLength"
									[loading]="locationLoading" [minTermLength]="3" [typeahead]="locationInput$" [searchable]="true"
									[multiple]="true" placeholder="Please enter 3 or more characters">
								</ng-select>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="row">
						<div class="col-md-6">
							<div class="card" *ngIf="selectedHeadings.length === 0">
								<div class="card-header">Headings</div>
								<div class="card-body">
									<div>None selected.</div>
								</div>
							</div>
							<div class="card" *ngFor="let heading of selectedHeadings">
								<div class="card-header">
									<span>{{heading.Name}}</span>
									<strong class="float-end" *ngIf="selectedLocations.length === 1">Hours: {{getRecommendedHours(heading,
										selectedLocations[0])}}</strong>
								</div>
								<div class="collapse show">
									<div class="card-body">
										<table class="table table-bordered">
											<thead>
												<tr>
													<th>Tier</th>
													<th>Hours</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let tierHour of heading.TierHours"
													[ngClass]="{'highlighted': selectedLocations.length === 1 && tierHour.TierId === selectedLocations[0].TierId}">
													<td>{{tierHour.TierId}}</td>
													<td><input [(ngModel)]="tierHour.Hours" (change)="validateTierHours(tierHour)" type="number"
															min="1" class="form-control"></td>
												</tr>
											</tbody>
										</table>
									</div>
									<div class="card-footer text-end">
										<button (click)="deselectHeading(heading)" class="btn btn-link">Cancel</button>
										<button (click)="saveHeading(heading)" class="btn btn-success">
											<i class="fa fa-save"></i>
											Save
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="card" *ngIf="selectedLocations.length === 0">
								<div class="card-header">Locations</div>
								<div class="card-body">
									<div>None selected.</div>
								</div>
							</div>
							<div class="card" *ngFor="let location of selectedLocations">
								<div class="card-header">
									<span>{{location.Name}}</span>
									<strong class="float-end" *ngIf="selectedHeadings.length === 1">Hours:
										{{getRecommendedHours(selectedHeadings[0], location)}}</strong>
								</div>
								<div class="collapse show">
									<div class="card-body">
										<table class="table table-bordered">
											<thead>
												<tr>
													<th>Tier</th>
												</tr>
											</thead>
											<tbody>
												<tr
													[ngClass]="{'highlighted': selectedHeadings.length !== 0 && selectedLocations.length === 1}">
													<td><input [(ngModel)]="location.TierId" (change)="validateLocationTier(location)"
															type="number" min="1" max="{{getMaxTier()}}" class="form-control"></td>
												</tr>
											</tbody>
										</table>
									</div>
									<div class="card-footer text-end">
										<button (click)="deselectLocation(location)" class="btn btn-link">Cancel</button>
										<button (click)="saveLocation(location)" class="btn btn-success">
											<i class="fa fa-save"></i>
											Save
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>