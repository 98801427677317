<div *withPermissions="let allow of ['CanUploadCustomReports']">
  <div [hidden]="!showAddNew" class="row" style="margin-bottom: 5px;">
    <div class="col-md-12">
      <button [disabled]="!allow().CanUploadCustomReports()" (click)="addCustomReport()"
        class="btn btn-primary float-end">
        <i class="fa fa-plus"></i>
        Add Custom Report
      </button>
    </div>
  </div>
  <app-table [items]="reports()" [exportFilename]="'custom-reports'">
    <ng-template #tableHeader>
      <tr>
        <th export sort field="Name">Name</th>
        <th export sort field="AssignedMonth">Month</th>
        <th export sort field="AssignedYear">Year</th>
        <th export sort field="source">Source</th>
        <th export field="S3Url">File</th>
        <th *ngIf="allowDelete">Action</th>
      </tr>
    </ng-template>
    <ng-template #tableBody let-report>
      <tr>
        <td>{{report.Name}}</td>
        <td>{{report.AssignedMonth}}</td>
        <td>{{report.AssignedYear}}</td>
        <td>{{report.source}}</td>
        <td>
          <a [externalLink]="{ url: report.S3Url, text: 'View File' }" class="btn btn-primary"></a>
        </td>
        <ng-container *ngIf="allowDelete">
          <td>
            <ng-container *ngIf="report.CustomReportId > 0">
              <button (click)="deleteFile(report)" class="btn btn-danger">
                <i class="fa fa-times"></i>
                Delete
              </button>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </app-table>
</div>