<div>
	<div class="row">
		<div class="col-md-12">
			<compose Selector="app-components-managecustomer-opentickets" [ActivateOptions]="{
                        customer: customerObservable,
                        parent: this,
                        apiCallback: openTicketsPublicApiCallback.bind(this),
                        notesAndTicketsPublicApi: getPublicApi()
                    }"></compose>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<compose Selector="app-components-managecustomer-customersynopsis" [ActivateOptions]="{
                        customer: customerObservable,
                        notesAndTicketsPublicApi: getPublicApi()
                    }"></compose>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<app-tab-panel [displayMode]="tabPanelDisplayMode.horizontalCard">
				<app-tab title="Notes">
					<app-components-managecustomer-notes *tabContent [options]="{
						customer: customer,
						apiCallback: notesPublicApiCallback.bind(this)
					}"></app-components-managecustomer-notes>
				</app-tab>
				<app-dynamic-tab title="Notifications">
					<app-components-managecustomer-customer-notifications *tabContent [customerId]="customerObservable.CustomerId()">
					</app-components-managecustomer-customer-notifications>
				</app-dynamic-tab>
			</app-tab-panel>
		</div>
	</div>
</div>