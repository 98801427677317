import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-components-clicktocall',
  templateUrl: './clickToCall.component.html'
})
export class ClickToCallComponent {
  @Input() public Phone: string;
  @Input() public CountryCode: string = null;

  public get Href(): string {
    try
    {
      const util = libphonenumber.PhoneNumberUtil.getInstance();
      const number = util.parse(this.Phone, this.CountryCode);
      return 'tel:' + util.format(number, libphonenumber.PhoneNumberFormat.E164);        
    }
    catch
    {
      return undefined;
    }
  }

  public onClick(event: any): void {
    if (!this.Href) {
      window.launchpad.utils.selectText(undefined, event);
    }
  }
}