import { Component, EventEmitter, OnInit, TemplateRef } from '@angular/core';
import System from 'framework/System';
import BaseComponent from '../BaseComponent';
import IValidatedResult = app.interfaces.IValidatedResult;
import KeywordSuggestionStatus = Boo.Objects.Enums.KeywordSuggestionStatus;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import Utils from '../../../../app/shared/utils';
import { KeywordSuggestionService } from '../../../services/keyword-suggestion.service';
import { UniqueKeywordSuggestionValidator } from '../../shared/Keywords/unique-keyword-suggestion-validator';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UrlSuggestionService } from '../../../services/url-suggestion.service';
import { Observable, forkJoin } from 'rxjs';
import { switchMap, finalize, map } from 'rxjs/operators';
import { SessionStorageService } from '../../../services/session-storage.service';
import { CustomerService } from '../../../services/customer.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketService } from '../../../services/ticket.service';
import { CustomerNoteService } from '../../../services/customer-note.service';
import { KeywordSuggestionConfig } from 'app/models/KeywordSuggestionConfig';
import { AddKeywordSuggestionRequest } from '../../shared/Keywords/add-keyword-suggestion-request';
import { TaskKeyValuePairService } from '../../../services/task-keyvaluepair.service';
import { KeywordsHelper } from './helpers/keywords-helper';
import { SeoOnboardingKeywordsHelper } from './helpers/seo-onboarding-keywords-helper';
import { StrategyUpdateKeywordsHelper } from './helpers/strategy-update-keywords-helper';
import { TaskNoteService } from '../../../services/task-note.service';
import { CustomerCampaignService } from '../../../services/customer-campaign.service';

@Component({
    selector: 'app-components-ticket-keywords',
    templateUrl: './Keywords.component.html'
})
export class KeywordsComponent extends BaseComponent implements OnInit {
    summary: Boo.Objects.KeywordSuggestions.KeywordSuggestionSummary;
    keywordSuggestionValidator: UniqueKeywordSuggestionValidator;
    nonKnockoutCustomer: Boo.Objects.Customer;
    isOpen = false;
    redoNoteForm: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(2048)]);
    isNationalChanged = new EventEmitter<boolean>();
    addKeywordToUrlSuggestion = new EventEmitter<AddKeywordSuggestionRequest>();
    originalIsNational: boolean;
    keywordResearchNotes: KeywordResearchDisplayNote[];
    discoveryNote: string;
    salesNote: string;
    keywordTypes: Boo.Objects.KeywordSuggestionType[];
    isEnabled: boolean;
    triggerTicketCancel: () => void;
    keywordSuggestionConfig: KeywordSuggestionConfig;
    keywordSuggestionCount: number;
    historicKeyword: Boo.Objects.LegacyKeywordSuggestion;
    newHaloKeywords: Boo.Objects.LegacyKeywordSuggestion[] = [];
    approvedHaloKeywordCount: number = 0;
    canChangeCampaignType: boolean = false;
    unorderedUrls: Boo.Objects.WebsiteUrl[] = [];
    orderedUrls: Boo.Objects.WebsiteUrl[] = [];
    activeHaloKeywords: Boo.Objects.WebsiteKeywordTracking[] = [];
    helper: KeywordsHelper;

    ticketTypes: typeof Boo.Objects.Enums.TicketTypeEnum;

    hisoricKeywordForm: UntypedFormGroup = new UntypedFormGroup({
        urlSuggestion: new UntypedFormControl(null, Validators.required),
        keywordType: new UntypedFormControl(null, Validators.required)
    });

    private keywordDiscoveryTag: string = 'Keyword Discovery: ';

    private researchTaskNoteTypes: Boo.Objects.Enums.TaskNoteContactTypesEnum[] = [
        Boo.Objects.Enums.TaskNoteContactTypesEnum.KeywordResearchNote,
        Boo.Objects.Enums.TaskNoteContactTypesEnum.KeywordResearchRedoNote,
        Boo.Objects.Enums.TaskNoteContactTypesEnum.KeywordResearchStrategyUpdateNote,
        Boo.Objects.Enums.TaskNoteContactTypesEnum.KeywordResearchStrategyUpdateRedoNote
    ]

    constructor(
        private keywordSuggestionService: KeywordSuggestionService,
        private urlSuggestionService: UrlSuggestionService,
        private customerService: CustomerService,
        private modalService: NgbModal,
        private sessionStorageService: SessionStorageService,
        private ticketService: TicketService,
        private customerNoteService: CustomerNoteService,
        private taskKeyValuePairService: TaskKeyValuePairService,
        private taskNoteService: TaskNoteService,
        private customerCampaignService: CustomerCampaignService) {
        super();
    }

    ngOnInit(): void {
        this.redoNoteForm.disable();
        this.keywordSuggestionValidator = this.helper.createUniqueKeywordSuggestionValidator();
    }

    public activate(params: app.ticket.components.interfaces.IKeywordsViewModelActivateParams): JQueryPromise<void> {
        this.triggerTicketCancel = params.triggerTicketCancel;
        this.isEnabled = params.canEdit;
        this.canChangeCampaignType = params.featureConfig.canChangeCampaignType;
        this.isOpen = params.featureConfig.openOnLoad;

        this.hisoricKeywordForm.get('urlSuggestion').valueChanges.subscribe((urlSuggestion: Boo.Objects.LegacyUrlSuggestion) => {
            let suggestion = urlSuggestion?.KeywordSuggestions.find(x => x.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Approved);

            if (suggestion) {
                this.hisoricKeywordForm.get('keywordType').setValue(suggestion.KeywordSuggestionTypeId);
                this.hisoricKeywordForm.get('keywordType').disable();
            } else {
                this.hisoricKeywordForm.get('keywordType').setValue(null);
                this.hisoricKeywordForm.get('keywordType').enable();
            }
        });

        this.helper =  this.getHelper(params.ticket.TicketTypeId());

        return super.activate(params).then(() => $.when<any>(
            Utils.wrapDfd(this.keywordSuggestionService.getTicketSummary(this.ticket.CustomerId())),
            Utils.wrapDfd(this.taskNoteService.getByTicketId(this.ticket.TicketId())),
            Utils.wrapDfd(this.sessionStorageService.getStaticData()),
            this.getDiscoveryNote(params.customer.CustomerId()),
            Utils.wrapDfd(this.customerNoteService.getSalesNotes(params.customer.CustomerId())),
            Utils.wrapDfd(this.helper.loadAdditional())))
            .then((summary: Boo.Objects.KeywordSuggestions.KeywordSuggestionSummary, notes: Boo.Objects.TaskNote[], staticData: Boo.Objects.LaunchPadStaticData, discoveryNote: string, salesNotes: Boo.Objects.CustomerNote[]) => {
                this.summary = summary;
                this.keywordTypes = staticData.KeywordSuggestionTypes;
                this.summary.HaloKeywordSuggestions = this.summary.HaloKeywordSuggestions.filter(x => x.KeywordSuggestionStatusId !== KeywordSuggestionStatus.Historic);
                this.summary.HaloKeywordSuggestions.map(suggestion => suggestion.KeywordSuggestionType = this.keywordTypes.find(x => x.KeywordSuggestionTypeId === suggestion.KeywordSuggestionTypeId));
                this.summary.HistoricalKeywordSuggestions.map(suggestion => suggestion.KeywordSuggestionType = this.keywordTypes.find(x => x.KeywordSuggestionTypeId === suggestion.KeywordSuggestionTypeId));
                this.summary.UrlSuggestions.flatMap(x => x.KeywordSuggestions).map(suggestion => suggestion.KeywordSuggestionType = this.keywordTypes.find(x => x.KeywordSuggestionTypeId === suggestion.KeywordSuggestionTypeId));
                this.nonKnockoutCustomer = ko.toJS(this.customer);
                this.originalIsNational = this.customer.IsCampaignNational();

                // show all kwr notes
                this.keywordResearchNotes = notes.filter(x => this.researchTaskNoteTypes.includes(x.TaskNoteContactTypeId))
                    .sort((a, b) => b.InsertedDate.getTime() - a.InsertedDate.getTime())
                    .map(x => <KeywordResearchDisplayNote>{
                        Note: x.Note,
                        Type: this.getTaskNoteTypeDisplayText(x.TaskNoteContactTypeId),
                        InsertedDate: x.InsertedDate
                    });

                this.discoveryNote = discoveryNote;
                if (salesNotes && salesNotes.length > 0) {
                    this.salesNote = _.chain(salesNotes)
                            .filter((note: Boo.Objects.CustomerNote) => {
                                return note.Text !== '';
                            })
                            .pluck('Text')
                            .value()
                            .reverse()
                            .join('\n--------\n');
                }
                const assignableKeywordTypes = [Boo.Objects.Enums.KeywordSuggestionTypes.RecommendedOne, Boo.Objects.Enums.KeywordSuggestionTypes.RecommendedTwo];
                this.keywordTypes = this.keywordTypes.filter((type: Boo.Objects.KeywordSuggestionType) => assignableKeywordTypes.indexOf(type.KeywordSuggestionTypeId) > -1);
                this.keywordSuggestionConfig = this.helper.getKeywordSuggestionConfig();
                this.keywordSuggestionCount = summary.UrlSuggestions.flatMap(x => x.KeywordSuggestions).length;
                this.updateHaloKeywordCount();
                return
            });
    }

    loadStrategyUpdateData(): Observable<any> {
        return forkJoin([
            this.customerCampaignService.getWebsiteUrls(this.ticket.CustomerId(), true, this.customerCampaignService.activeStatuses()), // excludeInactiveKeywords: true
            this.customerCampaignService.getActiveHaloKeywords(this.ticket.CustomerId()),
            this.taskKeyValuePairService.getByTaskIdAndKeyId(this.ticket.AssociatedTaskId(), Boo.Objects.Enums.KeyEnum.KeywordResearchWebsiteUrlIds)
        ])
        .pipe(
            map(([urls, haloKeywords, taskKeyValuePair]) => {
                let orderedUrlIds = taskKeyValuePair.KeyValuePair.Value.split(',').map(x => parseInt(x));
                this.unorderedUrls = urls.filter(x => !orderedUrlIds.includes(x.WebsiteUrlId));
                this.orderedUrls = urls.filter(x => orderedUrlIds.includes(x.WebsiteUrlId));
                this.activeHaloKeywords = haloKeywords;
        }));
    }

    redoStateChange(isRedoRequested: boolean): void {
        if (isRedoRequested) {
            this.redoNoteForm.enable();
        } else {
            this.redoNoteForm.disable();
            this.redoNoteForm.reset();
            this.redoNoteForm.setValue('');
        }
    }

    updateIsNational(isNational: boolean): void {
        if (this.customer.IsCampaignNational() !== isNational) {
            this.customer.IsCampaignNational(isNational);

            if (this.originalIsNational !== null) {
                let isNationalChanged = isNational !== this.originalIsNational;

                if (isNationalChanged) {
                    this.newHaloKeywords = this.summary.UrlSuggestions.flatMap(x => x.KeywordSuggestions).map(x => this.copySuggestionAsHalo(x));
                } else {
                    this.newHaloKeywords = [];
                }

                this.isNationalChanged.emit(isNationalChanged);
                this.updateHaloKeywordCount();
            }
        }
    }

    redo(modal: NgbActiveModal): void {
        if (this.redoNoteForm.invalid) {
            this.redoNoteForm.markAsTouched();
            return;
        }

        this.isLoading(true);

        if (this.customer.IsCampaignNational() !== this.originalIsNational) {
            this.summary.UrlSuggestions.forEach(urlSuggestion => {
                if (!urlSuggestion.RejectionNote) {
                    urlSuggestion.RejectionNote = this.customer.IsCampaignNational() ? "Campaign changed to National" : "Campaign changed to Local";
                }
            });
        }
        // Set the UrlSuggestionId to null for all new halo keywords to disassociate them from the UrlSuggestion
        this.newHaloKeywords.forEach(keywordSuggestion => keywordSuggestion.UrlSuggestionId = null);
        this.summary.HaloKeywordSuggestions = this.summary.HaloKeywordSuggestions.concat(this.newHaloKeywords.filter(x => x.KeywordSuggestionStatusId !== Boo.Objects.Enums.KeywordSuggestionStatus.Rejected));
        // Clear the new halo keywords to prevent visual bugs or issues if the ticket fails to save
        this.newHaloKeywords = [];

        this.summary.HaloKeywordSuggestions.forEach(keywordSuggestion => {
            keywordSuggestion.KeywordSuggestionStatusId = keywordSuggestion.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Rejected ? Boo.Objects.Enums.KeywordSuggestionStatus.Historic : Boo.Objects.Enums.KeywordSuggestionStatus.Approved;
        });

        forkJoin([this.urlSuggestionService.save(this.customer.CustomerId(), this.summary.UrlSuggestions), this.keywordSuggestionService.save(this.summary.HaloKeywordSuggestions), this.customerService.saveCampaignType(this.customer.CustomerId(), this.customer.IsCampaignNational())])
            .pipe(
                switchMap(() => this.helper.requestRedo(this.keywordSuggestionService)),
                finalize(() => modal.close())
            )
            .subscribe(
                () => null,
                err => toastr.error(err)
            );
    }

    openRedoModal(content: TemplateRef<any>): void {
        if (this.redoNoteForm.enabled) {
            if (this.summary.UrlSuggestions.some(x => x.KeywordSuggestions.some(y => y.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Proposed))) {
                toastr.error('Each URL must be approved or rejected before requesting a Keyword Research Redo.');
                return;
            }

            let duplicateKeywords = this.getDuplicateKeywords();
            if (duplicateKeywords.length > 0) {
                toastr.error('Cannot request a redo with active duplicate halo keywords. Please reject the duplicates before requesting a Keyword Research Redo.'
                    + '<br><br><b>Duplicate Halo Keywords:</b><br>' + duplicateKeywords.join('<br>'));
                return;
            }

            if (this.approvedHaloKeywordCount > this.keywordSuggestionConfig.maximumHaloKeywordSuggestions) {
                toastr.error(`Too many halo keywords. Please reject ${this.approvedHaloKeywordCount - this.keywordSuggestionConfig.maximumHaloKeywordSuggestions} halo keywords before requesting a Keyword Research Redo.`);
                return;
            }
        }

        let modal = this.modalService.open(content, { backdrop: 'static' });
        modal.dismissed.subscribe(() => this.redoNoteForm.patchValue(''));
        modal.closed.subscribe(() => this.triggerTicketCancel());
    }

    openHistoricKeywordModal(content: TemplateRef<any>, suggestion: Boo.Objects.LegacyKeywordSuggestion): void {
        if (this.getNonRedoUrlSuggestions().length === 0) {
            toastr.error('All URLs currently have a redo requested.');
            return;
        }
        
        this.hisoricKeywordForm.get('keywordType').disable();
        this.historicKeyword = suggestion;
        let modal = this.modalService.open(content);
        
        modal.dismissed.subscribe(() => this.hisoricKeywordForm.reset());
        modal.closed.subscribe(() => this.hisoricKeywordForm.reset());
    }

    confirmReAddHistoricKeyword(modal: NgbActiveModal): void {
        if (this.hisoricKeywordForm.invalid) {
            this.hisoricKeywordForm.markAllAsTouched();
            return;
        }

        this.addKeywordToUrlSuggestion.emit({
            url: this.hisoricKeywordForm.get('urlSuggestion').value.Url, 
            keyword: this.historicKeyword.Keyword,
            area: this.historicKeyword.Area,
            isAreaFirst: this.historicKeyword.IsAreaFirst,
            rank: this.historicKeyword.Rank,
            type: this.hisoricKeywordForm.get('keywordType').value
        });
        modal.close();
    }

    updateKeywordCount() {
        this.keywordSuggestionCount = this.summary.UrlSuggestions.flatMap(x => x.KeywordSuggestions).length;
    }

    validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
        return super.validate(saveType)
            .then((validationResult) => {
                if (saveType === SaveTypes.Complete) {
                    if (this.redoNoteForm.enabled) {
                        validationResult.isValid = false;
                        validationResult.errorMessages.push('Cannot complete onboarding with Keyword Research Redo requests.');
                    } else if (this.summary.UrlSuggestions.some(x => x.KeywordSuggestions.some(y => y.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Proposed))) {
                        validationResult.isValid = false;
                        validationResult.errorMessages.push('All keyword suggestions must be approved before completing onboarding');
                    }
                } else if (saveType === SaveTypes.Update) {
                    if (this.redoNoteForm.enabled) {
                        validationResult.isValid = false;
                        validationResult.errorMessages.push('Cannot save onboarding with Keyword Research Redo requests.');
                    }
                }

                let duplicateKeywords = this.getDuplicateKeywords();
                if (duplicateKeywords.length > 0) {
                    validationResult.isValid = false;
                    validationResult.errorMessages.push('Cannot save with active duplicate halo keywords. Please reject the duplicates.'
                        + '<br><br><b>Duplicate Halo Keywords:</b><br>' + duplicateKeywords.join('<br>'));
                }

                if (this.approvedHaloKeywordCount > this.keywordSuggestionConfig.maximumHaloKeywordSuggestions) {
                    validationResult.isValid = false;  
                    validationResult.errorMessages.push(`Too many halo keywords. Please reject ${this.approvedHaloKeywordCount - this.keywordSuggestionConfig.maximumHaloKeywordSuggestions} halo keywords.`);
                }

                if (this.summary.UrlSuggestions.length + this.unorderedUrls.length > this.keywordSuggestionConfig.maximumPages) {
                    validationResult.isValid = false;
                    validationResult.errorMessages.push(`Maximum total urls is ${this.keywordSuggestionConfig.maximumPages}.`);
                }

                if (!validationResult.isValid) {
                    // open toggle panel and clear suggestion filter so errors are visible
                    this.isOpen = true;
                }

                return validationResult;
            });
    }

    save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
        return super.save(saveType)
            .then(() => {
                // Set the UrlSuggestionId to null for all new halo keywords to disassociate them from the UrlSuggestion
                this.newHaloKeywords.forEach(keywordSuggestion => keywordSuggestion.UrlSuggestionId = null);
                this.summary.HaloKeywordSuggestions = this.summary.HaloKeywordSuggestions.concat(this.newHaloKeywords.filter(x => x.KeywordSuggestionStatusId !== Boo.Objects.Enums.KeywordSuggestionStatus.Rejected));
                // Clear the new halo keywords to prevent visual bugs or issues if the ticket fails to save
                this.newHaloKeywords = [];

                if (saveType === SaveTypes.Complete) {
                    this.summary.HaloKeywordSuggestions.forEach(keywordSuggestion => {
                        keywordSuggestion.KeywordSuggestionStatusId = keywordSuggestion.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Rejected ? Boo.Objects.Enums.KeywordSuggestionStatus.Historic : Boo.Objects.Enums.KeywordSuggestionStatus.Approved;
                    });

                    this.summary.UrlSuggestions.forEach(urlSuggestion => {
                        urlSuggestion.KeywordSuggestions.forEach(keywordSuggestion => {
                            if (keywordSuggestion.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Rejected) {
                                keywordSuggestion.KeywordSuggestionStatusId = Boo.Objects.Enums.KeywordSuggestionStatus.Historic;
                            }
                        });
                    });
                }

                return Utils.wrapDfd(forkJoin([this.urlSuggestionService.save(this.customer.CustomerId(), this.summary.UrlSuggestions), this.keywordSuggestionService.save(this.summary.HaloKeywordSuggestions)]));
            })
            .then(() => {
                if (this.customer.IsCampaignNational() !== this.originalIsNational) {
                    return <app.ticket.interfaces.ISaveData>{
                        customer: {
                            IsCampaignNational: this.customer.IsCampaignNational()
                        }
                    };
                }

                return System.emptyPromise();
            })
    }

    getKeywordDisplayText(keyword: Boo.Objects.LegacyKeywordSuggestion): string {
        if (keyword.Area) {
          return keyword.IsAreaFirst ? keyword.Area + ' ' + keyword.Keyword : keyword.Keyword + ' ' + keyword.Area;
        } else {
          return keyword.Keyword;
        }
      }

    getNonRedoUrlSuggestions(): Boo.Objects.LegacyUrlSuggestion[] {
        return this.summary.UrlSuggestions.filter(x => x.KeywordSuggestions.length === 0 || x.KeywordSuggestions.some(y => y.KeywordSuggestionStatusId !== Boo.Objects.Enums.KeywordSuggestionStatus.Rejected));
    }

    urlApproved(urlSuggestion: Boo.Objects.LegacyUrlSuggestion) {
        let newHalo = urlSuggestion.KeywordSuggestions.filter(x => x.KeywordSuggestionStatusId === Boo.Objects.Enums.KeywordSuggestionStatus.Rejected && !this.summary.HaloKeywordSuggestions.some(y => y.Keyword === x.Keyword && y.Area === x.Area))
            .map(x => this.copySuggestionAsHalo(x));
        this.newHaloKeywords = this.newHaloKeywords.concat(newHalo);
        this.updateHaloKeywordCount();
    }

    urlApprovalUndone(urlSuggestion: Boo.Objects.LegacyUrlSuggestion) {
        this.newHaloKeywords = this.newHaloKeywords.filter(x => x.UrlSuggestionId !== urlSuggestion.UrlSuggestionId)
        this.updateHaloKeywordCount();
    }

    copySuggestionAsHalo(keywordSuggestion: Boo.Objects.LegacyKeywordSuggestion) {
        return <Boo.Objects.LegacyKeywordSuggestion>{
            Area: keywordSuggestion.Area,
            CustomerId: keywordSuggestion.CustomerId,
            IsAreaFirst: keywordSuggestion.IsAreaFirst,
            IsCustomerChoice: false,
            Keyword: keywordSuggestion.Keyword,
            KeywordSuggestionStatusId: Boo.Objects.Enums.KeywordSuggestionStatus.Proposed,
            KeywordSuggestionTypeId: Boo.Objects.Enums.KeywordSuggestionTypes.Halo,
            Page: keywordSuggestion.Page,
            Rank: keywordSuggestion.Rank,
            UrlSuggestionId: keywordSuggestion.UrlSuggestionId // Set the UrlSuggestionId so we can remove these keywords later if the approval is changed. Removed on save.
        };
    }

    updateHaloKeywordCount() {
        this.approvedHaloKeywordCount = this.helper.getHaloKeywordCount();
    }

    getTaskNoteTypeDisplayText(typeId: number): string {
        switch (typeId) {
            case Boo.Objects.Enums.TaskNoteContactTypesEnum.KeywordResearchNote:
                return 'Note';
            case Boo.Objects.Enums.TaskNoteContactTypesEnum.KeywordResearchRedoNote:
                return 'Redo';
            case Boo.Objects.Enums.TaskNoteContactTypesEnum.KeywordResearchStrategyUpdateNote:
                return 'Strategy Update Note';
            case Boo.Objects.Enums.TaskNoteContactTypesEnum.KeywordResearchStrategyUpdateRedoNote:
                return 'Strategy Update Redo Note';
        }
    }

    private getDiscoveryNote(customerId: number): JQueryPromise<string> {
        return this.customerNoteService.get(customerId)
        .filter(`startswith(Text, '${this.keywordDiscoveryTag}')`)
        .orderBy('InsertedDate desc')
        .top(1)
        .load()
        .then((notes: framework.data.IPageResult<Boo.Objects.CustomerNote>) => {
            if (notes.Items.length) {
            return notes.Items[0].Text.replace(this.keywordDiscoveryTag, '').trim();
            } else {
            return 'No discovery note available.';
            }
      });
    }

    private getDuplicateKeywords(): string[] {
        // Only need to check new halo keywords for duplicates
        let proposedHalo = this.newHaloKeywords.filter(x => x.KeywordSuggestionStatusId !== Boo.Objects.Enums.KeywordSuggestionStatus.Rejected);
        return proposedHalo.reduce(
            (acc, cur) => {
                let entry = acc.find(x => x.Keyword.toLowerCase() === cur.Keyword.toLowerCase() && x.Area?.toLowerCase() === cur.Area?.toLowerCase());
                entry ? entry.Count++ : acc.push({ Keyword: cur.Keyword, Area: cur.Area, Count: 1 });
                return acc;
            },
            []
        ).filter(x => x.Count > 1).map(x => x.Keyword + (x.Area ? ' ' + x.Area : ''));
    }

    private getHelper(ticketType: Boo.Objects.Enums.TicketTypeEnum): KeywordsHelper {
        switch (ticketType) {
            case Boo.Objects.Enums.TicketTypeEnum.SeoOnboarding:
                return new SeoOnboardingKeywordsHelper(this);
            case Boo.Objects.Enums.TicketTypeEnum.KeywordResearchStrategyUpdate:
                return new StrategyUpdateKeywordsHelper(this);
            default:
                return new SeoOnboardingKeywordsHelper(this);
        }
    }
}

interface KeywordResearchDisplayNote {
    Note: string;
    Type: string;
    InsertedDate: Date;
}
