import app from 'App';
import routes from 'app/routes';
import activityLog from 'app/activitylog';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SessionStorageService } from './session-storage.service';
import { ReloadService } from './reload.service';
import Utils from '../shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ViewLocatorService {
  private routes = new routes().routes;
  private activityLog = new activityLog();

  public currentPath = ko.observable();

  private temporarilyIgnoreRouteChanges = false;

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private reloadService: ReloadService) { }

  public cancelNavigation(oldHash: any) {
    if (!oldHash) {
      // If "oldHash" is null or undefined, fire off a changeRoute with a blank path
      this.setRoute('');
      this.changeRoute('', undefined);
    } else {
      this.temporarilyIgnoreRouteChanges = true;

      // Change the hash
      this.setRoute(oldHash);

      setTimeout(() => {
        // Turn hash change monitoring back on
        this.temporarilyIgnoreRouteChanges = false;
      }, 0);
    }
  }

  /**
   * Gets the current route hash
   */
  public getRoute() {
    return (this.router.url || '/').substring(1);
  }

  public setRoute(hash: string): void {
    if (hash.indexOf('/') !== 0) {
      hash = '/' + hash;
    }

    this.router.navigateByUrl(hash);
  }

  /**
   * Sets the displayed route hash without actually changing the view,
   * since hash change monitoring is temporarily disabled.
   */
  public setDisplayedRoute(newHash: any) {
    this.temporarilyIgnoreRouteChanges = true;
    this.setRoute(newHash);
    setTimeout(() => {
      // Turn hash change monitoring back on
      this.temporarilyIgnoreRouteChanges = false;

      amplify.publish(launchpad.config.events.urlChanged, newHash);
      gtag('event', 'page_view', { page_path: '/#/' + newHash });
    }, 0);
  }

  /**
   * Adds a new hashPart to the end of the displayed route without changing the view
   */
  public addToDisplayedRoute(hashPart: any, preventDuplicates: any) {
    hashPart = '/' + (hashPart ? hashPart.toString() : '').replace(/^\/+/, '');
    var oldHash = this.getRoute().replace(/\/+$/, '');

    if (!preventDuplicates || oldHash.substr(-hashPart.length) !== hashPart) {
      this.setDisplayedRoute(oldHash + hashPart);
    }
  }

  /**
 * Removes the hashPart (if it exists) from the end of the displayed route without changing the view
 */
  public removeFromDisplayedRoute(hashPart: any) {
    hashPart = '/' + (hashPart ? hashPart.toString() : '').replace(/^\/+/, '');
    var oldHash = this.getRoute().replace(/\/+$/, '');

    if (oldHash.substr(-hashPart.length) === hashPart) {
      this.setDisplayedRoute(oldHash.substr(0, oldHash.length - hashPart.length));
    }
  }

  private parseHash(hash: any) {
    // If there isn't a hash to parse, then just return an undefined
    if (hash === undefined || hash === null) {
      return undefined;
    }

    var urlFragments = [];
    var newHash;

    // Test if there is more than 1 "/" in the hash
    if (hash.indexOf('/') !== hash.lastIndexOf('/')) {
      // Split the hash on "/"s
      urlFragments = hash.split('/').map(function (s: any) { return decodeURIComponent(s); });

      // Each route should only have two parts (base)/(page)
      newHash = urlFragments[0] + '/' + urlFragments[1];

      // Anything extra is additional information that should be passed to the content view model
      urlFragments = urlFragments.slice(2, urlFragments.length);
    } else {
      // There's no additional segments in the hash so just use the hash passed in
      newHash = hash;
    }

    return {
      path: newHash,
      fragments: urlFragments
    };
  }

  private changeRoute(originalNewHash: any, originalOldHash: any) {
    //Handle additional parameters in the url
    var parsedOldHash = this.parseHash(originalOldHash);
    var parsedNewHash = this.parseHash(originalNewHash);
    var oldHash = parsedOldHash ? parsedOldHash.path : undefined;
    var newHash = parsedNewHash.path;

    //Prevent navigation in the background while a modal view is up
    var $mainContainer = $(launchpad.config.mainContainer);
    if (!$mainContainer.is(':visible')) {
      this.cancelNavigation(oldHash);
      return;
    }

    // If the session is expired and the user has already been notified then cancel navigation
    if (app && app.hasUserBeenNotifiedSessionExpired()) {
      app.emphasizeRefreshNotification();
      this.cancelNavigation(oldHash);
      return;
    }

    // auto reload if reload is required
    if (this.reloadService.isReloadRequired()) {
      this.reloadService.reload('Auto reload on route change from ' + oldHash + ' to ' + newHash);
      return;
    }

    // Let the user see that the app is navigating (spinner icon in the footer)
    app.isNavigating(true);

    // Find the appropriate route from the routes array (routes.js)
    var route: any = _.find(this.routes, (item: any) => {
      // split the hash on "?" in case there's a querystring.
      // currently the only view that expects a querystring is reset password. (1/17/20 mbeatty)
      return (newHash || '').split('?')[0].toLowerCase() === (item.hash || '').toLowerCase();
    });

    // If a route wasn't found and it's not the blank route, show an error notification (route not found)
    if (!route) {
      amplify.publish(launchpad.config.events.errorOccurred, newHash + ' could not be found.');
      app.isNavigating(false);
      return;
    }

    // Some app header/footer logic (could potentially be handled in urlChanged event in app
    if (newHash === 'login' || newHash.includes('resetpassword') || newHash === 'switchpartner' || newHash === 'logout') {
      app.showHeader(false);
      app.showFooter(false);
    } else {
      app.showHeader(true);
      app.showFooter(true);
    }

    // call tracking code
    if (gtag) {
      gtag('event', 'page_view', { page_path: '/#/' + newHash });
    }

    // Log the request in our activity log
    if (this.activityLog.record) {
      this.activityLog.record('State Change: ' + ('/#/' + newHash));
    }

    // Redirect if necessary
    // This is all the way down here so that we track the route change as per usual then redirect.
    if (route.redirectHash) {
      this.changeRoute(route.redirectHash, originalNewHash);
      this.setDisplayedRoute(route.redirectHash);
      return;
    }

    $.when<any>(
      Utils.wrapDfd(this.sessionStorageService.getUser()),
      Utils.wrapDfd(this.sessionStorageService.getPartner()))
      .done((user, partner) => {
        if (!user && newHash !== 'login' && newHash !== 'logout' && newHash.indexOf('resetpassword') !== 0) {
          this.cancelNavigation(oldHash);
          return;
        }

        if (!partner && newHash !== 'login' && newHash !== 'logout' && newHash !== 'switchpartner' && newHash.indexOf('resetpassword') !== 0) {
          this.cancelNavigation(oldHash);
          return;
        }

        let unauthorized = () => {
          // View/VM cannot be activated
          toastr.error('You are not authorized to access ' + route.title);
          app.isNavigating(false);
          if (!oldHash) {
            app.goToDefaultRoute(user.UserLevelId);
          } else {
            this.cancelNavigation(oldHash);
          }

          return;
        }

        const contentViewLoaded = $.Deferred();
        const statsViewLoaded = $.Deferred();
        const navigationViewLoaded = $.Deferred();
        app.route({
          contentAngularSelector: route.contentAngularSelector,
          contentViewLoadedCallback: () => { contentViewLoaded.resolve(); },
          statsAngularSelector: route.statsAngularSelector,
          statsViewLoadedCallback: () => { statsViewLoaded.resolve(); },
          navigationAngularSelector: route.navigationAngularSelector,
          navigationViewLoadedCallback: () => { navigationViewLoaded.resolve(); },
          title: route.title,
          urlFragments: parsedNewHash.fragments,
          unauthorizedCallback: () => { unauthorized(); }
        });

        let deferreds = [];
        if (route.contentAngularSelector) {
          deferreds.push(contentViewLoaded);
        }
        if (route.statsAngularSelector) {
          deferreds.push(statsViewLoaded);
        }
        if (route.navigationAngularSelector) {
          deferreds.push(navigationViewLoaded);
        }
        $.when.apply(this, deferreds).done(() => {
          app.isNavigating(false);
          amplify.publish(launchpad.config.events.urlChanged, newHash);
        });
      });
  }

  public hashesMatch(hashA: string, hashB: string): boolean {
    let normalizeHash = (x: string) => {
      x = x || '';
      if (x.startsWith('/')) {
        x = x.substring(1);
      }
      if (x.startsWith('#/')) {
        x = x.substring(2);
      }

      return x.trim().toLowerCase();
    }

    return normalizeHash(hashA) === normalizeHash(hashB);
  }

  public activateRouting(): void {
    this.router.events.subscribe(event => {
      if (this.temporarilyIgnoreRouteChanges) {
        return;
      }

      if (event instanceof NavigationEnd) {
        let oldHash = (this.router.getCurrentNavigation().initialUrl || '/').toString().substring(1);
        let newHash = (this.router.getCurrentNavigation().extractedUrl || '/').toString().substring(1);
        setTimeout(() => {
          this.changeRoute(newHash, oldHash)
        }, 0);
      }
    });
  }
}
