<div class="row">
  <div class="col-md-1">
    <ng-container *ngIf="!isApproved && !isRejected">
      <button class="btn w-100 btn-success" (click)="approveGroup()">Approve</button>
    </ng-container>
  </div>
  <div class="col-md-11">
    <ul class="list-group">
      <ng-container *ngFor="let keyword of suggestions">
        <li class="list-group-item" [ngClass]="{'bg-success bg-gradient': isApproved, 'text-danger text-decoration-line-through': isRejected }">
          <div class="row">
            <div class="col-md-9">
              <ng-container *ngIf="keyword.IsCustomerChoice">
                <a class="fa fa-times" (click)="removeKeyword(keyword)"></a>
              </ng-container>
              {{ getKeywordDisplayText(keyword) }}
              <span class="badge rounded-pill bg-secondary">{{"rank: " + keyword.Rank}}</span>
              <span class="badge rounded-pill bg-secondary" *ngIf="keyword.IsCustomerChoice">Customer Choice</span>
            </div>
            <div class="col-md-3">
              {{ keyword.KeywordSuggestionType.Name }}
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>