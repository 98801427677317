/* tslint:disable */

import { Component } from '@angular/core';
import app from 'App';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';


@Component({
  selector: 'app-components-logout',
  templateUrl: './Logout.component.html'
})
export class LogoutComponent {

  constructor(private sessionStorageService: SessionStorageService) { }

  public activate(): void {
    let isYellow = false;
    $.when<any>(
      Utils.wrapDfd(this.sessionStorageService.getPartner()),
      Utils.wrapDfd(this.sessionStorageService.getStaticData()))
    .then((partner, staticData) => {
      isYellow = window.launchpad.utils.partnerInPartnerGroup(partner.PartnerId, window.launchpad.config.PartnerGroupEnum.Yellow, staticData.PartnerGroups);
      app.showContent(false);
      this.sessionStorageService.clear();
      this.sessionStorageService.logout();
    })
    .always(() => {
      if (isYellow) {
        window.location.href = window.urlConfig.yellowLogoutUrl;
      } else {
        window.location.reload();
      };
    });
  }
}
