import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[file]'
})
export class FileDirective implements AfterViewInit {
  @Input('file') public Settings: { data: KnockoutObservable<File>, accept: string };

  private element: HTMLInputElement;
  constructor(elementRef: ElementRef) {
    this.element = elementRef.nativeElement;
  }

  public ngAfterViewInit(): void {
    if (this.element.tagName.toLowerCase() !== 'input') {
      throw new Error('File binding only applies to input elements (type=file)');
    }

    const acceptTypes = ko.utils.unwrapObservable(this.Settings.accept);
    if (acceptTypes) {
      this.element.setAttribute('accept', acceptTypes);
    }

    this.element.onchange = (e: any) => {
      if (this.element.files) {
        this.Settings.data(this.element.files[0]);
      }
    };
  }
}