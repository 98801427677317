import { Component, OnInit } from '@angular/core';
import { InternalActionService } from '../../../services/internal-action.service';
import { InternalTaskService } from '../../../services/internal-task.service';
import { ReloadService } from '../../../services/reload.service';
import { finalize } from 'rxjs/operators';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { InternalActionWorkspaceResult } from './internal-action-workspace/internal-action-workspace.component'
import NamedTaskType from '../../../shared/models/named-task-type';

type InternalTaskDetails = Boo.Objects.InternalTaskDetails;

@Component({
  selector: 'app-components-specialist-internal-tasks',
  templateUrl: './internal-tasks.component.html'
})
export class InternalTasksComponent implements OnInit {
  isLoading: boolean = false;
  myInternalTasks: Boo.Objects.InternalTaskDetails[] = [];
  selectedTaskTypeId?: number = null;
  taskTypes: NamedTaskType[] = [];

  areStatsLoading: boolean = true;
  stats: Boo.Objects.InternalTaskStats;

  constructor(
    private internalActionService: InternalActionService,
    private internalTaskService: InternalTaskService,
    private reloadService: ReloadService) { }

  ngOnInit() {
    this.internalTaskService.getStats().subscribe({
      next: stats => {
        this.stats = stats;
        this.areStatsLoading = false;
      },
      error: () => toastr.error("Unable to load your internal task stats.")
    });

    this.reloadMyInternalTasks();
  }

  getNext() {
    if (this.reloadService.isReloadRequired()) {
      this.reloadService.reload('Auto reload from internaltasks getNext');
      return true;
    }

    this.isLoading = true;

    this.internalActionService.getNextActionInQueue()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        {
          next: (action) => {
            if (!action) {
              toastr.warning('There are no more tasks available for you.');
              return;
            }

            this.loadInternalAction(action);
          },
          error: () => toastr.error("Unable to retrieve next action.")
        }
      );
  }

  openTask(internalTask: InternalTaskDetails) {
    if (this.reloadService.isReloadRequired()) {
      this.reloadService.reload('Auto reload from internaltasks openTaskByInternalTaskId');
      return;
    }

    this.internalActionService.getByInternalTaskId(internalTask.InternalTaskId).subscribe(
      {
        next: (action) => this.loadInternalAction(action),
        error: () => toastr.error("Unable to open action.")
      }
    );
  }

  confirmUnlock(internalTask: InternalTaskDetails) {
    bootbox.confirm('Are you sure you want to unlock and unassign this task?',
      (confirm: boolean) => {
        if (confirm) {
          this.unlockAndUnassign(internalTask);
        }
      });
  }

  filteredTasks(): any {
    if (!this.selectedTaskTypeId) {
      return this.myInternalTasks;
    }

    return this.myInternalTasks.filter(x => x.TaskTypeId == this.selectedTaskTypeId);
  }

  private loadInternalAction(internalAction: Boo.Objects.InternalAction) {
    PriorityViewModel.ngShow('app-components-specialist-internal-tasks-internal-action-workspace', { internalAction })
      .then((result: InternalActionWorkspaceResult) => {
        if (result.getNext) {
          this.getNext();
        }
      })
      .always(() => {
        this.reloadMyInternalTasks()
      });
  }

  private reloadMyInternalTasks(): void {
    this.internalTaskService.getForUser()
      .subscribe({
        next: tasks => {
          this.myInternalTasks = tasks;
          this.taskTypes = tasks.filter(x => tasks.find(y => y.TaskTypeId == x.TaskTypeId) == x).map(x => {
            return {
              taskTypeName: x.TaskTypeName,
              taskTypeId: x.TaskTypeId
            }
          });
        },
        error: () => toastr.error("Unable to load your internal tasks.")
      })
  }

  private unlockAndUnassign(internalTask: InternalTaskDetails): void {
    this.internalActionService.unlockAndUnassign(internalTask.InternalTaskId)
      .pipe(finalize(this.reloadMyInternalTasks.bind(this)))
      .subscribe({
        error: () => toastr.error("Unable to unlock and unassign task.")
      });
  }
}