import { Component, Input, OnInit } from '@angular/core';
import timer from 'app/managecustomer/Timer';
import WebsiteUrlsAndTasksService, { ObsoleteWorkflowDefinitions } from 'app/services/WebsiteUrlsAndTasksService';
import { WebsiteUrlViewModel, IOnsiteRecommendation } from 'app/services/WebsiteUrlsAndTasksService';
import { PriorityViewModel } from 'app/models/PriorityViewModel';

@Component({
    selector: 'app-components-managecustomer-workflowtaskhistory',
    templateUrl: './WorkflowTaskHistory.component.html'
})
export class WorkflowTaskHistoryComponent implements OnInit {
    @Input() customer: Boo.Objects.Customer;

    websiteUrlStatuses_Active = Boo.Objects.Enums.WebsiteUrlStatuses.Active;
    pendingTaskStatusId: Boo.Objects.Enums.TaskStatusEnum = Boo.Objects.Enums.TaskStatusEnum.Pending;
    isLoading: KnockoutObservable<boolean>;
    websiteUrls: KnockoutObservableArray<WebsiteUrlViewModel> = ko.observableArray([]);
    tasks: KnockoutObservableArray<IOnsiteRecommendation> = ko.observableArray([]);
    urlFilter: KnockoutObservable<string>;
    urlFilterOptions: KnockoutComputed<app.interfaces.ISelectOption[]>;
    originFilter: KnockoutObservable<string>;
    originFilterOptions: KnockoutComputed<app.interfaces.ISelectOption[]>;
    statusFilter: KnockoutObservable<string>;
    statusFilterOptions: KnockoutComputed<app.interfaces.ISelectOption[]>;
    createdByFilter: KnockoutObservable<string>;
    createdByFilterOptions: KnockoutComputed<app.interfaces.ISelectOption[]>;
    assignmentFilter: KnockoutObservable<string>;
    filteredTasks: KnockoutComputed<WebsiteUrlWithTask[]>;

    constructor(
        private websiteUrlsAndTasksService: WebsiteUrlsAndTasksService) { }

    ngOnInit() {
        this.isLoading = ko.observable(true);
        this.urlFilter = ko.observable('');
        this.originFilter = ko.observable('');
        this.statusFilter = ko.observable('');
        this.createdByFilter = ko.observable('');
        this.assignmentFilter = ko.observable('');

        this.filteredTasks = ko.computed(() => {
            let filteredTasks = _.filter(this.tasks(), x => {
                if (this.originFilter() && this.originFilter() !== x.Origin) {
                    return false;
                }

                if (this.statusFilter() && this.statusFilter() !== x.DisplayStatus) {
                    return false;
                }

                if (this.createdByFilter() && this.createdByFilter() !== x.CreatedBy) {
                    return false;
                }

                let isAssigned = !!x.AssignedTo;
                if (this.assignmentFilter() === 'assigned' && !isAssigned) {
                    return false;
                }

                if (this.assignmentFilter() === 'unassigned' && isAssigned) {
                    return false;
                }

                return true;
            });

            return _.compact(_.map(filteredTasks, (task: IOnsiteRecommendation) => {
                let url = _.find(this.websiteUrls(), x => x.WebsiteUrlId() === task.WebsiteUrlId);
                if (!url) {
                    return undefined;
                }

                if (this.urlFilter() && this.urlFilter() !== task.PublishUrl && this.urlFilter() !== url.Url()) {
                    return undefined;
                }

                let result = <WebsiteUrlWithTask>_.extend({}, task, url);
                if (task.KeywordPhrases) {
                    result.KeywordPhrases = <any>ko.observable(task.KeywordPhrases);
                }
                return result;
            }));
        });

        return this.refresh();
    }

    refresh(): JQueryPromise<any> {
        this.isLoading(true);
        return $.when(this.loadUrls(), this.loadTasks()).then(() => {
            this.urlFilterOptions = ko.computed(() => _.map(_.union([''], _.sortBy(_.map(this.websiteUrls(), x => x.Url()), x => x), _.map(this.tasks(), x => x.PublishUrl || '')), x => { return { Name: x, Value: x }; }));
            this.originFilterOptions = ko.computed(() => _.map(_.union([''], _.sortBy(_.map(this.tasks(), x => x.Origin), x => x)), x => { return { Name: x, Value: x }; }));
            this.statusFilterOptions = ko.computed(() => _.map(_.union([''], _.sortBy(_.map(this.tasks(), x => x.DisplayStatus), x => x)), x => { return { Name: x, Value: x }; }));
            this.createdByFilterOptions = ko.computed(() => _.map(_.union([''], _.sortBy(_.map(this.tasks(), x => x.CreatedBy), x => x)), x => { return { Name: x, Value: x }; }));
        })
            .fail((message: string) => {
                toastr.error(message);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    isOnsiteBlogTask(workflowDefinitionId: number): boolean {
        return workflowDefinitionId === Boo.Objects.Enums.WorkflowDefinitionsEnum.OnsiteBloggingV2 || workflowDefinitionId === ObsoleteWorkflowDefinitions.ObsoleteOnsiteBloggingWorkflow;
    }

    isOnsiteCopyTask(WorkflowDefinitionId: number): boolean {
        return WorkflowDefinitionId === Boo.Objects.Enums.WorkflowDefinitionsEnum.OnsiteCopy;
    }

    loadTasks(): JQueryPromise<any> {
        this.isLoading(true);
        timer.resetUIActivityTime();
        return this.websiteUrlsAndTasksService.loadTasks(this.customer.CustomerId, true, true).then(tasks => {
            _.each(tasks, task => {
                (task as any).DetailsText = ko.computed(() => {
                    let result = '';
                    if (task.FileUrl) {
                        result += 'File: ' + task.FileUrl + '\r\n';
                    }
                    if (task.Comment) {
                        result += 'Instructions: ' + task.Comment + '\r\n';
                    }
                    if (task.Note) {
                        result += 'Approval Note: ' + task.Note + '\r\n';
                    }
                    return result;
                });
            });

            this.tasks(tasks);
        });
    }

    isContentRevisionTask(item: IOnsiteRecommendation): Boolean {
        return (item.TaskTypeId === Boo.Objects.Enums.TaskTypeEnum.OnsiteBloggingRevisionStandard || item.TaskTypeId === Boo.Objects.Enums.TaskTypeEnum.OnsiteBloggingRevisionPremium)
    }

    // MURDER - this method can be removed once recommendations are ready to be edited
    isOnsiteRecommendationTask(workflowDefinitionId: number): boolean {
        return workflowDefinitionId === Boo.Objects.Enums.WorkflowDefinitionsEnum.OnsiteRecommendation;
    }

    shouldShowImplementationInstructions(item: IOnsiteRecommendation): Boolean {
        // Copy can provide implementation instructions at ordering, while blogging (and presumably recommendations) cannot, so we need a special case for copy
        // MURDER "&& !this.isOnsiteRecommendationTask(item.WorkflowDefinitionId)" excludes recommendations from being shown and needs to be removed once editing implementation instructions is ready for recommendations
        return (this.isOnsiteCopyTask(item.WorkflowDefinitionId) && item.BoostToPublish) || (item.PublishTaskStatus != null && !this.isOnsiteRecommendationTask(item.WorkflowDefinitionId));
    }

    shouldShowRejectionInstructions(item: IOnsiteRecommendation): boolean {
        // Confirming rejection does not have an external status like the rest, so we have to have a special case for it
        return item.ExternalStatus === 'Reject' || item.WorkflowStateId === Boo.Objects.Enums.WorkflowStates.ConfirmingRejection;
    }

    editImplementationInstructions(item: IOnsiteRecommendation): void {
        PriorityViewModel.ngShow(
            'app-components-managecustomer-editimplementationinstructions',
            { 
                previewTaskId: item.PreviewTaskId, 
                requestTaskId: item.RequestTaskId,
                isReadOnly: this.areInstructionsReadonly(item),
                workflowDefinitionId: item.WorkflowDefinitionId
            }
        );
    }

    editRejectionInstructions(item: IOnsiteRecommendation): void {
        PriorityViewModel.ngShow(
            'app-components-managecustomer-editrejectioninstructions',
            { 
                taskId: item.TaskId,
                isReadOnly: this.areInstructionsReadonly(item, true),
                workflowDefinitionId: item.WorkflowDefinitionId
            }
        );
    }

    private areInstructionsReadonly(item: IOnsiteRecommendation, isRejection: Boolean = false): boolean {
        // We have several historical task types that may not follow our standard format or are in strange states (such as not having a request task, or completed copy tasks being verified without a publish task)
        // Most if not all of these should be completed anyway, so mark them all as non-editable so we don't edit completed tasks by accident
        if (this.websiteUrlsAndTasksService.isHistoricalTask(item.TaskTypeId) || item.RequestTaskId == null || (item.PublishTaskStatus == null && item.TaskVerifiedDate != null)) {
            return true;
        } else if (isRejection) {
            // Confirming rejection is a special case in which tasks are complete but we should be able to edit rejection instructions
            if (item.WorkflowStateId === Boo.Objects.Enums.WorkflowStates.ConfirmingRejection) {
                return false;
            }
            return (item.TaskStatusId === Boo.Objects.Enums.TaskStatusEnum.Complete
                || item.TaskStatusId === Boo.Objects.Enums.TaskStatusEnum.Locked
                || item.TaskStatusId === Boo.Objects.Enums.TaskStatusEnum.ReconcileComplete);
        } else {
            return (item.PublishTaskStatus === Boo.Objects.Enums.TaskStatusEnum.Complete
                || item.PublishTaskStatus === Boo.Objects.Enums.TaskStatusEnum.Locked
                || item.PublishTaskStatus === Boo.Objects.Enums.TaskStatusEnum.ReconcileComplete);
        }
    }

    private loadUrls(): JQueryPromise<any> {
        this.isLoading(true);
        timer.resetUIActivityTime();
        return this.websiteUrlsAndTasksService.loadUrls(this.customer.CustomerId).then(urls => {
            this.websiteUrls(urls);
        });
    }

}

type WebsiteUrlWithTask = WebsiteUrlViewModel & IOnsiteRecommendation;
