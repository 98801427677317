<div class="row" *ngIf="isVisible || audit">
  <div class="col-md-12">
    <div class="card card-default" [ngClass]="{'card-warning': audit && audit.Matches.length > 0 }" [loadingOverlay]="isLoading">
      <div class="card-header">
        <div class="row">
          <div class="col-md-6 text-left">Duplicate Content Checker <app-components-helptext [key]="'ContentAudit_DuplicateContentChecker'"></app-components-helptext></div>
          <div class="col-md-6 text-right" style="text-align: right;">
            <button *ngIf="(remainingDelay$ | async) === 0"
              (click)="auditContent()" [disabled]="isLoading" class="btn btn-primary">Check Content</button>
            <button *ngIf="(remainingDelay$ | async) > 0" [disabled]="true" class="btn btn-primary">Check
              Content in {{ remainingDelay$ | async | date:'mm:ss' }}</button>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="audit">
        <ul class="list-group">
          <li class="list-group-item" *ngFor="let match of audit.Matches">
            <b>URL:</b> <a [externalLink]="{ url: match.Url, text:match.Url }" rel="noreferrer"></a><br />
            <b>Matched Content:</b> {{ match.MatchedText }} <br />
            <b>Percentage:</b> {{ match.Percentage === 0 ? '<1%' : match.Percentage | percent }}
          </li>
        </ul>
        <ng-container *ngIf="audit.Matches.length === 0">
          No matches found
        </ng-container>
      </div>
    </div>
  </div>
</div>