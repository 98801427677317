import { Component } from '@angular/core';
import { QaGradingArea } from '../qa-grading-area/qa-grading-area.component';

@Component({
	selector: 'app-components-qa-grading-area-row',
	templateUrl: './qa-grading-area-row.component.html'
})
export class QaGradingAreaRow extends QaGradingArea {
	public criteriaClass: string;
	public firstClass: string;
	ngOnInit(): void {
		super.ngOnInit();
		//If there are less than 3, auto grow criteria
		this.criteriaClass = this.displayConfigs.length < 3 ? "col" : "col-md-4";
		//If there is a row with 2 elements, add an offset so that it will be the top row instead of the bottom
		this.firstClass = this.criteriaClass + (this.displayConfigs.length % 3 === 2 && this.displayConfigs.length > 3 ? " offset-md-2" : "");
	}
}