<div>
  <h4>BoostSite</h4>
  <ng-container *ngIf="!boostSiteAccountContainsData">
    None
  </ng-container>
  <ng-container *ngIf="boostSiteAccountContainsData">
    <div class="row">
      <div class="col-md-2">Admin SSO</div>
      <div class="col-md-10">
        <ng-container *ngIf="url">
          <a rel="noreferrer" target="_blank" href="{{url}}"
          style="cursor: pointer; white-space: nowrap;">
          <i class="fas fa-external-link-square-alt"></i><span class="externalLink">
            {{(url.length > 45)? (url | slice:0:45)+'...' : url}}
          </span></a>
          <button class="btn btn-sm btn-danger" (click)="removeUrl()">
            <i class="fas fa-times" style="margin-right: 0px;"></i>
            Hide
          </button>
        </ng-container>
        <ng-container *ngIf="!url">
          <button class="btn btn-sm btn-info" (click)="getUrl()">
            <i class="fas fa-copy" style="margin-right: 0px;"></i>
            Show
          </button>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">Account Id</div>
      <div class="col-md-10"><span
          (click)="window.launchpad.utils.selectText(undefined, $event)">{{website.WebsiteAccount.AccountId}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">Site Id</div>
      <div class="col-md-10"><span
          (click)="window.launchpad.utils.selectText(undefined, $event)">{{website.SiteId}}</span>
      </div>
    </div>
  </ng-container>
</div>