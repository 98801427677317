import { Component, Input, OnInit } from '@angular/core';
import Check from 'framework/Check';
import { PriorityViewModel } from 'app/models/PriorityViewModel';

@Component({
  selector: 'app-components-managecustomer-ticketgroup',
  templateUrl: './TicketGroup.component.html'
})
export class TicketGroupComponent implements OnInit {
  moment = moment;
  Date = Date;
  @Input() customer: CustomerObservable;
  @Input() parent: any;
  @Input() public tickets: Boo.Objects.TicketPartial[];
  @Input() private notesAndTicketsPublicApi: app.managecustomer.interfaces.INotesAndTicketsPublicApi;

  ngOnInit(): void {
    Check.isNotEmpty(this.customer, 'Customer cannot be null');
    Check.isNotEmpty(this.tickets, 'Tickets cannot be empty');
  }

  public editTicket(ticket: Boo.Objects.Ticket): void {
    PriorityViewModel.show('app-components-ticket', { customer: this.customer, ticketId: ticket.TicketId })
      .always((result: app.ticket.interfaces.IPriorityViewResult) => {
        if (result.modifiedDataFlags && result.modifiedDataFlags.customer) {
          this.notesAndTicketsPublicApi.refreshCustomer();
        }
        this.notesAndTicketsPublicApi.reloadTickets();
        this.notesAndTicketsPublicApi.reloadNotes();
      });
  }
}