<div class="row">
  <div class="col-md-6">
    <div>
      <span>
        Raw HTML
      </span>
      <button class="btn btn-info float-end" (click)="openModal(mustacheModal)">How are templates structured?</button>
    </div>
    <div [(formGroup)]="forms">
      <label class="mt-2 fw-bold">Title</label>
      <input class="form-control" type="text" formControlName="title" [formValidation]="title">
      <label class="mt-2 fw-bold">Body</label>
      <textarea (keydown)="handleKeydown($event)" class="form-control h-100" type="text" formControlName="body" [formValidation]="body" rows="20"></textarea>
    </div>
    <div class="pt-2 d-flex justify-content-end gap-2">
      <ng-container *ngIf="canEdit() && !isEditing">
        <ng-container *ngIf="canRevert()">
          <button class="btn btn-warning" (click)="openModal(confirmRevert)">Revert to Version</button>
        </ng-container>
        <button class="btn btn-success" (click)="edit()">Edit Template</button>
      </ng-container>
      <ng-container *ngIf="isEditing">
        <ng-container *ngIf="template().NotificationTemplateId">
          <button class="btn btn-danger" (click)="cancel()">Cancel</button>
        </ng-container>
        <button class="btn btn-success" (click)="template().PartnerId === null ? openModal(confirmSave) : save()">Save</button>
      </ng-container>
    </div>
  </div>
  <div class="col-md-6">
    <div>
      <div>
        <span>
          Rendered HTML
        </span>
        <i class="fa fa-question-circle" [ngbPopover]="tooltip" popoverTitle="HTML Rendering" triggers="mouseenter:mouseleave"></i>
        <button class="btn btn-warning float-end" (click)="openRenderModal(renderModal)">Render</button>
        <button class="btn btn-warning me-2 float-end" (click)="renderHtmlRaw()">Render Without Substitution</button>
      </div>
      <div>
        @if (!!renderedTitle) {
          <label class="mt-2 fw-bold">Title</label>
          <div [innerHTML]="renderedTitle | purifyHtml"></div>
        }
        @if(!!renderedBody) {
          <label class="mt-2 fw-bold">Body</label>
          <div [innerHTML]="renderedBody | purifyHtml"></div>
        }
        @if (!!renderedTitle || !!renderedBody) {
          @if (canSendEmail()) {
            <div class="d-flex justify-content-end">
              <button class="btn btn-warning mt-2" (click)="sendRendered(false)">Send Email</button>
            </div>
          }
          @if (canSendFrontApp()) {
            <div class="d-flex">
              <div class="flex-fill align-self-center">
                <label class="mt-2 fw-bold">Send To</label>
                <div class="btn-link" (click)="recipientFormControl.setValue(recipient()); openModal(recipientModal)">
                  <i class="fas fa-pencil-alt"></i>{{ recipient() }}
                </div>
              </div>
              <div class="d-flex gap-1 justify-content-end">
                <button class="btn btn-warning mt-2" (click)="sendRendered(true)">Send Email</button>
                <button class="btn btn-warning mt-2" (click)="sendRendered(false)">Send Email Without Sender</button>
              </div>
            </div>
          }
        }
      </div>
    </div>
  </div>
</div>

<ng-template #renderModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Field Substitution</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <app-mustache-value-display [(tags)]="templateTags"></app-mustache-value-display>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button class="btn btn-warning" (click)="modal.close()">Render</button>
  </div>
</ng-template>

<ng-template #tooltip>
  In order to display the HTML correctly in your browser, we need to render it as part of the webpage.
  In order to prevent this from causing issues with how the page is displayed and to prevent any potential
  vulnerabilities we need to clean the HTML. This can lead to minor differences in what you see here and the email.
</ng-template>

<ng-template #mustacheModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Template Explanation</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    Templates are built according to the <a href="https://mustache.github.io/mustache.5.html">Mustache(5) Specification</a>.
    Currently, our notification system supports the 1.1.2 with lambdas specification, which allows for the use of
    <b>Variables, Implicit Iterators, Sections, False Values or Empty Lists, Non-Empty Lists, Lambdas, Non-False Values, and Inverted Sections.</b>
    The browser renderer supports all of these except for lambdas.
  </div>
</ng-template>

<ng-template #confirmSave let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Confirm Save</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    Are you sure you want to save changes to the default template? This will affect all partners.
    <div class="d-flex justify-content-end gap-2">
      <button class="btn btn-warning" (click)="modal.dismiss()">Cancel</button>
      <button class="btn btn-success" (click)="modal.dismiss(); save()">Save</button>
    </div>
  </div>
</ng-template>

<ng-template #confirmRevert let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Confirm Reversion</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="template().PartnerId === null">
      Are you sure you want to revert the default template? This will affect all partners.
    </ng-container>
    <ng-container *ngIf="template().PartnerId !== null">
      Are you sure you want to revert the template?
    </ng-container>
    <div class="d-flex justify-content-end gap-2">
      <button class="btn btn-warning" (click)="modal.dismiss()">Cancel</button>
      <button class="btn btn-success" (click)="modal.dismiss(); revert()">Confirm</button>
    </div>
  </div>
</ng-template>

<ng-template #recipientModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Send To</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <input class="form-control" type="text" [formControl]="recipientFormControl">
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button class="btn btn-warning" (click)="modal.close()">Cancel</button>
    <button class="btn btn-success" (click)="modal.dismiss(); saveRecipient()">Confirm</button>
  </div>
</ng-template>